import React, { useState } from 'react';
import { Box, Popover } from '@material-ui/core';
import CommentBubble from 'modules/workflow/components/CommentBubble';
import colors from 'styles/colors.module.scss';
import { CommentsByVersion_commentsByVersion as CommentsByVersion } from 'app/schemaInterfaces/CommentsByVersion';

interface CommentBubblesProps {
  comments: CommentsByVersion[];
  scale: number;
}
const CommentBubbles = ({
  comments,
  scale,
}: CommentBubblesProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  return (
    <>
      {comments.map((comment, indx) => {
        return (
          <Box
            className="flex"
            key={indx}
            title={comment.text}
            position="absolute"
            top={`${comment.offsetPosition.y}%`}
            left={`${comment.offsetPosition.x}%`}
            style={{
              cursor: 'pointer',
              zIndex: 2,
              transform: `scale(${1 / scale})`,
            }}
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
              e.stopPropagation();
              e.persist();
            }}
          >
            <CommentBubble
              text={`${indx + 1}`}
              bubbleColor={colors.primary}
              bubbleTextColor={colors.white}
              bubbleBorder={`1px solid ${colors.primary}`}
            />
          </Box>
        );
      })}
      <Popover
        PaperProps={{ style: { padding: '10px' } }}
        open={anchorEl !== null}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        onBackdropClick={(e) => {
          e.stopPropagation();
          setAnchorEl(null);
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {anchorEl?.title}
      </Popover>
    </>
  );
};

export default CommentBubbles;
