import { TFunction } from 'i18next';
import { Entity } from 'modules/oneManager/entities/form/definitions';
import moment from 'moment';
import * as Yup from 'yup';

export interface DocumentCopyFormValues {
  name: string;
  entities: Entity[];
}

export const getDocumentCopyFormSchema = (t: TFunction) =>
  Yup.object({
    name: Yup.string().required(t('common.generic_error.required')),
    entities: Yup.array().min(1, t('common.generic_error.required')),
  });

export const monoprixData = [
  {
    nom: 'Prospectus',
    categorie: 'Print',
    pagination_totale: ['4', '8', '12', '16', '20', '24'],
    largeur_format_fini_cm: ['28', '21', '20', '18'],
    longueur_format_fini_cm: ['34', '29.7', '28.5', '26'],
    papier: ['LWC', 'CSB'],
    impression: ['Quadri'],
    faconnage: ['Piqué, rogné', 'Collé, rogné'],
  },
  {
    nom: 'Affiche',
    categorie: 'PLV',
    pagination_totale: ['1', '2'],
    largeur_format_fini_cm: ['12', '15', '21', '28', '40', '42', '84.1'],
    longueur_format_fini_cm: ['60', '34', '28.5', '26'],
    papier: ['Sans Bois'],
    impression: ['Quadri'],
    faconnage: ['Coupe au format'],
  },
  {
    nom: 'Stop Rayon',
    categorie: 'PLV',
    pagination_totale: [],
    largeur_format_fini_cm: [],
    longueur_format_fini_cm: [],
    papier: [],
    impression: ['Quadri'],
    faconnage: ['Coupe franche'],
  },
];

export const minDateTo = (dateFrom: Date | null) =>
  dateFrom ? dateFrom : moment();
