import { useLazyQuery, useQuery } from '@apollo/client';
import {
  GET_SUPPLY_PRODUCTS,
  GET_SUPPLY_PRODUCT_BY_ID,
} from 'app/graphql/queries/supplyProducts';
import {
  GetSupplyProducts,
  GetSupplyProductsVariables,
  GetSupplyProducts_supplyProducts,
  GetSupplyProducts_supplyProducts_conditionnement,
} from 'app/schemaInterfaces/GetSupplyProducts';
import { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { GetSupports_supports_supportAttributes } from 'app/schemaInterfaces/GetSupports';
import { DocumentFormValues } from '../model/definitions';
import { useFormikContext } from 'formik';
import {
  Conditionnement,
  NON_VALUE,
} from './DocumentProductSubForm.definition';
import {
  GetSupplyProductById,
  GetSupplyProductByIdVariables,
} from 'app/schemaInterfaces/GetSupplyProductById';
import { AttributeKey } from 'app/schemaInterfaces/globalTypes';
import {
  filterProductByAttribute,
  getAttributeOptions,
  getPackagingAttributeMaps,
  serializeOsProductToFormikAttributes,
} from './DocumentProductSubform.helper';
import { useTranslation } from 'react-i18next';

export type AttributeFormOptions = {
  attribute: GetSupports_supports_supportAttributes;
  values: (
    | string
    | GetSupplyProducts_supplyProducts_conditionnement
    | Conditionnement
    | null
  )[];
};

type UseProductAttributesParams = {
  /**
   * the id of the selected support
   */
  supportId: string;
  /**
   * the product id of the current document (update mode)
   */
  productId?: string | null;
  /**
   * The exhaustive attributes list of the selected support
   */
  attributes: GetSupports_supports_supportAttributes[];
};

type UseProductAttributesReturn = {
  /**
   * The list of available product attributes options
   */
  attributeFormOptions: AttributeFormOptions[];
  /**
   * The loading state of the products queries
   */
  loading: boolean;
  /**
   * The function to handle attribute change
   */
  handleAttributeChange: (
    attribute: GetSupports_supports_supportAttributes,
    value: string
  ) => void;
  /**
   * The boolean to know if there is a supply config error
   */
  isOsConfigError: boolean;
};

/**
 * Discriminate the product attributes options available depending of active formik context productAttributes values.
 * @param param0.supportId the id of the selected support
 * @param param0.productId the product id of the current document (update mode)
 * @param param0.attributes The exhaustive attributes list of the selected support
 */
export const useProductAttributes = ({
  supportId,
  productId,
  attributes,
}: UseProductAttributesParams): UseProductAttributesReturn => {
  /** setup base hooks */
  const { t } = useTranslation();
  const {
    values: {
      productAttributes: FormikProductAttributes,
      document: FormikDocument,
    },
    setFieldValue,
  } = useFormikContext<DocumentFormValues>();

  /** LazyQuery to get the current product (update mode) */
  const [
    getProductById,
    { data: currentOsProduct, loading: currentOsProductLoading },
  ] = useLazyQuery<GetSupplyProductById, GetSupplyProductByIdVariables>(
    GET_SUPPLY_PRODUCT_BY_ID
  );

  /** query to get all products of the selected support */
  const {
    data: osProductsBySupport,
    loading: loadingOsProductsBySupport,
  } = useQuery<GetSupplyProducts, GetSupplyProductsVariables>(
    GET_SUPPLY_PRODUCTS,
    {
      variables: {
        filters: {
          supportId,
        },
      },
    }
  );

  /** Current discriminated product list */
  const [filteredOsProducts, setFilteredOsProducts] = useState<
    GetSupplyProducts_supplyProducts[]
  >([]);

  /**
   * Memoize all packaging attributes to get a static values
   * (because of its structure, different than other attributes)
   */
  const memoizedPackagingValues = useMemo(
    () =>
      getPackagingAttributeMaps(
        osProductsBySupport?.supplyProducts ?? [],
        t('global.document_form.attributes_names.conditionnement')
      ),
    [osProductsBySupport, t]
  );

  /** Current discriminated attributes options list */
  const attributeFormOptions: AttributeFormOptions[] = useMemo(
    () =>
      getAttributeOptions(
        attributes,
        filteredOsProducts,
        memoizedPackagingValues.packagingAttributesByContentMap
      ),
    [filteredOsProducts, attributes, memoizedPackagingValues]
  );

  /** Boolean to know if all attributes are filled */
  const isAllAttributesFilled = useMemo(() => {
    return attributeFormOptions.every(({ values }) => values.length === 1);
  }, [attributeFormOptions]);

  /** Query the current product if productId is provided (update mode) */
  useEffect(() => {
    if (productId) {
      getProductById({ variables: { productId: productId } });
    }
  }, [productId, getProductById]);

  /**
   * Update the filteredOsProducts list when the productAttributes change
   * (based on all selected support products)
   */
  useEffect(() => {
    if (osProductsBySupport) {
      const newFilteredProducts = filterProductByAttribute(
        osProductsBySupport.supplyProducts,
        FormikProductAttributes
      );
      setFilteredOsProducts(newFilteredProducts);
      if (newFilteredProducts.length === 1) {
        const newProductAttributes = serializeOsProductToFormikAttributes(
          newFilteredProducts[0]
        );
        if (!_.isEqual(newProductAttributes, FormikProductAttributes)) {
          setFieldValue('productAttributes', newProductAttributes);
        }
      }
    }
  }, [FormikProductAttributes, osProductsBySupport, setFieldValue]);

  /** Update formik document.productId field when the filteredOsProducts change */
  useEffect(() => {
    if (filteredOsProducts.length === 1) {
      setFieldValue('document.productId', filteredOsProducts[0].id, true);
    } else if (productId !== null) {
      setFieldValue('document.productId', null, true);
    }
  }, [filteredOsProducts, setFieldValue, FormikProductAttributes, productId]);

  /** Update formik productAttributes with the current active product (update mode) */
  useEffect(() => {
    if (productId && currentOsProduct) {
      setFieldValue(
        'productAttributes',
        serializeOsProductToFormikAttributes(currentOsProduct.supplyProductById)
      );
    }
  }, [currentOsProduct, productId, setFieldValue]);

  /** the handler to handle attribute change */
  const handleAttributeChange = (
    attribute: GetSupports_supports_supportAttributes,
    value: string
  ) => {
    /** Serialize displayed values for server request (for packaging & null value) */
    let newAttributeValue =
      attribute.key === AttributeKey.conditionnement
        ? memoizedPackagingValues.packagingAttributesByValueMap.get(value) ||
          null
        : value === NON_VALUE
        ? null
        : value;
    setFieldValue(`productAttributes.${attribute.key}`, newAttributeValue);
  };

  return {
    attributeFormOptions,
    loading: currentOsProductLoading || loadingOsProductsBySupport,
    handleAttributeChange,
    isOsConfigError:
      ((!FormikDocument.productId && isAllAttributesFilled) ||
        filteredOsProducts.length === 0) &&
      (!currentOsProductLoading || !loadingOsProductsBySupport),
  };
};
