import { FetchResult } from '@apollo/client';
import { AddressBlocks_addressBlocks } from 'app/schemaInterfaces/AddressBlocks';
import { RemoveAddressBlock } from 'app/schemaInterfaces/RemoveAddressBlock';
import { useState } from 'react';

export const useAddressBlockDelete = (
  removeAddressBlock: (
    id: string
  ) => Promise<
    FetchResult<RemoveAddressBlock, Record<string, any>, Record<string, any>>
  >
) => {
  const [open, setOpen] = useState<boolean>(false);
  const [itemToDelete, setItemToDelete] = useState<string | null>(null);
  const openDialog = () => {
    setOpen(true);
  };

  const deleteItem = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: string | null
  ) => {
    if (item) {
      await removeAddressBlock(item);
    }
  };

  const handleItemToDelete = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: AddressBlocks_addressBlocks
  ) => {
    setItemToDelete(item.id);
  };

  return {
    itemToDelete,
    setItemToDelete,
    deleteItem,
    open,
    setOpen,
    handleItemToDelete,
    openDialog,
  };
};
