import {
  CONNECT_TO_FACEBOOK,
  FACEBOOK_PAGE_STATUS,
  GET_ENTITY_SOCIAL_NETWORKS,
} from '../../../app/graphql/queries/socialNetworks';
import {
  FacebookConnect,
  FacebookConnectVariables,
} from '../../../app/schemaInterfaces/FacebookConnect';
import {
  GetEntity,
  GetEntityVariables,
} from '../../../app/schemaInterfaces/GetEntity';
import {
  GetFacebookPageStatus,
  GetFacebookPageStatusVariables,
} from '../../../app/schemaInterfaces/GetFacebookPageStatus';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useMutationProvider,
  useQueryLazyProvider,
  useQueryProvider,
} from '../../../app/utils/provider/useProvider';

import Loader from '../../../components/Loader/Loader/Loader';
import useCurrentEntity from '../../../app/current-entity-context/useCurrentEntity';
import { useCurrentUser } from '../../../app/auth/UserContext';
import { useHistory } from 'react-router-dom';
import { usePrevious } from '../../../state/use-previous';
import { useRouter } from 'app/routes/useRouter';
import { useTranslation } from 'react-i18next';

export const useSocialNetworks = () => {
  const { t } = useTranslation();
  const { goBack } = useRouter().history;
  const [clientNetworkPageId, setClientNetworkPageId] = useState<
    string | undefined
  >(undefined);
  const { currentEntity, setCurrentEntity } = useCurrentEntity();
  const user = useCurrentUser();

  const history = useHistory();
  const clientNetWorkPageIdPrevious = usePrevious<string | undefined>(
    clientNetworkPageId
  );

  const {
    data: socialNetworksData,
    loading: socialNetworksLoading,
    refetch,
  } = useQueryProvider<GetEntity, GetEntityVariables>(
    GET_ENTITY_SOCIAL_NETWORKS,
    {
      variables: {
        id: currentEntity?.id ?? '',
      },
      fetchPolicy: 'network-only',
    }
  );

  const [
    getFacebookPageStatus,
    { data: dataStatus, loading: statusLoading },
  ] = useQueryLazyProvider<
    GetFacebookPageStatus,
    GetFacebookPageStatusVariables
  >(FACEBOOK_PAGE_STATUS, {
    onCompleted: (data) => {
      if (data && currentEntity) {
        const entity = {
          ...currentEntity,
          socialNetworks: socialNetworksData?.entity?.socialNetworks ?? null,
        };
        setCurrentEntity(entity);
        goBack();
      }
    },
  });

  const [
    facebookConnect,
    { data: facebookConnectData, error },
  ] = useMutationProvider<FacebookConnect, FacebookConnectVariables>(
    CONNECT_TO_FACEBOOK,
    {
      onError: (error) => {
        return error;
      },
      onCompleted: async () => {
        if (currentEntity && currentEntity.id)
          await getFacebookPageStatus({
            variables: {
              id: currentEntity?.id,
            },
          });
      },
    }
  );

  const changeValueClientNetworkPageId = useMemo(() => {
    return clientNetworkPageId
      ? clientNetworkPageId
      : socialNetworksData?.entity?.socialNetworks?.clientNetworkLink ??
          currentEntity?.socialNetworks?.clientNetworkPageId ??
          '';
  }, [
    currentEntity?.socialNetworks?.clientNetworkPageId,
    clientNetworkPageId,
    socialNetworksData?.entity?.socialNetworks?.clientNetworkLink,
  ]);

  const handleAuthFacebook = useCallback(async () => {
    if (!currentEntity?.id || !clientNetworkPageId) return;
    await facebookConnect({
      variables: {
        id: currentEntity?.id,
        clientNetworkPageId,
      },
    });
  }, [currentEntity?.id, facebookConnect, clientNetworkPageId]);

  const redirectPage = useCallback(
    async (name: string) => {
      history.push(name);
    },
    [history]
  );

  useEffect(() => {
    if (dataStatus?.facebookPageStatus?.message) {
      setClientNetworkPageId(
        `https://www.facebook.com/${
          dataStatus?.facebookPageStatus?.message?.name ??
          dataStatus?.facebookPageStatus?.message?.id
        }`
      );
    }
  }, [
    currentEntity,
    dataStatus,
    setCurrentEntity,
    socialNetworksData?.entity?.socialNetworks,
    user.id,
  ]);

  useEffect(() => {
    if (facebookConnectData || error) {
      refetch();
    }
  }, [facebookConnectData, error, refetch]);

  const isCompleteInformations = useMemo(
    () =>
      socialNetworksData?.entity?.socialNetworks?.clientNetworkPageId &&
      clientNetWorkPageIdPrevious === clientNetworkPageId,
    [
      clientNetworkPageId,
      clientNetWorkPageIdPrevious,
      socialNetworksData?.entity?.socialNetworks?.clientNetworkPageId,
    ]
  );

  const isCanPost = useMemo(
    () => socialNetworksData?.entity?.socialNetworks?.isCanPost,
    [socialNetworksData?.entity?.socialNetworks?.isCanPost]
  );

  const isDisabled = useMemo(
    () =>
      !!socialNetworksData?.entity?.socialNetworks?.clientNetworkLink &&
      clientNetWorkPageIdPrevious === clientNetworkPageId,
    [
      clientNetworkPageId,
      clientNetWorkPageIdPrevious,
      socialNetworksData?.entity?.socialNetworks?.clientNetworkLink,
    ]
  );

  const isErrorMessage = useMemo(
    () =>
      error?.message ||
      (!socialNetworksData?.entity?.socialNetworks?.isCanPost &&
        socialNetworksData?.entity?.socialNetworks?.clientNetworkPageId),
    [
      error?.message,
      socialNetworksData?.entity?.socialNetworks?.clientNetworkPageId,
      socialNetworksData?.entity?.socialNetworks?.isCanPost,
    ]
  );

  const renderLoading = useCallback(() => {
    if (socialNetworksLoading || statusLoading) {
      return <Loader />;
    }
  }, [socialNetworksLoading, statusLoading]);

  return {
    t,
    error,
    clientNetworkLink:
      clientNetworkPageId ??
      socialNetworksData?.entity?.socialNetworks?.clientNetworkLink ??
      '',
    socialNetworksLoading,
    handleAuthFacebook,
    clientNetWorkPageIdPrevious,
    statusLoading,
    redirectPage,
    socialNetworksData,
    changeValueClientNetworkPageId,
    setClientNetworkPageId,
    isCompleteInformations,
    isCanPost,
    isDisabled,
    isErrorMessage,
    renderLoading,
  };
};
