import React, { useState } from 'react';
import { Box, CircularProgress, Grid } from '@material-ui/core';
import colors from 'styles/colors.module.scss';
import { useStudioDispatchContext } from 'modules/workflow/oneStudioContext';
import BackgroundItemsSubPanel, {
  BackgroundAsset,
} from 'modules/workflow/containers/Leftsidebar/TemplatesPanel/BackgroundItemsSubPanel';
import { studioAppActions } from 'modules/workflow/reducers/actionsInterfaces';
import useAssetQuery from 'modules/dam/hooks/useAssetQuery';
import {
  AscDescType,
  AssetStatus,
  AssetType,
  CharterType,
} from 'app/schemaInterfaces/globalTypes';
import QuickSearchBar from 'components/QuickSearchBar/QuickSearchBar';
import PanelList from 'components/studio/PanelList';
import BigBreadcrumbs from 'components/studio/BigBreadcrumbs';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { DraggedItemTypes } from 'modules/workflow/containers/CatalogPreview/EditPlatformDefinitions';
import useCurrentEntity from 'app/current-entity-context/useCurrentEntity';

interface OptionTemplate {
  label: string;
  value: CharterType;
}

const TemplatesPanel = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useStudioDispatchContext();
  const [searchAsset, setSearchAsset] = useState<string>('');
  const [optionSelected, setOptionSelected] = useState<
    OptionTemplate | undefined
  >(undefined);

  const options = [
    {
      label: t(`dam.charter_types.familybackground`),
      value: CharterType.FamilyBackground,
    },
    {
      label: t(`dam.charter_types.pagebackground`),
      value: CharterType.PageBackground,
    },
  ];

  const { currentEntity } = useCurrentEntity();

  const { assets, loadMore, loading } = useAssetQuery(
    {
      filters: {
        entityId: currentEntity?.id,
        text: searchAsset,
        divisions: {},
        assetTypes: [AssetType.Charter],
        charterTypes: optionSelected ? [optionSelected?.value] : undefined,
        status: [AssetStatus.Active],
      },
      sort: {
        name: AscDescType.asc,
      },
    },
    16,
    !Boolean(optionSelected)
  );

  const searchBackgroundTemplates = (
    searchEvent: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearchAsset(searchEvent.target.value);
  };

  const handleDrag = (backgroundAsset: BackgroundAsset, type: CharterType) => {
    const item = { ...backgroundAsset, key: uuidv4() };
    const backgroundCategory =
      type === CharterType.FamilyBackground
        ? DraggedItemTypes.BACKGROUND_IMAGE
        : DraggedItemTypes.BACKGROUND_COLOR;

    dispatch(
      studioAppActions.setDraggedBackground({
        draggedItem: {
          item,
          type: backgroundCategory,
        },
      })
    );
  };

  const handleTemplateClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    value: CharterType
  ) => {
    setOptionSelected(options?.find((option) => option.value === value));
  };

  return (
    <Box p="15px" height="100vh" overflow="scroll">
      <Box display="flex" alignItems="center">
        <Grid
          container
          item
          direction="row"
          justify="center"
          alignItems="center"
        >
          <QuickSearchBar
            placeholder={t(
              'workflow.left_side_bar.templates_panel.search_placeholder'
            )}
            onChange={searchBackgroundTemplates}
            inputBaseWidth="231px"
            bgcolor={colors.white}
            hasBorder={false}
          />
        </Grid>
      </Box>
      <Box>
        {optionSelected ? (
          <Box marginTop="10px">
            <BigBreadcrumbs
              links={[
                {
                  label: t(
                    'workflow.left_side_bar.templates_panel.big_breadcrumbs_template'
                  ),
                  name: 'Template',
                },
                {
                  label: optionSelected.label,
                  name: optionSelected.value,
                  selected: true,
                },
              ]}
              onClick={() => setOptionSelected(undefined)}
            />
            <BackgroundItemsSubPanel
              backgroundItems={assets}
              onDragStart={(backgroundAsset: BackgroundAsset) =>
                handleDrag(backgroundAsset, optionSelected.value)
              }
              onLoadMore={loadMore}
              loading={loading}
            />
          </Box>
        ) : options ? (
          <PanelList options={options} onClick={handleTemplateClick} />
        ) : (
          <Box display="flex" justifyContent="center" color={colors.white}>
            <CircularProgress color="inherit" />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TemplatesPanel;
