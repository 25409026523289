import { useQuery, WatchQueryFetchPolicy } from '@apollo/client';
import { GET_ALL_CAMPAIGNS_LEAN } from 'app/graphql/queries/campaigns';
import {
  GetAllCampaignsLean,
  GetAllCampaignsLeanVariables,
} from 'app/schemaInterfaces/GetAllCampaignsLean';
import {
  CampaignFilterInput,
  PaginationInput,
} from 'app/schemaInterfaces/globalTypes';

interface useCampaignsLeanQueryProps {
  filter?: CampaignFilterInput;
  pagination?: PaginationInput;
  fetchPolicy?: WatchQueryFetchPolicy;
}

const useCampaignsLeanQuery = ({
  filter = {},
  pagination = {},
  fetchPolicy = 'network-only',
}: useCampaignsLeanQueryProps = {}) =>
  useQuery<GetAllCampaignsLean, GetAllCampaignsLeanVariables>(
    GET_ALL_CAMPAIGNS_LEAN,
    {
      fetchPolicy,
      variables: {
        filter,
        pagination,
      },
    }
  );

export default useCampaignsLeanQuery;
