import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';
import colors from 'styles/colors.module.scss';
import { PreviewFile } from '../ControlledFileUpload/ControlledFileUpload';
import 'modules/dam/AssetThumbnail.scss';
import {
  BackgroundSizeCssProps,
  FileExtension,
  Optional,
} from 'app/utils/common';
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';
import PdfPreview, { PdfPreviewSize } from '../PdfPreview/PdfPreview';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import { fileUploadIconStyle } from './FileUploadIcon.style';

export interface InitialImage {
  url: Optional<string>;
  extension: Optional<string>;
}

interface FileUploadIconProps {
  width?: number;
  height?: number;
  fontSize?: number;
  fileUploadBackground?: string;
  files: PreviewFile[];
  initialImage?: InitialImage;
  IsLargePreview?: boolean;
  previewBackgroundSize?: BackgroundSizeCssProps;
  isLoading?: boolean;
  setIsLoading?: (isLoading: boolean) => void;
  previewWidth?: number;
  previewHeight?: number;
  openDialog: () => void;
  setFilesPreview: React.Dispatch<React.SetStateAction<PreviewFile[]>>;
}

const FileUploadIcon = ({
  width = 93,
  previewWidth,
  previewHeight,
  fileUploadBackground = colors.fileUploadBackground,
  fontSize = 40,
  files,
  initialImage,
  IsLargePreview,
  previewBackgroundSize = 'contain',
  isLoading,
  setIsLoading,
  openDialog,
  setFilesPreview,
}: FileUploadIconProps): JSX.Element => {
  const isPreviewImage = files.length !== 0;
  const boxWidth =
    isPreviewImage || initialImage?.url
      ? width * (IsLargePreview ? 3.5 : 1.5)
      : width;
  const displayImage = (): string | undefined =>
    isPreviewImage ? files[0].preview : initialImage?.url;

  const renderPdfPreview = () => {
    const hasUserDroppedANewFile = !!files.length;
    const isDroppedFilePdf =
      hasUserDroppedANewFile &&
      files[0].base64 &&
      files[0].extension === FileExtension.Pdf;

    if (isDroppedFilePdf) {
      return (
        <PdfPreview
          file={files[0].base64 as string}
          size={PdfPreviewSize.Large}
        />
      );
    }
  };

  return (
    <Box
      css={fileUploadIconStyle(
        IsLargePreview,
        isPreviewImage,
        initialImage,
        previewWidth,
        previewHeight,
        boxWidth,
        fileUploadBackground,
        fontSize,
        isLoading
      )}
    >
      {files.length > 0 && isLoading && <CircularProgress />}
      {displayImage() && (
        <div className="image-box">
          <img
            alt="loaded"
            src={displayImage()}
            onLoad={() => {
              setIsLoading && setIsLoading(false);
            }}
            className="image"
          />
          <div className="button-box">
            <div onClick={openDialog} className="replace-icon">
              <CreateOutlinedIcon fontSize="small" />
            </div>
            <div onClick={() => setFilesPreview([])} className="delete-icon">
              <DeleteForeverOutlinedIcon fontSize="small" />
            </div>
          </div>
        </div>
      )}
      {files.length === 0 && !initialImage?.url && (
        <Box pt={1} onClick={openDialog}>
          <PhotoCameraOutlinedIcon
            color="disabled"
            className="camera-icon"
            style={{ color: colors.white, fontSize }}
          />
        </Box>
      )}
      {files.length === 0 && !initialImage?.url && (
        <Box position="absolute" bottom="0" right="0" onClick={openDialog}>
          <AddCircleOutlinedIcon
            color="primary"
            fontSize="small"
            className="circle-icon"
          />
        </Box>
      )}
      {renderPdfPreview()}
    </Box>
  );
};

export default FileUploadIcon;
