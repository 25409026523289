import { gql } from '@apollo/client';

export const CONNECT_TO_FACEBOOK = gql`
  mutation FacebookConnect($clientNetworkPageId: String!, $id: String!) {
    facebookConnect(clientNetworkPageId: $clientNetworkPageId, id: $id) {
      id
      name
    }
  }
`;

export const FACEBOOK_PAGE_STATUS = gql`
  query GetFacebookPageStatus($id: String!) {
    facebookPageStatus(id: $id) {
      can_post
      message {
        id
        name
        message
      }
    }
  }
`;

export const GET_ENTITY_SOCIAL_NETWORKS = gql`
  query GetEntity($id: String!) {
    entity(id: $id) {
      name
      id
      socialNetworks {
        clientNetworkPageId
        clientNetworkLink
        isCanPost
      }
    }
  }
`;
