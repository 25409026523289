import _ from 'lodash';
import { useMutation, useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import { FormControl, MenuItem, TextField } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { AddressBlocks_addressBlocks } from 'app/schemaInterfaces/AddressBlocks';
import {
  GetVersionQuery,
  GetVersionQueryVariables,
} from 'app/schemaInterfaces/GetVersionQuery';
import {
  GET_VERSION_QUERY,
  UPDATE_CATALOG_MUTATION,
} from 'app/graphql/queries/version';
import { ChangeEvent, useEffect } from 'react';
import { useStoreActions } from 'state/store';
import colors from 'styles/colors.module.scss';
import { SelectedBasketdocument } from '../sales.state';
import {
  UpdateVersionMutation,
  UpdateVersionMutationVariables,
} from 'app/schemaInterfaces/UpdateVersionMutation';
import { VersionPageInput } from 'app/schemaInterfaces/globalTypes';
import { PersonalizationItem } from 'modules/workflow/oneStudioContext';
import { normalizeDigitalContent } from 'modules/workflow/containers/EditPlatform.utils.helper';

const style = css`
  .MuiInputBase-input.MuiSelect-outlined.MuiSelect-outlined {
    padding: 12px;
  }
`;

const SelectAddressBlocks = ({
  options,
  index,
  basketDocumentItem,
}: {
  options: AddressBlocks_addressBlocks[] | undefined;
  index: number;
  basketDocumentItem: SelectedBasketdocument;
}) => {
  const setAddressBlock = useStoreActions(
    (actions) => actions.salesModule.basketdocuments.setAddressBlockToDocument
  );

  const { data: versionData } = useQuery<
    GetVersionQuery,
    GetVersionQueryVariables
  >(GET_VERSION_QUERY, {
    variables: { documentId: basketDocumentItem.basketDocument.document.id },
    fetchPolicy: 'network-only',
  });
  const [updateVersion] = useMutation<
    UpdateVersionMutation,
    UpdateVersionMutationVariables
  >(UPDATE_CATALOG_MUTATION, {
    onCompleted: (mutationResult) => {
      console.log({
        status: 'Version Updated Successfully',
        data: mutationResult.updateVersionPages,
      });
    },
  });

  useEffect(() => {
    if (!versionData) return;
    const selectedAddressBlock: number | undefined = options?.findIndex(
      (option) => {
        return versionData?.getVersion?.pages?.some((version) =>
          version?.pageTemplate?.some((pageTemplate) =>
            pageTemplate?.personalizationItems?.some(
              (perso: PersonalizationItem) =>
                perso.content?.id === option.asset.id
            )
          )
        );
      }
    );

    if (!selectedAddressBlock || selectedAddressBlock === -1) return;
    if (options?.[selectedAddressBlock]) {
      setAddressBlock({
        index,
        documentId: basketDocumentItem.basketDocument.document.id,
        addressBlockId: options[selectedAddressBlock as number].id,
      });
    }
  }, [
    versionData,
    options,
    basketDocumentItem.basketDocument.document.id,
    index,
    setAddressBlock,
  ]);

  function handleUpdateDocumentVersionPages(
    newValue: AddressBlocks_addressBlocks
  ) {
    if (!versionData?.getVersion?.id) return;
    const selectedAddressBlock = versionData?.getVersion?.pages?.some(
      (version) =>
        version?.pageTemplate?.some((pageTemplate) =>
          pageTemplate?.personalizationItems?.some(
            (perso: any) => perso.subType === 'ADDRESSBLOCK'
          )
        )
    );
    if (!selectedAddressBlock) return;
    const res = versionData?.getVersion?.pages?.map((version) => {
      const page = version?.pageTemplate?.map((pageTemplate) => {
        const item = pageTemplate?.personalizationItems?.map((perso: any) => {
          if (perso.subType === 'ADDRESSBLOCK') {
            return {
              ...perso,
              content: {
                ...perso.content,
                id: newValue.asset.id,
                url: newValue.asset.url,
                name: newValue.name,
              },
            };
          }
          return perso;
        });
        return {
          ...pageTemplate,
          personalizationItems: item,
        };
      });
      return {
        ..._.omit(version, ['id', '__typename']),
        pageTemplate: page,
      };
    });

    if (!res) return;
    updateVersion({
      variables: {
        id: versionData?.getVersion?.id,
        pages: res as VersionPageInput[],
        digitalContent: normalizeDigitalContent(
          versionData?.getVersion?.digitalContent
        ),
      },
    });
  }

  useEffect(() => {
    if (
      !Boolean(basketDocumentItem.addresses?.[index].addressBlockId) &&
      options?.[0]
    ) {
      setAddressBlock({
        index,
        documentId: basketDocumentItem.basketDocument.document.id,
        addressBlockId: options[0].id,
      });
    }
  }, [basketDocumentItem, index, options, setAddressBlock]);

  const handleChange = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    const newValue = options?.find(
      (addressBlock) => addressBlock.id === event.target.value
    );
    if (newValue) {
      setAddressBlock({
        index,
        documentId: basketDocumentItem.basketDocument.document.id,
        addressBlockId: newValue.id,
      });
      handleUpdateDocumentVersionPages(newValue);
    }
  };

  const addressBlockId = basketDocumentItem.addresses?.[index].addressBlockId;
  const value = options?.find(
    (addressBlock) => addressBlock.id === addressBlockId
  );

  return (
    <FormControl fullWidth css={style}>
      <TextField
        css={style}
        select
        variant="outlined"
        color="primary"
        SelectProps={{
          renderValue: (option: any) => {
            return option.name || '';
          },
          value: value ?? '',
          defaultValue: options?.[0] ?? '',
          IconComponent: (props) => <KeyboardArrowDownIcon {...props} />,
        }}
        onChange={handleChange}
      >
        {options
          ?.filter(
            (addressBlock) =>
              addressBlock.supportId ===
              basketDocumentItem.basketDocument.document.supportId
          )
          ?.map((item) => (
            <MenuItem
              style={{
                backgroundColor:
                  value?.id === item.id ? colors.menuHover : 'none',
              }}
              value={item.id || ''}
              key={item.id}
            >
              <div>
                <h4>{item.name}</h4>
              </div>
            </MenuItem>
          )) ?? <span />}
      </TextField>
    </FormControl>
  );
};
export default SelectAddressBlocks;
