import { gql } from '@apollo/client';

const COMMENT_DETAIL = gql`
  fragment CommentDetail on Comment {
    clientPosition {
      x
      y
    }
    createdAt
    id
    isSeen
    offsetPosition {
      x
      y
    }
    replies {
      createdAt
      id
      text
      userId
      user {
        familyName
        givenName
      }
    }
    text
    userId
    user {
      familyName
      givenName
    }
  }
`;

export const GET_COMMENTS_BY_VERSION = gql`
  query CommentsByVersion($versionId: String!) {
    commentsByVersion(versionId: $versionId) {
      ...CommentDetail
    }
  }
  ${COMMENT_DETAIL}
`;

export const ADD_COMMENT = gql`
  mutation AddComment(
    $versionId: String!
    $comment: CommentInput!
    $pageId: String!
  ) {
    addComment(versionId: $versionId, pageId: $pageId, comment: $comment) {
      ...CommentDetail
    }
  }
  ${COMMENT_DETAIL}
`;

export const DELETE_COMMENT = gql`
  mutation DeleteComment(
    $versionId: String!
    $commentId: String!
    $pageId: String!
  ) {
    deleteCommentById(
      commentId: $commentId
      pageId: $pageId
      versionId: $versionId
    ) {
      ...CommentDetail
    }
  }
  ${COMMENT_DETAIL}
`;

export const DELETE_REPLY = gql`
  mutation DeleteReply(
    $versionId: String!
    $commentId: String!
    $pageId: String!
    $replyId: String!
  ) {
    deleteReplyById(
      commentId: $commentId
      pageId: $pageId
      versionId: $versionId
      replyId: $replyId
    ) {
      ...CommentDetail
    }
  }
  ${COMMENT_DETAIL}
`;

export const UPDATE_COMMENT = gql`
  mutation UpdateComment(
    $versionId: String!
    $commentId: String!
    $pageId: String!
    $text: String!
  ) {
    updateComment(
      commentId: $commentId
      text: $text
      pageId: $pageId
      versionId: $versionId
    ) {
      ...CommentDetail
    }
  }
  ${COMMENT_DETAIL}
`;

export const UPDATE_REPLY = gql`
  mutation UpdateReply(
    $versionId: String!
    $commentId: String!
    $pageId: String!
    $replyId: String!
    $text: String!
  ) {
    updateReply(
      commentId: $commentId
      text: $text
      pageId: $pageId
      versionId: $versionId
      replyId: $replyId
    ) {
      ...CommentDetail
    }
  }
  ${COMMENT_DETAIL}
`;
