import React from 'react';
import { IProductTemplate } from '../../ProductItem';
import PriceTagElementContainer from './PriceTagElementContainer';
import ProductBargainPrices from './ProductBargainPrices';
import { TypographyIdentities } from 'modules/workflow/oneStudioContext';
import { useTranslation } from 'react-i18next';
import ProductPriceLabels from './ProductPriceLabels';
import ProductPriceOptionalUnity from './ProductPriceOptionalUnity';
import { priceFooterPosition } from 'modules/workflow/containers/Leftsidebar/ProductsPanel/helpers/visualIdentitiesHelper';

interface PriceTagFooterProps {
  product: IProductTemplate;
  isCompaniesPrice: boolean;
  fullCompaniesPrice: TypographyIdentities | undefined;
  foreignPriceSymbol: string;
  priceLabels: TypographyIdentities | undefined;
  priceWithTaxes: Record<string, any>;
  hasFieldEntityId: boolean;
  priceOptionalUnity: TypographyIdentities | undefined;
  priceUnity: TypographyIdentities | undefined;
  fieldEntityId: string;
}

const PriceTagFooter = ({
  product,
  isCompaniesPrice,
  fullCompaniesPrice,
  priceWithTaxes,
  foreignPriceSymbol,
  priceLabels,
  priceUnity,
  priceOptionalUnity,
  hasFieldEntityId,
  fieldEntityId,
}: PriceTagFooterProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        left: priceFooterPosition(
          priceLabels,
          fullCompaniesPrice,
          priceOptionalUnity
        ).x,
        top: priceFooterPosition(
          priceLabels,
          fullCompaniesPrice,
          priceOptionalUnity
        ).y,
        position: 'absolute',
        width: '100%',
        textAlign: 'center',
      }}
    >
      {/* soit... TTC */}
      {isCompaniesPrice &&
        fullCompaniesPrice &&
        priceWithTaxes &&
        !!priceWithTaxes.length && (
          <>
            <PriceTagElementContainer
              product={product}
              priceTagElement={fullCompaniesPrice}
              value={`${t(
                'workflow.typography_item.le_soit'
              )} ${priceWithTaxes} € ${foreignPriceSymbol}`}
              textAlign="center"
            />
            {!hasFieldEntityId && (
              <ProductBargainPrices
                product={product}
                priceLabels={priceLabels}
                priceOptionalUnity={fullCompaniesPrice}
                isCompaniesPrice={isCompaniesPrice}
              />
            )}
          </>
        )}
      {/* soit le kg */}
      {priceUnity &&
        priceOptionalUnity &&
        fieldEntityId &&
        hasFieldEntityId &&
        !isCompaniesPrice && (
          <ProductPriceOptionalUnity
            product={product}
            priceOptionalUnity={priceOptionalUnity}
            priceLabels={priceLabels}
          />
        )}
      {/* ecoparticipation */}
      {priceLabels && fieldEntityId && hasFieldEntityId && (
        <ProductPriceLabels
          product={product}
          priceLabels={priceLabels}
          priceOptionalUnity={priceOptionalUnity}
          isCompaniesPrice={isCompaniesPrice}
        />
      )}
    </div>
  );
};

export default PriceTagFooter;
