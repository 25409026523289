import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const myListStyle = (isPim: boolean) => css`
  width: '100%';

  h5 {
    color: ${isPim ? colors.body : colors.white};
    width: 100%;
    overflow: hidden;
    padding-left: 8px;
    text-overflow: ellipsis;
    font-weight: 500;
  }
  .arrows {
    display: flex;
  }
`;
