import React, { FC } from 'react';
import { Box, Button, ButtonProps, Typography } from '@material-ui/core';

export interface HeaderButtonsProps {
  bgColor?: string;
  submitButtonTitle?: string;
  secondaryButtonTitle?: string;
  submitButtonProps?: ButtonProps;
  secondaryButtonProps?: ButtonProps;
  disableSubmitButton?: boolean;
  title?: string;
}

const HeaderButtons: FC<HeaderButtonsProps> = ({
  bgColor,
  submitButtonTitle,
  secondaryButtonTitle = 'Annuler',
  submitButtonProps,
  secondaryButtonProps,
  disableSubmitButton,
  title,
}) => (
  <Box
    bgcolor={bgColor}
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    px={8}
  >
    <Typography variant="h4">{title}</Typography>
    <Box py={2} display="flex">
      <Box pr={3}>
        <Button color="primary" {...secondaryButtonProps}>
          {secondaryButtonTitle}
        </Button>
      </Box>
      <Button
        variant="contained"
        type="submit"
        {...submitButtonProps}
        disabled={disableSubmitButton}
      >
        {submitButtonTitle}
      </Button>
    </Box>
  </Box>
);

export default HeaderButtons;
