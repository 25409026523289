import React from 'react';
import { Switch } from 'react-router-dom';
import ProtectedRoute from 'components/Navigation/ProtectedRoute/ProtectedRoute';
import MenuToolBar from 'components/Navigation/MenuToolBar/MenuToolBar';
import { useTranslation } from 'react-i18next';
import { storeMenuItems, StoreRoutes } from './storeHelper';
import AddressBlockContainer from './addressBlock/AddressBlockContainer/AddressBlockContainer';
import DeliveryAddressContainer from './deliveryAddress/DeliveryAddressContainer';
import SocialNetworksContainer from './socialNetworks/SocialNetworksContainer/SocialNetworksContainer';
import useCurrentEntity from 'app/current-entity-context/useCurrentEntity';
import InactiveEntityAlert from 'components/InactiveEntityAlert/InactiveEntityAlert';
import StorePage from './StorePage/StorePage';

const StoreModule = () => {
  const { currentEntity } = useCurrentEntity();

  const { t } = useTranslation();

  return currentEntity ? (
    <>
      <MenuToolBar menuItems={storeMenuItems} />

      <Switch>
        <ProtectedRoute exact path={StoreRoutes.Root}>
          <StorePage />
        </ProtectedRoute>
        <ProtectedRoute exact path={StoreRoutes.AddressBlocks}>
          <AddressBlockContainer />
        </ProtectedRoute>
        <ProtectedRoute exact path={StoreRoutes.DeliveryAddresses}>
          <DeliveryAddressContainer />
        </ProtectedRoute>
        <ProtectedRoute exact path={StoreRoutes.SocialNetworks}>
          <SocialNetworksContainer />
        </ProtectedRoute>
      </Switch>
    </>
  ) : (
    <div style={{ margin: 60 }}>
      <InactiveEntityAlert
        message={t('campaign_page.warning.selection_needed_message')}
      />
    </div>
  );
};

export default StoreModule;
