import React, { useState } from 'react';
import {
  Grid,
  Box,
  Typography,
  InputBase,
  IconButton,
} from '@material-ui/core';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import { useStudioStateContext } from 'modules/workflow/oneStudioContext';
import CommentBubble from 'modules/workflow/components/CommentBubble';
import colors from 'styles/colors.module.scss';
import { useAddReply } from 'modules/workflow/containers/CatalogPreview/addReply';
import CheckIcon from '@material-ui/icons/Check';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import { useTranslation } from 'react-i18next';
import SimpleActionsMenu from 'components/ActionsMenu/SimpleActionsMenu';
import { Action } from 'components/ActionsMenu/ActionsMenu';
import ClearIcon from '@material-ui/icons/Clear';
import { useCurrentUser } from 'app/auth/UserContext';
import {
  CommentsByVersion_commentsByVersion as Comment,
  CommentsByVersion_commentsByVersion_replies as Reply,
} from 'app/schemaInterfaces/CommentsByVersion';
import { css } from '@emotion/react';

export enum CommentsTypes {
  ADD_REPLY = 'ADD_REPLY',
  REPLY = 'REPLY',
  COMMENT = 'COMMENT',
}

const style = (type: CommentsTypes) => css`
  margin-top: ${type === CommentsTypes.COMMENT ? '5px' : 0};
  margin-bottom: ${type === CommentsTypes.COMMENT
    ? '5px'
    : type === CommentsTypes.ADD_REPLY
    ? '20px'
    : '15px'};
  cursor: pointer;
  &:hover {
    background-color: ${colors.workzone};
    border-radius: 4px;
  }
  .input {
    width: 87%;
    height: fit-content;
    border-bottom: 1px solid ${colors.lightGrey};
    padding: 5px;
    margin-top: 5px;
    font-size: 14px;
  }
  .Mui-focused {
    border-bottom: 1px solid ${colors.primary};
  }
  .reply-input {
    width: 100%;
    display: flex;
    justify-content: space-between;
    button {
      width: 13%;
    }
  }
  .comment {
    margin-left: ${type === CommentsTypes.REPLY ? '0' : '8px'};
    display: flex;
    flex-direction: column;
    .date {
      color: ${colors.grey};
    }
  }
`;

interface CommentCardsProps {
  type: CommentsTypes;
  item: Comment | Reply;
  postedDate?: string;
  commentIndex: number;
  pageIndex: number;
  deleteComment?: () => void;
  updateComment?: (text: string) => void;
}

const CommentCards = ({
  item,
  type,
  postedDate,
  commentIndex,
  pageIndex,
  deleteComment,
  updateComment,
}: CommentCardsProps): JSX.Element => {
  const { t } = useTranslation();
  const currentUser = useCurrentUser();

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [replyText, setReplyText] = useState<string>('');
  const [text, setText] = useState<string>('');

  const globalState = useStudioStateContext();

  const isCurrentPageValidated: boolean =
    globalState.version.pages[globalState.currentPage - 1]?.validated;

  const [addReply] = useAddReply();

  const addReplyHandler = () => {
    const pageId = globalState.version.pages[pageIndex].id;

    const variables = {
      versionId: globalState.version.id,
      pageId,
      commentId: item.id,
      userId: currentUser.id,
      text: replyText,
    };
    addReply({ variables });
    setReplyText('');
  };

  const enableEditHandler = () => {
    setText(item.text);
    setIsEdit(true);
  };

  const validateUpdate = () => {
    updateComment?.(text);
    setIsEdit(false);
    setText('');
  };

  const commentActions: Action[] =
    item.userId === currentUser.id
      ? [
          {
            title: t(`workflow.validate`),
            icon: <CheckIcon />,
          },
          {
            title: t(`workflow.edit`),
            icon: <CreateOutlinedIcon />,
            onClick: () => enableEditHandler(),
          },
          {
            title: t(`workflow.delete`),
            icon: <DeleteOutlineOutlinedIcon />,
            onClick: () => deleteComment?.(),
          },
        ]
      : [
          {
            title: t(`workflow.validate`),
            icon: <CheckIcon />,
          },
        ];

  const isTypeAddReply = type === CommentsTypes.ADD_REPLY;
  const isTypeComment = type === CommentsTypes.COMMENT;

  return (
    <Grid css={style(type)} container item spacing={1}>
      {(isTypeComment || !isTypeAddReply) && (
        <Grid container item direction="row" justify="space-between">
          <Grid container item xs={11} justify="flex-start" alignItems="center">
            <Grid item>
              {isTypeComment && (
                <CommentBubble
                  text={`${commentIndex + 1}`}
                  bubbleColor={colors.primary}
                  size={32}
                />
              )}
            </Grid>
            {!isTypeAddReply && (
              <Grid className="comment">
                <Typography variant="h6">
                  {item.user?.givenName} {item.user?.familyName}
                </Typography>
                <Typography variant="caption" align="center" className="date">
                  {postedDate}
                </Typography>
              </Grid>
            )}
          </Grid>
          {!isTypeAddReply && (
            <Grid item xs={1}>
              <SimpleActionsMenu
                actions={commentActions}
                button={<MoreVertOutlinedIcon fontSize="small" />}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              />
            </Grid>
          )}
        </Grid>
      )}
      <Grid container item direction="column" xs={12}>
        {!isTypeAddReply && (
          <Grid container item direction="row">
            {isEdit ? (
              <Box display="flex">
                <InputBase
                  className="input"
                  multiline
                  placeholder={t(`workflow.your_reply`)}
                  value={text}
                  inputProps={{
                    maxLength: 120,
                    border: 'none',
                    borderBottom: '1px solid blue',
                  }}
                  onChange={(event) => {
                    setText(event.target.value);
                  }}
                  disabled={isCurrentPageValidated}
                ></InputBase>
                <IconButton
                  disabled={!text}
                  onClick={validateUpdate}
                  size="small"
                >
                  <CheckIcon htmlColor={colors.success} />
                </IconButton>
                <IconButton onClick={() => setIsEdit(false)} size="small">
                  <ClearIcon htmlColor={colors.error} />
                </IconButton>
              </Box>
            ) : (
              <Typography variant="body1">{item?.text}</Typography>
            )}
          </Grid>
        )}
        {isTypeAddReply && (
          <Grid container item direction="row" className="reply-input">
            <InputBase
              className="input"
              multiline
              fullWidth
              placeholder={t(`workflow.your_reply`)}
              inputProps={{
                maxLength: 140,
              }}
              value={replyText}
              onChange={(event) => {
                setReplyText(event.target.value);
              }}
              disabled={isCurrentPageValidated}
            ></InputBase>
            <IconButton
              disabled={!replyText.length}
              onClick={addReplyHandler}
              size="small"
              className="reply-button"
            >
              <SendOutlinedIcon color="primary" />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default CommentCards;
