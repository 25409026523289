import { Box } from '@material-ui/core';
import { Immutable } from 'immer';
import React from 'react';
import { AssetInput } from 'app/schemaInterfaces/globalTypes';
import colors from 'styles/colors.module.scss';
import AssetFiltersPopover from 'modules/dam/filters/AssetFiltersPopover';
import { AssetSearch } from 'modules/dam/filters/AssetSearch';
import { AssetSort } from 'modules/dam/filters/AssetSort';
import { AssetTypeFilter } from 'modules/dam/filters/AssetTypeFilter';
import {
  AssetFilters,
  AssetSortOptions,
} from 'modules/dam/reducers/filters/definitions';
import AssetActionMenu from 'modules/dam/toolbar/AssetActionMenu';

interface DamToolbarProps {
  count?: number;
  filters: Immutable<AssetFilters>;
  sort: AssetSortOptions;
  onAssetCreate: (asset: AssetInput, file: File) => void;
  onAssetsImport?: (asset: AssetInput, files: File[]) => void; //Not implemented yet
  isSticky: boolean;
  isImportDamToCampaign?: boolean;
  isAddAssets: boolean;
}

const DamToolbar = ({
  count,
  filters,
  sort,
  onAssetCreate,
  onAssetsImport,
  isSticky,
  isAddAssets,
}: DamToolbarProps): JSX.Element => {
  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        py={3}
        px={8}
        bgcolor={colors.white}
      >
        <Box>
          <AssetTypeFilter
            count={count}
            selectedAssetType={filters.assetTypes}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <AssetFiltersPopover filters={filters} />
          <Box pl={2}>
            <AssetSort selectedSort={sort} />
          </Box>
          {isSticky && (
            <Box pl={2}>
              <AssetSearch text={filters.text} />
            </Box>
          )}
          {isAddAssets && (
            <Box pl={2}>
              <AssetActionMenu
                onAssetCreate={onAssetCreate}
                onAssetsImport={onAssetsImport}
              />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default DamToolbar;
