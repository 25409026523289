import {
  ActionsUnion,
  createAction,
  createActionPayload,
} from 'modules/workflow/reducers/actionsType';
import { StudioContextActionType } from 'modules/workflow/reducers/studioActions';
import {
  ZoneProps,
  DraggedItem,
  Position,
  Version,
  DraggedAsset,
  DraggedBackgroundAsset,
  DraggedProduct,
  PersonalizationItem,
  CurrentTab,
} from 'modules/workflow/oneStudioContext';
import { ItemSize } from 'modules/workflow/containers/CatalogPreview/catalogPreviewHelper';
import { IProductTemplate } from 'modules/workflow/Clients/ToutFaire/ProductItem';
import {
  DraggedItemTypes,
  Menu,
  NormalizedPersonalizationItem,
} from 'modules/workflow/containers/CatalogPreview/EditPlatformDefinitions';
import { GetDocument } from 'app/schemaInterfaces/GetDocument';

export const BLACK = 'black';
export const WHITE = 'white';

interface SetCommentMode {
  commentMode: boolean;
}

interface ToggleTextColor {
  productId: string;
  pageNumber: number;
  zoneIndex: number;
  typographyItemId: string;
}

export interface LocalizableItem<T> {
  item: T;
  pageNumber: number;
}

interface SetPageTemplate {
  pageNumber: number;
  pageTemplate: ZoneProps[];
}

interface SetPageCustomization {
  indexPage: number;
  isCustomizable: boolean;
}

interface setSectorToZone {
  pageNumber: number;
  zoneIndex: number;
  sector: string;
}
interface SetPagesComparisonStatus {
  isTwoPagesView: boolean;
}
interface SetBackgroundImage {
  pageNumber: number;
  zoneIndex: number;
  background: DraggedBackgroundAsset;
}
interface SetBackgroundColor {
  pageNumber: number;
  backgroundColor: DraggedBackgroundAsset;
}

interface SetDraggedItem<ItemType, Type> {
  draggedItem: DraggedItem<ItemType, Type>;
}

interface SetPersonalizationItem {
  pageNumber: number;
  zoneIndex: number;
  personalizationItems: PersonalizationItem[];
}

interface SetProduct {
  pageNumber: number;
  zoneIndex: number;
  product: Omit<IProductTemplate, 'components'>;
  scaleValue?: number;
}

interface TogglePanningFunctionality {
  panningEnabled: boolean;
}

interface AssetWithPosition extends DraggedAsset {
  position: Position;
}

interface InsertAsset {
  pageNumber: number;
  zoneIndex: number;
  newAsset: AssetWithPosition;
}
interface UpdateAsset extends InsertAsset {
  assetIndex: number;
}

interface SetUploadedImageUrl {
  uploadedBackgroundImageURL: string;
  uploadedBackgroundImageTitle: string;
}

export interface GetVersion {
  version: {
    id: string;
    campaignId: string;
    createdAt: string;
    updatedAt: Date;
    pages: Version['pages'];
  };
}

interface UpdateProductPosition {
  pageNumber: number;
  zoneIndex: number;
  position: Position;
  productId: string;
}

interface UpdateProductSize {
  pageNumber: number;
  zoneIndex: number;
  productId: string;
  delta: ItemSize;
}

interface SetZoneProductNumber {
  pageNumber: number;
  zoneIndex: number;
  numberOfProducts: number;
}

interface SetZoneTurnover {
  pageNumber: number;
  zoneIndex: number;
  turnover: number;
}

interface SetSelectedZone {
  selectedZone?: string;
}

export interface ProductToDelete {
  pageNumber: number;
  type: Menu.Product;
  id: string;
}

export interface ReplaceItem<T, U> {
  itemToDelete: U;
  itemToAdd: T;
  scaleValue?: number;
}

export interface UpdateAssetInCampaign {
  pageNumber: number;
  zoneIndex: number;
  productId: string;
  assetType: string;
}

export interface UpdateAssetPosition extends UpdateAssetInCampaign {
  position: Position;
  assetId?: string;
  visualId?: string;
  isRegrouping: boolean;
  fieldName?: string;
}

export interface UpdateAssetSize extends UpdateAssetInCampaign {
  size: ItemSize;
  isRegrouping: boolean;
  visualId?: string;
  regroupingSpacing?: number;
}

interface SetScaleValue {
  scaleValue: number;
}
interface SetTabValue {
  tabValue: CurrentTab;
}
export interface SetEditable {
  isEditable: boolean;
}

export interface SetDigital {
  isDigital: boolean;
}

export interface SetDigitalContent {
  message: string;
}

export const studioAppActions = {
  undo: createAction<typeof StudioContextActionType.UNDO>(
    StudioContextActionType.UNDO
  ),
  redo: createAction<typeof StudioContextActionType.REDO>(
    StudioContextActionType.REDO
  ),
  setCommentMode: createActionPayload<
    typeof StudioContextActionType.SET_COMMENT_MODE,
    SetCommentMode
  >(StudioContextActionType.SET_COMMENT_MODE),
  toggleTextColor: createActionPayload<
    typeof StudioContextActionType.TOGGLE_TEXT_COLOR,
    ToggleTextColor
  >(StudioContextActionType.TOGGLE_TEXT_COLOR),
  deleteAsset: createActionPayload<
    typeof StudioContextActionType.DELETE_ASSET,
    LocalizableItem<DraggedAsset>
  >(StudioContextActionType.DELETE_ASSET),

  deleteBackgroundAsset: createActionPayload<
    typeof StudioContextActionType.DELETE_BACKGROUND_ASSET,
    LocalizableItem<DraggedBackgroundAsset>
  >(StudioContextActionType.DELETE_BACKGROUND_ASSET),
  deletePersonalizationZone: createActionPayload<
    typeof StudioContextActionType.DELETE_PERSONALIZATION_ZONE,
    LocalizableItem<NormalizedPersonalizationItem>
  >(StudioContextActionType.DELETE_PERSONALIZATION_ZONE),
  deleteProductById: createActionPayload<
    typeof StudioContextActionType.DELETE_PRODUCT_BY_ID,
    LocalizableItem<IProductTemplate>
  >(StudioContextActionType.DELETE_PRODUCT_BY_ID),
  replaceProduct: createActionPayload<
    typeof StudioContextActionType.REPLACE_PRODUCT,
    ReplaceItem<
      Omit<IProductTemplate, 'components' | 'position'>,
      ProductToDelete
    >
  >(StudioContextActionType.REPLACE_PRODUCT),
  setPageTemplate: createActionPayload<
    typeof StudioContextActionType.SET_PAGE_TEMPLATE,
    SetPageTemplate
  >(StudioContextActionType.SET_PAGE_TEMPLATE),
  setPageNumber: createActionPayload<
    typeof StudioContextActionType.SET_PAGE_NUMBER,
    {
      pageNumber: number;
    }
  >(StudioContextActionType.SET_PAGE_NUMBER),
  setSectorToZone: createActionPayload<
    typeof StudioContextActionType.SET_SECTOR_TO_ZONE,
    setSectorToZone
  >(StudioContextActionType.SET_SECTOR_TO_ZONE),
  updateUndoRedoHistory: createAction<
    typeof StudioContextActionType.UPDATE_UNDO_REDO_HISTORY
  >(StudioContextActionType.UPDATE_UNDO_REDO_HISTORY),
  setPagesComparisonStatus: createActionPayload<
    typeof StudioContextActionType.SET_PAGES_COMPARISON_STATUS,
    SetPagesComparisonStatus
  >(StudioContextActionType.SET_PAGES_COMPARISON_STATUS),
  setBackgroundImage: createActionPayload<
    typeof StudioContextActionType.SET_BACKGROUND_IMAGE,
    SetBackgroundImage
  >(StudioContextActionType.SET_BACKGROUND_IMAGE),
  setBackgroundColor: createActionPayload<
    typeof StudioContextActionType.SET_BACKGROUND_COLOR,
    SetBackgroundColor
  >(StudioContextActionType.SET_BACKGROUND_COLOR),
  setValidPage: createActionPayload<
    typeof StudioContextActionType.SET_VALID_PAGE,
    {
      pageNumber: number;
      isValidated: boolean;
    }
  >(StudioContextActionType.SET_VALID_PAGE),
  setDraggedPageTemplate: createActionPayload<
    typeof StudioContextActionType.SET_DRAGGED_ITEM,
    SetDraggedItem<ZoneProps[], DraggedItemTypes.PAGE_TEMPLATE>
  >(StudioContextActionType.SET_DRAGGED_ITEM),
  setDraggedAsset: createActionPayload<
    typeof StudioContextActionType.SET_DRAGGED_ITEM,
    SetDraggedItem<DraggedAsset, DraggedItemTypes.ASSET>
  >(StudioContextActionType.SET_DRAGGED_ITEM),
  setPersonalizationItemCategory: createActionPayload<
    typeof StudioContextActionType.SET_DRAGGED_ITEM,
    SetDraggedItem<string, DraggedItemTypes.SET_PERSONALIZATION_ITEM_CATEGORY>
  >(StudioContextActionType.SET_DRAGGED_ITEM),
  setDraggedProduct: createActionPayload<
    typeof StudioContextActionType.SET_DRAGGED_ITEM,
    SetDraggedItem<DraggedProduct, DraggedItemTypes.PRODUCT>
  >(StudioContextActionType.SET_DRAGGED_ITEM),
  setDraggedBackground: createActionPayload<
    typeof StudioContextActionType.SET_DRAGGED_ITEM,
    SetDraggedItem<
      DraggedBackgroundAsset,
      DraggedItemTypes.BACKGROUND_IMAGE | DraggedItemTypes.BACKGROUND_COLOR
    >
  >(StudioContextActionType.SET_DRAGGED_ITEM),
  setPersonalizationItem: createActionPayload<
    typeof StudioContextActionType.SET_PERSONALIZATION_ITEMS,
    SetPersonalizationItem
  >(StudioContextActionType.SET_PERSONALIZATION_ITEMS),
  setPageCustomization: createActionPayload<
    typeof StudioContextActionType.SET_PAGES_CUSTOMIZATION,
    SetPageCustomization
  >(StudioContextActionType.SET_PAGES_CUSTOMIZATION),
  setProduct: createActionPayload<
    typeof StudioContextActionType.SET_PRODUCT,
    SetProduct
  >(StudioContextActionType.SET_PRODUCT),
  togglePanningFunctionality: createActionPayload<
    typeof StudioContextActionType.TOGGLE_PANNING_FUNCTIONALITY,
    TogglePanningFunctionality
  >(StudioContextActionType.TOGGLE_PANNING_FUNCTIONALITY),
  insertAsset: createActionPayload<
    typeof StudioContextActionType.INSERT_ASSET,
    InsertAsset
  >(StudioContextActionType.INSERT_ASSET),
  updateAsset: createActionPayload<
    typeof StudioContextActionType.UPDATE_ASSET,
    UpdateAsset
  >(StudioContextActionType.UPDATE_ASSET),
  setUploadedImageUrl: createActionPayload<
    typeof StudioContextActionType.SET_UPLOADED_BACKGROUND_IMAGE_URL,
    SetUploadedImageUrl
  >(StudioContextActionType.SET_UPLOADED_BACKGROUND_IMAGE_URL),
  DeleteAllItems: createActionPayload<
    typeof StudioContextActionType.DELETE_ALL_ITEMS,
    {
      pageNumber: number;
    }
  >(StudioContextActionType.DELETE_ALL_ITEMS),

  SetDocument: createActionPayload<
    typeof StudioContextActionType.SET_DOCUMENT,
    GetDocument
  >(StudioContextActionType.SET_DOCUMENT),

  SetVersion: createActionPayload<
    typeof StudioContextActionType.SET_VERSION,
    GetVersion
  >(StudioContextActionType.SET_VERSION),

  UpdateProductPosition: createActionPayload<
    typeof StudioContextActionType.UPDATE_PRODUCT_POSITION,
    UpdateProductPosition
  >(StudioContextActionType.UPDATE_PRODUCT_POSITION),
  updateProductSize: createActionPayload<
    typeof StudioContextActionType.UPDATE_PRODUCT_SIZE,
    UpdateProductSize
  >(StudioContextActionType.UPDATE_PRODUCT_SIZE),
  setZoneProductNumber: createActionPayload<
    typeof StudioContextActionType.SET_ZONE_PRODUCT_NUMBER,
    SetZoneProductNumber
  >(StudioContextActionType.SET_ZONE_PRODUCT_NUMBER),
  setZoneTurnover: createActionPayload<
    typeof StudioContextActionType.SET_ZONE_TURNOVER,
    SetZoneTurnover
  >(StudioContextActionType.SET_ZONE_TURNOVER),
  setSelectedZone: createActionPayload<
    typeof StudioContextActionType.SET_SELECTED_ZONE,
    SetSelectedZone
  >(StudioContextActionType.SET_SELECTED_ZONE),
  updateAssetPosition: createActionPayload<
    typeof StudioContextActionType.UPDATE_ASSET_POSITION,
    UpdateAssetPosition
  >(StudioContextActionType.UPDATE_ASSET_POSITION),
  updatePersonalizationProductAssetPosition: createActionPayload<
    typeof StudioContextActionType.UPDATE_PERSONALIZATION_PRODUCT_ASSET_POSITION,
    UpdateAssetPosition
  >(StudioContextActionType.UPDATE_PERSONALIZATION_PRODUCT_ASSET_POSITION),
  updateAssetSize: createActionPayload<
    typeof StudioContextActionType.UPDATE_ASSET_SIZE,
    UpdateAssetSize
  >(StudioContextActionType.UPDATE_ASSET_SIZE),
  setScaleValue: createActionPayload<
    typeof StudioContextActionType.SET_SCALE_VALUE,
    SetScaleValue
  >(StudioContextActionType.SET_SCALE_VALUE),
  setTabValue: createActionPayload<
    typeof StudioContextActionType.SET_TAB_VALUE,
    SetTabValue
  >(StudioContextActionType.SET_TAB_VALUE),
  toggleDisplayPersonalization: createAction<
    typeof StudioContextActionType.TOGGLE_DISPLAY_PERSONALIZATION
  >(StudioContextActionType.TOGGLE_DISPLAY_PERSONALIZATION),
  updatePersonalizationProductAssetSize: createActionPayload<
    typeof StudioContextActionType.UPDATE_PERSONALIZATION_PRODUCT_ASSET_SIZE,
    UpdateAssetSize
  >(StudioContextActionType.UPDATE_PERSONALIZATION_PRODUCT_ASSET_SIZE),
  SetEditable: createActionPayload<
    typeof StudioContextActionType.SET_EDITABLE,
    SetEditable
  >(StudioContextActionType.SET_EDITABLE),
  SetDigital: createActionPayload<
    typeof StudioContextActionType.SET_DIGITAL,
    SetDigital
  >(StudioContextActionType.SET_DIGITAL),
  SetDigitalContent: createActionPayload<
    typeof StudioContextActionType.SET_DIGITAL_CONTENT,
    SetDigitalContent
  >(StudioContextActionType.SET_DIGITAL_CONTENT),
};

export type AcceptedActions = ActionsUnion<typeof studioAppActions>;
