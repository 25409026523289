import { gql } from '@apollo/client';

export const GET_SUPPLY_PRODUCTS_ATTRIBUTES = gql`
  query GetSupplyProductsAttributes($filters: SupplyProductsFilterInput!) {
    supplyProductAttributes(filters: $filters) {
      certification
      chant
      classification
      codeAt
      orientation
      conditionnement {
        numberOfLots
        total
        conditionnementList {
          numberOfExLots
          firstConditionnement {
            conditionnement
            nbExConditionnement
            labeling
          }
          secondConditionnement {
            conditionnement
            nbExConditionnement
            labeling
          }
          threeConditionnement {
            conditionnement
            nbExConditionnement
            labeling
          }
        }
      }
      couleur
      decoupe
      descriptionIndication
      diametreCm
      enveloppeFenetre
      epaisseurMicrons
      faconnage
      finitionRecto
      finitionVerso
      fourniture
      grammageCouvertureGr
      grammageGr
      grammageInterieurGr
      id
      impressionLogo
      impressionRectoVerso
      format
      largeurCouvertureFormatFiniCm
      largeurCouvertureFormatOuvertCm
      largeurCouvertureFormatPageCm
      largeurFormatFiniCm
      largeurFormatOuvertCm
      largeurFormatPageCm
      largeurInterieurEncartFormatFiniCm
      largeurInterieurEncartFormatOuvertCm
      largeurInterieurEncartFormatPageCm
      largeurInterieurFormatFiniCm
      largeurInterieurFormatOuvertCm
      largeurInterieurFormatPageCm
      largeurTailleFenetreCm
      longueurCouvertureFormatFiniCm
      longueurCouvertureFormatOuvertCm
      longueurCouvertureFormatPageCm
      longueurFormatFiniCm
      longueurFormatOuvertCm
      longueurFormatPageCm
      longueurInterieurEncartFormatFiniCm
      longueurInterieurEncartFormatOuvertCm
      longueurInterieurEncartFormatPageCm
      longueurInterieurFormatFiniCm
      longueurInterieurFormatOuvertCm
      longueurInterieurFormatPageCm
      longueurTailleFenetreCm
      matiere
      matiereCouverture
      matiereInterieur
      nbCouleurCouvertureRecto
      nbCouleurCouvertureVerso
      nbCouleurInterieurEncartRecto
      nbCouleurInterieurEncartVerso
      nbCouleurInterieurRecto
      nbCouleurInterieurVerso
      nbCouleurRecto
      nbCouleurVerso
      optionsFaconnage
      ouvertureEnveloppe
      paginationCouverture
      paginationInterieur
      paginationInterieurEncart
      paginationTotale
      precisionsFaconnage
      precisionsFourniture
      profondeurFormatFiniCm
      repiquage
      repiquageCoeur
      repiquageDOS
      surfacage
      surfacageCouvertureRecto
      surfacageCouvertureVerso
      surfacageInterieurRecto
      surfacageInterieurVerso
      taille
      tauxSurfacage
      typeImpression
      typeMatiere
      typeMatiereCouverture
      typeMatiereInterieur
      typePapier
      typeSupport
      ciblage
      modeleAchat
      objectifMarketing
      paysDeDiffusion
      typeEnvoi
      zoneTerritorial
      largeurPixel
      longueurPixel
      fondsPerdus
      faconnageDeLivraison
    }
  }
`;

export const GET_SUPPLY_PRODUCTS = gql`
  query GetSupplyProducts($filters: SupplyProductsFilterInput!) {
    supplyProducts(filters: $filters) {
      id
      certification
      chant
      classification
      codeAt
      conditionnement {
        numberOfLots
        total
        conditionnementList {
          numberOfExLots
          firstConditionnement {
            conditionnement
            nbExConditionnement
            labeling
          }
          secondConditionnement {
            conditionnement
            nbExConditionnement
            labeling
          }
          threeConditionnement {
            conditionnement
            nbExConditionnement
            labeling
          }
        }
      }
      couleur
      decoupe
      descriptionIndication
      diametreCm
      enveloppeFenetre
      epaisseurMicrons
      faconnage
      finitionRecto
      finitionVerso
      fourniture
      orientation
      format
      grammageCouvertureGr
      grammageGr
      grammageInterieurGr
      impressionLogo
      impressionRectoVerso
      largeurCouvertureFormatFiniCm
      largeurCouvertureFormatOuvertCm
      largeurCouvertureFormatPageCm
      largeurFormatFiniCm
      largeurFormatOuvertCm
      largeurFormatPageCm
      largeurInterieurEncartFormatFiniCm
      largeurInterieurEncartFormatOuvertCm
      largeurInterieurEncartFormatPageCm
      largeurInterieurFormatFiniCm
      largeurInterieurFormatOuvertCm
      largeurInterieurFormatPageCm
      largeurTailleFenetreCm
      longueurCouvertureFormatFiniCm
      longueurCouvertureFormatOuvertCm
      longueurCouvertureFormatPageCm
      longueurFormatFiniCm
      longueurFormatOuvertCm
      longueurFormatPageCm
      longueurInterieurEncartFormatFiniCm
      longueurInterieurEncartFormatOuvertCm
      longueurInterieurEncartFormatPageCm
      longueurInterieurFormatFiniCm
      longueurInterieurFormatOuvertCm
      longueurInterieurFormatPageCm
      longueurTailleFenetreCm
      matiere
      matiereCouverture
      matiereInterieur
      nbCouleurCouvertureRecto
      nbCouleurCouvertureVerso
      nbCouleurInterieurEncartRecto
      nbCouleurInterieurEncartVerso
      nbCouleurInterieurRecto
      nbCouleurInterieurVerso
      nbCouleurRecto
      nbCouleurVerso
      optionsFaconnage
      ouvertureEnveloppe
      paginationCouverture
      paginationInterieur
      paginationInterieurEncart
      paginationTotale
      precisionsFaconnage
      precisionsFourniture
      profondeurFormatFiniCm
      repiquage
      repiquageCoeur
      repiquageDOS
      surfacage
      surfacageCouvertureRecto
      surfacageCouvertureVerso
      surfacageInterieurRecto
      surfacageInterieurVerso
      taille
      tauxSurfacage
      typeImpression
      typeMatiere
      typeMatiereCouverture
      typeMatiereInterieur
      typePapier
      typeSupport
      ciblage
      modeleAchat
      objectifMarketing
      paysDeDiffusion
      typeEnvoi
      zoneTerritorial
      longueurPixel
      largeurPixel
      fondsPerdus
      faconnageDeLivraison
    }
  }
`;

export const GET_SUPPLY_PRODUCT_BY_ID = gql`
  query GetSupplyProductById($productId: String!) {
    supplyProductById(productId: $productId) {
      id
      certification
      chant
      classification
      codeAt
      conditionnement {
        numberOfLots
        total
        conditionnementList {
          numberOfExLots
          firstConditionnement {
            conditionnement
            nbExConditionnement
            labeling
          }
          secondConditionnement {
            conditionnement
            nbExConditionnement
            labeling
          }
          threeConditionnement {
            conditionnement
            nbExConditionnement
            labeling
          }
        }
      }
      couleur
      decoupe
      descriptionIndication
      diametreCm
      enveloppeFenetre
      epaisseurMicrons
      faconnage
      finitionRecto
      finitionVerso
      fourniture
      orientation
      format
      grammageCouvertureGr
      grammageGr
      grammageInterieurGr
      impressionLogo
      impressionRectoVerso
      largeurCouvertureFormatFiniCm
      largeurCouvertureFormatOuvertCm
      largeurCouvertureFormatPageCm
      largeurFormatFiniCm
      largeurFormatOuvertCm
      largeurFormatPageCm
      largeurInterieurEncartFormatFiniCm
      largeurInterieurEncartFormatOuvertCm
      largeurInterieurEncartFormatPageCm
      largeurInterieurFormatFiniCm
      largeurInterieurFormatOuvertCm
      largeurInterieurFormatPageCm
      largeurTailleFenetreCm
      longueurCouvertureFormatFiniCm
      longueurCouvertureFormatOuvertCm
      longueurCouvertureFormatPageCm
      longueurFormatFiniCm
      longueurFormatOuvertCm
      longueurFormatPageCm
      longueurInterieurEncartFormatFiniCm
      longueurInterieurEncartFormatOuvertCm
      longueurInterieurEncartFormatPageCm
      longueurInterieurFormatFiniCm
      longueurInterieurFormatOuvertCm
      longueurInterieurFormatPageCm
      longueurTailleFenetreCm
      matiere
      matiereCouverture
      matiereInterieur
      nbCouleurCouvertureRecto
      nbCouleurCouvertureVerso
      nbCouleurInterieurEncartRecto
      nbCouleurInterieurEncartVerso
      nbCouleurInterieurRecto
      nbCouleurInterieurVerso
      nbCouleurRecto
      nbCouleurVerso
      optionsFaconnage
      ouvertureEnveloppe
      paginationCouverture
      paginationInterieur
      paginationInterieurEncart
      paginationTotale
      precisionsFaconnage
      precisionsFourniture
      profondeurFormatFiniCm
      repiquage
      repiquageCoeur
      repiquageDOS
      surfacage
      surfacageCouvertureRecto
      surfacageCouvertureVerso
      surfacageInterieurRecto
      surfacageInterieurVerso
      taille
      tauxSurfacage
      typeImpression
      typeMatiere
      typeMatiereCouverture
      typeMatiereInterieur
      typePapier
      typeSupport
      ciblage
      modeleAchat
      objectifMarketing
      paysDeDiffusion
      typeEnvoi
      zoneTerritorial
      longueurPixel
      largeurPixel
      fondsPerdus
      faconnageDeLivraison
    }
  }
`;
