import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const refusedHeadStyle = css`
  font-weight: bold;
  color: initial;
`;

export const refusedSubHeadStyle = css`
  color: ${colors.disabled};
`;
