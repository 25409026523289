import { TFunction } from 'i18next';
import moment from 'moment';
import { NumberSize } from 're-resizable';
import {
  CurrentTab,
  PersonalizationItem,
  PersonalizationSubType,
  TabMenu,
  TypographyItem,
} from 'modules/workflow/oneStudioContext';
import {
  AcceptedActions,
  studioAppActions,
} from 'modules/workflow/reducers/actionsInterfaces';
import { StudioContextActionType } from 'modules/workflow/reducers/studioActions';
import { ItemSize } from 'modules/workflow/containers/CatalogPreview/catalogPreviewHelper';
import { AssetType } from 'app/schemaInterfaces/globalTypes';
import { DraggableData } from 'react-rnd';

export const setNewTab = (
  subType: PersonalizationSubType,
  tabValue: CurrentTab
): CurrentTab => {
  switch (subType) {
    case PersonalizationSubType.ADDRESS_BLOCK:
      return { value: TabMenu.ADDRESS_BLOCK };
    case PersonalizationSubType.PRODUCT:
      return { value: TabMenu.PRODUCT };
    case PersonalizationSubType.VISUAL:
      return { value: TabMenu.ASSET, subType: AssetType.Visual };
    case PersonalizationSubType.PICTO:
      return { value: TabMenu.ASSET, subType: AssetType.Picto };
    case PersonalizationSubType.LOGO:
      return { value: TabMenu.ASSET, subType: AssetType.Logo };
    case PersonalizationSubType.CHARTER:
      return { value: TabMenu.ASSET, subType: AssetType.Charter };
    default:
      return tabValue;
  }
};

export const updatePersoItemPositionHandler = (
  dispatch: (action: AcceptedActions) => void,
  personalizationItems: PersonalizationItem[],
  position: DraggableData,
  pageNumber: number,
  zoneIndex: number,
  persoKey: string,
  setPosition: React.Dispatch<
    React.SetStateAction<{
      x: number;
      y: number;
    }>
  >
) => {
  const newPersonalizationItems = personalizationItems.map(
    (personalizationItem) => {
      if (personalizationItem.key === persoKey) {
        return {
          ...personalizationItem,
          position: { x: position.x, y: position.y },
        };
      } else {
        return personalizationItem;
      }
    }
  );

  dispatch(
    studioAppActions.setPersonalizationItem({
      pageNumber: pageNumber - 1,
      zoneIndex: zoneIndex,
      personalizationItems: newPersonalizationItems,
    })
  );

  setPosition({ x: position.x, y: position.y });
};

export const updatePersoItemDimensionsHandler = (
  delta: NumberSize,
  pageNumber: number,
  zoneIndex: number,
  contentKey: string,
  dispatch: (action: AcceptedActions) => void,
  personalizationItems: PersonalizationItem[],
  subZoneDimensions: { width: number; height: number },
  setDimensions: React.Dispatch<
    React.SetStateAction<{
      width: number;
      height: number;
    }>
  >
) => {
  // set the global state with X and Y from position
  let dimensions: ItemSize = { width: 0, height: 0 };
  dimensions.width = subZoneDimensions.width + delta.width;
  dimensions.height = subZoneDimensions.height + delta.height;

  const newPersonalizationItems = personalizationItems.map(
    (personalizationItem) => {
      if (
        personalizationItem.key === contentKey &&
        personalizationItem.content
      ) {
        return {
          ...personalizationItem,
          dimensions,
          content: {
            ...personalizationItem.content,
            size: {
              height: dimensions.height,
              width: dimensions.width,
            },
          },
        };
      } else if (personalizationItem.key === contentKey) {
        return {
          ...personalizationItem,
          dimensions,
        };
      } else {
        return personalizationItem;
      }
    }
  );

  dispatch({
    type: StudioContextActionType.SET_PERSONALIZATION_ITEMS,
    payload: {
      pageNumber: pageNumber - 1,
      zoneIndex: zoneIndex,
      personalizationItems: newPersonalizationItems,
    },
  });

  setDimensions(dimensions);
};

const setDateTitle = (text: TypographyItem, t: TFunction) => {
  const format = moment(text.dateFrom).isSame(moment(text.dateTo), 'year')
    ? 'DD MMMM'
    : 'DD MMMM YYYY';

  return t('local.common.from_to_date', {
    dateFrom: moment(text.dateFrom).format(format),
    dateTo: moment(text.dateTo).format('DD MMMM YYYY'),
  });
};

export const updateTypographyHandler = (
  pageNumber: number,
  zoneIndex: number,
  typographyItem: PersonalizationItem,
  dispatch: (action: AcceptedActions) => void,
  personalizationItems: PersonalizationItem[],
  t: TFunction
) => {
  if (
    typographyItem.subType === PersonalizationSubType.DATE &&
    typographyItem.text?.dateFrom &&
    typographyItem.text?.dateTo
  ) {
    typographyItem.text.title = setDateTitle(typographyItem.text, t);
  }
  const newPersonalizationItems = personalizationItems?.map(
    (personalizationItem) =>
      typographyItem.key === personalizationItem.key
        ? typographyItem
        : personalizationItem
  );

  dispatch({
    type: StudioContextActionType.SET_PERSONALIZATION_ITEMS,
    payload: {
      pageNumber: pageNumber,
      zoneIndex: zoneIndex,
      personalizationItems: newPersonalizationItems as PersonalizationItem[],
    },
  });
};
