import { useQuery } from '@apollo/client';
import { Immutable } from 'immer';
import { useCallback, useMemo } from 'react';
import { GET_ASSETS } from 'app/graphql/queries/dam';
import { GetAssets, GetAssetsVariables } from 'app/schemaInterfaces/GetAssets';
import {
  AssetFilters,
  AssetSortOptions,
} from 'modules/dam/reducers/filters/definitions';

const LIMIT = 8;

export interface QueryParams {
  filters: Immutable<AssetFilters>;
  sort?: Immutable<AssetSortOptions>;
}

const useAssetQuery = (
  { sort, filters }: QueryParams,
  limit = LIMIT,
  skip = false
) => {
  const queryFilterVariables: GetAssetsVariables = useMemo(
    () => ({
      filters: {
        ...(filters as AssetFilters),
        tags: filters.tags?.map(({ id }) => id),
        entityId: filters.entityId,
      },
    }),
    [filters]
  );

  const { error, data, fetchMore, networkStatus, loading, refetch } = useQuery<
    GetAssets,
    GetAssetsVariables
  >(GET_ASSETS, {
    variables: {
      ...queryFilterVariables,
      sort,
      pagination: { offset: 0, limit },
    },
    notifyOnNetworkStatusChange: true,
    skip,
  });

  const assets = data?.getAssets?.assets;
  const count = data?.getAssets?.count;

  const loadMore = useCallback(() => {
    if (!loading) {
      fetchMore({
        variables: {
          pagination: {
            offset: assets?.length || 0,
            limit,
          },
        },
        updateQuery: (prevData: GetAssets, { fetchMoreResult }): GetAssets => {
          if (!fetchMoreResult || fetchMoreResult.getAssets.assets.length === 0)
            return prevData;
          return {
            getAssets: {
              ...prevData.getAssets,
              assets: [
                ...prevData.getAssets.assets,
                ...fetchMoreResult.getAssets.assets,
              ],
            },
          };
        },
      });
    }
  }, [assets, limit, fetchMore, loading]);

  const refetchWithFilters = useCallback(() => {
    refetch({
      ...queryFilterVariables,
      sort,
    });
  }, [refetch, queryFilterVariables, sort]);

  return {
    assets,
    count,
    networkStatus,
    loadMore,
    refetch: refetchWithFilters,
    error,
    loading,
  };
};

export default useAssetQuery;
