import { Button } from '@material-ui/core';
import { FC, MouseEvent } from 'react';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import {
  validationButtonAcceptStyle,
  validationButtonDeclineStyle,
  validationButtonStyle,
} from './FollowOrderValidationButton.style';

export interface FollowOrderValidationButtonProps {
  type: 'accept' | 'decline';
  onClick: (e: MouseEvent) => void;
}

const FollowOrderValidationButton: FC<FollowOrderValidationButtonProps> = ({
  type,
  children,
  onClick,
}) => (
  <Button
    css={[
      validationButtonStyle,
      type === 'accept' && validationButtonAcceptStyle,
      type === 'decline' && validationButtonDeclineStyle,
    ]}
    variant="outlined"
    endIcon={type === 'accept' ? <DoneIcon /> : <CloseIcon />}
    onClick={onClick}
  >
    {children}
  </Button>
);

export default FollowOrderValidationButton;
