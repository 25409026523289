import React, { useMemo } from 'react';
import { Box, Grid } from '@material-ui/core';
import {
  useStudioStateContext,
  useStudioDispatchContext,
  Page,
} from 'modules/workflow/oneStudioContext';
import PagesRow from 'modules/workflow/containers/Leftsidebar/PagesPanel/PagesRow';
import PagesPanelHeader from 'modules/workflow/containers/Leftsidebar/PagesPanel/PagesPanelHeader';
import { studioAppActions } from 'modules/workflow/reducers/actionsInterfaces';
import colors from 'styles/colors.module.scss';
import LinearProgressBar from 'components/LinearProgressBar/LinearProgressBar';
import { useTranslation } from 'react-i18next';
import { PagesPanelFilters } from 'modules/workflow/containers/Leftsidebar/PagesPanel/filterDefinitions';
import { useQuery } from '@apollo/client';
import {
  GetVersionQuery,
  GetVersionQueryVariables,
} from 'app/schemaInterfaces/GetVersionQuery';
import { GET_VERSION_QUERY } from 'app/graphql/queries/version';

export interface PageWithIndex extends Page {
  pageIndex: number;
}

export interface FiltrationData {
  pagesNotValidated: number;
  hasIncompleteProducts: number;
}

const PagesPanel = (): JSX.Element => {
  const globalState = useStudioStateContext();
  const dispatch = useStudioDispatchContext();
  const { t } = useTranslation();

  const hasThumbnail = useMemo(
    () => globalState.version.pages.some((page) => page.thumbnailUrl),
    [globalState.version.pages]
  );

  const { data: parentVersionData } = useQuery<
    GetVersionQuery,
    GetVersionQueryVariables
  >(GET_VERSION_QUERY, {
    variables: { documentId: globalState.document?.parentId ?? '' },
    skip: hasThumbnail,
  });

  const arrayOfPages: PageWithIndex[] = globalState.version.pages.map(
    (page, index) => {
      const { pageTemplate, ...restOfPage } = page;
      return {
        ...restOfPage,
        pageIndex: index + 1,
        ...(!hasThumbnail &&
        parentVersionData?.getVersion.pages?.[index].thumbnailUrl
          ? {
              ...restOfPage,
              thumbnailUrl: parentVersionData?.getVersion.pages?.[index]
                .thumbnailUrl as string,
            }
          : {}),
      };
    }
  );
  const [filtrationData, setfiltrationData] = React.useState<FiltrationData>({
    pagesNotValidated: 5,
    hasIncompleteProducts: 5,
  });

  // Set filter for pages
  const filterPages = (pages: string[]): void => {
    if (!!pages.length) {
      let optionsFilter = {} as FiltrationData;

      if (pages.includes(PagesPanelFilters.Validate)) {
        const index = pages.findIndex(
          (item) => item === PagesPanelFilters.Validate
        );
        optionsFilter = {
          ...filtrationData,
          pagesNotValidated: +pages[index],
        };
      }

      if (pages.includes(PagesPanelFilters.IncompleteProducts)) {
        const index = pages.findIndex(
          (item) => item === PagesPanelFilters.IncompleteProducts
        );
        optionsFilter = {
          ...filtrationData,
          pagesNotValidated: +pages[index],
        };
      }

      setfiltrationData({
        ...filtrationData,
        ...optionsFilter,
      });
    } else {
      setfiltrationData({
        pagesNotValidated: 5,
        hasIncompleteProducts: 5,
      });
    }
  };

  const applyIncompleteProductFilterOnPage = (
    page: Page,
    hasIncompleteProducts: number
  ) => {
    if (hasIncompleteProducts === 1) {
      // get pages that only has > 0 Invalid products
      return page.incompleteProducts > 0;
    } else if (hasIncompleteProducts === 0) {
      // get pages that all its products are valid
      return page.incompleteProducts === 0;
    } else {
      // the default filter value, get all.
      return true;
    }
  };

  const applyNonValidatedPagesFilterOnPage = (
    page: Page,
    pagesNotValidated: number
  ) => {
    if (pagesNotValidated === 1) {
      // get only non validated pages
      return page.validated;
    } else if (pagesNotValidated === 0) {
      // get validated pages only
      return !page.validated;
    } else {
      // the default filter value, get all.
      return true;
    }
  };

  const pageMatchesFiltrationCriteria = (
    page: Page,
    filtrationData: FiltrationData
  ) => {
    // each filtration cycle should tell us which product will

    let pageMatchesIncompleteProductsFilter = applyIncompleteProductFilterOnPage(
      page,
      filtrationData.hasIncompleteProducts
    );

    let pageMatchesNonValidatedPagesFilter = applyNonValidatedPagesFilterOnPage(
      page,
      filtrationData.pagesNotValidated
    );

    return (
      pageMatchesIncompleteProductsFilter && pageMatchesNonValidatedPagesFilter
    );
  };

  let numberOfValidatedPages = 0;

  let filteredPages: Array<PageWithIndex>;
  filteredPages = arrayOfPages.filter((page) => {
    let pageIsValid = pageMatchesFiltrationCriteria(page, filtrationData);
    if (page.validated) {
      numberOfValidatedPages++;
    }

    return pageIsValid;
  });

  const selectPageArrowHandler = (pageIndex: number) => {
    pageIndex = globalState.currentPage + pageIndex;
    dispatch(studioAppActions.setCommentMode({ commentMode: false }));
    dispatch(studioAppActions.setPageNumber({ pageNumber: pageIndex }));
  };

  const selectPageHandler = (pageIndex: number) => {
    // do not let the page number go below ZERO
    pageIndex = pageIndex <= 0 ? 1 : pageIndex;
    // do not let the page number go beyong the max pages existing
    pageIndex =
      pageIndex > filteredPages.length ? filteredPages.length : pageIndex;

    dispatch(studioAppActions.setPageNumber({ pageNumber: pageIndex }));
  };

  const variablesToTranslate = {
    numberOfValidatedPages,
    total: globalState.version.pages.length,
  };

  return (
    <Box p="30px" height="100vh" overflow="scroll">
      <Grid container spacing={3} direction="column">
        <Box>
          <PagesPanelHeader
            totalPagesNumber={filteredPages.length}
            filterPages={filterPages}
            selectPageHandlerFn={selectPageArrowHandler}
          />
        </Box>
        <LinearProgressBar
          boxProps={{ mt: 3, mb: 5, color: colors.white }}
          totalAchievement={globalState.version.pages.length}
          progressAchievement={numberOfValidatedPages}
          title={
            numberOfValidatedPages > 1
              ? t(
                  `workflow.validated_pages_in_progress_plural`,
                  variablesToTranslate
                )
              : t(`workflow.validated_pages_in_progress`, variablesToTranslate)
          }
          typographyVariant="body1"
          progressBarColor={colors.success}
        />
        <PagesRow pagesData={filteredPages} selectPageFn={selectPageHandler} />
      </Grid>
    </Box>
  );
};

export default PagesPanel;
