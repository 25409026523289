import { GetCampaignById_getCampaignById } from 'app/schemaInterfaces/GetCampaignById';
import {
  GetDocument_document,
  GetDocument_document_quantities,
} from 'app/schemaInterfaces/GetDocument';
import { GetEntities_entities } from 'app/schemaInterfaces/GetEntities';
import { GetSupports_supports_supportAttributes } from 'app/schemaInterfaces/GetSupports';
import {
  AttributeKey,
  DocumentCreateInput,
  DocumentOrigin,
  SupplyAttributesFilterInput,
  BigShopConfigCategory,
  TemplateType,
} from 'app/schemaInterfaces/globalTypes';
import {
  DocumentFormValues,
  Quantities,
} from 'modules/pro/documents/form/model/definitions';
import { filteredAttributes } from 'modules/sales/components/QuantitySelectHelper';
import { TFunction } from 'react-i18next';
import { serializePackagingOsProduct } from '../DocumentProductSubForm/DocumentProductSubform.helper';

const getQuantities = (
  quantities: GetDocument_document_quantities
): Quantities => {
  const { __typename, ...rest } = quantities;
  if (rest.rangeQuantity) {
    const { minimum, maximum } = rest.rangeQuantity;
    return { ...rest, rangeQuantity: { minimum, maximum } };
  }
  return rest;
};

export const getInitialValues = (
  campaignId: string,
  entities: GetEntities_entities[],
  document: GetDocument_document | undefined,
  campaign: GetCampaignById_getCampaignById | undefined
): DocumentFormValues => {
  const campaignEntityIds = campaign?.entities.map(({ id }) => id);
  const filteredCampaignEntities = entities?.filter((entity) =>
    campaignEntityIds?.includes(entity.id)
  );
  const filteredDocumentEntites = entities?.filter((entity) =>
    document?.entityIds?.includes(entity.id)
  );
  let productAttributes = {};
  if (document?.productAttributes) {
    const {
      __typename,
      conditionnement,
      ...rest
    } = document?.productAttributes;
    productAttributes = {
      ...rest,
      ...(conditionnement
        ? { conditionnement: serializePackagingOsProduct(conditionnement) }
        : {}),
    };
  }

  return {
    document: {
      campaignName: campaign?.name ?? '',
      name: document?.name ?? '',
      version: document?.version ?? '',
      campaignId,
      origin: document?.origin ?? DocumentOrigin.Source,
      entities: document ? filteredDocumentEntites : filteredCampaignEntities,
      category: document?.category ?? null,
      mediumType: document?.mediumType ?? '',
      supportId: document?.supportId ?? '',
      parentId: document?.parentId ?? null,
      productId: document?.productId ?? null,
      orderTypes: document?.orderTypes ?? [],
      quantities: document?.quantities
        ? getQuantities(document.quantities)
        : null,
      commitmentDateFrom:
        document?.commitmentDateFrom ?? campaign?.commitmentDateFrom,
      commitmentDateTo:
        document?.commitmentDateTo ?? campaign?.commitmentDateTo,
      freeOrderDateFrom: document?.freeOrderDateFrom ?? null,
      freeOrderDateTo: document?.freeOrderDateTo ?? null,
      isCustomizable: document?.isCustomizable ?? false,
      isMandatory: document?.isMandatory ?? false,
      hasAddressBlock: document?.hasAddressBlock ?? false,
      template: {
        type: document?.template.type ?? TemplateType.BIGONE,
        metadata: {
          // TODO: add data coming from backend if the need values in future.
          bigone: {},
          chili: document?.template.metadata.chili
            ? { templateId: document?.template.metadata.chili.templateId }
            : { templateId: '' },
        },
      },
    },
    productAttributes: filteredAttributes(productAttributes, true) ?? {},
    file: null,
    thumbnailId: document?.thumbnailId ?? null,
  };
};

const getTotalPagination = (
  attributes: GetSupports_supports_supportAttributes[],
  productAttributes: SupplyAttributesFilterInput
) => {
  const totalPaginationAttribute = attributes.find(
    ({ key }) => key === AttributeKey.paginationTotale
  );
  if (!totalPaginationAttribute) {
    if (!productAttributes.nbCouleurVerso) {
      return 1;
    } else {
      if (
        productAttributes.impressionRectoVerso &&
        productAttributes.impressionRectoVerso ===
          productAttributes.nbCouleurVerso
      ) {
        return 1;
      } else {
        return 2;
      }
    }
  } else {
    return productAttributes.paginationTotale
      ? +productAttributes.paginationTotale
      : null;
  }
};

export const mapToDocumentInput = (
  attributes: GetSupports_supports_supportAttributes[],
  {
    document: { entities, campaignName, productId, ...document },
    productAttributes,
    thumbnailId,
  }: DocumentFormValues
): DocumentCreateInput => {
  const productData: SupplyAttributesFilterInput = Object.entries(
    productAttributes
  )
    .filter(([_, value]) => value !== null)
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
  return {
    ...document,
    thumbnailId,
    productId: productId as string, // ensured by validation,
    category: document.category as BigShopConfigCategory, // ensured by validation
    supportId: document.supportId as string, // ensured by validation
    entityIds: entities.map((entity) => entity.id),
    numberOfPages: getTotalPagination(attributes, productAttributes) ?? 0,
    // we are saving this fields in the backend temporary not to break WF who are using them
    ...(productAttributes?.largeurFormatFiniCm && {
      finishedFormatWidth: parseInt(productAttributes.largeurFormatFiniCm),
    }),
    ...(productAttributes?.longueurFormatFiniCm && {
      finishedFormatHeight: parseInt(productAttributes.longueurFormatFiniCm),
    }),
    // we are saving this fields in the backend temporary not to break BigShop who are using them
    ...(productAttributes?.faconnage && {
      shaping: productAttributes.faconnage,
    }),
    ...((productAttributes?.nbCouleurRecto ||
      productAttributes?.nbCouleurVerso) && {
      impression: `${productAttributes.nbCouleurRecto} ${productAttributes.nbCouleurVerso}`.trim(),
    }),
    ...(productAttributes?.typePapier && {
      paper: productAttributes.typePapier,
    }),
    productAttributes: productData,
    template: {
      ...document.template,
      metadata: {
        bigone:
          document.template.type === TemplateType.BIGONE
            ? document.template.metadata.bigone
            : undefined,
        chili:
          document.template.type === TemplateType.CHILI
            ? document.template.metadata.chili
            : undefined,
      },
    },
  };
};

export const getSupportCategory = (
  category: BigShopConfigCategory,
  t: TFunction
) => {
  switch (category) {
    case BigShopConfigCategory.AdvertisingPrints:
      return t('global.document_form.categories_names.advertising_prints');
    case BigShopConfigCategory.AdvertisingItems:
      return t('global.document_form.categories_names.advertising_items');
    case BigShopConfigCategory.Digital:
      return t('global.document_form.categories_names.digital');
    case BigShopConfigCategory.SignagePos:
      return t('global.document_form.categories_names.signage_pos');
    case BigShopConfigCategory.StationeryShop:
      return t('global.document_form.categories_names.stationery');
    case BigShopConfigCategory.PostersAndLeaflets:
      return t('global.document_form.categories_names.postersAndLeaflets');
    case BigShopConfigCategory.SignsAndSignage:
      return t('global.document_form.categories_names.signsAndSignage');
    case BigShopConfigCategory.OfferBrochures:
      return t('global.document_form.categories_names.offerBrochures');
    case BigShopConfigCategory.Billboards:
      return t('global.document_form.categories_names.billboards');
    case BigShopConfigCategory.TrafficSigns:
      return t('global.document_form.categories_names.trafficSigns');
    default:
      return '';
  }
};

export const getAttribute = (category: AttributeKey, t: TFunction) => {
  switch (category) {
    case AttributeKey.certification:
      return t('global.document_form.attributes_names.certification');
    case AttributeKey.chant:
      return t('global.document_form.attributes_names.chant');
    case AttributeKey.ciblage:
      return t('global.document_form.attributes_names.ciblage');
    case AttributeKey.classification:
      return t('global.document_form.attributes_names.classification');
    case AttributeKey.conditionnement:
      return t('global.document_form.attributes_names.conditionnement');
    case AttributeKey.couleur:
      return t('global.document_form.attributes_names.couleur');
    case AttributeKey.decoupe:
      return t('global.document_form.attributes_names.decoupe');
    case AttributeKey.descriptionIndication:
      return t('global.document_form.attributes_names.descriptionIndication');
    case AttributeKey.diametreCm:
      return t('global.document_form.attributes_names.diametreCm');
    case AttributeKey.enveloppeFenetre:
      return t('global.document_form.attributes_names.enveloppeFenetre');
    case AttributeKey.epaisseurMicrons:
      return t('global.document_form.attributes_names.epaisseurMicrons');
    case AttributeKey.faconnage:
      return t('global.document_form.attributes_names.faconnage');
    case AttributeKey.finitionRecto:
      return t('global.document_form.attributes_names.finitionRecto');
    case AttributeKey.finitionVerso:
      return t('global.document_form.attributes_names.finitionVerso');
    case AttributeKey.format:
      return t('global.document_form.attributes_names.format');
    case AttributeKey.fourniture:
      return t('global.document_form.attributes_names.fourniture');
    case AttributeKey.grammageCouvertureGr:
      return t('global.document_form.attributes_names.grammageCouvertureGr');
    case AttributeKey.grammageGr:
      return t('global.document_form.attributes_names.grammageGr');
    case AttributeKey.grammageInterieurGr:
      return t('global.document_form.attributes_names.grammageInterieurGr');
    case AttributeKey.impressionLogo:
      return t('global.document_form.attributes_names.impressionLogo');
    case AttributeKey.impressionRectoVerso:
      return t('global.document_form.attributes_names.impressionRectoVerso');
    case AttributeKey.largeurCouvertureFormatFiniCm:
      return t(
        'global.document_form.attributes_names.largeurCouvertureFormatFiniCm'
      );
    case AttributeKey.largeurCouvertureFormatOuvertCm:
      return t(
        'global.document_form.attributes_names.largeurCouvertureFormatOuvertCm'
      );
    case AttributeKey.largeurCouvertureFormatPageCm:
      return t(
        'global.document_form.attributes_names.largeurCouvertureFormatPageCm'
      );
    case AttributeKey.largeurFormatFiniCm:
      return t('global.document_form.attributes_names.largeurFormatFiniCm');
    case AttributeKey.largeurFormatOuvertCm:
      return t('global.document_form.attributes_names.largeurFormatOuvertCm');
    case AttributeKey.largeurFormatPageCm:
      return t('global.document_form.attributes_names.largeurFormatPageCm');
    case AttributeKey.largeurInterieurEncartFormatFiniCm:
      return t(
        'global.document_form.attributes_names.largeurInterieurEncartFormatFiniCm'
      );
    case AttributeKey.largeurInterieurEncartFormatOuvertCm:
      return t(
        'global.document_form.attributes_names.largeurInterieurEncartFormatOuvertCm'
      );
    case AttributeKey.largeurInterieurFormatFiniCm:
      return t(
        'global.document_form.attributes_names.largeurInterieurFormatFiniCm'
      );
    case AttributeKey.largeurInterieurFormatOuvertCm:
      return t(
        'global.document_form.attributes_names.largeurInterieurFormatOuvertCm'
      );
    case AttributeKey.largeurInterieurEncartFormatPageCm:
      return t(
        'global.document_form.attributes_names.largeurInterieurEncartFormatPageCm'
      );
    case AttributeKey.largeurInterieurFormatPageCm:
      return t(
        'global.document_form.attributes_names.largeurInterieurFormatPageCm'
      );
    case AttributeKey.largeurPixel:
      return t('global.document_form.attributes_names.largeurPixel');
    case AttributeKey.largeurTailleFenetreCm:
      return t('global.document_form.attributes_names.largeurTailleFenetreCm');
    case AttributeKey.longueurCouvertureFormatFiniCm:
      return t(
        'global.document_form.attributes_names.longueurCouvertureFormatFiniCm'
      );
    case AttributeKey.longueurCouvertureFormatOuvertCm:
      return t(
        'global.document_form.attributes_names.longueurCouvertureFormatOuvertCm'
      );
    case AttributeKey.longueurCouvertureFormatPageCm:
      return t(
        'global.document_form.attributes_names.longueurCouvertureFormatPageCm'
      );
    case AttributeKey.longueurFormatFiniCm:
      return t('global.document_form.attributes_names.longueurFormatFiniCm');
    case AttributeKey.longueurFormatOuvertCm:
      return t('global.document_form.attributes_names.longueurFormatOuvertCm');
    case AttributeKey.longueurFormatPageCm:
      return t('global.document_form.attributes_names.longueurFormatPageCm');
    case AttributeKey.longueurInterieurEncartFormatFiniCm:
      return t(
        'global.document_form.attributes_names.longueurInterieurEncartFormatFiniCm'
      );
    case AttributeKey.longueurInterieurFormatPageCm:
      return t(
        'global.document_form.attributes_names.longueurInterieurFormatPageCm'
      );
    case AttributeKey.longueurPixel:
      return t('global.document_form.attributes_names.longueurPixel');
    case AttributeKey.longueurTailleFenetreCm:
      return t('global.document_form.attributes_names.longueurTailleFenetreCm');
    case AttributeKey.matiere:
      return t('global.document_form.attributes_names.matiere');
    case AttributeKey.matiereCouverture:
      return t('global.document_form.attributes_names.matiereCouverture');
    case AttributeKey.matiereInterieur:
      return t('global.document_form.attributes_names.matiereInterieur');
    case AttributeKey.modeleAchat:
      return t('global.document_form.attributes_names.modeleAchat');
    case AttributeKey.nbCouleurCouvertureRecto:
      return t(
        'global.document_form.attributes_names.nbCouleurCouvertureRecto'
      );
    case AttributeKey.nbCouleurCouvertureVerso:
      return t(
        'global.document_form.attributes_names.nbCouleurCouvertureVerso'
      );
    case AttributeKey.nbCouleurInterieurEncartRecto:
      return t(
        'global.document_form.attributes_names.nbCouleurInterieurEncartRecto'
      );
    case AttributeKey.nbCouleurInterieurEncartVerso:
      return t(
        'global.document_form.attributes_names.nbCouleurInterieurEncartVerso'
      );
    case AttributeKey.nbCouleurInterieurRecto:
      return t('global.document_form.attributes_names.nbCouleurInterieurRecto');
    case AttributeKey.nbCouleurInterieurVerso:
      return t('global.document_form.attributes_names.nbCouleurInterieurVerso');
    case AttributeKey.nbCouleurRecto:
      return t('global.document_form.attributes_names.nbCouleurRecto');
    case AttributeKey.nbCouleurVerso:
      return t('global.document_form.attributes_names.nbCouleurVerso');
    case AttributeKey.objectifMarketing:
      return t('global.document_form.attributes_names.objectifMarketing');
    case AttributeKey.optionsFaconnage:
      return t('global.document_form.attributes_names.optionsFaconnage');
    case AttributeKey.orientation:
      return t('global.document_form.attributes_names.orientation');
    case AttributeKey.ouvertureEnveloppe:
      return t('global.document_form.attributes_names.ouvertureEnveloppe');
    case AttributeKey.paginationCouverture:
      return t('global.document_form.attributes_names.paginationCouverture');
    case AttributeKey.paginationInterieur:
      return t('global.document_form.attributes_names.paginationInterieur');
    case AttributeKey.paginationInterieurEncart:
      return t(
        'global.document_form.attributes_names.paginationInterieurEncart'
      );
    case AttributeKey.paginationTotale:
      return t('global.document_form.attributes_names.paginationTotale');
    case AttributeKey.paysDeDiffusion:
      return t('global.document_form.attributes_names.paysDeDiffusion');
    case AttributeKey.precisionsFaconnage:
      return t('global.document_form.attributes_names.precisionsFaconnage');
    case AttributeKey.precisionsFourniture:
      return t('global.document_form.attributes_names.precisionsFourniture');
    case AttributeKey.profondeurFormatFiniCm:
      return t('global.document_form.attributes_names.profondeurFormatFiniCm');
    case AttributeKey.taille:
      return t('global.document_form.attributes_names.taille');
    case AttributeKey.tauxSurfacage:
      return t('global.document_form.attributes_names.tauxSurfacage');
    case AttributeKey.typeEnvoi:
      return t('global.document_form.attributes_names.typeEnvoi');
    case AttributeKey.typeImpression:
      return t('global.document_form.attributes_names.typeImpression');
    case AttributeKey.typeMatiere:
      return t('global.document_form.attributes_names.typeMatiere');
    case AttributeKey.typeMatiereCouverture:
      return t('global.document_form.attributes_names.typeMatiereCouverture');
    case AttributeKey.typeMatiereInterieur:
      return t('global.document_form.attributes_names.typeMatiereInterieur');
    case AttributeKey.typePapier:
      return t('global.document_form.attributes_names.typePapier');
    case AttributeKey.typeSupport:
      return t('global.document_form.attributes_names.typeSupport');
    case AttributeKey.zoneTerritorial:
      return t('global.document_form.attributes_names.zoneTerritorial');

    default:
      return '';
  }
};
