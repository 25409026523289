import React, { useState, useEffect } from 'react';
import { Typography, Backdrop, CircularProgress } from '@material-ui/core';
import { Option } from 'components/ControlledSelect/ControlledSelect';
import { useGetInformationDocument } from 'modules/workflow/services/useGetInformationDocument';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'app/routes/useRouter';
import UncontrolledSelect from 'components/UncontrolledSelect';
import SelectConfirmationDialog from 'components/ActionsButtons/SelectConfirmationDialog';
import useReplaceDocumentMutation from 'queries/useReplaceDocumentMutation';
import { GetDocument_document_versions } from 'app/schemaInterfaces/GetDocument';
import { CSSProperties } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import useCurrentEntity from 'app/current-entity-context/useCurrentEntity';
import {
  actionButtonsTitle,
  confirmationDialog,
} from '../containers/CatalogPreview/EditPlatformDefinitions';

interface VersionSwitchProps {
  textFieldStyle?: CSSProperties;
  variant?: 'h6' | 'caption';
  textStyle?: CSSProperties;
}

const VersionSwitch = React.forwardRef<HTMLElement, VersionSwitchProps>(
  (
    { textFieldStyle, variant = 'caption', textStyle }: VersionSwitchProps,
    ref
  ): JSX.Element => {
    const { t } = useTranslation();

    const { documentData } = useGetInformationDocument();
    const { currentEntity } = useCurrentEntity();
    const {
      replaceDocument,
      loading: isLoading,
    } = useReplaceDocumentMutation();

    const [isSwitchConfirmationOpen, setIsSwitchConfirmationOpen] = useState<
      boolean
    >(false);

    const history = useHistory();

    const handleSwitchValidation = async (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      item: string | undefined
    ) => {
      if (item && document?.id) {
        await replaceDocument({
          variables: {
            documentIdToCopy: item,
            documentIdToUpdate: document.id,
            entityId: currentEntity?.id,
          },
        });
      }
    };

    const document = documentData?.document;

    const relatedDocuments = documentData?.document.versions;

    const router = useRouter();

    const [selectedDocumentId, setSelectedDocumentId] = useState<
      string | undefined
    >(undefined);

    const documentOptions: Option[] = relatedDocuments
      ? (relatedDocuments as GetDocument_document_versions[])
          ?.map(({ id, version }) => ({
            value: id,
            label: version,
          }))
          .sort((currentDocument, nextDocument) =>
            (currentDocument.label ?? '').localeCompare(
              nextDocument.label ?? ''
            )
          )
      : [];

    const handleVersionSwitch = (
      event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
      setSelectedDocumentId(event.target.value);
      if (document?.isCustomization) {
        setIsSwitchConfirmationOpen(true);
      } else {
        if ((history.location.state as Record<string, string>).prevPath) {
          router.push({
            pathname: `/studio/${event.target.value}`,
            state: {
              prevPath: (history.location.state as Record<string, string>)
                .prevPath,
            },
          });
        } else router.push(`/studio/${event.target.value}`);
      }
    };

    useEffect(() => {
      if (document?.isCustomization && document.versionId) {
        setSelectedDocumentId(document.versionId);
      } else {
        document?.id && setSelectedDocumentId(document.id);
      }
    }, [document]);

    const hasVersionSwitch =
      selectedDocumentId && relatedDocuments && relatedDocuments.length > 1;

    return hasVersionSwitch ? (
      <>
        <UncontrolledSelect
          options={documentOptions}
          value={selectedDocumentId}
          onChange={handleVersionSwitch}
          textFieldStyle={textFieldStyle}
        />
        <Backdrop className="backdrop" open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <SelectConfirmationDialog<string | undefined>
          item={selectedDocumentId}
          isOpen={isSwitchConfirmationOpen}
          onClose={() => {
            setIsSwitchConfirmationOpen(false);
            document?.versionId && setSelectedDocumentId(document.versionId);
          }}
          onClick={(event, item) => handleSwitchValidation(event, item)}
          confirmationDialog={confirmationDialog(t)}
          actionButtonsTitle={actionButtonsTitle(t)}
        />
      </>
    ) : (
      <Typography
        variant={variant}
        variantMapping={{ caption: 'span' }}
        style={{ lineHeight: 0, ...(textStyle ? textStyle : {}) }}
      >
        &nbsp;{document?.version}
      </Typography>
    );
  }
);

export default VersionSwitch;
