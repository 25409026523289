import { useQuery } from '@apollo/client';
import { GET_DOCUMENTS_COMMITMENTS } from 'app/graphql/queries/commitments';
import { GetDocumentsCommitments } from 'app/schemaInterfaces/GetDocumentsCommitments';
import { CommitmentFilterInput } from 'app/schemaInterfaces/globalTypes';

const useDocumentsCommitmentsQuery = (filter: CommitmentFilterInput) => {
  const { data, loading, error, refetch } = useQuery<GetDocumentsCommitments>(
    GET_DOCUMENTS_COMMITMENTS,
    {
      fetchPolicy: 'no-cache',
      variables: {
        filter: {
          ...filter,
          isArchived: false,
        },
      },
    }
  );
  return { data: data?.documentsCommitments, loading, error, refetch };
};

export default useDocumentsCommitmentsQuery;
