import React from 'react';
import {
  useStudioStateContext,
  useStudioDispatchContext,
  PersonalizationType,
} from 'modules/workflow/oneStudioContext';
import { useTranslation } from 'react-i18next';
import 'modules/workflow/Clients/ToutFaire/index.css';
import {
  isItemAsset,
  isItemBackgroundAsset,
  isItemPersonalization,
  isItemPersonalizationCard,
  isItemProduct,
  isItemProductCard,
  NormalizedIProductTemplate,
} from 'modules/workflow/containers/CatalogPreview/EditPlatformDefinitions';
import { useCurrentUser } from 'app/auth/UserContext';
import ActionButtons from 'components/ActionsButtons/ActionButtons';
import { setActionButtons } from '../CatalogPreview/EditPlatformHelper';
import { studioAppActions } from 'modules/workflow/reducers/actionsInterfaces';
import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

const style = (scale: number) => css`
  position: absolute;
  bottom: 0;
  right: 0;
  transform: scale(${1 / scale});
  transform-origin: bottom right;
  background-color: ${colors.white};
  width: fit-content;
  float: right;
  margin: 8px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  border: 1px solid ${colors.neutalLightGrey};
  z-index: 30;
  svg {
    color: ${colors.documentsTab};
  }
  svg:hover {
    color: ${colors.primary};
  }
`;
interface EditionButtonsProps<T> {
  item: T;
  handleProductsSheetDialogOpen?: (
    productId: string,
    productDetails: NormalizedIProductTemplate
  ) => void;
  handleTypographyDialog?: () => void;
}

function EditionButtons<T>({
  item,
  handleProductsSheetDialogOpen,
  handleTypographyDialog,
}: EditionButtonsProps<T>): JSX.Element {
  const { t } = useTranslation();

  const globalState = useStudioStateContext();
  const pageNumber = globalState.currentPage - 1;

  const isCustomization = Boolean(globalState.document?.isCustomization);
  const dispatch = useStudioDispatchContext();

  const currentPage = globalState.version.pages[pageNumber];
  const user = useCurrentUser();

  let isCurrentPageEditable = true;

  if (
    isCustomization &&
    (!currentPage.isCustomizable || currentPage.validated)
  ) {
    isCurrentPageEditable = false;
  }
  const scale = globalState.isTwoPagesView
    ? (globalState.scaleValue * 2) / 3
    : globalState.scaleValue;

  const deleteItem = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: T
  ) => {
    if (isItemAsset(item)) {
      dispatch(
        studioAppActions.deleteAsset({
          item,
          pageNumber: globalState.currentPage - 1,
        })
      );
    } else if (isItemProduct(item)) {
      dispatch(
        studioAppActions.deleteProductById({
          item,
          pageNumber: globalState.currentPage - 1,
        })
      );
    } else if (isItemBackgroundAsset(item)) {
      dispatch(
        studioAppActions.deleteBackgroundAsset({
          item,
          pageNumber: globalState.currentPage - 1,
        })
      );
    } else if (isItemPersonalization(item)) {
      dispatch(
        studioAppActions.deletePersonalizationZone({
          item,
          pageNumber: globalState.currentPage - 1,
        })
      );
    } else {
      throw new Error('item type not found');
    }
  };

  function handleOpenDialog<T>(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: T
  ) {
    if (isItemProductCard(item)) {
      handleProductsSheetDialogOpen?.(item.id, item);
    }
    if (
      isItemPersonalizationCard(item) &&
      item.type === PersonalizationType.TYPOGRAPHY
    ) {
      handleTypographyDialog?.();
    }
  }

  return (
    <div css={style(scale)}>
      <ActionButtons<T>
        item={item}
        actionButtons={setActionButtons<T>({
          item,
          deleteItem,
          handleOpenDialog,
          t,
          isDisabled: !isCurrentPageEditable,
          isCustomization,
          userType: user.type,
        })}
        hasDivider={true}
      />
    </div>
  );
}

export default EditionButtons;
