import StaticMapController from 'components/StaticMapController/StaticMapController';
import { icon as createLeafletIcon, LatLngExpression } from 'leaflet';
import { FC, PropsWithChildren } from 'react';
import {
  AttributionControl,
  MapContainer,
  Marker,
  Popup,
  TileLayer,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

const MAP_MARKER_ICON = createLeafletIcon({
  iconUrl: '/images/map-marker2.png',
  iconSize: [24, 42],
  iconAnchor: [12, 42],
  popupAnchor: [0, -42],
});

export interface StaticMapProps {
  marker: LatLngExpression;
  zoom: number;
  className?: string;
}

const StaticMap: FC<PropsWithChildren<StaticMapProps>> = ({
  marker,
  zoom,
  className,
  children,
}) => (
  <MapContainer
    attributionControl={false}
    center={marker}
    zoom={zoom}
    zoomControl={true}
    boxZoom={false}
    doubleClickZoom={false}
    dragging={false}
    keyboard={false}
    scrollWheelZoom={false}
    className={className}
  >
    <AttributionControl prefix={false} />
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
    <StaticMapController center={marker} zoom={zoom} />
    <Marker position={marker} icon={MAP_MARKER_ICON}>
      <Popup>{children}</Popup>
    </Marker>
  </MapContainer>
);

export default StaticMap;
