import { Grid } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useRouter } from 'app/routes/useRouter';
import { GetCategory_category } from 'app/schemaInterfaces/GetCategory';
import { GetTags_tags } from 'app/schemaInterfaces/GetTags';
import {
  ArrayOfIds,
  BigShopConfigCategory,
  CampaignFilterInput,
  TagType,
} from 'app/schemaInterfaces/globalTypes';
import { FilterInputProps } from 'components/FilterOptions/FilterOptions';
import { UncontrolledDatePicker } from 'components/UncontrolledDatePicker';
import { UncontrolledMultipleValueSelect } from 'components/UncontrolledMultipleValueSelect';
import { UncontrolledMultipleValueSelectV2 } from 'components/UncontrolledMultipleValueSelectV2';
import useDebounce from 'components/useDebounce';
import _ from 'lodash';
import useCategoriesQuery from 'modules/pro/hooks/useCategoriesQuery';
import { ShopRoute } from 'modules/shop/shopHelpers';
import useSupportsQuery from 'queries/useSupportsQuery';
import useTagsQuery from 'queries/useTagsQuery';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useCampaignFilterPopover = (
  filters: CampaignFilterInput,
  setFilters: React.Dispatch<React.SetStateAction<CampaignFilterInput>>
) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [filtersValues, setFiltersValues] = useState<CampaignFilterInput>(
    filters || {}
  );
  const debouncedValue = useDebounce(filtersValues, 500);

  useEffect(() => {
    if (debouncedValue !== undefined && setFilters) {
      setFilters((filters) => {
        return {
          ...filters,
          tags: debouncedValue.tags,
          categories: debouncedValue.categories,
          dateInterval: debouncedValue.dateInterval,
          commitmentDateInterval: debouncedValue.commitmentDateInterval,
          documentCategories: debouncedValue.documentCategories,
        };
      });
    }
  }, [debouncedValue, setFilters]);

  const { t } = useTranslation();
  const router = useRouter();
  const routeName = router.location.pathname;

  const isCampaignModel = routeName === ShopRoute.AllLocalCampaigns;

  const { tags } = useTagsQuery({ type: TagType.Campaign });
  const { category } = useCategoriesQuery();
  const { data: supportData } = useSupportsQuery();

  const documentCategoriesOptions = _.uniq(
    supportData?.supports.map((support) => support?.onePacConfig?.category!)
  );

  const categoriesOptions = (
    categories: GetCategory_category[] | GetTags_tags[] | undefined
  ) =>
    categories?.map(({ id, title }) => ({
      value: id,
      label: title,
    }));

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const tagsValues = filtersValues.tags?.map(({ id }) => id);

  const handleChangeTags = (event: React.ChangeEvent<{ value: string[] }>) => {
    const tagsFilter: ArrayOfIds[] = event.target.value.map((item) => ({
      id: item,
    }));
    setFiltersValues({
      ...filtersValues,
      tags: tagsFilter.length ? tagsFilter : null,
    });
  };
  const handleChangeCategories = (
    event: React.ChangeEvent<{ value: string[] }>
  ) => {
    const categories = event.target.value.length ? event.target.value : null;
    setFiltersValues((filters) => ({ ...filters, categories }));
  };

  const handleChangeDocumentCategories = (
    event: React.ChangeEvent<{ value: string[] }>
  ) => {
    const documentCategories = event.target.value.length
      ? (event.target.value as BigShopConfigCategory[])
      : null;
    setFiltersValues((filtersValues) => ({
      ...filtersValues,
      documentCategories,
    }));
  };

  const handleDateIntervalChange = (
    key: 'startDate' | 'endDate',
    value: MaterialUiPickersDate
  ) => {
    setFiltersValues((filtersValues) => ({
      ...filtersValues,
      dateInterval: value
        ? { ...filtersValues.dateInterval, [key]: value }
        : null,
    }));
  };

  const handleCommitmentDateIntervalChange = (
    key: 'startDate' | 'endDate',
    value: MaterialUiPickersDate
  ) => {
    setFiltersValues((filtersValues) => ({
      ...filtersValues,
      commitmentDateInterval: value
        ? {
            ...filtersValues.commitmentDateInterval,
            [key]: value,
          }
        : null,
    }));
  };

  const resetFilter = () => {
    setFiltersValues((filtersValues) => ({
      ...filtersValues,
      tags: null,
      dateInterval: null,
      documentCategories: null,
      categories: null,
      commitmentDateInterval: null,
    }));
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;

  const campaignFilterInputs: FilterInputProps[] = [
    {
      filterTitile: t('global.filters_popover.tags'),
      filterInput: (
        <UncontrolledMultipleValueSelectV2
          options={categoriesOptions(isCampaignModel ? category : tags) ?? []}
          value={
            (isCampaignModel ? filtersValues.categories : tagsValues) ?? []
          }
          handleChange={
            isCampaignModel ? handleChangeCategories : handleChangeTags
          }
          placeholder={t('common.placeholder.all')}
          isFullWidth={true}
        />
      ),
    },
    ...(!isCampaignModel
      ? [
          {
            filterTitile: t('global.filters_popover.date'),
            filterInput: (
              <Grid container justify="space-between" spacing={2}>
                <Grid item xs={6}>
                  <UncontrolledDatePicker
                    placeholder={t('common.dates.measurements.from')}
                    fullWidth
                    value={filtersValues.dateInterval?.startDate ?? null}
                    handleClear={() =>
                      handleDateIntervalChange('startDate', null)
                    }
                    onChange={(dateFrom) =>
                      handleDateIntervalChange('startDate', dateFrom)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <UncontrolledDatePicker
                    placeholder={t('common.dates.measurements.to')}
                    fullWidth
                    value={filtersValues.dateInterval?.endDate ?? null}
                    handleClear={() =>
                      handleDateIntervalChange('endDate', null)
                    }
                    onChange={(dateTo) =>
                      handleDateIntervalChange('endDate', dateTo)
                    }
                  />
                </Grid>
              </Grid>
            ),
          },
          {
            filterTitile: t('global.filters_popover.commitment_date'),
            filterInput: (
              <Grid container justify="space-between" spacing={2}>
                <Grid item xs={6}>
                  <UncontrolledDatePicker
                    placeholder={t('common.dates.measurements.from')}
                    fullWidth
                    value={
                      filtersValues.commitmentDateInterval?.startDate ?? null
                    }
                    handleClear={() =>
                      handleCommitmentDateIntervalChange('startDate', null)
                    }
                    onChange={(commitmentDateFrom) =>
                      handleCommitmentDateIntervalChange(
                        'startDate',
                        commitmentDateFrom
                      )
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <UncontrolledDatePicker
                    placeholder={t('common.dates.measurements.to')}
                    fullWidth
                    value={
                      filtersValues.commitmentDateInterval?.endDate ?? null
                    }
                    handleClear={() =>
                      handleCommitmentDateIntervalChange('endDate', null)
                    }
                    onChange={(commitmentDateTo) =>
                      handleCommitmentDateIntervalChange(
                        'endDate',
                        commitmentDateTo
                      )
                    }
                  />
                </Grid>
              </Grid>
            ),
          },
        ]
      : []),
    {
      filterTitile: t('global.filters_popover.category'),
      filterInput: (
        <UncontrolledMultipleValueSelect
          options={documentCategoriesOptions}
          value={filtersValues.documentCategories ?? []}
          handleChange={handleChangeDocumentCategories}
          fullWidth
          placeholder={t('common.placeholder.all')}
        />
      ),
    },
  ];

  return {
    popoverId,
    anchorEl,
    handleClick,
    handleClose,
    resetFilter,
    campaignFilterInputs,
  };
};
