import { Box, createStyles, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import colors from 'styles/colors.module.scss';
import StatusBadge, { BadgeStatus } from 'components/Badge/StatusBadge';
import AssetFileUploadField from 'modules/dam/form/fields/AssetFileUploadField';
import { processConformity } from 'modules/dam/form/helpers';
import mime from 'mime-types';
import { Skeleton } from '@material-ui/lab';
import { InitialImage } from 'components/FileUploadIcon/FileUploadIcon';
import {
  AssetFormModel,
  BaseAssetFormValues,
} from 'modules/dam/form/model/definitions';

const useStyles = makeStyles(() =>
  createStyles({
    conformityBadge: {
      position: 'absolute',
      right: 40,
      top: 30,
    },
  })
);

interface AssetFileUploadFormProps {
  asset: AssetFormModel;
  assetImage?: InitialImage;
  reportError: string | null;
  setReportError: React.Dispatch<React.SetStateAction<string | null>>;
}

const AssetFileUploadForm = ({
  asset,
  assetImage,
  reportError,
  setReportError,
}: AssetFileUploadFormProps): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    values: { asset: assetInput },
    setFieldValue,
  } = useFormikContext<BaseAssetFormValues>();

  const [maxSurfaceSize, setMaxSurfaceSize] = useState<number | undefined>(
    undefined
  );

  const isConform = assetInput.conform;

  const maxSurfaceSizeMessage =
    maxSurfaceSize !== undefined
      ? `${
          isConform
            ? t('dam.conformity.conform')
            : t('dam.conformity.non_conform')
        }:
	${t('dam.conformity.max_print_surface', { size: maxSurfaceSize })}
	`
      : undefined;

  const displayMetadataField = (text: string, value?: string) => (
    <Box pb={2} display="flex">
      <Box display="flex" whiteSpace="pre">
        {text} &nbsp;
      </Box>
      {isLoading ? <Skeleton width="100%" /> : value}
    </Box>
  );

  useEffect(() => {
    const qualityAttributes = processConformity({
      assetType: assetInput.assetType,
      visualType: assetInput.visualType,
      metadata: assetInput.metadata,
    });
    setFieldValue('asset.conform', qualityAttributes.conform);
    if (qualityAttributes.conform !== null) {
      setMaxSurfaceSize(qualityAttributes.maxSurfaceSize);
    }
  }, [
    assetInput.metadata,
    assetInput.assetType,
    assetInput.visualType,
    setFieldValue,
  ]);

  const dimensionsInCm =
    (asset.metadata?.dimensions?.widthInCm ?? undefined) &&
    (asset.metadata?.dimensions?.heightInCm ?? undefined);
  const hasDimensionsInCm = dimensionsInCm !== undefined;

  return (
    <Box p={2} bgcolor={colors.documentsHeaderBackground} height="100%">
      <Box position="relative" pt={3}>
        {isConform !== null && isConform !== undefined && (
          <StatusBadge
            className={classes.conformityBadge}
            status={isConform ? BadgeStatus.Success : BadgeStatus.Error}
            message={maxSurfaceSizeMessage}
          />
        )}
        <AssetFileUploadField
          assetImage={assetImage}
          onFileReadStart={() => setIsLoading(true)}
          onFileReadEnd={() => setIsLoading(false)}
          setReportError={setReportError}
        />
      </Box>

      <Box px={2} pt={3}>
        {reportError ? (
          <Box pb={2} color={colors.error}>
            {reportError}
          </Box>
        ) : (
          <>
            {displayMetadataField(
              t('dam.form_wrapper.extension'),
              mime.extension(asset.metadata?.mimetype) || undefined
            )}
            <Box pb={2}>
              <Divider />
            </Box>
            {displayMetadataField(
              t('dam.form_wrapper.format'),
              asset.metadata?.dimensions?.width &&
                asset.metadata?.dimensions?.height
                ? `${
                    hasDimensionsInCm
                      ? asset.metadata.dimensions.widthInCm
                      : asset.metadata.dimensions.width
                  } x ${
                    hasDimensionsInCm
                      ? asset.metadata.dimensions.heightInCm
                      : asset.metadata.dimensions.height
                  } ${hasDimensionsInCm ? 'cm' : 'px'}`
                : undefined
            )}
            <Box pb={2}>
              <Divider />
            </Box>
            {displayMetadataField(
              t('dam.form_wrapper.weight'),
              asset.metadata?.fileSize
                ? t('common.bytes.size', { size: asset.metadata.fileSize })
                : undefined
            )}
            <Box pb={2}>
              <Divider />
            </Box>
            {displayMetadataField(
              t('dam.form_wrapper.credit'),
              asset.metadata?.credit ?? undefined
            )}
            <Box pb={2}>
              <Divider />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};
export default AssetFileUploadForm;
