import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { GetAssets_getAssets_assets as Asset } from 'app/schemaInterfaces/GetAssets';
import { useInfiniteScroll } from 'components/infinite-scroll/useInfiniteScroll';
import { CircularProgress } from '@material-ui/core';
import colors from 'styles/colors.module.scss';
import { DEFAULT_IMAGE_PREVIEW } from 'app/utils/common';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      overflowY: 'scroll',
      maxHeight: '310px',
    },
    image: {
      height: 'inherit',
      width: 'inherit',
      padding: '3px',
    },
    backgroundTitle: {
      opacity: '0.6',
      fontSize: '10px',
    },
    paperRoot: {
      width: '112px',
      height: '142px',
    },
  })
);
export interface BackgroundAsset {
  id: string;
  image?: string;
  thumbnailUrl?: string;
  name?: string;
}
interface BackgroundItemsSubPanelProps {
  backgroundItems: Asset[] | undefined;
  onDragStart: (backgroundAsset: BackgroundAsset) => void;
  onLoadMore: () => void;
  loading: boolean;
}

const BackgroundItemsSubPanel = ({
  backgroundItems,
  onDragStart,
  loading,
  onLoadMore,
}: BackgroundItemsSubPanelProps): JSX.Element => {
  const classes = useStyles();
  const waypointRef = useInfiniteScroll(onLoadMore, loading);

  return (
    <Box pl={1}>
      <Grid container item>
        {backgroundItems?.map(
          (background, index) =>
            background.name &&
            background.url && (
              <Grid item xs={6} key={index}>
                <Box marginTop="15px">
                  <Paper
                    classes={{ root: classes.paperRoot }}
                    elevation={2}
                    draggable={true}
                    onDragStart={() =>
                      onDragStart({
                        id: background.id,
                        name: background.name ?? '',
                        image: background.url ?? '',
                        thumbnailUrl: background.thumbnailUrl ?? '',
                      })
                    }
                  >
                    <img
                      className={classes.image}
                      src={
                        background.displayedThumbUrl ?? DEFAULT_IMAGE_PREVIEW
                      }
                      alt={background.name}
                    />
                  </Paper>
                </Box>
              </Grid>
            )
        )}
      </Grid>
      {loading ? (
        <Box display="flex" justifyContent="center" color={colors.white}>
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <div ref={waypointRef} />
      )}
    </Box>
  );
};

export default BackgroundItemsSubPanel;
