import { Box, Button } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { DocumentFilterInput } from 'app/schemaInterfaces/globalTypes';
import { Action } from 'components/ActionsMenu/ActionsMenu';
import SimpleActionsMenu from 'components/ActionsMenu/SimpleActionsMenu';
import QuickSearchBar from 'components/QuickSearchBar/QuickSearchBar';
import DocumentsTypeFilter from 'modules/pro/components/DocumentsTypeFilter';
import { DocumentsFilterPopover } from 'modules/pro/documents/DocumentsFilterPopover/DocumentsFilterPopover';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface DocumentsToolbarProps {
  anchorEl?: HTMLButtonElement | null;
  placeholder?: string;
  actions: Action[];
  hasFilterIcon?: boolean;
  hasImportIcon?: boolean;
  mediumTypesOptions: string[];
  orderTypesOptions: string[];
  numbersOfPagesOptions: number[];
  filter: DocumentFilterInput;
  setFilter: React.Dispatch<React.SetStateAction<DocumentFilterInput>>;
}

const DocumentsToolbar = ({
  placeholder,
  actions,
  hasFilterIcon,
  mediumTypesOptions,
  orderTypesOptions,
  numbersOfPagesOptions,
  filter,
  setFilter,
}: DocumentsToolbarProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      justifyContent={hasFilterIcon ? 'space-between' : 'flex-end'}
    >
      {hasFilterIcon && <DocumentsTypeFilter />}
      <Box display="flex" alignItems="center">
        <Box pr={1}>
          <DocumentsFilterPopover
            mediumTypesOptions={mediumTypesOptions}
            orderTypesOptions={orderTypesOptions}
            numbersOfPagesOptions={numbersOfPagesOptions}
            filter={filter}
            setFilter={setFilter}
          />
        </Box>
        <Box pr={1}>
          <QuickSearchBar placeholder={placeholder} />
        </Box>
        <SimpleActionsMenu
          actions={actions}
          button={
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddCircleIcon />}
            >
              {t('global.table_toolbar.create')}
            </Button>
          }
        />
      </Box>
    </Box>
  );
};

export default DocumentsToolbar;
