import {
  Box,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  PopoverOrigin,
  PopoverPosition,
  Tooltip,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import React, { useState } from 'react';

import { ConfirmationDialog } from 'components/ActionsButtons/ActionButtons';
import { Link } from 'react-router-dom';
import SelectConfirmationDialog from 'components/ActionsButtons/SelectConfirmationDialog';
import colors from 'styles/colors.module.scss';

export interface Action {
  title?: string;
  icon?: React.ReactNode;
  to?: string | { pathname: string; state: { prevPath: string } };
  isBlankTarget?: boolean;
  onClick?: (event?: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  description?: string;
  component?: React.ElementType<any>;
  confirmationDialog?: ConfirmationDialog;
  isDisabledAvailable?: boolean;
  tooltipMessage?: string;
}

export interface ActionsMenuProps {
  actions: Action[];
  button: React.ReactNode;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  handleMenuClick?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  handleMenuClose: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  anchorEl?: HTMLButtonElement | null;
  maxWidth?: number;
  anchorPosition?: PopoverPosition;
  open?: boolean;
  anchorReference?: 'none' | 'anchorEl' | 'anchorPosition';
  closeOnItemClick?: boolean;
  isDisabled?: boolean;
  isNonInteractive?: boolean;
}

export const ActionsMenu = ({
  actions,
  button,
  anchorOrigin,
  transformOrigin,
  handleMenuClick,
  handleMenuClose,
  anchorEl,
  maxWidth = 224,
  anchorPosition,
  open = Boolean(anchorEl),
  anchorReference,
  closeOnItemClick = false,
  isDisabled = false,
  isNonInteractive = false,
}: ActionsMenuProps): JSX.Element => {
  const classes = makeStyles({
    menuItem: {
      '&:hover': {
        backgroundColor: colors.menuHover,
        '& span:first-child': {
          color: colors.primary,
        },
        '& .MuiListItemIcon-root': {
          color: colors.primary,
        },
      },
    },
    listItemText: {
      whiteSpace: 'break-spaces',
    },
    menu: {
      maxWidth,
      width: 180,
    },
  })({ maxWidth });

  const iconClasses = makeStyles((theme) =>
    createStyles({
      root: {
        minWidth: 0,
        marginRight: theme.spacing(2),
      },
    })
  )();

  const [isActionDialogOpen, setIsActionDialogOpen] = useState<boolean>(false);

  const closeActionDialog = () => {
    setIsActionDialogOpen(false);
  };

  return (
    <>
      <Box
        display="flex"
        aria-label="actions menu"
        aria-haspopup="true"
        onClick={!isNonInteractive ? handleMenuClick : undefined}
      >
        {button}
      </Box>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        classes={{ paper: classes.menu }}
        keepMounted
        open={open}
        onClose={handleMenuClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        anchorPosition={anchorPosition}
        anchorReference={anchorReference}
      >
        {actions?.map(
          (
            {
              icon,
              title,
              description,
              onClick,
              to,
              component = 'li',
              isBlankTarget,
              confirmationDialog,
              isDisabledAvailable,
              tooltipMessage,
            },
            index
          ) => (
            <Tooltip key={index} title={tooltipMessage ?? ''}>
              <Box>
                <MenuItem
                  disabled={isDisabled && isDisabledAvailable}
                  onClick={(
                    event: React.MouseEvent<HTMLLIElement, MouseEvent>
                  ) => {
                    if (closeOnItemClick) handleMenuClose?.(event as any);
                    if (confirmationDialog) {
                      setIsActionDialogOpen(true);
                    } else {
                      onClick?.(event);
                    }
                  }}
                  component={to ? Link : component}
                  to={to}
                  target={isBlankTarget ? '_blank' : undefined}
                >
                  {icon && (
                    <ListItemIcon classes={iconClasses}>{icon}</ListItemIcon>
                  )}

                  <ListItemText
                    classes={{ root: classes.listItemText }}
                    primary={title}
                    secondary={
                      <Typography component="span" variant="caption">
                        {description}
                      </Typography>
                    }
                  />
                  {confirmationDialog && isActionDialogOpen && (
                    <SelectConfirmationDialog
                      item={undefined}
                      isOpen={isActionDialogOpen}
                      onClose={closeActionDialog}
                      confirmationDialog={confirmationDialog}
                      onClick={() => onClick?.()}
                    />
                  )}
                </MenuItem>
              </Box>
            </Tooltip>
          )
        )}
      </Menu>
    </>
  );
};
