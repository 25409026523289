import { Box, Button, Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AppTable from 'components/AppTable/AppTable';
import { AddressBlocks_addressBlocks } from 'app/schemaInterfaces/AddressBlocks';
import { AddressBlockColumns } from '../useAddressBlockTable';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import QuickSearchBar from 'components/QuickSearchBar/QuickSearchBar';
import CircularProgressLoader from 'components/Loader/CircularProgressLoader/CircularProgressLoader';
import UploadProgressSnackbar from 'components/UploadProgressSnackbar';
import SelectConfirmationDialog from 'components/ActionsButtons/SelectConfirmationDialog';
import AddressBlockDialog from '../AddressBlockDialog';
import { addressBlockContainerSyle } from './AddressBlockContainer.style';
import { useAddressBlockContainer } from './AddressBlockContainer.hooks';
import { useAddressBlockMutate } from '../useAddressBlockMutate';
import { useAddressBlockDelete } from './AddressBlockDelete.hooks';

export interface AddressBlockFormValues {
  name: string;
  supplyProductIds: string[];
  supportId: string;
  hdPdf?: File;
  thumbnailFile?: File;
}

const AddressBlockContainer = (): JSX.Element => {
  const { t } = useTranslation();

  const {
    uploadProgress,
    clearProgress,
    removeAddressBlock,
  } = useAddressBlockMutate({
    reportProgress: true,
  });

  const {
    itemToDelete,
    setItemToDelete,
    deleteItem,
    open,
    setOpen,
    handleItemToDelete,
    openDialog,
  } = useAddressBlockDelete(removeAddressBlock);

  const {
    loading,
    error,
    editMode,
    clickRowIndex,
    setClickRowIndex,
    isEditModeActive,
    formValues,
    setFormValues,
    submitForm,
    clearChanges,
    overRowIndex,
    supplyProductIds,
    formats,
    dataNormalized,
    getRowId,
    onRowOver,
  } = useAddressBlockContainer();

  return (
    <div>
      <Box py={3} css={addressBlockContainerSyle}>
        <h1>{t('menuItems.addressblocks')} </h1>
        <div>
          <Grid container justify="flex-end">
            <Box display="flex">
              <QuickSearchBar />
              <Box pl={1}>
                <Button
                  onClick={openDialog}
                  variant="contained"
                  color="primary"
                  startIcon={<AddCircleIcon />}
                >
                  {t(
                    'one_manager.store_information.address_block_page.add_address_block'
                  )}
                </Button>
              </Box>
            </Box>
          </Grid>
          <Box pt={3} pb={4}>
            {loading ? (
              <CircularProgressLoader error={error} />
            ) : (
              <AppTable<AddressBlocks_addressBlocks>
                columns={AddressBlockColumns(
                  handleItemToDelete,
                  editMode,
                  clickRowIndex,
                  setClickRowIndex,
                  isEditModeActive,
                  formValues,
                  setFormValues,
                  submitForm,
                  clearChanges,
                  t,
                  overRowIndex,
                  supplyProductIds,
                  formats
                )}
                data={dataNormalized()}
                getRowId={getRowId}
                onRowOver={onRowOver}
              />
            )}
          </Box>
        </div>
        <UploadProgressSnackbar
          open={uploadProgress.length > 0}
          defaultExpanded={true}
          uploads={uploadProgress}
          onClose={() => clearProgress()}
        />
        <SelectConfirmationDialog<string | null>
          isOpen={Boolean(itemToDelete)}
          onClose={() => setItemToDelete(null)}
          item={itemToDelete}
          confirmationDialog={{
            title: t('common.delete_confirmation_dialog.title'),
            description: t(
              'workflow.right_sidebar.products_panel.delete_confirmation_dialog.address_block'
            ),
          }}
          onClick={deleteItem}
        />
      </Box>

      <AddressBlockDialog
        open={open}
        setOpen={setOpen}
        formValues={formValues}
        setFormValues={setFormValues}
        supplyProductIds={supplyProductIds}
        formats={formats}
      />
    </div>
  );
};

export default AddressBlockContainer;
