import { Grid, IconButton, Tooltip } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import QuantitySelect from '../QuantitySelect';
import React, { useState } from 'react';
import { filteredAttributes } from '../QuantitySelectHelper';
import { DEFAULT_IMAGE_PREVIEW, formatPriceHT } from 'app/utils/common';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import DocumentPreview from 'components/ConfirmationDialogs/DocumentPreview/DocumentPreview';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined';
import FormatColorFillOutlinedIcon from '@material-ui/icons/FormatColorFillOutlined';
import SelectConfirmationDialog from 'components/ActionsButtons/SelectConfirmationDialog';
import { Skeleton } from '@material-ui/lab';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { getWorkshopDocumentLink } from 'app/utils/route-helper';
import { useTranslation } from 'react-i18next';
import { basketDocumentRowStyle, basketRowStyle } from './BasketRow.style';
import { useBasketRow } from './BasketRow.hooks';
import { getDisabledTooltipMessage } from './BasketRow.helper';
import { BasketRowProps } from './BasketRow.definitions';

const BasketRow = ({
  basketDocument,
  setSelectedBasketDocuments,
  isCampaignDisabled,
  refetch,
  selectedBasketDocuments,
  addToSelectedDocuments,
}: BasketRowProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isDeletePopInOpen, setIsDeletePopInOpen] = useState<boolean>(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);

  const {
    loading,
    isValidated,
    isCustomizable,
    mediumType,
    name,
    thumbnailUrl,
    version,
    productId,
    productAttributes,
    error,
    handleSelectValues,
    validatedIcon,
    handleDelete,
    checkBox,
    options,
    lowestQuantity,
    isQuantitySelectDisabled,
    isDigital,
    selectedValue,
    maximumDocQuantity,
    isDeletable,
    isDisabled,
    handlePriceError,
    hasPriceError,
    isUpdatingPricing,
  } = useBasketRow({
    basketDocument,
    selectedBasketDocuments,
    setSelectedBasketDocuments,
    refetch,
    isCampaignDisabled,
    addToSelectedDocuments,
  });

  if (loading) {
    return <Skeleton variant="rect" width="100%" height={65} />;
  }

  return (
    <div
      css={[basketRowStyle, basketDocumentRowStyle]}
      className="document-row"
    >
      <Grid
        container
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={1} className="checkbox">
          {!isDisabled ? (
            checkBox
          ) : (
            <Tooltip
              placement="right-end"
              title={getDisabledTooltipMessage(
                t,
                isCampaignDisabled,
                basketDocument.document.isValidated,
                basketDocument.document.isDisabled,
                hasPriceError,
                isUpdatingPricing
              )}
            >
              <span className="checkbox">{checkBox}</span>
            </Tooltip>
          )}
        </Grid>
        <Grid item xs={1} className="media-wrapper">
          <img src={thumbnailUrl ?? DEFAULT_IMAGE_PREVIEW} alt="document" />
          <IconButton size="small" onClick={() => setIsPreviewOpen(true)}>
            <FindInPageOutlinedIcon fontSize="small" />
          </IconButton>
        </Grid>
        <Grid item xs={4} className="content">
          <h5>
            {name} - {version}
          </h5>
          <div className="tag-wrapper">
            {mediumType}
            <span className="divider">|</span>
            {productAttributes?.paginationTotale
              ? t('local.common.number_of_pages', {
                  count: parseInt(productAttributes.paginationTotale),
                })
              : undefined}
            {isCustomizable && <FormatColorFillOutlinedIcon className="icon" />}
            {isCustomizable && isValidated && validatedIcon}
          </div>
        </Grid>

        <Grid item xs={4} className="actions">
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '310px',
              }}
            >
              {isDigital &&
                basketDocument.document.priceInformations?.isIncluded && (
                  <h5>{t('local.campaign_page.included')}</h5>
                )}
              {!isDigital && productId && (
                <>
                  <QuantitySelect
                    selectedValue={selectedValue}
                    handleSelect={handleSelectValues}
                    handlePriceError={handlePriceError}
                    options={options ?? []}
                    error={error}
                    productId={productId}
                    isDisabled={isQuantitySelectDisabled}
                    isFixedWidth
                    minimum={lowestQuantity}
                    maximum={maximumDocQuantity}
                    hasPriceError={hasPriceError}
                  />
                  {!!selectedValue?.price && (
                    <h5
                      style={{
                        paddingLeft: 15,
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {basketDocument.document.priceInformations?.isIncluded
                        ? t('local.campaign_page.included')
                        : formatPriceHT(selectedValue?.price ?? 0, t)}
                    </h5>
                  )}
                </>
              )}
            </div>
          </div>
        </Grid>
        <Grid item xs={2} className="buttons">
          <IconButton disabled={isCampaignDisabled}>
            {isCustomizable ? (
              <Link
                to={{
                  pathname: getWorkshopDocumentLink(basketDocument.document.id),
                  state: { prevPath: history.location.pathname },
                }}
                style={{
                  textDecoration: 'none',
                  color: 'inherit',
                  backgroundColor: 'inherit',
                  lineHeight: 0,
                  fontSize: 'inherit',
                }}
              >
                <EditOutlinedIcon />
              </Link>
            ) : (
              <VisibilityOutlinedIcon onClick={() => setIsPreviewOpen(true)} />
            )}
          </IconButton>
          {isDeletable && (
            <IconButton
              onClick={() => setIsDeletePopInOpen(true)}
              className="delete-button"
            >
              <DeleteForeverOutlinedIcon />
            </IconButton>
          )}
        </Grid>
      </Grid>

      <SelectConfirmationDialog
        item={basketDocument.document.id}
        isOpen={isDeletePopInOpen}
        onClose={() => setIsDeletePopInOpen(false)}
        onClick={() => handleDelete()}
        confirmationDialog={{
          title: t('common.delete_confirmation_dialog.title'),
          description: t('sales.basket_page.delete_dialog'),
        }}
        actionButtonsTitle={{
          reject: t('common.button.cancel'),
          accept: t('common.simple_confirmation_dialog.yes'),
        }}
      />
      <DocumentPreview
        isOpen={isPreviewOpen}
        onClose={() => setIsPreviewOpen(false)}
        title={basketDocument.document.name}
        version={basketDocument.document.version}
        url={basketDocument.document.thumbnailUrl}
        isCustomizable={basketDocument.document.isCustomizable}
        category={basketDocument.document.category}
        mediumType={basketDocument.document.mediumType}
        attributes={filteredAttributes(
          basketDocument.document.productAttributes
        )}
        quantities={
          basketDocument.document.quantities?.strictQuantity
            ? { strict: basketDocument.document.quantities.strictQuantity }
            : lowestQuantity && maximumDocQuantity
            ? { minimum: lowestQuantity, maximum: maximumDocQuantity }
            : undefined
        }
      />
    </div>
  );
};

export default BasketRow;
