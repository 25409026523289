import {
  DocumentNode,
  MutationHookOptions,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  LazyQueryHookOptions,
  QueryHookOptions,
} from '@apollo/client/react/types/types';

export const useQueryProvider = <T, U>(
  document: DocumentNode,
  options?: QueryHookOptions<T, U>
) => {
  return useQuery<T, U>(document, options);
};

export const useQueryLazyProvider = <T, U>(
  document: DocumentNode,
  options?: LazyQueryHookOptions<T, U>
) => {
  return useLazyQuery<T, U>(document, options);
};

export const useMutationProvider = <T, U>(
  document: DocumentNode,
  options?: MutationHookOptions<T, U>
) => {
  return useMutation<T, U>(document, options);
};
