import React, { useState } from 'react';
import { css } from '@emotion/react/macro';
import useAppContext from 'app/app-context/useAppContext';
import NewCampaignsContainer from './components/home-shortcuts/new-campaigns/NewCampaignsContainer';
import AvailableDocuments from './components/available-documents/AvailableDocuments/AvailableDocuments';
import LocalCampaignsContainer from './components/local-campaigns/LocalCampaignsContainer';
import useClientOffers from 'app/auth/useClientOffers';
import { ModuleKey } from 'app/routes/helper';

const style = css`
  padding-bottom: 150px;
  .banner {
    width: 100%;
  }
  h1 {
    text-align: center;
    margin-top: 60px;
  }
  .planning {
    padding-bottom: 30px;
  }
`;

const HomePage = () => {
  const { client } = useAppContext();
  const [isBannerVisible, setBannerVisible] = useState<boolean>(true);
  const { hasOffers } = useClientOffers();

  return (
    <div css={style}>
      {isBannerVisible && (
        <img
          className="banner"
          src={client?.config?.clientAssets?.banner ?? ''}
          alt="banner"
          onError={() => setBannerVisible(false)}
        />
      )}
      {hasOffers([ModuleKey.ActionMarketing, ModuleKey.AM_OP_Nationales]) && (
        <NewCampaignsContainer />
      )}
      {hasOffers([ModuleKey.ActionMarketing, ModuleKey.AM_OP_Locales]) && (
        <LocalCampaignsContainer />
      )}
      {hasOffers([ModuleKey.ActionMarketing]) && <AvailableDocuments />}
    </div>
  );
};

export default HomePage;
