import React, { ReactNode } from 'react';
import { Toolbar, Box, makeStyles } from '@material-ui/core';
import { useStudioStateContext } from 'modules/workflow/oneStudioContext';
import colors from 'styles/colors.module.scss';
import { Theme } from '@material-ui/core/styles';
import { css } from '@emotion/react';

const style = (theme: Theme) => css`
  .MuiBackdrop-root {
    color: ${colors.white};
    z-index: ${theme.zIndex.drawer + 1} !important;
  }
`;

const useStyles = makeStyles({
  root: {
    border: `0.5px solid ${colors.lightGrey}`,
  },
});

interface SecondToolbarProps {
  toolbarActions?: ToolbarActions;
}

export interface ToolbarActions {
  left?: ReactNode;
  right?: ReactNode;
}

const SecondToolbar = ({
  toolbarActions = {},
}: SecondToolbarProps): JSX.Element => {
  const globalState = useStudioStateContext();
  const classes = useStyles();
  const isEditable = globalState.isEditable;

  return (
    <Toolbar variant="dense" classes={{ root: classes.root }} css={style}>
      <Box display="inline-flex" flexGrow={1} py={1} alignItems="center">
        {toolbarActions.left}
      </Box>
      <Box display="inline-flex" py={1}>
        {isEditable && <Box display="inline-flex">{toolbarActions.right}</Box>}
      </Box>
    </Toolbar>
  );
};

export default SecondToolbar;
