import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const entityMenuSelectStyle = {
  header: css`
    display: flex;
    align-items: center;
    height: 36px;
    padding: 0px 8px;
    background-color: ${colors.documentsHeaderBackground};
    border: solid 1px ${colors.buttonGroupOutlined};
    cursor: pointer;
  `,
  title: css`
    padding-left: 10px;
  `,
  icon: css`
    color: ${colors.disabled};
  `,
};
