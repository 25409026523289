import axios from 'axios';

export interface DownloadFileParam {
  url: string;
  filename: string;
  mimetype: string;
  progressCallback?: (percentage: number) => void;
  useS3CorsWorkaround?: boolean;
}

export const downloadFile = ({
  filename,
  mimetype,
  url,
  progressCallback,
  useS3CorsWorkaround = false,
}: DownloadFileParam): Promise<void> =>
  axios
    .get(useS3CorsWorkaround ? `${url}&x-cors-workaround` : url, {
      responseType: 'blob',
      onDownloadProgress: (e: ProgressEvent) => {
        if (progressCallback) {
          progressCallback(Math.round((e.loaded * 100) / e.total));
        }
      },
    })
    .then((response) => {
      const objectURL = window.URL.createObjectURL(
        new Blob([response.data], { type: mimetype })
      );
      const link = document.createElement('a');
      link.href = objectURL;
      link.setAttribute('download', filename);
      link.click();
      link.remove();
    });
