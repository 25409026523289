import React from 'react';
import { useTranslation } from 'react-i18next';
import pagesStyle from 'modules/sales/pages/pages.style';
import BasketConfirmation from '../BasketConfirmation/BasketConfirmation';
import { Grid } from '@material-ui/core';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import InactiveEntityAlert from 'components/InactiveEntityAlert/InactiveEntityAlert';
import { useBasketPage } from './BasketPage.hooks';
import FilterButtons from 'components/ActionsButtons/FilterButtons';
import { DateStatus } from 'app/schemaInterfaces/globalTypes';
import BasketConfirmationPreview from '../BasketConfirmationPreview/BasketConfirmationPreview';
import AlertBox from 'components/AlertBox/AlertBox';

const BasketPage = () => {
  const { t } = useTranslation();

  const {
    onSubmit,
    selectedBasketDocuments,
    basket,
    styleButtonVariant,
    styleButtonTitle,
    setSelectedDateStatus,
    buttonsGroup,
    isFilterVisible,
    isContinueDisabled,
    errors,
  } = useBasketPage();

  return (
    <div css={pagesStyle.basketPage}>
      <h1>{t('sales.basket_page.title')} </h1>

      <Grid container spacing={4}>
        {isFilterVisible && (
          <Grid item xs={8}>
            <FilterButtons<DateStatus>
              buttons={buttonsGroup()}
              styleButtonVariant={styleButtonVariant}
              styleButtonTitle={styleButtonTitle}
              setValue={setSelectedDateStatus}
            />
          </Grid>
        )}
        <Grid item xs={8}>
          <InactiveEntityAlert
            message={t('campaign_page.warning.selection_needed_message')}
          />

          {basket}
        </Grid>
        <Grid item xs={4}>
          <BasketConfirmation
            onFirstBtnClick={onSubmit}
            length={selectedBasketDocuments.length}
            firstTitle={t('sales.basket_page.delivery_page.confirmation_title')}
            title={t('sales.basket_page.supports')}
            isDisabled={isContinueDisabled}
            icon={<ArrowForwardOutlinedIcon />}
            dataBloc={
              <BasketConfirmationPreview
                basketDocuments={selectedBasketDocuments}
              />
            }
          />
          {errors.length > 0 && (
            <AlertBox
              variant="warning"
              header={errors.map((msg) => (
                <p>{msg}</p>
              ))}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default BasketPage;
