import React from 'react';
import { Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { SummaryAddressDeliveryDetailsProps } from './SummaryAddressDeliveryDetails.definition';

const SummaryAddressDeliveryDetails = ({
  addresses,
}: SummaryAddressDeliveryDetailsProps) => {
  const { t } = useTranslation();

  const totalQuantity = useMemo(
    () =>
      addresses?.reduce((acc, currentAddress) => {
        return acc + currentAddress.quantity;
      }, 0),
    [addresses]
  );

  return (
    <>
      <p data-testid="address-delivery-details">
        {addresses.length}{' '}
        {t(
          `sales.basket_page.summary_page.delivery_addresses.${
            addresses.length > 1 ? 'delivery_point_plural' : 'delivery_point'
          }`
        )}
      </p>
      <Divider orientation="vertical" />
      <p>
        {t('sales.basket_page.summary_page.delivery_addresses.quantity_copy', {
          count: totalQuantity,
        })}
      </p>
    </>
  );
};

export default SummaryAddressDeliveryDetails;
