import { gql } from '@apollo/client';

export const DOWNLOAD_PDF = gql`
  mutation DownloadPdf(
    $versionId: String!
    $entityId: String
    $pdfDefinition: PdfDefinition
  ) {
    downloadPdf(
      versionId: $versionId
      entityId: $entityId
      pdfDefinition: $pdfDefinition
    ) {
      url
      mimetype
      name
    }
  }
`;
