import { gql } from '@apollo/client';

//TODO: Pas besoin de récupérer tout les attribut d'entité à ce niveau la
export const ENTITY_DETAIL = gql`
  fragment EntityDetail on Entity {
    id
    name
    country
    clientId
    createdAt
    createdBy
    legal {
      ape
      capital
      iban
      name
      rcs
      siret
      status
    }
    openingHours {
      days
      hours
    }
    parentId
    picture
    type
    updatedAt
    othersInformations {
      codeClient
      codeRegion
      codeSector
      surface
      typology
      typology2
    }
  }
`;

export const PRODUCT_ATTRIBUTES_DETAILS = gql`
  fragment ProductAttributesDetails on Document {
    productAttributes {
      certification
      chant
      classification
      codeAt
      conditionnement {
        numberOfLots
        total
        conditionnementList {
          numberOfExLots
          firstConditionnement {
            conditionnement
            nbExConditionnement
            labeling
          }
          secondConditionnement {
            conditionnement
            nbExConditionnement
            labeling
          }
          threeConditionnement {
            conditionnement
            nbExConditionnement
            labeling
          }
        }
      }
      couleur
      decoupe
      descriptionIndication
      diametreCm
      enveloppeFenetre
      epaisseurMicrons
      faconnage
      finitionRecto
      finitionVerso
      fourniture
      grammageCouvertureGr
      grammageGr
      grammageInterieurGr
      impressionLogo
      impressionRectoVerso
      format
      largeurCouvertureFormatFiniCm
      largeurCouvertureFormatOuvertCm
      largeurCouvertureFormatPageCm
      largeurFormatFiniCm
      largeurFormatOuvertCm
      largeurFormatPageCm
      largeurInterieurEncartFormatFiniCm
      largeurInterieurEncartFormatOuvertCm
      largeurInterieurEncartFormatPageCm
      largeurInterieurFormatFiniCm
      largeurInterieurFormatOuvertCm
      largeurInterieurFormatPageCm
      largeurTailleFenetreCm
      longueurCouvertureFormatFiniCm
      longueurCouvertureFormatOuvertCm
      longueurCouvertureFormatPageCm
      longueurFormatFiniCm
      longueurFormatOuvertCm
      longueurFormatPageCm
      longueurInterieurEncartFormatFiniCm
      longueurInterieurEncartFormatOuvertCm
      longueurInterieurEncartFormatPageCm
      longueurInterieurFormatFiniCm
      longueurInterieurFormatOuvertCm
      longueurInterieurFormatPageCm
      longueurTailleFenetreCm
      matiere
      matiereCouverture
      matiereInterieur
      nbCouleurCouvertureRecto
      nbCouleurCouvertureVerso
      nbCouleurInterieurEncartRecto
      nbCouleurInterieurEncartVerso
      nbCouleurInterieurRecto
      nbCouleurInterieurVerso
      nbCouleurRecto
      nbCouleurVerso
      optionsFaconnage
      ouvertureEnveloppe
      paginationCouverture
      paginationInterieur
      paginationInterieurEncart
      paginationTotale
      precisionsFaconnage
      precisionsFourniture
      profondeurFormatFiniCm
      repiquage
      repiquageCoeur
      repiquageDOS
      surfacage
      surfacageCouvertureRecto
      surfacageCouvertureVerso
      surfacageInterieurRecto
      surfacageInterieurVerso
      taille
      tauxSurfacage
      typeImpression
      typeMatiere
      typeMatiereCouverture
      typeMatiereInterieur
      typePapier
      typeSupport
      ciblage
      modeleAchat
      objectifMarketing
      paysDeDiffusion
      typeEnvoi
      zoneTerritorial
      largeurPixel
      longueurPixel
      fondsPerdus
      faconnageDeLivraison
      orientation
    }
  }
`;

export const DOCUMENT_DETAIL = gql`
  fragment DocumentDetail on Document {
    campaign {
      id
      name
      campaignType
      archived
    }
    campaignId
    category
    commitmentDateFrom
    commitmentDateTo
    createdAt
    entityIds
    finishedFormatHeight
    finishedFormatWidth
    widthInPx
    heightInPx
    freeOrderDateFrom
    freeOrderDateTo
    id
    impression
    isCustomizable
    isMandatory
    hasAddressBlock
    isCustomization
    isRecto
    isVerso
    mediumType
    name
    numberOfPages
    orderTypes
    quantities {
      type
      rangeQuantity {
        minimum
        maximum
      }
      strictQuantity
    }
    origin
    paper
    parentId
    quantity
    shaping
    thumbnailUrl
    updatedAt
    version
    versionId
    productId
    supportId
    isValidated
    isArchived
    template {
      type
      metadata {
        bigone {
          _
        }
        chili {
          templateId
        }
      }
    }
  }
`;

export const DOCUMENTS_FILTERING_VALUES_DETAIL = gql`
  fragment DocumentsFilteringValuesDetail on DocumentsWithValuesOptionsFilters {
    mediumTypes
    numbersOfPages
    orderTypes
    totalItems
    categories
  }
`;

export const GET_DOCUMENT = gql`
  query GetDocument($documentId: String!) {
    document(id: $documentId) {
      thumbnailId
      ...DocumentDetail
      ...ProductAttributesDetails
      parent {
        ...DocumentDetail
      }
      campaign {
        campaignType
      }
      versions {
        ...DocumentDetail
      }
      versionChildren {
        ...DocumentDetail
      }
    }
  }

  ${DOCUMENT_DETAIL}
  ${PRODUCT_ATTRIBUTES_DETAILS}
`;

export const GET_DOCUMENTS = gql`
  query GetDocuments(
    $filter: DocumentFilterInput!
    $sort: DocumentSortInput
    $pagination: PaginationInput
  ) {
    documents(filter: $filter, sort: $sort, pagination: $pagination) {
      documents {
        ...DocumentDetail
        ...ProductAttributesDetails
        parent {
          ...DocumentDetail
        }
        versionChildren {
          ...DocumentDetail
        }
        resourcesFile {
          filename
          mimetype
          url
        }
      }
      ...DocumentsFilteringValuesDetail
    }
  }

  ${DOCUMENT_DETAIL}
  ${DOCUMENTS_FILTERING_VALUES_DETAIL}
  ${PRODUCT_ATTRIBUTES_DETAILS}
`;

export const GET_DOCUMENTS_TO_SHOP = gql`
  query GetDocumentsToShop(
    $filter: DocumentFilterInput!
    $sort: DocumentSortInput
    $pagination: PaginationInput
  ) {
    documents(filter: $filter, sort: $sort, pagination: $pagination) {
      documents {
        category
        mediumType
        id
        isCustomization
        productId
        commitmentDateTo
        isMandatory
        isRecommended(filter: $filter)
        documentCommitmentCode(filter: $filter) {
          commitmentCode
          commitmentQuantity
          createdAt
        }
        thumbnailUrl
        isCustomizable
        name
        version
        numberOfPages
        priceInformations {
          priceMinimum
          quantityMinimum
          isIncluded
        }
        isDisabled(filter: $filter) {
          reason
          disabled
        }
        ...ProductAttributesDetails

        versionChildren {
          id
        }
        resourcesFile {
          filename
          mimetype
          url
        }
      }
    }
  }

  ${PRODUCT_ATTRIBUTES_DETAILS}
`;

export const GET_DOCUMENTS_WITH_RELATED = gql`
  query GetDocumentsWithRelated(
    $filter: DocumentFilterInput!
    $sort: DocumentSortInput
    $pagination: PaginationInput
  ) {
    documents(filter: $filter, sort: $sort, pagination: $pagination) {
      documents {
        ...DocumentDetail
        ...ProductAttributesDetails
        obsolete
        parent {
          ...DocumentDetail
        }
        children {
          ...DocumentDetail
        }
        versionChildren {
          ...DocumentDetail
        }
        campaign {
          campaignType
        }
      }
      ...DocumentsFilteringValuesDetail
    }
  }

  ${DOCUMENT_DETAIL}
  ${DOCUMENTS_FILTERING_VALUES_DETAIL}
  ${PRODUCT_ATTRIBUTES_DETAILS}
`;

export const CREATE_DOCUMENT = gql`
  mutation CreateDocument($values: DocumentCreateInput!) {
    createDocument(values: $values) {
      id
    }
  }
`;

export const UPDATE_DOCUMENT = gql`
  mutation UpdateDocument($documentId: String!, $values: DocumentUpdateInput!) {
    updateDocument(documentId: $documentId, values: $values) {
      ...DocumentDetail
    }
  }

  ${DOCUMENT_DETAIL}
`;

export const COPY_DOCUMENT = gql`
  mutation CopyDocument(
    $values: CopyDocumentInput!
    $origin: DocumentOrigin!
    $documentId: String!
  ) {
    copyDocument(values: $values, origin: $origin, documentId: $documentId) {
      ...DocumentDetail
    }
  }

  ${DOCUMENT_DETAIL}
`;

export const REPLACE_DOCUMENT_AND_VERSION = gql`
  mutation ReplaceDocumentAndVersion(
    $documentIdToUpdate: String!
    $documentIdToCopy: String!
    $entityId: String
  ) {
    replaceDocument: replaceDocumentAndVersion(
      documentIdToUpdate: $documentIdToUpdate
      documentIdToCopy: $documentIdToCopy
      entityId: $entityId
    ) {
      ...DocumentDetail
    }
  }
  ${DOCUMENT_DETAIL}
`;

export const DELETE_DOCUMENTS = gql`
  mutation DeleteDocuments($documentIds: [String!]!) {
    deleteDocuments(ids: $documentIds) {
      ids
      isSuccess
    }
  }
`;

export const UPDATE_DOCUMENT_THUMBNAIL = gql`
  mutation UpdateDocumentThumbnail(
    $thumbnailId: String!
    $documentId: String!
  ) {
    updateDocumentThumbnail(
      thumbnailId: $thumbnailId
      documentId: $documentId
    ) {
      id
    }
  }
`;

export const DOCUMENT_HISTORY = gql`
  query DocumentHistory(
    $pagination: PaginationInput
    $filter: DocumentHistoryFilterInput
  ) {
    documentHistory(pagination: $pagination, filter: $filter) {
      documents {
        document {
          ...DocumentDetail
          parent {
            ...DocumentDetail
          }
          campaign {
            campaignType
          }
        }
        date
      }
      totalItems
    }
  }
  ${DOCUMENT_DETAIL}
`;

export const STORE_RESOURCES = gql`
  mutation StoreResources($documentIds: [String!]!, $entityId: String!) {
    storeResources(documentIds: $documentIds, entityId: $entityId) {
      resources {
        resourceId
        mimetype
        resourceIndex
      }
      documentId
    }
  }
`;
