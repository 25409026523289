import moment from 'moment';
import React from 'react';
import {
  Toolbar,
  Box,
  Typography,
  Grid,
  Button,
  CircularProgress,
  Backdrop,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import colors from 'styles/colors.module.scss';
import Loader from 'components/Loader/Loader/Loader';
import HistoryIcon from '@material-ui/icons/History';
import { useGetInformationDocument } from 'modules/workflow/services/useGetInformationDocument';
import { DocumentOrigin } from 'app/schemaInterfaces/globalTypes';
import VersionSwitch from 'modules/workflow/components/VersionSwitch';
import ValidateVersion from './ValidateVersion';

const textFieldStyle = {
  '& .MuiOutlinedInput-input': {
    fontFamily: 'ScandiaWebMedium',
    fontWeight: 500,
    fontSize: '12px',
    color: colors.grey,
    '&.MuiInputBase-input.MuiSelect-outlined.MuiSelect-outlined': {
      paddingRight: '34px',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiInputBase-input.MuiSelect-outlined.MuiSelect-outlined': {
    padding: 0,
  },
};

const textStyle = {
  color: colors.grey,
};

export interface UrlParams {
  documentId: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: colors.white,
    },
  })
);
interface FirstToolbarProps {
  onFinalSave: () => Promise<void>;
  isFinalSaving?: boolean;
  autoSaveInfo: AutoSaveInfo;
  indeterminatePageCount?: boolean;
  disabled?: boolean;
}

export interface AutoSaveInfo {
  isSaving: boolean;
  lastSaved?: Date | null;
}

const FirstToolbar = React.forwardRef<HTMLElement, FirstToolbarProps>(
  (
    {
      onFinalSave,
      isFinalSaving = false,
      autoSaveInfo,
      indeterminatePageCount,
      disabled = false,
    }: FirstToolbarProps,
    ref
  ): JSX.Element => {
    const { t } = useTranslation();
    const {
      error: documentError,
      documentData,
      globalState,
    } = useGetInformationDocument();
    const isEditable = globalState.isEditable;

    const classes = useStyles();

    const status = autoSaveInfo.lastSaved
      ? t('workflow.toolbar.last_updated_time', {
          time: moment().diff(autoSaveInfo.lastSaved),
        })
      : undefined;

    const name = documentData?.document.name;

    const isDocumentSource =
      documentData?.document.origin === DocumentOrigin.Source;

    if (documentError) return <Loader error={documentError} />;

    return (
      <Toolbar variant="dense">
        <Backdrop className={classes.backdrop} open={isFinalSaving}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box justifyContent="space-between" width="100%" display="flex">
          <Box
            display="flex"
            py={2}
            pl="20px"
            alignItems="center"
            style={{ color: colors.toolBarBackGround }}
          >
            <Typography variant="h4">
              {isDocumentSource ? name : documentData?.document.parent?.name}
            </Typography>
            <Box pl="20px">
              <VersionSwitch
                ref={ref}
                textFieldStyle={textFieldStyle}
                variant="h6"
                textStyle={textStyle}
              />
            </Box>
          </Box>

          {isEditable && (
            <Box display="flex" alignItems="center">
              {status && (
                <Box bgcolor={colors.workzone} py={1} px={2} borderRadius="6px">
                  <Grid
                    container
                    item
                    direction="row"
                    alignItems="center"
                    style={{ color: colors.grey }}
                  >
                    {autoSaveInfo.isSaving ? (
                      <CircularProgress size={12} />
                    ) : (
                      <HistoryIcon fontSize="inherit" />
                    )}

                    <Typography variant="caption" style={{ marginLeft: '9px' }}>
                      {status}
                    </Typography>
                  </Grid>
                </Box>
              )}
              <Box pl={2} px={2}>
                <Button
                  color="primary"
                  onClick={onFinalSave}
                  disabled={disabled}
                >
                  <Typography variant="h5">
                    {t('common.button.save')}
                  </Typography>
                </Button>
              </Box>
              <ValidateVersion
                onFinalSave={onFinalSave}
                indeterminatePageCount={indeterminatePageCount}
                disabled={disabled}
              />
            </Box>
          )}
        </Box>
      </Toolbar>
    );
  }
);

export default FirstToolbar;
