import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const basketPreviewItemStyle = css`
  display: flex;
  flex-direction: row;

  padding-top: 16px;
  border-top: 1px solid ${colors.lightGrey};
  margin-top: 16px;

  &:first-child {
    padding-top: 0;
    border-top: none;
    margin-top: 0;
  }

  img {
    width: 67px;
    height: 67px;
    border-radius: 5px;
  }
`;

export const basketPreviewItemContentStyle = css`
  flex: 1;
  margin-left: 16px;

  div {
    margin-top: 4px;

    &:first-child {
      margin-top: 0;
    }
  }
`;

export const basketPreviewItemQuantityStyle = css`
  color: ${colors.grey};
`;
