import { useQuery } from '@apollo/client';
import { GET_ORDERS_DELIVERIES } from 'app/graphql/queries/orders';
import {
  GetOrderDeliveries,
  GetOrderDeliveriesVariables,
} from 'app/schemaInterfaces/GetOrderDeliveries';

const useOrderDeliveriesQuery = (id?: string) => {
  const { data, loading, error, refetch } = useQuery<
    GetOrderDeliveries,
    GetOrderDeliveriesVariables
  >(GET_ORDERS_DELIVERIES, {
    fetchPolicy: 'no-cache',
    variables: {
      id,
    },
  });

  return { data: data?.orderDeliveries, loading, error, refetch };
};

export default useOrderDeliveriesQuery;
