import { CustomTimelineItemBase } from 'components/ReversePlanning/ReversePlanning';
import { GetCurrentUser_user } from 'app/schemaInterfaces/GetCurrentUser';
import { GetEntities } from 'app/schemaInterfaces/GetEntities';
import { ItemContext } from 'react-calendar-timeline';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { UserType } from 'app/schemaInterfaces/globalTypes';
import colors from 'styles/colors.module.scss';
import { getNationalCampaignLink } from 'modules/shop/shopHelpers';
import moment from 'moment';
import {
  GetPlanningCampaigns_getPlanningCampaigns,
  GetPlanningCampaigns_getPlanningCampaigns_entities,
} from 'app/schemaInterfaces/GetPlanningCampaigns';

export const getCampaignsEventsEntities = (
  eventsFromQuery: GetPlanningCampaigns_getPlanningCampaigns,
  entities: GetEntities | undefined
) => {
  const getCampaignParentsEntities = eventsFromQuery?.entities.flatMap(
    (entity: GetPlanningCampaigns_getPlanningCampaigns_entities) =>
      entity.ancestors.length > 0 ? entity.ancestors : entity
  );
  return entities?.entities.filter((entity) => {
    return getCampaignParentsEntities.find((campaignEntity) => {
      return campaignEntity.id === entity.id;
    });
  });
};

export const getItemsBackgroundColor = (itemContext: ItemContext) =>
  itemContext.selected ? colors.white : undefined;

export const itemTooltipTitle = (
  item: CustomTimelineItemBase<MaterialUiPickersDate>,
  errorMessage: string
) => (moment().isBefore(item.commitmentDateFrom) ? errorMessage : '');

export const redirectToCampaignByUserType = (
  campaign: GetPlanningCampaigns_getPlanningCampaigns,
  user: GetCurrentUser_user
) => {
  if (user.type === UserType.Local) {
    return getNationalCampaignLink(campaign.id);
  } else {
    return `/campaign/${campaign.id}`;
  }
};
