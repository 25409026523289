import { useMutation } from '@apollo/client';
import usePermissions from 'app/auth/usePermissions';
import useCurrentEntity from 'app/current-entity-context/useCurrentEntity';
import { DOWNLOAD_PDF } from 'app/graphql/queries/pdf';
import { DOWNLOAD_JPG } from 'app/graphql/queries/version';
import {
  DownloadJpg,
  DownloadJpgVariables,
} from 'app/schemaInterfaces/DownloadJpg';
import {
  DownloadPdf,
  DownloadPdfVariables,
} from 'app/schemaInterfaces/DownloadPdf';
import { PdfDefinition } from 'app/schemaInterfaces/globalTypes';
import { useDownloadFileErrorWorkflow } from 'app/utils/customHooks/useDownloadFileErrorWorkflow';
import { useStudioStateContext } from 'modules/workflow/oneStudioContext';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { pdfActions } from './pdfHelper';

export interface UsePdfDownloadOptions {
  saveCatalog: (hasToRedirect: boolean) => Promise<void>;
}

export function usePdfDownload({ saveCatalog }: UsePdfDownloadOptions) {
  const globalState = useStudioStateContext();
  const { currentEntity } = useCurrentEntity();
  const downloadFile = useDownloadFileErrorWorkflow();
  const { t } = useTranslation();
  const [downloadPDF] = useMutation<DownloadPdf, DownloadPdfVariables>(
    DOWNLOAD_PDF
  );
  const [downloadJpg] = useMutation<DownloadJpg, DownloadJpgVariables>(
    DOWNLOAD_JPG
  );
  const { hasPermission } = usePermissions();
  const [isPdfDownloading, setIsPdfDownloading] = useState<boolean>(false);

  const downloadPdf = async (pdfDefinition: PdfDefinition) => {
    setIsPdfDownloading(true);
    await saveCatalog(false);
    const { data } = await downloadPDF({
      variables: {
        versionId: globalState.version?.id ?? '',
        entityId: currentEntity?.id ?? null,
        pdfDefinition: pdfDefinition,
      },
    });
    if (!data) throw new Error();

    downloadFile({
      url: data.downloadPdf.url,
      filename: data.downloadPdf.name,
      mimetype: data.downloadPdf.mimetype,
    }).finally(() => setIsPdfDownloading(false));
  };

  const downloadPdfHd = () => downloadPdf(PdfDefinition.HD);
  const downloadPdfLd = () => downloadPdf(PdfDefinition.LD);

  const downloadJpgs = async () => {
    setIsPdfDownloading(true);
    await saveCatalog(false);
    const { data } = await downloadJpg({
      variables: {
        versionId: globalState.version.id,
        entityId: currentEntity?.id as string,
      },
    });

    if (data?.downloadJpg) {
      downloadFile({
        url: data.downloadJpg.url,
        filename: data.downloadJpg.name,
        mimetype: data.downloadJpg.mimetype,
      }).finally(() => setIsPdfDownloading(false));
    }
  };

  const pdfOptions =
    globalState.isDigital || !currentEntity
      ? [{ onClick: downloadJpgs }]
      : pdfActions(downloadPdfHd, downloadPdfLd, t, hasPermission);

  return {
    pdfOptions,
    isPdfDownloading,
  };
}
