import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import {
  useStudioDispatchContext,
  useStudioStateContext,
  ZoneProps,
} from 'modules/workflow/oneStudioContext';
import useAppContext from 'app/app-context/useAppContext';
import { dropHandler } from 'modules/workflow/containers/CatalogPreview/onDropHandler';
import ZoneTemplate from 'modules/workflow/containers/CatalogPreview/ZoneTemplate';
import colors from 'styles/colors.module.scss';
import { useMutation, useApolloClient } from '@apollo/client';
import {
  CopyProducts,
  CopyProductsVariables,
} from 'app/schemaInterfaces/CopyProducts';
import {
  COPY_PRODUCTS,
  GET_PRODUCTS_DIVISIONS,
} from 'app/graphql/queries/products';
import { IProductTemplate } from 'modules/workflow/Clients/ToutFaire/ProductItem';
import PersonalizationItems from 'modules/workflow/containers/CatalogPreview/Personalization/PersonalizationItems';
import { createNewProductfromPreviousOne } from 'modules/workflow/containers/Leftsidebar/ProductsPanel/helpers/visualIdentitiesHelper';
import { productSize } from './CatalogPreviewHelpers';
import { useCurrentUser } from 'app/auth/UserContext';

export interface ZoneContentProps {
  zoneIndex: number;
  pageNumber: number;
  zoomScale: number;
}

const ZoneContent = ({
  zoneIndex,
  pageNumber,
  zoomScale,
}: ZoneContentProps): JSX.Element => {
  const dispatch = useStudioDispatchContext();
  const user = useCurrentUser();

  const globalState = useStudioStateContext();

  const [copyProducts] = useMutation<CopyProducts, CopyProductsVariables>(
    COPY_PRODUCTS,
    {
      refetchQueries: [
        {
          query: GET_PRODUCTS_DIVISIONS,
          variables: {
            // TODO: refetch la liste des produits créés pour l'user (pour pouvoir griser ensuite)
            campaignId: globalState.version.campaignId,
            pagination: {
              offset: null,
              limit: null,
            },
          },
        },
      ],
    }
  );

  const {
    client: { config: clientConfig },
  } = useAppContext();

  const client = useApolloClient();

  const getProduct = async (): Promise<
    Omit<IProductTemplate, 'components' | 'position'>
  > => {
    const product = globalState.draggedItem?.item as IProductTemplate;
    const isCustomization = globalState.document?.isCustomization;

    // If Product exists in campaign we set it as itemToAdd, else if Product doesnt exist in campaign, we create and retrieve the new Campaign Product
    if (
      (isCustomization && !product.documentId) ||
      (!isCustomization && !product.campaignId)
    ) {
      const importedProduct = await copyProducts({
        variables: {
          ...(isCustomization
            ? { documentId: globalState?.document?.id }
            : { campaignId: globalState?.version?.campaignId }),
          productIds: [product.id],
        },
      });

      if (importedProduct.data?.copyProducts) {
        const newProduct = importedProduct.data?.copyProducts[0];
        const productToAdd = (await createNewProductfromPreviousOne({
          data: newProduct,
          client,
          clientConfig,
          globalState,
        })) as IProductTemplate;

        return productToAdd;
      } else {
        return product;
      }
    } else {
      return product;
    }
  };

  const [isPersonalizationDropZone, setIsPersonalizationDropZone] = useState<
    boolean
  >(false);

  const [zoneData, setZoneData] = useState<ZoneProps>(
    globalState.version.pages[pageNumber - 1].pageTemplate[zoneIndex]
  );

  const [bgImageURL, setBgImageURL] = useState<string | undefined>(undefined);

  useEffect(() => {
    setZoneData(
      globalState.version.pages[pageNumber - 1].pageTemplate[zoneIndex]
    );
    setBgImageURL(
      globalState.version.pages[pageNumber - 1].pageTemplate[zoneIndex]
        .background?.image ?? ''
    );
  }, [globalState.version.pages, pageNumber, zoneIndex]);

  const supportConfig = clientConfig?.workflow.supportConfig
    ? clientConfig?.workflow.supportConfig.find(
        ({ supportId }) => supportId === globalState.document?.supportId
      )
    : null;

  return (
    <Box
      className={'boundZone-' + (pageNumber - 1) + '-' + zoneIndex}
      onDragOver={(e) => e.preventDefault()} //this line is required to let onDrop event to work 'issue in onDrop event'
      onDrop={(event) => {
        !isPersonalizationDropZone &&
          dropHandler(
            pageNumber - 1,
            zoneIndex,
            dispatch,
            globalState,
            event,
            zoomScale,
            getProduct,
            productSize(supportConfig),
            user.type
          );
      }}
      key={zoneIndex}
      position="absolute"
      left={`${zoneData.x}%`}
      top={`${zoneData.y}%`}
      width={`${zoneData.width}%`}
      height={`${zoneData.height}%`}
      style={{
        backgroundImage: `url(${bgImageURL})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      }}
      //boxshadow because border take space from content, or boxshadow is outside the box
      boxShadow={
        globalState.selectedZone === zoneIndex.toString()
          ? `0 0 0 2px ${colors.primary}`
          : `0 0 0 1px ${colors.zoneBorder}`
      }
    >
      {zoneData.personalizationItems && (
        <PersonalizationItems
          personalizationItems={zoneData.personalizationItems}
          pageNumber={pageNumber}
          zoneIndex={zoneIndex}
          zoomScale={zoomScale}
          setIsPersonalizationDropZone={setIsPersonalizationDropZone}
          isPersonalizationDropZone={isPersonalizationDropZone}
          getProduct={getProduct}
        />
      )}
      <ZoneTemplate
        zoomScale={zoomScale}
        zoneIndex={zoneIndex}
        pageNumber={pageNumber}
      />
    </Box>
  );
};

export default ZoneContent;
