import {
  BigShopConfigCategory,
  FollowOrderStatus,
  UserType,
} from 'app/schemaInterfaces/globalTypes';
import { TFunction } from 'i18next';
import colors from 'styles/colors.module.scss';
import { CommitmentStatus } from './OrderFollowStatusTag.definition';

export const hasOnlyDigitalDocuments = (
  documentsCategories?: BigShopConfigCategory[]
) =>
  !!documentsCategories?.every(
    (category) => category === BigShopConfigCategory.Digital
  );

export const hasBothDocumentTypes = (
  documentsCategories?: BigShopConfigCategory[]
) =>
  !hasOnlyDigitalDocuments(documentsCategories) &&
  Boolean(
    documentsCategories?.some(
      (category) => category === BigShopConfigCategory.Digital
    )
  );

export const getOrderFollowStatus = ({
  hasOnlyDigitalDocuments,
  hasBothDocumentTypes,
  orderStatus,
  userType,
  t,
}: {
  hasOnlyDigitalDocuments: boolean;
  hasBothDocumentTypes: boolean;
  orderStatus: FollowOrderStatus | CommitmentStatus | null;
  userType: UserType;
  t: TFunction;
}) => {
  switch (orderStatus) {
    case FollowOrderStatus.WaitingValidation:
      return {
        tagColor: colors.orange300,
        tagBgColor: colors.orange300Opacity,
        title:
          userType === UserType.Global
            ? t('follow_orders.to_validate')
            : t('follow_orders.waiting_validation'),
      };
    case FollowOrderStatus.Validated:
      return {
        tagColor: colors.success,
        tagBgColor: colors.acidGreenOpacity,
        title: t('follow_orders.validated'),
      };
    case FollowOrderStatus.Refused:
      return {
        tagColor: colors.orangeAcid,
        tagBgColor: colors.orangeAcidOpacity,
        title: t('follow_orders.refused'),
      };

    case FollowOrderStatus.ProductionInProgress:
      return {
        tagColor: colors.alertBlue,
        tagBgColor: colors.alertBlueOpacity,
        title: t('follow_orders.production_in_progress'),
      };
    case FollowOrderStatus.DeliveryInProgress:
      return {
        tagColor: colors.blueGrey,
        tagBgColor: colors.blueGreyOpacity,
        title: hasOnlyDigitalDocuments
          ? t('follow_orders.delivery_in_progress_digital')
          : hasBothDocumentTypes
          ? t('follow_orders.delivery_in_progress')
          : t('follow_orders.delivery_in_progress_print'),
      };

    case FollowOrderStatus.Delivered:
      return {
        tagColor: colors.loader6,
        tagBgColor: colors.weirdGreenOpacity,
        title: hasOnlyDigitalDocuments
          ? t('follow_orders.delivered_digital')
          : hasBothDocumentTypes
          ? t('follow_orders.delivered')
          : t('follow_orders.delivered_print'),
      };
    case FollowOrderStatus.Billed:
      return {
        tagColor: colors.ocre,
        tagBgColor: colors.ocreOpacity,
        title: t('follow_orders.billed'),
      };

    default:
      break;
  }
};
