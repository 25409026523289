import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  CreateProductSheet,
  CreateProductSheetVariables,
} from 'app/schemaInterfaces/CreateProductSheet';
import {
  CREATE_PRODUCT_SHEET,
  GET_PRODUCTS,
  UPDATE_PRODUCT_SHEET,
} from 'app/graphql/queries/products';

import ProductsSheetForm from 'modules/pim/baseProducts/ProductsSheetForm';
import {
  UpdateProduct,
  UpdateProductVariables,
  UpdateProduct_updateProduct,
} from 'app/schemaInterfaces/UpdateProduct';
import { useProductsSheetsContext } from 'modules/pim/baseProducts/BaseProducts';
import { ProductHistoryInput } from 'app/schemaInterfaces/globalTypes';
import {
  getFormDifference,
  VisuelFieldName,
} from 'modules/pim/baseProducts/ProductsHelper';
import { IProductTemplate } from 'modules/workflow/Clients/ToutFaire/ProductItem';

interface ProductsSheetFormCreationProps {
  handleClose: () => void;
  productData?: Record<string, any>;
  campaignId?: string;
  productId?: string;
  setIsUpdatedProductDetails?: React.Dispatch<
    React.SetStateAction<UpdateProduct_updateProduct | undefined>
  >;
  worflowProduct?: IProductTemplate;
}

const ProductsSheetFormCreation = ({
  handleClose,
  productData,
  campaignId,
  productId,
  setIsUpdatedProductDetails,
  worflowProduct,
}: ProductsSheetFormCreationProps): JSX.Element => {
  const [isUploadInProgress, setIsUploadInProgress] = useState<boolean>(false);
  const [isParentProductUpdated, setIsParentProductUpdated] = useState<boolean>(
    false
  );

  const handleChangeUpdateParentProduct = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsParentProductUpdated(event.target.checked);
  };

  const {
    filter,
    regrouping,
    formFilesManager,
    setRegrouping,
  } = useProductsSheetsContext();

  const [createProductSheet] = useMutation<
    CreateProductSheet,
    CreateProductSheetVariables
  >(CREATE_PRODUCT_SHEET, {
    onCompleted() {
      handleClose();
    },
    refetchQueries: [
      {
        query: GET_PRODUCTS,
        variables: {
          filter,
          pagination: { offset: 0, limit: 10 },
          isSortByDate: true,
        },
      },
    ],
  });

  const [createCampaignProductSheet] = useMutation<
    CreateProductSheet,
    CreateProductSheetVariables
  >(CREATE_PRODUCT_SHEET, {
    onCompleted() {
      handleClose();
    },
    refetchQueries: [
      {
        query: GET_PRODUCTS,
        variables: {
          campaignId: campaignId,
          filter,
          pagination: { offset: 0, limit: 10 },
          isSortByDate: true,
        },
      },
    ],
  });

  const [updateProductSheet] = useMutation<
    UpdateProduct,
    UpdateProductVariables
  >(UPDATE_PRODUCT_SHEET, {
    onCompleted() {
      handleClose();
    },
  });

  const newRegrouping = regrouping.map((oldRegrouping) => ({
    ...oldRegrouping,
    assetId: formFilesManager.find(
      (file) =>
        file.fieldName ===
        `${VisuelFieldName.mainVisuel}-${oldRegrouping.productId}`
    )?.assetId as string,
  }));

  const creationMode = async (values: Record<string, any>) => {
    if (!Boolean(campaignId)) {
      await createProductSheet({
        variables: {
          details: values,
          regrouping: newRegrouping,
        },
      });
    } else {
      const createCampaignProduct = await createCampaignProductSheet({
        variables: {
          campaignId: campaignId as string,
          details: values,
          regrouping: newRegrouping,
        },
      });
      if (
        isParentProductUpdated &&
        createCampaignProduct.data?.createProduct._id
      ) {
        const createdProduct = await createProductSheet({
          variables: {
            details: values,
            regrouping: newRegrouping,
          },
        });
        if (createdProduct.data?.createProduct._id) {
          await updateProductSheet({
            variables: {
              details: values,
              productId: createCampaignProduct.data?.createProduct._id,
              rootProductId: createdProduct.data?.createProduct._id,
              history: [],
              regrouping: newRegrouping,
            },
          });
        }
      }
    }
  };

  const createProductIfNotExistAfterUpdateCampaignProductSuccess = async (
    values: Record<string, any>,
    productId: string
  ) => {
    if (isParentProductUpdated) {
      const createProduct = await createProductSheet({
        variables: {
          details: values,
        },
      });
      await updateProductSheet({
        variables: {
          details: values,
          productId,
          rootProductId: createProduct.data?.createProduct._id,
          history: [],
          regrouping: newRegrouping,
        },
      });
    }
  };

  const updateCampaignProductOrCreateItIfNotExist = async (
    values: Record<string, any>,
    productsHistory: ProductHistoryInput[]
  ) => {
    const updatedCampaignProduct = await updateProductSheet({
      variables: {
        details: values,
        productId: productId as string,
        history: productsHistory,
        regrouping: newRegrouping,
      },
    });
    if (
      isParentProductUpdated &&
      updatedCampaignProduct.data?.updateProduct.rootProductId
    ) {
      updateProductSheet({
        variables: {
          details: values,
          productId: updatedCampaignProduct.data?.updateProduct.rootProductId,
          history: productsHistory,
          regrouping: newRegrouping,
        },
      });
    } else {
      createProductIfNotExistAfterUpdateCampaignProductSuccess(
        values,
        updatedCampaignProduct.data?.updateProduct._id as string
      );
    }
    if (worflowProduct && updatedCampaignProduct?.data?.updateProduct) {
      setIsUpdatedProductDetails?.(updatedCampaignProduct?.data?.updateProduct);
    }
  };

  const editMode = async (values: Record<string, any>) => {
    let productsHistory: ProductHistoryInput[] = [];
    getFormDifference(
      productsHistory,
      productData as Record<string, any>,
      values
    );

    if (!campaignId) {
      await updateProductSheet({
        variables: {
          details: values,
          productId: productId as string,
          history: productsHistory,
          regrouping: newRegrouping,
        },
      });
    } else {
      await updateCampaignProductOrCreateItIfNotExist(values, productsHistory);
    }
  };

  const onFormValidation = async (values: Record<string, any>) => {
    try {
      setIsUploadInProgress(true);
      !Boolean(productData)
        ? await creationMode(values)
        : await editMode(values);
      setIsUpdatedProductDetails?.(undefined);
      setIsUploadInProgress(false);
      setRegrouping([]);
    } catch (e) {
      setIsUploadInProgress(false);
      console.log(e);
    }
  };

  return (
    <ProductsSheetForm
      onFormValidation={onFormValidation}
      campaignId={campaignId}
      productId={productId}
      isParentProductUpdated={isParentProductUpdated}
      handleChangeUpdateParentProduct={handleChangeUpdateParentProduct}
      handleClose={handleClose}
      setUploadInProgress={setIsUploadInProgress}
      productData={productData}
      isUploadInProgress={isUploadInProgress}
    />
  );
};

export default ProductsSheetFormCreation;
