import { ApolloQueryResult } from '@apollo/client';
import { useAuth0 } from 'app/auth/AuthContext';
import { queryUrlForUpload } from 'modules/pim/baseProducts/ProductsHelper';
import { useState } from 'react';
/*
	in this FileUploadRestApi function
	i use the mutation as a Api rest, the graphql method
	is not working because of asura,
	it's just a workaround till we fix the asura issue
*/
const FileUploadRestApi = (
  query: string,
  variables: Record<string, any>,
  refetch: (variables?: Record<string, any>) => Promise<ApolloQueryResult<any>>
) => {
  const [isUploadInProgress, setIsUploadInProgress] = useState<boolean>(false);
  const { accessToken } = useAuth0();
  const uploadFile = async (acceptedFiles: File[]) => {
    if (!queryUrlForUpload) return null;
    setIsUploadInProgress(true);
    const body = new FormData();
    const file = acceptedFiles[0];
    const operation = {
      query,
      variables,
    };
    const map = {
      '0': ['variables.file'],
    };
    body.append('operations', JSON.stringify(operation));
    body.append('map', JSON.stringify(map));
    body.append('0', file);
    const queryOptions = {
      method: 'POST',
      body,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    try {
      const data = await fetch(queryUrlForUpload, queryOptions);
      if (data) {
        /*
				i refetch the query to get the products
				i can't use the write query from apollo
				because i use the mutation as a api rest
				*/
        refetch();
        setIsUploadInProgress(false);
        return data;
      }
    } catch (err) {
      console.log(err);
      return null;
    }
  };
  return { isUploadInProgress, setIsUploadInProgress, uploadFile };
};
export default FileUploadRestApi;
