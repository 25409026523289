import { css, CSSObject } from '@emotion/react';

import colors from 'styles/colors.module.scss';

export const cardListStyle = (
  isBackgroundDark: boolean,
  badgeStyle: CSSObject,
  cardStyle: CSSObject
) => css`
  width: 100%;

  .MuiButtonBase-root {
    border-radius: 4px;
    &:hover {
      background-color: ${isBackgroundDark ? colors.grey : colors.workzone};
    }
    padding: 9px;
  }

  .MuiListItemText-root {
    width: 130px;
    overflow: hidden;
    padding-left: 8px;
    text-overflow: ellipsis;
    color: ${colors.orange100};
  }
  .disabledItemStyle {
    filter: contrast(0.3);
    pointer-events: none;
  }

  .MuiCardMedia-media {
    width: 60px;
    height: 60px;
    object-fit: contain;
    border-radius: 0;
    ${cardStyle}
  }

  ${badgeStyle}
`;
