import { downloadFile } from '../FileDownload/FileDownload';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export interface UseDownloadFileParam {
  progressCallback?: (percentage: number) => void;
  useS3CorsWorkaround?: boolean;
}

export interface UseDownloadFileFuncParam {
  url: string;
  mimetype: string;
  filename: string;
}

export const useDownloadFileErrorWorkflow = (params?: UseDownloadFileParam) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  return ({ url, filename, mimetype }: UseDownloadFileFuncParam) =>
    downloadFile({
      url,
      filename,
      mimetype,
      progressCallback: params?.progressCallback,
      useS3CorsWorkaround: params?.useS3CorsWorkaround,
    })
      .then(() => {
        enqueueSnackbar(t('common.actions.download_success'), {
          variant: 'success',
        });
      })
      .catch(() => {
        enqueueSnackbar(t('common.actions.download_error'), {
          variant: 'error',
        });
      });
};
