import { Box, TextField, Typography } from '@material-ui/core';
import { AddressBlocks_addressBlocks } from 'app/schemaInterfaces/AddressBlocks';
import { TableColumns } from 'components/AppTable/AppTable';
import { TFunction } from 'i18next';
import React from 'react';
import { Dispatch } from 'react';
import { CellProps } from 'react-table';
import { AddressBlockFormValues } from './AddressBlockContainer/AddressBlockContainer';
import { actionEditButtons, actionFormButtons } from './AddressBlockHelper';
import colors from 'styles/colors.module.scss';
import ActionButtons from 'components/ActionsButtons/ActionButtons';
import UncontrolledSelect from 'components/UncontrolledSelect';
import { UncontrolledMultipleValueSelectV3 } from 'components/UncontrolledMultipleArrayValueSelect';
import { Option } from 'components/UncontrolledMultipleValueSelectV2';
import { OptionV3 } from 'components/UncontrolledMultipleArrayValueSelect';
import { AddressBlockDetail_supplyProducts } from 'app/schemaInterfaces/AddressBlockDetail';
import { theme } from 'styles/theme';

enum AddressBlockAccessor {
  supplyProducts = 'supplyProducts',
  support = 'support',
  id = 'id',
  name = 'name',
  asset = 'asset',
  status = 'status',
}

const displayProducts = (products: AddressBlockDetail_supplyProducts[]) => {
  let labels: string[] = [];
  products &&
    products.forEach((product) =>
      product.longueurFormatPageCm && product.largeurFormatPageCm
        ? labels.push(
            `${product.largeurFormatPageCm} x ${product.longueurFormatPageCm} cm`
          )
        : labels.push(
            `${product.largeurFormatFiniCm} x ${product.longueurFormatFiniCm} cm`
          )
    );
  labels = labels.filter((label, index) => labels.indexOf(label) === index);
  return labels.join(', ');
};

export const AddressBlockColumns = (
  deleteItem: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: AddressBlocks_addressBlocks
  ) => void,
  editMode: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: AddressBlocks_addressBlocks
  ) => void,
  clickRowIndex: number | undefined,
  setClickRowIndex: Dispatch<React.SetStateAction<number | undefined>>,
  isEditModeActive: boolean,
  formValues: AddressBlockFormValues,
  setFormValues: React.Dispatch<React.SetStateAction<AddressBlockFormValues>>,
  submitForm: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: AddressBlocks_addressBlocks
  ) => void,
  clearChanges: () => void,
  t: TFunction,
  overRowIndex: number | null,
  supportIds: Option[],
  formats: OptionV3[]
): TableColumns<AddressBlocks_addressBlocks>[] => [
  {
    Header: t(
      'one_manager.store_information.address_block_page.visual'
    ) as string,
    accessor: AddressBlockAccessor.asset,
    sticky: 'left',
    minWidth: 240,
    Cell: ({ cell }: CellProps<AddressBlocks_addressBlocks, string[]>) => (
      <img
        src={cell.row.values.asset.thumbnailUrl}
        alt="thumbnailUrl"
        height="72px"
        width="240px"
        style={{
          border: `1px solid ${colors.lightGrey}`,
          objectFit: 'cover',
        }}
      />
    ),
  },
  {
    Header: t(
      'one_manager.store_information.address_block_page.address_block_name'
    ) as string,
    accessor: AddressBlockAccessor.name,
    sticky: 'left',
    Cell: ({ cell }: CellProps<AddressBlocks_addressBlocks, string[]>) => {
      if (cell.row.index === clickRowIndex && isEditModeActive) {
        return (
          <TextField
            error={!formValues.name}
            placeholder={formValues.name}
            name="name"
            variant="standard"
            value={formValues.name}
            onChange={(e) =>
              setFormValues({ ...formValues, name: e.target.value })
            }
            autoFocus
            helperText={
              !formValues.name &&
              t(
                'one_manager.store_information.address_block_page.name_required'
              )
            }
            fullWidth
            className="name-field"
          />
        );
      }
      return <Typography variant="body1">{cell.row.values.name}</Typography>;
    },
  },
  {
    Header: t(
      'one_manager.store_information.address_block_page.medium_type'
    ) as string,
    accessor: AddressBlockAccessor.support,
    sticky: 'left',
    Cell: ({ cell }: CellProps<AddressBlocks_addressBlocks, string[]>) =>
      cell.row.index === clickRowIndex && isEditModeActive ? (
        <UncontrolledSelect
          value={formValues.supportId}
          options={supportIds}
          onChange={({ target: { value } }) =>
            setFormValues({
              ...formValues,
              supportId: value,
              supplyProductIds: [],
            })
          }
          fullWidth
          variant="standard"
          labelText={t('common.actions.select')}
          textFieldStyle={{
            marginTop: '15px',
            marginBottom: '9px',
            height: '40px',
          }}
          typographyProps={{
            fontFamily: theme.typography.body1.fontFamily,
            fontSize: theme.typography.body1.fontSize,
            lineHeight: theme.typography.body1.lineHeight,
          }}
          className="select"
        />
      ) : (
        <Box
          display="inline-block"
          alignItems="center"
          whiteSpace="nowrap"
          overflow="hidden"
        >
          <Typography variant="body1">
            {cell.row.values.support ? cell.row.values.support.name : ''}
          </Typography>
        </Box>
      ),
  },
  {
    Header: t(
      'one_manager.store_information.address_block_page.format'
    ) as string,
    accessor: AddressBlockAccessor.supplyProducts,
    sticky: 'left',
    Cell: ({ cell }: CellProps<AddressBlocks_addressBlocks, string[]>) =>
      cell.row.index === clickRowIndex && isEditModeActive ? (
        <UncontrolledMultipleValueSelectV3
          options={formats}
          values={formValues.supplyProductIds}
          handleChange={(supplyProductIds) => {
            setFormValues({
              ...formValues,
              supplyProductIds: supplyProductIds,
            });
          }}
          placeholder={t('common.actions.select')}
          isFullWidth={true}
        />
      ) : (
        <Typography variant="body1">
          {cell.row.values.supplyProducts
            ? displayProducts(cell.row.values.supplyProducts)
            : ''}
        </Typography>
      ),
  },
  // Temporary removed
  // {
  //   Header: t(
  //     'one_manager.store_information.address_block_page.validity'
  //   ) as string,
  //   accessor: AddressBlockAccessor.status,
  //   sticky: 'left',
  //   Cell: ({ cell }: CellProps<AddressBlocks_addressBlocks, string[]>) => {
  //     const status = validationTag(t, cell.row.values.status);
  //     return <Status title={status.title} backgroundColor={status.color} />;
  //   },
  // },
  {
    Header: '',
    accessor: AddressBlockAccessor.id,
    sticky: 'right',
    maxWidth: 90,
    Cell: ({ cell }: CellProps<AddressBlocks_addressBlocks, string[]>) => (
      <ActionButtons
        item={cell.row.values as AddressBlocks_addressBlocks}
        actionButtons={
          overRowIndex === cell.row.index && !isEditModeActive
            ? actionEditButtons<AddressBlocks_addressBlocks>(
                deleteItem,
                editMode,
                t
              )
            : isEditModeActive && clickRowIndex === cell.row.index
            ? actionFormButtons<AddressBlocks_addressBlocks>(
                submitForm,
                clearChanges,
                t
              )
            : undefined
        }
        itemIndex={cell.row.index}
        setClickItemIndex={setClickRowIndex}
      />
    ),
  },
];
