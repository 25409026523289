import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';
import { theme } from 'styles/theme';

export const addressBlockContainerSyle = css`
  margin-top: 20px;
  margin-left: 60px;
  margin-right: 60px;
  border-radius: 10px;
  h1 {
    color: ${colors.body};
    margin-left: 5px;
  }
  #mutiple-checkbox {
    height: 30px;
    padding: 6px 24px 6px 0px;
    display: flex;
    align-items: center;
  }

  .select {
    .MuiInputLabel-root {
      color: ${colors.disabled};
      font-family: ${theme.typography.body1.fontFamily};
      font-size: ${theme.typography.body1.fontSize};
    }
    label + .MuiInput-formControl {
      margin-top: 0;
    }

    .MuiInputLabel-formControl {
      transform: translate(0, 7px) scale(1);
    }

    .MuiInputLabel-animated {
      transition: none;
    }
  }
  .name-field {
    .MuiInputBase-root {
      height: 40px;
      padding: 6px 0px;
    }
  }
  .support-bloc {
    display: flex;
    justify-content: space-evenly;
  }
`;
