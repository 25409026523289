import React from 'react';
import { Box, Typography } from '@material-ui/core';

import {
  getUnifiedKeyFromPimClientConfig,
  HistoryType,
  unifiedKeyField,
} from 'modules/pim/baseProducts/ProductsHelper';
import useAppContext from 'app/app-context/useAppContext';
import { HistoryWithFieldType } from 'modules/pim/components/ProductSheetHistory';
import { capitalizeFirstLetter } from 'app/utils/common';
import { useTranslation } from 'react-i18next';
import { GetProducts_getProducts_products_regrouping } from 'app/schemaInterfaces/GetProducts';
import { subTabLabel } from '../regroupingHelper';

interface HistoryFieldsConfigProps {
  singleHistory: HistoryWithFieldType;
  regrouping: GetProducts_getProducts_products_regrouping[] | undefined;
}

interface FieldMap {
  text: JSX.Element;
  select: JSX.Element;
  damone: JSX.Element;
  dammultiple: JSX.Element;
  selectgroup: JSX.Element;
  checkbox: JSX.Element;
  [key: string]: JSX.Element;
}

interface DisplayHistoryActionAndValueChange {
  action?: string;
  value?: string;
  isValue?: boolean;
  isValueLineThrough?: boolean;
  isReplacement?: boolean;
}

const HistoryFieldsConfig = ({
  singleHistory,
  regrouping,
}: HistoryFieldsConfigProps): JSX.Element => {
  const {
    client: { config: clientConfig },
  } = useAppContext();
  const { t } = useTranslation();

  const historyFieldNameNormalized = () => {
    const IsFieldNameMatchingPimClientConfig =
      singleHistory.fieldName ===
      getUnifiedKeyFromPimClientConfig(unifiedKeyField.Divisions, clientConfig);
    if (!IsFieldNameMatchingPimClientConfig) {
      const requiredField = clientConfig?.pim.requiredFields.find(
        (field) => field.key === singleHistory.fieldName
      );
      return (
        requiredField?.label ?? capitalizeFirstLetter(singleHistory.fieldName)
      );
    }
    return capitalizeFirstLetter(singleHistory.fieldName);
  };

  const regroupingIndex = regrouping?.findIndex(
    (group) => group.productId === singleHistory.productId
  );

  const displayHistoryActionAndValueChange = ({
    action,
    isValueLineThrough = false,
    value,
    isReplacement = false,
  }: DisplayHistoryActionAndValueChange) =>
    !isReplacement ? (
      <Box>
        {action} {historyFieldNameNormalized()}
        {singleHistory.historyType === HistoryType.RegroupingHistory && (
          <p> dans {subTabLabel(regroupingIndex ?? 0, t)}</p>
        )}
        {value && (
          <Box
            pt={1}
            style={{
              textDecoration: isValueLineThrough ? 'line-through' : 'unset',
            }}
          >
            <Typography variant="body2">{value}</Typography>
          </Box>
        )}
      </Box>
    ) : (
      <Box>
        <Box display="flex">
          <Box>{t('pim.history.replaced_history')}</Box>
          <Box pl={0.5}>{historyFieldNameNormalized()}:</Box>
        </Box>
        <Box pt={1} style={{ textDecoration: 'line-through' }}>
          {singleHistory.oldValue}
        </Box>
        <Box pt={1}>{t('pim.history.replaced_by')}</Box>
        <Box pt={1}>
          <Typography variant="body2">{singleHistory.newValue}</Typography>
        </Box>
        {singleHistory.historyType === HistoryType.RegroupingHistory && (
          <p> dans {subTabLabel(regroupingIndex ?? 0, t)}</p>
        )}
      </Box>
    );

  const singleValueRenderer = () => (
    <Box>
      {!singleHistory.oldValue &&
        !!singleHistory.newValue?.length &&
        displayHistoryActionAndValueChange({
          action: t('pim.history.added_history'),
          value: singleHistory.newValue,
        })}
      {!!singleHistory.oldValue?.length &&
        !singleHistory.newValue &&
        displayHistoryActionAndValueChange({
          action: t('pim.history.deleted_history'),
          isValueLineThrough: true,
          value: singleHistory.oldValue,
        })}
      {!!singleHistory.oldValue?.length &&
        !!singleHistory.newValue?.length &&
        displayHistoryActionAndValueChange({
          isReplacement: true,
        })}
    </Box>
  );

  const checkboxValueRenderer = () => (
    <Box>
      {!!singleHistory.oldValue?.length &&
        !singleHistory.newValue &&
        displayHistoryActionAndValueChange({
          action: t('pim.history.deleted_history'),
        })}
      {!singleHistory.oldValue &&
        !!singleHistory.newValue?.length &&
        displayHistoryActionAndValueChange({
          action: t('pim.history.added_history'),
        })}
    </Box>
  );

  const multipleValuesRenderer = () => {
    const newValues = singleHistory.newValue.split(', ');
    const oldValues = singleHistory.oldValue.split(', ');
    const getAddedValue = newValues.filter(
      (value) => !singleHistory.oldValue.includes(value)
    );
    const getDeletedValue = oldValues.filter(
      (value) => !singleHistory.newValue.includes(value)
    );

    if (!singleHistory.oldValue) {
      return displayHistoryActionAndValueChange({
        action: t('pim.history.added_history'),
        value: singleHistory.newValue,
      });
    }

    if (!singleHistory.newValue) {
      return displayHistoryActionAndValueChange({
        action: t('pim.history.deleted_history'),
        isValueLineThrough: true,
        value: singleHistory.oldValue,
      });
    }

    if (
      singleHistory.newValue
        .toLowerCase()
        .includes(singleHistory.oldValue.toLowerCase()) &&
      newValues.length > oldValues.length
    ) {
      return displayHistoryActionAndValueChange({
        action: t('pim.history.added_history'),
        value: getAddedValue.join(', '),
      });
    }

    if (
      !singleHistory.newValue
        .toLowerCase()
        .includes(singleHistory.oldValue.toLowerCase()) &&
      newValues.length < oldValues.length
    ) {
      return displayHistoryActionAndValueChange({
        action: t('pim.history.deleted_history'),
        isValueLineThrough: true,
        value: getDeletedValue.join(', '),
      });
    }

    return displayHistoryActionAndValueChange({
      isReplacement: true,
    });
  };

  const fieldMap: FieldMap = {
    text: singleValueRenderer(),
    select: singleValueRenderer(),
    damone: singleValueRenderer(),
    dammultiple: multipleValuesRenderer(),
    selectgroup: singleValueRenderer(),
    checkbox: checkboxValueRenderer(),
    multiplecheckbox: multipleValuesRenderer(),
    dammultiplelogospictos: multipleValuesRenderer(),
    childselect: singleValueRenderer(),
    radiobox: singleValueRenderer(),
    switch: checkboxValueRenderer(),
  };
  return fieldMap[singleHistory.fieldType];
};

export default HistoryFieldsConfig;
