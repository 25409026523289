import { Box, FormLabel } from '@material-ui/core';
import { useField } from 'formik';
import React from 'react';
import useAppContext from 'app/app-context/useAppContext';
import { Entity } from './definitions';
import EntitiesSelect from './EntitiesSelect';
import { getDirectParents, getEntitiesByType } from './helpers';
import Label from 'components/Label/Label';

interface EntitiesFieldProps {
  name: string;
  // TODO: I dont think this is the way to make a component custumizable. either use render props or className like material ui components
  TitleWrapper?: React.ElementType<any>;
  textFieldClassName?: string;
  entitiesToFilter?: string[];
}

function EntitiesField({
  name,
  TitleWrapper,
  textFieldClassName,
  entitiesToFilter,
}: EntitiesFieldProps): JSX.Element {
  const {
    client: { structures },
  } = useAppContext();

  const [field, , helpers] = useField<Entity[]>(name);

  const handleSelect = (entity: Entity) => {
    const newEntities = field.value.filter(({ id }) => id !== entity.parentId);
    newEntities.push(entity);
    helpers.setValue(newEntities);
  };

  const handleDelete = (entity: Entity) => {
    // Filter the entities that needs to be deleted

    let newEntities = field.value.filter(
      ({ id, ancestors }) =>
        field.value.length > 1 &&
        id !== entity.id &&
        // the entity to delete might be a ancestor, in this case we delete all children
        !ancestors.find(({ id: parentId }) => parentId === entity.id)
    );
    if (entity.ancestors.length && !entitiesToFilter) {
      // replacing the deleted entity with the parent if it exists
      newEntities.push({
        ...entity.ancestors[0],
        ancestors: entity.ancestors.slice(1),
      });
    }
    helpers.setValue(newEntities);
  };

  const Wrapper = TitleWrapper ?? FormLabel;

  return (
    <>
      {structures.slice(1).map((structure, key) => {
        const isHighestLevel = key === 0;
        return (
          <Box key={structure.type} className="entity">
            <Box pt={2}>
              <Label required={isHighestLevel} TitleWrapper={Wrapper}>
                {structure.title}
              </Label>
            </Box>
            <EntitiesSelect
              type={structure.type}
              entities={getEntitiesByType(field.value, structure.type)}
              parents={getDirectParents(field.value, structure)}
              onEntitySelect={handleSelect}
              onEntityDelete={handleDelete}
              name={name}
              required={isHighestLevel}
              textFieldClassName={textFieldClassName}
              entitiesToFilter={entitiesToFilter}
            />
          </Box>
        );
      })}
    </>
  );
}

export default EntitiesField;
