import { CircularProgress, Divider } from '@material-ui/core';

import EntityMenuItem from 'components/Navigation/user-menu/entity-menu/EntityMenuItem/EntityMenuItem';
import EntityMenuSelect from 'components/Navigation/user-menu/entity-menu/EntityMenuSelect/EntityMenuSelect';
import { entityMenuStyle } from './EntityMenu.style';
import { useEntityMenu } from './EntityMenu.hook';
import { useTranslation } from 'react-i18next';

const EntityMenu = () => {
  const { t } = useTranslation();
  const {
    entities,
    loading,
    visibleStructures,
    isMenuItemDisabled,
    handleEntitySelect,
    getEntityByType,
    handleCardClick,
    selectedStructure,
    setSelectedStructure,
  } = useEntityMenu();
  return (
    <div css={entityMenuStyle} data-testid="entity-menu">
      {!selectedStructure &&
        visibleStructures.map((structure) => {
          const entity = getEntityByType(structure.type);
          return (
            <div key={structure.type}>
              <EntityMenuItem
                name={entity?.name ?? t('header.user_menu.entity_menu.all')}
                isDisabled={isMenuItemDisabled(structure)}
                title={structure.title}
                isLogoHidden={entity === null}
                onClick={() => handleCardClick(structure)}
              />
              <Divider />
            </div>
          );
        })}

      {selectedStructure &&
        (loading ? (
          <div className="menu-loader">
            <CircularProgress />
          </div>
        ) : (
          <EntityMenuSelect
            value={getEntityByType(selectedStructure.type)}
            title={selectedStructure.title}
            entities={entities}
            onEntitySelect={handleEntitySelect}
            onBackClick={() => setSelectedStructure(null)}
          />
        ))}
    </div>
  );
};

export default EntityMenu;
