import { Button, Popover } from '@material-ui/core';
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';
import { useTranslation } from 'react-i18next';
import { FC, PropsWithChildren } from 'react';
import { filterPopoverStyle } from './FilterPopover.style';

export interface FilterPopoverProps {
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleClose: () => void;
  anchorEl: HTMLButtonElement | null;
  popoverId?: string;
  isDarkMode?: boolean;
}

const FilterPopover: FC<PropsWithChildren<FilterPopoverProps>> = ({
  popoverId,
  anchorEl,
  handleClick,
  handleClose,
  children,
  isDarkMode,
}) => {
  const { t } = useTranslation();

  return (
    <div css={filterPopoverStyle(isDarkMode)} data-testid="filter">
      <Button
        startIcon={<FilterListOutlinedIcon />}
        onClick={handleClick}
        data-testid="button"
      >
        {t('global.filters_popover.filter')}
      </Button>
      <Popover
        data-testid="popover"
        id={popoverId}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {children}
      </Popover>
    </div>
  );
};

export default FilterPopover;
