import { gql } from '@apollo/client';

export const GET_CATEGORY = gql`
  query GetCategory($filter: CategoryFilterInput) {
    category: getCategory(filter: $filter) {
      id
      title
    }
  }
`;
