import { OrderError } from 'app/schemaInterfaces/globalTypes';

export const OrderI18nKeyErrorDictionary = new Map<string, string>([
  [OrderError.OrderNotFound, 'follow_orders.errors.not_found'],
  [
    OrderError.NotAwaitingValidation,
    'follow_orders.errors.not_awaiting_validation',
  ],
  [OrderError.InvalidNewStatus, 'follow_orders.errors.invalid_new_status'],
]);
