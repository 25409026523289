import { Button, Divider } from '@material-ui/core';
import { AppRoute } from 'app/routes/routes';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useStoreState } from 'state/store';
import {
  BasketType,
  FollowOrderStatus,
} from 'app/schemaInterfaces/globalTypes';
import { OrderConfirmationDetails } from '../BasketSteps';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import OrderFollowStatusTag from 'modules/followOrders/components/OrderFollowStatusTag/OrderFollowStatusTag';
import React, { FC } from 'react';
import { dlStyle, style } from './BasketConfirmationPage.style';
import { formatPrice } from 'app/utils/common';

export interface BasketConfirmationPageProps {
  commitmentNumber?: string;
  orderConfirmationDetails?: OrderConfirmationDetails;
}

const BasketConfirmationPage: FC<BasketConfirmationPageProps> = ({
  commitmentNumber,
  orderConfirmationDetails,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const basketType = useStoreState(
    (state) => state.salesModule.basket.basketType
  );

  const basketDocuments = useStoreState(
    (state) => state.salesModule.basketdocuments.selectedBasketdocuments
  );

  const { deliveryDate } = basketDocuments.reduce((acc, basketDocument) =>
    basketDocument.deliveryDate?.isAfter(acc.deliveryDate)
      ? basketDocument
      : acc
  );
  const today = moment();

  const confirmationTitle =
    basketType === BasketType.Commitment
      ? t('sales.basket_page.commitment_confirmation.commitment_confirmed')
      : t('sales.basket_page.commitment_confirmation.order_confirmed');

  const confirmationMessage =
    basketType === BasketType.Commitment
      ? t(
          'sales.basket_page.commitment_confirmation.commitment_confirmation_msg'
        )
      : orderConfirmationDetails?.orderStatus ===
        FollowOrderStatus.WaitingValidation
      ? t(
          'sales.basket_page.commitment_confirmation.order_confirmation_msg_validation'
        )
      : t('sales.basket_page.commitment_confirmation.order_confirmation_msg');

  const isPriceIncluded = !Boolean(orderConfirmationDetails?.orderPrice);

  return (
    <div css={style}>
      <div className="confirmaton-img">
        <img
          src={`/${process.env.REACT_APP_ASSETS}/commitment_confirmation.png`}
          alt="confirmation"
        />
      </div>
      <h1>
        <CheckCircleOutlineIcon className="check" /> {confirmationTitle}
      </h1>
      <p>{confirmationMessage}</p>
      <div className="button">
        <Button
          color="primary"
          variant="contained"
          onClick={() => history.push(AppRoute.FollowOrders)}
        >
          {t('sales.basket_page.commitment_confirmation.follow_orders_button')}
        </Button>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => history.push(AppRoute.Home)}
        >
          {t('sales.basket_page.commitment_confirmation.return_button')}
        </Button>
      </div>

      <div className="detailsContainer">
        <h2>{t('sales.basket_page.commitment_confirmation.details')}</h2>
        <Divider />
        <dl css={dlStyle}>
          <dt>
            {basketType === BasketType.Commitment
              ? t('sales.basket_page.commitment_confirmation.commitment_number')
              : t('sales.basket_page.commitment_confirmation.order_id')}
          </dt>
          <dd>
            {basketType === BasketType.Commitment
              ? commitmentNumber
              : orderConfirmationDetails?.orderId}
          </dd>

          <dt>{t('sales.basket_page.commitment_confirmation.validated_at')}</dt>
          <dd>{today.format('DD/MM/YYYY')}</dd>

          {orderConfirmationDetails && (
            <>
              <dt>
                {t('sales.basket_page.commitment_confirmation.order_status')}
              </dt>
              <dd>
                <OrderFollowStatusTag
                  orderStatus={orderConfirmationDetails.orderStatus}
                />
              </dd>
            </>
          )}
        </dl>
        <dl css={dlStyle}>
          {orderConfirmationDetails?.productSellingPriceHt ? (
            <>
              <dt>
                {t(
                  'sales.basket_page.commitment_confirmation.order_support_price'
                )}
              </dt>
              <dd>
                {basketType === BasketType.BigShop
                  ? isPriceIncluded
                    ? t('local.campaign_page.included')
                    : formatPrice(
                        orderConfirmationDetails.productSellingPriceHt,
                        t
                      )
                  : ''}
              </dd>
            </>
          ) : null}
          {orderConfirmationDetails?.transportSellingPriceHt ? (
            <>
              <dt>
                {t(
                  'sales.basket_page.commitment_confirmation.order_delivery_price'
                )}
              </dt>
              <dd>
                {basketType === BasketType.BigShop
                  ? isPriceIncluded
                    ? t('local.campaign_page.included')
                    : formatPrice(
                        orderConfirmationDetails.transportSellingPriceHt,
                        t
                      )
                  : ''}
              </dd>
            </>
          ) : null}
          {orderConfirmationDetails && (
            <>
              <dt>
                {t('sales.basket_page.commitment_confirmation.order_price')}
              </dt>
              <dd>
                {basketType === BasketType.BigShop
                  ? isPriceIncluded
                    ? t('local.campaign_page.included')
                    : formatPrice(orderConfirmationDetails.orderPrice, t)
                  : ''}
              </dd>
            </>
          )}

          {deliveryDate && (
            <>
              <dt>
                {t('sales.basket_page.commitment_confirmation.delivery_date')}
              </dt>
              <dd>{deliveryDate.format('DD/MM/YYYY')}</dd>
            </>
          )}
        </dl>
      </div>
    </div>
  );
};

export default BasketConfirmationPage;
