import { useMutation } from '@apollo/client';
import useCurrentEntity from 'app/current-entity-context/useCurrentEntity';
import {
  CREATE_ADDRESS_BLOCK,
  ADDRESS_BLOCKS,
  UPDATE_ADDRESS_BLOCK_BY_ID,
  REMOVE_ADDRESS_BLOCK,
  CREATE_ADDRESS_BLOCK_UPLOAD_URL,
} from 'app/graphql/queries/addressBlocks';
import {
  CreateAddressBlock,
  CreateAddressBlockVariables,
  CreateAddressBlock_addressBlock,
} from 'app/schemaInterfaces/CreateAddressBlock';
import {
  AddressBlockInput,
  AscDescType,
} from 'app/schemaInterfaces/globalTypes';
import {
  UpdateAddressBlockById,
  UpdateAddressBlockByIdVariables,
} from 'app/schemaInterfaces/UpdateAddressBlockById';
import { useAssetMutate } from 'modules/dam/hooks/useAssetMutate';
import { AssetType } from 'app/schemaInterfaces/globalTypes';
import {
  RemoveAddressBlock,
  RemoveAddressBlockVariables,
} from 'app/schemaInterfaces/RemoveAddressBlock';
import { AddressBlockFormValues } from './AddressBlockContainer/AddressBlockContainer';
import {
  CreateAddressBlockUploadUrl,
  CreateAddressBlockUploadUrlVariables,
} from 'app/schemaInterfaces/CreateAddressBlockUploadUrl';
import mime from 'mime-types';

export interface AssetMutateOptions {
  reportProgress?: boolean;
}

export const useAddressBlockMutate = (
  options: AssetMutateOptions = { reportProgress: false }
) => {
  const { currentEntity } = useCurrentEntity();

  const currentEntityId = currentEntity?.id as string;

  const refetchVariables = {
    query: ADDRESS_BLOCKS,
    variables: {
      entityId: currentEntityId,
      sort: { updatedAt: AscDescType.desc },
    },
  };

  const [createAddressBlockMutation] = useMutation<
    CreateAddressBlock,
    CreateAddressBlockVariables
  >(CREATE_ADDRESS_BLOCK, {
    refetchQueries: [refetchVariables],
  });

  const [createAddressBlockUploadUrl] = useMutation<
    CreateAddressBlockUploadUrl,
    CreateAddressBlockUploadUrlVariables
  >(CREATE_ADDRESS_BLOCK_UPLOAD_URL);

  const [removeAddressBlockMutation] = useMutation<
    RemoveAddressBlock,
    RemoveAddressBlockVariables
  >(REMOVE_ADDRESS_BLOCK, {
    refetchQueries: [refetchVariables],
  });

  const {
    createAsset,
    uploadProgress,
    queueProgress,
    initProgress,
    reportSuccess,
    reportError,
    clearProgress,
  } = useAssetMutate({
    reportProgress: true,
  });

  const [updateAddressBlock] = useMutation<
    UpdateAddressBlockById,
    UpdateAddressBlockByIdVariables
  >(UPDATE_ADDRESS_BLOCK_BY_ID, {
    refetchQueries: [refetchVariables],
  });

  const callCreateAddressBlockMutation = async (
    addressBlock: AddressBlockInput
  ): Promise<CreateAddressBlock_addressBlock> => {
    const result = await createAddressBlockMutation({
      variables: {
        addressBlock,
      },
    });
    if (!result.data) throw new Error();
    return result.data.addressBlock;
  };

  const removeAddressBlock = async (id: string) => {
    const result = await removeAddressBlockMutation({
      variables: {
        id,
      },
    });
    if (!result.data) throw new Error();
    return result;
  };

  const createAddressBlock = async ({
    name,
    supplyProductIds,
    supportId,
    hdPdf,
    thumbnailFile,
  }: AddressBlockFormValues): Promise<CreateAddressBlock_addressBlock> => {
    if (options.reportProgress && thumbnailFile) {
      initProgress(thumbnailFile.name);
    }
    try {
      if (thumbnailFile) queueProgress(thumbnailFile.name);

      const asset = await createAsset(
        {
          name: thumbnailFile?.name,
          isVisible: false,
          metadata: {
            credit: '',
            dimensions: undefined,
            fileHasCredit: undefined,
            fileSize: 0,
            mimetype: '',
          },
          assetType: AssetType.AddressBlock,
        },
        thumbnailFile as File
      );

      const hdPdfMimetype = !!hdPdf?.type
        ? hdPdf?.type
        : mime.lookup(hdPdf?.name.split('.').pop() as string);

      const { data: UrlData } = await createAddressBlockUploadUrl({
        variables: { mimetype: !!hdPdfMimetype ? hdPdfMimetype : '' },
      });
      const mutatedAddressBlock = await callCreateAddressBlockMutation({
        name: name,
        assetId: asset.id,
        entityId: currentEntityId,
        supplyProductIds,
        supportId,
        hdPdfId: UrlData?.createAddressBlockUploadUrl.id,
      });
      if (options.reportProgress && thumbnailFile) {
        reportSuccess(thumbnailFile.name);
      }

      return mutatedAddressBlock;
    } catch (e) {
      if (options.reportProgress && thumbnailFile) {
        reportError(thumbnailFile.name, (e as any).message);
      }
      throw new Error(e as any);
    }
  };

  return {
    uploadProgress,
    createAddressBlock,
    updateAddressBlock,
    removeAddressBlock,
    clearProgress,
  };
};
