import { css } from '@emotion/react/macro';
import colors from 'styles/colors.module.scss';

const basketPage = css`
  height: 100%;
  background-color: ${colors.pageBackground};
  padding-right: 42px;
  padding-left: 42px;
  margin-bottom: 40px;

  h1 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .header {
    position: relative;

    h1 {
      color: ${colors.pageBackground};
    }

    .tabs-wrapper {
      position: absolute;
      width: 100%;
      top: 132px;
      .container {
        justify-content: center;
        display: flex;
      }
    }
  }

  .MuiList-padding {
    padding: 0;

    .MuiListItem-root {
      padding: 16px;

      &:not(:last-of-type) {
        border-bottom: 1px solid #dadada;
      }
    }
  }
`;

const style = { basketPage };

export default style;
