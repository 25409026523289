import { GetSupplyProducts_supplyProducts } from 'app/schemaInterfaces/GetSupplyProducts';
import { OptionV3 } from 'components/UncontrolledMultipleArrayValueSelect';

export const getFormats = (
  supplyProducts: GetSupplyProducts_supplyProducts[]
): OptionV3[] => {
  const formats: OptionV3[] = [];
  supplyProducts.forEach((product) => {
    if (
      product.largeurFormatFiniCm &&
      product.longueurFormatFiniCm &&
      product.id
    ) {
      const label =
        product.longueurFormatPageCm && product.largeurFormatPageCm
          ? `${product.largeurFormatPageCm} x ${product.longueurFormatPageCm} cm`
          : `${product.largeurFormatFiniCm} x ${product.longueurFormatFiniCm} cm`;
      const value = [product.id];
      if (!formats.length) {
        formats.push({
          value,
          label,
        });
      } else {
        const index = formats.findIndex((format) => format.label === label);
        if (index !== -1) {
          formats[index] = {
            ...formats[index],
            value: [...formats[index].value, product.id],
          };
        } else {
          formats.push({
            value,
            label,
          });
        }
      }
    }
  });
  return formats;
};
