import {
  AscDescType,
  BigShopConfigCategory,
  FollowOrderStatus,
  OrderFilterInput,
} from 'app/schemaInterfaces/globalTypes';
import {
  DEFAULT_IMAGE_PREVIEW,
  truncateString,
  formatPriceHT,
} from 'app/utils/common';
import { GetOrders, GetOrdersVariables } from 'app/schemaInterfaces/GetOrders';
import { Tooltip } from '@material-ui/core';
import React, { useState } from 'react';
import { GET_ORDERS } from 'app/graphql/queries/orders';
import Icon from 'components/Icon/Icon';
import OrderFollowStatusTag from 'modules/followOrders/components/OrderFollowStatusTag/OrderFollowStatusTag';
import { TableRowData } from 'components/TableComponent/TableComponent';
import _ from 'lodash';
import moment from 'moment';
import useCurrentEntity from 'app/current-entity-context/useCurrentEntity';
import { useCurrentUser } from 'app/auth/UserContext';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useOrderValidationWorkflow } from 'modules/followOrders/customHooks/useOrderValidationWorkflow';
import FollowOrderValidationIconButton from 'modules/followOrders/components/FollowOrderValidationIconButton/FollowOrderValidationIconButton';
import { isUserValidatorForOrder } from 'modules/followOrders/utils/validation';

export const useFollowOrdersPage = () => {
  const { t } = useTranslation();

  const user = useCurrentUser();
  const validation = useOrderValidationWorkflow();

  const [filter, setFilter] = useState<OrderFilterInput>({});

  const tableHeadData = [
    '',
    t('sales.basket_page.order.campaign'),
    t('follow_orders.campaign'),
    t('sales.basket_page.order.support_categories'),
    t('sales.basket_page.order.client_name'),
    t('sales.basket_page.order.date'),
    t('follow_orders.status'),
    t('sales.basket_page.order.total'),
    '',
  ];

  const { currentEntity } = useCurrentEntity();

  const { data: orderData, loading: orderDataLoading } = useQuery<
    GetOrders,
    GetOrdersVariables
  >(GET_ORDERS, {
    variables: {
      ...(currentEntity && { entityId: currentEntity?.id }),
      sort: { createdAt: AscDescType.desc },
      filter,
    },
  });

  const getSupportCategoriesIcon = (
    supportCategoriesIcon: (string | null)[]
  ) => {
    const uniqIcons = _.uniq(supportCategoriesIcon);

    return uniqIcons
      .map((icon) => <Icon name={icon} className="support-category--icon" />)
      .filter((value) => value);
  };
  let documentCategories: BigShopConfigCategory[] | undefined = [];

  orderData?.getOrders?.forEach(
    (order) =>
      (documentCategories = order?.orderItem?.map(
        (item) => item.document?.category
      ))
  );

  const tableRowData = orderData?.getOrders?.map<TableRowData>((order) => {
    const isValidator = isUserValidatorForOrder(order, user);
    return {
      variant:
        isValidator &&
        order.followStatus === FollowOrderStatus.WaitingValidation
          ? 'warning'
          : undefined,
      data: [
        <img className="image" src={DEFAULT_IMAGE_PREVIEW} alt="order" />,
        <Tooltip
          title={
            order.fileReference?.operationName &&
            order.fileReference?.operationName.length > 17
              ? order.fileReference?.operationName
              : ''
          }
        >
          <h4>
            {truncateString(
              order.fileReference?.operationName ?? '',

              17
            )}
          </h4>
        </Tooltip>,

        'Locale', //TODO: get the campaign type from the Order when implemented in OS
        getSupportCategoriesIcon(
          order.orderItem?.map(
            (orderItem) =>
              orderItem?.product?.support?.onePacConfig?.categoryIcon ?? ''
          ) ?? []
        ),
        <Tooltip
          title={
            order.clientName && order.clientName.length > 15
              ? order.clientName
              : ''
          }
        >
          <p>{truncateString(order.clientName ?? '', 15)}</p>
        </Tooltip>,

        moment(order.createdAt).format('DD/MM/YYYY'),
        <OrderFollowStatusTag
          orderStatus={order.followStatus}
          documentsCategories={order?.orderItem?.map(
            (item) => item.document?.category
          )}
        />,
        <h4>
          {formatPriceHT(
            order.orderItem?.reduce(
              (acc, currentDocument) =>
                acc + (currentDocument.sellingPriceHt ?? 0),
              0
            ) ?? 0,
            t
          )}
        </h4>,
        isValidator &&
          order.followStatus === FollowOrderStatus.WaitingValidation && (
            <>
              <FollowOrderValidationIconButton
                type="accept"
                onClick={(e) => {
                  e.preventDefault();
                  validation.startAcceptation(order.id);
                }}
              >
                {t('follow_orders.accept_order_short')}
              </FollowOrderValidationIconButton>
              <FollowOrderValidationIconButton
                type="decline"
                onClick={(e) => {
                  e.preventDefault();
                  validation.startRefusal(order.id);
                }}
              >
                {t('follow_orders.decline_order_short')}
              </FollowOrderValidationIconButton>
            </>
          ),
      ],
    };
  });

  const orderPath = orderData?.getOrders?.map(
    (order) => `/follow-orders/${order.id}`
  );

  return {
    orderDataLoading,
    tableHeadData,
    tableRowData,
    orderPath,
    t,
    filter,
    setFilter,
    documentCategories,
    validation,
  };
};
