import { GetSupplyProducts_supplyProducts } from 'app/schemaInterfaces/GetSupplyProducts';
import { GetSupplyProducts_supplyProducts_conditionnement } from 'app/schemaInterfaces/GetSupplyProducts';
import { GetSupports_supports_supportAttributes } from 'app/schemaInterfaces/GetSupports';
import {
  AttributConditionnementFilterInput,
  AttributeKey,
  SupplyAttributesFilterInput,
} from 'app/schemaInterfaces/globalTypes';
import _ from 'lodash';
import {
  Conditionnement,
  NON_VALUE,
  SupplyProduct,
} from './DocumentProductSubForm.definition';
import { AttributeFormOptions } from './DocumentProductSubform.hooks';

export const isItemPackaging = (
  item:
    | string
    | Conditionnement
    | GetSupplyProducts_supplyProducts_conditionnement
    | null
    | undefined
): item is Conditionnement => {
  return Boolean((item as Conditionnement)?.numberOfLots);
};

export const isItemSupplyPackaging = (
  item: string | GetSupplyProducts_supplyProducts_conditionnement | null
): item is GetSupplyProducts_supplyProducts_conditionnement => {
  return Boolean(
    (item as GetSupplyProducts_supplyProducts_conditionnement)?.numberOfLots
  );
};
export const isItemAttributePackaging = (
  item: string | AttributConditionnementFilterInput
): item is AttributConditionnementFilterInput => {
  return Boolean((item as AttributConditionnementFilterInput)?.numberOfLots);
};

export const serializePackagingOsProduct = (
  packaging: GetSupplyProducts_supplyProducts_conditionnement
): AttributConditionnementFilterInput => {
  const { __typename: typename, ...restConditionnement } = packaging;
  return {
    ...restConditionnement,
    ...(restConditionnement.conditionnementList
      ? {
          conditionnementList: restConditionnement.conditionnementList?.map(
            (conditionnementItem) => {
              const { __typename, ...item } = conditionnementItem;
              let firstConditionnement;
              let secondConditionnement;
              let threeConditionnement;
              if (item.firstConditionnement) {
                const {
                  __typename: firstType,
                  ...first
                } = item.firstConditionnement;
                firstConditionnement = first;
              }
              if (item.secondConditionnement) {
                const {
                  __typename: secondType,
                  ...second
                } = item.secondConditionnement;
                secondConditionnement = second;
              }
              if (item.threeConditionnement) {
                const {
                  __typename: thirdType,
                  ...third
                } = item.threeConditionnement;
                threeConditionnement = third;
              }

              return {
                ...item,
                ...(firstConditionnement ? { firstConditionnement } : {}),
                ...(secondConditionnement ? { secondConditionnement } : {}),

                ...(threeConditionnement ? { threeConditionnement } : {}),
              };
            }
          ),
        }
      : {}),
  };
};

export const serializeOsProductToFormikAttributes = (
  product: GetSupplyProducts_supplyProducts
): SupplyProduct => {
  const { __typename, id, conditionnement, ...rest } = product;
  return {
    ...rest,
    ...(conditionnement
      ? { conditionnement: serializePackagingOsProduct(conditionnement) }
      : { conditionnement: null }),
  } as SupplyProduct;
};

export const filterProductByAttribute = (
  products: GetSupplyProducts_supplyProducts[],
  attributeValues: SupplyAttributesFilterInput
) => {
  const filteredProducts =
    products && products.length > 1
      ? (_.filter(
          products,
          attributeValues
        ) as GetSupplyProducts_supplyProducts[])
      : products ?? [];

  return filteredProducts;
};

export const getAttributeOptions = (
  attributes: GetSupports_supports_supportAttributes[],
  products: GetSupplyProducts_supplyProducts[],
  packagingAttributesByContentMap: Map<
    string,
    GetSupplyProducts_supplyProducts_conditionnement & { value: string }
  >
) => {
  return attributes.reduce<AttributeFormOptions[]>((prev, attribute) => {
    const productAttributesValues = products.map((product) =>
      attribute.key === AttributeKey.conditionnement
        ? packagingAttributesByContentMap.get(
            JSON.stringify(product[attribute.key])
          ) || NON_VALUE
        : product[attribute.key] || NON_VALUE
    );
    const values = _.uniqWith(productAttributesValues, _.isEqual).sort(
      (a, b) => {
        if (attribute.key === AttributeKey.conditionnement) {
          return (a as any).value > (b as any).value ? 1 : -1;
        }
        return a > b ? 1 : -1;
      }
    );
    if (
      productAttributesValues.length === 0 ||
      (values.length === 1 && values[0] === NON_VALUE)
    ) {
      return prev;
    }

    return [
      ...prev,
      {
        attribute,
        values,
      },
    ];
  }, []);
};

export const getPackagingAttributeMaps = (
  products: GetSupplyProducts_supplyProducts[],
  label: string = 'Packaging'
) => {
  const packagingAttributesByValueMap = new Map<
    string,
    GetSupplyProducts_supplyProducts_conditionnement
  >();

  const packagingAttributesByContentMap = new Map<
    string,
    GetSupplyProducts_supplyProducts_conditionnement & { value: string }
  >();

  const packagingAttributesValues = products.reduce<
    GetSupplyProducts_supplyProducts_conditionnement[]
  >((prev, product) => {
    const packagingAttribute = product[AttributeKey.conditionnement];
    return [...prev, ...(packagingAttribute ? [packagingAttribute] : [])];
  }, []);

  _.uniqWith(packagingAttributesValues, _.isEqual).forEach((attr, key) => {
    packagingAttributesByContentMap.set(JSON.stringify(attr), {
      ...attr,
      value: `${label} #${key + 1}`,
    });
    packagingAttributesByValueMap.set(`${label} #${key + 1}`, attr);
  });
  return {
    packagingAttributesByValueMap,
    packagingAttributesByContentMap,
  };
};
