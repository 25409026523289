import 'react-calendar-timeline/lib/Timeline.css';

import React, { FC, useMemo } from 'react';
import Timeline, {
  DateHeader,
  GetItemsProps,
  GetResizeProps,
  Id,
  ItemContext,
  ItemRendererGetResizePropsReturnType,
  ReactCalendarGroupRendererProps,
  ReactCalendarItemRendererProps,
  SidebarHeader,
  TimelineGroupBase,
  TimelineHeaders,
  TimelineItemBase,
} from 'react-calendar-timeline';
import { customDayHeader, customMonthHeader } from './ReversePlaning.utils';
import moment, { Moment } from 'moment';

import { Box } from '@material-ui/core';
import { GetEntities_entities } from 'app/schemaInterfaces/GetEntities';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ReversePlanningStyle } from './ReversePlanning.style';

export interface CustomReactCalendarItemRendererProps<
  CustomItem extends TimelineItemBase<MaterialUiPickersDate> = TimelineItemBase<
    MaterialUiPickersDate
  >
> {
  item: CustomItem;
  itemContext: ItemContext;
  getItemProps: (
    props: GetItemsProps
  ) => {
    key: Id;
    ref: React.Ref<any>;
    className: string;
    onMouseDown: React.MouseEventHandler;
    onMouseUp: React.MouseEventHandler;
    onDoubleClick: React.MouseEventHandler;
    onContextMenu: React.ReactEventHandler;
    style: React.CSSProperties;
  };
  getResizeProps: (
    propsOverride?: GetResizeProps
  ) => ItemRendererGetResizePropsReturnType;
}

export interface CustomTimelineItemBase<DateType>
  extends TimelineItemBase<DateType> {
  backgroundColor?: string;
  disabled?: boolean;
  entities?: GetEntities_entities[];
  redirectLink?: string;
  commitmentDateTo?: Moment;
  commitmentDateFrom?: Moment;
  type?: string;
}

export interface CustomTimelineGroupBase extends TimelineGroupBase {
  events?: CustomTimelineItemBase<MaterialUiPickersDate>[];
}

interface ReversePlanningProps {
  groupsPlanning: CustomTimelineGroupBase[];
  eventsPlanning: CustomTimelineItemBase<MaterialUiPickersDate>[];
  customGroupsRenderer?: (
    props: ReactCalendarGroupRendererProps<CustomTimelineGroupBase>
  ) => React.ReactNode;
  customItemsRenderer?: (
    props?: ReactCalendarItemRendererProps<
      CustomTimelineItemBase<MaterialUiPickersDate>
    >
  ) => React.ReactNode;
  customSidebarHeader?: ({
    getRootProps,
  }: {
    getRootProps: (propsToOverride?: {
      style: React.CSSProperties;
    }) => { style: React.CSSProperties };
  }) => React.ReactNode;
  selectedDatePlanning: MaterialUiPickersDate;
  isHomePage: boolean;
  customHeader?: React.ReactNode;
  customLegend?: React.ReactNode;
}

const ReversePlanning: FC<ReversePlanningProps> = ({
  groupsPlanning,
  eventsPlanning,
  selectedDatePlanning,
  customGroupsRenderer,
  customItemsRenderer,
  customSidebarHeader,
  isHomePage = false,
  customHeader = null,
  customLegend = null,
}) => {
  const defaultTimeStart = useMemo(
    () =>
      selectedDatePlanning &&
      moment(selectedDatePlanning).startOf('month').valueOf(),
    [selectedDatePlanning]
  );

  const defaultTimeEnd = useMemo(
    () =>
      selectedDatePlanning &&
      moment(selectedDatePlanning).endOf('month').valueOf(),
    [selectedDatePlanning]
  );

  return (
    <Box css={ReversePlanningStyle(isHomePage)}>
      {isHomePage && customHeader}
      <div className="content">
        {isHomePage && customLegend}
        <Timeline<TimelineItemBase<MaterialUiPickersDate>>
          groups={groupsPlanning ?? []}
          items={eventsPlanning ?? []}
          visibleTimeStart={defaultTimeStart ?? undefined}
          visibleTimeEnd={defaultTimeEnd ?? undefined}
          stackItems
          lineHeight={66}
          sidebarWidth={isHomePage ? 0 : 242}
          groupRenderer={customGroupsRenderer}
          itemRenderer={customItemsRenderer}
          canMove={false}
        >
          <TimelineHeaders style={{ border: 'none' }}>
            <SidebarHeader>
              {
                customSidebarHeader as ({
                  getRootProps,
                }: {
                  getRootProps: (propsToOverride?: {
                    style: React.CSSProperties;
                  }) => { style: React.CSSProperties };
                }) => React.ReactNode
              }
            </SidebarHeader>
            {!isHomePage && (
              <DateHeader
                data-testid="mount-view"
                unit="week"
                labelFormat="MMMM YYYY"
                height={40}
                intervalRenderer={customMonthHeader(isHomePage)}
              />
            )}
            <DateHeader
              unit="day"
              labelFormat="dd D"
              height={40}
              intervalRenderer={customDayHeader(selectedDatePlanning)}
            />
          </TimelineHeaders>
        </Timeline>
      </div>
    </Box>
  );
};

export default ReversePlanning;
