import { Grid } from '@material-ui/core';
import FormWrapper from 'components/FormWrapper/FormWrapper';
import { TRANSLATION_ROOT } from 'modules/oneManager/storeHelper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Contact from 'modules/oneManager/deliveryAddress/components/Contact';
import DeliveryAddress from 'modules/oneManager/deliveryAddress/components/DeliveryAddress';
import WorkingHours from 'modules/oneManager/deliveryAddress/components/WorkingHours';

interface DeliveryAddressFormProps {
  isOpen: boolean;
  handleClose: () => void;
}

const DeliveryAddressForm = ({
  isOpen,
  handleClose,
}: DeliveryAddressFormProps): JSX.Element => {
  const { t } = useTranslation();

  const actionButtons = [
    {
      name: t(`${TRANSLATION_ROOT}.delivery_address_form.submit`),
      handleClick: () => {},
    },
  ];

  return (
    <FormWrapper
      open={isOpen}
      handleClose={handleClose}
      title={t(`${TRANSLATION_ROOT}.delivery_address_form.title`)}
      actionButtons={actionButtons}
    >
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <DeliveryAddress />
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <WorkingHours />
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Contact />
        </Grid>
      </Grid>
    </FormWrapper>
  );
};

export default DeliveryAddressForm;
