import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const orderDetailsContainerStyle = css`
  .order-header {
    background-color: ${colors.workzone};
    height: 86px;
    position: sticky;
    top: 0;
    z-index: 10;

    .header-container {
      padding-left: 84px;
      padding-right: 84px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: inherit;

      span {
        display: flex;
      }
      .divider {
        color: ${colors.grey};
        border-left: 2px solid ${colors.buttonGroupOutlined};
        font-family: 'ScandiaWebMedium';
        font-weight: 500;
        margin-left: 24px;
        padding: 0 24px;
      }

      .order-info {
        display: flex;
        align-items: center;
        .subtitle {
          color: ${colors.grey};
        }
        h5 {
          font-weight: 500;
        }
        .order-date {
          color: ${colors.body};
        }
      }
    }
  }

  .billing-container {
    margin-top: 36px;
  }
  .campaign-header {
    background-color: ${colors.workzone};
    border: 1px solid ${colors.buttonGroupOutlined};
    border-left: none;
    border-right: none;
    padding: 29px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 16px;

    h5 {
      height: fit-content;
      color: ${colors.documentsTab};
      border-radius: 25px;
      padding: 5px 10px;
      font-weight: 500;
    }
  }
  .delivery-row {
    display: flex;
    flex-direction: column;
    .content {
      padding: 24px;
      justify-content: start;
    }
    .document-row {
      padding: 36px 0;
    }
    .document-row .media-wrapper.delivery img {
      width: 142px;
      height: 132px;
      align-self: center;
    }
  }
  .dropdown {
    color: ${colors.white};
    background-color: ${colors.grey};
    border-radius: 50%;
  }
  .MuiTabs-root {
    background-color: ${colors.workzone};
    padding-left: 84px;
  }
  .breadcrumbs {
    padding-left: 84px;
    padding-right: 84px;
  }

  .MuiButton-outlined.Mui-disabled {
    color: ${colors.buttonDisabled};
    border: 2px solid ${colors.buttonDisabled};
  }
  .spaced {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div {
      margin-right: 18px;
    }
    .MuiDivider-vertical {
      height: 20px;
      margin-right: 18px;
    }
  }
  .subtitle {
    display: block;
    font-family: 'ScandiaWebRegular';
    font-size: 12px;
    color: ${colors.body};
  }
`;
