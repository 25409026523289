import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import { Box, Typography, makeStyles } from '@material-ui/core';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { GET_ASSET_BY_ID } from 'app/graphql/queries/dam';
import {
  GetAssetById,
  GetAssetByIdVariables,
} from 'app/schemaInterfaces/GetAssetById';
import colors from 'styles/colors.module.scss';
import { ActionsMenu } from 'components/ActionsMenu/ActionsMenu';
import { FileStore } from 'modules/pim/baseProducts/PimFormFieldsConfig';
import { modifyFilesActions } from 'modules/pim/baseProducts/ProductsHelper';
import { PreviewFile } from 'components/ControlledFileUpload/ControlledFileUpload';
import StatusBadge, { BadgeStatus } from 'components/Badge/StatusBadge';
import { useTranslation } from 'react-i18next';
import FormThumbnail from 'components/FormThumbnail/FormThumbnail';
import { VisuelFieldName } from '../baseProducts/ProductsHelper';

interface FormFilesCardProps {
  file?: PreviewFile;
  base64File?: string;
  removeFiles: (filteredFile: FileStore) => void;
  updateFiles: (filteredFile: FileStore) => void;
  filteredFile: FileStore;
  fileName?: string;
  assetId?: string;
  name?: string;
  productId?: string;
}

export const FormFilesCard = ({
  file,
  base64File,
  removeFiles,
  filteredFile,
  assetId,
  updateFiles,
  name,
  productId,
}: FormFilesCardProps): JSX.Element => {
  const { t } = useTranslation();

  const [
    isEditFilesDialogOpen,
    setIsEditFilesDialogOpen,
  ] = useState<HTMLButtonElement | null>(null);

  const { data: assetData } = useQuery<GetAssetById, GetAssetByIdVariables>(
    GET_ASSET_BY_ID,
    {
      variables: {
        id: assetId as string,
      },
      skip: !Boolean(assetId),
    }
  );

  const { getAssetById: asset } = assetData ?? {};

  const handleOpenEditFilesActions = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setIsEditFilesDialogOpen(event.currentTarget);
  };

  const handleCloseEditFilesActions = () => {
    setIsEditFilesDialogOpen(null);
  };

  const classes = makeStyles({
    conformityBadge: {
      position: 'absolute',
      right: 12,
      top: 12,
    },
  })();

  const conformityFileOrAsset = asset?.conform ?? filteredFile.isConform;

  return (
    <Box border={`1px solid ${colors.buttonGroupOutlined}`} mt={1}>
      <Box px={1} py={1} display="flex" justifyContent="space-between">
        <Box display="flex" width={1}>
          <Box position="relative" width="25%">
            {conformityFileOrAsset !== undefined && (
              <StatusBadge
                className={classes.conformityBadge}
                status={
                  conformityFileOrAsset
                    ? BadgeStatus.Success
                    : BadgeStatus.Error
                }
                message={
                  conformityFileOrAsset
                    ? t('workflow.consistent')
                    : t('workflow.to_be_changed')
                }
              />
            )}
            <FormThumbnail
              file={file}
              base64File={base64File}
              url={asset?.displayedThumbUrl}
            />
          </Box>
          <Box pt={0.5} display="inline-flex" pl={2} width="75%">
            <Box>
              <Typography variant="h5">{t('pim.form.name')}</Typography>
            </Box>
            <Box pt={0.3} pl={0.5}>
              <Typography variant="subtitle2">
                {' '}
                {file ? file?.path : asset?.name}
              </Typography>
            </Box>
          </Box>
        </Box>
        {name !== `${VisuelFieldName.mainVisuel}-${productId ?? 0}` && (
          <Box display="flex">
            <Box>
              <ActionsMenu
                actions={modifyFilesActions({ updateFiles, filteredFile, t })}
                button={<CreateOutlinedIcon />}
                handleMenuClick={handleOpenEditFilesActions}
                handleMenuClose={handleCloseEditFilesActions}
                anchorEl={isEditFilesDialogOpen}
              />
            </Box>
            <Box
              onClick={() => removeFiles(filteredFile)}
              style={{ cursor: 'pointer' }}
            >
              <DeleteOutlineOutlinedIcon />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
