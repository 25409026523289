export const menuButtonStyle = (
  iconButtonClass: string,
  onIconButtonClickClass: string,
  iconButtonBackgroundColor: string | undefined,
  anchorEl: HTMLElement | null
) => {
  if (iconButtonBackgroundColor) {
    return {
      root: !anchorEl ? iconButtonClass : onIconButtonClickClass,
    };
  } else {
    return undefined;
  }
};

export const menuItemStyle = (index: number, hasIndentation: boolean) =>
  hasIndentation && index !== 0 ? { paddingLeft: '40px' } : undefined;
