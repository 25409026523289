import { GetAssetById_getAssetById as Asset } from 'app/schemaInterfaces/GetAssetById';
import {
  AssetInput,
  AssetStatus,
  AssetType,
  CharterType,
  VisualType,
} from 'app/schemaInterfaces/globalTypes';
import { Entity } from 'modules/oneManager/entities/form/definitions';
import { AssetFormModel } from 'modules/dam/form/model/definitions';

export const mapToAssetFormModel = ({
  asset,
  entities,
}: {
  asset?: Asset;
  entities?: Entity[];
}): AssetFormModel => ({
  name: asset?.name ?? '',
  assetType: asset ? asset.assetType : AssetType.Visual,
  visualType: asset ? asset.visualType : VisualType.Packshot,
  charterType: asset ? asset.charterType : CharterType.PageBackground,
  comment: asset?.comment ?? '',
  permanent: asset?.permanent ?? true,
  validFrom: asset?.validFrom ?? null,
  validTo: asset?.validTo ?? null,
  status: asset ? asset.status : AssetStatus.Active,
  conform: asset?.conform,
  entities: asset?.entities ?? entities ?? [],
  tags: asset?.tags?.map(({ id, title }) => ({ id, title })) ?? [],
  division: asset?.division ?? {},
  metadata: {
    mimetype: asset?.metadata.mimetype ?? '',
    fileSize: asset?.metadata.fileSize ?? 0,
    dimensions: asset?.metadata.dimensions
      ? {
          width: asset.metadata.dimensions.width,
          height: asset.metadata.dimensions.height,
          widthInCm: asset.metadata.dimensions.widthInCm,
          heightInCm: asset.metadata.dimensions.heightInCm,
        }
      : undefined,
    credit: asset?.metadata.credit ?? '',
    fileHasCredit: asset?.metadata.fileHasCredit,
  },
  isVisible: true,
});

export const mapToAssetInput = (asset: AssetFormModel): AssetInput => ({
  ...asset,
  entities: asset.entities.map(({ id }) => id),
});
