import { AppRoute } from 'app/routes/routes';
import { TFunction } from 'i18next';

export const competitorsBreadcrumbs = (t: TFunction) => [
  { path: AppRoute.Home, breadcrumb: t('header.home') },
  {
    path: AppRoute.Competitors,
    breadcrumb: t('header.competitors'),
  },
];
