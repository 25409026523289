import React from 'react';
import { Tabs, Tab, Typography } from '@material-ui/core';
import colors from 'styles/colors.module.scss';
import { DigitalMenu } from 'modules/workflow/oneStudioContext';
import { TFunction, useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const style = css`
  display: flex;
  flex-direction: column;
  .badge {
    width: 24px;
    height: 24px;
    background-color: ${colors.disabled};
    color: ${colors.white};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .digital-tabs {
    background-color: ${colors.superLightGrey};
    .Mui-selected {
      background-color: ${colors.white};
      color: ${colors.primary};
      .badge {
        background-color: ${colors.primary};
      }
    }
    .MuiTabs-indicator {
      height: 4px;
    }
    border-bottom: 1px solid ${colors.buttonGroupOutlined};
  }
  .steps {
    height: 43px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
    color: ${colors.primary};
    cursor: pointer;
    .arrow {
      font-size: 15px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  .left {
    justify-content: flex-start;
  }
`;

const steps = (
  t: TFunction,
  setDigitalTabValue: React.Dispatch<React.SetStateAction<DigitalMenu>>
): JSX.Element[] => [
  <div className="steps" onClick={() => setDigitalTabValue(DigitalMenu.TEXT)}>
    <Typography variant="body2">
      {t('workflow.digital_menu.next_step')}
    </Typography>
    <ArrowForwardIosIcon className="arrow" />
  </div>,
  <div
    className="steps left"
    onClick={() => setDigitalTabValue(DigitalMenu.VISUAL)}
  >
    <ArrowBackIosIcon className="arrow" />
    <Typography variant="body2">
      {t('workflow.digital_menu.previous_step')}
    </Typography>
  </div>,
];

interface DigitalTabsProps {
  digitalTabValue: DigitalMenu;
  setDigitalTabValue: React.Dispatch<React.SetStateAction<DigitalMenu>>;
}
const DigitalTabs = ({
  digitalTabValue,
  setDigitalTabValue,
}: DigitalTabsProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div css={style}>
      <Tabs
        className="digital-tabs"
        value={digitalTabValue}
        orientation="horizontal"
        centered
        variant="fullWidth"
        onChange={(e, index) => setDigitalTabValue(index)}
        indicatorColor="primary"
      >
        <Tab
          className="tab"
          label={
            <>
              <div className="badge">1</div>
              <Typography variant="body2">
                {t('workflow.digital_menu.visual')}
              </Typography>
            </>
          }
          value={DigitalMenu.VISUAL}
        />
        <Tab
          className="tab"
          label={
            <>
              <div className="badge">2</div>
              <Typography variant="body2">
                {t('workflow.digital_menu.text')}
              </Typography>
            </>
          }
          value={DigitalMenu.TEXT}
        />
      </Tabs>
      {steps(t, setDigitalTabValue)[digitalTabValue]}
    </div>
  );
};

export default DigitalTabs;
