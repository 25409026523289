import { useQuery, WatchQueryFetchPolicy } from '@apollo/client';
import {
  GetBasketDocuments,
  GetBasketDocumentsVariables,
} from 'app/schemaInterfaces/GetBasketDocuments';
import { BASKET_DOCUMENTS } from 'app/graphql/queries/basket';
import {
  BasketFilterInput,
  PaginationInput,
} from 'app/schemaInterfaces/globalTypes';

interface useBasketDocumentsQueryProps {
  filter?: BasketFilterInput;
  pagination?: PaginationInput;
  fetchPolicy?: WatchQueryFetchPolicy;
  skip?: boolean;
}

const useBasketDocumentsQuery = ({
  filter = {},
  pagination = {},
  fetchPolicy = 'network-only',
  skip,
}: useBasketDocumentsQueryProps) =>
  useQuery<GetBasketDocuments, GetBasketDocumentsVariables>(BASKET_DOCUMENTS, {
    fetchPolicy,
    variables: {
      filter,
      pagination,
    },
    skip,
  });

export default useBasketDocumentsQuery;
