import React, { useState } from 'react';
import { ActionsMenu, ActionsMenuProps } from './ActionsMenu';

type SimpleActionsMenuProps = Pick<
  ActionsMenuProps,
  | 'actions'
  | 'button'
  | 'anchorOrigin'
  | 'transformOrigin'
  | 'isDisabled'
  | 'maxWidth'
  | 'isNonInteractive'
>;

const SimpleActionsMenu = (props: SimpleActionsMenuProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <ActionsMenu
      {...props}
      handleMenuClick={handleClick}
      handleMenuClose={handleClose}
      anchorEl={anchorEl}
      closeOnItemClick={true}
    />
  );
};

export default SimpleActionsMenu;
