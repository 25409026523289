import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { ActionsMenu } from 'components/ActionsMenu/ActionsMenu';
import { useDropzone } from 'react-dropzone';
import {
  createProductSheetActions,
  createProductSheetForOperationActions,
} from 'modules/pim/baseProducts/ProductsHelper';
import ProductsSheetFormCreation from 'modules/pim/baseProducts/ProductsSheetFormCreation';
import { useProductsSheetsContext } from 'modules/pim/baseProducts/BaseProducts';
import colors from 'styles/colors.module.scss';
import ProductsSheetsFormAssetsTabs from 'modules/pim/components/ProductsSheetsFormAssetsTabs';
import DetachableFormWrapper from 'components/DetachableFormWrapper/DetachableFormWrapper';
import { ACCEPTED_EXCEL_TYPES } from 'modules/dam/form/helpers';
import { useTranslation } from 'react-i18next';
import ProductsSheetsFormProductsPanel from '../components/ProductsSheetsFormProductsPanel';

interface ProductsSheetCreationProps {
  uploadFile: (acceptedFiles: File[]) => void;
  isCampaign?: boolean;
  campaignId?: string | null;
  productId?: string;
}

const ProductsSheetCreation = ({
  uploadFile,
  isCampaign,
  campaignId,
  productId,
}: ProductsSheetCreationProps): JSX.Element => {
  const [openProductsSheetDialog, setOpenProductsSheetDialog] = useState<
    boolean
  >(false);
  const [
    isCreateProductsSheetDialogOpen,
    setIsCreateProductsSheetDialogOpen,
  ] = useState<HTMLButtonElement | null>(null);

  const {
    setFieldName,
    setFormFilesManager,
    setFilesLimitNumber,
    fieldName,
  } = useProductsSheetsContext();
  const [getAssetId, setGetAssetId] = useState<string | undefined>(undefined);
  const { t } = useTranslation();

  const handleOpenProductsSheetDialog = () => {
    setFieldName(undefined);
    setFormFilesManager([]);
    setFilesLimitNumber(0);
    setOpenProductsSheetDialog(true);
    setIsCreateProductsSheetDialogOpen(null);
  };
  const handleCloseProductsSheetDialog = () => {
    setFormFilesManager([]);
    setOpenProductsSheetDialog(false);
  };

  const handleOpenProductsSheetActions = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setIsCreateProductsSheetDialogOpen(event.currentTarget);
  };

  const handleCloseProductsSheetActions = () => {
    setIsCreateProductsSheetDialogOpen(null);
  };

  const { getInputProps, open } = useDropzone({
    accept: ACCEPTED_EXCEL_TYPES,
    onDrop: (acceptedFiles: File[]) => {
      setIsCreateProductsSheetDialogOpen(null);
      uploadFile(acceptedFiles);
    },
  });
  const getDetachableChildren = () => {
    if (fieldName === t('pim.field_name.regrouping')) {
      return <ProductsSheetsFormProductsPanel />;
    } else {
      return (
        <ProductsSheetsFormAssetsTabs
          setGetAssetId={setGetAssetId}
          getAssetId={getAssetId}
          productId={productId}
        />
      );
    }
  };

  const label =
    fieldName === t('pim.field_name.regrouping')
      ? t('pim.regrouping.add_regrouping')
      : t('pim.form.add_visual');

  return (
    <Box>
      <ActionsMenu
        actions={
          !isCampaign
            ? createProductSheetActions({
                open,
                handleOpenProductsSheetDialog,
                t,
              })
            : createProductSheetForOperationActions({
                open,
                handleOpenProductsSheetDialog,
                campaignId,
                t,
              })
        }
        button={
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddCircleIcon />}
          >
            {t('global.table_toolbar.create')}
          </Button>
        }
        handleMenuClick={handleOpenProductsSheetActions}
        handleMenuClose={handleCloseProductsSheetActions}
        anchorEl={isCreateProductsSheetDialogOpen}
      />
      <input {...getInputProps()} />
      <DetachableFormWrapper
        isOpen={openProductsSheetDialog}
        handleClose={handleCloseProductsSheetDialog}
        title={t('pim.form.create_title')}
        detachable={{
          children: getDetachableChildren,
          isOpen: !!fieldName,
          goBack: {
            label: label,
            handleClose: () => setFieldName(undefined),
          },
          bgcolor: colors.documentsHeaderBackground,
        }}
      >
        <ProductsSheetFormCreation
          handleClose={handleCloseProductsSheetDialog}
          campaignId={campaignId as string}
          productId={productId}
        />
      </DetachableFormWrapper>
    </Box>
  );
};

export default ProductsSheetCreation;
