import {
  Box,
  FormLabel,
  Container,
  Grid,
  TextField,
  Typography,
  FormControl,
  TextFieldProps,
} from '@material-ui/core';
import colors from 'styles/colors.module.scss';
import { handleIntegersOnly } from 'app/utils/common';
import { TRANSLATION_ROOT } from 'modules/oneManager/storeHelper';
import React from 'react';
import { useTranslation } from 'react-i18next';

const DeliveryAddress = (): JSX.Element => {
  const { t } = useTranslation();

  const textFieldsProps: TextFieldProps = {
    variant: 'outlined',
    margin: 'dense',
    size: 'small',
    fullWidth: true,
  };

  return (
    <FormControl component="fieldset" fullWidth>
      <Box p={2} mb={2} bgcolor={colors.documentsHeaderBackground}>
        <Typography variant="h4">
          {t(`${TRANSLATION_ROOT}.delivery_address_form.address.title`)}
        </Typography>
      </Box>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormLabel>
              {t(`${TRANSLATION_ROOT}.delivery_address_form.address.name`)}
            </FormLabel>
            <TextField {...textFieldsProps} />
          </Grid>
          <Grid item xs={12}>
            <FormLabel>
              {t(`${TRANSLATION_ROOT}.delivery_address_form.address.street`)}
            </FormLabel>
            <TextField {...textFieldsProps} />
          </Grid>
          <Grid item xs={12}>
            <FormLabel>
              {t(`${TRANSLATION_ROOT}.delivery_address_form.address.city`)}
            </FormLabel>
            <TextField {...textFieldsProps} />
          </Grid>
          <Grid container item xs={3} direction="column">
            <FormLabel>
              {t(`${TRANSLATION_ROOT}.delivery_address_form.address.zip_code`)}
            </FormLabel>
            <TextField {...textFieldsProps} onKeyPress={handleIntegersOnly} />
          </Grid>
          <Grid item xs={12}>
            <FormLabel>
              {t(
                `${TRANSLATION_ROOT}.delivery_address_form.address.additional_address`
              )}{' '}
            </FormLabel>
            <TextField
              {...textFieldsProps}
              placeholder={t(
                `${TRANSLATION_ROOT}.delivery_address_form.address.building`
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel>
              {t(
                `${TRANSLATION_ROOT}.delivery_address_form.address.additional_address`
              )}{' '}
              2
            </FormLabel>
            <TextField
              {...textFieldsProps}
              placeholder={t(
                `${TRANSLATION_ROOT}.delivery_address_form.address.floor`
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel>
              {t(`${TRANSLATION_ROOT}.delivery_address_form.address.country`)}
            </FormLabel>
            <TextField
              {...textFieldsProps}
              placeholder={t(
                `${TRANSLATION_ROOT}.delivery_address_form.address.country`
              )}
            />
          </Grid>
        </Grid>
      </Container>
    </FormControl>
  );
};

export default DeliveryAddress;
