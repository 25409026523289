import colors from 'styles/colors.module.scss';
import { css } from '@emotion/react/macro';

const style = css`
  width: 100%;

  .content {
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p {
      color: ${colors.grey};

      span.divider {
        margin: 0 8px;
      }
    }

    h3 {
      line-height: 100%;
      display: inline-block;
      margin-right: 16px;
      color: ${colors.body};

      & + span {
        border-radius: 25px;
        max-width: 100%;
        position: absolute;
        padding: 4px 10px;
        padding-top: 6px;
        margin-top: -4px;
      }
    }

    path {
      fill-opacity: 1;
    }

    .tag {
      border-radius: 40px;

      &.tagDisabled {
        color: ${colors.disabled};
        background: ${colors.documentsHeaderBackground};
      }
    }
  }

  &.campaign-row {
    .media {
      max-width: 200px;

      img {
        border-radius: 10px;
        height: 130px;
        object-fit: cover;
        width: 100%;
      }
    }
  }

  &.document-row {
    .MuiGrid-container {
      justify-content: space-between;
    }
    .media-documentInfo {
      display: flex;
    }
    .media-wrapper {
      background: ${colors.documentsHeaderBackground};
      display: flex;
      justify-content: center;
      height: 53px;
      width: 53px;
      align-items: center;
      align-self: center;
      position: relative;

      img {
        border-radius: 0;
        object-fit: contain;
        width: 32.56px;
        height: 45.81px;
      }
      &.delivery {
        img {
          width: 32.56px;
          height: 45.81px;
          align-self: center;
        }
      }
      .MuiIconButton-root {
        color: ${colors.primary};
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }

    .quantity-input {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      h5 {
        font-weight: 500;
      }
    }
    .price-input {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      p {
        color: ${colors.grey};
      }
    }
    .grid-button {
      align-self: center;
    }
    .documentInfo {
      display: flex;
      align-items: center;
      width: 300px;
    }
    .content {
      padding-right: 5px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
    .content-quantity {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      h4 {
        display: 'inline-block';
      }
    }

    .reference {
      display: flex;
      padding-top: 10px;

      h5 {
        font-weight: 500;
        color: ${colors.body};
        white-space: nowrap;
        overflow: hidden;
        width: 110px;
        text-overflow: ellipsis;
        svg {
          width: 20px;
          height: 20px;
          margin-right: 12px;
          margin-bottom: -6px;
        }
        &.ref-title {
          color: ${colors.grey};
        }
        &.code {
          padding-left: 32px;
        }
      }
      .MuiDivider-root {
        margin-top: 8px;
        height: 31px;
        width: 2px;
      }
    }
  }

  .editButton {
    margin-top: 8px;
  }
`;

export default style;
