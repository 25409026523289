import { CircularProgress } from '@material-ui/core';
import useAppContext from 'app/app-context/useAppContext';
import { GetAssetById_getAssetById } from 'app/schemaInterfaces/GetAssetById';
import { GetProductsWithVisualIdentity } from 'app/schemaInterfaces/GetProductsWithVisualIdentity';
import { Optional } from 'app/utils/common';
import { useInfiniteScroll } from 'components/infinite-scroll/useInfiniteScroll';
import {
  DivisionElement,
  GetProductsWithAssets,
  useProductsWithAssets,
} from 'components/useProductsWithAssets';
import { FileStore } from 'modules/pim/baseProducts/PimFormFieldsConfig';
import {
  getUnifiedKeyFromPimClientConfig,
  VisuelFieldName,
} from 'modules/pim/baseProducts/ProductsHelper';
import { UnifiedProductKeyToRenderOnPage } from 'modules/workflow/containers/Leftsidebar/ProductsPanel/ProductsView';
import React, { useCallback } from 'react';
import PimCardList from './PimCardList';

interface PimProductsViewProps {
  divisions: DivisionElement[];
  productId?: string;
  searchValue?: string;
  setSelectedProduct: React.Dispatch<
    React.SetStateAction<ProductsDataNormalized | undefined>
  >;
}

export interface ProductsDataNormalized extends GetProductsWithAssets {
  image?: string;
  name?: string;
}

export const normalizedAsset = (
  asset: GetAssetById_getAssetById | undefined,
  size: number
) => ({
  url: asset?.displayedThumbUrl as string,
  id: asset?.id as string,
  name: asset?.name as string,
  metadata: {
    dimensions: {
      width: asset?.metadata.dimensions?.width as number,
      height: asset?.metadata.dimensions?.height as number,
    },
  },
  //We set productImage size to cardSize-priceSize
  size: { width: size, height: size },
  position: { x: 0, y: 0 },
});

const PimProductsView = ({
  divisions,
  productId,
  searchValue,
  setSelectedProduct,
}: PimProductsViewProps): JSX.Element => {
  const {
    client: { config: clientConfig },
  } = useAppContext();

  const {
    productsDataWithUrl,
    productsData,
    loading,
    fetchMore,
  } = useProductsWithAssets(
    divisions,
    undefined,
    undefined,
    undefined,
    searchValue
  );

  const productNormalizedImage = (product?: GetProductsWithAssets) => {
    const mainVisual: Optional<FileStore> = product?.details.filesToSend?.find(
      (file: FileStore) => file.fieldName === VisuelFieldName.mainVisuel
    );

    return product?.assets?.find((asset) => asset.id === mainVisual?.assetId)
      ?.displayedThumbUrl;
  };

  const productNormalizedName = (product?: GetProductsWithAssets) =>
    product?.details[
      getUnifiedKeyFromPimClientConfig(
        UnifiedProductKeyToRenderOnPage.title,
        clientConfig
      )
    ];

  const productsDataNormalized: ProductsDataNormalized[] = productsDataWithUrl?.map(
    (product) => ({
      ...(product as GetProductsWithAssets),
      image: productNormalizedImage(product),
      name: productNormalizedName(product),
      campaignId: (product as GetProductsWithAssets).campaignId,
    })
  );

  const loadMoreProducts = useCallback(() => {
    if (!loading && productsData?.result.products?.length) {
      fetchMore({
        variables: {
          pagination: {
            offset: productsData?.result?.products?.length,
            limit: 5,
          },
        },
        updateQuery: (
          prevData: GetProductsWithVisualIdentity,
          { fetchMoreResult }
        ): GetProductsWithVisualIdentity => {
          if (!fetchMoreResult || fetchMoreResult.result.products.length === 0)
            return prevData;
          return {
            result: {
              ...prevData.result,
              products: [
                ...prevData.result.products,
                ...fetchMoreResult.result.products,
              ],
            },
          };
        },
      });
    }
  }, [fetchMore, loading, productsData?.result?.products?.length]);

  const waypointRef = useInfiniteScroll(loadMoreProducts, loading);

  const isProductUsed = (id: string) => id === productId;

  const productsDataSortWithType = (
    productsSortByName: ProductsDataNormalized[]
  ) => {
    return (
      <>
        <PimCardList
          items={productsSortByName}
          enableClick={isProductUsed}
          setSelectedProduct={setSelectedProduct}
        />
      </>
    );
  };

  return (
    <>
      <div>
        {productsDataNormalized &&
          productsDataNormalized.length > 0 &&
          productsDataSortWithType(productsDataNormalized)}

        {loading && (
          <div className="progress">
            <CircularProgress color="inherit" />
          </div>
        )}
        {!loading &&
          productsData &&
          productsData.result.products.length > 0 && (
            <div id="waypoint" ref={waypointRef} />
          )}
      </div>
    </>
  );
};

export default PimProductsView;
