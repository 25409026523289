import { css, CSSObject } from '@emotion/react/macro';
import 'ress/dist/ress.min.css';
import colors from 'styles/colors.module.scss';
import { fonts } from 'styles/theme';

const globalStyle = css`
  body {
    overflow-y: scroll;
    background-color: ${colors.background};
    color: ${colors.body};
    font-family: ScandiaWebRegular, ScandiaWebMedium, ScandiaWebLight,
      ScandiaWebBold, ScandiaWebBoldItalic, ScandiaWebLightItalic,
      ScandiaWebMediumItalic, ScandiaWebRegularItalic, ScandiaWebStencil, Roboto,
      Helvetica Neue, Arial, sans-serif !important;
  }

  ${fonts as CSSObject}

  h4 {
    font-family: ScandiaWebMedium;
    font-weight: 500;
  }

  h6 {
    font-weight: 500;
  }

  hr {
    background: none;
    border: none;
    border-top: 1px solid ${colors.buttonGroupOutlined};
  }

  .hide {
    display: none;
  }
  .flex {
    display: flex;
  }
  .app-container {
    margin-left: 42px;
    margin-right: 42px;
  }
`;

export default globalStyle;
