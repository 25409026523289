import { css } from '@emotion/react/macro';
import colors from 'styles/colors.module.scss';

export const toogleButtonStyle = css`
  .MuiToggleButtonGroup-grouped {
    margin-right: 16px;
    margin-bottom: 24px;
    padding: 11px 16px;
    border: 1px solid ${colors.buttonGroupOutlined};
    border-radius: 24px;
    color: ${colors.black};
  }
  .MuiToggleButtonGroup-grouped.Mui-selected {
    margin-right: 16px;
    margin-bottom: 24px;
    border-left: 1px solid ${colors.primary} !important;
    color: ${colors.primary};
    border: 1px solid ${colors.primary};
    background-color: ${colors.menuHover};
  }
  .MuiToggleButtonGroup-grouped:hover {
    background-color: ${colors.documentsHeaderBackground};
  }
`;
