import { Box, Button, ButtonProps } from '@material-ui/core';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import React, { FC } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';
import { downloadFileButtonStyle } from './DownloadFileButton.style';

export interface DownloadFileButtonProps {
  disabled?: boolean;
  className?: string;
  isDownloading: boolean;
  progress?: number;
  onDownload: (e: React.MouseEvent<HTMLButtonElement>) => void;
  variant?: ButtonProps['variant'];
}

const DownloadFileButton: FC<DownloadFileButtonProps> = ({
  className,
  disabled = false,
  isDownloading,
  onDownload,
  variant,
}) => {
  const { t } = useTranslation();
  const icon = isDownloading ? (
    <CircularProgress size={20} data-testid="progress-icon" />
  ) : (
    <GetAppOutlinedIcon data-testid="download-icon" />
  );

  return (
    <Box className={className} display="inline-flex">
      <Button
        color="primary"
        variant={variant}
        css={downloadFileButtonStyle}
        disabled={disabled}
        startIcon={icon}
        onClick={onDownload}
      >
        {t('dam.action_toolbar.download')}
      </Button>
    </Box>
  );
};

export default DownloadFileButton;
