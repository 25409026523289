import { FormLabel, MenuItem } from '@material-ui/core';
import { TemplateType } from 'app/schemaInterfaces/globalTypes';
import { Field, useFormikContext } from 'formik';
import { TextField, Select } from 'formik-material-ui';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentFormValues } from '../model/definitions';
import { templateFormStyle } from './DocumentTemplateSubform.style';

const DocumentTemplateSubform: FC = () => {
  const { t } = useTranslation();
  const { values } = useFormikContext<DocumentFormValues>();

  return (
    <div className="field-group" css={templateFormStyle}>
      <div className="field">
        <FormLabel>{t('global.document_form.template.type_label')}</FormLabel>
        <Field
          component={Select}
          className="text-field"
          name="document.template.type"
          variant="outlined"
          margin="dense"
        >
          {Object.values(TemplateType).map((type, index) => (
            <MenuItem value={type} key={index}>
              {t('global.document_form.template.type', { context: type })}
            </MenuItem>
          ))}
        </Field>
      </div>
      {values.document.template.type === TemplateType.CHILI && (
        <div className="field">
          <FormLabel>
            {t('global.document_form.template.chili_id_label')}
          </FormLabel>
          <Field
            component={TextField}
            className="text-field"
            name="document.template.metadata.chili.templateId"
            variant="outlined"
            margin="dense"
            placeholder={t(
              'global.document_form.template.chili_id_placeholder'
            )}
          />
        </div>
      )}
    </div>
  );
};

export default DocumentTemplateSubform;
