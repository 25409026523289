import { gql } from '@apollo/client';
import { ASSET_DETAIL } from './dam';

export const PRODUCT_VISUALS_IDENTITIES = gql`
  fragment VisualIdentities on VisualIdentity {
    asset {
      ...AssetDetail
    }
    clientId
    formFieldDetails
    formFieldKey
    index
    metadata {
      discountValue
      supportId
      fontFamily
      fontSize
      fontWeight
      fontStyle
      lineThrough
      lineHeight
      display
      isDraggable
      isResizable
      isCapitalLetters
      color
      position {
        x
        y
      }
      size {
        height
        width
      }
    }
    priceElement
    type
    visualKey
  }
  ${ASSET_DETAIL}
`;

export const GET_PRODUCTS = gql`
  query GetProducts(
    $filter: ProductFilterInput
    $pagination: PaginationInput
    $isSortByDate: Boolean
    $campaignId: String
  ) {
    getProducts(
      filter: $filter
      pagination: $pagination
      isSortByDate: $isSortByDate
      campaignId: $campaignId
    ) {
      products {
        _id
        clientId
        createdAt
        createdBy
        details
        updatedAt
        updatedBy
        history {
          fieldName
          oldValue
          newValue
          updatedAt
          updatedBy
        }
        regrouping {
          details
          isMain
          productId
          isVisible
          assetId
          createdDate
          userId
          asset {
            ...AssetDetail
          }
        }
      }
      valuesOptionsDivisionFilter {
        key
        label
        values
      }
      allWithPrice
      allWithVisual
    }
  }
  ${ASSET_DETAIL}
`;

export const GET_PRODUCTS_DIVISIONS = gql`
  query GetProductsDivisions(
    $campaignId: String
    $filter: ProductFilterInput
    $pagination: PaginationInput
    $isSortByDate: Boolean
  ) {
    pimProducts: getProducts(
      filter: $filter
      pagination: $pagination
      isSortByDate: $isSortByDate
    ) {
      valuesOptionsDivisionFilter {
        key
        label
        values
      }
      allWithPrice
      allWithVisual
    }
    campaignProducts: getProducts(
      campaignId: $campaignId
      filter: $filter
      pagination: $pagination
      isSortByDate: $isSortByDate
    ) {
      valuesOptionsDivisionFilter {
        key
        label
        values
      }
      allWithPrice
      allWithVisual
    }
  }
`;

// TODO: this is temporary, we'll use GET_PRODUCTS with minimal info later
export const GET_PRODUCTS_WITH_VISUAL_IDENTITY = gql`
  ${PRODUCT_VISUALS_IDENTITIES}
  query GetProductsWithVisualIdentity(
    $campaignId: String
    $filter: ProductFilterInput
    $pagination: PaginationInput
    $isSortByDate: Boolean
    $isSortByTitle: Boolean
    $usedInCampaignId: String
  ) {
    result: getProducts(
      campaignId: $campaignId
      filter: $filter
      pagination: $pagination
      isSortByDate: $isSortByDate
      isSortByTitle: $isSortByTitle
    ) {
      products {
        _id
        campaignId
        documentId
        clientId
        createdAt
        createdBy
        details
        regrouping {
          details
          isMain
          productId
          isVisible
          assetId
          createdDate
          userId
          asset {
            ...AssetDetail
          }
        }
        isUsedInCampaign(campaignId: $usedInCampaignId)
        updatedAt
        updatedBy
        visualIdentities {
          ...VisualIdentities
        }
      }
      valuesOptionsDivisionFilter {
        key
        label
        values
      }
      allWithPrice
      allWithVisual
    }
  }
  ${ASSET_DETAIL}
`;

export const IMPORT_PRODUCTS = gql`
  mutation ImportProducts($file: Upload!, $campaignId: String) {
    importProducts(file: $file, campaignId: $campaignId) {
      clientId
      _id
      createdAt
      createdBy
      details
      updatedAt
      updatedBy
      history {
        fieldName
        oldValue
        newValue
        updatedAt
        updatedBy
      }
    }
  }
`;

export const CREATE_PRODUCT_SHEET = gql`
  mutation CreateProductSheet(
    $details: Object!
    $campaignId: String
    $regrouping: [RegroupingInput!]
  ) {
    createProduct(
      details: $details
      campaignId: $campaignId
      regrouping: $regrouping
    ) {
      _id
      campaignId
      clientId
      createdAt
      createdBy
      details
      updatedAt
      updatedBy
      history {
        fieldName
        oldValue
        newValue
        updatedAt
        updatedBy
      }
      regrouping {
        details
        isMain
        productId
        isVisible
        assetId
        createdDate
        userId
      }
    }
  }
`;

export const GET_PRODUCT_BY_ID = gql`
  query GetProductById($productId: String!) {
    getProduct(id: $productId) {
      _id
      clientId
      createdAt
      createdBy
      details
      campaignId
      documentId
      updatedAt
      updatedBy
      rootProductId
      history {
        fieldName
        oldValue
        newValue
        updatedAt
        updatedBy
      }
      regrouping {
        details
        isMain
        productId
        isVisible
        assetId
        createdDate
        userId
        asset {
          ...AssetDetail
        }
      }
    }
  }
  ${ASSET_DETAIL}
`;

export const UPDATE_PRODUCT_SHEET = gql`
  ${PRODUCT_VISUALS_IDENTITIES}
  mutation UpdateProduct(
    $details: Object!
    $productId: String!
    $rootProductId: String
    $history: [ProductHistoryInput!]!
    $regrouping: [RegroupingInput!]!
  ) {
    updateProduct(
      details: $details
      id: $productId
      history: $history
      rootProductId: $rootProductId
      regrouping: $regrouping
    ) {
      _id
      clientId
      campaignId
      rootProductId
      createdAt
      createdBy
      details
      updatedAt
      updatedBy
      history {
        fieldName
        oldValue
        newValue
        updatedAt
        updatedBy
      }
      regrouping {
        details
        isMain
        productId
        isVisible
        assetId
        createdDate
        userId
        asset {
          ...AssetDetail
        }
      }
      visualIdentities {
        ...VisualIdentities
      }
    }
  }
  ${ASSET_DETAIL}
`;

export const EXPORT_MULTIPLE_PRODUCTS = gql`
  query ExportMultipleProducts($productIds: [String!]!) {
    exportProducts(productIds: $productIds)
  }
`;

export const COPY_PRODUCTS = gql`
  ${PRODUCT_VISUALS_IDENTITIES}

  mutation CopyProducts(
    $productIds: [String!]!
    $campaignId: String
    $documentId: String
  ) {
    copyProducts(
      productIds: $productIds
      campaignId: $campaignId
      documentId: $documentId
    ) {
      _id
      clientId
      campaignId
      createdAt
      createdBy
      details
      rootProductId
      updatedAt
      updatedBy
      history {
        fieldName
        oldValue
        newValue
        updatedAt
        updatedBy
      }
      visualIdentities {
        ...VisualIdentities
      }
      regrouping {
        details
        isMain
        productId
        isVisible
        assetId
        createdDate
        userId
        asset {
          ...AssetDetail
        }
      }
    }
  }
  ${ASSET_DETAIL}
`;

export const REMOVE_MULTIPLE_PRODUCTS = gql`
  mutation RemoveMultipleProducts($productIds: [String!]!) {
    removeMultipleProducts(productIds: $productIds)
  }
`;
