import { TFunction } from 'i18next';

export const getTabsConfig = (t: TFunction) => [
  {
    value: 'ALL',
    label: t('homepage.available_documents.all'),
  },
  {
    value: 'NEW',
    label: t('homepage.available_documents.new'),
  },
  { value: 'HISTORY', label: t('homepage.available_documents.history') },
];
