import { Box } from '@material-ui/core';
import { BoxProps } from '@material-ui/core/Box/Box';
import React, { CSSProperties } from 'react';

const stickyStyles: CSSProperties = {
  position: 'fixed',
  top: 0,
  width: '100%',
  right: 0,
};

interface StickyBoxProps extends BoxProps {
  isSticky: boolean;
}

export const StickyBox = React.forwardRef<HTMLDivElement, StickyBoxProps>(
  ({ isSticky, children, ...rest }, ref) => {
    return (
      // There is an issue on this in github/mui
      // @ts-ignore */
      <Box position="relative" {...rest} ref={ref} zIndex={1000}>
        <Box style={isSticky ? stickyStyles : undefined}>{children}</Box>
      </Box>
    );
  }
);
