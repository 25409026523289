import React, { FC } from 'react';
import { Box, FormControl, FormLabel, IconButton } from '@material-ui/core';
import { FieldProps } from 'formik';
import FileUploadIcon, { InitialImage } from '../FileUploadIcon/FileUploadIcon';
import colors from 'styles/colors.module.scss';
import { BackgroundSizeCssProps } from 'app/utils/common';
import { useControlledFileUpload } from './ControlledFileUpload.hook';

export interface PreviewFile extends File {
  preview: string;
  path?: string;
  base64?: string;
  extension?: string;
}

export interface ControlledFileUploadProps {
  onDropFormikField: (name: string, acceptedFiles: File[]) => void;
  IsLargePreview?: boolean;
  initialImage?: InitialImage;
  accept?: string | string[];
  previewBackgroundSize?: BackgroundSizeCssProps;
  isLoading?: boolean;
  setIsLoading?: (isLoading: boolean) => void;
  previewWidth?: number;
  previewHeight?: number;
}
const ControlledFileUpload: FC<FieldProps & ControlledFileUploadProps> = ({
  form: { errors, touched, setTouched },
  field: { name },
  initialImage,
  IsLargePreview,
  onDropFormikField,
  previewBackgroundSize,
  accept = '*',
  isLoading,
  setIsLoading,
  previewWidth,
  previewHeight,
}) => {
  const {
    getRootProps,
    getInputProps,
    hasNoUploadedImage,
    filesPreview,
    open,
    setFilesPreview,
  } = useControlledFileUpload({
    onDropFormikField,
    accept,
    setTouched,
    touched,
    name,
    setIsLoading,
    initialImage,
  });

  return (
    <>
      <Box
        data-testid="component"
        display="flex"
        justifyContent="center"
        {...getRootProps({ className: 'dropzone' })}
        pb={1}
      >
        <input {...getInputProps()} multiple={false} />
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
        >
          <FileUploadIcon
            files={filesPreview}
            initialImage={initialImage}
            IsLargePreview={IsLargePreview}
            fileUploadBackground={
              hasNoUploadedImage ? colors.fileUploadBackground : colors.workzone
            }
            previewBackgroundSize={previewBackgroundSize}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            previewWidth={previewWidth}
            previewHeight={previewHeight}
            openDialog={open}
            setFilesPreview={setFilesPreview}
          />
        </IconButton>
      </Box>
      <Box textAlign="center">
        <FormControl error={touched[name] && Boolean(errors[name])} fullWidth>
          <FormLabel>{touched[name] && errors[name]}</FormLabel>
        </FormControl>
      </Box>
    </>
  );
};
export default ControlledFileUpload;
