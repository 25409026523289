import {
  GetEntityById,
  GetEntityByIdVariables,
} from 'app/schemaInterfaces/GetEntityById';
import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';

import CurrentEntityContext from './CurrentEntityContext';
import { GetEntities_entities as Entity } from 'app/schemaInterfaces/GetEntities';
import { GET_ENTITY_BY_ID } from 'app/graphql/queries/entities';
import Loader from 'components/Loader/Loader/Loader';
import { Maybe } from 'app/utils/common';
import { UserType } from 'app/schemaInterfaces/globalTypes';
import { isNotNull } from 'app/utils/type-guards';
import useAppContext from 'app/app-context/useAppContext';
import { useCurrentUser } from 'app/auth/UserContext';
import { useLazyQuery } from '@apollo/client';

export const CURRENT_ENTITY = 'current-entity';

const CurrentEntityProvider = ({ children }: PropsWithChildren<{}>) => {
  const user = useCurrentUser();
  const {
    client: { structures },
  } = useAppContext();

  const visibleStructures = useMemo(
    () => structures.filter(({ isBillable, level }) => isBillable && level > 0),
    [structures]
  );

  const isInVisibleStructure = (entity?: Entity) =>
    visibleStructures.some(({ level }) => entity?.level === level);

  const [getEntityById, { loading, data: entityData }] = useLazyQuery<
    GetEntityById,
    GetEntityByIdVariables
  >(GET_ENTITY_BY_ID);

  const [currentEntity, setCurrentEntity] = useState<Maybe<Entity>>(() => {
    const entityLocalStorage = localStorage.getItem(CURRENT_ENTITY);
    if (
      !!entityLocalStorage &&
      isInVisibleStructure(JSON.parse(entityLocalStorage))
    ) {
      return JSON.parse(entityLocalStorage);
    } else {
      return user.type === UserType.Global
        ? null
        : user.entities
            .map((entity) => {
              if (entity.isBillable) return entity;
              // Skip non billable entities and replace them with their billable ancestor
              const entities = [entity, ...entity.ancestors];
              const index = entities.findIndex((entity) => entity.isBillable);
              if (index < 0) {
                return null;
              }
              return {
                ...entities[index],
                ancestors: entities.slice(index + 1),
              };
            })
            .filter(isNotNull)
            .filter((entity) =>
              visibleStructures.some(({ level }) => entity.level === level)
            )
            // sort by level and name
            .sort((entity1, entity2) =>
              entity1.level === entity2.level
                ? entity1.name.localeCompare(entity2.name)
                : entity1.level - entity2.level
            )[0] ?? null;
    }
  });

  const fetchAndSetCurrentEntity = (id?: string) => {
    if (id && entityData?.entity.id === id) {
      // lazy query is not retriggered when not changing the variables,
      // thus useEffect setting the current entity is not retriggered
      // so we need to do it manually here
      setCurrentEntity(entityData.entity);
    } else if (id) {
      getEntityById({ variables: { id } });
    } else {
      setCurrentEntity(null);
    }
  };

  useEffect(() => {
    if (entityData) {
      setCurrentEntity(entityData.entity);
    }
  }, [entityData]);

  useEffect(
    () =>
      currentEntity
        ? localStorage.setItem(
            CURRENT_ENTITY,
            JSON.stringify(currentEntity) ?? ''
          )
        : localStorage.removeItem(CURRENT_ENTITY),
    [currentEntity]
  );

  return (
    <CurrentEntityContext.Provider
      value={{
        visibleStructures,
        currentEntity,
        fetchAndSetCurrentEntity,
        setCurrentEntity,
      }}
    >
      {loading ? <Loader height="100vh" /> : children}
    </CurrentEntityContext.Provider>
  );
};

export default CurrentEntityProvider;
