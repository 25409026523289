import { ActionButton } from 'components/ActionsButtons/ActionButtons';
import {
  isItemPersonalizationCard,
  isItemProductCard,
} from 'modules/workflow/containers/CatalogPreview/EditPlatformDefinitions';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import React from 'react';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import { TFunction } from 'i18next';
import colors from 'styles/colors.module.scss';
import { PersonalizationType } from 'modules/workflow/oneStudioContext';
import { UserType } from 'app/schemaInterfaces/globalTypes';

interface SetActionButtons<T> {
  item: T;
  deleteItem: ActionButton<T>['onClick'];
  handleOpenDialog?: ActionButton<T>['onClick'];
  t: TFunction;
  isDisabled: boolean;
  isCustomization: boolean;
  userType: UserType;
}

export function setActionButtons<T>({
  item,
  deleteItem,
  handleOpenDialog,
  t,
  isDisabled,
  isCustomization,
  userType,
}: SetActionButtons<T>): ActionButton<T>[] {
  return [
    ...((isItemPersonalizationCard(item) &&
      item.type === PersonalizationType.TYPOGRAPHY) ||
    isItemProductCard(item)
      ? [
          {
            icon: (): JSX.Element => <CreateOutlinedIcon fontSize="small" />,
            onClick: handleOpenDialog,
            isDisabled,
          },
        ]
      : []),
    ...(!(
      isCustomization &&
      isItemPersonalizationCard(item) &&
      item.userType === UserType.Global &&
      userType === UserType.Local
    )
      ? [
          {
            icon: () => (
              <DeleteForeverOutlinedIcon
                fontSize="small"
                htmlColor={isDisabled ? colors.disabled : colors.tooltip}
              />
            ),
            onClick: deleteItem,
            confirmationDialog: {
              title: t('common.delete_confirmation_dialog.title'),
              description: t(
                'workflow.right_sidebar.products_panel.delete_confirmation_dialog.description'
              ),
            },
            isDisabled,
          },
        ]
      : []),
  ];
}
