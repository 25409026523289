import React, { FC, PropsWithChildren } from 'react';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

export interface PaperWrapperProps {
  condition?: boolean;
}

const PaperWrapper: FC<PropsWithChildren<PaperWrapperProps>> = ({
  condition,
  children,
}) => {
  return condition ? (
    <Paper data-testid="paper">
      <Box px={9} py={7}>
        {children}
      </Box>
    </Paper>
  ) : (
    <>{children}</>
  );
};

export default PaperWrapper;
