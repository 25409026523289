import { SelectedBasketdocument } from 'modules/sales/sales.state';
import { useBasketConfirmationPricingRequest } from 'modules/sales/utils/useBasketConfirmationPricingRequest';

export interface UseBasketConfirmationPreviewData {
  basketDocuments: SelectedBasketdocument[];
}

export function useBasketConfirmationPreview({
  basketDocuments,
}: UseBasketConfirmationPreviewData) {
  return useBasketConfirmationPricingRequest({
    products: basketDocuments.map((it) => it.basketDocument),
  });
}
