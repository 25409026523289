import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const stepLabelSubtitle = css`
  font-family: ScandiaWebRegular;
`;

export const stepLabelWarningStyle = css`
  .MuiStepLabel-label {
    color: ${colors.orange300};
  }
`;

export const stepLabelErrorStyle = css`
  .MuiStepLabel-label {
    color: ${colors.orangeAcid};
  }
`;

export const stepLabelSuccessStyle = css`
  .MuiStepLabel-label {
    color: ${colors.acidGreen};
  }
`;

export const stepStyle = css`
  .MuiStepConnector-alternativeLabel {
    top: 46px;
  }
  .MuiStepConnector-line {
    border-color: ${colors.loader3};
    border-top-width: 2px;
  }
  .MuiStepConnector-active,
  .MuiStepConnector-completed {
    .MuiStepConnector-line {
      border-color: ${colors.loader4};
    }
  }
`;

export const stepDateLabelStyle = css`
  margin-bottom: 10px;
  height: 21px;
  font-family: ScandiaWebMedium;
  text-align: center;
`;
