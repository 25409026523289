import { SelectedBasketdocument } from 'modules/sales/sales.state';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import colors from 'styles/colors.module.scss';
import { ChangeEvent, useState } from 'react';
import { useStoreActions } from 'state/store';
import useFabricationTimesQuery from 'queries/useFabricationTimesQuery';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

export const useDeliveryBasketRow = (
  basketDocumentItem: SelectedBasketdocument,
  setComment: (comment: string) => void
) => {
  const validatedIcon: JSX.Element = (
    <CheckCircleOutlineIcon
      htmlColor={colors.loader1}
      style={{ marginLeft: '12px' }}
    />
  );
  const [open, setOpen] = useState<boolean>(false);
  const [commentValue, setCommentValue] = useState<string>(
    basketDocumentItem.comment ?? ''
  );

  const setDeliveryDate = useStoreActions(
    (actions) => actions.salesModule.basketdocuments.setDeliveryDateToDocument
  );

  const totalQuantity = basketDocumentItem.addresses?.reduce(
    (acc, currentAddress) => {
      return acc + currentAddress.quantity;
    },
    0
  );
  const { data } = useFabricationTimesQuery({
    quantity: Boolean(totalQuantity) ? totalQuantity : 1,
    supportId: basketDocumentItem.basketDocument.document.supportId,
  });

  const openComment = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const saveComment = () => {
    setComment(commentValue);
    setOpen(false);
  };

  const handleCommentChange = (
    event: ChangeEvent<{ name?: string | undefined; value: string }>
  ) => {
    setCommentValue(event.target.value);
  };

  const handleChangeDeliveryDate = (date: MaterialUiPickersDate) => {
    setDeliveryDate({
      deliveryDate: date,
      documentId: basketDocumentItem.basketDocument.document.id,
    });
  };

  const handleClearDeliveryDate = () => {
    setDeliveryDate({
      documentId: basketDocumentItem.basketDocument.document.id,
    });
  };
  return {
    validatedIcon,
    open,
    data,
    openComment,
    handleClose,
    saveComment,
    handleCommentChange,
    handleChangeDeliveryDate,
    handleClearDeliveryDate,
    commentValue,
  };
};
