import React from 'react';
import { Divider, Grid, IconButton } from '@material-ui/core';
import itemRowStyle from 'styles/itemRow.style';
import {
  DEFAULT_IMAGE_PREVIEW,
  formatPrice,
  truncateString,
} from 'app/utils/common';
import { useTranslation } from 'react-i18next';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import FormatColorFillOutlinedIcon from '@material-ui/icons/FormatColorFillOutlined';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import colors from 'styles/colors.module.scss';
import { css } from '@emotion/react/macro';
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined';
import DocumentPreview from 'components/ConfirmationDialogs/DocumentPreview/DocumentPreview';
import { AddButtonProps } from 'modules/shop/shopHelpers';
import { filteredAttributes } from 'modules/sales/components/QuantitySelectHelper';
import AddToBasketButton from 'modules/shop/components/AddToBasketButton';
import { GetDocumentsToShop_documents_documents } from 'app/schemaInterfaces/GetDocumentsToShop';

interface OrderDocumentListProps {
  document: GetDocumentsToShop_documents_documents;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  addToBasket: () => void;
  buttonProps: AddButtonProps;
}

export const tagStyle = (color: string, backgroundColor: string) => css`
  background: ${backgroundColor};
  border-radius: 40px;
  color: ${color};
  font-family: ScandiaWebMedium;
  font-size: 12px;
  font-weight: 500;
  max-width: 150px;
  overflow: hidden;
  padding: 5px 10px;
  white-space: nowrap;
  width: fit-content;
  height: 27px;
`;

const OrderDocumentList = ({
  document,
  isOpen,
  setIsOpen,
  addToBasket,
  buttonProps,
}: OrderDocumentListProps) => {
  const { t } = useTranslation();
  return (
    <div
      css={itemRowStyle}
      className="document-row"
      style={{
        cursor: document.isDisabled.disabled ? '' : 'pointer',
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={5} className="media-documentInfo">
          <div className="media-wrapper">
            <img
              src={document.thumbnailUrl ?? DEFAULT_IMAGE_PREVIEW}
              alt="document"
            />
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                setIsOpen(true);
              }}
            >
              <FindInPageOutlinedIcon fontSize="small" />
            </IconButton>
          </div>
          <div className="content">
            <h5>
              {truncateString(document.name, 44)} -{' '}
              {truncateString(document.version, 44)}
            </h5>
            <div className="documentInfo">
              <p>
                {document.mediumType}
                <span className="divider">|</span>
                {t('local.common.number_of_pages', {
                  count: document.numberOfPages,
                })}
              </p>
              {document.isCustomizable && (
                <FormatColorFillOutlinedIcon
                  className="icon"
                  style={{ marginLeft: '8px', fontSize: '14px' }}
                />
              )}
              {document.isMandatory && (
                <div style={{ paddingLeft: '5px' }}>
                  <span css={tagStyle(colors.orange300, colors.orange100)}>
                    {t('global.document_form.is_mandatory.document_tag')}
                  </span>
                </div>
              )}
              {document.isRecommended && (
                <div style={{ paddingLeft: '5px' }}>
                  <span css={tagStyle(colors.primary, colors.menuHover)}>
                    {t('local.campaign_page.recommended')}
                  </span>
                </div>
              )}
            </div>
          </div>
        </Grid>
        {document.documentCommitmentCode && (
          <Grid item xs={4} className="reference">
            <div>
              <h5 className="ref-title">
                <LocalOfferOutlinedIcon color="primary" />
                {t('local.campaign_page.summary.reference')}{' '}
              </h5>
              <h5 className="code">
                {document.documentCommitmentCode.commitmentCode}{' '}
              </h5>
            </div>
            <Divider orientation="vertical" variant="middle" />
            <div>
              <h5 className="ref-title">
                {t('local.campaign_page.summary.valid_to')}{' '}
              </h5>
              <h5>{document.documentCommitmentCode.createdAt} </h5>
            </div>
            <div className="quantity-input">
              <ShoppingCartOutlinedIcon
                style={{
                  width: '24px',
                  marginRight: '10px',
                  color: colors.grey,
                }}
              />
              <h5>x{document.documentCommitmentCode.commitmentQuantity}</h5>
            </div>
          </Grid>
        )}

        {!document.documentCommitmentCode && document.priceInformations && (
          <Grid item xs={4} className="price-input">
            <p>
              {document.priceInformations.isIncluded
                ? ''
                : t('local.campaign_page.starting_price')}
            </p>
            <h4>
              {document.priceInformations.isIncluded ? (
                t('local.campaign_page.included')
              ) : (
                <p>
                  {formatPrice(document.priceInformations.priceMinimum, t)}{' '}
                  <sup> {t('common.money_unit_tax_free')}</sup>{' '}
                  {t('local.campaign_page.price_per_quantity', {
                    quantityMinimum:
                      document.priceInformations?.quantityMinimum,
                  })}
                </p>
              )}
            </h4>
          </Grid>
        )}
        {!document.documentCommitmentCode && (
          <Grid item xs={2} className="grid-button">
            <AddToBasketButton
              addToBasket={addToBasket}
              buttonProps={buttonProps}
              hasPrice={Boolean(document.priceInformations)}
            />
          </Grid>
        )}
      </Grid>
      <DocumentPreview
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title={document.name}
        version={document.version}
        url={document.thumbnailUrl}
        isCustomizable={document?.isCustomizable}
        category={document.category}
        mediumType={document.mediumType}
        attributes={filteredAttributes(document.productAttributes)}
      />
    </div>
  );
};

export default OrderDocumentList;
