import { TFunction } from 'i18next';

export const getStatusWording = ({
  isValidated,
  isObsolete,
  t,
}: {
  isValidated: boolean;
  isObsolete: boolean;
  t: TFunction;
}) =>
  isObsolete
    ? {
        title: t('global.documents_list.table.status_tag.obsolete'),
        tooltip: t('global.documents_list.table.status_tag.obsolete_tooltip'),
      }
    : isValidated
    ? {
        title: t('global.documents_list.table.status_tag.validated'),
        tooltip: '',
      }
    : {
        title: t('global.documents_list.table.status_tag.to_validate'),
        tooltip: t('global.documents_list.table.status_tag.tooltip'),
      };
