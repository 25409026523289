import { useAuth0 } from 'app/auth/AuthContext';
import useClientOffers from 'app/auth/useClientOffers';
import { useCurrentUser } from 'app/auth/UserContext';
import { ModuleKey } from 'app/routes/helper';
import { PermissionKey, UserType } from 'app/schemaInterfaces/globalTypes';
import { useEffect } from 'react';

export const useProtectedRoute = (
  path: string,
  open?: boolean,
  userType?: UserType,
  permission?: PermissionKey | PermissionKey[],
  offer?: ModuleKey
) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const user = useCurrentUser();
  const { offerKeys } = useClientOffers();

  let isAuthorized = true;

  if (userType) {
    isAuthorized = user.type === userType;
  }

  if (isAuthorized && !!offerKeys?.length && offer) {
    isAuthorized = offerKeys?.includes(offer) ? true : false;
  }
  const permissions = Array.isArray(permission) ? permission : [permission];

  if (isAuthorized && permission) {
    isAuthorized = isAuthorized = user.role
      ? user.role.permissions.some(
          (permissionItem) => permissions.indexOf(permissionItem.key) >= 0
        )
      : false;
  }
  useEffect(() => {
    (async () => {
      if (!isAuthenticated) {
        await loginWithRedirect({
          appState: { targetUrl: path },
        });
      }
    })();
  }, [open, isAuthenticated, loginWithRedirect, path]);

  return { isAuthenticated, isAuthorized };
};
