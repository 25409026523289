import React, { FC } from 'react';
import {
  Box,
  Divider,
  IconButton,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import ChromeReaderModeOutlinedIcon from '@material-ui/icons/ChromeReaderModeOutlined';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import {
  useStudioDispatchContext,
  useStudioStateContext,
} from 'modules/workflow/oneStudioContext';
import { studioAppActions } from 'modules/workflow/reducers/actionsInterfaces';
import colors from 'styles/colors.module.scss';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import PanToolTwoToneIcon from '@material-ui/icons/PanToolTwoTone';
import PanToolOutlinedIcon from '@material-ui/icons/PanToolOutlined';
import { useTranslation } from 'react-i18next';
import RedoIcon from '@material-ui/icons/Redo';
import UndoIcon from '@material-ui/icons/Undo';
import Paging from 'modules/workflow/components/Paging';

const useStyles = makeStyles({
  buttonStyle: {
    color: colors.grey,
  },
});

const ViewportControls: FC<{
  zoomIn: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  zoomOut: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  resetTransform: () => void;
}> = ({ zoomIn, zoomOut, resetTransform }) => {
  const globalState = useStudioStateContext();
  const dispatch = useStudioDispatchContext();
  const classes = useStyles();
  const { t } = useTranslation();

  const isEditable = globalState.isEditable;

  const comparePagesStatusHandler = (toggleStatus: boolean) => {
    dispatch(
      studioAppActions.setPagesComparisonStatus({
        isTwoPagesView: !toggleStatus,
      })
    );
  };

  const tooglePanningFunctionalityHandler = (toggleStatus: boolean) => {
    dispatch(
      studioAppActions.togglePanningFunctionality({
        panningEnabled: toggleStatus,
      })
    );
  };

  const isDisabledTwoPage =
    globalState.version.pages.length === globalState.currentPage ||
    globalState.version.pages.length === 1;

  const selectPageHandler = (pageIndex: number) => {
    pageIndex = globalState.currentPage + pageIndex;
    dispatch(studioAppActions.setCommentMode({ commentMode: false }));
    dispatch(studioAppActions.setPageNumber({ pageNumber: pageIndex }));
  };

  const isFirstPage = globalState.currentPage === 1;
  const isLastPage =
    globalState.currentPage === globalState.version.pages.length;
  const isTwoPages = globalState.isTwoPagesView && !isLastPage;

  return (
    <>
      {isEditable && (
        <Box display="inline-flex" alignItems="center">
          {/* temporary removed */}
          {/* <DocumentBriefsDialog />
						<History />
						<Divider orientation="vertical" flexItem variant="middle" />*/}
          {/* Start: Undeo & Redo Section */}
          <IconButton
            onClick={() => dispatch(studioAppActions.undo())}
            classes={{ root: classes.buttonStyle }}
          >
            <UndoIcon />
          </IconButton>
          <IconButton
            onClick={() => dispatch(studioAppActions.redo())}
            classes={{ root: classes.buttonStyle }}
          >
            <RedoIcon />
          </IconButton>
          <Divider orientation="vertical" flexItem variant="middle" />
        </Box>
      )}
      {/* End: Undeo & Redo Section */}
      {/* Start: Zooming Section */}

      <IconButton onClick={zoomIn}>
        <ZoomInIcon />
      </IconButton>
      <IconButton onClick={zoomOut}>
        <ZoomOutIcon />
      </IconButton>
      <IconButton onClick={resetTransform}>
        <ZoomOutMapIcon />
      </IconButton>

      <IconButton
        onClick={() =>
          tooglePanningFunctionalityHandler(!globalState.panningEnabled)
        }
        title={
          globalState.panningEnabled
            ? t('workflow.toolbar.disable_panning')
            : t('workflow.toolbar.enable_panning')
        }
      >
        {globalState.panningEnabled ? (
          <PanToolTwoToneIcon />
        ) : (
          <PanToolOutlinedIcon />
        )}
      </IconButton>

      {/* End: Zooming Section */}
      <Divider orientation="vertical" flexItem variant="middle" />
      <Paging
        pagingText={
          isTwoPages
            ? `${globalState.currentPage}-${globalState.currentPage + 1} / ${
                globalState.version.pages.length
              }`
            : `${globalState.currentPage} / ${globalState.version.pages.length}`
        }
        onClick={selectPageHandler}
        isFirstArrowActive={!isFirstPage}
        isLastArrowActive={!isLastPage}
        pagingStyle={{ border: `2px ${colors.buttonGroupOutlined} solid` }}
      />

      {/* End: Paging Section  */}
      <Divider orientation="vertical" flexItem variant="middle" />
      <Box mr={1.5}>
        <Tooltip title="Affichage page simple ou double">
          <IconButton
            disabled={isDisabledTwoPage}
            classes={{ root: classes.buttonStyle }}
            onClick={() =>
              comparePagesStatusHandler(globalState.isTwoPagesView)
            }
          >
            {!globalState.isTwoPagesView ? (
              <InsertDriveFileOutlinedIcon />
            ) : (
              <ChromeReaderModeOutlinedIcon />
            )}
          </IconButton>
        </Tooltip>
      </Box>

      {/* End: Pages Comparing Section  */}
    </>
  );
};

export default ViewportControls;
