import React, { useState, useReducer } from 'react';
import Box from '@material-ui/core/Box';
import { Tabs, Tab, makeStyles } from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import colors from 'styles/colors.module.scss';
import GridsPanel from 'modules/workflow/containers/Leftsidebar/GridsPanel';
import PagesPanel from 'modules/workflow/containers/Leftsidebar/PagesPanel/PagesPanel';
import TemplatesPanel from 'modules/workflow/containers/Leftsidebar/TemplatesPanel/TemplatesPanel';
import PersonalizationPanel from 'modules/workflow/containers/Leftsidebar/PersonalizationPanel/PersonalizationPanel';
import ProductsPanel from 'modules/workflow/containers/Leftsidebar/ProductsPanel/ProductsPanel';
import {
  LeftSideBarTabValue,
  TabMenu,
  useStudioDispatchContext,
  useStudioStateContext,
} from 'modules/workflow/oneStudioContext';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import GridOnOutlinedIcon from '@material-ui/icons/GridOnOutlined';
import TextureOutlinedIcon from '@material-ui/icons/TextureOutlined';
import CollectionsOutlinedIcon from '@material-ui/icons/CollectionsOutlined';
import FormatColorFillOutlinedIcon from '@material-ui/icons/FormatColorFillOutlined';
import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined';
import { useTranslation } from 'react-i18next';
import AssetsPanel from 'modules/workflow/containers/Leftsidebar/AssetsPanel/AssetsPanel';
import {
  ProductsSheetsStateProvider,
  ProductsSheetsContextState,
} from 'modules/pim/baseProducts/BaseProducts';
import {
  productsFiltersReducer,
  ProductsInitialState,
} from 'modules/pim/baseProducts/reducers/productReducer';
import { FileStore } from 'modules/pim/baseProducts/PimFormFieldsConfig';
import {
  PermissionKey,
  ProductFilterInput,
} from 'app/schemaInterfaces/globalTypes';
import { studioAppActions } from 'modules/workflow/reducers/actionsInterfaces';
import FilesPanel from 'modules/workflow/containers/Leftsidebar/FilesPanel/FilesPanel';
import { PimRegrouping } from 'modules/pim/baseProducts/PimFormConfigTypes';
import usePermissions from 'app/auth/usePermissions';

const backgroundColorTab = {
  backgroundColor: colors.documentsTab,
};

const useStyles = makeStyles({
  tabRoot: {
    width: '100%',
    color: colors.disabled,
    '&:hover': {
      color: colors.white,
      opacity: 1,
    },
    '&.Mui-selected': {
      ...backgroundColorTab,
      color: colors.white,
    },
    '&:focus': {
      color: colors.white,
    },
    '@media (min-width: 600px)': {
      minWidth: 'unset',
    },
  },
  tabsIndicator: {
    ...backgroundColorTab,
  },
  tabLabelIcon: {
    minHeight: '88px',
    fontSize: '10px',
    color: colors.disabled,
  },
  tabWrapper: {
    flexDirection: 'column',
    '&>:first-child': {
      marginRight: '0',
      marginBottom: '6px',
    },
  },
});

interface TabPanelProps {
  value: number;
}

const TabPanel = ({ value }: TabPanelProps): JSX.Element => {
  const [productsFilter, productsDispatch] = useReducer(
    productsFiltersReducer,
    ProductsInitialState
  );

  const [fieldName, setFieldName] = React.useState<string | undefined>(
    undefined
  );
  const [formFilesManager, setFormFilesManager] = useState<FileStore[]>([]);
  const [filesLimitNumber, setFilesLimitNumber] = useState<number>(0);

  const [regrouping, setRegrouping] = useState<PimRegrouping[]>([]);

  const values: ProductsSheetsContextState = {
    filter: productsFilter.filters as ProductFilterInput,
    dispatch: productsDispatch,
    fieldName,
    setFieldName,
    formFilesManager,
    setFormFilesManager,
    filesLimitNumber,
    setFilesLimitNumber,
    regrouping,
    setRegrouping,
  };

  const TabPanels: JSX.Element[] = [
    <PagesPanel />,
    <GridsPanel />,
    <TemplatesPanel />,
    <ProductsSheetsStateProvider value={values}>
      <ProductsPanel />
    </ProductsSheetsStateProvider>,
    <AssetsPanel />,
    <PersonalizationPanel />,
    <FilesPanel />,
  ];

  return (
    <Box
      flexGrow={1}
      bgcolor={colors.leftSideBar}
      height="1OOvh"
      width="284px"
      overflow="hidden auto"
    >
      {TabPanels[value]}
    </Box>
  );
};

const EditionBar = (): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();
  const tabClasses = {
    labelIcon: classes.tabLabelIcon,
    root: classes.tabRoot,
    wrapper: classes.tabWrapper,
  };
  const globalState = useStudioStateContext();
  const dispatch = useStudioDispatchContext();
  const { hasPermission } = usePermissions();
  const currentPage = globalState.version.pages[globalState.currentPage - 1];

  const tabValue = globalState.tabValue;
  const isCustomization = globalState.document?.isCustomization;

  const isEditable = globalState.isEditable;

  const currentPageIsEditable =
    (!isCustomization && !currentPage?.validated) ||
    (isCustomization && currentPage?.isCustomizable);

  const tabProps = {
    classes: tabClasses,
    disabled: !currentPageIsEditable,
  };

  return (
    <Box display="inline-flex">
      <Box bgcolor={colors.toolBarBackGround} width="88px">
        <Tabs
          classes={{
            indicator: classes.tabsIndicator,
          }}
          centered
          orientation="vertical"
          variant="fullWidth"
          value={tabValue.value}
          onChange={(_, newValue: LeftSideBarTabValue) => {
            dispatch(
              studioAppActions.setTabValue({
                tabValue: { value: newValue },
              })
            );
          }}
        >
          <Tab
            {...tabProps}
            label={<h6>{t('workflow.menu.page')}</h6>}
            icon={<FileCopyOutlinedIcon />}
            value={TabMenu.PAGES}
          />

          {!isCustomization &&
            isEditable &&
            hasPermission(PermissionKey.AccessGridTab) && (
              <Tab
                {...tabProps}
                label={<h6>{t('workflow.menu.grid')}</h6>}
                icon={<GridOnOutlinedIcon />}
                value={TabMenu.GRID}
              />
            )}

          {!isCustomization &&
            isEditable &&
            hasPermission(PermissionKey.AccessTemplateTab) && (
              <Tab
                {...tabProps}
                label={<h6>{t('workflow.menu.template')}</h6>}
                icon={<TextureOutlinedIcon />}
                value={TabMenu.TEMPLATE}
              />
            )}

          {isEditable && hasPermission(PermissionKey.AccessProductTab) && (
            <Tab
              {...tabProps}
              label={<h6>{t('workflow.menu.product')}</h6>}
              icon={<CollectionsOutlinedIcon />}
              value={TabMenu.PRODUCT}
            />
          )}
          {isEditable && hasPermission(PermissionKey.AccessMediaTab) && (
            <Tab
              {...tabProps}
              label={<h6>{t('workflow.menu.asset')}</h6>}
              icon={<CategoryOutlinedIcon />}
              value={TabMenu.ASSET}
            />
          )}
          {isEditable &&
            hasPermission(PermissionKey.AccessPersonnalizationItems) && (
              <Tab
                {...tabProps}
                label={<h6>{t('workflow.menu.customize')}</h6>}
                icon={<FormatColorFillOutlinedIcon />}
                value={TabMenu.CUSTOMIZE}
              />
            )}
          {isEditable && hasPermission(PermissionKey.AccessFiles) && (
            <Tab
              {...tabProps}
              label={
                <h6 style={{ whiteSpace: 'nowrap' }}>
                  {t('workflow.menu.file')}
                </h6>
              }
              icon={<FolderOutlinedIcon />}
              value={TabMenu.ADDRESS_BLOCK}
            />
          )}
        </Tabs>
      </Box>
      <TabPanel value={tabValue.value} />
    </Box>
  );
};

export default EditionBar;
