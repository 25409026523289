import { css } from '@emotion/react/macro';
import { Box, CircularProgress, Divider } from '@material-ui/core';
import UncontrolledCheckboxMenu, {
  CheckboxOption,
} from 'components/UncontrolledCheckboxMenu';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GET_DIVISIONS as I_GET_DIVISIONS } from 'app/schemaInterfaces/GET_DIVISIONS';
import colors from 'styles/colors.module.scss';
import SectorsView from 'modules/workflow/containers/Leftsidebar/ProductsPanel/SectorsView';
import { useQuery } from '@apollo/client';
import { GET_DIVISIONS } from 'app/graphql/queries/divisions';
import { useDivisionSelect } from 'components/divisions/useDivisionSelect';
import ProductsView from 'modules/workflow/containers/Leftsidebar/ProductsPanel/ProductsView';
import { DivisionElement } from 'components/useProductsWithAssets';
import { useStudioStateContext } from 'modules/workflow/oneStudioContext';
import ProductsTypeNavigation from 'modules/workflow/containers/Leftsidebar/ProductsPanel/ProductsTypeNavigation';
import PimSearchBar from 'modules/pim/components/PimSearchBar';
import { useProductsSheetsContext } from 'modules/pim/baseProducts/BaseProducts';
import ProductsPanelTabs from 'modules/workflow/containers/Leftsidebar/ProductsPanel/ProductsPanelTabs';
import { GET_PRODUCTS_DIVISIONS } from 'app/graphql/queries/products';
import {
  GetProductsDivisions,
  GetProductsDivisionsVariables,
} from 'app/schemaInterfaces/GetProductsDivisions';
import { Nullish } from 'app/utils/common';

export enum ProductsPanelTab {
  PIM = 'PIM',
  CAMPAIGN = 'CAMPAIGN',
}

const style = css`
  // TODO: create a reusable and clean loader component with circular progress
  height: 100vh;
  overflow: scroll;
  .progress {
    display: flex;
    width: 100%;
    justify-content: center;
  }
`;

const ProductsPanel = (): JSX.Element => {
  const { t } = useTranslation();
  const globalState = useStudioStateContext();

  const [tab, setTab] = useState<ProductsPanelTab>(ProductsPanelTab.CAMPAIGN);
  const [isCampaign, setIsCampaign] = useState<boolean>(true);
  const [divisions, setDivisions] = useState<DivisionElement[]>();

  const filterByDivisions = divisions?.reduce<Record<string, Nullish<string>>>(
    (divisions, { value, division }) => ({
      ...divisions,
      [division as string]: value,
    }),
    {}
  );

  const { data: productsDivisionsData, loading: divisionLoading } = useQuery<
    GetProductsDivisions,
    GetProductsDivisionsVariables
  >(GET_PRODUCTS_DIVISIONS, {
    variables: {
      campaignId: globalState.version.campaignId,
      filter: {
        details: filterByDivisions,
      },
    },
    fetchPolicy: 'network-only',
  });

  const [isLastDivision, setIsLastDivision] = useState<boolean>(false);
  const [selectedDivision, setSelectedDivision] = useState<DivisionElement>({
    value: undefined,
    division: undefined,
  });

  const { data: divisionsData } = useQuery<I_GET_DIVISIONS>(GET_DIVISIONS);

  const getDivisionValue = (divisionKey: string): string | null | undefined =>
    divisions?.find(({ division }) => division === divisionKey)?.value;

  const { selectModels } = useDivisionSelect(divisionsData, getDivisionValue);
  const divisionsOptions = selectModels.map(({ key, label }) => ({
    label,
    value: key,
  }));

  useEffect(() => {
    if (!!selectModels.length && !selectedDivision.division) {
      setSelectedDivision({
        ...selectedDivision,
        division: selectModels[0].key,
      });
    }
  }, [selectModels, selectedDivision, isCampaign]);

  const model = selectModels.find(
    (selectModel) => selectModel.key === selectedDivision.division
  );

  let options: CheckboxOption[] = [];

  const divisionsValues =
    tab === ProductsPanelTab.PIM
      ? productsDivisionsData?.pimProducts.valuesOptionsDivisionFilter
      : productsDivisionsData?.campaignProducts.valuesOptionsDivisionFilter;

  if (model) {
    !isLastDivision &&
      divisionsValues?.forEach((item) => {
        if (item.key === selectedDivision.division) {
          options = item.values.map((value) => ({
            value: value,
            label: value,
          }));
        }
      });
  }

  const handleSectorClick = (
    _: React.MouseEvent<HTMLDivElement, MouseEvent>,
    value: string | null
  ) => {
    let indexDivision = selectModels.findIndex(
      (model) => model.key === selectedDivision.division
    );

    const isLastItem = indexDivision === selectModels.length - 1;

    const isDivisionNotFound = indexDivision === -1;

    setDivisions([
      ...(divisions ?? []),
      { division: selectModels[indexDivision].key, value },
    ]);

    if (isDivisionNotFound || isLastItem) {
      setIsLastDivision(true);
    } else {
      setSelectedDivision({
        division: !isLastItem
          ? selectModels[indexDivision + 1].key
          : selectModels[indexDivision].key,
        value,
      });
      setIsLastDivision(false);
    }
  };

  const handleTabs = (value: ProductsPanelTab) => {
    setTab(value);
    if (value === ProductsPanelTab.CAMPAIGN) {
      setIsCampaign(true);
    } else {
      setIsCampaign(false);
    }
    setIsLastDivision(false);
    setDivisions([]);
    setSelectedDivision({ value: undefined, division: undefined });
  };

  const { filter } = useProductsSheetsContext();

  return (
    <Box css={style} p="15px">
      <Box mb={2}>
        <ProductsPanelTabs handleTabs={handleTabs} />
        <Divider style={{ backgroundColor: colors.disabled, width: '100%' }} />
      </Box>
      <Box display="flex" alignItems="center">
        <PimSearchBar
          placeholder={t(
            'workflow.left_side_bar.products_panel.search_bar_placeholder'
          )}
          bgcolor={colors.white}
          hasBorder={false}
          inputBaseWidth="100%"
        />
        <Box pl={2.5}>
          <UncontrolledCheckboxMenu
            iconButtonBackgroundColor={colors.grey}
            options={[
              {
                label: t(
                  'workflow.left_side_bar.products_panel.alphabetical_order'
                ),
                value: 'all',
              },
              ...divisionsOptions,
            ]}
            hasIndentation={true}
          />
        </Box>
      </Box>
      {selectedDivision.value && (
        <Box pt={2} pb={2}>
          <ProductsTypeNavigation
            divisions={divisions}
            setDivisions={setDivisions}
            setSelectedDivision={setSelectedDivision}
            setIsLastDivision={setIsLastDivision}
          />
        </Box>
      )}
      {(tab === ProductsPanelTab.CAMPAIGN
        ? productsDivisionsData?.campaignProducts.valuesOptionsDivisionFilter
        : productsDivisionsData?.pimProducts.valuesOptionsDivisionFilter) &&
      !divisionLoading ? (
        !isLastDivision && !filter?.text ? (
          <SectorsView
            options={options}
            onClick={(e, value) => {
              handleSectorClick(e, value);
            }}
          />
        ) : (
          <ProductsView
            selectedDivision={selectedDivision}
            divisions={divisions as DivisionElement[]}
            isCampaign={isCampaign}
          />
        )
      ) : (
        <div className="progress">
          <CircularProgress color="inherit" />
        </div>
      )}
    </Box>
  );
};

export default ProductsPanel;
