/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AddressBlockStatus {
  Declined = 'Declined',
  Pending = 'Pending',
  Validated = 'Validated',
}

export enum AscDescType {
  asc = 'asc',
  desc = 'desc',
}

export enum AssetStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum AssetType {
  AddressBlock = 'AddressBlock',
  Charter = 'Charter',
  Logo = 'Logo',
  Media = 'Media',
  Picto = 'Picto',
  Visual = 'Visual',
}

export enum AttributeKey {
  certification = 'certification',
  chant = 'chant',
  ciblage = 'ciblage',
  classification = 'classification',
  conditionnement = 'conditionnement',
  couleur = 'couleur',
  decoupe = 'decoupe',
  descriptionIndication = 'descriptionIndication',
  diametreCm = 'diametreCm',
  enveloppeFenetre = 'enveloppeFenetre',
  epaisseurMicrons = 'epaisseurMicrons',
  faconnage = 'faconnage',
  faconnageDeLivraison = 'faconnageDeLivraison',
  finitionRecto = 'finitionRecto',
  finitionVerso = 'finitionVerso',
  fondsPerdus = 'fondsPerdus',
  format = 'format',
  fourniture = 'fourniture',
  grammageCouvertureGr = 'grammageCouvertureGr',
  grammageGr = 'grammageGr',
  grammageInterieurGr = 'grammageInterieurGr',
  impressionLogo = 'impressionLogo',
  impressionRectoVerso = 'impressionRectoVerso',
  largeurCouvertureFormatFiniCm = 'largeurCouvertureFormatFiniCm',
  largeurCouvertureFormatOuvertCm = 'largeurCouvertureFormatOuvertCm',
  largeurCouvertureFormatPageCm = 'largeurCouvertureFormatPageCm',
  largeurFormatFiniCm = 'largeurFormatFiniCm',
  largeurFormatOuvertCm = 'largeurFormatOuvertCm',
  largeurFormatPageCm = 'largeurFormatPageCm',
  largeurInterieurEncartFormatFiniCm = 'largeurInterieurEncartFormatFiniCm',
  largeurInterieurEncartFormatOuvertCm = 'largeurInterieurEncartFormatOuvertCm',
  largeurInterieurEncartFormatPageCm = 'largeurInterieurEncartFormatPageCm',
  largeurInterieurFormatFiniCm = 'largeurInterieurFormatFiniCm',
  largeurInterieurFormatOuvertCm = 'largeurInterieurFormatOuvertCm',
  largeurInterieurFormatPageCm = 'largeurInterieurFormatPageCm',
  largeurPixel = 'largeurPixel',
  largeurTailleFenetreCm = 'largeurTailleFenetreCm',
  longueurCouvertureFormatFiniCm = 'longueurCouvertureFormatFiniCm',
  longueurCouvertureFormatOuvertCm = 'longueurCouvertureFormatOuvertCm',
  longueurCouvertureFormatPageCm = 'longueurCouvertureFormatPageCm',
  longueurFormatFiniCm = 'longueurFormatFiniCm',
  longueurFormatOuvertCm = 'longueurFormatOuvertCm',
  longueurFormatPageCm = 'longueurFormatPageCm',
  longueurInterieurEncartFormatFiniCm = 'longueurInterieurEncartFormatFiniCm',
  longueurInterieurEncartFormatOuvertCm = 'longueurInterieurEncartFormatOuvertCm',
  longueurInterieurEncartFormatPageCm = 'longueurInterieurEncartFormatPageCm',
  longueurInterieurFormatFiniCm = 'longueurInterieurFormatFiniCm',
  longueurInterieurFormatOuvertCm = 'longueurInterieurFormatOuvertCm',
  longueurInterieurFormatPageCm = 'longueurInterieurFormatPageCm',
  longueurPixel = 'longueurPixel',
  longueurTailleFenetreCm = 'longueurTailleFenetreCm',
  matiere = 'matiere',
  matiereCouverture = 'matiereCouverture',
  matiereInterieur = 'matiereInterieur',
  modeleAchat = 'modeleAchat',
  nbCouleurCouvertureRecto = 'nbCouleurCouvertureRecto',
  nbCouleurCouvertureVerso = 'nbCouleurCouvertureVerso',
  nbCouleurInterieurEncartRecto = 'nbCouleurInterieurEncartRecto',
  nbCouleurInterieurEncartVerso = 'nbCouleurInterieurEncartVerso',
  nbCouleurInterieurRecto = 'nbCouleurInterieurRecto',
  nbCouleurInterieurVerso = 'nbCouleurInterieurVerso',
  nbCouleurRecto = 'nbCouleurRecto',
  nbCouleurVerso = 'nbCouleurVerso',
  objectifMarketing = 'objectifMarketing',
  optionsFaconnage = 'optionsFaconnage',
  orientation = 'orientation',
  ouvertureEnveloppe = 'ouvertureEnveloppe',
  paginationCouverture = 'paginationCouverture',
  paginationInterieur = 'paginationInterieur',
  paginationInterieurEncart = 'paginationInterieurEncart',
  paginationTotale = 'paginationTotale',
  paysDeDiffusion = 'paysDeDiffusion',
  precisionsFaconnage = 'precisionsFaconnage',
  precisionsFourniture = 'precisionsFourniture',
  profondeurFormatFiniCm = 'profondeurFormatFiniCm',
  repiquage = 'repiquage',
  repiquageCoeur = 'repiquageCoeur',
  repiquageDOS = 'repiquageDOS',
  surfacage = 'surfacage',
  surfacageCouvertureRecto = 'surfacageCouvertureRecto',
  surfacageCouvertureVerso = 'surfacageCouvertureVerso',
  surfacageInterieurRecto = 'surfacageInterieurRecto',
  surfacageInterieurVerso = 'surfacageInterieurVerso',
  taille = 'taille',
  tauxSurfacage = 'tauxSurfacage',
  typeEnvoi = 'typeEnvoi',
  typeImpression = 'typeImpression',
  typeMatiere = 'typeMatiere',
  typeMatiereCouverture = 'typeMatiereCouverture',
  typeMatiereInterieur = 'typeMatiereInterieur',
  typePapier = 'typePapier',
  typeSupport = 'typeSupport',
  zoneTerritorial = 'zoneTerritorial',
}

export enum BasketError {
  _empty = '_empty',
}

export enum BasketType {
  BigShop = 'BigShop',
  Commitment = 'Commitment',
}

export enum BigShopConfigCategory {
  AdvertisingItems = 'AdvertisingItems',
  AdvertisingPrints = 'AdvertisingPrints',
  Digital = 'Digital',
  SignagePos = 'SignagePos',
  StationeryShop = 'StationeryShop',
  PostersAndLeaflets = 'PostersAndLeaflets',
  SignsAndSignage = 'SignsAndSignage',
  OfferBrochures = 'OfferBrochures',
  Billboards = 'Billboards',
  TrafficSigns = 'TrafficSigns',
}

export enum CampaignError {
  containOrderedDocuments = 'containOrderedDocuments',
}

export enum CampaignType {
  Local = 'Local',
  Model = 'Model',
  National = 'National',
}

export enum CharterType {
  FamilyBackground = 'FamilyBackground',
  PageBackground = 'PageBackground',
}

export enum ClientError {
  ClientNotFound = 'ClientNotFound',
}

export enum ConditioningOperator {
  lower = 'lower',
  upper = 'upper',
}

export enum DateStatus {
  ALL = 'ALL',
  INPROGRESS = 'INPROGRESS',
  TERMINATED = 'TERMINATED',
  UPCOMING = 'UPCOMING',
}

export enum DisabledDocumentErrors {
  campaignArchived = 'campaignArchived',
  documentCommited = 'documentCommited',
  documentInBasket = 'documentInBasket',
  documentTerminated = 'documentTerminated',
  documentWithNoPrices = 'documentWithNoPrices',
  needFacebookAuth = 'needFacebookAuth',
}

export enum DocumentError {
  documentOrdered = 'documentOrdered',
}

export enum DocumentOptions {
  customizable = 'customizable',
  notCustomizable = 'notCustomizable',
}

export enum DocumentOrderType {
  Commitment = 'Commitment',
  FreeOrder = 'FreeOrder',
  National = 'National',
  PrintableInStore = 'PrintableInStore',
}

export enum DocumentOrigin {
  Declination = 'Declination',
  Duplication = 'Duplication',
  Source = 'Source',
}

export enum DocumentQuantitiesType {
  Range = 'Range',
  Strict = 'Strict',
}

export enum EntityError {
  EntityNotFound = 'EntityNotFound',
}

export enum EntitySortBy {
  level = 'level',
  name = 'name',
}

export enum EntityType {
  Brand = 'Brand',
  Central = 'Central',
  Cooperative = 'Cooperative',
  Group = 'Group',
  Region = 'Region',
  Store = 'Store',
  SubGroup1 = 'SubGroup1',
  SubGroup2 = 'SubGroup2',
  SubGroup3 = 'SubGroup3',
  SubGroup4 = 'SubGroup4',
  Typology = 'Typology',
}

export enum FollowOrderStatus {
  Billed = 'Billed',
  Delivered = 'Delivered',
  DeliveryInProgress = 'DeliveryInProgress',
  ProductionInProgress = 'ProductionInProgress',
  Refused = 'Refused',
  Validated = 'Validated',
  WaitingValidation = 'WaitingValidation',
}

export enum InvoiceStatus {
  Blank = 'Blank',
  Counted = 'Counted',
  ToCount = 'ToCount',
  ToValidate = 'ToValidate',
}

export enum NotificationType {
  BASKET_REMINDER = 'BASKET_REMINDER',
  CAMPAIGN_CREATED = 'CAMPAIGN_CREATED',
  CAMPAIGN_STARTED = 'CAMPAIGN_STARTED',
  COMMITMENT_DOCUMENT_ENDED = 'COMMITMENT_DOCUMENT_ENDED',
  COMMITMENT_REMINDER = 'COMMITMENT_REMINDER',
  COMMITMENT_REMINDER_ACTION = 'COMMITMENT_REMINDER_ACTION',
  ORDER_ACCEPTED = 'ORDER_ACCEPTED',
  ORDER_DETAIL_FAILED = 'ORDER_DETAIL_FAILED',
  ORDER_FAILED = 'ORDER_FAILED',
  ORDER_REFUSED = 'ORDER_REFUSED',
  ORDER_REMINDER = 'ORDER_REMINDER',
  ORDER_VALIDATION_REQUIRED = 'ORDER_VALIDATION_REQUIRED',
  THEMATIC_CREATED = 'THEMATIC_CREATED',
  THEMATIC_DOCUMENT_CREATED = 'THEMATIC_DOCUMENT_CREATED',
  THEMATIC_DOCUMENT_ENDED = 'THEMATIC_DOCUMENT_ENDED',
}

export enum OrderError {
  InvalidNewStatus = 'InvalidNewStatus',
  NotAwaitingValidation = 'NotAwaitingValidation',
  OrderNotFound = 'OrderNotFound',
}

export enum OrderProductStatus {
  Delivered = 'Delivered',
  Faillure = 'Faillure',
  ManufacturingInProgress = 'ManufacturingInProgress',
  ShippingInProgress = 'ShippingInProgress',
  Waiting = 'Waiting',
}

export enum OrderStatus {
  Canceled = 'Canceled',
  Delivered = 'Delivered',
  InProgress = 'InProgress',
  WorkInProgress = 'WorkInProgress',
}

export enum PdfDefinition {
  HD = 'HD',
  LD = 'LD',
}

export enum PermissionCategory {
  Access = 'Access',
  Asset = 'Asset',
  Attachment = 'Attachment',
  Campaign = 'Campaign',
  Document = 'Document',
  Order = 'Order',
  Product = 'Product',
  RetroPlanning = 'RetroPlanning',
  Role = 'Role',
  Team = 'Team',
  User = 'User',
  Version = 'Version',
  Workflow = 'Workflow',
}

export enum PermissionKey {
  AccessAllPagesValidation = 'AccessAllPagesValidation',
  AccessBigShop = 'AccessBigShop',
  AccessCampaignAttachments = 'AccessCampaignAttachments',
  AccessCampaignCommitments = 'AccessCampaignCommitments',
  AccessCampaignDistribution = 'AccessCampaignDistribution',
  AccessCampaignMedia = 'AccessCampaignMedia',
  AccessCampaignOrders = 'AccessCampaignOrders',
  AccessCampaignPlanning = 'AccessCampaignPlanning',
  AccessCampaignProducts = 'AccessCampaignProducts',
  AccessCampaignUsers = 'AccessCampaignUsers',
  AccessDistributionDocumentWorkflow = 'AccessDistributionDocumentWorkflow',
  AccessFiles = 'AccessFiles',
  AccessGridTab = 'AccessGridTab',
  AccessMediaTab = 'AccessMediaTab',
  AccessModelCampaign = 'AccessModelCampaign',
  AccessNationalCampaign = 'AccessNationalCampaign',
  AccessPersonnalizationItems = 'AccessPersonnalizationItems',
  AccessPim = 'AccessPim',
  AccessProductTab = 'AccessProductTab',
  AccessTemplateTab = 'AccessTemplateTab',
  AccessVisualPersonnalizationItems = 'AccessVisualPersonnalizationItems',
  CreateAsset = 'CreateAsset',
  CreateAttachment = 'CreateAttachment',
  CreateModelCampaign = 'CreateModelCampaign',
  CreateModelCampaignDocument = 'CreateModelCampaignDocument',
  CreateNationalCampaign = 'CreateNationalCampaign',
  CreateNationalCampaignDocument = 'CreateNationalCampaignDocument',
  CreateNationalCampaignMedia = 'CreateNationalCampaignMedia',
  CreateOrder = 'CreateOrder',
  CreatePdfBD = 'CreatePdfBD',
  CreatePdfHD = 'CreatePdfHD',
  CreatePdfWeb = 'CreatePdfWeb',
  CreateProduct = 'CreateProduct',
  CreateRole = 'CreateRole',
  CreateUser = 'CreateUser',
  DeleteAsset = 'DeleteAsset',
  DeleteAttachment = 'DeleteAttachment',
  DeleteCommitment = 'DeleteCommitment',
  DeleteModelCampaign = 'DeleteModelCampaign',
  DeleteModelCampaignDocument = 'DeleteModelCampaignDocument',
  DeleteNationalCampaign = 'DeleteNationalCampaign',
  DeleteNationalCampaignDocument = 'DeleteNationalCampaignDocument',
  DeleteNationalCampaignMedia = 'DeleteNationalCampaignMedia',
  DeleteProduct = 'DeleteProduct',
  DeleteRole = 'DeleteRole',
  DeleteTeam = 'DeleteTeam',
  DeleteUser = 'DeleteUser',
  ExportDocumentPdf = 'ExportDocumentPdf',
  ExportNationalCampaignDistribution = 'ExportNationalCampaignDistribution',
  ReadAsset = 'ReadAsset',
  ReadAttachment = 'ReadAttachment',
  ReadBasketVersion = 'ReadBasketVersion',
  ReadDistribution = 'ReadDistribution',
  ReadModelCampaign = 'ReadModelCampaign',
  ReadModelCampaignDocument = 'ReadModelCampaignDocument',
  ReadNationalCampaign = 'ReadNationalCampaign',
  ReadNationalCampaignDocument = 'ReadNationalCampaignDocument',
  ReadNationalCampaignMedia = 'ReadNationalCampaignMedia',
  ReadProduct = 'ReadProduct',
  ReadRetroPlanning = 'ReadRetroPlanning',
  ReadRole = 'ReadRole',
  ReadTeam = 'ReadTeam',
  ReadUser = 'ReadUser',
  ReadVersion = 'ReadVersion',
  UpdateAsset = 'UpdateAsset',
  UpdateAttachment = 'UpdateAttachment',
  UpdateCampaignProduct = 'UpdateCampaignProduct',
  UpdateCustomizablePage = 'UpdateCustomizablePage',
  UpdateGlobalProduct = 'UpdateGlobalProduct',
  UpdateLocalProduct = 'UpdateLocalProduct',
  UpdateModelCampaign = 'UpdateModelCampaign',
  UpdateModelCampaignDocument = 'UpdateModelCampaignDocument',
  UpdateNationalCampaign = 'UpdateNationalCampaign',
  UpdateNationalCampaignDocument = 'UpdateNationalCampaignDocument',
  UpdateNationalCampaignMedia = 'UpdateNationalCampaignMedia',
  UpdateRole = 'UpdateRole',
  UpdateTeam = 'UpdateTeam',
  UpdateUser = 'UpdateUser',
  UpdateUserRole = 'UpdateUserRole',
}

export enum PriceElement {
  Chip = 'Chip',
  Currency = 'Currency',
  Decimal = 'Decimal',
  Typography = 'Typography',
  WholeNumber = 'WholeNumber',
}

export enum PriceError {
  IncorrectIncludedPrices = 'IncorrectIncludedPrices',
  IncorrectPrices = 'IncorrectPrices',
}

export enum SocialNetwork {
  Facebook = 'Facebook',
}

export enum SupportCategory {
  Digital = 'Digital',
  Plv = 'Plv',
  Print = 'Print',
  Roto = 'Roto',
}

export enum TagType {
  Asset = 'Asset',
  Campaign = 'Campaign',
}

export enum TemplateType {
  BIGONE = 'BIGONE',
  CHILI = 'CHILI',
}

export enum UserType {
  Global = 'Global',
  Local = 'Local',
}

export enum VisualIdentityType {
  Price = 'Price',
  Typography = 'Typography',
  Visual = 'Visual',
}

export enum VisualType {
  Ambiance = 'Ambiance',
  FlagshipProduct = 'FlagshipProduct',
  Packshot = 'Packshot',
}

export interface AddCommitmentInput {
  entityId: string;
  campaignId: string;
  document: CommitmentDocumentInput;
}

export interface AddCompetitorInput {
  entityId: string;
  url: string;
  socialNetwork: SocialNetwork;
}

export interface AddressBlockFormInput {
  name: string;
  supplyProductIds?: string[] | null;
  supportId?: string | null;
}

export interface AddressBlockInput {
  entityId: string;
  name?: string | null;
  assetId: string;
  supplyProductIds?: string[] | null;
  supportId?: string | null;
  status?: AddressBlockStatus | null;
  addressBlockPdf?: AddressBlockStatus | null;
  hdPdfId?: string | null;
}

export interface AddressBlockSortInput {
  updatedAt?: AscDescType | null;
}

export interface AddressesInput {
  quantity: number;
  address: ShippingAddressInput;
  addressBlockId?: string | null;
  productName: string;
  price: number;
}

export interface ArrayOfIds {
  id: string;
}

export interface AssetFiltersInput {
  entityId?: string | null;
  text?: string | null;
  assetTypes?: AssetType[] | null;
  visualTypes?: VisualType[] | null;
  charterTypes?: CharterType[] | null;
  tags?: string[] | null;
  divisions?: any | null;
  status?: AssetStatus[] | null;
  permanent?: boolean | null;
  validFrom?: any | null;
  validTo?: any | null;
}

export interface AssetInput {
  name?: string | null;
  imageId?: string | null;
  validFrom?: any | null;
  validTo?: any | null;
  permanent?: boolean | null;
  status?: AssetStatus | null;
  conform?: boolean | null;
  comment?: string | null;
  entities?: string[] | null;
  documentId?: string | null;
  tags?: AssetTagInput[] | null;
  assetType?: AssetType | null;
  visualType?: VisualType | null;
  charterType?: CharterType | null;
  metadata: MetadataInput;
  division?: any | null;
  isVisible: boolean;
}

export interface AssetSortInput {
  name?: AscDescType | null;
  updatedAt?: AscDescType | null;
}

export interface AssetTagInput {
  id?: string | null;
  title?: string | null;
}

export interface AttachmentFilterInput {
  filename?: string | null;
  mimetypes?: string[] | null;
  userIds?: string[] | null;
  size?: AttachmentSizeFilterInput | null;
}

export interface AttachmentInput {
  filename: string;
  campaignId: string;
  mimetype: string;
  size: number;
}

export interface AttachmentSizeFilterInput {
  min?: number | null;
  max?: number | null;
}

export interface AttributConditionnementFilterInput {
  numberOfLots: number;
  total?: number | null;
  conditionnementList: AttributConditionnementItemFilterInput[];
}

export interface AttributConditionnementItemFilterInput {
  numberOfExLots?: number | null;
  firstConditionnement?: ConditionnementFilterInput | null;
  secondConditionnement?: ConditionnementFilterInput | null;
  threeConditionnement?: ConditionnementFilterInput | null;
}

export interface BackgroundColorInput {
  id: string;
  key: string;
}

export interface BasketAddDocumentInput {
  documentId: string;
  entityId: string;
  type: BasketType;
}

export interface BasketFilterInput {
  entityId?: string | null;
  types?: BasketType[] | null;
  documentIds?: string[] | null;
  dateStatus?: DateStatus | null;
}

export interface BasketUpdateInput {
  quantity: number;
  price: number;
  productName: string;
}

export interface BillingAddressInput {
  name?: string | null;
  firstAddress?: string | null;
  secondAddress?: string | null;
  thirdAddress?: string | null;
  zipCode?: string | null;
  city?: string | null;
  country?: string | null;
}

export interface CampaignFilterInput {
  text?: string | null;
  tags?: ArrayOfIds[] | null;
  categories?: string[] | null;
  activeNationalCampaigns?: boolean | null;
  activeLocalCampaigns?: boolean | null;
  dateFilter?: DateFilter | null;
  dateInterval?: DateIntervalFilter | null;
  commitmentDateInterval?: DateIntervalFilter | null;
  campaignType?: CampaignType | null;
  mediumType?: string[] | null;
  documentCategories?: BigShopConfigCategory[] | null;
  sort?: CampaignSortInput | null;
  entityId?: string | null;
  hasCommitmentDates?: boolean | null;
  skipOutdatedDocuments?: boolean | null;
}

export interface CampaignInput {
  name: string;
  reference: string;
  parentCampaignId?: string | null;
  entities: string[];
  description?: string | null;
  dateFrom?: any | null;
  dateTo?: any | null;
  commitmentDateFrom?: any | null;
  commitmentDateTo?: any | null;
  tags?: CampaignTagInput[] | null;
  category?: string | null;
  campaignType: CampaignType;
  imageId?: string | null;
}

export interface CampaignSortInput {
  dateFrom?: AscDescType | null;
  dateTo?: AscDescType | null;
  createdAt?: AscDescType | null;
  updatedAt?: AscDescType | null;
  commitmentDateFrom?: AscDescType | null;
  name?: AscDescType | null;
}

export interface CampaignTagInput {
  id?: string | null;
  title?: string | null;
}

export interface CategoryFilterInput {
  title?: string | null;
}

export interface CommentInput {
  isSeen?: boolean | null;
  createdAt?: any | null;
  text: string;
  replies?: ReplyInput[] | null;
  offsetPosition: PositionInput;
  clientPosition: PositionInput;
}

export interface CommitmentByCodeFilterInput {
  commitmentCode: string;
  entityId: string;
  hasQuantity?: boolean | null;
}

export interface CommitmentDocumentInput {
  documentId: string;
  date?: any | null;
  comment?: string | null;
  addresses: AddressesInput[];
}

export interface CommitmentFilterInput {
  documentIds?: string[][] | null;
  commitmentCode?: string | null;
  entityId?: string | null;
  isArchived?: boolean | null;
  hasQuantity?: boolean | null;
}

export interface CommitmentSortInput {
  createdAt?: AscDescType | null;
}

export interface CompetitorsFiltersInput {
  entityId: string;
}

export interface CompetitorsSortInput {
  updatedAt?: AscDescType | null;
}

export interface ConditionnementFilterInput {
  conditionnement: string;
  nbExConditionnement?: number | null;
  labeling: boolean;
}

export interface CopyDocumentInput {
  entityIds: string[];
  version: string;
}

export interface CreateOrderChildInput {
  billingAddress: BillingAddressInput;
  orderItem: CreateOrderChildItemInput[];
  clientName: string;
}

export interface CreateOrderChildItemInput {
  expectedDeliveryDate: any;
  comment?: string | null;
  productId: string;
  quantity: number;
  onePacDocumentId: string;
  onePacThematicName: string;
  thumbnailAssetId: string;
  resources: ResourcesInput[];
  socialNetwork?: OrderItemWithSocialNetworksInput | null;
  shippingAddress: OrderAddressInput;
}

export interface CreateOrderInput {
  billingAddress: BillingAddressInput;
  orderItem: CreateOrderItemInput[];
  childrenOrder: CreateOrderChildInput[];
  clientName: string;
  operationName: string;
}

export interface CreateOrderItemInput {
  expectedDeliveryDate: any;
  comment?: string | null;
  productId: string;
  quantity: number;
  onePacDocumentId: string;
  onePacThematicName: string;
  thumbnailAssetId: string;
  resources: ResourcesInput[];
  socialNetwork?: OrderItemWithSocialNetworksInput | null;
}

export interface DateFilter {
  date?: string | null;
  status?: DateStatus | null;
}

export interface DateIntervalFilter {
  startDate?: any | null;
  endDate?: any | null;
}

export interface DigitalContentInput {
  message?: string | null;
  url?: string | null;
}

export interface DimensionsInput {
  height?: number | null;
  width?: number | null;
  widthInCm?: number | null;
  heightInCm?: number | null;
}

export interface DocumentCreateInput {
  numberOfPages: number;
  quantity?: number | null;
  extraUnitCost?: number | null;
  price?: number | null;
  finishedFormatWidth?: number | null;
  finishedFormatHeight?: number | null;
  format?: string | null;
  impression?: string | null;
  paper?: string | null;
  paperType?: string | null;
  productionTime?: number | null;
  isRecto?: boolean | null;
  shaping?: string | null;
  isVerso?: boolean | null;
  weight?: number | null;
  campaignId: string;
  origin: DocumentOrigin;
  parentId?: string | null;
  thumbnailId?: string | null;
  name: string;
  version: string;
  productId: string;
  isCustomizable?: boolean | null;
  hasAddressBlock?: boolean | null;
  isMandatory?: boolean | null;
  orderTypes: DocumentOrderType[];
  quantities?: QuantitiesInput | null;
  commitmentDateFrom?: any | null;
  commitmentDateTo?: any | null;
  freeOrderDateFrom?: any | null;
  freeOrderDateTo?: any | null;
  entityIds?: string[] | null;
  supportId: string;
  productAttributes?: any | null;
  category: BigShopConfigCategory;
  mediumType: string;
  template: TemplateInput;
}

export interface DocumentFilterInput {
  origins?: DocumentOrigin[] | null;
  ids?: string[] | null;
  campaignIds?: string[] | null;
  parentId?: string | null;
  versionId?: string | null;
  entityId?: string | null;
  orderTypes?: DocumentOrderType[] | null;
  mediumTypes?: string[] | null;
  categories?: BigShopConfigCategory[] | null;
  numberOfPages?: number[] | null;
  isCustomizable?: boolean | null;
  isCustomization?: boolean | null;
  isNew?: boolean | null;
  isValidated?: boolean | null;
  version?: string[] | null;
  isArchived?: boolean | null;
  text?: string | null;
  orderDateInterval?: DateIntervalFilter | null;
  commitmentDateInterval?: DateIntervalFilter | null;
  isActive?: boolean | null;
  showObsolete?: boolean | null;
}

export interface DocumentHistoryFilterInput {
  documentIds?: string[] | null;
  category?: string | null;
}

export interface DocumentSortInput {
  name?: AscDescType | null;
  freeOrderDateFrom?: AscDescType | null;
  createdAt?: AscDescType | null;
}

export interface DocumentUpdateInput {
  numberOfPages: number;
  quantity?: number | null;
  extraUnitCost?: number | null;
  price?: number | null;
  finishedFormatWidth?: number | null;
  finishedFormatHeight?: number | null;
  format?: string | null;
  impression?: string | null;
  paper?: string | null;
  paperType?: string | null;
  productionTime?: number | null;
  isRecto?: boolean | null;
  shaping?: string | null;
  isVerso?: boolean | null;
  weight?: number | null;
  campaignId: string;
  origin: DocumentOrigin;
  parentId?: string | null;
  thumbnailId?: string | null;
  name: string;
  version: string;
  productId: string;
  isCustomizable?: boolean | null;
  hasAddressBlock?: boolean | null;
  isMandatory?: boolean | null;
  orderTypes: DocumentOrderType[];
  quantities?: QuantitiesInput | null;
  commitmentDateFrom?: any | null;
  commitmentDateTo?: any | null;
  freeOrderDateFrom?: any | null;
  freeOrderDateTo?: any | null;
  entityIds?: string[] | null;
  supportId: string;
  productAttributes?: any | null;
  category: BigShopConfigCategory;
  mediumType: string;
  template: TemplateInput;
}

export interface EntityFiltersInput {
  parents?: string[] | null;
  ancestors?: string[] | null;
  types?: EntityType[] | null;
  sortBy?: EntitySortBy | null;
}

export interface MetadataInput {
  dimensions?: DimensionsInput | null;
  mimetype: string;
  fileSize: number;
  credit?: string | null;
  fileHasCredit?: boolean | null;
}

export interface MultipleNotificationInput {
  conditionItemId: string;
  type: NotificationType;
  sendingDate: any;
  metadata?: any | null;
}

export interface MultipleUniquePricesProductInput {
  productId: string;
  quantity: number;
}

export interface NotificationInput {
  userId: string;
  conditionItemId: string;
  type: NotificationType;
  sendingDate: any;
  metadata?: any | null;
}

export interface OrderAddressInput {
  name: string;
  firstAddress?: string | null;
  secondAddress?: string | null;
  thirdAddress?: string | null;
  postBox?: string | null;
  zipCode?: string | null;
  city?: string | null;
  country?: string | null;
  deliveryDepartment?: string | null;
}

export interface OrderFilterInput {
  clientId?: string | null;
  status?: OrderStatus | null;
  onePacDocumentIds?: string[] | null;
  childrenOrderClientIds?: string[] | null;
  followStatus?: FollowOrderStatus | null;
}

export interface OrderItemWithSocialNetworksInput {
  category: string;
  clientNetworkLink?: string | null;
  clientNetworkPageId?: string | null;
  publishDescription?: string | null;
  mediumType: string;
  expectedDeliveryDate: any;
  resources: ResourcesInput[];
}

export interface OrderSortInput {
  createdAt?: AscDescType | null;
}

export interface PaginationInput {
  offset?: number | null;
  limit?: number | null;
}

export interface PositionInput {
  x: number;
  y: number;
}

export interface ProductFilterInput {
  text?: string | null;
  withoutFilesToSend?: boolean | null;
  withoutPrice?: boolean | null;
  details?: any | null;
}

export interface ProductHistoryInput {
  fieldName: string;
  oldValue: string;
  newValue: string;
}

export interface PublicUserFiltersInput {
  entities?: string[] | null;
  teams?: string[] | null;
}

export interface QuantitiesInput {
  type?: DocumentQuantitiesType | null;
  rangeQuantity?: RangeQuantityInput | null;
  strictQuantity?: number | null;
}

export interface RangeQuantityInput {
  minimum?: number | null;
  maximum?: number | null;
}

export interface RegroupingInput {
  details: any;
  productId: string;
  isMain: boolean;
  isVisible: boolean;
  assetId?: string | null;
  createdDate?: any | null;
  userId?: string | null;
}

export interface ReplyInput {
  userId: string;
  createdAt?: any | null;
  text: string;
}

export interface ResourcesInput {
  mimetype: string;
  resourceId: string;
  resourceIndex: number;
}

export interface RetroplanningInput {
  campaignId: string;
  resourceId: string;
  startAt?: any | null;
  endAt?: any | null;
  status?: string | null;
  type: string;
  disabled?: boolean | null;
}

export interface RetroplanningUpdateInput {
  startAt?: any | null;
  endAt?: any | null;
  status?: string | null;
  type?: string | null;
  disabled?: boolean | null;
}

export interface ShippingAddressInput {
  id: string;
  name?: string | null;
  firstAddress?: string | null;
  secondAddress?: string | null;
  thirdAddress?: string | null;
  postBox?: string | null;
  zipCode?: string | null;
  city?: string | null;
  country?: string | null;
  deliveryDepartment?: string | null;
}

export interface SupplyAttributesFilterInput {
  codeAt?: string | null;
  id?: string | null;
  certification?: string | null;
  chant?: string | null;
  classification?: string | null;
  conditionnement?: AttributConditionnementFilterInput | null;
  couleur?: string | null;
  decoupe?: string | null;
  descriptionIndication?: string | null;
  diametreCm?: string | null;
  enveloppeFenetre?: string | null;
  epaisseurMicrons?: string | null;
  faconnage?: string | null;
  finitionRecto?: string | null;
  finitionVerso?: string | null;
  format?: string | null;
  fourniture?: string | null;
  grammageCouvertureGr?: string | null;
  grammageGr?: string | null;
  grammageInterieurGr?: string | null;
  impressionLogo?: string | null;
  impressionRectoVerso?: string | null;
  largeurCouvertureFormatFiniCm?: string | null;
  largeurCouvertureFormatOuvertCm?: string | null;
  largeurCouvertureFormatPageCm?: string | null;
  largeurFormatFiniCm?: string | null;
  largeurFormatOuvertCm?: string | null;
  largeurFormatPageCm?: string | null;
  largeurInterieurEncartFormatFiniCm?: string | null;
  largeurInterieurEncartFormatOuvertCm?: string | null;
  largeurInterieurEncartFormatPageCm?: string | null;
  largeurInterieurFormatFiniCm?: string | null;
  largeurInterieurFormatOuvertCm?: string | null;
  largeurInterieurFormatPageCm?: string | null;
  largeurTailleFenetreCm?: string | null;
  longueurCouvertureFormatFiniCm?: string | null;
  longueurCouvertureFormatOuvertCm?: string | null;
  longueurCouvertureFormatPageCm?: string | null;
  longueurFormatFiniCm?: string | null;
  longueurFormatOuvertCm?: string | null;
  longueurFormatPageCm?: string | null;
  longueurInterieurEncartFormatFiniCm?: string | null;
  longueurInterieurEncartFormatOuvertCm?: string | null;
  longueurInterieurEncartFormatPageCm?: string | null;
  longueurInterieurFormatFiniCm?: string | null;
  longueurInterieurFormatOuvertCm?: string | null;
  longueurInterieurFormatPageCm?: string | null;
  longueurTailleFenetreCm?: string | null;
  matiere?: string | null;
  matiereCouverture?: string | null;
  matiereInterieur?: string | null;
  nbCouleurCouvertureRecto?: string | null;
  nbCouleurCouvertureVerso?: string | null;
  nbCouleurInterieurEncartRecto?: string | null;
  nbCouleurInterieurEncartVerso?: string | null;
  nbCouleurInterieurRecto?: string | null;
  nbCouleurInterieurVerso?: string | null;
  nbCouleurRecto?: string | null;
  nbCouleurVerso?: string | null;
  optionsFaconnage?: string | null;
  orientation?: string | null;
  ouvertureEnveloppe?: string | null;
  paginationCouverture?: string | null;
  paginationInterieur?: string | null;
  paginationInterieurEncart?: string | null;
  paginationTotale?: string | null;
  precisionsFaconnage?: string | null;
  precisionsFourniture?: string | null;
  profondeurFormatFiniCm?: string | null;
  repiquage?: string | null;
  repiquageCoeur?: string | null;
  repiquageDOS?: string | null;
  surfacage?: string | null;
  surfacageCouvertureRecto?: string | null;
  surfacageCouvertureVerso?: string | null;
  surfacageInterieurRecto?: string | null;
  surfacageInterieurVerso?: string | null;
  taille?: string | null;
  tauxSurfacage?: string | null;
  typeImpression?: string | null;
  typeMatiere?: string | null;
  typeMatiereInterieur?: string | null;
  typeMatiereCouverture?: string | null;
  typePapier?: string | null;
  typeSupport?: string | null;
  zoneTerritorial?: string | null;
  typeEnvoi?: string | null;
  paysDeDiffusion?: string | null;
  objectifMarketing?: string | null;
  modeleAchat?: string | null;
  ciblage?: string | null;
  largeurPixel?: string | null;
  longueurPixel?: string | null;
  fondsPerdus?: string | null;
  faconnageDeLivraison?: string | null;
}

export interface SupplyProductsFilterInput {
  supportId: string;
  attributes?: SupplyAttributesFilterInput | null;
}

export interface TagsFilterInput {
  title?: string | null;
  type: TagType;
}

export interface TemplateBigoneMetadataInput {
  _?: boolean | null;
}

export interface TemplateChiliMetadataInput {
  templateId: string;
}

export interface TemplateInput {
  type: TemplateType;
  metadata: TemplateMetadataInput;
}

export interface TemplateMetadataInput {
  bigone?: TemplateBigoneMetadataInput | null;
  chili?: TemplateChiliMetadataInput | null;
}

export interface UpdateOrderStatusInput {
  id: string;
  followStatus: FollowOrderStatus;
  statusComment?: string | null;
}

export interface VersionPageInput {
  validated?: boolean | null;
  isCustomizable?: boolean | null;
  incompleteProducts?: number | null;
  unseenComments?: number | null;
  pageTemplate?: any[] | null;
  backgroundColor?: BackgroundColorInput | null;
  comments?: CommentInput[] | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
