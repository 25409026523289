import React, { ChangeEvent } from 'react';
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';
import {
  Button,
  Popover,
  Box,
  Typography,
  TextField,
  Link,
} from '@material-ui/core';
import { GetCampaignAttachments_getCampaignAttachments } from 'app/schemaInterfaces/GetCampaignAttachments';
import _ from 'lodash';
import { UncontrolledMultipleValueSelect } from 'components/UncontrolledMultipleValueSelect';
import { SourceFileFilters } from 'modules/pro/SourceFiles/SourceFilesContainer';
import mime from 'mime-types';

interface SourceFileFiltersPopoverProps {
  height: number;
  data: GetCampaignAttachments_getCampaignAttachments[];
  filters: SourceFileFilters;
  setFilters: React.Dispatch<React.SetStateAction<SourceFileFilters>>;
}

export const SourceFileFiltersPopover = ({
  height,
  data,
  filters,
  setFilters,
}: SourceFileFiltersPopoverProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [mimeTypeFilters, setMimeTypeFilters] = React.useState<string[]>([]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeMaxSize = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFilters({
      ...filters,
      size: { ...filters.size, max: +event.target.value },
    });
  };

  const handleChangeMinSize = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFilters({
      ...filters,
      size: { ...filters.size, min: +event.target.value },
    });
  };

  const handleChangeOwner = (event: ChangeEvent<{ value: string[] }>) => {
    setFilters({ ...filters, owners: event.target.value });
  };

  const handleChangeMimetype = (event: ChangeEvent<{ value: string[] }>) => {
    const mimetypes: string[] = event.target.value.map(
      (mimetype) => mime.contentType(mimetype) as string
    );
    setMimeTypeFilters(event.target.value);
    setFilters({ ...filters, mimetypes });
  };

  const resetFilter = () => {
    setFilters({});
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const owners: string[] = _.uniq(data.map((document) => document.userId));
  const mimetypes: string[] = _.uniq(
    data.map((document) => `${mime.extension(document.mimetype)}`)
  );

  return (
    <Box>
      <Button startIcon={<FilterListOutlinedIcon />} onClick={handleClick}>
        Filtrer
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box width="520px" height={height} p={2}>
          <Box display="flex" alignItems="center" pb={2}>
            <Box width="30%" display="flex">
              <Typography variant="body2">Taille (en Ko) :</Typography>
            </Box>
            <Box width="35%" pr={2}>
              <TextField
                name="min"
                variant="outlined"
                value={filters.size?.min ?? 0}
                onChange={handleChangeMinSize}
                margin="normal"
                size="small"
                label="Min"
                autoFocus
                inputProps={{ type: 'number', min: '0' }}
              />
            </Box>
            <Box width="35%">
              <TextField
                name="max"
                variant="outlined"
                value={filters.size?.max ?? 0}
                onChange={handleChangeMaxSize}
                margin="normal"
                size="small"
                label="Max"
                autoFocus
                inputProps={{ type: 'number', min: '0' }}
              />
            </Box>
          </Box>
          <Box display="flex" alignItems="center" pb={2}>
            <Box width="30%" display="flex">
              <Typography variant="body2">Propriétaire :</Typography>
            </Box>
            <Box width="70%" display="flex">
              <UncontrolledMultipleValueSelect
                options={owners}
                value={filters.owners ?? []}
                handleChange={handleChangeOwner}
                fullWidth
                placeholder="Tous"
              />
            </Box>
          </Box>
          <Box display="flex" alignItems="center" pb={2}>
            <Box width="30%" display="flex">
              <Typography variant="body2">Catégorie de support :</Typography>
            </Box>
            <Box width="70%" display="flex">
              <UncontrolledMultipleValueSelect
                options={mimetypes}
                value={mimeTypeFilters ?? []}
                handleChange={handleChangeMimetype}
                fullWidth
                placeholder="Tous"
              />
            </Box>
          </Box>

          <Box pt={2}>
            <Typography variant="overline">
              <Link
                component="button"
                variant="body2"
                color="primary"
                underline="always"
                onClick={resetFilter}
              >
                Réinitialiser tous les filtres
              </Link>
            </Typography>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};
