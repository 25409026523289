import { css } from '@emotion/react';
import { Snackbar, SnackbarCloseReason } from '@material-ui/core';
import React from 'react';
import colors from 'styles/colors.module.scss';

export enum UploadStatus {
  InQueue,
  InProgress,
  Success,
  Failed,
}

export interface UploadProgress {
  name: string;
  status: UploadStatus;
  progress?: number;
  errorMessage?: string;
}

export enum SnackBarType {
  INFO = 'info',
  ERROR = 'error',
  SUCCESS = 'success',
}

interface SnackBarProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: () => void;
  message?: string;
  title?: string;
  icon?: React.ReactNode;
  type: SnackBarType;
}

const SnackBar = ({
  open = false,
  setOpen,
  message,
  type,
  title,
  icon,
}: SnackBarProps): JSX.Element => {
  let color = '';
  switch (type) {
    case SnackBarType.INFO:
      color = colors.selectedCheckboxBorderColor;
      break;
    case SnackBarType.ERROR:
      color = colors.tagTextColor0;
      break;
    case SnackBarType.SUCCESS:
      color = colors.loader6;
      break;

    default:
      console.log(`Not permitted Type`);
  }
  const style = css`
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
    background-color: ${colors.white};
    color: ${colors.grey};
    min-height: 74px;
    width: 436px;
    margin-top: 55px;
    border-left: solid 6px ${color};
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .icon {
      padding-bottom: 16px;
      color: ${color};
      padding-right: ${icon && '20px'};
    }

    .snackbar-box {
      display: flex;
      padding-left: 20px;

      .message {
        display: flex;
        flex-direction: column;
        justify-content: center;

        p {
          font-size: 12px;
        }
        h5 {
          font-weight: 400;
        }
      }
    }

    .MuiSnackbar-anchorOriginTopRigh {
      justify-content: flex-start;
    }
  `;
  const handleClose = (
    _: React.SyntheticEvent<any, Event>,
    reason: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
      css={style}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <div className="snackbar-box">
        <div className="icon">{icon}</div>
        <div className="message">
          <h5> {title}</h5>
          <p> {message}</p>
        </div>
      </div>
    </Snackbar>
  );
};

export default SnackBar;
