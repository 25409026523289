import { useMutation } from '@apollo/client';
import { CREATE_DOCUMENT_THUMBNAIL_UPLOAD_URL } from 'app/graphql/queries/version';
import {
  CreateDocumentThumbnailUploadUrl,
  CreateDocumentThumbnailUploadUrlVariables,
} from 'app/schemaInterfaces/CreateDocumentThumbnailUploadUrl';
import {
  CampaignType,
  DocumentCreateInput,
  DocumentUpdateInput,
  NotificationType,
} from 'app/schemaInterfaces/globalTypes';
import { useMutationErrorWorkflow } from 'app/utils/customHooks/useMutationErrorWorkflow';
import { uploadFile } from 'app/utils/FileUpload/FileUpload';
import Loader from 'components/Loader/Loader/Loader';
import useCreateNotificationForMultipleEntities from 'modules/notifications/mutations/useCreateNotificationForMultipleEntities';
import useUpdateNotificationsDateByCondition from 'modules/notifications/mutations/useUpdateNotificationsDateByCondition';
import DocumentForm from 'modules/pro/documents/form/DocumentForm';
import useDocumentMutation from 'modules/pro/documents/useDocumentMutation';
import useCampaignByIdQuery from 'modules/shop/queries/useCampaignByIdQuery';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

const DocumentFormContainer = () => {
  const { goBack } = useHistory();
  const { id: campaignId, documentId } = useParams<{
    id: string;
    documentId: string;
  }>();
  const [createDocumentThumbnailUploadUrl] = useMutation<
    CreateDocumentThumbnailUploadUrl,
    CreateDocumentThumbnailUploadUrlVariables
  >(CREATE_DOCUMENT_THUMBNAIL_UPLOAD_URL);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { createDocument, updateDocument } = useDocumentMutation();

  const updateDocumentWithErrorWorkflow = useMutationErrorWorkflow({
    request: updateDocument,
  });

  const createDocumentWithErrorWorkflow = useMutationErrorWorkflow({
    request: createDocument,
  });

  const {
    updateNotificationsDateByItemId,
  } = useUpdateNotificationsDateByCondition();
  const {
    createNotificationForMultipleEntities,
  } = useCreateNotificationForMultipleEntities();
  const {
    data: campaignData,
    loading: campaignDataLoading,
  } = useCampaignByIdQuery(campaignId);

  const handleSubmit = async (
    document: DocumentCreateInput | DocumentUpdateInput,
    file?: File | null
  ) => {
    setIsLoading(true);
    if (file) {
      const { data } = await createDocumentThumbnailUploadUrl({
        variables: { mimetype: file.type },
      });

      if (data) {
        await uploadFile(file, data.versionThumbnailUploadUrl.url, file.type);
        document.thumbnailId = data.versionThumbnailUploadUrl.id;
      }
    }
    if (documentId) {
      const updatedDocument = await updateDocumentWithErrorWorkflow({
        documentId,
        values: { ...document, campaignId },
      });
      if (
        updatedDocument?.data?.updateDocument.commitmentDateFrom ??
        updatedDocument?.data?.updateDocument.freeOrderDateFrom
      ) {
        await updateNotificationsDateByItemId({
          variables: {
            conditionItemId: campaignId,
            type: CampaignType.National
              ? NotificationType.CAMPAIGN_CREATED
              : NotificationType.THEMATIC_CREATED,
            sendingDate:
              updatedDocument?.data?.updateDocument.commitmentDateFrom ??
              updatedDocument?.data?.updateDocument.freeOrderDateFrom,
          },
        });
      }
    } else {
      const result = await createDocumentWithErrorWorkflow({
        values: document,
      });
      if (
        result?.data?.createDocument &&
        (document.commitmentDateFrom ?? document.freeOrderDateFrom)
      ) {
        const updatedNotifications = await updateNotificationsDateByItemId({
          variables: {
            conditionItemId: campaignId,
            type: CampaignType.National
              ? NotificationType.CAMPAIGN_CREATED
              : NotificationType.THEMATIC_CREATED,
            sendingDate:
              document.commitmentDateFrom ?? document.freeOrderDateFrom,
          },
        });
        if (
          updatedNotifications.data?.updateNotificationsDateByItemId &&
          updatedNotifications.data?.updateNotificationsDateByItemId?.length ===
            0
        ) {
          await createNotificationForMultipleEntities({
            variables: {
              entitiesIds:
                campaignData?.getCampaignById.entities.map(({ id }) => id) ??
                [],
              notificationItem: {
                conditionItemId: campaignId,
                type:
                  campaignData?.getCampaignById.campaignType ===
                  CampaignType.National
                    ? NotificationType.CAMPAIGN_CREATED
                    : NotificationType.THEMATIC_CREATED,
                sendingDate:
                  document.commitmentDateFrom ?? document.freeOrderDateFrom,
                metadata: {
                  campaignType: campaignData?.getCampaignById.campaignType,
                  campaignName: campaignData?.getCampaignById.name,
                },
              },
            },
          });
        }
      }
    }
    setIsLoading(false);
    goBack();
  };

  return isLoading || campaignDataLoading ? (
    <Loader />
  ) : (
    <DocumentForm
      documentId={documentId}
      campaignId={campaignId}
      campaign={campaignData?.getCampaignById}
      onSubmit={handleSubmit}
    />
  );
};

export default DocumentFormContainer;
