import { css } from '@emotion/react';

export const linearProgressBarThumbStyle = (thumbColor: string) => css`
  background-color: ${thumbColor};
  border-radius: 15px;
`;

export const linearProgressBarStyle = (backgroundColor: string) => css`
  background-color: ${backgroundColor};
  border: 1px solid ${backgroundColor};
  height: 8px;
  border-radius: 10px;
`;
