import { Box, Button, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import colors from 'styles/colors.module.scss';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import { useTranslation } from 'react-i18next';
import DownloadFileButton from 'components/DownloadFileButton/DownloadFileButton';
import {
  useSelectedAssetsToolbar,
  UseSelectedAssetsToolbarParams,
} from './SelectedAssetsToolbar.hooks';

export interface SelectedAssetsToolbarProps
  extends UseSelectedAssetsToolbarParams {
  count: number;
  isAssetsDownloadable: boolean;
}

const SelectedAssetsToolbar: FC<SelectedAssetsToolbarProps> = ({
  count,
  isAssetsDownloadable,
  ...params
}) => {
  const { t } = useTranslation();
  const {
    isDownloading,
    handleSelectedAssetsDownload,
    deleteManyLoading,
    deleteMedias,
  } = useSelectedAssetsToolbar(params);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      py={2}
      px={4}
      bgcolor={colors.menuHover}
      mb={1}
    >
      <Typography variant="body1">
        {count} {t('dam.action_toolbar.selected_media', { count })}
      </Typography>
      {isAssetsDownloadable && (
        <Box display="flex" alignItems="center">
          <DownloadFileButton
            onDownload={handleSelectedAssetsDownload}
            isDownloading={isDownloading}
          />
          <Box pl={2}>
            <Button
              color="primary"
              startIcon={<DeleteForeverOutlinedIcon />}
              onClick={deleteMedias}
              disabled={deleteManyLoading}
            >
              <Typography variant="button">
                {t('dam.action_toolbar.delete')}
              </Typography>
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SelectedAssetsToolbar;
