import React, { useEffect, useState } from 'react';
import EmptyTableFileUpload from 'components/EmptyTableFileUpload';
import { PreviewFile } from 'components/ControlledFileUpload/ControlledFileUpload';
import { Box } from '@material-ui/core';
import ProductsSheetsFormSingleAsset from 'modules/pim/components/ProductsSheetsFormSingleAsset';
import { MetadataInput } from 'app/schemaInterfaces/globalTypes';
import colors from 'styles/colors.module.scss';

import { getBase64File } from 'app/utils/FileUpload/FileUpload';
import { displayableAssets, extractMetadata } from 'modules/dam/helpers';
import { useTranslation } from 'react-i18next';

const ProductsSheetFilesImport = ({
  productId,
}: {
  productId?: string;
}): JSX.Element => {
  const { t } = useTranslation();
  const [file, setFile] = useState<PreviewFile | undefined>();
  const [base64File, setBase64File] = useState<string>('');
  const [fileDimensionsAndCredit, setFileDimensionsAndCredit] = useState<
    Partial<MetadataInput>
  >({
    dimensions: undefined,
    credit: '',
  });
  const [reportError, setReportError] = useState<string | null>(null);

  const { dimensions, credit } = fileDimensionsAndCredit;

  const onFileChange = async (files: File[]) => {
    if (files.length) {
      setFile(files[0] as PreviewFile);
      const base64 = (await getBase64File(files[0])) as string;
      setBase64File(base64);
      try {
        const extractedMetadata = await extractMetadata(files[0]);
        setFileDimensionsAndCredit({
          dimensions: extractedMetadata?.dimensions,
          credit: extractedMetadata?.credit,
        });
      } catch (e) {
        setReportError((e as any).message);
      }
    }
  };

  useEffect(() => {
    if (!file) {
      setReportError(null);
    }
  }, [file]);

  return !file ? (
    <EmptyTableFileUpload
      img={`/${process.env.REACT_APP_ASSETS}/emptySourceFiles.svg`}
      subtitle={t('pim.upload.drag_and_drop_file')}
      buttonLabel={t('pim.upload.import_media')}
      onFileChange={onFileChange}
      acceptedFiles={displayableAssets}
      imgWidth="250px"
    />
  ) : (
    <Box bgcolor={colors.documentsHeaderBackground}>
      <ProductsSheetsFormSingleAsset
        file={file}
        base64File={base64File}
        setFile={setFile}
        fileDimensions={dimensions}
        fileCredit={credit}
        reportError={reportError}
        productId={productId}
      />
    </Box>
  );
};

export default ProductsSheetFilesImport;
