import { Box, createStyles } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  DraggedAsset,
  Position,
  useStudioDispatchContext,
  useStudioStateContext,
} from 'modules/workflow/oneStudioContext';
import { studioAppActions } from 'modules/workflow/reducers/actionsInterfaces';
import { processDpi } from 'app/utils/math';
import StatusBadge, { BadgeStatus } from 'components/Badge/StatusBadge';
import { NumberSize } from 're-resizable';
import { Direction } from 're-resizable/lib/resizer';
import React, { useEffect, useState } from 'react';
import { MILIMITER_TO_PIXELS } from 'modules/workflow/containers/CatalogPreview/singlePaperHelper';
import { useTranslation } from 'react-i18next';
import { Rnd, DraggableData, RndDragEvent } from 'react-rnd';
import EditionButtons from '../../CatalogPreview/EditionButtons';
import { css } from '@emotion/react';

interface AssetProps {
  pageNumber: number;
  zoneIndex: number;
  assetIndex: number;
  asset: DraggedAsset;
  isDraggableResizable?: boolean;
}

const style = css`
  position: absolute;
  z-index: 1;
  .buttons {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 17;
  }
`;
const useStyles = makeStyles((theme) =>
  createStyles({
    conformityBadge: {
      position: 'absolute',
      top: 20,
      right: 20,
    },
  })
);

const Asset = ({
  pageNumber,
  zoneIndex,
  assetIndex,
  asset,
  isDraggableResizable = true,
}: AssetProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useStudioDispatchContext();
  const globalState = useStudioStateContext();
  const [conform, setConform] = useState(true);
  const customClasses = useStyles();
  const [isActive, setIsActive] = useState<boolean>(false);

  useEffect(() => {
    const resizableSurfaceWidth = asset.size.width / (MILIMITER_TO_PIXELS * 10);
    const resizableSurfaceHeight =
      asset.size.height / (MILIMITER_TO_PIXELS * 10);
    const dpiWidth = processDpi(
      asset.metadata.dimensions.width,
      resizableSurfaceWidth
    );
    const dpiHeight = processDpi(
      asset.metadata.dimensions.height,
      resizableSurfaceHeight
    );

    setConform(Math.min(dpiWidth, dpiHeight) >= 300);
  }, [asset.size, asset.metadata]);

  const resizeHandler = (
    event: MouseEvent | TouchEvent,
    direction: Direction,
    ref: HTMLElement,
    delta: NumberSize,
    position: Position
  ) => {
    const newAsset = {
      ...asset,
      size: {
        height: asset.size.height + delta.height,
        width: asset.size.width + delta.width,
      },
      position: { x: position.x, y: position.y },
    };

    dispatch(
      studioAppActions.updateAsset({
        pageNumber,
        zoneIndex,
        assetIndex,
        newAsset,
      })
    );
  };

  const dragHandler = (event: RndDragEvent, position: DraggableData) => {
    const newAsset = {
      ...asset,
      position: { x: position.x, y: position.y },
    };

    dispatch(
      studioAppActions.updateAsset({
        pageNumber,
        zoneIndex,
        assetIndex,
        newAsset,
      })
    );
  };

  return (
    <Rnd
      className={
        isDraggableResizable && !globalState.commentMode
          ? 'resizable-item'
          : 'comment'
      }
      css={style}
      position={asset.position}
      lockAspectRatio={true}
      scale={globalState.scaleValue}
      size={asset.size}
      disableDragging={!isDraggableResizable}
      dragAxis="both"
      dragHandleClassName="handle"
      bounds="parent"
      onResizeStop={resizeHandler}
      onDragStop={dragHandler}
      onMouseOver={() => {
        !globalState.commentMode && setIsActive(true);
      }}
      onMouseLeave={() => {
        setIsActive(false);
      }}
    >
      <Box className="handle">
        <img
          className="handle"
          draggable="false"
          src={asset.thumbnailUrl ?? asset.url}
          alt="logoURL"
          style={{
            objectFit: 'contain',
            width: asset.size.width,
            height: asset.size.height,
          }}
        />
        <StatusBadge
          status={conform ? BadgeStatus.Success : BadgeStatus.Error}
          className={customClasses.conformityBadge}
          message={
            conform
              ? t('dam.conformity.conform')
              : t('dam.conformity.non_conform')
          }
        />
      </Box>
      {isActive && <EditionButtons<DraggedAsset> item={asset} />}
    </Rnd>
  );
};

export default Asset;
