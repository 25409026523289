import React, { FC } from 'react';
import { Button, Typography, makeStyles } from '@material-ui/core';
import {
  useStudioDispatchContext,
  useStudioStateContext,
} from 'modules/workflow/oneStudioContext';
import { studioAppActions } from 'modules/workflow/reducers/actionsInterfaces';
import colors from 'styles/colors.module.scss';
import { useTranslation } from 'react-i18next';
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';

const useStyles = makeStyles({
  buttonStyle: {
    color: colors.grey,
  },
  buttonStyleActive: {
    color: colors.primary,
  },
});

const CommentsToggler: FC = () => {
  const globalState = useStudioStateContext();
  const dispatch = useStudioDispatchContext();
  const classes = useStyles();
  const { t } = useTranslation();

  let currentPageIsValidated: boolean =
    globalState.version.pages[globalState.currentPage - 1]?.validated;

  const buttonClass = globalState.commentMode
    ? classes.buttonStyleActive
    : classes.buttonStyle;

  return (
    <>
      <Button
        classes={{ root: buttonClass }}
        startIcon={<CommentOutlinedIcon />}
        disabled={currentPageIsValidated}
        onClick={() => {
          dispatch(
            studioAppActions.setCommentMode({
              commentMode: !globalState.commentMode,
            })
          );
        }}
      >
        <Typography variant="h5">
          {globalState.commentMode
            ? t(`workflow.hide_comments`)
            : t(`workflow.add_comment`)}
        </Typography>
      </Button>
    </>
  );
};

export default CommentsToggler;
