import { EntityType, UserType } from 'app/schemaInterfaces/globalTypes';
import {
  GetEntitiesList,
  GetEntitiesListVariables,
  GetEntitiesList_entities,
} from 'app/schemaInterfaces/GetEntitiesList';
import { useMemo, useState } from 'react';

import { GET_ENTITIES_LIST } from 'app/graphql/queries/entities';
import { Maybe } from 'app/utils/common';
import { GetCurrentClient_client_structures as Structure } from 'app/schemaInterfaces/GetCurrentClient';
import _ from 'lodash';
import useAppContext from 'app/app-context/useAppContext';
import useCurrentEntity from 'app/current-entity-context/useCurrentEntity';
import { useCurrentUser } from 'app/auth/UserContext';
import { useLazyQuery } from '@apollo/client';

export const useEntityMenu = () => {
  const {
    client: { structures },
  } = useAppContext();

  const user = useCurrentUser();

  const {
    currentEntity,
    setCurrentEntity,
    fetchAndSetCurrentEntity,
    visibleStructures,
  } = useCurrentEntity();

  const [getEntities, { data, loading }] = useLazyQuery<
    GetEntitiesList,
    GetEntitiesListVariables
  >(GET_ENTITIES_LIST);

  const [selectedStructure, setSelectedStructure] = useState<Maybe<Structure>>(
    null
  );

  // The tree structure path of the current entity
  const currentEntityPath = useMemo(
    () => (currentEntity ? [currentEntity, ...currentEntity.ancestors] : []),
    [currentEntity]
  );

  // A dictionary of the user entities grouped by type
  const userEntitiesByType = useMemo(
    () =>
      _.chain(
        user.entities.map((entity) => [entity, ...entity.ancestors]).flat()
      )
        .uniqBy('id')
        .groupBy('type')
        .value(),
    [user.entities]
  );

  const getEntityByType = (type: EntityType) =>
    currentEntityPath.find((entity) => entity.type === type) ?? null;

  const handleCardClick = (structure: Structure) => {
    // Get the ancestor from the current entity path in order to use it in the query filters
    const ancestor =
      currentEntityPath.find(({ level }) => {
        const structureKey = structures.findIndex(
          (structure) => structure.level === level
        );
        return (
          structureKey > -1 &&
          structures[structureKey].isBillable &&
          structure.level > level
        );
      }) ?? null;

    setSelectedStructure(structure);
    getEntities({
      variables: {
        filters: {
          types: [structure.type],
          ancestors: ancestor && [ancestor.id],
        },
      },
    });
  };

  const handleEntitySelect = (entity: Maybe<GetEntitiesList_entities>) => {
    if (entity || !currentEntity) {
      fetchAndSetCurrentEntity(entity?.id);
    }
    // If null, find the the next ancestor to set as currentEntity
    else {
      let index = currentEntityPath.findIndex(
        ({ isBillable, level }) =>
          isBillable &&
          level < selectedStructure!.level &&
          visibleStructures.some((struct) => struct.level === level)
      );
      const newEntity =
        index !== -1
          ? {
              ...currentEntityPath[index],
              ancestors: currentEntityPath.slice(index + 1),
            }
          : null;

      setCurrentEntity(newEntity);
    }
    setSelectedStructure(null);
  };

  const isMenuItemDisabled = (structure: Structure) =>
    user.type === UserType.Local &&
    (structure.type !== EntityType.Store ||
      userEntitiesByType[EntityType.Store]?.length < 2);

  return {
    entities: data?.entities || [],
    loading,
    visibleStructures,
    isMenuItemDisabled,
    handleEntitySelect,
    getEntityByType,
    handleCardClick,
    selectedStructure,
    setSelectedStructure,
  };
};
