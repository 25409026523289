import { useQuery } from '@apollo/client';
import { GET_MULTIPLE_UNIQUE_PRICES } from 'app/graphql/queries/prices';
import { GetBasketDocuments_basket_basketDocuments } from 'app/schemaInterfaces/GetBasketDocuments';
import {
  getMultipleUniquePrices,
  getMultipleUniquePricesVariables,
} from 'app/schemaInterfaces/getMultipleUniquePrices';
import { MultipleUniquePricesProductInput } from 'app/schemaInterfaces/globalTypes';
import { getGqlErrorMessage } from 'app/utils/errors';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PriceI18nKeyErrorDictionary } from '../components/BasketRow/BasketRow.helper';

export interface UseBasketConfirmationPricingRequestData {
  products: GetBasketDocuments_basket_basketDocuments[];
}

export function useBasketConfirmationPricingRequest({
  products,
}: UseBasketConfirmationPricingRequestData) {
  const { t } = useTranslation();

  const requestedProducts = products
    .map((it) => ({ productId: it.document.productId, quantity: it.quantity }))
    .filter(
      (it): it is MultipleUniquePricesProductInput =>
        !!it.productId && !!it.quantity
    );

  const [cachedData, setCachedData] = useState<
    getMultipleUniquePrices | undefined
  >(undefined);
  const [errors, setErrors] = useState<string[]>([]);
  const { loading } = useQuery<
    getMultipleUniquePrices,
    getMultipleUniquePricesVariables
  >(GET_MULTIPLE_UNIQUE_PRICES, {
    variables: { products: requestedProducts },
    fetchPolicy: 'no-cache',
    skip: requestedProducts.length === 0,
    onCompleted: (data) => {
      setErrors([]);
      setCachedData(data);
    },
    onError: (error) => {
      setErrors(getGqlErrorMessage(error, PriceI18nKeyErrorDictionary, t));
    },
  });

  return {
    products: products.map((product) => ({
      product,
      pricing: cachedData?.multipleUniquePrices?.uniquePrices.find(
        (it) =>
          it.productId === product.document.productId &&
          it.quantity === product.quantity
      ),
    })),
    total: !loading ? cachedData?.multipleUniquePrices : undefined,
    errors: !loading ? errors : [],
  };
}
