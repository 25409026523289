import {
  FormControl,
  Box,
  Typography,
  TextField,
  MenuItem,
} from '@material-ui/core';
import { ChangeEvent } from 'react';
import colors from 'styles/colors.module.scss';
import style from 'styles/show.style';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { GetEntityBillingAdresses_entityBillingAdresses } from 'app/schemaInterfaces/GetEntityBillingAdresses';
import { useStoreState } from 'state/store';

interface SelectBillingAddressesProps {
  handleChange: (
    event: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => void;
  defaultBillingAddress?: GetEntityBillingAdresses_entityBillingAdresses;
  availableOptions?: GetEntityBillingAdresses_entityBillingAdresses[];
}

const SelectBillingAddresses = ({
  handleChange,
  defaultBillingAddress,
  availableOptions,
}: SelectBillingAddressesProps) => {
  const currentBillingAddress = useStoreState(
    (state) => state.salesModule.billingAddress.billingAddress
  );

  return (
    <FormControl fullWidth css={style}>
      <TextField
        select
        variant="outlined"
        color="primary"
        SelectProps={{
          renderValue: (option: any) => {
            return option || '';
          },
          value: currentBillingAddress?.name ?? '',
          defaultValue: defaultBillingAddress?.name ?? '',
          IconComponent: (props) => <KeyboardArrowDownIcon {...props} />,
        }}
        onChange={handleChange}
      >
        {availableOptions?.map((item) => (
          <MenuItem
            style={{
              backgroundColor:
                currentBillingAddress?.name === item.name
                  ? colors.menuHover
                  : 'none',
            }}
            value={item.name || ''}
            key={item.billingAddress?.id}
          >
            <Box display="flex">
              <Box gridRow={1}>
                <Typography variant="h5">{item.name}</Typography>
                <Typography variant="h6">
                  {item.billingAddress?.name}
                </Typography>
                <Typography variant="body1">
                  {item.billingAddress?.firstAddress}
                </Typography>
                <Typography variant="body1">
                  {item.billingAddress?.city}{' '}
                </Typography>
              </Box>
            </Box>
          </MenuItem>
        )) ?? <span />}
      </TextField>
    </FormControl>
  );
};

export default SelectBillingAddresses;
