import React from 'react';
import { Switch } from 'react-router-dom';
import ProtectedRoute from 'components/Navigation/ProtectedRoute/ProtectedRoute';
import FollowOrdersPage from 'modules/followOrders/pages/follow-orders-page/FollowOrdersPage/FollowOrdersPage';

const FollowOrdersModule = () => (
  <Switch>
    <ProtectedRoute path={'/follow-orders'} open={true}>
      <FollowOrdersPage />
    </ProtectedRoute>
  </Switch>
);

export default FollowOrdersModule;
