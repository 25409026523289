import React, { useEffect } from 'react';
import { Box, Grid } from '@material-ui/core';
import {
  useStudioStateContext,
  Page,
  useStudioDispatchContext,
} from 'modules/workflow/oneStudioContext';
import SinglePaper from 'modules/workflow/containers/CatalogPreview/SinglePaper';
import colors from 'styles/colors.module.scss';
import { useQuery } from '@apollo/client';
import { GET_DOCUMENT } from 'app/graphql/queries/documents';
import {
  GetDocument,
  GetDocumentVariables,
} from 'app/schemaInterfaces/GetDocument';
import { useRouter } from 'app/routes/useRouter';
import { UrlParams } from 'modules/workflow/containers/Toolbar/FirstToolbar/FirstToolbar';
import CircularProgressLoader from 'components/Loader/CircularProgressLoader/CircularProgressLoader';
import { SCALE_VALUE_A4 } from 'styles/theme';
import { studioAppActions } from 'modules/workflow/reducers/actionsInterfaces';
import { ItemSize } from 'modules/workflow/containers/CatalogPreview/catalogPreviewHelper';
import { getLengthFromCmToPx } from 'app/utils/math';

export interface PagesComparisonDataOfSelectedBATInterface {
  firstPage: {
    selectedOption: string;
    selectedImageURL?: string;
  };
  secondPage: {
    selectedOption: string;
    selectedImageURL?: string;
  };
}

export interface PageWithIndex extends Page {
  pageIndex: number;
}

interface CatalogPreviewProps {
  zoomScale: number;
}

// Régle de trois en se basant sur le scale value du fomat A4 (21x29.7)
// Scale homogéne donc on prend le max entre width/height
const getScaleValue = ({ width, height }: ItemSize): number =>
  (getLengthFromCmToPx(29.7) * SCALE_VALUE_A4) / Math.max(width, height);

const CatalogPreview = React.forwardRef<HTMLElement, CatalogPreviewProps>(
  ({ zoomScale }: CatalogPreviewProps, ref): JSX.Element => {
    let globalState = useStudioStateContext();
    const dispatch = useStudioDispatchContext();

    const { documentId } = useRouter<UrlParams>().query;
    const { data: documentData, error: documentError } = useQuery<
      GetDocument,
      GetDocumentVariables
    >(GET_DOCUMENT, {
      variables: { documentId },
    });

    const pageDimension = {
      width: documentData?.document.widthInPx ?? 0,
      height: documentData?.document.heightInPx ?? 0,
    };

    const isLastPage =
      globalState.currentPage === globalState.version.pages.length;

    const pageColumns = !globalState.isTwoPagesView || isLastPage ? 12 : 6;

    useEffect(() => {
      if (
        documentData?.document?.widthInPx &&
        documentData?.document?.heightInPx
      ) {
        dispatch(
          studioAppActions.setScaleValue({
            scaleValue: getScaleValue({
              width: documentData.document.widthInPx,
              height: documentData.document.heightInPx,
            }),
          })
        );
      }
    }, [documentData, dispatch]);

    return (
      <Box
        display="inline-flex"
        height="100vh"
        flexGrow={1}
        paddingTop="40px"
        justifyContent="center"
        bgcolor={colors.workzone}
      >
        {documentData ? (
          <Box height="700px" display="flex" justifyContent="center">
            <Grid container item justify="center" direction="row" xs={9}>
              <Grid container item xs={pageColumns} justify="center">
                <Grid container item justify="center">
                  <Box
                    height="50px"
                    width={
                      (documentData?.document?.widthInPx ?? 0) *
                      globalState.scaleValue
                    }
                    display="flex"
                  ></Box>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  style={{ maxHeight: '605px' }}
                  item
                >
                  <SinglePaper
                    pageNumber={globalState.currentPage}
                    zoomScale={zoomScale}
                    pageDimension={pageDimension}
                    ref={ref}
                  />
                </Grid>
              </Grid>
              {globalState.isTwoPagesView &&
                globalState.currentPage !==
                  globalState.version.pages.length && (
                  <Grid container item xs={pageColumns}>
                    <Grid container item justify="center">
                      <Box height="50px" width="434px" display="flex"></Box>
                    </Grid>
                    <Grid
                      container
                      item
                      direction="row"
                      justify="center"
                      style={{ maxHeight: '605px' }}
                    >
                      <SinglePaper
                        zoomScale={zoomScale}
                        pageNumber={globalState.currentPage + 1}
                        pageDimension={pageDimension}
                      />
                    </Grid>
                  </Grid>
                )}
            </Grid>
          </Box>
        ) : (
          <CircularProgressLoader error={documentError} />
        )}
      </Box>
    );
  }
);

export default CatalogPreview;
