import { ApolloQueryResult } from '@apollo/client';
import {
  GetBasketDocuments,
  GetBasketDocumentsVariables,
  GetBasketDocuments_basket_basketDocuments,
} from 'app/schemaInterfaces/GetBasketDocuments';
import { isDigitalDocument } from 'app/utils/digital-helper';
import { SelectedBasketdocument } from 'modules/sales/sales.state';
import useClientPricesQuery from 'modules/shop/queries/useClientPricesQuery';
import useRemoveBasketDocumentMutation from 'queries/useRemoveBasketDocumentMutation';
import useUpdateBasketDocumentMutation from 'queries/useUpdateBasketDocumentMutation';
import { useEffect, useState } from 'react';
import colors from 'styles/colors.module.scss';
import { ClientPrice } from '../QuantitySelect';
import { hasQuantityError } from '../QuantitySelectHelper';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Checkbox } from '@material-ui/core';
import { useMutationErrorWorkflow } from 'app/utils/customHooks/useMutationErrorWorkflow';
import { BasketI18nKeyErrorDictionary } from 'modules/sales/pages/basket-page/basket-steps/StepsHelper';
import {
  BasketUpdateInput,
  DisabledDocumentErrors,
} from 'app/schemaInterfaces/globalTypes';

export interface UseBasketRowParams {
  basketDocument: GetBasketDocuments_basket_basketDocuments;
  selectedBasketDocuments: SelectedBasketdocument[];
  setSelectedBasketDocuments: React.Dispatch<
    React.SetStateAction<SelectedBasketdocument[]>
  >;
  refetch: (
    variables?: Partial<GetBasketDocumentsVariables> | undefined
  ) => Promise<ApolloQueryResult<GetBasketDocuments>>;
  isCampaignDisabled: boolean;
  addToSelectedDocuments?: (
    document: GetBasketDocuments_basket_basketDocuments,
    isChecked: boolean
  ) => void;
}

export const useBasketRow = ({
  basketDocument,
  selectedBasketDocuments,
  setSelectedBasketDocuments,
  refetch,
  isCampaignDisabled,
  addToSelectedDocuments,
}: UseBasketRowParams) => {
  const {
    updateBasketDocument: updateBasketDocumentRequest,
  } = useUpdateBasketDocumentMutation();
  const updateBasketDocument = useMutationErrorWorkflow({
    request: updateBasketDocumentRequest,
    i18nKeyErrorDictionary: BasketI18nKeyErrorDictionary,
    showConfirmationMessage: false,
  });
  const [hasPriceError, setHasPriceError] = useState<boolean>(false);

  const {
    removeBasketDocuments: removeBasketDocumentsRequest,
  } = useRemoveBasketDocumentMutation();
  const removeBasketDocuments = useMutationErrorWorkflow({
    request: removeBasketDocumentsRequest,
    i18nKeyErrorDictionary: BasketI18nKeyErrorDictionary,
    showConfirmationMessage: false,
  });

  const { id, quantity, price, productName } = basketDocument;

  const {
    isValidated,
    isCustomizable,
    mediumType,
    name,
    thumbnailUrl,
    version,
    productAttributes,
    isMandatory,
    productId,
    priceInformations,
  } = basketDocument.document;

  const [selectedValue, setSelectedValue] = useState<ClientPrice | null>({
    quantity,
    price,
    productName,
    isIncluded: priceInformations?.isIncluded ?? false, //temp
  });

  const isDeletable = !isMandatory || basketDocument.isOutdated;

  const minimumDocQuantity =
    basketDocument.document?.quantities?.rangeQuantity?.minimum ?? 0;
  const maximumDocQuantity =
    basketDocument.document?.quantities?.rangeQuantity?.maximum ?? undefined;
  const hasStrictQuantity = Boolean(
    basketDocument.document.quantities?.strictQuantity
  );

  const { clientPricesData, clientPricesLoading } = useClientPricesQuery({
    productId,
    minimum: minimumDocQuantity,
    maximum: maximumDocQuantity,
  });

  const [error, setError] = useState<boolean>(false);

  const lowestQuantity = clientPricesData?.prices.length
    ? clientPricesData?.prices[0].quantity ?? 0
    : 0;

  const updateSelection = (values?: ClientPrice) => {
    const updateSelection = selectedBasketDocuments.map(
      (selectedBasketDocument) => {
        if (
          selectedBasketDocument.basketDocument.document.id ===
          basketDocument.document.id
        ) {
          return {
            ...selectedBasketDocument,
            basketDocument: {
              ...selectedBasketDocument.basketDocument,
              quantity: values?.quantity ?? selectedValue?.quantity ?? null,
              price: values?.price ?? selectedValue?.price ?? null,
              productName:
                values?.productName ?? selectedValue?.productName ?? null,
            },
          };
        } else {
          return selectedBasketDocument;
        }
      }
    );

    setSelectedBasketDocuments(updateSelection);
  };

  const [isUpdatingPricing, setIsUpdatingPricing] = useState(false);

  const handleSelectValues = async (values?: ClientPrice) => {
    setIsUpdatingPricing(true);
    try {
      const quantity = values?.quantity ?? selectedValue?.quantity ?? 0;
      if (
        hasQuantityError({
          quantity,
          minQuantity: lowestQuantity,
          maxQuantity: maximumDocQuantity,
        })
      ) {
        setError(true);
        setSelectedValue(null);
      } else {
        setError(false);
        const documentValues = {
          quantity: values?.quantity ?? selectedValue?.quantity ?? null,
          price: values?.price ?? selectedValue?.price ?? null,
          productName:
            values?.productName ?? selectedValue?.productName ?? null,
        };

        setSelectedValue({
          ...documentValues,
          isIncluded: values?.isIncluded ?? selectedValue?.isIncluded ?? false,
        });
        updateSelection(values);
        if (
          documentValues.price !== null &&
          documentValues.productName &&
          documentValues.quantity
        ) {
          if (hasPriceError && values?.price) {
            setHasPriceError(false);
          }
          await updateBasketDocument({
            id,
            values: documentValues as BasketUpdateInput,
          });
          await refetch();
        }
      }
    } finally {
      setIsUpdatingPricing(false);
    }
  };

  const handlePriceError = () => {
    setHasPriceError(true);
    const index = selectedBasketDocuments.findIndex(
      (item) => item.basketDocument.id === basketDocument.id
    );

    if (index !== -1) {
      const filteredSelectedBasketDocuments = selectedBasketDocuments.splice(
        index,
        1
      );
      setSelectedBasketDocuments(filteredSelectedBasketDocuments);
    }
  };

  const validatedIcon: JSX.Element = (
    <CheckCircleOutlineIcon
      htmlColor={colors.loader1}
      style={{ marginLeft: '12px' }}
    />
  );

  const handleDelete = async () => {
    const response = await removeBasketDocuments({
      ids: [basketDocument.id],
    });
    if (!response) {
      return;
    }

    if (response.data?.removeBasketDocuments.isSuccess) {
      refetch();
    }
  };

  const isDisabled =
    isCampaignDisabled ||
    !basketDocument.document.isValidated ||
    (basketDocument.document.isDisabled?.disabled &&
      basketDocument.document.isDisabled?.reason !==
        DisabledDocumentErrors.documentInBasket) ||
    hasPriceError ||
    isUpdatingPricing;

  const checked = selectedBasketDocuments.some(
    (it) => it.basketDocument.id === basketDocument.id
  );

  const checkBox = (
    <Checkbox
      onChange={(event) =>
        addToSelectedDocuments?.(basketDocument, event.target.checked)
      }
      disabled={isDisabled}
      defaultChecked={checked}
    />
  );

  useEffect(() => {
    const quantity =
      basketDocument.document.quantities?.strictQuantity ??
      basketDocument.quantity;

    setSelectedValue({
      price: basketDocument.price ?? 0,
      quantity,
      productName: basketDocument.productName ?? '',
      isIncluded: priceInformations?.isIncluded,
    });
  }, [
    basketDocument.document.quantities?.strictQuantity,
    basketDocument.productName,
    basketDocument.quantity,
    basketDocument.price,
    priceInformations?.isIncluded,
  ]);

  const isQuantitySelectDisabled = hasStrictQuantity;

  const isDigital = isDigitalDocument(basketDocument.document.category);

  return {
    loading: clientPricesLoading,
    lowestQuantity,
    isValidated,
    isCustomizable,
    mediumType,
    isDisabled,
    name,
    thumbnailUrl,
    version,
    productAttributes,
    productId,
    error,
    handleSelectValues,
    validatedIcon,
    handleDelete,
    checkBox,
    options: clientPricesData?.prices,
    isQuantitySelectDisabled,
    isDigital,
    selectedValue,
    maximumDocQuantity,
    isDeletable,
    handlePriceError,
    hasPriceError,
    isUpdatingPricing,
  };
};
