import { Box } from '@material-ui/core';
import ProductsSheetFilesImport from 'modules/pim/components/ProductsSheetsFilesImport';
import ProductsSheetsFormAssets from 'modules/pim/components/ProductsSheetsFormAssets';
import ProductsSheetsFormSingleAsset from 'modules/pim/components/ProductsSheetsFormSingleAsset';
import React from 'react';
import SideFormTabs from 'components/SideFormTabs/SideFormTabs';
import { TabPanelProductsForm } from 'modules/pim/baseProducts/ProductsHelper';
import { useProductsSheetsContext } from 'modules/pim/baseProducts/BaseProducts';
import { useTranslation } from 'react-i18next';

interface ProductsSheetsFormAssetsTabsProps {
  setGetAssetId: React.Dispatch<React.SetStateAction<string | undefined>>;
  getAssetId?: string;
  productId?: string;
}

enum AssetTab {
  Media,
  Import,
}

const ProductsSheetsFormAssetsTabs = ({
  getAssetId,
  setGetAssetId,
  productId,
}: ProductsSheetsFormAssetsTabsProps): JSX.Element => {
  const { fieldName } = useProductsSheetsContext();
  const { t } = useTranslation();

  const [selectedTabIndex, setSelectedTabIndex] = React.useState<AssetTab>(
    AssetTab.Media
  );

  const tabs = [
    { name: t('pim.form.dam_medias') },
    { name: t('pim.form.import') },
  ];

  const handleChangeTabIndex = (
    event: React.ChangeEvent<{}>,
    tabIndex: number
  ) => {
    setSelectedTabIndex(tabIndex);
  };

  return (
    <Box width="408px">
      <SideFormTabs
        selectedTabIndex={selectedTabIndex}
        handleChangeTabIndex={handleChangeTabIndex}
        tabs={tabs}
      />
      <TabPanelProductsForm
        value={selectedTabIndex}
        index={AssetTab.Media}
        fieldName={fieldName}
      >
        {!getAssetId ? (
          <ProductsSheetsFormAssets setGetAssetId={setGetAssetId} />
        ) : (
          <Box pt={2}>
            <ProductsSheetsFormSingleAsset
              assetId={getAssetId}
              setGetAssetId={setGetAssetId}
              productId={productId}
            />
          </Box>
        )}
      </TabPanelProductsForm>
      <TabPanelProductsForm value={selectedTabIndex} index={AssetTab.Import}>
        <Box>
          <ProductsSheetFilesImport productId={productId} />
        </Box>
      </TabPanelProductsForm>
    </Box>
  );
};

export default ProductsSheetsFormAssetsTabs;
