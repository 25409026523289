import React from 'react';
import { Box, Typography, Avatar } from '@material-ui/core';
import { userBadgeColors } from 'app/utils/colorsCodesMap';
import { capitalizeFirstLetter } from 'app/utils/common';

interface UserBadgeProps {
  firstName: string;
  lastName: string;
  isFullnameEmphasised?: boolean;
  hasFullName?: boolean;
}

const UserBadge = ({
  firstName,
  lastName,
  isFullnameEmphasised,
  hasFullName = true,
}: UserBadgeProps): JSX.Element => {
  const userColors = userBadgeColors.get(firstName.trim()[0].toUpperCase());

  return (
    <Box display="flex" alignItems="center">
      <Box>
        <Avatar style={{ backgroundColor: userColors }}>
          <Typography variant="body2">
            {lastName.charAt(0).toUpperCase()}
            {firstName.charAt(0).toUpperCase()}
          </Typography>
        </Avatar>
      </Box>
      {hasFullName && (
        <Box
          display="flex"
          pl={1}
          color={isFullnameEmphasised ? userColors : 'inherit'}
        >
          <Typography variant={isFullnameEmphasised ? 'body2' : 'body1'}>
            {capitalizeFirstLetter(`${lastName} ${firstName}`)}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default UserBadge;
