import { createTheme } from '@material-ui/core/styles';
import colors from 'styles/colors.module.scss';
import 'styles/fonts/fonts.scss';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export const SCALE_VALUE_A4 = 0.535;

interface Fonts {
  h1?: CSSProperties;
  h2?: CSSProperties;
  h3?: CSSProperties;
  h4?: CSSProperties;
  h5?: CSSProperties;
  h6?: CSSProperties;
  body1?: CSSProperties;
  body2?: CSSProperties;
  button?: CSSProperties;
  subtitle1?: CSSProperties;
  subtitle2?: CSSProperties;
  caption?: CSSProperties;
  overline?: CSSProperties;
}

export const fonts: Fonts = {
  h1: {
    // 32px
    fontFamily: 'ScandiaWebBold',
    fontSize: '2rem',
    lineHeight: '125%',
  },
  h2: {
    // 24px
    fontFamily: 'ScandiaWebBold',
    fontSize: '1.5rem',
    lineHeight: '142%',
  },
  h3: {
    // 20px
    fontFamily: 'ScandiaWebBold',
    fontSize: '1.25rem',
    lineHeight: '160%',
  },
  h4: {
    // 16px
    fontFamily: 'ScandiaWebMedium',
    fontSize: '1rem',
    lineHeight: '175%',
  },
  h5: {
    // 14px
    fontFamily: 'ScandiaWebMedium',
    fontSize: '0.875rem',
    lineHeight: '186%',
  },
  body1: {
    // 14px
    fontSize: '0.875rem',
    lineHeight: '171%',
  },
  body2: {
    // 14px
    fontFamily: 'ScandiaWebMedium',
    fontSize: '0.875rem',
    lineHeight: '160%',
  },
  button: {
    // 14px
    fontFamily: 'ScandiaWebMedium',
    fontSize: '0.75rem',
    lineHeight: '200%',
  },
  caption: {
    // 12px
    fontSize: '0.75rem',
    lineHeight: '167%',
  },
  h6: {
    // 12px
    fontFamily: 'ScandiaWebMedium',
    fontSize: '0.75rem',
    lineHeight: '200%',
  },
};

export const { h1, h2, h3, h4, h5, h6, body1, body2, caption, button } = fonts;

export const HEADER_HEIGHT = 210;
export const FOOTER_HEIGHT = 50;
export const BORDER_RADIUS_IMAGE = 10;
export const BORDER_RADIUS_BUTTON = 200;
export const screenHeight = () =>
  `calc(100vh - ${HEADER_HEIGHT + FOOTER_HEIGHT}px)`;

const defaultTheme = createTheme();

export const theme = createTheme({
  palette: {
    background: {
      default: colors.background,
    },
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    error: {
      main: colors.error,
    },
  },
  typography: {
    fontFamily: [
      'ScandiaWebRegular',
      'ScandiaWebMedium',
      'ScandiaWebLight',
      'ScandiaWebBold',
      'ScandiaWebBoldItalic',
      'ScandiaWebLightItalic',
      'ScandiaWebMediumItalic',
      'ScandiaWebRegularItalic',
      'ScandiaWebStencil',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    body1,
    body2,
    caption,
    button: {
      ...button,
      textTransform: 'unset',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1320,
      xl: 1920,
    },
  },
  overrides: {
    MuiPopover: {
      paper: {
        '& .MuiPickersBasePicker-container': {
          '& .MuiPickerDTTabs-tabs': {
            '& .Mui-selected': {
              '& .MuiSvgIcon-root': {
                color: colors.white,
              },
            },
            '& .MuiTabs-indicator': {
              backgroundColor: colors.white,
            },
          },
          '& .MuiPickersToolbar-toolbar': {
            '& .MuiGrid-container': {
              '& .MuiPickerDTToolbar-separator': {
                fontFamily: 'ScandiaWebRegular',
                fontSize: 32,
              },
              '& .MuiPickersToolbarButton-toolbarBtn': {
                '& .MuiTypography-h4': {
                  fontFamily: 'ScandiaWebRegular',
                  fontSize: 26,
                  textTransform: 'capitalize',
                },

                '& .MuiTypography-h3': {
                  fontFamily: 'ScandiaWebRegular',
                  fontSize: 32,
                },
              },
            },
          },
        },
      },
    },
    MuiCssBaseline: {
      '@global': {
        body: {
          color: colors.body,
        },
      },
    },
    MuiDrawer: {
      paper: {
        color: defaultTheme.palette.common.white,
        backgroundColor: colors.primary,
      },
      paperAnchorDockedLeft: {
        borderRight: 0,
        borderRadius: 0,
      },
    },
    MuiDialogTitle: {
      root: {
        paddingBottom: 0,
      },
    },
    MuiDialogActions: {
      spacing: {
        padding: defaultTheme.spacing(2),
      },
    },
    MuiCardHeader: {
      title: {
        paddingBottom: defaultTheme.spacing(1),
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        backgroundColor: 'transparent',
      },
      wrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        '&>:first-child': {
          marginBottom: '0',
          marginRight: defaultTheme.spacing(1),
        },
        ...body2,
      },
      labelIcon: {
        minHeight: 47,
      },
      fullWidth: {
        fontSize: '12px',
        textAlign: 'center',
      },
      textColorInherit: {
        color: colors.disabled,
        '&$disabled': {
          opacity: 'unset',
          color: colors.disabled,
        },
      },
    },
    MuiTabs: {
      flexContainer: {
        height: '100%',
      },
      centered: {
        alignItems: 'center',
      },
    },
    MuiAppBar: {
      root: {
        boxShadow: 'none',
      },
      colorDefault: {
        backgroundColor: 'transparent',
      },
    },
    MuiSvgIcon: {
      colorDisabled: {
        color: colors.disabled,
      },
      fontSizeSmall: {
        width: '16px',
        height: '16px',
      },
      colorSecondary: {
        color: colors.white,
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: colors.tooltip,
        ...body1,
      },
    },
    MuiCardMedia: {
      media: {
        borderRadius: BORDER_RADIUS_IMAGE,
      },
    },
    MuiListItemText: {
      primary: {
        ...body2,
      },
    },
    MuiChip: {
      colorPrimary: {
        color: colors.primary,
        backgroundColor: colors.chipBackgroundColor,
      },
      deleteIconColorPrimary: {
        color: colors.primary,
      },
    },
    MuiButton: {
      root: {
        borderRadius: BORDER_RADIUS_BUTTON,
        textAlign: 'unset',
        textTransform: 'none',
        '&$disabled': {
          opacity: 'unset',
          color: colors.inputLabelColor,
        },
        color: colors.tooltip,
      },
      textSecondary: {
        color: colors.white,
      },
      containedPrimary: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: colors.selectedCheckboxBorderColor,
        },
        '&:disabled': {
          backgroundColor: colors.buttonDisabled,
          color: colors.white,
        },
      },
      containedSecondary: {
        backgroundColor: colors.orange300,
        borderRadius: BORDER_RADIUS_BUTTON,
        '&:hover': {
          backgroundColor: colors.orange800,
        },
      },
      outlinedPrimary: {
        border: `2px solid ${colors.primary}`,
        '&:hover': {
          color: colors.selectedCheckboxBorderColor,
          border: `2px solid ${colors.selectedCheckboxBorderColor}`,
        },
      },
      label: h5,
    },
    MuiFormLabel: {
      root: {
        color: colors.body,
        ...body2,
      },
    },
    MuiInputLabel: {
      root: {
        color: colors.inputLabelColor,
      },
    },
    MuiInputBase: {
      adornedStart: {
        paddingLeft: defaultTheme.spacing(1),
      },
      inputAdornedStart: {
        paddingLeft: defaultTheme.spacing(1),
      },
      input: {
        '&.MuiOutlinedInput-input': {
          padding: '0 12px',
          height: '40px',
        },
        '&.MuiOutlinedInput-inputMultiline': {
          padding: 0,
        },
        '&.MuiSelect-outlined.MuiSelect-outlined': {
          padding: '9px 12px',
          height: 'auto',
        },

        '&::placeholder': {
          color: colors.inputLabelColor,
          opacity: 0.9,
        },
      },
    },
    MuiButtonGroup: {
      groupedOutlined: {
        borderColor: colors.buttonGroupOutlined,
      },
      groupedOutlinedPrimary: {
        '&:hover': {
          borderColor: colors.buttonGroupOutlined,
        },
      },
    },
    MuiTableHead: {
      root: {
        borderTop: `2px solid ${colors.disabled}`,
        backgroundColor: colors.documentsHeaderBackground,
      },
    },
    MuiTableCell: {
      root: {
        display: 'flex',
        alignItems: 'center',
        '&[data-sticky-last-left-td]': {
          boxShadow: `2px 0px 3px ${colors.gridBorder}`,
        },
        backgroundColor: 'inherit',
      },
      head: {
        backgroundColor: colors.documentsHeaderBackground,
      },
    },
    MuiTableRow: {
      root: {
        textDecoration: 'none',
        backgroundColor: colors.white,
        '&:hover': {
          backgroundColor: colors.tableRowHover,
        },
        borderRadius: 8,
        MuiRadio: {
          root: {
            color: colors.inputLabelColor,
          },
        },
        MuiCheckbox: {
          root: {
            color: colors.inputLabelColor,
          },
        },
      },
    },
    MuiToolbar: {
      gutters: {
        '@media (min-width: 600px)': {
          paddingLeft: 0,
        },
      },
    },
    MuiMenuItem: {
      root: {
        ...body2,
        '&:hover': {
          color: colors.primary,
          backgroundColor: colors.menuHover,
        },
      },
    },
    MuiAvatar: {
      circle: {
        width: '35px',
        height: '35px',
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: colors.primary,
        },
      },
    },
  },
});
