import { GetProductsWithAssets } from 'components/useProductsWithAssets';
import {
  VisualIdentityType,
  PriceElement,
} from 'app/schemaInterfaces/globalTypes';
import { ApolloClient, ApolloQueryResult } from '@apollo/client';
import { GetCurrentClient_client_config } from 'app/schemaInterfaces/GetCurrentClient';
import { NormalizedIProductTemplate } from 'modules/workflow/containers/CatalogPreview/EditPlatformDefinitions';
import {
  GetAssetById_getAssetById,
  GetAssetById,
  GetAssetByIdVariables,
} from 'app/schemaInterfaces/GetAssetById';
import {
  Asset,
  Position,
  StudioStates,
  TypographyIdentities,
} from 'modules/workflow/oneStudioContext';
import { FileStore } from 'modules/pim/baseProducts/PimFormFieldsConfig';
import { GET_ASSET_BY_ID } from 'app/graphql/queries/dam';
import {
  getUnifiedKeyFromPimClientConfig,
  getLabelByUnifiedKeyFromPimClientConfig,
} from 'modules/pim/baseProducts/ProductsHelper';
import { UnifiedProductKeyToRenderOnPage } from 'modules/workflow/containers/Leftsidebar/ProductsPanel/ProductsView';
import { CopyProducts_copyProducts } from 'app/schemaInterfaces/CopyProducts';
import { UpdateProduct_updateProduct } from 'app/schemaInterfaces/UpdateProduct';
import { TFunction } from 'i18next';
import colors from 'styles/colors.module.scss';
import { productRegrouping } from 'modules/pim/regroupingHelper';
import { GetProductsWithVisualIdentity_result_products_visualIdentities } from 'app/schemaInterfaces/GetProductsWithVisualIdentity';

export type Product =
  | GetProductsWithAssets
  | CopyProducts_copyProducts
  | UpdateProduct_updateProduct;

interface createNewProductfromPreviousOneProps {
  data: Product;
  client: ApolloClient<object>;
  clientConfig?: GetCurrentClient_client_config | null;
  productDetails?: NormalizedIProductTemplate;
  globalState: StudioStates;
}

export async function createNewProductfromPreviousOne({
  data,
  client,
  clientConfig,
  productDetails,
  globalState,
}: createNewProductfromPreviousOneProps) {
  let updatedProductAssets: GetAssetById_getAssetById[] = [];
  if (data.details.filesToSend) {
    const assetsPromises: Promise<
      ApolloQueryResult<GetAssetById>
    >[] = data.details.filesToSend.map((file: FileStore) =>
      client.query<GetAssetById, GetAssetByIdVariables>({
        query: GET_ASSET_BY_ID,
        variables: {
          id: file.assetId as string,
        },
      })
    );

    const assets = await Promise.all(assetsPromises);
    const productAssets = assets.reduce<GetAssetById['getAssetById'][]>(
      (productAssets, asset) => {
        productAssets.push(asset.data.getAssetById);
        return productAssets;
      },
      []
    );
    updatedProductAssets.push(...productAssets);
  }

  const productName = getUnifiedKeyFromPimClientConfig(
    UnifiedProductKeyToRenderOnPage.title,
    clientConfig
  );
  const productDescription = getUnifiedKeyFromPimClientConfig(
    UnifiedProductKeyToRenderOnPage.description,
    clientConfig
  );
  const productFamily = getLabelByUnifiedKeyFromPimClientConfig(
    UnifiedProductKeyToRenderOnPage.divisions,
    clientConfig
  );

  const productDiscountValue = data.details[
    getUnifiedKeyFromPimClientConfig(
      UnifiedProductKeyToRenderOnPage.discountvalue,
      clientConfig
    )
  ]?.toString();

  return {
    id: data?._id,
    name: data?.details[productName],
    description: data?.details[productDescription],
    family: data?.details[productFamily],
    lePlus: [],
    soit: '',
    price: +data?.details[
      getUnifiedKeyFromPimClientConfig(
        UnifiedProductKeyToRenderOnPage.price,
        clientConfig
      )
    ],
    salesUnit:
      data?.details[
        getUnifiedKeyFromPimClientConfig(
          UnifiedProductKeyToRenderOnPage.priceunity,
          clientConfig
        )
      ],
    components: productDetails ? productDetails.components : undefined,
    size: productDetails ? productDetails.size : undefined,
    position: productDetails ? productDetails.position : undefined,
    productDetails: data.details,
    visualIdentities: productVisuals(data, globalState.document?.supportId),
    typographyIdentities: productTypographies(
      data,
      globalState.document?.supportId
    ),
    priceTagTypographies: productPriceTagTypographies(
      data,
      productDiscountValue,
      globalState.document?.supportId
    ),
    regrouping: productRegrouping(
      data as GetProductsWithAssets,
      clientConfig as GetCurrentClient_client_config,
      globalState
    ),
  };
}

export const productVisuals = (
  product: Product,
  documentSupportId?: string
): Asset[] => {
  const visuals =
    product.visualIdentities &&
    product.visualIdentities.filter(
      ({
        type,
        priceElement,
      }: GetProductsWithVisualIdentity_result_products_visualIdentities) =>
        type === VisualIdentityType.Visual || priceElement === PriceElement.Chip
    );
  const newVisuals: Asset[] = [];
  visuals?.forEach(
    (
      visual: GetProductsWithVisualIdentity_result_products_visualIdentities
    ) => {
      const metadata = visual.metadata.find(
        ({ supportId }) =>
          supportId?.toLowerCase() === documentSupportId?.toLowerCase()
      );

      visual.asset &&
        newVisuals.push({
          id: visual.asset?.id,
          name: visual.asset?.name ?? '',
          fieldName: visual?.formFieldKey,
          index: visual?.index ?? undefined,
          visualType: visual.asset?.visualType ?? undefined,
          conform: visual.asset?.conform ?? undefined,
          url: visual.asset?.url ?? '',
          visualIdentityType: visual.type,
          metadata: {
            dimensions: {
              width: visual.asset?.metadata.dimensions?.width ?? 0,
              height: visual.asset?.metadata.dimensions?.height ?? 0,
            },
          },
          thumbnailUrl: visual.asset?.thumbnailUrl ?? '',
          size: {
            width: metadata?.size?.width ?? 0,
            height: metadata?.size?.height ?? 0,
          },
          position: {
            x: metadata?.position?.x ?? 0,
            y: metadata?.position?.y ?? 0,
          },
          assetType: visual.asset?.assetType ?? undefined,
          isDraggable: metadata?.isDraggable ?? undefined,
          isResizable: metadata?.isResizable ?? undefined,
          display: metadata?.display ?? undefined,
          color: metadata?.color ?? undefined,
        });
    }
  );
  return newVisuals;
};

export const productTypographies = (
  product: Product,
  documentSupportId?: string
): TypographyIdentities[] => {
  const typographies =
    product.visualIdentities &&
    product.visualIdentities.filter(
      ({
        type,
      }: GetProductsWithVisualIdentity_result_products_visualIdentities) =>
        type === VisualIdentityType.Typography
    );
  return typographies
    ? typographies.map(
        (
          typography: GetProductsWithVisualIdentity_result_products_visualIdentities,
          index: number
        ) => {
          const metadata = typography.metadata.find(
            ({ supportId }) =>
              supportId?.toLowerCase() === documentSupportId?.toLowerCase()
          );
          return {
            id: index.toString(),
            name: typography.formFieldKey,
            position: {
              x: metadata?.position?.x ?? 0,
              y: metadata?.position?.y ?? 0,
            },
            type: typography.type,
            index: typography.index ?? undefined,
            priceElement: typography.priceElement ?? undefined,
            fontWeight: metadata?.fontWeight ?? 400,
            fontFamily: metadata?.fontFamily ?? '',
            fontSize: metadata?.fontSize ?? 12,
            fontStyle: metadata?.fontStyle ?? '',
            lineThrough: metadata?.lineThrough ?? false,
            isCapitalLetters: metadata?.isCapitalLetters ?? undefined,
            lineHeight: metadata?.lineHeight ?? undefined,
            width: metadata?.size?.width ?? undefined,
            // default color of a text to black
            color: metadata?.color ?? colors.black,
          };
        }
      )
    : [];
};

export const productPriceTagTypographies = (
  product: Product,
  productDiscountValue: string,
  documentSupportId?: string
): TypographyIdentities[] => {
  const typographies =
    product.visualIdentities &&
    product.visualIdentities.filter(
      ({
        type,
        priceElement,
      }: GetProductsWithVisualIdentity_result_products_visualIdentities) =>
        type === VisualIdentityType.Price && priceElement !== PriceElement.Chip
    );
  return typographies
    ? typographies?.map(
        (
          typography: GetProductsWithVisualIdentity_result_products_visualIdentities,
          index: number
        ) => {
          const metadataDiscount = typography.metadata.find(
            ({ discountValue, supportId }) =>
              discountValue &&
              discountValue === productDiscountValue &&
              supportId === documentSupportId
          );
          const metadataNoDiscount = typography.metadata.find(
            ({ supportId, discountValue }) =>
              !discountValue && supportId === documentSupportId
          );
          const metadata =
            productDiscountValue && metadataDiscount?.discountValue
              ? metadataDiscount
              : metadataNoDiscount;

          return {
            id: index.toString(),
            name: typography.formFieldKey,
            position: {
              x: metadata?.position?.x ?? 0,
              y: metadata?.position?.y ?? 0,
            },
            type: typography.type,
            fontWeight: metadata?.fontWeight ?? 400,
            fontFamily: metadata?.fontFamily ?? '',
            fontSize: metadata?.fontSize ?? 12,
            fontStyle: metadata?.fontStyle ?? '',
            priceElement: typography.priceElement ?? undefined,
            lineThrough: metadata?.lineThrough ?? false,
            lineHeight: metadata?.lineHeight ?? undefined,
            width: metadata?.size?.width ?? undefined,
            color: metadata?.color ?? colors.black,
          };
        }
      )
    : [];
};

//chooses position of the bigger footer first and then move on to the other option :
export const priceFooterPosition = (
  priceLabels: TypographyIdentities | undefined,
  fullCompaniesPrice: TypographyIdentities | undefined,
  priceOptionalUnity: TypographyIdentities | undefined
): Position =>
  priceLabels
    ? priceLabels.position
    : fullCompaniesPrice
    ? fullCompaniesPrice.position
    : priceOptionalUnity
    ? priceOptionalUnity.position
    : { x: 0, y: 0 };

export const getPriceLabels = (
  firstPart: string,
  isCompaniesPrice: boolean,
  price: string,
  labelWithoutTaxes: string,
  t: TFunction
) =>
  `${firstPart} : ${
    !isCompaniesPrice
      ? `${price} €`
      : `${labelWithoutTaxes} € ${t(
          'workflow.typography_item.without_taxes_symbol'
        )} ${t('workflow.typography_item.le_soit')} ${price} € ${t(
          'workflow.typography_item.with_taxes_symbol'
        )}`
  }`;
