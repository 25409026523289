import React from 'react';
import { Box } from '@material-ui/core';
import BaseProductsList from 'modules/pim/baseProducts/BaseProductsList';
import { useParams } from 'react-router-dom';

const ProductsSheetImportPage = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  return (
    <Box position="relative">
      <BaseProductsList isImportMode={true} campaignId={id} />
    </Box>
  );
};

export default ProductsSheetImportPage;
