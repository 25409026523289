import { GetCurrentClient_client_structures } from 'app/schemaInterfaces/GetCurrentClient';
import { EntityType } from 'app/schemaInterfaces/globalTypes';
import { Entity } from './definitions';

/*
 Returns the first entities (from the given entities and there parents) that are one level or more higher than the given entity type
 */
export const getDirectParents = (
  entities: Entity[],
  structure: GetCurrentClient_client_structures
) => {
  return entities.reduce<string[]>((parents, entity) => {
    for (const ancestor of [entity, ...entity.ancestors]) {
      if (ancestor.level < structure.level) {
        parents.push(ancestor.id);
        break;
      }
    }

    return parents;
  }, []);
};

/*
	Run through the given entities and their parents and returns the entities of the given type
 */
export const getEntitiesByType = (entities: Entity[], type: EntityType) => {
  const entitiesMap = new Map<string, Entity>();

  for (const entity of entities) {
    if (entity.type === type) {
      entitiesMap.set(entity.id, entity);
    } else {
      entity.ancestors.forEach((ancestor, index) => {
        if (ancestor.type === type) {
          entitiesMap.set(ancestor.id, {
            ...ancestor,
            ancestors: entity.ancestors.slice(index + 1),
          });
        }
      });
    }
  }

  return Array.from(entitiesMap.values());
};
