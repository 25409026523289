import { FieldFunctionOptions } from '@apollo/client/cache/inmemory/policies';
import { Optional } from 'app/utils/common';

export const displayedThumbUrl = {
  read(
    existing: string,
    { readField }: FieldFunctionOptions
  ): Optional<string> {
    const url: Optional<string> = readField('url');
    const thumbnailUrl: Optional<string> = readField('thumbnailUrl');

    return thumbnailUrl ?? url;
  },
};
