import { gql } from '@apollo/client';

// NOTE: Keep this query as light as possible

export const GET_ENTITY_BY_ID = gql`
  query GetEntityById($id: String!) {
    entity(id: $id) {
      id
      name
      type
      level
      structureTitle
      parentId
      isBillable
      socialNetworks {
        clientNetworkPageId
        clientNetworkLink
        isCanPost
      }
      addressBlocks {
        clientId
        entityId
        supplyProductIds
        supplyProducts {
          longueurFormatPageCm
          largeurFormatPageCm
          largeurFormatFiniCm
          longueurFormatFiniCm
        }
        id
        asset {
          id
          url
          thumbnailUrl
          name
          assetType
          metadata {
            credit
            fileHasCredit
            dimensions {
              height
              width
              heightInCm
              widthInCm
            }
            fileSize
            mimetype
          }
        }
        supportId
        support {
          name
        }
        name
        status
      }
      ancestors {
        id
        parentId
        name
        type
        level
        isBillable
        structureTitle
        socialNetworks {
          clientNetworkPageId
          clientNetworkLink
          isCanPost
        }
        addressBlocks {
          clientId
          entityId
          supplyProductIds
          supplyProducts {
            longueurFormatPageCm
            largeurFormatPageCm
            largeurFormatFiniCm
            longueurFormatFiniCm
          }
          id
          asset {
            id
            url
            thumbnailUrl
            name
            assetType
            metadata {
              credit
              fileHasCredit
              dimensions {
                height
                width
                heightInCm
                widthInCm
              }
              fileSize
              mimetype
            }
          }
          supportId
          support {
            name
          }
          name
          status
        }
      }
    }
  }
`;

export const GET_ENTITIES = gql`
  query GetEntities($filters: EntityFiltersInput) {
    entities(filters: $filters) {
      id
      name
      type
      level
      structureTitle
      parentId
      isBillable
      socialNetworks {
        clientNetworkPageId
        clientNetworkLink
        isCanPost
      }
      addressBlocks {
        clientId
        entityId
        supplyProductIds
        supplyProducts {
          longueurFormatPageCm
          largeurFormatPageCm
          largeurFormatFiniCm
          longueurFormatFiniCm
        }
        id
        asset {
          id
          url
          thumbnailUrl
          name
          assetType
          metadata {
            credit
            fileHasCredit
            dimensions {
              height
              width
              heightInCm
              widthInCm
            }
            fileSize
            mimetype
          }
        }
        supportId
        support {
          name
        }
        name
        status
      }
      ancestors {
        id
        parentId
        name
        type
        level
        isBillable
        structureTitle
        socialNetworks {
          clientNetworkPageId
          clientNetworkLink
          isCanPost
        }
        addressBlocks {
          clientId
          entityId
          supplyProductIds
          supplyProducts {
            longueurFormatPageCm
            largeurFormatPageCm
            largeurFormatFiniCm
            longueurFormatFiniCm
          }
          id
          asset {
            id
            url
            thumbnailUrl
            name
            assetType
            metadata {
              credit
              fileHasCredit
              dimensions {
                height
                width
                heightInCm
                widthInCm
              }
              fileSize
              mimetype
            }
          }
          supportId
          support {
            name
          }
          name
          status
        }
      }
    }
  }
`;

export const GET_ENTITIES_LIST = gql`
  query GetEntitiesList($filters: EntityFiltersInput) {
    entities(filters: $filters) {
      id
      name
      type
      level
      structureTitle
      parentId
      isBillable
      ancestors {
        id
        parentId
        name
        type
        level
        isBillable
        structureTitle
      }
    }
  }
`;

export const GET_DELIVERY_ADDRESSES = gql`
  query GetClientShippingAddress($entityId: String!) {
    entityDeliveryAdresses(entityId: $entityId) {
      isMain
      address {
        id
        name
        firstAddress
        secondAddress
        thirdAddress
        postBox
        zipCode
        city
        country
        deliveryDepartment
      }
      contact {
        directorName
        email
        phone
      }
    }
  }
`;

export const GET_BILLING_ADDRESSES = gql`
  query GetEntityBillingAdresses($entityId: String!) {
    entityBillingAdresses(entityId: $entityId) {
      name
      id
      billingAddress {
        id
        name
        city
        country
        deliveryDepartment
        firstAddress
        isShipping
        postBox
        secondAddress
        thirdAddress
        zipCode
      }
    }
  }
`;

export const GET_ENTITY_GENERAL_INFO = gql`
  query GetEntityGeneralInfo($entityId: String!) {
    entityDeliveryAdresses(entityId: $entityId) {
      isMain
      address {
        id
        name
        firstAddress
        secondAddress
        thirdAddress
        zipCode
        city
        country
      }
    }
  }
`;
