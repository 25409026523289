import { css } from '@emotion/react';

export const summaryPublicationDeliveryDetailsStyle = css`
  .MuiGrid-container {
    align-items: center;
  }
  .link {
    align-items: center;
    display: flex;
    white-space: nowrap;
    a {
      overflow: hidden;
    }
    p {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
