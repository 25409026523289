import { GetBasketDocuments_basket_basketDocuments_document_productAttributes as ProductAttributes } from 'app/schemaInterfaces/GetBasketDocuments';
import { BigShopConfigCategory } from 'app/schemaInterfaces/globalTypes';

enum SupportTypes {
  FacebookAdd = 'Publicité au format JPEG',
  FacebookPost = 'Post au format JPEG',
}

const schedulableTypes = [SupportTypes.FacebookPost];

const urlInContentTypes = [SupportTypes.FacebookAdd];

export const hasPublicationPeriod = (attributes: ProductAttributes | null) => {
  return schedulableTypes.includes(attributes?.typeSupport as SupportTypes);
};

export const hasUrlInContent = (attributes?: ProductAttributes | null) => {
  return urlInContentTypes.includes(attributes?.typeSupport as SupportTypes);
};

const digitalCategories: BigShopConfigCategory[] = [
  BigShopConfigCategory.Digital,
];

export const isDigitalDocument = (category: BigShopConfigCategory) =>
  digitalCategories.includes(category);
