import colors from 'styles/colors.module.scss';
import { css } from '@emotion/react/macro';

export const documentsGridListStyle = (
  isRecommended: boolean | undefined,
  isMandatory: boolean
) => css`
  width: auto;
  min-height: 338px;
  border: 1px solid ${colors.workzone};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  :hover {
    border: 1px solid ${colors.primary};
  }
  opacity: 1;
  .media {
    position: relative;
    cursor: pointer;
    img {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      object-fit: cover;
      width: 100%;
      height: 158.59px;
    }
    .recommended-badge {
      position: absolute;
      top: 8px;
      left: 3px;
    }
    .mandatory-badge {
      position: absolute;
      top: 8px;
      right: ${isRecommended && isMandatory ? '3px' : null};
      left: ${isMandatory ? '3px' : null};
    }
    .customizable-icon {
      position: absolute;
      top: ${isRecommended || isMandatory ? '35px' : '8px'};
      left: 3px;
      width: 36px;
      height: 24px;
      background: ${colors.white};
      border-radius: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      .icon {
        font-size: 14px;
      }
    }
  }
  .content {
    .title {
      font-family: ScandiaWebMedium;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.03em;
      color: ${colors.black};
      padding: 6px 6px 0 6px;
    }
    .version {
      font-family: ScandiaWebMedium;
      font-weight: normal;
      font-size: 12px;
      padding: 6px 6px 0 6px;
    }
  }

  .details {
    .price-box {
      display: flex;
      flex-direction: column;
      padding: 8px 10px;
      justify-content: space-between;
      .starting-date {
        font-size: 12px;
        display: flex;
        align-items: center;
        color: ${colors.grey};
      }
      .price {
        font-family: ScandiaWebMedium;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 32px;
        display: flex;
        align-items: center;
        text-align: right;
        letter-spacing: -0.03em;
        color: ${colors.primary};
        sup {
          margin-left: 4px;
        }
      }
      .minimum-quantity {
        color: ${colors.primary};
        font-size: 12px;
      }
    }
    .commitment-info {
      padding-top: 8px;
      .commitment {
        display: flex;
        padding-left: 8px;
        align-items: center;
        p {
          font-family: ScandiaWebMedium;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 19px;
          letter-spacing: -0.03em;
          padding-left: 5px;
          margin-top: 2px;
          color: ${colors.grey};
        }
        .icon {
          color: ${colors.grey};
          font-size: 13.3px;
        }
      }
    }
  }
`;
