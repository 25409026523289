export const TRANSLATION_ROOT = 'one_manager.store_information';

export enum StoreRoutes {
  Root = '/store',
  DeliveryAddresses = '/store/delivery-address',
  AddressBlocks = '/store/address-block',
  SocialNetworks = '/store/social-networks',
  AreaConfiguration = '/store/area-configuration',
}

export const storeMenuItems = [
  {
    translationKey: 'menuItems.general_info',
    to: StoreRoutes.Root,
    key: 0,
  },
  {
    translationKey: 'menuItems.adresses',
    to: StoreRoutes.DeliveryAddresses,
    key: 1,
  },
  {
    translationKey: 'menuItems.social_networks',
    to: StoreRoutes.SocialNetworks,
    key: 3,
  },
  {
    translationKey: 'menuItems.addressblocks',
    to: StoreRoutes.AddressBlocks,
    key: 4,
  },
];
