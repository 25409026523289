import { css } from '@emotion/react';

export const pdfPreviewStyle = (size: number) => css`
  .react-pdf__Page__canvas {
    width: 100% !important;
    // height of the box containing the thumbnail
    height: ${size}px !important;
    object-fit: contain;
    color: red;
  }
`;
