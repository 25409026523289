import { Box, Checkbox, FormLabel, Grid } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import moment from 'moment';
import React from 'react';
import { AssetInput, AssetStatus } from 'app/schemaInterfaces/globalTypes';
import colors from 'styles/colors.module.scss';
import { BaseAssetFormValues } from 'modules/dam/form/model/definitions';
import { DivisionSelectFields } from 'modules/dam/form/fields/DivisionSelectFields';
import { UncontrolledDatePicker } from 'components/UncontrolledDatePicker';
import { useTranslation } from 'react-i18next';

const VisualAssetSubForm = (): JSX.Element => {
  const {
    values: { asset: assetInput },
    setFieldValue,
  } = useFormikContext<BaseAssetFormValues>();

  const { t } = useTranslation();
  const handlePermanentChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setFieldValue('asset.validTo', null);
      setFieldValue('asset.validFrom', null);
      setFieldValue('asset.status', AssetStatus.Active);
    }

    setFieldValue('asset.permanent', isChecked);
  };

  const handleValidFromChange = (date: MaterialUiPickersDate) => {
    setFieldValue('asset.validFrom', date);
    handleValidityChange(date, assetInput.validTo);
  };

  const handleValidToChange = (date: MaterialUiPickersDate) => {
    setFieldValue('asset.validTo', date);
    handleValidityChange(assetInput.validFrom, date);
  };

  const handleValidityChange = (
    validFrom: AssetInput['validFrom'],
    validTo: AssetInput['validTo']
  ) => {
    if (validTo && validFrom && !assetInput.permanent) {
      setFieldValue(
        'asset.status',
        moment().isBetween(validFrom, validTo)
          ? AssetStatus.Active
          : AssetStatus.Inactive
      );
    }
  };

  return (
    <Box>
      <Box p={2} mt={2} mb={2} bgcolor={colors.documentsHeaderBackground}>
        {t('dam.asset_form.complementary_info')}
      </Box>
      <Box px={2}>
        <DivisionSelectFields name="asset.division" margin="dense" />
        <Box pt={1}>
          <FormLabel>{t('dam.asset_form.comments')}</FormLabel>
        </Box>
        <Field
          component={TextField}
          name="asset.comment"
          placeholder={t('dam.asset_form.add_comments')}
          variant="outlined"
          margin="normal"
          fullWidth
          multiline
          rows={4}
        />
      </Box>
      <Box p={2} mt={2} mb={2} bgcolor={colors.documentsHeaderBackground}>
        {t('dam.asset_form.validity_info')}
      </Box>
      <Box px={2}>
        <Grid container justify="space-between" spacing={2}>
          <Grid container item xs={6}>
            <Box pt={1}>
              <FormLabel>{t('dam.asset_form.validity_start')}</FormLabel>
            </Box>
            <UncontrolledDatePicker
              placeholder={t('dam.asset_form.validity_start')}
              fullWidth
              margin="normal"
              disablePast
              value={assetInput.validFrom}
              disabled={assetInput.permanent ?? false}
              maxDate={assetInput.validTo ?? undefined}
              handleClear={() => setFieldValue('asset.validFrom', null)}
              onChange={handleValidFromChange}
            />
          </Grid>
          <Grid item xs={6}>
            <Box pt={1}>
              <FormLabel>{t('dam.asset_form.validity_end')}</FormLabel>
            </Box>
            <UncontrolledDatePicker
              placeholder={t('dam.asset_form.validity_end')}
              fullWidth
              margin="normal"
              value={assetInput.validTo}
              disabled={assetInput.permanent ?? false}
              minDate={assetInput.validFrom ?? moment()}
              handleClear={() => setFieldValue('asset.validTo', null)}
              onChange={handleValidToChange}
            />
          </Grid>
          <Box>
            <Checkbox
              checked={assetInput.permanent ?? false}
              onChange={handlePermanentChange}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            {t('dam.asset_form.fulltime_validity')}
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

export default VisualAssetSubForm;
