import HeaderFormActions from 'components/HeaderFormActions/HeaderFormActions';
import { useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

const DocumentFormHeader = ({
  isDisabledForm,
}: {
  isDisabledForm: () => boolean;
}) => {
  const { t } = useTranslation();
  const { documentId } = useParams<{
    documentId: string;
  }>();
  const { isValid, dirty } = useFormikContext();

  const { goBack } = useHistory();

  const documentSubmitButtons = {
    cancelAction: {
      name: t('global.document_form.button.cancel'),
      onClick: () => {
        goBack();
      },
    },
    confirmAction: {
      name: documentId
        ? t('global.document_form.button.update_document')
        : t('global.document_form.button.add_document'),
      isDisabled: !dirty || !isValid || isDisabledForm(),
    },
  };

  return (
    <div style={{ position: 'sticky', top: '0', zIndex: 1000 }}>
      <div>
        {/* The height of this Header is higher than in the Figma on purpose, to hide the BigOne MenuHeader */}
        <HeaderFormActions
          name={
            documentId
              ? t('global.document_form.update_header')
              : t('global.document_form.create_header')
          }
          {...documentSubmitButtons}
        />
      </div>
    </div>
  );
};

export default DocumentFormHeader;
