import React from 'react';
import {
  List,
  ListItem,
  Box,
  CardMedia,
  Typography,
  ListItemText,
} from '@material-ui/core';
import colors from 'styles/colors.module.scss';
import ActionButtons, { ActionButton } from '../ActionsButtons/ActionButtons';
import StatusBadge from '../Badge/StatusBadge';
import { setActionButtons } from 'modules/workflow/containers/CatalogPreview/EditPlatformHelper';
import { useTranslation } from 'react-i18next';
import { Item } from 'modules/workflow/containers/CatalogPreview/EditPlatformDefinitions';
import { cardListStyle } from './CardList.style';
import { CSSObject } from '@emotion/react';
import { useCardList } from './CardList.hook';

export interface CardListProps<T extends Item> {
  items?: T[];
  onDrag?: (event: React.DragEvent<HTMLElement>, item: T) => void;
  isBackgroundDark?: boolean;
  handleDoubleClickItem?: (item: T) => void;
  disabled?: boolean;
  draggable?: boolean;
  badgeStyle?: CSSObject;
  cardStyle?: CSSObject;
  enableClick?: (item: T) => boolean;
  deleteItem?: ActionButton<T>['onClick'];
  handleOpenDialog?: ActionButton<T>['onClick'];
}

function CardList<T extends Item>({
  items,
  onDrag,
  isBackgroundDark = false,
  handleDoubleClickItem,
  disabled,
  draggable = false,
  badgeStyle,
  cardStyle,
  enableClick,
  deleteItem,
  handleOpenDialog,
}: CardListProps<T>): JSX.Element {
  const { t } = useTranslation();

  const {
    hasActionButtons,
    isCurrentPageEditable,
    isCustomization,
    activeItemIndex,
    setActiveItemIndex,
    clickItemIndex,
    setClickItemIndex,
    userType,
  } = useCardList({
    deleteItem,
    handleOpenDialog,
  });

  return (
    <List
      css={cardListStyle(isBackgroundDark, badgeStyle ?? {}, cardStyle ?? {})}
      data-testid="card-list"
    >
      {items?.map((item, itemIndex) => (
        <ListItem
          data-testid={`list-item-${itemIndex}`}
          key={itemIndex}
          button
          onMouseOver={() => {
            setActiveItemIndex(itemIndex);
          }}
          onMouseLeave={() => {
            setActiveItemIndex(undefined);
          }}
        >
          <Box
            draggable={draggable}
            onDragStart={(event) => onDrag?.(event, item)}
            display="flex"
            alignItems="center"
            width="100%"
            minHeight="37px"
            className={!enableClick?.(item) ? 'disabledItemStyle' : undefined}
          >
            <Box
              display="flex"
              width={hasActionButtons ? '70%' : '100%'}
              alignItems="center"
              onDoubleClick={() => handleDoubleClickItem?.(item)}
              data-testid="card-list-button"
            >
              <CardMedia
                component="img"
                height="60"
                src={
                  item?.image ??
                  `/${process.env.REACT_APP_ASSETS}/noPreviewImageSquare.png`
                }
              />
              <ListItemText
                primary={
                  <Typography
                    variant="caption"
                    style={{
                      color: isBackgroundDark ? colors.white : colors.body,
                      paddingLeft: '8px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {item.name}
                  </Typography>
                }
              />
            </Box>
            {item.badgeStatus && itemIndex !== activeItemIndex && (
              <Box display="flex" width="20%" justifyContent="flex-end">
                <StatusBadge
                  className={badgeStyle ? 'badge' : ''}
                  status={item.badgeStatus}
                />
              </Box>
            )}
            {hasActionButtons && itemIndex === activeItemIndex && (
              <Box display="flex" width="30%" justifyContent="flex-end">
                <ActionButtons
                  item={item}
                  actionButtons={setActionButtons({
                    item,
                    deleteItem,
                    handleOpenDialog,
                    t,
                    isDisabled: !isCurrentPageEditable,
                    isCustomization,
                    userType,
                  })}
                  itemIndex={itemIndex}
                  isDisabled={disabled}
                  setClickItemIndex={setClickItemIndex}
                  clickItemIndex={clickItemIndex}
                />
              </Box>
            )}
          </Box>
        </ListItem>
      ))}
    </List>
  );
}

export default CardList;
