import React, { useEffect, useState } from 'react';
import QuickSearchBar from 'components/QuickSearchBar/QuickSearchBar';
import { CampaignFilterInput } from 'app/schemaInterfaces/globalTypes';
import colors from 'styles/colors.module.scss';
import useDebounce from 'components/useDebounce';

interface CampaignSearchBarProps {
  placeholder?: string;
  setFilters: React.Dispatch<React.SetStateAction<CampaignFilterInput>>;
  text?: string | null;
}

const CampaignSearchBar = ({
  placeholder,
  setFilters,
  text,
}: CampaignSearchBarProps): JSX.Element => {
  const [searchValue, setSearchValue] = useState<string>(text || '');
  const debouncedValue = useDebounce(searchValue, 500);

  const onChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const text = event.target.value;
    setSearchValue(text);
  };

  useEffect(() => {
    if (
      debouncedValue !== undefined &&
      debouncedValue.length > 0 &&
      setFilters
    ) {
      setFilters((filters) => ({
        ...filters,
        text: debouncedValue,
      }));
    } else {
      setFilters((filters) => ({
        ...filters,
        text: undefined,
      }));
    }
  }, [debouncedValue, setFilters, setSearchValue]);

  return (
    <QuickSearchBar
      placeholder={placeholder}
      onChange={onChange}
      value={searchValue}
      bgcolor={colors.white}
      inputBaseWidth={'430px'}
    />
  );
};

export default CampaignSearchBar;
