import React from 'react';
import {
  IconButton,
  Typography,
  makeStyles,
  Theme,
  createStyles,
  Grid,
} from '@material-ui/core';
import colors from 'styles/colors.module.scss';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { CSSProperties } from '@material-ui/styles';

interface PagingProps {
  pagingStyle?: CSSProperties;
  pagingText: string;
  onClick: (pageNumber: number) => void;
  isFirstArrowActive?: boolean;
  isLastArrowActive?: boolean;
}

const useStyles = makeStyles<Theme, { pagingStyle: CSSProperties | undefined }>(
  () =>
    createStyles({
      root: ({ pagingStyle }) => ({
        display: 'flex',
        width: '168px',
        height: '40px',
        alignItems: 'center',
        borderRadius: '20px',
        backgroundColor: `${colors.white}`,
        padding: '10px',
        justifyContent: 'space-between',
        ...pagingStyle,
      }),
    })
);

const Paging = ({
  pagingStyle,
  pagingText,
  onClick,
  isFirstArrowActive = true,
  isLastArrowActive = true,
}: PagingProps): JSX.Element => {
  const classes = useStyles({ pagingStyle });

  return (
    <Grid classes={{ root: classes.root }}>
      <IconButton
        edge="start"
        onClick={() => onClick(-1)}
        size="small"
        disabled={!isFirstArrowActive}
      >
        <ArrowBackIosIcon
          style={{
            color: isFirstArrowActive ? `${colors.body}` : `${colors.disabled}`,
            fontSize: '10',
          }}
        />
      </IconButton>

      <Typography variant="h5" style={{ color: colors.body }}>
        {pagingText}
      </Typography>
      <IconButton
        edge="start"
        onClick={() => onClick(1)}
        size="small"
        disabled={!isLastArrowActive}
      >
        <ArrowForwardIosIcon
          style={{
            color: isLastArrowActive ? `${colors.body}` : `${colors.disabled}`,
            fontSize: '10',
          }}
        />
      </IconButton>
    </Grid>
  );
};

export default Paging;
