import { gql } from '@apollo/client';

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    user: getCurrentUser {
      id
      clientId
      email
      type
      givenName
      familyName
      entities {
        id
        parentId
        name
        type
        level
        structureTitle
        isBillable
        addressBlocks {
          clientId
          entityId
          supplyProductIds
          supplyProducts {
            longueurFormatPageCm
            largeurFormatPageCm
            largeurFormatFiniCm
            longueurFormatFiniCm
          }
          id
          asset {
            id
            url
            thumbnailUrl
            name
            assetType
            metadata {
              credit
              fileHasCredit
              dimensions {
                height
                width
                heightInCm
                widthInCm
              }
              fileSize
              mimetype
            }
          }
          supportId
          support {
            name
          }
          name
          status
        }
        ancestors {
          id
          parentId
          name
          type
          level
          isBillable
          structureTitle
        }
      }
      role {
        id
        name
        permissions {
          key
          category
        }
      }
      team {
        id
        name
      }
    }
  }
`;

export const GET_USER_PROFILES = gql`
  query GetUserProfiles($filters: PublicUserFiltersInput!) {
    publicUserProfiles: getPublicUserProfiles(filters: $filters) {
      id
      givenName
      familyName
      team {
        id
        name
      }
    }
  }
`;
