import OneButtonConfirmationDialog, {
  ActionButtonsTitle,
} from 'components/ConfirmationDialogs/OneButtonConfirmationDialog/OneButtonConfirmationDialog';
import React from 'react';
import { ConfirmationDialog } from './ActionButtons';

export enum ConfirmationDialogType {
  OneButton = 'OneButton',
}

interface SelectConfirmationDialogProps<T = void> {
  isOpen: boolean;
  confirmationDialog: ConfirmationDialog;
  onClose: () => void;
  onClick?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: T
  ) => void;
  item: T;
  actionButtonsTitle?: ActionButtonsTitle;
}

function SelectConfirmationDialog<T = void>({
  item,
  isOpen,
  onClose,
  confirmationDialog,
  actionButtonsTitle,
  onClick,
}: SelectConfirmationDialogProps<T>): JSX.Element {
  const oneButtonConfirmationDialog = (
    <OneButtonConfirmationDialog
      isOpen={isOpen}
      title={confirmationDialog.title}
      description={confirmationDialog.description}
      subDescription={confirmationDialog.subDescription}
      actionButtonsTitle={actionButtonsTitle}
      onClick={(event) => onClick?.(event, item)}
      onClose={onClose}
    />
  );

  const selectDialog = () => {
    switch (confirmationDialog.type) {
      case ConfirmationDialogType.OneButton:
        return oneButtonConfirmationDialog;
      default:
        return oneButtonConfirmationDialog;
    }
  };

  return selectDialog();
}

export default SelectConfirmationDialog;
