import { Box } from '@material-ui/core';
import ProductSheetHistory from 'modules/pim/components/ProductSheetHistory';
import React from 'react';
import SideFormTabs from 'components/SideFormTabs/SideFormTabs';
import { TabPanelProductsForm } from 'modules/pim/baseProducts/ProductsHelper';
import { useTranslation } from 'react-i18next';

interface ProductsSheetsFormDetailsProps {
  productId: string;
  campaignId?: string;
}

enum DetailsTab {
  history,
}

const ProductsSheetsFormDetails = ({
  productId,
  campaignId,
}: ProductsSheetsFormDetailsProps): JSX.Element => {
  const { t } = useTranslation();

  const tabs = [{ name: t('pim.history.history') }];

  const [selectedTabIndex, setSelectedTabIndex] = React.useState<number>(
    DetailsTab.history
  );

  const handleChangeTabIndex = (
    event: React.ChangeEvent<{}>,
    tabIndex: number
  ) => {
    setSelectedTabIndex(tabIndex);
  };

  return (
    <Box px={5} width="408px" pt={1}>
      <Box>
        <SideFormTabs
          selectedTabIndex={selectedTabIndex}
          handleChangeTabIndex={handleChangeTabIndex}
          tabs={tabs}
          variant="fullWidth"
        />
        <TabPanelProductsForm
          value={selectedTabIndex}
          index={DetailsTab.history}
        >
          <ProductSheetHistory productId={productId} campaignId={campaignId} />
        </TabPanelProductsForm>
      </Box>
    </Box>
  );
};

export default ProductsSheetsFormDetails;
