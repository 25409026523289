import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
import PhotoSizeSelectActualOutlinedIcon from '@material-ui/icons/PhotoSizeSelectActualOutlined';
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined';
import BookOutlinedIcon from '@material-ui/icons/BookOutlined';
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import PlaceOutlinedIcon from '@material-ui/icons/PlaceOutlined';
import PersonalizationCards from 'modules/workflow/containers/Leftsidebar/PersonalizationPanel/PersonalizationCards';
import {
  PersonalizationSubType,
  PersonalizationType,
  useStudioStateContext,
} from 'modules/workflow/oneStudioContext';
import colors from 'styles/colors.module.scss';
import TitleOutlinedIcon from '@material-ui/icons/TitleOutlined';
import NotesOutlinedIcon from '@material-ui/icons/NotesOutlined';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import { useTranslation } from 'react-i18next';
import usePermissions from 'app/auth/usePermissions';
import { PermissionKey } from 'app/schemaInterfaces/globalTypes';

const useStyles = makeStyles({
  card: {
    border: `1px solid ${colors.grey}`,
    width: '113.15px',
    height: '113.15px',
    background: colors.leftSideBar,
    cursor: 'pointer',
    '&:hover': {
      background: colors.grey,
    },
  },
  cardContent: {
    textAlign: 'center',
    paddingTop: '24px',
  },
  cardText: {
    color: colors.white,
    lineHeight: '167%',
    letterSpacing: '0,03em',
  },
  iconStyles: {
    width: '32px',
    height: '32px',
    color: colors.lightGrey,
    marginBottom: '4px',
  },
});

export interface PersonalizationCardDataInterface {
  text: string;
  textStyles: string;
  cardStyles: string;
  cardContentStyles: string;
  type: PersonalizationSubType;
  icon?: JSX.Element;
}

const PersonalizationPanel = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const globalState = useStudioStateContext();

  const { hasPermission } = usePermissions();

  // Personalization Data that will be rendered in the Typography section
  const personalizationTypographyCardsToRender: PersonalizationCardDataInterface[] = [
    {
      text: t('workflow.left_side_bar.personalization_panel.title'),
      cardStyles: classes.card,
      cardContentStyles: classes.cardContent,
      textStyles: classes.cardText,
      type: PersonalizationSubType.HEADER_TITLE,
      icon: <TitleOutlinedIcon className={classes.iconStyles} />,
    },
    {
      text: t('workflow.left_side_bar.personalization_panel.subtitle'),
      cardStyles: classes.card,
      cardContentStyles: classes.cardContent,
      textStyles: classes.cardText,
      type: PersonalizationSubType.SUBTITLE,
      icon: <TitleOutlinedIcon className={classes.iconStyles} />,
    },
    {
      text: t('workflow.left_side_bar.personalization_panel.paragraph'),
      cardStyles: classes.card,
      cardContentStyles: classes.cardContent,
      textStyles: classes.cardText,
      type: PersonalizationSubType.NORMAL_PARAGRAPH,
      icon: <NotesOutlinedIcon className={classes.iconStyles} />,
    },
    {
      text: t('workflow.left_side_bar.personalization_panel.date'),
      cardStyles: classes.card,
      cardContentStyles: classes.cardContent,
      textStyles: classes.cardText,
      type: PersonalizationSubType.DATE,
      icon: <DateRangeOutlinedIcon className={classes.iconStyles} />,
    },
  ];

  // Personalization Data that will be rendered in the Content section
  const personalizationContentCardsToRender: PersonalizationCardDataInterface[] = [
    {
      text: t('workflow.left_side_bar.personalization_panel.products'),
      cardStyles: classes.card,
      cardContentStyles: classes.cardContent,
      textStyles: classes.cardText,
      type: PersonalizationSubType.PRODUCT,
      icon: <PhotoLibraryOutlinedIcon className={classes.iconStyles} />,
    },
    {
      text: t('workflow.left_side_bar.personalization_panel.visuals'),
      cardStyles: classes.card,
      cardContentStyles: classes.cardContent,
      textStyles: classes.cardText,
      type: PersonalizationSubType.VISUAL,
      icon: (
        <PhotoSizeSelectActualOutlinedIcon className={classes.iconStyles} />
      ),
    },
    {
      text: t('workflow.left_side_bar.personalization_panel.pictos'),
      cardStyles: classes.card,
      cardContentStyles: classes.cardContent,
      textStyles: classes.cardText,
      type: PersonalizationSubType.PICTO,
      icon: <StarBorderOutlinedIcon className={classes.iconStyles} />,
    },
    {
      text: t('workflow.left_side_bar.personalization_panel.logos'),
      cardStyles: classes.card,
      cardContentStyles: classes.cardContent,
      textStyles: classes.cardText,
      type: PersonalizationSubType.LOGO,
      icon: <HomeOutlinedIcon className={classes.iconStyles} />,
    },
    {
      text: t('workflow.left_side_bar.personalization_panel.charter'),
      cardStyles: classes.card,
      cardContentStyles: classes.cardContent,
      textStyles: classes.cardText,
      type: PersonalizationSubType.CHARTER,
      icon: <BookOutlinedIcon className={classes.iconStyles} />,
    },
    {
      text: t('workflow.left_side_bar.personalization_panel.address'),
      cardStyles: classes.card,
      cardContentStyles: classes.cardContent,
      textStyles: classes.cardText,
      type: PersonalizationSubType.ADDRESS_BLOCK,
      icon: <PlaceOutlinedIcon className={classes.iconStyles} />,
    },
  ];
  return (
    <Box pt={4} height="100vh">
      <Box>
        <Grid container item spacing={1} justify="center">
          {PersonalizationCards({
            personalizationCards: personalizationTypographyCardsToRender,
            personalizationSubType: PersonalizationType.TYPOGRAPHY,
          })}
        </Grid>
      </Box>
      {!globalState.document?.isCustomization &&
        hasPermission(PermissionKey.AccessVisualPersonnalizationItems) && (
          <Box marginTop="25px">
            <Grid container item spacing={1} justify="center">
              {PersonalizationCards({
                personalizationCards: personalizationContentCardsToRender,
                personalizationSubType: PersonalizationType.CONTENT,
              })}
            </Grid>
          </Box>
        )}
    </Box>
  );
};
export default PersonalizationPanel;
