import { css } from '@emotion/react';

export const shopStyle = css`
  padding-right: 42px;
  padding-left: 42px;
  h1 {
    padding-top: 39px;
    padding-bottom: 34px;
  }
  margin-bottom: 40px;
`;
