import produce, { castImmutable, Draft } from 'immer';
import {
  ProductsFiltersActions,
  ProductsFiltersActionTypes,
} from 'modules/pim/baseProducts/reducers/productsActions';
import { ProductFilterInput } from 'app/schemaInterfaces/globalTypes';
import { exhaustiveCheck } from 'app/utils/common';

export interface ProductsFiltersState {
  filters: ProductFilterInput;
}

export const ProductsInitialState: ProductsFiltersState = {
  filters: {
    text: '',
    withoutFilesToSend: false,
    withoutPrice: false,
    details: {},
  },
};

export const productsFiltersReducer = produce(
  (draft: Draft<ProductsFiltersState>, action: ProductsFiltersActions) => {
    switch (action.type) {
      case ProductsFiltersActionTypes.UpdateSearch:
        draft.filters.text = action.payload;
        break;
      case ProductsFiltersActionTypes.UpdateDivisions:
        (draft.filters.details as Record<string, string | number | string[]>)[
          action.payload.key
        ] = action.payload.values as string[];
        break;
      case ProductsFiltersActionTypes.UpdateFiles:
        draft.filters.withoutFilesToSend = action.payload;
        break;
      case ProductsFiltersActionTypes.UpdatePrice:
        draft.filters.withoutPrice = action.payload;
        break;
      case ProductsFiltersActionTypes.ClearFilters:
        return castImmutable(ProductsInitialState);
      default:
        exhaustiveCheck(action);
    }
  }
);
