import axios from 'axios';

export const uploadFile = (
  file: File,
  uploadUrl: string,
  mimetype: string = 'application/octet-stream',
  progressCallback?: (percentage: number) => void
): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      axios
        .put<void>(uploadUrl, reader.result, {
          onUploadProgress: (e: ProgressEvent) => {
            if (progressCallback) {
              progressCallback(Math.round((e.loaded * 100) / e.total));
            }
          },
          headers: {
            'Content-Type': mimetype,
          },
        })
        .then(() => resolve())
        .catch((error) => reject(error));
    };

    reader.readAsArrayBuffer(file);
  });
};

export const getBase64File = (
  file: File
): Promise<string | ArrayBuffer | null> =>
  new Promise<string | ArrayBuffer | null>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = () => reject(reader.error);
    reader.readAsDataURL(file);
  });

export const dataBase64toFile = (dataUrl: string, filename: string) => {
  const arr = dataUrl.split(',');
  const mimetype = arr[0].match(/:(.*?);/)?.[1];
  const base64 = atob(arr[1]);
  let b64Length = base64.length;
  let u8arr = new Uint8Array(b64Length);

  while (b64Length--) {
    u8arr[b64Length] = base64.charCodeAt(b64Length);
  }

  return new File([u8arr], filename, { type: mimetype ?? '' });
};
