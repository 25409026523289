import { useEffect, useState } from 'react';
import { matchPath } from 'react-router-dom';

import { useRouter } from 'app/routes/useRouter';
import { MenuItems } from './MenuToolBar';

export const useMenuToolBar = (menuItems: MenuItems[]) => {
  const routeName = useRouter().location.pathname;
  const [selectedMenu, setSelectedMenu] = useState<number>(0);

  const handleClick = (index: number) => setSelectedMenu(index);

  useEffect(() => {
    const selectedItem = menuItems.filter((it) =>
      matchPath(routeName, { path: it.to })
    );

    // needed for subroutes
    const lastRouteMatched = selectedItem[selectedItem.length - 1];

    lastRouteMatched && handleClick(lastRouteMatched.key);
  }, [routeName, menuItems]);

  return { selectedMenu, handleClick };
};
