import React, { FC, MouseEvent } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import {
  validationIconButtonStyle,
  validationIconButtonAcceptStyle,
  validationIconButtonDeclineStyle,
} from './FollowOrderValidationIconButton.style';

export interface FollowOrderValidationIconButtonProps {
  type: 'accept' | 'decline';
  onClick: (e: MouseEvent) => void;
}

const FollowOrderValidationIconButton: FC<FollowOrderValidationIconButtonProps> = ({
  type,
  onClick,
  children,
}) => (
  <Tooltip title={<>{children}</>} placement="top">
    <IconButton
      css={[
        validationIconButtonStyle,
        type === 'accept' && validationIconButtonAcceptStyle,
        type === 'decline' && validationIconButtonDeclineStyle,
      ]}
      onClick={onClick}
    >
      {type === 'accept' ? <DoneIcon /> : <CloseIcon />}
    </IconButton>
  </Tooltip>
);

export default FollowOrderValidationIconButton;
