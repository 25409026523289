import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const entityMenuOptionStyle = {
  container: css`
    &:hover .card .name {
      color: ${colors.primary};
    }
  `,
  selectedContainer: css`
    background-color: ${colors.menuHover};
  `,
};
