import React, { useState } from 'react';
import List from '@material-ui/core/List';
import { AppBar, Toolbar, Box, Divider } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ListItemLink from './Navigation/ListItemLink/ListItemLink';
import colors from 'styles/colors.module.scss';
import { useRouter } from 'app/routes/useRouter';
import { locationPathNameToSelectedMenu } from 'app/utils/common';
import { UserMenuToolbar } from 'components/Navigation/user-menu/UserMenuToolbar/UserMenuToolbar';
import LanguageSwitchToolbar from 'components/Navigation/LanguageSwitchToolbar/LanguageSwitchToolbar';
import { useTranslation } from 'react-i18next';
import Headroom from 'react-headroom';

interface MenuItems {
  translationKey: string;
  to?: string;
  isExternalLink?: boolean;
  isShown?: boolean;
}

interface MenuIcons {
  icon: JSX.Element;
  isMenu: boolean;
  to?: string;
}

export interface AppMenuProps {
  menuItems?: MenuItems[];
  menuIcons?: MenuIcons[];
  storeIcon?: React.ReactNode;
}

const AppMenu = ({
  menuItems,
  storeIcon,
  menuIcons,
}: AppMenuProps): JSX.Element => {
  const { t } = useTranslation();
  const routeName = useRouter().location.pathname;
  const history = useHistory();

  const [selectedMenu, setSelectedMenu] = useState<number>(
    locationPathNameToSelectedMenu[routeName]
  );

  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLLIElement | null>(null);

  const handleMenuSelect = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    if (index === 2) {
      setAnchorEl(event.currentTarget);
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  //TODO: Create a query in order to fetch user entities: query variables => (onlyUserEntities=true && type=true)
  /* const storesSubMenus = userEntities?.map((userEntity) => ({
    name: userEntity.name as string,
  }));
  const isUserEntitiesUnique = userEntities?.length === 1;*/

  return (
    <Headroom style={{ zIndex: 100 }}>
      <Box display="flex" height={72}>
        <AppBar
          position="fixed"
          style={{
            height: 72,
            background: colors.white,
            boxShadow: `0px 1px 1px ${colors.lightGrey}`,
            justifyContent: 'center',
          }}
        >
          <Toolbar>
            <List
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '.MuiListItemText-root': {
                  display: 'flex',
                },
              }}
            >
              <Box>
                <ListItemLink
                  label={storeIcon}
                  to="/"
                  onClick={() => setSelectedMenu(-1)}
                />
              </Box>
              <Box px={1}>
                <Divider orientation="vertical" style={{ height: '32px' }} />
              </Box>
              <Box>
                <ListItemLink
                  label={
                    <img
                      src={`/${process.env.REACT_APP_ASSETS}/LogoBigOneBlackAndWhite.png`}
                      height="40px"
                      alt="logoBigOne"
                    />
                  }
                  itemPaddingLeft={2}
                  to="/"
                  onClick={() => setSelectedMenu(-1)}
                />
              </Box>
            </List>

            <Box flexGrow="1" />
            <List style={{ display: 'flex', alignItems: 'center' }}>
              {menuItems?.map(
                (
                  {
                    translationKey,
                    to,
                    isExternalLink,
                    isShown = true,
                  }: MenuItems,
                  index: number
                ) =>
                  isShown && (
                    <Box key={index}>
                      <ListItemLink
                        label={t(translationKey)}
                        to={to}
                        isExternalLink={isExternalLink}
                        selected={selectedMenu}
                        onClick={() => setSelectedMenu(index)}
                        menuItemIndex={index}
                      />
                    </Box>
                  )
              )}
            </List>

            <LanguageSwitchToolbar />

            <List style={{ display: 'flex', alignItems: 'center' }}>
              {menuIcons?.map((menuIcon, index: number) => (
                <Box key={index} pt={0.5}>
                  {/* the first icon menu in this map is 0 so ot
								match the fifth element of the selectedMenu enum
								i need to start the menu with 5 + index  */}
                  <ListItemLink
                    label={menuIcon.icon}
                    onClick={(event) => {
                      if (menuIcon.isMenu) {
                        handleMenuSelect(event, index);
                      } else if (menuIcon.to) {
                        history.push(menuIcon.to);
                        setSelectedMenu(-1);
                      }
                    }}
                    menuItemIndex={5 + index}
                    dense={true}
                    disableGutters={true}
                  />
                </Box>
              ))}
            </List>
            <UserMenuToolbar
              open={open}
              anchorEl={anchorEl}
              handleMenuClose={handleClose}
            />
          </Toolbar>
        </AppBar>
      </Box>
    </Headroom>
  );
};

export default AppMenu;
