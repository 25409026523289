import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import BigBreadcrumbs, {
  BreadcrumbsLink,
} from 'components/studio/BigBreadcrumbs';
import { AssetType } from 'app/schemaInterfaces/globalTypes';
import { AssetsPanelActionCreator } from 'modules/workflow/containers/Leftsidebar/AssetsPanel/reducer/actions';
import { AssetsPanelContext } from 'modules/workflow/containers/Leftsidebar/AssetsPanel/reducer/context';

interface AssetTypeNavigationOptions {
  selectedAssetType: AssetType | null;
}

const AssetTypeNavigation = ({
  selectedAssetType,
}: AssetTypeNavigationOptions): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useContext(AssetsPanelContext);

  const navigationLinks = useMemo(() => {
    const value: BreadcrumbsLink[] = [
      {
        name: 'all',
        label: t('workflow.left_side_bar.medias_panel.content_navigation.all'),
      },
    ];
    if (selectedAssetType) {
      value.push({
        name: selectedAssetType,
        label: t(`dam.asset_types.${selectedAssetType?.toLowerCase()}`),
        selected: true,
      });
    }
    return value;
  }, [t, selectedAssetType]);

  return (
    <BigBreadcrumbs
      links={navigationLinks}
      onClick={() =>
        dispatch?.(AssetsPanelActionCreator.goToTypesView(undefined))
      }
    />
  );
};

export default AssetTypeNavigation;
