import { Badge, createStyles, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import colors from 'styles/colors.module.scss';

export enum BadgeStatus {
  Success = 'Success',
  Error = 'Error',
  Incomplete = 'Incomplete',
}

const badgeColors: Map<BadgeStatus, string> = new Map([
  [BadgeStatus.Success, colors.success],
  [BadgeStatus.Error, colors.error],
  [BadgeStatus.Incomplete, colors.loader8],
]);

const useStyles = makeStyles(() =>
  createStyles({
    statusBadge: (props: { status: BadgeStatus }) => ({
      '& > span': {
        border: '2px solid white',
        backgroundColor: badgeColors.get(props.status),
      },
    }),
  })
);

export interface StatusBadgeProps {
  className: string;
  status?: BadgeStatus;
  message?: string;
}
const StatusBadge = ({
  className,
  status = BadgeStatus.Success,
  message,
}: StatusBadgeProps): JSX.Element => {
  const classes = useStyles({ status });
  return (
    <Tooltip title={message ?? ''} arrow>
      <Badge
        className={`${className} ${classes.statusBadge}`}
        badgeContent=" "
      />
    </Tooltip>
  );
};
export default StatusBadge;
