import { gql } from '@apollo/client';

export const ASSET_METADATA = gql`
  fragment AssetMetadata on Metadata {
    credit
    fileHasCredit
    dimensions {
      height
      width
      heightInCm
      widthInCm
    }
    fileSize
    mimetype
    fileExtension @client
  }
`;

export const ASSET_DETAIL = gql`
  fragment AssetDetail on Asset {
    id
    assetType
    visualType
    charterType
    clientId
    comment
    division
    entities {
      id
      name
      type
      parentId
      level
      ancestors {
        id
        parentId
        name
        type
        level
      }
    }
    name
    permanent
    status
    conform
    tags {
      id
      title
    }
    metadata {
      ...AssetMetadata
    }
    url
    thumbnailUrl
    displayedThumbUrl @client
    validFrom
    validTo
    isVisible
  }
  ${ASSET_METADATA}
`;

export const CREATE_ASSET = gql`
  mutation CreateAsset($asset: AssetInput!) {
    createAsset(asset: $asset) {
      ...AssetDetail
    }
  }
  ${ASSET_DETAIL}
`;

export const GET_ASSET_BY_ID = gql`
  query GetAssetById($id: String!) {
    getAssetById(id: $id) {
      ...AssetDetail
    }
  }
  ${ASSET_DETAIL}
`;

export const UPDATE_ASSET = gql`
  mutation UpdateAsset($id: String!, $asset: AssetInput!) {
    updateAsset(id: $id, asset: $asset) {
      ...AssetDetail
    }
  }
  ${ASSET_DETAIL}
`;

// TODO: [Performance] there is no need to use AssetDetail in the list of assets, a more minimalist model should be enough
export const GET_ASSETS = gql`
  query GetAssets(
    $filters: AssetFiltersInput!
    $sort: AssetSortInput
    $pagination: PaginationInput
  ) {
    getAssets(filters: $filters, sort: $sort, pagination: $pagination) {
      assets {
        ...AssetDetail
      }
      count
    }
  }
  ${ASSET_DETAIL}
`;

// TODO: [Performance] there is no need to use AssetDetail in the list of assets, a more minimalist model should be enough
export const GET_SIMILAR_ASSETS = gql`
  query GetSimilarAssets($assetId: String!, $pagination: PaginationInput) {
    similarAssets: getSimilarAssets(
      assetId: $assetId
      pagination: $pagination
    ) {
      ...AssetDetail
    }
  }
  ${ASSET_DETAIL}
`;

export const CREATE_ASSET_UPLOAD_URL = gql`
  mutation CreateAssetUploadUrl($mimetype: String!) {
    createAssetUploadUrl(mimetype: $mimetype) {
      id
      url
    }
  }
`;

export const CREATE_ASSET_UPLOAD_ZIP_URL = gql`
  mutation CreateAssetsZipUrl($assetIds: [String!]!) {
    createAssetsZipUrl(assetIds: $assetIds) {
      url
    }
  }
`;

export const DELETE_MANY_ASSETS_SAFELY = gql`
  mutation DeleteManyAssetsSafely($ids: [String!]!) {
    deleteManyAssetsSafely(ids: $ids) {
      id
      result
    }
  }
`;
