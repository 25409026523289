import { useQuery } from '@apollo/client';
import { Badge, Box, Button, IconButton, Popover } from '@material-ui/core';
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Immutable } from 'immer';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDivisionMultiSelect } from 'components/divisions/useDivisionMultiSelect';
import { UncontrolledDatePicker } from 'components/UncontrolledDatePicker';
import UncontrolledSimpleAutocomplete from 'components/UncontrolledSimpleAutocompelete';
import { GET_DIVISIONS } from 'app/graphql/queries/divisions';
import { GET_DIVISIONS as I_GET_DIVISIONS } from 'app/schemaInterfaces/GET_DIVISIONS';
import colors from 'styles/colors.module.scss';
import { AssetsPanelActionCreator } from 'modules/workflow/containers/Leftsidebar/AssetsPanel/reducer/actions';
import { AssetsPanelContext } from 'modules/workflow/containers/Leftsidebar/AssetsPanel/reducer/context';

interface AssetPanelFiltersProps {
  divisions: Immutable<Record<string, string[] | undefined>>;
  validFrom?: MaterialUiPickersDate;
  validTo?: MaterialUiPickersDate;
}

const AssetPanelFilters = ({
  divisions,
  validFrom,
  validTo,
}: AssetPanelFiltersProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useContext(AssetsPanelContext);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const { data } = useQuery<I_GET_DIVISIONS>(GET_DIVISIONS);
  const { multiSelectModel: divisionSelectModel } = useDivisionMultiSelect(
    data
  );

  const hasActiveFilter =
    validTo || validFrom || Object.values(divisions).length > 0;

  const handleDivisionsChange = (key: string, values: string[]) => {
    dispatch?.(AssetsPanelActionCreator.updateDivisions({ key, values }));
    setAnchorEl(null);
  };

  const handleValidityDateChange = (
    key: 'validFrom' | 'validTo',
    value?: MaterialUiPickersDate
  ) => {
    dispatch?.(
      AssetsPanelActionCreator.updateValidityDates({
        validFrom,
        validTo,
        [key]: value ?? undefined,
      })
    );
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
        <Badge variant="dot" color="primary" invisible={!hasActiveFilter}>
          <FilterListOutlinedIcon style={{ color: colors.white }} />
        </Badge>
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box py={2} px={3} minWidth={200}>
          <Box>
            {divisionSelectModel.map((select) => (
              <UncontrolledSimpleAutocomplete
                key={select.key}
                value={(divisions[select.key] as string[] | undefined) ?? []}
                options={select.values}
                placeholder={select.label}
                margin="dense"
                onChange={(values) => handleDivisionsChange(select.key, values)}
              />
            ))}
          </Box>

          <Box>
            <UncontrolledDatePicker
              placeholder={t(
                'workflow.left_side_bar.medias_panel.filters.date_from'
              )}
              margin="dense"
              value={validFrom ?? null}
              maxDate={validTo}
              onChange={(date) => handleValidityDateChange('validFrom', date)}
              handleClear={() =>
                handleValidityDateChange('validFrom', undefined)
              }
              fullWidth
            />
          </Box>

          <UncontrolledDatePicker
            placeholder={t(
              'workflow.left_side_bar.medias_panel.filters.date_to'
            )}
            margin="dense"
            value={validTo ?? null}
            minDate={validFrom}
            onChange={(date) => handleValidityDateChange('validTo', date)}
            handleClear={() => handleValidityDateChange('validTo', undefined)}
            fullWidth
          />

          <Box mt={1} display="flex" justifyContent="flex-end">
            <Button
              color="primary"
              onClick={() => {
                setAnchorEl(null);
                dispatch?.(AssetsPanelActionCreator.clearFilters(undefined));
              }}
            >
              {t('workflow.left_side_bar.medias_panel.filters.clear')}
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default AssetPanelFilters;
