import React from 'react';
import { NumberSize } from 're-resizable';
import {
  Regrouping,
  useStudioDispatchContext,
  useStudioStateContext,
} from 'modules/workflow/oneStudioContext';
import { Direction } from 're-resizable/lib/resizer';
import { studioAppActions } from 'modules/workflow/reducers/actionsInterfaces';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import {
  AssetTypeInCatalog,
  IProductTemplate,
} from 'modules/workflow/Clients/ToutFaire/ProductItem';
import { VisualType } from 'app/schemaInterfaces/globalTypes';
import useAppContext from 'app/app-context/useAppContext';
import { VisuelFieldName } from 'modules/pim/baseProducts/ProductsHelper';
import { ClientConfig } from 'app/schemaInterfaces/ClientConfig';
import { css } from '@emotion/react';
import { Rnd, DraggableData, RndDragEvent } from 'react-rnd';

interface ResizableDraggableRegroupingProps {
  resizeStyle: CSSProperties;
  personalizationKey?: string;
  product: IProductTemplate;
  pageIndex: number;
  zoneIndex: number;
  assetType: AssetTypeInCatalog;
}

const ResizableDraggableRegrouping = ({
  personalizationKey,
  resizeStyle,
  product,
  pageIndex,
  zoneIndex,
}: ResizableDraggableRegroupingProps): JSX.Element => {
  const dispatch = useStudioDispatchContext();
  const globalState = useStudioStateContext();

  const {
    client: { config: clientConfig },
  } = useAppContext();

  const updateAssetPositionHandler = (
    event: RndDragEvent,
    position: DraggableData,
    pageNumber: number,
    zoneIndex: number
  ) => {
    personalizationKey
      ? dispatch(
          studioAppActions.updatePersonalizationProductAssetPosition({
            pageNumber: pageNumber,
            zoneIndex: zoneIndex,
            productId: personalizationKey,
            position: { x: position.x, y: position.y },
            assetType: product.regrouping?.[0].assetType ?? '',
            visualId: product.regrouping?.[0].id ?? '',
            isRegrouping: true,
          })
        )
      : dispatch(
          studioAppActions.updateAssetPosition({
            pageNumber: pageNumber,
            zoneIndex: zoneIndex,
            productId: product.id,
            position: { x: position.x, y: position.y },
            assetType: product.regrouping?.[0].assetType ?? '',
            visualId: product.regrouping?.[0].id ?? '',
            isRegrouping: true,
          })
        );
  };

  const defaultImageSize = 133;

  const handleSizeChanged = (
    event: MouseEvent | TouchEvent,
    direction: Direction,
    ref: HTMLElement,
    delta: NumberSize
  ) => {
    return product.regrouping?.map((group) => {
      const assetSize = {
        height: (group.size?.height ?? defaultImageSize) + delta.height,
        width: (group.size?.width ?? defaultImageSize) + delta.width,
      };
      return personalizationKey
        ? dispatch(
            studioAppActions.updatePersonalizationProductAssetSize({
              pageNumber: pageIndex,
              zoneIndex,
              productId: personalizationKey,
              size: assetSize,
              assetType: group.assetType ?? '',
              visualId: group.id,
              isRegrouping: true,
              regroupingSpacing: regroupingConfig?.spacing ?? 0,
            })
          )
        : dispatch(
            studioAppActions.updateAssetSize({
              pageNumber: pageIndex,
              zoneIndex,
              productId: product.id,
              size: assetSize,
              assetType: group.assetType ?? '',
              visualId: group.id,
              isRegrouping: true,
              regroupingSpacing: regroupingConfig?.spacing ?? 0,
            })
          );
    });
  };

  const productRegrouping = product.regrouping?.filter(({ isMain }) => !isMain);

  const getSumRegroupingElementsWidth = productRegrouping
    ?.map((group) => group.size.width)
    .reduce((fisrtWidth, secondWidth) => fisrtWidth + secondWidth, 0);

  const regroupingConfig = (clientConfig as ClientConfig)?.workflow.supportConfig?.find(
    ({ supportId }) => supportId === globalState.document?.supportId
  )?.regroupingConfig;

  const mainVisual = product.visualIdentities?.find(
    (visual) => visual.fieldName === VisuelFieldName.mainVisuel
  );

  const regroupingPosition =
    regroupingConfig &&
    mainVisual?.visualType !== VisualType.Ambiance &&
    mainVisual?.position
      ? {
          x: mainVisual ? mainVisual?.position.x : 0,
          y: regroupingConfig?.positionRatio
            ? mainVisual?.size.height +
              mainVisual?.position.y -
              regroupingConfig?.positionRatio
            : 0,
        }
      : productRegrouping && productRegrouping[0]?.position;

  const regroupingSize = {
    width:
      productRegrouping &&
      regroupingConfig?.spacing &&
      getSumRegroupingElementsWidth
        ? getSumRegroupingElementsWidth +
          (productRegrouping?.length - 1) * regroupingConfig?.spacing
        : 0,
    height: !!productRegrouping?.length
      ? productRegrouping?.[0].size.height
      : 0,
  };

  const regroupingGridStyle = () => {
    return css`
      display: grid;
      grid-template-columns: ${productRegrouping?.[0]?.size.width}px ${productRegrouping?.[0]
          ?.size.width}px ${productRegrouping?.[0]?.size.width}px;
      grid-gap: ${regroupingConfig?.spacing}px;
      max-width: ${product.size.width}px;
    `;
  };

  const regroupingBoxStyle = (group: Regrouping) => {
    return css`
      width: ${group.size.width}px;
      height: ${group.size.height}px;
      background-image: url(${group.thumbnailUrl});
      background-size: ${group.size.width}px ${group.size.height}px;
    `;
  };

  return productRegrouping &&
    !!productRegrouping.length &&
    regroupingConfig?.spacing &&
    mainVisual ? (
    <Rnd
      size={regroupingSize}
      className={globalState.commentMode ? 'comment' : 'resizable-item'}
      style={{
        position: 'absolute',
        ...resizeStyle,
      }}
      axis="both"
      dragHandleClassName="logoContainer"
      bounds="parent"
      maxWidth={product.size.width}
      scale={globalState.scaleValue}
      position={regroupingPosition}
      onResizeStop={(event, direction, ref, delta, position) => {
        updateAssetPositionHandler(
          event,
          position as DraggableData,
          pageIndex,
          zoneIndex
        );
        handleSizeChanged(event, direction, ref, delta);
      }}
      onDragStop={(event, position) =>
        updateAssetPositionHandler(event, position, pageIndex, zoneIndex)
      }
    >
      <div css={regroupingGridStyle()}>
        {productRegrouping?.map((group) => (
          <div>
            <div className="logoContainer" css={regroupingBoxStyle(group)}>
              <p
                style={{
                  width: 'inherit',
                  position: 'absolute',
                  bottom: 0,
                  fontFamily:
                    regroupingConfig?.typographyOnImage?.fontFamily ?? '',
                  fontSize: `${regroupingConfig?.typographyOnImage?.fontSize}pt`,
                  lineHeight: '1.2em',
                  textDecoration: 'none',
                }}
              >
                {product.productDetails[
                  `${regroupingConfig?.typographyOnImage?.key}-${group.productId}`
                ] ??
                  group.details[
                    regroupingConfig?.typographyOnImage?.key ?? ''
                  ]}{' '}
              </p>
            </div>
            <div
              style={{
                width: `${group.size.width}px`,
                height: `${group.size.height}px`,
              }}
            >
              <p
                style={{
                  fontFamily:
                    regroupingConfig?.typographyOffImage?.fontFamily ?? '',
                  fontSize: `${regroupingConfig?.typographyOffImage?.fontSize}pt`,
                  textDecoration: 'none',
                  wordBreak: 'break-word',
                  lineHeight: '1.2em',
                }}
              >
                {group.details[regroupingConfig?.typographyOffImage?.key ?? '']}
              </p>
            </div>
          </div>
        ))}
      </div>
    </Rnd>
  ) : (
    <div></div>
  );
};

export default ResizableDraggableRegrouping;
