import { gql } from '@apollo/client';

export const CREATE_NOTIFICATIONS = gql`
  mutation CreateNotification($notificationItem: NotificationInput!) {
    createNotification(notificationItem: $notificationItem) {
      _id
      createdAt
      type
      isRead
      link
      metadata
      sendingDate
      userId
      conditionItemId
    }
  }
`;

export const GET_USER_NOTIFICATIONS = gql`
  query GetUserNotifications {
    getUserNotifications {
      _id
      createdAt
      type
      isRead
      link
      metadata
      sendingDate
      userId
      conditionItemId
    }
  }
`;

export const UPDATE_NOTIFICATIONS = gql`
  mutation UpdateNotification($id: String!) {
    updateNotification(id: $id) {
      _id
      createdAt
      type
      isRead
      link
      metadata
      sendingDate
      userId
      conditionItemId
    }
  }
`;

export const UPDATE_NOTIFICATIONS_DATE_BY_CONDITION_ID = gql`
  mutation UpdateNotificationsDateByConditionId(
    $conditionItemId: String!
    $sendingDate: DateTime!
    $type: NotificationType!
  ) {
    updateNotificationsDateByItemId(
      conditionItemId: $conditionItemId
      sendingDate: $sendingDate
      type: $type
    ) {
      _id
      createdAt
      type
      isRead
      link
      metadata
      sendingDate
      userId
      conditionItemId
    }
  }
`;

export const CREATE_NOTIFICATION_FOR_MULTIPLE_ENTITIES = gql`
  mutation CreateNotificationForMultipleEntities(
    $entitiesIds: [String!]!
    $notificationItem: MultipleNotificationInput!
  ) {
    createNotificationForMultipleEntities(
      entitiesIds: $entitiesIds
      notificationItem: $notificationItem
    ) {
      _id
      userId
      conditionItemId
      type
      isRead
      sendingDate
      link
      metadata
    }
  }
`;
