import { gql } from '@apollo/client';

export const GET_ALL_CAMPAIGNS = gql`
  query GetAllCampaigns(
    $filter: CampaignFilterInput
    $pagination: PaginationInput
  ) {
    getAllCampaigns(filter: $filter, pagination: $pagination) {
      campaigns {
        id
        name
        description
        externalAgency
        owner
        imageUrl
        projectManager
        dateFrom
        dateTo
        reference
        refElpev
        totalSumOrders
        commitmentDateFrom
        commitmentDateTo
        mediumTypes {
          leaflet
          plv
          email
          sms
          digital
        }
        documentCategories
        tags {
          id
          title
        }
        category {
          id
          title
        }
        campaignType
        createdAt
        updatedAt
        commitmentDateFrom
        commitmentDateTo
        documents {
          id
          origin
          orderTypes
          freeOrderDateFrom
          freeOrderDateTo
          commitmentDateFrom
          commitmentDateTo
          isValidated
          children {
            id
          }
          versionChildren {
            id
          }
        }
        entities {
          id
          ancestors {
            id
          }
        }
      }
      totalItems
      dateStatusCount {
        totalItemsUpcoming
        totalItemsInProgress
        totalItemsTerminated
        totalItemsAllDateStatus
      }
    }
  }
`;

export const GET_ALL_CAMPAIGNS_LEAN = gql`
  query GetAllCampaignsLean(
    $filter: CampaignFilterInput
    $pagination: PaginationInput
  ) {
    getAllCampaigns(filter: $filter, pagination: $pagination) {
      campaigns {
        id
        name
        description
        imageUrl
        dateFrom
        dateTo
        reference
        documentCategories
        tags {
          title
        }
        category {
          title
        }
      }
      totalItems
      dateStatusCount {
        totalItemsUpcoming
        totalItemsInProgress
        totalItemsTerminated
        totalItemsAllDateStatus
      }
    }
  }
`;

export const CREATE_CAMPAIGN = gql`
  mutation CreateCampaign($campaign: CampaignInput!) {
    createCampaign(campaign: $campaign) {
      id
      campaignType
      createdAt
      dateFrom
      dateTo
      description
      externalAgency
      imageUrl
      name
      owner
      parentCampaignId
      projectManager
      reference
      refElpev
      tags {
        id
        title
      }
      category {
        id
        title
      }
      updatedAt
      totalSumOrders
      mediumTypes {
        leaflet
        plv
        email
        sms
        digital
      }
      documentCategories
      entities {
        id
        name
        country
        clientId
        createdAt
        createdBy
        legal {
          ape
          capital
          iban
          name
          rcs
          siret
          status
        }
        openingHours {
          days
          hours
        }
        parentId
        picture
        type
        updatedAt
        othersInformations {
          codeClient
          codeRegion
          codeSector
          surface
          typology
          typology2
        }
      }
    }
  }
`;

export const UPDATE_CAMPAIGN = gql`
  mutation UpdateCampaignById($id: String!, $campaign: CampaignInput!) {
    updateCampaignById(id: $id, campaign: $campaign) {
      id
      campaignType
      createdAt
      dateFrom
      dateTo
      description
      externalAgency
      imageUrl
      name
      owner
      parentCampaignId
      projectManager
      reference
      refElpev
      tags {
        id
        title
      }
      category {
        id
        title
      }
      updatedAt
      totalSumOrders
      mediumTypes {
        leaflet
        plv
        email
        sms
        digital
      }
      documentCategories
      entities {
        id
        name
        country
        clientId
        createdAt
        createdBy
        legal {
          ape
          capital
          iban
          name
          rcs
          siret
          status
        }
        openingHours {
          days
          hours
        }
        parentId
        picture
        type
        updatedAt
        othersInformations {
          codeClient
          codeRegion
          codeSector
          surface
          typology
          typology2
        }
      }
    }
  }
`;

export const GET_CAMPAIGN_IMAGE_UPLOAD_URL = gql`
  query GetCampaignImageUploadUrl($mimetype: String!) {
    getCampaignImageUploadUrl(mimetype: $mimetype) {
      id
      url
    }
  }
`;

export const GET_CAMPAIGNS_WITH_CATEGORY = gql`
  query GetCampaignsWithQuery($filter: CampaignFilterInput) {
    campaigns: getAllCampaigns(filter: $filter) {
      campaigns {
        id
        name
        imageUrl
        category {
          id
          title
        }
        documents {
          origin
          freeOrderDateTo
          freeOrderDateFrom
          isValidated
        }
        updatedAt
      }
    }
  }
`;

export const GET_CAMPAIGN_BY_ID = gql`
  query GetCampaignById($id: String!) {
    getCampaignById(id: $id) {
      id
      name
      campaignType
      createdAt
      dateFrom
      dateTo
      commitmentDateFrom
      commitmentDateTo
      description
      externalAgency
      imageId
      imageUrl
      mediumTypes {
        digital
        email
        leaflet
        plv
        sms
      }
      name
      owner
      parentCampaignId
      projectManager
      refElpev
      reference
      tags {
        id
        title
      }
      category {
        id
        title
      }
      totalSumOrders
      updatedAt
      archived
      entities {
        id
        name
        type
        parentId
        level
        ancestors {
          id
          parentId
          name
          type
          level
        }
      }
      documents {
        commitmentDateFrom
        commitmentDateTo
        freeOrderDateFrom
        freeOrderDateTo
        id
        isCustomizable
        hasAddressBlock
        isCustomization
        orderTypes
        origin
        parentId
        versionId
        isValidated
        children {
          id
        }
        versionChildren {
          id
        }
      }
    }
  }
`;

export const ARCHIVE_CAMPAIGN_BY_ID = gql`
  mutation ArchiveCampaignById($id: String!) {
    archiveCampaignById(id: $id)
  }
`;

export const GET_PlANNING_CAMPAIGNS = gql`
  query GetPlanningCampaigns(
    $startDate: DateTime!
    $endDate: DateTime!
    $entityId: String!
  ) {
    getPlanningCampaigns(
      startDate: $startDate
      endDate: $endDate
      entityId: $entityId
    ) {
      id
      name
      dateFrom
      dateTo
      campaignType
      createdAt
      updatedAt
      entities {
        id
        ancestors {
          id
        }
      }
    }
  }
`;
