import { gql } from '@apollo/client';

export const CREATE_COMPETITOR = gql`
  mutation CreateCompetitor($values: AddCompetitorInput!) {
    createCompetitor(values: $values) {
      id
      entityId
      url
      socialNetwork
    }
  }
`;

export const DELETE_COMPETITOR = gql`
  mutation DeleteCompetitor($id: String!) {
    deleteCompetitor(id: $id) {
      deletedCount
    }
  }
`;

export const IS_URL_VALID = gql`
  query IsUrlValid($url: String!) {
    isUrlValid(url: $url) {
      isValid
    }
  }
`;

export const GET_COMPETITORS = gql`
  query GetCompetitors(
    $filters: CompetitorsFiltersInput
    $sort: CompetitorsSortInput
  ) {
    competitors(filters: $filters, sort: $sort) {
      id
      entityId
      url
      socialNetwork
    }
  }
`;
