import { css } from '@emotion/react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
const style = css`
  .MuiDialog-container {
    h2 {
      font-size: 20px;
      font-weight: bold;
    }
    .MuiDialogActions-root {
      span {
        font-size: 14px;
      }
    }
  }
`;
interface CommentDialogProps {
  open: boolean;
  handleClose: () => void;
  commentValue: string;
  handleCommentChange: (
    event: ChangeEvent<{
      name?: string | undefined;
      value: string;
    }>
  ) => void;
  saveComment: () => void;
}
const CommentDialog = ({
  open,
  handleClose,
  commentValue,
  handleCommentChange,
  saveComment,
}: CommentDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} fullWidth onClose={handleClose} css={style}>
      <DialogTitle className="title">
        {t('sales.basket_page.delivery_page.comment.write_comment')}
      </DialogTitle>
      <DialogContent>
        <TextField
          variant="outlined"
          autoFocus
          margin="dense"
          label="Commentaire"
          fullWidth
          multiline
          minRows={6}
          value={commentValue}
          onChange={handleCommentChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="text" color="primary">
          {t('sales.basket_page.delivery_page.comment.cancel')}
        </Button>
        <Button onClick={saveComment} variant="contained" color="primary">
          {t('sales.basket_page.delivery_page.comment.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default CommentDialog;
