import 'react-calendar-timeline/lib/Timeline.css';

import { EntityType } from 'app/schemaInterfaces/globalTypes';
import {
  Badge,
  Box,
  Button,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  CustomReactCalendarItemRendererProps,
  CustomTimelineGroupBase,
  CustomTimelineItemBase,
} from 'components/ReversePlanning/ReversePlanning';
import {
  GetEntities,
  GetEntitiesVariables,
} from 'app/schemaInterfaces/GetEntities';
import React, { useState } from 'react';
import { capitalizeFirstLetter, truncateString } from 'app/utils/common';
import {
  getCampaignsEventsEntities,
  getItemsBackgroundColor,
  redirectToCampaignByUserType,
} from './HomeReversePlanningHeader';

import { GET_PlANNING_CAMPAIGNS } from 'app/graphql/queries/campaigns';
import { GET_ENTITIES } from 'app/graphql/queries/entities';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ReversePlanning from 'components/ReversePlanning/ReversePlanning';
import { Skeleton } from '@material-ui/lab';
import colors from 'styles/colors.module.scss';
import { legendStyle } from 'components/ReversePlanning/ReversePlanning.style';
import moment from 'moment';
import useCurrentEntity from 'app/current-entity-context/useCurrentEntity';
import { useCurrentUser } from 'app/auth/UserContext';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  GetPlanningCampaigns,
  GetPlanningCampaignsVariables,
  GetPlanningCampaigns_getPlanningCampaigns,
} from 'app/schemaInterfaces/GetPlanningCampaigns';

interface CustomSidebarHeaderProps {
  getRootProps: (propsToOverride?: {
    style: React.CSSProperties;
  }) => { style: React.CSSProperties };
}

const HomeReversePlanning = (): JSX.Element => {
  const { currentEntity } = useCurrentEntity();
  const { t } = useTranslation();
  const history = useHistory();
  const user = useCurrentUser();

  const [selectedDatePlanning, setSelectedDatePlanning] = useState<
    MaterialUiPickersDate
  >(moment());

  const startDate = moment(selectedDatePlanning).startOf('month');
  const endDate = moment(selectedDatePlanning).endOf('month');
  const { data: allCampaignsData, loading } = useQuery<
    GetPlanningCampaigns,
    GetPlanningCampaignsVariables
  >(GET_PlANNING_CAMPAIGNS, {
    fetchPolicy: 'network-only',
    variables: {
      entityId: currentEntity?.id ?? '',
      startDate,
      endDate,
    },
  });

  const { data: entities } = useQuery<GetEntities, GetEntitiesVariables>(
    GET_ENTITIES,
    {
      variables: {
        filters: {
          types: [EntityType.SubGroup1],
        },
      },
    }
  );

  const { getPlanningCampaigns: allCampaigns } = allCampaignsData ?? {};

  const getEventsForPlanning = allCampaigns?.length
    ? allCampaigns.reduce(
        (
          newEvents: CustomTimelineItemBase<MaterialUiPickersDate>[],
          eventsFromQuery: GetPlanningCampaigns_getPlanningCampaigns
        ) => {
          newEvents.push({
            id: eventsFromQuery.id ?? '',
            group: eventsFromQuery.id ?? '',
            title: eventsFromQuery.name,
            type: eventsFromQuery.campaignType,
            start_time: moment(eventsFromQuery.dateFrom),
            end_time: moment(eventsFromQuery.dateTo),
            backgroundColor: colors.planningNationalEvents,
            entities: getCampaignsEventsEntities(eventsFromQuery, entities),
            redirectLink: redirectToCampaignByUserType(eventsFromQuery, user),
          });
          return newEvents;
        },
        []
      )
    : [];

  const getRessourcesForPlanning = allCampaigns?.reduce(
    (
      newRessources: CustomTimelineGroupBase[],
      ressourcesFromQuery?: GetPlanningCampaigns_getPlanningCampaigns
    ) => {
      newRessources.push({
        id: ressourcesFromQuery?.id ?? '',
        title: ressourcesFromQuery?.name,
      });
      return newRessources;
    },
    []
  );

  const customItemsRenderer = (
    props?: CustomReactCalendarItemRendererProps<
      CustomTimelineItemBase<MaterialUiPickersDate>
    >
  ) => {
    const { item, itemContext, getItemProps, getResizeProps } = props ?? {};
    return item &&
      itemContext &&
      getItemProps &&
      getResizeProps &&
      !item.disabled ? (
      <Tooltip title={itemContext.title}>
        <span>
          <Box
            {...getItemProps({
              style: {
                border: 'none',
                backgroundColor: getItemsBackgroundColor(itemContext),
              },
            })}
            onClick={() =>
              history.push({
                pathname: item.redirectLink as string,
                state: { prevPath: history.location.pathname },
              })
            }
          >
            <Box
              style={{
                borderRadius: 40,
                display: item.disabled ? 'none' : 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: item.backgroundColor,
                border: 'none',
                height: '24px',
              }}
            >
              <Typography variant="body2" style={{ color: colors.black }}>
                {item.end_time &&
                item.end_time.diff(item.start_time, 'days') > 5
                  ? item.title
                  : truncateString(itemContext.title, 5)}
              </Typography>
            </Box>
            <Box
              color={colors.grey}
              width="inherit"
              alignItems="center"
              display="flex"
              justifyContent="center"
            >
              <Typography variant="caption">
                {item.entities?.map(({ name }) => name).join(', ')}
              </Typography>
            </Box>
          </Box>
        </span>
      </Tooltip>
    ) : null;
  };

  const customSidebarHeader = ({ getRootProps }: CustomSidebarHeaderProps) => {
    return (
      <Box
        {...getRootProps({
          style: {
            background: colors.documentsHeaderBackground,
            height: 88,
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
          },
        })}
      ></Box>
    );
  };

  const navigationArrows = () => (
    <Box display="flex">
      <IconButton
        size="medium"
        onClick={() =>
          setSelectedDatePlanning(moment(selectedDatePlanning).add(-1, 'month'))
        }
      >
        <NavigateBeforeIcon />
      </IconButton>
      <IconButton
        size="medium"
        onClick={() =>
          setSelectedDatePlanning(moment(selectedDatePlanning).add(1, 'month'))
        }
      >
        <NavigateNextIcon />
      </IconButton>
    </Box>
  );

  const homeReversePlanningHeader = () => (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="left"
      width="100%"
      p={1}
    >
      <Box pr={2}>
        <Button
          style={{
            color: colors.primary,
            boxShadow: 'none',
            backgroundColor: colors.menuHover,
          }}
          variant="contained"
          onClick={() => setSelectedDatePlanning(moment())}
        >
          {t('common.dates.measurements.today')}
        </Button>
      </Box>
      <Box>{navigationArrows}</Box>
      <Box pr={1} color={colors.black}>
        <Typography variant="h4">
          {selectedDatePlanning &&
            capitalizeFirstLetter(selectedDatePlanning.format('MMMM'))}
        </Typography>
      </Box>
      <Box
        bgcolor={colors.documentsHeaderBackground}
        borderRadius={40}
        display="flex"
        height="32px"
        width="95px"
        alignItems="center"
        p={1}
      >
        <Typography variant="body1">
          {t('common.dates.measurements.weeks')}
        </Typography>
        <Typography variant="body1" style={{ paddingLeft: 3 }}>
          {selectedDatePlanning && selectedDatePlanning.format('w')}
        </Typography>
      </Box>
    </Box>
  );

  if (loading) {
    return (
      <Box>
        <h1>{t('home_page.planning.header')}</h1>
        <Box>
          <Skeleton width="100%" height="500px" />
        </Box>
      </Box>
    );
  }

  const customLegend = () => (
    <div css={legendStyle}>
      <div className="badge national-badge">
        <Badge overlap="circular" variant="dot" className="circle" />
        <p>{t('local.common.national_campaigns')}</p>
      </div>
    </div>
  );

  return (
    <Box className="planning">
      <h1>{t('home_page.planning.header')}</h1>
      <Box>
        <ReversePlanning
          groupsPlanning={getRessourcesForPlanning as CustomTimelineGroupBase[]}
          eventsPlanning={
            getEventsForPlanning as CustomTimelineItemBase<
              MaterialUiPickersDate
            >[]
          }
          customItemsRenderer={customItemsRenderer}
          selectedDatePlanning={selectedDatePlanning}
          customSidebarHeader={customSidebarHeader}
          isHomePage={true}
          customHeader={homeReversePlanningHeader()}
          customLegend={customLegend()}
        />
      </Box>
    </Box>
  );
};

export default HomeReversePlanning;
