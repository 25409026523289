import React from 'react';
import { Language } from 'app/i18n/i18n';
import i18next from 'app/i18n/i18n';
import { buttonStyle } from './LanguageSwitchToolbar.stlye';
import { useLanguageSwitchToolbar } from './LanguageSwitchToolbar.hook';

const LanguageSwitchToolbar = () => {
  const { handleClick, languageIcons } = useLanguageSwitchToolbar();

  return (
    <div data-testid="toolbar">
      <button css={buttonStyle} data-testid="button">
        <img
          src={languageIcons[i18next.language as Language]}
          width="28"
          alt="flag"
          onClick={handleClick}
        />
      </button>

      {/* temporary disable */}
      {/* <Menu
        disableScrollLock={true}
        css={menuStyle}
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {Object.values(Language).map((language) => (
          <MenuItem
            key={language}
            onClick={() => handleMenuItemClick(language)}
          >
            <ListItemIcon>
              <img src={languageIcons[language]} width="25" alt="flag" />
            </ListItemIcon>
            <ListItemText primary={t(`common.languages.${language}`)} />
          </MenuItem>
        ))}
      </Menu> */}
    </div>
  );
};

export default LanguageSwitchToolbar;
