import colors from 'styles/colors.module.scss';
import { css } from '@emotion/react';

export const orderDocumentRowStyle = (lg: boolean) => css`
  padding: 24px;
  .addresses {
    .MuiSvgIcon-root {
      vertical-align: middle;
    }
    .see-more {
      text-align: center;
    }
  }
  .network-link {
    word-break: break-all;
  }
  .document-title {
    h5 {
      font-weight: 500;
      padding-bottom: 8px;
    }
    .divider {
      margin: 0 8px;
      border-left: 1px solid ${colors.buttonGroupOutlined};
    }
    p {
      padding-top: 4px;
      font-size: 12px;
    }
  }
  .icon-buton {
    margin-left: 90px;
    margin-top: 32px;
  }
  p {
    padding: 5px 0 8px 0;
    font-size: 12px;
  }

  .document-thumbnail--container {
    position: relative;
  }
  .document-thumbnail--container button {
    position: absolute;
    bottom: 10px;
    right: 15px;
    padding: 5px;
    background-color: rgba(255, 255, 255, 0.5);
  }
  .document-thumbnail--container button:hover {
    background-color: ${colors.white};
  }
  .document-thumbnail--container button svg {
    color: ${colors.primary};
    font-size: 18px;
  }
  .document-row-actions {
    & > * {
      margin: 0 5px;
    }
  }
  .price {
    text-align: ${lg ? 'left' : 'center'};
  }
`;
