import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Typography } from '@material-ui/core';
import {
  GetCompetitors,
  GetCompetitorsVariables,
} from 'app/schemaInterfaces/GetCompetitors';
import { ApolloQueryResult } from '@apollo/client';
import CompetitorFormContainer from '../CompetitorFormContainer/CompetitorFormContainer';

const StartCompetitiveDevelopment = ({
  refetch,
}: {
  refetch: (
    variables?: Partial<GetCompetitorsVariables> | undefined
  ) => Promise<ApolloQueryResult<GetCompetitors>>;
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Grid container item className="container" xs={12} spacing={3}>
      <Grid item xs={12}>
        <h2>{t('competitors.start')}</h2>
        <Typography variant="body1">{t('competitors.add_pages')}</Typography>
        <Typography variant="body1">
          {t('competitors.add_many_sources')}
        </Typography>{' '}
      </Grid>
      <Grid item xs={12} className="add-container" data-testid="add-container">
        <CompetitorFormContainer refetch={refetch}>
          <div className="add">
            <img
              src={`/${process.env.REACT_APP_ASSETS}/add-social-networks.png`}
              alt="social-networks"
              className="logo"
            />
            <Button variant="contained" color="primary" className="button">
              {t('competitors.add')}
            </Button>

            <h5>{t('competitors.start_competitive_dev')}</h5>
          </div>
        </CompetitorFormContainer>
      </Grid>
    </Grid>
  );
};

export default StartCompetitiveDevelopment;
