import { css } from '@emotion/react/macro';
import colors from 'styles/colors.module.scss';

const baseContainer = css`
  display: flex;
  align-items: center;
  padding: 11px;
  background-color: ${colors.white};
  min-height: 68px;
  cursor: pointer;
`;

/*
	@protected
 */
export const sharedStyle = {
  baseContainer,
};
