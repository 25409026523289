import React, { useState, useEffect } from 'react';
import useDebounce from 'components/useDebounce';
import QuickSearchBar from 'components/QuickSearchBar/QuickSearchBar';
import { useProductsSheetsContext } from 'modules/pim/baseProducts/BaseProducts';
import { ProductsFiltersActionCreator } from 'modules/pim/baseProducts/reducers/productsActions';

interface PimSearchBarProps {
  placeholder?: string;
  bgcolor?: string;
  hasBorder?: boolean;
  inputBaseWidth?: string;
}

const PimSearchBar = ({
  placeholder,
  bgcolor = 'transparent',
  hasBorder = true,
  inputBaseWidth = '220px',
}: PimSearchBarProps): JSX.Element => {
  const { dispatch } = useProductsSheetsContext();

  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && dispatch) {
      dispatch(ProductsFiltersActionCreator.updateSearch(debouncedSearchTerm));
    }
  }, [debouncedSearchTerm, dispatch]);

  const onChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearchTerm(event.target.value);
  };

  return (
    <QuickSearchBar
      placeholder={placeholder}
      onChange={onChange}
      value={searchTerm}
      inputBaseWidth={inputBaseWidth}
      bgcolor={bgcolor}
      hasBorder={hasBorder}
    />
  );
};

export default PimSearchBar;
