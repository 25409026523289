import React from 'react';
import { Box } from '@material-ui/core';
import Asset from 'modules/workflow/containers/Leftsidebar/AssetsPanel/Asset';
import { useStudioStateContext } from 'modules/workflow/oneStudioContext';
import { ZoneContentProps } from 'modules/workflow/containers/CatalogPreview/ZoneContent';
import ProductTemplate from 'modules/workflow/Clients/ToutFaire/ProductTemplate';

const ZoneTemplate = ({
  pageNumber,
  zoneIndex,
  zoomScale,
}: ZoneContentProps): JSX.Element => {
  const globalState = useStudioStateContext();

  let product =
    globalState.version.pages[pageNumber - 1].pageTemplate[zoneIndex].product ||
    undefined;
  const zoneAssets =
    globalState.version.pages[pageNumber - 1].pageTemplate[zoneIndex].assets;

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      {zoneAssets && (
        <Box width="100%" height="100%" position="absolute">
          {zoneAssets.map((asset, assetIndex) => {
            return (
              <Asset
                key={assetIndex}
                pageNumber={pageNumber - 1}
                zoneIndex={zoneIndex}
                assetIndex={assetIndex}
                asset={asset}
              />
            );
          })}
        </Box>
      )}
      {!!product?.length && (
        <ProductTemplate
          isDefaultProduct={false}
          productFromPlatform={product}
          pageIndex={pageNumber - 1}
          zoneIndex={zoneIndex}
        />
      )}
    </Box>
  );
};

export default ZoneTemplate;
