import { GetBasketDocuments_basket_basketDocuments } from 'app/schemaInterfaces/GetBasketDocuments';
import { GetClientShippingAddress_entityDeliveryAdresses_address } from 'app/schemaInterfaces/GetClientShippingAddress';
import { GetEntityBillingAdresses_entityBillingAdresses } from 'app/schemaInterfaces/GetEntityBillingAdresses';
import { BasketType } from 'app/schemaInterfaces/globalTypes';
import { StoreResources_storeResources } from 'app/schemaInterfaces/StoreResources';
import { Action, action } from 'easy-peasy';
import { Moment } from 'moment';

interface AddressInput {
  address: GetClientShippingAddress_entityDeliveryAdresses_address;
  documentId: string;
  index: number;
}

interface AddressBlockInput {
  addressBlockId: string;
  documentId: string;
  index: number;
}

interface QuantityInput {
  price: number | null;
  productName: string | null;
  quantity: number;
  documentId: string;
  index: number;
  isValid: boolean;
  isIncluded?: boolean;
}

interface CommentInput {
  documentId: string;
  comment: string;
}

interface DeliveryDateInput {
  documentId: string;
  deliveryDate?: Moment | null;
}

interface DeleteAddressInput {
  documentId: string;
  index: number;
}

interface AddAddressRowInput {
  documentId: string;
  address?: GetClientShippingAddress_entityDeliveryAdresses_address | null;
}

export interface SelectedBasketDocumentAddress {
  address?: GetClientShippingAddress_entityDeliveryAdresses_address;
  quantity: number;
  price: number | null;
  productName: string | null;
  isValid: boolean;
  addressBlockId?: string;
  isIncluded?: boolean;
}
export interface SelectedBasketdocument {
  basketDocument: GetBasketDocuments_basket_basketDocuments;
  deliveryDate?: Moment | null;
  comment?: string;
  addresses: SelectedBasketDocumentAddress[];
}

export interface SalesModuleModel {
  basketdocuments: {
    selectedBasketdocuments: SelectedBasketdocument[];
    setSelectedBasketDocuments: Action<
      SalesModuleModel['basketdocuments'],
      SelectedBasketdocument[]
    >;
    setAddressToDocument: Action<
      SalesModuleModel['basketdocuments'],
      AddressInput
    >;
    setAddressBlockToDocument: Action<
      SalesModuleModel['basketdocuments'],
      AddressBlockInput
    >;
    setQuantityToDocument: Action<
      SalesModuleModel['basketdocuments'],
      QuantityInput
    >;
    deleteAddressFromDocument: Action<
      SalesModuleModel['basketdocuments'],
      DeleteAddressInput
    >;
    addAddressRow: Action<
      SalesModuleModel['basketdocuments'],
      AddAddressRowInput
    >;
    setCommentToDocument: Action<
      SalesModuleModel['basketdocuments'],
      CommentInput
    >;
    setDeliveryDateToDocument: Action<
      SalesModuleModel['basketdocuments'],
      DeliveryDateInput
    >;
    setResources: Action<
      SalesModuleModel['basketdocuments'],
      StoreResources_storeResources
    >;
  };
  step: {
    activeStep: number;
    setActiveStep: Action<SalesModuleModel['step'], number>;
  };
  basket: {
    basketType: BasketType;
    setBasketType: Action<SalesModuleModel['basket'], BasketType>;
  };
  billingAddress: {
    billingAddress?: GetEntityBillingAdresses_entityBillingAdresses;
    setBillingAddress: Action<
      SalesModuleModel['billingAddress'],
      GetEntityBillingAdresses_entityBillingAdresses | undefined
    >;
  };
  campaignName: {
    name?: string;
    setName: Action<SalesModuleModel['campaignName'], string | undefined>;
  };
  basketStatus: {
    isBasketPending: boolean;
    setIsBasketPending: Action<SalesModuleModel['basketStatus'], boolean>;
  };
}

export const salesModule: SalesModuleModel = {
  basketdocuments: {
    selectedBasketdocuments: [],
    setSelectedBasketDocuments: action((state, payload) => {
      state.selectedBasketdocuments = payload;
    }),
    setAddressToDocument: action((state, payload) => {
      const basketIndex = state.selectedBasketdocuments.findIndex(
        (basketDocument) =>
          basketDocument.basketDocument.document.id === payload.documentId
      );
      state.selectedBasketdocuments[basketIndex].addresses[
        payload.index
      ].address = payload.address;
    }),
    setAddressBlockToDocument: action((state, payload) => {
      const basketIndex = state.selectedBasketdocuments.findIndex(
        (basketDocument) =>
          basketDocument.basketDocument.document.id === payload.documentId
      );
      state.selectedBasketdocuments[basketIndex].addresses[
        payload.index
      ].addressBlockId = payload.addressBlockId;
    }),
    setQuantityToDocument: action((state, payload) => {
      const basketIndex = state.selectedBasketdocuments.findIndex(
        (basketDocument) =>
          basketDocument.basketDocument.document.id === payload.documentId
      );
      state.selectedBasketdocuments[basketIndex].addresses[payload.index] = {
        ...state.selectedBasketdocuments[basketIndex].addresses[payload.index],
        quantity: payload.quantity,
        price: payload.price,
        productName: payload.productName,
        isValid: payload.isValid,
        isIncluded: payload.isIncluded,
      };
    }),

    deleteAddressFromDocument: action((state, payload) => {
      const basketIndex = state.selectedBasketdocuments.findIndex(
        (basketDocument) =>
          basketDocument.basketDocument.document.id === payload.documentId
      );
      state.selectedBasketdocuments[basketIndex].addresses.splice(
        payload.index,
        1
      );
    }),
    addAddressRow: action((state, payload) => {
      const basketIndex = state.selectedBasketdocuments.findIndex(
        (basketDocument) =>
          basketDocument.basketDocument.document.id === payload.documentId
      );

      const quantity =
        state.selectedBasketdocuments[basketIndex].basketDocument.document
          .priceInformations?.quantityMinimum ?? 0;

      const price =
        state.selectedBasketdocuments[basketIndex].basketDocument.price ?? 0;

      const productName =
        state.selectedBasketdocuments[basketIndex].basketDocument.productName;

      const isIncluded =
        state.selectedBasketdocuments[basketIndex].basketDocument.document
          .priceInformations?.isIncluded ?? false;

      const isValid = !!price || (price === 0 && isIncluded);

      const newInitialInput = {
        address: payload.address ?? undefined,
        quantity,
        price,
        productName,
        isValid,
        addressBlock: undefined,
        isIncluded,
      };
      state.selectedBasketdocuments[basketIndex].addresses.push(
        newInitialInput
      );
    }),
    setCommentToDocument: action((state, payload) => {
      const basketIndex = state.selectedBasketdocuments.findIndex(
        (basketDocument) =>
          basketDocument.basketDocument.document.id === payload.documentId
      );
      state.selectedBasketdocuments[basketIndex].comment = payload.comment;
    }),
    setDeliveryDateToDocument: action((state, payload) => {
      const basketIndex = state.selectedBasketdocuments.findIndex(
        (basketDocument) =>
          basketDocument.basketDocument.document.id === payload.documentId
      );
      state.selectedBasketdocuments[basketIndex].deliveryDate =
        payload.deliveryDate;
    }),
    setResources: action((state, payload) => {
      const basketIndex = state.selectedBasketdocuments.findIndex(
        (basketDocument) =>
          basketDocument.basketDocument.document.id === payload.documentId
      );
      state.selectedBasketdocuments[
        basketIndex
      ].basketDocument.document.resources = payload.resources;
    }),
  },

  step: {
    activeStep: 1,
    setActiveStep: action((state, payload) => {
      state.activeStep = payload;
    }),
  },
  basket: {
    basketType: BasketType.Commitment,
    setBasketType: action((state, payload) => {
      state.basketType = payload;
    }),
  },
  billingAddress: {
    billingAddress: undefined,
    setBillingAddress: action((state, payload) => {
      state.billingAddress = payload;
    }),
  },
  campaignName: {
    name: undefined,
    setName: action((state, payload) => {
      state.name = payload;
    }),
  },
  basketStatus: {
    isBasketPending: false,
    setIsBasketPending: action((state, payload) => {
      state.isBasketPending = payload;
    }),
  },
};
