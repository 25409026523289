import { css } from '@emotion/react';
import { CardActionArea, CardContent, Typography } from '@material-ui/core';
import { GetAllCampaigns_getAllCampaigns_campaigns } from 'app/schemaInterfaces/GetAllCampaigns';
import { isDocumentAvailable } from 'modules/pro/campaigns/campaignsHelper';
import { getLocalCampaignLink } from 'modules/shop/shopHelpers';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import colors from 'styles/colors.module.scss';

const style = (img: string) => css`
  height: 200px;
  border-radius: 15px;
  color: ${colors.white};
  background-image: linear-gradient(
      178.46deg,
      rgba(0, 0, 0, 0) 49.8%,
      ${colors.black} 113.41%
    ),
    url(${img});
  background-position: center;
  background-repeat: no-repeat;
  object-fit: cover;
  filter: drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.05));
  display: flex;
  text-decoration: none;

  h2 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-top: 100px;
  }
`;

const LocalCampaignCard = ({
  campaign,
}: {
  campaign: GetAllCampaigns_getAllCampaigns_campaigns;
}) => {
  const { t } = useTranslation();
  const img =
    campaign.imageUrl ?? `/${process.env.REACT_APP_ASSETS}/no_operation.png`;
  return (
    <Link
      css={style(img)}
      to={getLocalCampaignLink(campaign.id)}
      target="_self"
    >
      <CardActionArea>
        <CardContent className="card-body">
          <h2>{campaign.name}</h2>
          <Typography variant="body2">
            {t('sales.basket_page.medium_count', {
              count: campaign.documents?.filter((document) =>
                isDocumentAvailable(document)
              ).length,
            })}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Link>
  );
};

export default LocalCampaignCard;
