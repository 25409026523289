import { gql } from '@apollo/client';

export const VERSION_DETAIL = gql`
  fragment VersionDetail on Version {
    id
    campaignId
    createdAt
    updatedAt
    pages {
      id
      pageTemplate
      incompleteProducts
      unseenComments
      validated
      isCustomizable
      thumbnailUrl
      backgroundColor {
        id
        key
        image
        thumbnailUrl
        name
      }
    }
    digitalContent {
      message
      url
    }
    template {
      type
      metadata {
        bigone {
          _
        }
        chili {
          templateId
        }
      }
    }
  }
`;

export const GET_VERSION_QUERY = gql`
  query GetVersionQuery($documentId: String!) {
    getVersion(documentId: $documentId) {
      ...VersionDetail
    }
  }
  ${VERSION_DETAIL}
`;

export const GET_VERSION_PAGES_QUERY = gql`
  query GetVersionPagesQuery($documentId: String!) {
    getVersionPages: getVersion(documentId: $documentId) {
      pages {
        thumbnailUrl
      }
    }
  }
`;

export const UPDATE_CATALOG_MUTATION = gql`
  mutation UpdateVersionMutation(
    $id: String!
    $pages: [VersionPageInput!]!
    $entityId: String
    $digitalContent: DigitalContentInput
  ) {
    updateVersionPages(
      id: $id
      pages: $pages
      digitalContent: $digitalContent
      entityId: $entityId
    ) {
      ...VersionDetail
    }
  }
  ${VERSION_DETAIL}
`;

export const CREATE_DOCUMENT_THUMBNAIL_UPLOAD_URL = gql`
  mutation CreateDocumentThumbnailUploadUrl($mimetype: String!) {
    versionThumbnailUploadUrl: createDocumentThumbnailUploadUrl(
      mimetype: $mimetype
    ) {
      id
      url
    }
  }
`;

export const DOWNLOAD_JPG = gql`
  mutation DownloadJpg($versionId: String!, $entityId: String!) {
    downloadJpg(versionId: $versionId, entityId: $entityId) {
      url
      mimetype
      name
    }
  }
`;
