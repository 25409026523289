import {
  DocumentAccessor,
  DocumentI18nKeyErrorDictionary,
  documentsColumns,
} from '../DocumentsHelper';
import {
  DocumentFilterInput,
  DocumentOrigin,
} from 'app/schemaInterfaces/globalTypes';
import React, { useCallback, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { DocumentAndOtherFields } from './DocumentsList';
import { DocumentCopyFormValues } from '../documentHelper';
import { DocumentToOpen } from 'modules/pro/campaigns/campaignsHelper';
import { Row } from 'react-table';
import useCurrentEntity from 'app/current-entity-context/useCurrentEntity';
import useDocumentMutation from '../useDocumentMutation';
import useDocumentsQuery from '../useDocumentsQuery';
import { useInfiniteScroll } from 'components/infinite-scroll/useInfiniteScroll';
import { useMutationErrorWorkflow } from 'app/utils/customHooks/useMutationErrorWorkflow';
import { useTranslation } from 'react-i18next';

export const useDocumentsList = () => {
  const { id: campaignId } = useParams<{ id: string }>();
  const history = useHistory();
  const { t } = useTranslation();
  const { currentEntity } = useCurrentEntity();
  const [isDuplicateDialogOpen, setIsDuplicateDialogOpen] = useState<boolean>(
    false
  );
  const [selectedDocumentId, setSelectedDocumentId] = useState<
    string | undefined
  >(undefined);

  const [filter, setFilter] = useState<DocumentFilterInput>({
    campaignIds: [campaignId],
    entityId: currentEntity?.id,
    isArchived: false,
    isCustomization: false,
    showObsolete: true,
  });

  const [documentToOpen, setDocumentToOpen] = useState<DocumentToOpen | null>(
    null
  );

  const {
    documents,
    mediumTypes,
    numbersOfPages,
    orderTypes,
    documentsRefetch,
    documentsLoading,
    fetchMore,
    documentsComplete,
  } = useDocumentsQuery(
    {
      ...filter,
    },
    10
  );

  const {
    copyDocument,
    copyDocumentError,
    copyDocumentLoading,
    deleteDocuments,
  } = useDocumentMutation();

  const deleteDocumentsWithErrorWorkflow = useMutationErrorWorkflow({
    request: deleteDocuments,
    refetch: documentsRefetch,
    i18nKeyErrorDictionary: DocumentI18nKeyErrorDictionary,
    i18nKeyConfirmationMessage: 'common.actions.delete_success',
  });

  const closeDuplicateDialog = () => {
    setIsDuplicateDialogOpen(false);
  };

  const handleDuplicateMenuClick = (row: Row<DocumentAndOtherFields>) => {
    setIsDuplicateDialogOpen(true);
    setSelectedDocumentId(row.original['id']);
  };

  const openDocumentForm = (row: Row<DocumentAndOtherFields>) => {
    history.push(`/campaign/${campaignId}/document/edit/${row.original['id']}`);
  };
  const columns = useMemo(() => documentsColumns(t), [t]);

  const getRowId = React.useCallback(
    (row: DocumentAndOtherFields) => row.id as string,
    []
  );

  const visibleColumns = columns.filter(
    (column) => column.accessor !== DocumentAccessor.DocumentId
  );

  const handleDocumentDeleteAction = async (
    row: Row<DocumentAndOtherFields>
  ) => {
    const documentToDelete = documents?.find(
      (document) => document.id === row.original['id']
    );
    let documentIds: string[] = [documentToDelete?.id ?? ''];
    await deleteDocumentsWithErrorWorkflow({ documentIds });
  };

  const handleDocumentDuplicateAction = async (
    values: DocumentCopyFormValues
  ) => {
    const selectedDocument = documents?.find(
      (document) => document.id === selectedDocumentId
    );
    const entityIds = values.entities.map((entity) => entity.id);

    if (selectedDocument) {
      try {
        const { data } = await copyDocument?.({
          variables: {
            documentId: selectedDocument.id,
            values: { version: values.name, entityIds },
            origin: DocumentOrigin.Duplication,
          },
        });
        if (data?.copyDocument.id) {
          documentsRefetch({
            filter,
            pagination: {
              offset: 0,
              limit: documents ? documents?.length + 1 : 10,
            },
          });
        }
      } catch (error) {
        console.log(error);
      }

      closeDuplicateDialog();
    }
  };

  const handleOpenVisualize = (documentToOpen: DocumentToOpen) => {
    setDocumentToOpen(documentToOpen);
  };

  const loadMoreDocuments = useCallback(() => {
    if (!documentsLoading && !documentsComplete) {
      fetchMore({
        variables: {
          pagination: {
            offset: documents?.length || 0,
            limit: 10,
          },
        },
        updateQuery: (prevData, { fetchMoreResult }) => {
          return {
            documents: {
              ...prevData.documents,
              documents: [
                ...prevData.documents.documents,
                ...(fetchMoreResult?.documents.documents || []),
              ],
            },
          };
        },
      });
    }
  }, [fetchMore, documentsLoading, documents, documentsComplete]);

  const waypointRef = useInfiniteScroll(
    loadMoreDocuments,
    documentsLoading,
    documentsComplete
  );

  const pathname = history.location.pathname;
  return {
    isDuplicateDialogOpen,
    setFilter,
    documentToOpen,
    mediumTypes,
    numbersOfPages,
    orderTypes,
    handleDuplicateMenuClick,
    openDocumentForm,
    handleDocumentDeleteAction,
    handleOpenVisualize,
    waypointRef,
    campaignId,
    filter,
    documents,
    documentsLoading,
    visibleColumns,
    getRowId,
    handleDocumentDuplicateAction,
    closeDuplicateDialog,
    copyDocumentError,
    copyDocumentLoading,
    pathname,
    setDocumentToOpen,
    documentsComplete,
  };
};
