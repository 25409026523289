import { GetBasketDocuments_basket_basketDocuments_document_campaign as BasketDocumentCampaign } from 'app/schemaInterfaces/GetBasketDocuments';
import { ClientError, PriceError } from 'app/schemaInterfaces/globalTypes';
import _ from 'lodash';
import { SelectedBasketdocument } from 'modules/sales/sales.state';
import { BasketDocuments } from '../BasketSteps';

export const groupDocument = (
  selectedBasketDocuments: SelectedBasketdocument[]
): BasketDocuments[] => {
  const groupedDocument = _.groupBy(
    selectedBasketDocuments,
    (item) => item.basketDocument.document.campaign.id
  );
  return Object.keys(groupedDocument).map((key) => {
    const campaign = selectedBasketDocuments?.find(
      (item) => item.basketDocument.document.campaign.id === key
    )?.basketDocument.document.campaign;

    return {
      basketDocuments: groupedDocument[key],
      campaign: campaign as BasketDocumentCampaign,
    };
  });
};

export const BasketI18nKeyErrorDictionary = new Map<string, string>([
  [PriceError.IncorrectPrices, 'shop.errors.incorrect_prices'],
  [PriceError.IncorrectIncludedPrices, 'shop.errors.incorrect_prices'],
  [ClientError.ClientNotFound, 'shop.errors.client_not_found'],
]);
