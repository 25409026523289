import React from 'react';
import { Box, Typography, Tooltip, TooltipProps } from '@material-ui/core';
interface TagProps {
  titleColor: string | undefined;
  title: string;
  backgroundColor: string | undefined;
  isOverFlowEllipsis?: boolean;
  placement?: TooltipProps['placement'];
}
const overflowEllipsisStyle = (isOverFlowEllipsis: boolean) =>
  isOverFlowEllipsis && {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  };

const Tag = ({
  title,
  backgroundColor,
  titleColor,
  isOverFlowEllipsis = false,
  placement,
}: TagProps): JSX.Element => (
  <Box
    bgcolor={backgroundColor}
    borderRadius="4px"
    px={2}
    textAlign="center"
    whiteSpace="nowrap"
  >
    <Tooltip placement={placement} title={title}>
      <Typography
        variant="h6"
        style={{
          color: titleColor,
          ...overflowEllipsisStyle(isOverFlowEllipsis),
        }}
      >
        {title}
      </Typography>
    </Tooltip>
  </Box>
);
export default Tag;
