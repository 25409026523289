import { css } from '@emotion/react';
import colors from '../../../../../styles/colors.module.scss';

export default css`
  .justified {
    display: flex;
    justify-content: center;
  }
  .commitments {
    .campaign-header {
      background-color: ${colors.workzone};
      border: 1px solid ${colors.buttonGroupOutlined};
      border-left: none;
      border-right: none;
      padding: 29px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .campaign {
        display: flex;
        flex-direction: row;
        align-items: center;

        .tag {
          max-width: 93px;
        }
      }
    }

    h3 {
      display: inline-block;

      & + span {
        color: ${colors.grey};
        border-left: 1px solid ${colors.inputLabelColor};
        font-family: 'ScandiaWebMedium';
        font-weight: 500;
        margin-left: 16px;
        padding: 0 16px;
      }
    }
    h5 {
      font-weight: 500;
    }

    .Mui-disabled .actions .MuiIconButton-root {
      color: ${colors.black};
      &:hover {
        background-color: rgba(0, 0, 0, 0.08);
      }
    }
  }

  .MuiListItem-root {
    display: inline-block;
    padding: 0;
    border: solid transparent 5px;
  }
  .MuiListItem-gutters {
    padding-left: 0;
  }
  .selectedCampaign {
    border: solid ${colors.buttonDisabled} 5px;
    border-radius: 5px;
    .campaign-header {
      background-color: ${colors.menuHover};
    }
  }

  .Mui-disabled .delete-button {
    color: ${colors.black};
    &:hover {
      background-color: rgba(0, 0, 0, 0.08);
    }
  }
`;
