import { Box, Tooltip } from '@material-ui/core';
import React from 'react';
import { isAssetDisplayable } from 'modules/dam/helpers';
import { useStudioDispatchContext } from 'modules/workflow/oneStudioContext';
import { studioAppActions } from 'modules/workflow/reducers/actionsInterfaces';
import { GetAssets_getAssets_assets as Asset } from 'app/schemaInterfaces/GetAssets';
import { DraggedItemTypes } from 'modules/workflow/containers/CatalogPreview/EditPlatformDefinitions';
import { DEFAULT_IMAGE_PREVIEW } from 'app/utils/common';
import { setDraggedAsset } from 'modules/workflow/containers/Leftsidebar/AssetsPanel/assetHelper';

interface AssetThumbnailProps {
  asset: Asset;
}

export const AssetPanelThumbnail = ({
  asset,
}: AssetThumbnailProps): JSX.Element => {
  const dispatch = useStudioDispatchContext();

  const isImageSupported = isAssetDisplayable(asset.metadata.mimetype);
  const imageUrl =
    (isImageSupported && asset.displayedThumbUrl) || DEFAULT_IMAGE_PREVIEW;

  const handleDrag = () => {
    const newAsset = setDraggedAsset(
      asset.id,
      asset.name,
      imageUrl,
      asset.assetType,
      {
        height: asset.metadata.dimensions?.height,
        width: asset.metadata.dimensions?.width,
      }
    );

    dispatch(
      studioAppActions.setDraggedAsset({
        draggedItem: {
          item: newAsset,
          type: DraggedItemTypes.ASSET,
          subType: asset.assetType ?? '',
        },
      })
    );
  };

  return (
    <Tooltip title={asset.name ?? asset.id} placement="right" arrow>
      <Box>
        <img
          draggable
          onDragStart={handleDrag}
          src={imageUrl}
          width={90}
          height={90}
          style={{ objectFit: 'contain', cursor: 'pointer' }}
          alt={asset.name ?? asset.id}
        />
      </Box>
    </Tooltip>
  );
};
