import { displayedThumbUrl } from 'modules/dam/cache/localFields/asset/displayedThumbUrl';

export const asset = {
  Asset: {
    fields: {
      tags: {
        merge: false,
      },
      entities: {
        merge: false,
      },
      displayedThumbUrl,
    },
  },
};
