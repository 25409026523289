import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const requiredStyle = css`
  color: ${colors.error};
`;

export const fieldErrorStyle = css`
  color: ${colors.error};
  margin-top: 8px;
`;

export const labelStyle = css`
  margin-top: 16px;
  margin-bottom: 8px;
  display: block;
`;

export const headerStyle = css`
  margin-bottom: 4px;
`;
