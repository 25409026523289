import colors from 'styles/colors.module.scss';
import { css } from '@emotion/react';

export const ReversePlanningStyle = (isHomePage: boolean) => css`
  .content {
    border: ${isHomePage ? `1px solid ${colors.buttonGroupOutlined}` : 'none'};
    .react-calendar-timeline {
      border-radius: 4px;
      .rct-outer {
        background-color: ${colors.white};
        max-height: 400px;
        overflow-y: auto;
      }

      .rct-calendar-header {
        border: none;
        background-color: ${colors.white};
      }

      .rct-vertical-lines {
        display: none;
      }

      .rct-sidebar .rct-sidebar-row {
        &.rct-sidebar-row-even,
        &.rct-sidebar-row-odd {
          background-color: ${colors.white};
          border-bottom: 1px solid ${colors.reversePlanningBorderEvent};
          display: flex;
          align-items: center;
        }
      }

      .rct-horizontal-lines {
        .rct-hl-even,
        .rct-hl-odd {
          background-color: ${colors.white};
          border-bottom: 1px solid ${isHomePage ? 'transparent' : colors.white};
        }

        .rct-hl-even:hover,
        .rct-hl-odd:hover {
          background-color: ${colors.documentsHeaderBackground};
        }
      }

      .rct-sidebar .rct-sidebar-row {
        &.rct-sidebar-row-odd:hover,
        &.rct-sidebar-row-even:hover {
          background-color: ${colors.documentsHeaderBackground};
        }
      }

      .rct-items .rct-item {
        height: 32px !important;
        margin-top: 5px;
      }

      .rct-items .rct-item :active {
        background-color: ${colors.white} !important;
      }

      .rct-sidebar {
        border-right: 3px solid ${colors.retroplanningSideBarRightBorder} !important;
      }

      .rct-scroll {
        overflow: hidden;
      }
    }
  }

  .groupBox {
    .addTasksIcon {
      visibility: hidden;
    }

    &:hover .addTasksIcon {
      visibility: visible;
    }
  }
`;

export const legendStyle = css`
  display: flex;
  justify-content: space-between;
  padding: 15px 15px 15px 30px;
  width: 300px;
  .badge {
    display: flex;
    align-items: center;
    .circle {
      .MuiBadge-dot {
        width: 9px;
        height: 9px;
      }
    }
    p {
      padding-left: 10px;
      font-family: ScandiaWebRegular;
      font-weight: 400;
      font-size: 10px;
      line-height: 180%;
      letter-spacing: -0.02em;
    }
  }
  .local-badge {
    .circle {
      .MuiBadge-dot {
        background-color: ${colors.planningLocalEvents};
      }
    }
  }
  .national-badge {
    .circle {
      .MuiBadge-dot {
        background-color: ${colors.planningNationalEvents};
      }
    }
  }
`;
