import { useQuery, WatchQueryFetchPolicy } from '@apollo/client';
import { GET_DOCUMENTS_TO_SHOP } from 'app/graphql/queries/documents';
import {
  GetDocumentsToShop,
  GetDocumentsToShopVariables,
} from 'app/schemaInterfaces/GetDocumentsToShop';
import {
  DocumentFilterInput,
  DocumentSortInput,
  PaginationInput,
} from 'app/schemaInterfaces/globalTypes';

export interface DocumentsQueryProps {
  filter: DocumentFilterInput;
  fetchPolicy?: WatchQueryFetchPolicy;
  sort?: DocumentSortInput;
  pagination?: PaginationInput;
}
const useDocumentsToShopQuery = ({
  filter = {},
  fetchPolicy = 'no-cache',
  sort,
  pagination,
}: DocumentsQueryProps) =>
  useQuery<GetDocumentsToShop, GetDocumentsToShopVariables>(
    GET_DOCUMENTS_TO_SHOP,
    {
      fetchPolicy,
      variables: {
        filter,
        sort,
        pagination,
      },
    }
  );

export default useDocumentsToShopQuery;
