import { useQuery } from '@apollo/client';
import {
  Badge,
  Box,
  Button,
  Checkbox,
  IconButton,
  Popover,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { UncontrolledMultipleValueSelectV2 } from 'components/UncontrolledMultipleValueSelectV2';
import {
  getAssetTypeOptions,
  getVisualTypeOptions,
} from 'modules/dam/form/helpers';
import { Immutable } from 'immer';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GET_DIVISIONS } from 'app/graphql/queries/divisions';
import { GET_DIVISIONS as I_GET_DIVISIONS } from 'app/schemaInterfaces/GET_DIVISIONS';
import {
  AssetStatus,
  AssetType,
  TagType,
  VisualType,
} from 'app/schemaInterfaces/globalTypes';
import { useDivisionMultiSelect } from 'components/divisions/useDivisionMultiSelect';
import { UncontrolledDatePicker } from 'components/UncontrolledDatePicker';
import { UncontrolledMultipleValueSelect } from 'components/UncontrolledMultipleValueSelect';
import UncontrolledSimpleAutocomplete from 'components/UncontrolledSimpleAutocompelete';
import UncontrolledTagsAutocomplete from 'components/UncontrolledTagsAutocomplete';
import useTagsQuery from 'queries/useTagsQuery';
import { AssetFiltersActionCreator } from 'modules/dam/reducers/filters/actions';
import { AssetFiltersContext } from 'modules/dam/reducers/filters/context';
import {
  AssetFilters,
  AssetTagFilter,
} from 'modules/dam/reducers/filters/definitions';

interface AssetFiltersPopoverProps {
  filters: Immutable<AssetFilters>;
}

const AssetFiltersPopover = ({
  filters: {
    assetTypes,
    visualTypes,
    tags,
    divisions,
    validFrom,
    validTo,
    permanent,
    status,
  },
}: AssetFiltersPopoverProps): JSX.Element => {
  const { t } = useTranslation();

  const dispatch = useContext(AssetFiltersContext);

  const { tags: assetTags } = useTagsQuery({ type: TagType.Asset });

  const { data } = useQuery<I_GET_DIVISIONS>(GET_DIVISIONS);
  const { multiSelectModel: divisionSelectModel } = useDivisionMultiSelect(
    data
  );

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const disabledStatusFilter = localStorage.getItem(
    'DISABLED_DAM_STATUS_FILTER'
  );

  if (disabledStatusFilter === null) {
    localStorage.setItem('DISABLED_DAM_STATUS_FILTER', 'true');
  }

  const hasActiveFilter =
    assetTypes !== undefined ||
    visualTypes !== undefined ||
    tags !== undefined ||
    Object.keys(divisions).length !== 0 ||
    validFrom !== undefined ||
    validTo !== undefined ||
    permanent !== undefined ||
    (status !== undefined && disabledStatusFilter !== 'true');

  const handleAssetTypeChange = (assetTypes: AssetType[]) => {
    dispatch?.(
      AssetFiltersActionCreator.updateAssetTypes(
        assetTypes.length > 0 ? assetTypes : undefined
      )
    );
  };

  const handleVisualTypeChange = (visualTypes: VisualType[]) => {
    dispatch?.(
      AssetFiltersActionCreator.updateVisualTypes(
        visualTypes.length > 0 ? visualTypes : undefined
      )
    );
  };

  const handleTagsChange = (tags: AssetTagFilter[]) => {
    dispatch?.(
      AssetFiltersActionCreator.updateTags(tags.length > 0 ? tags : undefined)
    );
  };

  const handleDivisionsChange = (key: string, values: string[]) => {
    dispatch?.(
      AssetFiltersActionCreator.updateDivisions({
        key,
        values: values.length > 0 ? values : undefined,
      })
    );
  };

  const handleValidityDateChange = (
    key: 'validFrom' | 'validTo',
    value?: MaterialUiPickersDate
  ) => {
    dispatch?.(
      AssetFiltersActionCreator.updateValidityDates({
        validFrom,
        validTo,
        [key]: value ?? undefined,
      })
    );
  };

  const handleAssetPermanentChange = (checked: boolean) => {
    dispatch?.(AssetFiltersActionCreator.updatePermanent(checked || undefined));
  };

  const handleAssetStatusChange = (values: AssetStatus[]) => {
    dispatch?.(
      AssetFiltersActionCreator.updateStatus(
        values.length > 0 ? values : undefined
      )
    );
  };

  return (
    <Box>
      <Button
        startIcon={
          <Badge variant="dot" color="primary" invisible={!hasActiveFilter}>
            <FilterListOutlinedIcon />
          </Badge>
        }
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <Typography variant="h5">{t('dam.filters.title')}</Typography>
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box display="flex" justifyContent="flex-end">
          <IconButton aria-label="close" onClick={() => setAnchorEl(null)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box py={2} px={3} width={500}>
          <Box display="flex" alignItems="center">
            <Box width="30%">
              <Typography>{t('dam.filters.asset_types')}</Typography>
            </Box>
            <Box width="70%">
              <UncontrolledMultipleValueSelectV2
                value={(assetTypes as AssetType[] | undefined) ?? []}
                margin="dense"
                isFullWidth={true}
                handleChange={({ target: { value } }) =>
                  handleAssetTypeChange(value)
                }
                options={getAssetTypeOptions(t)}
                placeholder={t('dam.filters.asset_types')}
              />
            </Box>
          </Box>
          {assetTypes?.includes(AssetType.Visual) && (
            <Box display="flex" alignItems="center">
              <Box width="30%">
                <Typography>{t('dam.filters.visual_types')}</Typography>
              </Box>
              <Box width="70%">
                <UncontrolledMultipleValueSelectV2
                  value={(visualTypes as AssetType[] | undefined) ?? []}
                  options={getVisualTypeOptions(t)}
                  placeholder={t('dam.filters.visual_types')}
                  margin="dense"
                  isFullWidth={true}
                  handleChange={({ target: { value } }) =>
                    handleVisualTypeChange(value)
                  }
                />
              </Box>
            </Box>
          )}
          <Box display="flex" alignItems="center">
            <Box width="30%">
              <Typography>{t('dam.filters.tags')}</Typography>
            </Box>
            <Box width="70%">
              <UncontrolledTagsAutocomplete<AssetTagFilter>
                value={(tags as AssetTagFilter[]) ?? []}
                options={assetTags ?? []}
                placeholder={t('dam.filters.tags')}
                margin="dense"
                fullWidth
                onChange={(values) => handleTagsChange(values)}
              />
            </Box>
          </Box>
          {divisionSelectModel.map((select) => (
            <Box display="flex" alignItems="center" key={select.key}>
              <Box width="30%">
                <Typography>{select.label}</Typography>
              </Box>
              <Box width="70%">
                <UncontrolledSimpleAutocomplete
                  value={(divisions[select.key] as string[] | undefined) ?? []}
                  options={select.values}
                  placeholder={select.label}
                  margin="dense"
                  fullWidth
                  onChange={(values) =>
                    handleDivisionsChange(select.key, values)
                  }
                />
              </Box>
            </Box>
          ))}
          <Box display="flex" alignItems="center">
            <Box width="30%">
              <Typography>{t('dam.filters.validity_dates')}</Typography>
            </Box>
            <Box width="70%">
              <Box display="flex" justifyContent="space-around">
                <Box pr={1}>
                  <UncontrolledDatePicker
                    placeholder={t('dam.filters.date_from')}
                    margin="normal"
                    value={validFrom ?? null}
                    maxDate={validTo}
                    onChange={(date) =>
                      handleValidityDateChange('validFrom', date)
                    }
                    handleClear={() =>
                      handleValidityDateChange('validFrom', undefined)
                    }
                  />
                </Box>
                <Box>
                  <UncontrolledDatePicker
                    placeholder={t('dam.filters.date_to')}
                    margin="normal"
                    value={validTo ?? null}
                    minDate={validFrom}
                    onChange={(date) =>
                      handleValidityDateChange('validTo', date)
                    }
                    handleClear={() =>
                      handleValidityDateChange('validTo', undefined)
                    }
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Checkbox
              checked={permanent ?? false}
              onChange={(e, checked) => handleAssetPermanentChange(checked)}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <Typography>{t('dam.filters.permanent')}</Typography>
          </Box>
          {disabledStatusFilter !== 'true' && (
            <Box display="flex" alignItems="center">
              <Box width="30%">
                <Typography>{t('dam.filters.status')}</Typography>
              </Box>

              <Box width="70%">
                <UncontrolledMultipleValueSelect
                  value={(status as AssetStatus[] | undefined) ?? []}
                  options={[AssetStatus.Active, AssetStatus.Inactive]}
                  placeholder={t('dam.filters.status')}
                  margin="dense"
                  fullWidth
                  handleChange={({ target: { value } }) =>
                    handleAssetStatusChange(value)
                  }
                />
              </Box>
            </Box>
          )}
          <Box display="flex" py={2} justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                dispatch?.(AssetFiltersActionCreator.clearFilters(undefined));
                setAnchorEl(null);
              }}
            >
              {t('dam.filters.clear_button')}
            </Button>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default AssetFiltersPopover;
