import { useLazyQuery } from '@apollo/client';
import { GET_PRICE_BY_QUANTITY } from 'app/graphql/queries/prices';
import {
  getPriceByQuantity,
  getPriceByQuantityVariables,
} from 'app/schemaInterfaces/getPriceByQuantity';
import { useEffect } from 'react';

const usePriceByQuantity = ({
  productId,
  quantity,
}: {
  productId?: string | null;
  quantity?: number | null;
}) => {
  const [
    getPriceByQuantity,
    { data, loading: priceDataLoading },
  ] = useLazyQuery<getPriceByQuantity, getPriceByQuantityVariables>(
    GET_PRICE_BY_QUANTITY,
    {
      fetchPolicy: 'no-cache',
    }
  );

  useEffect(() => {
    if (productId && quantity) {
      getPriceByQuantity({ variables: { productId, quantity } });
    }
  }, [productId, quantity, getPriceByQuantity]);

  const priceData = data?.getPriceByQuantity;
  return { priceData, priceDataLoading };
};

export default usePriceByQuantity;
