import { Skeleton } from '@material-ui/lab';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPrice } from 'modules/sales/utils/priceFormatting';
import { basketTotalStyle } from './BasketConfirmationTotal.style';

export interface BasketConfirmationTotalProps {
  price?: number | null;
}

const BasketConfirmationTotal: FC<BasketConfirmationTotalProps> = ({
  price,
}) => {
  const { t } = useTranslation();

  return (
    <dl css={basketTotalStyle}>
      <dt>{t('sales.basket_page.delivery_page.delivery_addresses.total')}</dt>
      <dd>
        {typeof price === 'number' ? (
          formatPrice(price, t)
        ) : (
          <Skeleton width="8ch" />
        )}
      </dd>
    </dl>
  );
};

export default BasketConfirmationTotal;
