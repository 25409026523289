import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GetAssets_getAssets_assets as Asset } from 'app/schemaInterfaces/GetAssets';
import moment from 'moment';
import { useAssetMutate } from 'modules/dam/hooks/useAssetMutate';
import { useMutation } from '@apollo/client';
import {
  DeleteManyAssetsSafely,
  DeleteManyAssetsSafelyVariables,
} from 'app/schemaInterfaces/DeleteManyAssetsSafely';
import { DELETE_MANY_ASSETS_SAFELY } from 'app/graphql/queries/dam';
import { useSnackbar } from 'notistack';
import { useDownloadFileErrorWorkflow } from 'app/utils/customHooks/useDownloadFileErrorWorkflow';

export interface UseSelectedAssetsToolbarParams {
  assets: Asset[];
  refetch: () => void;
}

export function useSelectedAssetsToolbar({
  assets,
  refetch,
}: UseSelectedAssetsToolbarParams) {
  const { t } = useTranslation();
  const downloadFile = useDownloadFileErrorWorkflow({
    useS3CorsWorkaround: true,
  });
  const { createZipDownloadUrl } = useAssetMutate();

  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  function downloadAssetFile(url: string, filename: string, mimetype: string) {
    downloadFile({ url, filename, mimetype }).finally(() =>
      setIsDownloading(false)
    );
  }

  async function downloadMultipleAssets() {
    const assetIds = assets.map(({ id }) => id);
    const zippedAssetsUrl = await createZipDownloadUrl(assetIds);
    if (!zippedAssetsUrl) {
      setIsDownloading(false);
      throw new Error('Cannot create download url');
    } else {
      const date = moment().format('DD_MM_YYYY');
      downloadAssetFile(
        zippedAssetsUrl,
        `AssetsDAM_${date}`,
        'application/zip'
      );
    }
  }

  async function handleSelectedAssetsDownload(
    event: React.MouseEvent<HTMLButtonElement>
  ) {
    event.preventDefault();
    setIsDownloading(true);
    if (assets.length === 1 && assets[0].url) {
      downloadAssetFile(
        assets[0].url,
        assets[0].name ?? 'file',
        assets[0].metadata.mimetype
      );
    } else {
      downloadMultipleAssets();
    }
  }

  const { enqueueSnackbar } = useSnackbar();
  const [deleteManyAssetsSafely, { loading: deleteManyLoading }] = useMutation<
    DeleteManyAssetsSafely,
    DeleteManyAssetsSafelyVariables
  >(DELETE_MANY_ASSETS_SAFELY);

  async function deleteMedias() {
    const { data } = await deleteManyAssetsSafely({
      variables: { ids: assets.map(({ id }) => id) },
    });

    if (!data) {
      enqueueSnackbar(t('common.actions.delete_error'), { variant: 'error' });
      return;
    }

    refetch();

    const nonDeleted = data.deleteManyAssetsSafely
      .filter((it) => it.result !== true)
      .map((it) => assets.find((that) => that.id === it.id)?.name ?? it.id);
    if (nonDeleted.length === 0) {
      enqueueSnackbar(
        t('dam.action_toolbar.deletion_complete', {
          count: data.deleteManyAssetsSafely.length,
        }),
        { variant: 'success' }
      );
    } else if (nonDeleted.length === data.deleteManyAssetsSafely.length) {
      enqueueSnackbar(
        t('dam.action_toolbar.deletion_none', {
          count: data.deleteManyAssetsSafely.length,
        }),
        { variant: 'error' }
      );
    } else {
      enqueueSnackbar(
        `${t('dam.action_toolbar.deletion_partial')} ${nonDeleted.join(', ')}`,
        { variant: 'warning' }
      );
    }
  }

  return {
    isDownloading,
    handleSelectedAssetsDownload,
    deleteManyLoading,
    deleteMedias,
  };
}
