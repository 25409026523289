import React from 'react';
import colors from 'styles/colors.module.scss';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import { useCurrentUser } from 'app/auth/UserContext';
import { UserType } from 'app/schemaInterfaces/globalTypes';
import { ChipSwitch } from 'components/Switch/ChipSwitch';
import {
  useStudioDispatchContext,
  useStudioStateContext,
} from 'modules/workflow/oneStudioContext';
import { studioAppActions } from 'modules/workflow/reducers/actionsInterfaces';
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';

const style = (isUserGlobal: boolean, isCustomizable: boolean) => css`
  height: 40px;
  width: fit-content;
  display: flex;
  align-items: center;
  border-radius: 50px;
  background-color: ${isUserGlobal
    ? colors.white
    : isCustomizable
    ? colors.loader1
    : colors.menuHover};
  padding: 16px;
  .custom-switch-label {
    padding-left: 10px;
    color: ${isCustomizable ? colors.primary : colors.grey};
  }
  .custom-label {
    padding-right: 10px;
    color: ${isCustomizable ? colors.white : colors.documentsTab};
  }
`;

interface CustomizableSwitchProps {
  isCustomizable: boolean;
}
const CustomizableSwitch = ({
  isCustomizable,
}: CustomizableSwitchProps): JSX.Element => {
  const { t } = useTranslation();
  const user = useCurrentUser();
  const isUserGlobal = user.type === UserType.Global;
  const globalState = useStudioStateContext();
  const dispatch = useStudioDispatchContext();

  const switchCustomPageStatus = () =>
    dispatch(
      studioAppActions.setPageCustomization({
        indexPage: globalState.currentPage - 1,
        isCustomizable: !isCustomizable,
      })
    );

  return (
    <div css={style(isUserGlobal, isCustomizable)}>
      {isUserGlobal ? (
        <>
          <ChipSwitch
            checked={isCustomizable}
            onChange={switchCustomPageStatus}
            color="primary"
          />
          <h4 className="custom-switch-label">{t('global.customizable')}</h4>
        </>
      ) : (
        <>
          <h4 className="custom-label">
            {isCustomizable
              ? t('global.customizable')
              : t('global.not_customizable')}
          </h4>
          <FormatColorFillIcon
            htmlColor={isCustomizable ? colors.white : colors.documentsTab}
            fontSize="small"
          />
        </>
      )}
    </div>
  );
};

export default CustomizableSwitch;
