import { Position } from 'modules/workflow/oneStudioContext';
import React from 'react';

export interface ItemSize {
  width: number;
  height: number;
}

/*
Notes:
- an asset is contained in a <div>, and this asset is center horizontally and vertically relatively to this div
- the asset size (width and height), and its position (x,y) depends on the initial scaling of the page, and the zoom scale.
That's why the position and the size of the asset is multiplied by the SCALE_VALUE and by the zoomScale

A. The Problem:

When we drop an asset on the cursor mark (where we clicked),
it's the top left corner of the div containing the asset, which is positioned on the cursor mark (where we clicked)

This difference between the cursor mark position, and the div containing the asset positioned on the page,
is the offset we're trying to correct

B. The Aim:
We want to position the center of the div containing the asset, on the cursor mark (where we click)

C. The Solution:
We have to:

1. Calculate the position of the cursor mark where we click, relative to the page
    - the positionX where we clicked, relative to the page, is represents by :
	const positionXClicked = event.clientX - event.currentTarget.getClientRects()?.item(0)?.x || 0)

    - the positionY where we clicked, relative to the page, is represents by :
	const positionYClicked = event.clientY - event.currentTarget.getClientRects()?.item(0)?.y || 0)

    where event.client represents the position (x,y) at the coordinate (0, 0), relative to the browser
	where event.currentTarget represents the position (x,y) clicked, relative to the browser

	The difference between event.client and event.currentTarget represents the position (x,y) clicked relative to the page

2. Correct the position (x,y) calculated in 1) by the offset:
   - for positionX:  positionXClicked - currentAssetSize.width / 2
   - for positionY : positionYClicked - currentAssetSize.height / 2

   since we are aiming at centering the div containing the asset on the cursor mark, we only take into account
   half of the width and height of this div. That's why we divide the width and the height by 2
*/

export const getPersonalizationItemsSize = (scaleValue: number): ItemSize => ({
  width: 82 / scaleValue,
  height: 82 / scaleValue,
});

export const getZoneRect = (
  event: React.DragEvent<HTMLElement>
): DOMRect | null => {
  return event.currentTarget.getClientRects().item(0);
};

export const getDraggedItemPosition = (
  itemSize: ItemSize,
  event: React.DragEvent<HTMLElement>,
  zoomScale: number,
  scaleValue: number
): Position => {
  const currentAssetSize = {
    width: itemSize.width * scaleValue * zoomScale,
    height: itemSize.height * scaleValue * zoomScale,
  };

  const positionX =
    (event.clientX -
      (event.currentTarget.getClientRects()?.item(0)?.x || 0) -
      currentAssetSize.width / 2) /
    (scaleValue * zoomScale);
  const positionY =
    (event.clientY -
      (event.currentTarget.getClientRects()?.item(0)?.y || 0) -
      currentAssetSize.height / 2) /
    (scaleValue * zoomScale);

  return { x: positionX, y: positionY };
};

export const isPlaceableInTheZone = (
  event: React.DragEvent<HTMLElement>,
  itemSize: ItemSize,
  itemPosition: Position,
  zoomScale: number,
  scaleValue: number
): boolean => {
  // As the wrapper of the Zone has a classname of boundzone-page-zone we can get its boundaries
  // and check if the item will be fully inside the zone
  const zoneElement = getZoneRect(event);

  if (!zoneElement) {
    return false;
  }

  const isOkOnTheLeft: boolean = itemPosition.x > 0;
  const isOkOnTheTop: boolean = itemPosition.y > 0;
  const isOkOnTheRight =
    (itemPosition.x + itemSize.width) * scaleValue * zoomScale <
    zoneElement?.width;
  const isOkOnTheBottom =
    (itemPosition.y + itemSize.height) * scaleValue * zoomScale <
    zoneElement?.height;

  return isOkOnTheLeft && isOkOnTheTop && isOkOnTheRight && isOkOnTheBottom;
};

/**
 * Returns the position and size of the item. given the initial Size.
 * If the initial size can fit into the zone it returns the unchanged initial size and the position of the drop
 * Else (If it cannot fit in the zone) it forces the position to (0, 0) and changes the size of the item to 50% of either the height or the width of the zone
 */
export const getItemZoneSizeAndPosition = (
  event: React.DragEvent<HTMLElement>,
  itemSize: ItemSize,
  zoomScale: number,
  scaleValue: number
): { size: ItemSize; position: Position } => {
  const position = getDraggedItemPosition(
    itemSize,
    event,
    zoomScale,
    scaleValue
  );

  const size = {
    ...itemSize,
  };

  const zoneElement = getZoneRect(event);

  if (!zoneElement) {
    return { size, position };
  }

  if (!isPlaceableInTheZone(event, size, position, zoomScale, scaleValue)) {
    position.x = 0;
    position.y = 0;
    const sizeRatio = size.width / size.height;

    const resizedWidthOnWidth =
      (zoneElement.width / (scaleValue * zoomScale)) * 0.5;
    const resizedHeightOnWidth = resizedWidthOnWidth / sizeRatio;

    const resizedHeightOnHeight =
      (zoneElement.height / (scaleValue * zoomScale)) * 0.5;
    const resizedWidthOnHeight = resizedHeightOnHeight * sizeRatio;

    if (
      resizedWidthOnWidth <= zoneElement.width &&
      resizedHeightOnWidth <= zoneElement.height
    ) {
      size.width = resizedWidthOnWidth;
      size.height = resizedHeightOnWidth;
    } else {
      size.width = resizedWidthOnHeight;
      size.height = resizedHeightOnHeight;
    }
  }

  return { size, position };
};
