import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const style = css`
  padding-right: 60px;
  padding-left: 60px;
  .confirmaton-img {
    margin-top: 70px;
    height: 196px;
    img {
      display: block;
      width: 361px;
      margin: auto;
    }
  }
  h1 {
    text-align: center;
    margin-bottom: 8px;
    margin-top: 34px;
    padding: 0;

    .check {
      font-size: 1.25em;
      color: ${colors.primary};
      vertical-align: sub;
    }
  }
  p {
    text-align: center;
    white-space: pre-line;
  }
  .button {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin-top: 21px;
  }
  .detailsContainer {
    margin-top: 50px;
    margin-bottom: 100px;
    h2 {
      margin-bottom: 8px;
    }
  }
  .prices {
    margin-top: 30px;
  }
`;

export const dlStyle = css`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0px 16px;
  color: ${colors.grey};
  font-weight: 500;
  font-family: ScandiaWebMedium;
  font-size: 0.875rem;
  line-height: 186%;
  margin-top: 25px;

  dd {
    color: ${colors.body};
  }
`;
