import React from 'react';
import { IProductTemplate } from 'modules/workflow/Clients/ToutFaire/ProductItem';
import { TypographyIdentities } from 'modules/workflow/oneStudioContext';
import PriceTagElementContainer from 'modules/workflow/Clients/ToutFaire/components/price/PriceTagElementContainer';
import useAppContext from 'app/app-context/useAppContext';
import { getUnifiedKeyFromPimClientConfig } from 'modules/pim/baseProducts/ProductsHelper';
import { UnifiedProductKeyToRenderOnPage } from 'modules/workflow/containers/Leftsidebar/ProductsPanel/ProductsView';
import { css } from '@emotion/react/macro';

interface ProductPriceProps {
  product: IProductTemplate;
  priceOptionalUnity?: TypographyIdentities;
  priceLabels?: TypographyIdentities;
}

const ProductPriceOptionalUnity = ({
  product,
  priceOptionalUnity,
  priceLabels,
}: ProductPriceProps): JSX.Element => {
  const {
    client: { config: clientConfig },
  } = useAppContext();

  const firstPart =
    priceOptionalUnity?.name &&
    product.productDetails[priceOptionalUnity.name].split(':')[0];
  const price = product.productDetails[
    getUnifiedKeyFromPimClientConfig(
      UnifiedProductKeyToRenderOnPage.optionalunityprice,
      clientConfig
    )
  ]?.replace(/,/g, '.');

  const style = css`
    display: flex;
    justify-content: center;
    align-items: baseline;
    position: relative;
  `;
  return (
    <div css={style}>
      {priceOptionalUnity && (
        <PriceTagElementContainer
          product={product}
          priceTagElement={priceOptionalUnity}
          value={`${firstPart} : ${price ? `${price} €` : ''}`}
          textAlign="center"
        />
      )}
    </div>
  );
};

export default ProductPriceOptionalUnity;
