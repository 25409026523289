import {
  FormGroup,
  FormHelperText,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { ErrorMessage, useField } from 'formik';
import React from 'react';
import { css } from '@emotion/react/macro';
interface Option {
  label: string;
  value: string | number;
  disabled?: boolean;
}

export interface CheckboxesGroupProps {
  options: Option[];
  name: string;
  title?: string;
  onChange: (value: string) => void;
  isDisabled?: boolean;
  className?: string;
}

const style = css`
  .options {
    flex-direction: row;
    display: flex;
  }
  .MuiFormControlLabel-root {
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    display: flex;
  }
  .title {
    display: block;
  }
`;

const DocumentFormSelect = ({
  options,
  name,
  title,
  onChange,
  isDisabled,
  className,
}: CheckboxesGroupProps) => {
  const [, meta] = useField<string>(name);

  const handleChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    onChange(event.target.value as string);
  };

  return (
    <div css={style}>
      <FormGroup>
        <FormLabel className="title">{title} *</FormLabel>
        <Select
          value={meta.value ?? ''}
          onChange={handleChange}
          variant="outlined"
          disabled={isDisabled}
          className={className}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <FormControl error={meta.touched && Boolean(meta.error)}>
          <ErrorMessage name={name} component={FormHelperText} />
        </FormControl>
      </FormGroup>
    </div>
  );
};

export default DocumentFormSelect;
