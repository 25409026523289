import { createStore, createTypedHooks } from 'easy-peasy';
import { salesModule, SalesModuleModel } from 'modules/sales/sales.state';

export interface StoreModel {
  salesModule: SalesModuleModel;
}

const models: StoreModel = {
  salesModule,
};

const store = createStore(models);

const {
  useStoreActions,
  useStoreState,
  useStoreDispatch,
  useStore,
} = createTypedHooks<StoreModel>();

export { useStoreActions, useStoreState, useStoreDispatch, useStore };

export default store;
