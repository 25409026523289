import {
  PersonalizationSubType,
  StudioStates,
  DraggedPersonalizationZone,
  DraggedProduct,
  DraggedAsset,
  DraggedItem,
  PersonalizationItem,
  PersonalizationType,
} from 'modules/workflow/oneStudioContext';
import { StudioContextActionType } from 'modules/workflow/reducers/studioActions';
import {
  getDraggedItemPosition,
  getItemZoneSizeAndPosition,
  ItemSize,
  getPersonalizationItemsSize,
} from 'modules/workflow/containers/CatalogPreview/catalogPreviewHelper';
import {
  studioAppActions,
  AcceptedActions,
} from 'modules/workflow/reducers/actionsInterfaces';
import { UserType, VisualIdentityType } from 'app/schemaInterfaces/globalTypes';
import {
  DraggedItemTypes,
  isItemDraggedProduct,
  defaultComponents,
} from 'modules/workflow/containers/CatalogPreview/EditPlatformDefinitions';
import { v4 as uuidv4 } from 'uuid';
import { IProductTemplate } from 'modules/workflow/Clients/ToutFaire/ProductItem';
import {
  GetEntities_entities,
  GetEntities_entities_addressBlocks,
} from 'app/schemaInterfaces/GetEntities';
import { AssetType } from 'app/schemaInterfaces/globalTypes';
import { isAssetDisplayable } from 'modules/dam/helpers';
import { DEFAULT_IMAGE_PREVIEW, Maybe } from 'app/utils/common';
import { setDraggedAsset } from '../Leftsidebar/AssetsPanel/assetHelper';

const typographyDropHandler = (
  draggedItemSubType: string,
  personalizationItems: PersonalizationItem[] | undefined,
  pageNumberToApply: number,
  zoneIndex: number,
  dispatch: (action: AcceptedActions) => void,
  event: React.DragEvent<HTMLElement>,
  zoomScale: number,
  scaleValue: number,
  userType: UserType
) => {
  /*
		The div containing the [headerTitle/subtitle] is not exactly positioned on the cursor mark.

  	There's a vertical offset because the calculation of the offset was done relatively to the top of the cursor, while it should have been made relatively to its bottom
  	*/
  const personalizationItemsSize = getPersonalizationItemsSize(scaleValue);
  const draggedItemPosition = getDraggedItemPosition(
    personalizationItemsSize,
    event,
    zoomScale,
    scaleValue
  );

  if (draggedItemSubType === PersonalizationSubType.DATE) {
  }
  const defaultHeaderSubZoneTemplate: PersonalizationItem = {
    key: uuidv4(),
    type: PersonalizationType.TYPOGRAPHY,
    subType: draggedItemSubType as PersonalizationSubType,
    position: draggedItemPosition,
    dimensions: personalizationItemsSize,
    userType,
  };

  let newPersoZone = personalizationItems
    ? [...personalizationItems, defaultHeaderSubZoneTemplate]
    : [defaultHeaderSubZoneTemplate];

  dispatch(
    studioAppActions.setPersonalizationItem({
      pageNumber: pageNumberToApply,
      zoneIndex: zoneIndex,

      personalizationItems: newPersoZone,
    })
  );
};

const updateItemTemplate = (
  draggedItemType: PersonalizationSubType,
  event: React.DragEvent<HTMLElement>,
  zoomScale: number,
  scaleValue: number,
  item: PersonalizationItem[] | undefined,
  userType: UserType
): PersonalizationItem[] => {
  const personalizationItemsSize = getPersonalizationItemsSize(scaleValue);

  const draggedItemPosition = getDraggedItemPosition(
    personalizationItemsSize,
    event,
    zoomScale,
    scaleValue
  );

  const defaultSubZoneTemplate: PersonalizationItem = {
    key: uuidv4(),
    type: PersonalizationType.CONTENT,
    subType: draggedItemType,
    position: draggedItemPosition,
    dimensions: personalizationItemsSize,
    userType: userType,
  };

  const newItem: PersonalizationItem[] =
    item === undefined
      ? [defaultSubZoneTemplate]
      : [...item, defaultSubZoneTemplate];

  return newItem;
};

export const updateItemContent = (
  personalizationZone: DraggedPersonalizationZone,
  itemToAdd: DraggedPersonalizationContent['itemToAdd'],
  personalizationItems: PersonalizationItem[]
): PersonalizationItem[] =>
  personalizationItems?.map((personalizationItem) => {
    if (personalizationItem.key === personalizationZone.key) {
      return isItemDraggedProduct(itemToAdd.item)
        ? {
            ...personalizationItem,
            content: {
              ...itemToAdd.item,
              visualIdentities: itemToAdd.item.visualIdentities?.map(
                (visualIdentity) =>
                  visualIdentity.visualIdentityType ===
                    VisualIdentityType.Visual &&
                  visualIdentity.assetType === AssetType.Visual
                    ? {
                        ...visualIdentity,
                        size: {
                          //Resize visual proportionaly to the personalization card
                          width: (personalizationZone.size.width * 72) / 100,
                          height: (personalizationZone.size.height * 72) / 100,
                        },
                      }
                    : visualIdentity
              ),
              size: personalizationZone.size,
              components: defaultComponents,
              position: { x: 0, y: 0 },
            } as IProductTemplate,
          }
        : {
            ...personalizationItem,
            content: {
              ...itemToAdd.item,
              size: personalizationZone.size,
            } as DraggedAsset,
          };
    } else {
      return personalizationItem;
    }
  });

interface DraggedPersonalizationContent {
  personalizationZone: DraggedPersonalizationZone;
  itemToAdd:
    | DraggedItem<DraggedAsset, DraggedItemTypes.ASSET>
    | DraggedItem<DraggedProduct, DraggedItemTypes.PRODUCT>;
}

const contentDropHandler = (
  draggedItemSubType: string,
  personalizationItems: PersonalizationItem[] | undefined,
  pageNumberToApply: number,
  zoneIndex: number,
  dispatch: (action: AcceptedActions) => void,
  event: React.DragEvent<HTMLElement>,
  zoomScale: number,
  scaleValue: number,
  userType: UserType,
  personalizationContent?: DraggedPersonalizationContent
) => {
  const newPersonalizationItems =
    personalizationContent && personalizationItems
      ? updateItemContent(
          personalizationContent.personalizationZone,
          personalizationContent.itemToAdd,
          personalizationItems
        )
      : updateItemTemplate(
          draggedItemSubType as PersonalizationSubType,
          event,
          zoomScale,
          scaleValue,
          personalizationItems,
          userType
        );

  dispatch(
    studioAppActions.setPersonalizationItem({
      pageNumber: pageNumberToApply,
      zoneIndex: zoneIndex,
      personalizationItems: newPersonalizationItems,
    })
  );
};

export const dropHandler = async (
  pageNumberToApply: number,
  zoneIndex: number,
  dispatch: (action: AcceptedActions) => void,
  globalState: StudioStates,
  event: React.DragEvent<HTMLElement>,
  zoomScale: number,
  getProduct: () => Promise<Omit<IProductTemplate, 'components' | 'position'>>,
  sizeProduct: ItemSize,
  userType: UserType,
  personalizationZone?: DraggedPersonalizationZone
) => {
  if (!globalState.draggedItem) {
    return;
  }
  dispatch({ type: StudioContextActionType.UPDATE_UNDO_REDO_HISTORY });
  switch (globalState.draggedItem.type) {
    case 'PAGE_TEMPLATE': {
      dispatch(
        studioAppActions.setPageTemplate({
          pageNumber: pageNumberToApply,
          pageTemplate: globalState.draggedItem.item,
        })
      );
      break;
    }
    case 'BACKGROUND_IMAGE': {
      dispatch(
        studioAppActions.setBackgroundImage({
          pageNumber: pageNumberToApply,

          zoneIndex: zoneIndex,

          background: globalState.draggedItem.item,
        })
      );
      break;
    }
    case 'BACKGROUND_COLOR': {
      dispatch(
        studioAppActions.setBackgroundColor({
          pageNumber: pageNumberToApply,
          backgroundColor: globalState.draggedItem.item,
        })
      );
      break;
    }
    case 'SET_PERSONALIZATION_ITEM_CATEGORY': {
      const personalizationItems =
        globalState.version.pages[pageNumberToApply].pageTemplate[zoneIndex]
          .personalizationItems;
      if (globalState.draggedItem.subType === 'TYPOGRAPHY') {
        typographyDropHandler(
          globalState.draggedItem.item,
          personalizationItems,
          pageNumberToApply,
          zoneIndex,
          dispatch,
          event,
          zoomScale,
          globalState.scaleValue,
          userType
        );
      } else {
        contentDropHandler(
          globalState.draggedItem.item,
          personalizationItems,
          pageNumberToApply,
          zoneIndex,
          dispatch,
          event,
          zoomScale,
          globalState.scaleValue,
          userType
        );
      }
      break;
    }
    case 'PRODUCT': {
      if (
        globalState.version.pages[pageNumberToApply].pageTemplate[zoneIndex]
          .numberOfProducts &&
        globalState.version.pages[pageNumberToApply].pageTemplate[zoneIndex]
          .product?.length &&
        (globalState.version.pages[pageNumberToApply].pageTemplate[zoneIndex]
          .numberOfProducts as number) <=
          (globalState.version.pages[pageNumberToApply].pageTemplate[zoneIndex]
            .product?.length as number)
      ) {
        break;
      }

      //A CHANGER METTRE LA TAILLE DE L'IMAGE

      const draggedItemPosition = getDraggedItemPosition(
        sizeProduct,
        event,
        zoomScale,
        globalState.scaleValue
      );

      const productToAdd = await getProduct();

      if (
        personalizationZone &&
        personalizationZone.subType === PersonalizationSubType.PRODUCT
      ) {
        //The drop Zone is a Personalization Zone : We set the product in the selected Personalization Zone

        const personalizationItems =
          globalState.version.pages[pageNumberToApply].pageTemplate[zoneIndex]
            .personalizationItems;

        const DraggedProduct: DraggedItem<
          DraggedProduct,
          DraggedItemTypes.PRODUCT
        > = {
          item: productToAdd,
          type: DraggedItemTypes.PRODUCT,
        };

        contentDropHandler(
          personalizationZone.subType,
          personalizationItems,
          pageNumberToApply,
          zoneIndex,
          dispatch,
          event,
          zoomScale,
          globalState.scaleValue,
          userType,
          {
            personalizationZone,
            itemToAdd: DraggedProduct,
          }
        );
      } else {
        // the drop Zone isnt a Personalization Zone : We set the product on the page
        dispatch(
          studioAppActions.setProduct({
            pageNumber: pageNumberToApply,
            zoneIndex: zoneIndex,
            product: {
              ...productToAdd,
              position: draggedItemPosition,
              size: sizeProduct,
            },
            scaleValue: globalState.scaleValue,
          })
        );
      }
      break;
    }
    case 'ASSET': {
      const assetSize = {
        width: globalState.draggedItem.item.size.width * globalState.scaleValue,
        height:
          globalState.draggedItem.item.size.height * globalState.scaleValue,
      };

      const { size, position } = getItemZoneSizeAndPosition(
        event,
        assetSize,
        zoomScale,
        globalState.scaleValue
      );

      if (
        personalizationZone &&
        globalState.draggedItem.subType?.toUpperCase() ===
          personalizationZone.subType
      ) {
        const personalizationItems =
          globalState.version.pages[pageNumberToApply].pageTemplate[zoneIndex]
            .personalizationItems;
        contentDropHandler(
          personalizationZone.subType,
          personalizationItems,
          pageNumberToApply,
          zoneIndex,
          dispatch,
          event,
          zoomScale,
          globalState.scaleValue,
          userType,
          {
            personalizationZone,
            itemToAdd: globalState.draggedItem,
          }
        );
      } else {
        dispatch(
          studioAppActions.insertAsset({
            pageNumber: pageNumberToApply,
            zoneIndex: zoneIndex,
            newAsset: { ...globalState.draggedItem.item, size, position },
          })
        );
      }
      break;
    }
  }
};

export function handleOnDropAddressBlock(
  currentEntity: Maybe<GetEntities_entities>,
  globalState: StudioStates,
  dispatch: (action: AcceptedActions) => void
) {
  if (currentEntity?.addressBlocks?.length === 0) return null;
  const addressBlocks = currentEntity?.addressBlocks;

  const filteredAddressBlocks = addressBlocks?.filter(
    (addressBlock: GetEntities_entities_addressBlocks) =>
      addressBlock.supportId === globalState?.document?.supportId
  );

  if (!filteredAddressBlocks || filteredAddressBlocks?.length === 0)
    return null;

  const asset = filteredAddressBlocks![0].asset;
  const isImageSupported = isAssetDisplayable(asset.metadata.mimetype);
  const imageUrl =
    (isImageSupported && asset.thumbnailUrl) || DEFAULT_IMAGE_PREVIEW;

  const name = asset.name ?? asset.id;
  const newAsset = setDraggedAsset(
    asset.id,
    name,
    imageUrl,
    AssetType.AddressBlock,
    {
      height: asset.metadata.dimensions?.height,
      width: asset.metadata.dimensions?.width ?? 0,
    }
  );
  dispatch(
    studioAppActions.setDraggedAsset({
      draggedItem: {
        item: newAsset,
        type: DraggedItemTypes.ASSET,
        subType: AssetType.AddressBlock,
      },
    })
  );

  return newAsset;
}
