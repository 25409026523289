import { gql } from '@apollo/client';

export const typeDefs = gql`
  extend type Metadata {
    fileExtension: String!
  }
  extend type Asset {
    displayedThumbUrl: String!
  }

  # Used to get errors types generation:
  # => Need to be contributed if you add new errors enums
  extend type Query {
    documentError: DocumentError!
    campaignError: CampaignError!
    basketError: BasketError!
    orderError: OrderError!
    entityError: EntityError!
    clientError: ClientError!
    priceError: PriceError!
  }
  query errors {
    documentError
    campaignError
    basketError
    orderError
    entityError
    clientError
    priceError
  }
`;
