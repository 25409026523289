import { getBase64File } from 'app/utils/FileUpload/FileUpload';
import { useDropzone } from 'react-dropzone';
import mime from 'mime-types';
import { useState } from 'react';
import { PreviewFile } from './ControlledFileUpload';
import { FormikTouched } from 'formik';
import { InitialImage } from 'components/FileUploadIcon/FileUploadIcon';

export interface UseControlledFileUpload {
  onDropFormikField: (name: string, acceptedFiles: File[]) => void;
  setTouched: (
    touched: FormikTouched<any>,
    shouldValidate?: boolean | undefined
  ) => void;
  touched: FormikTouched<any>;
  name: string;
  accept?: string | string[];
  setIsLoading?: (isLoading: boolean) => void;
  initialImage?: InitialImage;
}

export const useControlledFileUpload = ({
  onDropFormikField,
  accept,
  setTouched,
  touched,
  name,
  setIsLoading,
  initialImage,
}: UseControlledFileUpload) => {
  const [filesPreview, setFilesPreview] = useState<PreviewFile[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const { getRootProps, getInputProps, open } = useDropzone({
    accept,
    noClick: true,
    noKeyboard: true,
    onDrop: async (acceptedFiles) => {
      setIsLoading && setIsLoading(true);
      const previews = await Promise.all(
        acceptedFiles.map(async (file) => ({
          ...file,
          preview: URL.createObjectURL(file),
          base64: (await getBase64File(file)) as string,
          extension: mime.extension(file.type) || undefined,
        }))
      );

      onDropFormikField(name, acceptedFiles);
      setFilesPreview(previews);
      setFiles(acceptedFiles);
      setTouched({ ...touched, [name]: true });
    },
  });

  const hasNoUploadedImage = files.length === 0 && !initialImage?.url;

  return {
    getRootProps,
    getInputProps,
    hasNoUploadedImage,
    filesPreview,
    open,
    setFilesPreview,
  };
};
