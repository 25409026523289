import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  FormControl,
  FormGroup,
  FormLabel,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { FormValues } from './AddCompetitorForm.hook';
import { SocialNetwork } from '../CompetitorsContainer/CompetitorsContainer.definition';
import { DefaultValues, useFormContext } from 'react-hook-form';
import { addCompetitorFormStyle } from './AddCompetitorForm.style';

const AddCompetitorForm = ({
  defaultValues,
  hasEntityError,
}: {
  defaultValues: DefaultValues<FormValues>;
  hasEntityError: boolean;
}): JSX.Element => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation();
  return (
    <form css={addCompetitorFormStyle} data-testid="add-competitor-form">
      <FormGroup>
        <label htmlFor="socialNetwork">
          {t('competitors.add_competitor_dialog.pick_network')} *
        </label>
        <TextField
          select
          {...register('socialNetwork', { required: true })}
          variant="outlined"
          hiddenLabel
          fullWidth
          size="small"
          error={!!errors['socialNetwork']}
          id="order-workflow-value"
          type="text"
          defaultValue={defaultValues.socialNetwork ?? SocialNetwork.Facebook}
        >
          {[
            {
              label: SocialNetwork.Facebook,
              value: SocialNetwork.Facebook,
            },
          ]?.map((item) => (
            <MenuItem
              key={item.value ?? undefined}
              value={item.value ?? undefined}
            >
              {item.label}
            </MenuItem>
          ))}
        </TextField>
        {errors['socialNetwork']?.message && (
          <Box>
            <FormControl
              error={
                errors['socialNetwork'] && Boolean(errors['socialNetwork'])
              }
              fullWidth
            >
              <FormLabel>{errors['socialNetwork']?.message}</FormLabel>
            </FormControl>
          </Box>
        )}
      </FormGroup>
      <FormGroup>
        <label htmlFor="url">
          {t('competitors.add_competitor_dialog.url')} *
        </label>
        <TextField
          {...register('url', { required: true })}
          variant="outlined"
          hiddenLabel
          fullWidth
          size="small"
          error={!!errors['url']}
          id="order-workflow-value"
          type="url"
          defaultValue={defaultValues.url}
        />
        {errors['url']?.message && (
          <Box>
            <FormControl
              error={errors['url'] && Boolean(errors['url'])}
              fullWidth
            >
              <FormLabel>{errors['url']?.message}</FormLabel>
            </FormControl>
          </Box>
        )}
        {hasEntityError && (
          <Box>
            <FormControl error={hasEntityError} fullWidth>
              <FormLabel>{t('common.generic_error.entity_error')}</FormLabel>
            </FormControl>
          </Box>
        )}
      </FormGroup>
    </form>
  );
};

export default AddCompetitorForm;
