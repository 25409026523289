import React from 'react';
import 'modules/workflow/transformLibraryStyles.css';
import StudioContextProvider from 'modules/workflow/oneStudioContext';
import StudioPlatformSelector from './StudioPlatformSelector';

const Studio = (): JSX.Element => {
  return (
    <StudioContextProvider>
      <StudioPlatformSelector />
    </StudioContextProvider>
  );
};

export default Studio;
