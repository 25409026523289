import { Card, CardMedia, createStyles, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import { DEFAULT_IMAGE_PREVIEW, FileExtension } from 'app/utils/common';
import React, { useMemo } from 'react';
import colors from 'styles/colors.module.scss';
import mime from 'mime-types';
import PdfPreview from 'components/PdfPreview/PdfPreview';

interface AssetPreviewProps {
  file: File;
  base64File: string;
  handleDelete: (filename: string) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: 100,
      height: 100,
      position: 'relative',
    },
    media: {
      width: 100,
      height: 100,
      backgroundSize: 'cover',
    },

    deleteForeverOutlinedIcon: {
      position: 'absolute',
      top: 0,
      right: 0,
      backgroundColor: colors.background,
      color: colors.tooltip,
      '&:hover': {
        backgroundColor: colors.background,
      },
    },
  })
);

const AssetPreview = ({
  file,
  base64File,
  handleDelete,
}: AssetPreviewProps): JSX.Element => {
  const [isHovered, setHover] = React.useState(false);

  const classes = useStyles();

  const ACCEPTED_FORMATS = [
    'image/png',
    'image/jpeg',
    'image/jpg',
    'image/svg+xml',
    'application/pdf',
  ];
  const isAssetDisplayable = ACCEPTED_FORMATS.includes(file.type);

  const url = useMemo(
    () =>
      isAssetDisplayable ? URL.createObjectURL(file) : DEFAULT_IMAGE_PREVIEW,
    [file, isAssetDisplayable]
  );

  const renderPreviewImage = () => {
    const fileExtension = file ? mime.extension(file.type) : undefined;
    if (
      isAssetDisplayable &&
      fileExtension === FileExtension.Pdf &&
      base64File
    ) {
      return <PdfPreview file={base64File as string} />;
    } else {
      return <CardMedia className={classes.media} image={url} />;
    }
  };

  return (
    <Card
      className={classes.root}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {renderPreviewImage()}
      {isHovered && (
        <IconButton
          aria-label="delete"
          color="primary"
          size="small"
          onClick={() => handleDelete(file.name)}
          className={classes.deleteForeverOutlinedIcon}
        >
          <DeleteForeverOutlinedIcon />
        </IconButton>
      )}
    </Card>
  );
};

export default AssetPreview;
