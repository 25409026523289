import 'moment/locale/fr';
import 'app/i18n/i18n';

import * as serviceWorker from './serviceWorker';

import App from 'app/App';
import AppContextProvider from 'app/app-context/AppContextProvider';
import { Auth0Provider } from 'app/auth/AuthContext';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import CurrentEntityProvider from 'app/current-entity-context/CurrentEntityProvider';
import GraphqlProvider from 'app/graphql/GraphqlProvider';
import NotificationsProvider from 'components/NotificationsProvider';
import React from 'react';
import ReactDOM from 'react-dom';
import { UserContextProvider } from 'app/auth/UserContext';

ReactDOM.render(
  <Auth0Provider>
    <NotificationsProvider>
      <GraphqlProvider>
        <UserContextProvider>
          <BrowserRouter>
            <CssBaseline />
            <AppContextProvider>
              <CurrentEntityProvider>
                <App />
              </CurrentEntityProvider>
            </AppContextProvider>
          </BrowserRouter>
        </UserContextProvider>
      </GraphqlProvider>
    </NotificationsProvider>
  </Auth0Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
