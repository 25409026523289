import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const filterOptionsStyle = css`
  width: 510px;
  padding: 28px;
  color: ${colors.body};
  .filter-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    h5 {
      font-weight: 500;
      width: 30%;
    }
    .input {
      width: 70%;
    }
  }
`;
