import React from 'react';
import TableComponent from 'components/TableComponent/TableComponent';
import Loader from 'components/Loader/Loader/Loader';
import { useFollowOrdersPage } from './FollowOrdersPage.hooks';
import { followOrdersPageStyle } from './FollowOrdersPage.style';
import FollowOrdersToolBar from 'modules/followOrders/FollowOrdersToolBar/FollowOrdersToolBar';
import OrderBreadcrumbs from 'modules/followOrders/OrderBreadcrumbs/OrderBreadcrumbs';
import FollowOrderValidationDialog from 'modules/followOrders/components/FollowOrderValidationDialog/FollowOrderValidationDialog';

const FollowOrdersPage = () => {
  const {
    orderDataLoading,
    tableHeadData,
    tableRowData,
    orderPath,
    t,
    filter,
    setFilter,
    documentCategories,
    validation,
  } = useFollowOrdersPage();

  return (
    <div css={followOrdersPageStyle}>
      <div className="breadcrumbs">
        <OrderBreadcrumbs />
      </div>
      <div>
        <FollowOrdersToolBar
          filter={filter}
          setFilter={setFilter}
          documentCategories={documentCategories}
        />
        {orderDataLoading ? (
          <Loader />
        ) : (
          <>
            <TableComponent
              tableHeadData={tableHeadData}
              tableRowData={tableRowData}
              paths={orderPath}
              isOrderList={true}
            />
            {!tableRowData?.length && (
              <p className="error">{t('common.generic_error.not_found')}</p>
            )}
          </>
        )}
      </div>
      <FollowOrderValidationDialog validation={validation} />
    </div>
  );
};

export default FollowOrdersPage;
