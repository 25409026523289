import { useMutation } from '@apollo/client';
import {
  DELETE_DOCUMENTS,
  COPY_DOCUMENT,
  CREATE_DOCUMENT,
  GET_DOCUMENTS_WITH_RELATED,
  UPDATE_DOCUMENT,
} from 'app/graphql/queries/documents';
import {
  DeleteDocuments,
  DeleteDocumentsVariables,
} from 'app/schemaInterfaces/DeleteDocuments';
import {
  CopyDocument,
  CopyDocumentVariables,
} from 'app/schemaInterfaces/CopyDocument';
import {
  CreateDocument,
  CreateDocumentVariables,
} from 'app/schemaInterfaces/CreateDocument';
import { documentFiltersVar } from './reactiveVars';
import {
  UpdateDocument,
  UpdateDocumentVariables,
} from 'app/schemaInterfaces/UpdateDocument';

const useDocumentMutation = () => {
  //TODO: remove refetch
  const mutationOptions = {
    refetchQueries: [
      {
        query: GET_DOCUMENTS_WITH_RELATED,

        variables: documentFiltersVar(),
      },
    ],
  };
  const [
    copyDocument,
    { error: copyDocumentError, loading: copyDocumentLoading },
  ] = useMutation<CopyDocument, CopyDocumentVariables>(
    COPY_DOCUMENT,
    mutationOptions
  );

  const [
    deleteDocuments,
    { error: deleteDocumentsError, loading: deleteDocumentsLoading },
  ] = useMutation<DeleteDocuments, DeleteDocumentsVariables>(
    DELETE_DOCUMENTS,
    mutationOptions
  );

  const [
    createDocument,
    { error: createDocumentError, loading: createDocumentLoading },
  ] = useMutation<CreateDocument, CreateDocumentVariables>(CREATE_DOCUMENT);

  const [
    updateDocument,
    { error: updateDocumentError, loading: updateDocumentLoading },
  ] = useMutation<UpdateDocument, UpdateDocumentVariables>(UPDATE_DOCUMENT);

  return {
    createDocument,
    createDocumentError,
    createDocumentLoading,
    copyDocument,
    copyDocumentError,
    copyDocumentLoading,
    deleteDocuments,
    deleteDocumentsError,
    deleteDocumentsLoading,
    updateDocument,
    updateDocumentError,
    updateDocumentLoading,
  };
};

export default useDocumentMutation;
