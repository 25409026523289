import { gql } from '@apollo/client';

export const GET_DIVISIONS = gql`
  query GET_DIVISIONS {
    getDivisions {
      divisionName
      subDivisionName
      valuesTree
      value
      id
      key
    }
  }
`;
