import { GetSupports_supports as Support } from 'app/schemaInterfaces/GetSupports';
import {
  CampaignType,
  DocumentOrderType,
  DocumentQuantitiesType,
  TemplateType,
} from 'app/schemaInterfaces/globalTypes';
import { Maybe } from 'app/utils/common';
import { checkFileMimeType } from 'modules/dam/form/validation/schema';
import { TFunction } from 'react-i18next';
import * as Yup from 'yup';
import { StringSchema } from 'yup';
import { FAMILY_ORDERS } from '../form.config';

const getQuantitiesValidations = (t: TFunction, lowestQuantity: number) => {
  const errorMessage = (quantity: number) =>
    t('sales.basket_page.quantity_select.min_quantity_required', {
      quantity: quantity,
    });
  return Yup.object({
    type: Yup.string().nullable(),
    rangeQuantity: Yup.object()
      .when('type', (type) => {
        if (type === DocumentQuantitiesType.Range) {
          return Yup.object({
            minimum: Yup.number().min(
              lowestQuantity,
              errorMessage(lowestQuantity)
            ),
            maximum: Yup.number().when('minimum', (minimum) =>
              Yup.number().required().min(minimum, errorMessage(minimum))
            ),
          });
        } else {
          return Yup.object({
            minimum: Yup.number().nullable(),
            maximum: Yup.number().nullable(),
          });
        }
      })
      .nullable(),
    strictQuantity: Yup.number()
      .when('type', (type) => {
        if (type === DocumentQuantitiesType.Strict) {
          return Yup.number().min(lowestQuantity, errorMessage(lowestQuantity));
        }
      })
      .nullable(),
  }).when('isMandatory', {
    is: true,
    then: Yup.object().required().shape({
      type: Yup.string().required(),
    }),
    otherwise: Yup.object().nullable().shape({
      type: Yup.string().nullable(),
    }),
  });
};

const validationDate = (
  documentType: DocumentOrderType,
  t: TFunction,
  campaignType?: CampaignType
) =>
  Yup.date().when('orderTypes', (orderTypes) => {
    if (
      orderTypes.includes(documentType) &&
      (campaignType ? campaignType === CampaignType.National : true)
    ) {
      return Yup.date().nullable().required(t('common.generic_error.required'));
    }
    return Yup.date().nullable();
  });

const getTemplateValidations = (t: TFunction) => ({
  template: Yup.object().when('isCustomizable', (isCustomizable) =>
    isCustomizable
      ? Yup.object({
          type: Yup.string()
            .required(t('common.generic_error.required'))
            .oneOf(Object.values(TemplateType)),
          metadata: Yup.object().when('type', (type) =>
            type === TemplateType.BIGONE
              ? Yup.object({
                  bigone: Yup.object(),
                })
              : type === TemplateType.CHILI
              ? Yup.object({
                  chili: Yup.object({
                    templateId: Yup.string().required(
                      t('common.generic_error.required')
                    ),
                  }),
                })
              : Yup.object()
          ),
        })
      : Yup.object()
  ),
});

const getStaticFieldsValidations = (
  t: TFunction,
  lowestQuantity: number,
  nonCustomizableCategories: string[],
  campaignType?: CampaignType
) => {
  return {
    name: Yup.string().required(t('common.generic_error.required')),
    version: Yup.string().required(t('common.generic_error.required')),
    category: Yup.string().required(t('global.document_form.errors.category')),
    mediumType: Yup.string().required(t('common.generic_error.required')),
    quantities: getQuantitiesValidations(t, lowestQuantity),
    productId: Yup.string()
      .nullable()
      .required(t('common.generic_error.required')),
    supportId: Yup.string()
      .nullable()
      .required(t('common.generic_error.required')),
    orderTypes: Yup.array().min(1, t('common.generic_error.required')),
    entities: Yup.array().min(1, t('common.generic_error.required')),
    isMandatory: Yup.boolean(),
    isCustomizable: Yup.boolean()
      .when('category', (category) =>
        nonCustomizableCategories.includes(category)
          ? Yup.boolean().oneOf(
              [false],
              t('common.generic_error.not_customizable')
            )
          : Yup.boolean().nullable()
      )
      .nullable(),
    commitmentDateFrom: validationDate(DocumentOrderType.Commitment, t),
    commitmentDateTo: validationDate(DocumentOrderType.Commitment, t),
    freeOrderDateFrom: validationDate(DocumentOrderType.FreeOrder, t),
    freeOrderDateTo: validationDate(
      DocumentOrderType.FreeOrder,
      t,
      campaignType
    ),
    ...getTemplateValidations(t),
  };
};

const getProductValidations = (t: TFunction, selectedSupport: Maybe<Support>) =>
  selectedSupport?.supportAttributes
    ?.filter(({ family, key }) => FAMILY_ORDERS[family])
    .reduce<Record<string, StringSchema<string | null | undefined, object>>>(
      (validations, attribute) => {
        validations[attribute.key] = Yup.string()
          .nullable()
          .test(
            'not-empty-and-nullable',
            t('common.generic_error.required'),
            (value) => (value?.length && value.length > 0) || value === null
          );
        return validations;
      },
      {}
    ) ?? {};

const formFileValidation = (isThumbnailUrl: boolean, t: TFunction) => {
  return !isThumbnailUrl
    ? {
        file: Yup.mixed()
          .test(
            'fileUpload',
            t('common.generic_error.only_jpg_png'),
            (value: unknown) => checkFileMimeType(value)
          )
          .required(t('global.document_form.picture_required')),
      }
    : null;
};

export const getDocumentFormSchema = (
  t: TFunction,
  selectedSupport: Maybe<Support>,
  lowestQuantity: number,
  nonCustomizableCategories: string[],
  isThumbnailUrl: boolean,
  campaignType?: CampaignType
) =>
  Yup.object({
    document: Yup.object().shape({
      ...getStaticFieldsValidations(
        t,
        lowestQuantity,
        nonCustomizableCategories,
        campaignType
      ),
    }),
    productAttributes: Yup.object(getProductValidations(t, selectedSupport)),
    ...formFileValidation(isThumbnailUrl, t),
  });
