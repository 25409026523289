import { FixedTabs, TabTitle } from 'components/FixedTabs';
import {
  GetCampaignById,
  GetCampaignByIdVariables,
} from 'app/schemaInterfaces/GetCampaignById';
import React, { useEffect, useState } from 'react';
import {
  campaignDetailsActions,
  documentsTabs,
} from 'modules/pro/campaigns/campaignsHelper';

import { Box } from '@material-ui/core';
import { CampaignDetailHeader } from 'components/CampaignDetailHeader/CampaignDetailHeader';
import { CampaignType } from 'app/schemaInterfaces/globalTypes';
import { GET_CAMPAIGN_BY_ID } from 'app/graphql/queries/campaigns';
import Loader from 'components/Loader/Loader/Loader';
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import colors from 'styles/colors.module.scss';
import { useParams } from 'react-router-dom';
import usePermissions from 'app/auth/usePermissions';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

const CampaignDetail = (): JSX.Element => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [translation, setTranslation] = useState<TabTitle[]>([]);
  const { hasPermission } = usePermissions();
  const { data, error: documentsError, refetch } =
    useQuery<GetCampaignById, GetCampaignByIdVariables>(GET_CAMPAIGN_BY_ID, {
      variables: {
        id,
      },
    }) || {};

  const { getCampaignById: campaign } = data ?? {};

  const isCampaignModel = campaign?.campaignType === CampaignType.Model;

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setTranslation(
      documentsTabs(
        t,
        hasPermission,
        campaign?.campaignType === CampaignType.Model
      )
    );
  }, [t, campaign?.campaignType]);

  return campaign ? (
    <Box position="relative">
      <CampaignDetailHeader
        title={campaign?.name}
        imageUrl={campaign?.imageUrl}
        description={campaign?.description}
        dateFrom={campaign?.dateFrom}
        dateTo={campaign?.dateTo}
        tags={campaign?.tags?.map((tag) => tag.title)}
        isCampaignModel={isCampaignModel}
        customerRef={campaign?.reference}
        height={180}
        actions={(handleOpenCampaignDialog, deleteCampaign, previewCampaign) =>
          campaignDetailsActions({
            handleOpenCampaignDialog,
            deleteCampaign,
            previewCampaign,
            t,
            hasPermission,
            isCampaignModel,
          })
        }
        button={<MoreVertOutlinedIcon />}
        isEditMode={true}
        campaignId={id}
        isArchived={campaign?.archived}
        refetch={refetch}
      />
      <Box position="absolute" top={132} left={56} width="93%">
        <FixedTabs
          tabsCssProps={{
            color: colors.documentTab,
          }}
          tabsTitle={translation}
        />
      </Box>
    </Box>
  ) : (
    <Loader error={documentsError} />
  );
};

export default CampaignDetail;
