import React, { FC } from 'react';
import { Box, Typography } from '@material-ui/core';
import colors from 'styles/colors.module.scss';

export interface CustomPlaceholderProps {
  title?: string;
}

const CustomPlaceholder: FC<CustomPlaceholderProps> = ({ title }) => (
  <Box color={colors.inputLabelColor}>
    <Typography variant="body1">{title}</Typography>
  </Box>
);

export default CustomPlaceholder;
