import { gql } from '@apollo/client';

export const UPDATE_ASSETS_BY_CAMPAIGN_ID = gql`
  mutation UpdateAssetsByCampaignId($assets: [String!]!, $campaignId: String!) {
    updateAssetsByCampaignId(assets: $assets, campaignId: $campaignId) {
      id
      assets
    }
  }
`;

export const GET_ALL_MEDIAS_OF_CAMPAIGN = gql`
  query GetCampaignAssets($campaignId: String!) {
    assetsCampaigns: getCampaignAssets(campaignId: $campaignId) {
      id
      clientId
      assetType
      name
      division
      visualType
      tags {
        id
        title
      }
      thumbnailUrl
      url
      metadata {
        mimetype
      }
    }
  }
`;
