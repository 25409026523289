import { useMutation } from '@apollo/client';
import {
  CREATE_ASSET,
  CREATE_ASSET_UPLOAD_URL,
  CREATE_ASSET_UPLOAD_ZIP_URL,
  UPDATE_ASSET,
} from 'app/graphql/queries/dam';
import {
  CreateAsset,
  CreateAsset_createAsset as CreatedAsset,
  CreateAssetVariables,
} from 'app/schemaInterfaces/CreateAsset';
import {
  CreateAssetUploadUrl,
  CreateAssetUploadUrlVariables,
  CreateAssetUploadUrl_createAssetUploadUrl as AssetUploadUrl,
} from 'app/schemaInterfaces/CreateAssetUploadUrl';
import { AssetInput } from 'app/schemaInterfaces/globalTypes';
import {
  UpdateAsset,
  UpdateAsset_updateAsset as UpdatedAsset,
  UpdateAssetVariables,
} from 'app/schemaInterfaces/UpdateAsset';
import { uploadFile } from 'app/utils/FileUpload/FileUpload';
import { useUploadProgress } from 'modules/dam/hooks/useUploadProgress';
import {
  CreateAssetsZipUrl,
  CreateAssetsZipUrl_createAssetsZipUrl as AssetsZipUrl,
  CreateAssetsZipUrlVariables as AssetsZipVariables,
} from 'app/schemaInterfaces/CreateAssetsZipUrl';
import mime from 'mime-types';
import { extractMetadata } from 'modules/dam/helpers';

const MULTI_UPLOAD_BATCH_SIZE = 1;

export interface AssetMutateOptions {
  reportProgress?: boolean;
}

export const useAssetMutate = (
  options: AssetMutateOptions = { reportProgress: false }
) => {
  const {
    uploadProgress,
    queueProgress,
    initProgress,
    reportProgress,
    reportSuccess,
    reportError,
    clearProgress,
  } = useUploadProgress();

  const [createAssetUploadUrl] = useMutation<
    CreateAssetUploadUrl,
    CreateAssetUploadUrlVariables
  >(CREATE_ASSET_UPLOAD_URL);
  const [createAssetMutation] = useMutation<CreateAsset, CreateAssetVariables>(
    CREATE_ASSET
  );
  const [updateAssetMutation] = useMutation<UpdateAsset, UpdateAssetVariables>(
    UPDATE_ASSET
  );
  const [createAssetsZipUrl] = useMutation<
    CreateAssetsZipUrl,
    AssetsZipVariables
  >(CREATE_ASSET_UPLOAD_ZIP_URL);

  const createUploadUrl = async (file: File): Promise<AssetUploadUrl> => {
    const mimetype = !!file.type
      ? file.type
      : mime.lookup(file.name.split('.').pop() as string);

    const { data } = await createAssetUploadUrl({
      variables: {
        mimetype: !!mimetype ? mimetype : '',
      },
    });
    if (!data) {
      throw new Error('Cannot create upload url');
    }
    return data.createAssetUploadUrl;
  };

  const callCreateAssetMutation = async (
    asset: AssetInput
  ): Promise<CreatedAsset> => {
    const result = await createAssetMutation({
      variables: {
        asset,
      },
    });
    if (!result.data) throw new Error();
    return result.data.createAsset;
  };

  const callUpdateAssetMutation = async (
    id: string,
    asset: AssetInput
  ): Promise<UpdatedAsset> => {
    const result = await updateAssetMutation({
      variables: {
        id,
        asset: asset,
      },
    });
    if (!result.data) throw new Error();
    return result.data.updateAsset;
  };

  const createAsset = async (
    asset: AssetInput,
    file: File
  ): Promise<CreatedAsset> => {
    if (options.reportProgress) {
      initProgress(file.name);
    }
    try {
      const { id: imageId, url } = await createUploadUrl(file);
      const mimetype = !!file.type
        ? file.type
        : mime.lookup(file.name.split('.').pop() as string);
      await uploadFile(
        file,
        url,
        !!mimetype ? mimetype : '',
        options.reportProgress
          ? (progress) => reportProgress(file.name, progress)
          : undefined
      );

      const mutatedAsset = await callCreateAssetMutation({
        ...asset,
        imageId,
      });

      if (options.reportProgress) {
        reportSuccess(file.name);
      }

      return mutatedAsset;
    } catch (e) {
      if (options.reportProgress) {
        reportError(file.name, (e as any).message);
      }
      throw e;
    }
  };

  const updateAsset = async (
    id: string,
    asset: AssetInput,
    file?: File | null
  ): Promise<UpdatedAsset> => {
    try {
      let imageId: string | undefined;

      if (file) {
        if (options.reportProgress) {
          initProgress(file.name);
        }
        const mimetype = !!file.type
          ? file.type
          : mime.lookup(file.name.split('.').pop() as string);

        const uploadPayload = await createUploadUrl(file);

        imageId = uploadPayload.id;

        await uploadFile(
          file,
          uploadPayload.url,
          !!mimetype ? mimetype : '',
          options.reportProgress
            ? (progress) => reportProgress(file.name, progress)
            : undefined
        );
      }

      const mutatedAsset = await callUpdateAssetMutation(id, {
        ...asset,
        imageId,
      });

      if (file && options.reportProgress) {
        reportSuccess(file.name);
      }

      return mutatedAsset;
    } catch (e) {
      if (file && options.reportProgress) {
        reportError(file.name, (e as any).message);
      }
      throw e;
    }
  };

  const importMultipleAssets = async (
    baseAssetInput: AssetInput,
    files: File[]
  ) => {
    const assetBatches: Array<{ asset: AssetInput; file: File }[]> = [];
    let assets: { asset: AssetInput; file: File }[] = [];

    // Build the batches of assets and init upload progress
    for (let i = 0; i < files.length; i++) {
      if (options.reportProgress) {
        queueProgress(files[i].name);
      }
      let extractedMetadata;
      try {
        extractedMetadata = await extractMetadata(files[i]);
      } catch (e) {
        if (options.reportProgress) {
          reportError(files[i].name, (e as any).message);
        }
        continue;
      }

      assets.push({
        asset: {
          ...baseAssetInput,
          name: files[i].name,
          metadata: {
            mimetype: files[i].type,
            fileSize: parseFloat((files[i].size / (1024 * 1024)).toFixed(2)),
            credit: extractedMetadata?.credit,
            dimensions: extractedMetadata?.dimensions,
          },
        },
        file: files[i],
      });
      if ((i + 1) % MULTI_UPLOAD_BATCH_SIZE === 0) {
        assetBatches.push(assets);
        assets = [];
      } else if (i + 1 === files.length) {
        assetBatches.push(assets);
      }
    }

    for (const batch of assetBatches) {
      // Try catch if we want to continue when an upload fails
      await Promise.all(
        batch.map(({ asset, file }) => createAsset(asset, file))
      );
    }
  };

  const createZipDownloadUrl = async (
    assetIds: string[]
  ): Promise<AssetsZipUrl['url']> => {
    const { data } = await createAssetsZipUrl({
      variables: {
        assetIds,
      },
    });
    if (!data) {
      throw new Error('Cannot create download url');
    }
    return data.createAssetsZipUrl.url;
  };

  return {
    uploadProgress,
    createAsset,
    updateAsset,
    initProgress,
    reportSuccess,
    reportError,
    queueProgress,
    importMultipleAssets,
    createZipDownloadUrl,
    clearProgress,
  };
};
