import React from 'react';
import { Box, Button, createStyles } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() =>
  createStyles({
    dropzone: {
      outline: 'none',
    },
  })
);

interface UploadFileButtonProps {
  buttonLabel: string;
  onFileChange: (event: File[]) => void;
  acceptedFiles: string | string[];
  icon: JSX.Element;
  isMultiple?: boolean;
  isDisabled?: boolean;
}

const UploadFileButton = ({
  onFileChange,
  acceptedFiles,
  buttonLabel,
  icon,
  isMultiple = true,
  isDisabled = false,
}: UploadFileButtonProps): JSX.Element => {
  const classes = useStyles();

  const { getInputProps } = useDropzone({
    onDrop: (files: File[]) => {
      onFileChange(files);
    },
    accept: acceptedFiles,
    multiple: isMultiple,
  });
  return (
    <Box borderRadius="25px">
      <Button
        color="primary"
        variant="contained"
        component="label"
        startIcon={icon}
        disabled={isDisabled}
      >
        {buttonLabel}
        <input
          style={{ display: 'none' }}
          {...getInputProps({ className: classes.dropzone })}
        />
      </Button>
    </Box>
  );
};

export default UploadFileButton;
