import { css, Theme } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const availableDocumentsStyle = (theme: Theme) => css`
  margin-left: 42px;
  margin-right: 42px;
  h1 {
    text-align: center;
    margin-top: 60px;
  }
  p {
    margin-bottom: 40px;
    text-align: center;
    font-family: ScandiaWebRegular;
    font-size: 14px;
  }
  .no-data--text {
    margin-top: 20px;
    font-size: 16px;
    font-family: ScandiaWebMedium;
    a {
      color: ${colors.primary};
      text-decoration: none;
    }
  }
  .documents {
  }
  .tabs-filter {
    background: ${colors.workzone};
    border-radius: 35px;
    width: fit-content;
    margin: auto;
    margin-bottom: 20px;
    font-size: 14px;
    font-family: ScandiaWebRegular;
    .tab {
      color: ${colors.grey};
    }

    .Mui-selected {
      background: ${colors.white};
      border-radius: 200px;
      padding: 8px 16px;
      margin: 5px;
      box-shadow: 0px 0px 8px rgba(59, 59, 80, 0.1);
      min-height: 32px;
      color: ${colors.primary};
    }
  }

  .tabs-categories-filter {
    background: ${colors.white};
    border-radius: 35px;
    width: fit-content;
    margin: auto;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 500;
    font-family: ScandiaWebRegular;
    color: ${colors.grey};
    border: 2px solid ${colors.buttonGroupOutlined};
    .tab {
      color: ${colors.grey};
      border-right: 1px solid ${colors.buttonGroupOutlined};
    }
    .tab:last-child {
      border: none;
    }
    .Mui-selected {
      background: ${colors.primary};
      padding: 8px 16px;
      box-shadow: 0px 0px 8px rgba(59, 59, 80, 0.1);
      color: ${colors.white};
    }
  }
  .MuiTabs-indicator {
    display: none;
  }

  .menu {
    position: relative;
    .select {
      display: flex;
      width: 250px;
      position: absolute;
      left: 0;

      h5 {
        margin-right: 16px;
        margin-top: 6px;
        color: ${colors.grey};
      }
    }
  }
`;
