import {
  Collapse,
  createStyles,
  Divider,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Snackbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  CheckCircleOutlineOutlined,
  Close,
  ErrorOutlineOutlined,
  ExpandLess,
  ExpandMore,
  Image,
} from '@material-ui/icons';
import React, { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import colors from 'styles/colors.module.scss';

const useStyles = makeStyles((theme) =>
  createStyles({
    expansionList: {
      width: 400,
      backgroundColor: theme.palette.background.paper,
      paddingTop: 0,
      paddingBottom: 0,
    },
    list: {
      width: '100%',
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 16,
      maxHeight: 280,
      overflow: 'scroll',
    },
    listItem: {
      paddingTop: 16,
      paddingBottom: 16,
    },
    successIcon: {
      color: colors.success,
    },
    successProgress: {
      '& > .MuiLinearProgress-bar': {
        backgroundColor: colors.success,
      },
    },
    errorIcon: {
      color: colors.error,
    },
  })
);

export enum UploadStatus {
  InQueue,
  InProgress,
  Success,
  Failed,
}

export interface UploadProgress {
  name: string;
  status: UploadStatus;
  progress?: number;
  errorMessage?: string;
}

interface UploadProgressSnackbarProps {
  uploads: UploadProgress[];
  open: boolean;
  defaultExpanded: boolean;
  onClose?: () => void;
}

const BASE_TRANS_KEY = 'common.components.upload_snackbar';

const UploadProgressSnackbar = ({
  uploads,
  open = false,
  defaultExpanded = true,
  onClose,
}: UploadProgressSnackbarProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState(defaultExpanded);

  const inProgressCount = uploads.filter(
    ({ status }) =>
      status === UploadStatus.InProgress || status === UploadStatus.InQueue
  ).length;
  const successCount = uploads.filter(
    ({ status }) => status === UploadStatus.Success
  ).length;
  const errorCount = uploads.length - successCount - inProgressCount;

  const inProgress = inProgressCount > 0;
  const completedWithErrors = inProgressCount === 0 && errorCount > 0;
  const defaultErrorMessage = t(`${BASE_TRANS_KEY}.default_error_message`);

  const snackbarTitle = inProgress ? (
    <Typography>
      {t(`${BASE_TRANS_KEY}.files_in_progress`, { count: inProgressCount })}
    </Typography>
  ) : (
    <Typography>
      {t(`${BASE_TRANS_KEY}.files_success`, { count: successCount })}{' '}
      {completedWithErrors
        ? `(${t(`${BASE_TRANS_KEY}.files_failed`, { count: errorCount })})`
        : ''}
    </Typography>
  );

  const handleClose = (e: MouseEvent) => {
    e.stopPropagation();
    if (onClose) onClose();
  };

  return (
    <Snackbar
      open={open}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      style={{ marginRight: '76px' }}
    >
      <Paper elevation={3}>
        <List className={classes.expansionList}>
          <ListItem button onClick={() => setExpanded(!expanded)}>
            <ListItemText>{snackbarTitle}</ListItemText>
            {expanded ? <ExpandLess /> : <ExpandMore />}
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </ListItem>
          <Divider />
          <Collapse in={expanded} timeout="auto">
            <List className={classes.list}>
              {uploads.map((upload, index) => (
                <React.Fragment key={index}>
                  <ListItem className={classes.listItem}>
                    <ListItemIcon>
                      <Image />
                    </ListItemIcon>
                    <ListItemText primary={upload.name} />
                    {upload.status === UploadStatus.Success ? (
                      <CheckCircleOutlineOutlined
                        className={classes.successIcon}
                      />
                    ) : undefined}
                    {upload.status === UploadStatus.Failed ? (
                      <Tooltip
                        title={upload.errorMessage ?? defaultErrorMessage}
                      >
                        <ErrorOutlineOutlined className={classes.errorIcon} />
                      </Tooltip>
                    ) : undefined}
                  </ListItem>
                  {upload.status === UploadStatus.InQueue && (
                    <LinearProgress variant={'determinate'} value={0} />
                  )}
                  {upload.status === UploadStatus.InProgress && (
                    <LinearProgress
                      variant={
                        upload.progress ? 'determinate' : 'indeterminate'
                      }
                      value={upload.progress}
                    />
                  )}
                  {upload.status === UploadStatus.Success && (
                    <LinearProgress
                      className={classes.successProgress}
                      variant="determinate"
                      value={100}
                    />
                  )}
                  {upload.status === UploadStatus.Failed && <Divider />}
                </React.Fragment>
              ))}
            </List>
          </Collapse>
        </List>
      </Paper>
    </Snackbar>
  );
};

export default UploadProgressSnackbar;
