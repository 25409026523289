import EntityCard from 'components/Navigation/user-menu/entity-menu/EntityCard/EntityCard';
import { sharedStyle } from 'components/Navigation/user-menu/entity-menu/shared.style';
import React, { FC } from 'react';
import { entityMenuOptionStyle } from './EntityMenuOption.stye';

export interface EntityMenuOptionProps {
  name: string;
  logo?: string;
  isLogoHidden?: boolean;
  isSelected?: boolean;
  onClick?: () => void;
}

const EntityMenuOption: FC<EntityMenuOptionProps> = ({
  name,
  logo,
  isLogoHidden,
  isSelected,
  onClick,
}) => {
  return (
    <div
      data-testid="menu-option"
      css={[
        sharedStyle.baseContainer,
        entityMenuOptionStyle.container,
        isSelected && entityMenuOptionStyle.selectedContainer,
      ]}
      onClick={onClick}
    >
      <EntityCard
        className="card"
        name={name}
        logo={logo}
        hideLogo={isLogoHidden}
      />
    </div>
  );
};

export default EntityMenuOption;
