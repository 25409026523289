import { css } from '@emotion/react/macro';
import 'ress/dist/ress.min.css';
import colors from 'styles/colors.module.scss';
import locationCursor from 'modules/workflow/containers/CatalogPreview/locationCursor.svg';

const editPlatform = (scale: number, commentMode: boolean) => css`
  .comment {
    cursor: ${commentMode ? `url(${locationCursor}),move` : ''} !important;
  }
  .resizable-item:hover {
    box-shadow: ${colors.lightBlue} 0px 0px 0px 1px inset,
      ${colors.lightBlue} 0px 0px 0px ${3 / scale}px;
    :before {
      content: '';
      display: block;
      position: absolute;
      bottom: ${-9 / scale}px;
      right: ${-9 / scale}px;
      width: ${15 / scale}px;
      height: ${15 / scale}px;
      border: ${2 / scale}px solid ${colors.lightBlue};
      border-radius: 50%;
      background-color: ${colors.white};
    }
  }
`;

export default editPlatform;
