import React from 'react';
import colors from 'styles/colors.module.scss';
import { Avatar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

interface CommentBubbleProps {
  text: string;
  bubbleColor: string;
  bubbleTextColor?: string;
  bubbleBorder?: string;
  size?: number;
}

const CommentBubble = ({
  text,
  bubbleTextColor,
  bubbleColor,
  bubbleBorder,
  size,
}: CommentBubbleProps): JSX.Element => {
  const avatarSize = '24px';
  const classes = makeStyles({
    avatar: {
      color: bubbleTextColor || colors.white,
      backgroundColor: bubbleColor,
      border: bubbleBorder || 'none',
      height: size ?? avatarSize,
      width: size ?? avatarSize,
    },
  })();

  return (
    <Avatar className={classes.avatar}>
      <Typography variant="h5">{text}</Typography>
    </Avatar>
  );
};

export default CommentBubble;
