import { ApolloQueryResult, useApolloClient, useQuery } from '@apollo/client';
import { GET_ASSET_BY_ID } from 'app/graphql/queries/dam';
import { GET_PRODUCTS_WITH_VISUAL_IDENTITY } from 'app/graphql/queries/products';
import {
  GetAssetById,
  GetAssetById_getAssetById,
  GetAssetByIdVariables,
} from 'app/schemaInterfaces/GetAssetById';
import {
  GetProductsWithVisualIdentity_result_products,
  GetProductsWithVisualIdentity,
  GetProductsWithVisualIdentityVariables,
} from 'app/schemaInterfaces/GetProductsWithVisualIdentity';
import { PaginationInput } from 'app/schemaInterfaces/globalTypes';
import { Nullish } from 'app/utils/common';
import { useProductsSheetsContext } from 'modules/pim/baseProducts/BaseProducts';
import { FileStore } from 'modules/pim/baseProducts/PimFormFieldsConfig';
import { useEffect, useState } from 'react';

export interface GetProductsWithAssets
  extends GetProductsWithVisualIdentity_result_products {
  assets?: GetAssetById_getAssetById[];
}

export interface DivisionElement {
  value?: string | null;
  division?: string;
}

export const useProductsWithAssets = (
  divisions: DivisionElement[],
  campaignId?: string,
  usedInCampaignId?: string,
  pagination?: PaginationInput,
  searchValue?: string
) => {
  const client = useApolloClient();
  const { filter } = useProductsSheetsContext();
  const [productsDataWithUrl, setProductsDataWithUrl] = useState<
    (GetProductsWithAssets | undefined)[]
  >([]);
  const filterByDivisions = divisions?.reduce<Record<string, Nullish<string>>>(
    (divisions, { value, division }) => ({
      ...divisions,
      [division as string]: value,
    }),
    {}
  );

  const { data: productsData, loading, fetchMore } = useQuery<
    GetProductsWithVisualIdentity,
    GetProductsWithVisualIdentityVariables
  >(GET_PRODUCTS_WITH_VISUAL_IDENTITY, {
    variables: {
      campaignId,
      usedInCampaignId,
      filter: {
        details: filterByDivisions,
        text: searchValue ?? filter?.text,
      },
      pagination,
      isSortByTitle: true,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    // TODO: use field resolver in backend to get asset urls
    const addUrlToProducts = async (
      data: GetProductsWithVisualIdentity_result_products[]
    ) => {
      const productsPromises = data.map((product) => {
        if (product.details.filesToSend) {
          const assetsPromise: Promise<
            ApolloQueryResult<GetAssetById>
          >[] = product.details.filesToSend.map((file: FileStore) => {
            return client.query<GetAssetById, GetAssetByIdVariables>({
              query: GET_ASSET_BY_ID,
              variables: {
                id: file.assetId as string,
              },
            });
          });
          return assetsPromise;
        }
        return undefined;
      });
      const productsWithItsAssets = await Promise.all(
        productsPromises?.reduce<
          Promise<GetAssetById_getAssetById[] | undefined>[]
        >((data, productsPromises) => {
          const getProductAssets = async () => {
            if (productsPromises) {
              const assets = await Promise.all(productsPromises);
              return assets.reduce<GetAssetById['getAssetById'][]>(
                (productAssets, asset) => {
                  productAssets.push(asset.data.getAssetById);
                  return productAssets;
                },
                []
              );
            }
          };
          data?.push(getProductAssets());
          return data;
        }, [])
      );
      const productWithUrl = data.map((product, index) => ({
        ...product,
        assets: !!productsWithItsAssets?.length
          ? productsWithItsAssets[index]
          : undefined,
      }));

      setProductsDataWithUrl(productWithUrl);
    };

    if (productsData?.result?.products) {
      addUrlToProducts(productsData.result.products);
    }
  }, [client, productsData]);

  return {
    productsDataWithUrl,
    setProductsDataWithUrl,
    productsData,
    loading,
    fetchMore,
  };
};
