import useCurrentEntity from 'app/current-entity-context/useCurrentEntity';
import {
  AscDescType,
  BigShopConfigCategory,
  DocumentFilterInput,
  DocumentOrderType,
  DocumentOrigin,
} from 'app/schemaInterfaces/globalTypes';
import useDebounce from 'components/useDebounce';
import _ from 'lodash';
import useDocumentsToShopQuery from 'modules/pro/documents/useDocumentToShopQuery';
import useCampaignByIdQuery from 'modules/shop/queries/useCampaignByIdQuery';
import { shopBreadcrumbs, thematicsLinkPrefix } from 'modules/shop/shopHelpers';
import useBasketDocumentsQuery from 'queries/useBasketDocumentsQuery';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

export const useCampaignPage = () => {
  const { pathname } = useLocation();
  const isCampaignModel = pathname.includes(thematicsLinkPrefix);

  const { campaignId } = useParams<{ campaignId: string }>();
  const { currentEntity } = useCurrentEntity();
  const { t } = useTranslation();

  const [pageFilter, setPageFilter] = useState<{
    categories: BigShopConfigCategory[];
  }>({ categories: [] });

  const [filter, setFilter] = useState<DocumentFilterInput>({
    campaignIds: [campaignId],
    orderTypes: isCampaignModel
      ? [DocumentOrderType.FreeOrder]
      : [DocumentOrderType.Commitment],
    ...(isCampaignModel ? { origins: [DocumentOrigin.Source] } : {}),
    isValidated: true,
    isCustomization: false,
    isActive: true,
  });

  const [searchValue, setSearchValue] = useState<string>(filter.text || '');
  const debouncedValue = useDebounce(searchValue, 500);

  useEffect(() => {
    if (
      debouncedValue !== undefined &&
      debouncedValue.length > 0 &&
      setFilter
    ) {
      setFilter((filter) => ({
        ...filter,
        text: debouncedValue,
      }));
    } else {
      setFilter((filter) => ({
        ...filter,
        text: undefined,
      }));
    }
  }, [debouncedValue, setFilter, setSearchValue]);

  const {
    data: documentsData,
    loading: isDocumentsLoading,
    error: documentsError,
    refetch: documentRefetch,
  } = useDocumentsToShopQuery({
    filter: { ...filter, entityId: currentEntity?.id },
    sort: { name: AscDescType.asc },
  });

  const basketFilter = {
    entityId: currentEntity?.id as string,
  };

  const {
    data: basketData,
    loading: isLoadingBasket,
    refetch: basketRefetch,
  } = useBasketDocumentsQuery({
    filter: basketFilter,
  });

  const {
    data: campaignData,
    loading: isCampaignLoading,
    error: campaignError,
  } = useCampaignByIdQuery(campaignId);

  const isLoading = isDocumentsLoading || isCampaignLoading;
  const documents = documentsData?.documents?.documents;

  const campaign = campaignData?.getCampaignById;

  const mediumTypes = _.uniqWith(
    documents?.map((document) => ({
      category: document.category,
      mediumType: document.mediumType,
    })),
    _.isEqual
  );
  const categories = _.uniq(documents?.map((document) => document.category));

  const onChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const text = event.target.value;
    setSearchValue(text);
  };

  const hasMandatoryDocuments =
    !isCampaignModel && documents?.some((document) => document.isMandatory);

  const isRecommendedDocumentInBasket = documents?.some(
    (document) => document.isRecommended
  );

  const isToats = hasMandatoryDocuments || isRecommendedDocumentInBasket;

  const breadcrumbs = shopBreadcrumbs(t, campaign?.name ?? '');
  const error = campaignError || documentsError;

  return {
    error,
    breadcrumbs,
    campaign,
    isToats,
    documents:
      pageFilter.categories.length < 1
        ? documents
        : documents?.filter((document) =>
            pageFilter.categories.includes(document.category)
          ),
    isLoading,
    basketRefetch,
    basketData,
    isLoadingBasket,
    documentRefetch,
    t,
    isCampaignModel,
    onChange,
    mediumTypes,
    categories,
    searchValue,
    filter,
    setFilter,
    pageFilter,
    setPageFilter,
  };
};
