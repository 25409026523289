import { css } from '@emotion/react/macro';

const style = css`
  h1 {
    margin-bottom: 32px;
  }

  h2 {
    margin-bottom: 8px;
  }

  .headerActions {
    text-align: right;

    a {
      font-family: ScandiaWebMedium;
      font-weight: 500;
      line-height: 40px;
    }
  }
`;

export default style;
