import { ButtonProps } from '@material-ui/core';
import { TFunction } from 'i18next';
import { ActionButtonsTitle } from './OneButtonConfirmationDialog';

export const actions = (
  onClose: () => void,
  t: TFunction,
  onClick: ButtonProps['onClick'],
  actionButtonsTitle?: ActionButtonsTitle
) => [
  {
    title: actionButtonsTitle
      ? actionButtonsTitle.reject
      : t('common.simple_confirmation_dialog.no'),
    dataTestId: 'reject',
    onClick: onClose,
    buttonVariant: 'text' as ButtonProps['variant'],
  },
  {
    title: actionButtonsTitle
      ? actionButtonsTitle?.accept
      : t('common.simple_confirmation_dialog.yes'),
    dataTestId: 'accept',
    onClick,
  },
];
