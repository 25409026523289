import { Collapse, Divider } from '@material-ui/core';
import OrderDocumentRow, {
  DocumentItem,
} from '../pages/follow-orders-page/OrderDocumentRow/OrderDocumentRow';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { formatPriceHT } from 'app/utils/common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { orderCampaignsWithDocumentsStyle } from './OrderCampaignsWithDocuments.style';

export type PublicationDate = string | null;

interface OrderCampaignsWithDocumentsProps {
  documentItems: DocumentItem[];
  campaignName: string;
  totalPriceHT: number;
  transportSellingPriceHt: number;
  isCampaignArchived: boolean;
}
const OrderCampaignsWithDocuments = ({
  documentItems,
  campaignName,
  totalPriceHT,
  isCampaignArchived,
  transportSellingPriceHt,
}: OrderCampaignsWithDocumentsProps) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const { t } = useTranslation();
  return (
    <div css={orderCampaignsWithDocumentsStyle}>
      <div className="order-campaign-container">
        <div className="billing-container">
          <div>
            <div
              className="campaign-header"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              <div className="header-wrapper">
                <h3>{campaignName}</h3>
                <span>
                  {t('sales.basket_page.medium_count', {
                    count: documentItems.length,
                  })}
                </span>
                {isCampaignArchived && (
                  <div className="archived-tag">
                    {t('follow_orders.archived')}
                  </div>
                )}
              </div>
              <div className="header-wrapper">
                <h3 className="total-price">
                  {formatPriceHT(totalPriceHT, t)}
                  <h6>
                    {t('follow_orders.delivery_price_total', {
                      price: transportSellingPriceHt || 0,
                    })}
                  </h6>
                </h3>
                {isExpanded ? (
                  <KeyboardArrowUpIcon className="dropdown" />
                ) : (
                  <KeyboardArrowDownIcon className="dropdown" />
                )}
              </div>
            </div>
            <Collapse in={isExpanded} timeout="auto">
              <div>
                {documentItems?.map((document, index) => (
                  <div key={index} className="delivery-row">
                    <OrderDocumentRow
                      {...document}
                      transportSellingPriceHT={transportSellingPriceHt}
                    />
                    <Divider />
                  </div>
                ))}
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderCampaignsWithDocuments;
