import React, { useEffect, useState } from 'react';
import { Paper, makeStyles, createStyles, Box } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import AddComment from 'modules/workflow/containers/CatalogPreview/AddComment';
import CommentBubbles from 'modules/workflow/containers/CatalogPreview/CommentBubbles';
import {
  useStudioStateContext,
  Position,
} from 'modules/workflow/oneStudioContext';
import { useGetCampaign } from 'modules/workflow/containers/CatalogPreview/campaignQueries';
import ZoneContent from 'modules/workflow/containers/CatalogPreview/ZoneContent';
import { useQuery } from '@apollo/client';
import {
  CommentsByVersion,
  CommentsByVersionVariables,
} from 'app/schemaInterfaces/CommentsByVersion';
import { GET_COMMENTS_BY_VERSION } from 'app/graphql/queries/comments';
import { ItemSize } from 'modules/workflow/containers/CatalogPreview/catalogPreviewHelper';
import CustomizableSwitch from './CustomizableSwitch';
import ValidatedSwitch from './ValidatedSwitch';
import { css } from '@emotion/react';
import { getLengthFromCmToPx } from 'app/utils/math';

const innerMargin = 0.8; // in cm
const outerMargin = 0.5; // in cm

const pageButtonsStyle = (
  scale: number,
  pageDimension: { width: number; height: number }
) => css`
  justify-content: space-between;
  align-items: flex-start;
  padding: 0;
  .content {
    position: relative;
    transform: scale(${1 / scale});
    transform-origin: top left;
    width: ${pageDimension.width * scale}px;
    top: ${-70 / scale}px;
    justify-content: space-between;
    display: flex;
    align-items: center;
  }
`;

const outerMarginInPx = getLengthFromCmToPx(outerMargin * 2);

const useStyles = makeStyles<
  Theme,
  {
    width: number;
    height: number;
    scaleValue: number;
    isTwoPageView: boolean;
    currentPageBackgroundColorImage: string | undefined;
  }
>(() =>
  createStyles({
    '& .react-transform-element': {
      overflow: 'hidden',
    },
    rotate270: {
      transform: 'rotate(270deg)',
    },
    lastPageImage: {
      width: '100px',
      float: 'left',
      marginRight: '5px',
      marginTop: '2px',
    },
    paragragh: {
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: 'DINPro-Regular',
      fontSize: '0.37rem',
    },
    logo: {
      margin: '3mm',
    },
    elpevLogo: {
      verticalAlign: 'middle',
    },
    paperElement: {
      transform: ({ scaleValue, isTwoPageView }) =>
        `scale(${isTwoPageView ? (scaleValue * 2) / 3 : scaleValue})`,
      transformOrigin: 'center',
      //this is used to place the paper on the page :
      margin: ({ width, height }) => `-${height * 0.5}px -${width * 0.5}px`,
      width: ({ width }) => width,
      height: ({ height }) => height,
      padding: `${getLengthFromCmToPx(innerMargin)}px`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
);

export interface Comment {
  offsetPosition: Position;
  clientPosition: Position;
  text: string;
  pageId: string;
}

interface SinglePaperProps {
  pageNumber: number;
  zoomScale: number;
  pageDimension: ItemSize;
}

const SinglePaper = React.forwardRef<HTMLElement, SinglePaperProps>(
  ({ pageNumber, zoomScale, pageDimension }, catalogRef): JSX.Element => {
    const globalState = useStudioStateContext();

    let currentPageBackgroundColorImage: string | undefined = '';
    if (globalState.version.pages[pageNumber - 1]) {
      currentPageBackgroundColorImage = globalState.version.pages[
        pageNumber - 1
      ].backgroundColor
        ? globalState.version.pages[pageNumber - 1].backgroundColor
            ?.thumbnailUrl ??
          globalState.version.pages[pageNumber - 1].backgroundColor?.image
        : '';
    }

    const classes = useStyles({
      width: pageDimension.width,
      height: pageDimension.height,
      scaleValue: globalState.scaleValue,
      isTwoPageView: globalState.isTwoPagesView,
      currentPageBackgroundColorImage,
    });
    const [comment, setComment] = useState<Comment>({
      text: '',
      pageId: '',
      offsetPosition: { x: -1, y: -1 },
      clientPosition: { x: -1, y: -1 },
    });

    const [isCurrentPageCustomizable, setIsCurrentPageCustomizable] = useState<
      boolean
    >(globalState.version.pages[pageNumber - 1]?.isCustomizable);

    useEffect(() => {
      setIsCurrentPageCustomizable(
        globalState.version.pages[pageNumber - 1]?.isCustomizable
      );
    }, [globalState.version.pages, pageNumber]);

    const { data } = useQuery<CommentsByVersion, CommentsByVersionVariables>(
      GET_COMMENTS_BY_VERSION,
      {
        variables: {
          versionId: globalState.version.id,
        },
      }
    );

    const {
      error: errorCampaign,
      loading: loadingCampaign,
      data: campaignData,
    } = useGetCampaign(globalState.version.campaignId);
    const error = errorCampaign;
    const loading = loadingCampaign;
    if (loading) {
      return <>Loading...</>;
    }
    if (error) {
      return <>Error! ${error.message}</>;
    }
    if (!campaignData) {
      return <>Error! NO DATA</>;
    }
    const commentsOnCurrentPage =
      data?.commentsByVersion[globalState.currentPage - 1];

    const addComment = (e: React.MouseEvent) => {
      e.persist();
      if (!globalState.commentMode) {
        return;
      }

      // cursor size (location cursor). It doesn't need the scale value because its size never changes.
      const cursorSize = {
        width: 22.25,
        height: 32,
      };

      // bubble size
      const markerSize = {
        width: 23 * globalState.scaleValue * zoomScale,
        height: 23 * globalState.scaleValue * zoomScale,
      };

      const offsetToCenterHorizontallyTheMarker =
        cursorSize.width / 2 - markerSize.width / 2;
      const offsetToCenterVerticallyTheMarker =
        cursorSize.height - markerSize.height;

      // client positions related to the browser
      // getClientRects gives the positions related to the page (singlePaper)
      // offset the positions of the comment related to the parent (singlePaper)
      const offsetX =
        e.clientX -
        (e.currentTarget.getClientRects()?.item(0)?.x || 0) +
        offsetToCenterHorizontallyTheMarker;
      const offsetY =
        e.clientY -
        (e.currentTarget.getClientRects()?.item(0)?.y || 0) +
        offsetToCenterVerticallyTheMarker;

      setComment({
        text: '',
        pageId: `${pageNumber - 1}`,
        // clientPosition is for the popover anchor that appears by clicking on the comment bubble
        clientPosition: { x: e.clientX, y: e.clientY },
        // offsetPosition is for the comment bubble positionning
        offsetPosition: {
          // clientWidth and clientHeight are the singlepaper values (excluding the borders) but it includes the margins
          x:
            (offsetX /
              (e.currentTarget.clientWidth *
                globalState.scaleValue *
                zoomScale)) *
            100,
          y:
            (offsetY /
              (e.currentTarget.clientHeight *
                globalState.scaleValue *
                zoomScale)) *
            100,
        },
      });
    };

    const boxProps = {
      position: 'relative',
      width: '100%',
      height: '100%',
    };

    const scale = globalState.isTwoPagesView
      ? (globalState.scaleValue * 2) / 3
      : globalState.scaleValue;

    const hasTrimbox = !globalState.isDigital;
    const pageButtons = (): JSX.Element => (
      <Box
        className={classes.paperElement}
        css={pageButtonsStyle(scale, pageDimension)}
        style={{ padding: 0 }}
      >
        <div className="content">
          <CustomizableSwitch isCustomizable={isCurrentPageCustomizable} />

          <ValidatedSwitch pageNumber={pageNumber} />
        </div>
      </Box>
    );

    return (
      <>
        {pageButtons()}
        <Paper
          square={true}
          elevation={5}
          className={`${classes.paperElement} comment`}
          style={{
            overflow: 'hidden',
          }}
          onClick={addComment}
          ref={catalogRef}
        >
          {currentPageBackgroundColorImage && (
            <img
              alt=""
              style={{
                ...(hasTrimbox
                  ? {
                      top: `-${outerMarginInPx / 2}px`,
                      left: `-${outerMarginInPx / 2}px`,
                    }
                  : {}),
                position: 'absolute',
                width: `${
                  pageDimension.width + (hasTrimbox ? outerMarginInPx : 0)
                }px`,
                height: `${
                  pageDimension.height + (hasTrimbox ? outerMarginInPx : 0)
                }px`,
                objectFit: 'contain',
                objectPosition: 'left',
              }}
              src={currentPageBackgroundColorImage}
            />
          )}

          {commentsOnCurrentPage && (
            <CommentBubbles comments={commentsOnCurrentPage} scale={scale} />
          )}
          {globalState.commentMode && (
            <AddComment
              pageId={globalState.version.pages[pageNumber - 1].id}
              comment={comment}
              setComment={setComment}
            />
          )}
          <Box {...boxProps}>
            {globalState.version.pages[pageNumber - 1]?.validated && (
              <Box
                zIndex={1000}
                height="100%"
                width="100%"
                position="absolute"
              />
            )}
            {globalState.version.pages[pageNumber - 1] &&
              globalState.version.pages[pageNumber - 1].pageTemplate.map(
                (eachZone, zoneIndex) => {
                  return (
                    <ZoneContent
                      zoomScale={zoomScale}
                      pageNumber={pageNumber}
                      zoneIndex={zoneIndex}
                      key={zoneIndex}
                    />
                  );
                }
              )}
            {!globalState.version.pages[pageNumber - 1] && (
              <Box
                width="100%"
                height="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                Please select Template
              </Box>
            )}
          </Box>
        </Paper>
      </>
    );
  }
);

export default SinglePaper;
