import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  IconButton,
  TextFieldProps,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgressLoader from 'components/Loader/CircularProgressLoader/CircularProgressLoader';
import { ApolloError } from '@apollo/client';
import EntitiesField from 'modules/oneManager/entities/form/EntitiesField';
import { Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';

interface DuplicateDocumentFormProps {
  label: string;
  open: boolean;
  title: string;
  onClose: () => void;
  confirmationText: string;
  isLoading: boolean | undefined;
  error: ApolloError | undefined;
  dialogMaxWidth?: DialogProps['maxWidth'];
}

const DuplicateDocumentForm = ({
  label,
  open,
  onClose,
  title,
  confirmationText,
  error,
  isLoading,
  dialogMaxWidth = 'xs',
  ...textFieldProps
}: DuplicateDocumentFormProps &
  Omit<TextFieldProps, 'onClick' | 'error'>): JSX.Element => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth={dialogMaxWidth} fullWidth>
      <Form>
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>
              <Typography variant="h3">{title}</Typography>
            </Box>
            <Box>
              <IconButton type="reset" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <Divider light />
        {!error && !isLoading ? (
          <>
            <Box py={1}>
              <DialogContent>
                <Typography variant="h5">{label}</Typography>
                <Box pb={2}>
                  <Field
                    component={TextField}
                    name="name"
                    variant="outlined"
                    margin="dense"
                    size="small"
                    fullWidth
                    {...textFieldProps}
                  />
                </Box>
                <EntitiesField name="entities" />
              </DialogContent>
            </Box>
            <Divider light={true} />
            <Box py={1}>
              <DialogActions>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  style={{ justifyContent: 'center' }}
                  data-testid="accept"
                >
                  {confirmationText}
                </Button>
              </DialogActions>
            </Box>
          </>
        ) : (
          <CircularProgressLoader error={error} />
        )}
      </Form>
    </Dialog>
  );
};

export default DuplicateDocumentForm;
