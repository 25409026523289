import { CircularProgress } from '@material-ui/core';
import useAppContext from 'app/app-context/useAppContext';
import { Optional } from 'app/utils/common';
import CardList from 'components/CardList/CardList';
import { useInfiniteScroll } from 'components/infinite-scroll/useInfiniteScroll';
import {
  DivisionElement,
  GetProductsWithAssets,
  useProductsWithAssets,
} from 'components/useProductsWithAssets';
import { useProductsSheetsContext } from 'modules/pim/baseProducts/BaseProducts';
import { FileStore } from 'modules/pim/baseProducts/PimFormFieldsConfig';
import {
  getUnifiedKeyFromPimClientConfig,
  VisuelFieldName,
} from 'modules/pim/baseProducts/ProductsHelper';
import ProductSheetEdit from 'modules/pim/components/ProductSheetEdit';
import React, { useCallback, useState } from 'react';
import {
  DraggedItemTypes,
  isItemPersonalizationProduct,
} from 'modules/workflow/containers/CatalogPreview/EditPlatformDefinitions';
import {
  DraggedProduct,
  useStudioDispatchContext,
  useStudioStateContext,
} from 'modules/workflow/oneStudioContext';
import { studioAppActions } from 'modules/workflow/reducers/actionsInterfaces';
import {
  productPriceTagTypographies,
  productTypographies,
  productVisuals,
} from 'modules/workflow/containers/Leftsidebar/ProductsPanel/helpers/visualIdentitiesHelper';
import { productRegrouping } from 'modules/pim/regroupingHelper';
import { GetProductsWithVisualIdentity } from 'app/schemaInterfaces/GetProductsWithVisualIdentity';

interface ProductsViewInterface {
  selectedDivision: DivisionElement;
  disabled?: boolean;
  divisions: DivisionElement[];
  isCampaign: boolean;
}

export enum UnifiedProductKeyToRenderOnPage {
  title = 'title',
  description = 'description',
  price = 'price',
  priceunity = 'priceunity',
  divisions = 'divisions',
  productCode = 'productcode',
  optionalunity = 'optionalunity',
  optionalunityprice = 'optionalunityprice',
  discountvalue = 'discountvalue',
  priceWithTax = 'pricewithtax',
  pricelabelwithtaxes = 'pricelabelwithtaxes',
  pricelabelwithouttaxes = 'pricelabelwithouttaxes',
  professionnalprice = 'professionnalprice',
  bargainpricewithtaxes = 'bargainpricewithtaxes',
  suggestedpricewithtaxes = 'suggestedpricewithtaxes',
  bargainprice = 'bargainprice',
  suggestedprice = 'suggestedprice',
}

interface ProductsDataNormalized extends GetProductsWithAssets {
  image?: string;
  name?: string;
}

interface ProductToEdit {
  productId?: string;
  campaignId?: string;
}

const ProductsView = ({
  selectedDivision,
  disabled,
  divisions,
  isCampaign,
}: ProductsViewInterface): JSX.Element => {
  const dispatch = useStudioDispatchContext();
  const {
    client: { config: clientConfig },
  } = useAppContext();

  const globalState = useStudioStateContext();

  const {
    productsDataWithUrl,
    productsData,
    loading,
    fetchMore,
  } = useProductsWithAssets(
    divisions,
    isCampaign ? globalState.version.campaignId : undefined,
    isCampaign ? undefined : globalState.version.campaignId
  );

  const productNormalizedImage = (product?: GetProductsWithAssets) => {
    const mainVisual: Optional<FileStore> = product?.details.filesToSend?.find(
      (file: FileStore) => file.fieldName === VisuelFieldName.mainVisuel
    );

    return product?.assets?.find((asset) => asset.id === mainVisual?.assetId)
      ?.displayedThumbUrl;
  };

  const productNormalizedName = (product?: GetProductsWithAssets) =>
    product?.details[
      getUnifiedKeyFromPimClientConfig(
        UnifiedProductKeyToRenderOnPage.title,
        clientConfig
      )
    ];

  const productsDataNormalized: ProductsDataNormalized[] = productsDataWithUrl?.map(
    (product) => ({
      ...(product as GetProductsWithAssets),
      image: productNormalizedImage(product),
      name: productNormalizedName(product),
      campaignId: (product as GetProductsWithAssets).campaignId,
    })
  );

  const [openProductsSheetDialog, setOpenProductsSheetDialog] = useState<
    boolean
  >(false);
  const {
    setFieldName,
    setFormFilesManager,
    setFilesLimitNumber,
  } = useProductsSheetsContext();
  const [isHistoryOpen, setIsHistoryOpen] = useState<boolean>(false);

  const [productToEdit, setProductToEdit] = useState<ProductToEdit>({
    productId: undefined,
    campaignId: undefined,
  });

  const normalizedProduct = (
    productWithUrl: GetProductsWithAssets,
    selectedDivision: DivisionElement
  ): DraggedProduct => {
    const productDiscountValue = productWithUrl.details[
      getUnifiedKeyFromPimClientConfig(
        UnifiedProductKeyToRenderOnPage.discountvalue,
        clientConfig
      )
    ]?.toString();
    return {
      id: productWithUrl._id,
      name:
        productWithUrl.details[
          getUnifiedKeyFromPimClientConfig(
            UnifiedProductKeyToRenderOnPage.title,
            clientConfig
          )
        ],
      description:
        productWithUrl.details[
          getUnifiedKeyFromPimClientConfig(
            UnifiedProductKeyToRenderOnPage.description,
            clientConfig
          )
        ],
      family: selectedDivision.value as string,
      lePlus: [],
      soit: '',
      price: +productWithUrl.details[
        getUnifiedKeyFromPimClientConfig(
          UnifiedProductKeyToRenderOnPage.price,
          clientConfig
        )
      ],
      salesUnit:
        productWithUrl.details[
          getUnifiedKeyFromPimClientConfig(
            UnifiedProductKeyToRenderOnPage.priceunity,
            clientConfig
          )
        ],
      campaignId: productWithUrl.campaignId ?? undefined,
      productDetails: productWithUrl.details,
      visualIdentities: productVisuals(
        productWithUrl,
        globalState.document?.supportId
      ),
      typographyIdentities: productTypographies(
        productWithUrl,
        globalState.document?.supportId
      ),
      priceTagTypographies: productPriceTagTypographies(
        productWithUrl,
        productDiscountValue,
        globalState.document?.supportId
      ),
      regrouping: productRegrouping(productWithUrl, clientConfig, globalState),
    };
  };

  const dragHandler = async (
    product: GetProductsWithAssets,
    selectedDivision: DivisionElement
  ) => {
    dispatch(
      studioAppActions.setDraggedProduct({
        draggedItem: {
          item: normalizedProduct(product, selectedDivision),
          type: DraggedItemTypes.PRODUCT,
        },
      })
    );
  };

  const { campaignId } = productToEdit;
  const handleCloseProductsSheetDialog = () => {
    setOpenProductsSheetDialog(false);
  };

  const handleOpenProductsSheetDialog = (product: ProductsDataNormalized) => {
    setProductToEdit({
      ...product.details,
      productId: product._id,
      campaignId: product.campaignId,
    });
    setOpenProductsSheetDialog(true);

    setFieldName(undefined);
    setFormFilesManager([]);
    setFilesLimitNumber(0);
  };

  const handleOpenHistory = () => setIsHistoryOpen(!isHistoryOpen);

  const loadMoreProducts = useCallback(() => {
    if (!loading && productsData?.result.products?.length) {
      fetchMore({
        variables: {
          pagination: {
            offset: productsData?.result?.products?.length,
            limit: 5,
          },
        },
        updateQuery: (
          prevData: GetProductsWithVisualIdentity,
          { fetchMoreResult }
        ): GetProductsWithVisualIdentity => {
          if (!fetchMoreResult || fetchMoreResult.result.products.length === 0)
            return prevData;
          return {
            result: {
              ...prevData.result,
              products: [
                ...prevData.result.products,
                ...fetchMoreResult.result.products,
              ],
            },
          };
        },
      });
    }
  }, [fetchMore, loading, productsData?.result?.products?.length]);

  const waypointRef = useInfiniteScroll(loadMoreProducts, loading);
  const isCustomization = globalState.document?.isCustomization;

  const isProductUsed = (product: ProductsDataNormalized) => {
    let isUsedInDocument =
      !isCampaign && !isCustomization
        ? Boolean(product.isUsedInCampaign)
        : false;

    const productCodeKey = getUnifiedKeyFromPimClientConfig(
      UnifiedProductKeyToRenderOnPage.productCode,
      clientConfig
    );
    const productCode = product.details[productCodeKey];

    globalState.version.pages.forEach((page) =>
      page.pageTemplate.forEach((zone) => {
        zone.product?.forEach((product) => {
          if (product.productDetails[productCodeKey] === productCode) {
            isUsedInDocument = true;
          }
        });

        zone.personalizationItems?.forEach((personalizationItem) => {
          if (
            personalizationItem.content &&
            isItemPersonalizationProduct(personalizationItem.content) &&
            personalizationItem.content.productDetails[productCodeKey] ===
              productCode
          ) {
            isUsedInDocument = true;
          }
        });
      })
    );

    return !isUsedInDocument;
  };

  const productsDataSortWithType = (
    productsSortByName: ProductsDataNormalized[]
  ) => {
    return (
      <>
        <CardList<ProductsDataNormalized>
          onDrag={(_, product: GetProductsWithAssets) =>
            dragHandler(product, selectedDivision)
          }
          items={productsSortByName}
          handleDoubleClickItem={handleOpenProductsSheetDialog}
          isBackgroundDark={true}
          disabled={disabled}
          draggable={true}
          enableClick={isProductUsed}
        />
      </>
    );
  };

  return (
    <>
      <div>
        {productsDataNormalized &&
          productsDataNormalized.length > 0 &&
          productsDataSortWithType(productsDataNormalized)}
        {loading && (
          <div className="progress">
            <CircularProgress color="inherit" />
          </div>
        )}
        {!loading &&
          productsData &&
          productsData.result.products.length > 0 && (
            <div id="waypoint" ref={waypointRef} />
          )}
      </div>
      <ProductSheetEdit
        openProductsSheetDialog={openProductsSheetDialog}
        handleCloseProductsSheetDialog={handleCloseProductsSheetDialog}
        handleOpenHistory={handleOpenHistory}
        isHistoryOpen={isHistoryOpen}
        productData={productToEdit}
        campaignId={campaignId}
        productId={productToEdit.productId}
      />
    </>
  );
};

export default ProductsView;
