import React from 'react';
import { Box } from '@material-ui/core';
import { DamWrapper } from 'modules/dam/DamWrapper';
import { useTranslation } from 'react-i18next';

export const ImportDamInCampaign = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Box pb={2}>
      <DamWrapper
        isImportDamToCampaign={true}
        headerSize={60}
        variant="h5"
        title={t('dam.main_title.assets_from_media')}
      />
    </Box>
  );
};
