import React from 'react';
import { Typography } from '@material-ui/core';
import { CellProps } from 'react-table';
import moment from 'moment';
import { GetCampaignAttachments_getCampaignAttachments } from 'app/schemaInterfaces/GetCampaignAttachments';
import { TableColumns } from 'components/AppTable/AppTable';

export interface SourceFileInterface {
  filename: string;
  userId: string;
  size: string;
  mimetype: string;
  updatedAt: string;
}

export const tableColumns: TableColumns<
  GetCampaignAttachments_getCampaignAttachments
>[] = [
  {
    Header: 'Nom',
    accessor: 'filename',
    sticky: 'left',
    Cell: ({ cell }: CellProps<SourceFileInterface, string>) => (
      <Typography variant="body2">{cell.row.values.filename}</Typography>
    ),
  },
  {
    Header: 'Propriétaire',
    accessor: 'userId',
    sticky: 'left',
    Cell: ({ cell }: CellProps<SourceFileInterface, string>) => (
      <Typography variant="body1">{cell.row.values.userId}</Typography>
    ),
  },

  {
    Header: 'Poids',
    accessor: 'size',
    sticky: 'left',
    Cell: ({ cell }: CellProps<SourceFileInterface, string>) => (
      <Typography variant="body1">{cell.row.values.size} Ko</Typography>
    ),
  },
  {
    Header: 'Type',
    accessor: 'mimetype',
    sticky: 'left',
    Cell: ({ cell }: CellProps<SourceFileInterface, string>) => (
      <Typography variant="body1">{cell.row.values.mimetype}</Typography>
    ),
  },
  {
    Header: 'Date de modification',
    accessor: 'updatedAt',
    sticky: 'left',
    Cell: ({ cell }: CellProps<SourceFileInterface, string>) => (
      <Typography variant="body1">
        {moment(cell.row.values.updatedAt).format('DD-MM-YYYY')}
      </Typography>
    ),
  },
];
