import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { RawResult } from 'leaflet-geosearch/dist/providers/openStreetMapProvider';
import { SearchResult } from 'leaflet-geosearch/dist/providers/provider';
import { useEffect, useState } from 'react';

const OSM_GEOCODING_PROVIDER = new OpenStreetMapProvider();

export interface UseGeocodingQueryParams {
  query?: string;
  skip?: boolean;
}

export interface UseGeocodingQueryResult {
  data?: SearchResult<RawResult>[];
  loading: boolean;
  error?: any;
}

export function useGeocodingQuery({
  query,
  skip = false,
}: UseGeocodingQueryParams): UseGeocodingQueryResult {
  const [result, setResult] = useState<UseGeocodingQueryResult>({
    loading: false,
  });

  useEffect(() => {
    if (skip || !query) {
      setResult({ loading: false });
      return;
    }

    setResult({ loading: true });
    OSM_GEOCODING_PROVIDER.search({ query })
      .then((data) => setResult({ data, loading: false }))
      .catch((error) => setResult({ error, loading: false }));
  }, [query, skip]);

  return result;
}
