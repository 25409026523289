import { useLazyQuery } from '@apollo/client';
import useCurrentEntity from 'app/current-entity-context/useCurrentEntity';
import { GET_ENTITIES_LIST } from 'app/graphql/queries/entities';
import {
  GetEntitiesList,
  GetEntitiesListVariables,
} from 'app/schemaInterfaces/GetEntitiesList';
import { EntitySortBy } from 'app/schemaInterfaces/globalTypes';
import { useEffect } from 'react';

export const useInactiveEntityAlert = () => {
  const [getEntitiesList, { data, loading }] = useLazyQuery<
    GetEntitiesList,
    GetEntitiesListVariables
  >(GET_ENTITIES_LIST);

  const {
    currentEntity,
    fetchAndSetCurrentEntity,
    visibleStructures,
  } = useCurrentEntity();

  useEffect(() => {
    if (!currentEntity) {
      getEntitiesList({
        variables: {
          filters: {
            types: visibleStructures.map(({ type }) => type),
            sortBy: EntitySortBy.level,
          },
        },
      });
    }
  }, [currentEntity, getEntitiesList, visibleStructures]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    fetchAndSetCurrentEntity(event.target.value as string);
  };

  return {
    currentEntity,
    handleChange,
    loading,
    entities: data?.entities,
  };
};
