import React from 'react';
import { DocumentFilterInput } from 'app/schemaInterfaces/globalTypes';
import { useDocumentsFilterPopover } from './DocumentsFilterPopover.hooks';
import FilterPopover from 'components/FilterPopover/FilterPopover';
import FilterOptions from 'components/FilterOptions/FilterOptions';

interface DocumentsFilterPopoverProps {
  mediumTypesOptions: string[];
  numbersOfPagesOptions: number[];
  orderTypesOptions: string[];
  filter: DocumentFilterInput;
  setFilter: React.Dispatch<React.SetStateAction<DocumentFilterInput>>;
}

export const DocumentsFilterPopover = ({
  mediumTypesOptions,
  orderTypesOptions,
  numbersOfPagesOptions,
  filter,
  setFilter,
}: DocumentsFilterPopoverProps): JSX.Element => {
  const {
    popoverId,
    anchorEl,
    handleClick,
    handleClose,
    resetFilter,
    documentsFilterInputs,
  } = useDocumentsFilterPopover({
    mediumTypesOptions,
    orderTypesOptions,
    numbersOfPagesOptions,
    filter,
    setFilter,
  });

  return (
    <FilterPopover
      popoverId={popoverId}
      anchorEl={anchorEl}
      handleClick={handleClick}
      handleClose={handleClose}
    >
      <FilterOptions
        resetFilter={resetFilter}
        filterInputs={documentsFilterInputs}
      />
    </FilterPopover>
  );
};
