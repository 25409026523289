import { Skeleton } from '@material-ui/lab';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import BasketConfirmationTotal from '../BasketConfirmationTotal/BasketConfirmationTotal';
import {
  formatProductPrice,
  formatTransportPrice,
} from 'modules/sales/utils/priceFormatting';
import {
  useBasketConfirmationSummary,
  UseBasketConfirmationSummaryData,
} from './BasketConfirmationSummary.hooks';
import { basketSummaryItemStyle } from './BasketConfirmationSummary.style';
import AlertBox from 'components/AlertBox/AlertBox';

export interface BasketConfirmationSummaryProps
  extends UseBasketConfirmationSummaryData {}

const BasketConfirmationSummary: FC<BasketConfirmationSummaryProps> = ({
  basketDocuments,
}) => {
  const { t } = useTranslation();
  const { total, products, errors } = useBasketConfirmationSummary({
    basketDocuments,
  });

  return errors.length === 0 ? (
    <>
      {products.map(({ product, pricing }) => (
        <dl css={basketSummaryItemStyle} key={product.id}>
          <dt>
            <strong>{product.document.name}</strong> -{' '}
            {product.document.version}
          </dt>
          <dd>
            {typeof pricing?.productSalesPriceExcludingVAT === 'number' ? (
              formatProductPrice(
                pricing.productSalesPriceExcludingVAT,
                pricing.isIncluded,
                t
              )
            ) : (
              <Skeleton width="8ch" />
            )}
          </dd>
          <dt>
            {t('sales.basket_page.delivery_page.delivery_addresses.quantity')}
          </dt>
          <dd>{product.quantity}</dd>
          <dt>
            {t(
              'sales.basket_page.delivery_page.delivery_addresses.delivery_price'
            )}
          </dt>
          <dd>
            {typeof pricing?.transportSalesPriceExcludingVAT === 'number' ? (
              formatTransportPrice(pricing.transportSalesPriceExcludingVAT, t)
            ) : (
              <Skeleton width="8ch" />
            )}
          </dd>
        </dl>
      ))}
      <BasketConfirmationTotal price={total?.totalCost} />
    </>
  ) : (
    <AlertBox
      variant="error"
      header={errors.map((it) => (
        <p>{it}</p>
      ))}
    />
  );
};

export default BasketConfirmationSummary;
