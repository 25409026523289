import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
// import deRessource from 'app/lang/de.json';
import enRessource from 'app/lang/en.json';
import frResource from 'app/lang/fr.json';
import vlsResource from 'app/lang/vls.json';
import formatters from './formatters';

export enum Language {
  // DE = 'de', // temporary
  EN = 'en',
  FR = 'fr',
  VLS = 'vls',
}

i18next.use(initReactI18next).init({
  lng: Language.FR,
  fallbackLng: Language.FR,
  resources: {
    // [Language.DE]: { // temporary
    //   translation: deRessource,
    // },
    [Language.EN]: {
      translation: enRessource,
    },
    [Language.FR]: {
      translation: frResource,
    },
    [Language.VLS]: {
      translation: vlsResource,
    },
  },
  interpolation: {
    escapeValue: false,
    format: function (value, format, lng) {
      if (format && formatters[format]) {
        return formatters[format](value, format, lng);
      }
      return value;
    },
  },
});

export default i18next;
