import { css } from '@emotion/react';
import { Step, StepLabel, Stepper, StepProps } from '@material-ui/core';
import colors from 'styles/colors.module.scss';

interface HorizontalLinearStepperProps {
  steps: string[];
  activeStep: number;
}

const style = css`
  background-color: ${colors.documentsHeaderBackground};
  width: fit-content;
  margin: auto;
  .MuiStepLabel-label {
    color: ${colors.buttonDisabled};
  }
  .MuiStepLabel-active,
  .MuiStepLabel-completed {
    color: ${colors.primary};
  }
  .MuiStepConnector-line {
    border-color: ${colors.buttonDisabled};
    width: 40px;
    margin: auto;
  }
  .MuiStepConnector-active {
    .MuiStepConnector-line {
      border-color: ${colors.primary};
    }
  }

  .MuiStepIcon-root {
    color: ${colors.buttonDisabled};
  }
  .MuiStepIcon-completed {
    color: ${colors.primary};
  }
  .MuiStepIcon-active {
    color: transparent;
    border: 2px solid ${colors.primary};
    border-radius: 50%;
    .MuiStepIcon-text {
      fill: ${colors.primary};
    }
  }
  text {
    font-family: 'ScandiaWebMedium';
  }
`;

const HorizontalLinearStepper = (
  { steps, activeStep }: HorizontalLinearStepperProps,
  { ...stepProps }: StepProps
) => {
  return (
    <Stepper activeStep={activeStep} css={style}>
      {steps.map((label) => {
        return (
          <Step key={label} {...stepProps}>
            <StepLabel>{label}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

export default HorizontalLinearStepper;
