import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const quickSearchBarStyle = (props: {
  borderRadius: string | number;
  bgcolor: string;
  hasBorder: boolean;
}) => css`
		${
      props.hasBorder &&
      css`
        border-width: 2px;
        border-style: solid;
      `
    }
    border-color: ${colors.buttonGroupOutlined};
    border-radius: ${
      typeof props.borderRadius === 'number'
        ? `${props.borderRadius}px`
        : props.borderRadius
    };
    background-color: ${props.bgcolor};
`;

export const quickSearchBarIconStyle = css`
  color: ${colors.grey};
`;
