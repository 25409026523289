import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const basketSummaryItemStyle = css`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-row-gap: 6px;
  grid-column-gap: 16px;

  dd {
    text-align: right;
    font-weight: bold;
  }

  margin-top: 16px;
  border-top: 1px solid ${colors.lightGrey};
  padding-top: 16px;

  &:first-child {
    margin-top: 0;
    border-top: none;
    padding-top: 0;
  }
`;
