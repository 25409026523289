import { gql } from '@apollo/client';

export const CREATE_COMMITMENT = gql`
  mutation CreateCommitment($values: AddCommitmentInput!) {
    createCommitment(values: $values) {
      id
      commitmentCode
      document {
        addresses {
          address {
            id
          }
          quantity
          productName
          productId
          price
        }
      }
    }
  }
`;

export const GET_COMMITMENTS = gql`
  query GetCommitments(
    $filter: CommitmentFilterInput
    $sort: CommitmentSortInput
  ) {
    getCommitments(filter: $filter, sort: $sort) {
      id
      entityId
      entity {
        name
      }
      document {
        support {
          onePacConfig {
            categoryIcon
          }
        }
      }
      commitmentCode
      createdAt
      updatedAt
      totalPrice
      campaign {
        id
        name
        reference
        imageUrl
      }
    }
  }
`;

export const GET_COMMITMENT = gql`
  query GetCommitment($filter: CommitmentByCodeFilterInput!) {
    getCommitment(filter: $filter) {
      ids
      entityId
      commitmentCode
      createdAt
      campaignName
      documents {
        documentId
        name
        version
        thumbnailUrl
        isValidated
        isCustomizable
        addresses {
          address {
            name
          }
          quantity
          productName
          productId
          price
        }
      }
      totalPrice
    }
  }
`;

export const GET_DOCUMENTS_COMMITMENTS = gql`
  query GetDocumentsCommitments($filter: CommitmentFilterInput!) {
    documentsCommitments(filter: $filter) {
      commitments {
        id
        commitmentCode
        createdAt
        entityId
        entity {
          name
        }
        totalPrice
        campaign {
          id
          name
        }
        document {
          date
          addresses {
            address {
              city
              country
              deliveryDepartment
              firstAddress
              id
              name
              postBox
              secondAddress
              thirdAddress
              zipCode
            }
            quantity
            recommendedQuantity
            entityName
            productName
            productId
            price
          }
          comment
          documentId
        }
      }
      deliveryPointCount
      quantity
      recommendedQuantity
      document {
        id
        name
        version
        thumbnailUrl
        isCustomizable
      }
      documentIds
    }
  }
`;

export const EXPORT_DISTRIBUTION = gql`
  query ExportDistribution(
    $campaignId: String!
    $documentIds: [String!]!
    $isCommitment: Boolean!
  ) {
    exportDistribution(
      campaignId: $campaignId
      documentIds: $documentIds
      isCommitment: $isCommitment
    ) {
      url
      name
    }
  }
`;

export const EXPORT_GLOBAL_COMMITMENTS = gql`
  query ExportGlobalCommitments(
    $campaignId: String!
    $documentIds: [[String!]!]!
  ) {
    exportGlobalCommitments(
      campaignId: $campaignId
      documentIds: $documentIds
    ) {
      url
      name
    }
  }
`;

export const DELETE_DOCUMENTS_COMMITMENTS = gql`
  mutation DeleteDocumentsCommitments($documentIds: [String!]!) {
    deleteCommitmentsDocument(documentIds: $documentIds)
  }
`;

export const EXPORT_PDF_ZIP = gql`
  query ExportZip($campaignId: String!, $documentIds: [String!]!) {
    exportZip(campaignId: $campaignId, documentIds: $documentIds) {
      url
    }
  }
`;

export const SEND_COMMITMENT_REMINDER = gql`
  query SendCommitmentReminder($campaignId: String!) {
    sendCommitmentReminder(campaignId: $campaignId)
  }
`;
