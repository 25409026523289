import { css } from '@emotion/react';

export const followOrdersToolBarTopBarStyle = css`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  h1 {
    margin: 0;
  }
`;

export const followOrdersToolBarBottomBarStyle = css`
  margin-bottom: 24px;
`;
