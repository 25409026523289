import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { SocialNetwork } from '../CompetitorsContainer/CompetitorsContainer.definition';

export const schema = (t: TFunction) =>
  Yup.object({
    socialNetwork: Yup.string().required(t('common.generic_error.required')),
    url: Yup.string().when('socialNetwork', (socialNetwork) => {
      if (socialNetwork === SocialNetwork.Facebook) {
        return Yup.string()
          .required(t('common.generic_error.required'))
          .matches(
            new RegExp('^(https?://){0,1}(www.){0,1}facebook.com'),
            t('common.generic_error.incorrect_format')
          );
      } else {
        return Yup.string().required(t('common.generic_error.required'));
      }
    }),
  });
