import { useQuery } from '@apollo/client';
import React, { PropsWithChildren } from 'react';
import Loader from '../../components/Loader/Loader/Loader';
import Splash from '../../components/Splash/Splash';
import { GET_CURRENT_CLIENT } from '../graphql/queries/client';
import { GetCurrentClient } from '../schemaInterfaces/GetCurrentClient';
import AppContext from './AppContext';

function AppContextProvider({ children }: PropsWithChildren<{}>): JSX.Element {
  const { data, loading, error } = useQuery<GetCurrentClient>(
    GET_CURRENT_CLIENT
  );

  if (loading) {
    return <Loader height="100vh" />;
  }

  if (error || !data) {
    return <Splash title="Error" body={error?.message} homeButton />;
  }

  return (
    <AppContext.Provider
      value={{
        client: data.client,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export default AppContextProvider;
