import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import QuickSearchBar from 'components/QuickSearchBar/QuickSearchBar';
import colors from 'styles/colors.module.scss';
import useDebounce from 'components/useDebounce';
import { AssetsPanelActionCreator } from 'modules/workflow/containers/Leftsidebar/AssetsPanel/reducer/actions';
import { AssetsPanelContext } from 'modules/workflow/containers/Leftsidebar/AssetsPanel/reducer/context';

interface AssetPanelSearchProps {
  text: string;
}

const AssetPanelSearch = ({ text }: AssetPanelSearchProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useContext(AssetsPanelContext);

  const initialRender = useRef<boolean>(true);

  const [value, setValue] = useState(text);
  const debouncedValue = useDebounce(value, 500);

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    setValue(text);
  }, [text]);

  useEffect(() => {
    // skip dispatch on initial render
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    if (debouncedValue !== undefined && dispatch) {
      dispatch?.(AssetsPanelActionCreator.updateSearch(debouncedValue));
    }
  }, [debouncedValue, dispatch]);

  return (
    <QuickSearchBar
      placeholder={t(
        'workflow.left_side_bar.medias_panel.search_bar_placeholder'
      )}
      inputBaseWidth="100%"
      value={value}
      bgcolor={colors.white}
      onChange={handleChange}
    />
  );
};

export default AssetPanelSearch;
