import moment from 'moment';
import { GetAllCampaigns_getAllCampaigns_campaigns } from 'app/schemaInterfaces/GetAllCampaigns';
import { GetCampaignById_getCampaignById } from 'app/schemaInterfaces/GetCampaignById';
import { GetDocuments_documents_documents } from 'app/schemaInterfaces/GetDocuments';

export const getFreeOrderLatestDate = (
  campaign:
    | GetAllCampaigns_getAllCampaigns_campaigns
    | GetCampaignById_getCampaignById
) =>
  Array.isArray(campaign.documents)
    ? campaign.documents
        ?.filter(
          (document: { freeOrderDateTo: any }) => document.freeOrderDateTo
        )
        ?.map((document: { freeOrderDateTo: any }) => document.freeOrderDateTo)
        .reduce(function (first: number, second: number) {
          return first > second ? first : second;
        }, 0)
    : null;

export const getFreeOrderEarlierDate = (
  campaign:
    | GetAllCampaigns_getAllCampaigns_campaigns
    | GetCampaignById_getCampaignById
) =>
  Array.isArray(campaign.documents)
    ? campaign.documents
        ?.filter(
          (document: { freeOrderDateFrom: any }) => document.freeOrderDateFrom
        )
        ?.map(
          (document: { freeOrderDateFrom: any }) => document.freeOrderDateFrom
        )
        .reduce(function (first: number, second: number) {
          return first < second ? first : second;
        }, 0)
    : null;

export const isFreeOrderTerminated = (date: any) => {
  return moment(date).isBefore(moment(new Date()));
};

export const getLastFreeOrderDate = (
  documents?: GetDocuments_documents_documents[]
): string | undefined =>
  Boolean(documents?.length)
    ? documents?.reduce((firstDocument, secondDocument) =>
        new Date(firstDocument.freeOrderDateTo) >
        new Date(secondDocument.freeOrderDateTo)
          ? firstDocument
          : secondDocument
      ).freeOrderDateTo
    : undefined;
