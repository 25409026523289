import React from 'react';

import 'modules/workflow/Clients/ToutFaire/ProductTemplate.css';
import 'modules/workflow/Clients/ToutFaire/index.css';

import { ZoneProps } from 'modules/workflow/oneStudioContext';
import ProductItem, {
  IProductTemplate,
} from 'modules/workflow/Clients/ToutFaire/ProductItem';

interface ProductTemplateProps {
  productFromPlatform: NonNullable<ZoneProps['product']>;
  pageIndex: number;
  zoneIndex: number;
  personalizationKey?: string;
  isDefaultProduct: boolean;
  isDraggableResizable?: boolean;
  isItemPersonalization?: boolean;
}

const ProductTemplate = ({
  pageIndex,
  zoneIndex,
  personalizationKey,
  productFromPlatform,
  isDefaultProduct,
  isDraggableResizable = true,
}: ProductTemplateProps): JSX.Element => {
  const products: IProductTemplate[] = productFromPlatform.map((product) => {
    return {
      ...product,
      ...(product.components && { components: product.components }),
    };
  });

  return (
    <div className="container handle">
      {products.map((product: IProductTemplate, index: number) => (
        <ProductItem
          key={index}
          product={product}
          zoneIndex={zoneIndex}
          pageIndex={pageIndex}
          isDefaultProduct={isDefaultProduct}
          isDraggableResizable={isDraggableResizable}
          personalizationKey={personalizationKey}
        />
      ))}
    </div>
  );
};

export default ProductTemplate;
