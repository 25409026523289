import React from 'react';
import { Divider, Grid, Link, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useCurrentEntity from 'app/current-entity-context/useCurrentEntity';
import moment from 'moment';
import { summaryPublicationDeliveryDetailsStyle } from './SummaryPublicationDeliveryDetails.style';
import { SummaryPublicationDeliveryDetailsProps } from './SummaryPublicationDeliveryDetails.definition';

const SummaryPublicationDeliveryDetails = ({
  deliveryDate,
}: SummaryPublicationDeliveryDetailsProps) => {
  const { currentEntity } = useCurrentEntity();
  const { t } = useTranslation();
  return (
    <Grid
      container
      css={summaryPublicationDeliveryDetailsStyle}
      alignItems="center"
      data-testid="publication-delivery-details"
    >
      {currentEntity?.socialNetworks?.clientNetworkLink && (
        <Grid item xs={8} className="link">
          <Tooltip
            placement="bottom-start"
            title={currentEntity?.socialNetworks?.clientNetworkLink}
          >
            <Link
              variant="body1"
              href={currentEntity?.socialNetworks?.clientNetworkLink}
              style={{
                overflow: 'hidden',
              }}
            >
              <p>{currentEntity?.socialNetworks?.clientNetworkLink}</p>
            </Link>
          </Tooltip>
          <Divider orientation="vertical" />
        </Grid>
      )}
      <Grid item xs={4}>
        {deliveryDate && (
          <p>
            {t('sales.basket_page.summary_page.delivery_addresses.publication')}{' '}
            :{' '}
            {moment() >= deliveryDate
              ? t('sales.basket_page.summary_page.delivery_addresses.now')
              : moment(deliveryDate).format('DD/MM/YYYY - HH:mm')}
          </p>
        )}
      </Grid>
    </Grid>
  );
};

export default SummaryPublicationDeliveryDetails;
