import React, { FC } from 'react';
import { Button } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import {
  addressBlockUploadContentStyle,
  addressBlockUploadDropzoneStyle,
  addressBlockUploadFieldStyle,
  addressBlockUploadIconStyle,
  addressBlockUploadLabelStyle,
} from './UploadAddressBlock.style';

export interface UploadAddressBlockProps {
  buttonLabel: string;
  onFileChange: (event: File[]) => void;
  acceptedFiles: string | string[];
  isMultiple?: boolean;
  isDisabled?: boolean;
  'data-testid'?: string;
}

const UploadAddressBlock: FC<UploadAddressBlockProps> = ({
  onFileChange,
  acceptedFiles,
  buttonLabel,
  isMultiple = false,
  isDisabled = false,
  'data-testid': dataTestId = 'fileinput',
}) => {
  const { t } = useTranslation();

  const { getInputProps, acceptedFiles: files } = useDropzone({
    onDrop: (files: File[]) => {
      onFileChange(files);
    },
    accept: acceptedFiles,
    multiple: isMultiple,
  });

  const uploadedFile = files.length > 0 ? files[0].name : undefined;

  return (
    <div css={addressBlockUploadFieldStyle}>
      <div css={addressBlockUploadContentStyle}>
        <span css={addressBlockUploadIconStyle}>
          <img
            src={
              uploadedFile
                ? `/${process.env.REACT_APP_ASSETS}/add_attachment_ok.svg`
                : `/${process.env.REACT_APP_ASSETS}/add_attachment.svg`
            }
            alt="label"
            data-testid="illustration"
          />
        </span>
        <span css={addressBlockUploadLabelStyle} data-testid="label">
          {uploadedFile ?? buttonLabel}
        </span>
      </div>

      <Button
        color="primary"
        variant="outlined"
        component="label"
        // startIcon={icon}
        disabled={isDisabled}
        className="upload-button"
      >
        {t('one_manager.store_information.address_block_page.browse')}
        <input
          css={addressBlockUploadDropzoneStyle}
          {...getInputProps()}
          data-testid={dataTestId}
        />
      </Button>
    </div>
  );
};

export default UploadAddressBlock;
