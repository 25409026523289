import React from 'react';
import { formatPrice, truncateString } from 'app/utils/common';
import { useTranslation } from 'react-i18next';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import {
  GetDocumentsToShop_documents_documents_priceInformations,
  GetDocumentsToShop_documents_documents_documentCommitmentCode,
} from 'app/schemaInterfaces/GetDocumentsToShop';

interface DocumentsGridViewDetailsProps {
  documentCommitmentCode: GetDocumentsToShop_documents_documents_documentCommitmentCode | null;
  priceInformations: GetDocumentsToShop_documents_documents_priceInformations | null;
}

const DocumentsGridViewDetails = ({
  documentCommitmentCode,
  priceInformations,
}: DocumentsGridViewDetailsProps) => {
  const { t } = useTranslation();

  if (documentCommitmentCode) {
    return (
      <div className="commitment-info" data-testId="commitment-info">
        <div className="commitment">
          <LocalOfferOutlinedIcon className="icon" />
          <p>{truncateString(documentCommitmentCode.commitmentCode, 25)}</p>
        </div>
        <div className="commitment">
          <EventOutlinedIcon className="icon" />
          <p>{documentCommitmentCode.createdAt}</p>
        </div>
        <div className="commitment">
          <ShoppingCartOutlinedIcon className="icon" />
          <p> {documentCommitmentCode.commitmentQuantity}</p>
        </div>
        <p className="commitment-quantity"></p>
      </div>
    );
  } else if (priceInformations) {
    return (
      <div className="price-box" data-testId="price-info">
        <p className="starting-date">
          {priceInformations.isIncluded
            ? ''
            : t('local.campaign_page.starting_price')}
        </p>
        <p className="price">
          {priceInformations.isIncluded ? (
            t('local.campaign_page.included')
          ) : (
            <>
              {formatPrice(priceInformations.priceMinimum, t)}{' '}
              <sup> {t('common.money_unit_tax_free')}</sup>
            </>
          )}
        </p>
        {!priceInformations.isIncluded && (
          <p className="minimum-quantity">
            {t('local.campaign_page.price_per_quantity', {
              quantityMinimum: priceInformations.quantityMinimum,
            })}
          </p>
        )}
      </div>
    );
  } else return null;
};

export default DocumentsGridViewDetails;
