import React, { FC, PropsWithChildren } from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
} from 'react-router-dom';
import { PermissionKey, UserType } from 'app/schemaInterfaces/globalTypes';
import { AppRoute } from 'app/routes/routes';
import { useProtectedRoute } from './ProtectedRoute.hook';
import { ModuleKey } from 'app/routes/helper';

export interface AppRouteProps extends RouteProps {
  path: string;
  open?: boolean;
  userType?: UserType;
  permission?: PermissionKey[] | PermissionKey;
  Component?: (props: PropsWithChildren<RouteComponentProps>) => JSX.Element;
  offer?: ModuleKey;
}

// TODO: Refactor the routing system
const ProtectedRoute: FC<PropsWithChildren<AppRouteProps>> = ({
  path,
  exact,
  open,
  userType,
  permission,
  Component,
  children,
  offer,
}) => {
  const { isAuthenticated, isAuthorized } = useProtectedRoute(
    path,
    open,
    userType,
    permission,
    offer
  );

  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => {
        return open ? (
          <div data-testid="route">
            {Component ? <Component {...props} /> : children}
          </div>
        ) : //TODO: maybe specifying isAuthenticated is redundant, to be redefined when we move away from Auth0
        isAuthenticated && isAuthorized ? (
          <>{Component ? <Component {...props} /> : children}</>
        ) : (
          <Redirect
            to={{
              pathname: AppRoute.AccessDenied,
            }}
          />
        );
      }}
    />
  );
};

export default ProtectedRoute;
