import EntityLogo from 'components/Navigation/user-menu/entity-menu/EntityLogo/EntityLogo';
import React, { FC } from 'react';
import { entityCardstyle } from './EntityCard.style';

interface EntityCardProps {
  name: string;
  logo?: string;
  title?: string;
  hideLogo?: boolean;
  className?: string;
}

const EntityCard: FC<EntityCardProps> = ({
  name,
  logo,
  title,
  hideLogo,
  className,
}) => {
  return (
    <div css={entityCardstyle} className={className} data-testid="entity-card">
      <EntityLogo name={name} logo={logo} isHidden={hideLogo} />
      <div className="title-container">
        {title && <p className="title">{title}</p>}
        <p className="name">{name}</p>
      </div>
    </div>
  );
};

export default EntityCard;
