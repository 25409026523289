import { ApolloError } from '@apollo/client';
import { TFunction } from 'i18next';
import { truncateString } from './common';

export function getGqlErrorMessage(
  error: ApolloError | null,
  i18nKeyErrorDictionary: Map<string, string> = new Map(),
  t: TFunction
) {
  const extractedI18nErrorsKeys =
    error?.graphQLErrors.reduce<string[]>(
      (prev, err) => [
        ...prev,
        ...((err?.extensions?.code === 'BAD_USER_INPUT' &&
          err?.extensions?.reasons?.reduce(
            (prev: string[], r: string) =>
              i18nKeyErrorDictionary.has(r)
                ? [...prev, i18nKeyErrorDictionary.get(r)]
                : prev,
            []
          )) ||
          []),
      ],
      []
    ) || [];
  if (extractedI18nErrorsKeys.length) {
    return extractedI18nErrorsKeys.map((err) => t(err));
  } else {
    return [
      process.env.NODE_ENV !== 'production'
        ? truncateString(error?.message, 160) ?? t('common.actions.error')
        : t('common.actions.error'),
    ];
  }
}
