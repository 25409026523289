import React from 'react';
import { CellProps, Row, HeaderProps } from 'react-table';
import {
  Box,
  Typography,
  Checkbox,
  createStyles,
  Switch,
  Theme,
  withStyles,
} from '@material-ui/core';
import { TableColumns } from 'components/AppTable/AppTable';
import BallotOutlinedIcon from '@material-ui/icons/BallotOutlined';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import LibraryAddOutlinedIcon from '@material-ui/icons/LibraryAddOutlined';
import { TabPanelProps } from 'components/TabPanel';
import {
  GetCurrentClient_client_config,
  GetCurrentClient_client_config_pim_listConfig,
} from 'app/schemaInterfaces/GetCurrentClient';
import {
  ProductsDetailsWithHisId,
  GetProductsWithAssets,
} from 'modules/pim/baseProducts/BaseProductsList';
import moment from 'moment';
import { ApolloQueryResult, useApolloClient } from '@apollo/client';
import {
  ExportMultipleProducts,
  ExportMultipleProductsVariables,
} from 'app/schemaInterfaces/ExportMultipleProducts';
import { EXPORT_MULTIPLE_PRODUCTS } from 'app/graphql/queries/products';
import { GET_DIVISIONS_getDivisions } from 'app/schemaInterfaces/GET_DIVISIONS';
import { getAssetTypeOptions } from 'modules/dam/form/helpers';
import {
  FileStore,
  PimFieldType,
} from 'modules/pim/baseProducts/PimFormFieldsConfig';
import {
  AssetType,
  AssetStatus,
  AssetInput,
  MetadataInput,
  ProductHistoryInput,
  VisualType,
  UserType,
} from 'app/schemaInterfaces/globalTypes';
import * as Yup from 'yup';
import { TFunction } from 'i18next';
import {
  GetAssetById,
  GetAssetById_getAssetById_metadata_dimensions,
} from 'app/schemaInterfaces/GetAssetById';
import { GetProductById_getProduct_history } from 'app/schemaInterfaces/GetProductById';
import { HistoryWithFieldType } from 'modules/pim/components/ProductSheetHistory';
import { DEFAULT_IMAGE_PREVIEW, truncateString } from 'app/utils/common';
import { GetCampaignById_getCampaignById } from 'app/schemaInterfaces/GetCampaignById';
import { GetCurrentUser_user } from 'app/schemaInterfaces/GetCurrentUser';
import { GetEntities_entities } from 'app/schemaInterfaces/GetEntities';
import { useTranslation } from 'react-i18next';
import { useDownloadFileErrorWorkflow } from 'app/utils/customHooks/useDownloadFileErrorWorkflow';

export const getProductsColumns = (
  clientConfig?: GetCurrentClient_client_config | null
) => {
  let count: number = 0;
  const newColumns: TableColumns<ProductsDetailsWithHisId>[] = [
    {
      id: '_selector',
      maxWidth: 45,
      Header: ({ getToggleAllRowsSelectedProps }: HeaderProps<any>) => (
        <Checkbox size="small" {...getToggleAllRowsSelectedProps()} />
      ),
      Cell: ({ row }: CellProps<any>) => (
        <Checkbox size="small" {...row.getToggleRowSelectedProps()} />
      ),
      sticky: 'left',
    },
  ];
  return clientConfig?.pim.listConfig.map(
    (columnsFromQuery?: GetCurrentClient_client_config_pim_listConfig) => {
      count++;
      newColumns.push({
        Header: columnsFromQuery?.label as string,
        accessor: columnsFromQuery?.key as string,
        width: 230,
        number: count,
        sticky: count < 5 ? 'left' : '',
        Cell: ({ cell }: CellProps<Record<string, any>, string>) => {
          for (const [key, value] of Object.entries(cell.row.values)) {
            if (columnsFromQuery?.key === key) {
              if (typeof value === 'boolean' && value) {
                return (
                  <Box>
                    <Typography variant="body1">x</Typography>
                  </Box>
                );
              }
              if ((cell.column as Record<string, any>).number === 1) {
                return (
                  <img
                    height="48px"
                    width="48px"
                    src={cell.row.original.assetUrl}
                    alt={value}
                    style={{ objectFit: 'contain' }}
                  />
                );
              }
              return (
                <Box whiteSpace="normal" style={{ wordBreak: 'break-all' }}>
                  <Typography variant="body1">
                    {value && truncateString(value, 50)}
                  </Typography>
                </Box>
              );
            }
          }
        },
      });
      return newColumns;
    }
  )[0];
};

export interface createProductSheetActionsInterface {
  open?: () => void;
  handleOpenProductsSheetDialog: () => void;
  campaignId?: string | null;
  t: TFunction;
}

export const createProductSheetActions = ({
  open,
  handleOpenProductsSheetDialog,
  t,
}: createProductSheetActionsInterface) => [
  {
    title: t('pim.helper.product'),
    description: t('pim.helper.create_product_manually'),
    onClick: () => handleOpenProductsSheetDialog(),
  },
  {
    title: t('pim.helper.products'),
    description: t('pim.helper.import_excel_file'),
    onClick: open,
  },
];

export const createProductSheetForOperationActions = ({
  open,
  handleOpenProductsSheetDialog,
  campaignId,
  t,
}: createProductSheetActionsInterface) => [
  {
    title: t('pim.helper.import_from_base'),
    icon: <BallotOutlinedIcon />,
    to: `/campaign/${campaignId}/productSheet/import`,
  },
  {
    title: t('pim.helper.import_file'),
    icon: <GetAppOutlinedIcon />,
    onClick: open,
  },
  {
    title: t('pim.helper.create_new'),
    icon: <LibraryAddOutlinedIcon />,
    onClick: () => handleOpenProductsSheetDialog(),
  },
];

export const queryUrlForUpload = process.env.REACT_APP_API_ENDPOINT;

interface TabPanelProductsFormProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  fieldName?: string;
}

export const TabPanelProductsForm = ({
  children,
  index,
  value,
  fieldName,
  ...tabPanelProps
}: TabPanelProductsFormProps & Partial<TabPanelProps>): JSX.Element => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...tabPanelProps}
    >
      {value === index && (
        <Box
          component="div"
          height={680}
          {...(!fieldName && { overflow: 'auto' })}
        >
          {children}
        </Box>
      )}
    </div>
  );
};

interface productsDetailsWithHisIdProps {
  productsSheets?: GetProductsWithAssets[];
}

export const productsDetailsWithHisId = ({
  productsSheets,
}: productsDetailsWithHisIdProps) => {
  return productsSheets?.reduce<ProductsDetailsWithHisId[]>(
    (newProductsSheets, oldProducsSheets) => {
      const { _id, assets } = oldProducsSheets ?? {};
      newProductsSheets?.push({
        ...oldProducsSheets.details,
        productId: _id,
        assetUrl: assets?.displayedThumbUrl ?? DEFAULT_IMAGE_PREVIEW,
      });
      return newProductsSheets;
    },
    []
  );
};

const exportDate = moment().format('DD/MM/YYYY');

export const useDownloadProductsSheet = (
  campaign?: GetCampaignById_getCampaignById
) => {
  const client = useApolloClient();
  const { t } = useTranslation();
  const downloadFile = useDownloadFileErrorWorkflow();

  return async (selectedRows: Row<Record<string, any>>[]) => {
    if (campaign) {
      const exportProductsSheet = await client.query<
        ExportMultipleProducts,
        ExportMultipleProductsVariables
      >({
        query: EXPORT_MULTIPLE_PRODUCTS,
        variables: {
          productIds: selectedRows.map(({ original }) => original.productId),
        },

        /*
				because the cache is not updated when a product is modified
				i have to use this so the query takes the data from the
				back-end and not from the cache
			*/
        fetchPolicy: 'network-only',
      });
      downloadFile({
        url: exportProductsSheet.data.exportProducts,
        filename: `${campaign.name ?? ''}-${t(
          'pim.helper.file_name'
        )} ${exportDate}`,
        mimetype: 'application/vnd.ms-excel',
      });
    } else {
      const exportProductsSheet = await client.query<
        ExportMultipleProducts,
        ExportMultipleProductsVariables
      >({
        query: EXPORT_MULTIPLE_PRODUCTS,
        variables: {
          productIds: selectedRows.map(({ original }) => original.productId),
        },

        /*
				because the cache is not updated when a product is modified
				i have to use this so the query takes the data from the
				back-end and not from the cache
			*/
        fetchPolicy: 'network-only',
      });
      downloadFile({
        url: exportProductsSheet.data.exportProducts,
        filename: `${t('pim.helper.file_name')} ${exportDate}`,
        mimetype: 'application/vnd.ms-excel',
      });
    }
  };
};

export const getDivisionLabel = (
  divisions: GET_DIVISIONS_getDivisions[],
  divisionsLabels: string[] = []
) => {
  divisionsLabels.push(divisions[0]?.key);

  if (divisions[0]?.valuesTree !== undefined) {
    getDivisionLabel(divisions[0].valuesTree, divisionsLabels);
  }

  return divisionsLabels;
};

export const getAssetsDivisionsForProductsSheets = (
  values: Record<string, any>,
  assetsDivisions: Record<string, any>,
  divisionsLabels: string[]
) => {
  return Object.entries(values).forEach(([key, value]) => {
    if (divisionsLabels.includes(key)) {
      assetsDivisions.push({ divisionName: key, value });
    }
  });
};

export const getAssetsType = (fieldName: string, t: TFunction) => {
  return getAssetTypeOptions(t).filter(({ label }) => {
    if (fieldName.includes((label as string).toLowerCase())) {
      return label;
    }
    return null;
  })[0];
};

export interface modifyFilesActionsActionsProps {
  updateFiles: (files: FileStore) => void;
  filteredFile: FileStore;
  t: TFunction;
}

export const modifyFilesActions = ({
  updateFiles,
  filteredFile,
  t,
}: modifyFilesActionsActionsProps) => [
  {
    title: t('pim.helper.replace_visual'),
    onClick: () => updateFiles(filteredFile),
  },
];

export const filteredFilesByFieldName = (
  filesManager: FileStore[],
  currentFieldName: string
) =>
  filesManager?.filter(
    (filteredFiles) => filteredFiles.fieldName === currentFieldName
  );

const checkIfAssetIsVisible = (
  campaignId: string | undefined,
  updateParentproduct: boolean
) => {
  if (campaignId && updateParentproduct) {
    return true;
  }
  if (campaignId && !updateParentproduct) {
    return false;
  }
  return true;
};

export const newAsset = (
  formFile: FileStore,
  assetsDivisions: Record<string, any>,
  campaignId: string | undefined,
  updateParentproduct: boolean
) => {
  const asset: AssetInput = {
    name: formFile.fieldFile?.path,
    assetType: formFile.fileAssetsType,
    visualType:
      formFile.fileAssetsType === AssetType.Visual ? VisualType.Packshot : null,
    division:
      formFile.fileAssetsType === AssetType.Visual &&
      assetsDivisions.reduce(
        (
          obj: Record<string, any>,
          item: { divisionName: string; value: string }
        ) => ({ ...obj, [item.divisionName]: item.value }),
        {}
      ),
    status: AssetStatus.Active,
    metadata: formFile.metadata as MetadataInput,
    isVisible: checkIfAssetIsVisible(campaignId, updateParentproduct),
  };
  return asset;
};

export const updatedIsVisibleAsset = (
  asset: ApolloQueryResult<GetAssetById>,
  campaignId: string | undefined,
  updateParentproduct: boolean
) => {
  const {
    credit,
    fileHasCredit,
    dimensions,
    fileSize,
    mimetype,
  } = asset.data.getAssetById.metadata;
  const {
    height,
    width,
  } = dimensions as GetAssetById_getAssetById_metadata_dimensions;
  const newAsset: AssetInput = {
    metadata: {
      credit,
      fileHasCredit,
      dimensions: { height, width },
      fileSize,
      mimetype,
    },
    isVisible: checkIfAssetIsVisible(campaignId, updateParentproduct),
  };
  return newAsset;
};

export const displayableFormats = [
  'image/png',
  'image/jpeg',
  'image/jpg',
  'image/svg+xml',
  'application/pdf',
];
export const FILTER_BY_DIVISIONS = 'FILTER_BY_DIVISIONS';
export const FILTER_BY_IF_WITH_FILES = 'FILTER_BY_IF_WITH_FILES';
export const FILTER_BY_IF_WITH_PRICE = 'FILTER_BY_IF_WITH_PRICE';
export const RESET_FILTERS = 'RESET_FILTERS';
export const SEARCH_BAR_FILTER = 'SEARCH_BAR_FILTER';

export enum unifiedKeyField {
  Price = 'price',
  ProductCode = 'productcode',
  Title = 'title',
  Description = 'description',
  InternalCode = 'internalcode',
  Divisions = 'divisions',
  Productcolor = 'color',
}

export const getUnifiedKeyFromPimClientConfig = (
  unifiedKey: string,
  clientConfig?: GetCurrentClient_client_config | null
) => {
  const requiredField = clientConfig?.pim.requiredFields.find(
    (field) => field.unifiedKey === unifiedKey
  );
  return requiredField?.key as string;
};

export const getLabelByUnifiedKeyFromPimClientConfig = (
  unifiedKey: string,
  clientConfig?: GetCurrentClient_client_config | null
) => {
  const requiredField = clientConfig?.pim.requiredFields.find(
    (field) => field.unifiedKey === unifiedKey
  );
  return requiredField?.placeholder as string;
};

export interface editProductInterface {
  open?: () => void;
  handleOpenProductsSheetDialog: () => void;
  productId?: string | null;
}
export const editProduct = ({
  handleOpenProductsSheetDialog,
  open,
  productId,
}: editProductInterface) => [
  { onClick: () => handleOpenProductsSheetDialog() },
];

//TODO : temporary. has to be generic
export enum VisuelFieldName {
  mainVisuel = 'visuelprincipal',
  secondVisuel = 'visuelassocie1',
}

//TODO : temporary. has to be generic
export enum TextFieldName {
  lePlus = 'texteplusproduit1',
  lePlusBis = 'texteplusproduit2',
}

export const isLePlus = (name: string | undefined): boolean =>
  name === TextFieldName.lePlus || name === TextFieldName.lePlusBis;

export const productFormValidation = (
  t: TFunction,
  clientConfig?: GetCurrentClient_client_config | null
) => {
  return Yup.object({
    [getUnifiedKeyFromPimClientConfig(
      unifiedKeyField.ProductCode,
      clientConfig
    )]: Yup.string().matches(/^\d+$/, t?.('pim.form.validation_field')),
  });
};

const notWantedFields = ['assetUrl', 'filesToSend'];

const historyValuesTypesManager = (
  value: string | boolean | number | string[]
): string => {
  if (typeof value === 'boolean') {
    const VALIDATED = 'X';
    return value ? VALIDATED : '';
  }
  if (!value) {
    return '';
  }
  if (Array.isArray(value)) {
    return value.join(', ');
  }
  if (typeof value === 'number') {
    return (value as number).toString();
  }
  return value;
};

export const getFormDifference = (
  historyData: ProductHistoryInput[],
  initialValues: Record<string, any>,
  values: Record<string, any>
) => {
  Object.keys(values).filter((fieldName) => {
    if (
      !notWantedFields.includes(fieldName) &&
      historyValuesTypesManager(initialValues[fieldName]) !==
        historyValuesTypesManager(values[fieldName])
    ) {
      historyData.push({
        fieldName: fieldName,
        oldValue: historyValuesTypesManager(initialValues[fieldName]),
        newValue: historyValuesTypesManager(values[fieldName]),
      });
    }
    return [];
  });
};

export enum HistoryType {
  ProductHistory = 'ProductHistory',
  RegroupingHistory = 'RegroupingHistory',
}

export const getHistoryWithFieldType = (
  productSheetHistory?: GetProductById_getProduct_history[],
  campaignProductSheetHistory?: GetProductById_getProduct_history[],
  clientConfig?: GetCurrentClient_client_config | null,
  divisionsLabels?: string[]
) => {
  const productHistory = productSheetHistory ?? campaignProductSheetHistory;
  return productHistory?.reduce<HistoryWithFieldType[]>(
    (historyWithTypesFields, oldHistory) => {
      clientConfig?.pim.requiredFields.forEach((field) => {
        if (field.key === oldHistory.fieldName) {
          historyWithTypesFields?.push({
            ...oldHistory,
            fieldType: field.type,
            historyType: HistoryType.ProductHistory,
          });
        }
        if (oldHistory.fieldName.includes('-')) {
          if (field.key === oldHistory.fieldName.split('-')[0]) {
            historyWithTypesFields?.push({
              ...oldHistory,
              fieldName: oldHistory.fieldName.split('-')[0],
              fieldType: field.type,
              historyType: HistoryType.RegroupingHistory,
              productId: oldHistory.fieldName.split('-')[1],
            });
          }
        }
        if (!!field.valueHardcoded?.length && field.valueHardcoded) {
          return field.valueHardcoded.forEach(
            (value: { key: string; type: string }) => {
              if (value.key === oldHistory.fieldName) {
                historyWithTypesFields?.push({
                  ...oldHistory,
                  fieldType: value.type,
                  historyType: HistoryType.ProductHistory,
                });
              }
            }
          );
        }
      });
      if (divisionsLabels?.includes(oldHistory.fieldName)) {
        historyWithTypesFields?.push({
          ...oldHistory,
          fieldType: PimFieldType.Text,
          historyType: HistoryType.ProductHistory,
        });
      }
      return historyWithTypesFields;
    },
    []
  );
};

export const getEntityIdAndHisAncestorId = (
  user: GetCurrentUser_user,
  currentEntity: GetEntities_entities,
  setFieldEntityId: React.Dispatch<React.SetStateAction<string>>
) => {
  return user.entities.forEach((userEntity) => {
    if (user.type === UserType.Global) {
      if (userEntity.id.includes(currentEntity.id)) {
        setFieldEntityId?.(userEntity.id);
      } else {
        return currentEntity.ancestors.forEach((ancestorEntity) => {
          if (userEntity.id.includes(ancestorEntity?.id as string)) {
            setFieldEntityId?.(userEntity.id);
          }
        });
      }
    } else {
      return userEntity?.ancestors.forEach((ancestorEntity) => {
        if (ancestorEntity.isBillable) {
          setFieldEntityId?.(ancestorEntity.id);
        }
      });
    }
  });
};

export const RegroupingSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 36,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(20px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 16,
      height: 16,
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  })
)(Switch);
