import React, { useState, useEffect } from 'react';
import useDebounce from 'components/useDebounce';
import QuickSearchBar from 'components/QuickSearchBar/QuickSearchBar';

interface RegroupingSearchBarProps {
  placeholder?: string;
  bgcolor?: string;
  hasBorder?: boolean;
  inputBaseWidth?: string;
  handleOnSearchProduct: (value: string) => void;
}

const RegroupingSearchBar = ({
  placeholder,
  bgcolor = 'transparent',
  hasBorder = true,
  inputBaseWidth = '220px',
  handleOnSearchProduct,
}: RegroupingSearchBarProps): JSX.Element => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    if (debouncedSearchTerm !== undefined) {
      handleOnSearchProduct(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, handleOnSearchProduct]);

  const onChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearchTerm(event.target.value);
  };

  return (
    <QuickSearchBar
      placeholder={placeholder}
      onChange={onChange}
      value={searchTerm}
      inputBaseWidth={inputBaseWidth}
      bgcolor={bgcolor}
      hasBorder={hasBorder}
    />
  );
};

export default RegroupingSearchBar;
