import { css } from '@emotion/react';
import colors from '../../../../styles/colors.module.scss';
import { theme } from '../../../../styles/theme';

export default css`
  margin-top: 20px;
  margin-left: 60px;
  margin-right: 60px;
  h1 {
    color: ${colors.body};
    margin-left: 5px;
  }
  #mutiple-checkbox {
    height: 30px;
    padding: 6px 24px 6px 0px;
    display: flex;
    align-items: center;
  }

  .select {
    .MuiInputLabel-root {
      color: ${colors.disabled};
      font-family: ${theme.typography.body1.fontFamily};
      font-size: ${theme.typography.body1.fontSize};
    }
    label + .MuiInput-formControl {
      margin-top: 0;
    }

    .MuiInputLabel-formControl {
      transform: translate(0, 7px) scale(1);
    }

    .MuiInputLabel-animated {
      transition: none;
    }
  }
  .name-field {
    .MuiInputBase-root {
      height: 40px;
      padding: 6px 0px;
    }
  }
  .bullet-point {
    border: 2px solid ${colors.primary};
    color: ${colors.primary};
    font-size: 14px;
    padding: 10px;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    align-items: center;
    justify-content: center;
    text-align: center;
    display: flex;
    margin-right: 10px;
  }
  .step--text {
    font-family: ScandiaWebMedium;
  }
  .facebook--container {
    margin-top: 25px;
  }
  .informative--container {
    margin-left: 30px;
    margin-top: 5px;
    color: ${colors.primary};
    .informative--text {
      color: ${colors.primary};
      font-size: 12px;
    }
  }
  .badge-waiting-auth {
    background: ${colors.buttonGroupOutlined};
    padding: 6px 6px;
    font-family: ScandiaWebMedium;
    border-radius: 4px;
    font-size: 12px;
    text-align: center;
    color: ${colors.leftSideBar};
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 35%;
    svg {
      font-size: 16px;
    }
  }
  .badge-auth-complete {
    background: ${colors.menuHover};
    padding: 6px 6px;
    font-family: ScandiaWebMedium;
    border-radius: 4px;
    font-size: 12px;
    text-align: center;
    color: ${colors.primary};
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 15%;
    svg {
      font-size: 16px;
    }
  }
`;
