import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const deliveryBasketRowStyle = css`
  padding: 24px;
  .MuiDialogActions-root {
    font-size: 10px;
  }
  .tag-wrapper {
    display: flex;
  }
  .document-title {
    display: flex;
    h5 {
      font-weight: 500;
      padding-bottom: 8px;
    }
    .divider {
      margin: 0 8px;
      border-left: 1px solid ${colors.buttonGroupOutlined};
    }
    p {
      padding-top: 4px;
      font-size: 12px;
    }
  }
  .icon-buton {
    margin-left: 90px;
    margin-top: 32px;
  }

  .title {
    > h2 {
      font-size: 20px;
    }
  }
  .MuiDialog-container {
    .MuiDialogTitle-root {
      font-size: 20px;
      font-weight: 700;
    }
  }
  .comment-box {
    display: flex;
    align-items: center;
    justify-content: center;
    .comment-button {
      .MuiSvgIcon-root {
        margin-right: 5px;
      }
      .MuiButton-label {
        font-size: 12.66px;
        text-align: center;
      }
    }
  }

  .date-box {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    .date-title {
      font-weight: bold;
      width: 100%;
      color: ${colors.grey};
      padding-bottom: 3px;
    }
    .MuiInputBase-input.MuiOutlinedInput-input {
      padding: 0;
    }
  }
  .publication-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .publication-title {
      font-weight: bold;
      color: ${colors.grey};
      padding-bottom: 10px;
    }
  }
`;
