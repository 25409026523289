import React, { PropsWithChildren, FC } from 'react';
import {
  Dialog,
  Box,
  DialogContent,
  IconButton,
  DialogTitle,
  Typography,
  DialogProps,
  Button,
  DialogActions,
  ButtonProps,
  Divider,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { TimelineGroupBase } from 'react-calendar-timeline';
import { formWrapperDividerStyle } from './FormWrapper.style';

export interface ActionButton {
  name: string;
  handleClick: () => void;
  props?: ButtonProps;
}

export interface FormWrapperProps {
  open: boolean;
  handleClose: () => void;
  title?: React.ReactNode;
  maxWidth?: DialogProps['maxWidth'];
  subtitle?: TimelineGroupBase['title'];
  classes?: DialogProps['classes'];
  actionButtons?: ActionButton[];
}

const FormWrapper: FC<PropsWithChildren<FormWrapperProps>> = ({
  open,
  handleClose,
  title,
  maxWidth,
  children,
  subtitle,
  classes,
  actionButtons,
}) =>
  open ? (
    <Dialog
      classes={classes}
      open={open}
      maxWidth={maxWidth}
      fullWidth
      onClose={handleClose}
      data-testid="form-dialog"
    >
      <Box display="flex" justifyContent="space-between">
        <Box>
          <DialogTitle id="customized-dialog-title" disableTypography>
            <Typography variant="h3">{title}</Typography>
            <Typography variant="body1">{subtitle}</Typography>
          </DialogTitle>
        </Box>
        <Box>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            data-testid="close-button"
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <DialogContent>{children}</DialogContent>
      {actionButtons && (
        <>
          <Divider css={formWrapperDividerStyle} />
          <DialogActions>
            {actionButtons.map((actionButton, index) => (
              <Button
                key={index}
                variant="contained"
                onClick={actionButton.handleClick}
                color="primary"
                {...actionButton.props}
              >
                {actionButton.name}
              </Button>
            ))}
          </DialogActions>
        </>
      )}
    </Dialog>
  ) : null;

export default FormWrapper;
