import { css } from '@emotion/react';
import { Asset, TypographyIdentities } from 'modules/workflow/oneStudioContext';

export const priceTagStyle = (priceChip?: Asset) => css`
  background-image: ${priceChip?.display
    ? `url(${priceChip?.url})`
    : undefined};
  background-size: contain;
  background-repeat: no-repeat;
  height: ${priceChip ? `${priceChip.size.height}pt` : undefined};
  width: ${priceChip ? `${priceChip.size.width}pt` : undefined};
  position: absolute;
  z-index: 10;
`;

export const productPriceStyle = (
  wholeNumber: string | undefined,
  hasPriceChip: boolean
) => css`
  display: flex;
  height: 100%;
  position: absolute;
  width: 100%;
  justify-content: ${hasPriceChip ? 'center' : 'left'};
  align-items: center;
`;

export const typographyItemStyle = (
  typographyItem: TypographyIdentities
) => css`
  color: ${typographyItem.color};
  font-size: ${typographyItem.fontSize}pt;
  font-weight: ${typographyItem.fontWeight};
  font-style: ${typographyItem.fontStyle};
  font-family: ${typographyItem.fontFamily};
  position: ${typographyItem.lineThrough ? 'absolute' : 'relative'};
  letter-spacing: -0.025em;
  line-height: ${typographyItem.lineHeight}pt;
  text-transform: ${typographyItem.isCapitalLetters ? 'uppercase' : 'none'};
  z-index: 5;
`;
