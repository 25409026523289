import React from 'react';
import { Grid, Card, CardContent, Typography } from '@material-ui/core';
import {
  useStudioDispatchContext,
  PersonalizationSubType,
  PersonalizationType,
  useStudioStateContext,
} from 'modules/workflow/oneStudioContext';
import { PersonalizationCardDataInterface } from 'modules/workflow/containers/Leftsidebar/PersonalizationPanel/PersonalizationPanel';
import { studioAppActions } from 'modules/workflow/reducers/actionsInterfaces';
import { DraggedItemTypes } from 'modules/workflow/containers/CatalogPreview/EditPlatformDefinitions';
import useCurrentEntity from 'app/current-entity-context/useCurrentEntity';
import { handleOnDropAddressBlock } from '../../CatalogPreview/onDropHandler';

interface PersonalizationCardsProps {
  personalizationCards: PersonalizationCardDataInterface[];
  personalizationSubType: string;
}

const PersonalizationCards = ({
  personalizationCards,
  personalizationSubType,
}: PersonalizationCardsProps): React.ReactNode => {
  const { currentEntity } = useCurrentEntity();

  const globalState = useStudioStateContext();
  const dispatch = useStudioDispatchContext();
  const dragPersonalizationItemHandler = (
    personalizationType: string,
    personalizationSubType: string
  ) => {
    dispatch(
      studioAppActions.setPersonalizationItemCategory({
        draggedItem: {
          item: personalizationType,
          type: DraggedItemTypes.SET_PERSONALIZATION_ITEM_CATEGORY,
          // this condition below is to add exception for the content item DATE to be among typography items.
          subType:
            personalizationType === PersonalizationSubType.DATE
              ? PersonalizationType.TYPOGRAPHY
              : personalizationSubType,
        },
      })
    );
  };

  return personalizationCards.map(
    (personalizationCard: PersonalizationCardDataInterface, index: number) => (
      <Grid item key={index}>
        <Card
          className={personalizationCard.cardStyles}
          draggable={true}
          onDragStart={() => {
            dragPersonalizationItemHandler(
              personalizationCard.type,
              personalizationSubType
            );
            if (personalizationCard.type === 'ADDRESSBLOCK') {
              handleOnDropAddressBlock(currentEntity, globalState, dispatch);
            }
          }}
          variant="outlined"
        >
          <CardContent className={personalizationCard.cardContentStyles}>
            <Typography>
              {' '}
              {personalizationCard.icon && personalizationCard.icon}
            </Typography>
            <Typography variant="h6" className={personalizationCard.textStyles}>
              {personalizationCard.text}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    )
  );
};
export default PersonalizationCards;
