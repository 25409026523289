import { useLazyQuery } from '@apollo/client';
import { GET_ORDERS_BY_DOCUMENTS } from 'app/graphql/queries/orders';
import {
  GetOrdersByDocuments,
  GetOrdersByDocumentsVariables,
} from 'app/schemaInterfaces/GetOrdersByDocuments';

const useOrderByDocumentsLazyQuery = () => {
  const [getOrderByDocuments, { data, loading, error, refetch }] = useLazyQuery<
    GetOrdersByDocuments,
    GetOrdersByDocumentsVariables
  >(GET_ORDERS_BY_DOCUMENTS, {
    fetchPolicy: 'no-cache',
  });

  return {
    getOrderByDocuments,
    data: data?.getOrdersByDocuments,
    loading,
    error,
    refetch,
  };
};

export default useOrderByDocumentsLazyQuery;
