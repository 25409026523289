import {
  Box,
  Button,
  DialogActions,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import { AssetType } from 'app/schemaInterfaces/globalTypes';
import { Form, useFormikContext } from 'formik';
import React from 'react';
import colors from 'styles/colors.module.scss';
import { MultiAssetFormValues } from 'modules/dam/form/model/definitions';
import { AssetStatusSwitchField } from 'modules/dam/form/fields/AssetStatusSwitchField';
import GeneralInformationSubForm from 'modules/dam/form/fields/GeneralInformationSubForm';
import PreviewUploadMultiple from 'modules/dam/form/PreviewUploadMultiple';
import VisualAssetSubForm from 'modules/dam/form/VisualAssetSubForm';
import { useTranslation } from 'react-i18next';
import EntitiesField from 'modules/oneManager/entities/form/EntitiesField';

const MultiAssetForm = (): JSX.Element => {
  const {
    values: { asset: assetInput },
  } = useFormikContext<MultiAssetFormValues>();
  const { t } = useTranslation();
  return (
    <>
      <Divider />
      <Form>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={6}>
            <Box py={2}>
              <Box p={1} mt={1} bgcolor={colors.documentsHeaderBackground}>
                {t('dam.asset_form.general_info')}
                <Typography
                  paragraph={true}
                  variant="subtitle2"
                  color="textSecondary"
                >
                  {t('dam.asset_form.fill_info')}
                </Typography>
              </Box>

              <GeneralInformationSubForm></GeneralInformationSubForm>

              <EntitiesField name="asset.entities" />

              {assetInput.assetType === AssetType.Visual && (
                <VisualAssetSubForm />
              )}
            </Box>
          </Grid>
          <PreviewUploadMultiple />
        </Grid>

        <Divider />
        <DialogActions style={{ justifyContent: 'space-between' }}>
          <AssetStatusSwitchField
            name="asset.status"
            isEditable={
              !assetInput.permanent &&
              !(assetInput.validTo && assetInput.validFrom)
            }
          />
          <Button variant="contained" type="submit" color="primary">
            {t('dam.asset_form.add_medias')}
          </Button>
        </DialogActions>
      </Form>
    </>
  );
};

export default MultiAssetForm;
