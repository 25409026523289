import React, { useState } from 'react';
import {
  CardMedia,
  Collapse,
  Divider,
  IconButton,
  ListItem,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import colors from 'styles/colors.module.scss';
import {
  Edit,
  RemoveRedEyeOutlined,
  MoreVertOutlined,
  ArrowDropDownCircle,
} from '@material-ui/icons';
import SimpleActionsMenu from 'components/ActionsMenu/SimpleActionsMenu';
import { css } from '@emotion/react';
import { getActions } from './DistributionHelper';
import { GetDocumentsCommitments } from 'app/schemaInterfaces/GetDocumentsCommitments';
import { DEFAULT_IMAGE_PREVIEW, getTheDate } from 'app/utils/common';
import {
  ApolloQueryResult,
  OperationVariables,
  useApolloClient,
} from '@apollo/client';
import {
  DELETE_DOCUMENTS_COMMITMENTS,
  EXPORT_DISTRIBUTION,
  EXPORT_PDF_ZIP,
} from 'app/graphql/queries/commitments';
import {
  ExportDistribution,
  ExportDistributionVariables,
} from 'app/schemaInterfaces/ExportDistribution';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ExportZip, ExportZipVariables } from 'app/schemaInterfaces/ExportZip';
import usePermissions from 'app/auth/usePermissions';
import { PermissionKey } from 'app/schemaInterfaces/globalTypes';
import {
  DeleteDocumentsCommitments,
  DeleteDocumentsCommitmentsVariables,
} from 'app/schemaInterfaces/DeleteDocumentsCommitments';
import { getWorkshopDocumentLink } from 'app/utils/route-helper';
import { Badge } from '@material-ui/core';
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';
import ConfirmationDialog from 'components/ConfirmationDialogs/ConfirmationDialog/ConfirmationDialog';
import UserBadge from 'components/UserBadge';
import { Distribution } from './DistributionList';
import { useDownloadFileErrorWorkflow } from 'app/utils/customHooks/useDownloadFileErrorWorkflow';
import { GetOrdersByDocuments } from 'app/schemaInterfaces/GetOrdersByDocuments';

const dialogStyle = css`
  min-width: 500px;
  .user {
    display: flex;
    margin-bottom: 17px;
  }
  .user-name {
    padding-left: 12px;
  }
  .message {
    background-color: ${colors.buttonGroupOutlined};
    border-radius: 6px;
    padding: 18px;
    margin-bottom: 17px;
  }
`;

const style = css`
  .list-item-container {
    background-color: ${colors.workzone};
    justify-content: center;
    height: 82px;
    margin-top: 8px;
    border-radius: 6px 6px 0px 0px;
  }
  .list-item {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .document-info {
    display: flex;
    width: 55%;
    .MuiCardMedia-media {
      width: 48px;
      height: 48px;
      object-fit: cover;
    }
    .info {
      padding-left: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    hr {
      height: 20px;
      margin-left: 9px;
      margin-right: 8px;
    }
  }
  .quantity {
    width: 20%;
  }
  .action-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .dropdown {
      color: ${colors.grey};
      margin-left: 45px;
    }
  }
  .item-row {
    padding: 23px 15px;
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: ${colors.buttonGroupOutlined};
  }
  .name {
    width: 10%;
    font-weight: bold;
  }
  .address {
    width: 25%;
  }
  .address-name {
    width: 20%;
  }
  .recommended-quantity {
    width: 14%;
    text-align: center;
  }
  .real-quantity {
    width: 13%;
    text-align: center;
  }
  .price {
    width: 10%;
    text-align: center;
  }
  .itemBox {
    display: flex;
  }
  .icon {
    background-color: inherit;
  }
  .comment {
    color: ${colors.disabled};
  }
  .MuiBadge-dot {
    background-color: ${colors.yellow};
    right: 3px;
    top: 3px;
    position: absolute;
  }
`;

interface DistributionRowProps {
  distribution: Distribution;
  triggerAlert: React.Dispatch<React.SetStateAction<boolean>>;
  refetch:
    | ((
        variables?: Partial<OperationVariables> | undefined
      ) => Promise<
        ApolloQueryResult<GetDocumentsCommitments | GetOrdersByDocuments>
      >)
    | undefined;
  isCampaignModel: boolean;
}

export const DistributionRow = ({
  distribution,
  triggerAlert,
  refetch,
  isCampaignModel,
}: DistributionRowProps): JSX.Element => {
  const { t } = useTranslation();
  const client = useApolloClient();
  const { id: campaignId } = useParams<{ id: string }>();

  const [isExpanded, setIsExpanded] = useState(false);
  const { hasPermission } = usePermissions();
  const [openComment, setOpenComment] = useState<number | null>(null);
  const history = useHistory();
  const downloadFile = useDownloadFileErrorWorkflow();
  const exportDistribution = async () => {
    const exportCommitmentsSheet = await client.query<
      ExportDistribution,
      ExportDistributionVariables
    >({
      query: EXPORT_DISTRIBUTION,
      variables: {
        campaignId,
        documentIds: distribution.documentIds,
        isCommitment: !isCampaignModel,
      },
      fetchPolicy: 'network-only',
    });
    downloadFile({
      url: exportCommitmentsSheet.data.exportDistribution.url,
      filename: exportCommitmentsSheet.data.exportDistribution.name,
      mimetype:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
  };

  const exportDocuments = () => {
    client.query<ExportZip, ExportZipVariables>({
      query: EXPORT_PDF_ZIP,
      variables: {
        campaignId,
        documentIds: distribution.documentIds,
      },
      fetchPolicy: 'network-only',
    });
    triggerAlert(true);
  };

  const deleteDocuments = async () => {
    await client.mutate<
      DeleteDocumentsCommitments,
      DeleteDocumentsCommitmentsVariables
    >({
      mutation: DELETE_DOCUMENTS_COMMITMENTS,
      variables: {
        documentIds: distribution.documentIds,
      },
      fetchPolicy: 'network-only',
    });
    refetch?.();
  };

  const collapseRows = distribution.orders.flatMap((order) =>
    order.addresses.map((address) => ({
      entityName: order.entityName,
      ...address,
      documentId: address.documentId,
    }))
  );

  const price = distribution.orders.reduce((acc, cur) => {
    if (cur.totalPrice) {
      return acc + cur.totalPrice;
    }
    return acc + 0;
  }, 0);

  const isCommentOpen = openComment !== null;
  const entityName = isCommentOpen
    ? distribution.orders[0].addresses[openComment].address.name ?? ''
    : '';
  const firstName = entityName.split(' ')[0];

  const lastName = entityName.split(' ')[1];

  return (
    <div css={style}>
      <ListItem button className="list-item-container">
        <div className="list-item" onClick={() => setIsExpanded(!isExpanded)}>
          <div
            className="document-info"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <CardMedia
              image={
                distribution.document.thumbnailUrl ?? DEFAULT_IMAGE_PREVIEW
              }
              component="img"
              alt={distribution.document.name}
            />
            <div className="info">
              <Typography variant="body2">
                {distribution.document.name +
                  ' - ' +
                  distribution.document.version}
              </Typography>
              <Divider orientation="vertical" />
              <Typography variant="body2">
                {t('global.distribution_list.delivery')} :{' '}
                {distribution.deliveryPointCount}
              </Typography>
            </div>
          </div>

          <Typography className="recommended-quantity" variant="body2">
            {distribution.recommendedQuantity}
          </Typography>

          <Typography className="real-quantity" variant="body2">
            {distribution.quantity}
          </Typography>
          <Typography className="price" variant="body2">
            {price.toFixed(2)}
          </Typography>

          <div className="action-buttons">
            {distribution.orders.length && (
              <>
                <SimpleActionsMenu
                  actions={getActions(
                    exportDistribution,
                    exportDocuments,
                    deleteDocuments,
                    hasPermission,
                    t,
                    isCampaignModel
                  )}
                  button={<MoreVertOutlined />}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  maxWidth={240}
                />

                <ArrowDropDownCircle
                  onClick={() => setIsExpanded(!isExpanded)}
                  className="dropdown"
                />
              </>
            )}
          </div>
        </div>
      </ListItem>
      <Collapse in={isExpanded} timeout="auto">
        <div>
          {collapseRows.map((row, index) => (
            <div key={index} className="item-row">
              <div className="list-item">
                <div className="name">{row.entityName}</div>
                <div className="address-name">{row.address.name}</div>
                <div className="address">
                  {row.address.firstAddress} {row.address.secondAddress}{' '}
                  {row.address.zipCode} {row.address.deliveryDepartment}
                </div>
                <div className="recommended-quantity">
                  {row.recommendedQuantity}
                </div>
                <div className="real-quantity">{row.quantity}</div>
                <div className="price">{row.price?.toFixed(2)}</div>
                {row.comment && (
                  <div className="comment">
                    <IconButton
                      aria-label="open-wf"
                      className="icon"
                      onClick={() => setOpenComment(index)}
                    >
                      <Badge variant="dot">
                        <CommentOutlinedIcon />
                      </Badge>
                    </IconButton>
                  </div>
                )}
                {hasPermission(
                  PermissionKey.AccessDistributionDocumentWorkflow
                ) && (
                  <div>
                    <IconButton aria-label="open-wf" className="icon">
                      <Link
                        to={{
                          pathname: `${getWorkshopDocumentLink(
                            row.documentId
                          )}${
                            !distribution.document.isCustomizable
                              ? '?type=readonly'
                              : ''
                          }`,
                          state: {
                            prevPath: history.location.pathname,
                          },
                        }}
                        style={{
                          textDecoration: 'none',
                          color: 'inherit',
                          backgroundColor: 'inherit',
                          display: 'flex',
                        }}
                      >
                        {distribution.document.isCustomizable ? (
                          <Edit />
                        ) : (
                          <RemoveRedEyeOutlined />
                        )}
                      </Link>
                    </IconButton>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </Collapse>
      {isCommentOpen && (
        <ConfirmationDialog
          isOpen={isCommentOpen}
          onClose={() => {
            setOpenComment(null);
          }}
          title={t('global.distribution_list.read_comment')}
          actions={[
            {
              title: t('global.distribution_list.close'),
              dataTestId: 'commitment.commitments[0].id',
            },
          ]}
          children={
            <div css={dialogStyle}>
              <div className="user">
                <UserBadge
                  firstName={firstName}
                  lastName={lastName}
                  hasFullName={false}
                />
                <div className="user-name">
                  <h4>
                    {distribution.orders[0].addresses[openComment].address.name}
                  </h4>
                  <p>{getTheDate(distribution.orders[0].createdAt)}</p>
                </div>
              </div>
              <p className="message">
                {distribution.orders[0].addresses[openComment].comment}
              </p>
            </div>
          }
        />
      )}
    </div>
  );
};
