import {
  Box,
  FormControl,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { GetClientShippingAddress_entityDeliveryAdresses } from 'app/schemaInterfaces/GetClientShippingAddress';
import { ChangeEvent } from 'react';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { BasketAddress } from './BasketDeliveryContainer/BasketDeliveryContainer.definitions';
import { SelectedBasketdocument } from '../sales.state';
import { css } from '@emotion/react/macro';
import colors from 'styles/colors.module.scss';

interface SelectAddressesProps {
  options?: GetClientShippingAddress_entityDeliveryAdresses[];
  handleAddress: (address: BasketAddress['address'], index: number) => void;
  basketDocumentItem: SelectedBasketdocument;
  index: number;
}
const style = css`
  .MuiInputBase-input.MuiSelect-outlined.MuiSelect-outlined {
    padding: 12px;
  }
`;

const SelectAddresses = ({
  options,
  handleAddress,
  basketDocumentItem,
  index,
}: SelectAddressesProps) => {
  const availableOptions = options?.filter((deliveryAddress) =>
    basketDocumentItem.addresses?.length
      ? basketDocumentItem.addresses?.every(
          (address) => address.address?.id !== deliveryAddress.address?.id
        )
      : options
  );

  let value = basketDocumentItem.addresses?.[index];

  const selectedOption = options?.find(
    (option) => option.address?.id === value.address?.id
  );
  selectedOption && availableOptions?.unshift(selectedOption);

  const handleChange = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    const newValue = options?.find(
      (address) => address.address?.id === event.target.value
    );

    handleAddress(newValue?.address, index);
  };

  return (
    <FormControl fullWidth css={style}>
      <TextField
        css={style}
        select
        variant="outlined"
        color="primary"
        SelectProps={{
          renderValue: (option: any) => {
            return option?.name || '';
          },
          value: value?.address ?? '',
          defaultValue: value?.address ?? '',
          IconComponent: (props) => <KeyboardArrowDownIcon {...props} />,
        }}
        onChange={handleChange}
      >
        {availableOptions?.map((item) => (
          <MenuItem
            style={{
              backgroundColor:
                value?.address?.id === item.address?.id
                  ? colors.menuHover
                  : 'none',
            }}
            value={item.address?.id || ''}
            key={item.address?.id}
          >
            <Box display="flex">
              <Box gridRow={1}>
                <Typography variant="h4">{item.address?.name}</Typography>
                <Typography variant="body1">
                  {item.address?.firstAddress}
                </Typography>
                <Typography variant="body1">{item.address?.city} </Typography>
              </Box>
            </Box>
          </MenuItem>
        )) ?? <span />}
      </TextField>
    </FormControl>
  );
};

export default SelectAddresses;
