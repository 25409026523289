import React, {
  useState,
  useMemo,
  useReducer,
  useEffect,
  useCallback,
} from 'react';
import { Box, Button } from '@material-ui/core';
import AppTable from 'components/AppTable/AppTable';
import {
  GET_PRODUCTS,
  COPY_PRODUCTS,
  REMOVE_MULTIPLE_PRODUCTS,
} from 'app/graphql/queries/products';
import {
  GetProducts,
  GetProducts_getProducts_products,
  GetProductsVariables,
} from 'app/schemaInterfaces/GetProducts';
import { useQuery, useApolloClient, useMutation } from '@apollo/client';
import useAppContext from 'app/app-context/useAppContext';
import {
  getProductsColumns,
  productsDetailsWithHisId,
  useDownloadProductsSheet,
  VisuelFieldName,
} from 'modules/pim/baseProducts/ProductsHelper';
import Loader from 'components/Loader/Loader/Loader';
import ProductsSheetCreation from 'modules/pim/baseProducts/ProductsSheetCreation';
import FileUploadRestApi from 'components/FileUploadRestApi';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { ColumnInstance, Row } from 'react-table';
import colors from 'styles/colors.module.scss';
import { useRouter } from 'app/routes/useRouter';
import HeaderButtons from 'components/HeaderButtons/HeaderButtons';
import {
  CopyProductsVariables,
  CopyProducts,
} from 'app/schemaInterfaces/CopyProducts';
import HideColumns from 'components/HideColumns/HideColumns';
import { FileStore } from 'modules/pim/baseProducts/PimFormFieldsConfig';
import {
  ProductsSheetsContextState,
  ProductsSheetsStateProvider,
} from 'modules/pim/baseProducts/BaseProducts';
import { PimFilterPopover } from 'modules/pim/components/PimFilterPopover';
import PimSearchBar from 'modules/pim/components/PimSearchBar';
import {
  GetAssetById,
  GetAssetByIdVariables,
  GetAssetById_getAssetById,
} from 'app/schemaInterfaces/GetAssetById';
import { GET_ASSET_BY_ID } from 'app/graphql/queries/dam';
import { useInfiniteScroll } from 'components/infinite-scroll/useInfiniteScroll';
import {
  ProductsInitialState,
  productsFiltersReducer,
} from 'modules/pim/baseProducts/reducers/productReducer';
import {
  PermissionKey,
  ProductFilterInput,
} from 'app/schemaInterfaces/globalTypes';
import ProductSheetEdit from 'modules/pim/components/ProductSheetEdit';
import {
  RemoveMultipleProducts,
  RemoveMultipleProductsVariables,
} from 'app/schemaInterfaces/RemoveMultipleProducts';
import SelectConfirmationDialog from 'components/ActionsButtons/SelectConfirmationDialog';
import { useTranslation } from 'react-i18next';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import { PimRegrouping } from './PimFormConfigTypes';
import usePermissions from 'app/auth/usePermissions';

export interface ProductsDetailsWithHisId
  extends GetProducts_getProducts_products {
  productId: string;
  filesToSend?: FileStore[];
  [key: string]: React.ReactNode;
}

interface BaseProductsListProps {
  isImportMode?: boolean;
  campaignId?: string;
}

interface ProductToEdit {
  productData: Record<string, any>;
}

export interface GetProductsWithAssets
  extends GetProducts_getProducts_products {
  assets?: GetAssetById_getAssetById;
}

const BaseProductsList = ({
  isImportMode = false,
  campaignId,
}: BaseProductsListProps): JSX.Element => {
  const {
    client: { config: clientConfig },
  } = useAppContext();
  const [productsFilter, productsDispatch] = useReducer(
    productsFiltersReducer,
    ProductsInitialState
  );

  const [productToEdit, setProductToEdit] = useState<ProductToEdit>({
    productData: {},
  });

  const { productData } = productToEdit;

  const {
    data: productsSheetsData,
    error: productsSheetsError,
    refetch,
    loading,
    fetchMore,
  } = useQuery<GetProducts, GetProductsVariables>(GET_PRODUCTS, {
    variables: {
      filter: productsFilter.filters as ProductFilterInput,
      pagination: { offset: 0, limit: 10 },
      isSortByDate: true,
    },
    notifyOnNetworkStatusChange: true,
  });

  const { getProducts: productsSheets } = productsSheetsData ?? {};

  const loadMoreProducts = useCallback(() => {
    if (!loading) {
      fetchMore({
        variables: {
          pagination: {
            offset: productsSheetsData?.getProducts.products.length || 0,
            limit: 10,
          },
          isSortByDate: true,
        },
        updateQuery: (
          prevData: GetProducts,
          { fetchMoreResult }
        ): GetProducts => {
          if (
            !fetchMoreResult ||
            fetchMoreResult.getProducts.products.length === 0
          )
            return prevData;
          return {
            getProducts: {
              ...prevData.getProducts,
              products: [
                ...prevData.getProducts.products,
                ...fetchMoreResult.getProducts.products,
              ],
            },
          };
        },
      });
    }
  }, [fetchMore, loading, productsSheetsData]);

  const waypointRef = useInfiniteScroll(loadMoreProducts, loading);

  const [openProductsSheetDialog, setOpenProductsSheetDialog] = useState<
    boolean
  >(false);
  const [selectedRows, setSelectedRows] = React.useState<
    Row<Record<string, any>>[]
  >([]);
  const [filteredColumns, setFilteredColumns] = useState<
    ColumnInstance<ProductsDetailsWithHisId>[]
  >([]);
  const [fieldName, setFieldName] = React.useState<string | undefined>(
    undefined
  );
  const [formFilesManager, setFormFilesManager] = useState<FileStore[]>([]);
  const [filesLimitNumber, setFilesLimitNumber] = useState<number>(0);
  const [productsDataWithUrl, setProductsDataWithUrl] = useState<
    (GetProductsWithAssets | undefined)[] | undefined
  >([]);
  const [isHistoryOpen, setIsHistoryOpen] = useState<boolean>(false);
  const [isComfirmationDialogOpen, setIsComfirmationDialogOpen] = useState<
    boolean
  >(false);

  const [regrouping, setRegrouping] = useState<PimRegrouping[]>([]);
  const { t } = useTranslation();

  const { goBack } = useRouter().history;
  const client = useApolloClient();
  const downloadProductsSheet = useDownloadProductsSheet();
  const handleOpenProductsSheetDialog = (row: Record<string, any>) => {
    setProductToEdit({
      productData: row,
    });
    setOpenProductsSheetDialog(true);
    setFieldName(undefined);
    setFormFilesManager([]);
    setFilesLimitNumber(0);
  };

  const handleCloseProductsSheetDialog = () => {
    setOpenProductsSheetDialog(false);
    setIsHistoryOpen(false);
    setRegrouping([]);
  };

  const handleOpenHistory = () => setIsHistoryOpen(!isHistoryOpen);

  const productsColumns = useMemo(() => getProductsColumns(clientConfig), [
    clientConfig,
  ]);
  const productsDetails = useMemo(
    () =>
      productsDetailsWithHisId({
        productsSheets: productsDataWithUrl as GetProductsWithAssets[],
      }) as ProductsDetailsWithHisId[],
    [productsDataWithUrl]
  );
  const { hasPermission } = usePermissions();
  const query = `
	mutation ImportProducts($file: Upload!) {
		importProducts(file: $file) {
			clientId
			_id
			createdAt
			createdBy
			details
			updatedAt
			updatedBy
		}
	}
  `;

  const variables = { file: null };

  const {
    isUploadInProgress,
    setIsUploadInProgress,
    uploadFile,
  } = FileUploadRestApi(query, variables, refetch);

  const toolbarButtons: React.ReactNode[] = [
    <Button
      variant="text"
      color="primary"
      onClick={() => downloadProductsSheet(selectedRows)}
      startIcon={<GetAppOutlinedIcon />}
    >
      {t('pim.toolbar.export')}
    </Button>,
    ...(hasPermission(PermissionKey.DeleteProduct)
      ? [
          <Button
            variant="text"
            color="primary"
            onClick={() => setIsComfirmationDialogOpen(true)}
            startIcon={<DeleteForeverOutlinedIcon />}
          >
            {t('pim.toolbar.delete')}
          </Button>,
        ]
      : []),
  ];
  const [copyProductsFromBase] = useMutation<
    CopyProducts,
    CopyProductsVariables
  >(COPY_PRODUCTS, {
    onCompleted() {
      setIsUploadInProgress(false);
      goBack();
    },
    refetchQueries: [
      {
        query: GET_PRODUCTS,
        variables: {
          campaignId: campaignId,
          filter: {},
        },
      },
    ],
  });

  const notStickyColumns = productsColumns
    ?.filter((column) => column.sticky !== 'left' && column)
    .map((column) => column?.Header as string);

  const appTableAllColumnsPropHandler = (
    allColumnsProp: ColumnInstance<ProductsDetailsWithHisId>[]
  ) => {
    setFilteredColumns(allColumnsProp);
  };

  const getRowId = React.useCallback(
    (row: ProductsDetailsWithHisId) => row.productId,
    []
  );

  const [deleteMultipleProducts] = useMutation<
    RemoveMultipleProducts,
    RemoveMultipleProductsVariables
  >(REMOVE_MULTIPLE_PRODUCTS, {
    refetchQueries: [
      {
        query: GET_PRODUCTS,
        variables: {
          filter: productsFilter.filters as ProductFilterInput,
          pagination: { offset: 0, limit: 10 },
          isSortByDate: true,
        },
      },
    ],
  });

  const deleteProducts = () =>
    deleteMultipleProducts({
      variables: {
        productIds: selectedRows.map(({ original }) => original.productId),
      },
    });

  const values: ProductsSheetsContextState = {
    filter: productsFilter.filters as ProductFilterInput,
    dispatch: productsDispatch,
    fieldName,
    setFieldName,
    formFilesManager,
    setFormFilesManager,
    filesLimitNumber,
    setFilesLimitNumber,
    regrouping,
    setRegrouping,
  };

  useEffect(() => {
    const addUrlToProducts = async (data: GetProducts) => {
      const productsPromises = data.getProducts.products.map((product) => {
        if (product.details.filesToSend) {
          const getMainVisuel = product.details.filesToSend?.find(
            (file: FileStore) => file.fieldName === VisuelFieldName.mainVisuel
          );
          if (getMainVisuel) {
            return client.query<GetAssetById, GetAssetByIdVariables>({
              query: GET_ASSET_BY_ID,
              variables: {
                id: getMainVisuel.assetId,
              },
            });
          }
        }
        return undefined;
      });
      const assetsData = await Promise.all(productsPromises);
      const productWithUrl = productsSheetsData?.getProducts.products.map(
        (product, index) => ({
          ...product,
          assets: assetsData[index]?.data.getAssetById,
        })
      );

      setProductsDataWithUrl(productWithUrl);
    };
    if (productsSheetsData?.getProducts) {
      addUrlToProducts(productsSheetsData);
    }
  }, [productsSheetsData, client, setIsUploadInProgress]);

  return (
    <ProductsSheetsStateProvider value={values}>
      <Box>
        {isImportMode && (
          <HeaderButtons
            bgColor={colors.documentsHeaderBackground}
            title={t('pim.add_dialog.import_from_base')}
            submitButtonTitle={t('pim.add_dialog.add_products')}
            disableSubmitButton={selectedRows.length > 0 ? false : true}
            submitButtonProps={{
              color: 'primary',
              onClick: () => {
                setIsUploadInProgress(true);
                copyProductsFromBase({
                  variables: {
                    campaignId: campaignId as string,
                    productIds: selectedRows.map(
                      ({ original }) => original.productId
                    ),
                  },
                });
              },
            }}
            secondaryButtonProps={{
              onClick: () => {
                goBack();
              },
            }}
          />
        )}
        <Box pt={2}>
          <Box px={6}>
            <Box pb={2} display="flex" justifyContent="flex-end">
              <Box pr={2}>
                <HideColumns
                  columnHiding={{
                    enabled: true,
                    columnsFilter: filteredColumns,
                    fixedColumnsIDs: notStickyColumns as string[] | undefined,
                  }}
                />
              </Box>
              <Box pr={2}>
                <PimFilterPopover products={productsSheetsData?.getProducts} />
              </Box>
              <Box pr={1}>
                <PimSearchBar placeholder={t('pim.table.search_placeholder')} />
              </Box>
              {!isImportMode && hasPermission(PermissionKey.CreateProduct) && (
                <ProductsSheetCreation uploadFile={uploadFile} />
              )}
            </Box>
            {productsColumns && productsDetails && clientConfig ? (
              !isUploadInProgress ? (
                <Box>
                  {productsSheets ? (
                    <AppTable<ProductsDetailsWithHisId>
                      columns={productsColumns}
                      data={productsDetails}
                      onRowClick={
                        !isImportMode && hasPermission(PermissionKey.AccessPim)
                          ? handleOpenProductsSheetDialog
                          : undefined
                      }
                      hasToolbar={true}
                      toolBarButtons={
                        !isImportMode ? toolbarButtons : undefined
                      }
                      setSelectedRows={setSelectedRows}
                      appTableAllColumnsPropHandler={
                        appTableAllColumnsPropHandler
                      }
                      getRowId={getRowId}
                    />
                  ) : (
                    <AppTable<ProductsDetailsWithHisId>
                      columns={productsColumns}
                      data={[]}
                      getRowId={getRowId}
                    />
                  )}
                </Box>
              ) : (
                <Loader error={productsSheetsError} />
              )
            ) : null}
            {loading ? (
              <Loader error={productsSheetsError} />
            ) : (
              <div ref={waypointRef} />
            )}
          </Box>
          {hasPermission(PermissionKey.AccessPim) && (
            <ProductSheetEdit
              openProductsSheetDialog={openProductsSheetDialog}
              handleCloseProductsSheetDialog={handleCloseProductsSheetDialog}
              handleOpenHistory={handleOpenHistory}
              isHistoryOpen={isHistoryOpen}
              productData={productData}
              productId={productData.productId}
            />
          )}
          <SelectConfirmationDialog
            item={undefined}
            isOpen={isComfirmationDialogOpen}
            onClose={() =>
              setIsComfirmationDialogOpen(!setIsComfirmationDialogOpen)
            }
            confirmationDialog={{
              title: t('common.delete_confirmation_dialog.title'),
              description:
                selectedRows.length > 1
                  ? t(
                      'pim.delete_confirmation_dialog.description_multiple_files'
                    )
                  : t('pim.delete_confirmation_dialog.description_one_file'),
            }}
            onClick={() => deleteProducts()}
          />
        </Box>
      </Box>
    </ProductsSheetsStateProvider>
  );
};

export default BaseProductsList;
