import React from 'react';
import { css } from '@emotion/react/macro';
import colors from 'styles/colors.module.scss';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { Breadcrumbs as MaterialBreadcrumbs } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import AppLink from 'components/Navigation/AppLink/AppLink';
import { ShopRoute } from 'modules/shop/shopHelpers';
import { AppRoute } from 'app/routes/routes';

const style = css`
  margin-top: 10px;
  ol {
    li {
      margin: 0;
    }

    a {
      font-size: 12px;
    }

    svg {
      width: 20px;
      height: 20px;
      margin: 0 4px;
    }

    li:last-of-type a,
    svg {
      color: ${colors.disabled};
    }
  }
`;

interface BreadcrumbsProps {
  paths: {
    path: ShopRoute | AppRoute;
    breadcrumb?: React.ComponentType | React.ElementType | string | null;
  }[];
  options?: {
    excludePaths?: string[];
  };
}

const Breadcrumbs = ({ paths, options }: BreadcrumbsProps) => {
  const breadcrumbs = useBreadcrumbs(paths, options);

  return (
    <div css={style}>
      <MaterialBreadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
        {breadcrumbs.map(({ match, breadcrumb }) => (
          <AppLink
            color="textPrimary"
            key={match.url}
            to={match.url as AppRoute}
            underline="none"
          >
            {breadcrumb}
          </AppLink>
        ))}
      </MaterialBreadcrumbs>
    </div>
  );
};

export default Breadcrumbs;
