import React, { useState, useEffect } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { NumberSize } from 're-resizable';
import {
  Asset,
  TypographyIdentities,
  useStudioDispatchContext,
  useStudioStateContext,
} from 'modules/workflow/oneStudioContext';
import { Direction } from 're-resizable/lib/resizer';
import { studioAppActions } from 'modules/workflow/reducers/actionsInterfaces';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import StatusBadge, { BadgeStatus } from 'components/Badge/StatusBadge';
import { MILIMITER_TO_PIXELS } from 'modules/workflow/containers/CatalogPreview/singlePaperHelper';
import { processDpi } from 'app/utils/math';
import { useTranslation } from 'react-i18next';
import {
  AssetTypeInCatalog,
  IProductTemplate,
} from 'modules/workflow/Clients/ToutFaire/ProductItem';
import { AssetType } from 'app/schemaInterfaces/globalTypes';
import { typographyItemStyle } from './components/price/priceTagHelper';
import { Rnd, DraggableData, RndDragEvent } from 'react-rnd';

const useStyles = makeStyles({
  conformityBadge: {
    position: 'absolute',
    right: 20,
    top: 15,
  },
});

interface ResizableDraggableAssetProps {
  id: string;
  asset: Asset;
  assetType: AssetTypeInCatalog;
  pageIndex: number;
  zoneIndex: number;
  resizeStyle: CSSProperties;
  personalizationKey?: string;
  product: IProductTemplate;
  isDraggable?: boolean;
  typographyItem?: TypographyIdentities;
  isDefaultProduct: boolean;
  toggleColor: (typographyItemId: string) => void;
  isResizable?: boolean;
}

const ResizableDraggableAsset = ({
  id,
  asset,
  assetType,
  pageIndex,
  zoneIndex,
  personalizationKey,
  resizeStyle,
  product,
  isDraggable = true,
  isResizable = true,
  typographyItem,
  isDefaultProduct,
  toggleColor,
}: ResizableDraggableAssetProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useStudioDispatchContext();
  const globalState = useStudioStateContext();

  const [isConform, setIsConform] = useState<boolean>(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (
      asset?.assetType === AssetType.Visual &&
      asset?.size &&
      asset?.metadata
    ) {
      const resizableSurfaceWidth =
        asset?.size.width / (MILIMITER_TO_PIXELS * 10);
      const resizableSurfaceHeight =
        asset?.size.height / (MILIMITER_TO_PIXELS * 10);
      const dpiWidth = processDpi(
        asset?.metadata.dimensions?.width as number,
        resizableSurfaceWidth
      );
      const dpiHeight = processDpi(
        asset?.metadata.dimensions?.height as number,
        resizableSurfaceHeight
      );

      setIsConform(Math.min(dpiWidth, dpiHeight) >= 300);
    }
  }, [asset?.size, asset?.metadata, assetType, asset?.assetType]);

  const updateAssetPositionHandler = (
    event: RndDragEvent,
    position: DraggableData,
    pageNumber: number,
    zoneIndex: number,
    assetType: AssetTypeInCatalog
  ) => {
    personalizationKey
      ? dispatch(
          studioAppActions.updatePersonalizationProductAssetPosition({
            pageNumber: pageNumber,
            zoneIndex: zoneIndex,
            productId: personalizationKey,
            position: { x: position.x, y: position.y },
            assetType,
            visualId: asset.id,
            fieldName: asset.fieldName,
            isRegrouping: false,
          })
        )
      : dispatch(
          studioAppActions.updateAssetPosition({
            pageNumber: pageNumber,
            zoneIndex: zoneIndex,
            productId: id,
            position: { x: position.x, y: position.y },
            assetType,
            visualId: asset.id,
            fieldName: asset.fieldName,
            isRegrouping: false,
          })
        );
  };

  const defaultImageSize = 133;

  const imageSize = {
    height: asset?.size.height ?? defaultImageSize,
    width: asset?.size.width ?? defaultImageSize,
  };

  const handleSizeChanged = (
    event: MouseEvent | TouchEvent,
    direction: Direction,
    ref: HTMLElement,
    delta: NumberSize,
    assetType: AssetTypeInCatalog,
    size?: Asset['size']
  ) => {
    const assetSize = {
      height: (size?.height ?? defaultImageSize) + delta.height,
      width: (size?.width ?? defaultImageSize) + delta.width,
    };
    personalizationKey
      ? dispatch(
          studioAppActions.updatePersonalizationProductAssetSize({
            pageNumber: pageIndex,
            zoneIndex,
            productId: personalizationKey,
            size: assetSize,
            assetType,
            visualId: asset.id,
            isRegrouping: false,
          })
        )
      : dispatch(
          studioAppActions.updateAssetSize({
            pageNumber: pageIndex,
            zoneIndex,
            productId: id,
            size: assetSize,
            assetType,
            visualId: asset.id,
            isRegrouping: false,
          })
        );
  };

  return (
    <Rnd
      size={asset?.size}
      className={
        isDraggable && !globalState.commentMode ? 'resizable-item' : 'comment'
      }
      bounds="parent"
      scale={globalState.scaleValue}
      position={asset?.position}
      disableDragging={!isDraggable}
      dragHandleClassName="logoContainer"
      dragAxis="both"
      onResizeStop={(event, direction, ref, delta, position) => {
        event.stopPropagation();
        handleSizeChanged(event, direction, ref, delta, assetType, asset?.size);
        updateAssetPositionHandler(
          event,
          position as DraggableData,
          pageIndex,
          zoneIndex,
          assetType
        );
      }}
      onDragStop={(event, position) => {
        event.stopPropagation();
        updateAssetPositionHandler(
          event,
          position,
          pageIndex,
          zoneIndex,
          assetType
        );
      }}
    >
      <Box
        className="logoContainer"
        width={asset?.size.width}
        height={asset?.size.height}
      >
        <img
          alt="product"
          src={asset?.url}
          width={imageSize.width}
          height={imageSize.height}
          style={{
            objectFit: 'contain',
            borderRadius: 0,
          }}
        />
        {assetType === AssetTypeInCatalog.productImage && (
          <StatusBadge
            status={isConform ? BadgeStatus.Success : BadgeStatus.Error}
            className={classes.conformityBadge}
            message={
              isConform ? t('workflow.consistent') : t('workflow.to_be_changed')
            }
          />
        )}
        {typographyItem && product && (
          <div
            style={{
              position: 'absolute',
              top: typographyItem.position.y,
              left: typographyItem.position.x,
            }}
          >
            <Typography
              css={typographyItemStyle(typographyItem)}
              onDoubleClick={() => {
                !isDefaultProduct && toggleColor(typographyItem.id);
              }}
            >
              {product.productDetails[typographyItem.name]}
            </Typography>
          </div>
        )}
      </Box>
    </Rnd>
  );
};

export default ResizableDraggableAsset;
