import {
  AttributConditionnementFilterInput,
  AttributeKey,
  BigShopConfigCategory,
} from 'app/schemaInterfaces/globalTypes';
import ConfirmationDialog, {
  ConfirmationDialogProps,
} from '../ConfirmationDialog/ConfirmationDialog';
import React, { FC } from 'react';
import {
  getAttribute,
  getSupportCategory,
} from 'modules/pro/documents/form/model/mappers';

import { DEFAULT_IMAGE_PREVIEW } from 'app/utils/common';
import IsCustomizableTag from 'modules/shop/components/IsCustomizableTag';
import { documentPreviewStyle } from './DocumentPreview.style';
import { useTranslation } from 'react-i18next';

type DocumentPreviewQuantities =
  | {
      strict: number;
    }
  | {
      minimum: number;
      maximum: number;
    };

export interface DocumentPreviewProps
  extends Omit<ConfirmationDialogProps, 'actions'> {
  url?: string | null;
  isCustomizable: boolean;
  mediumType: string;
  category: string;
  version: string;
  attributes?: {
    [k: string]: string | AttributConditionnementFilterInput | null;
  };
  quantities?: DocumentPreviewQuantities;
}

const DocumentPreview: FC<DocumentPreviewProps> = ({
  isOpen,
  onClose,
  title,
  url,
  isCustomizable,
  mediumType,
  category,
  attributes,
  version,
  quantities,
}) => {
  const { t } = useTranslation();

  return (
    <ConfirmationDialog
      data-testedid="dialog"
      maxWidth={false}
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      title={title}
      children={
        <div css={documentPreviewStyle}>
          <div className="document">
            <img
              className="preview"
              src={url ?? DEFAULT_IMAGE_PREVIEW}
              alt="document"
            />
          </div>
          <div className="document-details">
            <h3>
              {title} - {version}
            </h3>
            <div className="custumizable-tag">
              <IsCustomizableTag isCustomizable={isCustomizable} />
            </div>
            <div className="recap-attribute--container">
              <span className="label">
                {t('global.document_form.categories')} :{' '}
              </span>
              <span className="value">
                {getSupportCategory(category as BigShopConfigCategory, t)}
              </span>
            </div>
            <div className="recap-attribute--container">
              <span className="label">
                {t('global.document_form.supports')} :{' '}
              </span>
              <span className="value">{mediumType}</span>
            </div>
            {quantities &&
              ('strict' in quantities ? (
                <div className="recap-attribute--container">
                  <span className="label">
                    {t('global.document_form.strict-quantity')} :{' '}
                  </span>
                  <span className="value">{quantities.strict}</span>
                </div>
              ) : (
                <>
                  <div className="recap-attribute--container">
                    <span className="label">
                      {t('global.document_form.min-quantity')} :{' '}
                    </span>
                    <span className="value">{quantities.minimum}</span>
                  </div>
                  <div className="recap-attribute--container">
                    <span className="label">
                      {t('global.document_form.max-quantity')} :{' '}
                    </span>
                    <span className="value">{quantities.maximum}</span>
                  </div>
                </>
              ))}
            {attributes &&
              Object.entries(attributes)?.map(([key, value], index) => (
                <div key={index} className="recap-attribute--container">
                  <span className="label">
                    {getAttribute(key as AttributeKey, t)} :{' '}
                  </span>
                  <span className="value">{value}</span>
                </div>
              ))}
          </div>
        </div>
      }
    />
  );
};

export default DocumentPreview;
