import { Box, Button } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import { Action } from 'components/ActionsMenu/ActionsMenu';
import React from 'react';
import DocumentsTypeFilter from 'modules/pro/components/DocumentsTypeFilter';
import SimpleActionsMenu from './ActionsMenu/SimpleActionsMenu';
import QuickSearchBar from './QuickSearchBar/QuickSearchBar';
import { useTranslation } from 'react-i18next';

interface TableToolbarProps {
  anchorEl?: HTMLButtonElement | null;
  placeholder?: string;
  actions: Action[];
  isFilterIcon?: boolean;
  hasHiddenFieldFilter?: boolean;
}

const TableToolbar = ({
  placeholder,
  actions,
  isFilterIcon,
  hasHiddenFieldFilter = true,
}: TableToolbarProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      justifyContent={isFilterIcon ? 'space-between' : 'flex-end'}
    >
      {isFilterIcon && <DocumentsTypeFilter />}
      <Box display="flex" alignItems="center">
        {hasHiddenFieldFilter && (
          <Box pr={1}>
            <Button startIcon={<VisibilityOffOutlinedIcon />}>
              {t('global.table_toolbar.hide_columns')}{' '}
            </Button>
          </Box>
        )}
        {/* temporary removed */}
        {/* <Box pr={1}>
          <Button startIcon={<VisibilityOffOutlinedIcon />}>
            {t('global.table_toolbar.hide_columns')}{' '}
          </Button>
        </Box>
        <Box mr={!hasHiddenFieldFilter ? 2 : 'initial'}>
          <Button startIcon={<FilterListOutlinedIcon />}>
            {t('global.table_toolbar.filter')}
          </Button>
        </Box> */}

        {hasHiddenFieldFilter && (
          <Button>
            <PublishOutlinedIcon />
          </Button>
        )}

        <Box pr={1}>
          <QuickSearchBar placeholder={placeholder} />
        </Box>
        <SimpleActionsMenu
          actions={actions}
          button={
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddCircleIcon />}
            >
              {t('global.table_toolbar.create')}
            </Button>
          }
        />
      </Box>
    </Box>
  );
};

export default TableToolbar;
