import { useMutation } from '@apollo/client';
import { REPLACE_DOCUMENT_AND_VERSION } from '../app/graphql/queries/documents';
import { GET_VERSION_QUERY } from '../app/graphql/queries/version';
import {
  ReplaceDocumentAndVersion,
  ReplaceDocumentAndVersionVariables,
} from '../app/schemaInterfaces/ReplaceDocumentAndVersion';
import { useRouter } from 'app/routes/useRouter';
import { UrlParams } from '../modules/workflow/containers/Toolbar/FirstToolbar/FirstToolbar';

const useReplaceDocumentMutation = () => {
  const { documentId } = useRouter<UrlParams>().query;

  const [replaceDocument, { loading }] = useMutation<
    ReplaceDocumentAndVersion,
    ReplaceDocumentAndVersionVariables
  >(REPLACE_DOCUMENT_AND_VERSION, {
    refetchQueries: [
      {
        query: GET_VERSION_QUERY,
        variables: {
          documentId: documentId,
        },
      },
    ],
  });

  return {
    replaceDocument,
    loading,
  };
};

export default useReplaceDocumentMutation;
