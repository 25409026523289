import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { GetOrderById_getOrderById } from 'app/schemaInterfaces/GetOrderById';
import { FollowOrderStatus } from 'app/schemaInterfaces/globalTypes';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  hasBothDocumentTypes,
  hasOnlyDigitalDocuments,
} from '../components/OrderFollowStatusTag/OrderFollowStatusTag.helper';
import { FollowOrderStepData } from '../components/FollowOrderStep/FollowOrderStep';
import { useCurrentUser } from 'app/auth/UserContext';
import { isUserValidatorForOrder } from '../utils/validation';
import { format } from 'date-fns';

export interface UseOrderStepperParams {
  order: GetOrderById_getOrderById;
}

export function useOrderStepper({ order }: UseOrderStepperParams) {
  const { t } = useTranslation();

  const user = useCurrentUser();
  const isValidator = isUserValidatorForOrder(order, user);

  const corr = {
    WaitingValidation: 1,
    Validated: 1,
    Refused: 1,
    ProductionInProgress: 2,
    DeliveryInProgress: 3,
    Delivered: 3,
    Billed: 4,
  };

  const documentsCategories = useMemo(
    () => order.orderItem?.map((item) => item.document.category),
    [order.orderItem]
  );

  const steps: FollowOrderStepData[] = [
    {
      label: 'follow_orders.created',
      date: order.createdAt,
    },
  ];

  if (
    order.followStatus === FollowOrderStatus.WaitingValidation &&
    isValidator
  ) {
    steps.push({
      label: 'follow_orders.to_validate',
      sublabel: 'follow_orders.to_validate_desc',
      variant: 'warning',
      date: order.createdAt,
    });
  } else if (
    order.followStatus === FollowOrderStatus.WaitingValidation &&
    !isValidator
  ) {
    steps.push({
      label: 'follow_orders.waiting_validation',
      sublabel: 'follow_orders.waiting_validation_desc',
      variant: 'warning',
      icon: <MoreHorizIcon />,
      date: order.createdAt,
    });
  } else if (order.followStatus === FollowOrderStatus.Refused) {
    steps.push({
      label: 'follow_orders.refused',
      sublabel: 'follow_orders.refused_desc',
      variant: 'error',
      date: order.statusUpdatedAt,
    });
  } else {
    const date = order.statusUpdatedAt ?? order.createdAt;
    const time = date
      ? `${format(new Date(date), 'HH')}h${format(new Date(date), 'mm')}`
      : null;
    steps.push({
      label: 'follow_orders.validated',
      ...(time && {
        sublabel: order.onepacUserName
          ? t('follow_orders.validated_by_desc', {
              time,
              name: order.onepacUserName,
            })
          : t('follow_orders.validated_desc', {
              time,
            }),
      }),
      variant: 'success',
      date: order.statusUpdatedAt ?? order.createdAt,
    });
  }

  const hasOrderWorkflowBlock =
    order.followStatus === FollowOrderStatus.WaitingValidation && isValidator;
  const hasRefusedBlock = order.followStatus === FollowOrderStatus.Refused;
  const isPartialTimeline = hasOrderWorkflowBlock || hasRefusedBlock;

  if (!isPartialTimeline) {
    steps.push(
      {
        label: 'follow_orders.production',
      },
      {
        label: hasOnlyDigitalDocuments(documentsCategories)
          ? 'follow_orders.delivery_digital'
          : hasBothDocumentTypes(documentsCategories)
          ? 'follow_orders.delivery'
          : 'follow_orders.delivery_print',
      },
      {
        label: 'follow_orders.billing',
      }
    );
  }

  return {
    steps,
    isPartialTimeline,
    hasOrderWorkflowBlock,
    hasRefusedBlock,
    activeStep: corr[order.followStatus] ?? -1,
  };
}
