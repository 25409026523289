import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { AppRoute } from 'app/routes/routes';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { ShopRoute } from 'modules/shop/shopHelpers';
import { SalesRoute } from 'modules/sales/salesHelpers';
import { StoreRoutes } from 'modules/oneManager/storeHelper';

interface AppLinkProps {
  to?: AppRoute | ShopRoute | SalesRoute | StoreRoutes;
  onClick?: () => void;
  style?: CSSProperties;
  color?:
    | 'error'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'textPrimary'
    | 'textSecondary';
  underline?: 'none' | 'hover' | 'always';
  className?: string;
}
const AppLink: FC<AppLinkProps> = ({
  to,
  onClick,
  children,
  style,
  color,
  underline,
  className,
}) => {
  return (
    <Link
      data-testid="app-link"
      onClick={onClick}
      component={RouterLink}
      to={to || '#'}
      style={style}
      color={color}
      underline={underline}
      className={className}
    >
      {children}
    </Link>
  );
};

export default AppLink;
