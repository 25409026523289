import { useContext } from 'react';
import AppContext from './AppContext';

const useAppContext = () => {
  const appContext = useContext(AppContext);

  if (!appContext) {
    throw new Error('App context must be provided');
  }

  return appContext;
};

export default useAppContext;
