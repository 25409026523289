import {
  FormGroup,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { DocumentQuantitiesType } from 'app/schemaInterfaces/globalTypes';
import { useFormikContext } from 'formik';
import React from 'react';
import { DocumentFormValues } from '../model/definitions';

interface Option {
  label: string;
  value: DocumentQuantitiesType | null;
}

export interface CheckboxesGroupProps {
  options: Option[];
  title?: string;
  handleChange: (value: DocumentQuantitiesType | null) => void;
}

// For document form only, do not reuse
const RadioButtonsGroup = ({
  options,
  title,
  handleChange,
}: CheckboxesGroupProps) => {
  const {
    values: {
      document: { quantities },
    },
  } = useFormikContext<DocumentFormValues>();

  return (
    <div>
      <FormLabel>{title}</FormLabel>
      <FormGroup row>
        {options.map((option, index) => (
          <RadioGroup key={index}>
            <FormControlLabel
              name={option.label.toString()}
              control={
                <Radio
                  onClick={() => handleChange(option.value)}
                  name={option.label.toString()}
                  checked={
                    quantities?.type === option.value ||
                    quantities === option.value
                  }
                  color="primary"
                />
              }
              label={option.label}
              value={option.value}
              className="radio-group"
            />
          </RadioGroup>
        ))}
      </FormGroup>
    </div>
  );
};

export default RadioButtonsGroup;
