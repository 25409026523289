import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const entityCardstyle = () => css`
  display: flex;
  align-items: center;
  & .title-container {
    margin-left: 16px;
    flex-grow: 1;
    .title {
      color: ${colors.disabled};
      font-size: 12px;
    }
    .name {
      color: ${colors.tooltip};
      font-family: ScandiaWebMedium;
      font-size: 14px;
    }
  }
`;
