import {
  DocumentError,
  DocumentOrderType,
} from 'app/schemaInterfaces/globalTypes';

import { CellProps } from 'react-table';
import { DocumentAndOtherFields } from 'modules/pro/documents/DocumentsList/DocumentsList';
import DocumentStatusTag from './DocumentStatusTag/DocumentStatusTag';
import React from 'react';
import { TFunction } from 'i18next';
import { TableColumns } from 'components/AppTable/AppTable';
import { Typography } from '@material-ui/core';
import { setTypeName } from 'modules/pro/campaigns/campaignsHelper';

export enum DocumentAccessor {
  DocumentId = 'id',
  DocumentName = 'name',
  Origin = 'origin',
  MediumType = 'mediumType',
  OrderTypes = 'orderTypes',
  NumberOfPages = 'numberOfPages',
  Format = 'format',
  Quantity = 'quantity',
}

export const documentsColumns = (
  t: TFunction
): TableColumns<DocumentAndOtherFields>[] => [
  {
    Header: 'DocumentId',
    accessor: DocumentAccessor.DocumentId,
    Cell: ({ cell }: CellProps<DocumentAndOtherFields, string>) => (
      <Typography variant="body1">{cell.row.values.id}</Typography>
    ),
  },
  {
    Header: t('global.documents_list.table.columns.name') as string,
    accessor: (row) => row.name,
    sticky: 'left',
  },
  {
    Header: t('global.documents_list.table.columns.version') as string,
    accessor: (row) => row.version,
    sticky: 'left',
  },
  {
    Header: t('global.documents_list.table.columns.medium_type') as string,
    accessor: DocumentAccessor.MediumType,
    sticky: 'left',
    Cell: ({ cell }: CellProps<DocumentAndOtherFields, string>) => (
      <Typography variant="body1">{cell.row.values.mediumType}</Typography>
    ),
  },
  {
    Header: t('global.documents_list.table.columns.order_types') as string,
    accessor: DocumentAccessor.OrderTypes,
    sticky: 'left',
    Cell: ({ cell }: CellProps<DocumentAndOtherFields, string>) => (
      <Typography variant="body2">
        {cell.row.values.orderTypes
          .map((orderType: DocumentOrderType) => setTypeName(t)[orderType])
          .join(', ')}
      </Typography>
    ),
  },
  {
    Header: t('global.documents_list.table.columns.status') as string,

    accessor: (row) => (
      <DocumentStatusTag
        isValidated={Boolean(row.isValidated)}
        isObsolete={Boolean(row.obsolete)}
      />
    ),
    sticky: 'left',
  },
  {
    Header: t('global.documents_list.table.columns.number_of_pages') as string,
    accessor: 'numberOfPages',
    Cell: ({ cell }: CellProps<DocumentAndOtherFields, string>) => (
      <Typography variant="body1">{cell.row.values.numberOfPages} p</Typography>
    ),
  },
  {
    Header: t('global.documents_list.table.columns.format') as string,
    // accessor: DocumentAccessor.Format,
    Cell: ({ cell }: CellProps<DocumentAndOtherFields, string>) => (
      <Typography variant="body1">{cell.row.values.format}</Typography>
    ),
  },
  {
    Header: t('global.documents_list.table.columns.quantity') as string,
    accessor: DocumentAccessor.Quantity,
    Cell: ({ cell }: CellProps<DocumentAndOtherFields, string>) => (
      <Typography variant="body1">{cell.row.values.quantity}</Typography>
    ),
  },
];

export const DocumentI18nKeyErrorDictionary = new Map<string, string>([
  [
    DocumentError.documentOrdered,
    'global.documents_list.actions.tooltip_message',
  ],
]);
