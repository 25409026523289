import {
  AscDescType,
  CampaignType,
  UserType,
} from 'app/schemaInterfaces/globalTypes';
import { Button, Grid, Tab, Tabs } from '@material-ui/core';
import { useEffect, useState } from 'react';

import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import { GetAllCampaigns_getAllCampaigns_campaigns_category } from 'app/schemaInterfaces/GetAllCampaigns';
import Loader from 'components/Loader/Loader/Loader';
import LocalCampaignCard from './LocalCampaignCard';
import { ShopRoute } from 'modules/shop/shopHelpers';
import colors from 'styles/colors.module.scss';
import { css } from '@emotion/react';
import globalStyle from 'styles/global.style';
import { makeStyles } from '@material-ui/styles';
import useCampaignsQuery from 'modules/shop/queries/useCampaignsQuery';
import useCurrentEntity from 'app/current-entity-context/useCurrentEntity';
import { useRouter } from 'app/routes/useRouter';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useCurrentUser } from 'app/auth/UserContext';

const style = css`
  h1 {
    margin-top: 50px;
  }
  .subtitle {
    text-align: center;
    margin-bottom: 36px;
    margin-top: 8px;
    color: ${colors.documentsTab};
  }

  .link-button {
    margin-top: 24px;
    text-align: right;
  }

  .tabs-filter {
    background: ${colors.workzone};
    border-radius: 35px;
    width: fit-content;
    margin: auto;
    margin-bottom: 20px;
    font-size: 14px;
    font-family: ScandiaWebRegular;
    .tab {
      color: ${colors.grey};
    }

    .Mui-selected {
      background: ${colors.white};
      border-radius: 200px;
      padding: 8px 16px;
      margin: 5px;
      box-shadow: 0px 0px 8px rgba(59, 59, 80, 0.1);
      min-height: 32px;
      color: ${colors.primary};
    }
  }
`;

const useStyles = makeStyles({
  tabsIndicator: {
    display: 'none',
  },
});

const LocalCampaignsContainer = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const classes = useStyles();
  const user = useCurrentUser();
  const isUserGlobal = user.type === UserType.Global;
  const { currentEntity } = useCurrentEntity();
  const [selectedCategory, setSelectedCategory] = useState('ALL');
  const [campaignsCategories, setCampaignCategories] = useState<
    (GetAllCampaigns_getAllCampaigns_campaigns_category | null)[]
  >([]);
  const { data: campaignsData, loading: campaignsLoading } = useCampaignsQuery({
    filter: {
      entityId: currentEntity?.id,
      campaignType: CampaignType.Model,
      activeLocalCampaigns: true,
      sort: { name: AscDescType.asc },
      ...(selectedCategory &&
        selectedCategory !== 'ALL' && {
          categories: [selectedCategory],
        }),
      skipOutdatedDocuments: !isUserGlobal,
    },
    pagination: { limit: 12 },
  });

  const campaigns = campaignsData?.getAllCampaigns.campaigns;

  useEffect(() => {
    const categories = _.uniq(campaigns?.map((campaign) => campaign.category));
    setCampaignCategories(categories);
  }, [campaigns]);

  return campaignsLoading ? (
    <Loader />
  ) : (
    <div css={[globalStyle, style]} className="app-container">
      <h1>{t('homepage.local_campaigs')} </h1>
      <p className="subtitle">{t('homepage.local_campaigns_subtitle')}</p>
      <Tabs
        className="tabs-filter"
        value={selectedCategory}
        onChange={(e, newValue) => setSelectedCategory(newValue)}
        classes={{
          indicator: classes.tabsIndicator,
        }}
      >
        {[
          { id: 'ALL', title: 'Tout' },
          ...(campaignsCategories?.slice(0, 6) ?? []),
        ]?.map((tab) => (
          <Tab
            key={tab?.id}
            value={tab?.id}
            label={tab?.title}
            className="tab"
          />
        ))}
      </Tabs>
      <Grid container spacing={2}>
        {campaigns &&
          campaigns.map((campaign, index) => (
            <Grid item xs={2} key={index}>
              <LocalCampaignCard campaign={campaign} />
            </Grid>
          ))}
      </Grid>
      <div className="link-button">
        <Button
          color="primary"
          endIcon={<ArrowForwardOutlinedIcon />}
          onClick={() => router.push(ShopRoute.AllLocalCampaigns)}
        >
          {t('homepage.see_all_local_campaigns')}
        </Button>
      </div>
    </div>
  );
};

export default LocalCampaignsContainer;
