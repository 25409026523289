import { useEffect, useRef } from 'react';
import {
  ChiliApiKeyResolver,
  ChiliEditorObject,
  ChiliEventHandler,
  ChiliEventType,
  ChiliHTMLIFrameElement,
} from './types';
import { chiliResetHostInterface, chiliSetHostInterface } from './utils';

export function useChiliEditor(
  apiKey: ChiliApiKeyResolver,
  eventHandlers: Partial<Record<ChiliEventType, ChiliEventHandler>>
) {
  const editorRef = useRef<ChiliEditorObject>();
  const frameRef = useRef<ChiliHTMLIFrameElement>(null);
  const asyncRef = useRef<
    Partial<
      Record<ChiliEventType, (value: Parameters<ChiliEventHandler>) => void>
    >
  >({});

  useEffect(() => {
    chiliSetHostInterface({
      OnGetApiKey(callback) {
        callback(apiKey());
      },
      OnEditorEvent(event, targetID) {
        const editor = editorRef.current;
        if (!editor) {
          return;
        }

        // Automatically register all event handlers when document loaded
        if (event === 'DocumentFullyLoaded') {
          const registeredEvents = Object.keys(
            eventHandlers
          ) as ChiliEventType[];
          registeredEvents.forEach((k) => editor.AddListener(k));
        }

        // Call event handler if defined
        eventHandlers[event]?.(editor, targetID);

        // Resolve async promise if defined
        asyncRef.current[event]?.([editor, targetID]);
        asyncRef.current[event] = undefined;
      },
    });

    return () => {
      chiliResetHostInterface();
    };
  });

  function onEditorLoad() {
    editorRef.current = undefined;

    const frameWindow = frameRef.current?.contentWindow;
    if (!frameWindow || !frameWindow.GetEditor) {
      return;
    }

    frameWindow.GetEditor(() => {
      editorRef.current = frameWindow.editorObject;
    });
  }

  function waitFor(event: ChiliEventType) {
    return new Promise<Parameters<ChiliEventHandler>>((resolve, reject) => {
      if (!editorRef.current || asyncRef.current[event]) {
        return reject(new Error());
      }
      editorRef.current.AddListener(event);
      asyncRef.current[event] = resolve;
    });
  }

  return {
    editorRef,
    frameProps: {
      onLoad: onEditorLoad,
      ref: frameRef,
    },
    waitFor,
  };
}
