import { useQuery } from '@apollo/client';
import { GET_TAGS } from 'app/graphql/queries/tags';
import { GetTags, GetTagsVariables } from 'app/schemaInterfaces/GetTags';
import { TagsFilterInput } from 'app/schemaInterfaces/globalTypes';

const useTagsQuery = (filter: TagsFilterInput) => {
  const { data, loading, error } = useQuery<GetTags, GetTagsVariables>(
    GET_TAGS,
    {
      variables: {
        filter,
      },
      fetchPolicy: 'network-only',
    }
  );

  return { tags: data?.tags, loading, error };
};

export default useTagsQuery;
