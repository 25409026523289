import React, { FC } from 'react';

import { ChevronLeftOutlined } from '@material-ui/icons';
import { GetEntities_entities as Entity } from 'app/schemaInterfaces/GetEntities';
import EntityMenuOption from 'components/Navigation/user-menu/entity-menu/EntityMenuOption/EntityMenuOption';
import { GetEntitiesList_entities } from 'app/schemaInterfaces/GetEntitiesList';
import { Maybe } from 'app/utils/common';
import { entityMenuSelectStyle } from './EntityMenuSelect.style';
import { useTranslation } from 'react-i18next';

export interface EntityMenuSelectProps {
  value: Maybe<Omit<Entity, 'ancestors' | 'addressBlocks'>>;
  title: string;
  entities: GetEntitiesList_entities[];
  onEntitySelect?: (entity: Maybe<GetEntitiesList_entities>) => void;
  onBackClick?: () => void;
}

const EntityMenuSelect: FC<EntityMenuSelectProps> = ({
  value,
  title,
  entities,
  onEntitySelect,
  onBackClick,
}) => {
  const { t } = useTranslation();

  return (
    <div data-testid="menu-select">
      <div css={entityMenuSelectStyle.header} onClick={onBackClick}>
        <ChevronLeftOutlined />
        <h6 css={entityMenuSelectStyle.title}>{title}</h6>
      </div>
      <EntityMenuOption
        name={t('header.user_menu.entity_menu.all')}
        isLogoHidden={true}
        isSelected={value === null}
        onClick={() => onEntitySelect?.(null)}
      />
      {entities.map((entity) => (
        <EntityMenuOption
          key={entity.id}
          name={entity.name}
          isSelected={value?.id === entity.id}
          onClick={() => onEntitySelect?.(entity)}
        />
      ))}
    </div>
  );
};

export default EntityMenuSelect;
