import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const summaryStepStyle = css`
  padding-left: 64px;
  padding-right: 64px;
  .billing-container {
    margin-top: 42px;
  }
  .billing-box {
    padding: 20px;
  }
  .summary-basket-row {
    border-bottom: 2px solid ${colors.buttonGroupOutlined};
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 215px;
  }
  .campaign-header {
    margin-bottom: 0;
  }
`;
