import React, { useEffect } from 'react';
import { useAuth0 } from 'app/auth/AuthContext';
import Loader from 'components/Loader/Loader/Loader';

const Login = (): JSX.Element => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    (async () => {
      await loginWithRedirect({
        appState: { targetUrl: '/' },
        page: 'LOGIN',
      });
    })();
  }, [loginWithRedirect]);

  return <Loader height="100vh" />;
};

export default Login;
