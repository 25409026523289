import React, { PropsWithChildren, FC } from 'react';
import {
  Dialog,
  Box,
  DialogContent,
  IconButton,
  DialogTitle,
  Typography,
  DialogProps,
  Slide,
  Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import colors from 'styles/colors.module.scss';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

interface MaxWidth {
  open: DialogProps['maxWidth'];
  close: DialogProps['maxWidth'];
}

interface GoBack {
  label: string;
  handleClose?: () => void;
}

interface Detachable {
  children: React.ReactNode;
  isOpen: boolean;
  maxWidth?: MaxWidth;
  goBack?: GoBack;
  bgcolor?: string;
}

interface ShowMore {
  handleOpen?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  title?: React.ReactNode;
}

export interface DetachableFormWrapperProps {
  isOpen: boolean;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  handleClose: () => void;
  handleOpenShowMore?: () => void;
  maxWidth?: DialogProps['maxWidth'];
  classes?: DialogProps['classes'];
  detachable?: Detachable;
  showMore?: ShowMore;
}

const DetachableFormWrapper: FC<PropsWithChildren<
  DetachableFormWrapperProps
>> = ({
  isOpen,
  handleClose,
  title,
  maxWidth,
  children,
  detachable,
  subtitle,
  classes,
  showMore,
}) =>
  isOpen ? (
    <Dialog
      classes={classes}
      open={isOpen}
      maxWidth={
        maxWidth ?? !detachable?.isOpen
          ? detachable?.maxWidth?.close ?? 'sm'
          : detachable?.maxWidth?.open ?? 'md'
      }
      fullWidth
      onClose={handleClose}
      data-testid="dialog"
    >
      <Box display="flex">
        <Box display="flex" width="100%" flexDirection="column">
          <Box display="flex" justifyContent="space-between">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width={1}
              pt={1}
              pl={4}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="baseline"
                width={1}
              >
                <DialogTitle
                  id="main-form-dialog-title"
                  disableTypography
                  style={{ padding: 0 }}
                >
                  <Typography variant="h3">{title}</Typography>
                  <Typography variant="body1">{subtitle}</Typography>
                </DialogTitle>
                {showMore && showMore.title && (
                  <Box data-testid="showmore">
                    <Button
                      color="primary"
                      size="large"
                      endIcon={<ArrowRightIcon />}
                      onClick={showMore.handleOpen}
                      data-testid="showmore-button"
                    >
                      {showMore.title}
                    </Button>
                  </Box>
                )}
              </Box>
              {!detachable?.isOpen && (
                <Box data-testid="dialog-close">
                  <IconButton aria-label="close" onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              )}
            </Box>
          </Box>
          <DialogContent>{children}</DialogContent>
        </Box>
        {detachable && detachable.isOpen && (
          <Slide
            direction="left"
            timeout={300}
            in={detachable.isOpen}
            mountOnEnter
            unmountOnExit
            data-testid="detached"
          >
            <Box bgcolor={detachable.bgcolor}>
              <Box
                display="flex"
                justifyContent="space-between"
                bgcolor={colors.documentsHeaderBackground}
                paddingTop="5px"
              >
                <Button
                  color="primary"
                  size="large"
                  startIcon={<ArrowLeftIcon />}
                  onClick={() => detachable.goBack?.handleClose?.()}
                >
                  {detachable.goBack?.label}
                </Button>
                <IconButton aria-label="close" onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <Box display="flex">{detachable?.children}</Box>
            </Box>
          </Slide>
        )}
      </Box>
    </Dialog>
  ) : null;

export default DetachableFormWrapper;
