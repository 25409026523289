import { FormLabel, TextField } from '@material-ui/core';
import ConfirmationDialog from 'components/ConfirmationDialogs/ConfirmationDialog/ConfirmationDialog';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  fieldErrorStyle,
  headerStyle,
  labelStyle,
  requiredStyle,
} from './FollowOrderRefusalDialog.style';

export interface FollowOrderRefusalDialogProps {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: (data: FollowOrderRefusalDialogFormData) => void;
}

export interface FollowOrderRefusalDialogFormData {
  message: string;
}

const DEFAULT_FORM_VALUES: FollowOrderRefusalDialogFormData = {
  message: '',
};

const FollowOrderRefusalDialog: FC<FollowOrderRefusalDialogProps> = ({
  isOpen,
  onCancel,
  onConfirm,
}) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FollowOrderRefusalDialogFormData>({
    defaultValues: DEFAULT_FORM_VALUES,
  });

  function handleValidSubmit(data: FollowOrderRefusalDialogFormData) {
    reset(DEFAULT_FORM_VALUES);
    onConfirm(data);
  }

  function handleCancel() {
    reset(DEFAULT_FORM_VALUES);
    onCancel();
  }

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      title={t('follow_orders.decline_order')}
      onClose={handleCancel}
      actions={[
        {
          title: t('follow_orders.validation_dialog.cancel'),
          buttonVariant: 'text',
          onClick: handleCancel,
          isKeptOpenOnClick: true,
        },
        {
          title: t('follow_orders.validation_dialog.confirm_refusal'),
          onClick: handleSubmit(handleValidSubmit),
          isKeptOpenOnClick: true,
        },
      ]}
    >
      <h4 css={headerStyle}>
        {t('follow_orders.validation_dialog.subtitle_refusal')}
      </h4>
      <p>
        {t('follow_orders.validation_dialog.irreversible_action')}
        <br />
        {t('follow_orders.validation_dialog.explanation_refusal')}
      </p>
      <FormLabel css={labelStyle} htmlFor="refusal-dialog-message">
        {t('follow_orders.validation_dialog.refusal_message_field')}{' '}
        <span css={requiredStyle}>*</span>
      </FormLabel>
      <TextField
        {...register('message', {
          required: {
            value: true,
            message: t(
              'follow_orders.validation_dialog.refusal_message_required'
            ),
          },
          maxLength: {
            value: 200,
            message: t(
              'follow_orders.validation_dialog.refusal_message_too_long'
            ),
          },
        })}
        multiline
        minRows={5}
        maxRows={20}
        variant="outlined"
        fullWidth
        placeholder={t(
          'follow_orders.validation_dialog.refusal_message_placeholder'
        )}
        error={!!errors.message}
        id="refusal-dialog-message"
      />
      {!!errors.message && (
        <p css={fieldErrorStyle}>{errors.message.message}</p>
      )}
    </ConfirmationDialog>
  );
};

export default FollowOrderRefusalDialog;
