import { useLazyQuery } from '@apollo/client';
import { GET_CLIENT_PRICES } from 'app/graphql/queries/prices';
import {
  getClientPrices,
  getClientPricesVariables,
} from 'app/schemaInterfaces/getClientPrices';
import { useEffect } from 'react';

const useClientPricesQuery = ({
  productId,
  minimum,
  maximum,
}: {
  productId?: string | null;
  minimum?: number | null;
  maximum?: number | null;
}) => {
  const [
    getClientPrices,
    { data: clientPricesData, loading: clientPricesLoading },
  ] = useLazyQuery<getClientPrices, getClientPricesVariables>(
    GET_CLIENT_PRICES,
    {
      fetchPolicy: 'no-cache',
    }
  );
  useEffect(() => {
    if (productId) {
      getClientPrices({
        variables: {
          productId,
          minimum,
          maximum,
        },
      });
    }
  }, [getClientPrices, maximum, minimum, productId]);
  return {
    getClientPrices,
    clientPricesData,
    clientPricesLoading,
  };
};

export default useClientPricesQuery;
