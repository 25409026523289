import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import BigBreadcrumbs, {
  BreadcrumbsLink,
} from 'components/studio/BigBreadcrumbs';
import { DivisionElement } from 'components/useProductsWithAssets';

interface ProductsTypeNavigationProps {
  divisions?: DivisionElement[];
  setSelectedDivision?: React.Dispatch<React.SetStateAction<DivisionElement>>;
  setIsLastDivision?: React.Dispatch<React.SetStateAction<boolean>>;
  setDivisions?: React.Dispatch<
    React.SetStateAction<DivisionElement[] | undefined>
  >;
}

const ProductsTypeNavigation = ({
  divisions,
  setSelectedDivision,
  setIsLastDivision,
  setDivisions,
}: ProductsTypeNavigationProps): JSX.Element => {
  const { t } = useTranslation();

  const getSelectedDivisions = !!divisions?.length
    ? divisions?.reduce((divisions, { value }: DivisionElement) => ({
        ...divisions,
        value,
      }))
    : null;

  const navigationLinks = useMemo(() => {
    const value: BreadcrumbsLink[] = [
      {
        name: 'all',
        label: t('workflow.left_side_bar.medias_panel.content_navigation.all'),
      },
    ];
    if (divisions) {
      divisions.forEach((division) => {
        value.push({
          name: division.division as string,
          label: division.value as string,
          selected: Boolean(
            getSelectedDivisions?.value
              ?.toLowerCase()
              .includes((division.value as string).toLowerCase())
          ),
        });
      });
    }
    return value;
  }, [t, divisions, getSelectedDivisions]);

  const handleNavigationLinks = (link: BreadcrumbsLink) => {
    let indexDivision = navigationLinks.indexOf(link);
    setSelectedDivision?.({
      division: navigationLinks[indexDivision + 1]?.name,
      value: link.label,
    });
    setIsLastDivision?.(false);
    let newDivisions = divisions?.filter(
      (division, index) => index < indexDivision && division
    );
    setDivisions?.(newDivisions);
  };

  return (
    <BigBreadcrumbs links={navigationLinks} onClick={handleNavigationLinks} />
  );
};

export default ProductsTypeNavigation;
