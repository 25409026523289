import React from 'react';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { informationToastStyle } from './InformationToast.style';

export interface InformationToastProps {
  info: string;
  description?: string;
}

const InformationToast = ({ info, description }: InformationToastProps) => (
  <div css={informationToastStyle}>
    <div className="info">
      <InfoOutlinedIcon fontSize="small" className="icon" />
      <p>{info}</p>
    </div>
    {description && <p className="description">{description}</p>}
  </div>
);

export default InformationToast;
