import { css } from '@emotion/react/macro';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  TextField,
} from '@material-ui/core';
import {
  ErrorMessage,
  Field,
  getIn,
  isInteger,
  useFormikContext,
} from 'formik';
import { DocumentFormValues } from 'modules/pro/documents/form/model/definitions';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import RadioButtonsGroup from './fields/RadioButtonsGroup';
import { DocumentQuantitiesType } from 'app/schemaInterfaces/globalTypes';
import useClientPricesQuery from 'modules/shop/queries/useClientPricesQuery';
import InformationToast from './InformationToast/InformationToast';

const style = css`
  .quantity {
    width: auto;
  }
  .strict-quantity {
    width: 50%;
  }
  .radio-group {
    padding-right: 20px;
  }
  .MuiFormGroup-row {
    width: 500px;
  }

  .quantity-types-field {
    .MuiFormControlLabel-root {
      margin-top: 18px;
      margin-left: -10px;
    }
  }

  .field-date {
    width: 350px;
  }
`;

const DocumentQuantitiesSubform = ({
  setLowestQuantity,
}: {
  setLowestQuantity: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { t } = useTranslation();

  const {
    values: {
      document: { quantities, productId },
    },
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
  } = useFormikContext<DocumentFormValues>();

  const {
    getClientPrices,
    clientPricesData,
    clientPricesLoading,
  } = useClientPricesQuery({});

  const lowestQuantity = clientPricesData?.prices.length
    ? clientPricesData?.prices[0].quantity
    : 1;

  useEffect(() => {
    setLowestQuantity(lowestQuantity);
  }, [lowestQuantity, setLowestQuantity]);

  const getQuantityTypesOptions = () => [
    {
      label: t('global.document_form.quantity-range'),
      value: DocumentQuantitiesType.Range,
    },
    {
      label: t('global.document_form.strict-quantity'),
      value: DocumentQuantitiesType.Strict,
    },
    {
      label: t('global.document_form.free_quantity'),
      value: null,
    },
  ];

  const handleInputNumberFormat = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    // to prevent UI refresh when we press other keystrokes than numbers
    if (!isInteger(event.key)) {
      event.preventDefault();
    }
  };

  const strictQuantity = 'document.quantities.strictQuantity';
  const rangeMinimum = 'document.quantities.rangeQuantity.minimum';
  const rangeMaximum = 'document.quantities.rangeQuantity.maximum';

  const handleChange = (value: DocumentQuantitiesType | null) => {
    productId &&
      !clientPricesData &&
      getClientPrices({ variables: { productId } });
    if (quantities?.type === value || value === null) {
      setFieldValue(`document.quantities`, null);
    } else {
      setFieldValue(`document.quantities.type`, value);
      setFieldValue(`document.quantities.strictQuantity`, null);
      setFieldValue(`document.quantities.rangeQuantity.minimum`, null);
      setFieldValue(`document.quantities.rangeQuantity.maximum`, null);
    }
  };

  const handleQuantityChange = (value: string | undefined, name: string) => {
    setFieldTouched(name);
    setFieldValue(name, value ? parseInt(value) : null);
  };

  return (
    <div css={style}>
      <div className="quantity-types-field">
        <RadioButtonsGroup
          title={`${t('global.document_form.quantities')} *`}
          options={getQuantityTypesOptions()}
          handleChange={handleChange}
        />
      </div>
      {quantities === null && (
        <InformationToast info={t('global.document_form.quantity-info')} />
      )}

      {quantities?.type === DocumentQuantitiesType.Strict &&
        !clientPricesLoading && (
          <div className="field-date">
            <FormLabel className="label">
              {t('global.document_form.strict-quantity')} *
            </FormLabel>
            <Field
              component={TextField}
              className="text-field strict-quantity"
              name={strictQuantity}
              variant="outlined"
              margin="dense"
              onChange={(event: { target: { value: string } }) =>
                handleQuantityChange(event.target.value, strictQuantity)
              }
              onKeyPress={handleInputNumberFormat}
              value={quantities?.strictQuantity ?? null}
              inputProps={{
                placeholder: t('global.document_form.min-order', {
                  quantity: lowestQuantity,
                }),
              }}
            />
            <FormControl
              error={
                getIn(touched, strictQuantity) &&
                Boolean(getIn(errors, strictQuantity))
              }
            >
              <ErrorMessage name={strictQuantity} component={FormHelperText} />
            </FormControl>
          </div>
        )}

      {quantities?.type === DocumentQuantitiesType.Range &&
        !clientPricesLoading && (
          <div className="field">
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormLabel className="label">
                  {t('global.document_form.min-quantity')} *
                </FormLabel>
                <Field
                  component={TextField}
                  className="text-field quantity"
                  name={rangeMinimum}
                  variant="outlined"
                  margin="dense"
                  onChange={(event: { target: { value: string } }) =>
                    handleQuantityChange(event.target.value, rangeMinimum)
                  }
                  onKeyPress={handleInputNumberFormat}
                  value={quantities?.rangeQuantity?.minimum ?? null}
                  inputProps={{
                    placeholder: t('global.document_form.min-order', {
                      quantity: lowestQuantity,
                    }),
                  }}
                />
                <FormControl
                  error={
                    getIn(touched, rangeMinimum) &&
                    Boolean(getIn(errors, rangeMinimum))
                  }
                >
                  <ErrorMessage
                    name={rangeMinimum}
                    component={FormHelperText}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormLabel className="label">
                  {t('global.document_form.max-quantity')} *
                </FormLabel>
                <Field
                  component={TextField}
                  className="text-field quantity"
                  name={rangeMaximum}
                  variant="outlined"
                  margin="dense"
                  onChange={(event: { target: { value: string } }) =>
                    handleQuantityChange(event.target.value, rangeMaximum)
                  }
                  onKeyPress={handleInputNumberFormat}
                  value={quantities?.rangeQuantity?.maximum ?? null}
                />
                <FormControl
                  error={
                    getIn(touched, rangeMaximum) &&
                    Boolean(getIn(errors, rangeMaximum))
                  }
                >
                  <ErrorMessage
                    name={rangeMaximum}
                    component={FormHelperText}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </div>
        )}
    </div>
  );
};

export default DocumentQuantitiesSubform;
