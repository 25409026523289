import React, {
  useState,
  useMemo,
  useReducer,
  useEffect,
  useCallback,
} from 'react';
import { Box, Button } from '@material-ui/core';
import AppTable from 'components/AppTable/AppTable';
import { useQuery, useApolloClient, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import {
  getProductsColumns,
  productsDetailsWithHisId,
  useDownloadProductsSheet,
  VisuelFieldName,
} from 'modules/pim/baseProducts/ProductsHelper';
import Loader from 'components/Loader/Loader/Loader';
import FileUploadRestApi from 'components/FileUploadRestApi';
import ProductsSheetCreation from 'modules/pim/baseProducts/ProductsSheetCreation';
import { ProductsDetailsWithHisId } from 'modules/pim/baseProducts/BaseProductsList';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import {
  GetCampaignById,
  GetCampaignByIdVariables,
} from 'app/schemaInterfaces/GetCampaignById';
import { ColumnInstance, Row } from 'react-table';
import HideColumns from 'components/HideColumns/HideColumns';
import { FileStore } from 'modules/pim/baseProducts/PimFormFieldsConfig';
import {
  ProductsSheetsContextState,
  ProductsSheetsStateProvider,
} from 'modules/pim/baseProducts/BaseProducts';
import { PimFilterPopover } from 'modules/pim/components/PimFilterPopover';
import PimSearchBar from 'modules/pim/components/PimSearchBar';
import {
  GetAssetById,
  GetAssetByIdVariables,
  GetAssetById_getAssetById,
} from 'app/schemaInterfaces/GetAssetById';
import { GET_ASSET_BY_ID } from 'app/graphql/queries/dam';
import { useInfiniteScroll } from 'components/infinite-scroll/useInfiniteScroll';
import {
  productsFiltersReducer,
  ProductsInitialState,
} from 'modules/pim/baseProducts/reducers/productReducer';
import {
  PermissionKey,
  ProductFilterInput,
} from 'app/schemaInterfaces/globalTypes';
import ProductSheetEdit from 'modules/pim/components/ProductSheetEdit';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { useTranslation } from 'react-i18next';
import SelectConfirmationDialog from 'components/ActionsButtons/SelectConfirmationDialog';
import useAppContext from 'app/app-context/useAppContext';
import { GET_CAMPAIGN_BY_ID } from 'app/graphql/queries/campaigns';
import {
  GetProducts,
  GetProductsVariables,
  GetProducts_getProducts_products,
} from 'app/schemaInterfaces/GetProducts';
import {
  GET_PRODUCTS,
  REMOVE_MULTIPLE_PRODUCTS,
} from 'app/graphql/queries/products';
import {
  RemoveMultipleProducts,
  RemoveMultipleProductsVariables,
} from 'app/schemaInterfaces/RemoveMultipleProducts';
import { Optional } from 'app/utils/common';
import { PimRegrouping } from 'modules/pim/baseProducts/PimFormConfigTypes';
import usePermissions from 'app/auth/usePermissions';

interface ProductToEdit {
  productData: Record<string, any>;
}

export interface GetProductsWithMainAsset
  extends GetProducts_getProducts_products {
  assets?: GetAssetById_getAssetById;
}

const ProductsSheetList = (): JSX.Element => {
  const {
    client: { config: clientConfig },
  } = useAppContext();
  const { id } = useParams<{ id: string }>();
  const [openProductsSheetDialog, setOpenProductsSheetDialog] = useState<
    boolean
  >(false);
  const [selectedRows, setSelectedRows] = React.useState<
    Row<Record<string, any>>[]
  >([]);
  const [filteredColumns, setFilteredColumns] = useState<
    ColumnInstance<ProductsDetailsWithHisId>[]
  >([]);
  const [fieldName, setFieldName] = React.useState<Optional<string>>(undefined);
  const [formFilesManager, setFormFilesManager] = useState<FileStore[]>([]);
  const [filesLimitNumber, setFilesLimitNumber] = useState<number>(0);
  const [productsDataWithUrl, setProductsDataWithUrl] = useState<
    Optional<Optional<GetProductsWithMainAsset>[]>
  >([]);
  const [isHistoryOpen, setIsHistoryOpen] = useState<boolean>(false);
  const [isComfirmationDialogOpen, setIsComfirmationDialogOpen] = useState<
    boolean
  >(false);
  const [regrouping, setRegrouping] = useState<PimRegrouping[]>([]);
  const { t } = useTranslation();

  const [productsFilter, productsDispatch] = useReducer(
    productsFiltersReducer,
    ProductsInitialState
  );

  const [productToEdit, setProductToEdit] = useState<ProductToEdit>({
    productData: {},
  });

  const { productData } = productToEdit;

  const {
    data: productsSheetsData,
    error: campaignProductsError,
    refetch,
    loading,
    fetchMore,
  } = useQuery<GetProducts, GetProductsVariables>(GET_PRODUCTS, {
    variables: {
      campaignId: id,
      filter: productsFilter.filters,
      pagination: { offset: 0, limit: 10 },
      isSortByDate: true,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    skip: false,
  });

  const { data: campaignData } =
    useQuery<GetCampaignById, GetCampaignByIdVariables>(GET_CAMPAIGN_BY_ID, {
      variables: {
        id,
      },
    }) || {};

  const { getCampaignById: campaign } = campaignData ?? {};
  const { getProducts: productsSheets } = productsSheetsData ?? {};

  const loadMoreProducts = useCallback(() => {
    if (!loading) {
      fetchMore({
        variables: {
          pagination: {
            offset: productsSheetsData?.getProducts.products.length || 0,
            limit: 10,
          },
          isSortByDate: true,
        },
        updateQuery: (
          prevData: GetProducts,
          { fetchMoreResult }
        ): GetProducts => {
          if (
            !fetchMoreResult ||
            fetchMoreResult.getProducts.products.length === 0
          )
            return prevData;
          return {
            getProducts: {
              ...prevData.getProducts,
              products: [
                ...prevData.getProducts.products,
                ...fetchMoreResult.getProducts.products,
              ],
            },
          };
        },
      });
    }
  }, [fetchMore, loading, productsSheetsData]);

  const waypointRef = useInfiniteScroll(loadMoreProducts, loading);

  const handleOpenProductsSheetDialog = (row: Record<string, any>) => {
    setProductToEdit({
      productData: row,
    });
    setOpenProductsSheetDialog(true);
    setFieldName(undefined);
    setFormFilesManager([]);
    setFilesLimitNumber(0);
  };
  const { hasPermission } = usePermissions();
  const handleCloseProductsSheetDialog = () => {
    setOpenProductsSheetDialog(false);
    setIsHistoryOpen(false);
  };

  const handleOpenHistory = () => setIsHistoryOpen(!isHistoryOpen);

  const campaignProductsColumns = useMemo(
    () => getProductsColumns(clientConfig),
    [clientConfig]
  );
  const productsDetails = useMemo(
    () =>
      productsDetailsWithHisId({
        productsSheets: productsDataWithUrl as GetProductsWithMainAsset[],
      }) as ProductsDetailsWithHisId[],
    [productsDataWithUrl]
  );

  /*the name of the constante mutation need to be "query" because i'm using it as a api rest*/
  const query = `
	mutation ImportProductsCampaign($file: Upload!, $id: String!) {
		importProducts(file: $file, campaignId: $id) {
			clientId
			_id
			createdAt
			createdBy
			details
			updatedAt
			updatedBy
		}
	}
	`;

  const variables = { id, file: null };

  const {
    isUploadInProgress,
    uploadFile,
    setIsUploadInProgress,
  } = FileUploadRestApi(query, variables, refetch);

  const client = useApolloClient();

  const notStickyColumns = campaignProductsColumns
    ?.filter((column) => column.sticky !== 'left' && column)
    .map((column) => column?.Header as string);

  const appTableAllColumnsPropHandler = (
    allColumnsProp: ColumnInstance<ProductsDetailsWithHisId>[]
  ) => {
    setFilteredColumns(allColumnsProp);
  };

  const getRowId = React.useCallback(
    (row: ProductsDetailsWithHisId) => row.productId,
    []
  );

  const downloadProductsSheet = useDownloadProductsSheet(campaign);

  const toolbarButtons: React.ReactNode[] = [
    <Button
      variant="text"
      color="primary"
      onClick={() => downloadProductsSheet(selectedRows)}
      startIcon={<GetAppOutlinedIcon />}
    >
      {t('pim.toolbar.export')}
    </Button>,
    <Button
      variant="text"
      color="primary"
      onClick={() => setIsComfirmationDialogOpen(true)}
      startIcon={<FileCopyOutlinedIcon />}
    >
      {t('pim.toolbar.delete')}
    </Button>,
  ];

  const values: ProductsSheetsContextState = {
    filter: productsFilter.filters as ProductFilterInput,
    dispatch: productsDispatch,
    fieldName,
    setFieldName,
    formFilesManager,
    setFormFilesManager,
    filesLimitNumber,
    setFilesLimitNumber,
    regrouping,
    setRegrouping,
  };

  useEffect(() => {
    const addUrlToProducts = async (data: GetProducts) => {
      const productsPromises = data.getProducts.products.map((product) => {
        const getMainVisuel = product.details.filesToSend?.find(
          (file: FileStore) => file.fieldName === VisuelFieldName.mainVisuel
        );
        if (getMainVisuel) {
          return client.query<GetAssetById, GetAssetByIdVariables>({
            query: GET_ASSET_BY_ID,
            variables: {
              id: getMainVisuel.assetId,
            },
          });
        }
        return undefined;
      });
      const assetsData = await Promise.all(productsPromises);
      const productWithUrl = productsSheetsData?.getProducts.products.map(
        (product, index) => ({
          ...product,
          assets: assetsData[index]?.data.getAssetById,
        })
      );

      setProductsDataWithUrl(productWithUrl);
    };
    if (productsSheetsData?.getProducts) {
      addUrlToProducts(productsSheetsData);
    }
  }, [productsSheetsData, client, setIsUploadInProgress]);

  const [deleteMultipleProducts] = useMutation<
    RemoveMultipleProducts,
    RemoveMultipleProductsVariables
  >(REMOVE_MULTIPLE_PRODUCTS, {
    refetchQueries: [
      {
        query: GET_PRODUCTS,
        variables: {
          campaignId: id,
          filter: productsFilter.filters,
          pagination: { offset: 0, limit: 10 },
          isSortByDate: true,
        },
      },
    ],
  });

  const deleteProducts = () =>
    deleteMultipleProducts({
      variables: {
        productIds: selectedRows.map(({ original }) => original.productId),
      },
    });

  return (
    <ProductsSheetsStateProvider value={values}>
      <Box pt={2}>
        <Box>
          <Box pb={2} display="flex" justifyContent="flex-end">
            <Box pr={2}>
              <HideColumns
                columnHiding={{
                  enabled: true,
                  columnsFilter: filteredColumns,
                  fixedColumnsIDs: notStickyColumns as string[] | undefined,
                }}
              />
            </Box>
            <Box pr={2}>
              <PimFilterPopover products={productsSheetsData?.getProducts} />
            </Box>
            <Box pr={1}>
              <PimSearchBar placeholder={t('pim.table.search_placeholder')} />
            </Box>
            {hasPermission(PermissionKey.CreateProduct) && (
              <ProductsSheetCreation
                isCampaign={true}
                uploadFile={uploadFile}
                campaignId={id}
              />
            )}
          </Box>
          {campaignProductsColumns && productsDetails && clientConfig ? (
            !isUploadInProgress ? (
              <Box>
                {productsSheets ? (
                  <AppTable<ProductsDetailsWithHisId>
                    columns={campaignProductsColumns}
                    data={productsDetails}
                    onRowClick={handleOpenProductsSheetDialog}
                    getRowId={getRowId}
                    hasToolbar={true}
                    toolBarButtons={toolbarButtons}
                    setSelectedRows={setSelectedRows}
                    appTableAllColumnsPropHandler={
                      appTableAllColumnsPropHandler
                    }
                  />
                ) : (
                  <AppTable
                    columns={campaignProductsColumns}
                    data={[]}
                    getRowId={getRowId}
                  />
                )}
                {!productsSheets && <Loader error={campaignProductsError} />}
              </Box>
            ) : (
              <Loader error={campaignProductsError} />
            )
          ) : null}
          {loading ? (
            <Loader error={campaignProductsError} />
          ) : (
            <div ref={waypointRef} />
          )}
        </Box>
        <ProductSheetEdit
          openProductsSheetDialog={openProductsSheetDialog}
          handleCloseProductsSheetDialog={handleCloseProductsSheetDialog}
          handleOpenHistory={handleOpenHistory}
          isHistoryOpen={isHistoryOpen}
          productData={productData}
          campaignId={id}
          productId={productData.productId}
        />
        <SelectConfirmationDialog
          item={undefined}
          isOpen={isComfirmationDialogOpen}
          onClose={() =>
            setIsComfirmationDialogOpen(!setIsComfirmationDialogOpen)
          }
          confirmationDialog={{
            title: t('common.delete_confirmation_dialog.title'),
            description:
              selectedRows.length > 1
                ? t('pim.delete_confirmation_dialog.description_multiple_files')
                : t('pim.delete_confirmation_dialog.description_one_file'),
          }}
          onClick={() => deleteProducts()}
        />
      </Box>
    </ProductsSheetsStateProvider>
  );
};

export default ProductsSheetList;
