import { Box, Typography } from '@material-ui/core';
import { GetIntervalProps, IntervalContext } from 'react-calendar-timeline';
import React, { FC } from 'react';

import { IntervalRenderer } from 'react-calendar-timeline';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { capitalizeFirstLetter } from 'app/utils/common';
import colors from 'styles/colors.module.scss';
import moment from 'moment';

interface MonthViewProps {
  intervalContext: IntervalContext;
  isHomePage: boolean;
  getIntervalProps:
    | ((
        props?: GetIntervalProps | undefined
      ) => Required<GetIntervalProps> & {
        key: string | number;
      })
    | undefined;
}

interface DateViewProps {
  intervalContext: IntervalContext;
  getIntervalProps:
    | ((
        props?: GetIntervalProps | undefined
      ) => Required<GetIntervalProps> & {
        key: string | number;
      })
    | undefined;
  selectedDatePlanning: MaterialUiPickersDate;
}

export const MonthView = ({
  intervalContext,
  isHomePage,
  getIntervalProps,
}: MonthViewProps) => (
  <Box
    {...getIntervalProps?.({
      style: {
        background: !isHomePage
          ? colors.documentsHeaderBackground
          : colors.white,
        height: 40,
        borderLeft: !isHomePage ? `1px solid ${colors.disabled}` : 'none',
        display: 'flex',
        alignItems: 'center',
        color: colors.grey,
      },
    })}
    data-testid="month-view"
  >
    <Box display="flex" alignItems="center">
      <Box position="absolute" left="5%">
        <Typography>
          {moment(intervalContext.interval.startTime).format('w')}
        </Typography>
      </Box>
      <Box position="absolute" left="40%">
        <Typography variant="body2">
          {capitalizeFirstLetter(intervalContext.intervalText)}
        </Typography>
      </Box>
    </Box>
  </Box>
);

export const DateView: FC<DateViewProps> = ({
  intervalContext,
  getIntervalProps,
  selectedDatePlanning,
}) => (
  <Box
    {...getIntervalProps?.({
      style: {
        background: colors.white,
        height: 48,
        borderBottom: `1px solid ${colors.disabled}`,
        pointerEvents: 'none',
        width: 100,
      },
    })}
    display="flex"
    alignItems="center"
    justifyContent="center"
    width={50}
    data-testid="day-view"
  >
    {moment().isSame(intervalContext.interval.startTime, 'day') ? (
      <Box
        height="45px"
        width="45px"
        bgcolor={colors.primary}
        borderRadius={50}
        display="flex"
        flexDirection="column"
      >
        <Box color={colors.white} textAlign="center">
          <Typography variant="body1">
            {capitalizeFirstLetter(
              moment(intervalContext.interval.startTime)
                .format('ddd')
                .split('.')[0]
            )}
          </Typography>
        </Box>
        <Box color={colors.white} textAlign="center">
          <Typography variant="body1">
            {moment(intervalContext.interval.startTime).format('D')}
          </Typography>
        </Box>
      </Box>
    ) : (
      <Box display="flex" flexDirection="column">
        <Box textAlign="center">
          <Typography variant="body1">
            {capitalizeFirstLetter(
              moment(intervalContext.interval.startTime)
                .format('ddd')
                .split('.')[0]
            )}
          </Typography>
        </Box>
        <Box textAlign="center">
          <Typography variant="body1">
            {moment(intervalContext.interval.startTime).format('D')}
          </Typography>
        </Box>
      </Box>
    )}
    <Box
      position="absolute"
      left="98%"
      top="50%"
      bottom="1%"
      borderLeft={`1px solid ${colors.retroplanningBorder}`}
    />
  </Box>
);

export const customMonthHeader = (isHomePage: boolean) => (
  params?: IntervalRenderer<unknown>
) => {
  const { getIntervalProps, intervalContext } = params ?? {};
  return getIntervalProps && intervalContext ? (
    <MonthView
      intervalContext={intervalContext}
      isHomePage={isHomePage}
      getIntervalProps={getIntervalProps}
    />
  ) : null;
};

export const customDayHeader = (
  selectedDatePlanning: MaterialUiPickersDate
) => (params?: IntervalRenderer<unknown>) => {
  const { getIntervalProps, intervalContext } = params ?? {};
  return getIntervalProps && intervalContext ? (
    <DateView
      intervalContext={intervalContext}
      getIntervalProps={getIntervalProps}
      selectedDatePlanning={selectedDatePlanning}
    />
  ) : null;
};
