import React from 'react';
import { Box, Button, createStyles, Typography } from '@material-ui/core';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import { useDropzone } from 'react-dropzone';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() =>
  createStyles({
    dropzone: {
      outline: 'none',
    },
  })
);
interface EmptyTableFileUploadProps {
  img?: string;
  title?: string;
  subtitle: string;
  buttonLabel: string;
  onFileChange: (event: File[]) => void;
  acceptedFiles: string | string[];
  imgWidth?: string;
}
const centerItems = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const EmptyTableFileUpload = ({
  img,
  title,
  subtitle,
  onFileChange,
  acceptedFiles,
  buttonLabel,
  imgWidth,
}: EmptyTableFileUploadProps): JSX.Element => {
  const classes = useStyles();

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (files: File[]) => {
      onFileChange(files);
    },
    accept: acceptedFiles,
  });
  return (
    <Box>
      {img && (
        <Box
          {...getRootProps({ className: classes.dropzone })}
          {...centerItems}
          pt={3}
          style={{ cursor: 'pointer' }}
        >
          <Box>
            <img src={img} width={imgWidth} alt="upload" />
          </Box>
        </Box>
      )}
      <Box {...centerItems} pt={2}>
        <Typography paragraph={true} variant="h2">
          {title}
        </Typography>
      </Box>
      <Box {...centerItems} pt={1}>
        <Typography paragraph={true} variant="subtitle1">
          {subtitle}
        </Typography>
      </Box>
      <Box {...centerItems} pt={1}>
        <Box borderRadius="25px">
          <Button
            color="primary"
            variant="contained"
            component="label"
            endIcon={<CloudUploadOutlinedIcon />}
          >
            {buttonLabel}
            <input
              style={{ display: 'none' }}
              {...getInputProps({ className: classes.dropzone })}
            />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default EmptyTableFileUpload;
