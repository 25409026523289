import { ShopRoute, shopBreadcrumbs } from 'modules/shop/shopHelpers';

import Breadcrumbs from 'modules/shop/components/Breadcrumbs';
import { CampaignFilterInput } from 'app/schemaInterfaces/globalTypes';
import CampaignsList from 'modules/pro/campaigns/CampaignsList';
import DocumentSkeletonLoader from 'modules/home/components/available-documents/DocumentsSkeletonLoader';
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import NoItemsNotice from 'modules/shop/components/NoItemsNotice';
import React from 'react';
import ShopFilters from '../../components/ShopFilters';
import { allCampaignsStyle } from './AllCampaigns.style';
import { campaignDetailsActions } from 'modules/pro/campaigns/campaignsHelper';
import { useAllCampaigns } from './AllCampaigns.hooks';
import usePermissions from 'app/auth/usePermissions';

const AllCampaigns = ({ filters }: { filters: CampaignFilterInput }) => {
  const { hasPermission } = usePermissions();

  const {
    isCampaignModel,
    campaignsError,
    selectedDateStatus,
    setSelectedDateStatus,
    refetch,
    isLoading,
    campaignsData,
    t,
  } = useAllCampaigns(filters);

  return (
    <div css={allCampaignsStyle}>
      <Breadcrumbs paths={shopBreadcrumbs(t).paths} />
      <h1>
        {isCampaignModel
          ? t('global.thematics.thematics')
          : t('local.common.national_campaigns')}
      </h1>
      <ShopFilters
        dateStatusCount={campaignsData?.getAllCampaigns.dateStatusCount}
        error={campaignsError}
        path={ShopRoute.AllNationalCampaigns}
        hasSeeAll={false}
        hasFilters={!isCampaignModel}
        selectedDateStatus={selectedDateStatus}
        setSelectedDateStatus={setSelectedDateStatus}
        refetch={refetch}
        isCampaignModel={isCampaignModel}
      />
      {isLoading ? (
        <DocumentSkeletonLoader
          numberOfElements={1}
          height={400}
          borderRadius={10}
        />
      ) : campaignsData?.getAllCampaigns.campaigns?.length ? (
        <CampaignsList
          campaigns={campaignsData?.getAllCampaigns.campaigns}
          columnsWidth={[410, 270, 200, 245, 30]}
          imageWidth={80}
          actions={(
            manageCampaign,
            handleOpenCampaignDialog,
            deleteCampaign,
            previewCampaign
          ) =>
            campaignDetailsActions({
              manageCampaign,
              handleOpenCampaignDialog,
              deleteCampaign,
              previewCampaign,
              t,
              hasPermission,
              isCampaignModel,
            })
          }
          button={<MoreVertOutlinedIcon />}
          isCampaignModel={isCampaignModel}
          refetch={refetch}
        />
      ) : (
        <NoItemsNotice
          message={t(
            isCampaignModel
              ? 'shop.common.no_thematics'
              : 'shop.common.no_national_campaign'
          )}
        />
      )}
    </div>
  );
};

export default AllCampaigns;
