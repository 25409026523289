import { Grid } from '@material-ui/core';
import React from 'react';
import { GetAssets_getAssets_assets as Asset } from 'app/schemaInterfaces/GetAssets';
import { AssetPanelThumbnail } from 'modules/workflow/containers/Leftsidebar/AssetsPanel/AssetPanelThumbnail';

interface WorkflowAssetListProps {
  assets: Asset[];
}

export const AssetPanelList = ({
  assets,
}: WorkflowAssetListProps): JSX.Element => {
  return (
    <Grid container>
      {assets.map((asset) => (
        <Grid item xs={6} key={asset.id}>
          <AssetPanelThumbnail asset={asset} />
        </Grid>
      ))}
    </Grid>
  );
};
