import { useQuery } from '@apollo/client';
import { useCurrentUser } from 'app/auth/UserContext';
import { GET_CAMPAIGN_BY_ID } from 'app/graphql/queries/campaigns';
import {
  GetCampaignById,
  GetCampaignByIdVariables,
} from 'app/schemaInterfaces/GetCampaignById';

export const useCampaignForm = (id?: string) => {
  const { data } = useQuery<GetCampaignById, GetCampaignByIdVariables>(
    GET_CAMPAIGN_BY_ID,
    {
      variables: {
        id: id as string, // query is never executed when id is undefined
      },
      skip: !Boolean(id),
    }
  );

  const { getCampaignById: campaign } = data ?? {};
  const { entities } = useCurrentUser();
  return { campaign, entities };
};
