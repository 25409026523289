import { DimensionsInput } from 'app/schemaInterfaces/globalTypes';
import { v4 as uuidv4 } from 'uuid';
import { DraggedAsset } from 'modules/workflow/oneStudioContext';

export const setDraggedAsset = (
  id: string,
  name: string | null,
  url: string | null,
  assetType: string | undefined | null,
  size: DimensionsInput
) => {
  let draggedAsset: DraggedAsset = {
    id: id,
    key: uuidv4(),
    name: name ?? id,
    url: url ?? '',
    metadata: {
      dimensions: {
        height: size.height ?? 0,
        width: size.width ?? 0,
      },
    },
    position: { x: 0, y: 0 },
    size: {
      height: size.height ?? 0,
      width: size.width ?? 0,
    },
    assetType: assetType ?? '',
  };
  return draggedAsset;
};
