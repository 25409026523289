import React from 'react';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import { isInteger } from 'formik';
import { PermissionKey } from 'app/schemaInterfaces/globalTypes';
import moment from 'moment';
import { TFunction } from 'i18next';
import { SalesRoute } from 'modules/sales/salesHelpers';
import { Badge } from '@material-ui/core';
import { NotificationsPopover } from 'modules/notifications/NotificationsPopover';
import { AppRoute } from 'app/routes/routes';
import { ModuleKey } from 'app/routes/helper';

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatPriceHT = (price: number, t: TFunction) =>
  `${price.toFixed(2)} ${t('common.money_unit')} ${t(
    'common.money_unit_tax_free'
  )}`;

export const formatPrice = (price: number, t: TFunction) =>
  `${price.toFixed(2)} ${t('common.money_unit')}`;

export const menuItems = (
  hasPermission: (permission: PermissionKey) => boolean
) => [
  {
    translationKey: 'header.home',
    to: '/',
    moduleKey: ModuleKey.OP,
  },
  ...(hasPermission(PermissionKey.AccessBigShop)
    ? [
        {
          translationKey: 'header.shop',
          to: AppRoute.Shop,
          moduleKey: ModuleKey.ActionMarketing,
        },
      ]
    : []),
  {
    translationKey: 'header.competitors',
    to: '/competitors',
    moduleKey: ModuleKey.Competitors,
  },
  {
    translationKey: 'header.email_and_sms',
    to: 'https://crm.bigone.digital/',
    isExternalLink: true,
    moduleKey: ModuleKey.EmailSMS,
  },
  {
    translationKey: 'header.digital',
    to: 'https://bigone.digital/login',
    isExternalLink: true,
    moduleKey: ModuleKey.Digital,
  },
  {
    translationKey: 'header.library',
    to: '/dam',
    moduleKey: ModuleKey.DAM,
  },
  ...(hasPermission(PermissionKey.AccessPim)
    ? [
        {
          translationKey: 'header.products',
          to: '/products',
          moduleKey: ModuleKey.PIM,
        },
      ]
    : []),
];

export const menuIcons = (number: number) => [
  {
    icon: <NotificationsPopover />,
    isMenu: false,
  },
  {
    icon: (
      <Badge badgeContent={number} color="primary">
        <ShoppingCartOutlinedIcon style={{ marginBottom: '8px' }} />
      </Badge>
    ),
    isMenu: false,
    to: SalesRoute.CommitmentsBasket,
  },
  {
    icon: <AccountCircleOutlinedIcon />,
    isMenu: true,
  },
];
export enum SelectedMenu {
  None = -1,
  OnePac = 0,
  DigitalAssetManagement = 1,
  ProductsBase = 2,
  Manager = 3,
  ShoppingCart = 4,
  UserProfile = 5,
  Shop = 6,
}

export const locationPathNameToSelectedMenu: Record<string, SelectedMenu> = {
  /*
  When the user logs in Global, the component Campaigns loads at the route ‘/campaigns/‘.
  By default the user is on the tab “National”, so he’s redirected to ‘/campaigns/national/’
  To highlight the selected menu “OnePac”, we need to check if either of
  these two urls match, then we set the value SelectedMenu to SelectedMenu.OnePac
  */

  '/campaigns': SelectedMenu.OnePac,
  '/campaigns/national': SelectedMenu.OnePac,
  '/campaigns/model': SelectedMenu.OnePac,
  '/products': SelectedMenu.ProductsBase,
  '/actionsmarketing': SelectedMenu.Shop,
};

export function sortByAlphabeticalOrder<T extends Record<string, any>>(
  items: T[],
  value: string
) {
  return items?.sort((currentItem, nextItem) =>
    currentItem && nextItem && currentItem[value] && nextItem[value]
      ? currentItem[value].localeCompare(nextItem[value])
      : 0
  );
}

export const truncateString = (str: string = '', strLengthLimit: number) => {
  if (str.length > strLengthLimit) {
    return `${str.slice(0, strLengthLimit)}...`;
  } else {
    return str;
  }
};

export const exhaustiveCheck = (
  check: never,
  throwError: boolean = false
): never => {
  if (throwError) {
    throw new Error(
      `ERROR! The value ${JSON.stringify(check)} should be of type never.`
    );
  }
  return check;
};

/**
 * Change the type of Keys of T from NewType
 */
export type ChangeTypeOfKeys<
  T extends object,
  Keys extends keyof T,
  NewType
> = {
  // Loop to every key. We gonna check if the key
  // is assignable to Keys. If yes, change the type.
  // Else, retain the type.
  [key in keyof T]: key extends Keys ? NewType : T[key];
};

export type Optional<T> = T | undefined;
export type Nullish<T> = T | null | undefined;
export type Maybe<T> = T | null;

export enum FileExtension {
  Pdf = 'pdf',
  Psd = 'psd',
}

export type BackgroundSizeCssProps =
  | 'auto'
  | 'contain'
  | 'cover'
  | 'inherit'
  | 'initial'
  | 'revert'
  | 'unset';

export const TOUTFAIRE_CLIENT_ID = '5f0f0a3e2ef0fc001256581f';
export const MONOPRIX_CLIENT_ID = '5fce3e49881bc79a755e21ad';
export const DEFAULT_IMAGE_PREVIEW = `/${process.env.REACT_APP_ASSETS}/noPreviewImageSquare.png`;

export const handleIntegersOnly = async (
  event: React.KeyboardEvent<HTMLInputElement>
) => {
  // to prevent UI refresh when we press other keystrokes than numbers
  if (!isInteger(event.key)) {
    event.preventDefault();
  }
};

export const dateFormat = (dateFrom: string, dateTo: string, t: TFunction) =>
  moment(dateFrom).isSame(moment(dateTo), 'year')
    ? t('global.date-format.day_month')
    : t('global.date-format.day_month_year');

export const getTheDate = (createdAt: string) => {
  const date = new Date(createdAt);
  const postedDate = moment([
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
  ]).fromNow();
  const postedDateCapitalize = capitalizeFirstLetter(postedDate);

  return postedDateCapitalize;
};
export const format = 'DD/MM/YYYY';
