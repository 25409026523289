import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import FormWrapper from 'components/FormWrapper/FormWrapper';
import MultiAssetFormWrapper from 'modules/dam/form/MultiAssetFormWrapper';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AssetInput } from 'app/schemaInterfaces/globalTypes';
import SimpleActionsMenu from 'components/ActionsMenu/SimpleActionsMenu';
import AssetFormWrapper from 'modules/dam/form/AssetFormWrapper';

interface AssetActionMenuProps {
  onAssetCreate: (asset: AssetInput, file: File) => void;
  onAssetsImport?: (asset: AssetInput, files: File[]) => void;
}

const AssetActionMenu = ({
  onAssetCreate,
  onAssetsImport,
}: AssetActionMenuProps): JSX.Element => {
  const [openAssetDialog, setOpenAssetDialog] = useState<boolean>(false);
  const [openAssetsImport, setOpenAssetsImport] = useState<boolean>(false);
  const { t } = useTranslation();
  const handleFormValidation = (asset: AssetInput, file: File | null) => {
    asset.name = asset.name?.split(/\.(?=[^]+$)/)[0];
    setOpenAssetDialog(false);
    if (file) {
      onAssetCreate(asset, file);
    }
  };

  const handleMultiUploadFormValidation = (
    asset: AssetInput,
    files: File[]
  ) => {
    setOpenAssetsImport(false);
    onAssetsImport?.(asset, files);
  };

  const createAssetActions = [
    {
      title: t('dam.asset_actions.single_title'),
      description: t('dam.asset_actions.single_description'),
      onClick: () => setOpenAssetDialog(true),
    },
    {
      title: t('dam.asset_actions.multiple_title'),
      description: t('dam.asset_actions.multiple_description'),
      onClick: () => setOpenAssetsImport(true),
    },
  ];

  return (
    <Box>
      <SimpleActionsMenu
        actions={createAssetActions}
        button={
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddCircleIcon />}
          >
            {t('dam.asset_actions.sample_action_button')}
          </Button>
        }
      />

      <FormWrapper
        open={openAssetDialog}
        handleClose={() => setOpenAssetDialog(false)}
        title={t('dam.form_wrapper.single_upload_title')}
        maxWidth="md"
      >
        {
          <AssetFormWrapper
            onFormValidation={handleFormValidation}
            isUpdateInDam={true}
          />
        }
      </FormWrapper>

      <FormWrapper
        open={openAssetsImport}
        handleClose={() => setOpenAssetsImport(false)}
        title={t('dam.form_wrapper.multiple_upload_title')}
        subtitle={t('dam.form_wrapper.multiple_upload_subtitle')}
        maxWidth="md"
      >
        {
          <MultiAssetFormWrapper
            onFormValidation={handleMultiUploadFormValidation}
          />
        }
      </FormWrapper>
    </Box>
  );
};

export default AssetActionMenu;
