import produce, { castImmutable, Draft } from 'immer';
import {
  AscDescType,
  AssetStatus,
  AssetType,
} from 'app/schemaInterfaces/globalTypes';
import {
  AssetFiltersActions,
  AssetFiltersActionTypes,
} from 'modules/dam/reducers/filters/actions';
import { AssetFiltersState } from 'modules/dam/reducers/filters/definitions';

export const initialState: AssetFiltersState = {
  filters: {
    text: '',
    divisions: {},
    entityId: '',
    status: [AssetStatus.Active],
  },
  sort: {
    updatedAt: AscDescType.desc,
  },
};

export const filtersReducer = produce(
  (draft: Draft<AssetFiltersState>, action: AssetFiltersActions) => {
    switch (action.type) {
      case AssetFiltersActionTypes.UpdateSearch:
        draft.filters.text = action.payload;
        break;
      case AssetFiltersActionTypes.UpdateAssetTypes:
        draft.filters.assetTypes = action.payload;
        if (!draft.filters.assetTypes?.includes(AssetType.Visual)) {
          draft.filters.visualTypes = undefined;
        }
        break;
      case AssetFiltersActionTypes.UpdateVisualTypes:
        draft.filters.visualTypes = action.payload;
        break;
      case AssetFiltersActionTypes.UpdateSort:
        draft.sort = action.payload;
        break;
      case AssetFiltersActionTypes.UpdateTags:
        draft.filters.tags = action.payload;
        break;
      case AssetFiltersActionTypes.UpdateDivisions:
        draft.filters.divisions[action.payload.key] = action.payload.values;
        break;
      case AssetFiltersActionTypes.UpdateValidityDates:
        draft.filters.validFrom = action.payload.validFrom;
        draft.filters.validTo = action.payload.validTo;
        break;
      case AssetFiltersActionTypes.UpdateStatus:
        draft.filters.status = action.payload;
        break;
      case AssetFiltersActionTypes.UpdatePermanent:
        draft.filters.permanent = action.payload;
        break;
      case AssetFiltersActionTypes.ClearFilters:
        return castImmutable(
          localStorage.getItem('DISABLED_DAM_STATUS_FILTER') === 'true'
            ? initialState
            : {
                ...initialState,
                filters: {
                  ...initialState.filters,
                  status: undefined, // Clear the 'status' property inside the 'filters' object
                },
              }
        );
    }
  }
);
