import { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  UpdateAssetsByCampaignId,
  UpdateAssetsByCampaignIdVariables,
} from 'app/schemaInterfaces/UpdateAssetsByCampaignId';
import {
  GET_ALL_MEDIAS_OF_CAMPAIGN,
  UPDATE_ASSETS_BY_CAMPAIGN_ID,
} from 'app/graphql/queries/media';
import { useHistory, useParams } from 'react-router-dom';
import {
  GetCampaignAssets,
  GetCampaignAssetsVariables,
} from 'app/schemaInterfaces/GetCampaignAssets';
import { GET_DIVISIONS as I_GET_DIVISIONS } from 'app/schemaInterfaces/GET_DIVISIONS';
import { GET_DIVISIONS } from 'app/graphql/queries/divisions';
import { DivisionElement } from 'components/useProductsWithAssets';

export const useMediaQuery = () => {
  const history = useHistory();
  const { id: campaignId } = useParams<{ id: string }>();
  const [campaignAssets, setCampaignAssets] = useState<string[]>([]);
  const [divisions, setDivisions] = useState<DivisionElement[]>();

  const [updateAssetsByCampaignId] = useMutation<
    UpdateAssetsByCampaignId,
    UpdateAssetsByCampaignIdVariables
  >(UPDATE_ASSETS_BY_CAMPAIGN_ID);

  const { data, error, loading, refetch } = useQuery<
    GetCampaignAssets,
    GetCampaignAssetsVariables
  >(GET_ALL_MEDIAS_OF_CAMPAIGN, {
    variables: { campaignId },
    skip: !campaignId,
  });

  const { data: clientDivisions } = useQuery<I_GET_DIVISIONS>(GET_DIVISIONS);

  const updateCampaignAssets = useCallback(
    async (assets: string[], campaignId: string): Promise<void> => {
      await updateAssetsByCampaignId({
        variables: { campaignId, assets },
        refetchQueries: [
          {
            query: GET_ALL_MEDIAS_OF_CAMPAIGN,
            variables: { campaignId },
          },
        ],
        awaitRefetchQueries: true,
      });
    },
    [updateAssetsByCampaignId]
  );

  const handleSelectedAssets = useCallback(
    (assetId: string) => {
      const assets = [...campaignAssets];
      assets.includes(assetId)
        ? assets.splice(assets.indexOf(assetId), 1)
        : assets.push(assetId);
      setCampaignAssets(assets);
    },
    [campaignAssets]
  );

  const addMultipleCampaignAssets = useCallback(
    async (id: string): Promise<void> => {
      await updateCampaignAssets(campaignAssets, id);
      history.goBack();
    },
    [campaignAssets, updateCampaignAssets, history]
  );

  const addCampaignAsset = useCallback(
    async (id: string, assetId: string): Promise<void> => {
      const assets = [...campaignAssets, assetId];
      await updateCampaignAssets(assets, id);
    },
    [campaignAssets, updateCampaignAssets]
  );

  const handleCloseCampaignForm = useCallback(() => {
    setCampaignAssets([]);
    history.goBack();
  }, [history]);

  useEffect(() => {
    if (data) {
      const assetIds = data.assetsCampaigns.map(({ id }) => id);
      setCampaignAssets(assetIds);
    }
  }, [data, setCampaignAssets]);

  return {
    data: data?.assetsCampaigns,
    error,
    loading,
    refetch,
    divisions,
    setDivisions,
    setCampaignAssets,
    handleCloseCampaignForm,
    handleSelectedAssets,
    addMultipleCampaignAssets,
    campaignAssets,
    clientDivisions,
    addCampaignAsset,
  };
};
