import React from 'react';
import { Global, ThemeProvider } from '@emotion/react/macro';
import { ThemeProvider as MaterialUiThemeProvider } from '@material-ui/styles';
import { matchPath, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { StoreProvider } from 'easy-peasy';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ProtectedRoute from 'components/Navigation/ProtectedRoute/ProtectedRoute';
import { useCurrentUser } from 'app/auth/UserContext';
import ROUTES, { AppRoute } from 'app/routes/routes';
import AppMenu from 'components/AppMenu';
import { menuItems, menuIcons } from 'app/utils/common';
import { useRouter } from 'app/routes/useRouter';
import { theme } from 'styles/theme';
import useIntercom, { IntercomSettings } from '@reclaim-ai/react-intercom-hook';
import globalStyles from 'styles/global.style';
import store from 'state/store';
import useCurrentEntity from 'app/current-entity-context/useCurrentEntity';
import useBasketDocumentsQuery from 'queries/useBasketDocumentsQuery';
import useAppContext from './app-context/useAppContext';
import { SalesRoute } from 'modules/sales/salesHelpers';
import usePermissions from './auth/usePermissions';
import useClientOffers from './auth/useClientOffers';

const App = (): JSX.Element => {
  const { history } = useRouter();

  const { i18n } = useTranslation();

  const isStudio = matchPath(history.location.pathname, {
    path: AppRoute.Studio,
    exact: true,
  });
  const isCommitment = matchPath(history.location.pathname, {
    path: SalesRoute.CommitmentsSteps,
    exact: true,
  });
  const { hasPermission } = usePermissions();
  const user = useCurrentUser();
  const { currentEntity } = useCurrentEntity();
  const { offerKeys } = useClientOffers();

  const {
    client: { config },
  } = useAppContext();

  const filteredMenuItems = menuItems(hasPermission).filter((item) =>
    offerKeys?.includes(item.moduleKey as string)
  );

  const hasEntity = !!currentEntity?.id;
  const { data } = useBasketDocumentsQuery({
    skip: !hasEntity,
    filter: {
      entityId: currentEntity?.id as string,
    },
  });
  const basketCount = data?.basket.basketDocuments.length ?? 0;

  const isAddAssetToCampaign = matchPath(history.location.pathname, {
    path: AppRoute.ImportDamInCampaign,
    exact: true,
  });

  // set default language for local dates formatting in "date-fns"
  window.__localeId__ = i18n.language;

  // init intercom tracker
  useIntercom({
    app_id: process.env.REACT_APP_INTERCOM_APP_ID as string,
    user_id: user.id,
    name: `${user.givenName} ${user.familyName.toUpperCase()}`,
    email: user.email,
    company: {
      company_id: currentEntity?.id,
      name: currentEntity?.name,
    },
  } as IntercomSettings);

  return (
    <StoreProvider store={store}>
      <ThemeProvider theme={theme}>
        <Global styles={globalStyles} />
        <MuiPickersUtilsProvider
          libInstance={moment}
          utils={MomentUtils}
          locale={i18n.language}
        >
          <MaterialUiThemeProvider theme={theme}>
            {!isStudio && !isCommitment && !isAddAssetToCampaign && (
              <AppMenu
                menuItems={filteredMenuItems}
                menuIcons={menuIcons(basketCount)}
                storeIcon={
                  <img
                    src={config?.clientAssets?.logo ?? ''}
                    alt="logo"
                    height={32}
                  />
                }
              />
            )}
            <Switch>
              {Object.values(ROUTES).map(({ path, permission, ...rest }) => (
                <ProtectedRoute
                  permission={permission}
                  key={path}
                  path={path}
                  {...rest}
                />
              ))}
            </Switch>
          </MaterialUiThemeProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </StoreProvider>
  );
};

export default App;
