import {
  CircularProgress,
  FormHelperText,
  FormLabel,
  MenuItem,
  Select,
  Tooltip,
} from '@material-ui/core';
import { GetDocument_document } from 'app/schemaInterfaces/GetDocument';
import {
  GetSupports_supports as Support,
  GetSupports_supports_supportAttributes,
} from 'app/schemaInterfaces/GetSupports';
import { AttributeKey } from 'app/schemaInterfaces/globalTypes';
import AlertBox from 'components/AlertBox/AlertBox';
import { useFormikContext } from 'formik';
import { DocumentFormValues } from 'modules/pro/documents/form/model/definitions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Packaging from '../Packaging/Packaging';
import {
  Conditionnement,
  NON_VALUE,
} from './DocumentProductSubForm.definition';
import { isItemPackaging } from './DocumentProductSubform.helper';
import { useProductAttributes } from './DocumentProductSubform.hooks';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
  tooltipContainerStyle,
  tooltipIconStyle,
} from './DocumentProductSubform.style';

interface DocumentProductSubformProps {
  support: Support;
  currentDocument: GetDocument_document | undefined;
  attributes: GetSupports_supports_supportAttributes[];
  isDisabledForm?: boolean;
}

const DocumentProductSubForm = ({
  support,
  currentDocument,
  attributes,
  isDisabledForm,
}: DocumentProductSubformProps) => {
  const { t } = useTranslation();
  const { errors, touched } = useFormikContext<DocumentFormValues>();

  const {
    attributeFormOptions,
    loading,
    handleAttributeChange,
    isOsConfigError,
  } = useProductAttributes({
    supportId: support.id,
    productId: currentDocument?.productId,
    attributes,
  });

  const checkIfDisabled = (
    attribute: GetSupports_supports_supportAttributes,
    value: any
  ): boolean =>
    Boolean(
      attribute.key === 'paginationTotale' &&
        currentDocument?.productAttributes?.paginationTotale &&
        parseInt(value) <
          parseInt(currentDocument?.productAttributes?.paginationTotale)
    );

  const checkIfDisabledForm = (
    attribute: GetSupports_supports_supportAttributes
  ) =>
    isDisabledForm &&
    Boolean(
      (attribute.key === 'largeurFormatFiniCm' &&
        currentDocument?.productAttributes?.largeurFormatFiniCm) ||
        (attribute.key === 'longueurFormatFiniCm' &&
          currentDocument?.productAttributes?.largeurFormatFiniCm) ||
        (attribute.key === 'paginationTotale' &&
          currentDocument?.productAttributes?.paginationTotale)
    );

  if (loading) return <CircularProgress />;

  return (
    <div>
      {isOsConfigError && (
        <AlertBox
          variant="error"
          header={<div>{t('global.document_form.attributes.error')}</div>}
        />
      )}
      {attributeFormOptions?.map(({ attribute, values }, key) => {
        return (
          <div key={key}>
            <div className="field" css={tooltipContainerStyle}>
              {/*TODO: Translate attribute names using attribute key */}
              <FormLabel required={true}>{attribute.name}</FormLabel>
              <Select
                variant="outlined"
                fullWidth
                className="text-field"
                disabled={checkIfDisabledForm(attribute) || values.length === 1}
                value={
                  values.length === 1
                    ? isItemPackaging(values[0])
                      ? values[0].value
                      : values[0]
                    : ''
                }
                renderValue={(option) => {
                  return attribute.key === AttributeKey.conditionnement
                    ? (values.find(
                        (value) =>
                          isItemPackaging(value) && value?.value === option
                      ) as Conditionnement)?.value
                    : (option as string);
                }}
                onChange={(e) => {
                  handleAttributeChange(attribute, e.target.value as string);
                }}
              >
                {values.map((value, key) => {
                  return isItemPackaging(value) ? (
                    <MenuItem key={key} value={value.value ?? NON_VALUE}>
                      <Packaging packaging={value} />
                    </MenuItem>
                  ) : (
                    <MenuItem
                      key={value as any}
                      value={(value as string) ?? NON_VALUE}
                      disabled={checkIfDisabled(attribute, value)}
                    >
                      {value ??
                        t('global.document_form.product_attributes.none')}
                    </MenuItem>
                  );
                })}
              </Select>
              {values.length === 1 && isItemPackaging(values[0]) && (
                <Tooltip title={<Packaging packaging={values[0]} />}>
                  <InfoOutlinedIcon css={tooltipIconStyle} />
                </Tooltip>
              )}

              {touched.productAttributes &&
                errors.productAttributes?.[attribute.key] && (
                  <FormHelperText error>
                    {errors.productAttributes[attribute.key]}
                  </FormHelperText>
                )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DocumentProductSubForm;
