import { Box, Typography } from '@material-ui/core';
import React from 'react';
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from '@material-ui/pickers';
import moment from 'moment';
import { PersonalizationItem } from 'modules/workflow/oneStudioContext';

interface TypographyDateDialogContentProps {
  startDateLabel: string;
  endDateLabel: string;
  item: PersonalizationItem;
  setItemDates: React.Dispatch<React.SetStateAction<PersonalizationItem>>;
}

const TypographyDateDialogContent = ({
  startDateLabel,
  endDateLabel,
  item,
  setItemDates,
}: TypographyDateDialogContentProps): JSX.Element => {
  const keyboardDatePickerCommonProps: Partial<KeyboardDatePickerProps> = {
    inputVariant: 'outlined',
    disableToolbar: true,
    variant: 'inline',
    format: 'DD/MM/YYYY',
    margin: 'normal',
    id: 'date-picker-inline',
    KeyboardButtonProps: { 'aria-label': 'change date' },
  };

  return (
    <Box display="flex" justifyContent="space-around" my={2}>
      <Box pr={1}>
        <Typography variant="h5">{startDateLabel}</Typography>
        <KeyboardDatePicker
          autoOk
          minDate={moment()}
          value={item.text?.dateFrom ?? null}
          onChange={(date) =>
            setItemDates({ ...item, text: { ...item.text, dateFrom: date } })
          }
          {...keyboardDatePickerCommonProps}
        />
      </Box>

      <Box pl={1}>
        <Typography variant="h5">{endDateLabel}</Typography>
        <KeyboardDatePicker
          autoOk
          minDate={item.text?.dateFrom ?? moment()}
          value={item.text?.dateTo ?? null}
          onChange={(date) =>
            setItemDates({ ...item, text: { ...item.text, dateTo: date } })
          }
          {...keyboardDatePickerCommonProps}
        />
      </Box>
    </Box>
  );
};

export default TypographyDateDialogContent;
