import React from 'react';
import MyList from 'components/MyList/MyList';
import { CheckboxOption } from 'components/UncontrolledCheckboxMenu';
import { sortByAlphabeticalOrder } from 'app/utils/common';

interface SectorsViewProps {
  options: CheckboxOption[];
  onClick?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    value: CheckboxOption['value']
  ) => void;
  isPim?: boolean;
}

const SectorsView = ({
  options,
  onClick,
  isPim,
}: SectorsViewProps): JSX.Element => {
  const optionsSortByLabel =
    options && sortByAlphabeticalOrder<CheckboxOption>(options, 'label');
  return (
    <MyList
      options={optionsSortByLabel as CheckboxOption[]}
      onClick={onClick}
      isPim={isPim}
    />
  );
};

export default SectorsView;
