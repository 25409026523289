import colors from 'styles/colors.module.scss';
import { ConfirmationDialog } from 'components/ActionsButtons/ActionButtons';
import { TFunction } from 'i18next';
import React from 'react';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { AddressBlockStatus } from 'app/schemaInterfaces/globalTypes';

export const validationTag = (t: TFunction, status: AddressBlockStatus) => {
  return status === AddressBlockStatus.Validated
    ? {
        title: t(
          'one_manager.store_information.address_block_page.validation_status.validated'
        ),
        color: colors.success,
      }
    : status === AddressBlockStatus.Declined
    ? {
        title: t(
          'one_manager.store_information.address_block_page.validation_status.declined'
        ),
        color: colors.tagTextColor0,
      }
    : {
        title: t(
          'one_manager.store_information.address_block_page.validation_status.waiting_for_approve'
        ),
        color: colors.yellow,
      };
};

export interface ActionButton<T> {
  icon: (isActiveItemClickItem: boolean, item: T) => React.ReactNode;
  onClick?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: T
  ) => void;
  confirmationDialog?: ConfirmationDialog;
}

export function actionEditButtons<T>(
  deleteItem: ActionButton<T>['onClick'],
  editMode: ActionButton<T>['onClick'],
  t: TFunction
): ActionButton<T>[] {
  const actionButton: ActionButton<T>[] = [
    {
      icon: () => <CreateOutlinedIcon htmlColor={colors.tooltip} />,
      onClick: editMode,
    },
    {
      icon: () => <DeleteOutlineIcon htmlColor={colors.tooltip} />,
      onClick: deleteItem,
    },
  ];

  return actionButton;
}

export function actionFormButtons<T>(
  submitForm: ActionButton<T>['onClick'],
  clearChanges: ActionButton<T>['onClick'],
  t: TFunction
): ActionButton<T>[] {
  const actionButton: ActionButton<T>[] = [
    {
      icon: () => <CheckIcon htmlColor={colors.tooltip} />,
      onClick: submitForm,
    },
    {
      icon: () => <ClearIcon htmlColor={colors.tooltip} />,
      onClick: clearChanges,
    },
  ];

  return actionButton;
}

export const getFileNameWithNoExtensions = (name: string): string =>
  name.split(/\.(?=[^]+$)/)[0];
