import { css } from '@emotion/react/macro';
import colors from 'styles/colors.module.scss';

export const notificationStyle = css`
  .MuiPopover-paper {
    box-shadow: none;
    border: 2px solid ${colors.buttonGroupOutlined};
    box-sizing: border-box;
    border-radius: 4px 4px 4px 4px;
  }

  .header {
    background: ${colors.background};
    border-bottom: 2px solid ${colors.buttonGroupOutlined};
    box-sizing: border-box;
    border-radius: 4px 4px 0px 0px;
    height: 54px;
    display: flex;
    align-items: center;

    p {
      font-family: ScandiaWebMedium;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: -0.03em;
      padding-left: 12px;
    }
  }
  .notification-message-box {
    padding: 15px;
  }
  .date-header {
    font-family: ScandiaWebMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.03em;
    color: ${colors.disabled};
    padding-bottom: 10px;
  }
  .empty {
    font-family: ScandiaWebMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    display: flex;
    letter-spacing: -0.03em;
  }

  .notification {
    cursor: pointer;
    &:hover {
      background-color: ${colors.menuHover};
    }
    .message-box {
      display: flex;
      align-items: baseline;
      width: 404px;

      .red-dot {
        width: 2%;
        height: 8px;
        border-radius: 10px;
        background-color: ${colors.tagTextColor0};
      }
      .message {
        font-family: ScandiaWebMedium;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 26px;
        display: flex;
        letter-spacing: -0.03em;
        padding-left: 5px;
        width: 98%;
      }
    }
    .timeBox {
      display: flex;
      padding-left: 10px;
      .icon {
        color: ${colors.disabled};
        padding-top: 3px;
      }

      .time {
        padding-bottom: 10px;
        padding-left: 5px;
        font-family: ScandiaWebRegular;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        color: ${colors.disabled};
      }
    }
  }
`;

export const iconNotificationStyle = css`
  .MuiBadge-dot {
    background-color: ${colors.tagTextColor0};
    right: 8px;
    top: 8px;
    position: absolute;
  }
`;
