import React from 'react';
import { GetCurrentClient_client as Client } from '../schemaInterfaces/GetCurrentClient';

export interface AppContextData {
  client: Client;
}

const AppContext = React.createContext<AppContextData | undefined>(undefined);

export default AppContext;
