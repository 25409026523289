import { gql } from '@apollo/client';
import { CLIENT_CONFIG_FRAGMENT } from './client-config.fragment';

export const GET_CURRENT_CLIENT = gql`
  ${CLIENT_CONFIG_FRAGMENT}
  query GetCurrentClient {
    client {
      id
      name
      type
      structures {
        level
        title
        type
        isBillable
      }
      config {
        ...ClientConfig
      }
      offerIds
    }
  }
`;
