import { useMutation } from '@apollo/client';
import { BASKET_DOCUMENTS } from 'app/graphql/queries/basket';
import { CREATE_ORDER, GET_ORDERS } from 'app/graphql/queries/orders';
import {
  CreateOrder,
  CreateOrderVariables,
} from 'app/schemaInterfaces/CreateOrder';
import {
  BasketFilterInput,
  PaginationInput,
} from 'app/schemaInterfaces/globalTypes';

const useCreateOrderMutation = ({
  basketFilter = {},
  pagination,
}: {
  basketFilter?: BasketFilterInput;
  pagination?: PaginationInput;
}) => {
  const [
    createOrder,
    {
      data: createOrderData,
      error: createOrderError,
      loading: createOrderLoading,
    },
  ] = useMutation<CreateOrder, CreateOrderVariables>(CREATE_ORDER, {
    refetchQueries: [
      {
        query: BASKET_DOCUMENTS,
        variables: {
          filter: basketFilter,
          pagination,
        },
      },
      { query: GET_ORDERS },
    ],
  });

  return {
    createOrder,
    createOrderData,
    createOrderError,
    createOrderLoading,
  };
};

export default useCreateOrderMutation;
