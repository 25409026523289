import { Box, createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getBase64File } from 'app/utils/FileUpload/FileUpload';
import EmptyTableFileUpload from 'components/EmptyTableFileUpload';
import CircularProgressLoader from 'components/Loader/CircularProgressLoader/CircularProgressLoader';
import { ErrorMessage, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import colors from 'styles/colors.module.scss';

import AssetPreview from 'modules/dam/form/AssetPreview';

import { ACCEPTED_FORMATS } from 'modules/dam/form/helpers';

const useStyles = makeStyles(() =>
  createStyles({
    uploadArea: {
      backgroundColor: colors.documentsHeaderBackground,
      border: 'hidden',
      textAlign: 'center',
    },
    dropzone: {
      outline: 'none',
    },
  })
);

const PreviewUploadMultiple = (): JSX.Element => {
  const classes = useStyles();

  const [files, setFiles] = useState<Map<string, File>>(
    new Map<string, File>()
  );
  const [base64Files, setBase64Files] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue(
      'files',
      Array.from(files).map(([, file]) => file)
    );
  }, [files, setFieldValue]);

  const handleFileChange = async (newFiles: File[]) => {
    setIsLoading(true);
    const newFileMap = new Map(files);
    const base64FilesPromises = newFiles.map(
      async (file) => (await getBase64File(file)) as string
    );
    newFiles.forEach((file) => {
      newFileMap.set(file.name, file);
    });
    setFiles(newFileMap);
    setBase64Files(await Promise.all(base64FilesPromises));
    setIsLoading(false);
  };

  const handleDelete = (name: string) => {
    files.delete(name);
    setFiles(new Map(files));
  };

  const { getRootProps } = useDropzone({
    onDrop: handleFileChange,
  });

  return (
    <Grid item xs={6} {...getRootProps({ className: classes.dropzone })}>
      <Box p={2} minHeight={1200} className={classes.uploadArea}>
        {isLoading ? (
          <CircularProgressLoader />
        ) : (
          <>
            <Grid container spacing={1} alignContent="center">
              {Array.from(files).map(([, file], index) => (
                <Grid item key={file.name} xs={3}>
                  <AssetPreview
                    file={file}
                    base64File={base64Files[index]}
                    handleDelete={handleDelete}
                  />
                </Grid>
              ))}
            </Grid>
            <Box pt={1} p={9}>
              <EmptyTableFileUpload
                img={
                  !files.size
                    ? `/${process.env.REACT_APP_ASSETS}/emptySourceFilesResized.svg`
                    : undefined
                }
                title=""
                subtitle={t('dam.preview_upload.dropzone_title')}
                buttonLabel={t('dam.preview_upload.dropzone_button')}
                onFileChange={handleFileChange}
                acceptedFiles={ACCEPTED_FORMATS.join(',')}
              />
              <Box p={2} style={{ color: 'red' }}>
                {' '}
                <ErrorMessage name="files" />
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Grid>
  );
};

export default PreviewUploadMultiple;
