import { useMutation } from '@apollo/client';
import { UPDATE_BASKET_DOCUMENT } from 'app/graphql/queries/basket';
import {
  UpdateBasketDocument,
  UpdateBasketDocumentVariables,
} from 'app/schemaInterfaces/UpdateBasketDocument';

const useUpdateBasketDocumentMutation = () => {
  const [
    updateBasketDocument,
    {
      data: updateBasketDocumentData,
      error: updateBasketDocumentError,
      loading: updateBasketDocumentLoading,
    },
  ] = useMutation<UpdateBasketDocument, UpdateBasketDocumentVariables>(
    UPDATE_BASKET_DOCUMENT
  );

  return {
    updateBasketDocumentData,
    updateBasketDocument,
    updateBasketDocumentError,
    updateBasketDocumentLoading,
  };
};

export default useUpdateBasketDocumentMutation;
