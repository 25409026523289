import { makeStyles, Tab, Tabs } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import colors from 'styles/colors.module.scss';
import { ProductsPanelTab } from 'modules/workflow/containers/Leftsidebar/ProductsPanel/ProductsPanel';

interface ProductsPanelTabsProps {
  handleTabs: (tab: ProductsPanelTab) => void;
}

const useStyles = makeStyles({
  tabsCentered: {
    alignItems: 'center',
  },
  tabsIndicator: {
    backgroundColor: colors.white,
  },
  tabRoot: {
    whiteSpace: 'nowrap',
    '&:hover': {
      color: colors.white,
      opacity: 1,
    },
    '&.Mui-selected': {
      backgroundColor: colors.documentsTab,
      color: colors.white,
    },
    '&:focus': {
      color: colors.white,
    },
    '@media (min-width: 600px)': {
      minWidth: 'unset',
    },
  },
});

const ProductsPanelTabs = ({
  handleTabs,
}: ProductsPanelTabsProps): JSX.Element => {
  const { t } = useTranslation();
  const [tab, setTab] = useState<ProductsPanelTab>(ProductsPanelTab.CAMPAIGN);
  const classes = useStyles();
  const handleTab = (event: React.ChangeEvent<{}>, value: ProductsPanelTab) => {
    setTab(value);
    handleTabs(value);
  };
  return (
    <Tabs
      classes={{
        centered: classes.tabsCentered,
        indicator: classes.tabsIndicator,
      }}
      orientation="horizontal"
      variant="fullWidth"
      value={tab}
      onChange={(event, value: ProductsPanelTab) => handleTab(event, value)}
    >
      <Tab
        classes={{ root: classes.tabRoot }}
        label={t('workflow.left_side_bar.products_panel.campaign')}
        value={ProductsPanelTab.CAMPAIGN}
      />
      <Tab
        classes={{ root: classes.tabRoot }}
        label={t('workflow.left_side_bar.products_panel.products_base')}
        value={ProductsPanelTab.PIM}
      />
    </Tabs>
  );
};

export default ProductsPanelTabs;
