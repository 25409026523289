import { useQuery } from '@apollo/client';
import { GET_DOCUMENT } from 'app/graphql/queries/documents';
import { useRouter } from 'app/routes/useRouter';
import {
  GetDocument,
  GetDocumentVariables,
} from 'app/schemaInterfaces/GetDocument';
import { UrlParams } from 'modules/workflow/containers/Toolbar/FirstToolbar/FirstToolbar';
import { useStudioStateContext } from 'modules/workflow/oneStudioContext';

export const useGetInformationDocument = () => {
  const globalState = useStudioStateContext();
  const { documentId } = useRouter<UrlParams>().query;
  const { error, loading, data: documentData } = useQuery<
    GetDocument,
    GetDocumentVariables
  >(GET_DOCUMENT, {
    variables: {
      documentId,
    },
  });

  return {
    error,
    loading,
    documentData,
    globalState,
  };
};
