import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const userMenuToolbarStyle = css`
  .menu-item {
    padding: 20px;
    background: #fff;
    cursor: pointer;
  }
  .menu-item:hover {
    background: ${colors.menuHover};
  }
  .menu-item--text {
    font-family: 'ScandiaWebMedium';
    font-weight: 500;
    font-size: 14px;
    color: ${colors.body};
  }
  .logout {
    color: ${colors.fushia};
  }

  .MuiAvatar-root .entity-logo {
    width: 80px;
    height: 80px;
    background-color: ${colors.yellow};
    border: 1px solid ${colors.white};
  }

  .MuiMenu-paper {
    background-color: ${colors.documentsHeaderBackground};
  }
  .MuiMenu-list {
    padding: 0;
  }
  .avatar .MuiAvatar-root {
    width: 80px;
    height: 80px;
    background-color: ${colors.yellow};
    border: 1px solid ${colors.white};
  }
`;
