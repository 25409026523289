import React, { useEffect, useState } from 'react';
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';
import { Button, Popover, Link } from '@material-ui/core';
import colors from 'styles/colors.module.scss';
import { useTranslation } from 'react-i18next';
import { UncontrolledMultipleValueSelectV2 } from 'components/UncontrolledMultipleValueSelectV2';
import { css } from '@emotion/react/macro';
import { getSupportCategory } from 'modules/pro/documents/form/model/mappers';
import {
  BigShopConfigCategory,
  DocumentFilterInput,
} from 'app/schemaInterfaces/globalTypes';

interface DocumentsFilterProps {
  mediumTypesOptions: { category: BigShopConfigCategory; mediumType: string }[];
  categoryOptions: BigShopConfigCategory[];
  filter: DocumentFilterInput;
  setFilter: React.Dispatch<React.SetStateAction<DocumentFilterInput>>;
}

const style = css`
  .popover {
    width: 421px;
    padding: 26px 43px 30px 30px;
    h5 {
      font-weight: 500;
    }
    .filter {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      .input {
        width: 200px;
      }
    }
    .link {
      margin-top: 30px;
    }
  }
`;

export const DocumentsFilter = ({
  mediumTypesOptions,
  categoryOptions,
  filter,
  setFilter,
}: DocumentsFilterProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const { t } = useTranslation();

  const [mediumTypesOptionsFiltered, setMediumTypesOptionsFiltered] = useState<
    string[]
  >(mediumTypesOptions.map((option) => option.mediumType));

  useEffect(() => {
    if (filter.categories?.length) {
      setMediumTypesOptionsFiltered(
        mediumTypesOptions
          .filter((option) => filter.categories?.includes(option.category))
          .map((option) => option.mediumType)
      );
    } else {
      setMediumTypesOptionsFiltered(
        mediumTypesOptions.map((option) => option.mediumType)
      );
    }
  }, [filter.categories, mediumTypesOptions]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const resetFilter = () => {
    setFilter({ ...filter, categories: null, mediumTypes: null });
  };

  const handleChangeMediumType = (
    event: React.ChangeEvent<{ value: string[] }>
  ) => {
    setFilter({
      ...filter,
      mediumTypes: event.target.value.length ? event.target.value : null,
    });
  };

  const handleChangeCategory = (
    event: React.ChangeEvent<{ value: string[] }>
  ) => {
    setFilter({
      ...filter,
      categories: event.target.value.length
        ? (event.target.value as BigShopConfigCategory[])
        : null,
    });
  };

  const isOpen = Boolean(anchorEl);
  const popoverId = isOpen ? 'simple-popover' : undefined;

  return (
    <div>
      <Button
        style={
          isOpen
            ? { color: colors.primary, backgroundColor: colors.menuHover }
            : {}
        }
        startIcon={<FilterListOutlinedIcon />}
        onClick={handleClick}
      >
        {t('global.table_toolbar.filter')}
      </Button>
      <Popover
        css={style}
        id={popoverId}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="popover">
          <div className="filter">
            <div>
              <h5>{t('global.documents_list.filters_popover.category')}</h5>
            </div>
            <div className="input">
              <UncontrolledMultipleValueSelectV2
                value={filter.categories ?? []}
                options={categoryOptions
                  ?.map((value) => ({
                    label: getSupportCategory(value, t),
                    value,
                  }))
                  .sort((first, second) =>
                    first.label.localeCompare(second.label)
                  )}
                isFullWidth
                handleChange={handleChangeCategory}
              />
            </div>
          </div>
          <div className="filter">
            <div>
              <h5>{t('global.documents_list.filters_popover.support')}</h5>
            </div>
            <div className="input">
              <UncontrolledMultipleValueSelectV2
                value={filter.mediumTypes ?? []}
                options={mediumTypesOptionsFiltered
                  ?.map((value) => ({
                    label: value,
                    value,
                  }))
                  .sort((first, second) =>
                    first.label.localeCompare(second.label)
                  )}
                isFullWidth
                handleChange={handleChangeMediumType}
              />
            </div>
          </div>

          <div className="link">
            <Link
              component="button"
              variant="body2"
              color="primary"
              underline="always"
              onClick={resetFilter}
            >
              {t('global.documents_list.filters_popover.reset_filters')}
            </Link>
          </div>
        </div>
      </Popover>
    </div>
  );
};
