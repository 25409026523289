import { useMutation } from '@apollo/client';
import { CREATE_NOTIFICATION_FOR_MULTIPLE_ENTITIES } from 'app/graphql/queries/notifications';
import {
  CreateNotificationForMultipleEntities,
  CreateNotificationForMultipleEntitiesVariables,
} from 'app/schemaInterfaces/CreateNotificationForMultipleEntities';

const useCreateNotificationForMultipleEntities = () => {
  const [
    createNotificationForMultipleEntities,
    {
      data: createNotificationForMultipleEntitiesData,
      error: createNotificationForMultipleEntitiesError,
      loading: createNotificationForMultipleEntitiesLoading,
    },
  ] = useMutation<
    CreateNotificationForMultipleEntities,
    CreateNotificationForMultipleEntitiesVariables
  >(CREATE_NOTIFICATION_FOR_MULTIPLE_ENTITIES);

  return {
    createNotificationForMultipleEntities,
    createNotificationForMultipleEntitiesData,
    createNotificationForMultipleEntitiesError,
    createNotificationForMultipleEntitiesLoading,
  };
};

export default useCreateNotificationForMultipleEntities;
