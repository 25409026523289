import {
  GetCurrentClient_client_config,
  GetCurrentClient_client_config_pim_requiredFields,
} from 'app/schemaInterfaces/GetCurrentClient';
import { PimConfigFields } from './baseProducts/PimFormConfigTypes';
import { FileStore } from './baseProducts/PimFormFieldsConfig';
import { TFunction } from 'i18next';
import { VisuelFieldName } from './baseProducts/ProductsHelper';
import { GetProductsWithAssets } from 'components/useProductsWithAssets';
import { StudioStates } from 'modules/workflow/oneStudioContext';

export const productVisual = (filesToSend: FileStore[], productId?: string) => {
  const firstRegroupingVisual = filesToSend.find(
    (file: FileStore) => file.fieldName === VisuelFieldName.mainVisuel
  );
  return {
    fieldName: `${firstRegroupingVisual?.fieldName}-${productId}`,
    assetId: firstRegroupingVisual?.assetId,
    fileName: firstRegroupingVisual?.fileName,
  };
};

export const firstProductVisualRegrouping = (
  filesToSend: FileStore[],
  productId?: string
) => {
  const isfirstRegroupingVisual = filesToSend?.find(
    (file: FileStore) =>
      file.fieldName === `${VisuelFieldName.mainVisuel}-${productId}`
  );
  if (!isfirstRegroupingVisual) {
    const firstRegroupingVisual = filesToSend?.find(
      (file: FileStore) => file.fieldName === VisuelFieldName.mainVisuel
    );

    return {
      fieldName: `${firstRegroupingVisual?.fieldName}-${productId}`,
      assetId: firstRegroupingVisual?.assetId,
      fileName: firstRegroupingVisual?.fileName,
    };
  }
};

export const normalizedFields = (
  productId: string,
  regroupingFields:
    | GetCurrentClient_client_config_pim_requiredFields[]
    | undefined
) => {
  return regroupingFields?.map((newField) => {
    if (productId) {
      return {
        ...newField,
        key: `${newField.key}-${productId}`,
      };
    } else {
      if (newField.key === VisuelFieldName.mainVisuel) {
        return {
          ...newField,
          key: `${newField.key}-0`,
        };
      } else
        return {
          ...newField,
          key: newField.key,
        };
    }
  });
};

export const isFieldPimConfig = (
  field: GetCurrentClient_client_config_pim_requiredFields | PimConfigFields
): field is PimConfigFields => Boolean((field as PimConfigFields) !== null);

export const subTabLabel = (index: number, t: TFunction) =>
  index === 0
    ? t('pim.regrouping.main_product')
    : t('pim.regrouping.additional_product', {
        count: index,
      });

export const deletedVisual = (
  formFilesManager: FileStore[],
  productId: string
) =>
  formFilesManager.find(
    (item: FileStore) =>
      item.fieldName === `${VisuelFieldName.mainVisuel}-${productId}`
  )?.assetId;

export const filteredVisuals = (
  formFilesManager: FileStore[],
  productId: string
) =>
  formFilesManager.filter(
    (file) => file.assetId !== deletedVisual(formFilesManager, productId)
  );

export const productRegrouping = (
  productWithUrl: GetProductsWithAssets,
  clientConfig: GetCurrentClient_client_config | null,
  globalState: StudioStates
) => {
  const regroupingConfig = clientConfig?.workflow.supportConfig?.find(
    ({ supportId }) => supportId === globalState.document?.supportId
  )?.regroupingConfig;
  return regroupingConfig && productWithUrl.regrouping.length > 0
    ? [...productWithUrl.regrouping]
        .filter(({ isVisible }, index) => isVisible)
        .reverse()
        .map((regrouping) => ({
          productId: regrouping.productId,
          id: regrouping.asset?.id ?? '',
          name: regrouping.asset?.name ?? '',
          url: regrouping.asset?.url ?? '',
          thumbnailUrl: regrouping.asset?.thumbnailUrl ?? '',
          metadata: regrouping.asset?.metadata,
          assetType: regrouping.asset?.assetType ?? undefined,
          size: {
            width: regroupingConfig.size?.width ?? 0,
            height: regroupingConfig.size?.height ?? 0,
          },
          position: {
            x: regroupingConfig.position?.x ?? 0,
            y: regroupingConfig.position?.y ?? 0,
          },
          details: regrouping.details,
          isVisible: regrouping.isVisible,
          isMain: regrouping.isMain,
          visualType: regrouping.asset?.visualType ?? undefined,
        }))
    : undefined;
};
