import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import TableToolbar from 'components/TableToolbar';
import {
  createMediaActions,
  campaignAssetsTableColumn,
} from 'modules/pro/medias/MediaHelpers';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AppTable from 'components/AppTable/AppTable';
import { GetCampaignAssets_assetsCampaigns } from 'app/schemaInterfaces/GetCampaignAssets';
import { useMediaQuery } from 'modules/pro/medias/utils/useMediaQuery';
import { useDivisionSelect } from 'components/divisions/useDivisionSelect';
import FormWrapper from 'components/FormWrapper/FormWrapper';
import { AssetDialog } from 'modules/dam/AssetDialog';
import { AssetInput } from 'app/schemaInterfaces/globalTypes';
import { GetCampaignAssets_assetsCampaigns as Asset } from 'app/schemaInterfaces/GetCampaignAssets';
import { useAssetMutate } from 'modules/dam/hooks/useAssetMutate';
import AssetFormWrapper from 'modules/dam/form/AssetFormWrapper';
import CircularProgressLoader from 'components/Loader/CircularProgressLoader/CircularProgressLoader';
import UploadProgressSnackbar from 'components/UploadProgressSnackbar';

export const MediaContainer = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const {
    data: dataAssets,
    clientDivisions,
    divisions,
    loading,
    error,
    addCampaignAsset,
  } = useMediaQuery();
  const getDivisionValue = (divisionKey: string): string | null | undefined =>
    divisions?.find(({ division }) => division === divisionKey)?.value;
  const { selectModels } = useDivisionSelect(clientDivisions, getDivisionValue);
  const [openAssetDialog, setOpenAssetDialog] = useState<boolean>(false);
  const [assetRow, setAssetRow] = useState<Asset | undefined>(undefined);
  const {
    updateAsset,
    createAsset,
    uploadProgress,
    clearProgress,
  } = useAssetMutate({ reportProgress: true });
  const getRowId = React.useCallback(
    (row: GetCampaignAssets_assetsCampaigns) => row.id,
    []
  );

  const handleFormValidation = async (asset: AssetInput, file: File | null) => {
    setOpenAssetDialog(false);
    asset.name = asset.name?.split(/\.(?=[^]+$)/)[0];
    if (assetRow) {
      await updateAsset(assetRow.id, asset, file);
    } else {
      if (file) {
        const newAsset = await createAsset(asset, file);
        await addCampaignAsset(id, newAsset.id);
      }
    }
    setAssetRow(undefined);
  };

  const handleOpenAssetDialog = (row: Asset) => {
    setAssetRow(row);
    setOpenAssetDialog(true);
  };

  return (
    <Box pt={2}>
      <Box pb={2}>
        <TableToolbar
          hasHiddenFieldFilter={false}
          actions={createMediaActions(id, t, setOpenAssetDialog)}
          placeholder={t('global.media.table_toolbar_placeholder')}
        />
      </Box>
      {loading ? (
        <CircularProgressLoader error={error} height={100} />
      ) : (
        <Box pb={2}>
          {dataAssets && (
            <AppTable<GetCampaignAssets_assetsCampaigns>
              columns={campaignAssetsTableColumn(t, selectModels)}
              onRowClick={handleOpenAssetDialog}
              getRowId={getRowId}
              data={dataAssets}
              hasSelection={true}
            />
          )}
        </Box>
      )}
      <FormWrapper
        open={openAssetDialog}
        handleClose={() => {
          setOpenAssetDialog(false);
          setAssetRow(undefined);
        }}
        title={assetRow?.name ?? t('dam.form_wrapper.single_upload_title')}
        maxWidth="md"
      >
        {assetRow ? (
          <AssetDialog
            id={assetRow?.id}
            onFormValidation={handleFormValidation}
            isUpdateInDam={true}
          />
        ) : (
          <AssetFormWrapper
            onFormValidation={handleFormValidation}
            isUpdateInDam={false}
          />
        )}
      </FormWrapper>
      <UploadProgressSnackbar
        open={uploadProgress.length > 0}
        defaultExpanded={true}
        uploads={uploadProgress}
        onClose={() => clearProgress()}
      />
    </Box>
  );
};
