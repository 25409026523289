import Loader from 'components/Loader/Loader/Loader';
import colors from 'styles/colors.module.scss';
import OrderDetailsHeader from '../OrderDetailsHeader/OrderDetailsHeader';
import OrderBreadcrumbs from '../OrderBreadcrumbs/OrderBreadcrumbs';
import FixedTabs from 'components/FixedTabs';
import { orderDetailsContainerStyle } from './OrderDetailsContainer.style';
import { useOrderDetailsContainer } from './OrderDetailsContainer.hooks';

const OrderDetailsContainer = () => {
  const {
    tabsTranslation,
    isLoading,
    id,
    orderData,
  } = useOrderDetailsContainer();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div css={orderDetailsContainerStyle}>
      <div className="breadcrumbs">
        <OrderBreadcrumbs orderId={id} />
      </div>

      {orderData && <OrderDetailsHeader orderData={orderData.getOrderById} />}
      <FixedTabs
        tabsCssProps={{
          color: colors.documentTab,
        }}
        tabsTitle={tabsTranslation}
      />
    </div>
  );
};

export default OrderDetailsContainer;
