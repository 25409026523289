import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import PinDropOutlinedIcon from '@material-ui/icons/PinDropOutlined';
import { GetOrderById_getOrderById } from 'app/schemaInterfaces/GetOrderById';
import { GetOrderDeliveries_orderDeliveries } from 'app/schemaInterfaces/GetOrderDeliveries';
import { TabTitle } from 'components/FixedTabs';
import { TFunction } from 'i18next';
import FollowOrderControls from './FollowOrderControls/FollowOrderControls';
import FollowOrderDelivery from './FollowOrderDelivery/FollowOrderDelivery';
import FollowOrderDetailsContent from './FollowOrderDetailsContent/FollowOrderDetailsContent';

export enum FollowOrdersRoutes {
  BigShopBasket = '/follow-orders/commandes',
  CommitmentsBasket = '/follow-orders/engagements',
  Commitment = '/follow-orders/engagements/:id',
}

export const orderMenuItems = [
  {
    translationKey: 'menuItems.see_all',
    to: '/follow-orders/',
    key: 0,
  },
  {
    translationKey: 'menuItems.campaigns',
    to: FollowOrdersRoutes.CommitmentsBasket,
    key: 1,
  },
  {
    translationKey: 'menuItems.thematics',
    to: FollowOrdersRoutes.BigShopBasket,
    key: 2,
  },
];

export const followOrderTabs = (
  t: TFunction,
  order: GetOrderById_getOrderById,
  orderDeliveryData?: GetOrderDeliveries_orderDeliveries
): TabTitle[] => [
  {
    label: t('menuItems.order_details'),
    path: 'details',
    component: () => <FollowOrderDetailsContent order={order} />,
    icon: <ListAltOutlinedIcon />,
  },
  ...(orderDeliveryData?.deliveryPoints?.some((point) => point.packages?.length)
    ? [
        {
          label: t('menuItems.delivery_follow'),
          path: 'following',
          component: () => (
            <FollowOrderDelivery
              order={order}
              orderDeliveryData={orderDeliveryData}
            />
          ),
          icon: <PinDropOutlinedIcon />,
        },
      ]
    : []),
  ...(orderDeliveryData?.deliveryPoints?.some((point) => point.controls?.length)
    ? [
        {
          label: t('menuItems.delivery_control'),
          path: 'control',
          component: () => (
            <FollowOrderControls
              order={order}
              orderDeliveryData={orderDeliveryData}
            />
          ),
          //TO DO: To replace with the right icon when upgrade material-ui version to 5
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M19 2h-4.18C14.4.84 13.3 0 12 0c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm7 18H5V4h2v3h10V4h2v16z" />
            </svg>
          ),
        },
      ]
    : []),
];
