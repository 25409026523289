import { useMutation } from '@apollo/client';
import useCurrentEntity from 'app/current-entity-context/useCurrentEntity';
import { GET_ORDERS, UPDATE_ORDER_STATUS } from 'app/graphql/queries/orders';
import {
  UpdateOrderStatusVariables,
  UpdateOrderStatus,
} from 'app/schemaInterfaces/UpdateOrderStatus';
import { useMutationErrorWorkflow } from 'app/utils/customHooks/useMutationErrorWorkflow';
import { OrderI18nKeyErrorDictionary } from 'modules/followOrders/FollowOrder.helper';

const useUpdateOrderStatusMutation = () => {
  const { currentEntity } = useCurrentEntity();

  const [
    updateOrderStatusRequest,
    {
      data: updateOrderStatusData,
      error: updateOrderStatusError,
      loading: updateOrderStatusLoading,
    },
  ] = useMutation<UpdateOrderStatus, UpdateOrderStatusVariables>(
    UPDATE_ORDER_STATUS,
    { refetchQueries: [{ query: GET_ORDERS }] }
  );
  const updateOrderStatus = useMutationErrorWorkflow({
    request: updateOrderStatusRequest,
    i18nKeyErrorDictionary: OrderI18nKeyErrorDictionary,
    showConfirmationMessage: false,
  });

  function updateOrderStatusWithEntity(
    data: Omit<UpdateOrderStatusVariables, 'entityId'>
  ) {
    if (!currentEntity) {
      throw new Error('Cannot update order status when no entity selected!');
    }
    return updateOrderStatus({ entityId: currentEntity.id, ...data });
  }

  return {
    updateOrderStatus: updateOrderStatusWithEntity,
    updateOrderStatusData,
    updateOrderStatusError,
    updateOrderStatusLoading,
  };
};

export default useUpdateOrderStatusMutation;
