import React, { FC } from 'react';
import {
  refusedHeadStyle,
  refusedSubHeadStyle,
} from './FollowOrderRefusedBox.style';

import AlertBox from '../../../../components/AlertBox/AlertBox';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export interface FollowOrderRefusedBoxProps {
  name: string | null;
  date: string | null;
  reason: string | null;
}

const FollowOrderRefusedBox: FC<FollowOrderRefusedBoxProps> = ({
  name,
  date,
  reason,
}) => {
  const { t } = useTranslation();
  return (
    <AlertBox
      variant="error"
      header={
        <>
          <div css={refusedHeadStyle}>{name}</div>
          <div css={refusedSubHeadStyle}>
            {date &&
              t('follow_orders.refused_box.date', {
                date: moment(date).format('DD/MM/YYYY'),
              })}
          </div>
        </>
      }
    >
      {reason}
    </AlertBox>
  );
};

export default FollowOrderRefusedBox;
