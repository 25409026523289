import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

export interface DeliveryAddress {
  title: string;
  name: string;
  street: string;
  city: string;
  zipCode: string;
  additionalAddress: string;
  country: string;
  workinghours: WorkingHours;
  contact: Contact;
  comments: string;
}

export interface WorkingHours {
  workingDays: WorkingDay[];
}

export interface WorkingDay {
  day: string;
  isWorkingDay: boolean;
  start: MaterialUiPickersDate;
  end: MaterialUiPickersDate;
}

export interface Contact {
  firstName: string;
  lastName: string;
  function: string;
  phone: string;
  email: string;
}

export enum WeekDay {
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thusday',
  friday = 'friday',
  saturday = 'satuday',
  sunday = 'sunday',
}
