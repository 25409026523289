import { format as dateFnsFormat } from 'date-fns';
import { fr } from 'date-fns/locale';

const locales: Record<string, Locale> = { fr };

// TODO: this is not the way to declare i18next formatters (https://www.i18next.com/translation-function/formatting)
// by providing a default string of 'PP' or any of its variants for `formatStr`
// it will format dates in whichever way is appropriate to the locale
const format = (date: Date | number, formatStr = 'PP') =>
  dateFnsFormat(date, formatStr, {
    locale: locales[window.__localeId__],
  });

export default format;
