import { CheckboxOption } from 'components/UncontrolledCheckboxMenu';

export enum PagesPanelFilters {
  Validate = '1',
  Customizable = '2',
  Common = '3',
  IncompleteProducts = '4',
  HasComments = '5',
}

export interface PagesPanelHeaderProps {
  totalPagesNumber: number;
  filterPages: (pages: string[]) => void;
  selectPageHandlerFn: (pageNumber: number) => void;
}

export const filtersPagesPanel: CheckboxOption[] = [
  {
    label: 'Validées',
    value: PagesPanelFilters.Validate,
  },
  {
    label: 'Personnalisables',
    value: PagesPanelFilters.Customizable,
  },
  {
    label: 'Commune',
    value: PagesPanelFilters.Common,
  },
  {
    label: 'Produits incomplets',
    value: PagesPanelFilters.IncompleteProducts,
  },
  {
    label: 'Avec commentaires',
    value: PagesPanelFilters.HasComments,
  },
];
