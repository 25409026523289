import moment from 'moment';
import * as Yup from 'yup';
import { ACCEPTED_FORMATS } from 'modules/dam/form/helpers';
import mime from 'mime-types';
import { TFunction } from 'i18next';

export const validFromSchema = (t: TFunction) =>
  Yup.date()
    .nullable()
    .when('validTo', {
      is: (validTo) => !!validTo,
      then: Yup.date().max(
        Yup.ref('validTo'),
        t('common.generic_error.max_date_valid_to')
      ),
      otherwise: Yup.date().nullable(),
    })
    .min(
      moment().startOf('day').toDate(),
      t('common.generic_error.start_date_after_today')
    );

export const validToSchema = (t: TFunction) =>
  Yup.date()
    .nullable()
    .min(Yup.ref('validFrom'), t('common.generic_error.max_date_valid_from'))
    .min(
      moment().startOf('day').toDate(),
      t('common.generic_error.start_date_after_today')
    );

export const checkFileMimeType = (file: unknown) => {
  if (file && isFormValueAFile(file)) {
    const mimetype =
      file.type ?? mime.lookup(file.name.split('.').pop() as string);
    return ACCEPTED_FORMATS.includes(mimetype);
  }
  return true;
};

const isFormValueAFile = (value: unknown): value is File => {
  return (value as File).lastModified !== undefined;
};

export const getFileUploadSchema = (t: TFunction, isEditMode?: boolean) => {
  if (!isEditMode) {
    return Yup.mixed()
      .test('file', t('common.generic_error.file_types'), (value: unknown) =>
        checkFileMimeType(value)
      )
      .required(t('common.generic_error.image_required'));
  }

  return Yup.mixed().test(
    'file',
    t('common.generic_error.file_types'),
    (value: unknown) => checkFileMimeType(value)
  );
};

export const getAssetFormSchema = (isEditMode: boolean, t: TFunction) =>
  Yup.object({
    asset: Yup.object({
      name: Yup.string().required(t('common.generic_error.required')),
      assetType: Yup.string().required(t('common.generic_error.required')),
      visualType: Yup.string().nullable(),
      charterType: Yup.string().nullable(),
      comment: Yup.string().max(150, t('common.generic_error.max_char_number')),
      validFrom: validFromSchema(t),
      validTo: validToSchema(t),
      tags: Yup.array().of(
        Yup.string().max(30, t('common.generic_error.max_char_number'))
      ),
      entities: Yup.array().min(1, t('common.generic_error.required')),
    }),
    file: getFileUploadSchema(t, isEditMode),
  });

export const getMultiAssetFormSchema = (t: TFunction) =>
  Yup.object({
    asset: Yup.object({
      assetType: Yup.string().required(t('common.generic_error.required')),
      visualType: Yup.string().nullable(),
      entities: Yup.array().min(1, t('common.generic_error.required')),
      comment: Yup.string().max(150, t('common.generic_error.max_char_number')),
      validFrom: validFromSchema(t),
      validTo: validToSchema(t),
      tags: Yup.array().of(
        Yup.string().max(30, t('common.generic_error.max_char_number'))
      ),
    }),
    files: Yup.array()
      .of(getFileUploadSchema(t))
      .required(t('common.generic_error.image_required')),
  });
