import React, { useState } from 'react';
import colors from 'styles/colors.module.scss';
import TemporalFilters from 'components/TemporalFilters';
import {
  documentsButtonsGroup,
  DocumentsButtonTypeFilter,
} from 'modules/pro/campaigns/campaignsHelper';
import { useTranslation } from 'react-i18next';

const DocumentsTypeFilter = (): JSX.Element => {
  const [selectedFilterButton, setSelectedFilterButton] = useState<number>(
    DocumentsButtonTypeFilter.Print
  );

  const { t } = useTranslation();

  const handlePrint = () => {
    setSelectedFilterButton(DocumentsButtonTypeFilter.Print);
  };

  const handlePlv = () => {
    setSelectedFilterButton(DocumentsButtonTypeFilter.PLV);
  };

  const handleDigital = () => {
    setSelectedFilterButton(DocumentsButtonTypeFilter.Digital);
  };

  const isButtonSelected = (button: DocumentsButtonTypeFilter) =>
    button === selectedFilterButton;

  const styleButtonVariant = (button: DocumentsButtonTypeFilter) =>
    isButtonSelected(button) ? 'contained' : 'outlined';
  const styleButtonTitle = (button: DocumentsButtonTypeFilter) =>
    isButtonSelected(button) ? colors.white : colors.grey;

  return (
    <TemporalFilters
      buttonsGroupTitle={documentsButtonsGroup({
        handlePrint,
        handlePlv,
        handleDigital,
        t,
      })}
      styleButtonVariant={styleButtonVariant}
      styleButtonTitle={styleButtonTitle}
    />
  );
};

export default DocumentsTypeFilter;
