import React, { CSSProperties, useEffect, useState } from 'react';
import {
  FormControl,
  Typography,
  TextField,
  MenuItem,
  Menu,
  InputAdornment,
} from '@material-ui/core';
import colors from 'styles/colors.module.scss';
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { ApolloError, useApolloClient } from '@apollo/client';
import {
  getPriceByQuantity,
  getPriceByQuantityVariables,
} from 'app/schemaInterfaces/getPriceByQuantity';
import { GET_PRICE_BY_QUANTITY } from 'app/graphql/queries/prices';
import useDebounce from 'components/useDebounce';
import { useStoreActions } from 'state/store';
import { handleIntegersOnly, formatPriceHT } from 'app/utils/common';
import { getClientPrices_prices } from 'app/schemaInterfaces/getClientPrices';
import { hasQuantityError, getErrorMessage } from './QuantitySelectHelper';

const style = (isDisabled: boolean) => css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .arrow {
    display: ${isDisabled ? 'none' : 'flex'};
    color: ${colors.grey};
    cursor: pointer;
    padding: 12px 0px;
    margin-left: 0;
  }
  input {
    width: 350px;
    color: rgba(0, 0, 0, 0.87);
    cursor: text;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 8px !important;
  }
`;

const menuItemStyle = css`
  display: flex;
  flex-direction: column;
  .menu-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .discount {
    color: ${colors.darkGreen};
    background-color: ${colors.lightGreen};
    padding: 3px 6px;
    border-radius: 4px;
    margin-left: 8px;
  }
  .copy-price {
    width: 100%;
    text-align: left;
    > p {
      color: ${colors.grey};
    }
  }
`;

export interface ClientPrice {
  quantity: number | null;
  productName: string | null;
  price: number | null;
  isIncluded?: boolean;
}
interface QuantitySelectProps {
  options: getClientPrices_prices[];
  selectedValue: ClientPrice | null;
  handleSelect: (values: ClientPrice, index?: number) => void;
  index?: number;
  inputStyle?: CSSProperties;
  error?: boolean;
  isDisabled?: boolean;
  isFixedWidth?: boolean;
  minimum?: number | null;
  maximum?: number | null;
  productId: string;
  handlePriceError: () => void;
  hasPriceError: boolean;
}

const QuantitySelect = ({
  selectedValue,
  handleSelect,
  options,
  index,
  productId,
  error = false,
  minimum,
  maximum,
  isDisabled = false,
  isFixedWidth = false,
  handlePriceError,
  hasPriceError = false,
}: QuantitySelectProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [width, setWidth] = useState<string>('');
  const { t } = useTranslation();
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setWidth(event.currentTarget.parentElement?.style.width ?? '');
    setAnchorEl(event.currentTarget.parentElement);
  };
  const [value, setValue] = useState<string | null>(null);
  const debouncedValue = useDebounce(value, 500);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const setIsBasketPending = useStoreActions(
    (actions) => actions.salesModule.basketStatus.setIsBasketPending
  );

  const client = useApolloClient();

  const getPrice = async (quantity: number, productId: string) => {
    return await client
      .query<getPriceByQuantity, getPriceByQuantityVariables>({
        query: GET_PRICE_BY_QUANTITY,
        variables: {
          productId,
          quantity,
        },
      })
      .catch((err: ApolloError) => {
        handlePriceError();
        return null;
      });
  };

  const handleChange = async (value: string) => {
    const quantity = parseInt(value);

    setIsBasketPending(true);

    const updatedcost =
      Boolean(quantity) &&
      !hasQuantityError({
        quantity,
        minQuantity: minimum,
        maxQuantity: maximum,
      })
        ? await getPrice(quantity, productId)
        : null;

    setIsBasketPending(false);
    if (updatedcost?.data.getPriceByQuantity) {
      handleSelect(
        {
          price: updatedcost.data.getPriceByQuantity.price,
          quantity,
          productName: updatedcost.data.getPriceByQuantity.productName,
          isIncluded: updatedcost.data.getPriceByQuantity.isIncluded,
        },
        index
      );
    } else {
      handleSelect(
        {
          price: null,
          quantity: quantity,
          productName: null,
          isIncluded: false,
        },
        index
      );
    }
  };

  useEffect(() => {
    if (debouncedValue !== undefined && debouncedValue !== null) {
      handleChange(debouncedValue);
    }
    // eslint-disable-next-line
  }, [debouncedValue]);

  const hasError = error || hasPriceError;
  return (
    <FormControl css={style(isDisabled)}>
      <TextField
        disabled={isDisabled}
        value={
          value !== null
            ? value
            : (selectedValue?.quantity && selectedValue?.quantity.toString()) ??
              ''
        }
        variant="outlined"
        color="primary"
        placeholder={t('sales.basket_page.quantity_select.min_quantity', {
          quantity: minimum,
        })}
        onKeyPress={handleIntegersOnly}
        error={hasError}
        helperText={
          hasError &&
          getErrorMessage({
            minQuantity: minimum,
            maxQuantity: maximum,
            value: value ?? selectedValue?.quantity,
            hasPriceError,
            t,
          })
        }
        inputProps={{
          min: minimum,
          inputMode: 'numeric',
          pattern: '[0-9]*',
        }}
        InputProps={{
          startAdornment: options.length ? (
            <InputAdornment
              position="end"
              className="arrow"
              onClick={(e) => !isDisabled && handleClick(e)}
            >
              <KeyboardArrowDownOutlinedIcon />
            </InputAdornment>
          ) : (
            <></>
          ),
          style: {
            width: isFixedWidth ? '205px' : 'auto',
            display: 'flex',
            flexDirection: 'row-reverse',
            padding: 0,
          },
          onChange: (e) => setValue(e.target.value),
        }}
      />
      {options.length ? (
        <Menu
          id="checkbox-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            style: {
              width: width,
              maxHeight: '219px',
              boxShadow: '0px 0px 8px rgba(59, 59, 80, 0.1)',
              padding: '5px 10px',
            },
          }}
        >
          {options.map((option, optionIndex) => {
            return (
              <MenuItem
                key={optionIndex}
                onClick={() => {
                  setValue(option.quantity?.toString() ?? '');

                  setAnchorEl(null);
                }}
                css={menuItemStyle}
              >
                <div className="menu-item">
                  <div className="quantity">
                    <Typography variant="body1">
                      {t('sales.basket_page.quantity_select.copies', {
                        quantity: option.quantity,
                      })}
                    </Typography>
                  </div>
                  <Typography variant="body1">
                    {option.isIncluded
                      ? t('common.included')
                      : `${option.price?.toFixed(2)} ${t(
                          'common.money_unit'
                        )} (${t('common.money_unit_tax_free')})`}
                  </Typography>
                </div>
                {!option.isIncluded && (
                  <div className="copy-price">
                    <Typography variant="caption">
                      {t('sales.basket_page.quantity_select.price_per_copy', {
                        price: formatPriceHT(option.price ?? 0, t),
                      })}
                    </Typography>
                  </div>
                )}
              </MenuItem>
            );
          }) ?? <span />}
        </Menu>
      ) : null}
    </FormControl>
  );
};

export default QuantitySelect;
