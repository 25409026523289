import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import colors from 'styles/colors.module.scss';
import FirstToolbar, {
  AutoSaveInfo,
} from 'modules/workflow/containers/Toolbar/FirstToolbar/FirstToolbar';
import SecondToolbar, {
  ToolbarActions,
} from 'modules/workflow/containers/Toolbar/SecondToolbar/SecondToolbar';

export interface ToolbarProps {
  autoSaveInfo: AutoSaveInfo;
  toolbarActions?: ToolbarActions;
  indeterminatePageCount?: boolean;
  onFinalSave: () => Promise<void>;
  isFinalSaving?: boolean;
  disabled?: boolean;
}

const Toolbar = React.forwardRef<HTMLElement, ToolbarProps>(
  (
    {
      autoSaveInfo,
      toolbarActions,
      indeterminatePageCount,
      onFinalSave,
      isFinalSaving,
      disabled,
    }: ToolbarProps,
    ref
  ): JSX.Element => {
    return (
      <Box bgcolor={colors.white}>
        <CssBaseline />
        <FirstToolbar
          onFinalSave={onFinalSave}
          isFinalSaving={isFinalSaving}
          autoSaveInfo={autoSaveInfo}
          indeterminatePageCount={indeterminatePageCount}
          ref={ref}
          disabled={disabled}
        />
        <SecondToolbar toolbarActions={toolbarActions} />
      </Box>
    );
  }
);

export default Toolbar;
