import { useMutation } from '@apollo/client';
import { useCurrentUser } from 'app/auth/UserContext';
import useCurrentEntity from 'app/current-entity-context/useCurrentEntity';
import { STORE_RESOURCES } from 'app/graphql/queries/documents';
import { GetClientShippingAddress_entityDeliveryAdresses_address } from 'app/schemaInterfaces/GetClientShippingAddress';
import {
  BasketType,
  CommitmentDocumentInput,
  CreateOrderChildItemInput,
  CreateOrderInput,
  CreateOrderItemInput,
  OrderItemWithSocialNetworksInput,
  ResourcesInput,
} from 'app/schemaInterfaces/globalTypes';
import {
  StoreResources,
  StoreResources_storeResources,
  StoreResourcesVariables,
} from 'app/schemaInterfaces/StoreResources';
import { useMutationErrorWorkflow } from 'app/utils/customHooks/useMutationErrorWorkflow';
import { isDigitalDocument } from 'app/utils/digital-helper';
import { omit } from 'lodash';
import { SelectedBasketdocument } from 'modules/sales/sales.state';
import { SalesRoute } from 'modules/sales/salesHelpers';
import useCreateCommitmentMutation from 'queries/useCreateCommitmentMutation';
import useCreateOrderMutation from 'queries/useCreateOrderMutation';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'state/store';
import { OrderConfirmationDetails } from '../../BasketSteps';
import { BasketI18nKeyErrorDictionary, groupDocument } from '../StepsHelper';

export interface UseBasketSummaryStepData {
  setCommitmentNumber: Dispatch<SetStateAction<string | undefined>>;
  setOrderConfirmationDetails: Dispatch<
    SetStateAction<OrderConfirmationDetails | undefined>
  >;
}

export function useBasketSummaryStep({
  setCommitmentNumber,
  setOrderConfirmationDetails,
}: UseBasketSummaryStepData) {
  const { t } = useTranslation();
  const history = useHistory();
  const { currentEntity } = useCurrentEntity();

  const { clientId } = useCurrentUser();
  const [storeResourcesRequest, { loading: isStoringResources }] = useMutation<
    StoreResources,
    StoreResourcesVariables
  >(STORE_RESOURCES);
  const storeResources = useMutationErrorWorkflow({
    request: storeResourcesRequest,
    i18nKeyErrorDictionary: BasketI18nKeyErrorDictionary,
    showConfirmationMessage: false,
  });
  const basketDocuments = useStoreState(
    (state) => state.salesModule.basketdocuments.selectedBasketdocuments
  );
  const setResources = useStoreActions(
    (actions) => actions.salesModule.basketdocuments.setResources
  );

  const campaignName = useStoreState(
    (state) => state.salesModule.campaignName.name
  );
  const setStep = useStoreActions(
    (actions) => actions.salesModule.step.setActiveStep
  );
  const basketType = useStoreState(
    (state) => state.salesModule.basket.basketType
  );

  const currentBillingAddress = useStoreState(
    (state) => state.salesModule.billingAddress.billingAddress?.billingAddress
  );

  const getNormalizedDocuments = (
    basketDocumentItem: SelectedBasketdocument
  ): CommitmentDocumentInput => {
    const addresses = basketDocumentItem.addresses?.map((address) => {
      const {
        __typename,
        ...rest
      } = address.address as GetClientShippingAddress_entityDeliveryAdresses_address;
      return {
        quantity: address.quantity,
        address: rest,
        productName: address.productName as string, // insured by error
        price: address.price as number, // insured by error
        addressBlockId: address.addressBlockId,
      };
    });

    return {
      documentId: basketDocumentItem.basketDocument.document.id,
      addresses: addresses ?? [],
      comment: basketDocumentItem.comment,
    };
  };

  const basketFilter = {
    entityId: currentEntity?.id as string,
  };
  const {
    createCommitment: createCommitmentRequest,
    createCommitmentLoading: isCreatingCommitment,
  } = useCreateCommitmentMutation({ basketFilter });
  const createCommitment = useMutationErrorWorkflow({
    request: createCommitmentRequest,
    i18nKeyErrorDictionary: BasketI18nKeyErrorDictionary,
    showConfirmationMessage: false,
  });

  const {
    createOrder: createOrderRequest,
    createOrderLoading: isCreatingOrder,
  } = useCreateOrderMutation({
    basketFilter,
  });
  const createOrder = useMutationErrorWorkflow({
    request: createOrderRequest,
    i18nKeyErrorDictionary: BasketI18nKeyErrorDictionary,
    showConfirmationMessage: false,
  });

  const getOrderBillingAddress = () => {
    if (currentBillingAddress) {
      const {
        __typename,
        id,
        postBox,
        isShipping,
        deliveryDepartment,
        ...rest
      } = currentBillingAddress;
      return rest;
    }
  };

  const campaignsWithDocuments = groupDocument(basketDocuments);

  function getDocumentResources(
    documentId: string,
    basketResources: StoreResources_storeResources[]
  ): ResourcesInput[] {
    const documentResources = basketResources.find(
      (resource) => resource.documentId === documentId
    );
    if (!documentResources) {
      return [];
    }

    return documentResources.resources.map((resource) => ({
      ...omit(resource, ['__typename']),
      resourceId: `${clientId}/resources/${resource.resourceId}`,
    }));
  }

  function getBaseOrderItem(
    {
      basketDocument: { document: doc, digitalContent },
      deliveryDate,
      comment,
    }: SelectedBasketdocument,
    basketResources?: StoreResources_storeResources[]
  ): Omit<CreateOrderItemInput, 'quantity'> {
    const resources = basketResources
      ? getDocumentResources(doc.id, basketResources)
      : [];
    if (!doc.productId) {
      throw new Error('Missing or invalid data for base order item!');
    }

    let socialNetwork: OrderItemWithSocialNetworksInput | undefined;
    if (isDigitalDocument(doc.category)) {
      socialNetwork = {
        resources,
        publishDescription: digitalContent?.message ?? '',
        category: doc.category,
        clientNetworkLink: currentEntity?.socialNetworks?.clientNetworkLink,
        clientNetworkPageId: currentEntity?.socialNetworks?.clientNetworkPageId,
        mediumType: doc.mediumType,
        expectedDeliveryDate: deliveryDate,
      };
    }

    return {
      expectedDeliveryDate: deliveryDate,
      comment,
      productId: doc.productId,
      onePacDocumentId: doc.id,
      onePacThematicName: doc.campaign.name,
      thumbnailAssetId: `${clientId}/documents/${doc.thumbnailId}`,
      resources,
      socialNetwork,
    };
  }

  function getChildOrderItems(
    basketResources?: StoreResources_storeResources[]
  ): CreateOrderChildItemInput[] {
    return basketDocuments.flatMap((selectedDocument) =>
      selectedDocument.addresses.map((address) => {
        const shippingAddress = omit(address.address, ['__typename', 'id']);
        if (!shippingAddress.name) {
          throw new Error('Missing or invalid data for child order item!');
        }

        const item: CreateOrderChildItemInput = {
          ...getBaseOrderItem(selectedDocument, basketResources),
          quantity: address.quantity,
          shippingAddress: {
            ...shippingAddress,
            name: shippingAddress.name,
          },
        };
        return item;
      })
    );
  }

  function getRootOrderItems(
    basketResources?: StoreResources_storeResources[]
  ): CreateOrderItemInput[] {
    return basketDocuments.map((selectedDocument) => {
      const totalQuantity = selectedDocument.addresses.reduce(
        (total, address) => total + address.quantity,
        0
      );

      const item: CreateOrderItemInput = {
        ...getBaseOrderItem(selectedDocument, basketResources),
        quantity: totalQuantity,
      };
      return item;
    });
  }

  const onSubmit = async () => {
    if (!currentEntity) {
      history.replace({ pathname: SalesRoute.CommitmentsBasket });
      return;
    }
    const AreAllDocumentsHaveAddress = basketDocuments.every(
      (basketDocument) =>
        basketDocument.addresses && basketDocument.addresses.length > 0
    );

    if (isContinueDisabled) {
      return;
    } else if (
      AreAllDocumentsHaveAddress &&
      basketType === BasketType.Commitment
    ) {
      const datas = await Promise.all(
        basketDocuments.map(async (basketDocument) => {
          const res = await createCommitment({
            values: {
              entityId: currentEntity.id,
              campaignId:
                basketDocuments[0].basketDocument.document.campaign.id,
              document: getNormalizedDocuments(basketDocument),
            },
          });
          if (!res) {
            return;
          }

          return res.data;
        })
      );
      if (datas.length === basketDocuments.length && datas[0]) {
        setCommitmentNumber(datas[0].createCommitment.commitmentCode);
        setStep(3);
      }
    } else if (
      AreAllDocumentsHaveAddress &&
      basketType === BasketType.BigShop
    ) {
      const documentIds = basketDocuments.map(
        (basketDocument) => basketDocument.basketDocument.document.id
      );
      const resResources = await storeResources({
        entityId: currentEntity.id,
        documentIds,
      });
      if (!resResources) {
        return;
      }

      if (resResources.data?.storeResources) {
        resResources.data.storeResources.forEach((item) => {
          setResources(item);
        });

        const billingAddress = getOrderBillingAddress();
        if (!billingAddress || !campaignName) {
          throw new Error('Missing or invalid data for order!');
        }

        const order: CreateOrderInput = {
          billingAddress,
          orderItem: getRootOrderItems(resResources.data?.storeResources),
          childrenOrder: [
            {
              billingAddress,
              clientName: currentEntity.name,
              orderItem: getChildOrderItems(resResources.data?.storeResources),
            },
          ],
          clientName: currentEntity.name,
          operationName: campaignName,
        };
        const res = await createOrder({
          order,
          entityId: currentEntity?.id,
        });
        if (!res) {
          return;
        }

        if (res.data && res.data.createOrder.order.status) {
          setStep(4);
          setOrderConfirmationDetails({
            orderId: res.data.createOrder.order.id,
            orderStatus: res.data.createOrder.order.followStatus,
            orderPrice: res.data.createOrder.order.sellingPriceHt,
            productSellingPriceHt:
              res.data.createOrder.order.productSellingPriceHt,
            transportSellingPriceHt:
              res.data.createOrder.order.transportSellingPriceHt,
          });
        }
      }
    }
  };

  const onCancel = () => {
    history.push(
      basketType === BasketType.Commitment
        ? SalesRoute.CommitmentsBasket
        : SalesRoute.BigShopBasket
    );
  };
  const title =
    basketType === BasketType.Commitment
      ? t('sales.basket_page.summary_page.title_commitment')
      : t('sales.basket_page.summary_page.title_order');

  const subTitle =
    basketType === BasketType.Commitment
      ? t('sales.basket_page.summary_page.subtitle_commitment')
      : t('sales.basket_page.summary_page.subtitle_order');

  const isBasketPending = useStoreState(
    (state) => state.salesModule.basketStatus.isBasketPending
  );

  const isLoading =
    isCreatingCommitment ||
    isCreatingOrder ||
    isStoringResources ||
    isBasketPending;

  //
  // Basket validation
  //
  const { isContinueDisabled, errors } = useMemo(() => {
    // TODO : add price rules when we add isIncluded
    // WARNING : ISSUE how can we have productName by address AND by document
    const hasInvalidPriceInfo = basketDocuments.some(
      ({ basketDocument, addresses }) =>
        !basketDocument.document.productId ||
        !basketDocument.productName ||
        addresses.some(
          (address) =>
            !address.productName ||
            address.price === null ||
            address.price === undefined
        )
    );
    const hasMissingBillingAddress = !isBasketPending && !currentBillingAddress;

    const isContinueDisabled = hasInvalidPriceInfo || hasMissingBillingAddress;

    const errors: string[] = [];
    if (hasInvalidPriceInfo) {
      errors.push(t('sales.basket_page.errors.invalid_product_id'));
    }
    if (hasMissingBillingAddress) {
      errors.push(t('sales.basket_page.errors.missing_billing_address'));
    }

    return { isContinueDisabled, errors };
  }, [basketDocuments, currentBillingAddress, isBasketPending, t]);

  return {
    title,
    subTitle,
    basketType,
    campaignsWithDocuments,
    onSubmit,
    onCancel,
    basketDocuments,
    errors,
    isContinueDisabled,
    isLoading,
  };
}
