import { useQuery, WatchQueryFetchPolicy } from '@apollo/client';
import { GET_DOCUMENTS } from 'app/graphql/queries/documents';
import {
  GetDocuments,
  GetDocumentsVariables,
} from 'app/schemaInterfaces/GetDocuments';
import {
  DocumentFilterInput,
  DocumentSortInput,
  PaginationInput,
} from 'app/schemaInterfaces/globalTypes';

export interface DocumentsQueryProps {
  filter: DocumentFilterInput;
  fetchPolicy?: WatchQueryFetchPolicy;
  sort?: DocumentSortInput;
  pagination?: PaginationInput;
}
const useDocumentsQuery = ({
  filter = {},
  fetchPolicy = 'no-cache',
  sort,
  pagination,
}: DocumentsQueryProps) =>
  useQuery<GetDocuments, GetDocumentsVariables>(GET_DOCUMENTS, {
    fetchPolicy,
    variables: {
      filter,
      sort,
      pagination,
    },
  });

export default useDocumentsQuery;
