import { gql } from '@apollo/client';

export const GET_CHILI_EDITOR_CONFIG = gql`
  query GetChiliEditorConfig($templateId: String!) {
    getChiliEditorConfig(templateId: $templateId) {
      iframeUrl
      apiKey
      pdfExportSettings {
        id
        name
        xml
      }
    }
  }
`;
