import { Backdrop, CircularProgress } from '@material-ui/core';
import { Action } from 'components/ActionsMenu/ActionsMenu';
import SimpleActionsMenu from 'components/ActionsMenu/SimpleActionsMenu';
import React, { FC } from 'react';
import DownloadButton from './DownloadButton';

export interface DownloadOptionsMenuProps {
  options: Action[];
  isDownloading?: boolean;
  disabled?: boolean;
}

const DownloadOptionsMenu: FC<DownloadOptionsMenuProps> = ({
  options,
  isDownloading = false,
  disabled,
}) => (
  <>
    <Backdrop open={isDownloading}>
      <CircularProgress color="inherit" />
    </Backdrop>
    {options.length === 1 ? (
      <DownloadButton download={options[0].onClick} disabled={disabled} />
    ) : (
      <SimpleActionsMenu
        actions={options}
        button={<DownloadButton disabled={disabled} />}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        isNonInteractive={disabled}
      />
    )}
  </>
);

export default DownloadOptionsMenu;
