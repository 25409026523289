import { gql } from '@apollo/client';

export const GET_TAGS = gql`
  query GetTags($filter: TagsFilterInput!) {
    tags: getTags(filter: $filter) {
      id
      title
      type
    }
  }
`;
