import { useQuery } from '@apollo/client';
import React, { PropsWithChildren, useContext } from 'react';
import Loader from '../../components/Loader/Loader/Loader';
import Splash from '../../components/Splash/Splash';
import { GET_CURRENT_USER } from '../graphql/queries/users';
import {
  GetCurrentUser,
  GetCurrentUser_user,
} from '../schemaInterfaces/GetCurrentUser';

export type User = GetCurrentUser_user;

export const UserContext = React.createContext<User | undefined>(undefined);
/**
 * Once the user is authenticated, this provider makes an api call to onepac-api to get the user profile
 */
export const UserContextProvider = ({
  children,
}: PropsWithChildren<{}>): JSX.Element => {
  const { data, loading, error } = useQuery<GetCurrentUser>(GET_CURRENT_USER);

  if (loading) {
    return <Loader height="100vh" />;
  }

  if (error || !data) {
    return (
      <Splash
        title="Authorization Error"
        body={error?.message}
        homeButton
        logoutButton
      />
    );
  }

  return (
    <UserContext.Provider value={data.user}>{children}</UserContext.Provider>
  );
};

export const useCurrentUser = () => {
  const user = useContext(UserContext);
  if (!user) {
    throw new Error('User context must be provided');
  }
  return user;
};
