import produce, { Draft } from 'immer';
import {
  AssetsPanelActions,
  AssetsPanelActionTypes,
} from 'modules/workflow/containers/Leftsidebar/AssetsPanel/reducer/actions';
import {
  AssetDataSource,
  AssetsPanelState,
  ViewMode,
} from 'modules/workflow/containers/Leftsidebar/AssetsPanel/reducer/definitions';

export const assetPanelsInitialState: AssetsPanelState = {
  viewMode: ViewMode.TYPES_VIEW,
  dataSource: AssetDataSource.DAM,
  filters: {
    text: '',
    assetType: null,
    divisions: {},
    entityId: '',
  },
};

export const assetsPanelReducer = produce(
  (draft: Draft<AssetsPanelState>, action: AssetsPanelActions) => {
    switch (action.type) {
      case AssetsPanelActionTypes.UpdateSearch:
        draft.filters.text = action.payload;
        if (action.payload === '' && draft.filters.assetType === null) {
          draft.viewMode = ViewMode.TYPES_VIEW;
        } else {
          draft.viewMode = ViewMode.CONTENT_VIEW;
        }
        break;
      case AssetsPanelActionTypes.UpdateAssetTypes:
        draft.filters.assetType = action.payload;
        draft.viewMode = ViewMode.CONTENT_VIEW;
        break;
      case AssetsPanelActionTypes.UpdateDivisions:
        draft.filters.divisions[action.payload.key] = action.payload.values;
        break;
      case AssetsPanelActionTypes.UpdateValidityDates:
        draft.filters.validFrom = action.payload.validFrom;
        draft.filters.validTo = action.payload.validTo;
        break;
      case AssetsPanelActionTypes.ClearFilters:
        draft.filters.divisions = {};
        draft.filters.validFrom = undefined;
        draft.filters.validTo = undefined;
        break;
      case AssetsPanelActionTypes.SwitchDataSource:
        draft.dataSource = action.payload;
        break;
      case AssetsPanelActionTypes.GoToTypesView:
        draft.filters.assetType = null;
        draft.filters.text = '';
        draft.viewMode = ViewMode.TYPES_VIEW;
        break;
    }
  }
);
