import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const informationToastStyle = css`
  .info {
    display: flex;
    .icon {
      color: ${colors.grey};
      align-self: center;
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 24px;
      display: flex;
      align-items: center;
      letter-spacing: -0.03em;
      color: ${colors.grey};
      padding-left: 8px;
      padding-top: 3px;
    }
  }
  .description {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: -0.03em;
    color: ${colors.grey};
    padding-left: 25px;
  }
  .field {
    display: flex;
    flex-direction: column;
  }
`;
