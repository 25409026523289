import FollowOrderStepper from '../FollowOrderStepper/FollowOrderStepper';
import { GetOrderById_getOrderById } from 'app/schemaInterfaces/GetOrderById';
import OrderCampaignsWithDocuments from '../OrderCampaignsWithDocuments/OrderCampaignsWithDocuments';
import React from 'react';
import { useFollowOrderDetailsContent } from './FollowOrderDetailsContent.hooks';

const FollowOrderDetailsContent = ({
  order,
}: {
  order: GetOrderById_getOrderById;
}) => {
  const { orderDocuments } = useFollowOrderDetailsContent(order);

  return (
    <>
      {order && <FollowOrderStepper order={order} />}

      {orderDocuments?.map((item, index) => {
        return (
          <OrderCampaignsWithDocuments
            key={index}
            campaignName={item?.campaignName ?? ''}
            isCampaignArchived={!!item?.isCampaignArchived}
            documentItems={item.documentItems}
            totalPriceHT={item.totalPriceHT}
            transportSellingPriceHt={item.transportSellingPriceHt}
          />
        );
      })}
    </>
  );
};

export default FollowOrderDetailsContent;
