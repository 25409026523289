import { ChiliHostInterface, ChiliHostWindow } from './types';

export function chiliSetHostInterface(current: Partial<ChiliHostInterface>) {
  const defaults: Record<keyof ChiliHostInterface, undefined> = {
    OnGetApiKey: undefined,
    OnEditorEvent: undefined,
  };
  Object.assign(window as ChiliHostWindow, defaults, current);
}

export function chiliResetHostInterface() {
  chiliSetHostInterface({});
}
