import React, { useEffect } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  makeStyles,
  PopoverOrigin,
  Typography,
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import colors from 'styles/colors.module.scss';
import { useTranslation } from 'react-i18next';
import { menuButtonStyle, menuItemStyle } from './componetsHelper';

export interface CheckboxOption {
  label: string | null;
  value: string | null;
}

interface UncontrolledCheckboxMenuProps {
  options?: CheckboxOption[];
  iconMenuButton?: React.ReactNode;
  noItemMessage?: string;
  valuesProp?: CheckboxOption['value'][];
  hasIndentation?: boolean;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  onChange?: (values: UncontrolledCheckboxMenuProps['valuesProp']) => void;
  iconButtonBackgroundColor?: string;
  isPim?: boolean;
}

interface StyleProps {
  iconButtonBackgroundColor: UncontrolledCheckboxMenuProps['iconButtonBackgroundColor'];
}

const useStyles = makeStyles({
  iconButton: ({ iconButtonBackgroundColor }: StyleProps) => ({
    '&:hover': {
      backgroundColor: iconButtonBackgroundColor,
    },
  }),
  onIconButtonClick: ({ iconButtonBackgroundColor }: StyleProps) => ({
    backgroundColor: iconButtonBackgroundColor,
  }),
});

const UncontrolledCheckboxMenu = ({
  valuesProp,
  options,
  noItemMessage,
  iconMenuButton,
  hasIndentation = false,
  anchorOrigin,
  transformOrigin,
  iconButtonBackgroundColor,
  onChange,
  isPim,
}: UncontrolledCheckboxMenuProps): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles({ iconButtonBackgroundColor });

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [values, setValues] = React.useState<
    UncontrolledCheckboxMenuProps['valuesProp']
  >([]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const eventTargetValue = event.target.value;
    const tmpValues = [...(values ?? [])];
    tmpValues.includes(eventTargetValue)
      ? tmpValues.splice(tmpValues.indexOf(eventTargetValue), 1)
      : tmpValues.push(eventTargetValue);
    setValues(tmpValues);
    onChange?.(tmpValues);
  };

  useEffect(() => {
    setValues(valuesProp);
  }, [valuesProp]);

  return (
    <>
      <IconButton
        classes={menuButtonStyle(
          classes.iconButton,
          classes.onIconButtonClick,
          iconButtonBackgroundColor,
          anchorEl
        )}
        aria-controls="button-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
      >
        {iconMenuButton ?? (
          <FilterListIcon htmlColor={isPim ? colors.body : colors.white} />
        )}
      </IconButton>
      <Menu
        id="checkbox-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={
          anchorOrigin ?? {
            vertical: 'bottom',
            horizontal: 'right',
          }
        }
        transformOrigin={
          transformOrigin ?? {
            vertical: 'top',
            horizontal: 'right',
          }
        }
      >
        {options?.length !== 0 ? (
          options?.map((option, index) => {
            return (
              <label key={index}>
                <MenuItem style={menuItemStyle(index, hasIndentation)}>
                  <FormControlLabel
                    value={option.value}
                    control={
                      <Checkbox
                        onChange={handleChange}
                        checked={
                          values ? values.indexOf(option.value) > -1 : undefined
                        }
                      />
                    }
                    label={option.label}
                  />
                </MenuItem>
              </label>
            );
          })
        ) : (
          <Box textAlign="center" px={2}>
            <Typography variant="body1">
              {noItemMessage ?? t('workflow.no_item_default_message')}
            </Typography>
          </Box>
        )}
      </Menu>
    </>
  );
};

export default UncontrolledCheckboxMenu;
