import { Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { getStatusWording } from './DocumentStatusTag.helper';
import { documentStatusTagStyle } from './DocumentStatusTag.style';

const DocumentStatusTag = ({
  isValidated,
  isObsolete,
}: {
  isValidated: boolean;
  isObsolete: boolean;
}) => {
  const { t } = useTranslation();

  const statusWording = getStatusWording({ isValidated, isObsolete, t });

  return (
    <Tooltip title={statusWording.tooltip}>
      <div css={documentStatusTagStyle(isValidated && !isObsolete)}>
        {statusWording.title}
      </div>
    </Tooltip>
  );
};

export default DocumentStatusTag;
