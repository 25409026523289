import { css } from '@emotion/react';

export const atocompleteFieldStyle = css`
  .MuiAutocomplete-inputRoot.MuiOutlinedInput-marginDense {
    padding: 0;

    .MuiAutocomplete-input {
      padding: 0 12px;
      height: 40px;
    }
  }
`;
