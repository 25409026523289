import React, { useEffect, useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import colors from 'styles/colors.module.scss';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { FieldProps } from 'formik';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

export interface Option {
  id: string;
  value: string;
  oneSupplyAttributeName?: string;
  label?: string;
}

interface ControlledOneValueSelectProps {
  selectValues: Option[];
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onOptionChange?: (obj: Option) => void;
  oneSupplyAttributeName?: string;
  width: string;
  onPreviousSelection?: boolean;
}

const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '2px solid',
        borderColor: colors.buttonGroupOutlined,
        borderRadius: '4px',
      },
      '&:hover fieldset': {
        border: `1px solid ${colors.black}`,
      },
      '& .MuiInputBase-input.MuiSelect-outlined.MuiSelect-outlined': {
        padding: '11.7px 12px',
      },
    },
  },
})(TextField);

export const ControlledOneValueSelect = ({
  form: { errors, touched, setFieldValue, setTouched },
  field: { name, value: formikValues },
  selectValues,
  onOptionChange,
  oneSupplyAttributeName,
  width,
  onPreviousSelection,
}: FieldProps & ControlledOneValueSelectProps): JSX.Element => {
  const [value, setValue] = React.useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    setFieldValue(name, event.target.value);
    setTouched({ ...touched, [name]: true });
    onOptionChange?.({
      id: event.currentTarget.id,
      value: event.target.value,
      oneSupplyAttributeName,
    });
  };

  useEffect(() => {
    if (formikValues) {
      setValue(formikValues);
    }
  }, [formikValues]);

  return (
    <Box width={width} mt={2}>
      <CssTextField
        id="standard-select-option"
        select
        value={!onPreviousSelection ? value : ''}
        onChange={handleChange}
        fullWidth
        size="small"
        variant="outlined"
        error={touched[name] && Boolean(errors[name])}
        helperText={touched.name && errors.name}
        SelectProps={{
          onOpen: () => {
            setIsOpen(!isOpen);
          },
          onClose: () => {
            setIsOpen(!isOpen);
          },
          IconComponent: (props) => <KeyboardArrowDownIcon {...props} />,
        }}
      >
        {selectValues?.map((option, index) => (
          <MenuItem key={index} id={option.id} value={option.value}>
            {option.label ?? option.value}
          </MenuItem>
        ))}
      </CssTextField>
    </Box>
  );
};

export default ControlledOneValueSelect;
