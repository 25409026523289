import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { screenHeight } from 'styles/theme';
import PaperWrapper from 'components/PaperWrapper/PaperWrapper';
import '../Loader/Loader.scss';
import { ApolloError } from '@apollo/client';
import { useTranslation } from 'react-i18next';

export interface LoaderBaseProps {
  height?: number | string;
  error?: ApolloError;
  IsPaperWrap?: boolean;
  loader: React.ReactNode;
}

const Loader: FC<LoaderBaseProps> = ({
  height,
  error,
  IsPaperWrap,
  loader,
}) => {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      height={height || screenHeight()}
      flexDirection="column"
      textAlign="center"
      justifyContent="center"
      alignItems="center"
    >
      {error ? (
        <PaperWrapper condition={IsPaperWrap}>
          <Box px={3} mt={3}>
            <Typography variant="subtitle2">
              {t('common.generic_error.type')}
            </Typography>
            <Typography>
              {t('common.generic_error.message')}
              <br />
              {t('common.generic_error.refresh')}
            </Typography>
          </Box>
        </PaperWrapper>
      ) : (
        <>{loader}</>
      )}
    </Box>
  );
};

export default Loader;
