import { ApolloQueryResult, useMutation } from '@apollo/client';
import {
  ArchiveCampaignById,
  ArchiveCampaignByIdVariables,
} from 'app/schemaInterfaces/ArchiveCampaignById';
import {
  GetAllCampaignsLean,
  GetAllCampaignsLeanVariables,
} from 'app/schemaInterfaces/GetAllCampaignsLean';

import { ARCHIVE_CAMPAIGN_BY_ID } from 'app/graphql/queries/campaigns';
import { CampaignI18nKeyErrorDictionary } from '../campaignsHelper';
import { useMutationErrorWorkflow } from 'app/utils/customHooks/useMutationErrorWorkflow';
import { useState } from 'react';
import { useTableLine } from 'modules/pro/components/TableLine/TableLine.hooks';
import { useHistory } from 'react-router-dom';
import { ShopRoute } from 'modules/shop/shopHelpers';

export const useCampaignCreation = (
  id: string,
  isCampaignModel: boolean,
  refetch?: (
    variables?: GetAllCampaignsLeanVariables
  ) => Promise<ApolloQueryResult<GetAllCampaignsLean>>
) => {
  const { manageCampaign } = useTableLine(id);
  const [isCampaignDialogOpen, setIsCampaignDialogOpen] = useState<boolean>(
    false
  );
  const history = useHistory();

  const [isCampaignPreviewOpen, setCampaignPreviewOpen] = useState(false);

  const [
    openActionCreateCampaignDialog,
    setOpenActionCreateCampaignDialog,
  ] = useState<HTMLButtonElement | null>(null);

  const handleOpenCampaignDialog = () => {
    setIsCampaignDialogOpen(true);
    setOpenActionCreateCampaignDialog(null);
  };

  const handleCloseCampaignDialog = () => {
    setIsCampaignDialogOpen(false);
  };
  const handleOpenActionsCreateCampaignDialog = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setOpenActionCreateCampaignDialog(event.currentTarget);
  };

  const handleCloseActionsCreateCampaignDialog = () => {
    setOpenActionCreateCampaignDialog(null);
  };

  const [archiveCampaignById] = useMutation<
    ArchiveCampaignById,
    ArchiveCampaignByIdVariables
  >(ARCHIVE_CAMPAIGN_BY_ID);

  const deleteCampaignWithErrorWorkflow = useMutationErrorWorkflow({
    request: archiveCampaignById,
    refetch,
    i18nKeyErrorDictionary: CampaignI18nKeyErrorDictionary,
    i18nKeyConfirmationMessage: 'common.actions.delete_success',
    variables: { id },
  });

  const previewCampaign = () => {
    setCampaignPreviewOpen(true);
    setOpenActionCreateCampaignDialog(null);
  };
  const handleDeleteCampaign = () => {
    deleteCampaignWithErrorWorkflow();
    history.push(
      isCampaignModel
        ? ShopRoute.AllLocalCampaigns
        : ShopRoute.AllNationalCampaigns
    );
  };

  return {
    manageCampaign,
    handleOpenCampaignDialog,
    handleCloseCampaignDialog,
    openActionCreateCampaignDialog,
    setOpenActionCreateCampaignDialog,
    previewCampaign,
    handleDeleteCampaign,
    handleOpenActionsCreateCampaignDialog,
    handleCloseActionsCreateCampaignDialog,
    isCampaignDialogOpen,
    isCampaignPreviewOpen,
    setCampaignPreviewOpen,
  };
};
