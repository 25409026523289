import { Button } from '@material-ui/core';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import { useProductsSheetsContext } from './baseProducts/BaseProducts';

interface RegroupingTabProps {
  label: string;
  fieldName: string;
}

const RegroupingTab = ({ label, fieldName }: RegroupingTabProps) => {
  const { setFieldName } = useProductsSheetsContext();

  return (
    <div>
      <Button
        color="primary"
        startIcon={<AddOutlinedIcon />}
        onClick={() => setFieldName(fieldName)}
      >
        {label}
      </Button>
    </div>
  );
};

export default RegroupingTab;
