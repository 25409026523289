import {
  GET_DIVISIONS as I_GET_DIVISIONS,
  GET_DIVISIONS_getDivisions as Divisions,
} from 'app/schemaInterfaces/GET_DIVISIONS';
import { useMemo } from 'react';

export interface DivisionMultiSelectModel {
  key: string;
  label: string;
  values: string[];
}

export const useDivisionMultiSelect = (data: I_GET_DIVISIONS | undefined) => {
  const { multiSelectModel } = useMemo(() => {
    const buildMultiSelectModelsMap = (
      divisions: Divisions[],
      divisionsMap: Map<string, DivisionMultiSelectModel>
    ) => {
      const key = divisions[0]?.key;
      let selectModel = divisionsMap.get(key);
      if (!selectModel) {
        selectModel = {
          key,
          label: divisions[0]?.divisionName ?? 'None',
          values: [],
        };
        divisionsMap.set(key, selectModel);
      }
      selectModel.values = [
        ...selectModel.values,
        ...divisions.map(({ value }) => value),
      ];
      divisions.forEach((division) => {
        if (division.valuesTree) {
          buildMultiSelectModelsMap(division.valuesTree, divisionsMap);
        }
      });
    };
    if (!data) {
      return {
        multiSelectModel: [],
      };
    }
    const multiSelectMap = new Map<string, DivisionMultiSelectModel>();
    buildMultiSelectModelsMap(data.getDivisions, multiSelectMap);
    return {
      multiSelectModel: Array.from(multiSelectMap.values()),
    };
  }, [data]);

  return {
    multiSelectModel,
  };
};
