import { InitialImage } from './FileUploadIcon';
import { css } from '@emotion/react/macro';
import colors from 'styles/colors.module.scss';

export const fileUploadIconStyle = (
  IsLargePreview: boolean | undefined,
  isPreviewImage: boolean,
  initialImage: InitialImage | undefined,
  previewWidth: number | undefined,
  previewHeight: number | undefined,
  boxWidth: number,
  fileUploadBackground: string,
  fontSize: number,
  isLoading: boolean | undefined
) => css`
  width: ${IsLargePreview && (isPreviewImage || initialImage?.url)
    ? `${previewWidth}px`
    : `${boxWidth}px`};

  height: ${IsLargePreview && (isPreviewImage || initialImage?.url)
    ? `${previewHeight}px`
    : `${boxWidth}px`};

  background-color: ${fileUploadBackground};
  border-radius: ${IsLargePreview && (isPreviewImage || initialImage?.url)
    ? '0%'
    : '50%'};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;

  .camera-icon {
    color: ${colors.white};
    font-size: ${fontSize};
  }

  .circle-icon {
    border-radius: 50%;
    background-color: ${colors.white};
  }

  .image-box {
    .image {
      width: ${IsLargePreview ? `${previewWidth}px` : `${boxWidth}px`};
      height: ${IsLargePreview ? `${previewHeight}px` : `${boxWidth}px`};
      display: ${isLoading ? 'none' : 'block'};
      object-fit: cover;
      border-radius: ${IsLargePreview ? '0%' : '50%'};
    }

    .button-box {
      position: absolute;
      display: flex;
      top: 0px;
      right: 0px;
      background-color: #ffffff59;
      justify-content: space-evenly;
      width: 10%;

      .replace-icon {
        color: black;
      }
      .delete-icon {
        color: black;
      }
    }
  }
`;
