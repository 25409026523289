import { gql } from '@apollo/client';

export const GET_OFFERS_BY_IDS = gql`
  query GetOffersByIds($offerIds: [String!]!) {
    getOffersByIds(offerIds: $offerIds) {
      id
      name
      key
      description
    }
  }
`;
