import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Box } from '@material-ui/core';
import colors from 'styles/colors.module.scss';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { rootStyle, selectedStyle } from './ListItemLink.style';

export interface ListItemLinkProps {
  label?: React.ReactNode;
  to?: string;
  isExternalLink?: boolean;
  selected?: number;
  menuItemIndex?: number;
  itemPaddingLeft?: number;
  component?: React.ElementType<any>;
  hasSubMenu?: boolean;
  itemColor?: string;
  selectedItemColor?: string;
}

const ListItemLink: FC<
  ListItemLinkProps & Omit<ListItemProps, 'selected' | 'button'>
> = ({
  label,
  to,
  isExternalLink = false,
  selected,
  menuItemIndex,
  itemPaddingLeft = 3,
  component = 'li',
  hasSubMenu = false,
  itemColor,
  selectedItemColor,
  ...listItemProps
}) => {
  const renderLink = React.useMemo(
    () =>
      React.forwardRef<HTMLAnchorElement>((props, ref) => (
        <RouterLink ref={ref} to={to as string} {...props} />
      )),
    [to]
  );

  return (
    <ListItem
      data-testid="list-item"
      button
      component={to ? (isExternalLink ? 'a' : renderLink) : component}
      {...(isExternalLink && { href: to })}
      disableRipple
      css={[
        rootStyle(itemPaddingLeft, itemColor),
        selected === menuItemIndex && selectedStyle(selectedItemColor),
      ]}
      alignItems="center"
      {...listItemProps}
    >
      <Box display="flex" alignItems="center">
        <ListItemText primary={label} disableTypography />
        {hasSubMenu && (
          <Box pl={1.5} display="flex" data-testid="icon">
            <KeyboardArrowDownIcon htmlColor={colors.grey} />
          </Box>
        )}
      </Box>
    </ListItem>
  );
};

export default ListItemLink;
