import React, { useEffect, useMemo } from 'react';
import { List } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { GetDocumentsWithRelated_documents_documents } from 'app/schemaInterfaces/GetDocumentsWithRelated';
import { DocumentOrderType } from 'app/schemaInterfaces/globalTypes';
import useCurrentEntity from 'app/current-entity-context/useCurrentEntity';
import { DistributionRows } from './DistributionRows';
import useDocumentsQuery from 'modules/pro/documents/useDocumentsQuery';
import CircularProgressLoader from 'components/Loader/CircularProgressLoader/CircularProgressLoader';
import SnackBar, { SnackBarType } from 'components/SnackBar';
import { useTranslation } from 'react-i18next';
import DistributionMenu from './DistributionMenu';
import NoOrders from 'modules/followOrders/NoOrdres';
import {
  getCommitmentDistribution,
  getOrderDistribution,
} from './DistributionHelper';
import useDocumentsCommitmentsLazyQuery from 'queries/useDocumentCommitmentsLazyQuery';
import useOrderByDocumentsLazyQuery from 'queries/useOrderByDocumentsQuery';

export interface Distribution {
  orders: {
    id: string;
    code?: string;
    entityName: string;
    totalPrice: number;
    createdAt: string;
    addresses: {
      quantity: number | null;
      recommendedQuantity?: number | null;
      price: number | null;
      comment?: string | null;
      address: {
        name: string;
        firstAddress: string;
        secondAddress: string;
        deliveryDepartment: string;
        zipCode: string;
        city: string;
      };
      documentId: string;
    }[];
  }[];
  deliveryPointCount: number;
  quantity: number | null;
  recommendedQuantity?: number | null;
  document: {
    id: string;
    name: string;
    version: string;
    thumbnailUrl: string | null;
    isCustomizable: boolean;
  };
  documentIds: string[];
}

export type DocumentAndOtherFields = Partial<
  GetDocumentsWithRelated_documents_documents
> & {
  category: string | null;
  id: string;
  mediumType: string | null;
  name: string;
  numberOfPages: number | null;
  orderTypes: DocumentOrderType[];
  version: string | null;
};

const DistributionList = ({
  isCampaignModel,
}: {
  isCampaignModel: boolean;
}): JSX.Element => {
  const { id: campaignId } = useParams<{ id: string }>();
  const { currentEntity } = useCurrentEntity();
  const { t } = useTranslation();

  const [isOpenSnackBar, setIsOpenSnackBar] = React.useState(false);

  const { documents, documentsLoading } = useDocumentsQuery(
    {
      entityId: currentEntity?.id,
      campaignIds: [campaignId],
      isCustomization: false,
    },
    30
  );

  const {
    getDocumentsCommitments,
    data: documentsCommitments,
    loading: documentsCommitmentsLoading,
    error: documentsCommitmentsError,
    refetch: documentsCommitmentsRefetch,
  } = useDocumentsCommitmentsLazyQuery();

  const {
    getOrderByDocuments,
    data: orderData,
    loading: orderDataLoading,
    error: orderDataError,
    refetch: orderDataRefetch,
  } = useOrderByDocumentsLazyQuery();

  const options =
    documents?.map((document) => ({
      value: document.id,
      label: `${document.name} - ${document.version}`,
    })) ?? [];

  useEffect(() => {
    const documentIds: string[][] = documents
      ? documents.map((document) => [
          document.id,
          ...document.versionChildren.map((child) => child.id),
        ])
      : [];

    if (isCampaignModel) {
      getOrderByDocuments({
        variables: {
          documentIds,
        },
      });
    } else {
      getDocumentsCommitments({
        variables: {
          filter: { documentIds, isArchived: false },
        },
      });
    }
  }, [
    documents,
    getDocumentsCommitments,
    getOrderByDocuments,
    isCampaignModel,
  ]);
  const distributions = useMemo(
    () =>
      isCampaignModel
        ? getOrderDistribution(orderData)
        : getCommitmentDistribution(documentsCommitments),
    [documentsCommitments, orderData, isCampaignModel]
  );

  return (
    <div>
      {!isCampaignModel && documentsCommitmentsRefetch && (
        <DistributionMenu
          distributions={distributions}
          options={options}
          refetch={documentsCommitmentsRefetch}
        />
      )}
      {(isCampaignModel ? orderDataLoading : documentsCommitmentsLoading) ||
      documentsLoading ? (
        <CircularProgressLoader
          error={isCampaignModel ? orderDataError : documentsCommitmentsError}
        />
      ) : (
          isCampaignModel ? orderData?.length : documentsCommitments?.length
        ) ? (
        <List>
          <DistributionRows
            triggerAlert={setIsOpenSnackBar}
            distributions={distributions}
            refetch={
              isCampaignModel ? orderDataRefetch : documentsCommitmentsRefetch
            }
            isCampaignModel={isCampaignModel}
          />
        </List>
      ) : (
        <NoOrders
          message={t(
            `global.distribution_list.${
              isCampaignModel ? 'no_order' : 'no_commitment'
            }`
          )}
        />
      )}
      <SnackBar
        open={isOpenSnackBar}
        setOpen={setIsOpenSnackBar}
        message={t('global.distribution_list.export_document_message_info')}
        type={SnackBarType.INFO}
      />
    </div>
  );
};

export default DistributionList;
