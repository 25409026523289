import React, { FC } from 'react';
import { ButtonProps, Typography } from '@material-ui/core';
import colors from 'styles/colors.module.scss';
import ConfirmationDialog, {
  ConfirmationDialogProps,
} from '../ConfirmationDialog/ConfirmationDialog';
import { useTranslation } from 'react-i18next';
import { actions } from './OneButtonConfirmationDialog.utils';

export interface OneButtonConfirmationDialogProps
  extends Omit<ConfirmationDialogProps, 'actions'> {
  description: string;
  subDescription?: string;
  onClick: ButtonProps['onClick'];
  actionButtonsTitle?: ActionButtonsTitle;
}

export interface ActionButtonsTitle {
  reject: string;
  accept: string;
}

const OneButtonConfirmationDialog: FC<OneButtonConfirmationDialogProps> = ({
  isOpen,
  onClose,
  description,
  subDescription,
  title,
  onClick,
  actionButtonsTitle,
}) => {
  const { t } = useTranslation();

  return (
    <ConfirmationDialog
      data-testedid="dialog"
      isOpen={isOpen}
      onClose={onClose}
      actions={actions(onClose, t, onClick, actionButtonsTitle)}
      title={title}
    >
      <Typography variant="h5" style={{ color: colors.tooltip }}>
        {description}
      </Typography>
      {subDescription && (
        <Typography
          variant="body1"
          style={{ color: colors.tooltip, marginTop: '12px' }}
        >
          {subDescription}
        </Typography>
      )}
    </ConfirmationDialog>
  );
};

export default OneButtonConfirmationDialog;
