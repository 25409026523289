import { useLazyQuery } from '@apollo/client';
import { GET_DOCUMENTS_COMMITMENTS } from 'app/graphql/queries/commitments';
import { GetDocumentsCommitments } from 'app/schemaInterfaces/GetDocumentsCommitments';

const useDocumentsCommitmentsLazyQuery = () => {
  const [
    getDocumentsCommitments,
    { data, loading, error, refetch },
  ] = useLazyQuery<GetDocumentsCommitments>(GET_DOCUMENTS_COMMITMENTS, {
    fetchPolicy: 'no-cache',
  });
  return {
    getDocumentsCommitments,
    data: data?.documentsCommitments,
    loading,
    error,
    refetch,
  };
};

export default useDocumentsCommitmentsLazyQuery;
