import { useLazyQuery, useQuery } from '@apollo/client';
import useCurrentEntity from 'app/current-entity-context/useCurrentEntity';
import { ADDRESS_BLOCKS } from 'app/graphql/queries/addressBlocks';
import { GET_SUPPLY_PRODUCTS } from 'app/graphql/queries/supplyProducts';
import {
  AddressBlocks,
  AddressBlocksVariables,
  AddressBlocks_addressBlocks,
} from 'app/schemaInterfaces/AddressBlocks';
import {
  GetSupplyProducts,
  GetSupplyProductsVariables,
} from 'app/schemaInterfaces/GetSupplyProducts';
import { AscDescType } from 'app/schemaInterfaces/globalTypes';
import { OptionV3 } from 'components/UncontrolledMultipleArrayValueSelect';
import useSupportsQuery from 'queries/useSupportsQuery';
import React from 'react';
import { useEffect, useState } from 'react';
import { useAddressBlockMutate } from '../useAddressBlockMutate';
import { AddressBlockFormValues } from './AddressBlockContainer';
import { getFormats } from './AddressBlockContainer.utils';

export const useAddressBlockContainer = () => {
  const [clickRowIndex, setClickRowIndex] = useState<number | undefined>(
    undefined
  );

  const { updateAddressBlock } = useAddressBlockMutate({
    reportProgress: true,
  });

  const [formValues, setFormValues] = useState<AddressBlockFormValues>({
    thumbnailFile: undefined,
    hdPdf: undefined,
    name: '',
    supplyProductIds: [],
    supportId: '',
  });

  const [overRowIndex, setOverRowIndex] = useState<number | null>(null);
  const [isEditModeActive, setIsEditModeActive] = useState<boolean>(false);

  const { currentEntity } = useCurrentEntity();

  const { data: supportData } = useSupportsQuery();

  const [getProducts, { data: productsData }] = useLazyQuery<
    GetSupplyProducts,
    GetSupplyProductsVariables
  >(GET_SUPPLY_PRODUCTS);

  const supplyProductIds =
    supportData?.supports.map((support) => ({
      value: support.id,
      label: support.name,
    })) ?? [];

  const { error, data, loading } = useQuery<
    AddressBlocks,
    AddressBlocksVariables
  >(ADDRESS_BLOCKS, {
    variables: {
      // TODO: Handle the case when there is no sele
      entityId: currentEntity?.id as string,
      sort: { updatedAt: AscDescType.desc },
    },
  });

  const [formats, setFormats] = useState<OptionV3[]>([]);

  useEffect(() => {
    formValues.supportId &&
      getProducts({
        variables: {
          filters: {
            supportId: formValues.supportId,
          },
        },
      });

    const formats = productsData?.supplyProducts
      ? getFormats(productsData?.supplyProducts)
      : [];

    setFormats(formats);
  }, [getProducts, formValues.supportId, productsData?.supplyProducts]);

  const getRowId = React.useCallback(
    (row: AddressBlocks_addressBlocks) => row.id,
    []
  );

  useEffect(() => {
    if (!isEditModeActive) {
      setClickRowIndex(undefined);
    }
  }, [isEditModeActive]);

  const editMode = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: AddressBlocks_addressBlocks
  ) => {
    const selectedAddressBlock = data?.addressBlocks.find(
      ({ id }) => id === item.id
    );
    setFormValues({
      name: selectedAddressBlock?.name ?? '',
      supplyProductIds: selectedAddressBlock?.supplyProductIds ?? [],
      supportId: selectedAddressBlock?.supportId ?? '',
    });
    setIsEditModeActive(!isEditModeActive);
  };

  const submitForm = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: AddressBlocks_addressBlocks
  ) => {
    const addressBlock = data?.addressBlocks.find(
      (addressBlock) => addressBlock.id === item.id
    );
    if (addressBlock) {
      await updateAddressBlock({
        variables: {
          id: addressBlock.id,
          addressBlock: formValues,
        },
      });
    }
    clearChanges();
  };

  const clearChanges = () => {
    setIsEditModeActive(false);
  };

  const onRowOver = (rowIndex: number | null) => {
    setOverRowIndex(rowIndex);
  };

  const dataNormalized = (): AddressBlocks_addressBlocks[] =>
    data?.addressBlocks.map((addressBlock) => {
      return addressBlock;
    }) ?? [];

  return {
    loading,
    error,
    editMode,
    clickRowIndex,
    setClickRowIndex,
    isEditModeActive,
    formValues,
    setFormValues,
    submitForm,
    clearChanges,
    overRowIndex,
    supplyProductIds,
    formats,
    dataNormalized,
    getRowId,
    onRowOver,
  };
};
