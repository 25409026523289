import CurrentEntityContext from 'app/current-entity-context/CurrentEntityContext';
import { useContext } from 'react';

const useCurrentEntity = () => {
  const context = useContext(CurrentEntityContext);

  if (!context) {
    throw new Error('CurrentEntityContext must be provided');
  }

  return context;
};

export default useCurrentEntity;
