import { ListItem, ListItemProps } from '@material-ui/core';
import { FC, PropsWithChildren } from 'react';

interface ListItemButtonProps {
  isSelected: boolean;
  isDisabled: boolean;
}

const ListItemButton: FC<PropsWithChildren<
  ListItemButtonProps & Omit<ListItemProps, 'button'>
>> = ({ isSelected, isDisabled, children, ...listItemProps }) => {
  return (
    <ListItem
      data-testid="list-item"
      className={isSelected && !isDisabled ? 'selectedCampaign' : ''}
      disabled={isDisabled}
      {...listItemProps}
    >
      {children}
    </ListItem>
  );
};
export default ListItemButton;
