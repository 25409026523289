import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const filterPopoverStyle = (isDarkMode?: boolean) => css`
  .MuiButton-root {
    color: ${isDarkMode ? colors.white : colors.body};
    &:hover {
      color: ${isDarkMode ? colors.white : colors.primary};
      background-color: ${isDarkMode ? colors.body : colors.menuHover};
    }
  }
  .MuiButton-text {
    padding: 7px 14px;
  }
`;
