import { useApolloClient } from '@apollo/client';
import { useAuth0 } from 'app/auth/AuthContext';
import { useCurrentUser } from 'app/auth/UserContext';
import { CURRENT_ENTITY } from 'app/current-entity-context/CurrentEntityProvider';

export const useUserMenuToolbar = () => {
  const client = useApolloClient();

  const { logout } = useAuth0();
  const user = useCurrentUser();

  const handleLogout = async () => {
    localStorage.removeItem(CURRENT_ENTITY);
    logout();
    await client.resetStore();
  };
  return { user, handleLogout };
};
