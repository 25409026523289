import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const validationIconButtonStyle = css`
  background: ${colors.background};
  margin-left: 8px;
  width: 40px;
  height: 40px;
  padding: 10px;

  &:hover {
    background: ${colors.backgroundLight};
  }

  & .MuiSvgIcon-root {
    font-size: 20px;
  }
`;

export const validationIconButtonAcceptStyle = css`
  color: ${colors.success};
`;

export const validationIconButtonDeclineStyle = css`
  color: ${colors.orangeAcid};
`;
