import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PanelList from 'components/studio/PanelList';
import { AssetType } from 'app/schemaInterfaces/globalTypes';
import { AssetsPanelActionCreator } from 'modules/workflow/containers/Leftsidebar/AssetsPanel/reducer/actions';
import { AssetsPanelContext } from 'modules/workflow/containers/Leftsidebar/AssetsPanel/reducer/context';

export const AssetTypeSelectList = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useContext(AssetsPanelContext);

  const assetTypesOptions = [
    { value: AssetType.Visual, label: t('dam.asset_types.visual') },
    { value: AssetType.Logo, label: t('dam.asset_types.logo') },
    { value: AssetType.Picto, label: t('dam.asset_types.picto') },
    { value: AssetType.Media, label: t('dam.asset_types.media') },
    { value: AssetType.Charter, label: t('dam.asset_types.charter') },
  ];

  return (
    <PanelList
      options={assetTypesOptions}
      onClick={(event, value) =>
        dispatch?.(AssetsPanelActionCreator.updateAssetTypes(value))
      }
    />
  );
};
