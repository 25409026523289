import React from 'react';
import { DateStatus, PermissionKey } from 'app/schemaInterfaces/globalTypes';
import { useTranslation } from 'react-i18next';
import {
  AddCampaignButton,
  buttonsGroup,
  createCampaignActions,
} from 'modules/pro/campaigns/campaignsHelper';
import { Box, Button } from '@material-ui/core';
import CampaignCreation from 'modules/pro/campaigns/CampaignCreation/CampaignCreation';
import usePermissions from 'app/auth/usePermissions';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useRouter } from 'app/routes/useRouter';
import { ApolloError, ApolloQueryResult } from '@apollo/client';
import FilterButtons from 'components/ActionsButtons/FilterButtons';
import colors from 'styles/colors.module.scss';
import {
  GetAllCampaignsLean,
  GetAllCampaignsLeanVariables,
  GetAllCampaignsLean_getAllCampaigns_dateStatusCount,
} from 'app/schemaInterfaces/GetAllCampaignsLean';

interface ShopFiltersProps {
  error?: ApolloError;
  dateStatusCount?: GetAllCampaignsLean_getAllCampaigns_dateStatusCount;
  path: string;
  hasSeeAll?: boolean;
  isCampaignModel: boolean;
  hasFilters?: boolean;
  selectedDateStatus: DateStatus;
  setSelectedDateStatus: React.Dispatch<React.SetStateAction<DateStatus>>;
  refetch: (
    variables?: GetAllCampaignsLeanVariables
  ) => Promise<ApolloQueryResult<GetAllCampaignsLean>>;
}
const ShopFilters = ({
  error,
  dateStatusCount,
  path,
  hasSeeAll = true,
  isCampaignModel,
  hasFilters = true,
  selectedDateStatus,
  setSelectedDateStatus,
  refetch,
}: ShopFiltersProps) => {
  const { hasPermission } = usePermissions();
  const router = useRouter();

  const { t } = useTranslation();

  const isButtonSelected = (value: DateStatus) => value === selectedDateStatus;

  const styleButtonVariant = (value: DateStatus) =>
    isButtonSelected(value) ? 'contained' : 'outlined';
  const styleButtonTitle = (value: DateStatus) =>
    isButtonSelected(value) ? colors.white : colors.grey;

  return (
    <Box display="flex" justifyContent="space-between" pb={3}>
      <Box>
        {hasFilters && (
          <FilterButtons<DateStatus>
            buttons={buttonsGroup({
              dateStatusCount,
              t,
            })}
            styleButtonVariant={styleButtonVariant}
            styleButtonTitle={styleButtonTitle}
            setValue={setSelectedDateStatus}
          />
        )}
      </Box>
      <Box display="flex" justifyContent="flex-end">
        {hasPermission(
          PermissionKey.AccessModelCampaign &&
            PermissionKey.AccessNationalCampaign
        ) && (
          <CampaignCreation
            error={error}
            button={<AddCampaignButton />}
            actions={(handleOpenCampaignDialog) =>
              createCampaignActions({
                handleOpenCampaignDialog,
                t,
                hasPermission,
              })
            }
            isEditMode={false}
            isCampaignModel={isCampaignModel}
            refetch={refetch}
          />
        )}
        {hasSeeAll && (
          <Box marginLeft={3}>
            <Button
              variant="outlined"
              color="primary"
              endIcon={<ArrowForwardIcon fontSize="small" />}
              onClick={() => router.push(path)}
            >
              {t('shop.common.see_all')}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ShopFilters;
