import { useCurrentUser } from 'app/auth/UserContext';
import { ActionButton } from 'components/ActionsButtons/ActionButtons';
import { Item } from 'modules/workflow/containers/CatalogPreview/EditPlatformDefinitions';
import { useStudioStateContext } from 'modules/workflow/oneStudioContext';
import { useState } from 'react';

export interface UseCratdListProps<T extends Item> {
  deleteItem: ActionButton<T>['onClick'];
  handleOpenDialog: ActionButton<T>['onClick'];
}

export const useCardList = <T extends Item>({
  deleteItem,
  handleOpenDialog,
}: UseCratdListProps<T>) => {
  const [activeItemIndex, setActiveItemIndex] = useState<number | undefined>(
    undefined
  );
  const [clickItemIndex, setClickItemIndex] = useState<number | undefined>(
    undefined
  );
  const globalState = useStudioStateContext();

  const user = useCurrentUser();
  const userType = user.type;

  const hasActionButtons = deleteItem || handleOpenDialog;

  const isCustomization = Boolean(globalState.document?.isCustomization);
  const pageNumber = globalState.currentPage - 1;
  const currentPage = globalState.version.pages[pageNumber];

  let isCurrentPageEditable = true;

  if (
    isCustomization &&
    (!currentPage.isCustomizable || currentPage.validated)
  ) {
    isCurrentPageEditable = false;
  }

  return {
    hasActionButtons,
    isCurrentPageEditable,
    isCustomization,
    activeItemIndex,
    setActiveItemIndex,
    clickItemIndex,
    setClickItemIndex,
    userType,
  };
};
