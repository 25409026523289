import React from 'react';
import { css } from '@emotion/react';
import { GetDocumentsCommitments } from 'app/schemaInterfaces/GetDocumentsCommitments';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { DistributionRow } from './DistributionRow';
import { useTranslation } from 'react-i18next';
import { ApolloQueryResult, OperationVariables } from '@apollo/client';
import { Distribution } from './DistributionList';
import { GetOrdersByDocuments } from 'app/schemaInterfaces/GetOrdersByDocuments';

const style = css`
  margin-top: 24px;
  .titles {
    padding: 5px 20px;
  }
  .item-box {
    display: flex;
    text-align: center;
    div {
      display: flex;
      align-items: center;
    }
    .name {
      width: 10%;
    }
    .address {
      width: 25%;
    }
    .address-name {
      width: 20%;
    }
    .recommended-quantity {
      width: 14%;
      justify-content: center;
    }
    .real-quantity {
      width: 13%;
      justify-content: center;
    }
    .price {
      width: 10%;
      justify-content: center;
    }
  }
`;

interface DistributionRowProps {
  distributions: Distribution[];
  triggerAlert: React.Dispatch<React.SetStateAction<boolean>>;
  refetch:
    | ((
        variables?: Partial<OperationVariables> | undefined
      ) => Promise<
        ApolloQueryResult<GetDocumentsCommitments | GetOrdersByDocuments>
      >)
    | undefined;
  isCampaignModel: boolean;
}

export const DistributionRows = ({
  distributions,
  triggerAlert,
  refetch,
  isCampaignModel,
}: DistributionRowProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div css={style}>
      <div className="titles">
        <div className="item-box">
          <div className="name">
            {t('global.distribution_list.sale_point')}
            <ArrowDropDownIcon />
          </div>
          <div className="address-name">
            {t('global.distribution_list.delivery_point_name')}
            <ArrowDropDownIcon />
          </div>
          <div className="address">
            {t('global.distribution_list.delivery_point')}
            <ArrowDropDownIcon />
          </div>
          <div className="recommended-quantity">
            {' '}
            {t('global.distribution_list.recommended_quantity')}
            <ArrowDropDownIcon />
          </div>
          <div className="real-quantity">
            {' '}
            {t('global.distribution_list.real_quantity')}
            <ArrowDropDownIcon />
          </div>
          <div className="price">
            {t('global.distribution_list.price')}
            <ArrowDropDownIcon />
          </div>
        </div>
      </div>
      {distributions.map((distribution, index) => (
        <DistributionRow
          distribution={distribution}
          triggerAlert={triggerAlert}
          key={index}
          refetch={refetch}
          isCampaignModel={isCampaignModel}
        />
      ))}
    </div>
  );
};
