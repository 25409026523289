import { GetBasketDocuments_basket_basketDocuments_document_isDisabled } from 'app/schemaInterfaces/GetBasketDocuments';
import {
  DisabledDocumentErrors,
  PriceError,
} from 'app/schemaInterfaces/globalTypes';
import { TFunction } from 'i18next';

export const getDisabledTooltipMessage = (
  t: TFunction,
  isCampaignDisabled: boolean,
  isValidated: boolean,
  isDisabled: GetBasketDocuments_basket_basketDocuments_document_isDisabled,
  hasPriceError: boolean,
  isUpdatingPricing: boolean
) => {
  let tooltipMessage;
  switch (true) {
    case isCampaignDisabled:
    case isDisabled.reason === DisabledDocumentErrors.documentTerminated:
      tooltipMessage = t('sales.basket_page.order.order_terminated');
      break;
    case !isValidated:
      tooltipMessage = t('sales.basket_page.tooltip');
      break;
    case hasPriceError:
      tooltipMessage = t('sales.errors.invalid_prices');
      break;
    case isDisabled.reason === DisabledDocumentErrors.needFacebookAuth:
      tooltipMessage = t(
        'local.all_campaigns_page.tooltip_message_authenticate'
      );
      break;
    case isDisabled.reason === DisabledDocumentErrors.documentWithNoPrices:
      tooltipMessage = t('local.all_campaigns_page.no_price_for_document');
      break;
    case isDisabled.reason === DisabledDocumentErrors.documentCommited:
      tooltipMessage = t('local.campaign_page.summary.has_commitment');
      break;
    case isUpdatingPricing:
      tooltipMessage = t('sales.basket_page.updating_pricing_tooltip');
      break;
    default:
      tooltipMessage = '';
  }

  return tooltipMessage;
};

export const PriceI18nKeyErrorDictionary = new Map<string, string>([
  [PriceError.IncorrectPrices, 'shop.errors.incorrect_prices'],
]);
