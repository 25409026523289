import { Box, Chip, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import colors from 'styles/colors.module.scss';

export interface StatusSwitchProps {
  status: boolean;
  isEditable?: boolean;
  className?: string;
  onStatusChange?: (status: boolean) => void;
  enabledLabel?: string;
  disabledLabel?: string;
  error?: boolean;
  name?: string;
  isAlwaysActive?: boolean;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    chipContainer: {
      height: 20,
      '& > .MuiChip-label': {
        height: 20,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 2,
        paddingRight: 2,
      },
    },
    activeChipContainer: {
      color: colors.primary,
      '&, &:hover, &:focus': {
        backgroundColor: colors.activeChipContainerBg,
      },
    },
    inactiveChipContainer: {
      color: theme.palette.text.primary,
      '&, &:hover, &:focus': {
        backgroundColor: colors.disabled,
      },
    },
    childChip: {
      height: 16,
      fontSize: 12,
      '& > .MuiChip-label': {
        paddingLeft: 10,
        paddingRight: 10,
      },
    },
    activeChipSelected: {
      color: colors.white,
      '&, &:hover, &:focus': {
        backgroundColor: colors.primary,
      },
    },
    inactiveChipSelected: {
      '&, &:hover, &:focus': {
        backgroundColor: colors.inactiveChipSelectedBg,
      },
    },
    chipNotSelected: {
      color: 'inherit',
      '&, &:hover, &:focus': {
        backgroundColor: 'transparent',
      },
    },
  })
);

export const StatusSwitch = ({
  status,
  isEditable = true,
  onStatusChange,
  className,
  enabledLabel,
  disabledLabel,
  isAlwaysActive = false,
}: StatusSwitchProps): JSX.Element => {
  const classes = useStyles();

  const onChipClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (isEditable && onStatusChange) {
      onStatusChange(!status);
    }
  };

  const activeChip = (
    <Chip
      className={`${classes.childChip} ${
        status ? classes.activeChipSelected : classes.chipNotSelected
      }`}
      label={enabledLabel ?? 'Actif'}
      clickable={isEditable}
    />
  );
  const inactiveChip = (
    <Chip
      className={`${classes.childChip} ${
        status
          ? classes.chipNotSelected
          : isAlwaysActive
          ? classes.activeChipSelected
          : classes.inactiveChipSelected
      }`}
      label={disabledLabel ?? 'Inactif'}
      clickable={isEditable}
    />
  );

  return (
    <Box className={className}>
      <Chip
        className={`${classes.chipContainer} ${
          status || isAlwaysActive
            ? classes.activeChipContainer
            : classes.inactiveChipContainer
        }`}
        label={
          <>
            {activeChip}
            {inactiveChip}
          </>
        }
        clickable={isEditable}
        onClick={onChipClick}
        color="primary"
        data-testid="switch-button"
      />
    </Box>
  );
};
