import React from 'react';
//the libary does not have typescript
//@ts-ignore
import NavTabs, { Tabs, Content } from '@allpro/react-nav-tabs';
import { RouteComponentProps } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import colors from 'styles/colors.module.scss';
export interface TabTitle {
  label: React.ReactNode;
  path: string;
  component?: (props: RouteComponentProps) => React.ReactNode;
  icon?: React.ReactNode;
  total?: number;
}
interface FixedTabsProps {
  tabsTitle?: TabTitle[];
  tabsCssProps?: TabsCssProps;
}
interface TabsCssProps {
  color: string;
  backgroundColor?: string;
  indicatorDirection?: 'top' | 'bottom';
  padding?: string;
}
const useStyles = makeStyles<Theme, TabsCssProps>(
  createStyles({
    tabs: ({ color, backgroundColor, padding }) => ({
      '& .MuiTab-root.Mui-selected': {
        backgroundColor,
        color: colors.primary,
      },
      '& .MuiTab-textColorInherit': {
        color,
        opacity: ' 100%',
      },
      '& .MuiTab-root': {
        padding,
      },
    }),
    indicator: ({ indicatorDirection }) => ({
      [indicatorDirection as string]: 0,
    }),
  })
);
export const FixedTabs = ({
  tabsCssProps,
  tabsTitle,
}: FixedTabsProps): JSX.Element => {
  const {
    color = colors.disabled,
    backgroundColor = colors.white,
    indicatorDirection = 'top',
    padding = '12px',
  } = tabsCssProps ?? {};
  const classes = useStyles({
    color,
    backgroundColor,
    indicatorDirection,
    padding,
  });

  return (
    <NavTabs tabs={tabsTitle}>
      <Tabs
        classes={{ root: classes.tabs, indicator: classes.indicator }}
        indicatorColor="primary"
        aria-label="full width fixed tabs"
      />
      <Content />
    </NavTabs>
  );
};
export default FixedTabs;
