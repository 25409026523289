import { gql } from '@apollo/client';

export const ORDER_STATUS_FRAGMENT = gql`
  fragment OrderStatus on Order {
    followStatus
    onepacUserId
    onepacUserName
    statusComment
    statusUpdatedAt
  }
`;

export const ORDER_ITEM_FRAGMENT = gql`
  fragment OrderItem on OrderItem {
    id
    productId
    productName
    quantity
    sellingPriceHt
    transportSellingPriceHt
    status
    onePacDocumentId
    document {
      category
    }
    clientNetworkLink
    publicationDate
    expectedDeliveryDate
    product {
      support {
        id
        onePacConfig {
          categoryIcon
        }
      }
    }
    shippingAddress {
      name
      city
      country
      firstAddress
      deliveryDepartment
      name
      postBox
      secondAddress
      thirdAddress
      zipCode
    }
  }
`;

export const CREATE_ORDER = gql`
  mutation CreateOrder($order: CreateOrderInput!, $entityId: String) {
    createOrder(order: $order, entityId: $entityId) {
      isOrderWorkflowActive
      order {
        id
        status
        followStatus
        createdAt
        updatedAt
        source
        comment
        clientName
        transportSellingPriceHt
        productSellingPriceHt
        sellingPriceHt
        createdAt
        status
        fileReference {
          clientReference
          operationName
        }
        billingAddress {
          name
          city
          country
          firstAddress
          deliveryDepartment
          name
          postBox
          secondAddress
          thirdAddress
          zipCode
        }
        childrenOrder {
          id
          clientName
          billingAddress {
            name
            city
            country
            firstAddress
            deliveryDepartment
            name
            postBox
            secondAddress
            thirdAddress
            zipCode
          }
          orderItem {
            id
            productName
            quantity
          }
          invoices {
            createdAt
            id
            sendAt
            status
            validateAt
            url
          }
        }
        orderItem {
          id
          productName
          quantity
        }
        invoices {
          createdAt
          validateAt
          sendAt
          status
        }
      }
    }
  }
`;

export const ACTIVE_ORDER_WORKFLOWS_FRAGMENT = gql`
  fragment ActiveOrderWorkflows on Order {
    activeOrderWorkflows {
      config {
        supportCategory
        documentOrderTypes
        documentOptions
        priceConditioning {
          operator
          value
        }
        validators {
          mainUser {
            id
          }
          fallbackUsers {
            id
          }
          optional
        }
        id
      }
      matchedItems
    }
  }
`;

export const GET_ORDERS = gql`
  query GetOrders(
    $filter: OrderFilterInput
    $entityId: String
    $sort: OrderSortInput
  ) {
    getOrders(filter: $filter, entityId: $entityId, sort: $sort) {
      id
      status
      ...OrderStatus
      ...ActiveOrderWorkflows
      createdAt
      updatedAt
      source
      sellingPriceHt
      clientId
      version
      fileReference {
        clientReference
        operationName
      }
      clientName
      orderItem {
        ...OrderItem
      }
      billingAddress {
        name
        city
        country
        firstAddress
        deliveryDepartment
        name
        postBox
        secondAddress
        thirdAddress
        zipCode
      }
    }
  }
  ${ORDER_ITEM_FRAGMENT}
  ${ACTIVE_ORDER_WORKFLOWS_FRAGMENT}
  ${ORDER_STATUS_FRAGMENT}
`;

export const GET_ORDER_BY_ID = gql`
  query GetOrderById($id: String!, $entityId: String!) {
    getOrderById(id: $id, entityId: $entityId) {
      id
      status
      ...ActiveOrderWorkflows
      ...OrderStatus
      updatedAt
      version
      source
      comment
      clientId
      clientName
      sellingPriceHt
      transportSellingPriceHt
      createdAt
      status
      fileReference {
        clientReference
        operationName
      }
      orderItem {
        ...OrderItem
      }
      billingAddress {
        name
        city
        country
        firstAddress
        deliveryDepartment
        name
        postBox
        secondAddress
        thirdAddress
        zipCode
      }
      childrenOrder {
        id
        clientName
        sellingPriceHt
        billingAddress {
          name
          city
          country
          firstAddress
          deliveryDepartment
          name
          postBox
          secondAddress
          thirdAddress
          zipCode
        }
        orderItem {
          ...OrderItem
        }
        invoices {
          createdAt
          id
          sendAt
          status
          validateAt
          url
          originalInvoiceName
        }
      }
      orderItem {
        ...OrderItem
      }
      invoices {
        id
        createdAt
        validateAt
        sendAt
        status
      }
    }
  }
  ${ORDER_STATUS_FRAGMENT}
  ${ORDER_ITEM_FRAGMENT}
  ${ACTIVE_ORDER_WORKFLOWS_FRAGMENT}
`;

export const GET_ORDERS_BY_DOCUMENTS = gql`
  query GetOrdersByDocuments($documentIds: [[String!]!]!) {
    getOrdersByDocuments(documentIds: $documentIds) {
      quantity
      deliveryPointCount
      documentIds
      document {
        id
        name
        version
        thumbnailUrl
        isCustomizable
      }
      orders {
        id
        entityName
        totalPrice
        addresses {
          documentId
          quantity
          address {
            name
            firstAddress
            secondAddress
            zipCode
            deliveryDepartment
            city
          }
          price
          comment
        }
        createdAt
      }
    }
  }
`;

export const GET_ORDERS_DELIVERIES = gql`
  query GetOrderDeliveries($id: String) {
    orderDeliveries(id: $id) {
      result
      error
      deliveryPoints {
        id
        status
        name
        code
        address1
        address2
        zipCode
        country
        town
        longitude
        latitude
        status
        packages {
          id
          deliveryDate
          deliveryProofLink
          code
          carrier
          receiver
          numberOfPackages
          weight
          status
          message
        }
        controls {
          contactName
          contactPhone
          contactEmail
          eventClosed
          eventComment
          eventHistoryHtml
          answerDate
          receptions {
            articleId
            name
            receptionDetails {
              shop
              version
              quantity
              conformity
              receptionDone
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_ORDER_STATUS = gql`
  mutation UpdateOrderStatus(
    $entityId: String!
    $values: UpdateOrderStatusInput!
  ) {
    updateOrderStatus(entityId: $entityId, values: $values) {
      id
      ...OrderStatus
    }
  }
  ${ORDER_STATUS_FRAGMENT}
`;
