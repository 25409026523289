import {
  StudioStates,
  ZoneProps,
  Asset,
  PersonalizationItem,
  TypographyIdentities,
  Regrouping,
} from 'modules/workflow/oneStudioContext';
import { StudioContextActionType } from 'modules/workflow/reducers/studioActions';
import { AcceptedActions } from 'modules/workflow/reducers/actionsInterfaces';
import { IProductTemplate } from 'modules/workflow/Clients/ToutFaire/ProductItem';
import { exhaustiveCheck } from 'app/utils/common';
import {
  defaultComponents,
  isItemDraggedAsset,
  isItemDraggedProduct,
  isItemPersonalizationProduct,
} from 'modules/workflow/containers/CatalogPreview/EditPlatformDefinitions';
import colors from 'styles/colors.module.scss';

export const studioContextReducer = (
  state: StudioStates,
  action: AcceptedActions
): StudioStates => {
  let newState: StudioStates = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case StudioContextActionType.SET_COMMENT_MODE: {
      newState.commentMode = action.payload.commentMode;
      break;
    }
    case StudioContextActionType.TOGGLE_TEXT_COLOR: {
      const {
        typographyItemId,
        zoneIndex,
        pageNumber,
        productId,
      } = action.payload;

      const productIndex = newState.version.pages[pageNumber].pageTemplate[
        zoneIndex
      ].product?.findIndex((product) => product.id === productId);

      const typographyItemIndex =
        productIndex !== undefined &&
        newState.version.pages[pageNumber].pageTemplate[zoneIndex].product?.[
          productIndex
        ].typographyIdentities?.findIndex(
          (typographyIdentity) => typographyIdentity.id === typographyItemId
        );

      // Improve readibility of a text: should be white on a black background, should be black on a white background
      // on doubleClick event:
      // - if typographyItem is black, change it to white.
      // - if it's white, change it to black
      const typographyIdentities = ((state.version.pages[pageNumber]
        .pageTemplate[zoneIndex].product as IProductTemplate[])[
        productIndex as number
      ].typographyIdentities as TypographyIdentities[]).map(
        (typographyIdentity) =>
          typographyIdentity.id === typographyItemId
            ? {
                ...typographyIdentity,
                color:
                  typographyIdentity.color === colors.black
                    ? colors.white
                    : colors.black,
              }
            : typographyIdentity
      );

      if (
        productIndex !== undefined &&
        typographyItemIndex !== undefined &&
        newState.version.pages[pageNumber].pageTemplate[zoneIndex].product
      ) {
        ((newState.version.pages[pageNumber].pageTemplate[zoneIndex]
          .product as IProductTemplate[])[productIndex]
          .typographyIdentities as TypographyIdentities[]) = typographyIdentities;
      }

      break;
    }
    case StudioContextActionType.DELETE_ASSET: {
      const {
        item: { key: assetKey },
        pageNumber,
      } = action.payload;

      newState.version.pages[pageNumber].pageTemplate = newState.version.pages[
        pageNumber
      ].pageTemplate.map((zone) => {
        const filteredAssets = zone.assets?.filter(
          (asset) => asset.key !== assetKey
        );
        const filteredPersonalizationItems = zone.personalizationItems?.map(
          (personalizationItem) => {
            if (
              personalizationItem.content &&
              isItemDraggedAsset(personalizationItem.content) &&
              personalizationItem.content?.key === assetKey
            ) {
              const {
                key,
                type,
                subType,
                position,
                dimensions,
                userType,
              } = personalizationItem;
              return { key, type, subType, position, dimensions, userType };
            }
            return personalizationItem;
          }
        );
        return {
          ...zone,
          assets: filteredAssets,
          personalizationItems: filteredPersonalizationItems,
        };
      });

      break;
    }
    case StudioContextActionType.DELETE_BACKGROUND_ASSET: {
      const {
        item: { key: backgroundKey },

        pageNumber,
      } = action.payload;

      if (
        newState.version.pages[pageNumber].backgroundColor?.key ===
        backgroundKey
      ) {
        newState.version.pages[pageNumber].backgroundColor = undefined;
      } else {
        newState.version.pages[
          pageNumber
        ].pageTemplate = newState.version.pages[pageNumber].pageTemplate.map(
          (zone) => {
            return zone.background?.key === backgroundKey
              ? {
                  ...zone,

                  background: undefined,
                }
              : zone;
          }
        );
      }

      break;
    }
    case StudioContextActionType.DELETE_PERSONALIZATION_ZONE: {
      const {
        item: { key: personalizationKey },

        pageNumber,
      } = action.payload;

      newState.version.pages[pageNumber].pageTemplate.forEach((zone, index) => {
        newState.version.pages[pageNumber].pageTemplate[
          index
        ].personalizationItems = zone.personalizationItems?.filter(
          (personalizationItem) =>
            personalizationItem.key !== personalizationKey
        );
      });

      break;
    }
    case StudioContextActionType.DELETE_PRODUCT_BY_ID: {
      const {
        item: { id: productId },
        pageNumber,
      } = action.payload;

      newState.version.pages[pageNumber].pageTemplate = newState.version.pages[
        pageNumber
      ].pageTemplate.map((zone) => {
        const filteredProducts = zone.product?.filter(
          (product) => product.id !== productId
        );
        const filteredPersonalizationItems = zone.personalizationItems?.map(
          (personalizationItem) => {
            if (personalizationItem.content?.id === productId) {
              const {
                key,
                type,
                subType,
                position,
                dimensions,
                userType,
              } = personalizationItem;
              return { key, type, subType, position, dimensions, userType };
            }
            return personalizationItem;
          }
        );
        return {
          ...zone,
          product: filteredProducts,
          personalizationItems: filteredPersonalizationItems,
        };
      });

      break;
    }
    case StudioContextActionType.SET_PAGE_TEMPLATE: {
      newState.version.pages[action.payload.pageNumber] = {
        ...state.version.pages[action.payload.pageNumber],
        pageTemplate: action.payload.pageTemplate,
        incompleteProducts: 0,
        unseenComments: 0,
      };
      break;
    }
    case StudioContextActionType.SET_SECTOR_TO_ZONE: {
      newState.version.pages[action.payload.pageNumber].pageTemplate[
        action.payload.zoneIndex
      ] = {
        ...state.version.pages[action.payload.pageNumber].pageTemplate[
          action.payload.zoneIndex
        ],
        sector: action.payload.sector,
      };
      break;
    }
    case StudioContextActionType.SET_VALID_PAGE: {
      newState.version.pages[action.payload.pageNumber] = {
        ...state.version.pages[action.payload.pageNumber],
        validated: action.payload.isValidated,
      };

      break;
    }
    case StudioContextActionType.SET_PAGE_NUMBER:
      newState = { ...newState, currentPage: action.payload.pageNumber };
      break;
    case StudioContextActionType.UNDO: {
      const { historyStep } = state.undoRedo;
      if (historyStep >= 0) {
        const currentCatalog = state.version;
        newState.version = state.undoRedo.catalogHistory[historyStep];
        newState.undoRedo.catalogHistory[historyStep] = currentCatalog;
        newState.undoRedo.historyStep--;
      }
      break;
    }
    case StudioContextActionType.REDO: {
      const historyStep = state.undoRedo.historyStep + 1;
      if (historyStep < state.undoRedo.catalogHistory.length) {
        const currentCatalog = state.version;
        newState.version = state.undoRedo.catalogHistory[historyStep];
        newState.undoRedo.catalogHistory[historyStep] = currentCatalog;
        newState.undoRedo.historyStep++;
      }
      break;
    }
    case StudioContextActionType.UPDATE_UNDO_REDO_HISTORY: {
      newState.undoRedo.catalogHistory = state.undoRedo.catalogHistory.slice(
        0,
        state.undoRedo.historyStep + 1
      );
      newState.undoRedo.catalogHistory = [
        ...newState.undoRedo.catalogHistory,
        { ...state.version },
      ];
      newState.undoRedo.historyStep =
        newState.undoRedo.catalogHistory.length - 1;
      break;
    }
    case StudioContextActionType.SET_DRAGGED_ITEM: {
      const draggedItem = {
        ...action.payload.draggedItem,
        subType: action.payload.draggedItem.subType ?? '',
      };
      newState.draggedItem = draggedItem;
      break;
    }
    case StudioContextActionType.SET_BACKGROUND_IMAGE: {
      // which page
      newState.version.pages[action.payload.pageNumber].pageTemplate[
        // which zone
        action.payload.zoneIndex
      ] = {
        ...state.version.pages[action.payload.pageNumber].pageTemplate[
          action.payload.zoneIndex
        ],
        background: action.payload.background,
      };
      break;
    }
    case StudioContextActionType.SET_BACKGROUND_COLOR: {
      // which page
      newState.version.pages[action.payload.pageNumber] = {
        // spread the old data if exists, page templates
        ...state.version.pages[action.payload.pageNumber],
        // add the background color
        backgroundColor: action.payload.backgroundColor,
      };
      break;
    }
    case StudioContextActionType.SET_PERSONALIZATION_ITEMS: {
      // which page
      newState.version.pages[action.payload.pageNumber].pageTemplate[
        // which zone
        action.payload.zoneIndex
      ] = {
        ...state.version.pages[action.payload.pageNumber].pageTemplate[
          action.payload.zoneIndex
        ],
        personalizationItems: action.payload.personalizationItems,
      };
      break;
    }
    case StudioContextActionType.SET_PAGES_CUSTOMIZATION: {
      // which page
      newState.version.pages[action.payload.indexPage].isCustomizable =
        action.payload.isCustomizable;
      break;
    }
    case StudioContextActionType.SET_PRODUCT: {
      let templates: ZoneProps =
        newState.version.pages[action.payload.pageNumber].pageTemplate[
          action.payload.zoneIndex
        ];

      const product: IProductTemplate = {
        ...action.payload.product,
        components: defaultComponents,
      };

      const newTemplates: ZoneProps = {
        ...templates,
        product: [...(templates.product ?? []), product],
      };

      newState.version.pages[action.payload.pageNumber].pageTemplate[
        action.payload.zoneIndex
      ] = newTemplates;
      break;
    }

    case StudioContextActionType.REPLACE_PRODUCT: {
      const { itemToDelete, itemToAdd } = action.payload;

      newState.version.pages[itemToDelete.pageNumber].pageTemplate.forEach(
        (zone, zoneIndex) => {
          zone.personalizationItems?.forEach((personalizationItem, index) => {
            if (
              personalizationItem.content?.id === itemToDelete.id &&
              isItemPersonalizationProduct(personalizationItem.content)
            ) {
              (newState.version.pages[itemToDelete.pageNumber].pageTemplate[
                zoneIndex
              ].personalizationItems as PersonalizationItem[])[index] = {
                ...personalizationItem,
                content: {
                  ...itemToAdd,
                  components: defaultComponents,
                  position: personalizationItem?.content.position ?? {
                    x: 0,
                    y: 0,
                  },
                  size: personalizationItem?.content.size,
                },
              };
            }
          });

          zone.product?.forEach((product, index) => {
            if (product.id === itemToDelete.id) {
              (newState.version.pages[itemToDelete.pageNumber].pageTemplate[
                zoneIndex
              ].product as IProductTemplate[])[index] = {
                ...itemToAdd,
                components: defaultComponents,
                position: product.position,
                size: product.size,
              };
            }
          });
        }
      );
      break;
    }

    case StudioContextActionType.SET_PAGES_COMPARISON_STATUS: {
      newState.isTwoPagesView = action.payload.isTwoPagesView;
      break;
    }
    case StudioContextActionType.TOGGLE_PANNING_FUNCTIONALITY: {
      newState = { ...state, panningEnabled: action.payload.panningEnabled };
      break;
    }
    case StudioContextActionType.INSERT_ASSET: {
      let assets =
        state.version.pages[action.payload.pageNumber].pageTemplate[
          action.payload.zoneIndex
        ].assets;
      newState.version.pages[action.payload.pageNumber].pageTemplate[
        action.payload.zoneIndex
      ].assets = [...(assets ? assets : []), action.payload.newAsset];
      break;
    }
    case StudioContextActionType.UPDATE_ASSET: {
      if (
        newState.version.pages[action.payload.pageNumber].pageTemplate[
          action.payload.zoneIndex
        ].assets &&
        newState.version.pages[action.payload.pageNumber].pageTemplate[
          action.payload.zoneIndex
        ].assets?.length !== 0
      ) {
        (newState.version.pages[action.payload.pageNumber].pageTemplate[
          action.payload.zoneIndex
        ].assets as Asset[])[action.payload.assetIndex] = {
          ...action.payload.newAsset,
        };
      }
      break;
    }
    case StudioContextActionType.SET_UPLOADED_BACKGROUND_IMAGE_URL: {
      newState.uploadedBackgroundImage = {
        URL: action.payload.uploadedBackgroundImageURL,
        title: action.payload.uploadedBackgroundImageTitle,
      };
      break;
    }
    case StudioContextActionType.DELETE_ALL_ITEMS: {
      const template = state.version.pages[
        action.payload.pageNumber
      ].pageTemplate.map((zone) => {
        return {
          x: zone.x,
          y: zone.y,
          width: zone.width,
          height: zone.height,
          sector: zone.sector,
          product: zone.product,
        };
      });
      newState.version.pages[action.payload.pageNumber].pageTemplate = [
        ...template,
      ];
      break;
    }

    case StudioContextActionType.SET_DOCUMENT: {
      newState.document = { ...state.document, ...action.payload.document };
      break;
    }

    case StudioContextActionType.SET_VERSION: {
      newState.version = { ...state.version, ...action.payload.version };
      break;
    }

    case StudioContextActionType.UPDATE_PRODUCT_POSITION: {
      const template =
        newState.version.pages[action.payload.pageNumber].pageTemplate[
          action.payload.zoneIndex
        ].product;

      const productIndex = template?.findIndex(
        (product) => product.id === action.payload.productId
      );

      if (
        productIndex !== undefined &&
        newState.version.pages[action.payload.pageNumber].pageTemplate[
          action.payload.zoneIndex
        ].product
      ) {
        (newState.version.pages[action.payload.pageNumber].pageTemplate[
          action.payload.zoneIndex
        ].product as IProductTemplate[])[productIndex].position =
          action.payload.position;
      }
      break;
    }
    case StudioContextActionType.UPDATE_PRODUCT_SIZE: {
      const template =
        newState.version.pages[action.payload.pageNumber].pageTemplate[
          action.payload.zoneIndex
        ].product;

      const productIndex = template?.findIndex(
        (product) => product.id === action.payload.productId
      );

      if (
        productIndex !== undefined &&
        newState.version.pages[action.payload.pageNumber].pageTemplate[
          action.payload.zoneIndex
        ].product
      ) {
        (template as IProductTemplate[])[productIndex].size = {
          width:
            (template as IProductTemplate[])[productIndex].size.width +
            action.payload.delta.width,
          height:
            (template as IProductTemplate[])[productIndex].size.height +
            action.payload.delta.height,
        };
      }

      break;
    }
    case StudioContextActionType.SET_ZONE_PRODUCT_NUMBER: {
      newState.version.pages[action.payload.pageNumber].pageTemplate[
        action.payload.zoneIndex
      ].numberOfProducts = action.payload.numberOfProducts;
      break;
    }
    case StudioContextActionType.SET_ZONE_TURNOVER: {
      newState.version.pages[action.payload.pageNumber].pageTemplate[
        action.payload.zoneIndex
      ].turnover = action.payload.turnover;
      break;
    }
    case StudioContextActionType.SET_SELECTED_ZONE: {
      newState.selectedZone = action.payload.selectedZone;
      break;
    }
    case StudioContextActionType.UPDATE_ASSET_POSITION: {
      const template =
        newState.version.pages[action.payload.pageNumber].pageTemplate[
          action.payload.zoneIndex
        ].product;

      const productIndex = template?.findIndex(
        (product) => product.id === action.payload.productId
      );

      if (
        !action.payload.isRegrouping &&
        productIndex !== undefined &&
        newState.version.pages[action.payload.pageNumber].pageTemplate[
          action.payload.zoneIndex
        ].product &&
        action.payload.visualId
      ) {
        ((template as IProductTemplate[])[productIndex]?.visualIdentities?.find(
          ({ id, fieldName }) =>
            id === action.payload.visualId &&
            fieldName === action.payload.fieldName
        ) as Asset).position = action.payload.position;
      }

      if (
        action.payload.isRegrouping &&
        productIndex !== undefined &&
        newState.version.pages[action.payload.pageNumber].pageTemplate[
          action.payload.zoneIndex
        ].product &&
        action.payload.visualId
      ) {
        ((template as IProductTemplate[])[
          productIndex as number
        ]?.regrouping?.find(
          ({ id }) => id === action.payload.visualId
        ) as Regrouping).position = action.payload.position;
      }

      break;
    }
    case StudioContextActionType.UPDATE_PERSONALIZATION_PRODUCT_ASSET_POSITION: {
      const personalizationItems: PersonalizationItem[] | undefined =
        newState.version.pages[action.payload.pageNumber].pageTemplate[
          action.payload.zoneIndex
        ].personalizationItems;
      if (personalizationItems) {
        const newPersonalizationItems = personalizationItems.map(
          (personalizationItem) => {
            if (
              personalizationItem.key === action.payload.productId &&
              personalizationItem.content &&
              isItemDraggedProduct(personalizationItem.content)
            ) {
              const newVisualIdentities = personalizationItem.content.visualIdentities?.map(
                (visual: Asset) => {
                  if (
                    visual.id === action.payload.visualId &&
                    visual.fieldName === action.payload.fieldName
                  ) {
                    visual.position = action.payload.position;
                  }
                  return visual;
                }
              );

              const newRegrouping = (personalizationItem.content as IProductTemplate).regrouping?.map(
                (group) => {
                  if (group.id === action.payload.visualId) {
                    group.position = action.payload.position;
                  }
                  return group;
                }
              );
              const newPersonalizationItem = {
                ...personalizationItem,
                content: {
                  ...personalizationItem.content,
                  visualIdentities: newVisualIdentities,
                  regrouping: action.payload.isRegrouping
                    ? newRegrouping
                    : personalizationItem.content.regrouping,
                } as IProductTemplate,
              };
              return newPersonalizationItem;
            } else {
              return personalizationItem;
            }
          }
        );

        newState.version.pages[action.payload.pageNumber].pageTemplate[
          action.payload.zoneIndex
        ].personalizationItems = newPersonalizationItems;
      }

      break;
    }
    case StudioContextActionType.UPDATE_ASSET_SIZE: {
      const template =
        newState.version.pages[action.payload.pageNumber].pageTemplate[
          action.payload.zoneIndex
        ].product;

      const productIndex = template?.findIndex(
        (product) => product.id === action.payload.productId
      );

      if (
        !action.payload.isRegrouping &&
        productIndex !== undefined &&
        newState.version.pages[action.payload.pageNumber].pageTemplate[
          action.payload.zoneIndex
        ].product &&
        action.payload.visualId
      ) {
        ((template as IProductTemplate[])[productIndex]?.visualIdentities?.find(
          ({ id }) => id === action.payload.visualId
        ) as Asset).size = action.payload.size;
      }

      if (
        action.payload.isRegrouping &&
        productIndex !== undefined &&
        newState.version.pages[action.payload.pageNumber].pageTemplate[
          action.payload.zoneIndex
        ].product &&
        action.payload.visualId
      ) {
        const productRegrouping = (template as IProductTemplate[])[
          productIndex as number
        ]?.regrouping;
        (productRegrouping?.find(
          ({ id }) => id === action.payload.visualId
        ) as Regrouping).size = action.payload.size;

        const regroupingElementsMaxWidth =
          ((template as IProductTemplate[])[productIndex as number]?.size
            .width -
            ((productRegrouping?.length as number) - 2) *
              (action.payload?.regroupingSpacing as number)) /
          (productRegrouping?.length as number);

        if (action.payload.size.width > regroupingElementsMaxWidth) {
          (productRegrouping?.find(
            ({ id }) => id === action.payload.visualId
          ) as Regrouping).size.width =
            regroupingElementsMaxWidth + (regroupingElementsMaxWidth * 1) / 3;
        }
      }
      break;
    }
    case StudioContextActionType.SET_SCALE_VALUE:
      newState = { ...newState, scaleValue: action.payload.scaleValue };
      break;
    case StudioContextActionType.SET_TAB_VALUE:
      newState = { ...newState, tabValue: action.payload.tabValue };
      break;
    case StudioContextActionType.SET_EDITABLE:
      newState = { ...newState, isEditable: action.payload.isEditable };
      break;
    case StudioContextActionType.SET_DIGITAL:
      newState = { ...newState, isDigital: action.payload.isDigital };
      break;
    case StudioContextActionType.SET_DIGITAL_CONTENT:
      newState = {
        ...newState,
        version: {
          ...newState.version,
          digitalContent: {
            ...newState.version.digitalContent,
            message: action.payload.message,
          },
        },
      };
      break;
    case StudioContextActionType.TOGGLE_DISPLAY_PERSONALIZATION:
      newState = {
        ...newState,
      };
      break;
    case StudioContextActionType.UPDATE_PERSONALIZATION_PRODUCT_ASSET_SIZE: {
      let personalizationItems: PersonalizationItem[] | undefined =
        newState.version.pages[action.payload.pageNumber].pageTemplate[
          action.payload.zoneIndex
        ].personalizationItems;

      if (personalizationItems) {
        const newPersonalizationItems = personalizationItems.map(
          (personalizationItem) => {
            if (
              personalizationItem.key === action.payload.productId &&
              personalizationItem.content &&
              isItemDraggedProduct(personalizationItem.content)
            ) {
              const newVisualIdentities = (personalizationItem.content as IProductTemplate).visualIdentities?.map(
                (visual: Asset) => {
                  if (visual.id === action.payload.visualId) {
                    visual.size = action.payload.size;
                  }
                  return visual;
                }
              );

              const newRegrouping = (personalizationItem.content as IProductTemplate).regrouping?.map(
                (group) => {
                  if (group.id === action.payload.visualId) {
                    group.size = action.payload.size;
                  }
                  return group;
                }
              );

              const newPersonalizationItem = {
                ...personalizationItem,
                content: {
                  ...personalizationItem.content,
                  visualIdentities: newVisualIdentities,
                  regrouping: action.payload.isRegrouping
                    ? newRegrouping
                    : personalizationItem.content.regrouping,
                },
              } as PersonalizationItem;
              return newPersonalizationItem;
            } else {
              return personalizationItem;
            }
          }
        );

        newState.version.pages[action.payload.pageNumber].pageTemplate[
          action.payload.zoneIndex
        ].personalizationItems = newPersonalizationItems;
      }
      break;
    }
    default:
      exhaustiveCheck(action);
  }
  console.log({ currentState: newState, type: action.type });

  return newState;
};
