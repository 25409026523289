import React, { FC, ReactNode } from 'react';
import { Step, StepLabel } from '@material-ui/core';
import {
  stepDateLabelStyle,
  stepLabelErrorStyle,
  stepLabelSubtitle,
  stepLabelSuccessStyle,
  stepLabelWarningStyle,
  stepStyle,
} from './FollowOrderStep.style';

import StatusIcon from 'components/StatusIcon/StatusIcon';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export interface FollowOrderStepData {
  label: string;
  sublabel?: string;
  date?: string | null;
  variant?: 'warning' | 'error' | 'success';
  icon?: ReactNode;
}

export interface FollowOrderStepProps {
  stepData: FollowOrderStepData;
  active?: boolean;
  completed?: boolean;
}

const FollowOrderStep: FC<FollowOrderStepProps> = ({
  stepData: { label, date, sublabel, variant, icon },
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <Step
      css={stepStyle}
      data-testid={`${label}-${
        variant
          ? variant
          : props.completed
          ? 'completed'
          : props.active
          ? 'active'
          : 'disabled'
      }`}
      {...props}
    >
      <div css={stepDateLabelStyle}>
        {date ? moment(date).format('DD/MM/YYYY') : ''}
      </div>
      <StepLabel
        icon={
          <StatusIcon
            variant={
              variant
                ? variant
                : !props.active && !props.completed
                ? 'disabled'
                : props.completed
                ? 'completed'
                : undefined
            }
            icon={icon}
          />
        }
        css={[
          variant === 'warning' && stepLabelWarningStyle,
          variant === 'error' && stepLabelErrorStyle,
          variant === 'success' && stepLabelSuccessStyle,
        ]}
      >
        {t(label)}
        <div css={stepLabelSubtitle}>{sublabel && t(sublabel)}</div>
      </StepLabel>
    </Step>
  );
};

export default FollowOrderStep;
