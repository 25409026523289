import React, { useEffect, useState } from 'react';
import colors from 'styles/colors.module.scss';
import { css } from '@emotion/react';
import { Typography } from '@material-ui/core';
import {
  useStudioDispatchContext,
  useStudioStateContext,
} from 'modules/workflow/oneStudioContext';
import useDebounce from 'components/useDebounce';
import { studioAppActions } from 'modules/workflow/reducers/actionsInterfaces';
import { useTranslation } from 'react-i18next';
import { hasUrlInContent } from 'app/utils/digital-helper';

const style = css`
  background-color: ${colors.leftSideBar};
  width: 372px;
  height: 100vh;
  padding: 21px;
  display: flex;
  flex-direction: column;
  .title {
    color: ${colors.background};
    opacity: 0.6;
    margin-bottom: 8px;
  }
  .input {
    background-color: ${colors.white};
    height: 134px;
    resize: none;
    border-radius: 2px;
    overflow-y: scroll;
  }
`;

const DigitalTextEditBar = (): JSX.Element => {
  const globalState = useStudioStateContext();
  const dispatch = useStudioDispatchContext();
  const { t } = useTranslation();
  const [value, setValue] = useState<string>(
    globalState.version.digitalContent?.message ?? ''
  );

  const debouncedValue = useDebounce(value, 500);

  const handleChange = (value: string) => {
    setValue(value);
  };

  useEffect(() => {
    if (debouncedValue !== undefined && dispatch) {
      dispatch(
        studioAppActions.SetDigitalContent({
          message: debouncedValue,
        })
      );
    }
  }, [debouncedValue, dispatch]);

  const isRequired =
    globalState.document &&
    (!globalState.document.isCustomizable ||
      globalState.document.isCustomization);
  const isInvalid = isRequired && !value;

  return (
    <div css={style}>
      <Typography className="title" variant="caption">
        {t('workflow.digital_menu.text_content')}
        {isRequired && ' *'}
      </Typography>
      <textarea
        className="input"
        onChange={(e) => handleChange(e.target.value)}
        value={value}
        maxLength={60000}
      />
      {isInvalid && (
        <Typography color="error" variant="body1">
          {t('common.generic_error.required')}
        </Typography>
      )}
      {hasUrlInContent(globalState.document?.productAttributes) && (
        <>
          {/* TODO: upcoming US */}
          {/* <Typography className="title" variant="caption">
            {t('workflow.digital_menu.url')}
          </Typography>
          */}
        </>
      )}
    </div>
  );
};

export default DigitalTextEditBar;
