import { Box, Typography } from '@material-ui/core';
import {
  GetCampaignById,
  GetCampaignByIdVariables,
} from 'app/schemaInterfaces/GetCampaignById';
import { formattedDateFrom, formattededDateTo } from 'app/utils/date/format';
import { tagBackgroundColors, tagTextColors } from 'app/utils/colorsCodesMap';

import { Action } from 'components/ActionsMenu/ActionsMenu';
import { ApolloQueryResult } from '@apollo/client';
import CampaignCreation from 'modules/pro/campaigns/CampaignCreation/CampaignCreation';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import React from 'react';
import Tag from 'modules/pro/components/Tag';
import { campaignDetailHeaderStyle } from './CampaignDetailHeader.style';
import colors from 'styles/colors.module.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  getLocalCampaignLink,
  getNationalCampaignLink,
} from 'modules/shop/shopHelpers';

export interface OperationDetailHeaderProps {
  title?: string;
  imageUrl?: string | null;
  description?: string | null;
  tags?: (string | null)[];
  dateFrom?: Date;
  dateTo?: Date;
  isCampaignModel: boolean;
  customerRef?: string | null;
  actions: (
    handleOpenCampaignDialog: () => void,
    deleteCampaign: () => void,
    previewCampaign: () => void,
    manageCampaign?: () => void
  ) => Action[];
  height?: number;
  button?: React.ReactNode;
  isEditMode?: boolean;
  campaignId: string;
  refetch?: (
    variables?: Partial<GetCampaignByIdVariables> | undefined
  ) => Promise<ApolloQueryResult<GetCampaignById>>;
  isToast?: boolean;
  isArchived?: boolean;
}

export const CampaignDetailHeader: React.FC<OperationDetailHeaderProps> = ({
  title,
  imageUrl,
  description,
  tags,
  dateFrom,
  dateTo,
  isCampaignModel,
  customerRef,
  actions,
  height,
  button,
  isEditMode = true,
  campaignId,
  refetch,
  isToast,
  children,
  isArchived,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      css={campaignDetailHeaderStyle}
      minHeight={height}
      py={3}
      data-testid="campaign-header"
    >
      <Box pl={7}>
        <Box display="flex">
          <Box display="flex" alignItems="center" pb={1}>
            <Box>
              <Link
                to={
                  isCampaignModel
                    ? getLocalCampaignLink(campaignId)
                    : getNationalCampaignLink(campaignId)
                }
              >
                <h2>{title}</h2>
              </Link>
            </Box>
            <Box pl={1} pt={1} style={{ cursor: 'pointer' }}>
              {actions && (
                <CampaignCreation
                  actions={actions}
                  button={button}
                  isEditMode={isEditMode}
                  isCampaignModel={isCampaignModel}
                  id={campaignId}
                  refetchCampaignById={refetch}
                  campaginPreviewDetails={{
                    title,
                    imageUrl,
                    description,
                    tags,
                    dateFrom,
                    dateTo,
                  }}
                />
              )}
            </Box>
          </Box>
        </Box>
        <Box display="flex">
          {!isCampaignModel && (
            <Box pr={2} data-testid="campaign-dates">
              <Typography variant="body1">
                {t('local.common.from_to_date', {
                  dateFrom: dateFrom && formattedDateFrom(dateFrom),
                  dateTo: dateTo && formattededDateTo(dateTo),
                })}
              </Typography>
            </Box>
          )}
          <Box display="flex">
            {tags?.map(
              (title, index) =>
                title && (
                  <Box pr={1} key={index}>
                    <Tag
                      title={title}
                      backgroundColor={tagBackgroundColors.get(
                        title.trim()[0].toUpperCase()
                      )}
                      titleColor={tagTextColors.get(
                        title.trim()[0].toUpperCase()
                      )}
                      isOverFlowEllipsis={false}
                    />
                  </Box>
                )
            )}

            {isArchived && (
              <Tag
                title={t('global.archived')}
                backgroundColor={colors.error}
                titleColor={colors.white}
                isOverFlowEllipsis={false}
              />
            )}
          </Box>
        </Box>
      </Box>
      {customerRef && (
        <Box
          pr={8}
          color={colors.body}
          display="flex"
          justifyContent="flex-end"
        >
          {customerRef}
        </Box>
      )}
      {isToast && (
        <div className="toast">
          <div className="info">
            <InfoOutlinedIcon fontSize="small" className="icon" />
            <p>{t('local.common.mandatory_documents_in_campaign')}</p>
          </div>
          <p className="description">
            {t('local.common.mandatory_documents_in_basket')}
          </p>
        </div>
      )}
      <Box width={1} px={7} py={1}>
        {children}
      </Box>
    </Box>
  );
};
