import { Avatar } from '@material-ui/core';
import { FC } from 'react';
import { entityLogoStyle } from './EntityLogo.style';

interface EntityLogoProps {
  name: string;
  logo?: string;
  isHidden?: boolean;
}

const EntityLogo: FC<EntityLogoProps> = ({ name, logo, isHidden }) =>
  !isHidden ? (
    <Avatar css={entityLogoStyle} alt={name} src={logo} data-testid="logo">
      {logo ? false : name[0].toUpperCase()}
    </Avatar>
  ) : null;

export default EntityLogo;
