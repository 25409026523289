import React, { useState } from 'react';
import {
  createStyles,
  makeStyles,
  MenuProps,
  PopoverOrigin,
  TextField,
  TextFieldProps,
  Theme,
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Option } from 'components/ControlledSelect/ControlledSelect';
import colors from 'styles/colors.module.scss';
import { css } from '@emotion/react';

const style = css`
  width: 100%;
  background-color: inherit;
`;

interface UncontrolledSelectProps {
  value?: string | number;
  options?: Option[];
  usePlaceholderItem?: boolean;
  isBorderRound?: boolean;
  textFieldStyle?: CSSProperties;
  anchorProps?: PopoverOrigin;
  isHeader?: boolean;
  variant?: TextFieldProps['variant'];
  typographyProps?: CSSProperties;
  labelText?: string;
}
const UncontrolledSelect = ({
  value,
  options,
  usePlaceholderItem = false,
  isBorderRound = false,
  textFieldStyle,
  anchorProps,
  isHeader = false,
  variant = 'outlined',
  typographyProps,
  labelText,
  ...textFieldProps
}: TextFieldProps & UncontrolledSelectProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const borderRoundStyle = (): CSSProperties => {
    if (isOpen && isHeader) {
      return {
        '& .MuiInputBase-root': {
          borderRadius: '25px',
          color: colors.primary,
        },
      };
    } else {
      return {
        '& .MuiInputBase-root': {
          border: `2px solid ${
            isOpen ? colors.primary : colors.buttonGroupOutlined
          }`,
          borderRadius: '25px',
          '&:hover': {
            backgroundColor: colors.documentsHeaderBackground,
          },
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        '& .MuiInputBase-input.MuiSelect-outlined.MuiSelect-outlined ': {
          padding: '6px 35px 6px 12px',
        },
      };
    }
  };

  const typographySelect = (theme: Theme) => ({
    fontFamily: theme.typography.body2.fontFamily,
    fontSize: theme.typography.body2.fontSize,
    lineHeight: theme.typography.body2.lineHeight,
    ...typographyProps,
  });
  const useStyle = makeStyles((theme) =>
    createStyles({
      selectRoot: typographySelect(theme),
      textfieldRoot: {
        ...textFieldStyle,
        ...(isBorderRound && borderRoundStyle()),
      },
      textfieldRootOpen: {
        '& .MuiInputBase-root': {
          color: colors.primary,
        },
        ...textFieldStyle,
        ...(isBorderRound && borderRoundStyle()),
        '&:hover': {
          backgroundColor: colors.documentsHeaderBackground,
        },
      },
      menuItemRoot: {
        marginLeft: '4px',
        marginRight: '4px',
      },
    })
  );
  const classes = useStyle();

  const textfieldClasses = (): string | undefined => {
    if (isBorderRound || (textFieldStyle && isOpen)) {
      return classes.textfieldRootOpen;
    } else if (isBorderRound || textFieldStyle) {
      return classes.textfieldRoot;
    } else {
      return undefined;
    }
  };

  const styleMenu = (anchorProps?: PopoverOrigin): Partial<MenuProps> => ({
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
    ...anchorProps,
  });

  return (
    <div css={style}>
      <FormControl fullWidth>
        <TextField
          select
          value={value}
          variant={variant}
          color="primary"
          label={!value && labelText && labelText}
          SelectProps={{
            classes: { root: classes.selectRoot },
            MenuProps: styleMenu(anchorProps),
            onOpen: () => {
              setIsOpen(true);
            },
            onClose: () => {
              setIsOpen(false);
            },
            IconComponent: (props) => <KeyboardArrowDownIcon {...props} />,
          }}
          classes={{ root: textfieldClasses() }}
          {...textFieldProps}
        >
          {usePlaceholderItem && (
            <MenuItem value="" disabled>
              {textFieldProps.placeholder}
            </MenuItem>
          )}
          {options?.length ? (
            options?.map((item) => (
              <MenuItem
                key={item.value ?? undefined}
                value={item.value ?? undefined}
                classes={{ root: classes.menuItemRoot }}
              >
                {item.label}
              </MenuItem>
            ))
          ) : (
            // when we have no option, we want to display nothing but TextField requires we
            // return a React.ReactNode
            <span />
          )}
        </TextField>
      </FormControl>
    </div>
  );
};
export default UncontrolledSelect;
