import { css } from '@emotion/react/macro';
import { FormLabel } from '@material-ui/core';
import { Field, useFormikContext } from 'formik';
import { DocumentFormValues } from 'modules/pro/documents/form/model/definitions';
import { useTranslation } from 'react-i18next';
import { CheckboxWithLabel } from 'formik-material-ui';
import React from 'react';
import InformationToast from './InformationToast/InformationToast';

const style = css`
  padding-top: 40px;
`;

const DocumentOptionsForm = () => {
  const { t } = useTranslation();

  const {
    values: { document },
  } = useFormikContext<DocumentFormValues>();

  return (
    <div css={style}>
      <div className="title">
        <FormLabel>{t('global.document_form.option_title')}</FormLabel>
      </div>
      <div className="field-group">
        <div className="field">
          <Field
            type="checkbox"
            component={CheckboxWithLabel}
            name="document.isMandatory"
            Label={{ label: t('global.document_form.is_mandatory.label') }}
          />
        </div>
        <div className="field">
          <Field
            type="checkbox"
            component={CheckboxWithLabel}
            name="document.hasAddressBlock"
            Label={{ label: t('global.document_form.has_address_block.label') }}
          />
        </div>
      </div>
      {document.isMandatory && (
        <InformationToast
          info={t('global.document_form.is_mandatory.information')}
          description={t('global.document_form.is_mandatory.message')}
        />
      )}
    </div>
  );
};

export default DocumentOptionsForm;
