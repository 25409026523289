import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const infoIconStyle = css`
  padding-top: 40px;
  .tooltip {
    color: ${colors.grey};

    margin-left: -38px;
    margin-top: 29px;
  }
`;
