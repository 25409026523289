import { Box, Button, FormControl, Typography } from '@material-ui/core';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import React from 'react';
import colors from 'styles/colors.module.scss';

export interface ButtonInfo {
  id: any;
  title: string;
  handleChange?: (id: ButtonInfo['id']) => void;
  buttonColor?: 'inherit' | 'default' | 'primary' | 'secondary';
}

export interface ButtonGroupFilterProps {
  buttons: ButtonInfo[];
  value?: ButtonInfo['id'];
  styleButtonVariant?: (
    button: ButtonInfo['id']
  ) => 'text' | 'contained' | 'outlined';
  styleButtonTitle?: (button: ButtonInfo['id']) => string;
}

const ButtonGroupFilter = ({
  buttons,
  value,
  styleButtonTitle,
  styleButtonVariant,
}: ButtonGroupFilterProps): JSX.Element => {
  const isButtonSelected = (id: ButtonInfo['id']) => id === value;
  const defaultStyleButtonVariant = (id: ButtonInfo['id']) =>
    isButtonSelected(id) ? 'contained' : 'outlined';
  const defaultStyleButtonTitle = (id: ButtonInfo['id']) =>
    isButtonSelected(id) ? colors.white : colors.grey;

  return (
    <FormControl data-testid="button-group-filter">
      <Box display="flex" alignItems="center">
        <ButtonGroup size="medium" color="primary" variant="outlined">
          {buttons.map(
            ({ title, handleChange, buttonColor, id }, index: number) => (
              <Button
                data-testid="button"
                color={buttonColor}
                variant={
                  styleButtonVariant
                    ? styleButtonVariant(id)
                    : defaultStyleButtonVariant(id)
                }
                key={index}
                onClick={() => handleChange?.(id)}
              >
                <Typography
                  style={{
                    color: styleButtonTitle
                      ? styleButtonTitle(id)
                      : defaultStyleButtonTitle(id),
                  }}
                  variant="body2"
                >
                  {title}
                </Typography>
              </Button>
            )
          )}
        </ButtonGroup>
      </Box>
    </FormControl>
  );
};

export default ButtonGroupFilter;
