import { useQuery, WatchQueryFetchPolicy } from '@apollo/client';
import { GET_BILLING_ADDRESSES } from 'app/graphql/queries/entities';

import {
  GetEntityBillingAdresses,
  GetEntityBillingAdressesVariables,
} from 'app/schemaInterfaces/GetEntityBillingAdresses';

const useBillingAddressesQuery = (
  id: string | undefined,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network'
) =>
  useQuery<GetEntityBillingAdresses, GetEntityBillingAdressesVariables>(
    GET_BILLING_ADDRESSES,
    {
      fetchPolicy,
      variables: {
        entityId: id as string,
      },
    }
  );

export default useBillingAddressesQuery;
