import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import colors from 'styles/colors.module.scss';
import ListItemLink from '../ListItemLink/ListItemLink';
import { useMenuToolBar } from './MenuToolBar.hook';
import { menuToolBarStyle } from './MenuToolBar.style';

export interface MenuItems {
  translationKey: string;
  to?: string;
  key: number;
}

export interface MenuToolBarProps {
  menuItems: MenuItems[];
  searchBar?: JSX.Element;
  filter?: JSX.Element;
}

const MenuToolBar: FC<MenuToolBarProps> = ({
  menuItems,
  searchBar,
  filter,
}) => {
  const { t } = useTranslation();
  const { selectedMenu, handleClick } = useMenuToolBar(menuItems);
  return (
    <div css={menuToolBarStyle}>
      <div className="menu-items" data-testid="menu">
        {menuItems?.map((item, index) => {
          return (
            <ListItemLink
              key={index}
              label={t(item.translationKey)}
              to={item.to}
              selected={selectedMenu}
              onClick={() => handleClick(index)}
              menuItemIndex={item.key}
              itemColor={colors.disabled}
              selectedItemColor={colors.white}
            />
          );
        })}
      </div>
      <div className="filter">
        <div data-testid="filter"> {filter}</div>
        <div data-testid="search-bar"> {searchBar}</div>
      </div>
    </div>
  );
};

export default MenuToolBar;
