import { css } from '@emotion/react/macro';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
} from '@material-ui/core';
import {
  CampaignType,
  DocumentOrderType,
} from 'app/schemaInterfaces/globalTypes';
import { Nullish } from 'app/utils/common';
import { UncontrolledDatePicker } from 'components/UncontrolledDatePicker';
import CheckboxesGroup from 'modules/pro/documents/form/fields/CheckboxesGroup';
import { ErrorMessage, getIn, useFormikContext } from 'formik';
import { DocumentFormValues } from 'modules/pro/documents/form/model/definitions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

const style = css`
  .label {
    margin-bottom: 10px;
  }
  .checkbox-group {
    padding-right: 20px;
  }
  .order-types-field {
    width: 100%;
    .MuiFormControlLabel-root {
      margin-top: 18px;
      margin-left: -10px;
    }
  }
  .date {
    width: 203px;
  }
  .date-left {
    margin-right: 40px;
  }
`;

const DocumentOrderTypeSubform = ({
  documentId,
  campaignType,
  campaignDateFrom,
  campaignDateTo,
  campaignCommitmentDateFrom,
  campaignCommitmentDateTo,
}: {
  documentId: string | undefined;
  campaignType: CampaignType;
  campaignDateFrom: MaterialUiPickersDate;
  campaignDateTo: MaterialUiPickersDate;
  campaignCommitmentDateFrom: MaterialUiPickersDate;
  campaignCommitmentDateTo: MaterialUiPickersDate;
}) => {
  const { t } = useTranslation();
  const commonMaterialProps = {
    dateFrom: {
      margin: 'dense',
      disablePast: !Boolean(documentId),
      fullWidth: true,
    },
    dateTo: {
      margin: 'dense',
      fullWidth: true,
    },
    textField: {
      fullWidth: true,
      variant: 'outlined',
      size: 'small',
    },
    label: {
      required: true,
    },
  } as const;
  const {
    values: { document },
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<DocumentFormValues>();

  const getOrderTypesOptions = (
    documentOrderTypes: Nullish<DocumentOrderType[]>
  ) => [
    {
      label: t('global.campaigns_helper.central'),
      value: DocumentOrderType.National,
      disabled:
        documentOrderTypes?.includes(DocumentOrderType.Commitment) ||
        documentOrderTypes?.includes(DocumentOrderType.FreeOrder),
    },
    {
      label: t('global.campaigns_helper.free_order'),
      value: DocumentOrderType.FreeOrder,
      disabled:
        (campaignType !== CampaignType.Model &&
          !documentOrderTypes?.includes(DocumentOrderType.Commitment)) ||
        documentOrderTypes?.includes(DocumentOrderType.National),
      tooltipMessage:
        campaignType !== CampaignType.Model &&
        !documentOrderTypes?.includes(DocumentOrderType.National) &&
        !documentOrderTypes?.includes(DocumentOrderType.Commitment)
          ? t('global.document_form.tooltip_message')
          : '',
    },
  ];

  const orderTypesOptions = getOrderTypesOptions(document.orderTypes);

  if (campaignType !== CampaignType.Model) {
    orderTypesOptions.splice(1, 0, {
      label: t('global.campaigns_helper.commitment'),
      value: DocumentOrderType.Commitment,
      disabled: document.orderTypes?.includes(DocumentOrderType.National),
    });
  }

  const handleField = (name: string, value?: MaterialUiPickersDate | null) => {
    setFieldTouched(name);
    setFieldValue(name, value);
  };

  return (
    <div css={style}>
      {/* OrderTypes field, accept multiple values */}
      <div className="order-types-field">
        <CheckboxesGroup
          name="document.orderTypes"
          title={t('global.document_form.order_type')}
          options={orderTypesOptions}
        />
      </div>
      {/* if National is selected, Commitment and FreeOrder selectboxes are disabled  */}
      {/* Document commitment input dates */}

      {document.orderTypes?.includes(DocumentOrderType.Commitment) && (
        <div className="field-date">
          <FormLabel {...commonMaterialProps.label} className="label">
            {t('global.document_form.commitment_date')}
          </FormLabel>
          <Grid container spacing={2}>
            <Grid item className="date date-left">
              <UncontrolledDatePicker
                placeholder={t('global.document_form.start')}
                value={document.commitmentDateFrom}
                maxDate={
                  document.commitmentDateTo ??
                  campaignCommitmentDateTo ??
                  undefined
                }
                minDate={campaignCommitmentDateFrom ?? undefined}
                handleClear={() =>
                  handleField('document.commitmentDateFrom', null)
                }
                onChange={(date) =>
                  handleField('document.commitmentDateFrom', date)
                }
                {...commonMaterialProps.dateFrom}
              />
              <FormControl
                error={
                  getIn(touched, 'document.commitmentDateFrom') &&
                  Boolean(getIn(errors, 'document.commitmentDateFrom'))
                }
              >
                <ErrorMessage
                  name={'document.commitmentDateFrom'}
                  component={FormHelperText}
                />
              </FormControl>
            </Grid>

            <Grid item className="date">
              <UncontrolledDatePicker
                placeholder={t('global.document_form.end')}
                value={document.commitmentDateTo}
                minDate={
                  document.commitmentDateFrom ??
                  campaignCommitmentDateFrom ??
                  undefined
                }
                maxDate={campaignCommitmentDateTo ?? undefined}
                handleClear={() =>
                  handleField('document.commitmentDateTo', null)
                }
                onChange={(date) =>
                  handleField('document.commitmentDateTo', date?.endOf('day'))
                }
                {...commonMaterialProps.dateTo}
              />
              <FormControl
                error={
                  getIn(touched, 'document.commitmentDateTo') &&
                  Boolean(getIn(errors, 'document.commitmentDateTo'))
                }
              >
                <ErrorMessage
                  name={'document.commitmentDateTo'}
                  component={FormHelperText}
                />
              </FormControl>
            </Grid>
          </Grid>
        </div>
      )}
      {/* Document free order input dates */}
      {document.orderTypes?.includes(DocumentOrderType.FreeOrder) && (
        <div className="field-date">
          <FormLabel {...commonMaterialProps.label} className="label">
            {t('global.document_form.free_order_date')}
          </FormLabel>
          <Grid container spacing={2}>
            <Grid item className="date date-left">
              <UncontrolledDatePicker
                placeholder={t('global.document_form.start')}
                value={document.freeOrderDateFrom}
                minDate={campaignDateFrom ?? undefined}
                maxDate={
                  document.freeOrderDateTo ?? campaignDateTo ?? undefined
                }
                handleClear={() =>
                  handleField('document.freeOrderDateFrom', null)
                }
                onChange={(date) =>
                  handleField('document.freeOrderDateFrom', date)
                }
                {...commonMaterialProps.dateFrom}
              />
              <FormControl
                error={
                  getIn(touched, 'document.freeOrderDateFrom') &&
                  Boolean(getIn(errors, 'document.freeOrderDateFrom'))
                }
              >
                <ErrorMessage
                  name={'document.freeOrderDateFrom'}
                  component={FormHelperText}
                />
              </FormControl>
            </Grid>

            <Grid item className="date">
              <UncontrolledDatePicker
                placeholder={t('global.document_form.end')}
                value={document.freeOrderDateTo}
                minDate={
                  document.freeOrderDateFrom ?? campaignDateFrom ?? undefined
                }
                maxDate={campaignDateTo ?? undefined}
                handleClear={() =>
                  handleField('document.freeOrderDateTo', null)
                }
                onChange={(date) =>
                  handleField('document.freeOrderDateTo', date)
                }
                {...commonMaterialProps.dateTo}
              />
              <FormControl
                error={
                  getIn(touched, 'document.freeOrderDateTo') &&
                  Boolean(getIn(errors, 'document.freeOrderDateTo'))
                }
              >
                <ErrorMessage
                  name={'document.freeOrderDateTo'}
                  component={FormHelperText}
                />
              </FormControl>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
};

export default DocumentOrderTypeSubform;
