import React from 'react';

import QuickSearchBar from 'components/QuickSearchBar/QuickSearchBar';
import { SourceFileFilters } from 'modules/pro/SourceFiles/SourceFilesContainer';
// import { useCampaignStateContext } from './Campaigns';

interface SourceFilesSearchBarProps {
  placeholder?: string;
  filters: SourceFileFilters;
  setFilters: React.Dispatch<React.SetStateAction<SourceFileFilters>>;
}

const SourceFilesSearchBar = ({
  placeholder,
  filters,
  setFilters,
}: SourceFilesSearchBarProps): JSX.Element => {
  const onChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    event.preventDefault();
    setFilters({ ...filters, searchBar: event.target.value });
  };

  return (
    <QuickSearchBar
      placeholder={placeholder}
      onChange={onChange}
      value={filters.searchBar}
    />
  );
};

export default SourceFilesSearchBar;
