import { GetOrderDeliveries_orderDeliveries } from 'app/schemaInterfaces/GetOrderDeliveries';
import moment from 'moment';
import OrderSachaDeliveryPointTag from '../OrderSachaDeliveryPointTag';
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined';
import { useTranslation } from 'react-i18next';

export const useFollowOrderDelivery = (
  orderDeliveryData?: GetOrderDeliveries_orderDeliveries
) => {
  const { t } = useTranslation();
  const tableHeadData = [
    t('follow_orders.delivery_follow.transporter'),
    t('follow_orders.delivery_follow.shipping_number'),
    t('follow_orders.delivery_follow.delivery_points'),
    t('follow_orders.delivery_follow.address'),
    t('follow_orders.delivery_follow.zipcode'),
    t('follow_orders.delivery_follow.town'),
    t('follow_orders.delivery_follow.date_hour'),
    t('follow_orders.delivery_follow.receiver'),
    t('follow_orders.delivery_follow.status'),
    t('follow_orders.delivery_follow.proof'),
  ];

  const tableRowData = orderDeliveryData?.deliveryPoints?.flatMap(
    (deliveryPoint) =>
      deliveryPoint.packages?.map((packageItem) => [
        packageItem.carrier,
        packageItem.code,
        deliveryPoint.name,
        deliveryPoint.address1,
        deliveryPoint.zipCode,
        deliveryPoint.town,
        moment(packageItem.deliveryDate).format('DD/MM/YY HH:mm'),
        packageItem.receiver,
        <OrderSachaDeliveryPointTag deliveryPointStatus={packageItem.status} />,
        <span
          onClick={() =>
            window.open(packageItem.deliveryProofLink ?? '#', '_blank')
          }
        >
          <FindInPageOutlinedIcon />
        </span>,
      ]) ?? []
  );

  return { tableRowData, tableHeadData, t };
};
