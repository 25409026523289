import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
} from '@material-ui/core';
import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { style, tableRowWarningStyle } from './TableComponent.style';

export interface TableRowData {
  variant?: 'normal' | 'warning';
  data: ReactNode[];
}

export interface TableProps {
  tableHeadData?: ReactNode[];
  tableRowData?: (TableRowData['data'] | TableRowData)[];
  paths?: string[];
  isOrderList?: boolean;
}

const TableComponent: FC<TableProps> = ({
  tableHeadData,
  tableRowData,
  paths,
  isOrderList,
}) => {
  return (
    <Table css={style(isOrderList)}>
      <TableHead>
        <TableRow>
          {tableHeadData?.map((item, index) => (
            <TableCell key={index} align="left">
              {item}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {tableRowData?.map((row, index) => {
          if (Array.isArray(row)) {
            row = { data: row };
          }
          const path = paths && paths[index];
          return (
            <TableRow
              key={index}
              component={Link}
              to={path || '#'}
              css={[row.variant === 'warning' && tableRowWarningStyle]}
            >
              {row.data.map((cell, index) => (
                <TableCell key={index} align="left">
                  <p>{cell}</p>
                </TableCell>
              ))}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default TableComponent;
