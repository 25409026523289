import { Field, useFormikContext } from 'formik';
import React, { useState } from 'react';
import { MetadataInput as Metadata } from 'app/schemaInterfaces/globalTypes';
import {
  readImageMetadata,
  readPdfMetadata,
  readPsdMetadata,
} from 'app/utils/FileMetadataReader/FileMetadataReader';
import ControlledFileUpload, {
  PreviewFile,
} from 'components/ControlledFileUpload/ControlledFileUpload';
import {
  ACCEPTED_FORMATS,
  ACCEPTED_IMAGE_TYPES,
  ACCEPTED_PDF_TYPES,
  ACCEPTED_PHOTOSHOP_TYPES,
} from 'modules/dam/form/helpers';
import mime from 'mime-types';
import { InitialImage } from 'components/FileUploadIcon/FileUploadIcon';
import { SingleAssetFormValues } from 'modules/dam/form/model/definitions';

interface AssetFileUploadFieldProps {
  assetImage?: InitialImage;
  onFileReadStart: () => void;
  onFileReadEnd: () => void;
  setReportError: React.Dispatch<React.SetStateAction<string | null>>;
}

const AssetFileUploadField = ({
  assetImage,
  onFileReadStart,
  onFileReadEnd,
  setReportError,
}: AssetFileUploadFieldProps): JSX.Element => {
  const { setFieldValue } = useFormikContext<SingleAssetFormValues>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <Field
      name="file"
      initialImage={assetImage}
      IsLargePreview={true}
      component={ControlledFileUpload}
      previewWidth={325}
      previewHeight={325}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      accept={ACCEPTED_FORMATS}
      onDropFormikField={async (name: string, [file]: PreviewFile[]) => {
        onFileReadStart();
        let credit: string | undefined;
        let dimensions: Metadata['dimensions'];

        setFieldValue(name, file);
        setFieldValue('asset.name', file.name.split('.')[0]);
        try {
          if (ACCEPTED_IMAGE_TYPES.includes(file.type)) {
            const extractedMetadata = await readImageMetadata(file);
            credit = extractedMetadata.credit;
            dimensions = extractedMetadata.dimensions;
          }
          if (ACCEPTED_PHOTOSHOP_TYPES.includes(file.type)) {
            const extractedMetadata = await readPsdMetadata(file);
            credit = extractedMetadata.credit;
            dimensions = extractedMetadata.dimensions;
          }
          const mimetype = !!file.type
            ? file.type
            : mime.lookup(file.name.split('.').pop() as string);
          if (file.type === ACCEPTED_PDF_TYPES) {
            const extractedMetadata = await readPdfMetadata(file);
            credit = extractedMetadata.credit;
            dimensions = extractedMetadata.dimensions;
          }

          const metadata: Metadata = {
            fileSize: file.size,
            mimetype: mimetype ? mimetype : '',
            credit: credit ?? '',
            fileHasCredit: Boolean(credit),
            dimensions:
              dimensions?.width && dimensions?.height
                ? {
                    width: dimensions.width,
                    height: dimensions.height,
                    widthInCm: dimensions.widthInCm ?? undefined,
                    heightInCm: dimensions.heightInCm ?? undefined,
                  }
                : undefined,
          };

          setFieldValue('asset.metadata', metadata);
          setReportError(null);
        } catch (e) {
          setReportError((e as any).message);
        }
        onFileReadEnd();
      }}
    />
  );
};

export default AssetFileUploadField;
