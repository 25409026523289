import { css } from '@emotion/react/macro';
import { Box, CircularProgress } from '@material-ui/core';
import UncontrolledCheckboxMenu, {
  CheckboxOption,
} from 'components/UncontrolledCheckboxMenu';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GET_DIVISIONS as I_GET_DIVISIONS } from 'app/schemaInterfaces/GET_DIVISIONS';
import colors from 'styles/colors.module.scss';
import SectorsView from 'modules/workflow/containers/Leftsidebar/ProductsPanel/SectorsView';
import { useQuery } from '@apollo/client';
import { GET_DIVISIONS } from 'app/graphql/queries/divisions';
import { useDivisionSelect } from 'components/divisions/useDivisionSelect';
import { DivisionElement } from 'components/useProductsWithAssets';
import ProductsTypeNavigation from 'modules/workflow/containers/Leftsidebar/ProductsPanel/ProductsTypeNavigation';
import { GET_PRODUCTS_DIVISIONS } from 'app/graphql/queries/products';
import {
  GetProductsDivisions,
  GetProductsDivisionsVariables,
} from 'app/schemaInterfaces/GetProductsDivisions';
import { Nullish } from 'app/utils/common';
import RegroupingSearchBar from './RegroupingSearchBar';
import PimProductsView, { ProductsDataNormalized } from './PimProductView';
import ProductsSheetsFormSingleProduct from './ProductsSheetsFormSingleProduct';

const style = css`
  height: 820px;
  width: 408px;
  overflow-y: scroll;

  .progress {
    display: flex;
    width: 100%;
    justify-content: center;
  }
`;

const ProductsSheetsFormProductsPanel = ({
  productId,
}: {
  productId?: string;
}): JSX.Element => {
  const { t } = useTranslation();

  const [divisions, setDivisions] = useState<DivisionElement[]>();

  const [searchValue, setSearchValue] = useState<string>('');

  const filterByDivisions = divisions?.reduce<Record<string, Nullish<string>>>(
    (divisions, { value, division }) => ({
      ...divisions,
      [division as string]: value,
    }),
    {}
  );

  const { data: productsDivisionsData, loading: divisionLoading } = useQuery<
    GetProductsDivisions,
    GetProductsDivisionsVariables
  >(GET_PRODUCTS_DIVISIONS, {
    variables: {
      filter: {
        details: filterByDivisions,
      },
    },
    fetchPolicy: 'network-only',
  });

  const [isLastDivision, setIsLastDivision] = useState<boolean>(false);
  const [selectedDivision, setSelectedDivision] = useState<DivisionElement>({
    value: undefined,
    division: undefined,
  });
  const [selectedProduct, setSelectedProduct] = useState<
    ProductsDataNormalized | undefined
  >(undefined);

  const { data: divisionsData } = useQuery<I_GET_DIVISIONS>(GET_DIVISIONS);

  const getDivisionValue = (divisionKey: string): string | null | undefined =>
    divisions?.find(({ division }) => division === divisionKey)?.value;

  const { selectModels } = useDivisionSelect(divisionsData, getDivisionValue);
  const divisionsOptions = selectModels.map(({ key, label }) => ({
    label,
    value: key,
  }));

  useEffect(() => {
    if (!!selectModels.length && !selectedDivision.division) {
      setSelectedDivision({
        ...selectedDivision,
        division: selectModels[0].key,
      });
    }
  }, [selectModels, selectedDivision]);

  const model = selectModels.find(
    (selectModel) => selectModel.key === selectedDivision.division
  );

  let options: CheckboxOption[] = [];

  const divisionsValues =
    productsDivisionsData?.pimProducts.valuesOptionsDivisionFilter;

  if (model) {
    !isLastDivision &&
      divisionsValues?.forEach((item) => {
        if (item.key === selectedDivision.division) {
          options = item.values.map((value) => ({
            value: value,
            label: value,
          }));
        }
      });
  }
  const handleSectorClick = (
    _: React.MouseEvent<HTMLDivElement, MouseEvent>,
    value: string | null
  ) => {
    let indexDivision = selectModels.findIndex(
      (model) => model.key === selectedDivision.division
    );

    const isLastItem = indexDivision === selectModels.length - 1;

    const isDivisionNotFound = indexDivision === -1;

    setDivisions((previousDivisions = []) => [
      ...previousDivisions,
      { division: selectModels[indexDivision].key, value },
    ]);

    if (isDivisionNotFound || isLastItem) {
      setIsLastDivision(true);
      return;
    }
    setSelectedDivision({
      division: !isLastItem
        ? selectModels[indexDivision + 1].key
        : selectModels[indexDivision].key,
      value,
    });
    setIsLastDivision(false);
  };

  return (
    <Box css={style} p="15px">
      {!selectedProduct && (
        <Box display="flex" alignItems="center">
          <RegroupingSearchBar
            placeholder={t(
              'workflow.left_side_bar.products_panel.search_bar_placeholder'
            )}
            bgcolor={colors.white}
            hasBorder={false}
            inputBaseWidth="100%"
            handleOnSearchProduct={setSearchValue}
          />
          <Box pl={2.5}>
            <UncontrolledCheckboxMenu
              options={[
                {
                  label: t(
                    'workflow.left_side_bar.products_panel.alphabetical_order'
                  ),
                  value: 'all',
                },
                ...divisionsOptions,
              ]}
              hasIndentation={true}
              isPim={true}
            />
          </Box>
        </Box>
      )}
      {!selectedProduct && selectedDivision.value && (
        <Box pt={2} pb={2}>
          <ProductsTypeNavigation
            divisions={divisions}
            setDivisions={setDivisions}
            setSelectedDivision={setSelectedDivision}
            setIsLastDivision={setIsLastDivision}
          />
        </Box>
      )}
      {selectedProduct && (
        <ProductsSheetsFormSingleProduct
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
        />
      )}
      {!selectedProduct &&
        (productsDivisionsData?.pimProducts.valuesOptionsDivisionFilter &&
        !divisionLoading ? (
          !isLastDivision && !searchValue ? (
            <SectorsView
              options={options}
              onClick={(e, value) => {
                handleSectorClick(e, value);
              }}
              isPim={true}
            />
          ) : (
            <PimProductsView
              divisions={divisions as DivisionElement[]}
              productId={productId}
              setSelectedProduct={setSelectedProduct}
              searchValue={searchValue}
            />
          )
        ) : (
          <div className="progress">
            <CircularProgress color="inherit" />
          </div>
        ))}
    </Box>
  );
};

export default ProductsSheetsFormProductsPanel;
