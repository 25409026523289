import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const followOrdersPageStyle = css`
  margin-top: 40px;
  padding-right: 60px;
  padding-left: 60px;

  .MuiList-padding {
    padding: 0;

    .MuiListItem-root {
      padding: 16px;

      &:not(:last-of-type) {
        border-bottom: 1px solid #dadada;
      }
    }
  }

  h1 {
    margin-bottom: 40px;
    color: ${colors.body};
  }
  .image {
    width: 38px;
    height: 38px;
    object-fit: contain;
  }
  .support-category--icon {
    color: ${colors.grey};
  }
  .error {
    text-align: center;
    margin-top: 104px;
  }
`;
