import {
  ActionsUnion,
  createActionPayload,
  createAction,
} from 'modules/workflow/reducers/actionsType';

export enum ProductsFiltersActionTypes {
  ClearFilters,
  UpdateSearch,
  UpdatePrice,
  UpdateFiles,
  UpdateDivisions,
}

interface UpdateDivisionPayload {
  key: string;
  values?: string[];
}

export const ProductsFiltersActionCreator = {
  clearFilters: createAction<typeof ProductsFiltersActionTypes.ClearFilters>(
    ProductsFiltersActionTypes.ClearFilters
  ),
  updateSearch: createActionPayload<
    typeof ProductsFiltersActionTypes.UpdateSearch,
    string
  >(ProductsFiltersActionTypes.UpdateSearch),
  updateDivisions: createActionPayload<
    typeof ProductsFiltersActionTypes.UpdateDivisions,
    UpdateDivisionPayload
  >(ProductsFiltersActionTypes.UpdateDivisions),
  updatePrice: createActionPayload<
    typeof ProductsFiltersActionTypes.UpdatePrice,
    boolean | undefined
  >(ProductsFiltersActionTypes.UpdatePrice),
  updateFiles: createActionPayload<
    typeof ProductsFiltersActionTypes.UpdateFiles,
    boolean | undefined
  >(ProductsFiltersActionTypes.UpdateFiles),
};

export type ProductsFiltersActions = ActionsUnion<
  typeof ProductsFiltersActionCreator
>;
