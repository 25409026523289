import { GetCurrentClient_client_config_workflow_supportConfig } from 'app/schemaInterfaces/GetCurrentClient';
import { ItemSize } from './catalogPreviewHelper';

//TODO: make productConfig.size required in the backend
export const productSize = (
  supportConfig:
    | GetCurrentClient_client_config_workflow_supportConfig
    | null
    | undefined
): ItemSize =>
  supportConfig?.productConfig?.size?.width &&
  supportConfig?.productConfig?.size?.height
    ? (supportConfig?.productConfig?.size as ItemSize)
    : {
        width: 0,
        height: 0,
      };
