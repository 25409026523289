import React, { FC } from 'react';
import {
  List,
  ListItemText,
  ListItem,
  ListItemSecondaryAction,
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import colors from 'styles/colors.module.scss';
import { CheckboxOption } from '../UncontrolledCheckboxMenu';
import { myListStyle } from './MyList.style';

export interface MyListProps {
  options: CheckboxOption[];
  onClick?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    value: CheckboxOption['value']
  ) => void;
  isPim?: boolean;
}

const MyList: FC<MyListProps> = ({ options, onClick, isPim }) => {
  return (
    <List css={myListStyle(isPim ? isPim : false)} data-testid="list">
      {options.map((option, index) => (
        <ListItem
          data-testid="button"
          key={index}
          button
          onClick={(event) => {
            onClick?.(event, option.value);
          }}
        >
          <ListItemText primary={<h5>{option.value}</h5>} />
          <ListItemSecondaryAction className="arrows">
            <ArrowForwardIcon htmlColor={colors.inputLabelColor} />
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
};

export default MyList;
