import { css } from '@emotion/react/macro';
import { Button, Grid } from '@material-ui/core';
import useCurrentEntity from 'app/current-entity-context/useCurrentEntity';
import {
  AscDescType,
  CampaignType,
  UserType,
} from 'app/schemaInterfaces/globalTypes';
import useCampaignsQuery from 'modules/shop/queries/useCampaignsQuery';
import { useTranslation } from 'react-i18next';
import colors from 'styles/colors.module.scss';
import NewCampaignsCard from './NewCampaignsCard';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import { useRouter } from 'app/routes/useRouter';
import { ShopRoute } from 'modules/shop/shopHelpers';
import globalStyle from 'styles/global.style';
import React, { useState } from 'react';
import Slider from 'components/Slider/Slider';
import DocumentSkeletonLoader from '../../available-documents/DocumentsSkeletonLoader';
import { useCurrentUser } from 'app/auth/UserContext';

const DEFAULT_LIMIT = 3;

const style = css`
  h1 {
    margin-top: 50px;
  }
  .subtitle {
    text-align: center;
    margin-bottom: 36px;
    margin-top: 8px;
    color: ${colors.documentsTab};
  }

  .link-button {
    margin-top: 5px;
    text-align: right;
  }
`;

const NewCampaignsContainer = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const user = useCurrentUser();
  const isUserGlobal = user.type === UserType.Global;
  const { currentEntity } = useCurrentEntity();
  const [offset, setOffset] = useState(3);

  const { data: campaignsData, loading: campaignsLoading } = useCampaignsQuery({
    filter: {
      entityId: currentEntity?.id,
      campaignType: CampaignType.National,
      hasCommitmentDates: true,
      activeNationalCampaigns: true,
      sort: { commitmentDateFrom: AscDescType.asc },
      skipOutdatedDocuments: !isUserGlobal,
    },
    pagination: { limit: 3, offset: offset - 3 },
  });

  const campaigns = campaignsData?.getAllCampaigns?.campaigns ?? [];
  const count = campaignsData?.getAllCampaigns.totalItems ?? 0;

  return (
    <div css={[globalStyle, style]} className="app-container">
      <h1>{t('homepage.new_campaigns')} </h1>
      <p className="subtitle">{t('homepage.new_campaigns_subtitle')}</p>

      <Slider
        onRightArrowClick={() =>
          setOffset((currentOffset) => currentOffset + 3)
        }
        hasRightArrow={offset < count}
        onLeftArrowClick={() => setOffset((currentOffset) => currentOffset - 3)}
        hasLeftArrow={offset - 3 > 0}
      >
        <Grid container spacing={2}>
          {campaignsLoading ? (
            <DocumentSkeletonLoader
              numberOfElements={DEFAULT_LIMIT}
              height={374}
              borderRadius={10}
            />
          ) : (
            campaigns?.map((campaign, index) => (
              <Grid item xs={4} key={index}>
                <NewCampaignsCard campaign={campaign} />
              </Grid>
            ))
          )}
        </Grid>
      </Slider>

      <div className="link-button">
        <Button
          color="primary"
          endIcon={<ArrowForwardOutlinedIcon />}
          onClick={() => router.push(ShopRoute.AllNationalCampaigns)}
        >
          {t('homepage.all_campaigns')}
        </Button>
      </div>
    </div>
  );
};
export default NewCampaignsContainer;
