import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const borderStyle = css`
  border: 1px solid ${colors.buttonGroupOutlined};
`;

export const buttonStyle = css`
  ${borderStyle}
  border-top: none;
  border-bottom: none;
  cursor: pointer;
  height: 32px;
  padding: 0 22px;

  img {
    ${borderStyle}
    border-radius: 30px;
    background-color: ${colors.pageBackground};
    margin-top: 2px;
  }
`;

export const menuStyle = css`
  .MuiListItemIcon-root {
    padding-right: 14px;
    min-width: initial;
  }
  .MuiMenu-paper {
    margin-left: -150px;
    margin-top: 40px;
    width: 200px;
  }
`;
