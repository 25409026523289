import { css } from '@emotion/react';

export const orderFollowStatusTagStyle = (
  tagBgColor: string,
  tagColor: string
) => css`
  background: ${tagBgColor};
  border-radius: 26px;
  color: ${tagColor};
  font-family: ScandiaWebMedium;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  overflow: hidden;
  padding: 5px 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: fit-content;
`;
