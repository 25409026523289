import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { AssetType } from 'app/schemaInterfaces/globalTypes';
import {
  ActionsUnion,
  createActionPayload,
} from 'modules/workflow/reducers/actionsType';
import { AssetDataSource } from 'modules/workflow/containers/Leftsidebar/AssetsPanel/reducer/definitions';

export enum AssetsPanelActionTypes {
  ClearFilters,
  SwitchDataSource,
  GoToTypesView,
  UpdateSearch,
  UpdateAssetTypes,
  UpdateDivisions,
  UpdateValidityDates,
}

interface UpdateDivisionPayload {
  key: string;
  values?: string[];
}

interface UpdateValidityDatesPayload {
  validFrom?: MaterialUiPickersDate;
  validTo?: MaterialUiPickersDate;
}

export const AssetsPanelActionCreator = {
  clearFilters: createActionPayload<
    typeof AssetsPanelActionTypes.ClearFilters,
    undefined
  >(AssetsPanelActionTypes.ClearFilters),
  switchDataSource: createActionPayload<
    typeof AssetsPanelActionTypes.SwitchDataSource,
    AssetDataSource
  >(AssetsPanelActionTypes.SwitchDataSource),
  goToTypesView: createActionPayload<
    typeof AssetsPanelActionTypes.GoToTypesView,
    undefined
  >(AssetsPanelActionTypes.GoToTypesView),
  updateSearch: createActionPayload<
    typeof AssetsPanelActionTypes.UpdateSearch,
    string
  >(AssetsPanelActionTypes.UpdateSearch),
  updateAssetTypes: createActionPayload<
    typeof AssetsPanelActionTypes.UpdateAssetTypes,
    AssetType
  >(AssetsPanelActionTypes.UpdateAssetTypes),
  updateDivisions: createActionPayload<
    typeof AssetsPanelActionTypes.UpdateDivisions,
    UpdateDivisionPayload
  >(AssetsPanelActionTypes.UpdateDivisions),
  updateValidityDates: createActionPayload<
    typeof AssetsPanelActionTypes.UpdateValidityDates,
    UpdateValidityDatesPayload
  >(AssetsPanelActionTypes.UpdateValidityDates),
};

export type AssetsPanelActions = ActionsUnion<typeof AssetsPanelActionCreator>;
