import { useMutation } from '@apollo/client';
import { BASKET_DOCUMENTS } from 'app/graphql/queries/basket';

import { CREATE_COMMITMENT } from 'app/graphql/queries/commitments';
import {
  CreateCommitment,
  CreateCommitmentVariables,
} from 'app/schemaInterfaces/CreateCommitment';
import {
  BasketFilterInput,
  PaginationInput,
} from 'app/schemaInterfaces/globalTypes';

const useCreateCommitmentMutation = ({
  basketFilter = {},
  pagination,
}: {
  basketFilter?: BasketFilterInput;
  pagination?: PaginationInput;
}) => {
  const [
    createCommitment,
    {
      data: createCommitmentData,
      error: createCommitmentError,
      loading: createCommitmentLoading,
    },
  ] = useMutation<CreateCommitment, CreateCommitmentVariables>(
    CREATE_COMMITMENT,
    {
      refetchQueries: [
        {
          query: BASKET_DOCUMENTS,
          variables: {
            filter: basketFilter,
            pagination,
          },
        },
      ],
    }
  );

  return {
    createCommitment,
    createCommitmentData,
    createCommitmentError,
    createCommitmentLoading,
  };
};

export default useCreateCommitmentMutation;
