import React, { FC } from 'react';
import { useOrderValidationWorkflow } from 'modules/followOrders/customHooks/useOrderValidationWorkflow';
import FollowOrderAcceptationDialog from 'modules/followOrders/components/FollowOrderAcceptationDialog/FollowOrderAcceptationDialog';
import FollowOrderRefusalDialog from 'modules/followOrders/components/FollowOrderRefusalDialog/FollowOrderRefusalDialog';

interface FollowOrderValidationDialogProps {
  validation: ReturnType<typeof useOrderValidationWorkflow>;
}

const FollowOrderValidationDialog: FC<FollowOrderValidationDialogProps> = ({
  validation,
}) => (
  <>
    <FollowOrderAcceptationDialog
      isOpen={!!validation.currentAcceptation}
      onCancel={validation.cancel}
      onConfirm={validation.confirmAcceptation}
    />
    <FollowOrderRefusalDialog
      isOpen={!!validation.currentRefusal}
      onCancel={validation.cancel}
      onConfirm={validation.confirmRefusal}
    />
  </>
);

export default FollowOrderValidationDialog;
