import React, { useState } from 'react';
import {
  makeStyles,
  Box,
  IconButton,
  IconButtonProps,
  Divider,
} from '@material-ui/core';
import SelectConfirmationDialog, {
  ConfirmationDialogType,
} from './SelectConfirmationDialog';
import colors from 'styles/colors.module.scss';

export interface ConfirmationDialog {
  title: string;
  description: string;
  subDescription?: string;
  type?: ConfirmationDialogType;
}

export interface ActionButton<T> {
  icon: (isActiveItemClickItem: boolean, item: T) => React.ReactNode;
  iconButtonProps?: IconButtonProps;
  onClick?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: T
  ) => void;
  confirmationDialog?: ConfirmationDialog;
  isDisabled?: boolean;
}

export interface ActionButtonsProps<T> {
  item: T;
  itemIndex?: number;
  clickItemIndex?: number;
  actionButtons?: ActionButton<T>[];
  isDisabled?: boolean;
  setClickItemIndex?: React.Dispatch<React.SetStateAction<number | undefined>>;
  hasDivider?: boolean;
}

function ActionButtons<T>({
  item,
  itemIndex,
  actionButtons,
  setClickItemIndex,
  clickItemIndex,
  hasDivider,
}: ActionButtonsProps<T>) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const isActiveItemClickItem = clickItemIndex === itemIndex;

  const useStyles = makeStyles({
    button: {
      padding: '9px',
    },
  });

  const classes = useStyles();

  const onClose = () => setIsOpen(false);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    confirmationDialog: ConfirmationDialog | undefined,
    onClick:
      | ((
          event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
          item: T
        ) => void)
      | undefined
  ) => {
    if (confirmationDialog) {
      setIsOpen(true);
    } else {
      onClick?.(event, item);
    }
    setClickItemIndex?.(itemIndex);
  };

  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center">
      {actionButtons?.map(
        (
          { icon, iconButtonProps, onClick, isDisabled, confirmationDialog },
          actionButtonIndex
        ) => (
          <>
            <Box
              key={actionButtonIndex}
              width={actionButtons.length > 2 ? '25px' : 'auto'}
            >
              <IconButton
                disabled={isDisabled}
                data-testid={`icon-button-${actionButtonIndex}`}
                onClick={(event) =>
                  handleClick(event, confirmationDialog, onClick)
                }
                disableRipple
                classes={{ root: classes.button }}
                {...iconButtonProps}
              >
                {icon(isActiveItemClickItem, item)}
              </IconButton>
              {confirmationDialog && isOpen && (
                <SelectConfirmationDialog<T>
                  isOpen={isOpen}
                  onClose={onClose}
                  item={item}
                  confirmationDialog={confirmationDialog}
                  onClick={onClick}
                />
              )}
            </Box>
            {hasDivider && actionButtons.length - 1 !== actionButtonIndex && (
              <Divider
                orientation="vertical"
                style={{
                  height: '29px',
                  backgroundColor: colors.neutalLightGrey,
                }}
              />
            )}
          </>
        )
      )}
    </Box>
  );
}

export default ActionButtons;
