import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const documentStatusTagStyle = (isValidated: boolean) => css`
  background: ${isValidated
    ? 'rgba(103, 219, 0, 0.1)'
    : 'rgba(254, 200, 0, 0.1)'};
  border-radius: 26px;
  color: ${isValidated ? colors.success : colors.orange300};
  font-family: ScandiaWebMedium;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  overflow: hidden;
  padding: 5px 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: fit-content;
  cursor: pointer;
`;
