import React from 'react';
import {
  PersonalizationItem,
  PersonalizationType,
} from 'modules/workflow/oneStudioContext';

import { IProductTemplate } from 'modules/workflow/Clients/ToutFaire/ProductItem';
import ContentItem from 'modules/workflow/containers/CatalogPreview/Personalization/ContentItem';
import TypographyItem from 'modules/workflow/containers/CatalogPreview/Personalization/TypographyItem';

interface PersonalizationItemsProps {
  personalizationItems: PersonalizationItem[];
  pageNumber: number;
  zoneIndex: number;
  zoomScale: number;
  setIsPersonalizationDropZone: React.Dispatch<React.SetStateAction<boolean>>;
  isPersonalizationDropZone: boolean;
  getProduct: () => Promise<Omit<IProductTemplate, 'components' | 'position'>>;
}

const PersonalizationItems = ({
  personalizationItems,
  pageNumber,
  zoneIndex,
  zoomScale,
  setIsPersonalizationDropZone,
  isPersonalizationDropZone,
  getProduct,
}: PersonalizationItemsProps): JSX.Element => {
  const persoZones = personalizationItems.map((personalizationItem, key) => {
    if (personalizationItem.type === PersonalizationType.CONTENT) {
      return (
        <ContentItem
          key={key}
          pageNumber={pageNumber}
          zoneIndex={zoneIndex}
          contentItem={personalizationItem}
          zoomScale={zoomScale}
          setIsPersonalizationDropZone={setIsPersonalizationDropZone}
          isPersonalizationDropZone={isPersonalizationDropZone}
          getProduct={getProduct}
        />
      );
    } else {
      return (
        <TypographyItem
          key={key}
          pageNumber={pageNumber}
          zoneIndex={zoneIndex}
          typographyItem={personalizationItem}
          zoomScale={zoomScale}
        />
      );
    }
  });
  return <div className="flex">{persoZones}</div>;
};

export default PersonalizationItems;
