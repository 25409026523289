import HomePage from 'modules/home/HomePage';
import Login from 'modules/login/Login';
import NotFound from 'app/routes/pages/NotFound';
import BaseProducts from 'modules/pim/baseProducts/BaseProducts';
import CampaignDetail from 'modules/pro/documents/CampaignDetail';
import DocumentFormContainer from 'modules/pro/documents/DocumentFormContainer';
import ProductsSheetImportPage from 'modules/pro/ProductsFiles/ProductsSheetImportPage';
import { PropsWithChildren } from 'react';
import { RouteComponentProps } from 'react-router';
import Studio from 'modules/workflow';
import { ImportDamInCampaign } from 'modules/pro/medias/ImportDamInCampaign';
import { DamPage } from 'modules/dam/DamPage';
import { PermissionKey, UserType } from 'app/schemaInterfaces/globalTypes';
import ShopModule from 'modules/shop/ShopModule';
import SalesModule from 'modules/sales/SalesModule';
import FollowOrdersModule from 'modules/followOrders/FollowOrdersModule';
import AccessDenied from './pages/AccessDenied';
import CompetitorsContainer from 'modules/competitors/CompetitorsContainer/CompetitorsContainer';
import { ModuleKey } from './helper';
import OrderDetailsContainer from 'modules/followOrders/OrderDetailsContainer/OrderDetailsContainer';
import StoreModule from 'modules/oneManager/StoreModule';

export enum AppRoute {
  Home = '/',
  Login = '/login',
  Studio = '/studio/:documentId',
  Products = '/products',
  CampaignId = '/campaign/:id',
  ImportDamInCampaign = '/campaign/:id/media/add',
  DocumentFormContainer = '/campaign/:id/document/creation',
  DocumentEditFormContainer = '/campaign/:id/document/edit/:documentId',
  NotFound = '*',
  Dam = '/dam',
  Competitors = '/competitors',
  ProductsSheetImport = '/campaign/:id/productSheet/import',
  Shop = '/actionsmarketing',
  Store = '/store',
  Sales = '/sales',
  FollowOrders = '/follow-orders',
  OrderId = '/follow-orders/:id',
  AccessDenied = '/--',
}

interface AppRoutes {
  path: AppRoute;
  Component: (props: PropsWithChildren<RouteComponentProps>) => JSX.Element;
  exact?: boolean;
  open?: boolean;
  userType?: UserType;
  permission?: PermissionKey[] | PermissionKey;
  link?: string;
  offer?: ModuleKey;
}

const ROUTES: { [key in AppRoute]: AppRoutes } = {
  [AppRoute.Home]: {
    path: AppRoute.Home,
    Component: HomePage,
    exact: true,
    link: 'Home',
  },
  [AppRoute.Login]: { path: AppRoute.Login, Component: Login, open: true },
  [AppRoute.Competitors]: {
    path: AppRoute.Competitors,
    Component: CompetitorsContainer,
    open: true,
    exact: true,
  },
  [AppRoute.Store]: {
    path: AppRoute.Store,
    Component: StoreModule,
    open: true,
  },
  [AppRoute.ImportDamInCampaign]: {
    path: AppRoute.ImportDamInCampaign,
    Component: ImportDamInCampaign,
    open: true,
  },
  [AppRoute.Products]: {
    path: AppRoute.Products,
    Component: BaseProducts,
    permission: PermissionKey.AccessPim,
  },
  [AppRoute.DocumentFormContainer]: {
    path: AppRoute.DocumentFormContainer,
    Component: DocumentFormContainer,
    permission: [
      PermissionKey.AccessNationalCampaign,
      PermissionKey.AccessModelCampaign,
    ],
  },
  [AppRoute.DocumentEditFormContainer]: {
    path: AppRoute.DocumentEditFormContainer,
    Component: DocumentFormContainer,
    open: true,
  },
  [AppRoute.ProductsSheetImport]: {
    path: AppRoute.ProductsSheetImport,
    Component: ProductsSheetImportPage,
    open: true,
  },
  [AppRoute.CampaignId]: {
    path: AppRoute.CampaignId,
    Component: CampaignDetail,
    permission: [
      PermissionKey.AccessNationalCampaign,
      PermissionKey.AccessModelCampaign,
    ],
    offer: ModuleKey.ActionMarketing,
  },
  [AppRoute.Studio]: {
    path: AppRoute.Studio,
    Component: Studio,
    exact: true,
    open: true,
  },
  [AppRoute.Dam]: {
    path: AppRoute.Dam,
    Component: DamPage,
    open: true,
  },
  [AppRoute.Shop]: {
    path: AppRoute.Shop,
    Component: ShopModule,
    offer: ModuleKey.ActionMarketing,
  },
  [AppRoute.Sales]: {
    path: AppRoute.Sales,
    Component: SalesModule,
    open: true,
  },
  [AppRoute.FollowOrders]: {
    path: AppRoute.FollowOrders,
    Component: FollowOrdersModule,
    open: true,
    exact: true,
  },
  [AppRoute.OrderId]: {
    path: AppRoute.OrderId,
    Component: OrderDetailsContainer,
    open: true,
  },
  [AppRoute.AccessDenied]: {
    path: AppRoute.AccessDenied,
    Component: AccessDenied,
    open: true,
    exact: true,
  },
  [AppRoute.NotFound]: {
    path: AppRoute.NotFound,
    Component: NotFound,
    open: true,
  },
};

export default ROUTES;
