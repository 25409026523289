import { useLazyQuery } from '@apollo/client';
import { Box, Divider } from '@material-ui/core';
import { GET_ASSET_BY_ID } from 'app/graphql/queries/dam';
import {
  GetAssetById,
  GetAssetByIdVariables,
} from 'app/schemaInterfaces/GetAssetById';
import { AssetInput } from 'app/schemaInterfaces/globalTypes';
import colors from 'styles/colors.module.scss';
import React, { useEffect, useState } from 'react';
import { AssetSimilar } from 'modules/dam/AssetSimilar';
import { DialogTab } from 'modules/dam/DialogTab';
import AssetFormWrapper from 'modules/dam/form/AssetFormWrapper';
import DownloadFileButton from 'components/DownloadFileButton/DownloadFileButton';
import Loader from 'components/Loader/Loader/Loader';
import { useDownloadFileErrorWorkflow } from 'app/utils/customHooks/useDownloadFileErrorWorkflow';

interface AssetDialogProps {
  id?: string;
  onFormValidation?: (values: AssetInput, file: File | null) => void;
  isUpdateInDam: boolean;
}

export const AssetDialog = ({
  id,
  onFormValidation,
  isUpdateInDam,
}: AssetDialogProps): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const [getAssetById, { data, error }] = useLazyQuery<
    GetAssetById,
    GetAssetByIdVariables
  >(GET_ASSET_BY_ID);

  const downloadFile = useDownloadFileErrorWorkflow({
    useS3CorsWorkaround: true,
  });

  const asset = data?.getAssetById;

  const tabs = [
    <AssetFormWrapper
      asset={asset}
      onFormValidation={onFormValidation}
      isUpdateInDam={isUpdateInDam}
    />,
    asset && <AssetSimilar asset={asset} />,
  ];

  const handleChange = (newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleDownload = async (
    e: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    e.preventDefault();

    if (!isDownloading && asset?.url) {
      setIsDownloading(true);
      downloadFile({
        url: asset.url,
        filename: asset.name ?? 'file',
        mimetype: asset.metadata.mimetype,
      }).finally(() => setIsDownloading(false));
    }
  };

  useEffect(() => {
    if (id) {
      getAssetById({ variables: { id } });
    }
  }, [id, getAssetById]);

  if (id && !asset) {
    return <Loader error={error} />;
  }

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        py={0}
        px={1}
        mt={5}
        mb={0}
        bgcolor={colors.white}
      >
        <Box p={1}>
          <DialogTab handleChange={handleChange} selectedTab={selectedTab} />
        </Box>
        <Box p={1}>
          {selectedTab === 0 && (
            <DownloadFileButton
              disabled={isDownloading}
              onDownload={handleDownload}
              isDownloading={isDownloading}
              variant={'outlined'}
            />
          )}
        </Box>
      </Box>
      <Divider />
      <Box minHeight={700}>{tabs[selectedTab]}</Box>
    </Box>
  );
};
