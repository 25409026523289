import { Button, Divider, Grid, IconButton } from '@material-ui/core';
import React, { useState } from 'react';
import {
  getCommitmentLatestDate,
  isDocumentTerminated,
} from 'modules/shop/shopHelpers';
import { ApolloQueryResult } from '@apollo/client';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import { DEFAULT_IMAGE_PREVIEW } from 'app/utils/common';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import FormatColorFillOutlinedIcon from '@material-ui/icons/FormatColorFillOutlined';
import { GetCampaignById_getCampaignById } from 'app/schemaInterfaces/GetCampaignById';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { SalesRoute } from 'modules/sales/salesHelpers';
import SelectConfirmationDialog from 'components/ActionsButtons/SelectConfirmationDialog';
import colors from 'styles/colors.module.scss';
import { css } from '@emotion/react';
import { getFreeOrderLatestDate } from 'modules/shop/pages/campaign-page/shopHelpers';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import useRemoveBasketDocumentMutation from 'queries/useRemoveBasketDocumentMutation';
import { useTranslation } from 'react-i18next';
import { DocumentOrderType } from 'app/schemaInterfaces/globalTypes';
import {
  GetDocumentsToShop,
  GetDocumentsToShopVariables,
} from 'app/schemaInterfaces/GetDocumentsToShop';
import {
  GetBasketDocuments,
  GetBasketDocumentsVariables,
} from 'app/schemaInterfaces/GetBasketDocuments';

const style = (isOrderTerminated: boolean) => css`
  border: 2px solid ${colors.buttonGroupOutlined};
  border-radius: 4px 4px 0px 0px;
  h4,
  h5 {
    padding: 16px;
    background: ${colors.documentsHeaderBackground};
  }

  h5 {
    font-weight: normal;
    letter-spacing: -0.03em;
    background: ${colors.documentsHeaderBackground};
  }

  h5.latest_date {
    background: ${isOrderTerminated
      ? colors.tagBackground1
      : colors.leftSideBar};
    color: ${isOrderTerminated ? colors.orange300 : colors.pageBackground};
    padding: 8px 16px;
    svg {
      width: 20px;
      height: 20px;
      margin-right: 18px;
      margin-bottom: -5px;
    }
  }

  h5.notice {
    color: ${colors.documentsTab};
    display: flex;
    svg {
      width: 20px;
      height: 20px;
      margin-right: 18px;
      margin-bottom: -5px;
    }
  }

  .message {
    text-align: center;
    padding: 20px 10%;
    color: ${colors.disabled};
  }
  .summary-container {
    .order-button {
      display: flex;
      padding: 20px;
      .button-content {
        justify-content: space-between;
        display: flex;
      }
    }
    .row-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 8px;
      height: 80px;
      img {
        width: 48px;
        height: 48px;
      }
      .value {
        font-family: 'ScandiaWebBold';
      }
      .content {
        width: 45%;
        h6 {
          overflow: hidden;
          max-height: 2.9em;
          line-height: 1.4em;
        }
        .document-info {
          display: flex;
        }
        .custom-info {
          display: flex;
          p {
            padding-left: 10px;
            font-family: ScandiaWebMedium;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 20px;
            letter-spacing: -0.03em;
            color: ${colors.grey};
          }
        }
        .not-custom-info {
          font-family: ScandiaWebMedium;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 20px;
          letter-spacing: -0.03em;
          color: ${colors.grey};
        }
      }
    }
    .footer {
      display: flex;
      justify-content: space-between;
      padding: 27px;
      .total-price {
        font-weight: 700;
        font-size: 16px;
        font-family: 'ScandiaWebBold';

        span {
          display: block;
        }
      }
    }
  }
`;

interface OrderBasketSummaryProps {
  campaign: GetCampaignById_getCampaignById;
  lastOrderDate: string;
  isCommitment: boolean;
  basketData: GetBasketDocuments;
  documentRefetch: (
    variables?: Partial<GetDocumentsToShopVariables> | undefined
  ) => Promise<ApolloQueryResult<GetDocumentsToShop>>;
  basketRefetch: (
    variables?: Partial<GetBasketDocumentsVariables> | undefined
  ) => Promise<ApolloQueryResult<GetBasketDocuments>>;
}

const OrderBasketSummary = ({
  campaign,
  lastOrderDate,
  isCommitment,
  basketData,
  documentRefetch,
  basketRefetch,
}: OrderBasketSummaryProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isDeletePopInOpen, setIsDeletePopInOpen] = useState<boolean>(false);
  const [documentToDelete, setDocumentToDelete] = useState<string>('');

  const { removeBasketDocuments } = useRemoveBasketDocumentMutation();

  const handleDelete = async (basketDocumentId: string) => {
    const response = await removeBasketDocuments({
      variables: {
        ids: [basketDocumentId],
      },
    });
    if (response.data?.removeBasketDocuments.isSuccess) {
      basketRefetch();
      documentRefetch();
    }
  };

  const filteredBasketDocuments = basketData?.basket.basketDocuments.filter(
    ({ document }) =>
      document.campaign.id === campaign.id &&
      document.orderTypes.includes(
        isCommitment
          ? DocumentOrderType.Commitment
          : DocumentOrderType.FreeOrder
      )
  );

  const isSomeDocumentCustumizable = filteredBasketDocuments.some(
    (filteredBasketDocument) => filteredBasketDocument.document.isCustomizable
  );

  const toBasket = () =>
    history.push({ pathname: SalesRoute.CommitmentsBasket });

  return (
    <div css={style(isDocumentTerminated(lastOrderDate))}>
      {filteredBasketDocuments && (
        <>
          <h4>
            {isCommitment
              ? t('local.campaign_page.summary.title', {
                  count: filteredBasketDocuments.length,
                })
              : t('local.campaign_page.summary.titleFreeOrder', {
                  count: filteredBasketDocuments.length,
                })}
          </h4>
          {isDocumentTerminated(lastOrderDate) ? (
            <>
              <h5 className="latest_date">
                <ReportProblemOutlinedIcon />
                {isCommitment
                  ? t('local.campaign_page.summary.commitments_terminated')
                  : t('local.campaign_page.summary.freeOrder_terminated')}
              </h5>
            </>
          ) : Boolean(filteredBasketDocuments.length) ? (
            <h5 className="latest_date">
              {isCommitment
                ? t('local.common.commitment_latest_date', {
                    date: moment(getCommitmentLatestDate(campaign))
                      .utc()
                      .format('DD/MM/YY'),
                  })
                : t('local.common.freeOrder_latest_date', {
                    date: moment(getFreeOrderLatestDate(campaign))
                      .utc()
                      .format('DD/MM/YY'),
                  })}
            </h5>
          ) : (
            <Divider />
          )}
          {filteredBasketDocuments.length ? (
            <div className="summary-container">
              <Grid>
                {filteredBasketDocuments.map((basketDocument) => (
                  <Grid
                    key={basketDocument.id}
                    item
                    xs={12}
                    className="row-item"
                  >
                    <SelectConfirmationDialog
                      item={basketDocument.document.id}
                      isOpen={
                        isDeletePopInOpen &&
                        basketDocument.id === documentToDelete
                      }
                      onClose={() => setIsDeletePopInOpen(false)}
                      onClick={() => handleDelete(documentToDelete)}
                      confirmationDialog={{
                        title: t('common.delete_confirmation_dialog.title'),
                        description: t('sales.basket_page.delete_dialog'),
                      }}
                      actionButtonsTitle={{
                        reject: t('common.button.cancel'),
                        accept: t('common.simple_confirmation_dialog.yes'),
                      }}
                    />
                    <div className="media-wrapper">
                      <img
                        src={
                          basketDocument.document.thumbnailUrl ??
                          DEFAULT_IMAGE_PREVIEW
                        }
                        alt="document"
                      />
                    </div>
                    <div className="content">
                      <h6>
                        {basketDocument.document.name} -{' '}
                        {basketDocument.document.version}
                      </h6>
                      {basketDocument.document.isCustomizable ? (
                        <div className="custom-info">
                          <FormatColorFillOutlinedIcon fontSize="small" />
                          <p>{t('global.customizable')}</p>
                        </div>
                      ) : (
                        <p className="not-custom-info">
                          {t('global.not_customizable')}
                        </p>
                      )}
                    </div>
                    <IconButton
                      onClick={() => {
                        setIsDeletePopInOpen(true);
                        setDocumentToDelete(basketDocument.id);
                      }}
                    >
                      <DeleteForeverOutlinedIcon />
                    </IconButton>
                  </Grid>
                ))}
                <div className="order-button">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={toBasket}
                    fullWidth
                    className="button-content"
                  >
                    {!isCommitment ? (
                      isSomeDocumentCustumizable ? (
                        <p>
                          {t('local.campaign_page.summary.order_and_custumize')}
                        </p>
                      ) : (
                        <p>{t('local.campaign_page.summary.order')}</p>
                      )
                    ) : isSomeDocumentCustumizable ? (
                      <p>
                        {t('local.campaign_page.summary.customize_and_commit')}
                      </p>
                    ) : (
                      <p>{t('local.campaign_page.summary.to_commitment')}</p>
                    )}
                    <ArrowForwardOutlinedIcon />
                  </Button>
                </div>
              </Grid>
            </div>
          ) : (
            <p className="message">
              {isCommitment
                ? t('local.campaign_page.summary.empty')
                : t('local.campaign_page.summary.emptyFreeOrder')}
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default OrderBasketSummary;
