import React from 'react';
import { IProductTemplate } from '../../ProductItem';
import { VisualIdentityType } from 'app/schemaInterfaces/globalTypes';
import { typographyItemStyle } from './priceTagHelper';
import PriceTag from './PriceTag';
import { Typography } from '@material-ui/core';
import { Asset, TypographyIdentities } from 'modules/workflow/oneStudioContext';
import { css } from '@emotion/react';

const style = (
  typography: TypographyIdentities | undefined,
  priceChip: Asset | undefined
) => css`
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  width: 100%;
  .typography-container {
    width: ${typography && typography.width ? typography.width : 100}%;
    z-index: 10;
    padding-left: ${typography && typography.position
      ? typography.position.x
      : 0}px;
    padding-bottom: ${typography && typography.position
      ? typography.position.y
      : 0}px;
  }
  .price-container {
    height: ${priceChip && priceChip.size ? priceChip.size.height : 0}pt;
    width: ${priceChip && priceChip.size ? priceChip.size.width : 0}pt;
  }
`;

interface PriceAndNameProps {
  product: IProductTemplate;
  isDefaultProduct: boolean;
  toggleColor: (typographyItemId: string) => void;
  typographies: TypographyIdentities[] | undefined;
}

const PriceAndName = ({
  product,
  isDefaultProduct,
  toggleColor,
  typographies,
}: PriceAndNameProps): JSX.Element => {
  const priceChip = product.visualIdentities?.find(
    (visual) => visual.visualIdentityType === VisualIdentityType.Price
  );

  //TODO : quick fix for demo ===> make dynamic again with a typographies.map but use index to sort
  const libelle = typographies?.find(
    (typography) => typography.name === 'libelleproduit'
  );

  const descriptif = typographies?.find(
    (typography) => typography.name === 'descriptiftextelong'
  );
  const firstTypoElement = typographies && typographies[0];

  return (
    <div css={style(libelle ?? descriptif ?? firstTypoElement, priceChip)}>
      {typographies && (
        <div className="typography-container">
          {libelle && (
            <Typography
              css={typographyItemStyle(libelle)}
              onDoubleClick={() => {
                !isDefaultProduct && toggleColor(libelle.id);
              }}
            >
              {product.productDetails[libelle.name]}
            </Typography>
          )}
          {descriptif && (
            <Typography
              css={typographyItemStyle(descriptif)}
              onDoubleClick={() => {
                !isDefaultProduct && toggleColor(descriptif.id);
              }}
            >
              {product.productDetails[descriptif.name]}
            </Typography>
          )}
          {/*
          TODO : quick fix for demo ===> make dynamic again with a typographies.map but use index to sort
           {typographies?.map((typographyItem, index) => (
            <Typography
              key={index}
              css={typographyItemStyle(typographyItem)}
              onDoubleClick={() => {
                !isDefaultProduct && toggleColor(typographyItem.id);
              }}
            >
              {product.productDetails[typographyItem.name]}
            </Typography>
          ))} */}
        </div>
      )}
      <div className="price-container">
        <PriceTag product={product} />
      </div>
    </div>
  );
};

export default PriceAndName;
