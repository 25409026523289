import { PDFDataRangeTransport } from 'pdfjs-dist/types/src/pdf';
import React, { FC } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Props as ReactPdfDocumentProps } from 'react-pdf/dist/Document';
import { Props as ReactPdfPageProps } from 'react-pdf/dist/Page';
import { pdfPreviewStyle } from './PdfPreview.style';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export interface FileConfig {
  url?: string;
  data?: Uint8Array;
  range?: PDFDataRangeTransport;
  httpHeaders?: RequestInit;
  withCredentials?: boolean;
}

export interface PdfPreviewProps extends ReactPdfDocumentProps {
  file: string | File | FileConfig;
  pageNumber?: ReactPdfPageProps['pageNumber'];
  size?: PdfPreviewSize;
}

export enum PdfPreviewSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

const defaultErrorHandler = (error: Error) => console.log(error);

const PdfPreview: FC<PdfPreviewProps> = ({
  file,
  onLoadError = defaultErrorHandler,
  size = PdfPreviewSize.Small,
}) => (
  <Document file={file} onLoadError={onLoadError}>
    <Page
      pageNumber={1}
      css={pdfPreviewStyle(
        size === PdfPreviewSize.Large
          ? 400
          : size === PdfPreviewSize.Medium
          ? 250
          : 100
      )}
    />
  </Document>
);

export default PdfPreview;
