import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const appTableStyle = (isHover: boolean) => css`
  .MuiTableRow-root {
    &:hover {
      background-color: ${isHover ? colors.greyHover : colors.white};
    }
  }
  .MuiFormControl-root {
    width: 110px;
  }
`;
