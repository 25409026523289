import {
  GetDocuments,
  GetDocumentsVariables,
} from 'app/schemaInterfaces/GetDocuments';

import { GET_DOCUMENTS } from 'app/graphql/queries/documents';
import { GetOrderById_getOrderById } from 'app/schemaInterfaces/GetOrderById';
import _ from 'lodash';
import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { useCurrentUser } from 'app/auth/UserContext';
import { isUserValidatorForOrder } from '../utils/validation';
import { FollowOrderStatus } from 'app/schemaInterfaces/globalTypes';

export const useFollowOrderDetailsContent = (
  order: GetOrderById_getOrderById
) => {
  const [
    clientNetworkLinks,
    orderItemStatus,
    publicationsDates,
  ] = useMemo(() => {
    const clientNetworkLinks = order.orderItem
      ? order.orderItem.map((item) => item.clientNetworkLink)
      : [];

    const orderItemStatus = order.orderItem
      ? order.orderItem.map((item) => item.status)
      : [];
    const publicationsDates = order.orderItem
      ? order.orderItem.map((item) => item.publicationDate)
      : [];
    return [clientNetworkLinks, orderItemStatus, publicationsDates];
  }, [order]);

  const { data: documentsData } =
    useQuery<GetDocuments, GetDocumentsVariables>(GET_DOCUMENTS, {
      variables: {
        filter: {
          ids: order.orderItem?.map((item) => item.onePacDocumentId),
          showObsolete: true,
        },
      },
    }) || {};

  const shippingAddress = order.childrenOrder
    ? order.childrenOrder.map((order) =>
        order.orderItem?.map((item) => ({
          name: item?.shippingAddress?.name,
          quantity: item?.quantity,
          id: item.onePacDocumentId,
        }))
      )?.[0]
    : [];

  const user = useCurrentUser();
  const isValidator = isUserValidatorForOrder(order, user);

  const orderDocuments = _.chain(documentsData?.documents.documents)
    .groupBy('campaignId')
    .map((documents) => ({
      totalPriceHT: order.sellingPriceHt,
      transportSellingPriceHt: order.transportSellingPriceHt,
      campaignName: documents[0].campaign.name,
      isCampaignArchived: documents[0].campaign.archived,
      documentItems: documents.map((document, index) => {
        const orderItem = order.orderItem?.find(
          (item) => item.onePacDocumentId === document.id
        );
        return {
          document,
          orderMetadata: {
            addresses:
              shippingAddress?.filter((item) => item.id === document.id) ?? [],
            sumPrice: orderItem?.sellingPriceHt,
            clientNetworkLink: clientNetworkLinks[index],
            status: orderItemStatus[index],
            publicationDate: publicationsDates[index],
            needsValidation:
              order?.followStatus === FollowOrderStatus.WaitingValidation &&
              isValidator &&
              !!order.activeOrderWorkflows.find((it) =>
                it.matchedItems.includes(orderItem?.id ?? '')
              ),
          },
        };
      }),
    }))
    .value();
  return { orderDocuments };
};
