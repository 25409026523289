import React, { useState } from 'react';
import { ApolloQueryResult } from '@apollo/client';
import { Divider } from '@material-ui/core';
import DocumentsGridView from '../DocumentsGridView';
import { GetDocumentsToShop_documents_documents } from 'app/schemaInterfaces/GetDocumentsToShop';
import ListItemButton from 'components/List/ListItemButton/ListItemButton';
import OrderDocumentList from 'components/OrderProcess/OrderDocumentList';
import { useDocumentsListView } from './DocumentsListView.hooks';
import { GetDocumentsToShopVariables } from 'app/schemaInterfaces/GetDocumentsToShop';
import { GetDocumentsToShop } from 'app/schemaInterfaces/GetDocumentsToShop';
import {
  GetBasketDocumentsVariables,
  GetBasketDocuments,
} from 'app/schemaInterfaces/GetBasketDocuments';
import { DisabledDocumentErrors } from 'app/schemaInterfaces/globalTypes';

interface DocumentsListViewProps {
  document: GetDocumentsToShop_documents_documents;
  gridView: boolean;
  documentRefetch: (
    variables?: Partial<GetDocumentsToShopVariables> | undefined
  ) => Promise<ApolloQueryResult<GetDocumentsToShop>>;
  basketRefetch: (
    variables?: Partial<GetBasketDocumentsVariables> | undefined
  ) => Promise<ApolloQueryResult<GetBasketDocuments>>;
  setIsAlert?: React.Dispatch<React.SetStateAction<boolean>>;
  disabled: boolean;
}

const DocumentsListView = ({
  document,
  gridView,
  documentRefetch,
  basketRefetch,
  setIsAlert,
  disabled,
}: DocumentsListViewProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { handleSubmitOrder, buttonProps } = useDocumentsListView({
    document,
    documentRefetch,
    basketRefetch,
    setIsAlert,
    disabled,
  });

  return gridView ? (
    <DocumentsGridView
      key={document.id}
      document={document}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      addToBasket={handleSubmitOrder}
      buttonProps={buttonProps}
    />
  ) : (
    <>
      <ListItemButton
        key={document.id}
        isDisabled={
          document.isDisabled.reason ===
          DisabledDocumentErrors.documentTerminated
        }
        isSelected={false}
      >
        <OrderDocumentList
          document={document}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          addToBasket={handleSubmitOrder}
          buttonProps={buttonProps}
        />
      </ListItemButton>
      <Divider className="divider" />
    </>
  );
};

export default DocumentsListView;
