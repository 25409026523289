import React from 'react';
import {
  List,
  ListItem,
  Box,
  CardMedia,
  Typography,
  ListItemText,
} from '@material-ui/core';
import colors from 'styles/colors.module.scss';
import { ProductsDataNormalized } from './PimProductView';
import { css } from '@emotion/react';

export interface PimCardListProps {
  items?: ProductsDataNormalized[];
  enableClick: (id: string) => boolean;
  setSelectedProduct: React.Dispatch<
    React.SetStateAction<ProductsDataNormalized | undefined>
  >;
}

function PimCardList({
  items,
  enableClick,
  setSelectedProduct,
}: PimCardListProps): JSX.Element {
  const style = css`
    width: '100%';

    .MuiListItem-root {
      border-radius: 4px;
      &:hover {
        background-color: ${colors.workzone};
      }
      padding: 9px;
    }
    .MuiListItemText-root {
      width: 130px;
      overflow: hidden;
      padding-left: 8px;
      text-overflow: ellipsis;
    }
    .disabledItemStyle {
      filter: contrast(0.3);
      pointer-events: none;
    }
    .caption {
      color: ${colors.body};
      padding-left: 8px;
      white-space: nowrap;
    }
    .card-media {
      width: 60px;
      height: 60px;
      object-fit: contain;
      border-radius: 0;
    }
  `;

  return (
    <List css={style}>
      {items?.map((item, itemIndex) => (
        <ListItem data-testid={`list-item-${itemIndex}`} key={itemIndex} button>
          <Box
            display="flex"
            alignItems="center"
            width="100%"
            minHeight="37px"
            className={enableClick(item._id) ? 'disabledItemStyle' : undefined}
          >
            <Box
              display="flex"
              width="100%"
              alignItems="center"
              onClick={() => {
                setSelectedProduct(item);
              }}
            >
              <CardMedia
                component="img"
                height="60"
                src={
                  item?.image ??
                  `/${process.env.REACT_APP_ASSETS}/noPreviewImageSquare.png`
                }
                className="card-media"
              />
              <ListItemText
                primary={
                  <Typography variant="caption" className="caption">
                    {item.name}
                  </Typography>
                }
              />
            </Box>
          </Box>
        </ListItem>
      ))}
    </List>
  );
}

export default PimCardList;
