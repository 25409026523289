/**
 * Split a collection into lines of a set amount of items for layouting purposes
 */
export function splitLines<T>(items: T[], perLine: number): T[][] {
  const res: T[][] = [];
  for (let i = 0; i < items.length; i += perLine) {
    res.push(items.slice(i, i + perLine));
  }
  return res;
}
