import React from 'react';
import {
  Box,
  CardMedia,
  Divider,
  Button,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  GetAssetById,
  GetAssetByIdVariables,
  GetAssetById_getAssetById,
} from 'app/schemaInterfaces/GetAssetById';
import { GET_ASSET_BY_ID } from 'app/graphql/queries/dam';
import Loader from 'components/Loader/Loader/Loader';
import { useQuery } from '@apollo/client';
import { useProductsSheetsContext } from 'modules/pim/baseProducts/BaseProducts';
import { PreviewFile } from 'modules/pim/baseProducts/ProductsSheetFilesUpload';
import { getAssetsType } from 'modules/pim/baseProducts/ProductsHelper';
import { FileStore } from 'modules/pim/baseProducts/PimFormFieldsConfig';
import { DimensionsInput, VisualType } from 'app/schemaInterfaces/globalTypes';
import { processAssetDpiAndMaxSurface } from 'modules/dam/form/helpers';
import StatusBadge, { BadgeStatus } from 'components/Badge/StatusBadge';
import { useTranslation } from 'react-i18next';
import mime from 'mime-types';
import { FileExtension } from 'app/utils/common';
import { getBase64File } from 'app/utils/FileUpload/FileUpload';
import PdfPreview, { PdfPreviewSize } from 'components/PdfPreview/PdfPreview';
import colors from 'styles/colors.module.scss';
import { VisuelFieldName } from '../baseProducts/ProductsHelper';

interface ProductsSheetsFormSingleAssetProps {
  assetId?: string;
  setGetAssetId?: React.Dispatch<React.SetStateAction<string | undefined>>;
  file?: PreviewFile;
  setFile?: React.Dispatch<React.SetStateAction<PreviewFile | undefined>>;
  fileDimensions?: DimensionsInput | null;
  fileCredit?: string | null;
  base64File?: string;
  reportError?: string | null;
  productId?: string;
}

const ProductsSheetsFormSingleAsset = ({
  assetId,
  setGetAssetId,
  file,
  setFile,
  fileDimensions,
  fileCredit,
  base64File,
  reportError,
  productId,
}: ProductsSheetsFormSingleAssetProps): JSX.Element => {
  const { t } = useTranslation();

  const classes = makeStyles({
    conformityBadge: {
      position: 'absolute',
      right: 70,
      top: 15,
    },
  })();

  const { data: assetData, error } = useQuery<
    GetAssetById,
    GetAssetByIdVariables
  >(GET_ASSET_BY_ID, {
    variables: {
      id: assetId as string,
    },
    skip: !Boolean(assetId),
  });
  const {
    fieldName,
    formFilesManager,
    setFieldName,
    setFormFilesManager,
  } = useProductsSheetsContext();

  const { getAssetById: asset } = assetData ?? {};

  const fileAssetsType = getAssetsType(fieldName as string, t);

  const assetAttributes =
    fileDimensions?.width &&
    fileDimensions?.height &&
    processAssetDpiAndMaxSurface(
      fileDimensions?.width,
      fileDimensions?.height,
      fileAssetsType.value,
      VisualType.Packshot
    );

  let isConform: boolean =
    assetAttributes && assetAttributes.dpi >= 300 ? true : false;

  const conformityFileOrAsset = () => {
    if (asset && asset?.conform !== null) {
      return asset?.conform as boolean;
    } else if (file) {
      return isConform;
    }
    return undefined;
  };

  const addAssetToForm = async (asset: GetAssetById_getAssetById) => {
    let filesToStore: FileStore[] = [...formFilesManager];
    if (file) {
      filesToStore.push({
        fieldName: fieldName as string,
        fieldFile: file,
        base64File: (await getBase64File(file)) as string,
        fileAssetsType: fileAssetsType.value,
        fileName: file.path,
        isConform: isConform,
        metadata: {
          fileSize: parseFloat(
            (file && file.size / (1024 * 1024))?.toFixed(2) as string
          ),
          mimetype: file.type,
          credit: fileCredit ?? '',
          fileHasCredit: !!fileCredit,
          dimensions:
            fileDimensions?.width && fileDimensions?.height
              ? { width: fileDimensions.width, height: fileDimensions.height }
              : undefined,
        },
      });
      setFieldName(undefined);
    } else {
      filesToStore.push({
        fieldName: fieldName as string,
        fileName: asset.name as string,
        assetId: asset.id,
      });
      setGetAssetId?.(undefined);
    }

    if (fieldName === VisuelFieldName.mainVisuel) {
      const newFilesToStore = filesToStore.filter(
        (file) =>
          file.fieldName !== `${VisuelFieldName.mainVisuel}-${productId ?? 0}`
      );

      if (asset.id) {
        newFilesToStore.push({
          fieldName: `${VisuelFieldName.mainVisuel}-${productId ?? 0}`,
          fileName: asset.name ?? '',
          assetId: asset.id,
        });
      } else if (file) {
        newFilesToStore.push({
          fieldName: `${VisuelFieldName.mainVisuel}-${productId ?? 0}`,
          fieldFile: file,
          base64File: (await getBase64File(file)) as string,
          fileAssetsType: fileAssetsType.value,
          fileName: file.path,
          isConform: isConform,
          metadata: {
            fileSize: parseFloat(
              (file && file.size / (1024 * 1024))?.toFixed(2) as string
            ),
            mimetype: file.type,
            credit: fileCredit ?? '',
            fileHasCredit: !!fileCredit,
            dimensions:
              fileDimensions?.width && fileDimensions?.height
                ? { width: fileDimensions.width, height: fileDimensions.height }
                : undefined,
          },
        });
      } else {
        // Just to be sure that if we don't have asset.id or file we do something with the files !
        setFormFilesManager(filesToStore);
      }

      setFormFilesManager(newFilesToStore);
    } else {
      setFormFilesManager(filesToStore);
    }
  };

  const convertedFileSize = (file: PreviewFile) => {
    if (file.size) {
      return Math.floor(file.size / 1000);
    } else {
      return null;
    }
  };

  const renderPreviewImage = () => {
    const fileExtension = file ? mime.extension(file.type) : undefined;
    const isPdf = base64File && fileExtension === FileExtension.Pdf;

    if (isPdf) {
      return (
        <PdfPreview file={base64File as string} size={PdfPreviewSize.Large} />
      );
    } else {
      return (
        <CardMedia
          image={asset?.displayedThumbUrl ?? URL.createObjectURL(file)}
          title={asset?.name ?? file?.path}
          style={{
            height: '300px',
            width: '300px',
            cursor: 'pointer',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            backgroundSize: 'contain',
          }}
        />
      );
    }
  };

  return asset || file ? (
    <Box pt={3}>
      <Box position="relative">
        {conformityFileOrAsset() !== undefined && (
          <StatusBadge
            className={classes.conformityBadge}
            status={
              (conformityFileOrAsset() as boolean)
                ? BadgeStatus.Success
                : BadgeStatus.Error
            }
            message={
              conformityFileOrAsset()
                ? t('dam.conformity.conform')
                : t('dam.conformity.non_conform')
            }
          />
        )}
        {renderPreviewImage()}
      </Box>
      {reportError ? (
        <Typography variant="body2" style={{ color: colors.error }}>
          {reportError}
        </Typography>
      ) : (
        <Box p={2} height="100%" pt={4}>
          <Box px={2}>
            <Box pb={2} display="flex" alignItems="baseline">
              <Box>
                <Typography variant="body2">
                  {t('dam.form_wrapper.extension')}{' '}
                </Typography>
              </Box>
              <Box pl={0.5}>
                <Typography variant="body1">
                  {asset?.metadata?.mimetype ?? file?.type}
                </Typography>
              </Box>
            </Box>
            <Box pb={2}>
              <Divider />
            </Box>
            <Box pb={2} display="flex" alignItems="baseline">
              <Box>
                <Typography variant="body2">
                  {' '}
                  {t('dam.form_wrapper.format')}
                </Typography>
              </Box>
              <Box pl={0.5}>
                <Typography variant="body1">
                  {asset?.metadata?.dimensions?.width &&
                  asset?.metadata?.dimensions?.height
                    ? `${asset?.metadata.dimensions.width} x ${asset.metadata.dimensions.height} px`
                    : `${fileDimensions && fileDimensions.width} x ${
                        fileDimensions && fileDimensions.height
                      } px`}
                </Typography>
              </Box>
            </Box>
            <Box pb={2}>
              <Divider />
            </Box>
            <Box pb={2} display="flex" alignItems="baseline">
              <Box>
                <Typography variant="body2">
                  {t('dam.form_wrapper.weight')}{' '}
                </Typography>
              </Box>
              <Box pl={0.5}>
                <Typography variant="body1">
                  {asset?.metadata?.fileSize
                    ? `${asset?.metadata.fileSize * 1000}  Ko`
                    : `${convertedFileSize(file as PreviewFile)} Ko`}
                </Typography>
              </Box>
            </Box>
            <Box pb={2}>
              <Divider />
            </Box>
            <Box pb={2} display="flex" alignItems="baseline">
              <Box>
                <Typography variant="body2">
                  {t('dam.form_wrapper.credit')}{' '}
                </Typography>
              </Box>
              <Box pl={0.5}>
                <Typography variant="body1">
                  {asset?.metadata?.credit ?? fileCredit}
                </Typography>
              </Box>
            </Box>
            <Box pb={2}>
              <Divider />
            </Box>
          </Box>
        </Box>
      )}
      <Box display="flex" justifyContent="flex-end" pt={3} pr={3}>
        <Button
          color="primary"
          onClick={() =>
            assetId ? setGetAssetId?.(undefined) : setFile?.(undefined)
          }
        >
          {t('pim.form.return')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            addAssetToForm((asset as GetAssetById_getAssetById) ?? file)
          }
          disabled={Boolean(reportError)}
        >
          {t('pim.form.add_media')}
        </Button>
      </Box>
    </Box>
  ) : (
    <Loader error={error} />
  );
};

export default ProductsSheetsFormSingleAsset;
