import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const orderBreadcrumbsStyle = css`
  padding: 10px 0px;
  ol {
    li {
      margin: 0;
    }

    a {
      font-size: 14px;
    }

    svg {
      width: 20px;
      height: 20px;
      margin: 0 4px;
    }

    li:last-of-type a,
    svg {
      color: ${colors.disabled};
    }
  }
`;
