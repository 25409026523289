import React, { useState, useEffect } from 'react';
import colors from 'styles/colors.module.scss';
import {
  useStudioDispatchContext,
  useStudioStateContext,
} from 'modules/workflow/oneStudioContext';
import { studioAppActions } from 'modules/workflow/reducers/actionsInterfaces';
import { Tooltip } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useTranslation } from 'react-i18next';

interface CustomizableSwitchProps {
  pageNumber: number;
}
const ValidatedSwitch = ({
  pageNumber,
}: CustomizableSwitchProps): JSX.Element => {
  const globalState = useStudioStateContext();
  const dispatch = useStudioDispatchContext();

  const [isCurrentPageValidated, setIsCurrentPageValidated] = useState<boolean>(
    globalState.version.pages[pageNumber - 1]?.validated
  );

  useEffect(() => {
    setIsCurrentPageValidated(
      globalState.version.pages[pageNumber - 1]?.validated
    );
  }, [globalState.version.pages, pageNumber]);

  const { t } = useTranslation();

  const validatePage = (isValidated: boolean) =>
    dispatch(
      studioAppActions.setValidPage({
        pageNumber: pageNumber - 1,
        isValidated: !isValidated,
      })
    );

  const isDisabled =
    (!globalState.version.pages[pageNumber - 1].isCustomizable &&
      globalState.document?.isCustomization) ||
    !globalState.isEditable;

  return (
    <Tooltip
      title={
        isDisabled
          ? t('workflow.right_sidebar.validated').toString()
          : t('workflow.validate_page').toString()
      }
      placement="right"
    >
      <CheckCircleIcon
        htmlColor={isCurrentPageValidated ? colors.loader1 : colors.disabled}
        style={{
          width: 49,
          height: 49,
          cursor: isDisabled ? 'auto' : 'pointer',
        }}
        onClick={() => !isDisabled && validatePage(isCurrentPageValidated)}
      />
    </Tooltip>
  );
};

export default ValidatedSwitch;
