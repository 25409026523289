import { ApolloError, ApolloQueryResult } from '@apollo/client';
import { GetAllCampaignsLeanVariables } from 'app/schemaInterfaces/GetAllCampaignsLean';
import { CampaignType } from 'app/schemaInterfaces/globalTypes';
import CampaignForm from 'modules/pro/campaigns/form/CampaignForm/CampaignForm';
import Loader from 'components/Loader/Loader/Loader';
import { GetAllCampaignsLean } from 'app/schemaInterfaces/GetAllCampaignsLean';
import {
  GetCampaignById,
  GetCampaignByIdVariables,
} from 'app/schemaInterfaces/GetCampaignById';
import { useCampaignFormCreation } from './CampaignFormCreation.hooks';

export interface CampaignFormCreationProps {
  handleClose: () => void;
  submitButton: string;
  campaignName: string;
  campaignDescription: string;
  error?: ApolloError;
  id?: string;
  isEditMode: boolean;
  isCampaignModel: boolean;
  refetch?: (
    variables?: GetAllCampaignsLeanVariables
  ) => Promise<ApolloQueryResult<GetAllCampaignsLean>>;
  refetchCampaignById?: (
    variables?: Partial<GetCampaignByIdVariables> | undefined
  ) => Promise<ApolloQueryResult<GetCampaignById>>;
}

const CampaignFormCreation = ({
  handleClose,
  submitButton,
  campaignName,
  campaignDescription,
  error,
  id,
  isEditMode,
  isCampaignModel,
  refetch,
  refetchCampaignById,
}: CampaignFormCreationProps): JSX.Element => {
  const {
    onFormValidation,
    tags,
    category,
    isUploadInProgress,
  } = useCampaignFormCreation(
    isEditMode,
    isCampaignModel,
    handleClose,
    id,
    refetch,
    refetchCampaignById
  );

  return tags && !isUploadInProgress ? (
    <CampaignForm
      allTags={tags}
      allCategories={category}
      onFormValidation={onFormValidation}
      submitButton={submitButton}
      campaignType={
        !isCampaignModel ? CampaignType.National : CampaignType.Model
      }
      campaignName={campaignName}
      campaignDescription={campaignDescription}
      id={id}
      isCampaignModel={isCampaignModel}
    />
  ) : (
    <Loader error={error} />
  );
};

export default CampaignFormCreation;
