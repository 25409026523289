import React from 'react';
import { Box, CardMedia, Divider, Button, Typography } from '@material-ui/core';
import { PreviewFile } from 'modules/pim/baseProducts/ProductsSheetFilesUpload';
import {
  getLabelByUnifiedKeyFromPimClientConfig,
  getUnifiedKeyFromPimClientConfig,
  unifiedKeyField,
  VisuelFieldName,
} from 'modules/pim/baseProducts/ProductsHelper';
import { useTranslation } from 'react-i18next';
import mime from 'mime-types';
import { DEFAULT_IMAGE_PREVIEW, FileExtension } from 'app/utils/common';
import PdfPreview, { PdfPreviewSize } from 'components/PdfPreview/PdfPreview';
import colors from 'styles/colors.module.scss';
import { ProductsDataNormalized } from './PimProductView';
import useAppContext from 'app/app-context/useAppContext';
import { css } from '@emotion/react';
import { useProductsSheetsContext } from '../baseProducts/BaseProducts';
import { productVisual } from '../regroupingHelper';
import { FileStore } from '../baseProducts/PimFormFieldsConfig';
import { useCurrentUser } from 'app/auth/UserContext';

interface ProductsSheetsFormSingleProductProps {
  file?: PreviewFile;
  setFile?: React.Dispatch<React.SetStateAction<PreviewFile | undefined>>;
  base64File?: string;
  reportError?: string | null;
  selectedProduct: ProductsDataNormalized;
  setSelectedProduct: React.Dispatch<
    React.SetStateAction<ProductsDataNormalized | undefined>
  >;
}
const style = css`
  .error-msg {
    color: ${colors.error};
  }
  .card-media {
    height: 300px;
    width: 300px;
    cursor: pointer;
    display: block;
    margin: auto;
    background-size: contain;
  }
`;

const ProductsSheetsFormSingleProduct = ({
  file,
  base64File,
  reportError,
  selectedProduct,
  setSelectedProduct,
}: ProductsSheetsFormSingleProductProps): JSX.Element => {
  const { t } = useTranslation();

  const {
    client: { config: clientConfig },
  } = useAppContext();

  const user = useCurrentUser();

  const {
    regrouping,
    setRegrouping,
    setFormFilesManager,
    formFilesManager,
    setFieldName,
  } = useProductsSheetsContext();

  const renderPreviewImage = () => {
    const fileExtension = file ? mime.extension(file.type) : undefined;
    const isPdf = base64File && fileExtension === FileExtension.Pdf;

    if (isPdf) {
      return (
        <PdfPreview file={base64File as string} size={PdfPreviewSize.Large} />
      );
    } else {
      return (
        <CardMedia
          image={selectedProduct.image ?? DEFAULT_IMAGE_PREVIEW}
          title={selectedProduct.name}
          className="card-media"
        />
      );
    }
  };

  const addProductToRegrouping = (product: ProductsDataNormalized) => {
    if (!Boolean(regrouping.length)) {
      const firstRegrouping = {
        details: {},
        isMain: true,
        productId: '',
        isVisible: true,
        assetId: '',
        createdDate: new Date(),
        userId: '',
      };
      setRegrouping([
        firstRegrouping,
        {
          details: product.details,
          isMain: false,
          productId: product._id,
          isVisible: true,
          assetId: product.details.filesToSend.find(
            (visual: FileStore) =>
              visual.fieldName === VisuelFieldName.mainVisuel
          ).assetId,
          createdDate: new Date(),
          userId: user.id,
        },
      ]);
    } else {
      setRegrouping([
        ...regrouping,
        {
          details: product.details,
          isMain: false,
          productId: product._id,
          isVisible: true,
          assetId: product.details.filesToSend.find(
            (visual: FileStore) =>
              visual.fieldName === VisuelFieldName.mainVisuel
          ).assetId,
          createdDate: new Date(),
          userId: user.id,
        },
      ]);
    }
    setFieldName(undefined);

    setFormFilesManager([
      ...formFilesManager,
      productVisual(product.details.filesToSend, product._id) as FileStore,
    ]);
  };

  return (
    <Box pt={3} css={style}>
      <Box position="relative">{renderPreviewImage()}</Box>
      {reportError ? (
        <Typography variant="body2" className="error-msg">
          {reportError}
        </Typography>
      ) : (
        <Box p={2} height="100%" pt={4}>
          <Box px={2}>
            <Box pb={2} display="flex" alignItems="baseline">
              <Box>
                <Typography variant="body2">
                  {getLabelByUnifiedKeyFromPimClientConfig(
                    unifiedKeyField.Title,
                    clientConfig
                  )}{' '}
                  :
                </Typography>
              </Box>
              <Box pl={0.5}>
                <Typography variant="body1">{selectedProduct.name}</Typography>
              </Box>
            </Box>
            <Box pb={2}>
              <Divider />
            </Box>
            <Box pb={2} display="flex" alignItems="baseline">
              <Box>
                <Typography variant="body2">
                  {' '}
                  {getLabelByUnifiedKeyFromPimClientConfig(
                    unifiedKeyField.ProductCode,
                    clientConfig
                  )}{' '}
                  :
                </Typography>
              </Box>
              <Box pl={0.5}>
                <Typography variant="body1">
                  {
                    selectedProduct.details[
                      getUnifiedKeyFromPimClientConfig(
                        unifiedKeyField.ProductCode,
                        clientConfig
                      )
                    ]
                  }{' '}
                </Typography>
              </Box>
            </Box>
            <Box pb={2}>
              <Divider />
            </Box>
          </Box>
        </Box>
      )}
      <Box display="flex" justifyContent="flex-end" pt={3} pr={3}>
        <Button color="primary" onClick={() => setSelectedProduct(undefined)}>
          {t('pim.form.return')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={Boolean(reportError)}
          onClick={() => addProductToRegrouping(selectedProduct)}
        >
          {t('pim.regrouping.add_product')}
        </Button>
      </Box>
    </Box>
  );
};

export default ProductsSheetsFormSingleProduct;
