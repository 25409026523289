import React from 'react';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs as MaterialBreadcrumbs } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import AppLink from 'components/Navigation/AppLink/AppLink';
import { AppRoute } from 'app/routes/routes';
import { orderBreadcrumbsStyle } from './OrderBreadcrumbs.style';

const OrderBreadcrumbs = ({ orderId }: { orderId?: string }) => {
  const { t } = useTranslation();

  const breadcrumbs = useBreadcrumbs([
    { path: AppRoute.Home, breadcrumb: t('header.home') },
    {
      path: AppRoute.FollowOrders,
      breadcrumb: t('follow_orders.title'),
    },

    {
      path: AppRoute.OrderId,
      breadcrumb: orderId,
    },
  ]);

  return (
    <div css={orderBreadcrumbsStyle}>
      <MaterialBreadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
        {breadcrumbs.map(({ match, breadcrumb }) => (
          <AppLink
            color="textPrimary"
            key={match.url}
            to={match.url as AppRoute}
            underline="none"
          >
            {breadcrumb}
          </AppLink>
        ))}
      </MaterialBreadcrumbs>
    </div>
  );
};

export default OrderBreadcrumbs;
