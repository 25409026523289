import { makeStyles, Tab, Tabs } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import colors from 'styles/colors.module.scss';

export interface DialogTabProps {
  selectedTab: number;
  handleChange?: (newValue: number) => void;
}

const useStyles = makeStyles({
  tabsRoot: {
    '& .MuiTab-root.Mui-selected': {
      backgroundColor: colors.white,
      color: colors.primary,
    },
  },
  tabsIndicator: {
    top: 0,
  },
});

export const DialogTab = ({
  handleChange,
  selectedTab,
}: DialogTabProps): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Tabs
      classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
      value={selectedTab}
      variant="standard"
      onChange={(event, value) => handleChange?.(value)}
      indicatorColor="primary"
    >
      <Tab label={t('dam.dialog_tab.details')} wrapped={true} />
      <Tab label={t('dam.dialog_tab.similar_asset')} wrapped={true} />
    </Tabs>
  );
};
