import { useQuery } from '@apollo/client';
import { GET_CATEGORY } from 'app/graphql/queries/categories';
import {
  GetCategory,
  GetCategoryVariables,
} from 'app/schemaInterfaces/GetCategory';

const useCategoriesQuery = () => {
  const { data, loading, error } = useQuery<GetCategory, GetCategoryVariables>(
    GET_CATEGORY
  );
  return { category: data?.category, loading, error };
};

export default useCategoriesQuery;
