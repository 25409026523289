import colors from 'styles/colors.module.scss';
import { css } from '@emotion/react';

export const sideFormTabsStyle = css`
  & .MuiTab-root.Mui-selected {
    color: ${colors.primary};
  }
  & .MuiButtonBase-root {
    background-color: ${colors.documentsHeaderBackground};
  }

  & .MuiTabs-indicator {
    top: inherit;
  }
`;
