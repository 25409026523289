import {
  ApolloCache,
  useApolloClient,
  useMutation,
  useQuery,
} from '@apollo/client';
import { Button, CircularProgress } from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import Skeleton from '@material-ui/lab/Skeleton/Skeleton';
import {
  CREATE_CAMPAIGN_ATTACHMENT,
  DELETE_CAMPAIGN_ATTACHMENTS,
  GET_ATTACHMENT_URL,
  GET_SOURCE_FILES,
  GET_ZIPPED_ATTACHMENT_URL,
} from 'app/graphql/queries/sourceFiles';
import {
  CreateCampaignAttachment,
  CreateCampaignAttachmentVariables,
} from 'app/schemaInterfaces/CreateCampaignAttachment';
import {
  GetAttachmentUrl,
  GetAttachmentUrlVariables,
} from 'app/schemaInterfaces/GetAttachmentUrl';
import {
  GetCampaignAttachments,
  GetCampaignAttachments_getCampaignAttachments,
  GetCampaignAttachmentsVariables,
} from 'app/schemaInterfaces/GetCampaignAttachments';
import {
  GetZippedAttachmentsUrl,
  GetZippedAttachmentsUrlVariables,
} from 'app/schemaInterfaces/GetZippedAttachmentsUrl';
import { useTranslation } from 'react-i18next';
import { useGetCampaign } from 'modules/workflow/containers/CatalogPreview/campaignQueries';
import { uploadFile } from 'app/utils/FileUpload/FileUpload';
import AppTable from 'components/AppTable/AppTable';
import EmptyTableFileUpload from 'components/EmptyTableFileUpload';
import Loader from 'components/Loader/Loader/Loader';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Row } from 'react-table';
import {
  DeleteCampaignAttachments,
  DeleteCampaignAttachmentsVariables,
} from 'app/schemaInterfaces/DeleteCampaignAttachments';
import OneButtonConfirmationDialog from 'components/ConfirmationDialogs/OneButtonConfirmationDialog/OneButtonConfirmationDialog';
import { tableColumns } from 'modules/pro/SourceFiles/SourceFileHelper';
import SourceFilesToolbar from 'modules/pro/SourceFiles/SourceFilesToolbar';
import mime from 'mime-types';
import {
  ACCEPTED_EXCEL_TYPES,
  ACCEPTED_FORMATS,
  ACCEPTED_PPT_TYPES,
  ACCEPTED_WORD_TYPES,
} from 'modules/dam/form/helpers';
import { useDownloadFileErrorWorkflow } from 'app/utils/customHooks/useDownloadFileErrorWorkflow';

export interface SourceFileFilters {
  size?: { min?: number; max?: number };
  mimetypes?: string[];
  owners?: string[];
  searchBar?: string;
}

const SourceFilesContainer = (): JSX.Element => {
  const { t } = useTranslation();
  const columns = useMemo(() => tableColumns, []);
  const downloadFile = useDownloadFileErrorWorkflow();
  const getRowId = React.useCallback(
    (row: GetCampaignAttachments_getCampaignAttachments) => row.id,
    []
  );

  const [selectedRows, setSelectedRows] = React.useState<
    Row<GetCampaignAttachments_getCampaignAttachments>[]
  >([]);
  const [filters, setFilters] = React.useState<SourceFileFilters>({});
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState<boolean>(
    false
  );

  const { id } = useParams<{ id: string }>();
  const { data: campaignData } = useGetCampaign(id);

  const client = useApolloClient();

  const filtersVariables = useMemo(
    () => ({
      size: filters.size,
      mimetypes: filters.mimetypes?.length ? filters.mimetypes : null,
      userIds: filters.owners?.length ? filters.owners : null,
      filename: filters.searchBar,
    }),
    [filters]
  );

  const { data, error } = useQuery<
    GetCampaignAttachments,
    GetCampaignAttachmentsVariables
  >(GET_SOURCE_FILES, {
    variables: {
      campaignId: id,
      filter: filtersVariables,
    },
  });

  // get data for the options of the filters (without applying filters)
  const { data: dataFilters } = useQuery<
    GetCampaignAttachments,
    GetCampaignAttachmentsVariables
  >(GET_SOURCE_FILES, {
    variables: {
      campaignId: id,
    },
  });

  const [campaignAttachments, setCampaignAttachments] = React.useState<
    GetCampaignAttachments_getCampaignAttachments[]
  >([]);

  const readAttachmentsQuery = useCallback(
    (
      cache: ApolloCache<CreateCampaignAttachment | DeleteCampaignAttachments>
    ) => {
      return cache.readQuery<
        GetCampaignAttachments,
        GetCampaignAttachmentsVariables
      >({
        query: GET_SOURCE_FILES,
        variables: {
          campaignId: id,
        },
      });
    },
    [id]
  );

  const readAttachmentsQueryWithFilters = useCallback(
    (
      cache: ApolloCache<CreateCampaignAttachment | DeleteCampaignAttachments>
    ) => {
      return cache.readQuery<
        GetCampaignAttachments,
        GetCampaignAttachmentsVariables
      >({
        query: GET_SOURCE_FILES,
        variables: {
          campaignId: id,
          filter: filtersVariables,
        },
      });
    },
    [id, filtersVariables]
  );

  const writeAttachmentsQuery = useCallback(
    (
      cache: ApolloCache<CreateCampaignAttachment | DeleteCampaignAttachments>,
      data: GetCampaignAttachments_getCampaignAttachments[]
    ) => {
      cache.writeQuery<GetCampaignAttachments>({
        query: GET_SOURCE_FILES,
        variables: {
          campaignId: id,
        },
        data: {
          getCampaignAttachments: data,
        },
      });
    },
    [id]
  );

  const writeAttachmentsQueryWithFilters = useCallback(
    (
      cache: ApolloCache<CreateCampaignAttachment | DeleteCampaignAttachments>,
      data: GetCampaignAttachments_getCampaignAttachments[]
    ) => {
      cache.writeQuery<GetCampaignAttachments>({
        query: GET_SOURCE_FILES,
        variables: {
          campaignId: id,
          filter: filtersVariables,
        },
        data: {
          getCampaignAttachments: data,
        },
      });
    },
    [id, filtersVariables]
  );

  const [createAttachment] = useMutation<
    CreateCampaignAttachment,
    CreateCampaignAttachmentVariables
  >(CREATE_CAMPAIGN_ATTACHMENT, {
    update(cache, { data }) {
      const documentsByCampaignId = readAttachmentsQuery(cache);
      const documentsByCampaignIdFilters = readAttachmentsQueryWithFilters(
        cache
      );
      if (documentsByCampaignId && data?.createCampaignAttachment) {
        writeAttachmentsQuery(cache, [
          ...documentsByCampaignId.getCampaignAttachments,
          data.createCampaignAttachment,
        ]);
      }
      if (documentsByCampaignIdFilters && data?.createCampaignAttachment) {
        writeAttachmentsQueryWithFilters(cache, [
          ...documentsByCampaignIdFilters.getCampaignAttachments,
          data.createCampaignAttachment,
        ]);
      }
    },
  });

  const [
    deleteCampaignAttachments,
    { loading: deleteAttachmentsLoading },
  ] = useMutation<
    DeleteCampaignAttachments,
    DeleteCampaignAttachmentsVariables
  >(DELETE_CAMPAIGN_ATTACHMENTS, {
    update(cache) {
      const campaignAttachments = readAttachmentsQuery(cache);
      const campaignAttachmentsWithFilters = readAttachmentsQueryWithFilters(
        cache
      );
      if (campaignAttachments) {
        writeAttachmentsQuery(
          cache,
          campaignAttachments.getCampaignAttachments.filter(
            ({ id }) => !selectedRows.find(({ id: rowId }) => id === rowId)
          )
        );
      }
      if (campaignAttachmentsWithFilters) {
        writeAttachmentsQueryWithFilters(
          cache,
          campaignAttachmentsWithFilters.getCampaignAttachments.filter(
            ({ id }) => !selectedRows.find(({ id: rowId }) => id === rowId)
          )
        );
      }
    },
  });

  const download = async () => {
    if (selectedRows.length > 1) {
      const attachmentIds = selectedRows.map((row) => row.original.id);
      const zippedFilesUrl = await client.query<
        GetZippedAttachmentsUrl,
        GetZippedAttachmentsUrlVariables
      >({
        query: GET_ZIPPED_ATTACHMENT_URL,
        variables: {
          attachmentIds,
          campaignId: id,
        },
      });
      downloadFile({
        url: zippedFilesUrl.data.getZippedAttachmentsUrl.url,
        filename: `${campaignData?.campaign.name}-fichiers joints.zip`,
        mimetype: 'application/zip',
      });
    } else {
      selectedRows.map(async (row) => {
        const response = await client.query<
          GetAttachmentUrl,
          GetAttachmentUrlVariables
        >({
          query: GET_ATTACHMENT_URL,
          variables: {
            attachmentId: row.original.id,
          },
        });

        downloadFile({
          url: response.data.getAttachmentUrl.url,
          filename: row.original.filename,
          mimetype: row.original.mimetype,
        });
      });
    }
  };

  const handleDeleteConfirmationClick = async () => {
    if (selectedRows.length > 0) {
      const attachmentIds = selectedRows.map((row) => row.original.id);
      await deleteCampaignAttachments({
        variables: {
          campaignId: id,
          attachmentIds,
        },
      });
    }
  };

  const onFileChange = async (files: File[]) => {
    if (files.length) {
      files.map(async (file: File) => {
        const mimetype = !!file.type
          ? file.type
          : mime.lookup(file.name.split('.').pop() as string);

        const variables = {
          attachment: {
            campaignId: id,
            filename: file.name,
            size: Math.floor(file.size / 1000),
            mimetype: !!mimetype ? mimetype : '',
          },
        };
        const result = await createAttachment({ variables });
        if (result.data) {
          await uploadFile(
            file,
            result.data.createCampaignAttachment.uploadUrl as string,
            !!mimetype ? mimetype : ''
          );
        }
      });
    }
  };

  const toolbarButtons: React.ReactNode[] = [
    <Button
      variant="text"
      color="primary"
      onClick={() => download()}
      startIcon={<GetAppOutlinedIcon />}
    >
      {t('global.attachments.toolbar.download_button')}
    </Button>,
    <Button
      variant="text"
      color="primary"
      onClick={() => setIsDeleteDialogOpen(true)}
      disabled={deleteAttachmentsLoading}
      startIcon={
        deleteAttachmentsLoading ? (
          <CircularProgress size={20} />
        ) : (
          <FileCopyOutlinedIcon />
        )
      }
    >
      {t('common.delete_confirmation_dialog.title')}
    </Button>,
  ];

  useEffect(() => {
    const campaignAttachments = data?.getCampaignAttachments.map(
      (attachment) => {
        const attachmentWithExtension = { ...attachment };
        attachmentWithExtension.mimetype = mime.extension(
          attachment.mimetype
        ) as string;
        return attachmentWithExtension;
      }
    );
    if (campaignAttachments) {
      setCampaignAttachments(campaignAttachments);
    }
  }, [data]);

  return (
    <>
      <OneButtonConfirmationDialog
        isOpen={isDeleteDialogOpen}
        title={t('common.delete_confirmation_dialog.title')}
        description={t(
          'global.attachments.delete_confirmation_dialog_description',
          { count: selectedRows.length }
        )}
        onClick={() => handleDeleteConfirmationClick()}
        onClose={() => setIsDeleteDialogOpen(false)}
      />
      {dataFilters ? (
        <>
          {dataFilters?.getCampaignAttachments.length > 0 && (
            <SourceFilesToolbar
              searchPlaceholder={t('global.attachments.search_document')}
              onFileChange={onFileChange}
              acceptedFiles={[
                ...ACCEPTED_FORMATS,
                ...ACCEPTED_EXCEL_TYPES,
                ...ACCEPTED_PPT_TYPES,
                ...ACCEPTED_WORD_TYPES,
              ]}
              filters={filters}
              dataFilters={dataFilters?.getCampaignAttachments ?? []}
              setFilters={setFilters}
            />
          )}
        </>
      ) : (
        <Skeleton variant="rect" />
      )}

      {data ? (
        <>
          {!data?.getCampaignAttachments.length &&
          Object.keys(filters).length === 0 ? (
            <EmptyTableFileUpload
              img={`/${process.env.REACT_APP_ASSETS}/emptySourceFiles.svg`}
              title={t('global.attachments.no_document')}
              subtitle={t('global.attachments.drag_and_drop_file')}
              buttonLabel={t('global.attachments.import_files')}
              onFileChange={onFileChange}
              acceptedFiles={[
                ...ACCEPTED_FORMATS,
                ...ACCEPTED_EXCEL_TYPES,
                ...ACCEPTED_PPT_TYPES,
                ...ACCEPTED_WORD_TYPES,
              ]}
            />
          ) : (
            <AppTable<GetCampaignAttachments_getCampaignAttachments>
              columns={columns}
              data={campaignAttachments ?? []}
              hasSelection={true}
              columnSorting={true}
              hasToolbar={true}
              getRowId={getRowId}
              toolBarButtons={toolbarButtons}
              setSelectedRows={setSelectedRows}
            />
          )}
        </>
      ) : (
        <Loader error={error} />
      )}
    </>
  );
};

export default SourceFilesContainer;
