import React, { FC } from 'react';
import {
  Dialog,
  Box,
  IconButton,
  Typography,
  DialogProps,
  Card,
  CardContent,
} from '@material-ui/core';
import { tagBackgroundColors, tagTextColors } from 'app/utils/colorsCodesMap';
import CloseIcon from '@material-ui/icons/Close';
import Tag from 'modules/pro/components/Tag';
import { useTranslation } from 'react-i18next';
import { dialogPreviewImageStyle } from './DialogPreview.style';
import { formattedDateFrom, formattededDateTo } from 'app/utils/date/format';

export interface DialogPreviewProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string;
  maxWidth?: DialogProps['maxWidth'];
  dateFrom?: Date;
  dateTo?: Date;
  isCampaignModel?: boolean;
  description?: string | null;
  tags?: (string | null)[];
  imageUrl?: string | null;
  dialogWidth?: number;
}

const DialogPreview: FC<DialogPreviewProps> = ({
  open,
  setOpen,
  title,
  maxWidth,
  dateFrom,
  dateTo,
  isCampaignModel,
  description,
  tags,
  imageUrl,
}) => {
  const { t } = useTranslation();

  function handleClose() {
    setOpen(false);
  }

  return (
    <Dialog
      open={open}
      maxWidth={maxWidth}
      fullWidth
      onClose={handleClose}
      data-testid="dialog"
    >
      <Card>
        <Box
          height={167}
          css={dialogPreviewImageStyle(imageUrl)}
          display="flex"
          justifyContent="flex-end"
        >
          <Box>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <CardContent>
          <Box pl={2}>
            <Typography variant="h2">{title}</Typography>
            {!isCampaignModel && (
              <Typography variant="body2">
                {t('local.common.from_to_date', {
                  dateFrom: dateFrom && formattedDateFrom(dateFrom),
                  dateTo: dateTo && formattededDateTo(dateTo),
                })}
              </Typography>
            )}
            <Box display="flex" pt={1} flexWrap="wrap" pb={2}>
              {tags?.map(
                (title, index) =>
                  title && (
                    <Box pr={1} key={index} pb={1}>
                      <Tag
                        title={title}
                        backgroundColor={tagBackgroundColors.get(
                          title.trim()[0].toUpperCase()
                        )}
                        titleColor={tagTextColors.get(
                          title.trim()[0].toUpperCase()
                        )}
                        isOverFlowEllipsis={false}
                      />
                    </Box>
                  )
              )}
            </Box>
            <Typography>{description}</Typography>
          </Box>
        </CardContent>
      </Card>
    </Dialog>
  );
};

export default DialogPreview;
