import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';
import { theme } from 'styles/theme';

export const rootStyle = (itemPaddingLeft: number, itemColor?: string) => css`
  color: ${colors.grey};
  font-family: ScandiaWebMedium;
  padding-top: 0;
  padding-bottom: 0;
  color: ${itemColor ?? colors.grey};
  padding-left: ${theme.spacing(itemPaddingLeft)}px;
  &:hover {
    background-color: transparent;
  }
`;

export const selectedStyle = (selectedItemColor?: string) => css`
  color: ${selectedItemColor ?? colors.primary};
`;
