import { SelectedBasketdocument } from 'modules/sales/sales.state';
import { useBasketConfirmationPricingRequest } from 'modules/sales/utils/useBasketConfirmationPricingRequest';

export interface UseBasketConfirmationSummaryData {
  basketDocuments: SelectedBasketdocument[];
}

export function useBasketConfirmationSummary({
  basketDocuments,
}: UseBasketConfirmationSummaryData) {
  return useBasketConfirmationPricingRequest({
    products: basketDocuments.map((it) => ({
      ...it.basketDocument,
      quantity: it.addresses.reduce((a, x) => a + x.quantity, 0),
    })),
  });
}
