import { useMutation } from '@apollo/client';
import useCurrentEntity from 'app/current-entity-context/useCurrentEntity';
import {
  BASKET_DOCUMENTS,
  REMOVE_BASKET_DOCUMENTS,
} from 'app/graphql/queries/basket';
import {
  RemoveBasketDocuments,
  RemoveBasketDocumentsVariables,
} from 'app/schemaInterfaces/RemoveBasketDocuments';

const useRemoveBasketDocumentMutation = () => {
  const { currentEntity } = useCurrentEntity();
  const selectedEntityId = currentEntity?.id as string; //TODO: current entity can be null and we need to handle this

  const [
    removeBasketDocuments,
    {
      data: removeBasketDocumentsData,
      error: removeBasketDocumentsError,
      loading: removeBasketDocumentsLoading,
    },
  ] = useMutation<RemoveBasketDocuments, RemoveBasketDocumentsVariables>(
    REMOVE_BASKET_DOCUMENTS,
    {
      refetchQueries: [
        {
          query: BASKET_DOCUMENTS,
          variables: {
            filter: {
              entityId: selectedEntityId,
            },
          },
        },
      ],
    }
  );

  return {
    removeBasketDocuments,
    removeBasketDocumentsData,
    removeBasketDocumentsError,
    removeBasketDocumentsLoading,
  };
};

export default useRemoveBasketDocumentMutation;
