import {
  ConditioningOperator,
  DocumentOptions,
  DocumentOrderType,
} from 'app/schemaInterfaces/globalTypes';
import { TFunction } from 'react-i18next';

// Source: onemanagerv2-web

export function getDocumentOrderTypeName(
  type: DocumentOrderType,
  t: TFunction
): string {
  switch (type) {
    case DocumentOrderType.Commitment:
      return t('follow_orders.workflow_box.type_commitment');
    case DocumentOrderType.FreeOrder:
      return t('follow_orders.workflow_box.type_order');
    default:
      return type;
  }
}

export function getDocumentOptionName(
  option: DocumentOptions,
  t: TFunction
): string {
  switch (option) {
    case DocumentOptions.customizable:
      return t('follow_orders.workflow_box.option_customizable');
    case DocumentOptions.notCustomizable:
      return t('follow_orders.workflow_box.option_noncustomizable');
    default:
      return option;
  }
}

export function getConditioningOperatorName(
  option: ConditioningOperator,
  t: TFunction
): string {
  switch (option) {
    case ConditioningOperator.upper:
      return t('follow_orders.workflow_box.criterion_greater');
    case ConditioningOperator.lower:
      return t('follow_orders.workflow_box.criterion_lesser');
    default:
      return option;
  }
}

export function joinListInSentence(
  list: string[],
  type: 'and' | 'or' | 'both',
  t: TFunction
): string {
  if (list.length === 0) {
    return '';
  } else if (list.length === 1) {
    return list[0];
  }

  const body = list.slice(0, list.length - 1);
  const tail = list[list.length - 1];
  return `${body.join(', ')} ${t(
    `follow_orders.workflow_box.list_join_${type}`
  )} ${tail}`;
}

export function arrayAt<T>(
  array: T[] | null | undefined,
  index: number
): T | undefined {
  if (array && array.length > index) {
    return array[index];
  } else {
    return undefined;
  }
}
