import {
  Box,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import AccessTimeOutlinedIcon from '@material-ui/icons/AccessTimeOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import FindInPageIcon from '@material-ui/icons/FindInPageOutlined';
import GetAppIcon from '@material-ui/icons/GetApp';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import { GetDocuments_documents_documents } from 'app/schemaInterfaces/GetDocuments';
import { OrderProductStatus } from 'app/schemaInterfaces/globalTypes';
import { DEFAULT_IMAGE_PREVIEW, formatPriceHT } from 'app/utils/common';
import { useDownloadFileErrorWorkflow } from 'app/utils/customHooks/useDownloadFileErrorWorkflow';
import { isDigitalDocument } from 'app/utils/digital-helper';
import DocumentPreview from 'components/ConfirmationDialogs/DocumentPreview/DocumentPreview';
import StatusIcon from 'components/StatusIcon/StatusIcon';
import { filteredAttributes } from 'modules/sales/components/QuantitySelectHelper';
import IsCustomizableTag from 'modules/shop/components/IsCustomizableTag';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import colors from 'styles/colors.module.scss';
import itemRowStyle from 'styles/itemRow.style';
import { convertTimestampToDate } from '../../../../../app/utils/date/format';
import { PublicationDate } from '../../../OrderCampaignsWithDocuments/OrderCampaignsWithDocuments';
import { orderDocumentRowStyle } from './OrderDocumentRow.stye';

export interface DocumentItem {
  document: GetDocuments_documents_documents;
  transportSellingPriceHT?: number | null;
  orderMetadata: {
    addresses: Address[];
    sumPrice?: number | null;
    clientNetworkLink?: string | null;
    needsValidation: boolean;
    lientNetworkLink?: string | null;
    status?: OrderProductStatus | null;
    publicationDate?: PublicationDate | null;
  };
}

export interface Address {
  name?: string;
  quantity?: number | null;
}

const OrderDocumentRow: React.FC<DocumentItem> = ({
  document,
  orderMetadata,
  transportSellingPriceHT,
}) => {
  const { t } = useTranslation();

  const {
    sumPrice,
    clientNetworkLink,
    addresses,
    needsValidation,
    status,
    publicationDate,
  } = orderMetadata;

  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.down('lg'));
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);
  const onClosePreview = () => setIsPreviewOpen(false);
  const onOpenPreview = () => setIsPreviewOpen(true);
  const downloadFile = useDownloadFileErrorWorkflow();
  const handleDownloadFile = () =>
    downloadFile({
      url: document.resourcesFile!.url,
      filename: document.resourcesFile!.filename,
      mimetype: document.resourcesFile!.mimetype,
    });

  const totalQuantity = addresses?.reduce((acc, currentAddress) => {
    return acc + (currentAddress.quantity ?? 0);
  }, 0);

  const isShowClientNetworkLink = useMemo<boolean>(
    () => document.category && isDigitalDocument(document.category),
    [document.category]
  );
  const hasClientNetworkLinkError = useMemo<boolean>(
    () =>
      isShowClientNetworkLink &&
      (status === null || status === OrderProductStatus.Faillure),
    [isShowClientNetworkLink, status]
  );

  return (
    <div>
      <div>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          css={[orderDocumentRowStyle(lg), itemRowStyle]}
          className="document-row"
        >
          <Grid
            xs={12}
            sm={6}
            md={3}
            item
            className="media-wrapper delivery document-thumbnail--container"
          >
            <img
              src={document.thumbnailUrl ?? DEFAULT_IMAGE_PREVIEW}
              alt="document"
              className="media"
            />
            <IconButton onClick={onOpenPreview}>
              <FindInPageIcon />
            </IconButton>
          </Grid>

          <Grid xs={12} sm={6} md={2} item>
            <div className="document-title">
              <h5>
                {document.name} - {document.version}{' '}
              </h5>
            </div>
            <Box display="flex">
              <IsCustomizableTag isCustomizable={document.isCustomizable} />
              {document.isCustomizable && document.isValidated && (
                <CheckCircleOutlineIcon
                  htmlColor={colors.loader1}
                  style={{ marginLeft: '12px' }}
                />
              )}
            </Box>

            {hasClientNetworkLinkError && (
              <div className="tag-wrapper-error">
                <div>
                  <Typography>
                    {t('sales.basket_page.summary_page.post_error_message')}
                  </Typography>
                </div>
              </div>
            )}
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={isShowClientNetworkLink ? 4 : 2}
            className="addresses"
          >
            <div>
              <h6>
                {t(
                  document.category && isDigitalDocument(document.category)
                    ? 'sales.basket_page.summary_page.delivery_addresses.broadcast_page'
                    : 'sales.basket_page.summary_page.delivery_addresses.delivery_points'
                )}
              </h6>
              {isShowClientNetworkLink ? (
                <div className="social_network_url">
                  <div
                    className="link align_vertical"
                    onClick={() =>
                      clientNetworkLink && window.open(clientNetworkLink)
                    }
                  >
                    <span>
                      <LocationOnOutlinedIcon fontSize="medium" />
                    </span>
                    <span className="network-link">
                      {clientNetworkLink ?? ''}
                    </span>
                  </div>
                  <div className="publication_date align_vertical">
                    <span>
                      <AccessTimeOutlinedIcon fontSize="medium" />
                    </span>
                    <span></span>
                    {publicationDate
                      ? convertTimestampToDate(
                          publicationDate,
                          'dd/MM/yyyy HH:mm'
                        )
                      : ''}
                  </div>
                </div>
              ) : (
                addresses?.map((address, index) => (
                  <p key={index}>
                    <LocationOnOutlinedIcon />
                    <span>{address?.name} </span>
                    <span>
                      (
                      {
                        <Tooltip
                          title={
                            t(
                              'sales.basket_page.summary_page.delivery_addresses.quantity_per_address'
                            ) ?? ''
                          }
                        >
                          <span>
                            {document.category &&
                            isDigitalDocument(document.category)
                              ? 'digital'
                              : address.quantity}
                          </span>
                        </Tooltip>
                      }
                      )
                    </span>
                  </p>
                ))
              )}
            </div>
          </Grid>

          {!isShowClientNetworkLink &&
            (!document.category ||
              (document.category && !isDigitalDocument(document.category))) && (
              <>
                <Grid xs={12} sm={6} md={1} item>
                  <div>
                    <h6>
                      {t(
                        'sales.basket_page.summary_page.delivery_addresses.quantity'
                      )}
                    </h6>
                  </div>

                  <div className="quantity">
                    <span>
                      {t(
                        'sales.basket_page.summary_page.delivery_addresses.quantity_copy',
                        { count: totalQuantity }
                      )}
                    </span>
                  </div>
                </Grid>

                <Grid item xs={10} sm={6} md={1} className="purchase_price">
                  <h6>
                    {t(
                      'sales.basket_page.summary_page.delivery_addresses.purchase_price'
                    )}
                  </h6>
                  <span>
                    {!sumPrice
                      ? t('common.included')
                      : formatPriceHT(sumPrice, t)}
                  </span>
                </Grid>

                <Grid item xs={10} sm={6} md={1} className="shipping_cost">
                  <h6>
                    {t(
                      'sales.basket_page.summary_page.delivery_addresses.shipping_cost'
                    )}
                  </h6>
                  <span>
                    {!transportSellingPriceHT
                      ? t('common.included')
                      : formatPriceHT(transportSellingPriceHT, t)}
                  </span>
                </Grid>
              </>
            )}

          <Grid item xs={10} sm={6} md={1} className="price">
            <h3>
              {!sumPrice && !transportSellingPriceHT
                ? t('common.included')
                : formatPriceHT(
                    (sumPrice || 0) +
                      (addresses && addresses.length && transportSellingPriceHT
                        ? transportSellingPriceHT * addresses.length
                        : 0),
                    t
                  )}
            </h3>
          </Grid>

          <Grid xs={2} sm={6} md={1} item>
            <Box
              display="flex"
              justifyContent={sm ? 'flex-start' : 'flex-end'}
              className="document-row-actions"
              alignItems="center"
            >
              <IconButton
                disabled={!document.resourcesFile}
                onClick={handleDownloadFile}
              >
                <Tooltip
                  title={t('follow_orders.order_item_download_support') ?? ''}
                >
                  <GetAppIcon />
                </Tooltip>
              </IconButton>
              {needsValidation && (
                <Tooltip
                  title={
                    t(
                      'follow_orders.React, order_item_waiting_for_validation'
                    ) ?? ''
                  }
                >
                  <StatusIcon variant="warning" />
                </Tooltip>
              )}
            </Box>
          </Grid>
        </Grid>
      </div>
      <DocumentPreview
        isOpen={isPreviewOpen}
        onClose={onClosePreview}
        version={document.version}
        title={document.name}
        url={document.thumbnailUrl}
        isCustomizable={document.isCustomizable}
        category={document.category}
        mediumType={document.mediumType}
        attributes={filteredAttributes(document.productAttributes)}
      />
    </div>
  );
};

export default OrderDocumentRow;
