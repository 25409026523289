import {
  ARCHIVE_CAMPAIGN_BY_ID,
  GET_ALL_CAMPAIGNS,
} from 'app/graphql/queries/campaigns';
import { Action, ActionsMenu } from 'components/ActionsMenu/ActionsMenu';
import { ApolloQueryResult, useMutation } from '@apollo/client';
import {
  ArchiveCampaignById,
  ArchiveCampaignByIdVariables,
} from 'app/schemaInterfaces/ArchiveCampaignById';
import {
  AscDescType,
  BigShopConfigCategory,
  CampaignType,
  PermissionKey,
} from 'app/schemaInterfaces/globalTypes';
import { Box, CardMedia, Tooltip, Typography } from '@material-ui/core';
import {
  GetAllCampaignsLean,
  GetAllCampaignsLeanVariables,
  GetAllCampaignsLean_getAllCampaigns_campaigns,
  GetAllCampaignsLean_getAllCampaigns_campaigns_category,
} from 'app/schemaInterfaces/GetAllCampaignsLean';
import React, { Suspense } from 'react';
import {
  ShopRoute,
  getLocalCampaignLink,
  getNationalCampaignLink,
} from 'modules/shop/shopHelpers';
import { tagBackgroundColors, tagTextColors } from 'app/utils/colorsCodesMap';

import AppLink from 'components/Navigation/AppLink/AppLink';
import CampaignFormCreation from 'modules/pro/campaigns/form/CampaignFormCreation/CampaignFormCreation';
import { CampaignI18nKeyErrorDictionary } from 'modules/pro/campaigns/campaignsHelper';
import DevicesOutlinedIcon from '@material-ui/icons/DevicesOutlined';
import DialogPreview from 'components/DialogPreview/DialogPreview';
import FormWrapper from 'components/FormWrapper/FormWrapper';
import MapOutlinedIcon from '@material-ui/icons/MapOutlined';
import { Skeleton } from '@material-ui/lab';
import StyleOutlinedIcon from '@material-ui/icons/StyleOutlined';
import Tag from 'modules/pro/components/Tag';
import { getSupportCategory } from '../../documents/form/model/mappers';
import moment from 'moment';
import { useCampaignCreation } from 'modules/pro/campaigns/CampaignCreation/CampaignCreation.hooks';
import useCurrentEntity from 'app/current-entity-context/useCurrentEntity';
import { useImage } from 'react-image';
import { useMutationErrorWorkflow } from 'app/utils/customHooks/useMutationErrorWorkflow';
import usePermissions from 'app/auth/usePermissions';
import { useTableLine } from './TableLine.hooks';
import { useTranslation } from 'react-i18next';

interface TableLineProps {
  title?: string;
  tags?: (string | null)[];
  category?: GetAllCampaignsLean_getAllCampaigns_campaigns_category | null;
  imageUrl?: string | null;
  customerRef?: string | null;
  action?: React.ReactNode;
  dateFrom?: Date;
  dateTo?: Date;
  description?: string | null;
  documentCategories?: GetAllCampaignsLean_getAllCampaigns_campaigns['documentCategories'];
  imageWidth: number;
  columnsWidth: number[];
  actions: (
    manageCampaign: () => void,
    handleOpenCampaignDialog: () => void,
    previewCampaign: () => void,
    deleteCampaign: () => void,
    isDisabled?: boolean
  ) => Action[];
  campaignId: string;
  button: React.ReactNode;
  isCampaignModel: boolean;
  refetch: (
    variables?: Partial<GetAllCampaignsLeanVariables> | undefined
  ) => Promise<ApolloQueryResult<GetAllCampaignsLean>>;
}

export const TableLine = ({
  imageUrl,
  title,
  dateFrom,
  dateTo,
  tags,
  category,
  customerRef,
  columnsWidth,
  imageWidth,
  actions,
  campaignId,
  documentCategories,
  button,
  isCampaignModel,
  refetch,
  description,
}: TableLineProps): JSX.Element => {
  const { t } = useTranslation();
  const formattedDateFrom: string | null = dateFrom
    ? moment(dateFrom).format('DD MMMM')
    : null;
  const formattededDateTo: string | null = dateTo
    ? moment(dateTo).format('DD MMMM YYYY')
    : null;

  const { hasPermission } = usePermissions();
  const {
    previewCampaign,
    handleOpenCampaignDialog,
    isCampaignPreviewOpen,
    setCampaignPreviewOpen,
    handleCloseCampaignDialog,
    isCampaignDialogOpen,
    openActionCreateCampaignDialog,
    setOpenActionCreateCampaignDialog,
  } = useCampaignCreation(campaignId);

  const handleOpenActions = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setOpenActionCreateCampaignDialog(event.currentTarget);
  };
  const handleCloseActions = () => {
    setOpenActionCreateCampaignDialog(null);
  };

  const { currentEntity } = useCurrentEntity();

  const { manageCampaign } = useTableLine(campaignId);

  const [archiveCampaignById] = useMutation<
    ArchiveCampaignById,
    ArchiveCampaignByIdVariables
  >(ARCHIVE_CAMPAIGN_BY_ID, {
    refetchQueries: [
      {
        query: GET_ALL_CAMPAIGNS,
        variables: {
          filter: {
            entityId: currentEntity?.id,
            campaignType: isCampaignModel
              ? CampaignType.Model
              : CampaignType.National,
            sort: isCampaignModel
              ? { createdAt: AscDescType.desc }
              : { dateFrom: AscDescType.asc },
          },
        },
      },
    ],
  });

  const deleteCampaignWithErrorWorkflow = useMutationErrorWorkflow({
    request: archiveCampaignById,
    refetch,
    i18nKeyErrorDictionary: CampaignI18nKeyErrorDictionary,
    i18nKeyConfirmationMessage: 'common.actions.delete_success',
    variables: { id: campaignId },
    afterRequestStartCallback: handleCloseActions,
  });

  function LoadedImage({ imageUrl }: { imageUrl: any }) {
    const { src } = useImage({
      srcList: [
        imageUrl,
        `/${process.env.REACT_APP_ASSETS}/noPreviewImageSquare.png`,
      ],
    });

    return <CardMedia image={src} component="img" alt={title} height="80" />;
  }

  return (
    <Box width="100%" display="flex">
      <AppLink
        to={
          isCampaignModel
            ? (getLocalCampaignLink(campaignId) as ShopRoute)
            : (getNationalCampaignLink(campaignId) as ShopRoute)
        }
        style={{ width: '100%' }}
        color="inherit"
        underline="none"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Box display="flex" width={`${columnsWidth[0]}px`}>
            <Box width={imageWidth}>
              <Suspense
                fallback={
                  <Skeleton animation="wave" height={80} variant="rect" />
                }
              >
                <LoadedImage imageUrl={imageUrl} />
              </Suspense>
            </Box>
            <Box
              pl={3}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Typography variant="body2">{title}</Typography>
              {tags && (
                <Box pt={1} display="flex" alignItems="center">
                  {tags.slice(0, 3).map(
                    (title, index) =>
                      title && (
                        <Box pr={1} key={index}>
                          <Tag
                            title={title}
                            backgroundColor={tagBackgroundColors.get(
                              title.trim()[0].toUpperCase()
                            )}
                            titleColor={tagTextColors.get(
                              title.trim()[0].toUpperCase()
                            )}
                          />
                        </Box>
                      )
                  )}
                  {tags.length > 3 && (
                    <Tooltip
                      title={
                        tags
                          .slice(3)
                          .reduce(
                            (tagTotal, tagCurrent) =>
                              `${tagTotal}, ${tagCurrent}`
                          ) as string
                      }
                    >
                      <Typography variant="body2">
                        + {tags.length - 3}
                      </Typography>
                    </Tooltip>
                  )}
                </Box>
              )}

              <Box pt={1} display="flex" alignItems="center">
                <Box pr={1}>
                  {isCampaignModel && category && (
                    <Tag
                      title={category?.title}
                      backgroundColor={tagBackgroundColors.get(
                        category?.title.trim()[0].toUpperCase()
                      )}
                      titleColor={tagTextColors.get(
                        category?.title.trim()[0].toUpperCase()
                      )}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            display="flex"
            justifyContent="flex-start"
            width={`${columnsWidth[1]}px`}
          >
            {!isCampaignModel && (
              <Typography>{`Du ${formattedDateFrom} au ${formattededDateTo}`}</Typography>
            )}
          </Box>

          <Box
            display="flex"
            justifyContent="flex-start"
            width={`${columnsWidth[2]}px`}
          >
            <Box display="flex" width="50%" justifyContent="space-around">
              {documentCategories?.includes(
                BigShopConfigCategory.AdvertisingPrints
              ) && (
                <Tooltip
                  title={getSupportCategory(
                    BigShopConfigCategory.AdvertisingPrints,
                    t
                  )}
                >
                  <StyleOutlinedIcon color="disabled" />
                </Tooltip>
              )}
              {documentCategories?.includes(
                BigShopConfigCategory.SignagePos
              ) && (
                <Tooltip
                  title={getSupportCategory(
                    BigShopConfigCategory.SignagePos,
                    t
                  )}
                >
                  <MapOutlinedIcon color="disabled" />
                </Tooltip>
              )}
              {documentCategories?.includes(BigShopConfigCategory.Digital) && (
                <Tooltip
                  title={getSupportCategory(BigShopConfigCategory.Digital, t)}
                >
                  <DevicesOutlinedIcon color="disabled" />
                </Tooltip>
              )}
            </Box>
          </Box>
          <Box width={`${columnsWidth[3]}px`} style={{ lineBreak: 'anywhere' }}>
            <Typography variant="body1">{customerRef}</Typography>
          </Box>
        </Box>
      </AppLink>
      {hasPermission(
        PermissionKey.AccessModelCampaign &&
          PermissionKey.AccessNationalCampaign
      ) && (
        <Box display="flex" width={`${columnsWidth[4]}px`} alignItems="center">
          <ActionsMenu
            actions={actions(
              manageCampaign,
              handleOpenCampaignDialog,
              deleteCampaignWithErrorWorkflow,
              previewCampaign
            )}
            button={button}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            handleMenuClick={handleOpenActions}
            handleMenuClose={handleCloseActions}
            anchorEl={openActionCreateCampaignDialog}
          />
        </Box>
      )}

      <Box pt={1}>
        <DialogPreview
          imageUrl={imageUrl}
          title={title}
          dateFrom={dateFrom}
          dateTo={dateTo}
          tags={tags}
          isCampaignModel={isCampaignModel}
          description={description}
          maxWidth="sm"
          open={isCampaignPreviewOpen}
          setOpen={setCampaignPreviewOpen}
        />
      </Box>

      {/* here */}
      <FormWrapper
        open={isCampaignDialogOpen}
        handleClose={handleCloseCampaignDialog}
        title={
          !isCampaignModel
            ? t('global.national_campaigns.modify_campaign')
            : t('global.thematics.modify_thematic')
        }
        maxWidth="sm"
      >
        <CampaignFormCreation
          handleClose={handleCloseCampaignDialog}
          submitButton={
            !isCampaignModel
              ? t('global.national_campaigns.modify_campaign')
              : t('global.thematics.modify_thematic')
          }
          campaignName={
            !isCampaignModel
              ? t('global.campaigns_list.table.columns.campaign_name')
              : t('global.thematics.thematic_name')
          }
          campaignDescription={
            !isCampaignModel
              ? t('global.national_campaigns.campaign_description')
              : t('global.thematics.thematic_description')
          }
          id={campaignId}
          isEditMode={true}
          isCampaignModel={isCampaignModel}
          refetch={refetch}
        />
      </FormWrapper>
    </Box>
  );
};
