import { FormatFunction } from 'i18next';

/**
 * Format bytes as human-readable text.
 * @return Formatted string.
 */
const test: FormatFunction = function (value) {
  if (value && typeof value === 'number') {
    const fractionDigits = 1;
    const thresh = 1024;

    if (Math.abs(value) < thresh) {
      return `${value} $t(common.bytes.measurements.b)`;
    }

    const units = [
      '$t(common.bytes.measurements.kb)',
      '$t(common.bytes.measurements.mb)',
      '$t(common.bytes.measurements.gb)',
      '$t(common.bytes.measurements.tb)',
      '$t(common.bytes.measurements.pb)',
      '$t(common.bytes.measurements.eb)',
      '$t(common.bytes.measurements.zb)',
      '$t(common.bytes.measurements.yb)',
    ];

    let index = -1;

    do {
      value /= thresh;
      ++index;
    } while (
      Math.round(Math.abs(value) * 10 ** fractionDigits) /
        10 ** fractionDigits >=
        thresh &&
      index < units.length - 1
    );

    return `${value.toFixed(fractionDigits)} ${units[index]}`;
  }
  return value;
};

export default test;
