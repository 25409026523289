import React, { useRef } from 'react';
import { Box, Grid } from '@material-ui/core';
import { useStudioStateContext } from 'modules/workflow/oneStudioContext';
import UncontrolledCheckboxMenu, {
  CheckboxOption,
} from 'components/UncontrolledCheckboxMenu';
import {
  filtersPagesPanel,
  PagesPanelHeaderProps,
} from 'modules/workflow/containers/Leftsidebar/PagesPanel/filterDefinitions';
import Paging from 'modules/workflow/components/Paging';

const PagesPanelHeader = ({
  totalPagesNumber,
  filterPages,
  selectPageHandlerFn,
}: PagesPanelHeaderProps): JSX.Element => {
  const valueSelectFilter = useRef<CheckboxOption['value'][]>([]);

  let globalState = useStudioStateContext();

  const isCustomizable = globalState.document?.isCustomizable;
  const isFirstPage = globalState.currentPage === 1;
  const isLastPage =
    globalState.currentPage === globalState.version.pages.length;

  const handleCheckedFilterFn = (value?: CheckboxOption['value'][]): void => {
    filterPages(value as string[]);
  };

  const isTwoPages =
    globalState.isTwoPagesView &&
    globalState.version.pages.length !== globalState.currentPage;

  return (
    <Grid container item alignItems="center">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        pl={1}
      >
        <Paging
          pagingText={
            isTwoPages
              ? `${globalState.currentPage}-${
                  globalState.currentPage + 1
                } / ${totalPagesNumber}`
              : `${globalState.currentPage} / ${totalPagesNumber}`
          }
          onClick={selectPageHandlerFn}
          isFirstArrowActive={!isFirstPage}
          isLastArrowActive={!isLastPage}
          pagingStyle={{ marginRight: '8px' }}
        />

        {isCustomizable && (
          <Box ml={2} display="flex" width={3} height={3} alignItems="center">
            <UncontrolledCheckboxMenu
              options={filtersPagesPanel}
              valuesProp={valueSelectFilter.current}
              onChange={handleCheckedFilterFn}
            />
          </Box>
        )}
      </Box>
    </Grid>
  );
};

export default PagesPanelHeader;
