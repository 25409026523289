import { GetOrderById_getOrderById } from 'app/schemaInterfaces/GetOrderById';
import { GetOrderDeliveries_orderDeliveries } from 'app/schemaInterfaces/GetOrderDeliveries';
import TableComponent from 'components/TableComponent/TableComponent';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { followOrderControlsStyle } from './FollowOrderControls.style';
import { tableHeadData, tableRowData } from './FollowOrderControls.utils';

const FollowOrderControls = ({
  order,
  orderDeliveryData,
}: {
  order?: GetOrderById_getOrderById;
  orderDeliveryData?: GetOrderDeliveries_orderDeliveries;
}) => {
  const { t } = useTranslation();

  return (
    <div css={followOrderControlsStyle}>
      <h3>{t('follow_orders.delivery_control.delivery_control')}</h3>
      <span className="order-subtitle">
        {t('follow_orders.last_update')}{' '}
        {moment(order?.updatedAt).format('DD/MM/YY')}
      </span>

      <div className="table-container">
        <TableComponent
          tableHeadData={tableHeadData(t)}
          tableRowData={tableRowData(orderDeliveryData)}
        />
      </div>
    </div>
  );
};
export default FollowOrderControls;
