import MenuItem from '@material-ui/core/MenuItem';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { FieldProps } from 'formik';
import React, { FC } from 'react';
import CustomPlaceholder from '../CustomPlaceholder/CustomPlaceholder';
import { Box } from '@material-ui/core';
import { controlledSelectTextFieldStyle } from './ControlledSelect.style';

export interface Option {
  label?: string;
  value?: string | number;
}

export type ControlledSelectProps = FieldProps &
  TextFieldProps & {
    options?: Option[];
  };

const ControlledSelect: FC<ControlledSelectProps> = ({
  form: { errors, touched, setTouched, setFieldValue },
  field: { name, value },
  options,
  ...textFieldProps
}) => {
  const id = `sel_${name}`;
  const errorMessage = touched[name] && errors[name];

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setFieldValue(name, event.target.value);
    setTouched({ ...touched, [name]: true });
  }

  return (
    <TextField
      select
      error={!!errorMessage}
      helperText={errorMessage}
      onChange={handleChange}
      value={value ?? ''}
      {...textFieldProps}
      label={
        !value ? (
          <Box pt={0.5}>
            <CustomPlaceholder title={textFieldProps.placeholder} />
          </Box>
        ) : undefined
      }
      InputLabelProps={{
        shrink: false,
        margin: 'dense',
      }}
      css={controlledSelectTextFieldStyle}
    >
      {options?.map((item) => (
        <MenuItem key={`${id}_${item.value}`} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default ControlledSelect;
