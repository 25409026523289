import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  createStyles,
  Card,
  CardContent,
  Typography,
  IconButton,
  Box,
} from '@material-ui/core';

import {
  useStudioStateContext,
  useStudioDispatchContext,
  PersonalizationSubType,
  PersonalizationItem,
} from 'modules/workflow/oneStudioContext';
import colors from 'styles/colors.module.scss';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useTranslation } from 'react-i18next';
import { NumberSize } from 're-resizable';
import { Direction } from 're-resizable/lib/resizer';
import {
  updatePersoItemDimensionsHandler,
  updatePersoItemPositionHandler,
  updateTypographyHandler,
} from 'modules/workflow/containers/CatalogPreview/Personalization/personalizationZoneHelpers';
import 'modules/workflow/Clients/ToutFaire/index.css';
import TypographyItemFormContainer from 'modules/workflow/containers/CatalogPreview/Personalization/TypographyItemFormContainer';
import { setEditTitle } from 'modules/workflow/containers/CatalogPreview/EditPlatformDefinitions';
import useAppContext from 'app/app-context/useAppContext';
import { useCurrentUser } from 'app/auth/UserContext';
import { Rnd, DraggableData, RndDragEvent } from 'react-rnd';
import EditionButtons from '../EditionButtons';
import { css } from '@emotion/react';

const style = css`
  position: absolute;
  z-index: 16;
`;
interface TypographyItemProps {
  pageNumber: number;
  zoneIndex: number;
  typographyItem: PersonalizationItem;
  zoomScale: number;
}

const TypographyItem = ({
  pageNumber,
  zoneIndex,
  typographyItem,
  zoomScale,
}: TypographyItemProps): JSX.Element => {
  const { t } = useTranslation();
  const {
    client: { config: clientConfig },
  } = useAppContext();

  const user = useCurrentUser();

  const globalState = useStudioStateContext();

  const supportConfig = clientConfig?.workflow.supportConfig?.find(
    ({ supportId }) => supportId === globalState.document?.supportId
  );

  const dispatch = useStudioDispatchContext();

  const useStyles = makeStyles(() =>
    createStyles({
      card: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: `dashed ${1.7 / globalState.scaleValue}px ${colors.primary}`,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        width: '100%',
        height: '100%',
      },
      cardContent: {
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        color: `${colors.primary}`,
        fontSize: `${8 / globalState.scaleValue}px`,
        lineHeight: 'normal',
        padding: `${6 / globalState.scaleValue}px`,

        '&:last-child': {
          paddingBottom: 0,
          paddingTop: 0,
        },

        '& .MuiIconButton-root': {
          padding: '0',
        },

        '& .MuiSvgIcon-root': {
          fontSize: `${9 / globalState.scaleValue}px`,
        },
      },

      //TO DO: the fonts will have to be set in the backend depending on the client

      title: {
        fontFamily:
          supportConfig?.personalizationTypography?.title?.fontFamily ??
          'Nexa-XBold',
        fontSize:
          `${supportConfig?.personalizationTypography?.title?.fontSize}pt` ??
          `70pt`,
        textAlign: 'center',
        color: colors.black,
        wordBreak: 'break-word',
        whiteSpace: 'pre-wrap',
      },
      subtitle: {
        fontFamily:
          supportConfig?.personalizationTypography?.subtitle?.fontFamily ??
          'Nexa-Bold',
        textAlign: 'center',
        fontSize:
          `${supportConfig?.personalizationTypography?.subtitle?.fontSize}pt` ??
          `23pt`,
        color: colors.black,
        wordBreak: 'break-word',
        whiteSpace: 'pre-wrap',
      },
      paragraph: {
        fontFamily:
          supportConfig?.personalizationTypography?.paragraph?.fontFamily ??
          'DINPro-Regular',
        fontSize:
          `${supportConfig?.personalizationTypography?.paragraph?.fontSize}pt` ??
          `10pt`,
        color: colors.black,
        wordBreak: 'break-word',
        whiteSpace: 'pre-wrap',
      },
      date: {
        fontFamily:
          supportConfig?.personalizationTypography?.date?.fontFamily ??
          'Nexa-Bold',
        textAlign: 'center',
        fontSize:
          `${supportConfig?.personalizationTypography?.date?.fontSize}pt` ??
          `23pt`,
        color: colors.black,
      },
    })
  );

  const classes = useStyles();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [newTypographyItem, setNewTypographyItem] = useState<
    PersonalizationItem
  >(typographyItem);

  const [textPosition, setTextPosition] = useState({
    x: typographyItem.position?.x ?? 0,
    y: typographyItem.position?.y ?? 0,
  });

  const [typographyZoneDimensions, setTypographyZoneDimensions] = useState({
    width: typographyItem.dimensions?.width,
    height: typographyItem.dimensions?.height,
  });
  const [isActive, setIsActive] = useState<boolean>(false);

  const updateTypographyItemPositionHandler = (
    event: RndDragEvent,
    position: DraggableData,
    pageNumber: number,
    zoneIndex: number,
    typographyKey: string
  ) => {
    // set the global state with X and Y from position

    const personalizationItems =
      globalState.version.pages[pageNumber - 1].pageTemplate[zoneIndex]
        .personalizationItems;

    if (personalizationItems) {
      updatePersoItemPositionHandler(
        dispatch,
        personalizationItems,
        position,
        pageNumber,
        zoneIndex,
        typographyKey,
        setTextPosition
      );
    }
  };

  const updateTypographyItemDimensionsHandler = (
    event: MouseEvent | TouchEvent,
    direction: Direction,
    ref: HTMLElement,
    delta: NumberSize,
    pageNumber: number,
    zoneIndex: number,
    typographyKey: string
  ) => {
    // set the global state with X and Y from position
    const personalizationItems =
      globalState.version.pages[pageNumber - 1].pageTemplate[zoneIndex]
        .personalizationItems;
    if (personalizationItems) {
      updatePersoItemDimensionsHandler(
        delta,
        pageNumber,
        zoneIndex,
        typographyKey,
        dispatch,
        personalizationItems,
        typographyZoneDimensions,
        setTypographyZoneDimensions
      );
    }
  };

  const updateTypography = () => {
    const personalizationItems =
      globalState.version.pages[pageNumber - 1].pageTemplate[zoneIndex]
        .personalizationItems;
    personalizationItems &&
      updateTypographyHandler(
        pageNumber - 1,
        zoneIndex,
        newTypographyItem,
        dispatch,
        personalizationItems,
        t
      );
  };

  // deviding by scaleValue to adjust the size of the TypographyZone to the document's size
  useEffect(() => {
    setNewTypographyItem(typographyItem);
    setTypographyZoneDimensions({
      width: typographyItem.dimensions?.width,
      height: typographyItem.dimensions?.height,
    });
    setTextPosition({
      x: typographyItem.position?.x ?? 0,
      y: typographyItem.position?.y ?? 0,
    });
  }, [typographyItem, globalState.scaleValue]);

  const typographyInput = (): JSX.Element => {
    return (
      <>
        <Card className={`handle ${classes.card}`} variant="outlined">
          <CardContent className={classes.cardContent}>
            <IconButton color="primary" onClick={() => setIsOpen(!isOpen)}>
              <AddCircleIcon />
            </IconButton>
            <p>{setEditTitle(typographyItem.subType, t)}</p>
          </CardContent>
        </Card>
      </>
    );
  };

  const typographyText = () => {
    switch (typographyItem.subType) {
      case PersonalizationSubType.HEADER_TITLE:
        return (
          <Typography className={classes.title}>
            {typographyItem.text?.title}
          </Typography>
        );
      case PersonalizationSubType.SUBTITLE:
        return (
          <Typography className={classes.subtitle}>
            {typographyItem.text?.title}
          </Typography>
        );
      case PersonalizationSubType.NORMAL_PARAGRAPH:
        return (
          <Typography className={classes.paragraph}>
            {typographyItem.text?.title}
          </Typography>
        );
      case PersonalizationSubType.DATE:
        return (
          <Typography className={classes.date}>
            {typographyItem.text?.title}
          </Typography>
        );
      default:
        break;
    }
  };

  const isDraggable = typographyItem.userType === user.type ? false : true;
  const isResizable = typographyItem.userType !== user.type ? false : true;

  const handleTypographyDialog = () => {
    setIsOpen(true);
  };
  return (
    <>
      <Rnd
        css={style}
        dragAxis="both"
        dragHandleClassName="handle"
        disableDragging={isDraggable}
        className={
          isResizable && !globalState.commentMode ? 'resizable-item' : 'comment'
        }
        position={textPosition}
        size={typographyZoneDimensions}
        scale={globalState.scaleValue * zoomScale}
        bounds={'.boundZone-' + (pageNumber - 1) + '-' + zoneIndex}
        onResizeStop={(event, direction, ref, delta, position) => {
          updateTypographyItemPositionHandler(
            event,
            position as DraggableData,
            pageNumber,
            zoneIndex,
            typographyItem.key
          );
          updateTypographyItemDimensionsHandler(
            event,
            direction,
            ref,
            delta,
            pageNumber,
            zoneIndex,
            typographyItem.key
          );
        }}
        // update the global context position
        onDragStop={(event, position) =>
          updateTypographyItemPositionHandler(
            event,
            position,
            pageNumber,
            zoneIndex,
            typographyItem.key
          )
        }
        onMouseOver={() => {
          !globalState.commentMode && setIsActive(true);
        }}
        onMouseLeave={() => {
          setIsActive(false);
        }}
      >
        {typographyItem.text?.title ? (
          <Box
            display="inline-block"
            position="absolute"
            overflow="hidden"
            zIndex={16}
            className="handle"
            width={typographyZoneDimensions.width}
            height={typographyZoneDimensions.height}
          >
            {typographyText()}
          </Box>
        ) : (
          typographyInput()
        )}
        {isActive && (
          <EditionButtons<PersonalizationItem>
            item={typographyItem}
            handleTypographyDialog={handleTypographyDialog}
          />
        )}
        <TypographyItemFormContainer
          handleValidationAction={() => {
            updateTypography();
            setIsOpen(false);
          }}
          isDialogOpen={isOpen}
          closeDialog={() => setIsOpen(!isOpen)}
          title={setEditTitle(typographyItem.subType, t)}
          setTypographyItem={setNewTypographyItem}
          typographyItem={newTypographyItem}
        />
      </Rnd>
    </>
  );
};

export default TypographyItem;
