import { AppRoute } from 'app/routes/routes';
import { GetAllCampaigns_getAllCampaigns_campaigns } from 'app/schemaInterfaces/GetAllCampaigns';
import { GetBasketDocuments_basket_basketDocuments_document_productAttributes as ProductAttributes } from 'app/schemaInterfaces/GetBasketDocuments';
import { GetCampaignById_getCampaignById } from 'app/schemaInterfaces/GetCampaignById';
import { GetDocuments_documents_documents_quantities } from 'app/schemaInterfaces/GetDocuments';
import { ModuleKey } from 'app/routes/helper';
import { TFunction } from 'i18next';
import {
  DisabledDocumentErrors,
  UserType,
} from 'app/schemaInterfaces/globalTypes';
import { getClientPrices } from 'app/schemaInterfaces/getClientPrices';
import moment from 'moment';
import { GetDocumentsToShop_documents_documents } from 'app/schemaInterfaces/GetDocumentsToShop';
import { GetDocumentsToShop_documents_documents_isDisabled } from 'app/schemaInterfaces/GetDocumentsToShop';

export const shopLinkPrefix = '/actionsmarketing';
export const thematicsLinkPrefix = shopLinkPrefix + '/operations-locales';
export const campaignLinkPrefix = shopLinkPrefix + '/operations-nationales';

export const studioLinkPrefix = '/studio';

export enum ShopRoute {
  Home = '/actionsmarketing',
  AllLocalCampaigns = '/actionsmarketing/operations-locales',
  AllNationalCampaigns = '/actionsmarketing/operations-nationales',
  LocalCampaign = '/actionsmarketing/operations-locales/:campaignId',
  NationalCampaign = '/actionsmarketing/operations-nationales/:campaignId',
  Planning = '/actionsmarketing/planning',
}

export const getLocalCampaignLink = (campaignId: string): string =>
  `${thematicsLinkPrefix}/${campaignId}`;

export const getNationalCampaignLink = (campaignId: string): string =>
  `${campaignLinkPrefix}/${campaignId}`;

export const shopBreadcrumbs = (t: TFunction, campaignName?: string) => {
  const paths = [
    { path: AppRoute.Home, breadcrumb: t('header.home') },
    {
      path: ShopRoute.Home,
      breadcrumb: t('header.shop'),
    },
    {
      path: ShopRoute.AllNationalCampaigns,
      breadcrumb: t('local.common.national_campaigns'),
    },
    {
      path: ShopRoute.AllLocalCampaigns,
      breadcrumb: t('global.thematics.thematics'),
    },
    {
      path: ShopRoute.NationalCampaign,
      breadcrumb: campaignName,
    },
    {
      path: ShopRoute.LocalCampaign,
      breadcrumb: campaignName,
    },
    {
      path: ShopRoute.Planning,
      breadcrumb: t('menuItems.planning'),
    },
  ];
  const options = campaignName ? { excludePaths: ['/'] } : {};
  return { paths, options };
};

export const shopMenuItems = [
  {
    translationKey: 'menuItems.see_all',
    to: ShopRoute.Home,
    key: 0,
    moduleKey: ModuleKey.OP,
  },
  {
    translationKey: 'menuItems.campaigns',
    to: ShopRoute.AllNationalCampaigns,
    key: 1,
    moduleKey: ModuleKey.AM_OP_Nationales,
  },
  {
    translationKey: 'menuItems.thematics',
    to: ShopRoute.AllLocalCampaigns,
    key: 2,
    moduleKey: ModuleKey.AM_OP_Locales,
  },
  {
    translationKey: 'menuItems.planning',
    to: ShopRoute.Planning,
    key: 3,
    moduleKey: ModuleKey.AM_Planning,
  },
];

export const getDocumentLink = (documentId: string): string =>
  `${studioLinkPrefix}/${documentId}`;

export const getRedirectUrl = (
  userType: UserType,
  id: string | undefined,
  localUrl: string
) => (userType === UserType.Global ? `/campaign/${id}` : localUrl + id);

export const getCampaignId = (
  campaigns: GetAllCampaigns_getAllCampaigns_campaigns[] | undefined
) => campaigns && campaigns[0]?.id;

export const getCampaignImageUrl = (
  campaigns: GetAllCampaigns_getAllCampaigns_campaigns[] | undefined
) => campaigns && campaigns[0]?.imageUrl;

export const getCommitmentLatestDate = (
  campaign:
    | GetAllCampaigns_getAllCampaigns_campaigns
    | GetCampaignById_getCampaignById
) =>
  Array.isArray(campaign.documents)
    ? campaign.documents
        ?.filter(
          (document: { commitmentDateTo: any }) => document.commitmentDateTo
        )
        ?.map(
          (document: { commitmentDateTo: any }) => document.commitmentDateTo
        )
        .reduce(function (first: number, second: number) {
          return first > second ? first : second;
        }, 0)
    : null;

export const getCommitmentEarlierDate = (
  campaign:
    | GetAllCampaigns_getAllCampaigns_campaigns
    | GetCampaignById_getCampaignById
) =>
  Array.isArray(campaign.documents)
    ? campaign.documents
        ?.filter(
          (document: { commitmentDateFrom: any }) => document.commitmentDateFrom
        )
        ?.map(
          (document: { commitmentDateFrom: any }) => document.commitmentDateFrom
        )
        .reduce(function (first: number, second: number) {
          return first < second ? first : second;
        }, 0)
    : null;

export const isDocumentTerminated = (date: any) => {
  return moment(date).isBefore(moment(new Date()));
};

export const getLastCommitmentDate = (
  documents?: GetDocumentsToShop_documents_documents[]
) => {
  if (documents?.length === 0) return '';
  return documents?.reduce((firstDocument, secondDocument) =>
    new Date(firstDocument.commitmentDateTo) >
    new Date(secondDocument.commitmentDateTo)
      ? firstDocument
      : secondDocument
  ).commitmentDateTo;
};

export const createDocumentUrl = (text: string) => {
  let url = '';
  url += text ? `&searchBar=${text}` : '';

  return url?.substr(1);
};

export const disableOrderButton = ({
  selectedQuantity,
  quantities,
  clientPricesData,
}: {
  selectedQuantity?: number;
  quantities: GetDocuments_documents_documents_quantities | null;
  clientPricesData?: getClientPrices;
}) => {
  if (!selectedQuantity) {
    return true;
  } else if (selectedQuantity && quantities) {
    if (
      quantities.rangeQuantity &&
      quantities?.rangeQuantity?.maximum &&
      quantities?.rangeQuantity?.minimum
    ) {
      return (
        selectedQuantity > quantities?.rangeQuantity?.maximum ||
        selectedQuantity < quantities?.rangeQuantity?.minimum
      );
    } else if (quantities.strictQuantity) {
      return selectedQuantity !== quantities.strictQuantity;
    } else return true;
  } else {
    return (
      selectedQuantity &&
      selectedQuantity < (clientPricesData?.prices[0].quantity ?? 0)
    );
  }
};

export interface AddButtonProps {
  message: string;
  hasIcon: boolean;
  isDisabled: boolean;
  TooltipTitle: string;
}

interface getButtonPropsParams {
  isDisabled: GetDocumentsToShop_documents_documents_isDisabled;
  t: TFunction;
  disabled: boolean;
  tooltipTitle?: string;
  loading: boolean;
}

export const getButtonProps = ({
  isDisabled,
  t,
  disabled,
  tooltipTitle = '',
  loading,
}: getButtonPropsParams): AddButtonProps => {
  let message;
  switch (true) {
    case isDisabled.reason === DisabledDocumentErrors.needFacebookAuth:
      message = t('local.all_campaigns_page.authenticate');
      break;
    case isDisabled.reason === DisabledDocumentErrors.documentTerminated:
      message = t('sales.basket_page.order.order_terminated');
      break;
    case isDisabled.reason === DisabledDocumentErrors.documentInBasket ||
      loading:
      message = t('local.all_campaigns_page.added_to_basket');
      break;
    case isDisabled.reason === DisabledDocumentErrors.documentWithNoPrices:
      message = t('local.all_campaigns_page.no_price_for_document');
      break;
    case isDisabled.reason === DisabledDocumentErrors.documentCommited:
      message = t('local.campaign_page.summary.has_commitment');
      break;
    default:
      message = t('local.all_campaigns_page.add_to_basket');
  }
  let TooltipTitle;
  switch (true) {
    case disabled:
      TooltipTitle = t('campaign_page.warning.select_to_order');
      break;
    case isDisabled.reason === DisabledDocumentErrors.needFacebookAuth:
      TooltipTitle = t('local.all_campaigns_page.tooltip_message_authenticate');
      break;
    default:
      TooltipTitle = tooltipTitle;
  }

  return {
    message,
    hasIcon:
      isDisabled.reason === DisabledDocumentErrors.documentInBasket &&
      !disabled,
    isDisabled:
      message !== t('local.all_campaigns_page.authenticate') &&
      (isDisabled.disabled || disabled || loading),
    TooltipTitle,
  };
};

enum SupportTypes {
  FacebookAdd = 'Publicité au format JPEG',
  FacebookPost = 'Post au format JPEG',
}

const isSchedulableType = [SupportTypes.FacebookPost];

export const hasPublicationPeriod = (attributes: ProductAttributes | null) => {
  return isSchedulableType.includes(attributes?.typeSupport as SupportTypes);
};
