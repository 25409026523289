import { DigitalContent, PageTemplate, Version } from '../oneStudioContext';

export const normalizeVersion = (version: Version) => {
  return version.pages.map((page) => {
    let copyPage = { ...page };
    if (page.backgroundColor) {
      copyPage.backgroundColor = {
        id: page.backgroundColor.id,
        key: page.backgroundColor.key,
      };
    }
    copyPage.pageTemplate = copyPage.pageTemplate.map((template) => {
      if (template.background) {
        return {
          ...template,
          background: {
            id: template.background.id,
            key: template.background.key,
          },
        };
      }
      return template;
    });

    const {
      id,
      __typename,
      thumbnailUrl,
      ...pageWithoutID
    } = copyPage as PageTemplate & {
      __typename: 'VersionPage';
    };

    return {
      ...pageWithoutID,
      pageTemplate: pageWithoutID.pageTemplate?.map((pageTemplate) => ({
        ...pageTemplate,
        assets: pageTemplate.assets?.map((asset) => ({
          id: asset.id,
          key: asset.key,
          size: asset.size,
          position: asset.position,
        })),
      })),
    };
  });
};

export const normalizeDigitalContent = (content: DigitalContent | null) => {
  if (!content) return content;
  const { __typename, ...digitalContent } = content as DigitalContent & {
    __typename: 'DigitalContent';
  };
  return digitalContent;
};
