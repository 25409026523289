import { Formik } from 'formik';
import React from 'react';

import { AssetInput } from 'app/schemaInterfaces/globalTypes';
import { GetAssetById_getAssetById as Asset } from 'app/schemaInterfaces/GetAssetById';
import AssetForm from 'modules/dam/form/AssetForm';
import { SingleAssetFormValues } from 'modules/dam/form/model/definitions';
import {
  mapToAssetFormModel,
  mapToAssetInput,
} from 'modules/dam/form/model/mappers';
import { getAssetFormSchema } from 'modules/dam/form/validation/schema';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from 'app/auth/UserContext';

export interface AssetFormWrapperProps {
  asset?: Asset;
  onFormValidation?: (values: AssetInput, file: File | null) => void;
  isUpdateInDam: boolean;
}

const AssetFormWrapper = ({
  asset,
  onFormValidation,
  isUpdateInDam,
}: AssetFormWrapperProps): JSX.Element => {
  const { t } = useTranslation();

  const { entities } = useCurrentUser();

  const initialValues: SingleAssetFormValues = {
    asset: mapToAssetFormModel({ asset, entities }),
    file: null,
  };

  const handleSubmit = (values: SingleAssetFormValues) => {
    onFormValidation?.(mapToAssetInput(values.asset), values.file);
  };

  return (
    <>
      <Formik<SingleAssetFormValues>
        initialValues={initialValues}
        validationSchema={getAssetFormSchema(Boolean(asset), t)}
        onSubmit={handleSubmit}
      >
        <AssetForm asset={asset} isUpdateInDam={isUpdateInDam} />
      </Formik>
    </>
  );
};

export default AssetFormWrapper;
