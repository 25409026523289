import useCurrentEntity from 'app/current-entity-context/useCurrentEntity';
import { GetClientShippingAddress_entityDeliveryAdresses_address } from 'app/schemaInterfaces/GetClientShippingAddress';
import { BasketType } from 'app/schemaInterfaces/globalTypes';
import { isDigitalDocument } from 'app/utils/digital-helper';
import { useStoreActions, useStoreState } from 'state/store';
import { SelectedBasketdocument } from 'modules/sales/sales.state';
import { SalesRoute } from 'modules/sales/salesHelpers';
import useCampaignByIdQuery from 'modules/shop/queries/useCampaignByIdQuery';
import useBasketDocumentsQuery from 'queries/useBasketDocumentsQuery';
import useDocumentsQuery from 'queries/useDocumentsQuery';
import { useEffect, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { groupDocument } from '../StepsHelper';
import { useTranslation } from 'react-i18next';

export const useDeliveryStep = () => {
  const { t } = useTranslation();
  const { currentEntity } = useCurrentEntity();
  const { search } = useLocation();
  const campaignId = new URLSearchParams(search).get('id');
  const history = useHistory();

  const { data: documentsData } = useDocumentsQuery({
    filter: {
      campaignIds: [campaignId as string],
    },
  });

  const {
    data: basketData,
    loading: basketDataLoading,
  } = useBasketDocumentsQuery({
    filter: { entityId: currentEntity?.id },
    fetchPolicy: 'no-cache',
  });

  const { data: campaignData } = useCampaignByIdQuery(campaignId ?? '');

  const campaignDocumentIds = documentsData?.documents.documents.map(
    (documentItem) => documentItem.id
  );
  const basketDocumentsToAdd = basketData?.basket.basketDocuments.filter(
    (basketDocument) =>
      campaignDocumentIds?.includes(basketDocument.document.id)
  );
  const setBasketDocuments = useStoreActions(
    (actions) => actions.salesModule.basketdocuments.setSelectedBasketDocuments
  );

  const setCampaignName = useStoreActions(
    (actions) => actions.salesModule.campaignName.setName
  );

  const basketDocuments = useStoreState(
    (state) => state.salesModule.basketdocuments.selectedBasketdocuments
  );

  const setStep = useStoreActions(
    (actions) => actions.salesModule.step.setActiveStep
  );

  const setAddress = useStoreActions(
    (actions) => actions.salesModule.basketdocuments.setAddressToDocument
  );

  const setName = useStoreActions(
    (actions) => actions.salesModule.campaignName.setName
  );

  const setComment = useStoreActions(
    (actions) => actions.salesModule.basketdocuments.setCommentToDocument
  );

  const campaignName = useStoreState(
    (state) => state.salesModule.campaignName.name
  );

  const basketType = useStoreState(
    (state) => state.salesModule.basket.basketType
  );

  const setBasketType = useStoreActions(
    (actions) => actions.salesModule.basket.setBasketType
  );

  const setIsBasketPending = useStoreActions(
    (actions) => actions.salesModule.basketStatus.setIsBasketPending
  );

  const campaignsWithDocuments = groupDocument(basketDocuments);

  const selectedBasketType = basketDocumentsToAdd?.map(
    (basketDocument) => basketDocument.type
  )[0];

  useEffect(() => {
    setIsBasketPending(basketDataLoading);
    if (!Boolean(basketDocuments.length)) {
      const basketDocs = basketDocumentsToAdd?.map((basketDoc) => ({
        basketDocument: basketDoc,
        addresses: [],
      }));
      if (basketDocs && selectedBasketType) {
        setBasketDocuments(basketDocs);
        setBasketType(selectedBasketType);
        setStep(1);
        setCampaignName(campaignData?.getCampaignById.name);
      }
    }
  }, [
    basketDocuments.length,
    basketDocumentsToAdd,
    setBasketDocuments,
    setStep,
    setBasketType,
    selectedBasketType,
    setCampaignName,
    campaignData?.getCampaignById.name,
    basketDataLoading,
    setIsBasketPending,
  ]);

  const onChangeAddress = (
    basketDocumentItem: SelectedBasketdocument,
    basketAddress:
      | GetClientShippingAddress_entityDeliveryAdresses_address
      | null
      | undefined,
    index: number
  ) => {
    setAddress({
      index,
      documentId: basketDocumentItem.basketDocument.document.id,
      address: basketAddress as GetClientShippingAddress_entityDeliveryAdresses_address,
    });
  };

  const handleUpdateBasketDocument = (productName: string, index: number) => {
    basketDocuments[index] = {
      ...basketDocuments[index],
      basketDocument: {
        ...basketDocuments[index].basketDocument,
        productName,
      },
    };
    setBasketDocuments(basketDocuments);
  };

  const onSubmit = async () => {
    setStep(2);
  };

  const onCancel = () => {
    history.push(
      basketType === BasketType.Commitment
        ? SalesRoute.CommitmentsBasket
        : SalesRoute.BigShopBasket
    );
  };

  const handleComment = (
    basketDocumentItem: SelectedBasketdocument,
    comment: string
  ) => {
    setComment({
      comment,
      documentId: basketDocumentItem.basketDocument.document.id,
    });
  };

  //
  // Basket validation
  //
  const { isContinueDisabled, errors } = useMemo(() => {
    const hasInvalidAddresses = basketDocuments.some(
      (document) =>
        !isDigitalDocument(document.basketDocument.document.category) &&
        document.addresses.some(
          (address) =>
            !Boolean(address.isValid) || !Boolean(address.address?.id)
        )
    );
    const hasEmptyAddressArrays = basketDocuments.some(
      (document) => !Boolean(document.addresses.length)
    );
    const hasMissingDeliveryDates = basketDocuments.some(
      (document) => !document.deliveryDate
    );
    const isLocalCampaignInvalid =
      basketType === BasketType.BigShop && !Boolean(campaignName);
    const hasNoEntitySelected = !currentEntity;

    const isContinueDisabled =
      hasInvalidAddresses ||
      hasEmptyAddressArrays ||
      hasMissingDeliveryDates ||
      isLocalCampaignInvalid ||
      hasNoEntitySelected;

    const errors: string[] = [];
    if (hasInvalidAddresses) {
      errors.push(t('sales.basket_page.errors.invalid_address'));
    }
    if (hasMissingDeliveryDates) {
      errors.push(t('sales.basket_page.errors.missing_delivery_date'));
    }
    if (isLocalCampaignInvalid) {
      errors.push(t('sales.basket_page.errors.invalid_product_id'));
    }
    if (hasNoEntitySelected) {
      errors.push(t('campaign_page.warning.selection_needed_message'));
    }

    return { isContinueDisabled, errors };
  }, [basketDocuments, basketType, campaignName, currentEntity, t]);

  return {
    setName,
    isContinueDisabled,
    handleComment,
    onCancel,
    onChangeAddress,
    onSubmit,
    campaignsWithDocuments,
    handleUpdateBasketDocument,
    basketType,
    campaignName,
    basketDocuments,
    errors,
  };
};
