import { Box } from '@material-ui/core';
import React from 'react';
import { GetAssetById_getAssetById as Asset } from 'app/schemaInterfaces/GetAssetById';
import { AssetList } from 'modules/dam/AssetList';
import useSimilarAssetQuery from 'modules/dam/hooks/useSimilarAssetQuery';
import { css } from '@emotion/react';

interface AssetSimilarProps {
  asset: Asset;
}

const style = css`
  height: 69vh;
  overflow: auto;
`;

export const AssetSimilar = ({ asset }: AssetSimilarProps): JSX.Element => {
  const { assets, loadMore, error, loading } = useSimilarAssetQuery(
    asset.id,
    30
  );

  return (
    <Box p={2}>
      {assets && (
        <div css={style}>
          <AssetList
            assets={assets}
            error={error}
            loading={loading}
            onLoadMore={loadMore}
            columns={4}
          />
        </div>
      )}
    </Box>
  );
};
