import { GetCurrentUser_user } from 'app/schemaInterfaces/GetCurrentUser';
import { GetOrderById_getOrderById } from 'app/schemaInterfaces/GetOrderById';
import { UserType } from 'app/schemaInterfaces/globalTypes';

export function isUserValidatorForOrder(
  order: Pick<GetOrderById_getOrderById, 'activeOrderWorkflows'>,
  user: Pick<GetCurrentUser_user, 'id'>
) {
  return order.activeOrderWorkflows.some((workflow) =>
    workflow.config.validators.some(
      (validators) =>
        validators.mainUser.id === user.id ||
        validators.fallbackUsers.some(
          (fallbackUser) => fallbackUser.id === user.id
        )
    )
  );
}

export function mightUserBeValidator(user: Pick<GetCurrentUser_user, 'type'>) {
  return user.type === UserType.Global;
}
