import React, { PropsWithChildren } from 'react';
import { Box, Divider, Typography } from '@material-ui/core';
import colors from 'styles/colors.module.scss';

interface UserHistoryProps {
  updatedAt: string;
  color?: string;
  border?: string;
  dividerColor?: string;
}

const UserHistory = ({
  updatedAt,
  children,
  color = colors.buttonGroupOutlined,
  border = `1px solid ${colors.buttonGroupOutlined}`,
  dividerColor = colors.buttonGroupOutlined,
}: PropsWithChildren<UserHistoryProps>): JSX.Element => {
  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        color={color}
        justifyContent="center"
      >
        <Box width="30%">
          <Divider />
        </Box>
        <Box
          border={border}
          borderRadius="200px"
          py={0.25}
          px={1}
          color={colors.disabled}
        >
          <Typography variant="caption">{updatedAt}</Typography>
        </Box>
        <Box width="30%" color={dividerColor}>
          <Divider />
        </Box>
      </Box>
      {children && <Box>{children}</Box>}
    </Box>
  );
};

export default UserHistory;
