import { Box, FormLabel } from '@material-ui/core';
import {
  AssetStatus,
  AssetType,
  CharterType,
  TagType,
  VisualType,
} from 'app/schemaInterfaces/globalTypes';
import UncontrolledSelect from 'components/UncontrolledSelect';
/* import { UserEntitiesMultiSelectFromContextField } from 'components/UserEntitiesMultiSelectFromContextField'; */
import { Field, useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TagsAutocompleteField from 'components/TagsAutocompleteField/TagsAutocompleteField';
import useTagsQuery from 'queries/useTagsQuery';
import { BaseAssetFormValues } from 'modules/dam/form/model/definitions';
import {
  getAssetTypeOptions,
  getCharterTypeOptions,
  getVisualTypeOptions,
} from 'modules/dam/form/helpers';
import ControlledSelect from 'components/ControlledSelect/ControlledSelect';

const GeneralInformationSubForm = (): JSX.Element => {
  const { t } = useTranslation();

  const { tags } = useTagsQuery({ type: TagType.Asset });

  const {
    values: { asset: assetInput },
    setFieldValue,
  } = useFormikContext<BaseAssetFormValues>();

  const handleAssetTypeChange = (value: AssetType) => {
    setFieldValue('asset.assetType', value);
    setFieldValue('asset.visualType', null);
    setFieldValue('asset.comment', '');
    setFieldValue('asset.division', null);
    setFieldValue('asset.validFrom', null);
    setFieldValue('asset.validTo', null);
    setFieldValue('asset.permanent', null);
    setFieldValue('asset.status', AssetStatus.Active);
    switch (value) {
      case AssetType.Visual:
        setFieldValue('asset.visualType', VisualType.Packshot);
        break;
      case AssetType.Charter:
        setFieldValue('asset.charterType', CharterType.PageBackground);
        break;
    }
  };

  return (
    <>
      {/*  commente pour monoprix demo, à remetre apres */}
      {/* 	<Box pt={1}>
				<FormLabel>Groupe</FormLabel>git
			</Box>

			<Box pt={2}>
				<UserEntitiesMultiSelectFromContextField name="asset.entities" placeholder="Groupe" margin="dense" />-{' '}
			</Box> */}
      <Box pt={2}>
        <FormLabel>{t('dam.asset_form.media_type')}</FormLabel>
      </Box>
      <Box>
        <UncontrolledSelect
          value={assetInput.assetType ?? ''}
          margin="dense"
          placeholder={t('dam.asset_form.media_type')}
          usePlaceholderItem
          options={getAssetTypeOptions(t)}
          fullWidth
          onChange={({ target: { value } }) =>
            handleAssetTypeChange(value as AssetType)
          }
        />
      </Box>
      {assetInput.assetType === AssetType.Visual && (
        <Box>
          <Box pt={2}>
            <FormLabel>{t('dam.asset_form.visual_type')}</FormLabel>
          </Box>
          <Box>
            <Field
              component={ControlledSelect}
              name="asset.visualType"
              margin="dense"
              variant="outlined"
              options={getVisualTypeOptions(t)}
              fullWidth
            />
          </Box>
        </Box>
      )}
      {assetInput.assetType === AssetType.Charter && (
        <Box>
          <Box pt={2}>
            <FormLabel>{t('dam.form_wrapper.charter_type')}</FormLabel>
          </Box>
          <Box>
            <Field
              component={ControlledSelect}
              name="asset.charterType"
              margin="dense"
              variant="outlined"
              options={getCharterTypeOptions(t)}
              fullWidth
            />
          </Box>
        </Box>
      )}
      <Box pt={1}>
        <FormLabel>{t('dam.form_wrapper.tags')}</FormLabel>
      </Box>
      <TagsAutocompleteField
        name="asset.tags"
        margin="dense"
        size="small"
        placeholder={t('dam.form_wrapper.tags')}
        fullWidth
        options={tags?.map((tag) => ({ id: tag.id, title: tag.title }))}
      />
    </>
  );
};

export default GeneralInformationSubForm;
