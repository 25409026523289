import { ApolloError } from '@apollo/client';
import { Grid } from '@material-ui/core';
import React from 'react';
import { GetAssets_getAssets_assets as Asset } from 'app/schemaInterfaces/GetAssets';
import { AssetInput, AssetType } from 'app/schemaInterfaces/globalTypes';
import { useInfiniteScroll } from 'components/infinite-scroll/useInfiniteScroll';
import Loader from 'components/Loader/Loader/Loader';
import { AssetThumbnail } from 'modules/dam/AssetThumbnail';
import NoItemsNotice from 'modules/shop/components/NoItemsNotice';
import { useTranslation } from 'react-i18next';

type AssetListProps = {
  assets?: Asset[];
  error?: ApolloError;
  loading: boolean;
  disablePagination?: boolean;
  onLoadMore: () => void;
  onAssetUpdate?: (id: string, asset: AssetInput, file: File | null) => void;
  onAssetSelect?: (asset: Asset, selected: boolean) => void;
  columns?: 3 | 4 | 6 | 12;
  campaignAssetsIds?: string[];
  isCampaignAssetsPage?: boolean;
  selectedAssetType?: readonly AssetType[];
};

export const AssetList = ({
  assets,
  error,
  loading,
  disablePagination,
  onLoadMore,
  onAssetUpdate,
  columns,
  campaignAssetsIds,
  onAssetSelect,
  isCampaignAssetsPage,
  selectedAssetType,
}: AssetListProps): JSX.Element => {
  const waypointRef = useInfiniteScroll(onLoadMore, loading);
  const { t } = useTranslation();

  return (
    <>
      {assets &&
        (Boolean(assets.length) ? (
          <Grid container spacing={4}>
            {assets.map((asset) => (
              <Grid
                item
                key={asset.id}
                {...{
                  xs: columns ?? 12,
                  sm: columns ?? 6,
                  md: columns ?? 4,
                  lg: columns ?? 3,
                }}
              >
                <AssetThumbnail
                  isCampaignAssetsPage={isCampaignAssetsPage}
                  campaignAssetsIds={campaignAssetsIds}
                  asset={asset}
                  onAssetUpdate={onAssetUpdate}
                  {...{ onAssetSelect }}
                />
              </Grid>
            ))}
            {!disablePagination &&
              (loading ? <Loader error={error} /> : <div ref={waypointRef} />)}
          </Grid>
        ) : (
          <NoItemsNotice
            message={t(
              selectedAssetType
                ? `dam.main_title.no_assets_${selectedAssetType}`
                : 'dam.main_title.no_assets'
            )}
          />
        ))}
    </>
  );
};
