import { Button } from '@material-ui/core';
import React, { FC } from 'react';
import {
  headerButtonGroupStyle,
  headerConfirmButtonStyle,
  headerContainerStyle,
  headerRootStyle,
} from './HeaderFormActions.style';

export interface Action {
  name: string;
  onClick?: () => void;
  isDisabled?: boolean;
}

export interface HeaderFormActionsProps {
  name: string;
  cancelAction?: Action;
  confirmAction?: Action;
  otherAction?: Action;
}

// TODO: why create a reusable component that is used in one place (the document form) ? this is an antipattern, it's better to KISS then refactor if needed
const HeaderFormActions: FC<HeaderFormActionsProps> = ({
  name,
  cancelAction,
  confirmAction,
  otherAction,
}) => (
  <div css={headerRootStyle}>
    <div css={headerContainerStyle}>
      <h4>{name}</h4>
      <div css={headerButtonGroupStyle}>
        {cancelAction && (
          <Button
            color="primary"
            onClick={cancelAction.onClick}
            disabled={cancelAction.isDisabled}
          >
            {cancelAction.name}
          </Button>
        )}
        {confirmAction && (
          <div css={headerConfirmButtonStyle}>
            <Button
              type="submit"
              variant="contained"
              onClick={confirmAction.onClick}
              disabled={confirmAction.isDisabled}
              color="primary"
            >
              {confirmAction.name}
            </Button>
          </div>
        )}
      </div>
    </div>
  </div>
);

export default HeaderFormActions;
