import { css } from '@emotion/react/macro';
import colors from 'styles/colors.module.scss';

const page = css`
  height: 100%;
  background-color: ${colors.pageBackground};
  padding-bottom: 100px;
  .content-container {
    padding-right: 42px;
    padding-left: 42px;
  }
  .MuiContainer-maxWidthMd {
    max-width: 1096px;
  }
  .toolbar {
    padding-bottom: 24px;
    display: flex;
    justify-content: space-between;

    .filter {
      display: flex;
      .MuiButton-label {
        margin-right: 16px;
      }
    }
  }
  .summary {
    padding-top: 90px;
  }
`;

const campaignPage = css`
  .main-container {
    padding-top: 30px;
    padding-left: 40px;
    padding-right: 41px;
    > .MuiGrid-container {
      display: flex;
      justify-content: center;
    }
  }
  .list-container {
    display: flex;
    justify-content: center;
    .item {
      margin: 16px;
    }
  }
  .list {
    margin: 8px;
    .MuiList-padding {
      padding: 0;
    }

    .MuiListItem-root {
      display: block;
      padding-top: 0px;
      padding-bottom: 0px;
      border: solid transparent 5px;
      border-radius: 5px;

      &:hover {
        background-color: ${colors.workzone};
      }
    }

    .selectedCampaign {
      border: solid ${colors.buttonDisabled} 5px;
      border-radius: 5px;
    }

    .MuiIconButton-root {
      color: ${colors.body};
      margin-bottom: 4px;
    }
  }
`;

const style = { campaignPage, page };

export default style;
