import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import colors from 'styles/colors.module.scss';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import useCurrentEntity from 'app/current-entity-context/useCurrentEntity';

const style = (disabled: boolean) => css`
  .button {
    color: ${disabled ? colors.disabled : colors.grey};
  }
`;

interface DownloadButtonProps {
  download?: () => void;
  disabled?: boolean;
}

const DownloadButton = ({
  download,
  disabled = false,
}: DownloadButtonProps): JSX.Element => {
  const { t } = useTranslation();
  const { currentEntity } = useCurrentEntity();
  const isDisabled = !currentEntity || disabled;
  return (
    <Tooltip
      css={style(isDisabled)}
      title={
        currentEntity
          ? (t('workflow.toolbar.download') as string)
          : (t('workflow.toolbar.select_entity') as string)
      }
      placement="bottom-end"
    >
      <span>
        <IconButton
          edge="end"
          className="button"
          disabled={isDisabled}
          onClick={download}
        >
          <GetAppOutlinedIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default DownloadButton;
