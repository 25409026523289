import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import { GET_CHILI_EDITOR_CONFIG } from 'app/graphql/queries/chili';
import {
  GetChiliEditorConfig,
  GetChiliEditorConfigVariables,
} from 'app/schemaInterfaces/GetChiliEditorConfig';
import Loader from 'components/Loader/Loader/Loader';
import { FC, useState } from 'react';
import { AutoSaveInfo } from '../containers/Toolbar/FirstToolbar/FirstToolbar';
import DownloadOptionsMenu from '../containers/Toolbar/SecondToolbar/DownloadOptionsMenu';
import Toolbar from '../containers/Toolbar/Toolbar';
import { useSaveCatalog } from '../containers/Toolbar/useSaveCatalog';
import { useStudioStateContext } from '../oneStudioContext';
import { useChiliEditor } from './chili/useChiliEditor';

export interface ChiliPlatformMetadata {
  templateId: string;
}

export interface ChiliPlatformProps {
  metadata: ChiliPlatformMetadata;
}

const containerStyle = css`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const iframeStyle = css`
  border: none;
  flex: 1;
`;

const ChiliPlatform: FC<ChiliPlatformProps> = ({ metadata }) => {
  const { version } = useStudioStateContext();
  const { data } = useQuery<
    GetChiliEditorConfig,
    GetChiliEditorConfigVariables
  >(GET_CHILI_EDITOR_CONFIG, {
    variables: { templateId: metadata.templateId },
    fetchPolicy: 'no-cache',
  });

  const [autoSaveInfo, setAutoSaveInfo] = useState<AutoSaveInfo>({
    isSaving: false,
    lastSaved: version.updatedAt,
  });
  function updateAutoSaveInfo(isSaving: boolean) {
    setAutoSaveInfo({ isSaving, lastSaved: new Date() });
  }

  const [isEditorReady, setIsEditorReady] = useState(false);
  const { frameProps, editorRef, waitFor } = useChiliEditor(
    () => data?.getChiliEditorConfig.apiKey!,
    {
      DocumentFullyLoaded(editor, itemID) {
        // If another template loads instead of the selected one (eg: user opens new template)
        // we should load back the selected template
        if (itemID !== metadata.templateId) {
          editor.ExecuteFunction(
            'document',
            'OpenDocument',
            metadata.templateId
          );
        }
      },
      DocumentInitialViewRendered() {
        setIsEditorReady(true);
      },
      DocumentSaveAsRequested(editor) {
        // Prevent user from saving template in a new file, showing error alert
        editor.SetProperty('document', 'saveDenied', true);
        editor.Alert('Operation not permitted', 'Error');
      },
      DocumentClosing(editor) {
        setIsEditorReady(false);
        // Show error alert when loading another template
        editor.Alert('Operation not permitted', 'Error');
      },
      DocumentSaveRequested() {
        updateAutoSaveInfo(true);
      },
      DocumentSaved() {
        updateAutoSaveInfo(false);
      },
    }
  );

  const { saveCatalog, isLoading: isSavingCatalog } = useSaveCatalog();
  const [isFinalSaving, setIsFinalSaving] = useState(false);
  async function handleFinalSave() {
    if (!editorRef.current) throw new Error('Chili editor not ready!');
    setIsFinalSaving(true);
    try {
      editorRef.current.ExecuteFunction('document', 'Save');
      updateAutoSaveInfo(true);
      await waitFor('DocumentSaved');
      await saveCatalog(true);
    } finally {
      setIsFinalSaving(false);
    }
  }

  const downloadOptions =
    data?.getChiliEditorConfig.pdfExportSettings.map((setting) => ({
      title: setting.name,
      onClick: () => onDownloadOption(setting.xml),
    })) ?? [];
  const [isDownloading, setIsDownloading] = useState(false);
  async function onDownloadOption(xml: string) {
    if (!editorRef.current) throw new Error('Chili editor not ready!');
    setIsDownloading(true);
    try {
      editorRef.current.ExecuteFunction(
        'document',
        'CreateTempPDF_FromXml',
        xml,
        '_blank'
      );
      await waitFor('DocumentOutputCreated');
    } finally {
      setIsDownloading(false);
    }
  }

  return (
    <div css={containerStyle}>
      <Toolbar
        autoSaveInfo={autoSaveInfo}
        indeterminatePageCount
        onFinalSave={handleFinalSave}
        isFinalSaving={isSavingCatalog || isFinalSaving}
        toolbarActions={{
          right: (
            <DownloadOptionsMenu
              isDownloading={isDownloading}
              options={downloadOptions}
              disabled={!isEditorReady}
            />
          ),
        }}
        disabled={!isEditorReady}
      />
      {data ? (
        <iframe
          {...frameProps}
          css={iframeStyle}
          title="CHILI Publisher Editor"
          src={data?.getChiliEditorConfig.iframeUrl}
        />
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default ChiliPlatform;
