import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const workflowHeadStyle = css`
  font-weight: bold;
  color: ${colors.orange300};
`;

export const dividerStyle = css`
  margin: 8px 0;
`;
