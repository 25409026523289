import {
  Box,
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Table,
  Link,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import colors from 'styles/colors.module.scss';
import React, { ReactNode, useEffect } from 'react';
import {
  CellProps,
  Column,
  ColumnInstance,
  HeaderProps,
  Hooks,
  Row,
  useColumnOrder,
  useExpanded,
  useFilters,
  useFlexLayout,
  useGroupBy,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import { useSticky } from 'react-table-sticky';
import { Action } from '../ActionsMenu/ActionsMenu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SimpleActionsMenu from '../ActionsMenu/SimpleActionsMenu';
import { StickyBox } from '../sticky/StickyBox';
import { useSticky as useStickyBar } from 'components/sticky/useSticky';
import { useTranslation } from 'react-i18next';
import { appTableStyle } from './AppTable.style';

export type TableColumns<T extends object = {}> = Column<T> & {
  sticky?: string;
  number?: number;
};

export interface ColumnHidingInterface {
  enabled: boolean;
  fixedColumnsIDs?: string[];
}

interface InitialPaginationState {
  pageIndex: number;
  pageSize?: number;
}
interface PaginationSetupInterface {
  // For quick change of the table to be pagination-less (may be needed in debugging)
  enabled?: boolean;
  type?: string;
  fetchData?: (pageSize: number, pageIndex: number) => void;
  loading?: boolean;
  controlledPageCount?: number;
  // For now, it's used for client side pagination only
  initialPageSize?: number;
  // fetched every time pagination occurs (for accurate data count from the database)
  totalItemsCount?: number;
}

export interface AppTableProps<T extends object = {}> {
  columns: TableColumns<T>[];
  data: T[];
  getRowId: (originalRow: T, relativeIndex: number, parent?: Row<T>) => string;
  to?: (originalRow: T) => string;
  component?: React.ElementType<any>;
  hasSelection?: boolean;
  rowActions?: (row: Row<T>) => Action[];
  appTableAllColumnsPropHandler?: (allColumnsProp: ColumnInstance<T>[]) => void;
  columnHiding?: ColumnHidingInterface;
  columnSorting?: boolean;
  paginationSetup?: PaginationSetupInterface;
  hasToolbar?: boolean;
  toolBarButtons?: ReactNode[];
  setSelectedRows?: React.Dispatch<React.SetStateAction<Row<any>[]>>;
  onRowClick?: (row: T) => void;
  onRowDoubleClick?: (row: T) => void;
  notStickyColumns?: TableColumns<Record<string, any>>[];
  onRowOver?: (rowIndex: number | null) => void;
}

const IndeterminateCheckbox = React.forwardRef<
  HTMLButtonElement,
  CheckboxProps
>((props, ref) => (
  <FormControlLabel
    control={<Checkbox ref={ref} {...props} />}
    //TODO: check if label needs to be translated
    label="Toggle All"
  />
));

export function AppTable<T extends object = {}>(
  props: AppTableProps<T>
): JSX.Element {
  const { t } = useTranslation();

  const {
    // initialization to avoid bugs if not passed.
    to = () => '',
    component = 'tr',
    hasSelection = false,
    appTableAllColumnsPropHandler,
    columnSorting = false,
    // initialize to empty object to avoid errors
    columnHiding = { fixedColumnsIDs: [], enabled: false },
    paginationSetup = {},
    hasToolbar,
    toolBarButtons,
    setSelectedRows,
    onRowClick,
    rowActions,
    onRowOver,
    onRowDoubleClick,
  } = props;

  const selectionHook = (hooks: Hooks<T>) => {
    hooks.allColumns.push((columns) => [
      {
        id: '_selector',
        maxWidth: 45,
        Header: ({ getToggleAllRowsSelectedProps }: HeaderProps<T>) => (
          <Checkbox size="small" {...getToggleAllRowsSelectedProps()} />
        ),
        Cell: ({ row }: CellProps<T>) => (
          <Checkbox size="small" {...row.getToggleRowSelectedProps()} />
        ),
        sticky: 'left',
      },
      ...columns,
    ]);
    hooks.useInstanceBeforeDimensions.push(({ headerGroups }) => {
      const selectionGroupHeader = headerGroups[0].headers[0];
      selectionGroupHeader.canResize = false;
    });
  };

  const actionsHook = (hooks: Hooks<T>) => {
    hooks.allColumns.push((columns) => [
      {
        id: '_actions',
        maxWidth: 45,
        Cell: ({ row }: CellProps<T>) => (
          <SimpleActionsMenu
            button={
              <IconButton size="small">
                <MoreVertIcon />
              </IconButton>
            }
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            isDisabled={true}
            actions={(rowActions as (row: Row<T>) => Action[])(row)}
          />
        ),
        sticky: 'left',
      },
      ...columns,
    ]);

    hooks.useInstanceBeforeDimensions.push(({ headerGroups }) => {
      const selectionGroupHeader = headerGroups[0].headers[0];
      selectionGroupHeader.canResize = false;
    });
  };

  const hooks = [
    useColumnOrder,
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded,
    useFlexLayout,
    useResizeColumns,
    ...(hasSelection ? [selectionHook] : []),
    ...(rowActions ? [actionsHook] : []),
    useSticky,
    useSortBy,
    usePagination,
    useRowSelect,
  ];

  // This is temp, as setting the initial page size works only in client size pagination
  // It needs to be set in both cases if needed

  let initialPaginationState: InitialPaginationState;

  if (paginationSetup.type !== 'clientSide') {
    initialPaginationState = {
      pageIndex: 0,
    };
  } else {
    // Server Side
    initialPaginationState = {
      pageIndex: 0,
      pageSize: paginationSetup.initialPageSize,
    };
  }

  const instance = useTable<T>(
    {
      ...props,
      // Pass our hoisted table state
      // Start on page 1
      initialState: initialPaginationState,

      // If true, it resets the pageIndex to the initialState each time it's changed.
      // It happens in case of "re-fetching" the data using the useEffect
      autoResetPage: false,

      // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // (For server-side pagination)
      manualPagination: paginationSetup.type === 'serverSide',
      pageCount: paginationSetup.controlledPageCount,
      autoResetSelectedRows: false,
    },
    ...hooks
  );

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    prepareRow,
    rows,
    allColumns,
    getToggleHideAllColumnsProps,
    //state,
    selectedFlatRows,
    // state: { selectedRowIds },

    // Pagination-related
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = instance;

  // To be used in case of pagination (use pages),
  // or in case of data without pagination (use just rows)
  let tableData: Row<T>[] = paginationSetup.enabled ? page : rows;

  const CustomTableRow = (row: Row<T>) => {
    return (
      <TableRow
        {...row.getRowProps()}
        component={to(row.original) ? Link : component}
        to={to(row.original)}
        onMouseEnter={() => onRowOver?.(row.index)}
        onMouseLeave={() => onRowOver?.(null)}
        onDoubleClick={() => onRowDoubleClick?.(row.original)}
      >
        {/* <TableRow {...row.getRowProps()} > */}
        {row.cells.map((cell) => (
          <TableCell
            {...cell.getCellProps()}
            onDoubleClick={() =>
              cell.column.id !== '_selector'
                ? onRowClick?.(cell.row.original)
                : null
            }
          >
            {cell.render('Cell')}
          </TableCell>
        ))}
      </TableRow>
    );
  };

  // Send allColumns to the parent to be used for column hiding
  useEffect(() => {
    // Only use it in case it's set in the instantiation of the AppTable.
    if (appTableAllColumnsPropHandler)
      appTableAllColumnsPropHandler(allColumns);
  }, [allColumns, appTableAllColumnsPropHandler]);

  // Listen for changes in pagination data state, upon that get next page (server side)
  useEffect(() => {
    if (paginationSetup.enabled && paginationSetup.type === 'serverSide') {
      paginationSetup.fetchData?.(pageSize, pageIndex);
    }
  }, [
    paginationSetup,
    paginationSetup.fetchData,
    pageIndex,
    pageSize,
    paginationSetup.enabled,
    paginationSetup.type,
  ]);

  useEffect(() => {
    if (setSelectedRows && selectedFlatRows.length) {
      setSelectedRows(selectedFlatRows);
    }
  }, [selectedFlatRows, setSelectedRows]);

  const [stickyRef, isSticky] = useStickyBar();

  return (
    <div css={appTableStyle(Boolean(onRowClick))}>
      <StickyBox ref={stickyRef} isSticky={isSticky}>
        {hasToolbar && Object.keys(selectedFlatRows).length > 0 && (
          <Box
            display="flex"
            justifyContent="space-between"
            bgcolor={colors.menuHover}
            p={2}
            mb={1}
          >
            <Box display="flex" alignItems="center">
              <DescriptionOutlinedIcon
                color="primary"
                fontSize="small"
                style={{ paddingRight: '12px', width: 'auto' }}
              />
              <Box>
                <Typography color="primary" variant="body2">
                  {Object.keys(selectedFlatRows).length}
                </Typography>
              </Box>
              <Box pl={1}>
                <Typography color="primary" variant="body2">
                  {Object.keys(selectedFlatRows).length > 1
                    ? t('pim.toolbar.multiple_selected_File')
                    : t('pim.toolbar.one_selected_File')}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" alignItems="center" color={colors.primary}>
              {toolBarButtons &&
                toolBarButtons.map((button, index) => (
                  <Box pr={1} key={index}>
                    {button}
                  </Box>
                ))}
            </Box>
          </Box>
        )}
      </StickyBox>

      {/* This is the basic column hiding of the react-table library, if needed. */}
      {columnHiding.enabled && (
        <>
          <Box>
            <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} />
          </Box>
          {allColumns.map(
            (column) =>
              // Only show checkboxes for non fixed columns
              columnHiding.fixedColumnsIDs?.includes(
                column.Header as string
              ) && (
                <Box key={column.id}>
                  <FormControlLabel
                    control={<Checkbox {...column.getToggleHiddenProps()} />}
                    label={column.Header}
                  />
                </Box>
              ),
            columnHiding
          )}
          <br />
        </>
      )}
      <Box overflow="auto">
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    // Table header props (sorting, searching...etc)
                    {...column.getHeaderProps()}
                    {...(columnSorting &&
                      column.getHeaderProps(column.getSortByToggleProps))}
                  >
                    <Box display="flex" color={colors.grey} whiteSpace="nowrap">
                      <Typography variant="body2">
                        {column.render('Header')}
                      </Typography>
                    </Box>

                    {/*Start: Sort direction indicator */}
                    {columnSorting && (
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <KeyboardArrowDownIcon fontSize="small" />
                          ) : (
                            <KeyboardArrowUpIcon fontSize="small" />
                          )
                        ) : (
                          ''
                        )}
                      </span>
                    )}
                    {/*End: Sort direction indicator */}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {tableData.map((row, i) => {
              prepareRow(row);
              return CustomTableRow(row);
            })}
            {/*Start: Table's data status */}
            {paginationSetup.enabled && (
              <TableRow>
                {paginationSetup.loading ? (
                  // Use our custom loading state to show a loading indicator
                  <TableCell colSpan={10000}>
                    {t('common.app_table.no_data')}
                  </TableCell>
                ) : null}
              </TableRow>
            )}
            {/*End: Table's data status */}
          </TableBody>
        </Table>
      </Box>
      {/* Start: Pagination bar */}
      {paginationSetup.enabled && (
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <IconButton onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              <SkipPreviousIcon />
            </IconButton>
            <IconButton
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              <NavigateBeforeIcon />
            </IconButton>
            <IconButton onClick={() => nextPage()} disabled={!canNextPage}>
              <NavigateNextIcon />
            </IconButton>
            <IconButton
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              <SkipNextIcon />
            </IconButton>
          </Grid>
          <Grid container item xs={1} alignItems="center">
            <Typography>
              {t('common.app_table.page')}{' '}
              <span style={{ fontWeight: 'bold' }}>{pageIndex + 1}</span> /{' '}
              <span style={{ fontWeight: 'bold' }}>{pageOptions.length}</span>
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <FormControl>
              <InputLabel htmlFor="component-helper">
                {t('common.app_table.go_to_page')}
              </InputLabel>
              <Input
                id="component-helper"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={1}>
            <FormControl>
              <InputLabel shrink>{t('common.app_table.total')}</InputLabel>
              <Select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
                displayEmpty
              >
                {[5, 10, 15, 20, 30, 40, 50].map((pageSize, index) => (
                  <MenuItem value={pageSize} key={index}>
                    {pageSize}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )}
      {/* End: Pagination bar */}
    </div>
  );
}
export default AppTable;
