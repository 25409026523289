import React from 'react';
import { css } from '@emotion/react/macro';
import AssignmentTwoToneIcon from '@material-ui/icons/AssignmentTwoTone';

export const style = css`
  text-align: center;
  width: 100%;

  svg {
    width: 7em;
    height: 7em;
  }

  p {
    color: grey;
  }
`;

interface NoItemsNoticeProps {
  message: string;
}

const NoItemsNotice = ({ message }: NoItemsNoticeProps) => (
  <div css={style}>
    <AssignmentTwoToneIcon color="disabled" />
    <p>{message}</p>
  </div>
);

export default NoItemsNotice;
