import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { FormikTouched } from 'formik';

export const handleChange = (
  setTouched: (
    touched: FormikTouched<any>,
    shouldValidate?: boolean | undefined
  ) => void,
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void,
  touched: FormikTouched<any>,
  name: string
) => (date: MaterialUiPickersDate) => {
  setTouched({ ...touched, [name]: true });
  setFieldValue(name, date);
};
