import { css } from '@emotion/react';
import { isNumber } from 'lodash';
import { useTranslation } from 'react-i18next';
import colors from 'styles/colors.module.scss';

//TODO : temp until commitment status are implemented
export enum CommitmentStatus {
  Validated = 'Validated',
}

const style = (tagBgColor: string, tagColor: string) => css`
  background: ${tagBgColor};
  border-radius: 26px;
  color: ${tagColor};
  font-family: ScandiaWebMedium;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  overflow: hidden;
  padding: 5px 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: fit-content;
`;

const OrderSachaDeliveryPointTag = ({
  deliveryPointStatus,
}: {
  deliveryPointStatus: number | null;
}) => {
  const { t } = useTranslation();

  const followStatusItems = [
    'follow_orders.sacha_status.delivered',
    'follow_orders.sacha_status.delivery_in_progress',
    'follow_orders.sacha_status.transit',
    'follow_orders.sacha_status.incident',
    'follow_orders.sacha_status.preparation',
    'follow_orders.sacha_status.back',
  ];

  let tagColor = '';
  let tagBgColor = '';
  switch (deliveryPointStatus) {
    case 0:
      tagColor = colors.success;
      tagBgColor = colors.acidGreenOpacity;
      break;
    case 1:
      tagColor = colors.orange300;
      tagBgColor = colors.orange300Opacity;
      break;
    case 2:
      tagColor = colors.orangeAcid;
      tagBgColor = colors.orangeAcidOpacity;
      break;
    case 3:
      tagColor = colors.alertBlue;
      tagBgColor = colors.alertBlueOpacity;
      break;
    case 4:
      tagColor = colors.blueGrey;
      tagBgColor = colors.blueGreyOpacity;
      break;
    case 5:
      tagColor = colors.loader6;
      tagBgColor = colors.weirdGreenOpacity;
      break;
    default:
      break;
  }

  console.log('deliveryPointStatus', deliveryPointStatus);
  return (
    <div css={style(tagBgColor, tagColor)}>
      {' '}
      {isNumber(deliveryPointStatus)
        ? t(followStatusItems[deliveryPointStatus])
        : ''}{' '}
    </div>
  );
};

export default OrderSachaDeliveryPointTag;
