import React, {
  createContext,
  useReducer,
  useContext,
  useEffect,
  PropsWithChildren,
} from 'react';
import { studioContextReducer } from 'modules/workflow/reducers/studioReducer';
import {
  AcceptedActions,
  GetVersion,
  studioAppActions,
} from 'modules/workflow/reducers/actionsInterfaces';
import { useQuery } from '@apollo/client';
import { GET_VERSION_QUERY } from 'app/graphql/queries/version';
import useDocumentQuery from 'queries/useDocumentQuery';
import {
  GetVersionQuery,
  GetVersionQueryVariables,
  GetVersionQuery_getVersion_template,
} from 'app/schemaInterfaces/GetVersionQuery';
import { useRouter } from 'app/routes/useRouter';
import Loader from 'components/Loader/Loader/Loader';
import { IProductTemplate } from 'modules/workflow/Clients/ToutFaire/ProductItem';
import { ItemSize } from 'modules/workflow/containers/CatalogPreview/catalogPreviewHelper';
import { UrlParams } from 'modules/workflow/containers/Toolbar/FirstToolbar/FirstToolbar';
import { BackgroundAsset } from 'modules/workflow/containers/Leftsidebar/TemplatesPanel/BackgroundItemsSubPanel';
import { DraggedItemTypes } from 'modules/workflow/containers/CatalogPreview/EditPlatformDefinitions';
import { SCALE_VALUE_A4 } from 'styles/theme';
import { Moment } from 'moment';
import { GetDocument_document } from 'app/schemaInterfaces/GetDocument';
import {
  VisualIdentityType,
  PriceElement,
  VisualType,
  AssetType,
  UserType,
  BigShopConfigCategory,
} from 'app/schemaInterfaces/globalTypes';
import { Redirect, useLocation } from 'react-router-dom';
import usePermissions from 'app/auth/usePermissions';
import { PermissionKey } from 'app/schemaInterfaces/globalTypes';
import { AppRoute } from 'app/routes/routes';
import { AssetMetadata } from 'app/schemaInterfaces/AssetMetadata';

export interface Position {
  x: number;
  y: number;
}

export interface Field {
  id: number;
  name: string;
}

export interface TypographyItem {
  title?: string;
  dateFrom?: Moment | null;
  dateTo?: Moment | null;
}

export interface PersonalizationItem {
  content?: IProductTemplate | DraggedAsset;
  text?: TypographyItem;
  key: string;
  type: PersonalizationType;
  subType: PersonalizationSubType;
  position: Position;
  dimensions: ItemSize;
  userType: UserType;
}

export interface Asset {
  id: string;
  // TODO: Save only ID, position and size when sending mutation => exclude (url, metadata and name)
  name: string;
  url: string;
  thumbnailUrl?: string;
  metadata: {
    dimensions: ItemSize;
  };
  size: ItemSize;
  position: Position;
  visualIdentityType?: VisualIdentityType;
  assetType?: string;
  isDraggable?: boolean;
  isResizable?: boolean;
  visualType?: VisualType;
  fieldName?: string;
  conform?: boolean;
  display?: boolean;
  color?: string;
  index?: number;
}

export interface TypographyIdentities {
  id: string;
  name: string;
  position: Position;
  type: VisualIdentityType;
  fontWeight: number;
  fontFamily: string;
  fontSize: number;
  fontStyle: string;
  priceElement?: PriceElement;
  lineThrough: boolean;
  isCapitalLetters?: boolean;
  color?: string;
  lineHeight?: number;
  display?: boolean;
  width?: number;
  index?: number;
}

export interface Regrouping {
  productId: string | null;
  id: string;
  name: string;
  url: string;
  thumbnailUrl: string;
  metadata?: AssetMetadata;
  assetType?: AssetType;
  position: Position;
  size: ItemSize;
  details: IProductTemplate['productDetails'];
  isVisible: boolean;
  isMain: boolean;
  visualType?: VisualType;
}

export interface DraggedAsset extends Asset {
  key: string;
}

export interface DraggedBackgroundAsset extends BackgroundAsset {
  key: string;
}

export interface Components {
  components: {
    title: {
      color: 'white' | 'black';
    };
    description: {
      color: 'white' | 'black';
    };
    lePlus: {
      color: 'white' | 'black';
    };
  };
}

export interface Product {
  id: number;
  family: { name: string };
  label: string;
  description: string;
  image: string;
  selling_price: number;
  sales_unit: string;
  condition: string;
  position?: Position;
  size?: ItemSize;
  product_infos: { product_info: string }[];
}

export interface ZoneProps extends Position {
  width: number;
  height: number;
  sector: string;
  numberOfProducts?: number;
  turnover?: number;
  background?: DraggedBackgroundAsset;
  personalizationItems?: PersonalizationItem[];
  product?: IProductTemplate[];
  assets?: DraggedAsset[];
}

export interface Page {
  validated: boolean;
  isCustomizable: boolean;
  incompleteProducts: number;
  unseenComments: number;
  thumbnailUrl?: string;
}

export interface PageTemplate extends Page {
  id: string;
  pageTemplate: ZoneProps[];
  backgroundColor?: DraggedBackgroundAsset;
}

export interface DigitalContent {
  message?: string | null;
  url?: string | null;
}
export interface Version {
  id: string;
  campaignId: string;
  createdAt: string;
  updatedAt: Date | null;
  pages: PageTemplate[];
  digitalContent: DigitalContent | null;
  template: GetVersionQuery_getVersion_template | null;
}

interface undoRedo {
  catalogHistory: Version[];
  historyStep: number;
}

export interface DraggedZone {
  pageNumberToApply: number;
  zoneIndex: number;
}

export interface DraggedPersonalizationZone {
  key: string;
  subType: PersonalizationSubType;
  size: ItemSize;
}

export interface CurrentTab {
  value: LeftSideBarTabValue;
  subType?: AssetType;
}

export interface StudioStates {
  version: Version;
  document: GetDocument_document | null;
  undoRedo: undoRedo;
  currentPage: number;
  isTwoPagesView: boolean;
  draggedItem?:
    | DraggedItem<ZoneProps[], DraggedItemTypes.PAGE_TEMPLATE>
    | DraggedItem<DraggedAsset, DraggedItemTypes.ASSET>
    | DraggedItem<string, DraggedItemTypes.SET_PERSONALIZATION_ITEM_CATEGORY>
    | DraggedItem<DraggedProduct, DraggedItemTypes.PRODUCT>
    | DraggedItem<
        DraggedBackgroundAsset,
        DraggedItemTypes.BACKGROUND_COLOR | DraggedItemTypes.BACKGROUND_IMAGE
      >;
  panningEnabled: boolean;
  uploadedBackgroundImage: {
    URL: string;
    title: string;
  };
  commentMode: boolean;
  selectedZone?: string;
  scaleValue: number;
  tabValue: CurrentTab;
  isEditable: boolean;
  isDigital: boolean;
}

export interface DraggedItem<Item, Type> {
  item: Item;
  type: Type;
  subType?: string;
}

export enum PersonalizationType {
  TYPOGRAPHY = 'TYPOGRAPHY',
  CONTENT = 'CONTENT',
}

export enum PersonalizationSubType {
  HEADER_TITLE = 'HEADER_TITLE',
  SUBTITLE = 'SUBTITLE',
  NORMAL_PARAGRAPH = 'NORMAL_PARAGRAPH',
  DATE = 'DATE',
  PRODUCT = 'PRODUCT',
  ADDRESS_BLOCK = 'ADDRESSBLOCK',
  VISUAL = 'VISUAL',
  PICTO = 'PICTO',
  LOGO = 'LOGO',
  CHARTER = 'CHARTER',
}
export enum TabMenu {
  ADDRESS_BLOCK = 6,
  ASSET = 4,
  CUSTOMIZE = 5,
  GRID = 1,
  PAGES = 0,
  PRODUCT = 3,
  TEMPLATE = 2,
}

export enum DigitalMenu {
  VISUAL = 0,
  TEXT = 1,
}

export type LeftSideBarTabValue =
  | TabMenu.ADDRESS_BLOCK
  | TabMenu.ASSET
  | TabMenu.CUSTOMIZE
  | TabMenu.GRID
  | TabMenu.PAGES
  | TabMenu.PRODUCT
  | TabMenu.TEMPLATE;

export const personalizationMediaTypes = [
  'VISUAL',
  'PICTO',
  'LOGO',
  'CHARTER',
  'ADDRESSBLOCK',
];

export type DraggedProduct = Omit<
  IProductTemplate,
  'position' | 'size' | 'components'
>;

type StudioDispatcher = (action: AcceptedActions) => void;

export const StudioStateContext = createContext<StudioStates | undefined>(
  undefined
);
export const StudioDispatchContext = createContext<
  StudioDispatcher | undefined
>(undefined);

export const useStudioStateContext = () => {
  const context = useContext(StudioStateContext);
  if (context === undefined) {
    throw new Error(
      'useStudioStateContext must be used within StudioContextProvider.'
    );
  }
  return context;
};
export const useStudioDispatchContext = () => {
  const context = useContext(StudioDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useStudioDispatchContext must be used within StudioContextProvider.'
    );
  }
  return context;
};

const initializeStudioContext = (): StudioStates => {
  return {
    document: null,
    version: {
      id: '0',
      campaignId: '',
      createdAt: '',
      updatedAt: null,
      pages: [],
      digitalContent: null,
      template: null,
    },
    undoRedo: {
      catalogHistory: [],
      historyStep: -1,
    },
    currentPage: 1,
    isTwoPagesView: false,
    panningEnabled: false,
    uploadedBackgroundImage: {
      URL: '',
      title: '',
    },
    commentMode: false,
    scaleValue: SCALE_VALUE_A4,
    tabValue: { value: TabMenu.PAGES, subType: undefined },
    isEditable: true,
    isDigital: false,
  };
};
const StudioContextProvider = ({
  children,
}: PropsWithChildren<{}>): JSX.Element => {
  const [state, dispatch] = useReducer(
    studioContextReducer,
    initializeStudioContext()
  );
  const { documentId } = useRouter<UrlParams>().query;
  const { search } = useLocation();
  const documentType = new URLSearchParams(search).get('type');

  const { data: documentData } = useDocumentQuery(documentId);

  const { data: versionData, error } = useQuery<
    GetVersionQuery,
    GetVersionQueryVariables
  >(GET_VERSION_QUERY, {
    variables: { documentId },
    fetchPolicy: 'network-only',
  });

  const { hasPermission } = usePermissions();

  const hasAccess = documentData
    ? documentData?.document.isCustomization
      ? hasPermission(PermissionKey.ReadBasketVersion)
      : hasPermission(PermissionKey.ReadVersion)
    : true;

  useEffect(() => {
    if (documentData) {
      dispatch(
        studioAppActions.SetDocument({
          document: documentData.document,
        })
      );
    }

    if (versionData) {
      dispatch(
        studioAppActions.SetVersion({
          version: versionData.getVersion as GetVersion['version'],
        })
      );
    }
    if (documentType) {
      dispatch(
        studioAppActions.SetEditable({
          isEditable:
            documentType === 'readonly'
              ? false
              : Boolean(documentData?.document.isCustomizable),
        })
      );
    }
    if (documentData?.document.category === BigShopConfigCategory.Digital) {
      dispatch(
        studioAppActions.SetDigital({
          isDigital: true,
        })
      );
    }
  }, [documentData, versionData, documentType, dispatch]);

  return hasAccess ? (
    versionData && documentData ? (
      <StudioStateContext.Provider value={state}>
        <StudioDispatchContext.Provider value={dispatch}>
          {children}
        </StudioDispatchContext.Provider>
      </StudioStateContext.Provider>
    ) : (
      <Loader error={error} />
    )
  ) : (
    <Redirect to={{ pathname: AppRoute.AccessDenied }} />
  );
};

export default StudioContextProvider;
