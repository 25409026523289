import {
  AddBasketDocument,
  AddBasketDocumentVariables,
} from 'app/schemaInterfaces/AddBasketDocument';
import { ApolloQueryResult, useMutation } from '@apollo/client';

import { useHistory, useLocation } from 'react-router-dom';
import { ADD_BASKET_DOCUMENT } from 'app/graphql/queries/basket';
import {
  BasketType,
  DisabledDocumentErrors,
} from 'app/schemaInterfaces/globalTypes';
import { useMutationErrorWorkflow } from 'app/utils/customHooks/useMutationErrorWorkflow';
import { StoreRoutes } from 'modules/oneManager/storeHelper';
import { BasketI18nKeyErrorDictionary } from 'modules/sales/pages/basket-page/basket-steps/StepsHelper';
import { thematicsLinkPrefix, getButtonProps } from 'modules/shop/shopHelpers';
import { GetDocumentsToShop_documents_documents } from 'app/schemaInterfaces/GetDocumentsToShop';
import useCurrentEntity from 'app/current-entity-context/useCurrentEntity';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { GetDocumentsToShopVariables } from 'app/schemaInterfaces/GetDocumentsToShop';
import { GetDocumentsToShop } from 'app/schemaInterfaces/GetDocumentsToShop';
import {
  GetBasketDocumentsVariables,
  GetBasketDocuments,
} from 'app/schemaInterfaces/GetBasketDocuments';

export interface useDocumentsListViewParams {
  document: GetDocumentsToShop_documents_documents;
  documentRefetch: (
    variables?: Partial<GetDocumentsToShopVariables> | undefined
  ) => Promise<ApolloQueryResult<GetDocumentsToShop>>;
  basketRefetch: (
    variables?: Partial<GetBasketDocumentsVariables> | undefined
  ) => Promise<ApolloQueryResult<GetBasketDocuments>>;
  setIsAlert?: React.Dispatch<React.SetStateAction<boolean>>;
  disabled: boolean;
}
export const useDocumentsListView = ({
  document,
  documentRefetch,
  basketRefetch,
  setIsAlert,
  disabled,
}: useDocumentsListViewParams) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { currentEntity } = useCurrentEntity();

  const isCampaignModel = pathname.includes(thematicsLinkPrefix);

  const [updateQuantityRequest] = useMutation<
    AddBasketDocument,
    AddBasketDocumentVariables
  >(ADD_BASKET_DOCUMENT);
  const updateQuantity = useMutationErrorWorkflow({
    request: updateQuantityRequest,
    i18nKeyErrorDictionary: BasketI18nKeyErrorDictionary,
    showConfirmationMessage: false,
  });

  const addToBasket = async () => {
    return await updateQuantity({
      values: {
        documentId: document.id,
        entityId: currentEntity?.id ?? '',
        type: !isCampaignModel ? BasketType.Commitment : BasketType.BigShop,
      },
    });
  };

  const handleSubmitOrder = async () => {
    if (
      document.isDisabled.reason === DisabledDocumentErrors.needFacebookAuth
    ) {
      history.push(StoreRoutes.SocialNetworks);
    } else {
      setIsLoading(true);
      const response = await addToBasket();
      if (!response) {
        setIsLoading(false);
        return;
      }

      if (response.data?.addBasketDocument) {
        setIsAlert?.(true);
        await documentRefetch();
        await basketRefetch();
      }
      setIsLoading(false);
    }
  };

  const buttonProps = getButtonProps({
    isDisabled: document.isDisabled,
    t,
    disabled,
    loading: isLoading,
  });

  return {
    handleSubmitOrder,
    buttonProps,
  };
};
