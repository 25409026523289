import { useQuery } from '@apollo/client';
import { GET_SUPPORT_FABRICATION_TIMES } from 'app/graphql/queries/supports';
import {
  FabricationTimes,
  FabricationTimesVariables,
} from 'app/schemaInterfaces/FabricationTimes';

export interface FabricationTimesQueryProps {
  quantity: number;
  supportId: string;
}

const useFabricationTimesQuery = ({
  supportId,
  quantity,
}: FabricationTimesQueryProps) =>
  useQuery<FabricationTimes, FabricationTimesVariables>(
    GET_SUPPORT_FABRICATION_TIMES,
    {
      variables: {
        quantity,
        supportId,
      },
      fetchPolicy: 'no-cache',
    }
  );

export default useFabricationTimesQuery;
