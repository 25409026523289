import { Box, Button } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { SourceFileFiltersPopover } from 'modules/pro/SourceFiles/SourceFileFiltersPopover';
import SourceFilesSearchBar from 'modules/pro/SourceFiles/SourceFilesSearchBar';
import { GetCampaignAttachments_getCampaignAttachments } from 'app/schemaInterfaces/GetCampaignAttachments';
import { SourceFileFilters } from 'modules/pro/SourceFiles/SourceFilesContainer';

interface TableToolbarProps {
  searchPlaceholder?: string;
  onFileChange: (files: File[]) => void;
  acceptedFiles: string | string[];
  filters: SourceFileFilters;
  setFilters: React.Dispatch<React.SetStateAction<SourceFileFilters>>;
  dataFilters: GetCampaignAttachments_getCampaignAttachments[];
}

const SourceFilesToolbar = ({
  searchPlaceholder,
  onFileChange,
  acceptedFiles,
  filters,
  setFilters,
  dataFilters,
}: TableToolbarProps): JSX.Element => {
  const { getInputProps, open } = useDropzone({
    onDrop: (files: File[]) => {
      onFileChange(files);
    },
    accept: acceptedFiles,
  });

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      pt={2}
      pb={2}
    >
      <Box display="flex" alignItems="center">
        <Box pr={1}>
          <SourceFileFiltersPopover
            filters={filters}
            setFilters={setFilters}
            data={dataFilters}
            height={300}
          />
        </Box>
        <Box pr={1}>
          <SourceFilesSearchBar
            placeholder={searchPlaceholder}
            filters={filters}
            setFilters={setFilters}
          />
        </Box>
        <Box borderRadius="25px">
          <Button
            color="primary"
            size="small"
            component="label"
            variant="contained"
            startIcon={<AddCircleIcon />}
          >
            Ajouter un fichier
            <input
              style={{ display: 'none' }}
              {...getInputProps()}
              onClick={open}
            />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SourceFilesToolbar;
