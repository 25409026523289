import React, { useState, FC, useMemo } from 'react';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { FieldProps } from 'formik';
import { Option } from 'components/ControlledOneValueSelect';
import {
  radioValueBoxStyle,
  radioValueSelectedBoxStyle,
} from './RadioBoxsItems.style';
import { splitLines } from 'app/utils/collections';

export interface RadioBoxsItemsProps extends FieldProps {
  radioValues: Option[];
  itemsPerLine: number;
  initialSelectedValue: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onOptionChange?: (obj: Option) => void;
  oneSupplyAttributeName: string;
  onPreviousSelection: boolean;
  isBorderAndBackground?: boolean;
}

const RadioBoxsItems: FC<RadioBoxsItemsProps> = ({
  form: { errors, touched, setFieldValue },
  field: { name },
  radioValues,
  itemsPerLine,
  initialSelectedValue,
  oneSupplyAttributeName,
  onPreviousSelection,
  onOptionChange,
  isBorderAndBackground = true,
}) => {
  const [value, setValue] = useState<string>(initialSelectedValue);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    setFieldValue(name, event.target.value);
    onOptionChange?.({
      id: event.target.id,
      value: event.target.value,
      oneSupplyAttributeName,
    });
  };

  const errorMessage = touched[name] && errors[name];
  const lines = useMemo(() => splitLines(radioValues, itemsPerLine), [
    radioValues,
    itemsPerLine,
  ]);

  return (
    <FormControl component="fieldset" error={!!errorMessage}>
      <RadioGroup
        value={!onPreviousSelection ? value : ''}
        data-testid="radio-group"
      >
        {lines.map((lineValues, lineNumber) => (
          <Box key={lineNumber} display="flex" mt={2}>
            {lineValues.map((radioValue, index) => (
              <Box
                key={index}
                css={[
                  radioValueBoxStyle(isBorderAndBackground),
                  value === radioValue.value &&
                    !onPreviousSelection &&
                    radioValueSelectedBoxStyle(isBorderAndBackground),
                ]}
                display="flex"
                mr={2}
              >
                <FormControlLabel
                  value={radioValue.value}
                  control={
                    <Radio
                      id={radioValue.id}
                      name={name}
                      onChange={handleChange}
                    />
                  }
                  label={radioValue.label ?? radioValue.value}
                />
              </Box>
            ))}
          </Box>
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioBoxsItems;
