import Tab from '@material-ui/core/Tab';
import { AppBar, Tabs, makeStyles } from '@material-ui/core';
import React from 'react';
import { capitalizeFirstLetter } from 'app/utils/common';
import colors from 'styles/colors.module.scss';

interface FormTabsProps {
  items?: Record<string, any>;
  selectedTabIndex: number;
  handleChangeTabIndex: (
    event: React.ChangeEvent<{}>,
    tabIndex: number
  ) => void;
}

function simpleTabPanel(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const FormTabs = ({
  items,
  selectedTabIndex,
  handleChangeTabIndex,
}: FormTabsProps): JSX.Element => {
  const classes = makeStyles({
    tab: {
      minWidth: '1px',
      color: colors.inputLabelColor,
      '&$selected': {
        color: colors.tooltip,
      },
    },
    selected: {
      color: colors.tooltip,
      border: `1px solid ${colors.inputLabelColor}`,
      borderRadius: '6px 6px 0px 0px',
      borderBottom: 'none',
    },
    indicator: {
      display: 'none',
    },
    fixed: {
      borderBottom: `1px solid ${colors.inputLabelColor}`,
    },
  })();

  return (
    <AppBar position="static" color="default">
      <Tabs
        classes={{ indicator: classes.indicator, fixed: classes.fixed }}
        value={selectedTabIndex}
        onChange={handleChangeTabIndex}
        aria-label="simple tabs example"
      >
        {items?.map((item: Record<string, any>, index: number) => (
          <Tab
            classes={{ root: classes.tab, selected: classes.selected }}
            key={index}
            label={capitalizeFirstLetter(item.tabName)}
            {...simpleTabPanel(index)}
          />
        ))}
      </Tabs>
    </AppBar>
  );
};
