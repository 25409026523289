import React from 'react';
import { useCampaignFilterPopover } from './CampaignFilterPopover.hooks';
import FilterPopover from 'components/FilterPopover/FilterPopover';
import FilterOptions from 'components/FilterOptions/FilterOptions';
import { CampaignFilterInput } from 'app/schemaInterfaces/globalTypes';

interface FilterPopoverProps {
  setFilters: React.Dispatch<React.SetStateAction<CampaignFilterInput>>;
  filters: CampaignFilterInput;
}

export const CampaignFilterPopover = ({
  setFilters,
  filters,
}: FilterPopoverProps): JSX.Element => {
  const {
    popoverId,
    anchorEl,
    handleClick,
    handleClose,
    resetFilter,
    campaignFilterInputs,
  } = useCampaignFilterPopover(filters, setFilters);

  return (
    <FilterPopover
      popoverId={popoverId}
      anchorEl={anchorEl}
      handleClick={handleClick}
      handleClose={handleClose}
      isDarkMode={true}
    >
      <FilterOptions
        resetFilter={resetFilter}
        filterInputs={campaignFilterInputs}
      />
    </FilterPopover>
  );
};
