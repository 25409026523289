import React, { PropsWithChildren } from 'react';
import { ApolloClient, ApolloProvider, from, HttpLink } from '@apollo/client';
import { useAuth0 } from '../auth/AuthContext';
import { onError } from '@apollo/link-error';
import { typeDefs } from './queries/typeDefs';
import { cache } from './cache';

const GraphqlProvider = ({ children }: PropsWithChildren<{}>): JSX.Element => {
  const { accessToken } = useAuth0();

  const apolloClient = new ApolloClient({
    cache,
    typeDefs,
    link: from([
      // Global error handler middleware
      onError(({ graphQLErrors }) => {
        if (graphQLErrors) {
          console.log('Errors: ', graphQLErrors);
          graphQLErrors.map(({ message, locations, path }) =>
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
          );
        }
      }),
      // Adds Authorization header to every graphql request
      new HttpLink({
        uri: process.env.REACT_APP_API_ENDPOINT,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        fetchOptions: { notifyOnNetworkStatusChange: true },
      }),
    ]),
  });
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};
export default GraphqlProvider;
