import {
  ApolloQueryResult,
  useApolloClient,
  useMutation,
} from '@apollo/client';
import {
  CREATE_CAMPAIGN,
  GET_CAMPAIGN_IMAGE_UPLOAD_URL,
  UPDATE_CAMPAIGN,
} from 'app/graphql/queries/campaigns';
import {
  CreateCampaign,
  CreateCampaignVariables,
} from 'app/schemaInterfaces/CreateCampaign';
import {
  GetAllCampaignsLean,
  GetAllCampaignsLeanVariables,
} from 'app/schemaInterfaces/GetAllCampaignsLean';
import {
  GetCampaignById,
  GetCampaignByIdVariables,
} from 'app/schemaInterfaces/GetCampaignById';
import {
  GetCampaignImageUploadUrl,
  GetCampaignImageUploadUrlVariables,
} from 'app/schemaInterfaces/GetCampaignImageUploadUrl';
import { CampaignType, TagType } from 'app/schemaInterfaces/globalTypes';
import {
  UpdateCampaignById,
  UpdateCampaignByIdVariables,
} from 'app/schemaInterfaces/UpdateCampaignById';
import { uploadFile } from 'app/utils/FileUpload/FileUpload';
import useCategoriesQuery from 'modules/pro/hooks/useCategoriesQuery';
import useTagsQuery from 'queries/useTagsQuery';
import { useState } from 'react';
import { CampaignFormModel } from '../model/definitions';

export const useCampaignFormCreation = (
  isEditMode: boolean,
  isCampaignModel: boolean,
  handleClose: () => void,
  id?: string,
  refetch?: (
    variables?: GetAllCampaignsLeanVariables
  ) => Promise<ApolloQueryResult<GetAllCampaignsLean>>,
  refetchCampaignById?: (
    variables?: Partial<GetCampaignByIdVariables> | undefined
  ) => Promise<ApolloQueryResult<GetCampaignById>>
) => {
  const client = useApolloClient();

  const { tags } = useTagsQuery({ type: TagType.Campaign });

  const { category } = useCategoriesQuery();
  const [isUploadInProgress, setIsUploadInProgress] = useState<boolean>(false);

  const [createCampaign] = useMutation<CreateCampaign, CreateCampaignVariables>(
    CREATE_CAMPAIGN
  );

  const [updateCampaignById] = useMutation<
    UpdateCampaignById,
    UpdateCampaignByIdVariables
  >(UPDATE_CAMPAIGN);

  const uploadFileToCloud = async (file: File): Promise<string> => {
    const mimetype = file.type;
    const {
      data: {
        getCampaignImageUploadUrl: { id, url },
      },
    } = await client.query<
      GetCampaignImageUploadUrl,
      GetCampaignImageUploadUrlVariables
    >({
      query: GET_CAMPAIGN_IMAGE_UPLOAD_URL,
      variables: {
        mimetype,
      },
      fetchPolicy: 'no-cache',
    });

    await uploadFile(file, url, mimetype);

    return id;
  };

  const onFormValidation = async (values: CampaignFormModel) => {
    const campaign = {
      campaignType: !isCampaignModel
        ? CampaignType.National
        : CampaignType.Model,
      dateFrom: !isCampaignModel ? values.dateFrom : null,
      dateTo: !isCampaignModel ? values.dateTo : null,
      commitmentDateFrom: !isCampaignModel ? values.commitmentDateFrom : null,
      commitmentDateTo: !isCampaignModel ? values.commitmentDateTo : null,
      description: values.description,
      entities: values.entities.map(({ id }) => id),
      name: values.name,
      imageId: values.imageId,
      reference: values.reference,
      tags: !isCampaignModel ? values.tags : null,
      category: isCampaignModel ? values.category : null,
    };

    try {
      setIsUploadInProgress(true);

      if (values.fileUpload) {
        campaign.imageId = await uploadFileToCloud(values.fileUpload);
      }

      if (!isEditMode) {
        const data = await createCampaign({
          variables: {
            campaign,
          },
        });
        if (data) {
          refetch?.();
        }
      } else {
        const data = await updateCampaignById({
          variables: {
            id: id as string,
            campaign,
          },
        });
        if (data) {
          refetchCampaignById?.();
        }
      }

      setIsUploadInProgress(false);
      handleClose();
    } catch (e) {
      setIsUploadInProgress(false);
      console.log(e);
    }
  };
  return { onFormValidation, tags, category, isUploadInProgress };
};
