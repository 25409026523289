import { css } from '@emotion/react/macro';
import colors from 'styles/colors.module.scss';

export const switchViewStyle = (switchAction: boolean) => css`
  label {
    position: relative;
    top: 0;
    left: 0;
    border-radius: 25px;
    width: 95px;
    height: 40px;
    display: flex;
    align-items: center;
    background: ${colors.menuHover};
    cursor: pointer;
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      border-radius: 25px;
      width: 40px;
      height: 30px;
      margin: 5px;
      transition: 0.2s;
      background: ${colors.primary};
    }
    .icon-box {
      display: flex;
      width: inherit;
      justify-content: space-around;

      .switch-icon-list {
        position: relative;
        left: 1px;
        z-index: 1;
        display: flex;
        align-items: center;
      }
      .switch-icon-grid {
        position: relative;
        right: 1px;
        z-index: 1;
        display: flex;
        align-items: center;
      }
      .icon {
        font-size: 18px;
      }
      .list-icon {
        color: ${!switchAction ? colors.white : colors.primary};
      }
      .grid-icon {
        color: ${switchAction ? colors.white : colors.primary};
      }
    }
  }

  input {
    opacity: 0;
    z-index: 1;
    border-radius: 25px;
    width: 40px;
    height: 30px;
    display: none;
  }
  input:checked + label {
    background-color: ${colors.menuHover};
    &::after {
      content: '';
      display: block;
      border-radius: 25px;
      width: 40px;
      height: 30px;
      margin-left: 50px;
      transition: 0.2s;
      background: ${colors.primary};
    }
  }
`;
