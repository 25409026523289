import { Grid } from '@material-ui/core';
import { DEFAULT_IMAGE_PREVIEW, formatPriceHT } from 'app/utils/common';
import IsCustomizableTag from 'modules/shop/components/IsCustomizableTag';
import colors from 'styles/colors.module.scss';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { useTranslation } from 'react-i18next';
import { summaryAddressesRowStyle } from './SummaryAddressesRow.style';
import SummaryAddressDeliveryDetails from '../SummaryAddressDeliveryDetails/SummaryAddressDeliveryDetails';
import { hasPublicationPeriod } from 'app/utils/digital-helper';
import SummaryPublicationDeliveryDetails from '../SummaryPublicationDeliveryDetails/SummaryPublicationDeliveryDetails';
import { SummaryAddressesRowProps } from './SummaryAddressesRow.definitions';

const SummaryAddressesRow = ({
  basketDocumentItem,
}: SummaryAddressesRowProps) => {
  const { t } = useTranslation();

  const {
    isValidated,
    isCustomizable,
    name,
    thumbnailUrl,
    productAttributes,
  } = basketDocumentItem.basketDocument.document;

  const validatedIcon: JSX.Element = (
    <CheckCircleOutlineIcon
      htmlColor={colors.loader1}
      style={{ marginLeft: '12px' }}
    />
  );

  const totalPrice = basketDocumentItem.addresses?.reduce(
    (acc, currentAddress) => {
      return acc + (currentAddress.price ?? 0); // insured by validation
    },
    0
  );
  const price = totalPrice
    ? formatPriceHT(totalPrice, t)
    : t('local.campaign_page.included'); // TODO : use isIncluded

  return (
    <Grid container css={summaryAddressesRowStyle} className="document-row">
      <Grid item xs={2} className="media-wrapper">
        <img
          src={thumbnailUrl ?? DEFAULT_IMAGE_PREVIEW}
          alt="document"
          className="media"
        />
      </Grid>
      <Grid container item xs={10} justifyContent="center">
        <Grid item xs={9} className="content">
          <div className="document-title">
            <h5>{name}</h5>
          </div>
          <div className="details-wrapper">
            {hasPublicationPeriod(productAttributes) ? (
              <SummaryPublicationDeliveryDetails
                deliveryDate={basketDocumentItem.deliveryDate}
              />
            ) : basketDocumentItem.addresses ? (
              <SummaryAddressDeliveryDetails
                addresses={basketDocumentItem.addresses}
              />
            ) : null}
          </div>
          <div className="tag-wrapper">
            <IsCustomizableTag isCustomizable={isCustomizable} />
            {isCustomizable && isValidated && validatedIcon}
          </div>
        </Grid>
        <Grid item xs={3} className="summary-price">
          <h3>{price}</h3>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SummaryAddressesRow;
