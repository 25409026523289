import React from 'react';
import {
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  Box,
  Typography,
} from '@material-ui/core';
import {
  useStudioDispatchContext,
  ZoneProps,
} from 'modules/workflow/oneStudioContext';
import { studioAppActions } from 'modules/workflow/reducers/actionsInterfaces';
import colors from 'styles/colors.module.scss';
import useAppContext from 'app/app-context/useAppContext';
import { DraggedItemTypes } from 'modules/workflow/containers/CatalogPreview/EditPlatformDefinitions';

export interface Templates {
  zones: ZoneProps[];
}

const GridsPanel = (): JSX.Element => {
  const dispatch = useStudioDispatchContext();
  const {
    client: { config: clientConfig },
  } = useAppContext();

  let clientTemplates = clientConfig?.workflow.templates ?? [];
  const templates: Templates[] = clientTemplates.reduce<Templates[]>(
    (templates, clientTemplate) => {
      const templateWithSector = clientTemplate?.zones?.reduce<ZoneProps[]>(
        (zones, zone, index) => {
          zones.push({
            ...zone,
            sector: '',
          });
          return zones;
        },
        []
      );
      templates?.push({
        zones: templateWithSector as ZoneProps[],
      });
      return templates;
    },
    []
  );

  const dragHandler = (indx: number) => {
    dispatch(
      studioAppActions.setDraggedPageTemplate({
        draggedItem: {
          item: templates[indx].zones as ZoneProps[],
          type: DraggedItemTypes.PAGE_TEMPLATE,
        },
      })
    );
  };
  return (
    <Box p="30px" height="100vh" overflow="scroll">
      <Grid container spacing={1}>
        <Grid item xs={12} style={{ color: colors.white }}>
          <Typography variant="h5">Secteurs</Typography>
        </Grid>
        {templates.map((template, indx) => {
          return (
            <Grid item xs={6} key={indx}>
              <Card>
                <CardActionArea>
                  <CardMedia
                    draggable
                    onDragStart={() => dragHandler(indx)}
                    style={{
                      height: '142px',
                      position: 'relative',
                    }}
                  >
                    {template.zones?.map((zone, index) => (
                      <Box
                        position="absolute"
                        left={`${zone.x}%`}
                        top={`${zone.y}%`}
                        width={`${zone.width}%`}
                        height={`${zone.height}%`}
                        border={`1px solid ${colors.gridBorder}`}
                        key={index}
                      ></Box>
                    ))}
                  </CardMedia>
                </CardActionArea>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default GridsPanel;
