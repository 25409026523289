import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const competitorsPageStyle = css`
  .header {
    margin-top: 26px;
    margin-bottom: 21px;
    padding-right: 42px;
    padding-left: 42px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .content {
    background-color: ${colors.workzone};
    padding: 42px;
    .add-container {
      display: flex;
      justify-content: center;
      align-items: start;
    }
    .add-competitor {
      cursor: pointer;
      height: 357px;
      width: 381px;
      background-color: ${colors.white};
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
    }

    .iframe-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 60px;
      .title {
        margin-bottom: 10px;
        background-color: ${colors.deepBlue};
        height: 56px;
        width: 401px;
        border-radius: 10px;
        color: ${colors.white};
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 17px;
        button {
          color: ${colors.white};
        }
      }
      .iframe {
        width: 401px;
      }
    }
  }
`;
