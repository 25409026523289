import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from 'app/auth/AuthContext';

interface SplashProps {
  title?: string;
  body?: string;
  homeButton?: boolean;
  logoutButton?: boolean;
}

const Splash: FC<SplashProps> = ({
  title,
  body,
  homeButton = false,
  logoutButton = false,
}) => {
  const { t } = useTranslation();
  const classes = makeStyles((theme) => ({
    background: {
      height: '100vh',
      backgroundColor: theme.palette.primary.main,
    },
  }))();

  const logoUrl = `/${process.env.REACT_APP_ASSETS}/logo.png`;

  const { logout } = useAuth0();

  return (
    <Box
      className={classes.background}
      display="flex"
      flexDirection="column"
      textAlign="center"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        flexGrow={1}
        px={3}
      >
        <Box pb={3}>
          <img src={logoUrl} width={210} alt="BigOne Logo" />
        </Box>
        <Typography paragraph variant="h4" color="textPrimary">
          {title}
        </Typography>
        <Typography gutterBottom variant="subtitle2" color="textPrimary">
          {body}
        </Typography>
        <Box py={4} display="flex" gridGap={8}>
          {homeButton && (
            <Button variant="contained" color="secondary" size="large" href="/">
              {t('common.button.home')}
            </Button>
          )}
          {logoutButton && (
            <Button variant="contained" size="large" onClick={logout}>
              {t('common.toolbar.logout')}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Splash;
