import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import {
  Button,
  Box,
  Typography,
  Container,
  AppBar,
  makeStyles,
  Toolbar,
  FormControlLabel,
  Checkbox,
  IconButton,
} from '@material-ui/core';
import useAppContext from 'app/app-context/useAppContext';
import {
  TabPanelProductsForm,
  getDivisionLabel,
  getAssetsDivisionsForProductsSheets,
  newAsset,
  getUnifiedKeyFromPimClientConfig,
  unifiedKeyField,
  productFormValidation,
  updatedIsVisibleAsset,
  getEntityIdAndHisAncestorId,
  RegroupingSwitch,
} from 'modules/pim/baseProducts/ProductsHelper';
import { capitalizeFirstLetter } from 'app/utils/common';
import { FormTabs } from 'components/FormTabs';
import Loader from 'components/Loader/Loader/Loader';
import PimFormFieldsConfig, {
  FileStore,
} from 'modules/pim/baseProducts/PimFormFieldsConfig';
import {
  PimFormConfig,
  PimRegrouping,
} from 'modules/pim/baseProducts/PimFormConfigTypes';
import colors from 'styles/colors.module.scss';
import { useQuery, useApolloClient } from '@apollo/client';
import { GET_DIVISIONS as I_GET_DIVISIONS } from 'app/schemaInterfaces/GET_DIVISIONS';
import { GET_DIVISIONS } from 'app/graphql/queries/divisions';
import { useCurrentUser } from 'app/auth/UserContext';
import { useAssetMutate } from 'modules/dam/hooks/useAssetMutate';
import {
  GetAssetById,
  GetAssetByIdVariables,
} from 'app/schemaInterfaces/GetAssetById';
import { GET_ASSET_BY_ID } from 'app/graphql/queries/dam';
import { useProductsSheetsContext } from 'modules/pim/baseProducts/BaseProducts';
import { useTranslation } from 'react-i18next';
import UploadProgressSnackbar from 'components/UploadProgressSnackbar';
import useCurrentEntity from 'app/current-entity-context/useCurrentEntity';
import {
  GetProductById,
  GetProductByIdVariables,
} from 'app/schemaInterfaces/GetProductById';
import { GET_PRODUCT_BY_ID } from 'app/graphql/queries/products';
import RegroupingTab from '../RegroupingTab';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import _ from 'lodash';
import {
  filteredVisuals,
  firstProductVisualRegrouping,
  isFieldPimConfig,
  normalizedFields,
  subTabLabel,
} from '../regroupingHelper';
import usePermissions from 'app/auth/usePermissions';
import { PermissionKey } from 'app/schemaInterfaces/globalTypes';

interface ProductsSheetFormProps {
  onFormValidation: (values: Record<string, any>) => void;
  campaignId?: string;
  productId?: string;
  isParentProductUpdated?: boolean;
  handleChangeUpdateParentProduct?: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleClose: () => void;
  setUploadInProgress: React.Dispatch<React.SetStateAction<boolean>>;
  productData?: Record<string, any>;
  isUploadInProgress: boolean | undefined;
}

const ProductsSheetForm = ({
  onFormValidation,
  campaignId,
  productId,
  isParentProductUpdated,
  handleChangeUpdateParentProduct,
  setUploadInProgress,
  productData,
  isUploadInProgress,
}: ProductsSheetFormProps): JSX.Element => {
  const {
    client: { config: clientConfig },
  } = useAppContext();

  const { hasPermission } = usePermissions();

  const { data: product } = useQuery<GetProductById, GetProductByIdVariables>(
    GET_PRODUCT_BY_ID,
    {
      variables: {
        productId: productId as string,
      },
    }
  );
  const { data: allDivisionsValues } = useQuery<I_GET_DIVISIONS>(GET_DIVISIONS);
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const {
    formFilesManager,
    setFormFilesManager,
    regrouping,
    setRegrouping,
  } = useProductsSheetsContext();
  const {
    createAsset,
    updateAsset,
    uploadProgress,
    clearProgress,
  } = useAssetMutate({ reportProgress: true });
  const user = useCurrentUser();
  const client = useApolloClient();
  const { t } = useTranslation();
  const { currentEntity } = useCurrentEntity();
  const [fieldEntityId, setFieldEntityId] = useState<string>('');

  const classes = makeStyles({
    appBar: {
      backgroundColor: 'white',
      height: '70px',
      borderTop: `1px solid ${colors.inputLabelColor}`,
    },
    dialog: {
      '& .MuiDialog-paper': {
        backgroundColor: colors.documentsHeaderBackground,
        height: 'inherit',
      },
    },
    toolbar: {
      justifyContent: 'flex-end',
    },

    button: {
      '& .MuiIconButton-root': {
        padding: '0',
      },
    },
  })();

  const handleChangeTabIndex = (
    event: React.ChangeEvent<{}>,
    tabIndex: number
  ) => {
    setSelectedTabIndex(tabIndex);
  };

  const pimFormConfig: PimFormConfig[] | undefined =
    clientConfig?.pim.formConfig;

  const pimFormFields = clientConfig?.pim.fields;

  let divisionsLabels: string[] = [];

  if (allDivisionsValues)
    divisionsLabels = getDivisionLabel(allDivisionsValues.getDivisions);

  let initialValues: Record<string, any> = {};
  pimFormFields?.forEach((item) => {
    initialValues[item.key as string] = '';
    if (productData) {
      Object.entries(productData).forEach(([fieldName, fieldValue]) => {
        if (fieldName) {
          initialValues[fieldName] = fieldValue;
        }
        return initialValues;
      });
    }
  });

  useEffect(() => {
    if (productData) {
      const productRegrouping =
        product?.getProduct.regrouping.map((regrouping) => ({
          details: regrouping.details,
          isMain: regrouping.isMain,
          productId: regrouping.productId,
          isVisible: regrouping.isVisible,
          assetId: regrouping.assetId,
          createdDate: regrouping.createdDate ?? new Date(),
          userId: regrouping.userId ?? user.id,
        })) ?? [];

      setRegrouping([...productRegrouping]);

      const regroupingVisual = firstProductVisualRegrouping?.(
        productData.filesToSend,
        productId
      );

      if (productData.filesToSend) {
        const allVisuals = [...productData.filesToSend, regroupingVisual];

        if (regroupingVisual) {
          setFormFilesManager(
            _.uniqBy(allVisuals, (visual) => visual?.fieldName)
          );
        } else {
          setFormFilesManager([...productData.filesToSend]);
        }
      }
    }
  }, [
    productData,
    setFormFilesManager,
    setRegrouping,
    product?.getProduct.regrouping,
    productId,
    user.id,
  ]);

  const handleAssetCreate = async (values: Record<string, any>) => {
    let filesToStoreCopy: FileStore[] = [...formFilesManager];
    let assetsDivisions: Record<string, any> = [];
    if (!!formFilesManager.length) {
      getAssetsDivisionsForProductsSheets(
        values,
        assetsDivisions,
        divisionsLabels
      );
      const assetsPromises = formFilesManager.map(async (formFile, index) => {
        if (!formFile.assetId && formFile.fieldFile) {
          return createAsset(
            newAsset(
              formFile,
              assetsDivisions,
              campaignId,
              isParentProductUpdated as boolean
            ),
            formFile.fieldFile
          ).then((asset) => {
            filesToStoreCopy[index].assetId = asset.id as string;
          });
        }
        if (isParentProductUpdated && formFile.assetId) {
          const asset = await client.query<GetAssetById, GetAssetByIdVariables>(
            {
              query: GET_ASSET_BY_ID,
              variables: {
                id: formFile.assetId,
              },
            }
          );
          if (!asset.data.getAssetById.isVisible) {
            updateAsset(
              asset.data.getAssetById.id,
              updatedIsVisibleAsset(asset, campaignId, isParentProductUpdated)
            );
          }
        }
      });

      setUploadInProgress(true);
      await Promise.all(assetsPromises);
      filesToStoreCopy.forEach((object) => {
        if (object.base64File) {
          delete object['base64File'];
        }
      });
      const newValues = { ...values, filesToSend: filesToStoreCopy };
      onFormValidation(newValues);
    } else {
      onFormValidation(values);
    }
  };

  const regroupingFields = clientConfig?.pim?.requiredFields.filter(
    (field) => field.isRegrouping
  );

  const formTabs = clientConfig?.pim.formConfig;

  const regRoupingButtonPosition = regrouping.length > 1 ? 'start' : 'center';

  const handleRegroupingDelete = (deletedProtuct: PimRegrouping) => {
    const newRegrouping = regrouping.filter(
      (regroupingProduct) =>
        regroupingProduct.productId !== deletedProtuct.productId
    );
    setRegrouping(newRegrouping);
    setFormFilesManager(
      filteredVisuals(formFilesManager, deletedProtuct.productId)
    );
  };

  const isVisibleIconDisplay = (index: number, productItem: PimRegrouping) =>
    index > 0 && (
      <>
        <RegroupingSwitch
          checked={productItem.isVisible}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const newRegroupings = regrouping.slice();
            newRegroupings[index].isVisible = event.target.checked;
            setRegrouping(newRegroupings);
          }}
          color="primary"
        />
        <Typography>{t('pim.regrouping.switch_label')}</Typography>
      </>
    );

  const deleteIconDisplay = (index: number, deletedProtuct: PimRegrouping) =>
    index > 0 && (
      <IconButton
        className={classes.button}
        onClick={() => handleRegroupingDelete(deletedProtuct)}
      >
        <DeleteForeverOutlinedIcon />
      </IconButton>
    );

  if (isUploadInProgress)
    return (
      <>
        <Loader />
        <UploadProgressSnackbar
          open={uploadProgress.length > 0}
          defaultExpanded={true}
          uploads={uploadProgress}
          onClose={() => clearProgress()}
        />
      </>
    );

  return (
    <Formik<Record<string, any>>
      initialValues={initialValues}
      validationSchema={productFormValidation(t, clientConfig)}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={async (values) => {
        handleAssetCreate(values);
      }}
    >
      {({ values }) => {
        const disableSubmitButton =
          !values[
            getUnifiedKeyFromPimClientConfig(
              unifiedKeyField.ProductCode,
              clientConfig
            )
          ] &&
          !values[
            getUnifiedKeyFromPimClientConfig(
              unifiedKeyField.InternalCode,
              clientConfig
            )
          ];
        currentEntity &&
          getEntityIdAndHisAncestorId(user, currentEntity, setFieldEntityId);
        return clientConfig ? (
          <Form>
            <FormTabs
              selectedTabIndex={selectedTabIndex}
              handleChangeTabIndex={handleChangeTabIndex}
              items={formTabs}
            />
            {pimFormConfig?.map((pimConfig, index) =>
              pimConfig.tabName !== t('pim.field_name.regrouping') ? (
                <Box key={index}>
                  <TabPanelProductsForm value={selectedTabIndex} index={index}>
                    {pimConfig.subTabs.map(
                      (singleTab, index) =>
                        // quick fix to hide some fields in the pim according to the logged user entity
                        // waiting for a better conception from the backend client-config
                        singleTab.fields
                          .flatMap((field) => field.entityIds)
                          .includes(fieldEntityId as string) && (
                          <Box key={index} pt={3}>
                            <Box
                              bgcolor={colors.documentsHeaderBackground}
                              height="55px"
                              width="100%"
                            >
                              <Box py={2} px={2}>
                                <Typography variant="h4">
                                  {capitalizeFirstLetter(singleTab.subTabName)}
                                </Typography>
                              </Box>
                            </Box>
                            <Container>
                              {singleTab.fields.map(
                                (field, index) =>
                                  field.entityIds.includes(
                                    fieldEntityId as string
                                  ) && (
                                    <PimFormFieldsConfig
                                      key={index}
                                      field={field}
                                      values={values}
                                      fieldIndex={index}
                                    />
                                  )
                              )}
                            </Container>
                          </Box>
                        )
                    )}
                  </TabPanelProductsForm>
                </Box>
              ) : (
                <TabPanelProductsForm value={selectedTabIndex} index={index}>
                  {regrouping.length > 1 &&
                    regrouping.map((productItem, regroupingIndex) => {
                      return (
                        <Box key={regroupingIndex} pt={3}>
                          <Box
                            bgcolor={colors.documentsHeaderBackground}
                            height="55px"
                            width="100%"
                          >
                            <Box
                              py={2}
                              px={2}
                              display="flex"
                              justifyContent="space-between"
                              className={classes.button}
                            >
                              <Typography variant="h4">
                                {subTabLabel(regroupingIndex, t)}
                              </Typography>
                              <Box
                                display="flex"
                                width="25%"
                                alignItems="center"
                                justifyContent="space-around"
                              >
                                {isVisibleIconDisplay(
                                  regroupingIndex,
                                  productItem
                                )}
                                {deleteIconDisplay(
                                  regroupingIndex,
                                  productItem
                                )}
                              </Box>
                            </Box>
                          </Box>
                          <Container>
                            {normalizedFields(
                              productItem.productId,
                              regroupingFields
                            )?.map((field, fieldIndex) => {
                              if (field.key && !initialValues[field.key]) {
                                const reg = new RegExp(
                                  `-${productItem.productId}`,
                                  'g'
                                );
                                initialValues[field.key] =
                                  productItem.details[
                                    field.key.replace(reg, '')
                                  ];
                              }
                              return (
                                isFieldPimConfig(field) && (
                                  <PimFormFieldsConfig
                                    key={fieldIndex}
                                    field={field}
                                    values={{ ...initialValues, ...values }}
                                    fieldIndex={fieldIndex}
                                    isDisabled={
                                      field.unifiedKey ===
                                      unifiedKeyField.ProductCode
                                    }
                                    productId={productId}
                                  />
                                )
                              );
                            })}{' '}
                          </Container>
                        </Box>
                      );
                    })}
                  <Container>
                    <Box
                      display="flex"
                      justifyContent={regRoupingButtonPosition}
                      py={4}
                    >
                      <Field
                        name={t('pim.field_name.regrouping')}
                        label={t('pim.regrouping.add_regrouping')}
                        component={RegroupingTab}
                        fieldName={t('pim.field_name.regrouping')}
                      />
                    </Box>
                  </Container>
                </TabPanelProductsForm>
              )
            )}
            <AppBar
              position="sticky"
              color="primary"
              classes={{ root: classes.appBar }}
            >
              <Toolbar classes={{ root: classes.toolbar }}>
                <Box display="flex" justifyContent="flex-end" pt={1.5}>
                  <Box pr={2}>
                    {campaignId &&
                      hasPermission(PermissionKey.UpdateGlobalProduct) && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isParentProductUpdated}
                              onChange={handleChangeUpdateParentProduct}
                              color="primary"
                            />
                          }
                          label={
                            <Box color="black">
                              <Typography>
                                {t('pim.form.update_base_products')}
                              </Typography>
                            </Box>
                          }
                        />
                      )}
                  </Box>
                  {hasPermission(PermissionKey.UpdateGlobalProduct) ||
                  (product?.getProduct.campaignId &&
                    hasPermission(PermissionKey.UpdateCampaignProduct)) ||
                  (product?.getProduct.documentId &&
                    hasPermission(PermissionKey.UpdateLocalProduct)) ? (
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      disabled={disableSubmitButton}
                    >
                      {t('pim.form.submit_form')}
                    </Button>
                  ) : null}
                </Box>
              </Toolbar>
            </AppBar>
          </Form>
        ) : (
          <Loader />
        );
      }}
    </Formik>
  );
};

export default ProductsSheetForm;
