import React, { FC } from 'react';
import {
  dividerStyle,
  workflowHeadStyle,
} from './FollowOrderWorkflowBox.style';
import {
  arrayAt,
  getConditioningOperatorName,
  getDocumentOptionName,
  getDocumentOrderTypeName,
  joinListInSentence,
} from './FollowOrderWorkflowBox.utils';
import { Divider } from '@material-ui/core';
import { GetOrderById_getOrderById_activeOrderWorkflows } from 'app/schemaInterfaces/GetOrderById';
import AlertBox from '../../../../components/AlertBox/AlertBox';
import { getSupportCategory } from 'modules/pro/documents/form/model/mappers';
import { useTranslation } from 'react-i18next';

export interface FollowOrderWorkflowBoxProps {
  workflows: GetOrderById_getOrderById_activeOrderWorkflows[];
}

const FollowOrderWorkflowBox: FC<FollowOrderWorkflowBoxProps> = ({
  workflows,
}) => {
  const { t } = useTranslation();

  return (
    <AlertBox
      variant="warning"
      header={
        <div css={workflowHeadStyle}>
          {t('follow_orders.workflow_box.title')}
        </div>
      }
    >
      {workflows.map((workflow, index) => {
        const condition = arrayAt(workflow.config.priceConditioning, 0);

        return (
          <React.Fragment key={workflow.config.id}>
            {index > 0 && <Divider css={dividerStyle} />}
            <div>
              <strong>{t('follow_orders.workflow_box.category')}</strong>{' '}
              {getSupportCategory(workflow.config.supportCategory, t)}
            </div>
            <div>
              <strong>{t('follow_orders.workflow_box.option')}</strong>{' '}
              {joinListInSentence(
                workflow.config.documentOptions.map((v) =>
                  getDocumentOptionName(v, t)
                ),
                'or',
                t
              )}
            </div>
            <div>
              <strong>{t('follow_orders.workflow_box.type')}</strong>{' '}
              {joinListInSentence(
                workflow.config.documentOrderTypes.map((v) =>
                  getDocumentOrderTypeName(v, t)
                ),
                'or',
                t
              )}
            </div>
            {condition && (
              <div>
                <strong>{t('follow_orders.workflow_box.criterion')}</strong>{' '}
                {t('follow_orders.workflow_box.criterion_pricing', {
                  operator: getConditioningOperatorName(condition.operator, t),
                  value: condition.value,
                })}
              </div>
            )}
          </React.Fragment>
        );
      })}
    </AlertBox>
  );
};

export default FollowOrderWorkflowBox;
