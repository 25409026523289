import { Grid } from '@material-ui/core';
import { TRANSLATION_ROOT } from 'modules/oneManager/storeHelper';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeliveryAddressForm from './DeliveryAddressForm';
import useDeliveryAddressesQuery from 'queries/useDeliveryAddressesQuery';
import useCurrentEntity from 'app/current-entity-context/useCurrentEntity';
import DeliveryAddressRow from './DeliveryAddressRow';
import { css } from '@emotion/react';

const DeliveryAddressContainer = (): JSX.Element => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const style = css`
    padding: 16px 0px;
    margin-left: 50px;

    .titles {
      display: flex;
      padding: 24px 12px;
      h3 {
        width: 50%;
      }
    }
  `;
  const handleClose = () => setIsOpen(false);

  const { currentEntity } = useCurrentEntity();

  const { data } = useDeliveryAddressesQuery(currentEntity?.id);
  const deliveryAdresses = data?.entityDeliveryAdresses;
  return (
    <div css={style}>
      <Grid container justify="flex-end">
        <Grid item>
          {/* temporary removed */}
          {/* <Button
            startIcon={<AddCircleIcon />}
            variant="contained"
            color="primary"
            onClick={() => handleOpen()}
          >
            {t(`${TRANSLATION_ROOT}.store_page.add_address`)}
          </Button> */}
        </Grid>
        <DeliveryAddressForm isOpen={isOpen} handleClose={handleClose} />
        <Grid container>
          <Grid item>{/* MAP HERE */}</Grid>
          <Grid item xs={6}>
            <div className="titles">
              <h1>{t(`${TRANSLATION_ROOT}.tabs.general_information`)}</h1>
            </div>
            {deliveryAdresses?.map((deliveryAddress, index) => (
              <DeliveryAddressRow
                key={index}
                deliveryAddress={deliveryAddress}
              />
            ))}
          </Grid>
        </Grid>
      </Grid>

      <DeliveryAddressForm isOpen={isOpen} handleClose={handleClose} />
    </div>
  );
};

export default DeliveryAddressContainer;
