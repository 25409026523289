import React, { FC } from 'react';
import * as icons from '@material-ui/icons';

export interface IconProps {
  className: string;
  name?: string | null;
}

const Icon: FC<IconProps> = ({ name, className }) => {
  if (!name) {
    return null;
  }

  const Icon = (icons as Record<string, FC<any>>)[name];
  if (!Icon) {
    return <span data-testid="icon-content">...</span>;
  }

  return <Icon className={className} data-testid="icon-content" />;
};

export default Icon;
