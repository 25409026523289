import React from 'react';
import Box from '@material-ui/core/Box';
import { Typography, IconButton } from '@material-ui/core';
import CommentsPanel from 'modules/workflow/containers/CatalogPreview/CommentsPanel';
import colors from 'styles/colors.module.scss';
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import {
  useStudioDispatchContext,
  useStudioStateContext,
} from 'modules/workflow/oneStudioContext';
import { studioAppActions } from 'modules/workflow/reducers/actionsInterfaces';
import { css } from '@emotion/react';

const style = (commentMode: boolean) => css`
  background-color: ${colors.white};
  border-left: 0.5px solid ${colors.lightGrey};
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  width: ${commentMode ? '312px' : '0'};
  transition: width 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  .header {
    display: flex;
    width: 100%;
    height: 60px;
    background-color: ${colors.workzone};
    justify-content: space-between;
    align-items: center;
    padding: 22px 10px 22px 22px;
    .title {
      display: flex;
      align-items: center;
      h4 {
        margin: 12px;
      }
    }
  }
  .content {
    overflow: hidden auto;
    width: 312px;
    padding: 24px;
    height: 86%;
  }
`;

const CommentBar = (): JSX.Element => {
  const globalState = useStudioStateContext();

  const dispatch = useStudioDispatchContext();

  const { t } = useTranslation();

  const onClose = () => {
    dispatch(
      studioAppActions.setCommentMode({
        commentMode: false,
      })
    );
  };
  return (
    <div css={style(globalState.commentMode)}>
      <div className="header">
        <div className="title">
          <CommentOutlinedIcon />
          <Typography variant="h4">{t(`workflow.comment`)}</Typography>
        </div>
        <IconButton>
          <CloseIcon onClick={onClose} />
        </IconButton>
      </div>
      <Box className="content">
        <CommentsPanel />
      </Box>
    </div>
  );
};

export default CommentBar;
