import { Box, FormLabel, TextField } from '@material-ui/core';
import {
  OptionV3,
  UncontrolledMultipleValueSelectV3,
} from 'components/UncontrolledMultipleArrayValueSelect';
import { useAddressBlockMutate } from './useAddressBlockMutate';
import { useTranslation } from 'react-i18next';
import UncontrolledSelect from 'components/UncontrolledSelect';
import UploadAddressBlock from './UploadAddressBlock';
import {
  ACCEPTED_IMAGE_TYPES,
  ACCEPTED_PDF_TYPES,
} from 'modules/dam/form/helpers';
import { AddressBlockFormValues } from './AddressBlockContainer/AddressBlockContainer';
import { Option } from 'components/UncontrolledMultipleValueSelectV2';
import FormWrapper from 'components/FormWrapper/FormWrapper';
import { FC } from 'react';
import { addressBlockDialogSectionStyle } from './AddressBlockDialog.style';

export interface AddressBlockDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  formValues: AddressBlockFormValues;
  setFormValues: React.Dispatch<React.SetStateAction<AddressBlockFormValues>>;
  supplyProductIds: Option[];
  formats: OptionV3[];
}

const AddressBlockDialog: FC<AddressBlockDialogProps> = ({
  open,
  setOpen,
  formValues,
  setFormValues,
  supplyProductIds,
  formats,
}) => {
  const { t } = useTranslation();
  const { createAddressBlock } = useAddressBlockMutate({
    reportProgress: true,
  });

  function handleClose() {
    // Reset form values on close
    setFormValues({
      thumbnailFile: undefined,
      hdPdf: undefined,
      name: '',
      supplyProductIds: [],
      supportId: '',
    });
    setOpen(false);
  }

  function saveAddressBlock() {
    createAddressBlock(formValues);
    handleClose();
  }

  return (
    <FormWrapper
      open={open}
      handleClose={handleClose}
      title={t(
        'one_manager.store_information.address_block_page.add_address_block'
      )}
      maxWidth="sm"
      actionButtons={[
        {
          name: t('one_manager.store_information.address_block_page.save'),
          handleClick: saveAddressBlock,
        },
      ]}
    >
      <Box pt={3}>
        <FormLabel css={addressBlockDialogSectionStyle}>
          {t('one_manager.store_information.address_block_page.files_download')}
        </FormLabel>
      </Box>

      <Box pt={1}>
        <FormLabel>
          {t(
            'one_manager.store_information.address_block_page.addressblock_pdf_file'
          )}{' '}
          *
        </FormLabel>
      </Box>
      <UploadAddressBlock
        buttonLabel={t(
          'one_manager.store_information.address_block_page.upload_pdf'
        )}
        onFileChange={(files) => {
          let name = formValues.name;
          if (!name || name.trim().length === 0) {
            // Set document name to filename without extension
            const nameTokens = files[0].name.split('.');
            name = nameTokens.slice(0, nameTokens.length - 1).join('.');
          }

          setFormValues({
            ...formValues,
            hdPdf: files[0],
            name: name,
          });
        }}
        acceptedFiles={[...ACCEPTED_IMAGE_TYPES, ACCEPTED_PDF_TYPES]}
        data-testid="addressblock_pdf"
      />

      <Box pt={3}>
        <FormLabel>
          {t('one_manager.store_information.address_block_page.thumbnail')} *
        </FormLabel>
      </Box>
      <UploadAddressBlock
        buttonLabel={t(
          'one_manager.store_information.address_block_page.upload_thumbnail'
        )}
        onFileChange={(files) =>
          setFormValues({ ...formValues, thumbnailFile: files[0] })
        }
        acceptedFiles={[...ACCEPTED_IMAGE_TYPES, ACCEPTED_PDF_TYPES]}
        data-testid="addressblock_thumbnail"
      />

      <Box pt={3}>
        <FormLabel>
          {t(
            'one_manager.store_information.address_block_page.addressblock_name'
          )}{' '}
          *
        </FormLabel>
      </Box>
      <TextField
        placeholder={t(
          'one_manager.store_information.address_block_page.addressblock_name_placeholder'
        )}
        data-testid="addressblock_name"
        variant="outlined"
        value={formValues.name}
        onChange={(e) => setFormValues({ ...formValues, name: e.target.value })}
        autoFocus
        fullWidth
        margin="dense"
      />
      <Box display="flex" pt={3} pb={3}>
        <Box flex="1" pr={1}>
          <Box>
            <FormLabel>
              {t(
                'one_manager.store_information.address_block_page.medium_type'
              )}{' '}
              *
            </FormLabel>
          </Box>
          <UncontrolledSelect
            value={formValues.supportId}
            options={supplyProductIds}
            onChange={({ target: { value } }) =>
              setFormValues({
                ...formValues,
                supportId: value,
                supplyProductIds: [],
              })
            }
            fullWidth
            margin="dense"
            variant="outlined"
            labelText={t('common.actions.select')}
            data-testid="addressblock_medium"
          />
        </Box>
        <Box flex="1" pl={1}>
          <Box>
            <FormLabel>
              {t('one_manager.store_information.address_block_page.format')} *
            </FormLabel>
          </Box>
          <UncontrolledMultipleValueSelectV3
            options={formats}
            values={formValues.supplyProductIds}
            handleChange={(supplyProductIds) => {
              setFormValues({
                ...formValues,
                supplyProductIds: supplyProductIds,
              });
            }}
            placeholder={t('common.actions.select')}
            isFullWidth={true}
            variant="outlined"
            margin="dense"
            data-testid="addressblock_format"
          />
        </Box>
      </Box>
    </FormWrapper>
  );
};

export default AddressBlockDialog;
