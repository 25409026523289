import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const basketDocumentRowStyle = css`
  &.document-row {
    .MuiGrid-container {
      justify-content: space-between;
    }
    .media-documentInfo {
      display: flex;
    }
    .media-wrapper {
      background: ${colors.documentsHeaderBackground};
      display: flex;
      justify-content: center;
      height: 53px;
      width: 53px;
      align-items: center;
      align-self: center;
      position: relative;

      img {
        border-radius: 0;
        object-fit: contain;
        width: 32.56px;
        height: 45.81px;
      }

      .MuiIconButton-root {
        color: ${colors.primary};
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
    .MuiOutlinedInput-root {
      width: 122px;
    }
    .quantity-input {
      display: flex;
      align-items: center;
    }
    .documentInfo {
      display: flex;
      align-items: center;
    }
    .content {
      padding-left: 16px;
      padding-right: 5px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .divider {
      color: ${colors.grey};
      margin: 0 8px;
    }

    .edit-button {
      margin-top: 10px;
    }
  }
`;
export const basketRowStyle = css`
  .icon {
    font-size: 12px;
    margin-bottom: -2px;
  }
  .tag-wrapper {
    display: flex;
    padding-top: 8px;
    .icon {
      font-size: 18px;
      align-self: center;
      margin-left: 8px;
    }
  }
  .checkbox {
    display: flex;
    justify-content: center;
  }

  .MuiGrid-grid-xs-1 {
    flex-basis: 0;
  }
  .MuiCheckbox-root {
    height: 45px;
    align-self: center;
  }
  .actions {
    display: flex;
    justify-content: center;
  }
  .buttons {
    display: flex;
    justify-content: flex-end;
  }
`;
