import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { StatusSwitch } from 'components/Switch/StatusSwitch';
import { UncontrolledDateTimePicker } from 'components/UncontrolledDateTimePicker';

export interface PublicationPeriodProps {
  handleChangeDeliveryDate: (date: MaterialUiPickersDate) => void;
  deliveryDate: Moment | null;
}

const PublicationPeriod = ({
  handleChangeDeliveryDate,
  deliveryDate,
}: PublicationPeriodProps) => {
  const { t } = useTranslation();
  const [isDelayed, setIsDelayed] = useState<boolean>(true);

  const nowPlusDelay = moment().add(20, 'minutes');

  const handleSwitch = () => {
    if (!isDelayed) {
      handleChangeDeliveryDate(nowPlusDelay);
    } else {
      handleChangeDeliveryDate(moment());
    }
    setIsDelayed(!isDelayed);
  };

  return (
    <>
      <Grid item xs={3} className="publication-box">
        <span className="publication-title">
          {t('sales.basket_page.delivery_page.publication')}
        </span>
        <StatusSwitch
          status={isDelayed}
          onStatusChange={handleSwitch}
          enabledLabel={t('sales.basket_page.delivery_page.delayed')}
          disabledLabel={t('sales.basket_page.delivery_page.now')}
          isAlwaysActive={true}
        />
      </Grid>
      <Grid item xs={3} className="date-box">
        <span className="date-title">
          {t('sales.basket_page.delivery_page.wanted_publication_date')}
        </span>
        <UncontrolledDateTimePicker
          placeholder={t(
            'sales.basket_page.delivery_page.wanted_publication_date'
          )}
          disabled={!isDelayed}
          value={deliveryDate}
          minDate={nowPlusDelay}
          size="small"
          fullWidth
          onChange={(date) => {
            if (date && date < nowPlusDelay) {
              handleChangeDeliveryDate(nowPlusDelay);
            } else {
              handleChangeDeliveryDate(date);
            }
          }}
          initialFocusedDate={nowPlusDelay}
          color="secondary"
        />
      </Grid>
    </>
  );
};

export default PublicationPeriod;
