import { Button, Divider } from '@material-ui/core';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import { useCurrentUser } from 'app/auth/UserContext';
import { GetOrderById_getOrderById } from 'app/schemaInterfaces/GetOrderById';
import { FollowOrderStatus } from 'app/schemaInterfaces/globalTypes';
import { truncateString } from 'app/utils/common';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import OrderFollowStatusTag from '../components/OrderFollowStatusTag/OrderFollowStatusTag';
import { actionsStyle } from './OrderDetailsHeader.style';
import { useDownloadFileErrorWorkflow } from 'app/utils/customHooks/useDownloadFileErrorWorkflow';
import FollowOrderValidationDialog from '../components/FollowOrderValidationDialog/FollowOrderValidationDialog';
import { useOrderValidationWorkflow } from '../customHooks/useOrderValidationWorkflow';
import FollowOrderValidationButton from '../components/FollowOrderValidationButton/FollowOrderValidationButton';
import { isUserValidatorForOrder } from '../utils/validation';

export interface OrderDetailsHeaderProps {
  orderData: GetOrderById_getOrderById;
}

const OrderDetailsHeader: FC<OrderDetailsHeaderProps> = ({
  orderData: order,
}) => {
  const { t } = useTranslation();

  const user = useCurrentUser();
  const isValidator = isUserValidatorForOrder(order, user);

  const downloadFile = useDownloadFileErrorWorkflow({
    useS3CorsWorkaround: true,
  });

  const invoices =
    order.childrenOrder?.flatMap((order) => order.invoices ?? []) ?? [];

  async function downloadInvoice() {
    if (invoices.length > 0) {
      const { url, originalInvoiceName, id } = invoices[0];
      if (url) {
        await downloadFile({
          url,
          filename: originalInvoiceName ?? id,
          mimetype: 'application/pdf',
        });
      }
    }
  }

  const validation = useOrderValidationWorkflow();

  function acceptOrder() {
    validation.startAcceptation(order.id);
  }

  function declineOrder() {
    validation.startRefusal(order.id);
  }

  return (
    <div className="order-header">
      <div className="header-container">
        <div className="spaced">
          <div>
            <div className="order-info">
              <LocalOfferOutlinedIcon
                color="primary"
                style={{ marginRight: '10px' }}
              />
              <h3 className="operation-name">
                {truncateString(order.fileReference?.operationName ?? '', 30)}
              </h3>
            </div>
          </div>
          <Divider orientation="vertical" />
          <div>
            <span className="client-name">
              {truncateString(order.clientName ?? '', 30)}
            </span>
          </div>
          <Divider orientation="vertical" />
          <div>
            <span className="order-code">{order.id}</span>
          </div>
          <Divider orientation="vertical" />
          <div>
            <OrderFollowStatusTag
              orderStatus={order.followStatus}
              documentsCategories={order.orderItem?.map(
                (item) => item.document.category
              )}
            />
          </div>
          <Divider orientation="vertical" />
          <div>
            <span>{`${t('follow_orders.delivery_point_count')} : ${
              order.childrenOrder?.length
            }`}</span>
          </div>
        </div>
        <div css={actionsStyle}>
          {isValidator &&
            order.followStatus === FollowOrderStatus.WaitingValidation && (
              <>
                <FollowOrderValidationButton
                  type="accept"
                  onClick={acceptOrder}
                >
                  {t('follow_orders.accept_order')}
                </FollowOrderValidationButton>
                <FollowOrderValidationButton
                  type="decline"
                  onClick={declineOrder}
                >
                  {t('follow_orders.decline_order')}
                </FollowOrderValidationButton>
              </>
            )}
          {invoices.length > 0 && (
            <Button
              color="primary"
              variant="outlined"
              onClick={downloadInvoice}
            >
              {t('follow_orders.download_invoice')}
            </Button>
          )}
        </div>
      </div>
      <FollowOrderValidationDialog validation={validation} />
    </div>
  );
};

export default OrderDetailsHeader;
