import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  Divider,
  FormControlLabel,
  FormLabel,
  Grid,
  Typography,
} from '@material-ui/core';
import { AssetType } from 'app/schemaInterfaces/globalTypes';
import { Field, Form, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GetAssetById_getAssetById as Asset } from 'app/schemaInterfaces/GetAssetById';
import colors from 'styles/colors.module.scss';
import EntitiesField from 'modules/oneManager/entities/form/EntitiesField';
import AssetFileUploadForm from 'modules/dam/form/AssetFileUploadForm';
import { SingleAssetFormValues } from 'modules/dam/form/model/definitions';
import { AssetStatusSwitchField } from 'modules/dam/form/fields/AssetStatusSwitchField';
import GeneralInformationSubForm from 'modules/dam/form/fields/GeneralInformationSubForm';
import VisualAssetSubForm from 'modules/dam/form/VisualAssetSubForm';
import { css } from '@emotion/react';

const style = css`
  .grid {
    height: 69vh;
    overflow: auto;
  }

  .actions {
    justify-content: space-between;
    margin-top: 8px;
    border-top: 1px solid ${colors.lightGrey};
  }
`;

interface AssetFormProps {
  asset?: Asset;
  isUpdateInDam: boolean;
}

const AssetForm = ({ asset, isUpdateInDam }: AssetFormProps): JSX.Element => {
  const {
    values: { asset: assetInput, file },
    setFieldValue,
  } = useFormikContext<SingleAssetFormValues>();
  const { t } = useTranslation();
  const [reportError, setReportError] = useState<string | null>(null);

  //TODO: Use a controlled checkbox field that hooks up to the formik state rather than declaring another state
  const [isVisibleInDam, setIsVisibleInDam] = useState<boolean>(
    asset?.isVisible ?? true
  );
  const handleChangeVisibleInDam = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsVisibleInDam(event.target.checked);
    setFieldValue('asset.isVisible', event.target.checked);
  };

  return (
    <div css={style}>
      <Divider />
      <Form>
        <Grid container justify="center" spacing={2} className="grid">
          <Grid item xs={5}>
            <AssetFileUploadForm
              asset={assetInput}
              assetImage={{
                url: (asset?.thumbnailUrl || asset?.url) ?? undefined,
                extension: asset?.metadata.fileExtension,
              }}
              reportError={reportError}
              setReportError={setReportError}
            />
          </Grid>
          <Grid item xs={7}>
            <Box py={2}>
              <Box p={2} mt={1} bgcolor={colors.documentsHeaderBackground}>
                {t('dam.asset_form.general_info')}
              </Box>
              <Box px={2}>
                <Box pt={2}>
                  <FormLabel>{t('dam.asset_form.name')}</FormLabel>
                </Box>
                <Field
                  component={TextField}
                  name="asset.name"
                  placeholder={t('dam.asset_form.name')}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />

                <GeneralInformationSubForm />

                {(file || asset) && (
                  <>
                    <Box pt={2}>
                      <FormLabel>{t('dam.asset_form.credit')}</FormLabel>
                    </Box>
                    <Field
                      component={TextField}
                      name="asset.metadata.credit"
                      disabled={
                        file
                          ? assetInput.metadata.fileHasCredit
                          : asset?.metadata.fileHasCredit
                      }
                      placeholder={t('dam.asset_form.credit')}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </>
                )}
                <EntitiesField name="asset.entities" />
              </Box>
              {assetInput.assetType === AssetType.Visual && (
                <VisualAssetSubForm />
              )}
            </Box>
          </Grid>
        </Grid>

        <DialogActions className="actions">
          <AssetStatusSwitchField
            name="asset.status"
            isEditable={
              !assetInput.permanent &&
              !(assetInput.validTo && assetInput.validFrom)
            }
          />
          <Box display="flex" justifyContent="flex-end">
            {!isUpdateInDam && (
              <FormControlLabel
                control={
                  <Checkbox
                    name="asset.isVisible"
                    checked={isVisibleInDam}
                    onChange={handleChangeVisibleInDam}
                    color="primary"
                  />
                }
                label={
                  <Box color="black">
                    <Typography>{t('dam.update_in_dam')}</Typography>
                  </Box>
                }
              />
            )}
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={Boolean(reportError)}
            >
              {asset ? t('dam.update_media') : t('dam.add_media')}
            </Button>
          </Box>
        </DialogActions>
      </Form>
    </div>
  );
};

export default AssetForm;
