import { Box, Typography } from '@material-ui/core';
import React, { PropsWithChildren, FC } from 'react';
import { headerListRootStyle } from './HeaderList.style';

export interface HeaderListProps {
  backgroundImageFileName?: string;
  title?: string;
  bgcolor?: string;
  height?: number;
}

const HeaderList: FC<PropsWithChildren<HeaderListProps>> = ({
  title,
  backgroundImageFileName,
  children,
  bgcolor = 'inherit',
  height = 180,
}) => (
  <Box
    height={height}
    css={headerListRootStyle(backgroundImageFileName)}
    bgcolor={bgcolor}
    display="flex"
    alignItems="center"
  >
    {title ? (
      <Box color="white" pl={7}>
        <Typography variant="h1">{title}</Typography>
      </Box>
    ) : (
      children
    )}
  </Box>
);

export default HeaderList;
