import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const summaryAddressesRowStyle = css`
  width: 100%;
  justify-content: space-between;
  .MuiGrid-container {
    align-items: center;
  }
  .content {
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .document-title {
      display: flex;
      h5 {
        font-weight: 500;
      }
      .divider {
        margin: 0 8px;
        border-left: 1px solid ${colors.buttonGroupOutlined};
      }
      p {
        padding-top: 4px;
        font-size: 12px;
      }
    }
    .tag-wrapper {
      display: flex;
    }
  }

  .media-wrapper {
    border-radius: 4px;
    max-width: 140px;
    background: ${colors.documentsHeaderBackground};
    display: flex;
    justify-content: center;
    height: 140px;
    width: 140px;
    align-items: center;
    align-self: center;
    position: relative;

    img {
      height: 98%;
      width: 98%;
      object-fit: contain;
      align-self: center;
    }
  }
  .details-wrapper {
    display: flex;
    color: ${colors.grey};
    hr {
      height: 16px;
      margin-left: 8px;
      margin-right: 8px;
    }
  }
  .summary-price {
    text-align: end;
  }
`;
