import colors from 'styles/colors.module.scss';
import { css } from '@emotion/react';

export const campaignDetailHeaderStyle = css`
  background: ${colors.documentsHeaderBackground};
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-between;
  a {
    text-decoration: none;
    color: ${colors.body};
  }

  .toast {
    width: fit-content;
    height: fit-content;
    min-width: 468px;

    border-left: 5px solid ${colors.selectedCheckboxBorderColor};
    background-color: white;
    padding: 12px;
    margin-right: 5%;
    .info {
      display: flex;
      justify-content: center;
      .icon {
        color: ${colors.selectedCheckboxBorderColor};
        align-self: center;
      }
      p {
        font-family: ScandiaWebMedium;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        padding-left: 8px;
        letter-spacing: -0.03em;
        color: ${colors.grey};
      }
    }
    .description {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 24px;
      display: flex;
      align-items: center;
      letter-spacing: -0.03em;
      color: ${colors.grey};
      justify-content: center;
    }
  }
`;
