import { Button, Tooltip } from '@material-ui/core';

import { AddButtonProps } from '../shopHelpers';
import CheckIcon from '@material-ui/icons/Check';
import React from 'react';
import colors from 'styles/colors.module.scss';
import { css } from '@emotion/react/macro';

const style = (hasPrice: boolean | undefined) => css`
  padding: 0px 8px 8px 8px;
  .button {
    box-sizing: border-box;
    border-radius: 200px;
    height: 30px;
    display: flex;
    justify-content: center;
    border-color: ${colors.primary};
    background-color: ${colors.white};
    color: ${colors.primary};
    opacity: 1;
  }
  p {
    font-family: ScandiaWebMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    width: 100%;
    justify-content: ${hasPrice ? 'center' : 'left'};
  }
  .MuiButton-outlinedPrimary:disabled {
    border-color: ${colors.primary};
    background-color: ${colors.primary};
    color: ${colors.white};
    opacity: 0.4;
  }
  .icon {
    margin-left: 5px;
  }
`;
interface AddToBasketButtonProps {
  addToBasket: () => void;
  buttonProps: AddButtonProps;
  hasPrice?: boolean;
}

const AddToBasketButton = ({
  addToBasket,
  buttonProps,
  hasPrice,
}: AddToBasketButtonProps) => {
  return (
    <div css={style(hasPrice)}>
      <Tooltip title={buttonProps.TooltipTitle} arrow>
        <span>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => addToBasket()}
            disabled={buttonProps.isDisabled}
            className="button"
            fullWidth
          >
            <p>
              {buttonProps.message}
              {buttonProps.hasIcon && (
                <CheckIcon className="icon" fontSize="small" />
              )}
            </p>
          </Button>
        </span>
      </Tooltip>
    </div>
  );
};

export default AddToBasketButton;
