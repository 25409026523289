import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const basketTotalStyle = css`
  display: flex;
  flex-direction: row;
  gap: 6px;

  dt {
    flex: 1;
  }

  dd {
    text-align: right;
  }

  margin-left: -16px;
  margin-right: -16px;
  margin-top: 16px;
  padding: 16px;
  background: ${colors.documentsHeaderBackground};
  border: 2px solid ${colors.buttonGroupOutlined};
  border-left: none;
  border-right: none;

  font-family: ScandiaWebBold;
  font-size: 20px;
  font-weight: bold;
`;
