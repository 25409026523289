import FilterButtons from 'components/ActionsButtons/FilterButtons';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import colors from 'styles/colors.module.scss';
import { FollowOrdersFilterPopoverProps } from './FollowOrdersFilterPopover/FollowOrdersFilterPopover';
import {
  StatusType,
  useFollowOrdersToolBar,
  UseFollowOrdersToolBarParams,
} from './FollowOrdersToolBar.hooks';
import {
  followOrdersToolBarTopBarStyle,
  followOrdersToolBarBottomBarStyle,
} from './FollowOrdersToolBar.style';

export type FollowOrdersToolBarProps = FollowOrdersFilterPopoverProps &
  UseFollowOrdersToolBarParams;

const FollowOrdersToolBar: FC<FollowOrdersToolBarProps> = ({
  filter,
  setFilter,
  documentCategories,
}) => {
  const { t } = useTranslation();
  const {
    statusOptions,
    statusValue,
    handleSetStatus,
  } = useFollowOrdersToolBar({ filter, setFilter });

  return (
    <div>
      <div css={followOrdersToolBarTopBarStyle}>
        <h1>{t('follow_orders.title')} </h1>
        {/* Commented until filter feature is implemented  */}
        {/* <div>
	        <FollowOrdersFilterPopover
	          filter={filter}
	          setFilter={setFilter}
	          documentCategories={documentCategories}
	        />
	      </div> */}
      </div>
      <div css={followOrdersToolBarBottomBarStyle}>
        <FilterButtons<StatusType>
          buttons={statusOptions}
          styleButtonVariant={(v) =>
            v === statusValue ? 'contained' : 'outlined'
          }
          styleButtonTitle={(v) =>
            v === statusValue ? colors.white : colors.grey
          }
          setValue={handleSetStatus}
        />
      </div>
    </div>
  );
};

export default FollowOrdersToolBar;
