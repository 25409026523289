import { css } from '@emotion/react';
import HorizontalLinearStepper from 'components/stepper/HorizontalLinearStepper';
import { useTranslation } from 'react-i18next';
import { useStoreState } from 'state/store';
import colors from 'styles/colors.module.scss';
import { GetBasketDocuments_basket_basketDocuments_document_campaign as BasketDocumentCampaign } from 'app/schemaInterfaces/GetBasketDocuments';
import DeliveryStep from './basket-steps/DeliveryStep/DeliveryStep';
import SummaryStep from './basket-steps/SummaryStep/SummaryStep';
import { SelectedBasketdocument } from 'modules/sales/sales.state';
import BasketConfirmationPage from './BasketConfirmationPage/BasketConfirmationPage';
import { useEffect, useState } from 'react';
import {
  BasketType,
  FollowOrderStatus,
} from 'app/schemaInterfaces/globalTypes';
import { isDigitalDocument } from 'app/utils/digital-helper';
import { useRouter } from 'app/routes/useRouter';
import useCurrentEntity from 'app/current-entity-context/useCurrentEntity';
import { SalesRoute } from 'modules/sales/salesHelpers';

export interface OrderConfirmationDetails {
  orderId: string;
  orderPrice: number;
  orderStatus: FollowOrderStatus;
  productSellingPriceHt: number | null;
  transportSellingPriceHt: number | null;
}

const style = css`
  .campaign-header {
    background-color: ${colors.workzone};
    border: 1px solid ${colors.buttonGroupOutlined};
    border-left: none;
    border-right: none;
    padding: 29px;
    margin-top: 42px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 16px;
    h5 {
      height: fit-content;
      color: ${colors.documentsTab};
      border-radius: 25px;
      padding: 5px 10px;
      font-weight: 500;
    }
  }
  .commitment-header {
    height: 72px;
    background-color: ${colors.primary};

    img {
      width: 129px;
      display: block;
      margin: auto;
      padding-top: 16px;
    }
  }
  .commitment-stepper {
    background-color: ${colors.documentsHeaderBackground};
  }
  h1 {
    padding-bottom: 8px;
  }
  .delivery-grid {
    padding-top: 53px;
    h1 {
      padding-top: 0;
    }
  }

  .delivery-basket-row {
    border: 2px solid ${colors.buttonGroupOutlined};
    border-radius: 4px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
  }
  h3 {
    display: inline-block;

    & + span {
      color: ${colors.grey};
      border-left: 1px solid ${colors.inputLabelColor};
      font-family: 'ScandiaWebMedium';
      font-weight: 500;
      margin-left: 16px;
      padding: 0 16px;
    }
  }
`;

export interface BasketDocuments {
  basketDocuments: SelectedBasketdocument[];
  campaign: BasketDocumentCampaign;
}

const BasketSteps = () => {
  const activeStep = useStoreState(
    (state) => state.salesModule.step.activeStep
  );
  const basketType = useStoreState(
    (state) => state.salesModule.basket.basketType
  );
  const selectedBasketdocuments = useStoreState(
    (state) => state.salesModule.basketdocuments.selectedBasketdocuments
  );

  const hasDigitalDocuments = selectedBasketdocuments.some(
    (selectedBasketdocument) =>
      isDigitalDocument(selectedBasketdocument.basketDocument.document.category)
  );
  const hasOnlyDigitalDocuments = selectedBasketdocuments.every(
    (selectedBasketdocument) =>
      isDigitalDocument(selectedBasketdocument.basketDocument.document.category)
  );
  const [commitmentNumber, setCommitmentNumber] = useState<
    string | undefined
  >();

  const [orderConfirmationDetails, setOrderConfirmationDetails] = useState<
    OrderConfirmationDetails
  >();

  const { t } = useTranslation();

  const { history } = useRouter();
  const { currentEntity } = useCurrentEntity();
  const hasEntity = !!currentEntity?.id;
  useEffect(() => {
    if (!hasEntity) {
      history.replace({ pathname: SalesRoute.CommitmentsBasket });
    }
  }, [history, hasEntity]);

  const deliveryMessage = hasOnlyDigitalDocuments
    ? t('sales.basket_page.commitment_steps.diffusion')
    : hasDigitalDocuments
    ? `${t('sales.basket_page.commitment_steps.delivery')} / ${t(
        'sales.basket_page.commitment_steps.diffusion'
      )}`
    : t('sales.basket_page.commitment_steps.delivery');

  const steps = [
    t('sales.basket_page.commitment_steps.basket'),
    deliveryMessage,
    t('sales.basket_page.commitment_steps.recap'),
    t('sales.basket_page.commitment_steps.confirmation'),
  ];

  //Temporary disabled until payment is made
  // if (basketType === BasketType.BigShop) {
  //   steps.splice(3, 0, t('sales.basket_page.commitment_steps.payment'));
  // }
  return (
    <div css={style}>
      <div className="commitment-header">
        <img
          src={`/${process.env.REACT_APP_ASSETS}/logoBigOne-white.png`}
          alt="logo"
        />
      </div>
      <div className="commitment-stepper">
        <HorizontalLinearStepper steps={steps} activeStep={activeStep} />
      </div>
      {activeStep === 1 && <DeliveryStep />}
      {activeStep === 2 && (
        <SummaryStep
          setCommitmentNumber={setCommitmentNumber}
          setOrderConfirmationDetails={setOrderConfirmationDetails}
        />
      )}
      {activeStep === 3 && basketType === BasketType.Commitment && (
        <BasketConfirmationPage commitmentNumber={commitmentNumber} />
      )}
      {/* Temporary disabled until payment is made */}
      {/* {activeStep === 3 && basketType === BasketType.BigShop && <PaymentStep />} */}
      {activeStep === 4 && basketType === BasketType.BigShop && (
        <BasketConfirmationPage
          commitmentNumber={commitmentNumber}
          orderConfirmationDetails={orderConfirmationDetails}
        />
      )}
    </div>
  );
};

export default BasketSteps;
