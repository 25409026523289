import { FileExtension, Optional } from 'app/utils/common';
import {
  ImageMetadata,
  readPdfMetadata,
  readPsdMetadata,
  readImageMetadata,
} from 'app/utils/FileMetadataReader/FileMetadataReader';
import mime from 'mime-types';
import { ACCEPTED_IMAGE_TYPES } from 'modules/dam/form/helpers';

export const displayableAssets = ['image/png', 'image/jpeg', 'application/pdf'];

export const isAssetDisplayable = (mimetype: string) =>
  displayableAssets.includes(mimetype);

export const extractMetadata = (
  file: File
): Optional<Promise<ImageMetadata>> => {
  const fileExtension = mime.extension(file.type);
  if (fileExtension === FileExtension.Pdf) return readPdfMetadata(file);
  if (fileExtension === FileExtension.Psd) return readPsdMetadata(file);
  if (ACCEPTED_IMAGE_TYPES.includes(file.type)) return readImageMetadata(file);
};
