import { ApolloError, MutationTuple } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { getGqlErrorMessage } from '../errors';

export interface UseMutationErrorWorkflowParam<TData, TVariables> {
  request: MutationTuple<TData, TVariables>[0];
  variables?: TVariables;
  refetch?: () => Promise<any>;
  beforeRequestStartCallback?: () => void;
  afterRequestStartCallback?: () => void;
  i18nKeyErrorDictionary?: Map<string, string>;
  i18nKeyConfirmationMessage?: string;
  showConfirmationMessage?: boolean;
}

export const useMutationErrorWorkflow = <TData, TVariables>({
  request,
  variables,
  refetch,
  beforeRequestStartCallback,
  afterRequestStartCallback,
  i18nKeyErrorDictionary = new Map(),
  i18nKeyConfirmationMessage,
  showConfirmationMessage = true,
}: UseMutationErrorWorkflowParam<TData, TVariables>) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  return (vars: TVariables | undefined = variables) => {
    beforeRequestStartCallback?.();
    const requestPromise = request({ variables: vars })
      .then((result) => {
        refetch?.();
        if (showConfirmationMessage) {
          enqueueSnackbar(
            t(i18nKeyConfirmationMessage || 'common.actions.success'),
            {
              variant: 'success',
            }
          );
        }
        return result;
      })
      .catch((error: ApolloError | null) =>
        getGqlErrorMessage(error, i18nKeyErrorDictionary, t).forEach((err) =>
          enqueueSnackbar(err, { variant: 'error' })
        )
      );
    afterRequestStartCallback?.();
    return requestPromise;
  };
};
