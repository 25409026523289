import { Box } from '@material-ui/core';
import InputBase, { InputBaseProps } from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import React, { FC } from 'react';
import {
  quickSearchBarStyle,
  quickSearchBarIconStyle,
} from './QuickSearchBar.style';

export interface QuickSearchBarProps extends InputBaseProps {
  borderRadius?: string | number;
  hasBorder?: boolean;
  bgcolor?: string;
  inputBaseWidth?: string;
  iconSize?: 'small' | 'inherit' | 'default' | 'large';
  hasIcon?: boolean;
}

const QuickSearchBar: FC<QuickSearchBarProps> = ({
  borderRadius = 16,
  hasBorder = true,
  bgcolor = 'transparent',
  inputBaseWidth = '220px',
  iconSize = 'small',
  hasIcon = true,
  ...inputBaseProps
}) => (
  <Box css={quickSearchBarStyle({ borderRadius, bgcolor, hasBorder })}>
    <Box width={inputBaseWidth} pt={0.4}>
      <InputBase
        fullWidth
        startAdornment={
          hasIcon && (
            <SearchIcon
              css={quickSearchBarIconStyle}
              fontSize={iconSize}
              data-testid="search-icon"
            />
          )
        }
        {...inputBaseProps}
      />
    </Box>
  </Box>
);

export default QuickSearchBar;
