// https://popfactory.atlassian.net/browse/BIG-228
// [dpi] = [Taille en PX] / [Taille en cm] x 2,54
export const processDpi = (pixelSize: number, surfaceSize: number): number =>
  (pixelSize * 2.54) / surfaceSize;

// https://popfactory.atlassian.net/browse/BIG-228
// [taille cm] = [taille px] x 2,54 / 300dpi
export const processMaxSurfaceSize = (pixelSize: number): number =>
  Math.floor(((pixelSize * 2.54) / 300) * 100) / 100;

// see https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
export const roundToDecimal = (number: number): number =>
  Math.round((number + Number.EPSILON) * 10) / 10;

export const addZeroes = (number: number): string => {
  const decimal = number.toString().split('.')[1];
  const length = decimal && decimal.length > 2 ? decimal.length : 2;
  return Number(number).toFixed(length);
};

const inchesToCmRatio = 2.54;

export const pxToCm = (dimensionInPx: number, dpi: number): number =>
  (dimensionInPx / dpi) * inchesToCmRatio;

const dpiBase = 96;

export const getLengthFromCmToPx = (lengthInCm: number) =>
  (lengthInCm * dpiBase) / inchesToCmRatio;
