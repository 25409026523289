import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

const style = css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .no-order-img {
    margin-top: 70px;
    margin-bottom: 142px;
    height: 196px;
    img {
      display: block;
      width: 361px;
      margin: auto;
    }
  }
  .no-order-msg {
    text-align: center;
    margin-bottom: 28px;
    h1 {
      margin-bottom: 28px;
      color: ${colors.body};
    }
  }
`;
interface NoOrdersProps {
  message: string;
  title?: string;
}
const NoOrders = ({ title, message }: NoOrdersProps) => {
  return (
    <div css={style}>
      <div className="no-order-img">
        <img
          src={`/${process.env.REACT_APP_ASSETS}/no_order.png`}
          alt="no-order"
        />
      </div>
      <div className="no-order-msg">
        {title && <h1> {title} </h1>}
        <p>{message} </p>
      </div>
    </div>
  );
};

export default NoOrders;
