import { ShopRoute, shopBreadcrumbs } from 'modules/shop/shopHelpers';
import Breadcrumbs from 'modules/shop/components/Breadcrumbs';
import { CampaignFilterInput } from 'app/schemaInterfaces/globalTypes';
import CampaignsList from 'modules/pro/campaigns/CampaignsList';
import DocumentSkeletonLoader from 'modules/home/components/available-documents/DocumentsSkeletonLoader';
import HomeReversePlanning from 'modules/home/components/home-reversePlanning/HomeReversePlanning';
import { ModuleKey } from 'app/routes/helper';
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import NoItemsNotice from 'modules/shop/components/NoItemsNotice';
import React from 'react';
import ShopFilters from '../components/ShopFilters';
import { campaignDetailsActions } from 'modules/pro/campaigns/campaignsHelper';
import { shopStyle } from './Shop.style';
import useClientOffers from 'app/auth/useClientOffers';
import usePermissions from 'app/auth/usePermissions';
import { useShop } from './Shop.hooks';
import { useTranslation } from 'react-i18next';

const Shop = ({ filters }: { filters: CampaignFilterInput }) => {
  const { hasPermission } = usePermissions();
  const { t } = useTranslation();

  const {
    localCampaigns,
    localCampaignsError,
    isLocalCampaignsLoading,
    localCampaignsRefetch,
    nationalCampaigns,
    nationalCampaignsError,
    isNationalCampaignsLoading,
    nationalCampaignsRefetch,
    selectedDateStatus,
    setSelectedDateStatus,
  } = useShop(filters);

  const { hasOffers } = useClientOffers();

  return (
    <div css={shopStyle}>
      <Breadcrumbs paths={shopBreadcrumbs(t).paths} />
      {hasOffers([ModuleKey.ActionMarketing, ModuleKey.AM_OP_Nationales]) && (
        <>
          <h1>{t('local.common.national_campaigns')}</h1>
          <ShopFilters
            dateStatusCount={nationalCampaigns?.getAllCampaigns.dateStatusCount}
            error={nationalCampaignsError}
            path={ShopRoute.AllNationalCampaigns}
            selectedDateStatus={selectedDateStatus}
            setSelectedDateStatus={setSelectedDateStatus}
            isCampaignModel={false}
            refetch={nationalCampaignsRefetch}
          />
          {isNationalCampaignsLoading ? (
            <DocumentSkeletonLoader
              numberOfElements={1}
              height={394}
              borderRadius={10}
            />
          ) : nationalCampaigns?.getAllCampaigns.campaigns?.length ? (
            <CampaignsList
              campaigns={nationalCampaigns?.getAllCampaigns.campaigns}
              columnsWidth={[410, 270, 200, 245, 30]}
              imageWidth={80}
              actions={(
                manageCampaign,
                handleOpenCampaignDialog,
                deleteCampaign,
                previewCampaign
              ) =>
                campaignDetailsActions({
                  manageCampaign,
                  handleOpenCampaignDialog,
                  deleteCampaign,
                  previewCampaign,
                  t,
                  hasPermission,
                  isCampaignModel: false,
                })
              }
              button={<MoreVertOutlinedIcon />}
              isCampaignModel={false}
              refetch={nationalCampaignsRefetch}
            />
          ) : (
            <NoItemsNotice message={t('shop.common.no_national_campaign')} />
          )}
        </>
      )}
      {hasOffers([ModuleKey.ActionMarketing, ModuleKey.AM_OP_Locales]) && (
        <>
          <h1>{t('global.thematics.thematics')}</h1>
          <ShopFilters
            error={localCampaignsError}
            path={ShopRoute.AllLocalCampaigns}
            hasFilters={false}
            selectedDateStatus={selectedDateStatus}
            setSelectedDateStatus={setSelectedDateStatus}
            isCampaignModel={true}
            refetch={localCampaignsRefetch}
          />
          {isLocalCampaignsLoading ? (
            <DocumentSkeletonLoader
              numberOfElements={1}
              height={394}
              borderRadius={10}
            />
          ) : localCampaigns?.getAllCampaigns.campaigns?.length ? (
            <CampaignsList
              campaigns={localCampaigns?.getAllCampaigns.campaigns}
              columnsWidth={[410, 270, 200, 245, 30]}
              imageWidth={80}
              actions={(
                manageCampaign,
                handleOpenCampaignDialog,
                deleteCampaign,
                previewCampaign
              ) =>
                campaignDetailsActions({
                  manageCampaign,
                  handleOpenCampaignDialog,
                  deleteCampaign,
                  previewCampaign,
                  t,
                  hasPermission,
                  isCampaignModel: true,
                })
              }
              button={<MoreVertOutlinedIcon />}
              isCampaignModel={true}
              refetch={localCampaignsRefetch}
            />
          ) : (
            <NoItemsNotice message={t('shop.common.no_thematics')} />
          )}
        </>
      )}
      {hasOffers([ModuleKey.AM_Planning]) && <HomeReversePlanning />}
    </div>
  );
};

export default Shop;
