import { useQuery, WatchQueryFetchPolicy } from '@apollo/client';
import { ADDRESS_BLOCKS } from 'app/graphql/queries/addressBlocks';
import {
  AddressBlocks,
  AddressBlocksVariables,
} from 'app/schemaInterfaces/AddressBlocks';
import { AddressBlockSortInput } from 'app/schemaInterfaces/globalTypes';

interface useAddressBlocksQueryProps {
  entityId: string;
  sort: AddressBlockSortInput;
  fetchPolicy?: WatchQueryFetchPolicy;
}

const useAddressBlocksQuery = ({
  entityId,
  sort,
  fetchPolicy = 'cache-and-network',
}: useAddressBlocksQueryProps) =>
  useQuery<AddressBlocks, AddressBlocksVariables>(ADDRESS_BLOCKS, {
    fetchPolicy,
    variables: {
      entityId,
      sort,
    },
  });

export default useAddressBlocksQuery;
