import {
  GetBasketDocuments,
  GetBasketDocuments_basket_basketDocuments,
  GetBasketDocuments_basket_basketDocuments_document_campaign as BasketDocumentCampaign,
} from 'app/schemaInterfaces/GetBasketDocuments';
import { BasketType } from 'app/schemaInterfaces/globalTypes';
import { TFunction } from 'i18next';
import _ from 'lodash';

export enum SalesRoute {
  BigShopBasket = '/sales/panier/commandes',
  CommitmentsBasket = '/sales/panier/engagements',
  CommitmentsSteps = '/sales/engagements-steps',
}

export const getLastDate = (
  basketDocuments: GetBasketDocuments_basket_basketDocuments[]
) =>
  basketDocuments
    .map((basketDocument) =>
      basketDocument.document.commitmentDateTo
        ? basketDocument.document.commitmentDateTo
        : basketDocument.document.freeOrderDateTo
    )
    .reduce((firstDate, secondDate) =>
      new Date(firstDate) > new Date(secondDate) ? firstDate : secondDate
    );

export const getCampaigns = (
  basketDocuments?: GetBasketDocuments_basket_basketDocuments[]
): {
  campaignBasketDocuments: GetBasketDocuments_basket_basketDocuments[];
  campaign: BasketDocumentCampaign;
}[] => {
  const groupedBy = _.groupBy(
    basketDocuments,
    (basketDocument) => basketDocument.document.campaign.id
  );
  return Object.keys(groupedBy).map((key) => {
    const campaign = basketDocuments?.find(
      (basketDocument) => basketDocument.document.campaign.id === key
    )?.document.campaign;

    return {
      campaignBasketDocuments: groupedBy[key],
      campaign: campaign as BasketDocumentCampaign,
    };
  });
};

export const basketConfirmationTitle = (basketType: BasketType, t: TFunction) =>
  basketType === BasketType.Commitment
    ? t('sales.basket_page.my_commitments')
    : t('sales.basket_page.order.title');

export const basketDateTag = (basketType: BasketType, t: TFunction) =>
  basketType === BasketType.Commitment
    ? t('sales.basket_page.commitment_date_to')
    : t('sales.basket_page.order.order_date_to');

export const firstBasketConfirmationTitle = (
  basketType: BasketType,
  t: TFunction
) =>
  basketType === BasketType.Commitment
    ? t('sales.basket_page.confirmation')
    : t('sales.basket_page.order.order_confirmation');

export const getQuantity = (
  basketData: GetBasketDocuments,
  documentId: string
) =>
  basketData?.basket.basketDocuments.find(
    (basketDocument) => basketDocument.document.versionId === documentId
  )?.quantity;
