import { css } from '@emotion/react';

export const entityMenuItemStyle = {
  disabledContainer: css`
    cursor: default;
  `,
  card: css`
    flex-grow: 1;
  `,
};
