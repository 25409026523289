import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { AssetType } from 'app/schemaInterfaces/globalTypes';

export enum ViewMode {
  CONTENT_VIEW,
  TYPES_VIEW,
}

export enum AssetDataSource {
  DAM = 'DAM',
  CAMPAIGN = 'CAMPAIGN',
}

interface AssetPanelFilters {
  text: string;
  divisions: Record<string, string[] | undefined>;
  assetType: AssetType | null;
  validFrom?: MaterialUiPickersDate;
  validTo?: MaterialUiPickersDate;
  entityId: string;
}

export interface AssetsPanelState {
  viewMode: ViewMode;
  dataSource: AssetDataSource;
  filters: AssetPanelFilters;
}
