import { useQuery, WatchQueryFetchPolicy } from '@apollo/client';
import { GET_ALL_CAMPAIGNS } from 'app/graphql/queries/campaigns';
import {
  GetAllCampaigns,
  GetAllCampaignsVariables,
} from 'app/schemaInterfaces/GetAllCampaigns';
import {
  CampaignFilterInput,
  PaginationInput,
} from 'app/schemaInterfaces/globalTypes';

interface useCampaignsQueryProps {
  filter?: CampaignFilterInput;
  pagination?: PaginationInput;
  fetchPolicy?: WatchQueryFetchPolicy;
}
const useCampaignsQuery = ({
  filter = {},
  pagination = {},
  fetchPolicy = 'network-only',
}: useCampaignsQueryProps = {}) =>
  useQuery<GetAllCampaigns, GetAllCampaignsVariables>(GET_ALL_CAMPAIGNS, {
    fetchPolicy,
    variables: {
      filter,
      pagination,
    },
  });

export default useCampaignsQuery;
