import React from 'react';
import { Button, Box, Typography } from '@material-ui/core';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { css } from '@emotion/react';

const style = css`
  .button {
    width: 130px;
    height: 40px;
  }
`;
export interface Buttons<T> {
  title: string;
  buttonColor?: 'inherit' | 'default' | 'primary' | 'secondary';
  value: T;
}

export interface TemporalFilterProps<T> {
  buttons: Buttons<T>[];
  styleButtonVariant: (value: T) => 'text' | 'contained' | 'outlined';
  styleButtonTitle: (value: T) => string;
  setValue: React.Dispatch<T>;
}

function FilterButtons<T>({
  buttons,
  styleButtonTitle,
  styleButtonVariant,
  setValue,
}: TemporalFilterProps<T>): JSX.Element {
  return (
    <Box display="flex" alignItems="center" css={style}>
      <ButtonGroup size="medium" color="primary" variant="outlined">
        {buttons.map(({ title, buttonColor, value }, index: number) => (
          <Button
            color={buttonColor}
            variant={styleButtonVariant(value)}
            key={index}
            onClick={() => setValue(value)}
            className="button"
          >
            <Typography
              style={{ color: styleButtonTitle(value) }}
              variant="body2"
            >
              {title}
            </Typography>
          </Button>
        ))}
      </ButtonGroup>
    </Box>
  );
}

export default FilterButtons;
