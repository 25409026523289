import { Button, Tooltip } from '@material-ui/core';
import { ClientPrice } from '../QuantitySelect';
import {
  hasQuantityError,
  filteredAttributes,
  isPriceValid,
} from '../QuantitySelectHelper';
import { useEffect, useMemo, useState } from 'react';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Skeleton } from '@material-ui/lab';
import useAddressBlocksQuery from 'queries/useAddressBlocksQuery';
import useClientPricesQuery from 'modules/shop/queries/useClientPricesQuery';
import useCurrentEntity from 'app/current-entity-context/useCurrentEntity';
import useDeliveryAddressesQuery from 'queries/useDeliveryAddressesQuery';
import usePriceByQuantity from 'modules/shop/queries/usePriceByQuantityQuery';
import { useStoreActions } from 'state/store';
import { useTranslation } from 'react-i18next';
import { isDigitalDocument } from 'app/utils/digital-helper';
import BasketDeliveryTable from '../BasketDeliveryTable/BasketDeliveryTable';
import {
  getFacebookHeadData,
  getFacebookPostRowData,
  getPrintDocumentHeadData,
  getPrintDocumentRowData,
} from './BasketDeliveryContainer.helper';
import { basketDeliveryContainerStyle } from './BasketDeliveryContainer.style';
import { BasketDeliveryContainerProps } from './BasketDeliveryContainer.definitions';

const BasketDeliveryContainer = ({
  basketDocumentItem,
  setAddress,
  handleUpdateBasketDocument,
}: BasketDeliveryContainerProps) => {
  const { currentEntity } = useCurrentEntity();
  const { t } = useTranslation();
  const { data } = useDeliveryAddressesQuery(currentEntity?.id);
  const deliveryAddresses = data?.entityDeliveryAdresses;
  const mainAddress = deliveryAddresses?.find(({ isMain }) => isMain);

  const deleteAddressFromDocument = useStoreActions(
    (actions) => actions.salesModule.basketdocuments.deleteAddressFromDocument
  );

  const setQuantityToDocument = useStoreActions(
    (actions) => actions.salesModule.basketdocuments.setQuantityToDocument
  );

  const addAddressRow = useStoreActions(
    (actions) => actions.salesModule.basketdocuments.addAddressRow
  );

  const totalQuantity = basketDocumentItem.addresses?.reduce(
    (acc, currentAddress) => {
      return acc + currentAddress.quantity;
    },
    0
  );

  const { data: addressBlockData } = useAddressBlocksQuery({
    entityId: currentEntity?.id as string,
    sort: {},
  });

  const addDeliveryAddress = () => {
    addAddressRow({
      documentId: basketDocumentItem.basketDocument.document.id,
    });
  };

  const deleteDeliveryAddress = (index: number) => {
    deleteAddressFromDocument({
      documentId: basketDocumentItem.basketDocument.document.id,
      index,
    });
  };
  const attributes = filteredAttributes(
    basketDocumentItem.basketDocument.document.productAttributes
  );
  const productId = basketDocumentItem.basketDocument.document.productId;

  const minimumDocQuantity =
    basketDocumentItem.basketDocument?.document?.quantities?.rangeQuantity
      ?.minimum ?? 0;
  const maximumDocQuantity =
    basketDocumentItem.basketDocument?.document?.quantities?.rangeQuantity
      ?.maximum ?? undefined;
  const hasStrictQuantity = Boolean(
    basketDocumentItem.basketDocument.document?.quantities?.strictQuantity
  );

  const { clientPricesData, clientPricesLoading } = useClientPricesQuery({
    productId,
    minimum: minimumDocQuantity,
    maximum: maximumDocQuantity,
  });

  const { priceData, priceDataLoading } = usePriceByQuantity({
    productId,
    quantity:
      basketDocumentItem.basketDocument?.document.quantities?.strictQuantity,
  });
  const [hasPriceError, setHasPriceError] = useState<boolean>(false);

  const [error, setError] = useState<boolean>(false);
  const lowestQuantity = clientPricesData?.prices[0]?.quantity ?? 0;

  const handlePriceError = () => {
    setHasPriceError(true);
  };

  useEffect(() => {
    if (mainAddress && !basketDocumentItem.addresses.length) {
      addAddressRow({
        documentId: basketDocumentItem.basketDocument.document.id,
        address: mainAddress?.address,
      });

      setQuantityToDocument({
        index: 0,
        documentId: basketDocumentItem.basketDocument.document.id,
        quantity: basketDocumentItem.basketDocument.quantity ?? 0,
        price: basketDocumentItem.basketDocument.price,
        productName: basketDocumentItem.basketDocument.productName,
        isValid: !Boolean(
          hasQuantityError({
            quantity: basketDocumentItem.basketDocument.quantity,
            minQuantity: lowestQuantity,
            maxQuantity: maximumDocQuantity,
          })
        ),
        isIncluded:
          basketDocumentItem.basketDocument.document.priceInformations
            ?.isIncluded,
      });
    }
  }, [
    addAddressRow,
    basketDocumentItem,
    hasStrictQuantity,
    lowestQuantity,
    mainAddress,
    maximumDocQuantity,
    minimumDocQuantity,
    setQuantityToDocument,
  ]);

  const onChangeAddress = (values: ClientPrice, index?: number) => {
    if (index !== undefined) {
      if (values.productName) {
        handleUpdateBasketDocument(values.productName, index);
        hasPriceError && setHasPriceError(false);
      }

      setQuantityToDocument({
        index: index ?? 0,
        documentId: basketDocumentItem.basketDocument.document.id,
        quantity: values.quantity ?? 0,
        price: values.price ?? 0,
        productName: values.productName,
        isValid:
          !Boolean(
            hasQuantityError({
              quantity: values.quantity,
              minQuantity: lowestQuantity,
              maxQuantity: maximumDocQuantity,
            })
          ) &&
          !!values.productName &&
          isPriceValid(values.price, values.isIncluded),
        isIncluded: values.isIncluded,
      });
    }

    hasQuantityError({
      quantity: values.quantity,
      minQuantity: lowestQuantity,
      maxQuantity: maximumDocQuantity,
    })
      ? setError(true)
      : setError(false);
  };

  useEffect(() => {
    if (
      basketDocumentItem.basketDocument.document?.quantities &&
      basketDocumentItem.basketDocument.document.quantities.strictQuantity
    ) {
      const quantity =
        basketDocumentItem.basketDocument.document.quantities.strictQuantity;
      if (priceData && clientPricesData) {
        const price = priceData.price ?? 0;

        setQuantityToDocument({
          index: 0,
          documentId: basketDocumentItem.basketDocument.document.id,
          quantity,
          price,
          productName: basketDocumentItem.basketDocument.productName,
          isValid: !Boolean(
            hasQuantityError({
              quantity,
              minQuantity: lowestQuantity,
              maxQuantity: maximumDocQuantity,
            })
          ),
          isIncluded:
            basketDocumentItem.basketDocument.document.priceInformations
              ?.isIncluded,
        });
      } else {
        setQuantityToDocument({
          index: 0,
          documentId: basketDocumentItem.basketDocument.document.id,
          quantity,
          price: 0,
          productName: '',
          isValid: false,
          isIncluded:
            basketDocumentItem.basketDocument.document.priceInformations
              ?.isIncluded,
        });
      }
    }
  }, [
    basketDocumentItem.basketDocument.document.id,
    basketDocumentItem.basketDocument.document.quantities,
    basketDocumentItem.basketDocument.productName,
    clientPricesData,
    lowestQuantity,
    maximumDocQuantity,
    minimumDocQuantity,
    priceData,
    priceData?.price,
    setQuantityToDocument,
    basketDocumentItem.basketDocument.document.priceInformations?.isIncluded,
  ]);

  const isDigital = useMemo(
    () =>
      isDigitalDocument(basketDocumentItem.basketDocument.document.category),
    [basketDocumentItem.basketDocument.document.category]
  );

  const isQuantiySelectDisabled = !attributes || hasStrictQuantity;

  if (clientPricesLoading || priceDataLoading) {
    return <Skeleton variant="rect" width="100%" height={220} />;
  }

  const tableHeadData = isDigital
    ? getFacebookHeadData(t)
    : getPrintDocumentHeadData(t);

  const tableRowData = isDigital
    ? getFacebookPostRowData({
        basketDocumentItem,
        t,
        entityName: currentEntity?.name,
        url: currentEntity?.socialNetworks?.clientNetworkLink,
      })
    : getPrintDocumentRowData({
        basketDocumentItem,
        clientPricesData,
        setAddress,
        deliveryAddresses,
        t,
        onChangeAddress,
        options: clientPricesData?.prices ?? [],
        error,
        handlePriceError,
        hasPriceError,
        isQuantiySelectDisabled,
        minimumDocQuantity: lowestQuantity,
        maximumDocQuantity,
        addressBlocks: addressBlockData?.addressBlocks,
        deleteDeliveryAddress,
      });

  return (
    <div css={basketDeliveryContainerStyle}>
      <BasketDeliveryTable
        tableHeadData={tableHeadData}
        tableRowData={tableRowData}
      />
      {!isDigital && (
        <div className="add">
          <Tooltip
            title={
              basketDocumentItem.addresses.length === deliveryAddresses?.length
                ? t(
                    'sales.basket_page.delivery_page.delivery_addresses.address_alert'
                  ) ?? ''
                : ''
            }
          >
            <span>
              <Button
                disabled={
                  basketDocumentItem.addresses.length ===
                    deliveryAddresses?.length ||
                  !Boolean(deliveryAddresses?.length)
                }
                color="primary"
                variant="text"
                onClick={addDeliveryAddress}
              >
                +{' '}
                {t(
                  'sales.basket_page.delivery_page.delivery_addresses.add_delivery_point'
                )}
              </Button>
              <ErrorOutlineIcon />
            </span>
          </Tooltip>

          <span className="quantity">
            {t(
              'sales.basket_page.delivery_page.delivery_addresses.total_quantity'
            )}
            : {totalQuantity ?? 0}
          </span>
        </div>
      )}
    </div>
  );
};

export default BasketDeliveryContainer;
