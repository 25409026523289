import React, { useContext } from 'react';
import { AscDescType } from 'app/schemaInterfaces/globalTypes';
import { Option } from 'components/ControlledSelect/ControlledSelect';
import UncontrolledSelect from 'components/UncontrolledSelect';
import { AssetFiltersActionCreator } from 'modules/dam/reducers/filters/actions';
import { AssetFiltersContext } from 'modules/dam/reducers/filters/context';
import { AssetSortOptions } from 'modules/dam/reducers/filters/definitions';

interface AssetSortProps {
  selectedSort: AssetSortOptions;
}

enum SelectValues {
  UpdateAtDesc = 0,
  UpdatedAtAsc = 1,
}

export const AssetSort = ({ selectedSort }: AssetSortProps): JSX.Element => {
  const dispatch = useContext(AssetFiltersContext);

  const options: Option[] = [
    {
      label: 'Les plus récents',
      value: SelectValues.UpdateAtDesc,
    },
    {
      label: 'Les plus anciens',
      value: SelectValues.UpdatedAtAsc,
    },
  ];

  const handleChangeInternal = (e: React.ChangeEvent<{ value: unknown }>) => {
    if (!dispatch) return;
    switch (e.target.value as SelectValues) {
      case SelectValues.UpdateAtDesc:
        dispatch(
          AssetFiltersActionCreator.updateSort({
            updatedAt: AscDescType.desc,
          })
        );
        break;
      case SelectValues.UpdatedAtAsc:
        dispatch(
          AssetFiltersActionCreator.updateSort({
            updatedAt: AscDescType.asc,
          })
        );
        break;
    }
  };

  return (
    <UncontrolledSelect
      options={options}
      value={
        selectedSort.updatedAt === AscDescType.desc
          ? SelectValues.UpdateAtDesc
          : SelectValues.UpdatedAtAsc
      }
      onChange={handleChangeInternal}
      isBorderRound={true}
    />
  );
};
