import Chip from '@material-ui/core/Chip';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useState } from 'react';
import { atocompleteFieldStyle } from './TagsAutocompleteField/TagsAutocompleteField.style';

export interface UncontrolledSimpleAutocompleteProps {
  value: string[];
  options: string[];
  onChange: (values: string[]) => void;
  freeSolo?: boolean;
}

const UncontrolledSimpleAutocomplete = ({
  value,
  options,
  onChange,
  freeSolo = false,
  ...textFieldProps
}: UncontrolledSimpleAutocompleteProps &
  Pick<
    TextFieldProps,
    'margin' | 'fullWidth' | 'placeholder'
  >): JSX.Element => {
  const [inputValue, setInputValue] = useState('');

  return (
    <Autocomplete<string>
      autoComplete
      freeSolo={freeSolo}
      multiple={true}
      options={options}
      getOptionLabel={(option) => option}
      renderTags={(options, getTagProps) =>
        options.map((option, index) => (
          <Chip label={option} color="primary" {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          {...textFieldProps}
          css={atocompleteFieldStyle}
        />
      )}
      value={value}
      inputValue={inputValue}
      onChange={(event, values) => onChange(values)}
      onInputChange={(event, value) => setInputValue(value)}
    />
  );
};

export default UncontrolledSimpleAutocomplete;
