import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import {
  useStudioDispatchContext,
  useStudioStateContext,
} from 'modules/workflow/oneStudioContext';
import colors from 'styles/colors.module.scss';
import { PageWithIndex } from 'modules/workflow/containers/Leftsidebar/PagesPanel/PagesPanel';
import { Tooltip, Typography } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import { css } from '@emotion/react';
import { studioAppActions } from 'modules/workflow/reducers/actionsInterfaces';
import { useCurrentUser } from 'app/auth/UserContext';
import { UserType } from 'app/schemaInterfaces/globalTypes';
import { useTranslation } from 'react-i18next';

const iconStyle = (hasMultipleIcon: boolean, isUserGlobal: boolean) => css`
  display: flex;
  justify-content: ${hasMultipleIcon ? 'space-between' : 'end'};
  height: 20px;
  .customizable-button {
    cursor: ${isUserGlobal ? 'pointer' : 'auto'};
  }
`;

const style = (url?: string) => css`
  background-image: url(${url});
  background-size: cover;
`;

const useStyles = makeStyles((theme) => ({
  paperElementBorders: {
    color: colors.primary,
    boxSizing: 'border-box',
    outlineOffset: '-5px',
    border: `2px solid ${colors.primary}`,
  },
  paperRoot: {
    width: '104px',
    height: '132px',
  },
}));

interface PagesRowProps {
  pagesData?: PageWithIndex[];
  selectPageFn: (pageIndex: number) => void;
}
const PagesRow = ({ pagesData, selectPageFn }: PagesRowProps): JSX.Element => {
  const classes = useStyles();
  const globalState = useStudioStateContext();
  const dispatch = useStudioDispatchContext();
  const user = useCurrentUser();
  const isUserGlobal = user.type === UserType.Global;
  const { t } = useTranslation();
  const switchCustomPageStatus = (isCustomizable: boolean, index: number) =>
    dispatch(
      studioAppActions.setPageCustomization({
        indexPage: index,
        isCustomizable: !isCustomizable,
      })
    );

  const getTooltipText = (isCustomizable: boolean) =>
    isUserGlobal
      ? isCustomizable
        ? t('workflow.left_side_bar.pages_panel.make_non_customizable_page')
        : t('workflow.left_side_bar.pages_panel.make_customizable_page')
      : isCustomizable
      ? t('workflow.left_side_bar.pages_panel.customizable_page')
      : t('workflow.left_side_bar.pages_panel.non_customizable_page');

  return (
    <Grid container>
      {pagesData?.map((page, index) => {
        //first and last page will take a whole row
        const pageColumns =
          index === 0 || index === globalState.version.pages.length - 1
            ? 12
            : 6;
        return (
          <Grid container item xs={pageColumns} key={index}>
            <Box width="104px" marginBottom="12px" pl={1}>
              <Grid container direction="column" item spacing={1}>
                <Grid item css={iconStyle(page.validated, isUserGlobal)}>
                  {page.validated && (
                    <CheckCircleOutlineIcon
                      htmlColor={colors.loader1}
                      fontSize="small"
                    />
                  )}
                  <Tooltip title={getTooltipText(page.isCustomizable)}>
                    <FormatColorFillIcon
                      className="customizable-button"
                      htmlColor={
                        page.isCustomizable ? colors.loader1 : colors.lightGrey
                      }
                      fontSize="small"
                      onClick={() =>
                        isUserGlobal &&
                        switchCustomPageStatus(page.isCustomizable, index)
                      }
                    />
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Paper
                    css={style(page.thumbnailUrl)}
                    classes={{ root: classes.paperRoot }}
                    className={
                      globalState.currentPage === page.pageIndex
                        ? classes.paperElementBorders
                        : ''
                    }
                    elevation={2}
                    onClick={() => selectPageFn(page.pageIndex)}
                    key={index}
                  />
                </Grid>
                <Grid container item justify="center">
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    fontFamily="MontserratWebRegular"
                    fontSize="12px"
                    color={colors.white}
                    bgcolor={
                      globalState.currentPage === page.pageIndex
                        ? colors.primary
                        : colors.leftSideBar
                    }
                    borderRadius="50%"
                    width="30px"
                    height="30px"
                  >
                    <Typography variant="h5">{page.pageIndex}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default PagesRow;
