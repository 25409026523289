import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import colors from 'styles/colors.module.scss';
import { useTranslation } from 'react-i18next';
import { ConfirmationDialogProps } from 'components/ConfirmationDialogs/ConfirmationDialog/ConfirmationDialog';
import { Option } from './DistributionMenu';
import UploadFileButton from 'components/UploadFileButton';
import { ACCEPTED_EXCEL_TYPES } from 'modules/dam/form/helpers';
import CloseIcon from '@material-ui/icons/Close';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { css } from '@emotion/react';
import { CircularProgress } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const style = css`
  .MuiPaper-root {
    max-width: 520px;
  }
  .title {
    padding: 10px 30px;
  }
  .import {
    transform: rotate(180deg);
  }
  .MuiDialogContent-root {
    padding: 10px 30px;
  }
  .MuiDialogActions-root {
    justify-content: space-between;
    padding: 10px 30px;
    button {
      width: 222px;
    }
  }
  .select {
    margin-bottom: 30px;
    width: 325px;
  }
  .support {
    padding-bottom: 9px;
  }
  .template {
    padding-bottom: 6px;
  }
  .subtext {
    color: ${colors.grey};
  }
  .loader {
    color: ${colors.white};
  }
  .message {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    padding: 12px;
    .closeIcon {
      cursor: pointer;
    }
    .tip {
      padding-left: 13px;
    }
  }
  .success {
    background-color: ${colors.green};
    color: ${colors.acidGreen};
  }
  .failure {
    background-color: ${colors.softPink};
    color: ${colors.pink};
  }
`;

interface OneButtonConfirmationDialogProps
  extends Omit<ConfirmationDialogProps, 'actions'> {
  onClick: (file: File[]) => Promise<void>;
  options: Option[];
  importId: string | null;
  onChange: (value: string) => void;
  onClickSecondary: () => void;
  isUploadInProgress: boolean;
  isImportSuccess: boolean | null;
  setIsImportSuccess: React.Dispatch<React.SetStateAction<boolean | null>>;
}

const ImportDistributionDialog = ({
  isOpen,
  onClose,
  onChange,
  title,
  options,
  importId,
  onClick,
  onClickSecondary,
  isUploadInProgress,
  setIsImportSuccess,
  isImportSuccess,
}: OneButtonConfirmationDialogProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="xs" css={style}>
      <DialogTitle className="title">
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography variant="h3">{title}</Typography>
          </Box>
          <Box>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                onClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <Divider light />
      <Box py={1}>
        <DialogContent>
          {isImportSuccess !== null && (
            <div
              className={
                isImportSuccess ? 'message success' : 'message failure'
              }
            >
              <Box display="flex">
                {isImportSuccess ? <CheckCircleOutlineIcon /> : <CloseIcon />}
                <Typography variant="body1" className="tip">
                  {isImportSuccess
                    ? t('global.distribution_list.success')
                    : t('global.distribution_list.failure')}
                </Typography>
              </Box>
              <CloseIcon
                className="closeIcon"
                fontSize="small"
                onClick={(event) => {
                  event.stopPropagation();
                  setIsImportSuccess(null);
                }}
              />
            </div>
          )}
          <Typography variant="h5" className="support">
            {t('global.distribution_list.choose_document')}
          </Typography>

          <Select
            className="select"
            variant="outlined"
            value={importId}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
            onChange={(e) => onChange(e.target.value as string)}
          >
            {options.map(
              ({ value, label }) =>
                value && (
                  <MenuItem key={value} value={value}>
                    {label ?? t('global.document_form.product_attributes.none')}
                  </MenuItem>
                )
            )}
          </Select>
          <Typography variant="h5" className="template">
            {t('global.distribution_list.has_template')}
          </Typography>
          <Typography variant="body1" className="subtext">
            {t('global.distribution_list.import_template')}
          </Typography>
        </DialogContent>
      </Box>
      <Divider light={true} />
      <Box py={1}>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={(event) => {
              event.stopPropagation();
              onClickSecondary();
            }}
          >
            {t('global.distribution_list.download_template')}
          </Button>

          <UploadFileButton
            buttonLabel={t('global.distribution_list.import_distribution')}
            isDisabled={isUploadInProgress}
            icon={
              isUploadInProgress ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                <GetAppOutlinedIcon className="import" />
              )
            }
            onFileChange={onClick}
            acceptedFiles={ACCEPTED_EXCEL_TYPES}
            isMultiple={false}
          />
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ImportDistributionDialog;
