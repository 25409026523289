import React, { FC } from 'react';
import { Box, Typography, LinearProgress, BoxProps } from '@material-ui/core';
import colors from 'styles/colors.module.scss';
import { Variant } from '@material-ui/core/styles/createTypography';
import { ClassNames } from '@emotion/react';
import {
  linearProgressBarStyle,
  linearProgressBarThumbStyle,
} from './LinearProgressBar.style';

export interface LinearProgressBarProps {
  boxProps?: BoxProps;
  typographyVariant?: Variant;
  progressBarBackgroundColor?: string;
  progressBarColor: string;
  title?: string;
  isPercentToDisplay?: boolean;
  totalAchievement: number;
  progressAchievement: number;
}

const LinearProgressBar: FC<LinearProgressBarProps> = ({
  boxProps,
  typographyVariant,
  totalAchievement,
  progressAchievement,
  isPercentToDisplay,
  title,
  progressBarBackgroundColor,
  progressBarColor,
}) => {
  const percent = (progressAchievement * 100) / totalAchievement;
  const backgroundColor = progressBarBackgroundColor ?? colors.white;

  return (
    <Box {...boxProps} px={1}>
      {title && (
        <Box mb={0.5}>
          <Typography variant={typographyVariant}>{title}</Typography>
        </Box>
      )}
      <ClassNames>
        {({ css }) => (
          <LinearProgress
            css={linearProgressBarStyle(backgroundColor)}
            classes={{
              barColorPrimary: css`
                ${linearProgressBarThumbStyle(progressBarColor)}
              `,
            }}
            variant="determinate"
            value={percent}
          />
        )}
      </ClassNames>
      {isPercentToDisplay && (
        <Typography variant={typographyVariant}>{percent} %</Typography>
      )}
    </Box>
  );
};

export default LinearProgressBar;
