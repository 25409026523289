import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardMedia,
  Checkbox,
  createStyles,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import DownloadFileButton from 'components/DownloadFileButton/DownloadFileButton';
import FormWrapper from 'components/FormWrapper/FormWrapper';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GetAssets_getAssets_assets as Asset } from 'app/schemaInterfaces/GetAssets';
import { AssetInput, AssetStatus } from 'app/schemaInterfaces/globalTypes';
import StatusBadge, { BadgeStatus } from 'components/Badge/StatusBadge';
import { StatusSwitch } from 'components/Switch/StatusSwitch';
import { AssetDialog } from 'modules/dam/AssetDialog';
import { isAssetDisplayable } from 'modules/dam/helpers';
import colors from 'styles/colors.module.scss';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import 'modules/dam/AssetThumbnail.scss';
import { useDownloadFileErrorWorkflow } from 'app/utils/customHooks/useDownloadFileErrorWorkflow';

const DEFAULT_IMAGE_PREVIEW = `/${process.env.REACT_APP_ASSETS}/noPreviewImageSquare.png`;

export type AssetThumbnailProps = {
  asset: Asset;
  onAssetUpdate?: (id: string, asset: AssetInput, file: File | null) => void;
  onAssetSelect?: (asset: Asset, selected: boolean) => void;
  campaignAssetsIds?: string[];
  isCampaignAssetsPage?: boolean;
};

const useStyles = makeStyles<Theme, { assetStatusPosition: CSSProperties }>(
  () =>
    createStyles({
      root: {
        height: 312,
        position: 'relative',
      },
      media: {
        height: 312,
        backgroundSize: 'contain',
      },
      button: {
        variant: 'outlined',
        position: 'absolute',
        bottom: 20,
        right: 20,
      },
      assetStatus: ({ assetStatusPosition }) => ({
        position: 'absolute',
        top: 8,
        ...assetStatusPosition,
      }),
      conformityBadge: {
        position: 'absolute',
        top: 20,
        right: 20,
      },
      checkbox: {
        width: '40px',
        height: '40px',
      },
    })
);

export const AssetThumbnail = ({
  asset,
  onAssetUpdate,
  onAssetSelect,
  campaignAssetsIds,
  isCampaignAssetsPage,
}: AssetThumbnailProps): JSX.Element => {
  const assetStatusPosition =
    onAssetSelect === undefined ? { left: 10 } : { left: 53 };

  const { t } = useTranslation();

  const classes = useStyles({ assetStatusPosition });
  const [isHovered, setHover] = React.useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [openAssetDialog, setOpenAssetDialog] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const downloadFile = useDownloadFileErrorWorkflow({
    useS3CorsWorkaround: true,
  });

  const isImageSupported = isAssetDisplayable(asset.metadata.mimetype);
  const imageUrl =
    isImageSupported && asset.displayedThumbUrl
      ? asset.displayedThumbUrl
      : DEFAULT_IMAGE_PREVIEW;

  const handleFormValidation = async (
    updatedAsset: AssetInput,
    file: File | null
  ) => {
    handleCloseAssetDialog();
    updatedAsset.name = updatedAsset.name?.split(/\.(?=[^]+$)/)[0];
    onAssetUpdate?.(asset.id, updatedAsset, file);
  };

  const handleOpenAssetDialog = () => {
    setOpenAssetDialog(true);
  };

  const handleCloseAssetDialog = () => {
    setOpenAssetDialog(false);
  };

  const handleDownload = async (
    e: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    e.preventDefault();
    if (!isDownloading && asset.url) {
      setIsDownloading(true);
      downloadFile({
        url: asset.url,
        filename: asset.name ?? 'file',
        mimetype: asset.metadata.mimetype,
      }).finally(() => setIsDownloading(false));
    }
  };

  const toggleAssetsCheck = isCampaignAssetsPage
    ? campaignAssetsIds?.includes(asset.id)
    : isChecked;

  return (
    <Box>
      <Card
        className={classes.root}
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <CardActionArea onClick={handleOpenAssetDialog}>
          <CardMedia
            className={classes.media}
            image={imageUrl}
            title={asset.name ?? ''}
          />
        </CardActionArea>
        <CardActions>
          {onAssetSelect && (toggleAssetsCheck || isHovered) && (
            <Box
              position="absolute"
              top="0"
              left="0"
              width="40px"
              height="40px"
              bgcolor={colors.white}
              borderRadius="0px 0px 6px 0px"
            >
              <Checkbox
                className={classes.checkbox}
                checked={toggleAssetsCheck}
                onChange={() => {
                  onAssetSelect(asset, !isChecked);
                  setIsChecked(!isChecked);
                }}
              />
            </Box>
          )}
          {isHovered && asset.conform !== null && (
            <StatusBadge
              className={classes.conformityBadge}
              status={asset.conform ? BadgeStatus.Success : BadgeStatus.Error}
              message={
                asset.conform
                  ? t('dam.conformity.conform')
                  : t('dam.conformity.non_conform')
              }
            />
          )}
          {isHovered && (
            <>
              <StatusSwitch
                className={classes.assetStatus}
                status={asset.status === AssetStatus.Active}
                isEditable={false}
              />

              <DownloadFileButton
                className={classes.button}
                disabled={isDownloading}
                onDownload={handleDownload}
                isDownloading={isDownloading}
                variant={'outlined'}
              />
            </>
          )}
        </CardActions>
      </Card>
      <FormWrapper
        open={openAssetDialog}
        handleClose={handleCloseAssetDialog}
        title={asset.name ?? ''}
        maxWidth="md"
      >
        <AssetDialog
          id={asset.id}
          onFormValidation={handleFormValidation}
          isUpdateInDam={true}
        />
      </FormWrapper>
    </Box>
  );
};
