import { GetSupplyProducts_supplyProducts } from 'app/schemaInterfaces/GetSupplyProducts';
import { AttributConditionnementFilterInput } from 'app/schemaInterfaces/globalTypes';

export interface Conditionnement extends AttributConditionnementFilterInput {
  value: string;
}

export type SupplyProduct = Omit<
  GetSupplyProducts_supplyProducts,
  | '__typename'
  | 'conditionnement.__typename'
  | 'conditionnement.conditionnementList.firstConditionnement.__typename'
  | 'conditionnement.conditionnementList.secondConditionnement.__typename'
  | 'conditionnement.conditionnementList.threeConditionnement.__typename'
  | 'conditionnement.conditionnementList.__typename'
>;

export const NON_VALUE = 'None';
