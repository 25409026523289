import React, { FC } from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import BasketConfirmation from '../../BasketConfirmation/BasketConfirmation';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import SummaryAddressesRow from 'modules/sales/components/SummaryAddressesRow/SummaryAddressesRow';
import { BasketType } from 'app/schemaInterfaces/globalTypes';
import {
  basketConfirmationTitle,
  basketDateTag,
  firstBasketConfirmationTitle,
  getLastDate,
} from 'modules/sales/salesHelpers';
import BillingAddressBlock from 'modules/sales/components/BillingAddressBloc';
import AlertBox from 'components/AlertBox/AlertBox';
import { summaryStepStyle } from './SummaryStep.style';
import {
  useBasketSummaryStep,
  UseBasketSummaryStepData,
} from './SummaryStep.hooks';
import BasketConfirmationSummary from '../../BasketConfirmationSummary/BasketConfirmationSummary';

export interface SummaryStepProps extends UseBasketSummaryStepData {}

const SummaryStep: FC<SummaryStepProps> = ({
  setCommitmentNumber,
  setOrderConfirmationDetails,
}) => {
  const { t } = useTranslation();
  const {
    title,
    subTitle,
    basketType,
    campaignsWithDocuments,
    onSubmit,
    onCancel,
    basketDocuments,
    errors,
    isContinueDisabled,
    isLoading,
  } = useBasketSummaryStep({
    setCommitmentNumber,
    setOrderConfirmationDetails,
  });

  return (
    <div css={summaryStepStyle}>
      <Grid container spacing={4} className="billing-container">
        <Grid item xs={8}>
          <h1>{title}</h1>
          <p>{subTitle}</p>
          {basketType === BasketType.BigShop && (
            <Grid container item spacing={4} className="billing-box">
              <Grid item xs={6}>
                <BillingAddressBlock />
              </Grid>
            </Grid>
          )}

          {campaignsWithDocuments.map(
            ({ campaign, basketDocuments }, index) => (
              <div key={index}>
                <div className="campaign-header">
                  <div>
                    <h3>{campaign.name}</h3>
                    <span>
                      {t('sales.basket_page.medium_count', {
                        count: basketDocuments.length,
                      })}
                    </span>
                  </div>
                  <h5>
                    {`${basketDateTag(basketType, t)} : ${moment(
                      getLastDate(
                        basketDocuments.map(
                          (basketDocumentItem) =>
                            basketDocumentItem.basketDocument
                        )
                      )
                    ).format(' DD/MM/YYYY')}`}{' '}
                  </h5>
                </div>
                <div>
                  {basketDocuments.map((basketDocumentItem) => (
                    <div
                      key={basketDocumentItem.basketDocument.id}
                      className="summary-basket-row"
                    >
                      <SummaryAddressesRow
                        basketDocumentItem={basketDocumentItem}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )
          )}
        </Grid>
        <Grid item xs={4}>
          <BasketConfirmation
            title={basketConfirmationTitle(basketType, t)}
            firstTitle={firstBasketConfirmationTitle(basketType, t)}
            onFirstBtnClick={onSubmit}
            secondTitle={t('sales.basket_page.summary_page.continue_sales')}
            onSecondBtnClick={onCancel}
            length={basketDocuments.length}
            icon={
              isLoading ? (
                <CircularProgress size={20} />
              ) : (
                <ArrowForwardOutlinedIcon />
              )
            }
            isDisabled={isContinueDisabled || isLoading}
            dataBloc={
              <BasketConfirmationSummary basketDocuments={basketDocuments} />
            }
          />
          {errors.length > 0 && (
            <AlertBox
              variant="warning"
              header={errors.map((msg) => (
                <p>{msg}</p>
              ))}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default SummaryStep;
