import React, { useState } from 'react';
import { Switch, useLocation } from 'react-router-dom';
import ProtectedRoute from 'components/Navigation/ProtectedRoute/ProtectedRoute';
import { shopMenuItems, ShopRoute } from 'modules/shop/shopHelpers';
import Shop from 'modules/shop/Shop/Shop';
import AllCampaigns from 'modules/shop/pages/AllCampaigns/AllCampaigns';
import CampaignPage from 'modules/shop/pages/CampaignPage/CampaignPage';
import MenuToolBar from 'components/Navigation/MenuToolBar/MenuToolBar';
import PlanningPage from './pages/PlanningPage';
import CampaignSearchBar from 'modules/pro/campaigns/CampaignSearchBar';
import { useTranslation } from 'react-i18next';
import { CampaignFilterInput } from 'app/schemaInterfaces/globalTypes';
import useClientOffers from 'app/auth/useClientOffers';
import { ModuleKey } from 'app/routes/helper';
import { CampaignFilterPopover } from 'modules/pro/components/CampaignFilterPopover/CampaignFilterPopover';

const ShopModule = () => {
  const location = useLocation();
  const pathWithToolbar = [
    ShopRoute.Home,
    ShopRoute.AllLocalCampaigns,
    ShopRoute.AllNationalCampaigns,
    ShopRoute.Planning,
  ];
  const [filters, setFilters] = useState<CampaignFilterInput>({});
  const { t } = useTranslation();
  const { offerKeys } = useClientOffers();
  const hasToolBar = pathWithToolbar.includes(location.pathname as ShopRoute);

  const filteredShopMenuItems = shopMenuItems.filter((item) =>
    offerKeys?.includes(item.moduleKey)
  );

  return (
    <>
      {hasToolBar && (
        <MenuToolBar
          menuItems={filteredShopMenuItems}
          searchBar={
            <CampaignSearchBar
              placeholder={t(
                'global.national_campaigns.campaign_search_bar_placeholder'
              )}
              setFilters={setFilters}
              text={filters?.text}
            />
          }
          filter={
            <CampaignFilterPopover setFilters={setFilters} filters={filters} />
          }
        />
      )}
      <Switch>
        <ProtectedRoute exact path={ShopRoute.Home}>
          <Shop filters={filters} />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={ShopRoute.AllLocalCampaigns}
          offer={ModuleKey.AM_OP_Locales}
        >
          <AllCampaigns filters={filters} />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={ShopRoute.AllNationalCampaigns}
          offer={ModuleKey.AM_OP_Nationales}
        >
          <AllCampaigns filters={filters} />
        </ProtectedRoute>
        <ProtectedRoute exact path={ShopRoute.LocalCampaign} open={true}>
          <CampaignPage />
        </ProtectedRoute>
        <ProtectedRoute exact path={ShopRoute.NationalCampaign} open={true}>
          <CampaignPage />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={ShopRoute.Planning}
          offer={ModuleKey.AM_Planning}
        >
          <PlanningPage />
        </ProtectedRoute>
      </Switch>
    </>
  );
};

export default ShopModule;
