import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { DocumentFormValues } from '../model/definitions';

export const useDocumentCustomizationForm = () => {
  const { t } = useTranslation();

  const {
    setFieldValue,
    values: {
      document: { isCustomizable },
    },
    errors,
    touched,
  } = useFormikContext<DocumentFormValues>();

  const documentCustomizationOptions = [
    {
      label: t('global.not_customizable'),
      value: false,
      isChecked: !isCustomizable,
    },
    {
      label: t('global.customizable'),
      value: true,
      isChecked: isCustomizable,
    },
  ];

  const handleChange = (value: boolean) => {
    setFieldValue(`document.isCustomizable`, value);
  };

  return {
    documentCustomizationOptions,
    handleChange,
    errors,
    touched,
  };
};
