import { css } from '@emotion/react/macro';
import { Divider, FormLabel, Grid } from '@material-ui/core';
import { GetSupports_supports as Support } from 'app/schemaInterfaces/GetSupports';
import {
  CampaignType,
  DocumentCreateInput,
  TemplateType,
} from 'app/schemaInterfaces/globalTypes';
import { Maybe } from 'app/utils/common';
import Loader from 'components/Loader/Loader/Loader';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import EntitiesField from 'modules/oneManager/entities/form/EntitiesField';
import DocumentFormHeader from 'modules/pro/documents/form/DocumentFormHeader';
import DocumentOrderTypeSubform from 'modules/pro/documents/form/DocumentOrderTypeSubform';
import DocumentProductSubform from 'modules/pro/documents/form/DocumentProductSubForm/DocumentProductSubform';
import DocumentSupportSubForm from 'modules/pro/documents/form/DocumentSupportSubForm';
import { DocumentFormValues } from 'modules/pro/documents/form/model/definitions';
import { getDocumentFormSchema } from 'modules/pro/documents/form/validation/schema';
import useSupportsQuery from 'queries/useSupportsQuery';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getInitialValues,
  mapToDocumentInput,
} from 'modules/pro/documents/form/model/mappers';
import useDocumentQuery from 'queries/useDocumentQuery';
import useBasketDocumentsQuery from 'queries/useBasketDocumentsQuery';
import colors from 'styles/colors.module.scss';
import { InfoOutlined } from '@material-ui/icons';
import { FAMILY_ORDERS } from './form.config';
import useDocumentsCommitmentsQuery from 'queries/useDocumentsCommitmentsQuery';
import DocumentOptionsForm from './DocumentOptionsForm';
import {
  GetEntities,
  GetEntitiesVariables,
  GetEntities_entities,
} from 'app/schemaInterfaces/GetEntities';
import DocumentSummary from './DocumentSummary';
import { GetCampaignById_getCampaignById } from 'app/schemaInterfaces/GetCampaignById';
import { useQuery } from '@apollo/client';
import { GET_ENTITIES } from 'app/graphql/queries/entities';
import { BigShopConfigCategory } from 'app/schemaInterfaces/globalTypes';
import DocumentTemplateSubform from './DocumentTemplateSubform/DocumentTemplateSubform';
import DocumentQuantitiesSubform from './DocumentQuantitiesSubform';
import DocumentCustomizationForm from './DocumentCustomizationForm/DocumentCustomizationForm';

//TODO: replace this and use STOCK information from OS
export const nonCustomizableCategories: BigShopConfigCategory[] = [
  BigShopConfigCategory.AdvertisingItems,
];

const style = css`
  padding-bottom: 60px;
  .form-container {
    margin-top: 40px;
    margin-left: 200px;
    margin-right: 200px;
    display: flex;
    justify-content: space-evenly;
    .product-informations {
      margin-left: 40px;
    }
    .grid-blocs {
      display: flex;
      justify-content: space-between;
    }
    .initial-values {
      background-color: ${colors.documentsHeaderBackground};
      border: 2px solid ${colors.buttonGroupOutlined};
      padding: 32px 24px;
      margin-top: 20px;
      position: sticky;
      top: 120px;
      .custumizable-tag {
        margin-top: 8px;
        margin-bottom: 8px;
      }
      .label {
        color: ${colors.documentsTab};
        font-weight: bold;
      }
      .value {
        color: ${colors.documentsTab};
      }
    }
  }
  .field {
    display: flex;
    flex-direction: column;
    min-height: 40px;
    margin-bottom: 4px;
    margin-top: 20px;
    width: 350px;
  }
  .field-date {
    display: flex;
    flex-direction: column;
    min-height: 40px;
    margin-bottom: 4px;
    margin-top: 20px;
  }
  .field-group {
    display: flex;
    .field {
      margin-right: 38px;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: fit-content;
    }
  }
  .divider {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .last-divider {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .not-editable--info {
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
    background-color: ${colors.white};
    color: ${colors.grey};
    min-height: 74px;
    border-left: solid 6px ${colors.selectedCheckboxBorderColor};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 15px;
    padding-bottom: 15px;

    .icon {
      padding-bottom: 16px;
      color: ${colors.selectedCheckboxBorderColor};
      padding-right: 20px;
    }

    .snackbar-box {
      display: flex;
      padding-left: 20px;

      .message {
        display: flex;
        flex-direction: column;
        justify-content: center;
        p {
          font-size: 12px;
          line-height: 1.2rem;
        }
        h5 {
          font-weight: 400;
          padding-bottom: 5px;
        }
      }
    }

    .MuiSnackbar-anchorOriginTopRigh {
      justify-content: flex-start;
    }
  }

  .recap-attribute--container {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .recap-attribute--container:last-child {
    border-bottom: none;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .text-field {
    width: 350px;
    min-height: 40px;
    margin-bottom: 4px;
    margin-top: 18px;
  }

  .text-field--fullwidth {
    min-height: 40px;
    margin-top: 18px;
    width: 350px;
  }
`;

interface DocumentFormProps {
  campaignId: string;
  campaign: GetCampaignById_getCampaignById | undefined;
  documentId: string;
  onSubmit: (document: DocumentCreateInput, file?: File | null) => void;
}

const DocumentForm = ({
  campaign,
  campaignId,
  documentId,
  onSubmit,
}: DocumentFormProps) => {
  const { t } = useTranslation();

  const { data, loading, error } = useSupportsQuery();

  const [selectedSupport, setSelectedSupport] = useState<Maybe<Support>>(null);

  const { data: documentData, loading: documentLoading } = useDocumentQuery(
    documentId
  );

  const { data: entitiesData, loading: entitiesDataLoading } = useQuery<
    GetEntities,
    GetEntitiesVariables
  >(GET_ENTITIES);

  const {
    data: basketDocumentsData,
    loading: loadingBasketDocuments,
  } = useBasketDocumentsQuery({
    filter: {
      documentIds: documentId ? [documentId] : [],
    },
  });

  const [lowestQuantity, setLowestQuantity] = useState<number>(1);

  const documentIds: string[][] = documentData?.document
    ? [
        [
          documentData?.document?.id,
          ...documentData?.document.versionChildren.map((child) => child.id),
        ],
      ]
    : [];

  const { data: documentsCommitments } = useDocumentsCommitmentsQuery({
    documentIds,
  });

  // Filter unused families and hidden attributes and sort attributes
  const attributes = useMemo(
    () =>
      selectedSupport?.supportAttributes
        ?.filter(({ family }) => FAMILY_ORDERS[family])
        .sort((attribute1, attribute2) =>
          attribute1.family !== attribute2.family
            ? FAMILY_ORDERS[attribute1.family] -
              FAMILY_ORDERS[attribute2.family]
            : (attribute1?.index ?? 0) - (attribute2?.index ?? 0)
        ) ?? [],
    [selectedSupport?.supportAttributes]
  );

  const isChili =
    documentData && documentData?.document.template.type === TemplateType.CHILI;

  //  if document in commitments or orders or basket we disable the form
  // TODO add check if in orders
  const isDisabledForm = () =>
    Boolean(
      documentsCommitments?.length && documentsCommitments[0].commitments.length
    ) || Boolean(basketDocumentsData?.basket.basketDocuments.length);

  if (error || (data?.supports && !data.supports.length))
    return (
      <p>
        There are no supports attached to this client or the big shop config is
        missing
      </p>
    );

  const handleSubmit = async (values: DocumentFormValues) => {
    onSubmit(mapToDocumentInput(attributes, values), values.file);
  };

  const isThumbnailUrl = documentData?.document.thumbnailUrl ? true : false;

  return (
    <div css={style}>
      {loading ||
      !data?.supports ||
      documentLoading ||
      entitiesDataLoading ||
      loadingBasketDocuments ? (
        <Loader />
      ) : (
        <div className="bloc">
          <Formik<DocumentFormValues>
            initialValues={getInitialValues(
              campaignId,
              entitiesData?.entities as GetEntities_entities[],
              documentData?.document,
              campaign
            )}
            validationSchema={getDocumentFormSchema(
              t,
              selectedSupport,
              lowestQuantity,
              nonCustomizableCategories,
              isThumbnailUrl,
              campaign?.campaignType as CampaignType
            )}
            onSubmit={handleSubmit}
          >
            {({ values }) => {
              return (
                <Form>
                  <DocumentFormHeader isDisabledForm={isDisabledForm} />

                  <div className="form-container">
                    <Grid container className="grid-blocs">
                      <Grid item xs={8}>
                        {isDisabledForm() && (
                          <div className="field not-editable--info">
                            <div className="snackbar-box">
                              <div className="icon">
                                <InfoOutlined />
                              </div>
                              <div className="message">
                                <h5>
                                  {t('global.document_form.cannot_edit_title')}
                                </h5>
                                <p>
                                  {t(
                                    'global.document_form.cannot_edit_description'
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                        {/* Campaign name */}
                        <div className="field">
                          <FormLabel>
                            {t('global.document_form.campaign_name')}
                          </FormLabel>
                          <Field
                            component={TextField}
                            className="text-field"
                            name="document.campaignName"
                            variant="outlined"
                            margin="dense"
                            disabled
                          />
                        </div>
                        {/* Document name */}
                        <div className="field">
                          <FormLabel>
                            {t('global.document_form.name')} *
                          </FormLabel>
                          <Field
                            component={TextField}
                            className="text-field"
                            name="document.name"
                            variant="outlined"
                            margin="dense"
                            disabled={isDisabledForm()}
                          />
                        </div>
                        {/*	Document version name*/}
                        <div className="field">
                          <FormLabel>
                            {t('global.document_form.version')} *
                          </FormLabel>
                          <Field
                            component={TextField}
                            className="text-field"
                            name="document.version"
                            variant="outlined"
                            margin="dense"
                            disabled={isDisabledForm()}
                          />
                        </div>

                        {/* Entities field */}
                        {/* Dynamic entities fields given a clientId */}
                        <Grid item xs={12}>
                          <EntitiesField
                            name="document.entities"
                            textFieldClassName="text-field"
                            entitiesToFilter={campaign?.entities.map(
                              ({ id }) => id
                            )}
                          />
                        </Grid>
                        <Divider className="divider" />
                        {/* Support Sub form that allows support selection based on category and name */}
                        <DocumentSupportSubForm
                          supports={data.supports}
                          onSupportSelected={(support) =>
                            setSelectedSupport(support)
                          }
                          isDisabled={
                            isDisabledForm() || (documentData && !isChili)
                          }
                          isClearDisabled={
                            isDisabledForm() || (documentData && !isChili)
                          }
                        />
                        {selectedSupport && (
                          <div className="product-informations">
                            <DocumentProductSubform
                              support={selectedSupport}
                              currentDocument={documentData?.document}
                              attributes={attributes}
                              isDisabledForm={isDisabledForm()}
                            />
                          </div>
                        )}
                        {selectedSupport && values.document.productId && (
                          <>
                            <div className="field">
                              <DocumentQuantitiesSubform
                                setLowestQuantity={setLowestQuantity}
                              />
                            </div>
                            <Divider className="divider" />
                          </>
                        )}
                        <DocumentOptionsForm />
                        <DocumentCustomizationForm />
                        {values.document.isCustomizable && (
                          <DocumentTemplateSubform />
                        )}
                        <Divider className="divider" />
                        <DocumentOrderTypeSubform
                          documentId={documentData?.document.id}
                          campaignType={campaign?.campaignType as CampaignType}
                          campaignDateFrom={campaign?.dateFrom}
                          campaignDateTo={campaign?.dateTo}
                          campaignCommitmentDateFrom={
                            campaign?.commitmentDateFrom
                          }
                          campaignCommitmentDateTo={campaign?.commitmentDateTo}
                        />
                      </Grid>
                      <DocumentSummary
                        imageUrl={documentData?.document.thumbnailUrl}
                        attributes={attributes}
                      />
                    </Grid>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      )}
    </div>
  );
};

export default DocumentForm;
