import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const orderCampaignsWithDocumentsStyle = css`
  .order-campaign-container {
    padding-left: 84px;
    padding-right: 84px;
  }
  .billing-container {
    margin-top: 36px;
  }
  .campaign-header {
    background-color: ${colors.workzone};
    border: 1px solid ${colors.buttonGroupOutlined};
    border-left: none;
    border-right: none;
    padding: 29px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 16px;
    margin-top: 74px;
    h5 {
      height: fit-content;
      color: ${colors.documentsTab};
      border-radius: 25px;
      padding: 5px 10px;
      font-weight: 500;
    }
  }
  .archived-tag {
    background: ${'rgba(254, 200, 0, 0.1)'};
    border-radius: 26px;
    color: ${colors.orange300};
    font-family: ScandiaWebMedium;
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    overflow: hidden;
    padding: 5px 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: fit-content;
    cursor: pointer;
  }
  .social_network_url {
    .align_vertical {
      span:nth-of-type(even) {
        padding: 3px 0 0 5px;
      }
    }
    .link {
      span:nth-of-type(even) {
        color: ${colors.blue500};
        text-decoration: underline;
        cursor: pointer;
        display: inline-block;
        margin-bottom: 8px;
      }
      span:nth-of-type(odd) {
        color: ${colors.grey};
        font-weight: 900;
      }
    }
    .publication_date {
      span {
        color: ${colors.grey};
      }
    }
  }
  .delivery-row {
    display: flex;
    flex-direction: column;
    .content {
      padding: 24px;
      justify-content: start;
    }
    .document-row {
      padding: 36px 0;
    }
    .document-row .media-wrapper.delivery {
      height: 140px;
      width: 150px;
      max-width: 150px;

      img {
        padding: 4%;
        align-self: center;
      }
    }
  }
  h3 {
    display: inline-block;

    & + span {
      color: ${colors.grey};
      border-left: 1px solid ${colors.inputLabelColor};
      font-family: 'ScandiaWebMedium';
      font-weight: 500;
      margin-left: 16px;
      padding: 0 16px;
    }
  }
  .dropdown {
    color: ${colors.white};
    background-color: ${colors.grey};
    border-radius: 50%;
  }
  .header-wrapper {
    display: flex;
    align-items: center;
  }
  .total-price {
    padding-right: 32px;
  }
  .tag-wrapper-error {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .MuiTypography-root {
      color: ${colors.fushia};
      font-weight: 800;
      margin-right: 10px;
    }
    .MuiButtonBase-root {
      color: ${colors.blue500} !important;
      border: 1px solid ${colors.blue500};
      min-width: 150px;
      width: auto;
      transition: background 1s ease-in-out;

      &:hover {
        background: ${colors.blue300};
      }
    }
  }
`;
