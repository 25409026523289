import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const headerRootStyle = css`
  background-color: ${colors.documentsHeaderBackground};
`;

export const headerContainerStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 20px 200px;
`;
export const headerButtonGroupStyle = css`
  display: flex;
`;

export const headerConfirmButtonStyle = css`
  padding: 0 16px;
`;
