import React from 'react';
import { IProductTemplate } from 'modules/workflow/Clients/ToutFaire/ProductItem';
import { TypographyIdentities } from 'modules/workflow/oneStudioContext';
import PriceTagElementContainer from 'modules/workflow/Clients/ToutFaire/components/price/PriceTagElementContainer';
import useAppContext from 'app/app-context/useAppContext';
import { getUnifiedKeyFromPimClientConfig } from 'modules/pim/baseProducts/ProductsHelper';
import { UnifiedProductKeyToRenderOnPage } from 'modules/workflow/containers/Leftsidebar/ProductsPanel/ProductsView';
import { css } from '@emotion/react/macro';
import { useTranslation } from 'react-i18next';
import { getPriceLabels } from 'modules/workflow/containers/Leftsidebar/ProductsPanel/helpers/visualIdentitiesHelper';

interface ProductPriceProps {
  product: IProductTemplate;
  priceLabels?: TypographyIdentities;
  priceOptionalUnity?: TypographyIdentities;
  isCompaniesPrice?: boolean;
}

const ProductPriceLabels = ({
  product,
  priceLabels,
  priceOptionalUnity,
  isCompaniesPrice,
}: ProductPriceProps): JSX.Element => {
  const {
    client: { config: clientConfig },
  } = useAppContext();

  const { t } = useTranslation();

  const firstPart = t('workflow.typography_item.price_label');
  const price = product.productDetails[
    getUnifiedKeyFromPimClientConfig(
      UnifiedProductKeyToRenderOnPage.pricelabelwithtaxes,
      clientConfig
    )
  ]?.replace(/,/g, '.');

  const style = css`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: baseline;
    position: relative;
  `;

  const labelWithoutTaxes = product.productDetails[
    getUnifiedKeyFromPimClientConfig(
      UnifiedProductKeyToRenderOnPage.pricelabelwithouttaxes,
      clientConfig
    )
  ]?.toString();

  return (
    //TODO : check if condition is needed
    <div css={priceOptionalUnity && !isCompaniesPrice ? style : undefined}>
      {priceLabels && (
        <PriceTagElementContainer
          product={product}
          priceTagElement={priceLabels}
          value={getPriceLabels(
            firstPart,
            Boolean(isCompaniesPrice),
            price,
            labelWithoutTaxes,
            t
          )}
          textAlign="center"
        />
      )}
    </div>
  );
};

export default ProductPriceLabels;
