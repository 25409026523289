import { Breadcrumbs, createStyles, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ChevronRight } from '@material-ui/icons';
import React from 'react';
import colors from 'styles/colors.module.scss';
import { truncateString } from 'app/utils/common';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .MuiBreadcrumbs-separator': {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    breadcrumbText: {
      color: colors.inputLabelColor,
    },
    breadcrumbLink: {
      cursor: 'pointer',
    },
  })
);

export interface BreadcrumbsLink {
  label: string;
  name: string; // unique, use as a list item key
  selected?: boolean;
}

interface BreadcrumbsProps {
  links: BreadcrumbsLink[];
  onClick?: (link: BreadcrumbsLink) => void;
}

const BigBreadcrumbs = ({ links, onClick }: BreadcrumbsProps): JSX.Element => {
  const classes = useStyles();

  const selectedLinkIndex = links.findIndex((link) => link.selected);
  const displayableLinks =
    selectedLinkIndex !== -1 ? links.slice(0, selectedLinkIndex + 1) : links;

  const handleClick = (link: BreadcrumbsLink) => {
    if (!link.selected) {
      onClick?.(link);
    }
  };

  return (
    <Breadcrumbs
      className={classes.root}
      separator={<ChevronRight />}
      aria-label="breadcrumb"
    >
      {displayableLinks.map((link) => (
        <Typography
          key={link.name}
          className={`${classes.breadcrumbText} ${
            !link.selected ? classes.breadcrumbLink : ''
          }`}
          onClick={() => handleClick(link)}
          color="textPrimary"
          title={link.label}
        >
          {truncateString(link.label, 20)}
        </Typography>
      ))}
    </Breadcrumbs>
  );
};

export default BigBreadcrumbs;
