import React, { useReducer, useState, useEffect } from 'react';
import { useSticky } from 'components/sticky/useSticky';
import { StickyBox } from 'components/sticky/StickyBox';
import {
  filtersReducer,
  initialState,
} from 'modules/dam/reducers/filters/reducer';
import useAssetQuery from 'modules/dam/hooks/useAssetQuery';
import { useAssetMutate } from 'modules/dam/hooks/useAssetMutate';
import {
  AssetFiltersInput,
  AssetInput,
} from 'app/schemaInterfaces/globalTypes';
import { AssetFiltersContext } from 'modules/dam/reducers/filters/context';
import { Box, Button, Container, Typography } from '@material-ui/core';
import colors from 'styles/colors.module.scss';
import HeaderList from 'components/HeaderList/HeaderList';
import { AssetSearch } from 'modules/dam/filters/AssetSearch';
import DamToolbar from 'modules/dam/toolbar/DamToolbar';
import { AssetList } from 'modules/dam/AssetList';
import UploadProgressSnackbar from 'components/UploadProgressSnackbar';
import SelectedAssetsToolbar from 'modules/dam/toolbar/SelectedAssetsToolbar/SelectedAssetsToolbar';
import { GetAssets_getAssets_assets } from 'app/schemaInterfaces/GetAssets';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from 'modules/pro/medias/utils/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { Variant } from '@material-ui/core/styles/createTypography';
import useCurrentEntity from 'app/current-entity-context/useCurrentEntity';
import { assetsFiltersVar } from 'modules/pro/documents/reactiveVars';

interface DamWrapperProps {
  isImportDamToCampaign?: boolean;
  title: string;
  variant?: Variant | 'inherit';
  headerSize?: number;
}

export const DamWrapper = ({
  isImportDamToCampaign = false,
  title,
  variant = 'inherit',
  headerSize = 180,
}: DamWrapperProps) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { currentEntity } = useCurrentEntity();

  const [{ sort, filters }, dispatch] = useReducer(
    filtersReducer,
    initialState
  );

  const {
    addMultipleCampaignAssets,
    handleCloseCampaignForm,
    campaignAssets,
    handleSelectedAssets,
  } = useMediaQuery();

  const { assets, count, loadMore, refetch, error, loading } = useAssetQuery({
    sort,
    filters: { ...filters, entityId: currentEntity?.id },
  });

  const {
    createAsset,
    updateAsset,
    importMultipleAssets,
    uploadProgress,
    clearProgress,
  } = useAssetMutate({ reportProgress: true });

  const [stickyRef, isSticky] = useSticky();
  const [selectedAssets, setSelectedAssets] = useState<
    GetAssets_getAssets_assets[]
  >([]);

  useEffect(() => {
    // set filters in apollo store
    assetsFiltersVar({
      filters: { ...filters, entityId: currentEntity?.id } as AssetFiltersInput,
    });
  }, [filters, currentEntity]);

  const handleCreate = async (asset: AssetInput, file: File) => {
    await createAsset(asset, file);
    refetch();
  };

  const handleUpdate = async (
    id: string,
    asset: AssetInput,
    file: File | null
  ) => {
    await updateAsset(id, asset, file);
  };

  const handleSelectedAsset = (
    asset: GetAssets_getAssets_assets,
    selected: boolean
  ) => {
    if (isImportDamToCampaign) {
      handleSelectedAssets(asset.id);
    } else {
      if (selected) {
        setSelectedAssets([...selectedAssets, asset]);
      } else {
        const assetSelectedListCopy = [...selectedAssets];
        assetSelectedListCopy.splice(selectedAssets.indexOf(asset), 1);
        setSelectedAssets(assetSelectedListCopy);
      }
    }
  };

  const handleAssetsImport = async (asset: AssetInput, files: File[]) => {
    await importMultipleAssets(asset, files);
    refetch();
  };

  const selectedAssetsToolbar = (
    <SelectedAssetsToolbar
      count={
        isImportDamToCampaign ? campaignAssets.length : selectedAssets.length
      }
      assets={selectedAssets}
      isAssetsDownloadable={!isImportDamToCampaign}
      refetch={refetch}
    />
  );

  return (
    <AssetFiltersContext.Provider value={dispatch}>
      <Box bgcolor={colors.white} minHeight="100vh">
        <Box position="relative">
          <HeaderList
            bgcolor={colors.workzone}
            height={headerSize}
            backgroundImageFileName={
              !isImportDamToCampaign ? 'headerlistprostore.png' : undefined
            }
          >
            <Box
              ml={isImportDamToCampaign ? 2 : 'initial'}
              mr={isImportDamToCampaign ? 2 : 'initial'}
              display="flex"
              flexGrow={1}
              justifyContent={
                isImportDamToCampaign ? 'space-between' : 'center'
              }
              alignItems="center"
              flexDirection={isImportDamToCampaign ? 'row' : 'column'}
              color="white"
            >
              <Box
                mb={2}
                pt={isImportDamToCampaign ? 2 : 'initial'}
                color={isImportDamToCampaign ? colors.black : 'inherit'}
              >
                <Typography variant={variant}>{title}</Typography>
              </Box>
              {!isImportDamToCampaign && (
                <Box width="450px">
                  <AssetSearch hasBorder={false} text={filters.text} />
                </Box>
              )}

              {isImportDamToCampaign && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box>
                    <Button
                      color="primary"
                      style={{ marginRight: 16 }}
                      onClick={handleCloseCampaignForm}
                    >
                      {t('dam.cancel_assets')}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => addMultipleCampaignAssets(id)}
                    >
                      {t('dam.add_assets')}
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          </HeaderList>
        </Box>
        <StickyBox isSticky={isSticky} ref={stickyRef} mb={3}>
          <DamToolbar
            count={count}
            filters={filters}
            sort={sort}
            onAssetCreate={handleCreate}
            onAssetsImport={handleAssetsImport}
            isSticky={isSticky}
            isAddAssets={!isImportDamToCampaign}
          />
          {isSticky &&
            (selectedAssets.length > 0 || campaignAssets.length > 0) &&
            selectedAssetsToolbar}
        </StickyBox>
        <Container>
          {!isSticky &&
            (selectedAssets.length > 0 || campaignAssets.length > 0) &&
            selectedAssetsToolbar}
          <AssetList
            assets={assets}
            error={error}
            loading={loading}
            onLoadMore={loadMore}
            onAssetUpdate={handleUpdate}
            campaignAssetsIds={campaignAssets}
            isCampaignAssetsPage={isImportDamToCampaign}
            onAssetSelect={handleSelectedAsset}
            selectedAssetType={filters.assetTypes}
          />
        </Container>
      </Box>
      <UploadProgressSnackbar
        open={uploadProgress.length > 0}
        defaultExpanded={true}
        uploads={uploadProgress}
        onClose={() => clearProgress()}
      />
    </AssetFiltersContext.Provider>
  );
};
