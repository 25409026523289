import { TFunction } from 'i18next';
import {
  AssetInput,
  AssetType,
  CharterType,
  VisualType,
} from 'app/schemaInterfaces/globalTypes';
import { processDpi, processMaxSurfaceSize } from 'app/utils/math';

export const ACCEPTED_IMAGE_TYPES = [
  'image/tiff',
  'image/png',
  'image/jpg',
  'image/jpeg',
];
export const ACCEPTED_PHOTOSHOP_TYPES = [
  'application/x-photoshop',
  'application/photoshop',
  'application/psd',
  'image/psd',
  'image/vnd.adobe.photoshop',
  'zz-application/zz-winassoc-psd',
  '.psd',
];

export const ACCEPTED_WORD_TYPES = [
  'application/msword',
  'application/docx',
  'application/doc',
  'application/vnd.ms-word.document.macroEnabled.12',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

export const ACCEPTED_EXCEL_TYPES = [
  'application/vnd.ms-excel',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
  'application/msexcel',
  'application/x-msexcel',
  'application/x-ms-excel',
  'application/x-excel',
  'application/x-dos_ms_excel',
  'application/xls',
  'application/x-xls',
  'application/x-msi',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const ACCEPTED_PPT_TYPES = [
  'application/vnd.ms-powerpoint',
  'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
];

export const ACCEPTED_PDF_TYPES = 'application/pdf';

export const ACCEPTED_POSTSCRIPT_TYPES = 'application/postscript';

export const ACCEPTED_FORMATS = [
  ...ACCEPTED_IMAGE_TYPES,
  ...ACCEPTED_PHOTOSHOP_TYPES,
  ACCEPTED_PDF_TYPES,
  ACCEPTED_POSTSCRIPT_TYPES,
];

export const NO_THUMBNAILS_MEDIAS = [
  ACCEPTED_PDF_TYPES,
  ...ACCEPTED_PHOTOSHOP_TYPES,
];

export const getVisualTypeOptions = (t: TFunction) => [
  { value: VisualType.Packshot, label: t('dam.visual_types.packshot') },
  {
    value: VisualType.FlagshipProduct,
    label: t('dam.visual_types.flagship_product'),
  },
  { value: VisualType.Ambiance, label: t('dam.visual_types.ambiance') },
];

export const getCharterTypeOptions = (t: TFunction) => [
  {
    value: CharterType.PageBackground,
    label: t('dam.charter_types.pagebackground'),
  },
  {
    value: CharterType.FamilyBackground,
    label: t('dam.charter_types.familybackground'),
  },
];

export const getAssetTypeOptions = (t: TFunction) => [
  { value: AssetType.Visual, label: t('dam.asset_types.visual') },
  { value: AssetType.Logo, label: t('dam.asset_types.logo') },
  { value: AssetType.Picto, label: t('dam.asset_types.picto') },
  { value: AssetType.Media, label: t('dam.asset_types.media') },
  { value: AssetType.Charter, label: t('dam.asset_types.charter') },
];

const CONFORMITY_SURFACE_SIZES = {
  [AssetType.Visual]: {
    [VisualType.Packshot]: 4,
    [VisualType.Ambiance]: 8,
    [VisualType.FlagshipProduct]: 6,
  },
  [AssetType.Logo]: 2,
  [AssetType.Picto]: 2,
  [AssetType.Charter]: 8.46,
};

export const processAssetDpiAndMaxSurface = (
  width: number | null = 0,
  height: number | null = 0,
  assetType?: AssetType | null,
  visualType?: VisualType | null
) => {
  let surfaceSize: number | undefined = undefined;

  if (!width && !height) {
    return null;
  }

  switch (assetType) {
    case AssetType.Visual:
      if (!visualType) {
        return null;
      }
      surfaceSize = CONFORMITY_SURFACE_SIZES[AssetType.Visual][visualType];
      break;
    case AssetType.Logo:
    case AssetType.Charter:
    case AssetType.Picto:
      surfaceSize = CONFORMITY_SURFACE_SIZES[assetType];
      break;
    default:
      return null;
  }

  const pixelSize = Math.min(width ?? 0, height ?? 0);
  const dpi = processDpi(pixelSize, surfaceSize);
  return {
    dpi,
    maxSurfaceSize: processMaxSurfaceSize(pixelSize),
  };
};

export const processConformity = (
  assetInput: Pick<AssetInput, 'assetType' | 'visualType' | 'metadata'>
) => {
  const qualityAttributes = processAssetDpiAndMaxSurface(
    assetInput.metadata.dimensions?.width,
    assetInput.metadata.dimensions?.height,
    assetInput.assetType,
    assetInput.visualType
  );

  return {
    dpi: qualityAttributes?.dpi,
    maxSurfaceSize: qualityAttributes?.maxSurfaceSize,
    conform: qualityAttributes ? qualityAttributes.dpi >= 300 : null,
  };
};
