import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const basketDeliveryTableStyle = css`
  .header {
    background-color: ${colors.documentsHeaderBackground};
    margin: auto;
    width: 100%;
    padding: 8px 24px;
    height: 43px;
  }
  .content {
    margin: auto;
    width: 100%;
    padding: 16px 24px;
  }

  .table-row {
    align-items: center;
    .MuiTextField-root {
      margin: 0;
    }
    input[type='number'] {
      text-align: center;
    }
  }
  h5 {
    font-weight: 500;
    color: ${colors.grey};
  }
`;
