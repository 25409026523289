import { css } from '@emotion/react';

export const followOrderControlsStyle = css`
  margin-top: 38px;
  margin-right: 84px;
  margin-left: 84px;
  .table-container {
    margin-top: 32px;
  }
`;
