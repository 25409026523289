import React from 'react';
import { css } from '@emotion/react/macro';
import {
  useStudioDispatchContext,
  useStudioStateContext,
} from 'modules/workflow/oneStudioContext';
import useCurrentEntity from 'app/current-entity-context/useCurrentEntity';
import CardList from 'components/CardList/CardList';
import { DEFAULT_IMAGE_PREVIEW } from 'app/utils/common';
import useAddressBlocksQuery from 'queries/useAddressBlocksQuery';
import { AscDescType, DimensionsInput } from 'app/schemaInterfaces/globalTypes';
import { studioAppActions } from 'modules/workflow/reducers/actionsInterfaces';
import { DraggedItemTypes } from 'modules/workflow/containers/CatalogPreview/EditPlatformDefinitions';
import { AssetType } from 'app/schemaInterfaces/globalTypes';
import { setDraggedAsset } from 'modules/workflow/containers/Leftsidebar/AssetsPanel/assetHelper';
import CircularProgressLoader from 'components/Loader/CircularProgressLoader/CircularProgressLoader';
import colors from 'styles/colors.module.scss';

interface DisplayedAddressBlock {
  id: string;
  name: string;
  image: string;
  size: DimensionsInput;
  assetId: string;
}
const style = css`
  display: flex;
  flex-direction: column;
  padding: 16px;
  height: 100vh;
  overflow: scroll;
  .loader {
    color: ${colors.white};
  }
`;

const FilesPanel = () => {
  const { document } = useStudioStateContext();
  const dispatch = useStudioDispatchContext();
  const globalState = useStudioStateContext();

  const { currentEntity } = useCurrentEntity();

  const currentEntityId = currentEntity?.id as string;

  const { data: addressBlockData, loading, error } = useAddressBlocksQuery({
    entityId: currentEntityId,
    sort: { updatedAt: AscDescType.desc },
  });

  const addressBlocks = addressBlockData?.addressBlocks;

  const filteredAddressBlocks = addressBlocks?.filter(
    (addressBlock) => addressBlock.supportId === document?.supportId
  );

  const items: DisplayedAddressBlock[] =
    filteredAddressBlocks?.map((addressBlock) => ({
      id: addressBlock.id,
      assetId: addressBlock.asset.id,
      name: addressBlock.name as string,
      image: addressBlock.asset.thumbnailUrl ?? DEFAULT_IMAGE_PREVIEW,
      size: {
        height: addressBlock.asset.metadata.dimensions?.height,
        width: addressBlock.asset.metadata.dimensions?.width ?? 0,
      },
    })) ?? [];

  const dragHandler = async (addressBlock: DisplayedAddressBlock) => {
    const name = addressBlock.name ?? addressBlock.id;
    const newAsset = setDraggedAsset(
      addressBlock.assetId,
      name,
      addressBlock.image,
      AssetType.AddressBlock,
      {
        height: addressBlock.size.height,
        width: addressBlock.size.width,
      }
    );
    dispatch(
      studioAppActions.setDraggedAsset({
        draggedItem: {
          item: newAsset,
          type: DraggedItemTypes.ASSET,
          subType: AssetType.AddressBlock,
        },
      })
    );
  };

  const isClickEnabled = (addressBlock: DisplayedAddressBlock) => {
    let isClickEnabled = true;
    globalState.version.pages.forEach((page) =>
      page.pageTemplate.forEach((zone) => {
        zone.personalizationItems?.forEach((personalizationItem) => {
          if (personalizationItem.content?.id === addressBlock.assetId) {
            isClickEnabled = false;
          }
        });
        zone.assets?.forEach((asset) => {
          if (asset.id === addressBlock.assetId) {
            isClickEnabled = false;
          }
        });
      })
    );
    return isClickEnabled;
  };

  return (
    <div css={style}>
      {loading ? (
        <CircularProgressLoader error={error} className="loader" />
      ) : (
        items && (
          <CardList
            onDrag={(_, addressBlock) => dragHandler(addressBlock)}
            items={items}
            isBackgroundDark={true}
            draggable={true}
            enableClick={isClickEnabled}
          />
        )
      )}
    </div>
  );
};

export default FilesPanel;
