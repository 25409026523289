import { useQuery, WatchQueryFetchPolicy } from '@apollo/client';
import {
  GetCampaignById,
  GetCampaignByIdVariables,
} from 'app/schemaInterfaces/GetCampaignById';
import { GET_CAMPAIGN_BY_ID } from 'app/graphql/queries/campaigns';

const useCampaignByIdQuery = (
  campaignId: string,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network'
) =>
  useQuery<GetCampaignById, GetCampaignByIdVariables>(GET_CAMPAIGN_BY_ID, {
    fetchPolicy,
    variables: {
      id: campaignId,
    },
  });

export default useCampaignByIdQuery;
