import { gql } from '@apollo/client';

export const GET_CLIENT_PRICES = gql`
  query getClientPrices($productId: String!, $minimum: Float, $maximum: Float) {
    prices(productId: $productId, minimum: $minimum, maximum: $maximum) {
      price
      isIncluded
      quantity
      productName
      unitPrice
    }
  }
`;

export const GET_PRICE_BY_QUANTITY = gql`
  query getPriceByQuantity($productId: String!, $quantity: Float!) {
    getPriceByQuantity(productId: $productId, quantity: $quantity) {
      productName
      quantity
      price
      isIncluded
    }
  }
`;

export const GET_UNIQUE_PRICE = gql`
  query getUniquePrice($productId: String!, $quantity: Float!) {
    uniquePrice(productId: $productId, quantity: $quantity) {
      transportSalesPriceExcludingVAT
    }
  }
`;

export const GET_MULTIPLE_UNIQUE_PRICES = gql`
  query getMultipleUniquePrices(
    $products: [MultipleUniquePricesProductInput!]!
  ) {
    multipleUniquePrices(products: $products) {
      uniquePrices {
        productSalesPriceExcludingVAT
        transportSalesPriceExcludingVAT
        productId
        quantity
        isIncluded
      }
      totalCost
      totalProductSalesPriceExcludingVAT
      totalTransportSalesPriceExcludingVAT
    }
  }
`;
