import { GetCampaignById_getCampaignById } from 'app/schemaInterfaces/GetCampaignById';
import { Entity } from 'modules/oneManager/entities/form/definitions';
import { CampaignFormModel } from 'modules/pro/campaigns/form/model/definitions';

export const mapToCampaignFormModel = (
  campaign?: GetCampaignById_getCampaignById,
  entities?: Entity[]
): CampaignFormModel => {
  return {
    name: campaign?.name ?? '',
    reference: campaign?.reference ?? '',
    description: campaign?.description ?? '',
    dateFrom: campaign?.dateFrom ?? null,
    dateTo: campaign?.dateTo ?? null,
    commitmentDateFrom: campaign?.commitmentDateFrom ?? null,
    commitmentDateTo: campaign?.commitmentDateTo ?? null,
    tags: campaign?.tags?.map(({ id, title }) => ({ id, title })) ?? [],
    category: campaign?.category?.id ?? '',
    fileUpload: null,
    imageId: campaign?.imageId,
    entities: campaign?.entities ?? entities ?? [],
  };
};
