import ConfirmationDialog from 'components/ConfirmationDialogs/ConfirmationDialog/ConfirmationDialog';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { headerStyle } from '../FollowOrderRefusalDialog/FollowOrderRefusalDialog.style';

export interface FollowOrderAcceptationDialogProps {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const FollowOrderAcceptationDialog: FC<FollowOrderAcceptationDialogProps> = ({
  isOpen,
  onCancel,
  onConfirm,
}) => {
  const { t } = useTranslation();

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      title={t('follow_orders.accept_order')}
      onClose={onCancel}
      actions={[
        {
          title: t('follow_orders.validation_dialog.cancel'),
          buttonVariant: 'text',
          onClick: onCancel,
          isKeptOpenOnClick: true,
        },
        {
          title: t('follow_orders.validation_dialog.confirm_acceptation'),
          onClick: onConfirm,
          isKeptOpenOnClick: true,
        },
      ]}
    >
      <h4 css={headerStyle}>
        {t('follow_orders.validation_dialog.subtitle_acceptation')}
      </h4>
      <p>
        {t('follow_orders.validation_dialog.irreversible_action')}
        <br />
        {t('follow_orders.validation_dialog.explanation_acceptation')}
      </p>
    </ConfirmationDialog>
  );
};

export default FollowOrderAcceptationDialog;
