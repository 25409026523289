import { useEffect, useRef, useState } from 'react';

export const useSticky = () => {
  const stickyRef = useRef<HTMLDivElement>(null);
  const [isSticky, setSticky] = useState(false);

  const observerRef = useRef(
    new IntersectionObserver(
      (entries) => {
        if (!entries[0].isIntersecting) {
          setSticky(true);
        } else {
          setSticky(false);
        }
      },
      {
        threshold: 1,
      }
    )
  );

  useEffect(() => {
    const observer = observerRef.current;
    const element = stickyRef.current;
    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, []);

  return [stickyRef, isSticky] as const;
};
