import React, { ChangeEvent } from 'react';
import { Popover, TextField, Button, Grid } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { useStudioStateContext } from 'modules/workflow/oneStudioContext';
import {
  ADD_COMMENT,
  GET_COMMENTS_BY_VERSION,
} from 'app/graphql/queries/comments';
import {
  AddCommentVariables,
  AddComment as AddCommentInterface,
} from 'app/schemaInterfaces/AddComment';
import { Comment } from 'modules/workflow/containers/CatalogPreview/SinglePaper';
interface AddCommentProps {
  comment: Comment;
  setComment: (__: (comment: Comment) => Comment) => void;
  pageId: string;
}

const AddComment = ({
  comment,
  setComment,
  pageId,
}: AddCommentProps): JSX.Element => {
  const globalState = useStudioStateContext();

  const [addComment] = useMutation<AddCommentInterface, AddCommentVariables>(
    ADD_COMMENT,
    {
      refetchQueries: [
        {
          query: GET_COMMENTS_BY_VERSION,
          variables: {
            versionId: globalState.version.id,
          },
        },
      ],
    }
  );

  const commentChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setComment((prevState) => {
      return {
        ...prevState,
        text: value,
      };
    });
  };
  const addCommenthandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const newComment = {
      clientPosition: comment.clientPosition,
      offsetPosition: comment.offsetPosition,
      text: comment.text,
      replies: [],
    };
    const variables = {
      versionId: globalState.version.id,
      pageId,
      comment: newComment,
    };
    addComment({ variables });

    closePopover();
  };

  const closePopover = () => {
    setComment((_) => {
      return {
        text: '',
        pageId: '',
        clientPosition: { x: -1, y: -1 },
        offsetPosition: { x: -1, y: -1 },
      };
    });
  };
  return (
    <Popover
      open={comment.clientPosition.x !== -1 && comment.clientPosition.y !== -1}
      anchorReference="anchorPosition"
      anchorPosition={{
        top: comment.clientPosition.y,
        left: comment.clientPosition.x,
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onBackdropClick={closePopover}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Grid container direction="column">
        <Grid item>
          <TextField
            placeholder="Commentaire"
            variant="outlined"
            onChange={commentChangeHandler}
            inputProps={{
              maxLength: 140,
            }}
            autoFocus
          />
        </Grid>
        <Grid container item justify="center">
          <Button onClick={closePopover}>Annuler</Button>
          <Button disabled={comment.text === ''} onClick={addCommenthandler}>
            Publier
          </Button>
        </Grid>
      </Grid>
    </Popover>
  );
};

export default AddComment;
