import ExifReader from 'exifreader';
import { readPsd } from 'ag-psd';
import { pdfjs } from 'react-pdf';
import { pxToCm, roundToDecimal } from '../math';

interface Dimension {
  width?: number;
  height?: number;
  widthInCm?: number;
  heightInCm?: number;
}

export interface ImageMetadata {
  dimensions?: Dimension;
  credit?: string;
}

interface PdfInformation {
  PDFFormatVersion: string;
  IsAcroFormPresent: boolean;
  IsXFAPresent: boolean;
  Author?: string;
  [key: string]: any;
}

const scale = 1;
/**
 * Supports: JPEG, TIFF, PNG, PSD, PDF, WebP, HEIC/HEIF
 */
export const readImageMetadata = async (file: File): Promise<ImageMetadata> => {
  return new Promise<ImageMetadata>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.result) {
        try {
          const tags = ExifReader.load(reader.result as ArrayBuffer, {
            expanded: true,
          });
          resolve({
            credit:
              tags.xmp?.Credit?.description ||
              tags.iptc?.Credit?.description ||
              tags.exif?.Credit?.description ||
              tags.exif?.Artist?.description ||
              tags.exif?.Copyright?.description,
            dimensions: {
              width:
                tags.file?.['Image Width']?.value ||
                tags.pngFile?.['Image Width']?.value ||
                tags.exif?.ImageWidth?.value ||
                tags.iptc?.ImageWidth?.value,
              height:
                tags.file?.['Image Height']?.value ||
                tags.pngFile?.['Image Height']?.value ||
                tags.exif?.ImageLength?.value ||
                tags.iptc?.ImageLength?.value,
            },
          });
        } catch (e) {
          reject(e);
        }
      } else reject();
    };

    reader.onerror = () => reject();

    reader.readAsArrayBuffer(file);
  });
};

const getPsdCredit = (xmpMetadata: string): string | undefined => {
  const regex = /(?<=Credit>)(.*)(?=<\/photoshop:Credit>)/g;
  const creditMatch = xmpMetadata.match(regex);
  return creditMatch ? creditMatch[0] : undefined;
};

export const readPsdMetadata = async (file: File): Promise<ImageMetadata> => {
  return new Promise<ImageMetadata>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.result) {
        try {
          const metadata = readPsd(reader.result as ArrayBuffer, {
            skipLayerImageData: true,
            skipCompositeImageData: true,
            skipThumbnail: true,
          });
          const dpiScaleHorizontal =
            metadata.imageResources?.resolutionInfo?.horizontalResolution;
          const dpiScaleVertical =
            metadata.imageResources?.resolutionInfo?.verticalResolution;
          resolve({
            dimensions: {
              width: metadata.width,
              height: metadata.height,
              widthInCm: dpiScaleHorizontal
                ? roundToDecimal(pxToCm(metadata.width, dpiScaleHorizontal))
                : undefined,
              heightInCm: dpiScaleVertical
                ? roundToDecimal(pxToCm(metadata.height, dpiScaleVertical))
                : undefined,
            },
            credit: metadata.imageResources?.xmpMetadata
              ? getPsdCredit(metadata.imageResources.xmpMetadata)
              : undefined,
          });
        } catch (e) {
          reject(e);
        }
      } else reject();
    };

    reader.onerror = () => reject();

    reader.readAsArrayBuffer(file);
  });
};

export const readPdfMetadata = async (file: File): Promise<ImageMetadata> =>
  new Promise((resolve, reject) => {
    const pdfJs = pdfjs.getDocument(URL.createObjectURL(file));
    if (pdfJs) {
      pdfJs.promise.then(async (pdf) => {
        try {
          const { info } = await pdf.getMetadata();
          const credit = (info as PdfInformation).Author ?? undefined;

          const dimensions = await pdf.getPage(1).then((page) => {
            const dpiScale = 72;
            return {
              width: Math.floor(page.getViewport({ scale: scale }).width),
              height: Math.floor(page.getViewport({ scale: scale }).height),
              widthInCm: roundToDecimal(
                pxToCm(page.getViewport({ scale: scale }).width, dpiScale)
              ),
              heightInCm: roundToDecimal(
                pxToCm(page.getViewport({ scale: scale }).height, dpiScale)
              ),
            };
          });

          resolve({
            dimensions,
            credit,
          });
        } catch (e) {
          reject(e);
        }
      });
    } else reject();
  });
