import { gql } from '@apollo/client';
import { ASSET_METADATA } from './dam';

export const ADDRESS_BLOCK_DETAIL = gql`
  fragment AddressBlockDetail on AddressBlock {
    clientId
    entityId
    supplyProductIds
    supplyProducts {
      longueurFormatPageCm
      largeurFormatPageCm
      largeurFormatFiniCm
      longueurFormatFiniCm
    }
    id
    asset {
      id
      url
      thumbnailUrl
      name
      assetType
      metadata {
        ...AssetMetadata
      }
    }
    supportId
    support {
      name
    }
    name
    status
  }
  ${ASSET_METADATA}
`;

export const ADDRESS_BLOCKS = gql`
  query AddressBlocks($entityId: String!, $sort: AddressBlockSortInput) {
    addressBlocks(entityId: $entityId, sort: $sort) {
      ...AddressBlockDetail
    }
  }
  ${ADDRESS_BLOCK_DETAIL}
`;

export const CREATE_ADDRESS_BLOCK = gql`
  mutation CreateAddressBlock($addressBlock: AddressBlockInput!) {
    addressBlock: createAddressBlock(addressBlock: $addressBlock) {
      ...AddressBlockDetail
    }
  }
  ${ADDRESS_BLOCK_DETAIL}
`;

export const UPDATE_ADDRESS_BLOCK_BY_ID = gql`
  mutation UpdateAddressBlockById(
    $id: String!
    $addressBlock: AddressBlockFormInput!
  ) {
    updateAddressBlockById(id: $id, addressBlock: $addressBlock) {
      ...AddressBlockDetail
    }
  }
  ${ADDRESS_BLOCK_DETAIL}
`;

export const REMOVE_ADDRESS_BLOCK = gql`
  mutation RemoveAddressBlock($id: String!) {
    removeAddressBlock(id: $id)
  }
`;

export const CREATE_ADDRESS_BLOCK_UPLOAD_URL = gql`
  mutation CreateAddressBlockUploadUrl($mimetype: String!) {
    createAddressBlockUploadUrl(mimetype: $mimetype) {
      id
      url
    }
  }
`;
