import {
  GET_DIVISIONS as I_GET_DIVISIONS,
  GET_DIVISIONS_getDivisions as Divisions,
} from 'app/schemaInterfaces/GET_DIVISIONS';
import { useMemo } from 'react';
import { Option } from '../ControlledSelect/ControlledSelect';

type ValuesMap = Map<string, ValuesMap> | null;

export interface DivisionSelectModel {
  key: string;
  label: string;
  parents: string[];
  children: string[];
}

/**
 * A custom hook that generates the selects models for the divisions of a client and a Map of the select values.
 * It also exposes a getOptions function in order to fetch the select options for a certain division based on the
 * values of the parent divisions
 *
 * This hook can be re-used to create a dynamic select inputs for divisions
 */
export const useDivisionSelect = (
  data?: I_GET_DIVISIONS,
  getDivisionValue?: (key: string) => string | null | undefined
) => {
  const { selectModels, valuesTreeMap } = useMemo(() => {
    const buildSelectModels = (
      divisions: Divisions[],
      parents: string[] = []
    ): DivisionSelectModel[] => {
      const key = divisions[0]?.key;
      const label = divisions[0]?.divisionName ?? 'None';

      let models: DivisionSelectModel[] = [];
      if (divisions[0]?.valuesTree) {
        models = buildSelectModels(divisions[0].valuesTree, [...parents, key]);
      }
      return [
        {
          key,
          label,
          parents,
          children: models[0] ? [models[0].key, ...models[0].children] : [],
        },
        ...models,
      ];
    };

    const getValuesTreeMap = (divisions: Divisions[]): ValuesMap => {
      const valuesMap: ValuesMap = new Map<string, ValuesMap>();
      for (const division of divisions) {
        if (division.valuesTree) {
          valuesMap.set(division.value, getValuesTreeMap(division.valuesTree));
        } else {
          valuesMap.set(division.value, null);
        }
      }
      return valuesMap;
    };

    return {
      selectModels: data?.getDivisions
        ? buildSelectModels(data.getDivisions)
        : [],
      valuesTreeMap: data?.getDivisions
        ? getValuesTreeMap(data.getDivisions)
        : null,
    };
  }, [data]);

  const getOptions = ({ parents }: DivisionSelectModel): Option[] => {
    if (!getDivisionValue) return [];
    let values = valuesTreeMap;
    for (const divisionKey of parents) {
      const selectValue = getDivisionValue(divisionKey);
      if (!selectValue) return [];
      const poppedValues = values?.get(selectValue);
      if (!poppedValues) {
        return [];
      }
      values = poppedValues;
    }
    if (!values) return [];
    return Array.from(values.keys()).map((value) => ({ value, label: value }));
  };

  return {
    selectModels,
    valuesTreeMap,
    getOptions,
  };
};
