import { gql } from '@apollo/client';

export const GET_SUPPORTS = gql`
  query GetSupports {
    supports {
      id
      name
      category
      supportAttributes {
        name
        family
        index
        key
      }
      onePacConfig {
        category
        subCategory
        callQuantity
        predefinedQuantity1
        predefinedQuantity2
        predefinedQuantity3
        predefinedQuantity4
        predefinedQuantity5
      }
    }
  }
`;

export const GET_SUPPORT_FABRICATION_TIMES = gql`
  query FabricationTimes($supportId: String!, $quantity: Float!) {
    fabricationTimes(supportId: $supportId, quantity: $quantity) {
      standardMode
      expressMode
    }
  }
`;
