import React from 'react';
import { useTranslation } from 'react-i18next';
import { shopBreadcrumbs } from 'modules/shop/shopHelpers';
import { css } from '@emotion/react';
import Breadcrumbs from 'modules/shop/components/Breadcrumbs';
import HomeReversePlanning from 'modules/home/components/home-reversePlanning/HomeReversePlanning';

const style = css`
  padding-right: 42px;
  padding-left: 42px;
  h1 {
    padding-top: 39px;
    padding-bottom: 34px;
  }
`;

const PlanningPage = () => {
  const { t } = useTranslation();
  return (
    <div css={style}>
      <Breadcrumbs paths={shopBreadcrumbs(t).paths} />
      <HomeReversePlanning />
    </div>
  );
};

export default PlanningPage;
