import React, { FC } from 'react';
import '../Loader/Loader.scss';
import { ApolloError } from '@apollo/client';
import LoaderBase from '../LoaderBase/LoaderBase';
import { CircularProgress, CircularProgressProps } from '@material-ui/core';

export interface CircularProgressLoaderProps {
  error?: ApolloError | undefined;
  height?: number | string;
  IsPaperWrap?: boolean;
}

const CircularProgressLoader: FC<
  CircularProgressLoaderProps & CircularProgressProps
> = ({ height, error, IsPaperWrap, ...loaderProps }) => (
  <LoaderBase
    height={height}
    error={error}
    IsPaperWrap={IsPaperWrap}
    loader={<CircularProgress variant="indeterminate" {...loaderProps} />}
  />
);

export default CircularProgressLoader;
