import { useQuery, WatchQueryFetchPolicy } from '@apollo/client';
import {
  GetDocument,
  GetDocumentVariables,
} from 'app/schemaInterfaces/GetDocument';
import { GET_DOCUMENT } from 'app/graphql/queries/documents';

const useDocumentQuery = (
  documentId: string | undefined | null,
  fetchPolicy: WatchQueryFetchPolicy = 'no-cache'
) =>
  useQuery<GetDocument, GetDocumentVariables>(GET_DOCUMENT, {
    fetchPolicy,
    variables: {
      documentId: documentId as string,
    },
    skip: !documentId,
  });

export default useDocumentQuery;
