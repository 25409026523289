import React, { FC } from 'react';
import mime from 'mime-types';
import { FileExtension } from 'app/utils/common';
import PdfPreview from '../PdfPreview/PdfPreview';
import { formThumbnailImageStyle } from './FormThumbnail.style';

export interface FormThumbnailProps {
  file?: File;
  base64File?: string;
  url?: string;
  width?: ThumbnailSize;
  height?: ThumbnailSize;
  className?: string;
}

export enum ThumbnailSize {
  Small = '100px',
  Medium = '300px',
  Large = '500px',
}

const FormThumbnail: FC<FormThumbnailProps> = ({
  file,
  base64File,
  url,
  width = ThumbnailSize.Small,
  height = ThumbnailSize.Small,
  className,
}) => {
  const fileExtension = file ? mime.extension(file.type) : undefined;

  if (fileExtension === FileExtension.Pdf && base64File) {
    return <PdfPreview file={base64File as string} />;
  }

  return (
    <img
      alt="Preview"
      src={file ? URL.createObjectURL(file) : url}
      width={width}
      height={height}
      className={className}
      css={formThumbnailImageStyle}
    />
  );
};

export default FormThumbnail;
