import { css } from '@emotion/react';
import { Slide } from '@material-ui/core';
import { NavigateNext, NavigateBefore } from '@material-ui/icons';
import React, { ReactElement, useState } from 'react';
import colors from 'styles/colors.module.scss';

const style = () => css`
  position: relative;
  display: inline-flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  .next--icon {
    position: absolute;
    height: fit-content;
    box-shadow: 0px 0px 8px rgba(59, 59, 80, 0.1);
    border-radius: 50%;
    display: flex;
    font-size: 14px;
    background: ${colors.white};
    padding: 10px;
    cursor: pointer;
  }
  .arrows {
    height: 100%;
    position: absolute;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .next {
    right: 0;
  }
  .previous {
    left: 0;
  }
  .previous--icon {
    position: absolute;
    height: fit-content;
    box-shadow: 0px 0px 8px rgba(59, 59, 80, 0.1);
    border-radius: 50%;
    display: flex;
    font-size: 14px;
    background: ${colors.white};
    padding: 10px;
    cursor: pointer;
    z-index: 10;
  }
`;

interface SliderProps {
  children: ReactElement;
  /**
   * The function called when right arrow is clicked
   */
  onRightArrowClick: () => void;
  /**
   * If `true`, show the right arrow icon
   */
  hasRightArrow: boolean;
  /**
   * The function called when left arrow is clicked
   */
  onLeftArrowClick: () => void;
  /**
   * If `true`, show the left arrow icon
   */
  hasLeftArrow: boolean;
}

const Slider = ({
  children,
  onRightArrowClick,
  hasRightArrow,
  onLeftArrowClick,
  hasLeftArrow,
}: SliderProps) => {
  const [slideIn, setSlideIn] = useState(true);
  const [slideDirection, setSlideDirection] = useState<
    'left' | 'right' | 'up' | 'down'
  >('left');

  const onArrowClick = (direction: 'left' | 'right' | 'up' | 'down') => {
    const oppDirection = direction === 'left' ? 'right' : 'left';
    direction === 'left' ? onLeftArrowClick() : onRightArrowClick();
    setSlideDirection(direction);
    setSlideIn(false);

    setTimeout(() => {
      setSlideDirection(oppDirection);
      setSlideIn(true);
    }, 300);
  };

  return (
    <div css={style}>
      <div className="previous arrows">
        {hasLeftArrow && (
          <div className="previous--icon">
            <NavigateBefore
              onClick={() => {
                onArrowClick('left');
              }}
            />
          </div>
        )}
      </div>
      <Slide in={slideIn} direction={slideDirection}>
        {children}
      </Slide>
      <div className="next arrows">
        {hasRightArrow && (
          <div className="next--icon">
            <NavigateNext
              onClick={() => {
                onArrowClick('right');
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Slider;
