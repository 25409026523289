import React from 'react';

import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const DocumentSkeletonLoader = ({
  numberOfElements = 6,
  height,
  borderRadius,
}: {
  numberOfElements: number;
  height: number;
  borderRadius: number;
}) => (
  <>
    {Array.from(Array(numberOfElements).keys()).map((value, key) => (
      <Grid item xs key={key}>
        <Skeleton
          animation="wave"
          variant="rect"
          height={height}
          style={{ borderRadius }}
        />
      </Grid>
    ))}
  </>
);

export default DocumentSkeletonLoader;
