import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const downloadFileButtonStyle = css`
  color: ${colors.primary};
  height: 40px;
  background-color: ${colors.white};

  &:hover {
    background-color: ${colors.white};
  }
`;
