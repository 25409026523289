import { useQuery, WatchQueryFetchPolicy } from '@apollo/client';
import { GET_DELIVERY_ADDRESSES } from 'app/graphql/queries/entities';
import {
  GetClientShippingAddress,
  GetClientShippingAddressVariables,
} from 'app/schemaInterfaces/GetClientShippingAddress';

const useDeliveryAddressesQuery = (
  id: string | undefined | null,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network'
) =>
  useQuery<GetClientShippingAddress, GetClientShippingAddressVariables>(
    GET_DELIVERY_ADDRESSES,
    {
      fetchPolicy,
      variables: {
        entityId: id as string,
      },
    }
  );

export default useDeliveryAddressesQuery;
