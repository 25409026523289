import React, { useEffect } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Typography,
  Button,
} from '@material-ui/core';
import { FieldProps } from 'formik';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { FileStore } from 'modules/pim/baseProducts/PimFormFieldsConfig';
import colors from 'styles/colors.module.scss';
import { useProductsSheetsContext } from 'modules/pim/baseProducts/BaseProducts';
import { FormFilesCard } from 'modules/pim/components/FormFilesCard';
import { filteredFilesByFieldName } from 'modules/pim/baseProducts/ProductsHelper';
import { useTranslation } from 'react-i18next';

export interface PreviewFile extends File {
  preview: string;
  path?: string;
}

export interface ProductsSheetFilesUploadProps {
  numberOfFiles: number;
  placeholder: string;
  productId: string;
}

const ProductsSheetFilesUpload = ({
  form: { errors, touched, setFieldValue },
  field: { name },
  numberOfFiles,
  placeholder,
  productId,
}: FieldProps & ProductsSheetFilesUploadProps): JSX.Element => {
  const {
    formFilesManager,
    setFormFilesManager,
    fieldName,
    setFieldName,
    setFilesLimitNumber,
    filesLimitNumber,
  } = useProductsSheetsContext();

  const { t } = useTranslation();

  useEffect(() => {
    if (
      filteredFilesByFieldName(formFilesManager, fieldName as string).length >
        0 &&
      filteredFilesByFieldName(formFilesManager, fieldName as string).length ===
        filesLimitNumber
    ) {
      setFieldName(undefined);
    }
    setFieldValue(
      name,
      filteredFilesByFieldName(formFilesManager, name).map(
        ({ fileName }) => fileName
      )
    );
  }, [
    filesLimitNumber,
    setFieldName,
    fieldName,
    formFilesManager,
    setFormFilesManager,
    name,
    setFieldValue,
  ]);

  const removeFiles = (filteredFiles: FileStore) => {
    let filesToRemove: FileStore[] = [...formFilesManager];
    const index = filesToRemove.indexOf(filteredFiles);
    filesToRemove.splice(index, 1);
    setFormFilesManager(filesToRemove);
    setFieldName(filteredFiles.fieldName);
    setFilesLimitNumber(numberOfFiles);
    setFieldValue(
      name,
      filteredFilesByFieldName(formFilesManager, name).map(
        ({ fileName }) => fileName
      )
    );
  };

  const updateFiles = (filteredFiles: FileStore) => {
    let filesToEdit: FileStore[] = [...formFilesManager];
    filesToEdit.forEach((filetoEdit, index) => {
      if (filteredFiles === filetoEdit) {
        filesToEdit.splice(index, 1);
        setFieldName(filetoEdit.fieldName);
      }
      setFormFilesManager(filesToEdit);
      setFilesLimitNumber(numberOfFiles);
      setFieldValue(
        name,
        filteredFilesByFieldName(formFilesManager, name).map(
          ({ fileName }) => fileName
        )
      );
    });
  };

  return (
    <Box pt={2}>
      {filteredFilesByFieldName(formFilesManager, name).length <
        numberOfFiles && (
        <Box
          display="grid"
          height="102px"
          border={`2px dashed ${colors.primary}`}
          px={2}
          py={1}
          justifyContent="center"
          onClick={() => {
            setFieldName(name);
            return setFilesLimitNumber(numberOfFiles);
          }}
          style={{ cursor: 'pointer' }}
        >
          <Button color="primary">
            <AddCircleIcon />
          </Button>
          <Box color={colors.primary}>
            <Typography>
              {t('pim.form.add_one', {
                placeholder,
              })}
            </Typography>
          </Box>
        </Box>
      )}
      {filteredFilesByFieldName(formFilesManager, name).map(
        (filteredFile, index) => {
          if (!filteredFile.assetId) {
            return (
              <FormFilesCard
                file={filteredFile?.fieldFile}
                base64File={filteredFile.base64File}
                removeFiles={removeFiles}
                key={index}
                updateFiles={updateFiles}
                filteredFile={filteredFile}
                name={name}
                productId={productId}
              />
            );
          } else {
            return (
              <FormFilesCard
                removeFiles={removeFiles}
                key={index}
                updateFiles={updateFiles}
                filteredFile={filteredFile}
                assetId={filteredFile.assetId}
                name={name}
                productId={productId}
              />
            );
          }
        }
      )}
      <Box textAlign="center">
        <FormControl error={touched[name] && Boolean(errors[name])} fullWidth>
          <FormLabel>{touched[name] && errors[name]}</FormLabel>
        </FormControl>
      </Box>
    </Box>
  );
};

export default ProductsSheetFilesUpload;
