import React, { useEffect, useState } from 'react';
import { IProductTemplate } from '../../ProductItem';
import {
  VisualIdentityType,
  PriceElement,
} from 'app/schemaInterfaces/globalTypes';
import useAppContext from 'app/app-context/useAppContext';
import PriceTagElementContainer from './PriceTagElementContainer';
import ProductPrice from 'modules/workflow/Clients/ToutFaire/components/price/ProductPrice';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from 'app/auth/UserContext';
import useCurrentEntity from 'app/current-entity-context/useCurrentEntity';
import {
  getUnifiedKeyFromPimClientConfig,
  getEntityIdAndHisAncestorId,
} from 'modules/pim/baseProducts/ProductsHelper';
import { UnifiedProductKeyToRenderOnPage } from 'modules/workflow/containers/Leftsidebar/ProductsPanel/ProductsView';
import { priceTagStyle } from './priceTagHelper';
import PriceTagFooter from './PriceTagFooter';

interface PriceTagProps {
  product: IProductTemplate;
}

const PriceTag = ({ product }: PriceTagProps): JSX.Element => {
  const {
    client: { config: clientConfig },
  } = useAppContext();

  const user = useCurrentUser();
  const { currentEntity } = useCurrentEntity();
  const [fieldEntityId, setFieldEntityId] = useState<string>('');

  const { t } = useTranslation();

  const priceChip = product.visualIdentities?.find(
    (visual) => visual.visualIdentityType === VisualIdentityType.Price
  );

  const priceUnity = product.priceTagTypographies?.find(
    (priceTypo) =>
      priceTypo.priceElement === PriceElement.Typography &&
      priceTypo.name ===
        getUnifiedKeyFromPimClientConfig(
          UnifiedProductKeyToRenderOnPage.priceunity,
          clientConfig
        )
  );

  const priceOptionalUnity = product.priceTagTypographies?.find(
    (priceTypo) =>
      priceTypo.priceElement === PriceElement.Typography &&
      priceTypo.name ===
        getUnifiedKeyFromPimClientConfig(
          UnifiedProductKeyToRenderOnPage.optionalunity,
          clientConfig
        )
  );

  const priceLabels = product.priceTagTypographies?.find(
    (priceTypo) =>
      priceTypo.priceElement === PriceElement.Typography &&
      priceTypo.name ===
        getUnifiedKeyFromPimClientConfig(
          UnifiedProductKeyToRenderOnPage.pricelabelwithtaxes,
          clientConfig
        )
  );

  const isCompaniesPrice =
    product.productDetails[
      getUnifiedKeyFromPimClientConfig(
        UnifiedProductKeyToRenderOnPage.professionnalprice,
        clientConfig
      )
    ];

  const priceWithTaxes =
    product.productDetails[
      getUnifiedKeyFromPimClientConfig(
        UnifiedProductKeyToRenderOnPage.priceWithTax,
        clientConfig
      ).toString()
    ];

  const fullCompaniesPrice = product.priceTagTypographies?.find(
    (priceTypo) =>
      priceTypo.priceElement === PriceElement.Typography &&
      priceTypo.name ===
        getUnifiedKeyFromPimClientConfig(
          UnifiedProductKeyToRenderOnPage.professionnalprice,
          clientConfig
        )
  );

  const getField = clientConfig?.pim.requiredFields.find(
    (field) => field.unifiedKey === UnifiedProductKeyToRenderOnPage.priceWithTax
  );
  const hasFieldEntityId = getField?.entityIds?.includes(fieldEntityId);

  const priceSymbol = hasFieldEntityId
    ? t('workflow.typography_item.without_taxes_symbol')
    : 'HTVA';

  const foreignPriceSymbol = hasFieldEntityId
    ? t('workflow.typography_item.with_taxes_symbol')
    : 'TVAC';

  useEffect(() => {
    currentEntity &&
      getEntityIdAndHisAncestorId(user, currentEntity, setFieldEntityId);
  }, [currentEntity, user]);

  const hasPriceChip = Boolean(priceChip?.display);

  return (
    <div css={priceTagStyle(priceChip)}>
      {/* soit le kg :  */}
      {priceUnity && (
        <PriceTagElementContainer
          product={product}
          priceTagElement={priceUnity}
          value={priceUnity?.name && product.productDetails[priceUnity.name]}
          position="absolute"
          topValue={priceUnity?.position?.y}
          leftValue={priceUnity?.position?.x}
          widthValue="max-content"
        />
      )}

      <ProductPrice
        product={product}
        priceSymbol={priceSymbol}
        hasPriceChip={hasPriceChip}
        isCompaniesPrice={isCompaniesPrice}
        fullCompaniesPrice={fullCompaniesPrice}
        priceWithTaxes={priceWithTaxes}
        foreignPriceSymbol={foreignPriceSymbol}
        priceLabels={priceLabels}
        hasFieldEntityId={Boolean(hasFieldEntityId)}
        fieldEntityId={fieldEntityId}
        priceUnity={priceUnity}
        priceOptionalUnity={priceOptionalUnity}
      />

      {(isCompaniesPrice ||
        fullCompaniesPrice ||
        priceUnity ||
        priceOptionalUnity ||
        priceLabels) &&
        hasPriceChip && (
          <PriceTagFooter
            product={product}
            isCompaniesPrice={isCompaniesPrice}
            fullCompaniesPrice={fullCompaniesPrice}
            priceWithTaxes={priceWithTaxes}
            foreignPriceSymbol={foreignPriceSymbol}
            priceLabels={priceLabels}
            priceUnity={priceUnity}
            priceOptionalUnity={priceOptionalUnity}
            hasFieldEntityId={Boolean(hasFieldEntityId)}
            fieldEntityId={fieldEntityId}
          />
        )}
    </div>
  );
};

export default PriceTag;
