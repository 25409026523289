import { useMutation } from '@apollo/client';
import { UPDATE_NOTIFICATIONS_DATE_BY_CONDITION_ID } from 'app/graphql/queries/notifications';
import {
  UpdateNotificationsDateByConditionId,
  UpdateNotificationsDateByConditionIdVariables,
} from 'app/schemaInterfaces/UpdateNotificationsDateByConditionId';

const useUpdateNotificationsDateByCondition = () => {
  const [
    updateNotificationsDateByItemId,
    {
      data: updateNotificationsDateByItemIdData,
      error: updateNotificationsDateByItemIdError,
      loading: updateNotificationsDateByItemIdLoading,
    },
  ] = useMutation<
    UpdateNotificationsDateByConditionId,
    UpdateNotificationsDateByConditionIdVariables
  >(UPDATE_NOTIFICATIONS_DATE_BY_CONDITION_ID);

  return {
    updateNotificationsDateByItemId,
    updateNotificationsDateByItemIdData,
    updateNotificationsDateByItemIdError,
    updateNotificationsDateByItemIdLoading,
  };
};

export default useUpdateNotificationsDateByCondition;
