import { AppBar, Box, Tab, Tabs, TabsProps } from '@material-ui/core';
import React, { FC } from 'react';

import { sideFormTabsStyle } from './SideFormTabs.style';

export interface SideFormTabsProps extends TabsProps {
  tabs: { name: string }[];
  selectedTabIndex: number;
  handleChangeTabIndex: (
    event: React.ChangeEvent<{}>,
    tabIndex: number
  ) => void;
}

const getTabPanelBAseProps = (index: number) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const SideFormTabs: FC<SideFormTabsProps> = ({
  tabs,
  selectedTabIndex,
  handleChangeTabIndex,
  ...tabsProps
}) => {
  return (
    <Box px={5}>
      <AppBar position="static" color="default">
        <Tabs
          css={sideFormTabsStyle}
          value={selectedTabIndex}
          onChange={handleChangeTabIndex}
          aria-label="side form tabs"
          {...tabsProps}
          indicatorColor="primary"
        >
          {tabs.map(({ name }, index) => (
            <Tab
              key={index}
              label={`${name}`}
              {...getTabPanelBAseProps(index)}
            />
          ))}
        </Tabs>
      </AppBar>
    </Box>
  );
};

export default SideFormTabs;
