import * as React from 'react';
import Button from '@material-ui/core/Button';
import { styled } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import { SEND_COMMITMENT_REMINDER } from 'app/graphql/queries/commitments';
import {
  SendCommitmentReminder,
  SendCommitmentReminderVariables,
} from 'app/schemaInterfaces/SendCommitmentReminder';
import { useParams } from 'react-router-dom';
import { css } from '@emotion/react/macro';
import colors from 'styles/colors.module.scss';

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const CustomDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose } = props;

  const style = css`
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
    .title {
      font-family: ScandiaWebMedium;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 32px;
      letter-spacing: -0.03em;
    }
  `;

  return (
    <DialogTitle>
      <div css={style}>
        <div className="title">{children}</div>
        <div>
          {onClose ? (
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </div>
      </div>
    </DialogTitle>
  );
};

interface CommitmentReminderDialogProps {
  disabledCommitmentReminderButton: boolean;
}

export const CommitmentReminderDialog = ({
  disabledCommitmentReminderButton,
}: CommitmentReminderDialogProps) => {
  const { t } = useTranslation();

  const { id: campaignId } = useParams<{ id: string }>();

  const [
    openCommitmentReminderDialog,
    setOpenCommitmentReminderDialog,
  ] = React.useState<boolean>(false);

  const [commitmentReminder] = useLazyQuery<
    SendCommitmentReminder,
    SendCommitmentReminderVariables
  >(SEND_COMMITMENT_REMINDER);

  const handleClickOpen = () => {
    setOpenCommitmentReminderDialog(true);
  };
  const handleClose = () => {
    setOpenCommitmentReminderDialog(false);
  };

  const handleSendCommitmentDialog = async () => {
    await commitmentReminder({ variables: { campaignId } });
    setOpenCommitmentReminderDialog(false);
  };

  const dialogContentStyle = css`
    padding: 10px;
    .firstContent {
      font-family: ScandiaWebMedium;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      display: flex;
      align-items: center;
      letter-spacing: -0.03em;
    }
    .secondContent {
      font-family: ScandiaWebMedium;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;
      letter-spacing: -0.03em;
      color: ${colors.grey};
      padding-top: 5px;
    }
  `;

  const groupButtonStyle = css`
    display: flex;
    padding: 19px;
  `;

  return (
    <div>
      <div>
        <Button
          color="primary"
          variant="outlined"
          onClick={() =>
            !disabledCommitmentReminderButton ? handleClickOpen() : null
          }
          style={
            disabledCommitmentReminderButton ? { opacity: 0.5 } : undefined
          }
        >
          <Typography variant="body2">
            {t('global.distribution_list.commitment_reminder')}
          </Typography>
        </Button>
      </div>
      <CustomDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openCommitmentReminderDialog}
      >
        <CustomDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {t('global.distribution_list.commitment_reminder_dialog_title')}
        </CustomDialogTitle>
        <DialogContent dividers>
          <div css={dialogContentStyle}>
            <p className="firstContent">
              {t(
                'global.distribution_list.commitment_reminder_dialog_first_content'
              )}
            </p>
            <p className="secondContent">
              {t(
                'global.distribution_list.commitment_reminder_dialog_second_content'
              )}
            </p>
          </div>
        </DialogContent>
        <DialogActions>
          <div css={groupButtonStyle}>
            <div>
              <Button
                autoFocus
                color="primary"
                variant={undefined}
                onClick={handleClose}
              >
                <Typography variant="body2">
                  {t(
                    'global.distribution_list.commitment_reminder_dialog_cancel_button'
                  )}
                </Typography>
              </Button>
            </div>
            <div>
              <Button
                autoFocus
                color="primary"
                variant="contained"
                onClick={handleSendCommitmentDialog}
              >
                <Typography variant="body2">
                  {t(
                    'global.distribution_list.commitment_reminder_dialog_submit_button'
                  )}
                </Typography>
              </Button>
            </div>
          </div>
        </DialogActions>
      </CustomDialog>
    </div>
  );
};
