import { LatLngExpression } from 'leaflet';
import { FC, useEffect } from 'react';
import { useMap } from 'react-leaflet';

export interface StaticMapControllerProps {
  center?: LatLngExpression;
  zoom?: number;
}

const StaticMapController: FC<StaticMapControllerProps> = ({
  center,
  zoom,
}) => {
  const map = useMap();
  useEffect(() => {
    if (center !== undefined) {
      map.setView(center);
    }
  }, [map, center]);
  useEffect(() => {
    if (zoom !== undefined) {
      map.setZoom(zoom);
    }
  }, [map, zoom]);
  return null;
};

export default StaticMapController;
