import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const iconStyle = css`
  width: 32px;
  height: 32px;
  background: ${colors.primary};
  color: ${colors.background};
  border-radius: 100%;
  padding: 4px;
`;

export const iconDisabledStyle = css`
  background: ${colors.loader3};
`;

export const iconWarningStyle = css`
  background: ${colors.orange300};
`;

export const iconErrorStyle = css`
  background: ${colors.orangeAcid};
`;

export const iconSuccessStyle = css`
  background: ${colors.acidGreen};
`;
