import { Stepper } from '@material-ui/core';
import moment from 'moment';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FollowOrderRefusedBox from '../components/FollowOrderRefusedBox/FollowOrderRefusedBox';
import FollowOrderWorkflowBox from '../components/FollowOrderWorkflowBox/FollowOrderWorkflowBox';
import FollowOrderStep from '../components/FollowOrderStep/FollowOrderStep';
import {
  partialTimelineStyle,
  style,
  timelineContainerStyle,
  timelineStyle,
} from './FollowOrderStepper.style';
import {
  useOrderStepper,
  UseOrderStepperParams,
} from './FollowOrderStepper.hooks';

export interface FollowOrderStepperProps extends UseOrderStepperParams {}

const FollowOrderStepper: FC<FollowOrderStepperProps> = ({ order }) => {
  const { t } = useTranslation();
  const {
    steps,
    isPartialTimeline,
    hasOrderWorkflowBlock,
    hasRefusedBlock,
    activeStep,
  } = useOrderStepper({ order });

  return (
    <div css={style}>
      <h3>{t('follow_orders.order_detail')}</h3>
      <span className="order-subtitle">
        {t('follow_orders.last_update')}{' '}
        {moment(order.updatedAt).format('DD/MM/YYYY')}
      </span>
      <div css={timelineContainerStyle}>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          css={[timelineStyle, isPartialTimeline && partialTimelineStyle]}
        >
          {steps.map((stepData) => (
            <FollowOrderStep key={stepData.label} stepData={stepData} />
          ))}
        </Stepper>
        {hasOrderWorkflowBlock && (
          <FollowOrderWorkflowBox workflows={order.activeOrderWorkflows} />
        )}
        {hasRefusedBlock && (
          <FollowOrderRefusedBox
            name={order.onepacUserName}
            date={order.statusUpdatedAt}
            reason={order.statusComment}
          />
        )}
      </div>
    </div>
  );
};

export default FollowOrderStepper;
