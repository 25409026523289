import React, { useEffect, useRef } from 'react';
import Box from '@material-ui/core/Box';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import 'modules/workflow/transformLibraryStyles.css';
import { useStudioStateContext } from 'modules/workflow/oneStudioContext';
import Toolbar from 'modules/workflow/containers/Toolbar/Toolbar';
import LeftSideBar from 'modules/workflow/containers/Leftsidebar/LeftSideBar';
import CommentBar from 'modules/workflow/containers/CatalogPreview/CommentBar';
import colors from 'styles/colors.module.scss';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CatalogPreview from 'modules/workflow/containers/CatalogPreview/CatalogPreview';
import editPlatform from './editPlatform.style';
import { useMutation } from '@apollo/client';
import { UPDATE_CATALOG_MUTATION } from 'app/graphql/queries/version';
import {
  UpdateVersionMutation,
  UpdateVersionMutationVariables,
} from 'app/schemaInterfaces/UpdateVersionMutation';
import useCurrentEntity from 'app/current-entity-context/useCurrentEntity';
import {
  normalizeDigitalContent,
  normalizeVersion,
} from './EditPlatform.utils.helper';
import ViewportControls from './Toolbar/widgets/ViewportControls';
import CommentsToggler from './Toolbar/widgets/CommentsToggler';
import { Divider } from '@material-ui/core';
import DownloadOptionsMenu from './Toolbar/SecondToolbar/DownloadOptionsMenu';
import { useSaveCatalog } from './Toolbar/useSaveCatalog';
import { usePdfDownload } from './Toolbar/SecondToolbar/usePdfDownload';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& .react-transform-element': {
        overflow: 'hidden',
      },
    },
  })
);

interface TransformWrapperHelper {
  zoomIn: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  zoomOut: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  resetTransform: () => void;
  scale: number;
}

const EditPlatform = (): JSX.Element => {
  const globalState = useStudioStateContext();
  const catalogRef = useRef<HTMLElement>(null);
  const classes = useStyles();
  const isEditable = globalState.isEditable;
  const { currentEntity } = useCurrentEntity();
  const [updateVersion, { loading: isAutoSaveLoading }] = useMutation<
    UpdateVersionMutation,
    UpdateVersionMutationVariables
  >(UPDATE_CATALOG_MUTATION, {
    onCompleted: (mutationResult) => {
      console.log({
        status: 'Version Updated Successfully',
        data: mutationResult.updateVersionPages,
      });
    },
  });

  function useInterval(callback: () => Promise<void>, delay: number) {
    const savedCallback = useRef<any>();
    useEffect(() => {
      savedCallback.current = callback;
    }); // Configurer l’intervalle.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  useInterval(async () => {
    await updateVersion({
      variables: {
        id: globalState.version.id,
        pages: normalizeVersion(globalState.version),
        entityId: currentEntity?.id,
        digitalContent: normalizeDigitalContent(
          globalState.version.digitalContent
        ),
      },
    });
  }, 60000);

  const { saveCatalog, isLoading: isSavingCatalog } = useSaveCatalog();
  const { pdfOptions, isPdfDownloading } = usePdfDownload({ saveCatalog });

  return (
    <Box className="transform-custom-wrapper">
      <TransformWrapper
        pan={{
          disabled: !globalState.panningEnabled,
        }}
        defaultScale={1}
        defaultPositionX={200}
        defaultPositionY={100}
        zoomIn={{ step: 3 }}
        zoomOut={{ step: 3 }}
        wheel={{ disabled: true }}
        doubleClick={{ disabled: true }}
      >
        {({
          zoomIn,
          zoomOut,
          resetTransform,
          scale,
        }: TransformWrapperHelper) => (
          <Box
            css={editPlatform(globalState.scaleValue, globalState.commentMode)}
          >
            <Toolbar
              ref={catalogRef}
              autoSaveInfo={{
                isSaving: isAutoSaveLoading,
                lastSaved: globalState.version.updatedAt,
              }}
              onFinalSave={() => saveCatalog(true)}
              isFinalSaving={isSavingCatalog}
              toolbarActions={{
                left: (
                  <ViewportControls
                    zoomIn={zoomIn}
                    zoomOut={zoomOut}
                    resetTransform={resetTransform}
                  />
                ),
                right: (
                  <>
                    <CommentsToggler />
                    <Divider orientation="vertical" flexItem variant="middle" />
                    <DownloadOptionsMenu
                      isDownloading={isPdfDownloading}
                      options={pdfOptions}
                    />
                  </>
                ),
              }}
            />
            <Box display="flex">
              <LeftSideBar />
              <Box
                flexGrow={2}
                bgcolor={colors.workzone}
                className={classes.root}
              >
                <TransformComponent>
                  <CatalogPreview zoomScale={scale ?? 1} ref={catalogRef} />
                </TransformComponent>
              </Box>
              {isEditable && <CommentBar />}
            </Box>
          </Box>
        )}
      </TransformWrapper>
    </Box>
  );
};
export default EditPlatform;
