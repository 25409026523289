import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Tooltip,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useDocumentCustomizationForm } from './DocumentCustomizationForm.hooks';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { infoIconStyle } from './DocumentCustomizationForm.style';
import { ErrorMessage, getIn } from 'formik';

const DocumentCustomizationForm = () => {
  const { t } = useTranslation();

  const {
    documentCustomizationOptions,
    handleChange,
    errors,
    touched,
  } = useDocumentCustomizationForm();

  return (
    <div css={infoIconStyle}>
      <FormLabel>{t('global.document_form.is_customizable.label')} *</FormLabel>
      <div className="field-group">
        <FormGroup row>
          {documentCustomizationOptions.map((option, index) => (
            <RadioGroup key={index} className="field">
              <FormControlLabel
                name={option.label}
                control={
                  <Radio
                    onClick={() => handleChange(option.value)}
                    name={option.label}
                    checked={option.isChecked}
                    color="primary"
                  />
                }
                label={option.label}
                value={option.value}
              />
            </RadioGroup>
          ))}
        </FormGroup>
        <Tooltip
          title={<>{t('global.document_form.is_customizable.tooltip')}</>}
        >
          <InfoOutlinedIcon className="tooltip" />
        </Tooltip>
      </div>
      <FormControl
        error={
          getIn(touched, 'document.isCustomizable') &&
          !!getIn(errors, 'document.isCustomizable')
        }
      >
        <ErrorMessage
          name={'document.isCustomizable'}
          component={FormHelperText}
        />
      </FormControl>
    </div>
  );
};

export default DocumentCustomizationForm;
