import { Grid, Tab, Tabs } from '@material-ui/core';
import React from 'react';
import { AppRoute } from 'app/routes/routes';
import AvailableDocumentsCard from '../AvailableDocumentsCard';
import { BigShopConfigCategory } from 'app/schemaInterfaces/globalTypes';
import { DocumentHistory_documentHistory_documents_document } from 'app/schemaInterfaces/DocumentHistory';
import DocumentSkeletonLoader from '../DocumentsSkeletonLoader';
import { GetDocument_document } from 'app/schemaInterfaces/GetDocument';
import Slider from 'components/Slider/Slider';
import UncontrolledSelect from 'components/UncontrolledSelect';
import { getTabsConfig } from '../helper';
import { useTranslation } from 'react-i18next';
import { availableDocumentsStyle } from './AvailableDocuments.style';
import { useAvailableDocuments } from './AvailableDocuments.hooks';

export enum AllCategories {
  All = 'Tous',
}

export type SelectedCategories = BigShopConfigCategory | AllCategories;

export enum FilterOptions {
  New = 'NEW',
  History = 'HISTORY',
  All = 'ALL',
}

const AvailableDocuments = () => {
  const { t } = useTranslation();

  const {
    filter,
    setFilter,
    options,
    handleCategoryChange,
    selectedCategory,
    DEFAULT_LIMIT,
    documents,
    loading,
    documentLink,
    hasRightArrow,
    hasLeftArrow,
    onRightArrowClick,
    onLeftArrowClick,
  } = useAvailableDocuments();

  return (
    <div css={availableDocumentsStyle}>
      <h1>{t('home_page.available_documents.header')}</h1>
      <p>{t('home_page.available_documents.description')}</p>
      <div className="menu">
        <div className="select">
          <h5>{t('home_page.available_documents.category')}</h5>
          <UncontrolledSelect
            options={options}
            isBorderRound={true}
            onChange={handleCategoryChange}
            value={selectedCategory}
            fullWidth
          />
        </div>
        <Tabs
          className="tabs-filter"
          value={filter}
          onChange={(e, newValue) => setFilter(newValue)}
        >
          {getTabsConfig(t).map((tab) => (
            <Tab
              key={tab.value}
              value={tab.value}
              label={tab.label}
              className="tab"
            />
          ))}
        </Tabs>
      </div>
      <Slider
        onRightArrowClick={onRightArrowClick}
        hasRightArrow={hasRightArrow}
        onLeftArrowClick={onLeftArrowClick}
        hasLeftArrow={hasLeftArrow}
      >
        <Grid container className="documents" spacing={2}>
          {loading ? (
            <DocumentSkeletonLoader
              numberOfElements={DEFAULT_LIMIT}
              height={200}
              borderRadius={35}
            />
          ) : documents?.length > 0 ? (
            documents.map(
              (
                document:
                  | GetDocument_document
                  | DocumentHistory_documentHistory_documents_document
              ) => {
                return (
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={2}
                    lg={2}
                    xl={2}
                    key={document?.id}
                  >
                    <AvailableDocumentsCard
                      document={document}
                      documentLink={documentLink}
                    />
                  </Grid>
                );
              }
            )
          ) : (
            <Grid item xs={12}>
              <p className="no-data--text">
                {t(
                  `home_page.available_documents.no_${
                    filter === FilterOptions.History ? 'histories' : 'documents'
                  }_first_part`
                )}{' '}
                <a href={AppRoute.Shop}>
                  {t(
                    `home_page.available_documents.no_${
                      filter === FilterOptions.History
                        ? 'histories'
                        : 'documents'
                    }_operation`
                  )}
                </a>
                {t(
                  `home_page.available_documents.no_${
                    filter === FilterOptions.History ? 'histories' : 'documents'
                  }_last_part`
                )}
              </p>
            </Grid>
          )}
        </Grid>
      </Slider>
    </div>
  );
};

export default AvailableDocuments;
