import React from 'react';
import { Row } from 'react-table';
import { DocumentAndOtherFields } from 'modules/pro/documents/DocumentsList/DocumentsList';
import DuplicateDocumentForm from 'modules/pro/documents/CopyDocumentForm';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import { Formik } from 'formik';
import {
  DocumentCopyFormValues,
  getDocumentCopyFormSchema,
} from 'modules/pro/documents/documentHelper';
import { useCurrentUser } from 'app/auth/UserContext';

interface CopyDocumentFormContainerProps {
  handleDuplicateMenuClick: (row: Row<DocumentAndOtherFields>) => void;
  handleDocumentDuplicateAction: (
    values: DocumentCopyFormValues
  ) => Promise<void>;
  closeDuplicateDialog: () => void;
  isDuplicateDialogOpen: boolean;
  isLoading: boolean | undefined;
  error: ApolloError | undefined;
}

const CopyDocumentFormContainer = ({
  handleDocumentDuplicateAction,
  closeDuplicateDialog,
  isDuplicateDialogOpen,
  isLoading,
  error,
}: CopyDocumentFormContainerProps): JSX.Element => {
  const { t } = useTranslation();
  const { entities } = useCurrentUser();

  const initialValues: DocumentCopyFormValues = {
    name: '',
    entities,
  };

  return (
    <Formik<DocumentCopyFormValues>
      initialValues={initialValues}
      validationSchema={getDocumentCopyFormSchema(t)}
      enableReinitialize={true}
      onSubmit={(values, { resetForm }) => {
        handleDocumentDuplicateAction(values).then(() => {
          resetForm();
        });
      }}
    >
      <DuplicateDocumentForm
        onClose={closeDuplicateDialog}
        open={isDuplicateDialogOpen}
        title={t('global.documents_list.duplicate_dialog.title')}
        label={t('global.documents_list.duplicate_dialog.label')}
        confirmationText={t(
          'global.documents_list.duplicate_dialog.create_button'
        )}
        error={error}
        isLoading={isLoading}
      />
    </Formik>
  );
};

export default CopyDocumentFormContainer;
