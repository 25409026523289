import { useCurrentUser } from 'app/auth/UserContext';
import {
  FollowOrderStatus,
  OrderFilterInput,
} from 'app/schemaInterfaces/globalTypes';
import { Buttons } from 'components/ActionsButtons/FilterButtons';
import { useTranslation } from 'react-i18next';
import { mightUserBeValidator } from '../utils/validation';

export type StatusType = FollowOrderStatus | undefined;

export interface UseFollowOrdersToolBarParams {
  setFilter: React.Dispatch<React.SetStateAction<OrderFilterInput>>;
  filter: OrderFilterInput;
}

export function useFollowOrdersToolBar({
  filter,
  setFilter,
}: UseFollowOrdersToolBarParams) {
  const { t } = useTranslation();
  const currentUser = useCurrentUser();

  const statusOptions: Buttons<StatusType>[] = [
    {
      title: mightUserBeValidator(currentUser)
        ? t('follow_orders.status_filter.to_validate')
        : t('follow_orders.status_filter.waiting_validation'),
      value: FollowOrderStatus.WaitingValidation,
    },
    {
      title: t('follow_orders.status_filter.validated'),
      value: FollowOrderStatus.Validated,
    },
    {
      title: t('follow_orders.status_filter.refused'),
      value: FollowOrderStatus.Refused,
    },
    { title: t('follow_orders.status_filter.all'), value: undefined },
  ];
  const statusValue = filter.followStatus;

  function handleSetStatus(v: StatusType) {
    setFilter({ ...filter, followStatus: v });
  }

  return { statusOptions, statusValue, handleSetStatus };
}
