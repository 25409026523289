import React, { FC, PropsWithChildren } from 'react';
import {
  Box,
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

export interface ConfirmationDialogProps {
  isOpen: boolean;
  title: string;
  onClose: () => void;
  actions?: ConfirmationAction[];
  maxWidth?: DialogProps['maxWidth'];
}

export interface ConfirmationAction {
  title: string;
  dataTestId?: string;
  onClick?: ButtonProps['onClick'];
  buttonVariant?: ButtonProps['variant'];
  icon?: React.ReactNode;
  type?: ButtonProps['type'];
  isKeptOpenOnClick?: boolean;
}

const ConfirmationDialog: FC<PropsWithChildren<ConfirmationDialogProps>> = ({
  isOpen,
  onClose,
  title,
  children,
  actions,
  maxWidth,
}) => (
  <Dialog
    open={isOpen}
    onClose={onClose}
    maxWidth={maxWidth}
    scroll="body"
    data-testid="dialog"
  >
    <DialogTitle>
      <Box display="flex" alignItems="center">
        <Box flexGrow={1}>
          <Typography variant="h3">{title}</Typography>
        </Box>
        <Box>
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              onClose();
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
    </DialogTitle>
    <Divider light />
    <Box py={1}>
      <DialogContent>{children}</DialogContent>
    </Box>
    <Divider light={true} />
    {actions?.length && (
      <Box py={1}>
        <DialogActions>
          {actions?.map(
            (
              {
                title,
                onClick,
                dataTestId,
                buttonVariant,
                icon,
                type,
                isKeptOpenOnClick,
              },
              index
            ) => (
              <Button
                key={index}
                type={type}
                color="primary"
                variant={buttonVariant ?? 'contained'}
                style={{ justifyContent: 'center' }}
                onClick={(event) => {
                  event.stopPropagation();
                  onClick?.(event);
                  !isKeptOpenOnClick && onClose();
                }}
                data-testid={dataTestId}
                startIcon={icon}
              >
                {title}
              </Button>
            )
          )}
        </DialogActions>
      </Box>
    )}
  </Dialog>
);

export default ConfirmationDialog;
