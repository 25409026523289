import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const tooltipContainerStyle = css`
  position: relative;
`;

export const tooltipIconStyle = css`
  color: ${colors.grey};
  position: absolute;
  top: 49px;
  right: -34px;
`;
