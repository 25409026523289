import React from 'react';
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';
import {
  Button,
  Popover,
  Typography,
  Box,
  Link,
  Checkbox,
  makeStyles,
} from '@material-ui/core';
import colors from 'styles/colors.module.scss';
import { useProductsSheetsContext } from 'modules/pim/baseProducts/BaseProducts';
import UncontrolledSimpleAutocomplete from 'components/UncontrolledSimpleAutocompelete';
import { ProductsFiltersActionCreator } from 'modules/pim/baseProducts/reducers/productsActions';
import { GetProducts_getProducts } from 'app/schemaInterfaces/GetProducts';
import { Skeleton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';

export interface PimFilterPopoverProps {
  products: GetProducts_getProducts | undefined;
}

export const PimFilterPopover = ({
  products,
}: PimFilterPopoverProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const { t } = useTranslation();

  const { dispatch, filter } = useProductsSheetsContext();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const resetFilter = () => {
    dispatch?.(ProductsFiltersActionCreator.clearFilters());
  };

  const handleDivisionsChange = (key: string, values: string[]) => {
    dispatch?.(
      ProductsFiltersActionCreator.updateDivisions({
        key,
        values: values.length > 0 ? values : undefined,
      })
    );
  };

  const handlePriceChange = (checked: boolean) => {
    dispatch?.(ProductsFiltersActionCreator.updatePrice(checked || undefined));
  };

  const handleUpdateFiles = (checked: boolean) => {
    dispatch?.(ProductsFiltersActionCreator.updateFiles(checked || undefined));
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const classes = makeStyles({
    buttonStyle: {
      color: colors.tooltip,
    },
    selectedButton: {
      color: colors.primary,
      backgroundColor: colors.menuHover,
    },
  })();

  return (
    <Box>
      <Button
        startIcon={<FilterListOutlinedIcon />}
        onClick={handleClick}
        classes={{ root: !open ? classes.buttonStyle : classes.selectedButton }}
      >
        <Typography variant="h5">{t('pim.filter.filter')}</Typography>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box width="520px" p={3}>
          {products ? (
            products.valuesOptionsDivisionFilter.map((divisionOptions) => (
              <Box display="flex" alignItems="center" key={divisionOptions.key}>
                <Box width="30%">
                  <Typography>{divisionOptions.label}</Typography>
                </Box>
                <Box width="70%">
                  <UncontrolledSimpleAutocomplete
                    value={
                      (filter?.details &&
                        (filter?.details[divisionOptions.key] as
                          | string[]
                          | undefined)) ??
                      []
                    }
                    options={divisionOptions.values}
                    placeholder={divisionOptions.label}
                    margin="dense"
                    fullWidth
                    onChange={(values) =>
                      handleDivisionsChange(divisionOptions.key, values)
                    }
                  />
                </Box>
              </Box>
            ))
          ) : (
            <Box display="flex" alignItems="center">
              <Box width="30%">
                <Skeleton width="70%" height="40px" />
                <Skeleton width="70%" height="40px" />
              </Box>
              <Box width="70%">
                <Skeleton width="100%" height="40px" />
                <Skeleton width="100%" height="40px" />
              </Box>
            </Box>
          )}
          <Box display="flex" alignItems="center" pt={1}>
            <Box display="flex" width="28%">
              <Typography variant="body2">{t('pim.filter.state')}</Typography>
            </Box>

            <Box display="flex" alignItems="center" pr={4}>
              <Checkbox
                disabled={products?.allWithPrice ?? false}
                checked={filter?.withoutPrice ?? false}
                onChange={(event, checked) => handlePriceChange(checked)}
              />
              <Typography
                color={products?.allWithPrice ? 'textSecondary' : 'inherit'}
              >
                {t('pim.filter.no_price')}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Checkbox
                disabled={products?.allWithVisual ?? false}
                checked={filter?.withoutFilesToSend ?? false}
                onChange={(event, checked) => handleUpdateFiles(checked)}
              />
              <Typography
                color={products?.allWithVisual ? 'textSecondary' : 'inherit'}
              >
                {t('pim.filter.no_visual')}
              </Typography>
            </Box>
          </Box>
          <Box pt={2}>
            <Link
              component="button"
              variant="body2"
              color="primary"
              underline="always"
              onClick={resetFilter}
            >
              {t('pim.filter.reinitiate_filters')}
            </Link>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};
