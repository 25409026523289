import { DefaultValues, useForm } from 'react-hook-form';
import { useCallback, useEffect, useMemo } from 'react';
import {
  ApolloQueryResult,
  useApolloClient,
  useMutation,
} from '@apollo/client';
import { SocialNetwork } from '../CompetitorsContainer/CompetitorsContainer.definition';
import {
  CREATE_COMPETITOR,
  IS_URL_VALID,
} from 'app/graphql/queries/competitors';
import {
  CreateCompetitor,
  CreateCompetitorVariables,
} from 'app/schemaInterfaces/CreateCompetitor';
import { yupResolver } from '@hookform/resolvers/yup';
import { TFunction } from 'i18next';
import { schema } from './AddCompetitorForm.schema';
import {
  IsUrlValid,
  IsUrlValidVariables,
} from 'app/schemaInterfaces/IsUrlValid';
import {
  GetCompetitors,
  GetCompetitorsVariables,
} from 'app/schemaInterfaces/GetCompetitors';

export interface FormValues {
  socialNetwork: SocialNetwork;
  url: string;
}

export function useCompetitorForm({
  onClose,
  t,
  refetch,
  entityId,
}: {
  onClose: (success: boolean) => void;
  t: TFunction;
  refetch: (
    variables?: Partial<GetCompetitorsVariables> | undefined
  ) => Promise<ApolloQueryResult<GetCompetitors>>;
  entityId?: string;
}) {
  const [createCompetitor] = useMutation<
    CreateCompetitor,
    CreateCompetitorVariables
  >(CREATE_COMPETITOR);

  const client = useApolloClient();

  const defaultValues: DefaultValues<FormValues> = useMemo(() => {
    return {
      socialNetwork: SocialNetwork.Facebook,
    };
  }, []);

  const form = useForm<FormValues>({
    defaultValues,
    resolver: yupResolver(schema(t)),
  });

  useEffect(() => form.reset(), [form, defaultValues]);

  const close = useCallback(
    async (success: boolean) => {
      form.reset();
      onClose(success);
      refetch();
    },
    [form, onClose, refetch]
  );

  const onSubmit = useCallback(
    async (formData: FormValues) => {
      if (entityId && formData.url) {
        const { data } = await client.query<IsUrlValid, IsUrlValidVariables>({
          query: IS_URL_VALID,
          variables: {
            url: formData.url,
          },
        });
        if (data?.isUrlValid.isValid) {
          await createCompetitor({
            variables: { values: { ...formData, entityId } },
          });
          close(true);
        } else {
          form.setError('url', {
            message: t('common.generic_error.incorrect_format'),
          });
        }
      } else {
        return;
      }
    },
    [client, close, createCompetitor, entityId, form, t]
  );

  return {
    handleClose: () => close(true),
    handleSubmit: form.handleSubmit(onSubmit),
    form,
    defaultValues,
  };
}
