import { FollowOrderStatus } from 'app/schemaInterfaces/globalTypes';
import useUpdateOrderStatusMutation from 'queries/useUpdateOrderStatusMutation';
import { useState } from 'react';
import { FollowOrderRefusalDialogFormData } from '../components/FollowOrderRefusalDialog/FollowOrderRefusalDialog';

export function useOrderValidationWorkflow() {
  const { updateOrderStatus } = useUpdateOrderStatusMutation();

  const [currentAcceptation, setCurrentAcceptation] = useState<
    string | undefined
  >(undefined);
  const [currentRefusal, setCurrentRefusal] = useState<string | undefined>(
    undefined
  );

  function startAcceptation(orderId: string) {
    setCurrentAcceptation(orderId);
  }

  function startRefusal(orderId: string) {
    setCurrentRefusal(orderId);
  }

  function cancel() {
    setCurrentAcceptation(undefined);
    setCurrentRefusal(undefined);
  }

  function confirmAcceptation() {
    if (!currentAcceptation) {
      return;
    }
    cancel();
    updateOrderStatus({
      values: {
        id: currentAcceptation,
        followStatus: FollowOrderStatus.Validated,
      },
    });
  }

  function confirmRefusal(data: FollowOrderRefusalDialogFormData) {
    if (!currentRefusal) {
      return;
    }
    cancel();
    updateOrderStatus({
      values: {
        id: currentRefusal,
        followStatus: FollowOrderStatus.Refused,
        statusComment: data.message,
      },
    });
  }

  return {
    startAcceptation,
    startRefusal,
    currentAcceptation,
    currentRefusal,
    cancel,
    confirmAcceptation,
    confirmRefusal,
  };
}
