import React, { ChangeEvent } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select, { SelectProps } from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Typography, Box } from '@material-ui/core';
import CustomPlaceholder from 'components/CustomPlaceholder/CustomPlaceholder';
import { useTranslation } from 'react-i18next';

interface UncontrolledMultipleValueSelectProps {
  label?: string;
  margin?: 'none' | 'dense' | 'normal';
  fullWidth?: boolean;
  options?: string[];
  handleChange: (
    event: ChangeEvent<{ name?: string; value: any }>,
    child: React.ReactNode
  ) => void;
  value: string[];
  placeholder?: string;
}

const isValueArrayOfString = (value: unknown): value is string[] => {
  return Array.isArray(value as string[]);
};

export const UncontrolledMultipleValueSelect = ({
  label,
  margin,
  fullWidth,
  options,
  handleChange,
  value,
  placeholder,
  ...selectProps
}: UncontrolledMultipleValueSelectProps & SelectProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <FormControl variant="outlined" margin={margin} fullWidth={fullWidth}>
      <Select
        labelId="demo-mutiple-checkbox-label"
        id="demo-mutiple-checkbox"
        multiple
        IconComponent={(props) => <KeyboardArrowDownIcon {...props} />}
        displayEmpty
        renderValue={(value) =>
          isValueArrayOfString(value) && value.length ? (
            value.join(', ')
          ) : (
            <CustomPlaceholder title={placeholder} />
          )
        }
        margin="dense"
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        {...selectProps}
        value={value}
        onChange={handleChange}
      >
        {options?.length !== 0 ? (
          options?.map((option, index: number) => (
            <MenuItem key={index} value={option ?? undefined}>
              {option && (
                <Checkbox
                  checked={value ? value.indexOf(option) > -1 : undefined}
                />
              )}
              <ListItemText primary={option} />
            </MenuItem>
          ))
        ) : (
          <Box textAlign="center">
            <Typography variant="body1">{t('global.no_label')}</Typography>
          </Box>
        )}
      </Select>
    </FormControl>
  );
};
