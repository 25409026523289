import React from 'react';

export function createContext<T>() {
  const context = React.createContext<T | undefined>(undefined);

  function useContext() {
    const useContext = React.useContext(context);
    if (!useContext)
      throw new Error('useContext must be inside a Provider with a value');
    return useContext;
  }
  // make TypeScript infer a tuple, not an array of union types
  return [useContext, context.Provider] as const;
}
