import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  AssetStatus,
  AssetType,
  VisualType,
} from 'app/schemaInterfaces/globalTypes';
import {
  ActionsUnion,
  createActionPayload,
} from 'modules/workflow/reducers/actionsType';
import {
  AssetSortOptions,
  AssetTagFilter,
} from 'modules/dam/reducers/filters/definitions';

export enum AssetFiltersActionTypes {
  ClearFilters,
  UpdateSearch,
  UpdateSort,
  UpdateAssetTypes,
  UpdateVisualTypes,
  UpdateTags,
  UpdateDivisions,
  UpdateValidityDates,
  UpdatePermanent,
  UpdateStatus,
}

interface UpdateDivisionPayload {
  key: string;
  values?: string[];
}

interface UpdateValidityDatesPayload {
  validFrom?: MaterialUiPickersDate;
  validTo?: MaterialUiPickersDate;
}

export const AssetFiltersActionCreator = {
  clearFilters: createActionPayload<
    typeof AssetFiltersActionTypes.ClearFilters,
    undefined
  >(AssetFiltersActionTypes.ClearFilters),
  updateSearch: createActionPayload<
    typeof AssetFiltersActionTypes.UpdateSearch,
    string
  >(AssetFiltersActionTypes.UpdateSearch),
  updateAssetTypes: createActionPayload<
    typeof AssetFiltersActionTypes.UpdateAssetTypes,
    AssetType[] | undefined
  >(AssetFiltersActionTypes.UpdateAssetTypes),
  updateVisualTypes: createActionPayload<
    typeof AssetFiltersActionTypes.UpdateVisualTypes,
    VisualType[] | undefined
  >(AssetFiltersActionTypes.UpdateVisualTypes),
  updateTags: createActionPayload<
    typeof AssetFiltersActionTypes.UpdateTags,
    AssetTagFilter[] | undefined
  >(AssetFiltersActionTypes.UpdateTags),
  updateDivisions: createActionPayload<
    typeof AssetFiltersActionTypes.UpdateDivisions,
    UpdateDivisionPayload
  >(AssetFiltersActionTypes.UpdateDivisions),
  updateValidityDates: createActionPayload<
    typeof AssetFiltersActionTypes.UpdateValidityDates,
    UpdateValidityDatesPayload
  >(AssetFiltersActionTypes.UpdateValidityDates),
  updatePermanent: createActionPayload<
    typeof AssetFiltersActionTypes.UpdatePermanent,
    boolean | undefined
  >(AssetFiltersActionTypes.UpdatePermanent),
  updateStatus: createActionPayload<
    typeof AssetFiltersActionTypes.UpdateStatus,
    AssetStatus[] | undefined
  >(AssetFiltersActionTypes.UpdateStatus),
  updateSort: createActionPayload<
    typeof AssetFiltersActionTypes.UpdateSort,
    AssetSortOptions
  >(AssetFiltersActionTypes.UpdateSort),
};

export type AssetFiltersActions = ActionsUnion<
  typeof AssetFiltersActionCreator
>;
