import { NotificationType } from 'app/schemaInterfaces/globalTypes';
import { TFunction } from 'i18next';

export const notificationsMessages = (
  type: NotificationType,
  metadata: Record<string, any>,
  t: TFunction
) => {
  switch (type) {
    case NotificationType.CAMPAIGN_CREATED:
      return t('notifications.messages.campaign', {
        campaignName: metadata.campaignName,
      });
    case NotificationType.THEMATIC_CREATED:
      return t('notifications.messages.thematic', {
        campaignName: metadata.campaignName,
      });
    case NotificationType.THEMATIC_DOCUMENT_CREATED:
      return t('notifications.messages.new_thematic_document', {
        campaignName: metadata.campaignName,
      });
    case NotificationType.THEMATIC_DOCUMENT_ENDED:
      return t('notifications.messages.end_thematic_document', {
        campaignName: metadata.campaignName,
      });
    case NotificationType.COMMITMENT_DOCUMENT_ENDED:
      return t('notifications.messages.end_commitment_document', {
        campaignName: metadata.campaignName,
      });
    case NotificationType.COMMITMENT_REMINDER:
      return t('notifications.messages.commitment_reminder', {
        campaignName: metadata.campaignName,
      });
    case NotificationType.CAMPAIGN_STARTED:
      return t('notifications.messages.campaign_started', {
        campaignName: metadata.campaignName,
      });
    case NotificationType.COMMITMENT_REMINDER_ACTION:
      return t('notifications.messages.commitment_reminder', {
        campaignName: metadata.campaignName,
      });
    case NotificationType.ORDER_DETAIL_FAILED:
      return t('notifications.messages.order_detail_failed');
    case NotificationType.ORDER_SEND_SUCCESS:
      return t('notifications.messages.order_send_success');
    case NotificationType.BASKET_REMINDER:
      return t('notifications.messages.basket_reminder');
    case NotificationType.ORDER_VALIDATION_REQUIRED:
      return t('notifications.messages.order_validation_required', {
        orderId: metadata.orderId,
      });
    case NotificationType.ORDER_ACCEPTED:
      return t('notifications.messages.order_accepted', {
        orderId: metadata.orderId,
      });
    case NotificationType.ORDER_REFUSED:
      return t('notifications.messages.order_refused', {
        orderId: metadata.orderId,
      });
    default:
      return '';
  }
};
