import { useQuery } from '@apollo/client';
import useAppContext from 'app/app-context/useAppContext';
import { GET_OFFERS_BY_IDS } from 'app/graphql/queries/offers';
import { ModuleKey } from 'app/routes/helper';
import { GetOffersByIds } from 'app/schemaInterfaces/GetOffersByIds';

const useClientOffers = () => {
  const {
    client: { offerIds },
  } = useAppContext();

  const { data: dataOffers } = useQuery<GetOffersByIds>(GET_OFFERS_BY_IDS, {
    variables: { offerIds: offerIds },
  });
  const offerKeys = dataOffers?.getOffersByIds.map((offer) => offer.key);

  const hasOffers = (offers: ModuleKey[]) =>
    offers.every((offer) => {
      return offerKeys?.includes(offer);
    });

  return { offerKeys, hasOffers };
};

export default useClientOffers;
