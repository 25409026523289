import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const competitorsStyle = css`
  .breadcrumbs {
    padding-right: 42px;
    padding-left: 42px;
  }
  .logo {
    margin-bottom: 44px;
  }
  .container {
    text-align: center;
    margin-top: 80px;
  }
  .add-container {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    .add {
      background-color: ${colors.menuHover};
      border-radius: 10px;
      width: 380px;
      padding: 70px;
      h5 {
        color: ${colors.primary};
        margin-top: 14px;
      }
    }
    .button {
      width: 252px;
    }
  }
`;
