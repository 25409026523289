import React, { useEffect } from 'react';
import { Field, isInteger, useFormikContext } from 'formik';
import {
  Box,
  FormLabel,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@material-ui/core';
import ControlledSelect from 'components/ControlledSelect/ControlledSelect';
import useAppContext from 'app/app-context/useAppContext';

import ProductsSheetFilesUpload from 'modules/pim/baseProducts/ProductsSheetFilesUpload';
import { PimConfigFields } from 'modules/pim/baseProducts/PimFormConfigTypes';
import { PreviewFile } from 'components/ControlledFileUpload/ControlledFileUpload';
import { AssetType, MetadataInput } from 'app/schemaInterfaces/globalTypes';
import { GET_DIVISIONS as I_GET_DIVISIONS } from 'app/schemaInterfaces/GET_DIVISIONS';
import { useQuery } from '@apollo/client';
import { GET_DIVISIONS } from 'app/graphql/queries/divisions';
import {
  useDivisionSelect,
  DivisionSelectModel,
} from 'components/divisions/useDivisionSelect';
import UncontrolledSelect from 'components/UncontrolledSelect';
import {
  getUnifiedKeyFromPimClientConfig,
  unifiedKeyField,
} from 'modules/pim/baseProducts/ProductsHelper';
import colors from 'styles/colors.module.scss';
import RadioBoxsItems from 'components/RadioBoxsItems/RadioBoxsItems';
import { ChipSwitch } from 'components/Switch/ChipSwitch';

interface PimFormFieldsConfigProps {
  field: PimConfigFields;
  values: Record<string, any>;
  fieldIndex: number;
  isDisabled?: boolean;
  productId?: string;
}

export interface FileStore {
  fieldName: string;
  fieldFile?: PreviewFile;
  base64File?: string;
  fileName?: string | string[];
  assetId?: string;
  fileAssetsType?: AssetType;
  isConform?: boolean;
  metadata?: MetadataInput;
}

interface FieldMap {
  text: JSX.Element;
  select: JSX.Element;
  childselect: JSX.Element;
  damone: JSX.Element;
  dammultiple: JSX.Element;
  selectgroup: JSX.Element;
  checkbox: JSX.Element;
  radiobox: JSX.Element;
  [key: string]: JSX.Element;
}

interface OptionChange<FieldType, HTMLType = HTMLInputElement> {
  event: React.ChangeEvent<HTMLType>;
  field: FieldType;
}

interface OptionMultipleCheckboxChange<FieldType, HTMLType = HTMLInputElement> {
  event: React.ChangeEvent<HTMLType>;
  field: FieldType;
  checkboxValue: { value: string; label: string; key: string };
}

export enum PimFieldType {
  Text = 'text',
  Select = 'select',
  DamOne = 'damone',
  DamMultiple = 'Dammultiple',
  SelectGroup = 'selectgroup',
  Checkbox = 'checkbox',
}

const PimFormFieldsConfig = ({
  field,
  values,
  fieldIndex,
  isDisabled = false,
  productId,
}: PimFormFieldsConfigProps): JSX.Element => {
  const {
    client: { config: clientConfig },
  } = useAppContext();

  const {
    setFieldValue,
    getFieldMeta,
    touched,
    errors,
    handleBlur,
  } = useFormikContext<Record<string, any>>();

  const { data } = useQuery<I_GET_DIVISIONS>(GET_DIVISIONS);

  const getFieldKey = (divisionKey: string) => `${divisionKey}`;
  const getFieldValue = (key: string) =>
    getFieldMeta<string | null | undefined>(getFieldKey(key)).value;
  const { selectModels, getOptions } = useDivisionSelect(data, getFieldValue);

  const showFields = () => {
    if (
      !!field.parentFieldKey.length &&
      values[field.parentFieldKey]?.toString().includes(field.parentFieldValue)
    ) {
      return 'inline';
    } else if (!field.parentFieldKey) {
      return 'auto';
    } else {
      return 'none';
    }
  };

  useEffect(() => {
    if (
      !!field.parentFieldKey.length &&
      !values[field.parentFieldKey]?.toString().includes(field.parentFieldValue)
    ) {
      setFieldValue(field.key, '');
    }
  }, [
    field.key,
    field.parentFieldKey,
    field.parentFieldValue,
    setFieldValue,
    values,
    field,
  ]);

  const onTextFieldChange = ({
    event,
    field,
  }: OptionChange<PimConfigFields, HTMLInputElement | HTMLTextAreaElement>) => {
    values[field.key] = event.target.value;
    values[
      getUnifiedKeyFromPimClientConfig(unifiedKeyField.Price, clientConfig)
    ]
      ? setFieldValue(field.key, event.target.value.replace(/,/g, '.'))
      : setFieldValue(field.key, event.target.value);
  };

  const onCheckboxFieldChange = ({
    event,
    field,
  }: OptionChange<PimConfigFields>) => {
    values[field.key] = event.target.checked;
    setFieldValue(field.key, event.target.checked ? event.target.checked : '');
  };

  const onMultipleCheckboxFieldChange = ({
    event,
    checkboxValue,
    field,
  }: OptionMultipleCheckboxChange<PimConfigFields>) => {
    values[checkboxValue.key] = event.target.checked;
    setFieldValue(checkboxValue.key, event.target.checked);
    let selectedCheckboxValue: string[] = [];
    field.valueHardcoded.map(({ key: keyValue, label }) => {
      return Object.entries(values).forEach(([key, value]) => {
        if (value && key === keyValue) {
          selectedCheckboxValue.push(label);
        }
      });
    });
    setFieldValue(field.key, selectedCheckboxValue.join(', '));
  };

  const handleChange = (selectModel: DivisionSelectModel, value: string) => {
    setFieldValue(getFieldKey(selectModel.key), value);
    selectModel.children.forEach((key) => {
      setFieldValue(getFieldKey(key), null);
    });
  };

  const handleInputNumberFormat = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    // to prevent UI refresh when we press other keystrokes than numbers
    if (!isInteger(event.key) && event.key !== '.' && event.key !== ',') {
      event.preventDefault();
    }
  };

  const disableTextField = () => {
    if (
      values[
        getUnifiedKeyFromPimClientConfig(
          unifiedKeyField.ProductCode,
          clientConfig
        )
      ] &&
      field.key ===
        getUnifiedKeyFromPimClientConfig(
          unifiedKeyField.InternalCode,
          clientConfig
        )
    ) {
      return (
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          style={{ pointerEvents: 'none', backgroundColor: colors.disabled }}
        />
      );
    }
    if (
      values[
        getUnifiedKeyFromPimClientConfig(
          unifiedKeyField.InternalCode,
          clientConfig
        )
      ] &&
      field.key ===
        getUnifiedKeyFromPimClientConfig(
          unifiedKeyField.ProductCode,
          clientConfig
        )
    ) {
      return (
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          style={{ pointerEvents: 'none', backgroundColor: colors.disabled }}
        />
      );
    }
    return (
      <TextField
        disabled={isDisabled}
        error={Boolean(errors[field.key]) && Boolean(touched[field.key])}
        placeholder={field?.label}
        name={field?.key}
        variant="outlined"
        onChange={(event) => onTextFieldChange({ event, field })}
        margin="normal"
        fullWidth
        value={values[field.key]}
        onBlur={handleBlur}
        helperText={touched[field.key] && errors[field.key]}
        multiline={true}
        size="small"
        type={
          field.key ===
          getUnifiedKeyFromPimClientConfig(
            unifiedKeyField.ProductCode,
            clientConfig
          )
            ? 'number'
            : undefined
        }
        onKeyPress={
          field.key ===
          getUnifiedKeyFromPimClientConfig(unifiedKeyField.Price, clientConfig)
            ? handleInputNumberFormat
            : undefined
        }
      />
    );
  };

  const textfieldsFormRenderer = (field: PimConfigFields) => {
    return (
      <Box key={field.key} display={showFields()}>
        <Box pt={2} display="flex">
          <FormLabel>{field.label} </FormLabel>
          {field.required && <Box>*</Box>}
        </Box>
        {disableTextField()}
      </Box>
    );
  };
  const selectBoxFormRenderer = (field: PimConfigFields) => (
    <Box display={showFields()}>
      <Box pt={2} pb={2}>
        <FormLabel>{field.label}</FormLabel>
      </Box>
      <Field
        name={field.key}
        options={field.valueHardcoded?.map(({ value }) => ({
          label: value,
          value,
        }))}
        component={ControlledSelect}
        fullWidth
        variant="outlined"
        placeholder={field.placeholder}
      />
    </Box>
  );

  const childSelectBoxFormRenderer = (field: PimConfigFields) => (
    <Box>
      <Box pt={2} pb={2}>
        <FormLabel>{field.label}</FormLabel>
      </Box>
      <Field
        name={field.key}
        options={field.valueHardcoded?.filter(
          (value) =>
            value.parentValueKey === values[field.parentFieldKey as string]
        )}
        component={ControlledSelect}
        fullWidth
        select
        variant="outlined"
        placeholder={field.placeholder}
      />
    </Box>
  );

  const checkboxFormRenderer = (field: PimConfigFields, index: number) => (
    <Box key={index} display="grid">
      <Box pt={2}>
        <FormLabel>{field.label}</FormLabel>
      </Box>
      <FormControlLabel
        control={
          <Checkbox
            checked={Boolean(values[field.key])}
            onChange={(event) => onCheckboxFieldChange({ event, field })}
            name={field.label}
            color="primary"
          />
        }
        label={<Typography>{field.placeholder}</Typography>}
      />
    </Box>
  );

  const switchFormRenderer = (field: PimConfigFields) => (
    <Box key={field.editIndex}>
      <Box pt={2}>
        <FormLabel>{field.label}</FormLabel>
      </Box>
      <ChipSwitch
        checked={Boolean(values[field.key])}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          onCheckboxFieldChange({ event, field })
        }
        name={field.label}
        color="primary"
      />
    </Box>
  );

  const multipleCheckboxFormRenderer = (
    field: PimConfigFields,
    index: number
  ) => (
    <Box key={index} pt={1}>
      <Box pt={2}>
        <FormLabel>{field.label}</FormLabel>
      </Box>
      <Box display="flex">
        {field.valueHardcoded.map((checkboxValue, index) => (
          <Box key={index}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(values[checkboxValue.key])}
                  onChange={(event) =>
                    onMultipleCheckboxFieldChange({
                      event,
                      checkboxValue,
                      field,
                    })
                  }
                  name={checkboxValue.label}
                  color="primary"
                />
              }
              label={checkboxValue.label}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );

  const singlePictureFormRenderer = (field: PimConfigFields, index: number) => (
    <Box key={index} display={showFields()}>
      <Box pt={2}>
        <FormLabel>{field.label}</FormLabel>
      </Box>
      <Field
        name={field.key}
        component={ProductsSheetFilesUpload}
        numberOfFiles={1}
        placeholder={field.placeholder}
        productId={productId}
      />
    </Box>
  );

  const multiplePicturesFormRenderer = (
    field: PimConfigFields,
    index: number,
    limit: number
  ) => (
    <Box key={index}>
      <Box pt={2}>
        <FormLabel>{field.label}</FormLabel>
      </Box>
      <Field
        name={field.key}
        component={ProductsSheetFilesUpload}
        numberOfFiles={limit}
        placeholder={field.placeholder}
      />
    </Box>
  );

  const radioboxFormRenderer = (field: PimConfigFields) => (
    <Box>
      <Box pt={2}>
        <FormLabel>{field.label}</FormLabel>
      </Box>
      <Field
        name={field.key}
        itemsPerLine={1}
        initialSelectedValue={values[field.key]}
        radioValues={field.valueHardcoded?.map(({ value }) => ({
          id: value,
          value,
        }))}
        isBorderAndBackground={false}
        component={RadioBoxsItems}
        placeholder={field.placeholder}
      />
    </Box>
  );

  const divisionsRenderer = () => (
    <Box>
      {selectModels.map((selectModel) => {
        return (
          <Box key={selectModel.key}>
            <Box pt={2}>
              <FormLabel>{selectModel.label}</FormLabel>
            </Box>
            <Box pt={2}>
              <UncontrolledSelect
                placeholder={selectModel.label}
                value={getFieldValue(selectModel.key) ?? ''}
                // TODO: divisions in backend with sorting
                options={getOptions(selectModel).sort((first, second) =>
                  first.value && second.value
                    ? first.value
                        .toString()
                        .localeCompare(second.value.toString())
                    : 0
                )}
                usePlaceholderItem={true}
                onChange={({ target: { value } }) =>
                  handleChange(selectModel, value)
                }
              />
            </Box>
          </Box>
        );
      })}
    </Box>
  );

  const fieldMap: FieldMap = {
    text: textfieldsFormRenderer(field),
    select: selectBoxFormRenderer(field),
    radiobox: radioboxFormRenderer(field),
    childselect: childSelectBoxFormRenderer(field),
    damone: singlePictureFormRenderer(field, fieldIndex),
    dammultiple: multiplePicturesFormRenderer(field, fieldIndex, Infinity),
    selectgroup: divisionsRenderer(),
    checkbox: checkboxFormRenderer(field, fieldIndex),
    multiplecheckbox: multipleCheckboxFormRenderer(field, fieldIndex),
    dammultiplelogospictos: multiplePicturesFormRenderer(field, fieldIndex, 3),
    switch: switchFormRenderer(field),
  };
  return fieldMap[field.type];
};

export default PimFormFieldsConfig;
