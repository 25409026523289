import { Box, TextField, Typography } from '@material-ui/core';
import FormWrapper from 'components/FormWrapper/FormWrapper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  PersonalizationItem,
  PersonalizationSubType,
} from 'modules/workflow/oneStudioContext';
import TypographyDateDialogContent from 'modules/workflow/containers/CatalogPreview/Personalization/TypographyDateDialogContent';

interface TypographyItemFormContainerProps {
  handleValidationAction: () => void;
  closeDialog: () => void;
  isDialogOpen: boolean;
  title: string;
  typographyItem: PersonalizationItem;
  setTypographyItem: React.Dispatch<React.SetStateAction<PersonalizationItem>>;
}

const TypographyItemFormContainer = ({
  handleValidationAction,
  closeDialog,
  isDialogOpen,
  title,
  typographyItem,
  setTypographyItem,
}: TypographyItemFormContainerProps): JSX.Element => {
  const { t } = useTranslation();

  const actionButtons = [
    {
      name: t('workflow.validate'),
      handleClick: () => handleValidationAction(),
    },
  ];

  return (
    <FormWrapper
      open={isDialogOpen}
      handleClose={closeDialog}
      title={title}
      actionButtons={actionButtons}
      maxWidth="xs"
    >
      {typographyItem.subType === PersonalizationSubType.DATE ? (
        <TypographyDateDialogContent
          item={typographyItem}
          setItemDates={setTypographyItem}
          startDateLabel={t('workflow.typography_item.start_date')}
          endDateLabel={t('workflow.typography_item.end_date')}
        />
      ) : (
        <Box my={2}>
          <Typography variant="h5">
            {t('workflow.typography_item.text')}
          </Typography>
          <TextField
            variant="outlined"
            multiline={true}
            rows={4}
            fullWidth
            value={typographyItem.text?.title}
            onChange={(event) =>
              setTypographyItem({
                ...typographyItem,
                text: { ...typographyItem?.text, title: event.target.value },
              })
            }
          />
        </Box>
      )}
    </FormWrapper>
  );
};

export default TypographyItemFormContainer;
