import React, { ChangeEvent } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl, { FormControlProps } from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select, { SelectProps } from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CustomPlaceholder from 'components/CustomPlaceholder/CustomPlaceholder';
export interface Option {
  value: string;
  label: string;
}

interface UncontrolledMultipleValueSelectV2Props {
  value: string[];
  handleChange: (
    event: ChangeEvent<{ name?: string; value: any }>,
    child: React.ReactNode
  ) => void;
  options: Option[];
  label?: string;
  margin?: FormControlProps['margin'];
  isFullWidth?: boolean;
  placeholder?: string;
}
const isValueArrayOfString = (value: unknown): value is string[] => {
  return Array.isArray(value as string[]);
};
export const UncontrolledMultipleValueSelectV2 = ({
  label,
  margin,
  isFullWidth,
  options,
  handleChange,
  value,
  placeholder,
  ...selectProps
}: UncontrolledMultipleValueSelectV2Props & SelectProps): JSX.Element => {
  const renderValue = (value: unknown) => {
    if (isValueArrayOfString(value) && value.length) {
      const labels: string[] = [];
      options.forEach(
        (option) => value.includes(option.value) && labels.push(option.label)
      );
      return labels.join(', ');
    } else return <CustomPlaceholder title={placeholder} />;
  };

  return (
    <FormControl variant="outlined" margin={margin} fullWidth={isFullWidth}>
      <Select
        labelId="mutiple-checkbox-label"
        id="mutiple-checkbox"
        multiple
        IconComponent={(props) => <KeyboardArrowDownIcon {...props} />}
        displayEmpty
        renderValue={(value) => renderValue(value)}
        margin="dense"
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        {...selectProps}
        value={value}
        onChange={handleChange}
      >
        {options?.map((option, index: number) => (
          <MenuItem key={index} value={option.value ?? undefined}>
            {option && (
              <Checkbox
                checked={value ? value.indexOf(option.value) > -1 : undefined}
              />
            )}
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
