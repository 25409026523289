import React from 'react';
import { Box } from '@material-ui/core';
import BaseProductsList from 'modules/pim/baseProducts/BaseProductsList';
import { createContext } from 'app/utils/context';
import { FileStore } from 'modules/pim/baseProducts/PimFormFieldsConfig';
import { ProductsFiltersActions } from 'modules/pim/baseProducts/reducers/productsActions';
import { ProductFilterInput } from 'app/schemaInterfaces/globalTypes';
import { useTranslation } from 'react-i18next';
import { PimRegrouping } from './PimFormConfigTypes';
import { css } from '@emotion/react';

export interface ProductsSheetsContextState {
  fieldName: string | undefined;
  setFieldName: React.Dispatch<React.SetStateAction<string | undefined>>;
  formFilesManager: FileStore[];
  setFormFilesManager: React.Dispatch<React.SetStateAction<FileStore[]>>;
  filesLimitNumber: number;
  setFilesLimitNumber: React.Dispatch<React.SetStateAction<number>>;
  filter?: ProductFilterInput;
  dispatch?: React.Dispatch<ProductsFiltersActions>;
  regrouping: PimRegrouping[];
  setRegrouping: React.Dispatch<React.SetStateAction<PimRegrouping[]>>;
}

export const [
  useProductsSheetsContext,
  ProductsSheetsStateProvider,
] = createContext<ProductsSheetsContextState>();

const style = css`
  h1 {
    margin-top: 42px;
    margin-left: 50px;
  }
`;

const BaseProducts = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Box position="relative" css={style}>
      <h1>{t('pim.products_base')} </h1>
      <BaseProductsList />
    </Box>
  );
};

export default BaseProducts;
