import React from 'react';
import { Switch } from 'react-router-dom';
import ProtectedRoute from 'components/Navigation/ProtectedRoute/ProtectedRoute';
import BasketPage from 'modules/sales/pages/basket-page/BasketPage/BasketPage';
import BasketSteps from './pages/basket-page/BasketSteps';
import { SalesRoute } from './salesHelpers';

const SalesModule = () => (
  <Switch>
    <ProtectedRoute path={'/sales/panier'} open={true}>
      <BasketPage />
    </ProtectedRoute>
    <ProtectedRoute path={SalesRoute.CommitmentsSteps} open={true}>
      <BasketSteps />
    </ProtectedRoute>
  </Switch>
);

export default SalesModule;
