import { GetOrderById_getOrderById } from 'app/schemaInterfaces/GetOrderById';
import TableComponent from 'components/TableComponent/TableComponent';
import moment from 'moment';
import React from 'react';
import { GetOrderDeliveries_orderDeliveries } from 'app/schemaInterfaces/GetOrderDeliveries';
import { followOrderDeliveryStyle } from './FollowOrderDelivery.style';
import { useFollowOrderDelivery } from './FollowOrderDelivery.hooks';

const FollowOrderDelivery = ({
  order,
  orderDeliveryData,
}: {
  order?: GetOrderById_getOrderById;
  orderDeliveryData?: GetOrderDeliveries_orderDeliveries;
}) => {
  const { tableRowData, tableHeadData, t } = useFollowOrderDelivery(
    orderDeliveryData
  );

  return (
    <div css={followOrderDeliveryStyle}>
      <h3>{t('follow_orders.delivery_follow.delivery_follow')}</h3>
      <span className="order-subtitle">
        {t('follow_orders.last_update')}{' '}
        {moment(order?.updatedAt).format('DD/MM/YY')}
      </span>
      {
        <div className="table-container">
          <TableComponent
            tableHeadData={tableHeadData}
            tableRowData={tableRowData}
          />
        </div>
      }
    </div>
  );
};
export default FollowOrderDelivery;
