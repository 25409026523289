import { useState } from 'react';
import {
  UploadProgress,
  UploadStatus,
} from 'components/UploadProgressSnackbar';

export const useUploadProgress = () => {
  const [uploadProgress, setUploadProgress] = useState<
    Record<string, UploadProgress>
  >({});

  const queueProgress = (name: string) => {
    setUploadProgress((uploadProgress) => {
      return {
        ...uploadProgress,
        [name]: {
          name: name,
          status: UploadStatus.InQueue,
        },
      };
    });
  };

  const initProgress = (name: string) => {
    setUploadProgress((uploadProgress) => {
      return {
        ...uploadProgress,
        [name]: {
          name: name,
          status: UploadStatus.InProgress,
        },
      };
    });
  };

  const reportProgress = (name: string, progress: number) => {
    setUploadProgress((uploadProgress) => {
      const currentItemProgress = uploadProgress[name];
      if (!currentItemProgress) return uploadProgress;
      return {
        ...uploadProgress,
        [name]: {
          ...currentItemProgress,
          progress,
        },
      };
    });
  };

  const reportSuccess = (name: string) => {
    setUploadProgress((uploadProgress) => {
      const currentItemProgress = uploadProgress[name];
      if (!currentItemProgress) return uploadProgress;
      return {
        ...uploadProgress,
        [name]: {
          ...currentItemProgress,
          status: UploadStatus.Success,
        },
      };
    });
  };

  const reportError = (name: string, error: string) => {
    setUploadProgress((uploadProgress) => {
      const currentItemProgress = uploadProgress[name];
      if (!currentItemProgress) return uploadProgress;
      return {
        ...uploadProgress,
        [name]: {
          ...currentItemProgress,
          status: UploadStatus.Failed,
          errorMessage: error,
        },
      };
    });
  };

  const clearProgress = () => {
    setUploadProgress({});
  };

  return {
    uploadProgress: Object.values(uploadProgress),
    initProgress,
    queueProgress,
    reportProgress,
    reportSuccess,
    reportError,
    clearProgress,
  };
};
