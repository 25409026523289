import { css } from '@emotion/react';

export const addCompetitorFormStyle = css`
  min-width: 500px;
  .MuiFormGroup-root {
    padding-right: 20px;
    padding-left: 20px;
  }
  label {
    font-size: 14px;
    font-weight: bold;
  }
  .MuiSelect-root {
    height: 22px;
  }
  .MuiInputBase-root {
    height: 40px;
    margin-top: 4px;
    margin-bottom: 16px;
  }
`;
