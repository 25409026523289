import React, { PropsWithChildren, FC } from 'react';
import { labelWrapperStyle } from './Label.style';

export interface LabelProps {
  error?: boolean;
  required?: boolean;
  TitleWrapper?: React.ElementType<any>;
}

/**
 * Equivalent of FormLabel of Material UI
 * (it works with Typography while we use h1, ..., h6 )
 * To display * if a field is required
 * and the textRrror becomes red when there is an error
 */
const Label: FC<PropsWithChildren<LabelProps>> = ({
  children,
  TitleWrapper = 'h3',
  error,
  required,
}) => (
  <TitleWrapper css={error && labelWrapperStyle}>
    {children} {required && '*'}
  </TitleWrapper>
);

export default Label;
