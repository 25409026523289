import { makeStyles, Tab, Tabs } from '@material-ui/core';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AssetsPanelActionCreator } from 'modules/workflow/containers/Leftsidebar/AssetsPanel/reducer/actions';
import { AssetsPanelContext } from 'modules/workflow/containers/Leftsidebar/AssetsPanel/reducer/context';
import { AssetDataSource } from 'modules/workflow/containers/Leftsidebar/AssetsPanel/reducer/definitions';
import colors from 'styles/colors.module.scss';

interface AssetPanelTabsProps {
  dataSource: AssetDataSource;
}

const useStyles = makeStyles({
  tabsCentered: {
    alignItems: 'center',
  },
  tabsIndicator: {
    backgroundColor: colors.white,
  },
  tabRoot: {
    '&:hover': {
      color: colors.white,
      opacity: 1,
    },
    '&.Mui-selected': {
      backgroundColor: colors.documentsTab,
      color: colors.white,
    },
    '&:focus': {
      color: colors.white,
    },
    '@media (min-width: 600px)': {
      minWidth: 'unset',
    },
  },
});

const AssetPanelTabs = ({ dataSource }: AssetPanelTabsProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useContext(AssetsPanelContext);
  const classes = useStyles();

  return (
    <Tabs
      classes={{
        centered: classes.tabsCentered,
        indicator: classes.tabsIndicator,
      }}
      orientation="horizontal"
      variant="fullWidth"
      value={dataSource}
      onChange={(event, value: AssetDataSource) =>
        dispatch?.(AssetsPanelActionCreator.switchDataSource(value))
      }
    >
      <Tab
        classes={{ root: classes.tabRoot }}
        label={t('workflow.left_side_bar.medias_panel.campaign')}
        value={AssetDataSource.CAMPAIGN}
      />
      <Tab
        classes={{ root: classes.tabRoot }}
        label={t('workflow.left_side_bar.medias_panel.dam')}
        value={AssetDataSource.DAM}
      />
    </Tabs>
  );
};

export default AssetPanelTabs;
