import { Divider } from '@material-ui/core';
import { css } from '@emotion/react';
import useCurrentEntity from 'app/current-entity-context/useCurrentEntity';
import useBillingAddressesQuery from 'queries/useBillingAddressesQuery';
import { GetEntityBillingAdresses_entityBillingAdresses } from 'app/schemaInterfaces/GetEntityBillingAdresses';
import { ChangeEvent, useEffect } from 'react';
import { useStoreActions, useStoreState } from 'state/store';
import SelectBillingAddresses from './SelectBillingAddresses';
import { useTranslation } from 'react-i18next';

const style = css`
  .header {
    display: flex;
    justify-content: space-between;
  }
  .address-informations {
    margin-top: 16px;
    span {
      display: block;
      line-height: 24px;
    }
    .name {
      font-weight: bold;
    }
  }
  .MuiDivider-root {
    margin: 8px 0px 16px 0px;
  }
  .title {
    font-size: 1.25rem;
    font-family: 'ScandiaWebMedium';
    font-weight: bold;
    line-height: 34px;
  }
`;

const BillingAddressBlock = () => {
  const { t } = useTranslation();
  const { currentEntity } = useCurrentEntity();

  const {
    data: billingAddresses,
    loading: billingAddressesLoading,
  } = useBillingAddressesQuery(currentEntity?.id);

  const currentBillingAddress = useStoreState(
    (state) => state.salesModule.billingAddress.billingAddress
  );

  const setBillingAddress = useStoreActions(
    (actions) => actions.salesModule.billingAddress.setBillingAddress
  );

	useEffect(() => {
		if (!currentBillingAddress || (currentEntity && currentBillingAddress && currentEntity.name !== currentBillingAddress.name)) {
			setBillingAddress(billingAddresses?.entityBillingAdresses[0]);
		}
	}, [billingAddresses, currentBillingAddress, currentEntity, setBillingAddress]);


	const setIsBasketPending = useStoreActions(
    (actions) => actions.salesModule.basketStatus.setIsBasketPending
  );

  useEffect(() => {
    setIsBasketPending(billingAddressesLoading);
  }, [billingAddressesLoading, setIsBasketPending]);

  const availableOptions:
    | GetEntityBillingAdresses_entityBillingAdresses[]
    | undefined = billingAddresses?.entityBillingAdresses.filter(
    (billingAddess) => billingAddess.billingAddress
  );

  const handleChange = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    const newValue = availableOptions?.find(
      (address) => address.name === event.target.value
    );
    newValue && setBillingAddress(newValue);
  };

  const currentAddress = currentBillingAddress?.billingAddress;
  return (
    <div css={style}>
      <div className="header">
        <span className="title">{t('sales.basket_page.billing_address')}</span>
      </div>
      <Divider />
      {availableOptions && availableOptions?.length > 1 && (
        <SelectBillingAddresses
          handleChange={handleChange}
          defaultBillingAddress={billingAddresses?.entityBillingAdresses[0]}
          availableOptions={availableOptions}
        />
      )}

      <div className="address-informations">
        <span className="name">{currentAddress?.name}</span>
        <span>
          {currentAddress?.firstAddress} {currentAddress?.secondAddress}{' '}
          {currentAddress?.thirdAddress}
        </span>
        <span>
          {currentAddress?.zipCode} {currentAddress?.postBox}
        </span>
        <span>{currentAddress?.country}</span>
      </div>
    </div>
  );
};

export default BillingAddressBlock;
