import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { List, ListItem } from '@material-ui/core';
import {
  GetBasketDocuments,
  GetBasketDocumentsVariables,
  GetBasketDocuments_basket_basketDocuments,
} from 'app/schemaInterfaces/GetBasketDocuments';
import BasketRow from 'modules/sales/components/BasketRow/BasketRow';
import moment from 'moment';
import { SelectedBasketdocument } from 'modules/sales/sales.state';
import colors from 'styles/colors.module.scss';
import ListItemButton from 'components/List/ListItemButton/ListItemButton';
import { ApolloQueryResult } from '@apollo/client';
import { BasketType } from 'app/schemaInterfaces/globalTypes';
import Tag from 'modules/pro/components/Tag';
import { ActionCreator } from 'easy-peasy';
import OrderTerminatedTag from 'modules/shop/components/OrderTerminatedTag';
import {
  basketDateTag,
  getCampaigns,
  getLastDate,
} from 'modules/sales/salesHelpers';
import { isDocumentTerminated } from 'modules/shop/shopHelpers';
import style from './baskekContent.style';

const BasketContent = ({
  basketDocuments,
  refetch,
  basketType,
  selectedBasketDocuments,
  setSelectedBasketDocuments,
  selectedBasketType,
  setCampaignName,
  campaignName,
}: {
  basketDocuments: GetBasketDocuments_basket_basketDocuments[] | undefined;
  refetch: (
    variables?: Partial<GetBasketDocumentsVariables> | undefined
  ) => Promise<ApolloQueryResult<GetBasketDocuments>>;
  basketType: BasketType;
  selectedBasketType: BasketType;
  selectedBasketDocuments: SelectedBasketdocument[];
  setSelectedBasketDocuments: Dispatch<
    SetStateAction<SelectedBasketdocument[]>
  >;
  setCampaignName: ActionCreator<string | undefined>;
  campaignName: string | undefined;
}) => {
  const { t } = useTranslation();

  const selectedCampaign = selectedBasketDocuments.map(
    (basketDocumentItem) =>
      basketDocumentItem.basketDocument.document.campaign.id
  );

  const isCampaignSelected = (id: string) => {
    return selectedBasketType === BasketType.Commitment
      ? selectedCampaign.length > 0
      : selectedCampaign.length > 0 && selectedCampaign.includes(id);
  };

  const addToSelectedDocuments = (
    basketDocument: GetBasketDocuments_basket_basketDocuments,
    isChecked: boolean
  ) => {
    const selectedCampaignName = basketDocument.document.campaign.name;
    if (isChecked) {
      setSelectedBasketDocuments([
        ...selectedBasketDocuments,
        { basketDocument, addresses: [] },
      ]);
      if (campaignName) {
        const campaignNames = [campaignName, selectedCampaignName];
        setCampaignName(campaignNames.sort()[0]);
      } else setCampaignName(selectedCampaignName);
    } else {
      const filteredBasketDocuments = selectedBasketDocuments.filter(
        (document) =>
          basketDocument.document.id !== document.basketDocument.document.id
      );
      setSelectedBasketDocuments(filteredBasketDocuments);
      if (
        !filteredBasketDocuments.some(
          ({ basketDocument }) =>
            basketDocument.document.campaign.name === selectedCampaignName
        )
      ) {
        const campaignNames = filteredBasketDocuments.map(
          ({ basketDocument }) => basketDocument.document.campaign.name
        );
        if (campaignNames.length) {
          setCampaignName(campaignNames.sort()[0]);
        } else setCampaignName(undefined);
      }
    }
  };

  const isCampaignDisabled = (id: string) => {
    return selectedBasketType === BasketType.Commitment
      ? selectedCampaign.length > 0 && !selectedCampaign.includes(id)
      : selectedBasketType === BasketType.BigShop
      ? basketType === BasketType.Commitment
      : undefined;
  };

  const terminatedTagTitle =
    basketType === BasketType.Commitment
      ? t('local.campaign_page.summary.commitment_terminated')
      : t('sales.basket_page.order.order_terminated');

  const CampaignType =
    basketType === BasketType.Commitment
      ? 'Opération Nationale'
      : 'Opération locale';

  return (
    <div css={style}>
      <div className="commitments">
        {getCampaigns(basketDocuments).map(
          ({ campaign, campaignBasketDocuments }) => (
            <ListItemButton
              key={campaign.id}
              isSelected={isCampaignSelected(campaign.id)}
              isDisabled={
                isCampaignDisabled(campaign.id) ||
                isDocumentTerminated(getLastDate(campaignBasketDocuments))
              }
            >
              <div className="campaign-header">
                <div className="campaign">
                  <h3>{campaign.name}</h3>
                  <span>{CampaignType}</span>
                  <div className="tag">
                    <Tag
                      titleColor={colors.selectedCheckboxBorderColor}
                      title={t('sales.basket_page.medium_count', {
                        count: campaignBasketDocuments.length,
                      })}
                      backgroundColor="rgba(50, 108, 240, 0.1)"
                    />
                  </div>
                </div>
                {isDocumentTerminated(getLastDate(campaignBasketDocuments)) ? (
                  <OrderTerminatedTag title={terminatedTagTitle} />
                ) : (
                  <h5>
                    {`${basketDateTag(basketType, t)} : ${moment(
                      getLastDate(campaignBasketDocuments)
                    ).format(' DD/MM/YYYY')}`}{' '}
                  </h5>
                )}
              </div>

              <List>
                {campaignBasketDocuments.map((basketDocument) => (
                  <ListItem key={basketDocument.id}>
                    <BasketRow
                      basketDocument={basketDocument}
                      addToSelectedDocuments={addToSelectedDocuments}
                      setSelectedBasketDocuments={setSelectedBasketDocuments}
                      selectedBasketDocuments={selectedBasketDocuments}
                      isCampaignDisabled={
                        isCampaignDisabled(campaign.id) ||
                        isDocumentTerminated(
                          getLastDate(campaignBasketDocuments)
                        )
                      }
                      refetch={refetch}
                    />
                  </ListItem>
                ))}
              </List>
            </ListItemButton>
          )
        )}
      </div>
    </div>
  );
};

export default BasketContent;
