import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
} from '@material-ui/lab/useAutocomplete/useAutocomplete';
import {
  GetEntities,
  GetEntitiesVariables,
} from 'app/schemaInterfaces/GetEntities';
import React, { ChangeEvent } from 'react';
import { getIn, useFormikContext } from 'formik';

import { ArrowDropDown } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import Chip from '@material-ui/core/Chip';
import { Entity } from './definitions';
import { EntityType } from 'app/schemaInterfaces/globalTypes';
import { GET_ENTITIES } from 'app/graphql/queries/entities';
import TextField from '@material-ui/core/TextField';
import { css } from '@emotion/react';
import { useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

interface EntitiesSelectProps {
  entities: Entity[];
  type: EntityType;
  parents: string[];
  onEntitySelect: (entity: Entity) => void;
  onEntityDelete: (entity: Entity) => void;
  name: string;
  required: boolean;
  textFieldClassName?: string;
  entitiesToFilter?: string[];
}

const style = css`
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'][class*='MuiOutlinedInput-marginDense'] {
    padding: 0;

    .MuiAutocomplete-input {
      padding: 0 12px;
    }
  }
  .MuiInputBase-input {
    min-height: 40px;
  }
`;

const EntitiesSelect = ({
  entities,
  type,
  parents,
  onEntitySelect,
  onEntityDelete,
  name,
  required,
  textFieldClassName,
  entitiesToFilter,
}: EntitiesSelectProps): JSX.Element => {
  const [getEntities, { data, loading }] = useLazyQuery<
    GetEntities,
    GetEntitiesVariables
  >(GET_ENTITIES);

  const { t } = useTranslation();
  const { touched, errors, values } = useFormikContext<Record<string, any>>();

  const handleOpen = () => {
    getEntities({
      variables: {
        filters: {
          types: [type],
          parents:
            !entitiesToFilter && parents.length > 0 ? parents : undefined,
        },
      },
    });
  };

  const handleChange = (
    event: ChangeEvent<{}>,
    values: Entity[] | null,
    changeReason: AutocompleteChangeReason,
    changeDetail?: AutocompleteChangeDetails<Entity | null>
  ) => {
    if (changeDetail && changeDetail.option) {
      if (changeReason === 'select-option') {
        onEntitySelect(changeDetail.option);
      } else if (changeReason === 'remove-option') {
        onEntityDelete(changeDetail.option);
      }
    }
  };

  const options =
    data?.entities.filter((it) => {
      if (!entitiesToFilter || entitiesToFilter.includes(it.id)) {
        return true;
      }
      for (const ancestor of it.ancestors) {
        if (entitiesToFilter.includes(ancestor.id)) {
          return true;
        }
      }
      return false;
    }) ?? [];

  return (
    <Autocomplete<Entity>
      autoComplete
      multiple={true}
      disableClearable={true}
      options={options}
      loading={loading}
      loadingText={t('workflow.error.loading')}
      limitTags={3}
      onOpen={handleOpen}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name}
      renderTags={(options, getTagProps) =>
        options.map((option, index) => (
          <Chip
            label={option.name}
            color="primary"
            {...getTagProps({ index })}
          />
        ))
      }
      popupIcon={<ArrowDropDown />}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          margin="dense"
          variant="outlined"
          size="small"
          css={style}
          className={textFieldClassName}
          helperText={required && getIn(touched, name) && getIn(errors, name)}
          error={
            Boolean(getIn(errors, name) && getIn(touched, name)) && required
          }
          placeholder={
            !entities.length && !required && getIn(values, name).length
              ? t('common.placeholder.all')
              : ''
          }
        />
      )}
      value={entities}
      onChange={handleChange}
    />
  );
};

export default EntitiesSelect;
