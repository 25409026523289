import { InMemoryCache } from '@apollo/client';
import { asset } from 'modules/dam/cache/localFields/asset/asset';
import { metadata } from 'modules/dam/cache/localFields/metadata/metadata';

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        getCommentsByVersion: {
          merge: false,
        },
        getCampaignAttachments: {
          merge: false,
        },
      },
    },
    ...asset,
    ...metadata,
    Campaign: {
      fields: {
        tags: {
          merge: false,
        },
      },
    },
  },
});
