import { GetDocuments_documents_documents_productAttributes } from 'app/schemaInterfaces/GetDocuments';
import { SupplyAttributesFilterInput } from 'app/schemaInterfaces/globalTypes';
import { TFunction } from 'i18next';

export function isItemAttributConditionnement(
  item:
    | GetDocuments_documents_documents_productAttributes
    | SupplyAttributesFilterInput
): item is SupplyAttributesFilterInput {
  return (
    (item as SupplyAttributesFilterInput).conditionnement?.numberOfLots !==
    undefined
  );
}

export const filteredAttributes = (
  productAttributes:
    | GetDocuments_documents_documents_productAttributes
    | SupplyAttributesFilterInput
    | null,
  hasPackaging = false
) => {
  if (productAttributes) {
    const attributes = { ...productAttributes };

    if (isItemAttributConditionnement(attributes) && !hasPackaging) {
      delete attributes.conditionnement;
    }
    const filteredAttributes = Object.fromEntries(
      Object.entries(attributes).filter(([_, v]) => v != null)
    );

    if (filteredAttributes.__typename) {
      delete filteredAttributes.__typename;
    }

    if (filteredAttributes.codeAt) {
      delete filteredAttributes.codeAt;
    }

    return filteredAttributes ?? undefined;
  } else return undefined;
};

export const hasQuantityError = ({
  quantity,
  minQuantity,
  maxQuantity,
}: {
  quantity: number | null;
  minQuantity?: number | null;
  maxQuantity?: number | null;
}) => {
  let minimum = minQuantity ?? 0;

  const maximum = maxQuantity ?? Infinity;

  return !quantity || quantity < minimum || quantity > maximum;
};

export const isPriceValid = (price: number | null, isIncluded?: boolean) =>
  Boolean(price) || Boolean(price === 0 && isIncluded);

export const getErrorMessage = ({
  minQuantity,
  maxQuantity,
  value,
  hasPriceError,
  t,
}: {
  minQuantity?: number | null;
  maxQuantity?: number | null;
  value?: string | number | null;
  t: TFunction;
  hasPriceError: boolean;
}) => {
  let minimum = minQuantity ?? 0;

  const maximum = maxQuantity ?? Infinity;

  if (value && value > maximum) {
    return t('sales.basket_page.quantity_select.max_quantities_required', {
      quantity: maxQuantity,
    });
  } else if (value && value < minimum) {
    return t('sales.basket_page.quantity_select.min_quantity_required', {
      quantity: minimum,
    });
  } else if (hasPriceError) {
    return t('sales.errors.invalid_prices');
  } else {
    return t('sales.basket_page.quantity_select.min_quantity_required', {
      quantity: minimum,
    });
  }
};
