import { css } from '@emotion/react';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const AccessDenied = () => {
  const { t } = useTranslation();

  const style = css`
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    .content {
      span {
        display: block;
        margin-bottom: 10px;
      }
    }
    .title {
      font-size: 32px;
      margin-top: 24px;
      font-weight: 900;
    }
  `;

  const logoUrl = `/${process.env.REACT_APP_ASSETS}/access-denied.png`;

  return (
    <div css={style}>
      <div>
        <div className="content">
          <div>
            <img src={logoUrl} width={300} alt="BigOne Logo" />
          </div>
          <span className="title">{t('common.button.access_denied')}</span>
          <span>{t('common.button.access_denied')}</span>
          <span>{t('common.button.contact_admin')}</span>
        </div>
        <div>
          <Button variant="outlined" color="primary" size="large" href="/">
            {t('common.button.back_home')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AccessDenied;
