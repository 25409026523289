import React from 'react';
import { IProductTemplate } from '../../ProductItem';
import { PriceElement } from 'app/schemaInterfaces/globalTypes';
import useAppContext from 'app/app-context/useAppContext';
import PriceTagElementContainer from './PriceTagElementContainer';
import { TFunctionResult } from 'i18next';
import { getUnifiedKeyFromPimClientConfig } from 'modules/pim/baseProducts/ProductsHelper';
import { UnifiedProductKeyToRenderOnPage } from 'modules/workflow/containers/Leftsidebar/ProductsPanel/ProductsView';
import { productPriceStyle } from './priceTagHelper';
import { TypographyIdentities } from 'modules/workflow/oneStudioContext';
import PriceTagFooter from './PriceTagFooter';

interface ProductPriceProps {
  product: IProductTemplate;
  priceSymbol: string | TFunctionResult;
  hasPriceChip: boolean;
  isCompaniesPrice: boolean;
  fullCompaniesPrice: TypographyIdentities | undefined;
  foreignPriceSymbol: string;
  priceLabels: TypographyIdentities | undefined;
  priceWithTaxes: Record<string, any>;
  hasFieldEntityId: boolean;
  priceOptionalUnity: TypographyIdentities | undefined;
  priceUnity: TypographyIdentities | undefined;
  fieldEntityId: string;
}

const ProductPrice = ({
  product,
  priceSymbol,
  hasPriceChip,
  isCompaniesPrice,
  fullCompaniesPrice,
  priceWithTaxes,
  foreignPriceSymbol,
  priceLabels,
  priceUnity,
  priceOptionalUnity,
  hasFieldEntityId,
  fieldEntityId,
}: ProductPriceProps): JSX.Element => {
  const {
    client: { config: clientConfig },
  } = useAppContext();

  const priceWholeNumber = product.priceTagTypographies?.find(
    (priceTypo) =>
      priceTypo.priceElement === PriceElement.WholeNumber &&
      priceTypo.name ===
        getUnifiedKeyFromPimClientConfig(
          isCompaniesPrice
            ? UnifiedProductKeyToRenderOnPage.price
            : UnifiedProductKeyToRenderOnPage.priceWithTax,
          clientConfig
        )
  );

  const priceDecimal = product.priceTagTypographies?.find(
    (priceTypo) =>
      priceTypo.priceElement === PriceElement.Decimal &&
      priceTypo.name ===
        getUnifiedKeyFromPimClientConfig(
          isCompaniesPrice
            ? UnifiedProductKeyToRenderOnPage.price
            : UnifiedProductKeyToRenderOnPage.priceWithTax,
          clientConfig
        )
  );

  const priceCurrency = product.priceTagTypographies?.find(
    (priceTypo) =>
      priceTypo.priceElement === PriceElement.Currency &&
      priceTypo.name ===
        getUnifiedKeyFromPimClientConfig(
          isCompaniesPrice
            ? UnifiedProductKeyToRenderOnPage.price
            : UnifiedProductKeyToRenderOnPage.priceWithTax,
          clientConfig
        )
  );

  const withoutTaxeSymbol = product.priceTagTypographies?.find(
    (priceTypo) =>
      priceTypo.name ===
        getUnifiedKeyFromPimClientConfig(
          UnifiedProductKeyToRenderOnPage.professionnalprice,
          clientConfig
        ) && priceTypo.priceElement === PriceElement.WholeNumber
  );

  const wholeNumber =
    priceWholeNumber?.name &&
    Math.trunc(
      product.productDetails[priceWholeNumber.name]?.replace(/,/g, '.')
    ).toString();
  const decimal =
    priceDecimal?.name &&
    product.productDetails[priceDecimal.name]
      ?.replace(/,/g, '.')
      .toString()
      .split('.')[1];

  return (
    <div css={productPriceStyle(wholeNumber, hasPriceChip)}>
      <div
        style={{
          display: 'flex',
          height: '100%',
          position: 'absolute',
          alignItems: 'center',
        }}
      >
        {priceWholeNumber && (
          <PriceTagElementContainer
            product={product}
            priceTagElement={priceWholeNumber}
            value={wholeNumber}
            position="relative"
            topValue={priceWholeNumber.position?.y}
            leftValue={priceWholeNumber.position?.x}
          />
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            left: priceCurrency?.position?.x ?? priceDecimal?.position?.x ?? 0,
          }}
        >
          {decimal && (
            <>
              <PriceTagElementContainer
                product={product}
                priceTagElement={priceCurrency}
                value={isCompaniesPrice ? '€*' : '€'}
                position="relative"
                topValue={priceCurrency?.position?.y}
                textAlign="center"
              />
              {isCompaniesPrice && (
                <PriceTagElementContainer
                  product={product}
                  priceTagElement={withoutTaxeSymbol}
                  value={priceSymbol}
                  position="relative"
                  topValue={withoutTaxeSymbol?.position?.y}
                />
              )}
            </>
          )}
          {priceDecimal && (
            <PriceTagElementContainer
              product={product}
              priceTagElement={priceDecimal}
              value={decimal}
              position="relative"
              topValue={priceDecimal?.position?.y}
              textAlign="center"
            />
          )}
          {!decimal && (
            <>
              <PriceTagElementContainer
                product={product}
                priceTagElement={priceCurrency}
                value={isCompaniesPrice ? '€*' : '€'}
                position="relative"
                topValue={priceCurrency?.position?.y}
                textAlign="center"
              />
              {isCompaniesPrice && (
                <PriceTagElementContainer
                  product={product}
                  priceTagElement={withoutTaxeSymbol}
                  value={priceSymbol}
                  position="relative"
                  topValue={withoutTaxeSymbol?.position.y}
                />
              )}
            </>
          )}
        </div>
        {(isCompaniesPrice ||
          fullCompaniesPrice ||
          priceUnity ||
          priceOptionalUnity ||
          priceLabels) &&
          !hasPriceChip && (
            <PriceTagFooter
              product={product}
              isCompaniesPrice={isCompaniesPrice}
              fullCompaniesPrice={fullCompaniesPrice}
              priceWithTaxes={priceWithTaxes}
              foreignPriceSymbol={foreignPriceSymbol}
              priceLabels={priceLabels}
              priceUnity={priceUnity}
              priceOptionalUnity={priceOptionalUnity}
              hasFieldEntityId={hasFieldEntityId}
              fieldEntityId={fieldEntityId}
            />
          )}
      </div>
    </div>
  );
};

export default ProductPrice;
