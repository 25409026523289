import {
  Box,
  Container,
  FormControl,
  FormLabel,
  Grid,
  TextField,
  TextFieldProps,
  Typography,
} from '@material-ui/core';
import { TRANSLATION_ROOT } from 'modules/oneManager/storeHelper';
import React from 'react';
import colors from 'styles/colors.module.scss';
import { useTranslation } from 'react-i18next';

const Contact = (): JSX.Element => {
  const { t } = useTranslation();

  const textFieldsProps: TextFieldProps = {
    variant: 'outlined',
    margin: 'dense',
    size: 'small',
    fullWidth: true,
  };

  return (
    <FormControl component="fieldset" fullWidth>
      <Box p={2} mb={2} bgcolor={colors.documentsHeaderBackground}>
        <Typography variant="h4">
          {t(`${TRANSLATION_ROOT}.delivery_address_form.contact.title`)}
        </Typography>
      </Box>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormLabel>
              {t(
                `${TRANSLATION_ROOT}.delivery_address_form.contact.first_name`
              )}{' '}
            </FormLabel>
            <TextField {...textFieldsProps} />
          </Grid>
          <Grid item xs={12}>
            <FormLabel>
              {t(`${TRANSLATION_ROOT}.delivery_address_form.contact.last_name`)}{' '}
            </FormLabel>
            <TextField {...textFieldsProps} />
          </Grid>
          <Grid item xs={12}>
            <FormLabel>
              {t(
                `${TRANSLATION_ROOT}.delivery_address_form.contact.job_position`
              )}{' '}
            </FormLabel>
            <TextField {...textFieldsProps} />
          </Grid>
          <Grid item xs={12}>
            <FormLabel>
              {t(`${TRANSLATION_ROOT}.delivery_address_form.contact.phone`)}
            </FormLabel>
            <TextField {...textFieldsProps} />
          </Grid>
          <Grid item xs={12}>
            <FormLabel>
              {t(`${TRANSLATION_ROOT}.delivery_address_form.contact.email`)}
            </FormLabel>
            <TextField {...textFieldsProps} />
          </Grid>
        </Grid>
      </Container>
    </FormControl>
  );
};

export default Contact;
