import { css } from '@emotion/react';

export const deliveryStepStyle = css`
  padding-left: 64px;
  padding-right: 64px;
  .campaign-name {
    margin-top: 13px;
    display: flex;
    flex-direction: column;
    .label {
      margin-bottom: 5px;
    }
    .field {
      max-width: 350px;
    }
  }
`;
