import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const menuToolBarStyle = css`
  background: ${colors.body};
  height: 68px;
  display: flex;
  justify-content: space-between;

  .menu-items {
    display: flex;
    white-space: nowrap;
  }

  .filter {
    display: flex;

    padding-right: 8px;
    align-items: center;
    > div {
      margin-right: 20px;
    }
  }
`;
