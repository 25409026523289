import { Box, Button, Grid, TextField } from '@material-ui/core';
import React from 'react';
import style from './SocialNetworksContainer.style';

import {
  AccessTimeOutlined,
  CheckCircleOutline,
  CheckOutlined,
  EmojiObjectsOutlined,
  OpenInNewOutlined,
} from '@material-ui/icons';

import { useSocialNetworks } from '../useSocialNetworks';

const SocialNetworkContainer = (): JSX.Element => {
  const {
    t,
    handleAuthFacebook,
    changeValueClientNetworkPageId,
    clientNetworkLink,
    setClientNetworkPageId,
    isCompleteInformations,
    isCanPost,
    isDisabled,
    isErrorMessage,
    renderLoading,
  } = useSocialNetworks();

  renderLoading();

  return (
    <div>
      <Box py={2} css={style}>
        <h1>{t('menuItems.social_networks')} </h1>
        <h3 className="facebook--container">
          {t(
            'one_manager.store_information.social_networks.facebook_authorization_title'
          )}
        </h3>
        <p>
          {t(
            'one_manager.store_information.social_networks.facebook_authorization_description'
          )}
        </p>
        <Grid container style={{ marginTop: 20 }}>
          <Grid item container xs={12} alignItems="center">
            <h5 className="bullet-point">1</h5>
            <p className="step--text">
              {t(
                'one_manager.store_information.social_networks.facebook_connect_step_1'
              )}
            </p>
          </Grid>
          <TextField
            name="page_id"
            variant="outlined"
            value={changeValueClientNetworkPageId}
            onChange={(e) => setClientNetworkPageId(e.target.value)}
            style={{ width: 800, marginLeft: '46px' }}
            placeholder="https://mon-url-facebook.com ou identifiant-page-facebook"
            InputProps={{
              endAdornment: (
                <>
                  {isCompleteInformations && (
                    <>
                      {isCanPost ? (
                        <div className="badge-auth-complete">
                          <p>
                            {t(
                              'one_manager.store_information.social_networks.facebook_auth_complete'
                            )}
                          </p>
                          <CheckCircleOutline />
                        </div>
                      ) : (
                        <div className="badge-waiting-auth">
                          <p>
                            {t(
                              'one_manager.store_information.social_networks.facebook_waiting_auth'
                            )}
                          </p>
                          <AccessTimeOutlined />
                        </div>
                      )}
                    </>
                  )}
                </>
              ),
            }}
          />
          <Button
            color="primary"
            variant="contained"
            component="label"
            style={{
              marginLeft: 20,
              opacity: !!isCanPost ? 0.5 : 1,
            }}
            endIcon={<CheckOutlined />}
            disabled={isDisabled}
            onClick={handleAuthFacebook}
          >
            {t(
              isCanPost
                ? 'one_manager.store_information.social_networks.facebook_authorization_sent'
                : 'one_manager.store_information.social_networks.facebook_authorization_to_send'
            )}
          </Button>
        </Grid>
        {isErrorMessage && (
          <>
            <Grid container style={{ marginTop: 20 }}>
              <Grid item container xs={12} alignItems="center">
                <h5 className="bullet-point">2</h5>
                <p className="step--text">
                  {t(
                    'one_manager.store_information.social_networks.facebook_connect_step_2_title'
                  )}
                </p>
                <Button
                  color="primary"
                  variant="outlined"
                  component="label"
                  style={{ marginLeft: 20 }}
                  endIcon={<OpenInNewOutlined />}
                  onClick={() =>
                    clientNetworkLink && window.open(clientNetworkLink)
                  }
                >
                  {t(
                    'one_manager.store_information.social_networks.facebook_connect_step_2_auth'
                  )}
                </Button>
              </Grid>
              <Grid
                item
                container
                className="informative--container"
                alignItems="center"
              >
                <EmojiObjectsOutlined />
                <p className="informative--text">
                  {t(
                    'one_manager.store_information.social_networks.facebook_connect_step_2_info'
                  )}
                </p>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </div>
  );
};

export default SocialNetworkContainer;
