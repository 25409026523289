import { AssetFormModel } from 'modules/dam/form/model/definitions';
import { Formik } from 'formik';
import React from 'react';
import { AssetInput } from 'app/schemaInterfaces/globalTypes';
import {
  mapToAssetFormModel,
  mapToAssetInput,
} from 'modules/dam/form/model/mappers';
import { getMultiAssetFormSchema } from 'modules/dam/form/validation/schema';
import MultiAssetForm from 'modules/dam/form/MultiAssetForm';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from 'app/auth/UserContext';

export interface MultiAssetFormWrapperProps {
  onFormValidation?: (asset: AssetInput, files: File[]) => void;
}

export interface MultiAssetFormValues {
  asset: AssetFormModel;
  files: File[];
}

const MultiAssetFormWrapper = ({
  onFormValidation,
}: MultiAssetFormWrapperProps): JSX.Element => {
  const { entities } = useCurrentUser();

  const initialValues: MultiAssetFormValues = {
    asset: mapToAssetFormModel({ entities }),
    files: [],
  };

  const { t } = useTranslation();

  return (
    <>
      <Formik<MultiAssetFormValues>
        initialValues={initialValues}
        validationSchema={getMultiAssetFormSchema(t)}
        onSubmit={(values) =>
          onFormValidation?.(mapToAssetInput(values.asset), values.files)
        }
      >
        <MultiAssetForm />
      </Formik>
    </>
  );
};

export default MultiAssetFormWrapper;
