import { gql } from '@apollo/client';

export const GET_SOURCE_FILES = gql`
  query GetCampaignAttachments(
    $campaignId: ID!
    $filter: AttachmentFilterInput
  ) {
    getCampaignAttachments(campaignId: $campaignId, filter: $filter) {
      id
      userId
      size
      filename
      mimetype
      updatedAt
    }
  }
`;

export const CREATE_CAMPAIGN_ATTACHMENT = gql`
  mutation CreateCampaignAttachment($attachment: AttachmentInput!) {
    createCampaignAttachment(attachment: $attachment) {
      id
      userId
      size
      filename
      mimetype
      updatedAt
      uploadUrl
    }
  }
`;

export const GET_ATTACHMENT_URL = gql`
  query GetAttachmentUrl($attachmentId: String!) {
    getAttachmentUrl(attachmentId: $attachmentId) {
      url
    }
  }
`;

export const GET_ZIPPED_ATTACHMENT_URL = gql`
  query GetZippedAttachmentsUrl(
    $attachmentIds: [String!]!
    $campaignId: String!
  ) {
    getZippedAttachmentsUrl(
      attachmentIds: $attachmentIds
      campaignId: $campaignId
    ) {
      url
    }
  }
`;

export const DELETE_CAMPAIGN_ATTACHMENTS = gql`
  mutation DeleteCampaignAttachments(
    $campaignId: String!
    $attachmentIds: [String!]!
  ) {
    deleteCampaignAttachments(
      campaignId: $campaignId
      attachmentIds: $attachmentIds
    )
  }
`;
