import { css } from '@emotion/react';
import { Button, Grid, Typography } from '@material-ui/core';
import { GetSupports_supports as Support } from 'app/schemaInterfaces/GetSupports';
import { Maybe } from 'app/utils/common';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import { DocumentFormValues } from 'modules/pro/documents/form/model/definitions';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DocumentFormSelect from './fields/DocumentFormSelect';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import colors from 'styles/colors.module.scss';
import { getSupportCategory } from './model/mappers';

interface DocumentSupportSubFormProps {
  supports: Support[];
  onSupportSelected: (support: Maybe<Support>) => void;
  isDisabled?: boolean;
  isClearDisabled?: boolean;
}

const style = css`
  padding-bottom: 10px;
  .field {
    margin-top: 16px;
  }
  .flex {
    display: flex;
    align-items: end;
    .clean {
      text-align: center;
    }
  }
  .reinitialize {
    width: max-content;
    margin-left: 170px;
  }
  .info {
    color: ${colors.leftSideBar};
    display: flex;
    align-items: center;
    margin-top: 25px;
    padding-left: 8px;
    svg {
      width: 16px;
      margin-right: 18px;
      margin-bottom: 8px;
    }
  }
`;

const DocumentSupportSubForm = ({
  supports,
  onSupportSelected,
  isDisabled,
  isClearDisabled,
}: DocumentSupportSubFormProps) => {
  const { t } = useTranslation();
  const {
    values: {
      document: { category, supportId },
    },
    setFieldValue,
  } = useFormikContext<DocumentFormValues>();
  const categories = _.uniq(
    supports
      .filter((support) => support?.onePacConfig?.category)
      .map((support) => support?.onePacConfig?.category!)
  );

  const filteredSupports =
    category === null
      ? supports
      : supports.filter(
          (support) => support?.onePacConfig?.category === category
        );

  const handleCategoryChange = (value: string | null) => {
    setFieldValue('document.category', value);
    setFieldValue('document.supportId', null);
    setFieldValue('document.mediumType', '');
    setFieldValue(`productAttributes`, {});
    onSupportSelected(null);
  };

  const handleSupportChange = (value: string) => {
    const support = supports.find(({ id }) => id === value)!;
    setFieldValue('document.supportId', support.id);
    setFieldValue('document.category', support?.onePacConfig?.category);
    setFieldValue('document.mediumType', support.name);
    setFieldValue(`productAttributes`, {});
    onSupportSelected(support);
  };

  useEffect(() => {
    if (filteredSupports.length === 1 && category && supportId === null) {
      onSupportSelected(filteredSupports[0]);
      setFieldValue('document.supportId', filteredSupports[0].id);
      setFieldValue('document.mediumType', filteredSupports[0].name);
    } else if (supportId) {
      const support = supports.find(({ id }) => id === supportId)!;
      onSupportSelected(support);
    }
  }, [
    filteredSupports,
    onSupportSelected,
    category,
    setFieldValue,
    supportId,
    supports,
  ]);

  const cleanAttributes = () => {
    setFieldValue(`document.productId`, null);
    handleCategoryChange(null);
  };

  return (
    <div css={style}>
      <div className="field">
        <DocumentFormSelect
          onChange={handleCategoryChange}
          name="document.category"
          title={t('global.document_form.categories')}
          options={categories.map((categoryItem) => ({
            label: getSupportCategory(categoryItem, t),
            value: categoryItem,
          }))}
          className="text-field"
          isDisabled={isDisabled}
        />
      </div>
      <div className="field">
        <Grid container spacing={2} className="flex">
          <Grid item xs={7}>
            <DocumentFormSelect
              onChange={handleSupportChange}
              name="document.supportId"
              title={t('global.document_form.supports')}
              options={filteredSupports.map((support) => ({
                label: support.name,
                value: support.id,
              }))}
              className="text-field--fullwidth"
              isDisabled={isDisabled}
            />
          </Grid>

          <Grid item xs={5}>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={cleanAttributes}
              className="clean reinitialize"
              disabled={isClearDisabled}
            >
              {t('global.document_form.reinitialize')}
            </Button>
          </Grid>
          <div className="info">
            <InfoOutlinedIcon />
            <Typography variant="body1">
              {t('global.document_form.information')}
            </Typography>
          </div>
        </Grid>
      </div>
    </div>
  );
};

export default DocumentSupportSubForm;
