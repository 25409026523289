import React from 'react';
import { CellProps } from 'react-table';
import { Box, Tooltip, Typography } from '@material-ui/core';
import { TableColumns } from 'components/AppTable/AppTable';
import { GetCampaignAssets_assetsCampaigns } from 'app/schemaInterfaces/GetCampaignAssets';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import colors from 'styles/colors.module.scss';
import { DivisionSelectModel } from 'components/divisions/useDivisionSelect';
import { TableMediaAccessor } from 'modules/pro/medias/utils/definitions';
import mime from 'mime-types';
import { NO_THUMBNAILS_MEDIAS } from 'modules/dam/form/helpers';
import { Action } from 'components/ActionsMenu/ActionsMenu';

export const createMediaActions = (
  campaignId: string,
  t: (key: string) => string,
  setOpenAssetDialog: React.Dispatch<React.SetStateAction<boolean>>
): Action[] => [
  {
    title: t('dam.asset_actions.single_title'),
    description: t('dam.asset_actions.single_description'),
    onClick: () => setOpenAssetDialog(true),
  },
  {
    title: t('global.media.one_or_more_media'),
    description: t('global.media.import_media_campaigns'),
    to: `/campaign/${campaignId}/media/add`,
  },
];
export const campaignAssetsTableColumn = (
  t: (key: string) => string,
  divisionSelect: DivisionSelectModel[]
): TableColumns<GetCampaignAssets_assetsCampaigns>[] => {
  const overflowEllipsisStyle = {
    style: { overflow: 'hidden', textOverflow: 'ellipsis' },
  };

  const customerDivisions = (): TableColumns<
    GetCampaignAssets_assetsCampaigns
  >[] =>
    divisionSelect.map(({ label, key }) => ({
      Header: () => (
        <Box
          component="span"
          display="flex"
          flexDirection="row"
          color={colors.documentTab}
        >
          <Typography variant="h5" component="span">
            {label}
          </Typography>
          <ArrowDropDownIcon />
        </Box>
      ),
      accessor: key as 'metadata', //workaround
      sticky: 'left',
      Cell: ({
        cell,
      }: CellProps<GetCampaignAssets_assetsCampaigns, string>) => (
        <Typography variant="body1">
          {cell.row.original.division &&
          cell.row.original.division.hasOwnProperty(key)
            ? cell.row.original.division[key]
            : cell.row.values.division}
        </Typography>
      ),
    }));
  return [
    {
      Header: '',
      accessor: TableMediaAccessor.ThumbnailUrl,
      sticky: 'left',
      Cell: ({
        cell,
      }: CellProps<GetCampaignAssets_assetsCampaigns, string>) => {
        if (NO_THUMBNAILS_MEDIAS.includes(cell.row.values.metadata.mimetype)) {
          return (
            <img
              src={`/${process.env.REACT_APP_ASSETS}/noPreviewImageSquare.png`}
              alt="thumbnailUrl"
              height="48px"
            />
          );
        } else {
          return (
            <img
              src={cell.row.values.thumbnailUrl ?? cell.row.values.url}
              alt="thumbnailUrl"
              height="48px"
            />
          );
        }
      },
    },
    {
      Header: () => (
        <Box
          component="span"
          display="flex"
          flexDirection="row"
          color={colors.documentTab}
        >
          <Typography variant="h5" component="span">
            {t('global.media.name')}
          </Typography>
          <ArrowDropDownIcon />
        </Box>
      ),
      accessor: TableMediaAccessor.Name,
      width: 124,
      sticky: 'left',
      Cell: ({
        cell,
      }: CellProps<GetCampaignAssets_assetsCampaigns, string>) => (
        <Tooltip title={cell.row.values.name}>
          <Typography variant="body1" {...overflowEllipsisStyle} noWrap>
            {cell.row.values.name}
          </Typography>
        </Tooltip>
      ),
    },
    {
      Header: () => (
        <Box
          component="span"
          display="flex"
          flexDirection="row"
          color={colors.documentTab}
        >
          <Typography variant="h5" component="span">
            {t('global.media.type')}
          </Typography>
          <ArrowDropDownIcon />
        </Box>
      ),
      accessor: TableMediaAccessor.Metadata,
      width: 115,
      sticky: 'left',
      Cell: ({
        cell,
      }: CellProps<GetCampaignAssets_assetsCampaigns, string>) => (
        <Typography variant="body1">
          {mime.extension(cell.row.values.metadata.mimetype)}
        </Typography>
      ),
    },
    {
      Header: () => (
        <Box
          component="span"
          display="flex"
          flexDirection="row"
          color={colors.documentTab}
        >
          <Typography variant="h5" component="span">
            {t('global.media.tag')}
          </Typography>
          <ArrowDropDownIcon />
        </Box>
      ),
      accessor: TableMediaAccessor.Tags,
      width: 136,
      sticky: 'left',
      Cell: ({
        cell,
      }: CellProps<GetCampaignAssets_assetsCampaigns, string>) => {
        const tags = cell.row.values.tags.map(
          (tag: { title: string }) => `${tag.title} `
        );
        return (
          <Box
            display="inline-block"
            alignItems="center"
            whiteSpace="nowrap"
            overflow="hidden"
          >
            <Tooltip title={tags}>
              <Typography variant="body1" {...overflowEllipsisStyle}>
                {tags}
              </Typography>
            </Tooltip>
          </Box>
        );
      },
    },
    ...customerDivisions(),
  ];
};
