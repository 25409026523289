import colors from 'styles/colors.module.scss';

export const tagBackgroundColors = new Map([
  ['0', colors.tagBackground3],
  ['1', colors.tagBackground3],
  ['2', colors.tagBackground3],
  ['3', colors.tagBackground3],
  ['4', colors.tagBackground3],
  ['5', colors.tagBackground3],
  ['6', colors.tagBackground3],
  ['7', colors.tagBackground3],
  ['8', colors.tagBackground3],
  ['9', colors.tagBackground3],
  ['A', colors.tagBackground3],
  ['À', colors.tagBackground3],
  ['Â', colors.tagBackground3],
  ['F', colors.tagBackground3],
  ['K', colors.tagBackground3],
  ['P', colors.tagBackground3],
  ['U', colors.tagBackground3],
  ['Z', colors.tagBackground3],
  ['B', colors.tagBackground2],
  ['G', colors.tagBackground2],
  ['L', colors.tagBackground2],
  ['Q', colors.tagBackground2],
  ['V', colors.tagBackground2],
  ['C', colors.tagBackground0],
  ['H', colors.tagBackground0],
  ['M', colors.tagBackground0],
  ['R', colors.tagBackground0],
  ['W', colors.tagBackground0],
  ['D', colors.tagBackground4],
  ['I', colors.tagBackground4],
  ['Î', colors.tagBackground4],
  ['N', colors.tagBackground4],
  ['S', colors.tagBackground4],
  ['X', colors.tagBackground4],
  ['E', colors.tagBackground1],
  ['É', colors.tagBackground1],
  ['J', colors.tagBackground1],
  ['O', colors.tagBackground1],
  ['T', colors.tagBackground1],
  ['Y', colors.tagBackground1],
]);

export const tagTextColors = new Map([
  ['0', colors.tagTextColor3],
  ['1', colors.tagTextColor3],
  ['2', colors.tagTextColor3],
  ['3', colors.tagTextColor3],
  ['4', colors.tagTextColor3],
  ['5', colors.tagTextColor3],
  ['6', colors.tagTextColor3],
  ['7', colors.tagTextColor3],
  ['8', colors.tagTextColor3],
  ['9', colors.tagTextColor3],
  ['A', colors.tagTextColor3],
  ['À', colors.tagTextColor3],
  ['Â', colors.tagTextColor3],
  ['F', colors.tagTextColor3],
  ['K', colors.tagTextColor3],
  ['P', colors.tagTextColor3],
  ['U', colors.tagTextColor3],
  ['Z', colors.tagTextColor3],
  ['B', colors.tagTextColor2],
  ['G', colors.tagTextColor2],
  ['L', colors.tagTextColor2],
  ['Q', colors.tagTextColor2],
  ['V', colors.tagTextColor2],
  ['C', colors.tagTextColor0],
  ['H', colors.tagTextColor0],
  ['M', colors.tagTextColor0],
  ['R', colors.tagTextColor0],
  ['W', colors.tagTextColor0],
  ['D', colors.tagTextColor4],
  ['I', colors.tagTextColor4],
  ['Î', colors.tagTextColor4],
  ['N', colors.tagTextColor4],
  ['S', colors.tagTextColor4],
  ['X', colors.tagTextColor4],
  ['E', colors.tagTextColor1],
  ['É', colors.tagTextColor1],
  ['J', colors.tagTextColor1],
  ['O', colors.tagTextColor1],
  ['T', colors.tagTextColor1],
  ['Y', colors.tagTextColor1],
]);

export const userBadgeColors = new Map([
  ['A', colors.loader8],
  ['À', colors.tagTextColor3],
  ['Â', colors.tagTextColor3],
  ['F', colors.loader9],
  ['K', colors.tagTextColor3],
  ['P', colors.tagTextColor3],
  ['U', colors.loader9],
  ['Z', colors.tagTextColor3],
  ['B', colors.tagTextColor2],
  ['G', colors.tagTextColor2],
  ['L', colors.loader9],
  ['Q', colors.tagTextColor2],
  ['V', colors.tagTextColor2],
  ['C', colors.tagTextColor0],
  ['H', colors.selectedCheckboxBorderColor],
  ['M', colors.tagTextColor0],
  ['R', colors.selectedCheckboxBorderColor],
  ['W', colors.tagTextColor0],
  ['D', colors.tagTextColor4],
  ['I', colors.tagTextColor4],
  ['Î', colors.tagTextColor4],
  ['N', colors.loader8],
  ['S', colors.tagTextColor4],
  ['X', colors.loader8],
  ['E', colors.tagTextColor1],
  ['É', colors.tagTextColor1],
  ['J', colors.loader4],
  ['O', colors.tagTextColor1],
  ['T', colors.loader4],
  ['Y', colors.tagTextColor1],
]);
