import { css } from '@emotion/react/macro';
import { Box, Button, Grid, IconButton, Typography } from '@material-ui/core';
import { GetSupports_supports_supportAttributes } from 'app/schemaInterfaces/GetSupports';
import { DocumentFormValues } from 'modules/pro/documents/form/model/definitions';
import React, { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import colors from 'styles/colors.module.scss';
import IsCustomizableTag from 'modules/shop/components/IsCustomizableTag';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useDropzone } from 'react-dropzone';
import { ACCEPTED_IMAGE_TYPES } from 'modules/dam/form/helpers';
import { getBase64File } from 'app/utils/FileUpload/FileUpload';
import mime from 'mime-types';
import { PreviewFile } from 'components/ControlledFileUpload/ControlledFileUpload';
import CachedIcon from '@material-ui/icons/Cached';
import { useFormikContext } from 'formik';
import { filteredAttributes } from 'modules/sales/components/QuantitySelectHelper';
import { AttributConditionnementFilterInput } from 'app/schemaInterfaces/globalTypes';
import useAppContext from 'app/app-context/useAppContext';
import { getEntitiesByType } from 'modules/oneManager/entities/form/helpers';
import { Skeleton } from '@material-ui/lab';
import { getSupportCategory } from './model/mappers';
import { isItemAttributePackaging } from './DocumentProductSubForm/DocumentProductSubform.helper';
import Packaging from './Packaging/Packaging';

//TODO: replace this and use STOCK information from OS

const style = css`
  .label-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .drop-zone {
    cursor: pointer;
    text-align: center;
    border-radius: 5px;
    .caption {
      color: ${colors.disabled};
    }
    p {
      color: ${colors.primary};
    }
  }
  .image {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
  }
`;

interface DocumentSummaryProps {
  attributes: GetSupports_supports_supportAttributes[];
  imageUrl?: string | null;
}

const DocumentSummary = ({ attributes, imageUrl }: DocumentSummaryProps) => {
  const { values, setFieldValue } = useFormikContext<DocumentFormValues>();
  const [filesPreview, setFilesPreview] = useState<PreviewFile[]>([]);
  const {
    client: { structures },
  } = useAppContext();
  const { t } = useTranslation();
  const hasPreview = Boolean(filesPreview.length);
  const { getInputProps, getRootProps } = useDropzone({
    onDrop: async (files: File[]) => {
      const previews = await Promise.all(
        files.map(async (file) => ({
          ...file,
          preview: URL.createObjectURL(file),
          base64: (await getBase64File(file)) as string,
          extension: mime.extension(file.type) || undefined,
        }))
      );
      setFilesPreview(previews);
      setFieldValue('file', files[0]);
    },
    accept: ACCEPTED_IMAGE_TYPES,
  });

  const productAttributes = filteredAttributes(values.productAttributes, true);

  const groupedEntities = structures
    .slice(1)
    .map((structure) => {
      return getEntitiesByType(values.document?.entities, structure.type);
    })
    ?.reduce<{ structureTitle: string; names: string }[]>(
      (previous, current, index) => {
        if (current.length) {
          return [
            ...previous,
            {
              structureTitle: current[0].structureTitle ?? '',
              names: current.map(({ name }) => name).join(', '),
            },
          ];
        } else
          return [
            ...previous,
            {
              structureTitle: structures[index].title ?? '',
              names: t('common.placeholder.all'),
            },
          ];
      },
      []
    );

  function LoadedImage() {
    return (
      <img
        alt="loaded"
        src={filesPreview[0]?.base64 || imageUrl || ''}
        className="image"
      />
    );
  }

  return (
    <Grid item xs={4} css={style}>
      <div className="initial-values">
        <h3>
          {Boolean(values?.document.name)
            ? values?.document.name
            : t('global.document_form.name')}{' '}
          -{' '}
          {Boolean(values?.document.version)
            ? values?.document.version
            : t('global.document_form.version')}
        </h3>
        <div className="custumizable-tag">
          <IsCustomizableTag isCustomizable={values.document.isCustomizable} />
        </div>
        <div className="recap-attribute--container">
          <div className="label-container">
            <span className="label">
              {t('global.document_form.document_visual')}
            </span>
            {(hasPreview || imageUrl) && (
              <IconButton {...getRootProps()}>
                <input {...getInputProps()}></input>
                <CachedIcon fontSize="small" />
              </IconButton>
            )}
          </div>

          <span className="value" {...getRootProps()}>
            {!hasPreview && !imageUrl ? (
              <Box
                display="grid"
                height="102px"
                border={`2px dashed ${colors.buttonDisabled}`}
                px={2}
                py={1}
                justifyContent="center"
                className="drop-zone"
              >
                <Button color="primary">
                  <AddCircleIcon />
                  <input {...getInputProps()}></input>
                </Button>
                <Typography>
                  {t('global.document_form.download_picture')}
                </Typography>
                <Typography variant="caption" className="caption">
                  {t('global.document_form.images_max')}
                </Typography>
              </Box>
            ) : (
              <Suspense
                fallback={
                  <Skeleton animation="wave" height={100} variant="rect" />
                }
              >
                <LoadedImage />
              </Suspense>
            )}
          </span>
        </div>
        {groupedEntities?.map((entity, index) => (
          <div className="recap-attribute--container" key={index}>
            <span className="label">{entity.structureTitle} : </span>
            <span className="value">{entity.names}</span>
          </div>
        ))}
        {values.document.category && (
          <div className="recap-attribute--container">
            <span className="label">
              {t('global.document_form.categories')} :{' '}
            </span>
            <span className="value">
              {getSupportCategory(values.document.category, t)}
            </span>
          </div>
        )}
        {values.document.mediumType && (
          <div className="recap-attribute--container">
            <span className="label">
              {t('global.document_form.supports')} :{' '}
            </span>
            <span className="value">{values.document.mediumType}</span>
          </div>
        )}
        {productAttributes &&
          attributes &&
          Object.entries(productAttributes)
            ?.map(
              ([key, value]: [
                string,
                string | AttributConditionnementFilterInput | null
              ]) => {
                const attribute = attributes.find(
                  (supplyAttribute) => supplyAttribute.key === key
                );

                if (attribute && value) {
                  return isItemAttributePackaging(value) ? (
                    <div key={key} className="recap-attribute--container">
                      <Packaging
                        packaging={{
                          ...value,
                          value: `${t(
                            'global.document_form.attributes_names.conditionnement'
                          )}`,
                        }}
                      />
                    </div>
                  ) : (
                    <div key={key} className="recap-attribute--container">
                      <span className="label">{attribute.name} : </span>
                      <span className="value">{value}</span>
                    </div>
                  );
                }
                return null;
              }
            )
            .filter((value) => value)}
      </div>
    </Grid>
  );
};

export default DocumentSummary;
