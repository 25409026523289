import { useCurrentUser } from 'app/auth/UserContext';
import {
  BigShopConfigCategory,
  FollowOrderStatus,
} from 'app/schemaInterfaces/globalTypes';
import { useTranslation } from 'react-i18next';
import { CommitmentStatus } from './OrderFollowStatusTag.definition';
import {
  getOrderFollowStatus,
  hasBothDocumentTypes,
  hasOnlyDigitalDocuments,
} from './OrderFollowStatusTag.helper';
import { orderFollowStatusTagStyle } from './OrderFollowStatusTag.style';

const OrderFollowStatusTag = ({
  orderStatus,
  documentsCategories,
}: {
  orderStatus: FollowOrderStatus | CommitmentStatus | null;
  documentsCategories?: BigShopConfigCategory[];
}) => {
  const { t } = useTranslation();
  const { type: userType } = useCurrentUser();

  const orderFollowStatus = getOrderFollowStatus({
    hasOnlyDigitalDocuments: hasOnlyDigitalDocuments(documentsCategories),
    hasBothDocumentTypes: hasBothDocumentTypes(documentsCategories),
    orderStatus,
    userType,
    t,
  });

  return orderFollowStatus ? (
    <div
      css={orderFollowStatusTagStyle(
        orderFollowStatus.tagBgColor,
        orderFollowStatus.tagColor
      )}
    >
      {' '}
      {orderFollowStatus.title}{' '}
    </div>
  ) : null;
};

export default OrderFollowStatusTag;
