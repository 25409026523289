import { Skeleton } from '@material-ui/lab';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import BasketConfirmationTotal from '../BasketConfirmationTotal/BasketConfirmationTotal';
import { formatProductPrice } from 'modules/sales/utils/priceFormatting';
import {
  useBasketConfirmationPreview,
  UseBasketConfirmationPreviewData,
} from './BasketConfirmationPreview.hooks';
import {
  basketPreviewItemContentStyle,
  basketPreviewItemQuantityStyle,
  basketPreviewItemStyle,
} from './BasketConfirmationPreview.style';
import AlertBox from 'components/AlertBox/AlertBox';

export interface BasketConfirmationPreviewProps
  extends UseBasketConfirmationPreviewData {}

const BasketConfirmationPreview: FC<BasketConfirmationPreviewProps> = ({
  basketDocuments,
}) => {
  const { products, total, errors } = useBasketConfirmationPreview({
    basketDocuments,
  });
  const { t } = useTranslation();

  if (products.length === 0) {
    return null;
  }

  return errors.length === 0 ? (
    <>
      {products.map(({ product, pricing }) => (
        <div css={basketPreviewItemStyle} key={product.id}>
          <img src={product.document.thumbnailUrl ?? ''} alt="document" />
          <div css={basketPreviewItemContentStyle}>
            <div>
              <strong>{product.document.name}</strong> -{' '}
              {product.document.version}
            </div>
            {product.quantity && product.quantity > 0 ? (
              <>
                <div css={basketPreviewItemQuantityStyle}>
                  {t('sales.basket_page.quantity_select.copies', {
                    quantity: product.quantity,
                  })}
                </div>
                <div>
                  <strong>
                    {typeof pricing?.productSalesPriceExcludingVAT ===
                    'number' ? (
                      formatProductPrice(
                        pricing.productSalesPriceExcludingVAT,
                        pricing.isIncluded,
                        t
                      )
                    ) : (
                      <Skeleton width="8ch" />
                    )}
                  </strong>
                </div>
              </>
            ) : undefined}
          </div>
        </div>
      ))}
      <BasketConfirmationTotal
        price={total?.totalProductSalesPriceExcludingVAT}
      />
    </>
  ) : (
    <AlertBox
      variant="error"
      header={errors.map((it) => (
        <p>{it}</p>
      ))}
    />
  );
};

export default BasketConfirmationPreview;
