import { Button, Grid } from '@material-ui/core';
import { DEFAULT_IMAGE_PREVIEW } from 'app/utils/common';
import itemRowStyle from 'styles/itemRow.style';
import IsCustomizableTag from 'modules/shop/components/IsCustomizableTag';
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';
import BasketDeliveryContainer from '../BasketDeliveryContainer/BasketDeliveryContainer';
import { SelectedBasketdocument } from '../../sales.state';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CommentDialog from '../CommentDialog';
import { UncontrolledDatePicker } from 'components/UncontrolledDatePicker';
import moment from 'moment';
import { hasPublicationPeriod } from 'app/utils/digital-helper';
import PublicationPeriod from '../PublicationPeriod/PublicationPeriod';
import { BasketAddress } from '../BasketDeliveryContainer/BasketDeliveryContainer.definitions';
import { deliveryBasketRowStyle } from './DeliveryBasketRow.style';
import { useDeliveryBasketRow } from './DeliveryBasketRow.hooks';

const DeliveryBasketRow = ({
  basketDocumentItem,
  setAddress,
  handleUpdateBasketDocument,
  setComment,
  isCommitment,
}: {
  basketDocumentItem: SelectedBasketdocument;
  setAddress: (address: BasketAddress['address'], index: number) => void;
  handleUpdateBasketDocument: (productName: string, index: number) => void;
  setComment: (comment: string) => void;
  isCommitment: boolean;
}) => {
  const { t } = useTranslation();

  const {
    isValidated,
    isCustomizable,
    name,
    thumbnailUrl,
    productAttributes,
  } = basketDocumentItem.basketDocument.document;

  const {
    validatedIcon,
    open,
    data,
    openComment,
    handleClose,
    saveComment,
    handleCommentChange,
    handleChangeDeliveryDate,
    handleClearDeliveryDate,
    commentValue,
  } = useDeliveryBasketRow(basketDocumentItem, setComment);

  return (
    <div>
      <Grid
        container
        spacing={3}
        css={[deliveryBasketRowStyle, itemRowStyle]}
        className="document-row"
      >
        <Grid item xs={1} className="media-wrapper delivery">
          <img
            src={thumbnailUrl ?? DEFAULT_IMAGE_PREVIEW}
            alt="document"
            className="media"
          />
        </Grid>

        <Grid item xs={5} className="content">
          <div className="document-title">
            <h5>{name}</h5>
          </div>
          <div className="tag-wrapper">
            <IsCustomizableTag isCustomizable={isCustomizable} />
            {isCustomizable && isValidated && validatedIcon}
          </div>
        </Grid>
        {hasPublicationPeriod(productAttributes) ? (
          <PublicationPeriod
            handleChangeDeliveryDate={handleChangeDeliveryDate}
            deliveryDate={basketDocumentItem.deliveryDate ?? null}
          />
        ) : (
          <>
            <Grid item xs={3} className="date-box">
              {!isCommitment && (
                <div>
                  <span className="date-title">
                    {t('sales.basket_page.delivery_page.wanted_delivery_date')}
                  </span>
                  <UncontrolledDatePicker
                    placeholder={t(
                      'sales.basket_page.delivery_page.wanted_delivery_date'
                    )}
                    value={basketDocumentItem.deliveryDate ?? null}
                    minDate={moment().add(
                      data?.fabricationTimes.standardMode ?? 0,
                      'days'
                    )}
                    size="small"
                    fullWidth
                    handleClear={() => handleClearDeliveryDate()}
                    onChange={(date) => handleChangeDeliveryDate(date)}
                  />
                </div>
              )}
            </Grid>

            <Grid item xs={3} className="comment-box">
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={openComment}
                className="comment-button"
              >
                <CommentOutlinedIcon />{' '}
                {t('sales.basket_page.delivery_page.comment.comment')}
              </Button>
            </Grid>
          </>
        )}
      </Grid>
      <BasketDeliveryContainer
        basketDocumentItem={basketDocumentItem}
        setAddress={setAddress}
        handleUpdateBasketDocument={handleUpdateBasketDocument}
      />
      <CommentDialog
        commentValue={commentValue}
        handleClose={handleClose}
        handleCommentChange={handleCommentChange}
        open={open}
        saveComment={saveComment}
      />
    </div>
  );
};

export default DeliveryBasketRow;
