import React, { useState } from 'react';
import SnackBar, { SnackBarType } from 'components/SnackBar';
import { getLastCommitmentDate } from 'modules/shop/shopHelpers';
import Breadcrumbs from 'modules/shop/components/Breadcrumbs';
import { CampaignDetailHeader } from 'components/CampaignDetailHeader/CampaignDetailHeader';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import { DocumentsFilter } from '../DocumentsFilter';
import DocumentsListView from 'components/OrderProcess/DocumentsListView/DocumentsListView';
import { Grid } from '@material-ui/core';
import InactiveEntityAlert from 'components/InactiveEntityAlert/InactiveEntityAlert';
import Loader from 'components/Loader/Loader/Loader';
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import NoItemsNotice from 'modules/shop/components/NoItemsNotice';
import OrderBasketSummary from 'components/OrderProcess/OrderBasketSummary';
import QuickSearchBar from 'components/QuickSearchBar/QuickSearchBar';
import Splash from 'components/Splash/Splash';
import { SwitchView } from '../../components/SwitchView';
import { campaignDetailsActions } from 'modules/pro/campaigns/campaignsHelper';
import pagesStyle from 'modules/shop/components/style/pages.style';
import { useCampaignPage } from './CampaignPage.hooks';
import useCurrentEntity from 'app/current-entity-context/useCurrentEntity';
import usePermissions from 'app/auth/usePermissions';
import { getSupportCategory } from 'modules/pro/documents/form/model/mappers';
import { BigShopConfigCategory } from 'app/schemaInterfaces/globalTypes';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { toogleButtonStyle } from './CampaignPage.style';

const CampaignPage = () => {
  const { hasPermission } = usePermissions();
  const [gridView, setGridView] = useState<boolean>(true);
  const [isAlert, setIsAlert] = useState<boolean>(false);
  const { currentEntity } = useCurrentEntity();
  const {
    error,
    breadcrumbs,
    campaign,
    isToats,
    documents,
    isLoading,
    basketRefetch,
    documentRefetch,
    basketData,
    isLoadingBasket,
    t,
    isCampaignModel,
    onChange,
    mediumTypes,
    categories,
    searchValue,
    filter,
    setFilter,
    pageFilter,
    setPageFilter,
  } = useCampaignPage();

  if (error) {
    return <Splash title={t('common.generic_error.message')} />;
  }
  const handleChangeCategory = (value: BigShopConfigCategory[]) => {
    setPageFilter({
      ...pageFilter,
      categories: value,
    });
  };

  return (
    <div css={[pagesStyle.page, pagesStyle.campaignPage]}>
      <div style={{ padding: '20px 0px 20px 16px' }}>
        <Breadcrumbs paths={breadcrumbs.paths} options={breadcrumbs.options} />
      </div>

      {campaign && (
        <CampaignDetailHeader
          isArchived={campaign?.archived}
          title={campaign?.name}
          imageUrl={campaign?.imageUrl}
          description={campaign?.description}
          dateFrom={campaign?.dateFrom}
          dateTo={campaign?.dateTo}
          tags={campaign?.tags?.map((tag) => tag.title)}
          isCampaignModel={isCampaignModel}
          actions={(
            handleOpenCampaignDialog,
            deleteCampaign,
            previewCampaign,
            manageCampaign
          ) =>
            campaignDetailsActions({
              handleOpenCampaignDialog,
              deleteCampaign,
              previewCampaign,
              t,
              hasPermission,
              manageCampaign,
              isCampaignModel,
            })
          }
          button={<MoreVertOutlinedIcon />}
          isEditMode={true}
          campaignId={campaign?.id}
          isToast={isToats}
          height={130}
        >
          <InactiveEntityAlert
            message={t('campaign_page.warning.selection_needed_message')}
          />
        </CampaignDetailHeader>
      )}

      {!isLoading && !isLoadingBasket ? (
        <div className="main-container">
          {documents?.length ? (
            <Grid container spacing={5}>
              <Grid item xs={8} className="list">
                <ToggleButtonGroup
                  css={toogleButtonStyle}
                  value={pageFilter.categories}
                  onChange={(event, value) => handleChangeCategory(value)}
                >
                  {categories.map((categorie) => (
                    <ToggleButton value={categorie}>
                      {getSupportCategory(categorie, t)}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
                <div className="toolbar">
                  <div>
                    <SwitchView
                      switchAction={gridView}
                      setSwitchAction={setGridView}
                    />
                  </div>
                  <div className="filter">
                    <DocumentsFilter
                      categoryOptions={categories ?? []}
                      mediumTypesOptions={mediumTypes ?? []}
                      filter={filter}
                      setFilter={setFilter}
                    />
                    <QuickSearchBar
                      placeholder={t(
                        'global.documents_list.table_toolbar_placeholder'
                      )}
                      onChange={onChange}
                      value={searchValue}
                    />
                  </div>
                </div>
                <div>
                  <Grid container spacing={2}>
                    {documents.map((document) => (
                      <DocumentsListView
                        disabled={!currentEntity}
                        document={document}
                        key={document.id}
                        gridView={gridView}
                        documentRefetch={documentRefetch}
                        basketRefetch={basketRefetch}
                        setIsAlert={setIsAlert}
                      />
                    ))}
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={3} className="summary">
                {campaign && basketData && currentEntity && (
                  <OrderBasketSummary
                    basketData={basketData}
                    campaign={campaign}
                    lastOrderDate={getLastCommitmentDate(documents)}
                    isCommitment={!isCampaignModel}
                    documentRefetch={documentRefetch}
                    basketRefetch={basketRefetch}
                  />
                )}
              </Grid>
              <SnackBar
                open={isAlert}
                setOpen={setIsAlert}
                message={t('local.campaign_page.summary.document_added')}
                title={t('local.campaign_page.summary.add_to_basket')}
                icon={<CheckOutlinedIcon />}
                type={SnackBarType.SUCCESS}
              />
            </Grid>
          ) : (
            <NoItemsNotice message={t('shop.common.no_documents')} />
          )}
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default CampaignPage;
