import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const validationButtonStyle = css`
  background: ${colors.background};
  border: 2px solid;

  &:hover {
    background: ${colors.backgroundLight};
  }
`;

export const validationButtonAcceptStyle = css`
  color: ${colors.success};
  border-color: ${colors.success};
`;

export const validationButtonDeclineStyle = css`
  color: ${colors.orangeAcid};
  border-color: ${colors.orangeAcid};
`;
