import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const basketDeliveryContainerStyle = css`
  .price {
    text-align: right;
  }
  .deleteBox {
    display: flex;
    justify-content: center;
    align-items: center;
    .hide {
      display: none;
    }
  }
  .add {
    border-top: 1px solid ${colors.buttonGroupOutlined};
    padding: 24px;
    .MuiSvgIcon-root {
      vertical-align: middle;
      width: 14px;
      color: ${colors.grey};
      margin-right: 15px;
    }
    .quantity {
      margin-left: 11%;
    }
  }
  .quantity-input {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .link {
    display: flex;
    white-space: nowrap;
    a {
      overflow: hidden;
    }
    p {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
