import React from 'react';
import { Grid } from '@material-ui/core';
import { basketDeliveryTableStyle } from './BasketDeliveryTable.style';
import { BasketDeliveryTableProps } from './BasketDeliveryTable.definitions';

const BasketDeliveryTable = ({
  tableHeadData,
  tableRowData,
}: BasketDeliveryTableProps) => (
  <div css={basketDeliveryTableStyle}>
    <div className="header">
      <Grid container spacing={2}>
        {tableHeadData?.map((item, index) => (
          <Grid item key={index} xs={item.width}>
            <h5>{item.content}</h5>
          </Grid>
        ))}
      </Grid>
    </div>
    <div className="content">
      {tableRowData?.map((rowItem, index) => {
        return (
          <Grid container spacing={1} key={index} className="table-row">
            {rowItem.map((cellItem, index) => (
              <Grid item key={index} xs={cellItem.width}>
                {cellItem.content}
              </Grid>
            ))}
          </Grid>
        );
      })}
    </div>
  </div>
);
export default BasketDeliveryTable;
