import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@material-ui/core';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { css } from '@emotion/react';
import ImportDistributionDialog from './ImportDistributionDialog';
import FileUploadRestApi from 'components/FileUploadRestApi';
import {
  ApolloQueryResult,
  OperationVariables,
  useApolloClient,
} from '@apollo/client';
import { GetDocumentsCommitments } from 'app/schemaInterfaces/GetDocumentsCommitments';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  ExportDistribution,
  ExportDistributionVariables,
} from 'app/schemaInterfaces/ExportDistribution';
import {
  EXPORT_DISTRIBUTION,
  EXPORT_GLOBAL_COMMITMENTS,
} from 'app/graphql/queries/commitments';
import {
  ExportGlobalCommitments,
  ExportGlobalCommitmentsVariables,
} from 'app/schemaInterfaces/ExportGlobalCommitments';
import { CommitmentReminderDialog } from '../components/CommitmentReminderDialog';
import useCampaignByIdQuery from 'modules/shop/queries/useCampaignByIdQuery';
import moment from 'moment';
import { getCommitmentEarlierDate } from 'modules/shop/shopHelpers';
import { Distribution } from './DistributionList';
import { useDownloadFileErrorWorkflow } from 'app/utils/customHooks/useDownloadFileErrorWorkflow';

const style = css`
  width: 100%;
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  button {
    margin-left: 10px;
  }
  .import {
    transform: rotate(180deg);
  }
`;

export interface Option {
  label: string;
  value: string;
}

interface DistributionMenuProps {
  options: Option[];
  refetch: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<GetDocumentsCommitments>>;
  distributions: Distribution[] | undefined;
}

const DistributionMenu = ({
  options,
  refetch,
  distributions,
}: DistributionMenuProps): JSX.Element => {
  const [isImportDialogOpen, setIsImportDialogOpen] = useState<boolean>(false);
  const [importId, setImportId] = useState<string | null>(
    options.length ? options[0].value : null
  );
  const downloadFile = useDownloadFileErrorWorkflow();
  const [isImportSuccess, setIsImportSuccess] = useState<boolean | null>(null);
  const client = useApolloClient();
  const onClose = () => {
    setIsImportSuccess(null);
    setIsImportDialogOpen(false);
  };
  const { id: campaignId } = useParams<{ id: string }>();
  const { data: campaignData } = useCampaignByIdQuery(campaignId);

  const disabledCommitmentReminderButton =
    campaignData?.getCampaignById &&
    moment().isBefore(getCommitmentEarlierDate(campaignData?.getCampaignById))
      ? true
      : false;

  const { t } = useTranslation();
  const downloadTemplate = async () => {
    const exportCommitmentsSheet = await client.query<
      ExportDistribution,
      ExportDistributionVariables
    >({
      query: EXPORT_DISTRIBUTION,
      variables: {
        campaignId,
        documentIds: [],
        isCommitment: true,
      },
      fetchPolicy: 'network-only',
    });
    downloadFile({
      url: exportCommitmentsSheet.data.exportDistribution.url,
      filename: exportCommitmentsSheet.data.exportDistribution.name,
      mimetype:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
  };

  const importDistribution = async (file: File[]) => {
    const data = await uploadFile(file);

    if (!data) {
      setIsImportSuccess(false);
    } else {
      setIsImportSuccess(true);
    }
  };

  const exportAll = async () => {
    const allDocumentsIds = distributions?.map(
      (distribution) => distribution.documentIds
    );
    if (allDocumentsIds) {
      const exportCommitmentsSheet = await client.query<
        ExportGlobalCommitments,
        ExportGlobalCommitmentsVariables
      >({
        query: EXPORT_GLOBAL_COMMITMENTS,
        variables: {
          campaignId,
          documentIds: allDocumentsIds,
        },
        fetchPolicy: 'network-only',
      });
      downloadFile({
        url: exportCommitmentsSheet.data.exportGlobalCommitments.url,
        filename: exportCommitmentsSheet.data.exportGlobalCommitments.name,
        mimetype:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
    }
  };

  const handleImportSelect = (value: string) => setImportId(value);

  const query = `
	mutation UploadCommitmentRecommendation($file: Upload!, $documentId: String!, $campaignId: String!) {
		uploadCommitmentRecommendation(file: $file, documentId: $documentId, campaignId: $campaignId)
	}
  `;

  const variables = { file: null, documentId: importId, campaignId };
  const { isUploadInProgress, uploadFile } = FileUploadRestApi(
    query,
    variables,
    refetch
  );

  useEffect(() => {
    options.length ? setImportId(options[0].value) : setImportId(null);
  }, [options]);

  return (
    <div css={style}>
      <div>
        <CommitmentReminderDialog
          disabledCommitmentReminderButton={disabledCommitmentReminderButton}
        />
      </div>
      <div>
        <Button
          color="primary"
          variant="contained"
          startIcon={<GetAppOutlinedIcon className="import" />}
          onClick={() => setIsImportDialogOpen(true)}
        >
          <Typography variant="body2">
            {t('global.distribution_list.import')}
          </Typography>
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<GetAppOutlinedIcon />}
          onClick={exportAll}
        >
          {t('global.distribution_list.export')}
        </Button>
        <ImportDistributionDialog
          onChange={handleImportSelect}
          onClick={importDistribution}
          onClickSecondary={downloadTemplate}
          title={t('global.distribution_list.import_distribution')}
          isOpen={isImportDialogOpen}
          onClose={onClose}
          options={options}
          importId={importId}
          isUploadInProgress={isUploadInProgress}
          isImportSuccess={isImportSuccess}
          setIsImportSuccess={setIsImportSuccess}
        />
      </div>
    </div>
  );
};

export default DistributionMenu;
