import React from 'react';
import Splash from 'components/Splash/Splash';

const NotFound = (): JSX.Element => {
  return (
    <Splash title="Error 404" body="This page does not exist" homeButton />
  );
};

export default NotFound;
