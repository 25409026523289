import { useStoreActions, useStoreState } from 'state/store';
import { useHistory } from 'react-router-dom';
import { SalesRoute } from 'modules/sales/salesHelpers';
import { SelectedBasketdocument } from 'modules/sales/sales.state';
import { useMemo, useState } from 'react';
import BasketContent from '../basketContent/BasketContent';
import useBasketDocumentsQuery from 'queries/useBasketDocumentsQuery';
import useCurrentEntity from 'app/current-entity-context/useCurrentEntity';
import { BasketType, DateStatus } from 'app/schemaInterfaces/globalTypes';
import { useTranslation } from 'react-i18next';
import colors from 'styles/colors.module.scss';
import { hasQuantityError } from 'modules/sales/components/QuantitySelectHelper';

export const useBasketPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { currentEntity } = useCurrentEntity();
  const hasEntity = !!currentEntity?.id;

  const [selectedDateStatus, setSelectedDateStatus] = useState<DateStatus>(
    DateStatus.INPROGRESS
  );
  const { data, refetch } = useBasketDocumentsQuery({
    skip: !hasEntity,
    filter: {
      entityId: currentEntity?.id as string,
      dateStatus: selectedDateStatus,
    },
  });

  const [selectedBasketDocuments, setSelectedBasketDocuments] = useState<
    SelectedBasketdocument[]
  >([]);

  const setBasketDocuments = useStoreActions(
    (actions) => actions.salesModule.basketdocuments.setSelectedBasketDocuments
  );
  const setStep = useStoreActions(
    (actions) => actions.salesModule.step.setActiveStep
  );

  const setBasketType = useStoreActions(
    (actions) => actions.salesModule.basket.setBasketType
  );

  const selectedBasketType = selectedBasketDocuments.map(
    (document) => document.basketDocument.type
  )[0];

  const setCampaignName = useStoreActions(
    (actions) => actions.salesModule.campaignName.setName
  );
  const campaignName = useStoreState(
    (state) => state.salesModule.campaignName.name
  );

  const isBasketPending = useStoreState(
    (state) => state.salesModule.basketStatus.isBasketPending
  );

  const basket = [BasketType.Commitment, BasketType.BigShop].map(
    (basketType, index) => {
      const basketDocuments = data?.basket.basketDocuments.filter(
        (item) => item.type === basketType
      );

      const contentProps = {
        basketDocuments,
        refetch,
        basketType,
      };

      return (
        <div key={index}>
          <BasketContent
            {...contentProps}
            selectedBasketDocuments={selectedBasketDocuments}
            setSelectedBasketDocuments={setSelectedBasketDocuments}
            setCampaignName={setCampaignName}
            selectedBasketType={selectedBasketType}
            campaignName={campaignName}
          />
        </div>
      );
    }
  );

  const onSubmit = () => {
    setBasketDocuments(selectedBasketDocuments);
    setStep(1);
    setBasketType(selectedBasketType);
    history.push(SalesRoute.CommitmentsSteps);
  };

  const isButtonSelected = (value: DateStatus) => value === selectedDateStatus;

  const styleButtonVariant = (value: DateStatus) =>
    isButtonSelected(value) ? 'contained' : 'outlined';
  const styleButtonTitle = (value: DateStatus) =>
    isButtonSelected(value) ? colors.white : colors.grey;

  const buttonsGroup = () => [
    {
      title: `${t(
        'global.campaigns_helper.in_progress'
      )} ${`(${data?.basket.totalItemsInProgress})`}`,
      value: DateStatus.INPROGRESS,
    },
    {
      title: `${t(
        'global.campaigns_helper.terminated'
      )} ${`(${data?.basket.totalItemsTerminated})`}`,
      value: DateStatus.TERMINATED,
    },
  ];
  const isFilterVisible = Boolean(data?.basket);

  //
  // Basket validation
  //
  const { isContinueDisabled, errors } = useMemo(() => {
    const isSelectionEmpty = selectedBasketDocuments.length === 0;
    const hasNotValidatedDocs = selectedBasketDocuments.some(
      ({ basketDocument }) => !basketDocument.document.isValidated
    );
    const hasInvalidQuantities = selectedBasketDocuments.some(
      ({ basketDocument }) => {
        const minQ = basketDocument.document.quantities?.rangeQuantity?.minimum;
        const maxQ = basketDocument.document.quantities?.rangeQuantity?.maximum;
        const strictQ = basketDocument.document.quantities?.strictQuantity;
        return strictQ
          ? basketDocument.quantity !== strictQ
          : hasQuantityError({
              quantity: basketDocument.quantity,
              minQuantity: minQ,
              maxQuantity: maxQ,
            });
      }
    );
    const hasInvalidProducts = selectedBasketDocuments.some(
      ({ basketDocument }) => !basketDocument.document.productId
    );

    const isContinueDisabled =
      isSelectionEmpty ||
      hasNotValidatedDocs ||
      hasInvalidQuantities ||
      hasInvalidProducts ||
      isBasketPending;

    const errors: string[] = [];
    if (hasNotValidatedDocs) {
      errors.push(t('sales.basket_page.errors.document_not_validated'));
    }
    if (hasInvalidQuantities) {
      errors.push(t('sales.basket_page.errors.invalid_quantity'));
    }
    if (hasInvalidProducts) {
      errors.push(t('sales.basket_page.errors.invalid_product_id'));
    }

    return { isContinueDisabled, errors };
  }, [isBasketPending, selectedBasketDocuments, t]);

  return {
    onSubmit,
    selectedBasketDocuments,
    setSelectedBasketDocuments,
    selectedBasketType,
    basket,
    styleButtonVariant,
    styleButtonTitle,
    setSelectedDateStatus,
    buttonsGroup,
    isFilterVisible,
    isContinueDisabled,
    errors,
  };
};
