import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
} from '@material-ui/lab/useAutocomplete/useAutocomplete';
import { useField } from 'formik';
import React, { useState } from 'react';
import { atocompleteFieldStyle } from './TagsAutocompleteField.style';

interface TagOption {
  title: string;
}

export interface TagsAutocompleteFieldProps {
  name: string;
  placeholder?: string;
  options?: Array<TagOption>;
  margin?: 'none' | 'dense' | 'normal';
  size?: 'small' | 'medium';
  fullWidth?: boolean;
  tagCreator?: (title: string) => TagOption;
}

const TagsAutocompleteField = ({
  name,
  placeholder,
  options = [],
  fullWidth,
  margin,
  size,
}: TagsAutocompleteFieldProps): JSX.Element => {
  const [{ onBlur, value }, meta, helper] = useField<TagOption[]>(name);
  const [inputValue, setInputValue] = useState<string>('');

  const handleChange = (
    event: React.ChangeEvent<{}>,
    newValue: TagOption[] | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<TagOption | string>
  ) => {
    if (!newValue) return;
    if (
      reason === 'create-option' &&
      details &&
      typeof details.option === 'string'
    ) {
      helper.setValue([...value, { title: details.option }]);
    } else if (newValue) {
      helper.setValue(newValue);
    }
    helper.setTouched(true);
  };

  return (
    <Autocomplete<TagOption>
      autoComplete
      multiple={true}
      options={options}
      getOptionSelected={(option, value) => option.title === value.title}
      getOptionLabel={({ title }) => title}
      freeSolo
      renderTags={(options, getTagProps) =>
        options.map(({ title }, index) => (
          <Chip label={title} color="primary" {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          error={meta.touched && Boolean(meta.error)}
          helperText={meta.touched && meta.error}
          variant="outlined"
          margin={margin}
          fullWidth={fullWidth}
          placeholder={placeholder}
          size={size}
          css={atocompleteFieldStyle}
        />
      )}
      value={value}
      inputValue={inputValue}
      onChange={(event, value, reason, details) =>
        handleChange(event, value as TagOption[] | null, reason, details)
      }
      onInputChange={(e, value) => setInputValue(value)}
      onBlur={onBlur}
    />
  );
};

export default TagsAutocompleteField;
