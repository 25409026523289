import { useField } from 'formik';
import React from 'react';
import { AssetStatus } from 'app/schemaInterfaces/globalTypes';
import {
  StatusSwitch,
  StatusSwitchProps,
} from 'components/Switch/StatusSwitch';

type AssetFileUploadFieldProps = { name: string } & Omit<
  StatusSwitchProps,
  'status' | 'onStatusChange'
>;

export const AssetStatusSwitchField = ({
  name,
  ...rest
}: AssetFileUploadFieldProps): JSX.Element => {
  const [, meta, helpers] = useField<AssetStatus>(name);

  return (
    <StatusSwitch
      status={meta.value === AssetStatus.Active}
      onStatusChange={(status) =>
        helpers.setValue(status ? AssetStatus.Active : AssetStatus.Inactive)
      }
      {...rest}
    />
  );
};
