import React, { PropsWithChildren } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import { SnackbarProvider } from 'notistack';
import colors from 'styles/colors.module.scss';

const NotificationsProvider = ({
  children,
}: PropsWithChildren<{}>): JSX.Element => {
  const commonStyles = {
    fontSize: '1rem',
    letterSpacing: '0.5px',
  };

  const classes = makeStyles({
    success: {
      ...commonStyles,
      color: colors.primary,
      backgroundColor: colors.successBackground,
    },
    error: {
      ...commonStyles,
      color: colors.error,
      backgroundColor: colors.errorBackground,
    },
    warning: {
      ...commonStyles,
      color: colors.orange300,
      backgroundColor: colors.orange100,
    },
  })();

  const iconClasses = makeStyles((theme) =>
    createStyles({
      root: {
        marginRight: theme.spacing(2),
      },
    })
  )();

  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
      }}
      iconVariant={{
        success: <CheckCircleOutlineRoundedIcon classes={iconClasses} />,
        error: <ErrorOutlineRoundedIcon classes={iconClasses} />,
        warning: <ErrorOutlineRoundedIcon classes={iconClasses} />,
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

export default NotificationsProvider;
