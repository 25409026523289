import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const style = css`
  :hover {
    background-color: ${colors.menuHover};
    border: 1px solid ${colors.selectedCheckboxBorderColor};
    cursor: pointer;
  }
  border: 1px solid ${colors.buttonGroupOutlined};
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 6px;
  height: 135.33px;
  padding: 13px 20.79px;
  margin: 24px 0px;

  .card-content {
    font-size: 14px;
    line-height: 16.8px;

    > div {
      width: 50%;
      h5 {
        color: ${colors.disabled};
        margin-bottom: 8px;
      }
      span {
        :first-of-type {
          font-weight: bold;
        }
        display: block;
      }
      .email {
        color: blue;
      }
    }
  }
`;
