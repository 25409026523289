import { ChevronRight } from '@material-ui/icons';
import EntityCard from 'components/Navigation/user-menu/entity-menu/EntityCard/EntityCard';
import { sharedStyle } from 'components/Navigation/user-menu/entity-menu/shared.style';
import React, { FC } from 'react';
import { entityMenuItemStyle } from './EntityMenuItem.style';

export interface EntityMenuItemProps {
  name: string;
  title: string;
  isDisabled?: boolean;
  logo?: string;
  isLogoHidden?: boolean;
  onClick?: () => void;
}

const EntityMenuItem: FC<EntityMenuItemProps> = ({
  name,
  title,
  logo,
  isDisabled,
  isLogoHidden,
  onClick,
}) => {
  return (
    <div
      data-testid="entity-menu-item"
      css={[
        sharedStyle.baseContainer,
        isDisabled && entityMenuItemStyle.disabledContainer,
      ]}
      onClick={!isDisabled ? onClick : undefined}
    >
      <EntityCard
        css={entityMenuItemStyle.card}
        name={name}
        title={title}
        logo={logo}
        hideLogo={isLogoHidden}
      />
      {!isDisabled && <ChevronRight data-testid="icon" />}
    </div>
  );
};

export default EntityMenuItem;
