import React, { FC, ReactNode } from 'react';
import {
  alertBoxErrorStyle,
  alertBoxHeaderContentStyle,
  alertBoxHeaderStyle,
  alertBoxStyle,
  alertBoxWarningStyle,
} from './AlertBox.style';

import StatusIcon from '../StatusIcon/StatusIcon';

export interface AlertBoxProps {
  variant: 'warning' | 'error';
  header: ReactNode;
}

const AlertBox: FC<AlertBoxProps> = ({ variant, header, children }) => (
  <div
    css={[
      alertBoxStyle,
      variant === 'warning' && alertBoxWarningStyle,
      variant === 'error' && alertBoxErrorStyle,
    ]}
  >
    <div css={alertBoxHeaderStyle}>
      <StatusIcon variant={variant} />
      <div css={alertBoxHeaderContentStyle} className="alert-box--header">
        {header}
      </div>
    </div>
    {children}
  </div>
);

export default AlertBox;
