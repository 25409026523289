import { Avatar, Box, Divider, Menu, Typography } from '@material-ui/core';
import EntityMenu from 'components/Navigation/user-menu/entity-menu/EntityMenu/EntityMenu';
import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { AppRoute } from 'app/routes/routes';
import { userMenuToolbarStyle } from './UserMenuToolbar.style';
import { useUserMenuToolbar } from './UserMenuToolbar.hook';
import { useTranslation } from 'react-i18next';
import { UserType } from 'app/schemaInterfaces/globalTypes';

export interface UserMenuProps {
  handleMenuClose: () => void;
  anchorEl: HTMLLIElement | null;
  open: boolean;
}

export const UserMenuToolbar: FC<UserMenuProps> = ({
  handleMenuClose,
  anchorEl,
  open,
}) => {
  const { t } = useTranslation();
  const { user, handleLogout } = useUserMenuToolbar();
  return (
    <Menu
      data-testid="user-toolbar"
      id="simple-menu"
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      open={open}
      onClose={handleMenuClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      css={userMenuToolbarStyle}
    >
      <Box display="flex" p={2} textAlign="center" className="avatar">
        <Avatar alt={`${user.givenName} ${user.familyName}`}>
          <Typography variant="h2">{`${user.givenName[0].toUpperCase()}${user.familyName[0].toLocaleUpperCase()}`}</Typography>
        </Avatar>
        <Box ml={2}>
          <Typography variant="h4">
            {user.givenName} {user.familyName}
          </Typography>
        </Box>
      </Box>
      <Divider />

      <EntityMenu />
      <NavLink to={AppRoute.Store} style={{ textDecoration: 'none' }}>
        <div className="menu-item">
          <p className="menu-item--text">{t('header.user_menu.store')}</p>
        </div>
      </NavLink>
      <NavLink to="/follow-orders" style={{ textDecoration: 'none' }}>
        <div className="menu-item">
          <p className="menu-item--text">{t('header.user_menu.orders')}</p>
        </div>
      </NavLink>
      {user.type === UserType.Global && (
        <a
          href={process.env.REACT_APP_URL_ONE_MANAGER_WEB}
          style={{ textDecoration: 'none' }}
        >
          <div className="menu-item">
            <p className="menu-item--text">{t('header.user_menu.admin')}</p>
          </div>
        </a>
      )}
      <div className="menu-item" onClick={handleLogout}>
        <p className="menu-item--text logout">{t('common.toolbar.logout')}</p>
      </div>
    </Menu>
  );
};
