import { useQuery } from '@apollo/client';
import {
  GetSimilarAssets,
  GetSimilarAssetsVariables,
} from 'app/schemaInterfaces/GetSimilarAssets';
import { useCallback } from 'react';
import { GET_SIMILAR_ASSETS } from 'app/graphql/queries/dam';
const LIMIT = 8;

const useSimilarAssetQuery = (assetId: string, limit = LIMIT, skip = false) => {
  const { error, data, fetchMore, networkStatus, loading, refetch } = useQuery<
    GetSimilarAssets,
    GetSimilarAssetsVariables
  >(GET_SIMILAR_ASSETS, {
    variables: {
      assetId: assetId,
      pagination: { offset: 0, limit },
    },
    notifyOnNetworkStatusChange: true,
    skip,
  });

  const assets = data?.similarAssets;

  const loadMore = useCallback(() => {
    if (!loading) {
      fetchMore({
        variables: {
          pagination: {
            offset: assets?.length || 0,
            limit,
          },
        },
        updateQuery: (
          prevData: GetSimilarAssets,
          { fetchMoreResult }
        ): GetSimilarAssets => {
          if (!fetchMoreResult || fetchMoreResult.similarAssets.length === 0)
            return prevData;
          return {
            similarAssets: [
              ...prevData.similarAssets,
              ...fetchMoreResult.similarAssets,
            ],
          };
        },
      });
    }
  }, [assets, limit, fetchMore, loading]);

  return { assets, networkStatus, loadMore, refetch, error, loading };
};

export default useSimilarAssetQuery;
