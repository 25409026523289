import React, { Suspense, useEffect } from 'react';
import { Card, CardActionArea } from '@material-ui/core';
import { DEFAULT_IMAGE_PREVIEW } from 'app/utils/common';
import colors from 'styles/colors.module.scss';
import { useLazyQuery } from '@apollo/client';
import { BASKET_DOCUMENTS } from 'app/graphql/queries/basket';
import { useCurrentUser } from 'app/auth/UserContext';
import {
  GetBasketDocuments,
  GetBasketDocumentsVariables,
} from 'app/schemaInterfaces/GetBasketDocuments';
import { getWorkshopDocumentLink } from 'app/utils/route-helper';
import {
  getNationalCampaignLink,
  getLocalCampaignLink,
} from 'modules/shop/shopHelpers';
import { css } from '@emotion/react';
import { useImage } from 'react-image';
import { Skeleton } from '@material-ui/lab';
import { Link } from 'react-router-dom';

interface AvailableDocumentsCardProps {
  document: any;
  documentLink: string;
}

const style = css`
  border: 1px solid ${colors.buttonGroupOutlined};
  border-radius: 10px;
  .media {
    background: linear-gradient(
      to top,
      ${colors.menuHover} 0%,
      ${colors.menuHover} 50%,
      ${colors.primary} 50%,
      ${colors.primary} 100%
    );
  }

  &.campaign-card {
    .media-wrapper {
      height: 310px;
      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
  }

  &.document-card {
    .tag {
      width: 63px;
      position: absolute;
      top: 8px;
      left: 12px;
      display: flex;
      justify-content: center;
    }
    position: relative;
    .media-wrapper {
      background-color: ${colors.workzone};
      background-position: center;
      background-size: contain;
      height: 310px;
      &.media {
        height: 150px;
      }

      .tag {
        width: 62px;
        display: flex;
        justify-content: center;
        left: 16px;
        top: 8px;
        position: absolute;
      }
      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
        padding: 4%;
      }
    }
  }

  &.skeleton-card {
    .MuiSkeleton-root {
      height: 243px;
    }
  }

  .content {
    padding: 10px 16px;

    &:not(:last-of-type) {
      border-bottom: 1px solid ${colors.buttonGroupOutlined};
    }
    &.category {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    h2 {
      padding: 6px 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-family: ScandiaWebMedium;
      font-size: 16px;
      font-weight: 400;
    }

    h6 {
      padding: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      letter-spacing: -0.03em;
    }
    .date {
      color: ${colors.disabled};
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      text-align: right;
    }
  }
`;

const linkStyle = css`
  display: block;
  text-decoration: none;
`;

const AvailableDocumentsCard = ({
  document,
  documentLink,
}: AvailableDocumentsCardProps) => {
  const user = useCurrentUser();
  const [getBasketDocument, { data }] = useLazyQuery<
    GetBasketDocuments,
    GetBasketDocumentsVariables
  >(BASKET_DOCUMENTS);

  function LoadedImage({ imageUrl }: { imageUrl: any }) {
    const { src } = useImage({
      srcList: [imageUrl, DEFAULT_IMAGE_PREVIEW],
    });

    return <img src={src} alt="document" />;
  }

  useEffect(() => {
    if (user.type === 'Local') {
      getBasketDocument({
        variables: { filter: { documentIds: [document.id] } },
      });
    }
  }, [getBasketDocument, document.id, user.type]);

  let linkTarget;
  const nonCustomizableDocLink = `${getWorkshopDocumentLink(
    data?.basket.basketDocuments[0]?.document.id as string
  )}?type=readonly`;

  if (data?.basket.basketDocuments.length) {
    documentLink = document.isCustomizable
      ? getWorkshopDocumentLink(data?.basket.basketDocuments[0]?.document.id)
      : nonCustomizableDocLink;
    linkTarget = '_blank';
  } else {
    documentLink =
      document.campaign.campaignType === 'Model'
        ? getLocalCampaignLink(document.campaignId)
        : getNationalCampaignLink(document.campaignId);
    linkTarget = '_self';
  }

  return (
    <Link to={documentLink} target={linkTarget} css={linkStyle}>
      <Card elevation={0} css={style} className="document-card">
        <CardActionArea>
          <div className="media-wrapper media">
            <Suspense
              fallback={
                <Skeleton animation="wave" height={80} variant="rect" />
              }
            >
              <LoadedImage imageUrl={document.thumbnailUrl} />
            </Suspense>
          </div>

          <div className="content">
            <h2>{document.mediumType}</h2>
          </div>
        </CardActionArea>
      </Card>
    </Link>
  );
};

export default AvailableDocumentsCard;
