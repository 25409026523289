import { useMutation } from '@apollo/client';
import useCurrentEntity from 'app/current-entity-context/useCurrentEntity';
import { UPDATE_CATALOG_MUTATION } from 'app/graphql/queries/version';
import { AppRoute } from 'app/routes/routes';
import { useRouter } from 'app/routes/useRouter';
import {
  UpdateVersionMutation,
  UpdateVersionMutationVariables,
} from 'app/schemaInterfaces/UpdateVersionMutation';
import { useStudioStateContext } from 'modules/workflow/oneStudioContext';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  normalizeDigitalContent,
  normalizeVersion,
} from '../EditPlatform.utils.helper';

export function useSaveCatalog() {
  const globalState = useStudioStateContext();
  const { currentEntity } = useCurrentEntity();
  const router = useRouter();

  const { goBack } = useHistory();
  const { history } = useRouter();
  const [updateVersion, { loading: isLoading }] = useMutation<
    UpdateVersionMutation,
    UpdateVersionMutationVariables
  >(UPDATE_CATALOG_MUTATION, {
    onCompleted: (mutationResult) => {
      console.log({
        status: 'Version Updated Successfully',
        data: mutationResult.updateVersionPages,
      });
    },
  });
  const [redirect, setRedirect] = useState<boolean>(false);
  const saveCatalog = async (hasToRedirect: boolean) => {
    // Save document data API
    await updateVersion({
      variables: {
        id: globalState.version.id,
        pages: normalizeVersion(globalState.version),
        entityId: currentEntity?.id,
        digitalContent: normalizeDigitalContent(
          globalState.version.digitalContent
        ),
      },
    });

    hasToRedirect && setRedirect(true);
  };

  useEffect(() => {
    if (redirect) {
      if (
        history.location.state &&
        (history.location.state as Record<string, string>).prevPath
      ) {
        router.push(
          (history.location.state as Record<string, string>).prevPath
        );
      } else {
        router.push(AppRoute.Shop);
      }
      setRedirect(false);
    }
  }, [redirect, goBack, history, globalState.document?.campaignId, router]);

  return {
    saveCatalog,
    isLoading,
  };
}
