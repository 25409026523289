import {
  Box,
  Button,
  DialogActions,
  Divider,
  FormLabel,
  Grid,
  TextField,
} from '@material-ui/core';
import { GetCategory_category as Category } from 'app/schemaInterfaces/GetCategory';
import { CampaignType } from 'app/schemaInterfaces/globalTypes';
import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import {
  campaignFormValidation,
  SUPPORTED_FORMATS,
} from 'modules/pro/campaigns/campaignsHelper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { GetTags_tags as Tags } from 'app/schemaInterfaces/GetTags';
import ControlledDatePicker from 'components/ControlledDatePicker/ControlledDatePicker';
import ControlledFileUpload, {
  PreviewFile,
} from 'components/ControlledFileUpload/ControlledFileUpload';
import TagsAutocompleteField from 'components/TagsAutocompleteField/TagsAutocompleteField';
import EntitiesField from 'modules/oneManager/entities/form/EntitiesField';
import { CampaignFormModel } from 'modules/pro/campaigns/form/model/definitions';
import { mapToCampaignFormModel } from 'modules/pro/campaigns/form/model/mappers';
import ControlledOneValueSelect from 'components/ControlledOneValueSelect';
import { useCampaignForm } from './CampaignForm.hooks';

export interface CampaignFormProps {
  allTags?: Tags[];
  allCategories?: Category[];
  onFormValidation: (values: CampaignFormModel) => void;
  submitButton: string;
  campaignType: CampaignType;
  campaignName: string;
  campaignDescription: string;
  id?: string;
  isCampaignModel: boolean;
}

const CampaignForm = ({
  allTags,
  allCategories,
  onFormValidation,
  submitButton,
  campaignType,
  campaignName,
  campaignDescription,
  id,
}: CampaignFormProps): JSX.Element => {
  const { t } = useTranslation();

  const { campaign, entities } = useCampaignForm(id);
  return (
    <Formik<CampaignFormModel>
      validationSchema={campaignFormValidation({ campaignType }, t, campaign)}
      initialValues={mapToCampaignFormModel(campaign, entities)}
      enableReinitialize={true}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={async (values) => {
        onFormValidation(values);
      }}
    >
      {({
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
      }) => {
        return (
          <Form>
            <Field
              name="fileUpload"
              component={ControlledFileUpload}
              IsLargePreview={true}
              previewWidth={600}
              previewHeight={200}
              accept={SUPPORTED_FORMATS}
              initialImage={{
                url: campaign?.imageUrl,
              }}
              previewBackgroundSize="cover"
              onDropFormikField={(
                name: string,
                acceptedFiles: PreviewFile[]
              ) => {
                setFieldValue(name, acceptedFiles?.[0]?.path);
                setFieldValue('fileUpload', acceptedFiles[0]);
              }}
            />
            <Box>
              <FormLabel>
                {t('global.campaigns_list.table.columns.labels')} *
              </FormLabel>
            </Box>
            {campaignType === CampaignType.Model ? (
              <Field
                name="category"
                margin="normal"
                selectValues={allCategories?.map((category) => {
                  return {
                    id: category.id,
                    label: category.title,
                    value: category.id,
                  };
                })}
                component={ControlledOneValueSelect}
              />
            ) : (
              <TagsAutocompleteField
                name="tags"
                margin="normal"
                size="small"
                fullWidth
                options={allTags?.map((tag) => ({
                  id: tag.id,
                  title: tag.title,
                }))}
              />
            )}

            <Box pt={1}>
              <FormLabel>{campaignName} *</FormLabel>
            </Box>
            <TextField
              error={Boolean(errors.name) && touched.name}
              name="name"
              variant="outlined"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.name && errors.name}
              margin="normal"
              fullWidth
            />
            <Box pt={1}>
              <FormLabel>
                {t('global.campaigns_list.table.columns.reference')} *
              </FormLabel>
            </Box>
            <TextField
              error={Boolean(errors.reference) && touched.reference}
              name="reference"
              variant="outlined"
              value={values.reference}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.reference && errors.reference}
              margin="normal"
              fullWidth
            />
            <EntitiesField name="entities" />
            <Box pt={1}>
              <FormLabel>{campaignDescription}</FormLabel>
            </Box>
            <TextField
              error={Boolean(errors.description) && touched.description}
              name="description"
              variant="outlined"
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.description && errors.description}
              margin="normal"
              fullWidth
              multiline
              rows={8}
            />
            {campaignType !== CampaignType.Model && (
              <Box>
                <Grid container justify="space-between" spacing={2}>
                  <Grid container item xs={6}>
                    <Box pt={1}>
                      <FormLabel>
                        {t(
                          'global.campaigns_list.table.columns.campaign_start'
                        )}
                        *
                      </FormLabel>
                    </Box>
                    <Field
                      name="dateFrom"
                      fullWidth
                      margin="normal"
                      component={ControlledDatePicker}
                      disablePast={!campaign?.dateFrom}
                      minDate={moment()}
                      maxDate={values.dateTo ?? undefined}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Box pt={1}>
                      <FormLabel>
                        {t('global.campaigns_list.table.columns.campaign_end')}*
                      </FormLabel>
                    </Box>
                    <Field
                      name="dateTo"
                      fullWidth
                      margin="normal"
                      minDate={values.dateFrom ?? moment()}
                      component={ControlledDatePicker}
                    />
                  </Grid>
                </Grid>

                <Box pt={1}>
                  <FormLabel>
                    {t('global.campaigns_list.commitment_period')} *
                  </FormLabel>
                </Box>
                <Grid container justify="space-between" spacing={2}>
                  <Grid container item xs={6}>
                    <Field
                      name="commitmentDateFrom"
                      fullWidth
                      margin="normal"
                      component={ControlledDatePicker}
                      disablePast={!campaign?.commitmentDateFrom}
                      maxDate={values.commitmentDateTo ?? undefined}
                      minDate={moment()}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="commitmentDateTo"
                      fullWidth
                      margin="normal"
                      minDate={values.commitmentDateFrom ?? moment()}
                      component={ControlledDatePicker}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
            <Box pt={2}>
              <Divider />
            </Box>
            <DialogActions>
              <Button variant="contained" type="submit" color="primary">
                {submitButton}
              </Button>
            </DialogActions>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CampaignForm;
