import { FormatFunction } from 'i18next';

const duration: FormatFunction = function (value) {
  if (value && typeof value === 'number') {
    const conversionTable = [
      {
        measurement: 'seconds',
        coefficient: 1000,
        threshold: 60,
      },
      {
        measurement: 'minutes',
        coefficient: 1000 * 60,
        threshold: 60,
      },
      {
        measurement: 'hours',
        coefficient: 1000 * 60 * 60,
        threshold: 24,
      },
      {
        measurement: 'days',
        coefficient: 1000 * 60 * 60 * 24,
        threshold: 30,
      },
      {
        measurement: 'months',
        coefficient: 1000 * 60 * 60 * 24 * 30,
        threshold: 12,
      },
      {
        measurement: 'years',
        coefficient: 1000 * 60 * 60 * 24 * 30 * 12,
        threshold: Infinity,
      },
    ];

    for (const { coefficient, measurement, threshold } of conversionTable) {
      const convertedValue = value / coefficient;
      if (convertedValue < threshold) {
        return `${Math.floor(
          convertedValue
        )} $t(common.dates.measurements.${measurement}, {"count": ${convertedValue} })`;
      }
    }
  }
  return value;
};

export default duration;
