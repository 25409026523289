import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  FormControl,
  FormLabel,
  Tooltip,
} from '@material-ui/core';
import { ErrorMessage, useField, useFormikContext } from 'formik';
import React from 'react';
import { DocumentFormValues } from '../model/definitions';

interface Option {
  label: string;
  value: string | number;
  disabled?: boolean;
  tooltipMessage?: string;
}

export interface CheckboxesGroupProps {
  options: Option[];
  name: string;
  title?: string;
}

// For document form only, do not reuse
const CheckboxesGroup = ({ options, name, title }: CheckboxesGroupProps) => {
  const { setFieldValue } = useFormikContext<DocumentFormValues>();
  const [, meta, helpers] = useField<string[]>(name);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (meta.value.includes(event.target.value)) {
      const index = meta.value.findIndex(
        (value) => value === event.target.value
      );
      const values = [...meta.value];
      values.splice(index, 1);
      helpers.setValue(values);
      if (
        event.target.value === 'Commitment' ||
        event.target.value === 'FreeOrder'
      ) {
        //to get name with lowerCase first letter
        const value = event.target.value;
        const type = value[0].toLowerCase() + event.target.value.slice(1);

        setFieldValue(`document.${type}DateFrom`, null);
        setFieldValue(`document.${type}DateTo`, null);
      }
    } else {
      helpers.setValue([...meta.value, event.target.value]);
    }
  };

  return (
    <div>
      <FormLabel>{title} *</FormLabel>
      <FormGroup row>
        {options.map((option, index) => (
          <Tooltip title={option.tooltipMessage ?? ''} key={index}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(event) => handleChange(event)}
                  name={option.label.toString()}
                  checked={meta.value.includes(option.value as string)}
                  disabled={option.disabled}
                />
              }
              label={option.label}
              value={option.value}
              disabled={option.disabled}
              className="checkbox-group"
            />
          </Tooltip>
        ))}
      </FormGroup>
      {meta.touched && Boolean(meta.error) && (
        <FormControl error={meta.touched && Boolean(meta.error)}>
          <ErrorMessage name={name} component={FormHelperText} />
        </FormControl>
      )}
    </div>
  );
};

export default CheckboxesGroup;
