import { IProductTemplate } from 'modules/workflow/Clients/ToutFaire/ProductItem';
import { BackgroundAsset } from 'modules/workflow/containers/Leftsidebar/TemplatesPanel/BackgroundItemsSubPanel';
import {
  DraggedBackgroundAsset,
  DraggedAsset,
  DraggedProduct,
  Components,
  PersonalizationItem,
  PersonalizationSubType,
} from 'modules/workflow/oneStudioContext';
import { BadgeStatus } from 'components/Badge/StatusBadge';
import { ActionButton } from 'components/ActionsButtons/ActionButtons';
import { TFunction } from 'i18next';

export interface Item {
  image?: string;
  name?: string;
  badgeStatus?: BadgeStatus;
}

export enum DraggedItemTypes {
  PAGE_TEMPLATE = 'PAGE_TEMPLATE',
  ASSET = 'ASSET',
  SET_PERSONALIZATION_ITEM_CATEGORY = 'SET_PERSONALIZATION_ITEM_CATEGORY',
  PRODUCT = 'PRODUCT',
  position = 'position',
  size = 'size',
  components = 'components',
  BACKGROUND_COLOR = 'BACKGROUND_COLOR',
  BACKGROUND_IMAGE = 'BACKGROUND_IMAGE',
}

export interface ItemWithId extends Item {
  id: string;
}

export interface MenuType {
  type: Menu;
}

export enum Menu {
  Page = 'Page',
  Grid = 'Grid',
  Template = 'Template',
  Product = 'Product',
  Asset = 'Asset',
  Customize = 'Customize',
  File = 'File',
}

export interface MenuItem<T> {
  title: string;
  icon: React.ReactNode;
  data: T[];
  actionButtons?: ActionButton<T>[];
}

interface NonNullableItemWithID {
  id: string;
  image: string;
  badgeStatus?: BadgeStatus;
}

export type NormalizedPersonalizationItem = PersonalizationItem & Item;
export type NormalizedAsset = DraggedAsset & MenuType & NonNullableItemWithID;
export type NormalizedIProductTemplate = IProductTemplate &
  MenuType &
  NonNullableItemWithID;
export type NormalizedWorkflowZone = WorkflowZone & MenuType;
export type NormalizedBackgroundAsset = BackgroundAsset &
  BackgroundWithLocalization;

export interface DraggableItems {
  assets?: MenuItem<NormalizedAsset>;
  products: MenuItem<NormalizedIProductTemplate>;
  customizableItems: MenuItem<PersonalizationItem>;
  grids: MenuItem<NormalizedWorkflowZone>;
  templates: MenuItem<DraggedBackgroundAsset>;
  files: MenuItem<DraggedAsset>;
}

export interface WorkflowZone {
  x: number;
  y: number;
  width: number;
  height: number;
}

export type Items =
  | NormalizedAsset
  | NormalizedIProductTemplate
  | PersonalizationItem
  | NormalizedWorkflowZone;

export interface BackgroundWithLocalization {
  zoneIndex?: number;
}

export function isItemAsset<T>(item: T | DraggedAsset): item is DraggedAsset {
  return Boolean((item as DraggedAsset).metadata);
}

export function isItemProduct<T>(
  item: T | IProductTemplate
): item is IProductTemplate {
  return (item as IProductTemplate).price !== undefined;
}

export function isItemBackgroundAsset<T>(
  item: T | DraggedBackgroundAsset
): item is DraggedBackgroundAsset {
  return Boolean((item as DraggedBackgroundAsset).image);
}

export function isItemPersonalization<T>(
  items: T | NormalizedPersonalizationItem
): items is PersonalizationItem {
  return Boolean((items as PersonalizationItem)?.subType);
}

export const isItemDraggedProduct = (
  item: DraggedAsset | DraggedProduct
): item is DraggedProduct => {
  return Boolean((item as DraggedProduct).productDetails);
};

export const isItemPersonalizationProduct = (
  item: DraggedAsset | IProductTemplate
): item is IProductTemplate => {
  return Boolean((item as IProductTemplate).components);
};

export const isItemDraggedAsset = (
  item: DraggedAsset | DraggedProduct
): item is DraggedAsset => {
  return Boolean((item as DraggedAsset).url);
};

export const setEditTitle = (
  subType: string | undefined,
  t: TFunction
): string => {
  switch (subType) {
    case PersonalizationSubType.HEADER_TITLE:
      return t('workflow.typography_item.add_title');
    case PersonalizationSubType.SUBTITLE:
      return t('workflow.typography_item.add_subtitle');
    case PersonalizationSubType.NORMAL_PARAGRAPH:
      return t('workflow.typography_item.add_paragraph');
    case PersonalizationSubType.DATE:
      return t('workflow.typography_item.add_date');
    default:
      return '';
  }
};

export interface ActionButtonsProps<T> {
  setItemToDelete?: ActionButton<T>['onClick'];
  deleteItem: ActionButton<T>['onClick'];
  handleOpenDialog?: ActionButton<T>['onClick'];
}

export const defaultComponents: Components = {
  components: {
    description: {
      color: 'black',
    },
    lePlus: {
      color: 'black',
    },
    title: {
      color: 'black',
    },
  },
};

//Check Types In CardList :
export function isItemPersonalizationCard<T>(
  items: T | NormalizedPersonalizationItem
): items is NormalizedPersonalizationItem {
  return Boolean((items as NormalizedPersonalizationItem)?.subType);
}

export function isItemProductCard<T>(
  item: T | NormalizedIProductTemplate
): item is NormalizedIProductTemplate {
  return (item as NormalizedIProductTemplate).price !== undefined;
}

export const confirmationDialog = (t: TFunction) => ({
  title: t('workflow.right_sidebar.information_panel.actions.title'),
  description: t(
    'workflow.right_sidebar.information_panel.actions.description'
  ),
  subDescription: t(
    'workflow.right_sidebar.information_panel.actions.subDescription'
  ),
});

export const actionButtonsTitle = (t: TFunction) => ({
  reject: t('workflow.right_sidebar.information_panel.actions.reject'),
  accept: t('workflow.right_sidebar.information_panel.actions.accept'),
});
