import React from 'react';
import { IProductTemplate } from 'modules/workflow/Clients/ToutFaire/ProductItem';
import { TypographyIdentities } from 'modules/workflow/oneStudioContext';
import { css } from '@emotion/react/macro';
import { TFunctionResult } from 'i18next';

interface PriceTagElementContainerProps {
  product: IProductTemplate;
  priceTagElement?: TypographyIdentities;
  value?: string | number | TFunctionResult;
  position?: 'absolute' | 'fixed' | 'relative';
  topValue?: number;
  leftValue?: number;
  rightValue?: number;
  widthValue?: string;
  textAlign?: string;
}

const PriceTagElementContainer = ({
  priceTagElement,
  value,
  position,
  topValue,
  leftValue,
  rightValue,
  widthValue,
  textAlign,
}: PriceTagElementContainerProps): JSX.Element => {
  const style = css`
    font-size: ${priceTagElement?.fontSize}pt;
    font-weight: ${priceTagElement?.fontWeight};
    font-style: ${priceTagElement?.fontStyle};
    font-family: ${priceTagElement?.fontFamily};
    position: ${position};
    top: ${topValue}px;
    left: ${leftValue}px;
    right: ${rightValue}px;
    width: ${widthValue};
    text-align: ${textAlign};
    letter-spacing: -0.04em;
    line-height: ${priceTagElement?.lineHeight
      ? `${priceTagElement?.lineHeight}em`
      : '100%'};
    color: ${priceTagElement?.color};
  `;
  return <p css={style}>{value}</p>;
};

export default PriceTagElementContainer;
