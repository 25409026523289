import { Box, CircularProgress } from '@material-ui/core';
import React from 'react';

import AlertBox from 'components/AlertBox/AlertBox';
import UncontrolledSelect from 'components/UncontrolledSelect';
import { useTranslation } from 'react-i18next';
import { useInactiveEntityAlert } from './InactiveEntityAlert.hook';

export interface InactiveEntityAlertProps {
  message: string;
}

const InactiveEntityAlert: React.FC<InactiveEntityAlertProps> = ({
  children,
  message,
}) => {
  const { t } = useTranslation();
  const {
    currentEntity,
    entities,
    handleChange,
    loading,
  } = useInactiveEntityAlert();

  if (currentEntity) {
    return <Box>{children}</Box>;
  }
  return (
    <Box data-testid="alert-box">
      <AlertBox
        variant="warning"
        header={t('common.generic_error.entity.selection_needed')}
      >
        <Box>{message}</Box>
        <Box>
          {loading ? (
            <CircularProgress />
          ) : (
            <UncontrolledSelect
              value=""
              usePlaceholderItem
              fullWidth
              label={t('common.generic_error.entity.select_structure')}
              margin="dense"
              onChange={handleChange}
              options={
                entities?.map((entity) => ({
                  value: entity.id,
                  label: entity.name,
                })) || []
              }
            />
          )}
        </Box>
      </AlertBox>
    </Box>
  );
};

export default InactiveEntityAlert;
