import Chip from '@material-ui/core/Chip';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
} from '@material-ui/lab/useAutocomplete/useAutocomplete';
import React, { useState } from 'react';
import { atocompleteFieldStyle } from './TagsAutocompleteField/TagsAutocompleteField.style';

export interface TagOption {
  title: string;
}

export type UncontrolledTagsAutocompleteProps<T extends TagOption> = {
  value: T[];
  options: T[];
  onChange: (values: T[]) => unknown;
} & (
  | { freeSolo?: true; tagCreator: (title: string) => T }
  | { freeSolo?: false; tagCreator?: undefined }
);

function UncontrolledTagsAutocomplete<T extends TagOption>({
  value,
  options,
  freeSolo = false,
  onChange,
  tagCreator,
  ...textFieldProps
}: UncontrolledTagsAutocompleteProps<T> &
  Pick<TextFieldProps, 'margin' | 'fullWidth' | 'placeholder'>): JSX.Element {
  const [inputValue, setInputValue] = useState('');

  const handleChange = (
    event: React.ChangeEvent<{}>,
    newValue: T[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<TagOption | string>
  ) => {
    if (
      reason === 'create-option' &&
      details &&
      typeof details.option === 'string' &&
      tagCreator
    ) {
      onChange([...value, tagCreator(details.option)]);
    } else {
      onChange(newValue);
    }
  };

  return (
    <Autocomplete<T>
      autoComplete
      multiple={true}
      options={options}
      getOptionLabel={({ title }) => title}
      freeSolo={freeSolo}
      renderTags={(options, getTagProps) =>
        options.map(({ title }, index) => (
          <Chip label={title} color="primary" {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          {...textFieldProps}
          css={atocompleteFieldStyle}
        />
      )}
      value={value}
      inputValue={inputValue}
      onChange={handleChange}
      onInputChange={(event, value) => setInputValue(value)}
    />
  );
}

export default UncontrolledTagsAutocomplete;
