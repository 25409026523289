import { Box, CardMedia, Grid } from '@material-ui/core';
import { AssetStatus } from 'app/schemaInterfaces/globalTypes';
import { useInfiniteScroll } from 'components/infinite-scroll/useInfiniteScroll';
import Loader from 'components/Loader/Loader/Loader';
import AssetFiltersPopover from 'modules/dam/filters/AssetFiltersPopover';
import { AssetSort } from 'modules/dam/filters/AssetSort';
import useAssetQuery from 'modules/dam/hooks/useAssetQuery';
import { AssetFiltersContext } from 'modules/dam/reducers/filters/context';
import {
  filtersReducer,
  initialState,
} from 'modules/dam/reducers/filters/reducer';
import {
  displayableFormats,
  getAssetsType,
} from 'modules/pim/baseProducts/ProductsHelper';
import React, { useReducer, useEffect } from 'react';
import colors from 'styles/colors.module.scss';
import { DEFAULT_IMAGE_PREVIEW } from 'app/utils/common';
import { AssetFiltersActionCreator } from 'modules/dam/reducers/filters/actions';
import { useProductsSheetsContext } from '../baseProducts/BaseProducts';
import { useTranslation } from 'react-i18next';

interface ProductsSheetsFormAssetsProps {
  setGetAssetId: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const ProductsSheetsFormAssets = ({
  setGetAssetId,
}: ProductsSheetsFormAssetsProps): JSX.Element => {
  const { fieldName } = useProductsSheetsContext();

  const { t } = useTranslation();

  const [{ sort, filters }, dispatch] = useReducer(
    filtersReducer,
    initialState
  );
  const { assets, loadMore, error, loading, refetch } = useAssetQuery(
    { sort, filters },
    30
  );

  const formAssetType = getAssetsType(fieldName ?? '', t);

  useEffect(() => {
    if (formAssetType.value) {
      dispatch?.(
        AssetFiltersActionCreator.updateAssetTypes(
          formAssetType.value ? [formAssetType.value] : []
        )
      );
    }
  }, [formAssetType.value]);

  const waypointRef = useInfiniteScroll(loadMore, loading);

  const isDisplayable = (index: number) => {
    return (
      assets &&
      assets[index].url &&
      displayableFormats.includes(assets[index].metadata.mimetype)
    );
  };

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <AssetFiltersContext.Provider value={dispatch}>
      <Box
        pt={2}
        height="787px"
        bgcolor={colors.documentsHeaderBackground}
        overflow="scroll"
        px={2}
      >
        <Box display="flex" justifyContent="flex-end" pr={1}>
          <AssetFiltersPopover filters={filters} />
          <Box pl={2}>
            <AssetSort selectedSort={sort} />
          </Box>
        </Box>
        <Box pt={2}>
          <Grid container spacing={1}>
            {assets?.map((asset, index: number) =>
              asset.status === AssetStatus.Active && asset.isVisible ? (
                <Grid item key={asset.id} xs={4} spacing={6}>
                  <CardMedia
                    image={
                      isDisplayable(index)
                        ? asset.displayedThumbUrl
                        : DEFAULT_IMAGE_PREVIEW
                    }
                    title={asset.name ?? ''}
                    style={{
                      height: '112px',
                      width: '112px',
                      cursor: 'pointer',
                      backgroundSize: 'contain',
                    }}
                    onClick={() => setGetAssetId(asset.id)}
                  />
                </Grid>
              ) : null
            )}
            {loading ? (
              <Box width="100%">
                <Loader error={error} />
              </Box>
            ) : (
              <div ref={waypointRef} />
            )}
          </Grid>
        </Box>
      </Box>
    </AssetFiltersContext.Provider>
  );
};

export default ProductsSheetsFormAssets;
