import { Button } from '@material-ui/core';
import { FC } from 'react';
import { style } from './BasketConfirmation.style';

export interface BasketConfirmationProps {
  onFirstBtnClick: () => void;
  onSecondBtnClick?: () => void;
  title: string;
  firstTitle: string;
  secondTitle?: string;
  icon?: JSX.Element;
  length: number;
  isDisabled?: boolean;
  dataBloc?: JSX.Element;
}

const BasketConfirmation: FC<BasketConfirmationProps> = ({
  onFirstBtnClick,
  title,
  firstTitle,
  secondTitle,
  onSecondBtnClick,
  icon,
  length,
  isDisabled,
  dataBloc,
}) => {
  return (
    <div css={style}>
      <div className="confirmation-header">
        <h4>
          {title} ({length})
        </h4>
      </div>
      {dataBloc && <div className="data-bloc">{dataBloc}</div>}
      <div className="card-link">
        <Button
          color="primary"
          variant="contained"
          disabled={isDisabled}
          onClick={onFirstBtnClick}
        >
          {firstTitle} {icon}
        </Button>
      </div>
      {secondTitle && (
        <div className="card-link2">
          <Button color="primary" variant="text" onClick={onSecondBtnClick}>
            {secondTitle}
          </Button>
        </div>
      )}
    </div>
  );
};

export default BasketConfirmation;
