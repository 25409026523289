import React, { useState, FC, MouseEvent } from 'react';
import { Button, Popover, Box } from '@material-ui/core';
import { ColumnHidingInterface } from 'components/AppTable/AppTable';
import { ColumnInstance } from 'react-table';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import { useTranslation } from 'react-i18next';
import { hideColumnsPopoverStyle } from './HideColumns.style';
import HideColumnsToggler from 'components/HideColumnsToggler/HideColumnsToggler';

export interface ColumnHidingFilter extends ColumnHidingInterface {
  columnsFilter: ColumnInstance<any>[];
}

export interface HideColumnsProps {
  columnHiding: ColumnHidingFilter;
}

const HideColumns: FC<HideColumnsProps> = ({ columnHiding }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <Box>
      <Button
        startIcon={<VisibilityOffOutlinedIcon css={hideColumnsPopoverStyle} />}
        onClick={handleClick}
      >
        {t('common.app_table.hide_fields')}
      </Button>

      <Popover
        id="simple-popover"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        data-testid="hidecolumns-popover"
      >
        <Box px={2.5} py={2}>
          {columnHiding.enabled &&
            columnHiding.columnsFilter
              .filter((column) =>
                columnHiding.fixedColumnsIDs?.includes(column.Header as string)
              ) // Only show checkboxes for non fixed columns
              .map((column) => (
                <HideColumnsToggler key={column.id} column={column} />
              ))}
        </Box>
      </Popover>
    </Box>
  );
};

export default HideColumns;
