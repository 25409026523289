import { TRANSLATION_ROOT } from 'modules/oneManager/storeHelper';
import React, { useState } from 'react';
import colors from 'styles/colors.module.scss';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  KeyboardTimePicker,
  KeyboardTimePickerProps,
} from '@material-ui/pickers';
import format from 'app/i18n/formatters/dates';
import { eachDayOfInterval } from 'date-fns';
import { capitalizeFirstLetter } from 'app/utils/common';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import timeFormat from 'app/i18n/formatters/time';
import { WeekDay, WorkingDay } from '../DeliveryAddress.definitions';

const WorkingHours = (): JSX.Element => {
  const { t, i18n } = useTranslation();

  // dumb logic, to be refactored with Formik in the next US
  const [workingDays, setWorkingDays] = useState<WorkingDay[]>([
    {
      day: WeekDay.monday,
      isWorkingDay: true,
      start: null,
      end: null,
    },
    {
      day: WeekDay.tuesday,
      isWorkingDay: true,
      start: null,
      end: null,
    },
    {
      day: WeekDay.wednesday,
      isWorkingDay: true,
      start: null,
      end: null,
    },
    {
      day: WeekDay.thursday,
      isWorkingDay: true,
      start: null,
      end: null,
    },
    {
      day: WeekDay.friday,
      isWorkingDay: true,
      start: null,
      end: null,
    },
    {
      day: WeekDay.saturday,
      isWorkingDay: false,
      start: null,
      end: null,
    },
    {
      day: WeekDay.sunday,
      isWorkingDay: false,
      start: null,
      end: null,
    },
  ]);

  const handleDateChange = (
    date: MaterialUiPickersDate,
    dayIndex: number,
    field: string
  ) => {
    const newWorkingHours = workingDays.map((day, index) =>
      dayIndex === index
        ? {
            ...day,
            start: field === 'start' ? date : day.start,
            end: field === 'end' ? date : day.end,
          }
        : day
    );

    setWorkingDays(newWorkingHours);
  };

  const handleWorkingDay = (dayIndex: number) => {
    const newWorkingDays = workingDays.map((day, index) =>
      dayIndex === index
        ? {
            ...day,
            isWorkingDay: !day.isWorkingDay,
            start: !day.isWorkingDay ? day.start : null,
            end: !day.isWorkingDay ? day.end : null,
          }
        : day
    );

    setWorkingDays(newWorkingDays);
  };

  const weekDays = eachDayOfInterval({
    // Get days of the week from arbitrary dates to avoid registering them in [lang].json for each language
    start: new Date(2014, 9, 6),
    end: new Date(2014, 9, 12),
  }).map((day, index) => ({
    day: capitalizeFirstLetter(format(day, 'EEEE')),
    isWorkingDay: workingDays[index].isWorkingDay,
    start: workingDays[index].start,
    end: workingDays[index].end,
  }));

  const keyboardTimePickerCommonProps = (
    day: WorkingDay
  ): Partial<KeyboardTimePickerProps> => ({
    size: 'small',
    format: timeFormat[i18n.language],
    cancelLabel: t('common.button.cancel'),
    ampm: false,
    margin: 'normal',
    inputVariant: 'outlined',
    disabled: !day.isWorkingDay,
  });

  return (
    <FormControl component="fieldset" fullWidth>
      <Box p={2} mb={2} bgcolor={colors.documentsHeaderBackground}>
        <Typography variant="h4">
          {t(`${TRANSLATION_ROOT}.delivery_address_form.working_hours.title`)}
        </Typography>
      </Box>
      <Container>
        <FormGroup>
          {weekDays.map((day, index) => (
            <Grid key={index} container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <FormControlLabel
                  key={index}
                  label={day.day}
                  control={<Checkbox checked={day.isWorkingDay} />}
                  onChange={() => handleWorkingDay(index)}
                />
              </Grid>
              <Grid item xs={4}>
                <KeyboardTimePicker
                  id={`working-day-start-${day.day}`}
                  value={day.start}
                  onChange={(date) => handleDateChange(date, index, 'start')}
                  {...keyboardTimePickerCommonProps(day)}
                />
              </Grid>
              <Grid item xs={4}>
                <KeyboardTimePicker
                  id={`working-day-end-${day.day}`}
                  value={day.end}
                  onChange={(date) => handleDateChange(date, index, 'end')}
                  {...keyboardTimePickerCommonProps(day)}
                />
              </Grid>
            </Grid>
          ))}
        </FormGroup>
      </Container>
      <FormControl margin="normal">
        <Typography variant="h4">
          {t(
            `${TRANSLATION_ROOT}.delivery_address_form.working_hours.comments`
          )}
        </Typography>
      </FormControl>
      <TextField
        variant="outlined"
        multiline
        rows={4}
        placeholder={t(
          `${TRANSLATION_ROOT}.delivery_address_form.working_hours.additional_information`
        )}
      />
    </FormControl>
  );
};

export default WorkingHours;
