import {
  Asset,
  Components,
  Position,
  useStudioDispatchContext,
  useStudioStateContext,
  TypographyIdentities,
} from 'modules/workflow/oneStudioContext';
import { studioAppActions } from 'modules/workflow/reducers/actionsInterfaces';
import { NumberSize } from 're-resizable';
import React, { useState } from 'react';
import { StudioContextActionType } from 'modules/workflow/reducers/studioActions';
import { ItemSize } from 'modules/workflow/containers/CatalogPreview/catalogPreviewHelper';
import { Direction } from 're-resizable/lib/resizer';
import { Typography } from '@material-ui/core';
import ResizableDraggableAsset from 'modules/workflow/Clients/ToutFaire/ResizableDraggableAsset';
import {
  VisualIdentityType,
  VisualType,
} from 'app/schemaInterfaces/globalTypes';
import PriceTag from 'modules/workflow/Clients/ToutFaire/components/price/PriceTag';
import {
  isLePlus,
  VisuelFieldName,
} from 'modules/pim/baseProducts/ProductsHelper';
import PriceAndName from './components/price/PriceAndName';
import { css } from '@emotion/react';
import { typographyItemStyle } from './components/price/priceTagHelper';
import { Regrouping } from 'modules/workflow/oneStudioContext';
import { Rnd, DraggableData, RndDragEvent } from 'react-rnd';
import ResizableDraggableRegrouping from './ResizableDraggableRegrouping';
import EditionButtons from 'modules/workflow/containers/CatalogPreview/EditionButtons';
import {
  ProductsSheetsContextState,
  ProductsSheetsStateProvider,
} from 'modules/pim/baseProducts/BaseProducts';
import ProductSheetEdit from 'modules/pim/components/ProductSheetEdit';
import { FileStore } from 'modules/pim/baseProducts/PimFormFieldsConfig';
import { UpdateProduct_updateProduct } from 'app/schemaInterfaces/UpdateProduct';
import { NormalizedIProductTemplate } from 'modules/workflow/containers/CatalogPreview/EditPlatformDefinitions';
import { useWorkflowProductUpdate } from 'components/useWorkflowProductUpdate';
import { PimRegrouping } from 'modules/pim/baseProducts/PimFormConfigTypes';

interface ProductItemProps {
  product: IProductTemplate;
  pageIndex: number;
  zoneIndex: number;
  personalizationKey?: string;
  isDefaultProduct: boolean;
  isDraggableResizable?: boolean;
}

const style = (
  productBackgroundImage: Asset | undefined,
  product: IProductTemplate,
  productHeight: number
) => css`
  position: absolute !important;
  z-index: 10;
  background-image: url(${productBackgroundImage?.url ??
  productBackgroundImage?.thumbnailUrl});
  background-size: ${product.size.width}px ${product.size.height}px;
  background-repeat: no-repeat;
  .card-child {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .product-info {
    top: ${productHeight}px;
    position: relative;
    width: 100%;
  }
  .buttons {
    display: flex;
    z-index: 5;
  }
`;

export interface IProductTemplate {
  id: string;
  family: string;
  name: string;
  description: string;
  price: number;
  salesUnit: string;
  soit: string;
  lePlus: string[];
  components: Components;
  position: Position;
  size: ItemSize;
  campaignId?: string;
  documentId?: string;
  productDetails: Record<string, any>;
  visualIdentities: Asset[] | null;
  typographyIdentities: TypographyIdentities[] | null;
  priceTagTypographies: TypographyIdentities[] | null;
  regrouping?: Regrouping[];
}

export enum AssetTypeInCatalog {
  logo = 'logo',
  picto = 'picto',
  productImage = 'productImage',
}

interface ProductDetails {
  productId: string | undefined;
  productDetails: NormalizedIProductTemplate | undefined;
}

const ProductItem = ({
  product,
  pageIndex,
  zoneIndex,
  isDefaultProduct,
  isDraggableResizable = true,
  personalizationKey,
}: ProductItemProps): JSX.Element => {
  const dispatch = useStudioDispatchContext();
  const globalState = useStudioStateContext();
  const [isHistoryOpen, setIsHistoryOpen] = useState<boolean>(false);

  const [updatedProduct, setUpdatedProduct] = useState<ProductDetails>({
    productId: undefined,
    productDetails: undefined,
  });
  const [isUpdatedProductDetails, setIsUpdatedProductDetails] = useState<
    UpdateProduct_updateProduct
  >();
  const { productId, productDetails } = updatedProduct;
  const [formFilesManager, setFormFilesManager] = useState<FileStore[]>([]);
  const {
    productData,
    isProductsSheetDialogOpen,
    setIsProductsSheetDialogOpen,
  } = useWorkflowProductUpdate({
    productId: productId as string,
    isUpdatedProductDetails: isUpdatedProductDetails as UpdateProduct_updateProduct,
    productDetails: productDetails as NormalizedIProductTemplate,
  });
  const [fieldName, setFieldName] = React.useState<string | undefined>(
    undefined
  );
  const [regrouping, setRegrouping] = useState<PimRegrouping[]>([]);

  const [filesLimitNumber, setFilesLimitNumber] = useState<number>(0);

  const [isActive, setIsActive] = useState<boolean>(false);

  const handleOpenHistory = () => setIsHistoryOpen(!isHistoryOpen);

  const handleProductsSheetDialogOpen = (
    productId: string,
    productDetails: NormalizedIProductTemplate
  ) => {
    setUpdatedProduct({
      productId,
      productDetails,
    });
    setFieldName(undefined);
    setFormFilesManager([]);
    setFilesLimitNumber(0);
  };
  const handleCloseProductsSheetDialog = () => {
    setIsProductsSheetDialogOpen(false);
    setIsHistoryOpen(false);
    setUpdatedProduct({ ...updatedProduct, productId: undefined });
  };

  const updateProductItemPositionHandler = (
    event: RndDragEvent,
    position: DraggableData,
    pageNumber: number,
    zoneIndex: number
  ) => {
    dispatch({
      type: StudioContextActionType.UPDATE_PRODUCT_POSITION,
      payload: {
        pageNumber: pageNumber,
        zoneIndex: zoneIndex,
        productId: product.id,
        position: { x: position.x, y: position.y },
      },
    });
  };

  const toggleColor = (typographyItemId: string) => {
    dispatch(
      studioAppActions.toggleTextColor({
        pageNumber: pageIndex,
        zoneIndex,
        productId: product.id,
        typographyItemId,
      })
    );
  };

  const mainVisual = product.visualIdentities?.find(
    (visual) => visual.fieldName === VisuelFieldName.mainVisuel
  );

  const resizeHandler = (
    event: MouseEvent | TouchEvent,
    direction: Direction,
    ref: HTMLElement,
    delta: NumberSize,
    position: Position
  ) => {
    dispatch(
      studioAppActions.updateProductSize({
        pageNumber: pageIndex,
        zoneIndex,
        productId: product.id,
        delta,
      })
    );
    const mainVisual = product.visualIdentities?.find(
      (visual) => visual.isResizable
    );
    const newSize = {
      height: mainVisual?.size.height ?? 0 + delta.height,
      width: mainVisual?.size.width ?? 0 + delta.width,
    };
    dispatch(
      studioAppActions.updateAssetSize({
        pageNumber: pageIndex,
        zoneIndex,
        productId: product.id,
        size: newSize,
        assetType: mainVisual?.assetType as string,
        visualId: mainVisual?.id,
        isRegrouping: false,
      })
    );

    updateProductItemPositionHandler(
      event,
      position as DraggableData,
      pageIndex,
      zoneIndex
    );
  };

  const productBackgroundImage = product.visualIdentities?.find(
    (visual) =>
      visual.visualType === VisualType.Ambiance &&
      visual.metadata?.dimensions?.width
  );

  const priceChip = product.visualIdentities?.find(
    (visual) => visual.visualIdentityType === VisualIdentityType.Price
  );

  const typographies = product.typographyIdentities?.filter(
    (typography) => !isLePlus(typography.name)
  );

  const hasPriceChip = Boolean(priceChip?.display);

  const values: ProductsSheetsContextState = {
    fieldName,
    setFieldName,
    formFilesManager,
    setFormFilesManager,
    filesLimitNumber,
    setFilesLimitNumber,
    regrouping,
    setRegrouping,
  };

  return (
    <ProductsSheetsStateProvider value={values}>
      <Rnd
        size={product.size}
        position={product.position}
        className={
          isDraggableResizable && !globalState.commentMode
            ? 'resizable-item'
            : 'comment'
        }
        minWidth={priceChip?.size.width ?? '100px'}
        minHeight={priceChip?.size.height ?? '100px'}
        bounds="parent"
        dragHandleClassName="card-child"
        disableDragging={!isDraggableResizable}
        scale={globalState.scaleValue}
        css={style(productBackgroundImage, product, product.size.height)}
        onDragStop={(event, position) => {
          event.stopPropagation();
          updateProductItemPositionHandler(
            event,
            position,
            pageIndex,
            zoneIndex
          );
        }}
        onResizeStop={resizeHandler}
        onMouseOver={(e: any) => {
          e.stopPropagation();
          !globalState.commentMode && setIsActive(true);
        }}
        onMouseLeave={(e: any) => {
          e.stopPropagation();
          setIsActive(false);
        }}
      >
        <div
          className="card-child"
          style={{ width: '100%', height: '100%', position: 'absolute' }}
        >
          {hasPriceChip ? (
            <PriceTag product={product} />
          ) : (
            <PriceAndName
              product={product}
              isDefaultProduct={isDefaultProduct}
              toggleColor={toggleColor}
              typographies={typographies}
            />
          )}
        </div>
        {product.regrouping && (
          <ResizableDraggableRegrouping
            resizeStyle={{
              right: '0',
              top: '25.5px',
              zIndex: mainVisual?.index ? mainVisual.index + 1 : 3,
            }}
            personalizationKey={personalizationKey}
            product={product}
            pageIndex={pageIndex}
            zoneIndex={zoneIndex}
            assetType={AssetTypeInCatalog.productImage}
          />
        )}
        {product.visualIdentities?.map(
          (visual, index) =>
            visual.visualIdentityType !== VisualIdentityType.Price &&
            visual.visualType !== VisualType.Ambiance &&
            visual.size?.width !== 0 && (
              <ResizableDraggableAsset
                id={product.id}
                asset={visual}
                assetType={AssetTypeInCatalog.productImage}
                pageIndex={pageIndex}
                zoneIndex={zoneIndex}
                key={index}
                resizeStyle={
                  visual.isDraggable
                    ? {
                        zIndex: visual.index ?? 4,
                        right: '0',
                        top: '25.5px',
                      }
                    : {
                        zIndex: visual.index ?? 4,
                        border: 'none',
                      }
                }
                personalizationKey={personalizationKey}
                isDraggable={visual.isDraggable}
                isResizable={
                  visual.isResizable !== undefined
                    ? visual.isResizable
                    : visual.isDraggable
                }
                typographyItem={product.typographyIdentities?.find(
                  (typography) => typography.name === visual.fieldName
                )}
                product={product}
                isDefaultProduct={isDefaultProduct}
                toggleColor={toggleColor}
              />
            )
        )}

        {hasPriceChip && typographies && (
          <div className="product-info">
            {typographies
              ?.sort((first, second) => second.name.localeCompare(first.name))
              ?.map((typographyItem, index) => (
                <Typography
                  key={index}
                  css={typographyItemStyle(typographyItem)}
                  onDoubleClick={() => {
                    !isDefaultProduct && toggleColor(typographyItem.id);
                  }}
                >
                  {product.productDetails[typographyItem.name]}
                </Typography>
              ))}
          </div>
        )}
        {isActive && (
          <div className="buttons">
            <EditionButtons<IProductTemplate>
              item={product}
              handleProductsSheetDialogOpen={handleProductsSheetDialogOpen}
            />
          </div>
        )}
      </Rnd>
      <ProductSheetEdit
        openProductsSheetDialog={isProductsSheetDialogOpen}
        handleCloseProductsSheetDialog={handleCloseProductsSheetDialog}
        handleOpenHistory={handleOpenHistory}
        isHistoryOpen={isHistoryOpen}
        productData={productData}
        campaignId={globalState.version.campaignId}
        productId={productId}
        setIsUpdatedProductDetails={setIsUpdatedProductDetails}
        worflowProduct={productDetails}
      />
    </ProductsSheetsStateProvider>
  );
};

export default ProductItem;
