import { useQuery, WatchQueryFetchPolicy } from '@apollo/client';
import { GET_SUPPORTS } from '../app/graphql/queries/supports';
import { GetSupports } from '../app/schemaInterfaces/GetSupports';

const useSupportsQuery = (
  fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network'
) =>
  useQuery<GetSupports>(GET_SUPPORTS, {
    fetchPolicy,
  });

export default useSupportsQuery;
