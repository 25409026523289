import { useQuery } from '@apollo/client';
import { GET_DOCUMENTS_WITH_RELATED } from 'app/graphql/queries/documents';
import {
  GetDocumentsWithRelated,
  GetDocumentsWithRelatedVariables,
} from 'app/schemaInterfaces/GetDocumentsWithRelated';
import { DocumentFilterInput } from 'app/schemaInterfaces/globalTypes';

const useDocumentsQuery = (filter: DocumentFilterInput, limit: number) => {
  const {
    data,
    loading: documentsLoading,
    error: documentsError,
    networkStatus: documentsNetworkStatus,
    refetch: documentsRefetch,
    fetchMore,
  } = useQuery<GetDocumentsWithRelated, GetDocumentsWithRelatedVariables>(
    GET_DOCUMENTS_WITH_RELATED,
    {
      variables: {
        filter,
        pagination: { offset: 0, limit: limit },
      },
    }
  );

  const { documents, mediumTypes, orderTypes, numbersOfPages, totalItems } =
    data?.documents ?? {};

  return {
    documents,
    documentsError,
    documentsLoading,
    documentsNetworkStatus,
    documentsRefetch,
    mediumTypes,
    numbersOfPages,
    orderTypes,
    fetchMore,
    documentsComplete: !!totalItems && totalItems <= (documents?.length ?? 0),
  };
};

export default useDocumentsQuery;
