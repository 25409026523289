import { IconButton } from '@material-ui/core';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from '@material-ui/pickers';
import { format } from 'app/utils/common';
import { FieldProps } from 'formik';
import React, { FC, useState } from 'react';
import { controlledDatePickerStyle } from './ControlledDatePicker.style';
import { handleChange } from './ControlledDatePicker.utils';

const ControlledDatePicker: FC<FieldProps & KeyboardDatePickerProps> = ({
  field: { name, value },
  form: { errors, touched, setTouched, setFieldValue },
  ...props
}) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <KeyboardDatePicker
      data-testid="date-picker"
      {...props}
      css={controlledDatePickerStyle}
      disableToolbar
      inputVariant="outlined"
      variant="inline"
      format={format}
      value={value}
      onBlur={() => {
        setTouched({ ...touched, [name]: true });
      }}
      autoOk
      open={open}
      helperText={touched[name] && errors[name]}
      KeyboardButtonProps={{
        'aria-label': 'change date',
      }}
      error={touched[name] && Boolean(errors[name])}
      InputProps={{
        endAdornment: !props.disabled ? (
          <IconButton onClick={() => setOpen(true)} data-testid="event-button">
            <EventOutlinedIcon />
          </IconButton>
        ) : undefined,
      }}
      InputAdornmentProps={{
        position: 'start',
        style: {
          display: 'none',
        },
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={handleChange(setTouched, setFieldValue, touched, name)}
    />
  );
};

export default ControlledDatePicker;
