import React, { PropsWithChildren, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import useCurrentEntity from 'app/current-entity-context/useCurrentEntity';
import {
  GetCompetitors,
  GetCompetitorsVariables,
} from 'app/schemaInterfaces/GetCompetitors';
import { ApolloQueryResult } from '@apollo/client';
import { useCompetitorForm } from '../AddCompetitorForm/AddCompetitorForm.hook';
import { FormProvider } from 'react-hook-form';
import ConfirmationDialog, {
  ConfirmationAction,
} from 'components/ConfirmationDialogs/ConfirmationDialog/ConfirmationDialog';
import AddCompetitorForm from '../AddCompetitorForm/AddCompetitorForm';
import { ButtonProps } from '@material-ui/core';
import colors from 'styles/colors.module.scss';

const CompetitorFormContainer = ({
  refetch,
  children,
}: PropsWithChildren<{
  refetch: (
    variables?: Partial<GetCompetitorsVariables> | undefined
  ) => Promise<ApolloQueryResult<GetCompetitors>>;
}>): JSX.Element => {
  const { t } = useTranslation();
  const { currentEntity } = useCurrentEntity();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onClose = (isSuccess: boolean) => {
    setIsOpen(!isSuccess);
  };

  const { handleSubmit, handleClose, form, defaultValues } = useCompetitorForm({
    onClose,
    t,
    entityId: currentEntity?.id,
    refetch,
  });

  const actions = useMemo<ConfirmationAction[]>(
    () => [
      {
        title: t('common.button.cancel'),
        dataTestId: 'competitor.cancel',
        buttonVariant: 'outlined' as ButtonProps['variant'],
      },
      {
        title: t('competitors.add_competitor_dialog.save'),
        dataTestId: 'competitor.save',
        onClick: handleSubmit,
        icon: form.formState.isSubmitting ? (
          <CircularProgress
            size={20}
            data-testid="progress-icon"
            style={{ color: colors.white }}
          />
        ) : undefined,
        type: 'submit' as ButtonProps['type'],
        isKeptOpenOnClick: true,
      },
    ],
    [handleSubmit, form.formState.isSubmitting, t]
  );

  return (
    <FormProvider {...form}>
      <div onClick={() => setIsOpen(true)}>{children}</div>
      <ConfirmationDialog
        data-testedid="dialog"
        maxWidth="sm"
        isOpen={isOpen}
        onClose={handleClose}
        title={t('competitors.add_competitor_dialog.add')}
        actions={actions}
      >
        <AddCompetitorForm
          defaultValues={defaultValues}
          hasEntityError={!currentEntity?.id}
        />
      </ConfirmationDialog>
    </FormProvider>
  );
};

export default CompetitorFormContainer;
