import React from 'react';
import { Button, Box, FormControl, Typography } from '@material-ui/core';
import ButtonGroup from '@material-ui/core/ButtonGroup';

export interface ButtonGroupTitle {
  title: string;
  handleChange?: (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  buttonColor?: 'inherit' | 'default' | 'primary' | 'secondary';
  button: number;
}

export interface TemporalFilterProps {
  buttonsGroupTitle: ButtonGroupTitle[];
  styleButtonVariant: (button: number) => 'text' | 'contained' | 'outlined';
  styleButtonTitle: (button: number) => string;
  count?: number;
}

const TemporalFilters = ({
  buttonsGroupTitle,
  styleButtonTitle,
  styleButtonVariant,
}: TemporalFilterProps): JSX.Element => {
  return (
    <FormControl>
      <Box display="flex" alignItems="center">
        <ButtonGroup size="medium" color="primary" variant="outlined">
          {buttonsGroupTitle.map(
            ({ title, handleChange, buttonColor, button }, index: number) => (
              <Button
                color={buttonColor}
                variant={styleButtonVariant(button)}
                key={index}
                onClick={handleChange}
              >
                <Typography
                  style={{ color: styleButtonTitle(button) }}
                  variant="body2"
                >
                  {title}
                </Typography>
              </Button>
            )
          )}
        </ButtonGroup>
      </Box>
    </FormControl>
  );
};

export default TemporalFilters;
