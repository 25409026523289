import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const radioValueBoxStyle = (hasBorderAndBackground: boolean) => css`
  ${hasBorderAndBackground &&
  css`
    border-width: 2px;
    border-style: solid;
  `}
  padding: 7px 8px;
  min-width: 202px;
  height: 40px;
  border-radius: 4px;
  border-color: ${colors.buttonGroupOutlined};
`;

export const radioValueSelectedBoxStyle = (
  hasBorderAndBackground: boolean
) => css`
	${
    hasBorderAndBackground &&
    css`
      background: ${colors.menuHover};
    `
  }
	border-color: ${colors.selectedCheckboxBorderColor};
`;
