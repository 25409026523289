import { Box, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import React, { FC, useState } from 'react';
import { ColumnInstance } from 'react-table';

export interface HideColumnsTogglerProps {
  column: ColumnInstance<any>;
  disabled?: boolean;
}

const HideColumnsToggler: FC<HideColumnsTogglerProps> = ({ column }) => {
  const [isChecked, setIsChecked] = useState(
    !column.getToggleHiddenProps().checked
  );

  function isCheckedHandler() {
    column.toggleHidden(!isChecked);
    setIsChecked(!isChecked);
  }

  return (
    <Box>
      <FormControlLabel
        control={
          <Checkbox
            checked={isChecked}
            onChange={isCheckedHandler}
            data-testid="hidecolumns-checkbox"
          />
        }
        label={<Typography variant="h5">{column.Header}</Typography>}
      />
    </Box>
  );
};

export default HideColumnsToggler;
