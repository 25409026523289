import { useQuery } from '@apollo/client';
import {
  Box,
  CircularProgress,
  FormLabel,
  TextFieldProps,
} from '@material-ui/core';
import { useFormikContext } from 'formik';
import React from 'react';
import { GET_DIVISIONS } from 'app/graphql/queries/divisions';
import { GET_DIVISIONS as I_GET_DIVISIONS } from 'app/schemaInterfaces/GET_DIVISIONS';
import {
  DivisionSelectModel,
  useDivisionSelect,
} from 'components/divisions/useDivisionSelect';
import UncontrolledSelect from 'components/UncontrolledSelect';

export interface DivisionSelectFieldsProps {
  name: string;
  margin?: TextFieldProps['margin'];
}

export const DivisionSelectFields = ({
  name,
  ...rest
}: DivisionSelectFieldsProps): JSX.Element => {
  const { data, loading } = useQuery<I_GET_DIVISIONS>(GET_DIVISIONS);
  const { setFieldValue, getFieldMeta } = useFormikContext();

  const getFieldKey = (divisionKey: string) => `${name}.${divisionKey}`;
  const getFieldValue = (key: string) =>
    getFieldMeta<string | null | undefined>(getFieldKey(key)).value;

  const { selectModels, getOptions } = useDivisionSelect(data, getFieldValue);

  const handleChange = (selectModel: DivisionSelectModel, value: string) => {
    setFieldValue(getFieldKey(selectModel.key), value);
    selectModel.children.forEach((key) => {
      setFieldValue(getFieldKey(key), null);
    });
  };

  return loading && selectModels ? (
    <Box display="flex" justifyContent="center">
      <CircularProgress />
    </Box>
  ) : (
    <>
      {selectModels.map((selectModel) => {
        return (
          <Box key={selectModel.key}>
            <Box pt={1}>
              <FormLabel>{selectModel.label}</FormLabel>
            </Box>
            <UncontrolledSelect
              placeholder={selectModel.label}
              value={getFieldValue(selectModel.key) ?? ''}
              options={getOptions(selectModel)}
              usePlaceholderItem={true}
              onChange={({ target: { value } }) =>
                handleChange(selectModel, value)
              }
              {...rest}
            />
          </Box>
        );
      })}
    </>
  );
};
