import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  GetCompetitors,
  GetCompetitorsVariables,
  GetCompetitors_competitors,
} from 'app/schemaInterfaces/GetCompetitors';
import { Button, Grid, IconButton } from '@material-ui/core';
import { competitorsPageStyle } from './CompetitorsPage.style';
import AddIcon from '@material-ui/icons/Add';
import { ApolloQueryResult, useMutation } from '@apollo/client';
import CompetitorFormContainer from '../CompetitorFormContainer/CompetitorFormContainer';
import FacebookIcon from '@material-ui/icons/Facebook';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import { DELETE_COMPETITOR } from 'app/graphql/queries/competitors';
import {
  DeleteCompetitorVariables,
  DeleteCompetitor,
} from 'app/schemaInterfaces/DeleteCompetitor';

const CompetitorsPage = ({
  competitors,
  refetch,
}: {
  competitors: GetCompetitors_competitors[];
  refetch: (
    variables?: Partial<GetCompetitorsVariables> | undefined
  ) => Promise<ApolloQueryResult<GetCompetitors>>;
}): JSX.Element => {
  const { t } = useTranslation();

  const [deleteCompetitor] = useMutation<
    DeleteCompetitor,
    DeleteCompetitorVariables
  >(DELETE_COMPETITOR);

  const deleteEntitysCompetitor = async (id: string) => {
    const deleteData = await deleteCompetitor({
      variables: {
        id,
      },
    });
    if (deleteData.data?.deleteCompetitor.deletedCount) {
      refetch();
    } else {
      // TODO : deal with errors
    }
  };

  return (
    <Grid css={competitorsPageStyle} container>
      <div className="header">
        <h1>{t('competitors.my_competitors')}</h1>

        <CompetitorFormContainer refetch={refetch}>
          <Button
            variant="contained"
            color="primary"
            className="button"
            endIcon={<AddIcon fontSize="large" />}
          >
            {t('competitors.add_competitor_dialog.add')}
          </Button>
        </CompetitorFormContainer>
      </div>

      <Grid container item xs={12} className="content">
        {competitors.map((competitor, key) => {
          return (
            <Grid
              item
              key={key}
              xs={4}
              xl={3}
              className="iframe-container"
              data-testid="iframe-container"
            >
              <div className="title">
                <FacebookIcon />
                <IconButton
                  onClick={() => deleteEntitysCompetitor(competitor.id)}
                >
                  <DeleteForeverOutlinedIcon />
                </IconButton>
              </div>
              <iframe
                className="iframe"
                title={competitor.id}
                data-tabs="timeline"
                src={`https://www.facebook.com/plugins/page.php?href=${competitor.url}&tabs=timeline&share=false&adapt_container_width=true&share=false&width=401&height=650&hide_cta=true`}
                height="650px"
                style={{
                  overflow: 'scroll',
                }}
                data-adapt-container-width="true"
                scrolling="yes"
                frameBorder={0}
                hide-cta="true"
              ></iframe>
            </Grid>
          );
        })}
        <Grid item xs={4} xl={3} className="add-container">
          <CompetitorFormContainer refetch={refetch}>
            <div className="add-competitor" data-testid="add-competitor">
              <img
                src={`/${process.env.REACT_APP_ASSETS}/add-social-networks-2.png`}
                alt="social-networks"
                className="logo"
              />
              <Button variant="contained" color="primary" className="button">
                {t('competitors.add')}
              </Button>
            </div>
          </CompetitorFormContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CompetitorsPage;
