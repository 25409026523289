import React, { useEffect, useState } from 'react';
import { Card, CardContent, IconButton } from '@material-ui/core';
import { NumberSize } from 're-resizable';
import {
  useStudioStateContext,
  useStudioDispatchContext,
  PersonalizationSubType,
  PersonalizationItem,
} from 'modules/workflow/oneStudioContext';
import { Direction } from 're-resizable/lib/resizer';
import colors from 'styles/colors.module.scss';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useTranslation } from 'react-i18next';
import {
  dropHandler,
  handleOnDropAddressBlock,
  updateItemContent,
} from 'modules/workflow/containers/CatalogPreview/onDropHandler';
import ProductTemplate from 'modules/workflow/Clients/ToutFaire/ProductTemplate';
import Asset from 'modules/workflow/containers/Leftsidebar/AssetsPanel/Asset';
import { IProductTemplate } from 'modules/workflow/Clients/ToutFaire/ProductItem';
import {
  DraggedItemTypes,
  isItemDraggedAsset,
} from 'modules/workflow/containers/CatalogPreview/EditPlatformDefinitions';
import {
  setNewTab,
  updatePersoItemDimensionsHandler,
  updatePersoItemPositionHandler,
} from 'modules/workflow/containers/CatalogPreview/Personalization/personalizationZoneHelpers';
import { studioAppActions } from 'modules/workflow/reducers/actionsInterfaces';
import { css } from '@emotion/react';
import useAppContext from 'app/app-context/useAppContext';
import { productSize } from '../CatalogPreviewHelpers';
import { AssetType, UserType } from 'app/schemaInterfaces/globalTypes';
import useCurrentEntity from 'app/current-entity-context/useCurrentEntity';
import { StudioContextActionType } from 'modules/workflow/reducers/studioActions';
import { Rnd, DraggableData, RndDragEvent } from 'react-rnd';
import EditionButtons from '../EditionButtons';

interface ContentItemProps {
  pageNumber: number;
  zoneIndex: number;
  contentItem: PersonalizationItem;
  zoomScale: number;
  getProduct: () => Promise<Omit<IProductTemplate, 'components' | 'position'>>;
  setIsPersonalizationDropZone: React.Dispatch<React.SetStateAction<boolean>>;
  isPersonalizationDropZone: boolean;
}

const ContentItem = ({
  pageNumber,
  zoneIndex,
  contentItem,
  zoomScale,
  setIsPersonalizationDropZone,
  isPersonalizationDropZone,
  getProduct,
}: ContentItemProps): JSX.Element => {
  const globalState = useStudioStateContext();

  const style = css`
    display: flex;
    align-items: center;
    justify-content: center;
    border: dashed ${1.7 / globalState.scaleValue}px ${colors.primary};
    background-color: rgba(255, 255, 255, 0.5);
    width: 100%;
    height: 100%;

    .cardContent {
      align-items: center;
      justify-content: center;
      text-align: center;
      color: ${colors.primary};
      font-size: ${8 / globalState.scaleValue}px;
      line-height: normal;
      padding: 2px;

      .MuiSvgIcon-root {
        font-size: ${9 / globalState.scaleValue}px;
      }
      .MuiIconButton-root {
        padding: 0;
      }
    }
  `;

  const { t } = useTranslation();

  const { currentEntity } = useCurrentEntity();

  const dispatch = useStudioDispatchContext();

  const [contentSubZonePosition, setContentSubZonePosition] = useState({
    x: contentItem.position?.x ?? 0,
    y: contentItem.position?.y ?? 0,
  });

  const [contentSubZoneDimensions, setContentSubZoneDimensions] = useState({
    width: contentItem.dimensions?.width,
    height: contentItem.dimensions?.height,
  });

  useEffect(() => {
    setContentSubZonePosition({
      x: contentItem.position?.x ?? 0,
      y: contentItem.position?.y ?? 0,
    });
    setContentSubZoneDimensions({
      width: contentItem.dimensions?.width,
      height: contentItem.dimensions?.height,
    });
  }, [
    contentItem.dimensions?.height,
    contentItem.dimensions?.width,
    contentItem.position?.x,
    contentItem.position?.y,
  ]);

  const isDraggableResizableProduct = !contentItem.content;

  const updateContentItemPositionHandler = (
    event: RndDragEvent,
    position: DraggableData,
    pageNumber: number,
    zoneIndex: number,
    contentKey: string
  ) => {
    const personalizationItems: PersonalizationItem[] | undefined =
      globalState.version.pages[pageNumber - 1].pageTemplate[zoneIndex]
        .personalizationItems;
    if (personalizationItems) {
      updatePersoItemPositionHandler(
        dispatch,
        personalizationItems,
        position,
        pageNumber,
        zoneIndex,
        contentKey,
        setContentSubZonePosition
      );
    }
  };

  function handleInitAddressBlock() {
    const newAsset = handleOnDropAddressBlock(
      currentEntity,
      globalState,
      dispatch
    );
    if (!newAsset) return;
    dispatch({ type: StudioContextActionType.UPDATE_UNDO_REDO_HISTORY });
    const personalizationItems =
      globalState.version.pages[pageNumber - 1].pageTemplate[zoneIndex]
        .personalizationItems;

    if (!personalizationItems) return;

    const newPersonalizationItems = updateItemContent(
      personalizationZone,
      {
        item: newAsset,
        type: DraggedItemTypes.ASSET,
        subType: AssetType.AddressBlock,
      },
      personalizationItems
    );

    dispatch(
      studioAppActions.setPersonalizationItem({
        pageNumber: pageNumber - 1,
        zoneIndex: zoneIndex,
        personalizationItems: newPersonalizationItems,
      })
    );
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (
      contentItem.subType === PersonalizationSubType.ADDRESS_BLOCK &&
      !contentItem.content &&
      globalState?.document?.supportId
    ) {
      handleInitAddressBlock();
    }
  }, [
    contentItem.content,
    contentItem.subType,
    globalState?.document?.supportId,
  ]);

  const updateContentItemDimensionsHandler = (
    event: MouseEvent | TouchEvent,
    direction: Direction,
    ref: HTMLElement,
    delta: NumberSize,
    pageNumber: number,
    zoneIndex: number,
    contentKey: string
  ) => {
    // set the global state with X and Y from position
    const personalizationItems =
      globalState.version.pages[pageNumber - 1].pageTemplate[zoneIndex]
        .personalizationItems;

    if (personalizationItems) {
      updatePersoItemDimensionsHandler(
        delta,
        pageNumber,
        zoneIndex,
        contentKey,
        dispatch,
        personalizationItems,
        contentSubZoneDimensions,
        setContentSubZoneDimensions
      );
    }
  };

  const personalizationZone = {
    key: contentItem.key,
    subType: contentItem.subType,
    size: {
      width: contentItem.dimensions?.width,
      height: contentItem.dimensions?.height,
    },
  };

  const openTab = () => {
    dispatch(
      studioAppActions.setTabValue({
        tabValue: setNewTab(contentItem.subType, globalState.tabValue),
      })
    );
  };
  const [isActive, setIsActive] = useState<boolean>(false);

  const {
    client: { config: clientConfig },
  } = useAppContext();

  const supportConfig = clientConfig?.workflow.supportConfig
    ? clientConfig?.workflow.supportConfig.find(
        ({ supportId }) => supportId === globalState.document?.supportId
      )
    : null;

  const emptyPersonalizationCard = (
    title: string,
    userType: UserType
  ): JSX.Element => (
    <>
      <Card
        css={style}
        className="handle"
        variant="outlined"
        onDragOver={(e) => {
          e.preventDefault();
          setIsPersonalizationDropZone(true);
        }} //this line is required to let onDrop event to work 'issue in onDrop event'
        onDrop={(event) => {
          isPersonalizationDropZone &&
            dropHandler(
              pageNumber - 1,
              zoneIndex,
              dispatch,
              globalState,
              event,
              zoomScale,
              getProduct,
              productSize(supportConfig),
              userType,
              personalizationZone
            );
          setIsPersonalizationDropZone(false);
        }}
        onDragLeave={(e) => {
          e.preventDefault();
          setIsPersonalizationDropZone(false);
        }}
      >
        <CardContent className="cardContent">
          <IconButton color="primary" onClick={openTab}>
            <AddCircleIcon />
          </IconButton>
          <p>{title}</p>
        </CardContent>
      </Card>
      {isActive && (
        <EditionButtons<PersonalizationItem>
          item={contentItem}
          handleTypographyDialog={openTab}
        />
      )}
    </>
  );

  const personalizationCard = (title: string): JSX.Element => {
    if (contentItem.content) {
      if (isItemDraggedAsset(contentItem.content)) {
        return (
          <Asset
            pageNumber={pageNumber - 1}
            zoneIndex={zoneIndex}
            assetIndex={0}
            asset={contentItem.content}
            isDraggableResizable={isDraggableResizableProduct}
          />
        );
      } else
        return (
          <ProductTemplate
            isDefaultProduct={false}
            productFromPlatform={[contentItem.content]}
            pageIndex={pageNumber - 1}
            zoneIndex={zoneIndex}
            isDraggableResizable={isDraggableResizableProduct}
            personalizationKey={contentItem.key}
          />
        );
    } else {
      return emptyPersonalizationCard(title, contentItem.userType);
    }
  };

  const contentSubZoneToRender = () => {
    switch (contentItem.subType) {
      case PersonalizationSubType.PRODUCT:
        return personalizationCard(t('workflow.content_item.add_product'));
      case PersonalizationSubType.VISUAL:
        return personalizationCard(t('workflow.content_item.add_visual'));
      case PersonalizationSubType.PICTO:
        return personalizationCard(t('workflow.content_item.add_picto'));
      case PersonalizationSubType.LOGO:
        return personalizationCard(t('workflow.content_item.add_logo'));
      case PersonalizationSubType.CHARTER:
        return personalizationCard(t('workflow.content_item.add_chart'));
      case PersonalizationSubType.ADDRESS_BLOCK:
        return personalizationCard(t('workflow.content_item.add_address'));
    }
  };

  const minCardResize =
    contentItem.subType === PersonalizationSubType.PRODUCT ? '10px' : '1px';

  const isDraggableResizable = !globalState.document?.isCustomization;

  return (
    <>
      <Rnd
        size={contentSubZoneDimensions}
        minWidth={minCardResize}
        position={contentSubZonePosition}
        minHeight={minCardResize}
        style={{
          position: 'absolute',
          zIndex: 16,
        }}
        dragHandleClassName="handle"
        disableDragging={!isDraggableResizable}
        className={
          isDraggableResizable && !globalState.commentMode
            ? 'resizable-item'
            : 'comment'
        }
        bounds={'.boundZone-' + (pageNumber - 1) + '-' + zoneIndex}
        scale={globalState.scaleValue * zoomScale}
        onResizeStop={(event, direction, ref, delta, position) => {
          updateContentItemPositionHandler(
            event,
            position as DraggableData,
            pageNumber,
            zoneIndex,
            contentItem.key
          );
          updateContentItemDimensionsHandler(
            event,
            direction,
            ref,
            delta,
            pageNumber,
            zoneIndex,
            contentItem.key
          );
        }}
        onDragStop={(event, position) =>
          updateContentItemPositionHandler(
            event,
            position,
            pageNumber,
            zoneIndex,
            contentItem.key
          )
        }
        onMouseOver={() => {
          !globalState.commentMode && setIsActive(true);
        }}
        onMouseLeave={() => {
          setIsActive(false);
        }}
      >
        {contentSubZoneToRender()}
      </Rnd>
    </>
  );
};

export default ContentItem;
