import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const style = css`
  border: 2px solid ${colors.buttonGroupOutlined};
  border-radius: 4px;
  height: fit-content;
  padding-bottom: 16px;
  margin-bottom: 16px;
  width: 100%;

  .confirmation-header {
    background-color: ${colors.documentsHeaderBackground};
    height: 59px;
    border-bottom: 2px solid ${colors.buttonGroupOutlined};
    h4 {
      padding-top: 16px;
      padding-left: 16px;
    }
  }
  .data-bloc {
    padding: 16px 16px 0px 16px;
    .data-bloc-elements {
      display: flex;
      justify-content: space-between;
      .value {
        font-weight: bold;
      }
    }
  }
  .card-link {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 32px;

    .MuiSvgIcon-root {
      margin-left: 8px;
    }

    .MuiCircularProgress-root {
      margin-left: 12px;
      color: ${colors.white};
    }
  }
  .card-link2 {
    text-align: center;
  }
`;
