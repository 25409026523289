import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  FormControlLabel,
  Checkbox,
  DialogContent,
  Grid,
  DialogTitle,
  DialogActions,
  Typography,
  Box,
  IconButton,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import {
  useStudioDispatchContext,
  useStudioStateContext,
} from 'modules/workflow/oneStudioContext';
import { studioAppActions } from 'modules/workflow/reducers/actionsInterfaces';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from 'app/auth/UserContext';
import { UserType } from 'app/schemaInterfaces/globalTypes';
import { css } from '@emotion/react';
import { isDigitalDocument } from 'app/utils/digital-helper';

const style = css`
  .options {
    padding-left: 130px;
    padding-right: 130px;
  }
  .title {
    margin-bottom: 16px;
  }
  .closeIcon {
    padding: 5px;
  }
  .validate-all {
    padding: 10px;
  }
  .dialog-actions {
    justify-content: right;
  }
`;
interface Option {
  pageNumber: number;
  check: boolean;
}

interface MultiplePagesValidationProps {
  onFinalSave: () => Promise<void>;
  indeterminatePageCount?: boolean;
  disabled?: boolean;
}

const ValidateVersion = ({
  onFinalSave,
  indeterminatePageCount = false,
  disabled = false,
}: MultiplePagesValidationProps): JSX.Element => {
  const globalState = useStudioStateContext();

  const [openValidationDialog, setOpenValidationDialog] = useState<boolean>(
    false
  );
  const user = useCurrentUser();
  const dispatch = useStudioDispatchContext();

  const { t } = useTranslation();

  const [isValidated, setIsValidated] = useState<boolean>(false);

  const [options, setOptions] = useState<Option[]>([]);

  const [continueProcessToSave, setContinueProcessToSave] = useState<boolean>(
    false
  );

  useEffect(() => {
    const prepareOptionsState = () => {
      const allOptions: Option[] = globalState.version.pages.map(
        (page, indx) => {
          return {
            pageNumber: indx + 1,
            check: page.validated,
          };
        }
      );
      return allOptions;
    };
    setOptions(prepareOptionsState());
  }, [globalState.version.pages]);

  const save = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    options.forEach((option) => {
      dispatch(
        studioAppActions.setValidPage({
          pageNumber: option.pageNumber - 1,
          isValidated: option.check,
        })
      );
    });
    setContinueProcessToSave(true);
    setOpenValidationDialog(false);
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (continueProcessToSave) {
      const saving = async () => {
        await onFinalSave();
        setContinueProcessToSave(false);
      };
      saving();
    }
  }, [continueProcessToSave]);

  useEffect(() => {
    if (options.every(({ check }) => check === true)) {
      setIsValidated(true);
    } else {
      setIsValidated(false);
    }
  }, [options]);

  const isDisabled = (index: number) =>
    !globalState.version.pages[index].isCustomizable &&
    globalState.document?.isCustomization;

  const validateAll = () => {
    setOptions((prev) => {
      const newOptions = [...prev];
      if (isValidated) {
        return newOptions.map((option, index) => ({
          ...option,
          check: isDisabled(index) ? option.check : false,
        }));
      } else
        return newOptions.map((option, index) => ({
          ...option,
          check: isDisabled(index) ? option.check : true,
        }));
    });
  };

  const canModifyAll = options.some((option, index) => !isDisabled(index));

  const cancelChanges = () => {
    setOpenValidationDialog(false);
    setOptions(
      globalState.version.pages.map((page, indx) => {
        return {
          pageNumber: indx + 1,
          check: page.validated,
        };
      })
    );
  };

  const isDigitalMessageRequired =
    !globalState.document ||
    (isDigitalDocument(globalState.document.category) &&
      (!globalState.document.isCustomizable ||
        globalState.document.isCustomization));
  const isDigitalMessageInvalid =
    isDigitalMessageRequired && !globalState.version.digitalContent?.message;
  const isSaveDisabled = isDigitalMessageInvalid || !isValidated;

  return (
    <>
      <Button
        startIcon={<CheckIcon />}
        onClick={() => {
          setOpenValidationDialog(true);
        }}
        color="primary"
        variant="contained"
        style={{ borderRadius: '25px' }}
        disabled={disabled}
      >
        <Typography variant="h5">
          {t('workflow.toolbar.end_personnalization')}
        </Typography>
      </Button>
      <Dialog
        css={style}
        open={openValidationDialog}
        onClose={() => setOpenValidationDialog(false)}
        maxWidth="sm"
        PaperProps={{
          style: {
            maxHeight: '65%',
          },
        }}
      >
        <DialogTitle className="title">
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>
              <Typography variant="h3">
                {t('workflow.toolbar.end_personnalization')}
              </Typography>
            </Box>

            <IconButton className="closeIcon" onClick={cancelChanges}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography variant="body1">
            {user.type === UserType.Local
              ? t('workflow.toolbar.local_validation')
              : t('workflow.toolbar.global_validation')}
          </Typography>
        </DialogTitle>

        <DialogContent dividers>
          <FormControlLabel
            disabled={!canModifyAll}
            control={
              <Checkbox
                color="primary"
                checked={isValidated}
                onClick={validateAll}
              />
            }
            label={
              indeterminatePageCount
                ? t('workflow.toolbar.validate_all_pages_indeterminate')
                : t('workflow.toolbar.validate_all_pages')
            }
            className="validate-all"
          />
          {!indeterminatePageCount && (
            <Grid className="options" container>
              {options.map((option, indx) => (
                <Grid
                  container
                  item
                  direction="row"
                  key={option.pageNumber}
                  xs={6}
                >
                  <FormControlLabel
                    disabled={isDisabled(indx)}
                    control={
                      <Checkbox
                        color="primary"
                        checked={option.check}
                        onClick={() => {
                          setOptions((prev) => {
                            const newOptions = [...prev];
                            newOptions[indx].check = !newOptions[indx].check;
                            return newOptions;
                          });
                        }}
                      />
                    }
                    label={`${t('workflow.toolbar.page')} ${option.pageNumber}`}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </DialogContent>
        <DialogActions className="dialog-actions">
          {isDigitalMessageInvalid && (
            <Typography color="error" variant="body1">
              {t('workflow.toolbar.digital_message_required')}
            </Typography>
          )}
          <Button autoFocus onClick={cancelChanges} color="primary">
            {t('common.button.cancel')}
          </Button>
          <Button
            onClick={(event) => save(event)}
            color="primary"
            variant="contained"
            disabled={isSaveDisabled}
          >
            {t('common.button.end')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ValidateVersion;
