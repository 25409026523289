import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const addressBlockUploadFieldStyle = css`
  border: 2px dashed ${colors.buttonDisabled};
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  color: ${colors.primary};
  background-color: ${colors.menuHover};
  margin-top: 8px;
  margin-bottom: 4px;
`;

export const addressBlockUploadContentStyle = css`
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-right: 8px;
`;

export const addressBlockUploadIconStyle = css`
  border-radius: 50px;
  margin-right: 5px;
  display: flex;
`;

export const addressBlockUploadLabelStyle = css`
  margin: 0 8px;
`;

export const addressBlockUploadDropzoneStyle = css`
  display: none;
  outline: none;
`;
