import usePermissions from 'app/auth/usePermissions';
import { AppRoute } from 'app/routes/routes';
import { useRouter } from 'app/routes/useRouter';
import { PermissionKey } from 'app/schemaInterfaces/globalTypes';

export const useTableLine = (campaignId: string) => {
  const { hasPermission } = usePermissions();
  const router = useRouter();
  const manageCampaign = () => {
    hasPermission(PermissionKey.AccessNationalCampaign) &&
    hasPermission(PermissionKey.AccessModelCampaign)
      ? router.push(`/campaign/${campaignId}`)
      : router.push(AppRoute.AccessDenied);
  };

  return { manageCampaign };
};
