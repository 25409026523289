import { useLazyQuery } from '@apollo/client';
import useCurrentEntity from 'app/current-entity-context/useCurrentEntity';
import { GET_ORDER_BY_ID } from 'app/graphql/queries/orders';
import { AppRoute } from 'app/routes/routes';
import { useRouter } from 'app/routes/useRouter';
import {
  GetOrderById,
  GetOrderByIdVariables,
} from 'app/schemaInterfaces/GetOrderById';
import { TabTitle } from 'components/FixedTabs';
import useOrderDeliveriesQuery from 'queries/useOrderDeliveriesQuery';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { followOrderTabs } from '../followOrdersHelpers';

export const useOrderDetailsContainer = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const [tabsTranslation, setTabsTranslation] = useState<TabTitle[]>([]);
  const { currentEntity } = useCurrentEntity();

  const [
    getOrderData,
    { data: orderData, loading: orderDataLoading, error: orderDataError },
  ] = useLazyQuery<GetOrderById, GetOrderByIdVariables>(GET_ORDER_BY_ID);

  useEffect(() => {
    if (currentEntity?.id) {
      getOrderData({
        variables: { id, entityId: currentEntity.id },
      });
    }
  }, [currentEntity?.id, getOrderData, id]);

  const {
    data: orderDeliveryData,
    loading: orderDeliveryLoading,
    error: orderDeliveryError,
  } = useOrderDeliveriesQuery(id);

  const router = useRouter();

  useEffect(() => {
    if (orderDataError || orderDeliveryError) {
      router.replace(AppRoute.FollowOrders);
    }
  }, [orderDataError, orderDeliveryError, router]);

  useEffect(() => {
    if (orderData) {
      setTabsTranslation(
        followOrderTabs(t, orderData.getOrderById, orderDeliveryData)
      );
    }
  }, [t, orderData, orderDeliveryData]);

  const isLoading = orderDataLoading || orderDeliveryLoading;

  return { tabsTranslation, isLoading, id, orderData };
};
