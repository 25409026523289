import moment from 'moment';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

export const formattedDateFrom = (dateFrom: Date): string | null =>
  dateFrom ? moment(dateFrom).format('DD MMMM') : null;

export const formattededDateTo = (dateTo: Date): string | null =>
  dateTo ? moment(dateTo).format('DD MMMM YYYY') : null;

export const convertTimestampToDate = (
  dateFrom: string,
  dateTo: string,
  options = { locale: fr }
): string | null => {
  return format(new Date(dateFrom), dateTo, options);
};
