import { TFunction } from 'i18next';

// TODO remove when BIG-2432 merged
export function formatPrice(amount: number, t: TFunction): string {
  return `${amount.toFixed(2)} ${t('common.money_unit')}`;
}

export function formatProductPrice(
  amount: number,
  isIncluded: boolean,
  t: TFunction
) {
  return !isIncluded ? formatPrice(amount, t) : t('common.included');
}

export function formatTransportPrice(amount: number, t: TFunction) {
  return amount > 0 ? formatPrice(amount, t) : t('common.included');
}
