import React from 'react';
import { IProductTemplate } from 'modules/workflow/Clients/ToutFaire/ProductItem';
import { TypographyIdentities } from 'modules/workflow/oneStudioContext';
import PriceTagElementContainer from 'modules/workflow/Clients/ToutFaire/components/price/PriceTagElementContainer';
import useAppContext from 'app/app-context/useAppContext';
import { getUnifiedKeyFromPimClientConfig } from 'modules/pim/baseProducts/ProductsHelper';
import { UnifiedProductKeyToRenderOnPage } from 'modules/workflow/containers/Leftsidebar/ProductsPanel/ProductsView';
import { useTranslation } from 'react-i18next';

interface ProductBargainPricesProps {
  product: IProductTemplate;
  priceLabels?: TypographyIdentities;
  priceOptionalUnity?: TypographyIdentities;
  isCompaniesPrice?: boolean;
}

const ProductBargainPrices = ({
  product,
  priceOptionalUnity,
}: ProductBargainPricesProps): JSX.Element => {
  const {
    client: { config: clientConfig },
  } = useAppContext();

  const { t } = useTranslation();

  const bargainPrice = product.productDetails[
    getUnifiedKeyFromPimClientConfig(
      UnifiedProductKeyToRenderOnPage.bargainpricewithtaxes,
      clientConfig
    )
  ]?.replace(/,/g, '.');

  const suggestedPrice = product.productDetails[
    getUnifiedKeyFromPimClientConfig(
      UnifiedProductKeyToRenderOnPage.suggestedpricewithtaxes,
      clientConfig
    )
  ]?.replace(/,/g, '.');

  const bargainAndSuggestedPriceValuesRenderer = () => {
    if (bargainPrice && !suggestedPrice) {
      return `${t(
        'workflow.typography_item.bargain_price_term'
      )} ${bargainPrice} € ${t('workflow.typography_item.taxes_included')}`;
    } else if (!bargainPrice && suggestedPrice) {
      return `${t(
        'workflow.typography_item.suggested_price_term'
      )} ${suggestedPrice} € ${t('workflow.typography_item.taxes_included')}`;
    } else if (bargainPrice && suggestedPrice) {
      return `${t(
        'workflow.typography_item.bargain_price_term'
      )} ${bargainPrice} € et ${t(
        'workflow.typography_item.suggested_price_term'
      )} ${suggestedPrice} €`;
    }
  };

  return (
    <div>
      <PriceTagElementContainer
        product={product}
        priceTagElement={priceOptionalUnity}
        value={bargainAndSuggestedPriceValuesRenderer()}
        textAlign="center"
      />
      {bargainPrice && suggestedPrice && (
        <PriceTagElementContainer
          product={product}
          priceTagElement={priceOptionalUnity}
          value={t('workflow.typography_item.taxes_included')}
          textAlign="center"
        />
      )}
    </div>
  );
};

export default ProductBargainPrices;
