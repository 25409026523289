import {
  GetDocumentsWithRelated_documents_documents,
  GetDocumentsWithRelated_documents_documents_productAttributes,
} from 'app/schemaInterfaces/GetDocumentsWithRelated';
import {
  createDocumentActions,
  documentActions,
} from 'modules/pro/campaigns/campaignsHelper';

import AppTable from 'components/AppTable/AppTable';
import { Box, CircularProgress } from '@material-ui/core';
import CopyDocumentFormContainer from 'modules/pro/documents/CopyDocumentFormContainer';
import { DocumentOrderType } from 'app/schemaInterfaces/globalTypes';
import DocumentPreview from 'components/ConfirmationDialogs/DocumentPreview/DocumentPreview';
import DocumentsToolbar from 'modules/pro/documents/DocumentsToolbar';
import Loader from 'components/Loader/Loader/Loader';
import React from 'react';
import { useDocumentsList } from './DocumentsList.hooks';
import { useTranslation } from 'react-i18next';

export type DocumentAndOtherFields = Partial<
  GetDocumentsWithRelated_documents_documents
> & {
  category: string | null;
  id: string;
  mediumType: string | null;
  name: string;
  numberOfPages: number | null;
  orderTypes: DocumentOrderType[];
  version: string | null;
  productAttributes: GetDocumentsWithRelated_documents_documents_productAttributes | null;
};

const DocumentsList = (): JSX.Element => {
  const { t } = useTranslation();
  const {
    isDuplicateDialogOpen,
    setFilter,
    documentToOpen,
    mediumTypes,
    numbersOfPages,
    orderTypes,
    handleDuplicateMenuClick,
    openDocumentForm,
    handleDocumentDeleteAction,
    handleOpenVisualize,
    waypointRef,
    campaignId,
    filter,
    documents,
    documentsLoading,
    visibleColumns,
    getRowId,
    handleDocumentDuplicateAction,
    closeDuplicateDialog,
    copyDocumentError,
    copyDocumentLoading,
    pathname,
    setDocumentToOpen,
    documentsComplete,
  } = useDocumentsList();

  return (
    <div>
      <Box pt={2}>
        <Box pb={2}>
          <DocumentsToolbar
            actions={createDocumentActions({ campaignId, t })}
            mediumTypesOptions={mediumTypes ?? []}
            numbersOfPagesOptions={numbersOfPages ?? []}
            orderTypesOptions={orderTypes ?? []}
            placeholder={t('global.documents_list.table_toolbar_placeholder')}
            filter={filter}
            setFilter={setFilter}
          />
        </Box>
        {!documentsLoading ? (
          documents &&
          documents?.length > 0 && (
            <Box>
              <AppTable<DocumentAndOtherFields>
                columns={visibleColumns}
                data={documents}
                rowActions={(row) =>
                  documentActions({
                    row,
                    handleDocumentDeleteAction,
                    handleDuplicateMenuClick,
                    handleOpenVisualize,
                    t,
                    previousPath: pathname,
                    openDocumentForm,
                  })
                }
                getRowId={getRowId}
              />
              <CopyDocumentFormContainer
                handleDuplicateMenuClick={handleDuplicateMenuClick}
                handleDocumentDuplicateAction={handleDocumentDuplicateAction}
                closeDuplicateDialog={closeDuplicateDialog}
                isDuplicateDialogOpen={isDuplicateDialogOpen}
                error={copyDocumentError}
                isLoading={copyDocumentLoading}
              />
            </Box>
          )
        ) : (
          <Loader />
        )}
      </Box>
      {documentsLoading ? (
        <Loader />
      ) : (
        !documentsComplete && (
          <Box display="flex" justifyContent="center" py={2}>
            <CircularProgress variant="indeterminate" ref={waypointRef} />
          </Box>
        )
      )}
      {documentToOpen && (
        <DocumentPreview
          isOpen={Boolean(documentToOpen)}
          onClose={() => setDocumentToOpen(null)}
          title={documentToOpen.name}
          version={documentToOpen.version}
          url={documentToOpen.url}
          isCustomizable={documentToOpen.isCustomizable}
          category={documentToOpen.category}
          mediumType={documentToOpen.mediumType}
          attributes={documentToOpen.attributes}
        />
      )}
    </div>
  );
};

export default DocumentsList;
