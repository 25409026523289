import { useLazyQuery } from '@apollo/client';
import { useCurrentUser } from 'app/auth/UserContext';
import useCurrentEntity from 'app/current-entity-context/useCurrentEntity';
import { DOCUMENT_HISTORY, GET_DOCUMENTS } from 'app/graphql/queries/documents';
import {
  DocumentHistory,
  DocumentHistory_documentHistory_documents,
} from 'app/schemaInterfaces/DocumentHistory';
import {
  GetDocuments,
  GetDocumentsVariables,
  GetDocuments_documents_documents,
} from 'app/schemaInterfaces/GetDocuments';
import {
  UserType,
  AscDescType,
  BigShopConfigCategory,
} from 'app/schemaInterfaces/globalTypes';
import _ from 'lodash';
import { getSupportCategory } from 'modules/pro/documents/form/model/mappers';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AllCategories,
  FilterOptions,
  SelectedCategories,
} from './AvailableDocuments';
import { Option } from 'components/ControlledSelect/ControlledSelect';

export const useAvailableDocuments = () => {
  const { t } = useTranslation();
  const user = useCurrentUser();
  const DEFAULT_LIMIT = 6;
  const [filter, setFilter] = useState(FilterOptions.New);
  const [offset, setOffset] = useState(DEFAULT_LIMIT);
  const [categories, setCategories] = useState<string[]>([]);
  const allCategories = t('common.placeholder.all');
  const [selectedCategory, setSelectedCategory] = useState<SelectedCategories>(
    AllCategories.All
  );
  const [documentsData, setDocumentsData] = useState<any | undefined>({
    documents: [],
    totalItems: 0,
  });
  const [
    getDocumentHistory,
    { data: documentHistoryData, loading: documentHistoryLoading },
  ] = useLazyQuery<DocumentHistory>(DOCUMENT_HISTORY, {
    fetchPolicy: 'no-cache',
  });

  const [
    getDocuments,
    { data: documentsQuery, loading: documentsQueryLoading },
  ] = useLazyQuery<GetDocuments, GetDocumentsVariables>(GET_DOCUMENTS, {
    fetchPolicy: 'no-cache',
  });
  const { currentEntity } = useCurrentEntity();

  useEffect(() => {
    switch (filter) {
      case FilterOptions.New: {
        getDocuments({
          variables: {
            filter: {
              ...(selectedCategory &&
                selectedCategory !== AllCategories.All && {
                  categories: [selectedCategory],
                }),
              isValidated: user.type === UserType.Local ? true : undefined,
              entityId: currentEntity?.id,
            },
            sort: {
              createdAt: AscDescType.desc,
            },
            pagination: {
              limit: DEFAULT_LIMIT,
              offset: offset - DEFAULT_LIMIT,
            },
          },
        });
        break;
      }
      case FilterOptions.History: {
        getDocumentHistory({
          variables: {
            filter: {
              ...(selectedCategory && { category: selectedCategory }),
            },
            pagination: {
              limit: DEFAULT_LIMIT,
              offset: offset - DEFAULT_LIMIT,
            },
          },
        });
        break;
      }
      case FilterOptions.All: {
        getDocuments({
          variables: {
            filter: {
              ...(selectedCategory &&
                selectedCategory !== AllCategories.All && {
                  categories: [selectedCategory],
                }),
              isValidated: user.type === UserType.Local ? true : undefined,
              entityId: currentEntity?.id,
            },
            sort: {},
            pagination: {
              limit: DEFAULT_LIMIT,
              offset: offset - DEFAULT_LIMIT,
            },
          },
        });
        break;
      }
      default: {
        getDocuments({
          variables: {
            filter: {
              isValidated: user.type === UserType.Local ? true : undefined,
              entityId: currentEntity?.id,
            },
            sort: {},
            pagination: {
              limit: DEFAULT_LIMIT,
              offset: offset - DEFAULT_LIMIT,
            },
          },
        });
        break;
      }
    }
  }, [
    filter,
    offset,
    selectedCategory,
    getDocuments,
    getDocumentHistory,
    user.type,
    currentEntity?.id,
    allCategories,
  ]);

  useEffect(() => {
    setOffset(DEFAULT_LIMIT);
  }, [filter]);

  useEffect(() => {
    setDocumentsData({
      documents:
        documentHistoryData?.documentHistory?.documents.map(
          (document: DocumentHistory_documentHistory_documents) => ({
            ...document.document,
            date: document.date,
          })
        ) ?? [],
      totalItems: documentHistoryData?.documentHistory?.totalItems ?? 0,
    });
  }, [documentHistoryData]);

  const documents = documentsData?.documents;

  useEffect(() => {
    const documentCategories: BigShopConfigCategory[] = _.uniq(
      documents?.map(
        (document: GetDocuments_documents_documents) => document.category
      )
    );
    setDocumentsData({
      documents: documentsQuery?.documents?.documents,
      totalItems: documentsQuery?.documents.totalItems ?? 0,
    });

    if (categories?.length === 0 && documentCategories.length > 0) {
      setCategories(documentCategories);
    }
  }, [documentsQuery, documents, categories]);

  let documentLink: string = '';

  const options: Option[] =
    categories?.sort().map((category) => ({
      label: getSupportCategory(category as BigShopConfigCategory, t),
      value: category,
    })) ?? [];

  options.unshift({ label: allCategories, value: AllCategories.All });

  const handleCategoryChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setOffset(DEFAULT_LIMIT);
    setSelectedCategory(event.target.value as BigShopConfigCategory);
  };

  const loading = documentsQueryLoading || documentHistoryLoading;

  const hasRightArrow = offset < documentsData.totalItems;

  const hasLeftArrow = offset - DEFAULT_LIMIT > 0;

  const onRightArrowClick = () =>
    setOffset((currentOffset) => currentOffset + DEFAULT_LIMIT);

  const onLeftArrowClick = () =>
    setOffset((currentOffset) => currentOffset - DEFAULT_LIMIT);

  return {
    filter,
    setFilter,
    options,
    handleCategoryChange,
    selectedCategory,
    DEFAULT_LIMIT,
    documents,
    loading,
    documentLink,
    hasRightArrow,
    hasLeftArrow,
    onRightArrowClick,
    onLeftArrowClick,
  };
};
