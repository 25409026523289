import React from 'react';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { Action } from 'components/ActionsMenu/ActionsMenu';
import { TFunction } from 'react-i18next';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import { PermissionKey } from 'app/schemaInterfaces/globalTypes';
import { GetDocumentsCommitments_documentsCommitments } from 'app/schemaInterfaces/GetDocumentsCommitments';
import { GetOrdersByDocuments_getOrdersByDocuments } from 'app/schemaInterfaces/GetOrdersByDocuments';
import { Distribution } from './DistributionList';

export const getActions = (
  exportDistribution: Action['onClick'],
  exportDocuments: Action['onClick'],
  deleteDocuments: Action['onClick'],
  hasPermission: (permission: PermissionKey) => boolean,
  t: TFunction,
  isCampaignModel: boolean
): Action[] => [
  {
    title: t('global.distribution_list.export_distribution'),
    onClick: exportDistribution,
    icon: <GetAppOutlinedIcon />,
  },
  ...(!isCampaignModel
    ? [
        {
          title: t('global.distribution_list.export_document'),
          onClick: exportDocuments,
          icon: <GetAppOutlinedIcon />,
        },
      ]
    : []),
  ...(hasPermission(PermissionKey.DeleteCommitment) && !isCampaignModel
    ? [
        {
          title: t('global.distribution_list.delete_document'),
          onClick: deleteDocuments,
          icon: <DeleteForeverOutlinedIcon />,
        },
      ]
    : []),
];

export const getCommitmentDistribution = (
  commitmentsDocument?: GetDocumentsCommitments_documentsCommitments[]
): Distribution[] =>
  commitmentsDocument
    ? commitmentsDocument.map(
        ({
          deliveryPointCount,
          quantity,
          recommendedQuantity,
          document,
          documentIds,
          commitments,
        }) => ({
          deliveryPointCount,
          quantity,
          recommendedQuantity,
          document,
          documentIds,
          orders: commitments.map((commitment) => ({
            id: commitment.id,
            code: commitment.commitmentCode,
            totalPrice: commitment.totalPrice,
            entityName: commitment.entity.name,
            createdAt: commitment.createdAt,
            addresses: commitment.document.addresses.map(
              ({ quantity, price, address, recommendedQuantity }) => ({
                recommendedQuantity,
                quantity,
                price,
                comment: commitment.document.comment,
                documentId: document.id,
                address: {
                  name: address.name ?? '',
                  firstAddress: address.firstAddress ?? '',
                  zipCode: address.zipCode ?? '',
                  city: address.city ?? '',
                  secondAddress: address.secondAddress ?? '',
                  deliveryDepartment: address.deliveryDepartment ?? '',
                },
              })
            ),
          })),
        })
      )
    : [];

export const getOrderDistribution = (
  ordersByDocument?: GetOrdersByDocuments_getOrdersByDocuments[]
): Distribution[] =>
  ordersByDocument
    ? ordersByDocument.map(
        ({ deliveryPointCount, quantity, document, documentIds, orders }) => ({
          deliveryPointCount,
          quantity,
          document,
          documentIds,
          orders: orders.map(
            ({ id, totalPrice, entityName, addresses, createdAt }) => ({
              id,
              code: id,
              totalPrice,
              entityName,
              createdAt,
              addresses: addresses.map(
                ({ quantity, price, address, comment, documentId }) => ({
                  quantity,
                  price,
                  comment,
                  documentId,
                  address: {
                    name: address?.name ?? '',
                    firstAddress: address?.firstAddress ?? '',
                    secondAddress: address.secondAddress ?? '',
                    deliveryDepartment: address.deliveryDepartment ?? '',
                    zipCode: address?.zipCode ?? '',
                    city: address?.city ?? '',
                  },
                })
              ),
            })
          ),
        })
      )
    : [];
