import { Badge, Box, Popover } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import { GET_USER_NOTIFICATIONS } from 'app/graphql/queries/notifications';
import { useQuery } from '@apollo/client';
import {
  GetUserNotifications,
  GetUserNotifications_getUserNotifications,
} from 'app/schemaInterfaces/GetUserNotifications';
import { notificationsMessages } from './helper';
import moment from 'moment';
import RestoreOutlinedIcon from '@material-ui/icons/RestoreOutlined';
import { iconNotificationStyle, notificationStyle } from './notificationStyle';
import useUpdateNotification from './mutations/useUpdateNotification';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

export const NotificationsPopover = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const { t } = useTranslation();

  const history = useHistory();

  const { data: notificationData, refetch } = useQuery<GetUserNotifications>(
    GET_USER_NOTIFICATIONS,
    {}
  );

  const { getUserNotifications: userNotifications } = notificationData ?? {};

  const { updateNotification } = useUpdateNotification();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 10 * 60000);
    return () => clearInterval(interval);
  }, [refetch]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const userNotificationsByDate = _.chain(userNotifications)
    .groupBy((notification) => notification.sendingDate)
    .map((value, key) => ({
      sendingDate: key,
      notifications: _.reverse(value),
    }))
    .value();

  const updateUserNotification = async (
    notification: GetUserNotifications_getUserNotifications
  ) => {
    const result = await updateNotification({
      variables: {
        id: notification._id,
      },
    });
    if (result.data?.updateNotification) {
      await refetch();
    }
  };

  const redirectAndUpdate = async (
    notification: GetUserNotifications_getUserNotifications
  ) => {
    history.push(`${notification.link}`);
    await updateUserNotification(notification);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box>
      <Box
        onClick={Boolean(userNotifications?.length) ? handleClick : undefined}
      >
        <Badge
          variant="dot"
          css={iconNotificationStyle}
          invisible={Boolean(userNotifications?.length) ? false : true}
        >
          <NotificationsOutlinedIcon
            style={{ marginBottom: '8px' }}
            color={open ? 'primary' : 'inherit'}
          />
        </Badge>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        css={notificationStyle}
      >
        <div>
          <div className="header">
            <p>
              {t('notifications.title', { count: userNotifications?.length })}
            </p>
          </div>
          <div className="notification-message-box">
            {Boolean(userNotificationsByDate.length) ? (
              _.reverse(userNotificationsByDate)?.map(
                (userNotification, index) => {
                  return (
                    <div key={index}>
                      <p className="date-header">
                        {moment(userNotification.sendingDate).format(
                          'dddd Do MMMM'
                        )}
                      </p>
                      {userNotification.notifications.map(
                        (notification, notificationIndex) => (
                          <div className="notification" key={notificationIndex}>
                            <div className="message-box">
                              <div
                                className="red-dot"
                                onClick={() =>
                                  updateUserNotification(notification)
                                }
                              ></div>
                              <p
                                className="message"
                                onClick={() => redirectAndUpdate(notification)}
                              >
                                {notificationsMessages(
                                  notification.type,
                                  notification.metadata,
                                  t
                                )}
                              </p>
                            </div>
                            <div className="timeBox">
                              <RestoreOutlinedIcon
                                fontSize="small"
                                className="icon"
                              />
                              <p className="time">
                                {moment(userNotification.sendingDate).format(
                                  'LT'
                                )}
                              </p>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  );
                }
              )
            ) : (
              <p className="empty">{t('notifications.messages.empty')}</p>
            )}
          </div>
        </div>
      </Popover>
    </Box>
  );
};
