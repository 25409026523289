import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react/macro';
import colors from 'styles/colors.module.scss';
import FormatColorFillOutlinedIcon from '@material-ui/icons/FormatColorFillOutlined';

interface IsCustomizableTagProps {
  isCustomizable: boolean;
}

const IsCustomizableTag = ({ isCustomizable }: IsCustomizableTagProps) => {
  const { t } = useTranslation();

  const isCustomizableTag = css`
    background: ${isCustomizable
      ? colors.menuHover
      : colors.documentsHeaderBackground};
    border-radius: 40px;
    color: ${colors.documentsTab};
    font-family: ScandiaWebMedium;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.4px;
    max-width: 150px;
    overflow: hidden;
    padding: 5px 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: fit-content;
    display: flex;
    justify-items: center;

    .icon {
      font-size: 12px;
      margin-left: 8px;
    }
  `;

  return (
    <span css={isCustomizableTag}>
      {t(isCustomizable ? 'global.customizable' : 'global.not_customizable')}
      {isCustomizable && <FormatColorFillOutlinedIcon className="icon" />}
    </span>
  );
};

export default IsCustomizableTag;
