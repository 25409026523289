export enum StudioContextActionType {
  SET_PAGE_TEMPLATE = 'SET_PAGE_TEMPLATE',
  UNDO = 'UNDO',
  REDO = 'REDO',
  UPDATE_UNDO_REDO_HISTORY = 'UPDATE_UNDO_REDO_HISTORY',
  SET_PAGE_NUMBER = 'SET_PAGE_NUMBER',
  SET_DRAGGED_ITEM = 'SET_DRAGGED_ITEM',
  SET_SECTOR_TO_ZONE = 'SET_SECTOR_TO_ZONE',
  SET_PAGES_COMPARISON_STATUS = 'SET_PAGES_COMPARISON_STATUS',
  SET_BACKGROUND_IMAGE = 'SET_BACKGROUND_IMAGE',
  SET_BACKGROUND_COLOR = 'SET_BACKGROUND_COLOR',
  SET_PERSONALIZATION_ITEMS = 'SET_PERSONALIZATION_ITEMS',
  SET_PAGES_CUSTOMIZATION = 'SET_PAGES_CUSTOMIZATION',
  SET_PRODUCT = 'SET_PRODUCT',
  TOGGLE_PANNING_FUNCTIONALITY = 'TOGGLE_PANNING_FUNCTIONALITY',
  INSERT_ASSET = 'INSERT_ASSET',
  UPDATE_ASSET = 'UPDATE_ASSET',
  SET_VALID_PAGE = 'SET_VALID_PAGE',
  SET_UPLOADED_BACKGROUND_IMAGE_URL = 'SET_UPLOADED_BACKGROUND_IMAGE_URL',
  DELETE_ALL_ITEMS = 'DELETE_ALL_ITEMS',
  DELETE_ASSET = 'DELETE_ASSET',
  DELETE_BACKGROUND_ASSET = 'DELETE_BACKGROUND_ASSET',
  DELETE_PERSONALIZATION_ZONE = 'DELETE_PERSONALIZATION_ZONE',
  DELETE_PRODUCT_BY_ID = 'DELETE_PRODUCT_BY_ID',
  SET_COMMENT_MODE = 'SET_COMMENT_MODE',
  SET_VERSION = 'SET_VERSION',
  SET_DOCUMENT = 'SET_DOCUMENT',
  TOGGLE_TEXT_COLOR = 'TOGGLE_TEXT_COLOR',
  UPDATE_PRODUCT_POSITION = 'UPDATE_PRODUCT_POSITION',
  UPDATE_PRODUCT_SIZE = 'UPDATE_PRODUCT_SIZE',
  SET_ZONE_PRODUCT_NUMBER = 'SET_ZONE_PRODUCT_NUMBER',
  SET_ZONE_TURNOVER = 'SET_ZONE_TURNOVER',
  SET_SELECTED_ZONE = 'SET_SELECTED_ZONE',
  REPLACE_PRODUCT = 'REPLACE_PRODUCT',
  UPDATE_ASSET_POSITION = 'UPDATE_ASSET_POSITION',
  UPDATE_PERSONALIZATION_PRODUCT_ASSET_POSITION = 'UPDATE_PERSONALIZATION_PRODUCT_ASSET_POSITION',
  UPDATE_ASSET_SIZE = 'UPDATE_ASSET_SIZE',
  SET_SCALE_VALUE = 'SET_SCALE_VALUE',
  SET_TAB_VALUE = 'SET_TAB_VALUE',
  UPDATE_PERSONALIZATION_PRODUCT_ASSET_SIZE = 'UPDATE_PERSONALIZATION_PRODUCT_ASSET_SIZE',
  TOGGLE_DISPLAY_PERSONALIZATION = 'TOGGLE_DISPLAY_PERSONALIZATION',
  SET_EDITABLE = 'SET_EDITABLE',
  SET_DIGITAL = 'SET_DIGITAL',
  SET_DIGITAL_CONTENT = 'SET_DIGITAL_CONTENT',
}
