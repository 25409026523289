import { LatLngExpression } from 'leaflet';
import { useMemo } from 'react';
import { useGeocodingQuery } from './useGeocodingQuery';

export interface UseAddressPositionResolverParams {
  address?: AddressLike | null;
}

export interface AddressLike {
  name?: string | null;
  firstAddress?: string | null;
  secondAddress?: string | null;
  thirdAddress?: string | null;
  zipCode?: string | null;
  city?: string | null;
  country?: string | null;
}

export interface UseAddressPositionResolverResult {
  data?: LatLngExpression;
  loading: boolean;
  error?: any;
}

export function useAddressPositionResolver({
  address,
}: UseAddressPositionResolverParams): UseAddressPositionResolverResult {
  const query = useMemo(
    () =>
      [
        /*address?.name, */
        address?.firstAddress,
        address?.secondAddress,
        address?.thirdAddress,
        address?.zipCode,
        address?.city,
        address?.country,
      ]
        .filter((it) => !!it)
        .join(' '),
    [address]
  );
  const { data, loading, error } = useGeocodingQuery({
    query,
    skip: query.length === 0,
  });
  return {
    data:
      data && data.length > 0 ? { lat: data[0].y, lng: data[0].x } : undefined,
    loading,
    error,
  };
}
