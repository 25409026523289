import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { screenHeight } from 'styles/theme';
import PaperWrapper from 'components/PaperWrapper/PaperWrapper';
import './Loader.scss';
import colors from 'styles/colors.module.scss';
import { ApolloError } from '@apollo/client';
import { useTranslation } from 'react-i18next';

export interface LoaderProps {
  height?: number | string;
  error?: ApolloError;
  paperWrap?: boolean;
}

const Loader: FC<LoaderProps> = ({ height, error, paperWrap }) => {
  const { t } = useTranslation();
  return (
    <Box
      data-testid="loader"
      display="flex"
      height={height || screenHeight()}
      flexDirection="column"
      textAlign="center"
      justifyContent="center"
      alignItems="center"
    >
      {error ? (
        <PaperWrapper condition={paperWrap}>
          <Box px={3} mt={3} data-testid="error">
            <Typography variant="subtitle2">
              {t('common.server_error.error')}
            </Typography>
            <Typography>
              {t('common.server_error.alert')}
              <br />
              {t('common.server_error.refresh')}
            </Typography>
          </Box>
        </PaperWrapper>
      ) : (
        <>
          <svg
            className="loader"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 340 340"
          >
            <circle
              className="circle1"
              cx="170"
              cy="170"
              r="130"
              stroke={colors.orange300}
            />
            <circle
              className="circle12"
              cx="170"
              cy="170"
              r="130"
              stroke={colors.loader7}
            />
            <circle
              className="circle13"
              cx="170"
              cy="170"
              r="130"
              stroke={colors.loader4}
            />

            <circle
              className="circle2"
              cx="170"
              cy="170"
              r="80"
              stroke={colors.orange300}
            />
            <circle
              className="circle22"
              cx="170"
              cy="170"
              r="80"
              stroke={colors.white}
            />
            <circle
              className="circle23"
              cx="170"
              cy="170"
              r="80"
              stroke={colors.loader9}
            />

            <circle
              className="circle3"
              cx="170"
              cy="170"
              r="40"
              stroke={colors.white}
            />
            <circle
              className="circle32"
              cx="170"
              cy="170"
              r="40"
              stroke={colors.loader7}
            />
            <circle
              className="circle33"
              cx="170"
              cy="170"
              r="40"
              stroke={colors.loader4}
            />

            <circle
              className="circle4"
              cx="170"
              cy="170"
              r="30"
              stroke={colors.loader4}
            />
          </svg>
        </>
      )}
    </Box>
  );
};

export default Loader;
