import { Typography } from '@material-ui/core';
import { ConditionnementFilterInput } from 'app/schemaInterfaces/globalTypes';
import { useTranslation } from 'react-i18next';
import { Conditionnement } from '../DocumentProductSubForm/DocumentProductSubForm.definition';

interface PackagingProps {
  packaging: Conditionnement;
}

const Packaging = ({ packaging }: PackagingProps) => {
  const { t } = useTranslation();

  const packagingCard = (packaging: ConditionnementFilterInput) => (
    <Typography variant="body1">
      {packaging.conditionnement}
      {packaging.nbExConditionnement
        ? ` ${t('global.document_form.attributes.by_packaging')} ${
            packaging.nbExConditionnement
          }`
        : ''}
      {packaging.labeling
        ? ` ${t('global.document_form.attributes.with_labelling')}`
        : ''}
    </Typography>
  );

  return (
    <div>
      <b>{packaging.value} : </b>
      <Typography variant="body1">
        {t(
          `global.document_form.attributes.${
            packaging?.numberOfLots > 1
              ? 'packaging_with_lots_plural'
              : 'packaging_with_lot'
          }`,
          {
            numberOfLots: packaging?.numberOfLots,
          }
        )}
      </Typography>
      {packaging.conditionnementList.map((conditionnement, index) => (
        <div key={index}>
          {conditionnement.numberOfExLots && (
            <Typography variant="body1">
              {t(
                `global.document_form.attributes.${
                  conditionnement.numberOfExLots > 1
                    ? 'packaging_lots_plural'
                    : 'packaging_lot'
                }`,
                {
                  index: index + 1,
                  numberOfLots: conditionnement.numberOfExLots,
                }
              )}
            </Typography>
          )}
          <div style={{ paddingLeft: 10 }}>
            {conditionnement.firstConditionnement &&
              packagingCard(conditionnement.firstConditionnement)}
            {conditionnement.secondConditionnement &&
              packagingCard(conditionnement.secondConditionnement)}
            {conditionnement.threeConditionnement &&
              packagingCard(conditionnement.threeConditionnement)}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Packaging;
