import colors from 'styles/colors.module.scss';
import { css } from '@emotion/react';

export const alertBoxStyle = css`
  border: 2px solid ${colors.grey};
  border-radius: 10px;
  max-width: 530px;
  width: 100%;
  padding: 25px;
  background-color: ${colors.white};
`;

export const alertBoxWarningStyle = css`
  border-color: ${colors.orange300};
  .alert-box--header {
    color: ${colors.orange300};
  }
`;

export const alertBoxErrorStyle = css`
  border-color: ${colors.orangeAcid};
  .alert-box--header {
    color: ${colors.orangeAcid};
  }
`;

export const alertBoxHeaderStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;

  &:only-child {
    margin-bottom: 0;
  }
`;

export const alertBoxHeaderContentStyle = css`
  flex: 1;
  margin-left: 10px;
  font-weight: bold;
`;
