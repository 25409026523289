import { Box } from '@material-ui/core';
import React, { useContext } from 'react';
import { AssetType } from 'app/schemaInterfaces/globalTypes';
import ButtonGroupFilter, {
  ButtonInfo,
} from 'components/ButtonGroupFilter/ButtonGroupFilter';
import { AssetFiltersActionCreator } from 'modules/dam/reducers/filters/actions';
import { AssetFiltersContext } from 'modules/dam/reducers/filters/context';
import { useTranslation } from 'react-i18next';

export interface AssetTypeFilterProps {
  count?: number;
  selectedAssetType?: readonly AssetType[];
}

export const AssetTypeFilter = ({
  count,
  selectedAssetType,
}: AssetTypeFilterProps): JSX.Element => {
  const { t } = useTranslation();

  const dispatch = useContext(AssetFiltersContext);
  if (!dispatch) {
    return <></>;
  }

  const dispatchAssetType = (assetType?: AssetType) =>
    dispatch(
      AssetFiltersActionCreator.updateAssetTypes(
        assetType ? [assetType] : undefined
      )
    );

  const buttons: ButtonInfo[] = [
    {
      id: undefined,
      title: `${t('dam.asset_types.all')} (${count ?? 0})`,
      handleChange: () => dispatchAssetType(undefined),
    },
    {
      id: AssetType.Visual,
      title: t('dam.asset_types.visuals'),
      handleChange: () => dispatchAssetType(AssetType.Visual),
    },
    {
      id: AssetType.Logo,
      title: t('dam.asset_types.logos'),
      handleChange: () => dispatchAssetType(AssetType.Logo),
    },
    {
      id: AssetType.Picto,
      title: t('dam.asset_types.pictos'),
      handleChange: () => dispatchAssetType(AssetType.Picto),
    },
    {
      id: AssetType.Charter,
      title: t('dam.asset_types.charters'),
      handleChange: () => dispatchAssetType(AssetType.Charter),
    },
    {
      id: AssetType.Media,
      title: t('dam.asset_types.medias'),
      handleChange: () => dispatchAssetType(AssetType.Media),
    },
  ];

  let value: AssetType | undefined = undefined;
  if (selectedAssetType && selectedAssetType.length === 1) {
    value = selectedAssetType[0];
  }

  return (
    <Box>
      <ButtonGroupFilter buttons={buttons} value={value} />
    </Box>
  );
};
