import * as Yup from 'yup';

import {
  AttributConditionnementFilterInput,
  CampaignError,
  DateStatus,
  DocumentOrigin,
  PermissionKey,
} from 'app/schemaInterfaces/globalTypes';
import { Button, PopoverOrigin } from '@material-ui/core';
import DocumentsList, {
  DocumentAndOtherFields,
} from 'modules/pro/documents/DocumentsList/DocumentsList';
import {
  GetAllCampaigns_getAllCampaigns_campaigns,
  GetAllCampaigns_getAllCampaigns_campaigns_documents,
} from 'app/schemaInterfaces/GetAllCampaigns';

import { Action } from 'components/ActionsMenu/ActionsMenu';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AttachmentOutlinedIcon from '@material-ui/icons/AttachmentOutlined';
import { CampaignType } from 'app/schemaInterfaces/globalTypes';
import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import DeviceHubOutlinedIcon from '@material-ui/icons/DeviceHubOutlined';
import DistributionList from '../documents/DistributionList';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import FilterNoneOutlinedIcon from '@material-ui/icons/FilterNoneOutlined';
import FormatColorFillOutlinedIcon from '@material-ui/icons/FormatColorFillOutlined';
import { GetAllCampaigns_getAllCampaigns_dateStatusCount } from 'app/schemaInterfaces/GetAllCampaigns';
import { GetCampaignById_getCampaignById } from 'app/schemaInterfaces/GetCampaignById';
import { GetCampaignsWithQuery_campaigns_campaigns } from 'app/schemaInterfaces/GetCampaignsWithQuery';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import { MediaContainer } from 'modules/pro/medias/MediaContainer';
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined';
import ProductsSheetList from 'modules/pro/ProductsFiles/ProductsSheetList';
import React from 'react';
import { RemoveRedEyeOutlined } from '@material-ui/icons';
import { Row } from 'react-table';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import SourceFilesContainer from 'modules/pro/SourceFiles/SourceFilesContainer';
import { TFunction } from 'i18next';
import { TabTitle } from 'components/FixedTabs';
import { checkFileMimeType } from 'modules/dam/form/validation/schema';
import { filteredAttributes } from 'modules/sales/components/QuantitySelectHelper';
import { isWithinInterval } from 'date-fns';
import moment from 'moment';
import { nonCustomizableCategories } from '../documents/form/DocumentForm';
import { useTranslation } from 'react-i18next';

///////////////////Campaigns List//////////////////////////////////////////////

//TODO: add links after unifying camopaigns with thematics
export const campaignMenuItems = [
  {
    translationKey: 'menuItems.see_all',
    to: '',
    key: 0,
  },
  {
    translationKey: 'menuItems.campaigns',
    to: '',
    key: 1,
  },
  {
    translationKey: 'menuItems.thematics',
    to: '',
    key: 2,
  },
  {
    translationKey: 'menuItems.planning',
    to: '',
    key: 3,
  },
];

export const documentsTabs = (
  t: TFunction,
  hasPermission: (permission: PermissionKey) => boolean,
  isCampaignModel: boolean
): TabTitle[] => [
  {
    label: t('global.menu.documents'),
    path: 'documents',
    component: DocumentsList,
    icon: <FilterNoneOutlinedIcon />,
    total: 56,
  },
  ...(hasPermission(PermissionKey.AccessCampaignDistribution)
    ? [
        {
          label: t('global.menu.distribution'),
          path: 'répartition',
          component: () => (
            <DistributionList isCampaignModel={isCampaignModel} />
          ),
          icon: <DeviceHubOutlinedIcon />,
          total: 0,
        },
      ]
    : []),
  ...(hasPermission(PermissionKey.AccessCampaignProducts)
    ? [
        {
          label: t('global.menu.product_pages'),
          path: 'productsSheet',
          component: ProductsSheetList,
          icon: <PhotoLibraryOutlinedIcon />,
        },
      ]
    : []),
  /* temporary disable */
  // ...(hasPermission(PermissionKey.AccessCampaignPlanning)
  //   ? [
  //       {
  //         label: t('global.menu.schedule'),
  //         path: 'planning',
  //         component: ReversePlanningContainer,
  //         icon: <EventOutlinedIcon />,
  //         total: 0,
  //       },
  //     ]
  //   : []),
  ...(hasPermission(PermissionKey.AccessCampaignMedia)
    ? [
        {
          label: t('global.menu.media'),
          path: 'media',
          component: MediaContainer,
          icon: <CategoryOutlinedIcon />,
          total: 0,
        },
      ]
    : []),
  ...(hasPermission(PermissionKey.AccessCampaignAttachments)
    ? [
        {
          label: t('global.menu.attachments'),
          path: 'files',
          component: SourceFilesContainer,
          icon: <AttachmentOutlinedIcon />,
          total: 0,
        },
      ]
    : []),
  /* temporary disable */
  // ...(hasPermission(PermissionKey.AccessCampaignUsers)
  //   ? [
  //     {
  //       label: t('global.menu.users'),
  //       path: 'team',
  //       component: TeamsContainer,
  //       icon: <PeopleOutlineOutlinedIcon />,
  //       total: 0,
  //     },
  //   ]
  //   : []),
];

export interface CampaignDetailsActionsProps {
  previewCampaign: () => void;
  deleteCampaign: () => void;
  manageCampaign?: (
    event: React.MouseEvent<HTMLLIElement, MouseEvent> | undefined
  ) => void;
  isCampaignModel: boolean;
}

export const campaignDetailsActions = ({
  manageCampaign,
  handleOpenCampaignDialog,
  previewCampaign,
  deleteCampaign,
  t,
  hasPermission,
  isCampaignModel,
}: CampaignDetailsActionsProps & CreateCampaignActionsProps) => [
  ...(hasPermission(PermissionKey.ReadNationalCampaign)
    ? [
        {
          title: t('global.documents_list.actions.visualize'),
          icon: <ImageSearchIcon />,
          onClick: previewCampaign,
        },
      ]
    : []),
  ...(manageCampaign &&
  hasPermission(PermissionKey.AccessNationalCampaign) &&
  hasPermission(PermissionKey.AccessModelCampaign)
    ? [
        {
          title: t('global.campaigns_list.actions.administrate'),
          icon: <SettingsOutlinedIcon />,
          onClick: manageCampaign,
        },
      ]
    : []),
  ...(hasPermission(PermissionKey.UpdateNationalCampaign)
    ? [
        {
          title: t('global.campaigns_list.actions.modify'),
          icon: <CreateOutlinedIcon />,
          onClick: handleOpenCampaignDialog,
        },
      ]
    : []),

  ...(hasPermission(PermissionKey.DeleteNationalCampaign)
    ? [
        {
          title: t(
            isCampaignModel
              ? 'global.campaigns_list.archived_confirmation_dialog.title'
              : 'common.delete_confirmation_dialog.title'
          ),
          icon: <DeleteForeverOutlinedIcon />,
          confirmationDialog: {
            title: t(
              isCampaignModel
                ? 'global.campaigns_list.archived_confirmation_dialog.title'
                : 'common.delete_confirmation_dialog.title'
            ),
            description: t(
              isCampaignModel
                ? 'global.campaigns_list.archived_confirmation_dialog.description'
                : 'global.campaigns_list.delete_confirmation_dialog.description'
            ),
          },
          onClick: deleteCampaign,
          isDisabledAvailable: true,
        },
      ]
    : []),
];

export const getCampaignHeaderToolTipMessage = (t: TFunction) =>
  t('global.campaigns_list.actions.tooltip_message.campaign');

export interface DocumentToOpen {
  id: string;
  name: string;
  version: string;
  isCustomizable: boolean;
  mediumType: string;
  category: string;
  attributes?: {
    [k: string]: string | AttributConditionnementFilterInput | null;
  };
  url?: string | null;
}
export interface DocumentActions {
  row: Row<DocumentAndOtherFields>;
  handleDocumentDeleteAction: (row: Row<DocumentAndOtherFields>) => void;
  handleDuplicateMenuClick: (row: Row<DocumentAndOtherFields>) => void;
  handleOpenVisualize: (documentToOpen: DocumentToOpen) => void;
  t: TFunction;
  previousPath: string;
  openDocumentForm: (row: Row<DocumentAndOtherFields>) => void;
}
export const documentActions = ({
  row,
  handleDocumentDeleteAction,
  handleDuplicateMenuClick,
  handleOpenVisualize,
  t,
  previousPath,
  openDocumentForm,
}: DocumentActions): Action[] => {
  const isAllowToOpenWF = !nonCustomizableCategories.includes(
    row.original.category
  );
  return [
    ...(isAllowToOpenWF
      ? [
          {
            title: t('global.documents_list.actions.edit'),
            icon: <FormatColorFillOutlinedIcon />,
            to: {
              pathname: `/studio/${row.original['id']}`,
              state: { prevPath: previousPath },
            },
          },
        ]
      : [
          {
            title: t('global.documents_list.actions.visualize'),
            icon: <RemoveRedEyeOutlined />,
            onClick: () =>
              handleOpenVisualize({
                url: row.original.thumbnailUrl,
                id: row.original.id,
                name: row.original.name,
                version: row.original.version,
                isCustomizable: Boolean(row.original.isCustomizable),
                mediumType: row.original.mediumType,
                category: row.original.category,
                attributes: filteredAttributes(row.original.productAttributes),
              }),
          },
        ]),
    {
      title: t('global.documents_list.actions.copy'),
      icon: <FileCopyOutlinedIcon />,
      onClick: () => handleDuplicateMenuClick(row),
    },
    {
      title: t('global.campaigns_list.actions.modify'),
      icon: <CreateOutlinedIcon />,
      onClick: () => openDocumentForm(row),
    },
    {
      title: t('global.documents_list.actions.delete'),
      icon: <DeleteOutlineOutlinedIcon />,
      onClick: () => handleDocumentDeleteAction(row),
      confirmationDialog: {
        title: t('common.delete_confirmation_dialog.title'),
        description: t(
          'global.documents_list.delete_confirmation_dialog.description'
        ),
      },
    },
  ];
};

export interface ButtonGroupsProps {
  dateStatusCount?: GetAllCampaigns_getAllCampaigns_dateStatusCount;
  t: TFunction;
}

export const buttonsGroup = ({ dateStatusCount, t }: ButtonGroupsProps) => {
  return [
    {
      title: `${t('global.campaigns_helper.upcoming')} ${
        dateStatusCount ? `(${dateStatusCount.totalItemsUpcoming})` : ''
      }`,
      value: DateStatus.UPCOMING,
    },
    {
      title: `${t('global.campaigns_helper.in_progress')} ${
        dateStatusCount ? `(${dateStatusCount.totalItemsInProgress})` : ''
      }`,
      value: DateStatus.INPROGRESS,
    },
    {
      title: `${t('global.campaigns_helper.terminated')} ${
        dateStatusCount ? `(${dateStatusCount.totalItemsTerminated})` : ''
      }`,
      value: DateStatus.TERMINATED,
    },
    {
      title: `${t('global.campaigns_helper.all')} ${
        dateStatusCount ? `(${dateStatusCount.totalItemsAllDateStatus})` : ''
      }`,
      value: DateStatus.ALL,
    },
  ];
};

export const addCampaignButtonAnchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
};

export const addCampaignButtonTransformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

interface CreateCampaignActionsProps {
  handleOpenCampaignDialog: () => void;
  t: TFunction;
  hasPermission: (permission: PermissionKey) => boolean;
}

export const createCampaignActions = ({
  handleOpenCampaignDialog,
  t,
}: CreateCampaignActionsProps) => [
  {
    title: t('global.national_campaigns.one_campaign'),
    description: t('global.national_campaigns.program_campaign'),
    onClick: handleOpenCampaignDialog,
  },
  {
    title: t('global.national_campaigns.multiple_campaigns'),
    description: t('global.documents_list.import_file'),
  },
];

export const createCampaignModelActions = ({
  handleOpenCampaignDialog,
  t,
}: CreateCampaignActionsProps) => [
  {
    title: t('global.thematics.one_thematic'),
    description: t('global.thematics.add_thematic'),
    onClick: handleOpenCampaignDialog,
  },
  {
    title: t('global.thematics.multiple_thematics'),
    description: t('global.thematics.import_file'),
  },
];

export enum DocumentsButtonTypeFilter {
  Print = 1,
  PLV = 2,
  Digital = 3,
}

export interface DocumentsButtonGroupsProps {
  handlePrint: () => void;
  handlePlv: () => void;
  handleDigital: () => void;
  t: TFunction;
}

export const documentsButtonsGroup = ({
  handlePrint,
  handlePlv,
  handleDigital,
  t,
}: DocumentsButtonGroupsProps) => [
  {
    title: t('global.buttons.print'),
    handleChange: () => handlePrint(),
    button: DocumentsButtonTypeFilter.Print as number,
  },
  {
    title: t('global.buttons.plv'),
    handleChange: () => handlePlv(),
    button: DocumentsButtonTypeFilter.PLV as number,
  },
  {
    title: t('global.buttons.digital'),
    handleChange: () => handleDigital(),
    button: DocumentsButtonTypeFilter.Digital as number,
  },
];

export interface documentActionProps {
  campaignId?: string | null;
  t: TFunction;
}

export const createDocumentActions = ({
  campaignId,
  t,
}: documentActionProps) => [
  {
    title: t('global.documents_list.document'),
    description: t('global.documents_list.create_document'),
    to: `/campaign/${campaignId}/document/creation`,
  },
  {
    title: t('global.documents_list.documents'),
    description: t('global.documents_list.import_file'),
  },
];

export const AddCampaignButton = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Button variant="contained" color="primary" startIcon={<AddCircleIcon />}>
      {t('global.campaigns_list.actions.add')}
    </Button>
  );
};

export const IMAGE_WIDTH = 80;

export const columnsWidth = [410, 270, 200, 245, 30];

///////////////////Campaigns Filter////////////////////////////////////////////

export const FILTER_BY_TAGS = 'FILTER_BY_TAGS';
export const FILTER_BY_CATEGORIES = 'FILTER_BY_CATEGORIES';
export const POPULATE_CAMPAIGNS = 'POPULATE_CAMPAIGNS';
export const RESET_FILTERS = 'RESET_FILTERS';
export const BY_DATE_TO = 'BY_DATE_TO';
export const CREATED_AT = 'CREATED_AT';
export const BY_DATE_FROM = 'BY_DATE_FROM';
export const BY_UPDATED_AT = 'BY_UPDATED_AT';
export const FILTER_BY_DATE_FROM = 'FILTER_BY_DATE_FROM';
export const FILTER_BY_DATE_TO = 'FILTER_BY_DATE_TO';
export const CLEAR_DATE_FROM = 'CLEAR_DATE_FROM';
export const CLEAR_DATE_TO = 'CLEAR_DATE_TO';
export const POPULATE_MODELS = 'POPULATE_MODELS';
export const SEARCH_BAR_FILTER = 'SEARCH_BAR_FILTER';
export const FILTER_BY_STATUS = 'FILTER_BY_STATUS';

export const sortCampaignswithMomentDate = (
  campaigns: GetAllCampaigns_getAllCampaigns_campaigns[],
  sortDate: string
) => {
  if (sortDate === BY_DATE_FROM) {
    return campaigns
      ?.slice()
      .sort(
        (
          currentCampaign: GetAllCampaigns_getAllCampaigns_campaigns,
          nextCampaign: GetAllCampaigns_getAllCampaigns_campaigns
        ) =>
          currentCampaign &&
          nextCampaign &&
          currentCampaign.dateFrom &&
          nextCampaign.dateFrom
            ? moment(nextCampaign.dateFrom).isSameOrBefore(
                moment(currentCampaign.dateFrom)
              )
              ? 1
              : -1
            : 0
      );
  } else if (sortDate === BY_UPDATED_AT) {
    return campaigns
      ?.slice()
      .sort(
        (
          currentCampaign: GetAllCampaigns_getAllCampaigns_campaigns,
          nextCampaign: GetAllCampaigns_getAllCampaigns_campaigns
        ) =>
          currentCampaign &&
          nextCampaign &&
          currentCampaign.updatedAt &&
          nextCampaign.updatedAt
            ? moment(nextCampaign.updatedAt).isSameOrAfter(
                moment(currentCampaign.updatedAt)
              )
              ? 1
              : -1
            : 0
      );
  } else if (sortDate === CREATED_AT) {
    return campaigns
      ?.slice()
      .sort(
        (
          currentCampaign: GetAllCampaigns_getAllCampaigns_campaigns,
          nextCampaign: GetAllCampaigns_getAllCampaigns_campaigns
        ) =>
          currentCampaign &&
          nextCampaign &&
          currentCampaign.createdAt &&
          nextCampaign.createdAt
            ? moment(nextCampaign.createdAt).isSameOrAfter(
                moment(currentCampaign.createdAt)
              )
              ? 1
              : -1
            : 0
      );
  } else {
    return campaigns;
  }
};

/////////////////////////Campaign Form Config////////////////////////////

export const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

export const validationDate = (
  campaignType: CampaignType,
  validateDateIfCampaignTypeIsNotModel: (
    t: TFunction
  ) => Yup.DateSchema<Date, object>,
  t: TFunction
) => {
  if (campaignType !== CampaignType.Model) {
    return validateDateIfCampaignTypeIsNotModel(t);
  } else {
    return Yup.date().nullable();
  }
};

const validateDateFromIfCampaignTypeIsNotModel = (t: TFunction) => {
  return Yup.date()
    .nullable()
    .required(t('common.generic_error.required'))
    .when('dateTo', {
      is: (dateTo) => (dateTo ? true : false),
      then: Yup.date().max(
        Yup.ref('dateTo'),
        t('common.generic_error.max_date_valid_to')
      ),
      otherwise: Yup.date().nullable(),
    })
    .min(
      moment().startOf('day').toDate(),
      t('common.generic_error.start_date_after_today')
    );
};

const validateDateFromIfCampaignIsEdit = (t: TFunction) => {
  return Yup.date()
    .nullable()
    .required(t('common.generic_error.required'))
    .when('dateTo', {
      is: (dateTo) => (dateTo ? true : false),
      then: Yup.date().max(
        Yup.ref('dateTo'),
        t('common.generic_error.max_date_valid_to')
      ),
      otherwise: Yup.date().nullable(),
    });
};

const validateDateToIfCampaignTypeIsNotModel = (t: TFunction) =>
  Yup.date()
    .nullable()
    .required(t('common.generic_error.required'))
    .min(Yup.ref('dateFrom'), t('common.generic_error.max_date_valid_from'))
    .min(
      moment().startOf('day').toDate(),
      t('common.generic_error.start_date_after_today')
    );

const validateDateToIfCampaignIsEdit = (t: TFunction) =>
  Yup.date()
    .nullable()
    .required(t('common.generic_error.required'))
    .min(Yup.ref('dateFrom'), t('common.generic_error.max_date_valid_from'));

const validateCategory = (campaignType: CampaignType, t: TFunction) => {
  if (campaignType === CampaignType.Model) {
    return Yup.string().required(t('common.generic_error.required'));
  } else {
    return Yup.string();
  }
};

const validateTags = (campaignType: CampaignType, t: TFunction) => {
  if (campaignType === CampaignType.Model) {
    return Yup.array().of(
      Yup.string().max(30, t('common.generic_error.max_char_number'))
    );
  } else {
    return Yup.array()
      .of(Yup.string().max(30, t('common.generic_error.max_char_number')))
      .min(1, t('common.generic_error.required'));
  }
};

export const campaignFormValidation = (
  { campaignType }: { campaignType: CampaignType },
  t: TFunction,
  campaign?: GetCampaignById_getCampaignById
) => {
  return Yup.object({
    name: Yup.string()
      .required(t('common.generic_error.required'))
      .max(60, t('common.generic_error.max_char_number')),
    reference: Yup.string()
      .required(t('common.generic_error.required'))
      .max(60, t('common.generic_error.max_char_number')),
    description: Yup.string().max(
      200,
      t('common.generic_error.max_char_number')
    ),
    dateFrom: validationDate(
      campaignType,
      !campaign
        ? validateDateFromIfCampaignTypeIsNotModel
        : validateDateFromIfCampaignIsEdit,
      t
    ),
    dateTo: validationDate(
      campaignType,
      !campaign
        ? validateDateToIfCampaignTypeIsNotModel
        : validateDateToIfCampaignIsEdit,
      t
    ),
    commitmentDateFrom: validationDate(
      campaignType,
      !campaign
        ? validateDateFromIfCampaignTypeIsNotModel
        : validateDateFromIfCampaignIsEdit,
      t
    ),
    commitmentDateTo: validationDate(
      campaignType,
      !campaign
        ? validateDateToIfCampaignTypeIsNotModel
        : validateDateToIfCampaignIsEdit,
      t
    ),
    tags: validateTags(campaignType, t),
    category: validateCategory(campaignType, t),
    fileUpload: Yup.mixed().test(
      'fileUpload',
      t('common.generic_error.only_jpg_png'),
      (value: unknown) => checkFileMimeType(value)
    ),
    entities: Yup.array().min(1, t('common.generic_error.required')),
  });
};

export const fileReader = (file: File | null) => {
  return new Promise<ArrayBuffer>((resolve, reject) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as ArrayBuffer);
      };
      reader.readAsArrayBuffer(file);
    } else {
      reject('no file');
    }
  });
};

export enum MediumType {
  digital = 'digital',
  email = 'email',
  leaflet = 'leaflet',
  plv = 'plv',
  sms = 'sms',
}

export const setTypeName = (t: TFunction) => ({
  National: t('global.campaigns_helper.national'),
  Commitment: t('global.campaigns_helper.commitment'),
  FreeOrder: t('global.campaigns_helper.free_order'),
  PrintableInStore: t('global.campaigns_helper.printable_in_store'),
});

export const normalizedCampaign = (
  campaign: GetAllCampaigns_getAllCampaigns_campaigns
) => ({
  id: campaign.id,
  name: campaign.name,
  dateTo: campaign.dateTo,
  dateFrom: campaign.dateFrom,
  tags:
    campaign.tags?.map(({ title }) => ({ title } as { title: string })) ?? [],
  category: campaign.category ?? undefined,
  imageUrl: campaign.imageUrl ?? undefined,
});

export const hasCampaignStarted = (
  documents: GetAllCampaigns_getAllCampaigns_campaigns_documents[] | null
) =>
  Boolean(
    documents?.some(
      (document) =>
        new Date() > document.commitmentDateFrom ||
        new Date() > document.freeOrderDateFrom
    )
  );

export const isLocalCampaignVisible = (
  campaign:
    | GetCampaignsWithQuery_campaigns_campaigns
    | GetAllCampaigns_getAllCampaigns_campaigns
) =>
  Boolean(
    campaign.documents?.some(
      ({ origin, freeOrderDateFrom, freeOrderDateTo, isValidated }) => {
        const endDate = new Date(freeOrderDateTo).setHours(23, 59, 59);
        return origin === DocumentOrigin.Source &&
          freeOrderDateFrom &&
          freeOrderDateTo
          ? isWithinInterval(new Date(), {
              start: new Date(freeOrderDateFrom),
              end: new Date(endDate),
            })
          : moment(freeOrderDateFrom).isSameOrBefore(moment(), 'day') &&
              isValidated;
      }
    )
  );

export const isDocumentAvailable = (
  document: GetAllCampaigns_getAllCampaigns_campaigns_documents
) =>
  Boolean(
    document.isValidated &&
      new Date() > new Date(document.freeOrderDateFrom) &&
      document.origin === DocumentOrigin.Source
  );

export const CampaignI18nKeyErrorDictionary = new Map<string, string>([
  [
    CampaignError.containOrderedDocuments,
    'global.campaigns_list.actions.tooltip_message.campaign',
  ],
]);
