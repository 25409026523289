import { css } from '@emotion/react';

export const blockStyle = css`
  border: 1px solid #dadada;
  border-radius: 10px;
  background: #ffffff;
  padding: 35px;
  margin-bottom: 24px;

  /*&:hover {
	border-color: #326CF0;
	background: #EEF4FE;
}*/

  h2 {
    margin-bottom: 24px;
  }

  hr {
    margin: 18px 0;
  }
`;

export const containerStyle = css`
  padding: 0 64px;
`;

export const headerStyle = css`
  margin: 40px 0;
`;

export const gridStyle = css`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 40px;
`;

export const mapStyle = css`
  width: 100%;
  height: 605px;
  border-radius: 10px;
  background: #dadada;
  border: 1px solid #dadada;
`;

export const dataTableStyle = css`
  display: flex;
  gap: 16px;
  padding: 4px 0;

  dl {
    min-width: 185px;

    dt {
      margin-bottom: 8px;
      font-weight: bold;
      color: #afb1b8;
    }
  }
`;

export const dataTableInlineStyle = css`
  flex-direction: column;
  gap: 8px;

  dl {
    display: flex;
    width: 100%;
    justify-content: space-between;

    dt {
      margin: 0;
    }
  }
`;
