import React from 'react';
import { Button, Link, Tooltip } from '@material-ui/core';
import { TableData } from '../BasketDeliveryTable/BasketDeliveryTable.definitions';
import QuantitySelect from '../QuantitySelect';
import SelectAddressBlocks from '../SelectAddressBlocks';
import SelectAddresses from '../SelectDeliveryAddresses';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import {
  FacebookPostRowData,
  PrintDocumentRowData,
} from './BasketDeliveryContainer.definitions';
import { TFunction } from 'i18next';
import { formatPrice } from 'app/utils/common';

export const getPrintDocumentHeadData = (t: TFunction): TableData[] => [
  { content: 'N°', width: 1 },
  {
    content: t(
      'sales.basket_page.delivery_page.delivery_addresses.delivery_address'
    ),
    width: 3,
  },
  {
    content: t('sales.basket_page.delivery_page.delivery_addresses.quantity'),
    width: 3,
  },
  {
    content: t(
      'sales.basket_page.delivery_page.delivery_addresses.address_block'
    ),
    width: 3,
  },
  {
    content: (
      <h5 className="price">
        {t('sales.basket_page.delivery_page.delivery_addresses.price')}
      </h5>
    ),
    width: 2,
  },
];

export const getPrintDocumentRowData = ({
  basketDocumentItem,
  clientPricesData,
  setAddress,
  deliveryAddresses,
  t,
  onChangeAddress,
  options,
  error,
  handlePriceError,
  isQuantiySelectDisabled,
  minimumDocQuantity,
  maximumDocQuantity,
  addressBlocks,
  deleteDeliveryAddress,
  hasPriceError,
}: PrintDocumentRowData): TableData[][] =>
  basketDocumentItem.addresses.map((address, index) => [
    { content: <h5>{index + 1}</h5>, width: 1 },

    {
      content: (
        <SelectAddresses
          basketDocumentItem={basketDocumentItem}
          handleAddress={setAddress}
          options={deliveryAddresses}
          index={index}
        />
      ),
      width: 3,
    },
    {
      content:
        clientPricesData?.prices &&
        basketDocumentItem.basketDocument.document.productId ? (
          <QuantitySelect
            selectedValue={{
              quantity: address.quantity,
              price: address.price,
              productName: address.productName,
              isIncluded: address.isIncluded,
            }}
            productId={basketDocumentItem.basketDocument.document.productId}
            handleSelect={onChangeAddress}
            options={options}
            index={index}
            error={error}
            isDisabled={isQuantiySelectDisabled}
            minimum={minimumDocQuantity}
            maximum={maximumDocQuantity}
            handlePriceError={handlePriceError}
            hasPriceError={hasPriceError}
          />
        ) : (
          ''
        ),
      width: 3,
    },
    {
      content: basketDocumentItem.basketDocument.document.hasAddressBlock ? (
        <SelectAddressBlocks
          basketDocumentItem={basketDocumentItem}
          options={addressBlocks}
          index={index}
        />
      ) : (
        ''
      ),
      width: 3,
    },
    {
      content: (
        <Button
          className={!Boolean(index) ? 'hide' : undefined}
          variant="text"
          onClick={() => deleteDeliveryAddress(index)}
          disabled={!Boolean(index)}
        >
          <DeleteForeverOutlinedIcon />
        </Button>
      ),
      width: 1,
    },
    {
      content: (
        <h5 className="price">
          {address.isIncluded
            ? t('local.campaign_page.included')
            : address.price
            ? formatPrice(address.price, t)
            : ''}
        </h5>
      ),
      width: 1,
    },
  ]);

export const getFacebookHeadData = (t: TFunction): TableData[] => [
  { content: 'Page de diffusion', width: 9 },
  {
    content: (
      <h5 className="price">
        {t('sales.basket_page.delivery_page.delivery_addresses.price')}
      </h5>
    ),
    width: 3,
  },
];

export const getFacebookPostRowData = ({
  basketDocumentItem,
  t,
  entityName,
  url,
}: FacebookPostRowData): TableData[][] =>
  basketDocumentItem.addresses.map((address, index) => [
    {
      content: url ? (
        <span className="link">
          <h5>{entityName} : </h5> &nbsp;
          <Tooltip placement="bottom-start" title={url}>
            <Link
              variant="body1"
              href={url}
              style={{
                overflow: 'hidden',
              }}
            >
              <p>{url}</p>
            </Link>
          </Tooltip>
        </span>
      ) : (
        ''
      ),
      width: 9,
    },

    {
      content: (
        <h5 className="price">
          {address.isIncluded
            ? t('local.campaign_page.included')
            : address.price
            ? formatPrice(address.price, t)
            : ''}
        </h5>
      ),
      width: 3,
    },
  ]);
