import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import { CardContent, CardMedia, Typography } from '@material-ui/core';
import { DOCUMENT_HISTORY } from 'app/graphql/queries/documents';
import {
  DocumentHistory,
  DocumentHistoryVariables,
} from 'app/schemaInterfaces/DocumentHistory';
import {
  GetAllCampaigns_getAllCampaigns_campaigns,
  GetAllCampaigns_getAllCampaigns_campaigns_documents,
} from 'app/schemaInterfaces/GetAllCampaigns';
import { truncateString } from 'app/utils/common';
import Tag from 'modules/pro/components/Tag';
import { ShopRoute } from 'modules/shop/shopHelpers';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import colors from 'styles/colors.module.scss';

const style = (isCommitmentEnding: boolean) => css`
  height: 374px;
  border-radius: 10px;
  position: relative;
  text-decoration: none;
  color: inherit;

  .content {
    position: relative;
    .MuiCardMedia-media {
      border-radius: 10px 10px 0px 0px;
    }
    .date {
      color: ${isCommitmentEnding ? colors.tagTextColor0 : colors.documentTab};
      padding-bottom: 16px;
    }
    .tag {
      position: absolute;
      right: 20px;
      top: 20px;
    }
  }
`;

const NewCampaignsCard = ({
  campaign,
}: {
  campaign: GetAllCampaigns_getAllCampaigns_campaigns;
}) => {
  const { t } = useTranslation();

  const documentIds = campaign?.documents?.reduce(
    (
      previous: string[],
      current: GetAllCampaigns_getAllCampaigns_campaigns_documents
    ) => [
      ...previous,
      current.id,
      ...current.versionChildren.map(({ id }: { id: string }) => id),
    ],
    []
  );

  const {
    data: documentHistoryData,
    loading: documentHistoryDataLoading,
  } = useQuery<DocumentHistory, DocumentHistoryVariables>(DOCUMENT_HISTORY, {
    fetchPolicy: 'network-only',
    variables: { filter: { documentIds } },
  });

  const isCampaignNew =
    !documentHistoryData?.documentHistory.documents?.length &&
    !documentHistoryDataLoading;

  const today = new Date();
  today.setUTCHours(0, 0, 0, 0);

  const commitmentDateTo = new Date(campaign.commitmentDateTo);
  commitmentDateTo.setUTCHours(0, 0, 0, 0);

  const todayPlussevenDays = today.setDate(today.getDate() + 7);

  const isCommitmentEnding = +commitmentDateTo === todayPlussevenDays;

  return (
    <Link
      css={style(isCommitmentEnding)}
      to={`${ShopRoute.AllNationalCampaigns}/${campaign.id}`}
    >
      <div className="content">
        {isCampaignNew && (
          <div className="tag">
            <Tag
              titleColor={colors.white}
              title={
                !isCommitmentEnding
                  ? t('homepage.new')
                  : t('homepage.expires_soon')
              }
              backgroundColor={
                !isCommitmentEnding ? colors.acidGreen : colors.tagTextColor0
              }
            />
          </div>
        )}
        <CardMedia
          component="img"
          image={
            campaign.imageUrl ??
            `/${process.env.REACT_APP_ASSETS}/no_operation.png`
          }
          height="219"
          alt="campaign image"
        />
        <CardContent>
          <h3>{campaign.name}</h3>
          <Typography
            variant={isCommitmentEnding ? 'body2' : 'body1'}
            className="date"
          >
            {`${t('homepage.until')}
          ${moment(campaign.commitmentDateTo).format('DD/MM/YYYY')}`}
          </Typography>
          <Typography variant="body2">
            {campaign.description && truncateString(campaign.description, 108)}
          </Typography>
        </CardContent>
      </div>
    </Link>
  );
};

export default NewCampaignsCard;
