import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl, { FormControlProps } from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select, { SelectProps } from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CustomPlaceholder from 'components/CustomPlaceholder/CustomPlaceholder';

// V3 takes as Option value an array of string for each element displayed

export interface OptionV3 {
  value: string[];
  label: string;
}

interface UncontrolledMultipleValueSelectV3Props {
  values: string[];
  handleChange: (value: string[]) => void;
  options: OptionV3[];
  label?: string;
  margin?: FormControlProps['margin'];
  isFullWidth?: boolean;
  placeholder?: string;
  variant?: string;
}
const isValueArrayOfArrayOfString = (value: unknown): value is string[][] => {
  return Array.isArray(value as string[][]);
};
const isValueArrayOfString = (value: unknown): value is string[] => {
  return Array.isArray(value as string[]);
};

const deleteSimilarValues = (array: string[]) => {
  for (let i = 0; i < array.length - 1; i++) {
    for (let j = i + 1; j < array.length; j++) {
      if (array[i] === array[j]) {
        array.splice(j);
        array.splice(i);
      }
    }
  }
  return array;
};

const handleMultipleValueSelect = (values: unknown) => {
  const productIds: string[] = [];
  if (isValueArrayOfArrayOfString(values)) {
    values.forEach((values: string[]) => {
      if (isValueArrayOfString(values)) {
        values.forEach((value) => productIds.push(value));
      } else {
        productIds.push(values);
      }
    });
  }
  return deleteSimilarValues(productIds);
};
export const UncontrolledMultipleValueSelectV3 = ({
  label,
  margin,
  isFullWidth,
  options,
  handleChange,
  values,
  placeholder,
  variant = 'standard',
  ...selectProps
}: UncontrolledMultipleValueSelectV3Props & SelectProps): JSX.Element => {
  const renderValue = (value: unknown) => {
    if (isValueArrayOfArrayOfString(value) && value.length) {
      const labels: string[] = [];
      options.forEach(
        (option) =>
          value[0].includes(option.value[0]) && labels.push(option.label)
      );
      return labels.join(', ');
    } else return <CustomPlaceholder title={placeholder} />;
  };

  const formatOnChange = (value: unknown) => {
    handleMultipleValueSelect(value);
    handleChange(handleMultipleValueSelect(value));
  };

  return (
    <FormControl variant={variant} margin={margin} fullWidth={isFullWidth}>
      <Select
        labelId="mutiple-checkbox-label"
        id="mutiple-checkbox"
        multiple
        IconComponent={(props) => <KeyboardArrowDownIcon {...props} />}
        displayEmpty
        renderValue={(value) => renderValue(value)}
        margin="dense"
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        {...selectProps}
        value={values}
        onChange={(e) => formatOnChange(e.target.value)}
      >
        {options?.map((option, index: number) => {
          let isChecked = false;
          option.value.forEach((value) => {
            isChecked = values && values.includes(value);
          });

          return (
            <MenuItem key={index} value={option.value ?? undefined}>
              <Checkbox checked={isChecked} />
              <ListItemText primary={option.label} />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
