import { Action, ActionsMenu } from 'components/ActionsMenu/ActionsMenu';
import { ApolloError, ApolloQueryResult } from '@apollo/client/core';
import {
  GetAllCampaignsLean,
  GetAllCampaignsLeanVariables,
} from 'app/schemaInterfaces/GetAllCampaignsLean';
import {
  GetCampaignById,
  GetCampaignByIdVariables,
} from 'app/schemaInterfaces/GetCampaignById';
import React, { ReactNode } from 'react';

import Box from '@material-ui/core/Box';
import CampaignFormCreation from 'modules/pro/campaigns/form/CampaignFormCreation/CampaignFormCreation';
import DialogPreview from 'components/DialogPreview/DialogPreview';
import FormWrapper from 'components/FormWrapper/FormWrapper';
import { useCampaignCreation } from './CampaignCreation.hooks';
import { useTranslation } from 'react-i18next';

interface CampaignCreationProps {
  error?: ApolloError;
  id?: string;
  button: ReactNode;
  actions: (
    handleOpenCampaignDialog: () => void,
    deleteCampaign: () => void,
    previewCampaign: () => void,
    manageCampaign?: () => void
  ) => Action[];
  isEditMode: boolean;
  isCampaignModel: boolean;
  refetch?: (
    variables?: GetAllCampaignsLeanVariables
  ) => Promise<ApolloQueryResult<GetAllCampaignsLean>>;
  refetchCampaignById?: (
    variables?: Partial<GetCampaignByIdVariables> | undefined
  ) => Promise<ApolloQueryResult<GetCampaignById>>;
  campaginPreviewDetails?: {
    title?: string;
    imageUrl?: string | null;
    description?: string | null;
    tags?: (string | null)[];
    dateFrom?: Date;
    dateTo?: Date;
    icon?: React.ReactNode;
  };
}

const CampaignCreation = ({
  error,
  id,
  button,
  actions,
  isEditMode,
  isCampaignModel,
  refetch,
  refetchCampaignById,
  campaginPreviewDetails,
}: CampaignCreationProps): JSX.Element => {
  const { t } = useTranslation();

  const {
    manageCampaign,
    handleOpenCampaignDialog,
    handleCloseCampaignDialog,
    openActionCreateCampaignDialog,
    previewCampaign,
    handleDeleteCampaign,
    handleOpenActionsCreateCampaignDialog,
    handleCloseActionsCreateCampaignDialog,
    isCampaignDialogOpen,
    isCampaignPreviewOpen,
    setCampaignPreviewOpen,
  } = useCampaignCreation(id ?? '', isCampaignModel, refetch);

  return (
    <Box>
      {Boolean(
        actions(
          handleOpenCampaignDialog,
          handleDeleteCampaign,
          previewCampaign,
          manageCampaign
        ).length
      ) && (
        <>
          <ActionsMenu
            actions={actions(
              handleOpenCampaignDialog,
              handleDeleteCampaign,
              previewCampaign,
              manageCampaign
            )}
            button={button}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            handleMenuClick={handleOpenActionsCreateCampaignDialog}
            handleMenuClose={handleCloseActionsCreateCampaignDialog}
            anchorEl={openActionCreateCampaignDialog}
          />

          <FormWrapper
            open={isCampaignDialogOpen}
            handleClose={handleCloseCampaignDialog}
            title={
              !isCampaignModel
                ? !isEditMode
                  ? t('global.national_campaigns.create_campaign')
                  : t('global.national_campaigns.modify_campaign')
                : !isEditMode
                ? t('global.thematics.create_thematic')
                : t('global.thematics.modify_thematic')
            }
            maxWidth="sm"
          >
            <CampaignFormCreation
              error={error}
              handleClose={handleCloseCampaignDialog}
              submitButton={
                !isCampaignModel
                  ? !isEditMode
                    ? t('global.national_campaigns.create_campaign')
                    : t('global.national_campaigns.modify_campaign')
                  : !isEditMode
                  ? t('global.thematics.create_thematic')
                  : t('global.thematics.modify_thematic')
              }
              campaignName={
                !isCampaignModel
                  ? t('global.campaigns_list.table.columns.campaign_name')
                  : t('global.thematics.thematic_name')
              }
              campaignDescription={
                !isCampaignModel
                  ? t('global.national_campaigns.campaign_description')
                  : t('global.thematics.thematic_description')
              }
              id={id}
              isEditMode={isEditMode}
              isCampaignModel={isCampaignModel}
              refetch={refetch}
              refetchCampaignById={refetchCampaignById}
            />
          </FormWrapper>
        </>
      )}
      {campaginPreviewDetails && (
        <Box pt={1}>
          <DialogPreview
            imageUrl={campaginPreviewDetails.imageUrl}
            title={campaginPreviewDetails.title}
            dateFrom={campaginPreviewDetails.dateFrom}
            dateTo={campaginPreviewDetails.dateTo}
            tags={campaginPreviewDetails.tags}
            isCampaignModel={isCampaignModel}
            description={campaginPreviewDetails.description}
            maxWidth="sm"
            open={isCampaignPreviewOpen}
            setOpen={setCampaignPreviewOpen}
          />
        </Box>
      )}
    </Box>
  );
};

export default CampaignCreation;
