import { Link } from '@material-ui/core';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { filterOptionsStyle } from './FilterOptions.style';

export interface FilterInputProps {
  filterTitile: string;
  filterInput: JSX.Element;
}

export interface FilterOptionsProps {
  resetFilter: () => void;
  filterInputs: FilterInputProps[];
}

const FilterOptions: FC<FilterOptionsProps> = ({
  resetFilter,
  filterInputs,
}) => {
  const { t } = useTranslation();
  return (
    <div css={filterOptionsStyle} data-testid="filter-options">
      {filterInputs.map(({ filterTitile, filterInput }, index) => (
        <div className="filter-item" key={index}>
          <h5>{filterTitile} </h5>
          <div className="input">{filterInput}</div>
        </div>
      ))}

      <div>
        <Link
          data-testid="button"
          component="button"
          variant="body2"
          color="primary"
          underline="always"
          onClick={resetFilter}
        >
          {t('global.filters_popover.reset_filters')}
        </Link>
      </div>
    </div>
  );
};

export default FilterOptions;
