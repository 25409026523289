import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import colors from 'styles/colors.module.scss';
import React from 'react';

export interface PanelListOption<T> {
  value: T;
  label: string;
}

interface PanelListProps<T> {
  options: PanelListOption<T>[];
  onClick?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    value: T
  ) => void;
}

const useStyles = makeStyles({
  listItem: {
    '&:hover': {
      backgroundColor: colors.grey,
    },
  },
});

export function PanelList<T>({
  options,
  onClick,
}: PanelListProps<T>): JSX.Element {
  const classes = useStyles();

  return (
    <List style={{ width: '100%' }}>
      {options.map((option, index) => (
        <ListItem
          classes={{ selected: classes.listItem }}
          key={index}
          button
          onClick={(event) => {
            onClick?.(event, option.value);
          }}
        >
          <ListItemText
            primary={
              <Typography style={{ color: colors.white }}>
                {option.label}
              </Typography>
            }
          />
          <ListItemSecondaryAction style={{ display: 'flex' }}>
            <ArrowForwardIcon htmlColor={colors.inputLabelColor} />
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
}

export default PanelList;
