import { css } from '@emotion/react';

export const allCampaignsStyle = css`
  padding-right: 42px;
  padding-left: 42px;
  h1 {
    padding-top: 39px;
    padding-bottom: 34px;
  }
`;
