import {
  AscDescType,
  CampaignFilterInput,
  CampaignType,
  DateStatus,
  UserType,
} from 'app/schemaInterfaces/globalTypes';

import { ShopRoute } from 'modules/shop/shopHelpers';
import useCampaignsLeanQuery from '../../queries/useCampaignsLeanQuery';
import useCurrentEntity from 'app/current-entity-context/useCurrentEntity';
import { useCurrentUser } from 'app/auth/UserContext';
import { useRouter } from 'app/routes/useRouter';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useAllCampaigns = (filters: CampaignFilterInput) => {
  const { currentEntity } = useCurrentEntity();
  const router = useRouter();
  const routeName = router.location.pathname;
  const { t } = useTranslation();
  const [selectedDateStatus, setSelectedDateStatus] = useState<DateStatus>(
    DateStatus.INPROGRESS
  );
  const isCampaignModel = routeName === ShopRoute.AllLocalCampaigns;
  const user = useCurrentUser();
  const isUserGlobal = user.type === UserType.Global;

  const variables = {
    filter: {
      entityId: currentEntity?.id,
      sort: { createdAt: AscDescType.desc },
      campaignType: isCampaignModel
        ? CampaignType.Model
        : CampaignType.National,
      dateFilter: {
        status: isCampaignModel ? DateStatus.ALL : selectedDateStatus,
      },
      activeNationalCampaigns: !isCampaignModel && !isUserGlobal,
      activeLocalCampaigns: isCampaignModel && !isUserGlobal,
      skipOutdatedDocuments: isCampaignModel
        ? !isUserGlobal
        : selectedDateStatus === DateStatus.INPROGRESS && !isUserGlobal,
      ...filters,
    },
  };

  const {
    data: campaignsData,
    error: campaignsError,
    loading: isLoading,
    refetch,
  } = useCampaignsLeanQuery(variables);

  const toolTipMessage = isCampaignModel
    ? t('global.campaigns_list.actions.tooltip_message.model')
    : t('global.campaigns_list.actions.tooltip_message.campaign');

  return {
    isCampaignModel,
    campaignsError,
    selectedDateStatus,
    setSelectedDateStatus,
    refetch,
    isLoading,
    campaignsData,
    toolTipMessage,
    t,
  };
};
