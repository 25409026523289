import React, { ForwardRefRenderFunction, ReactNode } from 'react';
import {
  iconDisabledStyle,
  iconErrorStyle,
  iconStyle,
  iconSuccessStyle,
  iconWarningStyle,
} from './StatusIcon.style';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CheckIcon from '@material-ui/icons/Check';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

export interface StatusIconProps {
  variant?:
    | 'warning'
    | 'error'
    | 'success'
    | 'disabled'
    | 'completed'
    | 'active';
  icon?: ReactNode;
}

const StatusIcon: ForwardRefRenderFunction<HTMLDivElement, StatusIconProps> = (
  { variant = 'active', icon, ...props },
  ref
) => (
  <div
    {...props}
    ref={ref}
    css={[
      iconStyle,
      variant === 'disabled' && iconDisabledStyle,
      variant === 'warning' && iconWarningStyle,
      variant === 'error' && iconErrorStyle,
      variant === 'success' && iconSuccessStyle,
    ]}
  >
    {icon ? (
      icon
    ) : variant === 'warning' || variant === 'error' ? (
      <ErrorOutlineIcon />
    ) : variant === 'success' ? (
      <CheckCircleOutlineIcon />
    ) : variant === 'completed' ? (
      <CheckIcon />
    ) : undefined}
  </div>
);

export default React.forwardRef(StatusIcon);
