import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { GET_PRODUCT_BY_ID } from 'app/graphql/queries/products';
import {
  GetProductById,
  GetProductByIdVariables,
  GetProductById_getProduct_history,
} from 'app/schemaInterfaces/GetProductById';
import { useQuery } from '@apollo/client';

import Loader from 'components/Loader/Loader/Loader';
import moment from 'moment';
import colors from 'styles/colors.module.scss';
import _ from 'lodash';
import {
  getHistoryWithFieldType,
  getDivisionLabel,
  getUnifiedKeyFromPimClientConfig,
} from 'modules/pim/baseProducts/ProductsHelper';
import UserBadge from 'components/UserBadge';
import UserHistory from 'components/UserHistory';
import {
  GetUserProfiles,
  GetUserProfilesVariables,
} from 'app/schemaInterfaces/GetUserProfiles';
import { GET_USER_PROFILES } from 'app/graphql/queries/users';
import useAppContext from 'app/app-context/useAppContext';
import HistoryFieldsConfig from 'modules/pim/components/HistoryFieldsConfig';
import { GET_DIVISIONS } from 'app/graphql/queries/divisions';
import { GET_DIVISIONS as I_GET_DIVISIONS } from 'app/schemaInterfaces/GET_DIVISIONS';
import { Optional } from 'app/utils/common';
import { useTranslation } from 'react-i18next';
import { UnifiedProductKeyToRenderOnPage } from 'modules/workflow/containers/Leftsidebar/ProductsPanel/ProductsView';
import { subTabLabel } from '../regroupingHelper';

export interface HistoryWithFieldType
  extends GetProductById_getProduct_history {
  fieldType: string;
  historyType: string;
  productId?: string;
}

interface ProductSheetHistoryProps {
  productId: string;
  campaignId?: string;
}

const ProductSheetHistory = ({
  productId,
  campaignId,
}: ProductSheetHistoryProps): JSX.Element => {
  const {
    client: { config: clientConfig },
  } = useAppContext();

  const { t } = useTranslation();

  const { data: allDivisionsValues } = useQuery<I_GET_DIVISIONS>(GET_DIVISIONS);

  let divisionsLabels: string[] = [];

  if (allDivisionsValues) {
    divisionsLabels = getDivisionLabel(allDivisionsValues.getDivisions);
  }

  const { data: productSheetData, error: productSheetError } = useQuery<
    GetProductById,
    GetProductByIdVariables
  >(GET_PRODUCT_BY_ID, {
    variables: {
      productId,
    },
    skip: !!campaignId,
  });

  const { getProduct: productSheet } = productSheetData ?? {};

  const {
    data: campaignProductSheetData,
    error: campaignProductSheetError,
  } = useQuery<GetProductById, GetProductByIdVariables>(GET_PRODUCT_BY_ID, {
    variables: {
      productId,
    },
    skip: !campaignId,
  });

  const { data: getProfiles } = useQuery<
    GetUserProfiles,
    GetUserProfilesVariables
  >(GET_USER_PROFILES, {
    variables: {
      filters: {
        entities: null,
      },
    },
  });

  const { getProduct: campaignProductSheet } = campaignProductSheetData ?? {};

  const getHistoryUserProfile = (userId: string) =>
    getProfiles?.publicUserProfiles.map(
      ({ id, familyName, givenName }, index) => {
        return userId === id ? (
          <UserBadge
            key={index}
            lastName={familyName}
            firstName={givenName}
            isFullnameEmphasised={true}
          />
        ) : null;
      }
    );

  const newHistoryWithFieldType = getHistoryWithFieldType(
    productSheet?.history as Optional<GetProductById_getProduct_history[]>,
    campaignProductSheet?.history as Optional<
      GetProductById_getProduct_history[]
    >,
    clientConfig,
    divisionsLabels
  );

  const newHistoryWithFieldTypeByDate = _.chain(newHistoryWithFieldType)
    .groupBy((history) => moment(history.updatedAt).format('LL'))
    .map((value, key) => ({ updatedAt: key, history: _.reverse(value) }))
    .value();

  const productName = getUnifiedKeyFromPimClientConfig(
    UnifiedProductKeyToRenderOnPage.title,
    clientConfig
  );

  return productSheet || campaignProductSheet ? (
    <Box pt={2}>
      {productSheet && (
        <div>
          <UserHistory
            updatedAt={moment(new Date(productSheet?.createdAt)).format(
              'DD MMMM YYYY'
            )}
          >
            <Box pt={1} pb={1}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                {productSheet && getHistoryUserProfile(productSheet.updatedBy)}
                <Typography
                  variant="caption"
                  style={{ color: colors.disabled }}
                >
                  {moment(new Date(productSheet?.createdAt)).format('LT')}
                </Typography>
              </Box>
              <Box pl={5}>
                <Typography variant="body2">
                  {t('pim.history.created')}
                </Typography>
              </Box>
            </Box>
          </UserHistory>
          {productSheet?.regrouping.length > 1 &&
            productSheet.regrouping.map((group, index) => (
              <UserHistory
                updatedAt={moment(new Date(group.createdDate)).format(
                  'DD MMMM YYYY'
                )}
              >
                <Box pt={1} pb={1}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {productSheet && getHistoryUserProfile(group.userId ?? '')}
                    <Typography
                      variant="caption"
                      style={{ color: colors.disabled }}
                    >
                      {moment(new Date(group.createdDate)).format('LT')}
                    </Typography>
                  </Box>
                  <Box pl={5}>
                    <Typography variant="body2">
                      {index > 0
                        ? t('pim.history.product_regrouping_created')
                        : t('pim.history.regrouping_created')}
                    </Typography>
                    <div>
                      {subTabLabel(index ?? 0, t)}:
                      <Typography variant="body2">
                        {group.details[productName]}
                      </Typography>
                    </div>
                  </Box>
                </Box>
              </UserHistory>
            ))}
        </div>
      )}

      {_.reverse(newHistoryWithFieldTypeByDate).map(
        (newHistory, newHistoryIndex) => (
          <UserHistory updatedAt={newHistory.updatedAt} key={newHistoryIndex}>
            {newHistory.history.map((singleHistory, singleHistoryIndex) => (
              <Box pt={1} key={singleHistoryIndex}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  {getHistoryUserProfile(singleHistory.updatedBy)}
                  <Typography
                    variant="caption"
                    style={{ color: colors.disabled }}
                  >
                    {moment(singleHistory.updatedAt).format('LT')}
                  </Typography>
                </Box>
                <Box pl={5}>
                  <HistoryFieldsConfig
                    singleHistory={singleHistory}
                    regrouping={productSheet?.regrouping}
                  />
                </Box>
              </Box>
            ))}
          </UserHistory>
        )
      )}
    </Box>
  ) : (
    <Loader error={productSheetError ?? campaignProductSheetError} />
  );
};

export default ProductSheetHistory;
