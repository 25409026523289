import { ReactiveVar, makeVar } from '@apollo/client';
import { GetAssetsVariables } from 'app/schemaInterfaces/GetAssets';
import { GetDocumentsVariables } from 'app/schemaInterfaces/GetDocuments';
import { Optional } from 'app/utils/common';

export const documentFiltersVar: ReactiveVar<Optional<
  GetDocumentsVariables
>> = makeVar<Optional<GetDocumentsVariables>>(undefined);

export const assetsFiltersVar: ReactiveVar<Optional<
  GetAssetsVariables
>> = makeVar<Optional<GetAssetsVariables>>(undefined);
