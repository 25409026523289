import { useMutation } from '@apollo/client';
import { UPDATE_NOTIFICATIONS } from 'app/graphql/queries/notifications';
import {
  UpdateNotification,
  UpdateNotificationVariables,
} from 'app/schemaInterfaces/UpdateNotification';

const useUpdateNotification = () => {
  const [
    updateNotification,
    {
      data: updateNotificationData,
      error: updateNotificationError,
      loading: updateNotificationLoading,
    },
  ] = useMutation<UpdateNotification, UpdateNotificationVariables>(
    UPDATE_NOTIFICATIONS
  );

  return {
    updateNotification,
    updateNotificationData,
    updateNotificationError,
    updateNotificationLoading,
  };
};

export default useUpdateNotification;
