import { gql } from '@apollo/client';
import { PRODUCT_ATTRIBUTES_DETAILS } from './documents';

const DOCUMENT_BASIC = gql`
  fragment DocumentBasic on Document {
    campaign {
      id
      name
      dateTo
    }
    quantities {
      strictQuantity
      rangeQuantity {
        minimum
        maximum
      }
    }
    id
    category
    isCustomizable
    isCustomization
    isValidated
    mediumType
    name
    orderTypes
    origin
    thumbnailUrl
    thumbnailId
    resources {
      resourceId
      mimetype
      resourceIndex
    }
    versionId
    commitmentDateTo
    freeOrderDateTo
    version
    isValidated
    hasAddressBlock
    isMandatory
  }
`;

const BASKET_DOCUMENT_DETAIL = gql`
  fragment BasketDocumentDetail on Basket {
    document {
      ...DocumentBasic
    }
    entityId
    id
    quantity
    shippingPointsCount
    type
    userId
    price
    productName
  }

  ${DOCUMENT_BASIC}
`;

export const BASKET_DOCUMENT = gql`
  query GetBasketDocument($id: String!) {
    basketDocument(id: $id) {
      ...BasketDocumentDetail
    }
  }

  ${BASKET_DOCUMENT_DETAIL}
`;

export const BASKET_DOCUMENTS = gql`
  query GetBasketDocuments(
    $filter: BasketFilterInput
    $pagination: PaginationInput
  ) {
    basket(filter: $filter, pagination: $pagination) {
      basketDocuments {
        document {
          ...DocumentBasic
          ...ProductAttributesDetails
          supportId
          productId
          priceInformations {
            isIncluded
            quantityMinimum
          }
          isDisabled {
            disabled
            reason
          }
        }
        entityId
        id
        isOutdated
        quantity
        shippingPointsCount
        type
        userId
        price
        productName
        digitalContent {
          message
        }
      }
      totalItemsInProgress
      totalItemsTerminated
    }
  }

  ${DOCUMENT_BASIC}
  ${PRODUCT_ATTRIBUTES_DETAILS}
`;

export const ADD_BASKET_DOCUMENT = gql`
  mutation AddBasketDocument($values: BasketAddDocumentInput!) {
    addBasketDocument(values: $values) {
      documentId
      entityId
      id
      quantity
      shippingPointsCount
      type
      userId
      price
      productName
    }
  }
`;

export const REMOVE_BASKET_DOCUMENTS = gql`
  mutation RemoveBasketDocuments($ids: [String!]!) {
    removeBasketDocuments(ids: $ids) {
      isSuccess
    }
  }
`;

export const UPDATE_BASKET_DOCUMENT = gql`
  mutation UpdateBasketDocument($id: String!, $values: BasketUpdateInput!) {
    updateBasketDocument(id: $id, values: $values) {
      ...BasketDocumentDetail
    }
  }

  ${BASKET_DOCUMENT_DETAIL}
`;
