import { ApolloError, ApolloQueryResult } from '@apollo/client';
import { Box, Divider, List, ListItem, Typography } from '@material-ui/core';
import {
  GetAllCampaignsLean,
  GetAllCampaignsLeanVariables,
  GetAllCampaignsLean_getAllCampaigns_campaigns,
} from 'app/schemaInterfaces/GetAllCampaignsLean';
import React, { ReactNode } from 'react';
import { Action } from 'components/ActionsMenu/ActionsMenu';
import { TableLine } from 'modules/pro/components/TableLine/TableLine';
import colors from 'styles/colors.module.scss';
import { useTranslation } from 'react-i18next';

interface CampaignsListProps {
  columnsWidth: number[];
  campaigns?: GetAllCampaignsLean_getAllCampaigns_campaigns[];
  imageWidth: number;
  actions: (
    manageCampaign: () => void,
    handleOpenCampaignDialog: () => void,
    previewCampaign: () => void,
    deleteCampaign: () => void,
    isDisabled?: boolean
  ) => Action[];
  error?: ApolloError;
  button: ReactNode;
  isCampaignModel: boolean;
  refetch: (
    variables?: Partial<GetAllCampaignsLeanVariables> | undefined
  ) => Promise<ApolloQueryResult<GetAllCampaignsLean>>;
}

const CampaignsList = ({
  columnsWidth,
  campaigns,
  imageWidth,
  actions,
  button,
  isCampaignModel,
  refetch,
}: CampaignsListProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Box pb={10}>
      <Box display="flex">
        <ListItem>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Box display="flex" width={`${columnsWidth[0]}px`}>
              <Box width={imageWidth} mr={3} />
              <Box color={colors.grey}>
                <Typography variant="body2">
                  {!isCampaignModel
                    ? t('global.campaigns_list.table.columns.campaign_name')
                    : t('global.campaigns_list.table.columns.model_name')}
                </Typography>
              </Box>
            </Box>

            <Box width={`${columnsWidth[1]}px`} color={colors.grey}>
              {!isCampaignModel && (
                <Typography variant="body2">
                  {t('global.campaigns_list.table.columns.validity_dates')}
                </Typography>
              )}
            </Box>

            <Box width={`${columnsWidth[2]}px`} color={colors.grey}>
              <Typography variant="body2">
                {t('global.campaigns_list.table.columns.categories')}
              </Typography>
            </Box>
            <Box width={`${columnsWidth[3]}px`} color={colors.grey}>
              <Typography variant="body2">
                {t('global.campaigns_list.table.columns.reference')}
              </Typography>
            </Box>
          </Box>
        </ListItem>
        <Box width={`${columnsWidth[4]}px`} />
      </Box>
      <Divider />
      <List>
        {campaigns?.map((campaign, index: number) => (
          <Box mt={1} key={index}>
            <ListItem button>
              <TableLine
                title={campaign.name}
                tags={campaign.tags?.map((tag) => tag.title)}
                category={campaign.category}
                imageUrl={campaign.imageUrl}
                customerRef={campaign.reference}
                dateFrom={campaign.dateFrom}
                dateTo={campaign.dateTo}
                documentCategories={campaign.documentCategories}
                columnsWidth={columnsWidth}
                imageWidth={imageWidth}
                actions={actions}
                campaignId={campaign.id}
                button={button}
                isCampaignModel={isCampaignModel}
                refetch={refetch}
                description={campaign.description}
              />
            </ListItem>
            <Box pt={1}>
              <Divider />
            </Box>
          </Box>
        ))}
      </List>
    </Box>
  );
};

export default CampaignsList;
