import React from 'react';
import { Grid } from '@material-ui/core';
import { DEFAULT_IMAGE_PREVIEW, truncateString } from 'app/utils/common';
import { useTranslation } from 'react-i18next';
import FormatColorFillOutlinedIcon from '@material-ui/icons/FormatColorFillOutlined';
import colors from 'styles/colors.module.scss';
import { tagStyle } from './OrderDocumentList';
import { documentsGridListStyle } from './styles/documents.grid.list';
import DocumentPreview from 'components/ConfirmationDialogs/DocumentPreview/DocumentPreview';
import { filteredAttributes } from 'modules/sales/components/QuantitySelectHelper';
import AddToBasketButton from 'modules/shop/components/AddToBasketButton';
import { AddButtonProps } from 'modules/shop/shopHelpers';
import { GetDocumentsToShop_documents_documents } from 'app/schemaInterfaces/GetDocumentsToShop';
import DocumentsGridViewDetails from './DocumentGridViewDetails/DocumentGridViewDetails';

interface DocumentsGridViewProps {
  document: GetDocumentsToShop_documents_documents;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  addToBasket: () => void;
  buttonProps: AddButtonProps;
}

const DocumentsGridView = ({
  document,
  isOpen,
  setIsOpen,
  addToBasket,
  buttonProps,
}: DocumentsGridViewProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={12} sm={4} md={3} xl={2}>
        <div
          css={documentsGridListStyle(
            document.isRecommended,
            document.isMandatory
          )}
        >
          <div
            className="media"
            onClick={(e) => {
              setIsOpen(true);
            }}
          >
            <img
              src={document.thumbnailUrl ?? DEFAULT_IMAGE_PREVIEW}
              alt="document"
            />
            {document.isRecommended && (
              <div className="recommended-badge">
                <span css={tagStyle(colors.primary, colors.menuHover)}>
                  {t('local.campaign_page.recommended')}
                </span>
              </div>
            )}
            {document.isMandatory && (
              <div className="mandatory-badge">
                <span css={tagStyle(colors.primary, colors.menuHover)}>
                  {t('global.document_form.is_mandatory.document_tag')}
                </span>
              </div>
            )}
            {document.isCustomizable && (
              <div className="customizable-icon">
                <FormatColorFillOutlinedIcon className="icon" />
              </div>
            )}
            <div className="content">
              <p className="title">{truncateString(document.name, 30)}</p>
              <p className="version">{truncateString(document.version, 27)}</p>
            </div>
          </div>

          <div className="details">
            {
              <DocumentsGridViewDetails
                documentCommitmentCode={document.documentCommitmentCode}
                priceInformations={document.priceInformations}
              />
            }
            {!document.documentCommitmentCode && (
              <AddToBasketButton
                addToBasket={addToBasket}
                buttonProps={buttonProps}
                hasPrice={Boolean(document.priceInformations)}
              />
            )}
          </div>
        </div>
      </Grid>
      <DocumentPreview
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title={document.name}
        version={document.version}
        url={document.thumbnailUrl}
        isCustomizable={document?.isCustomizable}
        mediumType={document.mediumType}
        category={document.category}
        attributes={filteredAttributes(document.productAttributes)}
      />
    </>
  );
};

export default DocumentsGridView;
