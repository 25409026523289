import { css } from '@emotion/react';

export const style = css`
  padding-left: 84px;
  padding-right: 84px;
  margin-top: 38px;
`;

export const timelineContainerStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const timelineStyle = css`
  width: 100%;
  padding: 24px 0;
`;

export const partialTimelineStyle = css`
  width: 40%;
`;
