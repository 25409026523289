import { css } from '@emotion/react';
import { IconButton, TextField, TextFieldProps } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import React, { useState } from 'react';

type UncontrolledDatePickerProps = {
  value: MaterialUiPickersDate;
  handleClear:
    | ((event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
};
const style = css`
  .MuiIconButton-root {
    padding: 8px 0;
  }
`;

const SEVENTH_DAY_OF_THE_WEEK = 7
export const CustomTextField = (props: TextFieldProps): JSX.Element => (
  <TextField {...props} size="small" />
);

export const UncontrolledDatePicker = ({
  value,
  handleClear,
  ...keyboardDatePickerProps
}: UncontrolledDatePickerProps & KeyboardDatePickerProps): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false);
  const format = 'DD/MM/YYYY';

  const handleShouldDisableDate = (day: MaterialUiPickersDate) => {
	  if (!day) return false
	  return day.format('E') === SEVENTH_DAY_OF_THE_WEEK.toString()
  }

  return (
    <KeyboardDatePicker
      css={style}
      disableToolbar
      inputVariant="outlined"
      variant="inline"
      format={format}
      value={value}
      autoOk
      open={open}
      KeyboardButtonProps={{
        'aria-label': 'change date',
      }}
	  shouldDisableDate={handleShouldDisableDate}
      InputProps={{
        endAdornment: (
          <>
            {value ? (
              <IconButton
                disabled={keyboardDatePickerProps.disabled}
                onClick={handleClear}
              >
                <ClearIcon />
              </IconButton>
            ) : (
              <IconButton
                disabled={keyboardDatePickerProps.disabled}
                onClick={() => setOpen(true)}
              >
                <EventOutlinedIcon />
              </IconButton>
            )}
          </>
        ),
      }}
      InputAdornmentProps={{
        position: 'start',
        style: {
          display: 'none',
        },
      }}
      onClose={() => {
        setOpen(false);
      }}
      TextFieldComponent={CustomTextField}
      {...keyboardDatePickerProps}
    />
  );
};
