import { GetOrderDeliveries_orderDeliveries } from 'app/schemaInterfaces/GetOrderDeliveries';
import { TFunction } from 'i18next';
import moment from 'moment';

export const tableRowData = (
  orderDeliveryData: GetOrderDeliveries_orderDeliveries | undefined
) =>
  orderDeliveryData?.deliveryPoints?.flatMap(
    (deliveryPoint) =>
      deliveryPoint.controls?.flatMap((controlItem) =>
        controlItem.receptions.flatMap(
          (receptionItem) =>
            receptionItem.receptionDetails.map((detail) => [
              deliveryPoint.code,
              deliveryPoint.name,
              deliveryPoint.address1,
              deliveryPoint.zipCode,
              deliveryPoint.town,
              deliveryPoint.country,
              moment(controlItem.answerDate).format('DD/MM/YY HH:mm'),
              controlItem.contactName,
              detail.shop,
              controlItem.eventComment,
            ]) ?? []
        )
      ) ?? []
  );

export const tableHeadData = (t: TFunction) => [
  t('follow_orders.delivery_control.deposit_code'),
  t('follow_orders.delivery_control.deposit_name'),
  t('follow_orders.delivery_control.address'),
  t('follow_orders.delivery_control.zipcode'),
  t('follow_orders.delivery_control.town'),
  t('follow_orders.delivery_control.country'),
  t('follow_orders.delivery_control.date_hour'),
  t('follow_orders.delivery_control.contact'),
  t('follow_orders.delivery_control.store'),
  t('follow_orders.delivery_control.comment'),
];
