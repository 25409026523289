import { PermissionKey } from 'app/schemaInterfaces/globalTypes';
import { useCurrentUser } from './UserContext';

const usePermissions = () => {
  const currentUser = useCurrentUser();

  const hasPermission = (permission: PermissionKey) =>
    Boolean(
      currentUser.role?.permissions.filter(
        (userPermission) => userPermission.key === permission
      ).length
    );

  return { hasPermission };
};

export default usePermissions;
