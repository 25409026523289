import { TemplateType } from 'app/schemaInterfaces/globalTypes';
import { FC } from 'react';
import EditPlatform from './containers/EditPlatform';
import ChiliPlatform from './external/ChiliPlatform';
import { useStudioStateContext } from './oneStudioContext';

const StudioPlatformSelector: FC = () => {
  const { version } = useStudioStateContext();

  if (version.template?.type === TemplateType.BIGONE) {
    return <EditPlatform />;
  } else if (
    version.template?.type === TemplateType.CHILI &&
    version.template.metadata.chili
  ) {
    return <ChiliPlatform metadata={version.template.metadata.chili} />;
  } else {
    return <span>error!</span>;
  }
};

export default StudioPlatformSelector;
