import { useCurrentUser } from 'app/auth/UserContext';
import useCurrentEntity from 'app/current-entity-context/useCurrentEntity';
import {
  AscDescType,
  CampaignFilterInput,
  CampaignType,
  DateStatus,
  UserType,
} from 'app/schemaInterfaces/globalTypes';
import { useState } from 'react';
import useCampaignsLeanQuery from '../queries/useCampaignsLeanQuery';

export const useShop = (filters: CampaignFilterInput) => {
  const { currentEntity } = useCurrentEntity();
  const user = useCurrentUser();
  const isUserGlobal = user.type === UserType.Global;

  const [selectedDateStatus, setSelectedDateStatus] = useState<DateStatus>(
    DateStatus.INPROGRESS
  );

  const localVariables = {
    filter: {
      entityId: currentEntity?.id,
      sort: { createdAt: AscDescType.desc },
      activeLocalCampaigns: !isUserGlobal,
      campaignType: CampaignType.Model,
      skipOutdatedDocuments: !isUserGlobal,
      ...filters,
    },
    pagination: { limit: 3 },
  };

  const nationalVariables = {
    filter: {
      entityId: currentEntity?.id,
      sort: { createdAt: AscDescType.desc },
      activeNationalCampaigns: !isUserGlobal,
      campaignType: CampaignType.National,
      dateFilter: { status: selectedDateStatus },
      skipOutdatedDocuments:
        selectedDateStatus === DateStatus.INPROGRESS && !isUserGlobal,
      ...filters,
    },
    pagination: { limit: 3 },
  };

  const {
    data: localCampaigns,
    error: localCampaignsError,
    loading: isLocalCampaignsLoading,
    refetch: localCampaignsRefetch,
  } = useCampaignsLeanQuery(localVariables);

  const {
    data: nationalCampaigns,
    error: nationalCampaignsError,
    loading: isNationalCampaignsLoading,
    refetch: nationalCampaignsRefetch,
  } = useCampaignsLeanQuery(nationalVariables);
  return {
    localCampaigns,
    localCampaignsError,
    isLocalCampaignsLoading,
    localCampaignsRefetch,
    nationalCampaigns,
    nationalCampaignsError,
    isNationalCampaignsLoading,
    nationalCampaignsRefetch,
    selectedDateStatus,
    setSelectedDateStatus,
  };
};
