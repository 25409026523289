import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const style = (isOrderList: boolean | undefined) => css`
  display: ${isOrderList ? 'inline-table' : 'block'};
  overflow-x: auto;

  .MuiTableCell-root {
    display: table-cell;
    height: 43px;
    color: ${colors.body};
    font-family: 'ScandiaWebRegular';
    background: none;
  }

  .MuiTableCell-head {
    color: ${colors.grey};
    font-family: 'ScandiaWebMedium';
  }

  .MuiTableHead-root {
    border-top: 2px solid ${colors.lightGrey};
  }

  .MuiTableCell-body {
    background: none;
  }
`;

export const tableRowWarningStyle = css`
  background: ${colors.orangeBackground};
`;
