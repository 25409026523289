import { PermissionKey } from 'app/schemaInterfaces/globalTypes';
import { Action } from 'components/ActionsMenu/ActionsMenu';

import { TFunction } from 'i18next';

export const pdfActions = (
  downloadPdfHd: () => void,
  downloadPdfLd: () => void,
  t: TFunction,
  hasPermissions: (permission: PermissionKey) => boolean
): Action[] => {
  const actions = [
    ...(hasPermissions(PermissionKey.CreatePdfHD)
      ? [
          {
            title: t('workflow.toolbar.pdf_hd'),
            onClick: downloadPdfHd,
          },
        ]
      : []),

    ...(hasPermissions(PermissionKey.CreatePdfBD)
      ? [
          {
            title: t('workflow.toolbar.pdf_ld'),
            onClick: downloadPdfLd,
          },
        ]
      : []),

    //TODO : uncomment when we implement Web
    // ...(hasPermissions(PermissionKey.CreatePdfWeb) ? [] : []),
  ];

  return actions;
};
