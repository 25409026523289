export enum ModuleKey {
  EmailSMS = 'EmailSMS',
  Digital = 'Digital',
  Competitors = 'Competitors',
  PIM = 'PIM',
  DAM = 'DAM',
  BS = 'BS',
  OM = 'OM',
  OP = 'OP',
  ActionMarketing = 'ActionMarketing',
  AM_OP_Locales = 'AM_OP_Locales',
  AM_OP_Nationales = 'AM_OP_Nationales',
  AM_Planning = 'AM_Planning',
}
