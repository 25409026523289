import { useMutation, gql } from '@apollo/client';

const ADD_REPLY = gql`
  mutation addReply(
    $versionId: String!
    $commentId: String!
    $pageId: String!
    $text: String!
    $userId: String!
  ) {
    addReply(
      versionId: $versionId
      commentId: $commentId
      pageId: $pageId
      reply: { text: $text, userId: $userId }
    ) {
      clientPosition {
        x
        y
      }
      createdAt
      id
      isSeen
      offsetPosition {
        x
        y
      }
      replies {
        createdAt
        id
        text
        userId
      }
      text
      userId
    }
  }
`;

export const useAddReply = () => useMutation(ADD_REPLY);
