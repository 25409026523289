import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react/macro';
import colors from 'styles/colors.module.scss';
import { Tooltip } from '@material-ui/core';

const OrderTerminatedTag = ({ title }: { title: string }) => {
  const { t } = useTranslation();

  const style = css`
    background: ${colors.tagBackground0};
    border-radius: 40px;
    color: ${colors.tagTextColor0};
    font-family: ScandiaWebMedium;
    font-size: 12px;
    font-weight: 500;
    max-width: 150px;
    overflow: hidden;
    padding: 5px 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: fit-content;
    height: 27px;
  `;

  return (
    <Tooltip
      title={t('local.campaign_page.summary.tooltip') ?? ''}
      placement="right-start"
    >
      <span css={style}>{title}</span>
    </Tooltip>
  );
};

export default OrderTerminatedTag;
