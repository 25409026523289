import React from 'react';
import Breadcrumbs from 'modules/shop/components/Breadcrumbs';
import { useTranslation } from 'react-i18next';
import { competitorsBreadcrumbs } from './CompetitorsContainer.helper';
import { competitorsStyle } from './CompetitorsContainer.style';
import CircularProgress from '@material-ui/core/CircularProgress';
import useCurrentEntity from 'app/current-entity-context/useCurrentEntity';
import { GET_COMPETITORS } from 'app/graphql/queries/competitors';
import {
  GetCompetitors,
  GetCompetitorsVariables,
} from 'app/schemaInterfaces/GetCompetitors';
import { useQuery } from '@apollo/client';
import StartCompetitiveDevelopment from '../StartCompetitiveDevelopment/StartCompetitiveDevelopment';
import CompetitorsPage from '../CompetitorsPage/CompetitorsPage';
import { AscDescType } from 'app/schemaInterfaces/globalTypes';

const CompetitorsContainer = (): JSX.Element => {
  const { t } = useTranslation();
  const { currentEntity } = useCurrentEntity();

  const { data, loading, refetch } = useQuery<
    GetCompetitors,
    GetCompetitorsVariables
  >(GET_COMPETITORS, {
    variables: {
      filters: {
        entityId: currentEntity?.id ?? '',
      },
      sort: {
        updatedAt: AscDescType.desc,
      },
    },
  });

  return (
    <div css={competitorsStyle} data-testid="competitors-container">
      <div className="breadcrumbs">
        <Breadcrumbs paths={competitorsBreadcrumbs(t)} />
      </div>
      {loading ? (
        <CircularProgress />
      ) : !data?.competitors.length ? (
        <StartCompetitiveDevelopment refetch={refetch} />
      ) : (
        <CompetitorsPage competitors={data.competitors} refetch={refetch} />
      )}
    </div>
  );
};

export default CompetitorsContainer;
