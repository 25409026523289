import React, { Dispatch, SetStateAction } from 'react';

import { GetCurrentClient_client_structures } from 'app/schemaInterfaces/GetCurrentClient';
import { GetEntityById_entity } from 'app/schemaInterfaces/GetEntityById';
import { Maybe } from 'app/utils/common';

export interface CurrentEntityContextData {
  fetchAndSetCurrentEntity: (id?: string) => void;
  visibleStructures: GetCurrentClient_client_structures[];
  currentEntity: Maybe<GetEntityById_entity>;
  setCurrentEntity: Dispatch<SetStateAction<Maybe<GetEntityById_entity>>>;
}

const CurrentEntityContext = React.createContext<CurrentEntityContextData | null>(
  null
);

export default CurrentEntityContext;
