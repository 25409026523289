import React from 'react';
import CommentCards, {
  CommentsTypes,
} from 'modules/workflow/containers/CatalogPreview/CommentCards';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import colors from 'styles/colors.module.scss';
import { studioAppActions } from 'modules/workflow/reducers/actionsInterfaces';
import {
  useStudioDispatchContext,
  useStudioStateContext,
} from 'modules/workflow/oneStudioContext';
import { useTranslation } from 'react-i18next';
import { getTheDate } from 'app/utils/common';
import { useMutation, useQuery } from '@apollo/client';
import {
  DELETE_COMMENT,
  DELETE_REPLY,
  GET_COMMENTS_BY_VERSION,
  UPDATE_COMMENT,
  UPDATE_REPLY,
} from 'app/graphql/queries/comments';
import {
  DeleteReply,
  DeleteReplyVariables,
} from 'app/schemaInterfaces/DeleteReply';
import {
  DeleteComment,
  DeleteCommentVariables,
} from 'app/schemaInterfaces/DeleteComment';
import {
  UpdateComment,
  UpdateCommentVariables,
} from 'app/schemaInterfaces/UpdateComment';
import {
  UpdateReply,
  UpdateReplyVariables,
} from 'app/schemaInterfaces/UpdateReply';
import {
  CommentsByVersion,
  CommentsByVersionVariables,
  CommentsByVersion_commentsByVersion,
} from 'app/schemaInterfaces/CommentsByVersion';
import CircularProgressLoader from 'components/Loader/CircularProgressLoader/CircularProgressLoader';
import { css } from '@emotion/react';
const buttonStyle = {
  marginTop: '36px',
  marginBottom: '20px',
};

const panelStyle = css`
  .add-button {
    margin-top: 36px;
    margin-bottom: 20px;
    border-radius: 25px;
  }
  .activated {
    background-color: ${colors.menuHover};
  }
`;

const style = (isTypeReply: boolean) => css`
  border-left: ${isTypeReply ? `2px solid ${colors.lightGrey}` : 'none'};
  .arrow {
    cursor: pointer;
  }
`;

const useStyles = makeStyles({
  root: {
    marginBottom: '16px',
  },
  outlined: buttonStyle,
  activated: {
    ...buttonStyle,
    backgroundColor: colors.menuHover,
  },
  noCommentRoot: {
    marginBottom: '20px',
  },
});

const CommentsPanel = (): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();
  const globalState = useStudioStateContext();
  const dispatch = useStudioDispatchContext();

  const isCurrentPageValidated: boolean =
    globalState.version.pages[globalState.currentPage - 1]?.validated;

  const [deleteComment] = useMutation<DeleteComment, DeleteCommentVariables>(
    DELETE_COMMENT,
    {
      refetchQueries: [
        {
          query: GET_COMMENTS_BY_VERSION,
          variables: {
            versionId: globalState.version.id,
          },
        },
      ],
    }
  );
  const [deleteReply] = useMutation<DeleteReply, DeleteReplyVariables>(
    DELETE_REPLY
  );
  const [updateComment] = useMutation<UpdateComment, UpdateCommentVariables>(
    UPDATE_COMMENT
  );
  const [updateReply] = useMutation<UpdateReply, UpdateReplyVariables>(
    UPDATE_REPLY
  );
  const { data, loading, error, refetch } = useQuery<
    CommentsByVersion,
    CommentsByVersionVariables
  >(GET_COMMENTS_BY_VERSION, {
    variables: {
      versionId: globalState.version.id,
    },
  });

  let comments = [data?.commentsByVersion[globalState.currentPage - 1]];

  let isTwoPages = false;
  if (
    globalState.isTwoPagesView &&
    globalState.currentPage !== globalState.version.pages.length &&
    data
  ) {
    isTwoPages = true;
    comments = [
      ...comments,
      [...data.commentsByVersion[globalState.currentPage]],
    ];
  }

  const buttonAddComment: JSX.Element = (
    <Button
      className={
        globalState.commentMode ? 'add-button activated' : 'add-button'
      }
      disabled={isCurrentPageValidated}
      onClick={() => {
        dispatch(
          studioAppActions.setCommentMode({
            commentMode: true,
          })
        );
      }}
      color="primary"
      variant="outlined"
    >
      <Typography variant="h5">{t(`workflow.add_comment`)}</Typography>
    </Button>
  );

  const deleteCommentHandler = (idComment: string) => {
    deleteComment({
      variables: {
        versionId: globalState.version.id,
        commentId: idComment,
        pageId: (globalState.currentPage - 1).toString(),
      },
    });
  };

  const deleteReplyHandler = (idComment: string, idReply: string) => {
    deleteReply({
      variables: {
        versionId: globalState.version.id,
        commentId: idComment,
        pageId: (globalState.currentPage - 1).toString(),
        replyId: idReply,
      },
    });
  };

  const updateCommentHandler = async (idComment: string, text: string) => {
    await updateComment({
      variables: {
        versionId: globalState.version.id,
        commentId: idComment,
        pageId: (globalState.currentPage - 1).toString(),
        text,
      },
    });
    refetch();
  };

  const updateReplyHandler = (
    idComment: string,
    idReply: string,
    text: string
  ) => {
    updateReply({
      variables: {
        versionId: globalState.version.id,
        commentId: idComment,
        pageId: (globalState.currentPage - 1).toString(),
        replyId: idReply,
        text,
      },
    });
  };

  const addComment = (
    commentsPerPage: CommentsByVersion_commentsByVersion[] | undefined,
    pageIndex: number
  ): JSX.Element => (
    <Box marginTop={!pageIndex ? '5px' : '15px'} marginBottom="5px">
      <Typography variant="h5">
        {t(`workflow.comment_of_the_page`, {
          page: globalState.currentPage + pageIndex,
        })}
      </Typography>
      {!commentsPerPage?.length && (
        <Box style={{ textAlign: 'center' }}>
          <Typography
            variant="body1"
            align="center"
            classes={{ root: classes.noCommentRoot }}
          >
            {t(`workflow.no_comment_added_yet`)}
          </Typography>
          <Typography variant="body1" align="center">
            {t(`workflow.no_comment_added_yet_subtext`)}
          </Typography>
          {buttonAddComment}
        </Box>
      )}
    </Box>
  );

  if (loading) {
    return <CircularProgressLoader error={error} />;
  } else {
    return (
      <Box css={panelStyle}>
        {comments.map((commentsPerPage, pageIndex) => {
          const pageNumber = globalState.currentPage - 1 + pageIndex;

          return (
            <Box
              mt={pageIndex === 0 ? 0 : 2}
              key={pageIndex}
              color={colors.toolBarBackGround}
            >
              {isTwoPages && addComment(commentsPerPage, pageIndex)}
              {!isTwoPages &&
                !commentsPerPage?.length &&
                addComment(commentsPerPage, pageIndex)}
              {commentsPerPage?.map((comment, index) => (
                <React.Fragment key={index}>
                  <CommentCards
                    item={comment}
                    type={CommentsTypes.COMMENT}
                    postedDate={getTheDate(comment.createdAt)}
                    commentIndex={index}
                    pageIndex={pageNumber}
                    deleteComment={() => deleteCommentHandler(comment.id)}
                    updateComment={(text) =>
                      updateCommentHandler(comment.id, text)
                    }
                  />
                  <div css={style(Boolean(comment.replies?.length))}>
                    {comment.replies?.map((reply, index) => (
                      <Box ml="17px" key={index}>
                        <CommentCards
                          key={index}
                          item={reply}
                          type={CommentsTypes.REPLY}
                          postedDate={getTheDate(reply.createdAt)}
                          pageIndex={pageNumber}
                          commentIndex={index}
                          deleteComment={() =>
                            deleteReplyHandler(comment.id, reply.id)
                          }
                          updateComment={(text) =>
                            updateReplyHandler(comment.id, reply.id, text)
                          }
                        />
                      </Box>
                    ))}
                    <Box ml={Boolean(comment.replies?.length) ? '17px' : '0'}>
                      <CommentCards
                        key={index}
                        item={comment}
                        type={CommentsTypes.ADD_REPLY}
                        commentIndex={index}
                        pageIndex={pageNumber}
                      />
                    </Box>
                  </div>
                </React.Fragment>
              ))}
            </Box>
          );
        })}
      </Box>
    );
  }
};

export default CommentsPanel;
