import {
  DocumentFilterInput,
  DocumentOrderType,
} from 'app/schemaInterfaces/globalTypes';
import { FilterInputProps } from 'components/FilterOptions/FilterOptions';
import { UncontrolledMultipleValueSelectV2 } from 'components/UncontrolledMultipleValueSelectV2';
import { setTypeName } from 'modules/pro/campaigns/campaignsHelper';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface UseDocumentsFilterPopoverProps {
  mediumTypesOptions: string[];
  numbersOfPagesOptions: number[];
  orderTypesOptions: string[];
  filter: DocumentFilterInput;
  setFilter: React.Dispatch<React.SetStateAction<DocumentFilterInput>>;
}

export const useDocumentsFilterPopover = ({
  mediumTypesOptions,
  numbersOfPagesOptions,
  orderTypesOptions,
  filter,
  setFilter,
}: UseDocumentsFilterPopoverProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const { t } = useTranslation();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const resetFilter = () => {
    setFilter({
      ...filter,
      orderTypes: null,
      mediumTypes: null,
      numberOfPages: null,
    });
  };

  const handleChangeMediumType = (
    event: React.ChangeEvent<{ value: string[] }>
  ) => {
    setFilter({
      ...filter,
      mediumTypes: event.target.value.length ? event.target.value : null,
    });
  };
  const handleTypeChange = (
    event: React.ChangeEvent<{ value: DocumentOrderType[] }>
  ) => {
    setFilter({
      ...filter,
      orderTypes: event.target.value.length ? event.target.value : null,
    });
  };

  const handleChangeNumberOfPages = (
    event: React.ChangeEvent<{ value: string[] }>
  ) => {
    const numericValues = event.target.value.map((value) =>
      Number.parseInt(value)
    );
    setFilter({
      ...filter,
      numberOfPages: event.target.value.length ? numericValues : null,
    });
  };

  const documentsFilterInputs: FilterInputProps[] = [
    {
      filterTitile: t('global.documents_list.filters_popover.medium_type'),
      filterInput: (
        <UncontrolledMultipleValueSelectV2
          value={filter.mediumTypes ?? []}
          options={mediumTypesOptions
            ?.map((value) => ({
              label: value,
              value,
            }))
            .sort((first, second) => first.label.localeCompare(second.label))}
          handleChange={handleChangeMediumType}
          placeholder={t('common.placeholder.all')}
          isFullWidth={true}
        />
      ),
    },
    {
      filterTitile: t('global.documents_list.filters_popover.order_types'),
      filterInput: (
        <UncontrolledMultipleValueSelectV2
          value={filter.orderTypes ?? []}
          options={orderTypesOptions
            ?.map((value) => ({
              label: setTypeName(t)[value as DocumentOrderType],
              value,
            }))
            .sort((first, second) => first.label.localeCompare(second.label))}
          isFullWidth={true}
          placeholder={t('common.placeholder.all')}
          handleChange={handleTypeChange}
        />
      ),
    },
    {
      filterTitile: t('global.documents_list.filters_popover.number_of_pages'),
      filterInput: (
        <UncontrolledMultipleValueSelectV2
          value={
            filter.numberOfPages
              ? filter.numberOfPages.map((value) => value.toString())
              : []
          }
          options={numbersOfPagesOptions
            ?.map((value) => ({
              label: value.toString(),
              value: value.toString(),
            }))
            .sort(
              (first, second) =>
                Number.parseInt(first.value) - Number.parseInt(second.value)
            )}
          isFullWidth={true}
          placeholder={t('common.placeholder.all')}
          handleChange={handleChangeNumberOfPages}
        />
      ),
    },
  ];

  const isOpen = Boolean(anchorEl);
  const popoverId = isOpen ? 'simple-popover' : undefined;
  return {
    handleClick,
    handleClose,
    popoverId,
    anchorEl,
    resetFilter,
    documentsFilterInputs,
  };
};
