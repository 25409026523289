import { useQuery, gql } from '@apollo/client';

const GET_CAMPAIGN = gql`
  query GET_CAMPAIGN($campaignId: String!) {
    campaign: getCampaignById(id: $campaignId) {
      id
      name
      dateFrom
      dateTo
      entities {
        id
      }
    }
  }
`;

interface GetCampaign {
  campaign: {
    id: string;
    name: string;
    dateFrom: string;
    dateTo: string;
    entities: { id: string }[];
  };
}

export const useGetCampaign = (campaignId: string) => {
  return useQuery<GetCampaign>(GET_CAMPAIGN, {
    variables: { campaignId },
    skip: !campaignId,
  });
};
