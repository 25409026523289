import { gql } from '@apollo/client';

export const FONT_DETAILS_FRAGMENT = gql`
  fragment FontDetails on FontDetails {
    fontFamily
    fontSize
  }
`;

export const CLIENT_CONFIG_FRAGMENT = gql`
  fragment ClientConfig on ClientConfig {
    clientAssets {
      logo
      banner
      sms
      digital
    }
    pim {
      fields {
        cellSheetName
        key
        label
      }
      formConfig
      listConfig {
        cellSheetName
        colspan
        divisions {
          clientId
          divisionName
          id
          subDivisionName
          value
        }
        editIndex
        key
        label
        listIndex
        placeholder
        required
        subTabName
        tabName
        unifiedKey
        valueHardcoded
      }
      requiredFields {
        colspan
        editIndex
        key
        label
        listIndex
        placeholder
        required
        subTabName
        tabName
        unifiedKey
        valueHardcoded
        type
        entityIds
        isRegrouping
        divisions {
          id
        }
        cellSheetName
        parentFieldKey
        parentFieldValue
      }
    }
    workflow {
      templates {
        zones {
          height
          width
          x
          y
        }
      }
      supportConfig {
        supportId
        productConfig {
          size {
            height
            width
          }
        }
        personalizationTypography {
          title {
            ...FontDetails
          }
          subtitle {
            ...FontDetails
          }
          paragraph {
            ...FontDetails
          }
          date {
            ...FontDetails
          }
        }
        regroupingConfig {
          position {
            x
            y
          }
          size {
            width
            height
          }
          spacing
          positionRatio
          typographyOnImage {
            fontSize
            fontFamily
            key
          }
          typographyOffImage {
            fontSize
            fontFamily
            key
          }
        }
      }
    }
  }
  ${FONT_DETAILS_FRAGMENT}
`;
