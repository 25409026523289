import { css } from '@emotion/react';
import colors from 'styles/colors.module.scss';

export const documentPreviewStyle = css`
  display: flex;

  .document {
    background: ${colors.documentsHeaderBackground};
    margin-right: 8px;
    align-items: center;
    display: flex;
    flex-direction: column;

    .preview {
      max-width: 100%;
      min-width: 510px;
      max-height: 540px;
      object-fit: contain;
      padding-top: 16px;
    }
    .arrows {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 8px;
      background-color: ${colors.white};
      width: 136px;
      height: 40px;
      box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.11);
      border-radius: 5px;
      color: ${colors.primary};
      svg {
        font-size: 23px;
        color: ${colors.primary};
      }
    }
  }
  .document-details {
    padding: 16px;
    width: 404px;
    overflow: scroll;
    height: 588px;
    .recap-attribute--container {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      padding-top: 16px;
      padding-bottom: 16px;
    }

    .recap-attribute--container:last-child {
      border-bottom: none;
      padding-top: 16px;
      padding-bottom: 16px;
    }
    .label {
      color: ${colors.documentsTab};
      font-weight: bold;
      text-transform: capitalize;
    }
    .value {
      color: ${colors.documentsTab};
    }
    .custumizable-tag {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
`;
