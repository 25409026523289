import React, { useContext, useEffect, useState } from 'react';
import colors from 'styles/colors.module.scss';
import useDebounce from 'components/useDebounce';
import QuickSearchBar from 'components/QuickSearchBar/QuickSearchBar';
import { AssetFiltersActionCreator } from 'modules/dam/reducers/filters/actions';
import { AssetFiltersContext } from 'modules/dam/reducers/filters/context';

interface AssetSearchProps {
  text: string;
  hasBorder?: boolean;
}

export const AssetSearch = ({
  text,
  hasBorder = true,
}: AssetSearchProps): JSX.Element => {
  const dispatch = useContext(AssetFiltersContext);
  const [value, setValue] = useState(text);
  const debouncedValue = useDebounce(value, 500);

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    setValue(text);
  }, [text]);

  useEffect(() => {
    if (debouncedValue !== undefined && dispatch) {
      dispatch(AssetFiltersActionCreator.updateSearch(debouncedValue));
    }
  }, [debouncedValue, dispatch]);

  return (
    <QuickSearchBar
      hasBorder={hasBorder}
      bgcolor={colors.white}
      value={value}
      onChange={handleChange}
    />
  );
};
