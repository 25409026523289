import { useState, useEffect } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import {
  useStudioStateContext,
  useStudioDispatchContext,
} from 'modules/workflow/oneStudioContext';

import {
  Menu,
  NormalizedIProductTemplate,
} from 'modules/workflow/containers/CatalogPreview/EditPlatformDefinitions';
import { IProductTemplate } from 'modules/workflow/Clients/ToutFaire/ProductItem';
import { studioAppActions } from 'modules/workflow/reducers/actionsInterfaces';
import useAppContext from 'app/app-context/useAppContext';
import { UpdateProduct_updateProduct } from 'app/schemaInterfaces/UpdateProduct';
import {
  GetProductById,
  GetProductByIdVariables,
} from 'app/schemaInterfaces/GetProductById';
import { GET_PRODUCT_BY_ID } from 'app/graphql/queries/products';
import { createNewProductfromPreviousOne } from 'modules/workflow/containers/Leftsidebar/ProductsPanel/helpers/visualIdentitiesHelper';

interface UseWorkflowProductUpdateProps {
  productId: string;
  isUpdatedProductDetails: UpdateProduct_updateProduct;
  productDetails: NormalizedIProductTemplate;
}

interface ProductToEdit {
  productData: Record<string, any>;
}

export const useWorkflowProductUpdate = ({
  productId,
  isUpdatedProductDetails,
  productDetails,
}: UseWorkflowProductUpdateProps) => {
  const [productToEdit, setProductToEdit] = useState<ProductToEdit>({
    productData: {},
  });

  const client = useApolloClient();
  const {
    client: { config: clientConfig },
  } = useAppContext();
  const globalState = useStudioStateContext();
  const dispatch = useStudioDispatchContext();

  const [isProductsSheetDialogOpen, setIsProductsSheetDialogOpen] = useState<
    boolean
  >(false);

  const { data: productSheetData } = useQuery<
    GetProductById,
    GetProductByIdVariables
  >(GET_PRODUCT_BY_ID, {
    variables: {
      productId: productId as string,
    },
    skip: !productId,
    onCompleted() {
      setProductToEdit({
        productData: productSheetData?.getProduct.details,
      });
      setIsProductsSheetDialogOpen(true);
    },
  });

  const { productData } = productToEdit;

  useEffect(() => {
    if (isUpdatedProductDetails && productDetails) {
      const updatedVersionProduct = async () => {
        dispatch(
          studioAppActions.replaceProduct({
            itemToDelete: {
              id: productDetails.id,
              type: Menu.Product,
              pageNumber: globalState.currentPage - 1,
            },
            itemToAdd: (await createNewProductfromPreviousOne({
              data: isUpdatedProductDetails,
              client,
              clientConfig,
              productDetails,
              globalState,
            })) as IProductTemplate,
            scaleValue: globalState.scaleValue,
          })
        );
      };
      updatedVersionProduct();
    }
  }, [
    isUpdatedProductDetails,
    client,
    clientConfig,
    dispatch,
    globalState.currentPage,
    productDetails,
    globalState,
  ]);

  return {
    productData,
    isProductsSheetDialogOpen,
    setIsProductsSheetDialogOpen,
  };
};
