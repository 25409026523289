import { useQuery } from '@apollo/client';
import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useCurrentUser } from 'app/auth/UserContext';
import useCurrentEntity from 'app/current-entity-context/useCurrentEntity';
import { GET_ENTITY_GENERAL_INFO } from 'app/graphql/queries/entities';
import {
  GetEntityGeneralInfo,
  GetEntityGeneralInfoVariables,
} from 'app/schemaInterfaces/GetEntityGeneralInfo';
import { useAddressPositionResolver } from 'app/utils/customHooks/useAddressPositionResolver';
import StaticMap from 'components/StaticMap/StaticMap';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  blockStyle,
  containerStyle,
  dataTableInlineStyle,
  dataTableStyle,
  gridStyle,
  headerStyle,
  mapStyle,
} from './StorePage.style';

const StorePage: FC = () => {
  const { t } = useTranslation();
  const user = useCurrentUser();
  const { currentEntity } = useCurrentEntity();
  const { data: info } = useQuery<
    GetEntityGeneralInfo,
    GetEntityGeneralInfoVariables
  >(GET_ENTITY_GENERAL_INFO, {
    variables: { entityId: currentEntity?.id ?? '' },
    skip: !currentEntity,
  });
  const address = (
    info?.entityDeliveryAdresses?.find((it) => it.isMain) ??
    info?.entityDeliveryAdresses?.[0]
  )?.address;
  const { data: marker } = useAddressPositionResolver({ address });
  const zoom = 15;

  return (
    <div css={containerStyle}>
      <h1 css={headerStyle}>{t('menuItems.general_info')}</h1>
      <div css={gridStyle}>
        {info ? (
          <div>
            <div css={blockStyle}>
              <h2>{t('one_manager.store_information.general.location')}</h2>
              <div css={dataTableStyle}>
                <dl>
                  <dt>
                    {t('one_manager.store_information.general.location_name')}
                  </dt>
                  <dd>{address?.name}</dd>
                </dl>
                <dl>
                  <dt>
                    {t(
                      'one_manager.store_information.general.location_address'
                    )}
                  </dt>
                  <dd>
                    {address?.firstAddress}
                    <br />
                    {address?.zipCode} {address?.city?.toUpperCase()}
                  </dd>
                </dl>
                <dl>
                  <dt>
                    {t(
                      'one_manager.store_information.general.location_country'
                    )}
                  </dt>
                  <dd>{address?.country}</dd>
                </dl>
              </div>
            </div>
            <div css={blockStyle}>
              <h2>{t('one_manager.store_information.general.personal')}</h2>
              <div css={dataTableStyle}>
                <dl>
                  <dt>
                    {t('one_manager.store_information.general.personal_name')}
                  </dt>
                  <dd>
                    {user.givenName} {user.familyName}
                  </dd>
                </dl>
              </div>
              <hr />
              <div css={dataTableStyle}>
                <dl>
                  <dt>
                    {t('one_manager.store_information.general.personal_email')}
                  </dt>
                  <dd>{user.email}</dd>
                </dl>
              </div>
              <hr />
              <div css={[dataTableStyle, dataTableInlineStyle]}>
                <dl>
                  <dt>
                    {t(
                      'one_manager.store_information.general.personal_password'
                    )}
                  </dt>
                  <dd>
                    <a
                      href="mailto:support@bigone.one"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t(
                        'one_manager.store_information.general.contact_support'
                      )}
                    </a>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <Skeleton variant="rect" height="256px" />
            <Box mt="48px">
              <Skeleton variant="rect" height="256px" />
            </Box>
          </div>
        )}
        {marker && (
          <StaticMap marker={marker} zoom={zoom} css={mapStyle}>
            <strong>{address?.name}</strong>
            <br />
            {address?.firstAddress}
            <br />
            {address?.zipCode} {address?.city?.toUpperCase()}
            <br />
            {address?.country}
          </StaticMap>
        )}
      </div>
    </div>
  );
};

export default StorePage;
