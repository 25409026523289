import React from 'react';
import { FormLabel, Grid, TextField } from '@material-ui/core';
import DeliveryBasketRow from 'modules/sales/components/DeliveryBasketRow/DeliveryBasketRow';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import BasketConfirmation from '../../BasketConfirmation/BasketConfirmation';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import {
  basketConfirmationTitle,
  basketDateTag,
  getLastDate,
} from 'modules/sales/salesHelpers';
import { BasketType } from 'app/schemaInterfaces/globalTypes';
import { deliveryStepStyle } from './DeliveryStep.style';
import { useDeliveryStep } from './DeliveryStep.hooks';
import BasketConfirmationSummary from '../../BasketConfirmationSummary/BasketConfirmationSummary';
import AlertBox from 'components/AlertBox/AlertBox';

const DeliveryStep = () => {
  const {
    setName,
    isContinueDisabled,
    handleComment,
    onCancel,
    onChangeAddress,
    onSubmit,
    campaignsWithDocuments,
    handleUpdateBasketDocument,
    basketType,
    campaignName,
    basketDocuments,
    errors,
  } = useDeliveryStep();
  const { t } = useTranslation();

  return (
    <div css={deliveryStepStyle}>
      <Grid container spacing={4} className="delivery-grid">
        <Grid item xs={8}>
          {basketType === BasketType.BigShop && (
            <>
              <h1>{t('sales.basket_page.delivery_page.campaign_title')}</h1>
              <p>{t('sales.basket_page.delivery_page.campaign_subtitle')}</p>
              <div className="campaign-name">
                <FormLabel className="label">
                  {t('sales.basket_page.delivery_page.campaign_name')} *
                </FormLabel>
                <TextField
                  variant="outlined"
                  className="field"
                  value={campaignName ?? ''}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </>
          )}
          <h1
            style={{
              paddingTop: basketType === BasketType.BigShop ? '36px' : '0',
            }}
          >
            {t('sales.basket_page.delivery_page.title')}
          </h1>
          <p>{t('sales.basket_page.delivery_page.subtitle')}</p>
          {campaignsWithDocuments.map(
            ({ campaign, basketDocuments }, index) => (
              <div key={index}>
                <div className="campaign-header">
                  <div>
                    <h3>{campaign.name}</h3>
                    <span>
                      {t('sales.basket_page.medium_count', {
                        count: basketDocuments.length,
                      })}
                    </span>
                  </div>
                  <h5>
                    {`${basketDateTag(basketType, t)} : ${moment(
                      getLastDate(
                        basketDocuments.map(
                          (basketDocumentItem) =>
                            basketDocumentItem.basketDocument
                        )
                      )
                    ).format(' DD/MM/YYYY')}`}{' '}
                  </h5>
                </div>
                <div>
                  {basketDocuments.map((basketDocumentItem) => (
                    <div
                      key={basketDocumentItem.basketDocument.id}
                      className="delivery-basket-row"
                    >
                      <DeliveryBasketRow
                        isCommitment={basketType === BasketType.Commitment}
                        basketDocumentItem={basketDocumentItem}
                        setAddress={(basketAddress, indexRow) =>
                          onChangeAddress(
                            basketDocumentItem,
                            basketAddress,
                            indexRow
                          )
                        }
                        handleUpdateBasketDocument={handleUpdateBasketDocument}
                        setComment={(comment) =>
                          handleComment(basketDocumentItem, comment)
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
            )
          )}
        </Grid>
        <Grid item xs={4}>
          <BasketConfirmation
            onFirstBtnClick={onSubmit}
            onSecondBtnClick={onCancel}
            length={basketDocuments.length}
            isDisabled={isContinueDisabled}
            dataBloc={
              <BasketConfirmationSummary basketDocuments={basketDocuments} />
            }
            firstTitle={t('sales.basket_page.delivery_page.confirmation_title')}
            secondTitle={t('sales.basket_page.delivery_page.continue_sales')}
            title={basketConfirmationTitle(basketType, t)}
            icon={<ArrowForwardOutlinedIcon />}
          />
          {errors.length > 0 && (
            <AlertBox
              variant="warning"
              header={errors.map((msg) => (
                <p>{msg}</p>
              ))}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};
export default DeliveryStep;
