import React, { useState } from 'react';
import DetachableFormWrapper from 'components/DetachableFormWrapper/DetachableFormWrapper';
import ProductsSheetsFormAssetsTabs from 'modules/pim/components/ProductsSheetsFormAssetsTabs';
import ProductsSheetsFormDetails from 'modules/pim/components/ProductsSheetsFormDetails';
import ProductsSheetFormCreation from 'modules/pim/baseProducts/ProductsSheetFormCreation';
import { useProductsSheetsContext } from 'modules/pim/baseProducts/BaseProducts';
import colors from 'styles/colors.module.scss';
import { useTranslation } from 'react-i18next';
import { IProductTemplate } from 'modules/workflow/Clients/ToutFaire/ProductItem';
import { UpdateProduct_updateProduct } from 'app/schemaInterfaces/UpdateProduct';
import ProductsSheetsFormProductsPanel from './ProductsSheetsFormProductsPanel';

interface ProductSheetEditProps {
  openProductsSheetDialog?: boolean;
  handleCloseProductsSheetDialog: () => void;
  handleOpenHistory: () => void;
  productData: Record<string, any>;
  isHistoryOpen: boolean;
  campaignId?: string;
  productId?: string;
  setIsUpdatedProductDetails?: React.Dispatch<
    React.SetStateAction<UpdateProduct_updateProduct | undefined>
  >;
  worflowProduct?: IProductTemplate;
}

const ProductSheetEdit = ({
  openProductsSheetDialog,
  handleCloseProductsSheetDialog,
  handleOpenHistory,
  productData,
  isHistoryOpen,
  campaignId,
  productId,
  setIsUpdatedProductDetails,
  worflowProduct,
}: ProductSheetEditProps): JSX.Element => {
  const { fieldName, setFieldName } = useProductsSheetsContext();
  const [getAssetId, setGetAssetId] = useState<string | undefined>(undefined);
  const { t } = useTranslation();

  const getDetachableChildren = () => {
    if (fieldName === t('pim.field_name.regrouping')) {
      return <ProductsSheetsFormProductsPanel productId={productId} />;
    } else if (fieldName === undefined) {
      return (
        <ProductsSheetsFormDetails
          productId={productId as string}
          campaignId={campaignId}
        />
      );
    } else
      return (
        <ProductsSheetsFormAssetsTabs
          setGetAssetId={setGetAssetId}
          getAssetId={getAssetId}
          productId={productId}
        />
      );
  };

  const label =
    fieldName === t('pim.field_name.regrouping')
      ? t('pim.regrouping.add_regrouping')
      : fieldName === undefined
      ? t('pim.history.history')
      : t('pim.form.add_visual');

  return (
    <DetachableFormWrapper
      isOpen={openProductsSheetDialog as boolean}
      handleClose={handleCloseProductsSheetDialog}
      title={t('pim.form.edit_title')}
      showMore={{ handleOpen: handleOpenHistory, title: t('pim.form.details') }}
      detachable={{
        children: getDetachableChildren,
        isOpen: fieldName ? true : isHistoryOpen,
        goBack: {
          label: label,
          handleClose: () =>
            fieldName ? setFieldName(undefined) : handleOpenHistory(),
        },
        bgcolor: colors.documentsHeaderBackground,
      }}
    >
      <ProductsSheetFormCreation
        handleClose={handleCloseProductsSheetDialog}
        productData={productData}
        campaignId={campaignId}
        productId={productId}
        setIsUpdatedProductDetails={setIsUpdatedProductDetails}
        worflowProduct={worflowProduct}
      />
    </DetachableFormWrapper>
  );
};

export default ProductSheetEdit;
