import { GetClientShippingAddress_entityDeliveryAdresses } from 'app/schemaInterfaces/GetClientShippingAddress';
import React from 'react';
import { style } from 'modules/oneManager/deliveryAddress/deliveryAddressRow.style';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_ROOT } from '../storeHelper';

interface DeliveryAddressRowProps {
  deliveryAddress: GetClientShippingAddress_entityDeliveryAdresses;
}

const DeliveryAddressRow = ({ deliveryAddress }: DeliveryAddressRowProps) => {
  const { t } = useTranslation();

  const address = deliveryAddress.address;
  const contact = deliveryAddress.contact;
  return (
    <div css={style}>
      <div
        style={{
          display: 'flex',
          width: '100%',
        }}
        className="card-content"
      >
        <div>
          <h5>{t(`${TRANSLATION_ROOT}.store_page.address`)}</h5>
          <span>{address?.name}</span>
          <span>{address?.firstAddress}</span>
          <span>
            {address?.zipCode} {address?.city}
          </span>
          <span>{address?.country}</span>
        </div>
        <div>
          <h5>{t(`${TRANSLATION_ROOT}.store_page.contact`)}</h5>
          <span>{contact?.directorName}</span>
          <span>{contact?.phone}</span>
          <span className="email">{deliveryAddress.contact?.email}</span>
        </div>
      </div>
    </div>
  );
};

export default DeliveryAddressRow;
