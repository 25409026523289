import React from 'react';
import { ReorderOutlined, GridOnOutlined } from '@material-ui/icons';
import { switchViewStyle } from './style/switchView.style';

interface SwitchViewProps {
  switchAction: boolean;
  setSwitchAction: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SwitchView = ({
  switchAction,
  setSwitchAction,
}: SwitchViewProps): JSX.Element => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSwitchAction(event.target.checked);
  };
  return (
    <div css={switchViewStyle(switchAction)}>
      <input
        id="checkbox"
        type="checkbox"
        checked={switchAction}
        onChange={handleChange}
      />
      <label htmlFor="checkbox">
        <div className="icon-box">
          <div className="switch-icon-list">
            <ReorderOutlined className="list-icon icon" />
          </div>
          <div className="switch-icon-grid">
            <GridOnOutlined className="grid-icon icon" />
          </div>
        </div>
      </label>
    </div>
  );
};
