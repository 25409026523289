import { Box, CircularProgress, Divider } from '@material-ui/core';
import React, { useEffect, useReducer } from 'react';
import { useInfiniteScroll } from 'components/infinite-scroll/useInfiniteScroll';
import useAssetQuery from 'modules/dam/hooks/useAssetQuery';
import {
  AscDescType,
  AssetStatus,
  AssetType,
} from 'app/schemaInterfaces/globalTypes';
import colors from 'styles/colors.module.scss';
import AssetPanelFilters from 'modules/workflow/containers/Leftsidebar/AssetsPanel/AssetPanelFilters';
import { AssetPanelList } from 'modules/workflow/containers/Leftsidebar/AssetsPanel/AssetPanelList';
import AssetPanelSearch from 'modules/workflow/containers/Leftsidebar/AssetsPanel/AssetPanelSearch';
import AssetPanelTabs from 'modules/workflow/containers/Leftsidebar/AssetsPanel/AssetPanelTabs';
import AssetTypeNavigation from 'modules/workflow/containers/Leftsidebar/AssetsPanel/AssetTypeNavigation';
import { AssetTypeSelectList } from 'modules/workflow/containers/Leftsidebar/AssetsPanel/AssetTypeSelectList';
import { AssetsPanelContext } from 'modules/workflow/containers/Leftsidebar/AssetsPanel/reducer/context';
import {
  AssetDataSource,
  ViewMode,
} from 'modules/workflow/containers/Leftsidebar/AssetsPanel/reducer/definitions';
import {
  assetPanelsInitialState,
  assetsPanelReducer,
} from 'modules/workflow/containers/Leftsidebar/AssetsPanel/reducer/reducer';
import useCurrentEntity from 'app/current-entity-context/useCurrentEntity';
import { useStudioStateContext } from 'modules/workflow/oneStudioContext';
import { AssetsPanelActionCreator } from './reducer/actions';

const AssetsPanel = (): JSX.Element => {
  const [{ viewMode, dataSource, filters }, dispatch] = useReducer(
    assetsPanelReducer,
    assetPanelsInitialState
  );
  const { currentEntity } = useCurrentEntity();
  const globalState = useStudioStateContext();

  const { assets, loading, loadMore } = useAssetQuery(
    {
      filters: {
        entityId: currentEntity?.id,
        text: filters.text,
        assetTypes: filters.assetType
          ? [filters.assetType]
          : [
              AssetType.Visual,
              AssetType.Picto,
              AssetType.Logo,
              AssetType.Charter,
            ],
        status: [AssetStatus.Active],
        divisions: filters.divisions,
        validFrom: filters.validFrom,
        validTo: filters.validTo,
      },
      sort: {
        updatedAt: AscDescType.asc,
      },
    },
    10,
    dataSource !== AssetDataSource.DAM || viewMode === ViewMode.TYPES_VIEW
  );

  const waypoint = useInfiniteScroll(loadMore, loading);

  useEffect(() => {
    if (globalState.tabValue.subType) {
      dispatch?.(
        AssetsPanelActionCreator.updateAssetTypes(globalState.tabValue.subType)
      );
    }
  }, [globalState.tabValue.subType]);

  return (
    <AssetsPanelContext.Provider value={dispatch}>
      <Box p={2} height="100vh" overflow="scroll">
        <Box mb={2}>
          <AssetPanelTabs dataSource={dataSource} />
          <Divider
            style={{ backgroundColor: colors.disabled, width: '100%' }}
          />
        </Box>

        <Box my={1} display="flex" alignItems="center">
          <AssetPanelSearch text={filters.text} />
          {dataSource === AssetDataSource.DAM && (
            <AssetPanelFilters
              divisions={filters.divisions}
              validFrom={filters.validFrom}
              validTo={filters.validTo}
            />
          )}
        </Box>

        <Box mt={2} pl={2}>
          {viewMode === ViewMode.TYPES_VIEW && <AssetTypeSelectList />}

          {viewMode === ViewMode.CONTENT_VIEW && (
            <>
              <Box mb={2}>
                <AssetTypeNavigation selectedAssetType={filters.assetType} />
              </Box>
              {assets && <AssetPanelList assets={assets} />}
              {loading && (
                <Box
                  display="flex"
                  justifyContent="center"
                  color={colors.white}
                >
                  <CircularProgress color="inherit" />
                </Box>
              )}
              {!loading && assets && assets.length > 0 && (
                <div ref={waypoint} />
              )}
            </>
          )}
        </Box>
      </Box>
    </AssetsPanelContext.Provider>
  );
};

export default AssetsPanel;
