import i18next, { Language } from 'app/i18n/i18n';
import { useState } from 'react';
import FlagFR from 'circle-flags/flags/fr.svg';
import FlagGB from 'circle-flags/flags/gb.svg';
import FlagBE from 'circle-flags/flags/be.svg';
// import FlagDE from 'circle-flags/flags/de.svg';

export const useLanguageSwitchToolbar = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLImageElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (language: Language) => {
    i18next.changeLanguage(language as string);
    setAnchorEl(null);
  };

  const languageIcons: Record<Language, string> = {
    // [Language.DE]: FlagDE, // temporary
    [Language.EN]: FlagGB,
    [Language.FR]: FlagFR,
    [Language.VLS]: FlagBE,
  };
  return {
    anchorEl,
    handleClick,
    handleClose,
    handleMenuItemClick,
    languageIcons,
  };
};
