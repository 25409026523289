import React, { useState } from 'react';
import {
  DigitalMenu,
  useStudioStateContext,
} from 'modules/workflow/oneStudioContext';
import DigitalTabs from './DigitalTabs';
import EditionBar from './EditionBar';
import DigitalTextEditBar from './DigitalTextEditBar';

const TabPanels: JSX.Element[] = [<EditionBar />, <DigitalTextEditBar />];

const LeftSideBar = (): JSX.Element => {
  const [digitalTabValue, setDigitalTabValue] = useState<DigitalMenu>(
    DigitalMenu.VISUAL
  );
  const globalState = useStudioStateContext();
  return (
    <div>
      {globalState.isDigital && (
        <DigitalTabs
          digitalTabValue={digitalTabValue}
          setDigitalTabValue={setDigitalTabValue}
        />
      )}
      {TabPanels[digitalTabValue]}
    </div>
  );
};

export default LeftSideBar;
